import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    protocolIssuesCounter: {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      border: '1px solid black',
      marginRight: '51px',
      fontFamily: 'Archivo',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '13px',
      letterSpacing: '0em',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);
