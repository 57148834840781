import React, { ReactElement, useCallback } from 'react';
import CreateButton from './createButton';
import SearchInput from './IssueSearchInput';
import FiltersToggler, {
  FiltersTogglerMenuItem,
} from 'components/core/Layout/filters/FiltersToggler';
import TableSettings from './tableView/TableSettings';
import styles from './styles';
import {
  Pagination,
  usePagination,
} from 'components/common/withPagination/withPagination';
import {
  ReportPdfButton,
  ReportPdfMenuItem,
} from 'components/reports/reportPdfButton';
import useIsMobile from 'hooks/useIsMobile';
import Menu from '@mui/material/Menu';
import { ButtonIcon } from 'components/general';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useIntl } from 'react-intl';
import { ClassNameMap, withStyles } from '@mui/styles';
import { Box, Paper } from '@mui/material';

const TableView = React.lazy(() => import('./tableView/TableView'));
const VerticalIssueCards = React.lazy(
  () => import('./VerticalIssueCards')
);

const WorkbookRequestButton = React.lazy(
  () => import('components/reports/workbooks/issue/workbookRequestButton')
);
const WorkbookMenuItem = React.lazy(
  () => import('components/reports/workbooks/issue/workbookMenuItem')
);

type Props = {
  classes: ClassNameMap;
};

const Presentational: React.FC<Props> = ({ classes }) => {
  const pagination = usePagination();
  const isMobile = useIsMobile();

  return (
    <div className={classes.issueTableWrapper}>
      <Paper elevation={1} className={classes.controllersWrapper}>
        <CreateButton />
        <SearchInput />
        <div className={classes.rightPanel}>
          <Pagination {...pagination} />
          <Actions />
        </div>
      </Paper>
      {isMobile ? <VerticalIssueCards /> : <TableView />}
    </div>
  );
};

function Actions(): ReactElement {
  const isMobile = useIsMobile();

  return isMobile ? (
    <ActionsMenu />
  ) : (
    <>
      <ReportPdfButton />
      <WorkbookRequestButton />
      <TableSettings />
      <FiltersToggler />
    </>
  );
}

function ActionsMenu(): ReactElement {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const intl = useIntl();
  const FILTER = intl.formatMessage({
    id: 'documentation_filter_action',
  });
  const GENERATE_REPORT = intl.formatMessage({
    id: 'issue_list_send_report_action',
  });

  return (
    <>
      <ButtonIcon
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon htmlColor='black' />
      </ButtonIcon>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        // TODO MUI5 - chyba mozna usunac
        // getContentAnchorEl={undefined}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <ReportPdfMenuItem
          handleClose={handleClose}
          text={GENERATE_REPORT}
        />
        <WorkbookMenuItem />
        <FiltersTogglerMenuItem handleClose={handleClose} text={FILTER} />
      </Menu>
    </>
  );
}

export default withStyles(styles)(Presentational);
