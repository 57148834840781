import { Box, Theme as DefaultTheme, Divider, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCommonSingleEntityViewStyles } from 'components/common/styles/useCommonSingleEntityViewStyles';
import ViewWrapper from 'components/core/Layout/ViewWrapper';
import { MemoPreviewField } from 'components/general/PreviewField';
import useIsMobile from 'hooks/useIsMobile';
import { DateTime } from 'luxon';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { createTranslatedLabels } from '../../../views/projects/generalData/model';
import { AppLocaleOnView } from 'shared/types/locale';
import { EditOutlined } from '@mui/icons-material';
import { ButtonTextIcon } from 'components/general';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import {
  Permission,
  noPermission,
  noPermissionInOffline,
} from 'helpers/permission';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toUserRole } from 'redux/selectors/role';
import { useIsOffline } from 'components/common/withIsOffline';
import { EDIT_PROJECT_PERMISSIONS } from 'setup/permissions';

type SingleProjectProps = {
  _id: string;
  name: string;
  timezone: string;
  budget?: number;
  currency?: string;
  projectStartDate?: DateTime | null;
  projectEndDate?: DateTime | null;
  locale: AppLocaleOnView;
};

const numberFormat = new Intl.NumberFormat('de-DE', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const useSpecificStyles = makeStyles<DefaultTheme, { isMobile: boolean }>({
  row: {
    display: ({ isMobile }): string => (isMobile ? 'block' : 'flex'),
  },
  field: {
    width: ({ isMobile }): string => (isMobile ? '100%' : '50%'),
    marginTop: '16px',
  },
  my24: {
    marginTop: '24px',
    marginBottom: '24px',
  },
});

export const SingleProject = (
  props: SingleProjectProps
): React.ReactElement => {
  //TODO https://hustro.atlassian.net/browse/PT-3821
  const {
    _id,
    name,
    locale,
    timezone,
    projectStartDate,
    projectEndDate,
    currency,
    budget,
  } = props;

  const isMobile = useIsMobile();
  const classes = useCommonSingleEntityViewStyles({ isMobile });
  const specificClasses = useSpecificStyles({ isMobile });

  const intl = useIntl();

  const {
    NAME_LABEL,
    LANGUAGE_AND_LOCATION,
    PROJECT_LANGUAGE,
    TIMEZONE,
    DEADLINE_AND_BUDGET,
    PROJECT_START_DATE,
    PROJECT_END_DATE,
    PROJECT_CURRENCY,
    PROJECT_VALUE,
  } = useMemo(() => createTranslatedLabels(intl), [intl]);

  // const languageString =
  //   LANGUAGE_LABELS[localeCode] ?? localeCode.toUpperCase();
  const userRole = useSelector(toUserRole);
  const isOffline = useIsOffline();

  const editPermission = useMemo((): Permission => {
    if (!EDIT_PROJECT_PERMISSIONS[userRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [userRole, isOffline]);

  const history = useHistory();

  const edit = useCallback((): void => {
    history.push(`/project/${_id}/edit`);
  }, [_id, history]);
  return (
    <Box sx={{ px: 4, py: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <h1>{name}</h1>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ my: 'auto' }}>
          <MemoForbiddenTooltip
            visibleWhen={!editPermission.permission}
            reason={editPermission.reason}
            inlined
          >
            <ButtonTextIcon
              disabled={!editPermission.permission}
              startIcon={EditOutlined}
              onClick={edit}
            >
              {intl.formatMessage({
                id: 'general_edit',
              })}
            </ButtonTextIcon>
          </MemoForbiddenTooltip>
        </Grid>
      </Grid>

      <Divider sx={{ my: 1 }} />
      <MemoPreviewField
        className={specificClasses.field}
        label={NAME_LABEL}
        value={name}
        dense={true}
      />

      <Divider textAlign='left'>
        <h2>{LANGUAGE_AND_LOCATION}</h2>
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={6} md={4}>
          <MemoPreviewField
            className={specificClasses.field}
            label={PROJECT_LANGUAGE}
            value={locale.label}
            dense={true}
          />
        </Grid>
        <Grid item xs={8} sm={6} md={4}>
          <MemoPreviewField
            className={specificClasses.field}
            label={TIMEZONE}
            value={timezone}
            dense={true}
          />
        </Grid>
      </Grid>
      <Divider textAlign='left'>
        <h2>{DEADLINE_AND_BUDGET}</h2>
      </Divider>
      <Grid container spacing={2}>
        <Grid item xs={8} sm={4} md={3}>
          <MemoPreviewField
            className={specificClasses.field}
            label={PROJECT_START_DATE}
            value={projectStartDate || undefined}
            dense={true}
          />
        </Grid>
        <Grid item xs={8} sm={4} md={3}>
          <MemoPreviewField
            className={specificClasses.field}
            label={PROJECT_END_DATE}
            value={projectEndDate || undefined}
            dense={true}
          />
        </Grid>
        <Grid item xs={8} sm={4} md={3}>
          <MemoPreviewField
            className={specificClasses.field}
            label={PROJECT_CURRENCY}
            value={currency || ''}
            dense={true}
          />
        </Grid>{' '}
        <Grid item xs={8} sm={4} md={3}>
          <MemoPreviewField
            className={specificClasses.field}
            label={PROJECT_VALUE}
            value={budget ? numberFormat.format(budget) : ''}
            dense={true}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
