import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useRef,
} from 'react';
import { useHistory } from 'react-router-dom';

type RedirectContextType = {
  redirect: () => void;
  setRedirection: (redirect: Redirect | undefined) => void;
  isRedirectionSet: () => boolean;
  setCanRedirect: (boolean: boolean) => void;
};
export type Redirect = {
  path: string;
  state: unknown;
};

const RedirectContext = React.createContext<
  RedirectContextType | undefined
>(undefined);

function hasAllDependencies(state: unknown | undefined): boolean {
  return typeof state === 'object'
    ? Object.values(state || {}).every((value) => value !== undefined)
    : true;
}

const WithRedirect: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();
  const redirectionRef = useRef<Redirect | undefined>();
  const redirectCalled = useRef<boolean | undefined>();
  const canRedirectRef = useRef<boolean>(false);

  const redirect = useCallback(() => {
    redirectCalled.current = true;

    if (!canRedirectRef.current) {
      redirectCalled.current = false;
      return;
    }

    if (!redirectionRef.current) {
      return;
    }

    if (
      redirectionRef.current.path &&
      hasAllDependencies(redirectionRef.current.state)
    ) {
      redirectCalled.current = false;
      history.push(
        redirectionRef.current.path,
        redirectionRef.current.state
      );
    }
  }, [history]);

  const setRedirection = useCallback(
    (redirectObject: Redirect | undefined) => {
      redirectionRef.current = redirectObject;
      if (redirectCalled.current === true) {
        redirect();
      }
    },
    [redirect]
  );

  const setCanRedirect = useCallback((canRedirect: boolean) => {
    canRedirectRef.current = canRedirect;
    if (!canRedirectRef.current) {
      redirectCalled.current = false;
    }
  }, []);

  const isSet = useCallback(() => {
    return Boolean(redirectionRef.current);
  }, []);

  const ctx: RedirectContextType = {
    redirect: redirect,
    setRedirection: setRedirection,
    isRedirectionSet: isSet,
    setCanRedirect: setCanRedirect,
  };

  return (
    <RedirectContext.Provider value={ctx}>
      {children}
    </RedirectContext.Provider>
  );
};

function useRedirectContext(): RedirectContextType {
  const context = React.useContext(RedirectContext);
  if (context === undefined) {
    throw new Error(
      'useRedirect must be used within a RedirectContextProvider'
    );
  }
  return context;
}

const withRedirect =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithRedirect>
      <Component {...props} />
    </WithRedirect>
  );

export { WithRedirect, useRedirectContext, withRedirect };
