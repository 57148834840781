const CURRENT_CLIENTS = '[CURRENT_CLIENTS]';

export const FETCH_CURRENT_CLIENTS_SUCCESS = `${CURRENT_CLIENTS} FETCH SUCCESS`;
export const FETCH_CURRENT_CLIENTS_FAILURE = `${CURRENT_CLIENTS} FETCH FAILURE`;

const AUTH = '[AUTH]';

export const AUTH_SUCCESS = `${AUTH} SUCCESS`;
export const AUTH_FAILURE = `${AUTH} FAILURE`;
export const USER_LOGOUT = `${AUTH} LOG OUT`;

const ISSUE = '[SINGLE-ISSUE]';

export const ISSUE_CREATE_SUCCESS = `${ISSUE} CREATION SUCCESS`;
export const ISSUE_CREATE_FILL = `${ISSUE} UPDATE CREATED ISSUE`;

export const ISSUE_DELETE_START = `${ISSUE} DELETE BEGIN`;
export const ISSUE_DELETE_SUCCESS = `${ISSUE} DELETE SUCCESS`;

export const FETCH_ISSUE_FAILURE = `${ISSUE} FETCH FAILURE`;

export const UPDATE_ISSUE_SUCCESS = `${ISSUE} UPDATE SUCCESS`;

export const ISSUE_RESTORE_SUCCESS = `${ISSUE} RESTORE SUCCESS`;
export const ISSUE_RESTORE_FAILURE = `${ISSUE} RESTORE FAILED`;

const ISSUES = '[ISSUES]';

export const FETCH_ISSUES_DELETED = `${ISSUES} FETCH ALL DELETED`;

export const FETCH_ISSUES_BEGIN = `${ISSUES} FETCH BEGIN`;
export const FETCH_ISSUES_SUCCESS = `${ISSUES} FETCH SUCCESS`;
export const FETCH_ISSUES_FINISH = `${ISSUES} FETCH FINISH`;

export const CLEAR_SYNC_DATA = `${ISSUES} CLEAR SYNC DATA`;
export const CLEAR_ISSUE_LIST = `${ISSUES} CLEAR ISSUE LIST`;

const EVENTS = '[EVENTS]';

export const EVENT_CREATE = `${EVENTS} CREATE NEW EVENT`;
export const EVENT_CREATE_SUCCESS = `${EVENTS} CREATE NEW EVENT SUCCESS`;

export const EVENT_DELETE_SUCCESS = `${EVENTS} DELETE SUCCESS`;
export const EVENT_DELETE_FAILURE = `${EVENTS} DELETE EVENT UNSUCCESSFUL`;

export const EVENT_EDIT_SUCCESS = `${EVENTS} EVENT EDIT SUCCESS`;
export const EVENT_EDIT_FAILURE = `${EVENTS} EVENT EDIT FAILURE`;

const DOCUMENT = '[DOCUMENT]';

export const DOCUMENT_CONFIRMATION_UNKNOWN = `${DOCUMENT} ADD DOCUMENT UNCONFIRMED`;
export const DOCUMENT_CONFIRMATION_SUCCESS = `${DOCUMENT} DOCUMENT UPLOAD CONFIRMED`;
export const DOCUMENT_CONFIRMATION_FAILURE = `${DOCUMENT} UPLOAD CONFIRMATION FAILED`;

export const DOCUMENT_UPDATE_SUCCESS = `${DOCUMENT} DOCUMENT UPDATE SUCCESS`;
export const DOCUMENT_UPDATE_FAILURE = `${DOCUMENT} DOCUMENT UPDATE FAILED`;

export const DOCUMENT_DELETE_SUCCESS = `${DOCUMENT} DOCUMENT DELETE SUCCESS`;

const USER = '[USER]';

export const MODIFY_USER_SUCCESS = `${USER} MODIFYING SUCCESS`;
export const MODIFY_USER_FAILURE = `${USER} MODIFYING FAILURE`;

const PROJECT = '[PROJECT]';

export const FETCH_PROJECT_SUCCESS = `${PROJECT} FETCH SUCCESS`;
export const FETCH_PROJECT_FAILURE = `${PROJECT} FETCH FAILURE`;

const PROCESS = '[PROCESS]';

export const FETCH_PROCESS_LIST_SUCCESS = `${PROCESS} LIST FETCH SUCCESS`;
export const FETCH_PROCESS_LIST_FAILURE = `${PROCESS} LIST FETCH FAILURE`;

const UI = '[UI]';

export const SET_ISSUE_VIEW_TYPE = `${UI} SET ISSUE VIEW TYPE`;
export const SEARCH_VALUE_SET = `${UI} SEARCH VALUE SET`;

export const TABLE_SET_VISIBLE_COLUMNS = `${UI} TABLE VIEW SET COLUMNS`;
export const TABLE_SET_SORTING = `${UI} TABLE VIEW SET SORTING`;
export const TABLE_CLEAR_SORTING = `${UI} TABLE VIEW CLEAR SORTING`;

export const SHOW_TOASTER = `${UI} SHOW TOASTER`;
export const HIDE_TOASTER = `${UI} HIDE TOASTER`;

export const TOGGLE_FILTERS_SIDEBAR = `${UI} TOGGLE FILTERS VISIBILITY`;

export const SET_LANGUAGE = `${UI} SET LANGUAGE`;
export const SET_FORM_EDITING = `${UI} SET FORM IN EDIT`;
export const CLEAR_FORMS_EDITING = `${UI} CLEAR FORMS IN EDIT`;

export const LOCK_EVENT_FOR_EDITING = `${UI} LOCK EVENT FOR EDITING`;
export const UNLOCK_EVENT_EDITING = `${UI} UNLOCK EVENT EDITING`;

export const VERSION_MISMATCH_ACTION_TYPE = `${UI} VERSION MISMATCH`;

const ICONS = '[ICONS]';

export const FETCH_ICONS_BEGIN = `${ICONS} FETCH BEGIN`;
export const FETCH_ICONS_SUCCESS = `${ICONS} FETCH SUCCESS`;
export const FETCH_ICONS_FAILURE = `${ICONS} FETCH FAILURE`;
