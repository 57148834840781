import { PromiseExtended } from 'dexie';
import db from './index';
import { clear as visibleFieldsServiceClear } from './visibleFieldsService';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { entityDbClearFactory } from './entityDbClearFactory';
import { broadcastClearVisibleFields } from 'serviceWorker/broadcasts/visibleFields';
import { Changes } from 'shared/types/commonEntities';
import {
  makeDefaultAddBatch,
  makeDefaultAddBatchMapped,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { VisibleFieldEntity } from 'serviceWorker/repository/visibleField/visibleFieldEntity';
import { VisibleFieldInDto } from 'shared/dtos/in/visibleField';
import { visibleFieldInDtoToEntity } from 'serviceWorker/repository/visibleField/mappings';
import { LogLevel } from 'shared/types/logger';

export const clear = entityDbClearFactory(
  db,
  ['visibleFields', 'visibleFieldsService'],
  () => db.visibleFields.clear(),
  visibleFieldsServiceClear,
  broadcastClearVisibleFields
);

export const quantity = makeDefaultCount<VisibleFieldEntity>(
  db,
  db.visibleFields
);

export const get = makeDefaultGetMany<VisibleFieldEntity>(
  db,
  db.visibleFields,
  { deleted: 0 }
);

export const getOne = makeDefaultGetOne<string, VisibleFieldEntity>(
  db,
  db.visibleFields,
  '_id'
);

export const addBatch = makeDefaultAddBatchMapped<
  VisibleFieldInDto,
  VisibleFieldEntity
>(db, db.visibleFields, visibleFieldInDtoToEntity);

export const updateBatch = addBatch;

export const updateLocalBatch = makeDefaultAddBatch<VisibleFieldEntity>(
  db,
  db.visibleFields
);

export const updateOne = wrapQuery(
  db,
  (id: string, changes: Changes<VisibleFieldEntity>): PromiseExtended => {
    return db.visibleFields.update(id, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'visibleFields'),
        errorObj: e,
        additionalInfo: { query: { data: { id, changes } } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<
  string,
  VisibleFieldEntity
>(db, db.visibleFields, '_id');
