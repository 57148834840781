import { HashMap } from 'shared/types/commonView';
import { ISO6391LanguageCodeOption } from 'shared/types/locale';
import ar from './locale/messages.ar_AE.json';
import bg from './locale/messages.bg_BG.json';
import cs from './locale/messages.cs_CZ.json';
import de from './locale/messages.de_DE.json';
import en from './locale/messages.en.json';
import es from './locale/messages.es.json';
import fr from './locale/messages.fr.json';
import he from './locale/messages.he_IL.json';
import hr from './locale/messages.hr_HR.json';
import hu from './locale/messages.hu_HU.json';
import pl from './locale/messages.pl.json';
import pt from './locale/messages.pt_PT.json';
import ro from './locale/messages.ro.json';
import ru from './locale/messages.ru_UA.json';
import sk from './locale/messages.sk_SK.json';
import tr from './locale/messages.tr_TR.json';
import uk from './locale/messages.uk_UA.json';

export type TranslationsObject = HashMap<string>;

export function getUnescapedTranslationObjectByLocale(
  locale: ISO6391LanguageCodeOption
): TranslationsObject {
  switch (locale) {
    case 'en':
      return en;
    case 'ar':
      return ar;
    case 'bg':
      return bg;
    case 'hr':
      return hr;
    case 'cs':
      return cs;
    case 'fr':
      return fr;
    case 'de':
      return de;
    case 'he':
      return he;
    case 'hu':
      return hu;
    case 'pl':
      return pl;
    case 'pt':
      return pt;
    case 'ro':
      return ro;
    case 'ru':
      return ru;
    case 'sk':
      return sk;
    case 'es':
      return es;
    case 'tr':
      return tr;
    case 'uk':
      return uk;
    default:
      return en;
  }
}
