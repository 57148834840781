import { ContractInDto } from 'shared/dtos/in/contract';
import {
  ContractCreateOutDto,
  ContractEditOutDto,
} from 'shared/dtos/out/contract';

export interface IContractUploader {
  POST: (url: string, data: ContractCreateOutDto) => Promise<any>;
  PUT: (url: string, data: ContractEditOutDto) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadContractChanges {
  (
    uploader: IContractUploader,
    body: ContractEditOutDto,
    url: string
  ): Promise<unknown>;
}

export interface UploadContract {
  (
    uploader: IContractUploader,
    body: ContractCreateOutDto,
    url: string
  ): Promise<ContractInDto>;
}

export interface UploadContractDelete {
  (uploader: IContractUploader, url: string): Promise<unknown>;
}

export interface UploadContractRestore {
  (uploader: IContractUploader, url: string): Promise<unknown>;
}

export function uploadContractChanges(
  uploader: IContractUploader,
  body: ContractEditOutDto,
  url: string
): Promise<unknown> {
  return uploader.PUT(url, body);
}

export function uploadContract(
  uploader: IContractUploader,
  body: ContractCreateOutDto,
  url: string
): Promise<ContractInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadContractDelete(
  uploader: IContractUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}

export function uploadContractRestore(
  uploader: IContractUploader,
  url: string
): Promise<unknown> {
  return uploader.GET(url);
}
