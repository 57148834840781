import { projectIdSelector } from 'helpers/misc';
import { useCreateStore, Store } from 'hooks/createStore';
import { useInitialSearchEffect } from 'hooks/search/useInitialSearch';
import { useSearchUpdate } from 'hooks/search/useSearchUpdate';
import {
  ComponentType,
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { EntityLabel } from '../../common/SearchInput/types';

type DocumentationSearchContextType = {
  searchPhraseStore: Store<string>;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
};

const DocumentationSearchContext = createContext<
  DocumentationSearchContextType | undefined
>(undefined);

export function WithDocumentationSearch({
  children,
}: PropsWithChildren<any>): ReactElement {
  const projectId = useSelector(projectIdSelector);

  const searchPhraseStore = useCreateStore('');

  const setSearchPhrase: Dispatch<SetStateAction<string>> = useCallback(
    (cbOrValue) => {
      if (typeof cbOrValue === 'function') {
        searchPhraseStore.set(cbOrValue(searchPhraseStore.get()));
      } else if (typeof cbOrValue === 'string') {
        searchPhraseStore.set(cbOrValue);
      }
    },
    [searchPhraseStore]
  );

  useSearchUpdate(EntityLabel.documentation, setSearchPhrase);
  useInitialSearchEffect(
    projectId,
    EntityLabel.documentation,
    setSearchPhrase
  );

  const ctx: DocumentationSearchContextType = useMemo(() => {
    return {
      searchPhraseStore,
      setSearchPhrase,
    };
  }, [searchPhraseStore, setSearchPhrase]);

  return (
    <DocumentationSearchContext.Provider value={ctx}>
      {children}
    </DocumentationSearchContext.Provider>
  );
}

export function withDocumentationSearch<T>(Component: ComponentType<T>) {
  return ({ ...props }: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithDocumentationSearch>
      <Component {...props} />
    </WithDocumentationSearch>
  );
}

export function useDocumentationSearchContext(): DocumentationSearchContextType {
  const context: DocumentationSearchContextType | undefined = useContext(
    DocumentationSearchContext
  );
  if (context === undefined) {
    throw new Error(
      'useDocumentationSearchContext must be used within a useDocumentationSearchContextProvider'
    );
  }
  return context;
}
