export interface DrawingModel extends DrawingBase {
  data?: string;
  blob?: any;
  imageUrl?: string;
  mergedImageUrl?: string;
  drawingImageUrl?: string;
  thumbnailMergedImageUrl?: string;
  clear: boolean;
}

interface DrawingBase {
  type: Drawings;
}

export enum Drawings {
  fullClear = 'fullClear',
  withClear = 'withClear',
  withoutClear = 'withoutClear',
}
