interface CalculateAmountPercentageUseCase {
  execute(
    targetAmount: number | undefined,
    completedAmount: number | undefined
  ): number | null;
}

class AmountPercentageCalculator
  implements CalculateAmountPercentageUseCase
{
  execute(
    targetAmount: number | undefined | null,
    completedAmount: number | undefined | null
  ): number | null {
    if (!targetAmount) {
      return null;
    }
    if (!completedAmount) {
      return 0;
    }
    return Math.round((completedAmount / targetAmount) * 100);
  }
}

export const amountPercentageCalculator = new AmountPercentageCalculator();
