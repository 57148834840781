export function filterFieldValuesByProcess<
  T extends { processes: string[] },
>(fieldValues: T[], processId: string | undefined): T[] {
  if (!processId) {
    return [];
  }

  return fieldValues.filter((fieldValue) =>
    fieldValue.processes.includes(processId)
  );
}
