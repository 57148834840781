import DateFnsUtilsCore from '@date-io/date-fns';

// because of genitive case in calendar header (e.g. Polish: września 2020 -> wrzesień 2020)
class DateFnsUtils extends DateFnsUtilsCore {
  getCalendarHeaderText(date: Date): string {
    return super.format(date, 'LLLL yyyy');
  }
}

// because default EN-US locale starts a week inconsistently (on Sunday)
const startWeekWithMonday = (locale: Locale): Locale => {
  if (!locale.options) {
    locale.options = {};
  }

  locale.options.weekStartsOn = 1;

  return locale;
};

export { DateFnsUtils, startWeekWithMonday };
