import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useGalleryContext } from 'components/common/gallery';
import { identitySelector } from 'components/common/gallery/selectors';
import { useDocumentationForm } from 'components/dataCreationForms/withDocumentationForm';
import { DocumentationFile } from 'components/dataProviders/withDocumentationController/types';
import { useLevels } from 'components/dataProviders/withLevels';
import { withLevelsToSelect } from 'components/dataProviders/withLevelsToSelect';
import { listenToLevelUpdatedMessage } from 'shared/domain/level/listenToLevelUpdatedMessage';
import { ReactElement, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { DocumentationEdit } from '.';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 16,
      width: '100%',
    },
  },
  dialogContent: {
    padding: 0,
  },
  dialogTitle: {
    padding: '16px',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '18px',
    },
  },
});

function _DocumentationEditDialog(): ReactElement | null {
  const [mainDisplayedFile] =
    useGalleryContext<DocumentationFile>().useMainDisplayedFile(
      identitySelector
    );
  const { isOpen, closeDialog } = useDocumentationForm();
  const classes = useStyles();
  const { resync } = useLevels();

  useEffect(() => {
    const broadcast = listenToLevelUpdatedMessage(
      () => {
        resync();
      },
      () => {},
      () => true
    );

    return () => {
      broadcast.close();
    };
  }, [resync]);

  if (!mainDisplayedFile) {
    return null;
  }

  return (
    <Dialog
      className={classes.root}
      open={isOpen}
      keepMounted={false}
      onClose={closeDialog}
      data-qa='documentation-edit-form-dialog'
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id='edit_documentation' />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <DocumentationEdit file={mainDisplayedFile} close={closeDialog} />
      </DialogContent>
    </Dialog>
  );
}

export const DocumentationEditDialog = withLevelsToSelect(
  _DocumentationEditDialog
);
