import React, {
  SetStateAction,
  useEffect,
  MutableRefObject,
  useRef,
  useState,
} from 'react';
import { HashMap, LabelledEntity } from 'shared/types/commonView';

function isArrayAndEmpty(value: unknown): boolean {
  return Boolean(Array.isArray(value) && value.length === 0);
}

const emptyAvailable: LabelledEntity[] = [];
function extractValue(
  extractor: (
    availableItems: HashMap<LabelledEntity[]>,
    dependencyValue: LabelledEntity
  ) => LabelledEntity[] | undefined,
  availableItems: HashMap<LabelledEntity[]>,
  dependencyValue: LabelledEntity | LabelledEntity[]
): LabelledEntity[] {
  if (!dependencyValue) {
    return emptyAvailable;
  }

  if (Array.isArray(dependencyValue)) {
    const duplicatesMap: HashMap<true | undefined> = {};
    return dependencyValue
      .flatMap((value: LabelledEntity) => {
        return extractor(availableItems, value);
      })
      .filter((value: LabelledEntity | undefined) => {
        if (!value) return;
        if (duplicatesMap[value._id]) {
          return false;
        }
        duplicatesMap[value._id] = true;
        return true;
        // for some reason TS does not get that here we filter undefined values.
      }) as LabelledEntity[];
  }

  return extractor(availableItems, dependencyValue) || [];
}

export function findDependencies(
  dependsOn: string | undefined,
  extractor:
    | ((
        availableItems: HashMap<LabelledEntity[]>,
        dependencyValue: LabelledEntity
      ) => LabelledEntity[])
    | undefined,
  availableItems: LabelledEntity[] | HashMap<LabelledEntity[]> | undefined,
  decidingFieldValue: any
): any {
  if (
    !dependsOn ||
    !extractor ||
    !availableItems ||
    Array.isArray(availableItems)
  ) {
    return;
  }

  const dependantAvailableItems =
    dependsOn &&
    extractValue(extractor, availableItems, decidingFieldValue);

  return dependantAvailableItems;
}

function shouldReset(isMount: boolean, hasValue: any): boolean {
  return Boolean(isMount && hasValue);
}

export function useDependsOn(
  fieldName: string = '__no_way_this_would_ever_be_used_as_property__',
  values: HashMap<any>,
  formKey: string,
  setValues: (key: string, value: any) => void,
  valueRef: MutableRefObject<any>,
  didMountRef: MutableRefObject<boolean>
): void {
  const dependsOnValue = values[fieldName];

  useEffect(() => {
    // prevent validation error on first dependsOn value enter
    if (shouldReset(didMountRef.current, valueRef.current)) {
      setValues(formKey, Array.isArray(valueRef.current) ? [] : undefined);
    }
    // we only want this effect is dependsOnValue changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependsOnValue]);
}

export function useAutoSelect(
  autoSelect: 'asSingleSelect' | 'asMultiSelect' | undefined,
  value: any,
  available: any,
  setValue: React.Dispatch<SetStateAction<any>>
): boolean {
  const didMountRef = useRef<boolean>(false);
  const [autoSelected, setAutoSelected] = useState(!autoSelect);

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  useEffect(() => {
    setAutoSelected(false);
  }, [available]);

  useEffect(() => {
    if (autoSelected) {
      return;
    }

    if (autoSelect === 'asSingleSelect' ? value : value?.length) {
      setAutoSelected(true);
      return;
    }

    const valueEmpty =
      !value || (Array.isArray(value) && value.length === 0);
    const shouldAutoSelect =
      valueEmpty && autoSelect && available?.length === 1;

    if (didMountRef.current && shouldAutoSelect && !autoSelected) {
      setValue(
        autoSelect === 'asSingleSelect' ? available[0] : [available[0]]
      );
      setAutoSelected(true);
    }
  }, [value, available, autoSelect, setValue, autoSelected]);

  return autoSelected;
}
