import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { CompanyCreateModel } from './types/model';

export function startCompanyCreate(
  data: CompanyCreateModel,
  uniqueId: string
): void {
  debugLog('startCompanyCreate', data);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.COMPANIES,
    method: ServiceMethods.CREATE,
    data: { companyCreateModel: data, uniqueId },
  });
  broadcast.close();
}
