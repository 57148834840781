import db from './index';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { makeDefaultClear } from './defaultDaoFactories';

export interface ConfigData {
  _id: number;
  locale: string;
  frontendVersion: string;
  api: string;
  projectId: string;
  timezone: string;
}

export const clear = makeDefaultClear(db, db.config);

export const get = wrapQuery(
  db,
  (): Promise<ConfigData | undefined> =>
    db.config
      .toCollection()
      .last()
      .catch((e) =>
        logWithRethrow({
          msg: getErrorMsg(Operation.get, 'config'),
          errorObj: e,
          additionalInfo: null,
        })
      )
);

export const add = wrapQuery(db, async (data: any): Promise<any> => {
  db.transaction('rw', db.config, async function configAddTransaction() {
    await clear();
    return db.config.put(data).catch((e) =>
      logWithRethrow({
        msg: getErrorMsg(Operation.add, 'config'),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  });
});
