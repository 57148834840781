import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: ({ isMobile }: { isMobile: boolean }): string =>
      isMobile ? 'block' : 'flex',
  },

  tabs: {
    minWidth: '200px',
    width: ({ isMobile }): string => (isMobile ? 'unset' : '411px'),
    flexShrink: 0,
    position: 'relative',
    '& .tab': {
      minHeight: '57px',
      padding: 0,
      opacity: 1,
      maxWidth: 'unset',
      '& .MuiTab-wrapper': {
        alignItems: ({ isMobile }): string =>
          isMobile ? 'center' : 'flex-start',
        textTransform: 'none',
        fontWeight: 700,
        color: '#000',
        fontSize: '1rem',
        lineHeight: '1.2',
        padding: ({ isMobile }): string => (isMobile ? '8px' : '0'),
        [theme.breakpoints.down(400)]: {
          fontSize: '0.875rem',
        },
        marginLeft: ({ isMobile }): string => (isMobile ? '0' : '25px'),
      },
      borderBottom: '1px solid #DADADA',
      '&.Mui-selected': {
        borderRight: '1px solid #FFFFFF',
      },
    },
    '&::before': {
      display: ({ isMobile }): string => (isMobile ? 'none' : 'block'),
      content: '""',
      width: '1px',
      backgroundColor: '#DADADA',
      height: '9999px',
      top: '0px',
      right: '0px',
      position: 'absolute',
    },
  },

  actionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    height: '35px',
    marginTop: '20px',
    boxSizing: 'border-box',
  },

  panelWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: '20px',
  },

  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down(400)]: {
      gridTemplateColumns: '1fr',
    },
    gap: '24px',
    gridAutoFlow: 'dense',
  },
}));
