import React, { Dispatch } from 'react';

import { SearchInput } from 'components/common/SearchInput';
import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../../redux/actionTypes';
import { getSearchPhrase } from 'redux/selectors/issues';
import { genericSearchPhraseValidation } from 'shared/utils/search';
import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
import useIsMobile from 'hooks/useIsMobile';
import { useSearch } from '../../../hooks/search/useSearch';

const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>({
  searchInput: {
    marginLeft: ({ isMobile }): string => (isMobile ? '8px' : '16px'),
  },
});
function debounceSearchPhrase(
  dispatch: Dispatch<unknown>,
  previousSearchPhrase: string,
  currentSearchPhrase: string
): void {
  if (previousSearchPhrase === currentSearchPhrase) {
    return;
  }
  dispatch({
    type: actions.SEARCH_VALUE_SET,
    payload: currentSearchPhrase,
  });
}

const IssueSearchInput = (): React.ReactElement => {
  const searchPhraseInState = useSelector(getSearchPhrase);
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const searchPhraseChange = debounceSearchPhrase.bind(
    null,
    dispatch,
    searchPhraseInState
  );

  const { searchChange, searchClear, searchPhrase } = useSearch(
    searchPhraseChange,
    searchPhraseInState,
    genericSearchPhraseValidation
  );

  return (
    <SearchInput
      className={classes.searchInput}
      value={searchPhrase}
      searchChange={searchChange}
      searchClear={searchClear}
      dataQa='toolbar-search-input'
      placeholderTextId='dashboard_search_placeholder'
    />
  );
};

export default IssueSearchInput;
