import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import {
  broadcastUploadedVisibleFieldError,
  broadcastUploadedVisibleField,
} from 'serviceWorker/broadcasts/visibleFields';

export function broadcastVisibleFieldResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastUploadedVisibleFieldError(response.error);
  }

  return broadcastUploadedVisibleField(response, request.data.uniqueId);
}
