import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { IssueListReportModel } from './reportModel';
import { SingleIssueReportModel } from './singleIssueReportModel';
import { SingleInspectionReportModel } from './singleInspectionReportModel';

export function startReportCreate(
  data:
    | IssueListReportModel
    | SingleIssueReportModel
    | SingleInspectionReportModel,
  uniqueId: string
): void {
  debugLog('startReportCreate', data);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.REPORTS,
    method: ServiceMethods.CREATE,
    data: { ...data, uniqueId },
  });
  broadcast.close();
}
