import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { OnMessage } from './types';
import { BroadcastChannel } from 'broadcast-channel';
import * as dbIssueForm from 'serviceWorker/db/issueForm';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.issueFormChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllIssueForm: {
        await getAllIssueFormData(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllIssueFormData(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const items = await dbIssueForm.get();
  const end = Date.now();
  debugLog('getAllIssueForms time:', end - start);
  broadcast.postMessage({
    data: { items },
    type: DomainMessagesTypes.allIssueForm,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.ISSUE_FORMS,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
