import { makeBroadcastClear } from 'serviceWorker/broadcasts/factories';
import { ChannelNames } from 'shared/domain/channelNames';
import db from '.';
import {
  makeDefaultAddBatch,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { entityDbClearFactory } from './entityDbClearFactory';
import { clear as issueFormServiceClear } from './issueFormService';
import { IssueFormEntity } from 'serviceWorker/repository/issueForm/issueForm';

export const clear = entityDbClearFactory(
  db,
  ['issueForm', 'issueFormService'],
  () => db.issueForm.clear(),
  issueFormServiceClear,
  makeBroadcastClear(ChannelNames.issueFormChannel)
);

export const quantity = makeDefaultCount<IssueFormEntity>(
  db,
  db.issueForm
);

export const get = makeDefaultGetMany<IssueFormEntity>(db, db.issueForm);

export const addBatch = makeDefaultAddBatch<IssueFormEntity>(
  db,
  db.issueForm
);

export const updateBatch = addBatch;

export const removeBatch = makeDefaultRemoveBatch<string, IssueFormEntity>(
  db,
  db.issueForm,
  'name'
);
