import {
  GetDocumentsCustomEventDetail,
  GetDocumentsByIdsCustomEventDetail,
  GetDocuments,
  GetDocumentsByQueryCustomEventDetail,
} from 'shared/domain/messages/document/eventMessages';

export function isGetByIds(
  detail: GetDocumentsCustomEventDetail
): detail is GetDocumentsByIdsCustomEventDetail {
  return detail.type === GetDocuments.ids;
}

export function isGetByQuery(
  detail: GetDocumentsCustomEventDetail
): detail is GetDocumentsByQueryCustomEventDetail {
  return detail.type === GetDocuments.query;
}
