import { Color } from 'shared/utils/colors';

export const hexToRGB = (hex: string | Color, alpha: number): string => {
  const r = parseInt((hex as string).slice(1, 3), 16);
  const g = parseInt((hex as string).slice(3, 5), 16);
  const b = parseInt((hex as string).slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgba(${r}, ${g}, ${b})`;
  }
};
