import { useState, useEffect } from 'react';
import { useDirectories } from 'components/dataProviders/withDirectories';
import { useCurrentDirectoryContext } from 'components/dataProviders/withCurrentDirectory';
import { useHistory, useParams } from 'react-router-dom';
import { projectIdSelector } from 'helpers/misc';
import { useSelector } from 'react-redux';

export function useIsLoadingDocumentation(): boolean {
  const { isLoadingDirectoriesStore } = useDirectories();

  const [isLoadingDirectories, setIsLoadingDirectories] = useState(true);
  useEffect(() => {
    setIsLoadingDirectories(isLoadingDirectoriesStore.get());
    return isLoadingDirectoriesStore.subscribe(() => {
      setIsLoadingDirectories(isLoadingDirectoriesStore.get());
    });
  }, [isLoadingDirectoriesStore]);

  return isLoadingDirectories;
}

export function useDocumenationUrlControl(): void {
  const projectId = useSelector(projectIdSelector);
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const { folderId } = useParams<{ folderId: string }>();
  const history = useHistory();

  useEffect(() => {
    const currentDirectory = currentDirectoryStore.get();
    if (folderId && folderId !== currentDirectory) {
      currentDirectoryStore.set(folderId);
    }
  }, [folderId, currentDirectoryStore]);

  useEffect(() => {
    return currentDirectoryStore.subscribe(() => {
      const currentDirectory = currentDirectoryStore.get();
      if (!currentDirectory) {
        window.history.replaceState(
          '',
          '',
          `/project/${projectId}/documentation`
        );
        return;
      }
      if (history.location.pathname.includes('file')) {
        // we dont push dir when file was previously in pathname
        // to properly handle backtrack button and not overwrite history
        // when documentation was open with fileId in url
        return;
      }
      history.push(
        `/project/${projectId}/documentation/dir/${currentDirectory}`
      );
    });
  }, [currentDirectoryStore, history, projectId]);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname.endsWith('/documentation')) {
        currentDirectoryStore.set('');
      }
    });
  }, [history, currentDirectoryStore]);
}
