import React, { MouseEventHandler } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../setup/types/core';
import { makeStyles } from '@mui/styles';
import { History } from 'history';
import cn from 'classnames';
import ForbiddenTooltip from '../../general/ForbiddenTooltip/ForbiddenTooltip';
import { useIsOffline } from '../../common/withIsOffline';

const useStyles = makeStyles({
  wrapper: {
    position: 'absolute' as 'absolute',
    bottom: 65,
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: 'rgba(0,0,0,.54)',
    '&:hover:not(.disabled)': {
      color: 'rgba(0,0,0,.92)',
    },
    '&.disabled': {
      pointerEvents: 'none',
    },
  },
});

const getBaseUrl = (history: History<unknown>): string => {
  return history.location.pathname.split('/').slice(0, 7).join('/');
};

const GDPRClauseLink = (): JSX.Element => {
  const project = useSelector((state: StoreState) => state.projectData);
  const classes = useStyles();
  const history = useHistory();
  const isOffline = useIsOffline();

  const handleRouteChange: MouseEventHandler<HTMLAnchorElement> = (
    ev
  ): void => {
    if (getBaseUrl(history) === `/project/${project._id}/gdpr/clause`) {
      ev.preventDefault();
    }
  };

  return (
    <div className={classes.wrapper}>
      <ForbiddenTooltip
        visibleWhen={isOffline}
        reason='general_offline_operation_unavailable'
      >
        <Link
          to={`/project/${project._id}/gdpr/clause`}
          data-qa='gdpr_clause_link'
          className={cn(classes.link, { disabled: isOffline })}
          onClick={handleRouteChange}
        >
          <FormattedMessage id='gdpr_clause_link_text' />
        </Link>
      </ForbiddenTooltip>
    </div>
  );
};

export default GDPRClauseLink;
