import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { LabelledEntity } from 'shared/types/commonView';
import { UserRole } from 'shared/types/userRole';
import { objHasKey } from 'shared/utils/object';
import { StoreState } from '../setup/types/core';
import { debugLog } from 'shared/logger/debugLog';

export type WithUserRoleObject = { role: UserRole };

export const arrayFilterOutDuplicates = <T>(
  array: T[],
  prop?: keyof T
): T[] => {
  return array.filter(
    (elem, index, self) =>
      self.findIndex((t) => {
        if (prop && t) {
          return t[prop] === elem[prop];
        }

        return t === elem;
      }) === index
  );
};

export const useDebounce = function <T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return (): void => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export function useDebouncedCallback(
  timeoutRef: MutableRefObject<number | undefined>,
  action: CallableFunction,
  debounceTime: number
): (...args: any) => void {
  const mountedRef = useRef(false);
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);
  return useCallback(
    (...args: any) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        if (!mountedRef.current) {
          return;
        }
        action(...args);
      }, debounceTime);
    },
    [action, debounceTime, timeoutRef]
  );
}

function ignoreHashtagSign(string: string): string {
  return string.startsWith('#') ? string.slice(1) : string;
}

export const sanitizeInput = (string: string): string => {
  return ignoreHashtagSign(
    string.replace(/[-[\]{}()*+?.,\\^$\s]/g, '\\$&')
  );
};

export const capitalizeFirst = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const isEmpty = (obj: any): boolean => {
  for (let key in obj) {
    if (objHasKey(obj, key)) return false;
  }

  return true;
};

export const maxElementInArray = (array: number[]): number | undefined => {
  if (array.length === 0) {
    return undefined;
  }

  return array.reduce((a, b) => Math.max(a, b));
};

export const convertBlobToBase64 = async (data: Blob): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);

    reader.onerror = (): void => {
      rej();
    };

    reader.onloadend = (): void => {
      const base64data = reader.result as string;
      res(base64data);
    };
  });
};

export const convertWebpBlobToBase64Png = async (
  data: Blob
): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);

    reader.onerror = (): void => {
      rej();
    };

    reader.onloadend = (): void => {
      if (!reader.result) {
        return rej();
      }
      const img = new Image();
      img.src = reader.result as string;

      img.onload = function () {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        res(canvas.toDataURL('image/png'));
      };
    };
  });
};

export const isInRange = (
  value: number,
  bottom: number,
  top: number
): boolean => value <= top && value >= bottom;

export const isNullish = (
  value: unknown
): value is '' | null | undefined => {
  return value === '' || value === null || value === undefined;
};

export const projectIdSelector = (state: StoreState): string =>
  state.projectData._id;

export function toLabelledEntities<T extends LabelledEntity>(
  entities: T[]
): LabelledEntity[] {
  return entities.map(({ _id, label }) => ({ _id, label }));
}
export function toLabelledEntity<T extends LabelledEntity>(
  entity: T
): LabelledEntity {
  try {
    return {
      _id: entity._id,
      label: entity.label,
    };
  } catch (e) {
    debugLog(`id: ${entity._id}, label: ${entity.label}, e`);
    throw e;
  }
}

export function toUnsafeLabelledEntity<T extends LabelledEntity>(
  entity: T | undefined
): LabelledEntity | undefined {
  if (!entity) return undefined;
  return {
    _id: entity._id,
    label: entity.label,
  };
}
