import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  ApexAxisChartSeries,
  DateRangeObject,
  HsEffectFilters,
} from 'shared/types/analytics';
import { HashMap } from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByImpact,
  filterByProcess,
  filterBySite,
  filterBySubcontractor,
  filterByWorktype,
} from '../seriesCreation';

const combinedDangerousConditionsActionsId =
  'hs_effect_chart_dangerous_conditions_actions';

const sortedEffectIds: string[] = [
  '5f8eb44901c3328428b6623c', //fatal accident
  '5f8eb44f34a63e6c455937c9', //severe injury
  '5f8eb456b5441742fb4be051', //light injury
  '5f8eb46a83e578eb8f2bc2e6', //medical event
  '5f8eb5c54de26f3e02c41971', //near miss
  '5f8eb45df67db5b0e913af3d', //dangerous conditions
  '5f8eb463f6a6164a9c42fb77', //dangerous actions
];

const sortedAndCombinedEffectIds = sortedEffectIds
  .slice(0, -2)
  .concat(combinedDangerousConditionsActionsId);

const EMPTY: [ApexAxisChartSeries, []] = [[], []];

export function transformArrayToRecordOfZeros(
  array: string[]
): HashMap<0> {
  return array.reduce<HashMap<0>>((record, value) => {
    record[value] = 0;
    return record;
  }, {});
}

export function createSeriesFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: HsEffectFilters,
  timezone: string,
  additionalParams: { visibleProcesses: string[] }
): Promise<[ApexAxisChartSeries, []]> {
  if (!additionalParams.visibleProcesses) {
    throw new Error('Missing visibleProcesses param');
  }
  const [dangerousConditionsId, dangerousActionsId] =
    sortedEffectIds.slice(-2);

  const effectsCount: Record<string, number> =
    transformArrayToRecordOfZeros(sortedAndCombinedEffectIds);

  const [
    dateRangeFilter,
    originatorsFilter,
    siteFilter,
    workTypeFilter,
    coordinatorFilter,
    impactFilter,
    processesFilter,
  ] = filters.dataScope.filters;

  const filteredIssues = issues.filter((issue) => {
    if (!additionalParams.visibleProcesses.includes(issue.process)) {
      return false;
    }

    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedOriginators = filterBySubcontractor(
      originatorsFilter.value as string[],
      issue
    );
    if (!passedOriginators) {
      return false;
    }

    const passedSite = filterBySite(siteFilter.value as string[], issue);
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterByWorktype(
      workTypeFilter.value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      coordinatorFilter.value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    const passedImpact = filterByImpact(
      impactFilter.value as string[],
      issue
    );
    if (!passedImpact) {
      return false;
    }

    const passedProcesses = filterByProcess(
      processesFilter.value as string[],
      issue
    );
    if (!passedProcesses) {
      return false;
    }

    return true;
  });

  if (filteredIssues.length === 0) {
    return Promise.resolve(EMPTY);
  }

  filteredIssues.forEach((issue) => {
    const { effect } = issue.extendedData;

    if (effect) {
      if ([dangerousConditionsId, dangerousActionsId].includes(effect)) {
        effectsCount[combinedDangerousConditionsActionsId] += 1;
      } else {
        effectsCount[effect] += 1;
      }
    }
  });

  return Promise.resolve([
    [
      {
        name: 'workbook_label_issues',
        data: sortedAndCombinedEffectIds.map((id) => effectsCount[id]),
      },
    ],
    [],
  ]);
}
