import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { makeSimpleBroadcastError } from '../factories';

export const broadcastUploadedContractError = (error: any): void => {
  debugLog('broadcastUploadedContractError', error);
  broadcastContractError(DomainMessagesTypes.failUploadedEntity, error);
};

export function broadcastCreatedContractError(error: any): void {
  debugLog('broadcastCreatedContractError', error);
  broadcastContractError(DomainMessagesTypes.createdEntity, error);
}
const broadcastContractError = makeSimpleBroadcastError(
  ChannelNames.contractChannel
);
