import { PromiseExtended } from 'dexie';
import db from './index';
import { clear as levelsServiceClear } from './levelsService';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { entityDbClearFactory } from './entityDbClearFactory';
import { LevelEntity } from 'shared/domain/level/types/entity';
import { broadcastClearLevels } from 'serviceWorker/broadcasts/levels';
import { LevelInDto } from 'shared/dtos/in/level';
import { levelInDtoToEntity } from 'serviceWorker/repository/level/mappings';
import { Changes } from 'shared/types/commonEntities';
import {
  makeDefaultAddBatch,
  makeDefaultAddBatchMapped,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

export const clear = entityDbClearFactory(
  db,
  ['levels', 'levelsService'],
  () => db.levels.clear(),
  levelsServiceClear,
  broadcastClearLevels
);

export const quantity = makeDefaultCount<LevelEntity>(db, db.levels);

export const get = makeDefaultGetMany<LevelEntity>(db, db.levels);

export const getOne = makeDefaultGetOne<string, LevelEntity>(
  db,
  db.levels,
  '_id'
);

export const addBatch = makeDefaultAddBatchMapped<LevelInDto, LevelEntity>(
  db,
  db.levels,
  levelInDtoToEntity
);

export const updateBatch = addBatch;

export const updateLocalBatch = makeDefaultAddBatch<LevelEntity>(
  db,
  db.levels
);

export const updateOne = wrapQuery(
  db,
  (levelId: string, changes: Changes<LevelEntity>): PromiseExtended => {
    return db.levels.update(levelId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'levels'),
        errorObj: e,
        additionalInfo: { query: { data: { levelId, changes } } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<string, LevelEntity>(
  db,
  db.levels,
  '_id'
);
