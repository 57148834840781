import { CircularProgress } from '@mui/material';
import { ReactElement, useEffect, useRef, useState } from 'react';

import {
  Error as FailedIcon,
  CheckCircle as SuccessIcon,
  Close as CloseIcon,
  ExpandMore,
  ExpandLess,
} from '@mui/icons-material';
import { useMovableElement } from './model';
import { useStyles } from './styles';
import { createPortal } from 'react-dom';
import {
  UploadProgresStatus,
  Progress,
} from 'components/common/withUploadProgressStore';
import { useUploadProgress } from './UploadProgressContext';
import { ButtonIcon } from 'components/general';

export function UploadProgress(): ReactElement {
  const classes = useStyles();
  const canSetVisibleRef = useRef(true);
  const { filesStore, clear } = useUploadProgress();
  const [isVisible, setIsVisible] = useState(
    Boolean(filesStore.get().length)
  );
  const [isOpen, setOpen] = useState(true);
  const [files, setFiles] = useState(filesStore.get());

  const {
    position,
    mouseDownHandler,
    touchStartHandler,
    moveEnd,
    mouseMoveHandler,
    touchMoveHandler,
    reset,
  } = useMovableElement();

  useEffect(() => {
    return filesStore.subscribe(() => {
      setFiles(filesStore.get());
      const visible = Boolean(filesStore.get().length);
      if (canSetVisibleRef.current && visible) {
        setIsVisible((prev) => {
          if (visible !== prev) {
            canSetVisibleRef.current = false;
          }

          if (visible) {
            setOpen(true);
          }
          return visible;
        });
      }
    });
  }, [filesStore]);

  // react 18 types
  // @ts-ignore
  return createPortal(
    <div
      className={classes.root}
      style={{
        display: isVisible ? 'inline-flex' : 'none',
        bottom: position ? 'unset' : '60px',
        right: position ? 'unset' : '60px',
        left: position ? `${position.x}px` : 'unset',
        top: position ? `${position.y}px` : 'unset',
      }}
    >
      <h3 className={classes.header}>
        <div className={classes.actionsContainer}>
          <div
            onMouseDown={mouseDownHandler}
            onMouseMove={mouseMoveHandler}
            onMouseUp={moveEnd}
            onMouseOut={moveEnd}
            onTouchMove={touchMoveHandler}
            onTouchStart={touchStartHandler}
            onTouchEnd={moveEnd}
            className={classes.dragger}
          ></div>
          <ButtonIcon onClick={() => setOpen((prev) => !prev)}>
            {isOpen ? (
              <ExpandMore htmlColor='#EEEEEE' />
            ) : (
              <ExpandLess htmlColor='#EEEEEE' />
            )}
          </ButtonIcon>
          <ButtonIcon
            onClick={() =>
              clear().then(() => {
                canSetVisibleRef.current = true;
                setIsVisible(false);
                reset();
              })
            }
          >
            <CloseIcon htmlColor='#EEEEEE' />
          </ButtonIcon>
        </div>
      </h3>
      <div
        className={classes.listContainer}
        style={{ height: isOpen ? 'min(40vh, 260px)' : 0 }}
      >
        {files.map((file) => {
          return (
            <div
              key={`${file.uniqueFileId}-${file.status}`}
              className={classes.listElement}
            >
              <span>{`${file.title}`}</span>
              <Icon status={file.status} />
            </div>
          );
        })}
      </div>
    </div>,
    document.body
  );
}

function Icon({ status }: { status: UploadProgresStatus }): ReactElement {
  switch (status) {
    case Progress.FAILED:
      return <FailedIcon htmlColor='red' />;
    case Progress.PENDING:
      return <CircularProgress size={24} />;
    case Progress.UPLOADED:
      return <SuccessIcon htmlColor='green' />;
  }
}
