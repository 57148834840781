import { ReactElement } from 'react';

import { MemoComplianceCheckResultSelector } from '../ComplianceCheckResultSelector';
import { ComplianceCheckForm } from '../ComplianceCheckForm';
import { useStyles } from './styles';

import { MemoStatefulFormInputComponent } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper/stateful';
import { useIntl } from 'react-intl';
import { ComplianceCheckProps } from './types';

export const COMPLIANCE_CHECK_RESULT_PREFIX =
  'compliance-check-form-result';
export const COMPLIANCE_CHECK_FORM_PREFIX = 'compliance-check-form';
export const COMPLIANCE_CHECK_FORM_COMMENT =
  'compliance-check-form-comment';

export function ComplianceCheck({
  checklistItemId,
}: ComplianceCheckProps): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h4>
        {intl.formatMessage({
          id: 'protocol_item_form_compatibility_message',
        })}
      </h4>

      <MemoStatefulFormInputComponent
        Component={MemoComplianceCheckResultSelector}
        formKey={`${COMPLIANCE_CHECK_RESULT_PREFIX}-${checklistItemId}`}
        checklistItemId={checklistItemId}
        labelId={''}
        fieldName={`compliance-result-selector-${checklistItemId}`}
      />

      <ComplianceCheckForm checklistItemId={checklistItemId} />
    </div>
  );
}
