export interface IDocumentationMoveUploader {
  PUT: (
    url: string,
    data: { parentId: string | null }
  ) => Promise<unknown>;
}

export interface UploadDocumentationMove {
  (
    uploader: IDocumentationMoveUploader,
    url: string,
    parentId: string
  ): Promise<boolean>;
}

export const uploadDocumentationMove: UploadDocumentationMove =
  async function _uploadDocumentationMove(
    uploader: IDocumentationMoveUploader,
    url: string,
    parentId: string
  ): Promise<boolean> {
    try {
      await uploader.PUT(url, { parentId: parentId ? parentId : null });
      return true;
    } catch (e) {
      return false;
    }
  };
