import { Fragment, ReactElement } from 'react';
import {
  MentionConstructorModel,
  TextDescriptionModel,
} from 'shared/domain/issue/issueModel';
import Chip from '@mui/material/Chip';
import { LabelledEntity } from 'shared/types/commonView';
import { EventDescriptionType } from 'shared/types/eventDescription';

export interface EventDescriptionPartOnView {
  readonly type: EventDescriptionType;
  toMarkupText: () => string;
  toPreview: (index: number, classes?: any) => ReactElement;
  toLabelText: () => string;
}

export class TextDescription implements EventDescriptionPartOnView {
  readonly type: EventDescriptionType;
  constructor(private description: TextDescriptionModel) {
    this.type = description.type;
  }

  toMarkupText(): string {
    return this.description.text;
  }

  toPreview(index: number): ReactElement {
    return <Fragment key={index}>{this.description.text}</Fragment>;
  }

  toLabelText(): string {
    return this.description.text;
  }
}

export class Mention implements EventDescriptionPartOnView {
  readonly type: EventDescriptionType;
  readonly user: LabelledEntity & { email: string };
  constructor(mention: MentionConstructorModel) {
    this.type = mention.type;
    this.user = mention.user;
  }

  toMarkupText(): string {
    return `@[${this.user.label ?? this.user.email}](otherUser:${
      this.user._id
    })`;
  }

  toPreview(index: number, classes: any): ReactElement {
    return (
      <Chip
        size='small'
        className={classes.mentionChip}
        key={index}
        label={this.user.label}
      />
    );
  }

  toLabelText(): string {
    return `@${this.user.label}`;
  }
}

export class SelfMention implements EventDescriptionPartOnView {
  readonly type: EventDescriptionType;
  readonly user: LabelledEntity & { email: string };
  constructor(mention: MentionConstructorModel) {
    this.type = mention.type;
    this.user = mention.user;
  }

  toMarkupText(): string {
    return `@[${this.user.label ?? this.user.email}](currentUser:${
      this.user._id
    })`;
  }

  toPreview(index: number, classes: any): ReactElement {
    return (
      <Chip
        size='small'
        className={classes.mentionChip}
        key={index}
        label={this.user.label}
        color='primary'
      />
    );
  }

  toLabelText(): string {
    return `@${this.user.label}`;
  }
}
