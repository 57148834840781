import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { failUpload } from 'serviceWorker/repository/document/uploadConfirm';
import {
  PostFile,
  uploadFile,
} from 'serviceWorker/repository/file/postFile';
import { isAbortError } from 'serviceWorker/repository/httpRequest/abortRequest';
import {
  addRemoteIdToDocumentation,
  AddRemoteIdToDocumentation,
} from './addRemoteIdToDocumentation';
import { hardRemoveDocumentation } from './hardRemoveDocumentation';
import {
  setDocumentationSync,
  SetDocumentationSync,
} from './setDocumentationSync';
import { setVersionSync, SetVersionSync } from './setVersionSync';
import { UploadStatus } from 'shared/types/uploadStatus';
import { setUploadStatus } from './setUploadStatus';
import {
  broadcastFailUploadedDocumentation,
  broadcastUploadedDocumentation,
} from 'serviceWorker/broadcasts/documentations';

export interface IDocumentationUploader {
  POST: (
    url: string,
    data: any,
    abortController?: AbortController
  ) => Promise<any>;
  GET: (url: string) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  POST_FILE: PostFile;
  PUT: (url: string, data: any) => Promise<any>;
}

export interface UploadDocumentation {
  (
    abortController: AbortController,
    uploader: IDocumentationUploader,
    documentation: DocumentationModel,
    url: string
  ): Promise<boolean>;
}

// export for testing purposes
export function makeUploadDocumentation(
  addRemoteIdToDocumentation: AddRemoteIdToDocumentation,
  setDocumentationSync: SetDocumentationSync,
  setVersionSync: SetVersionSync
): UploadDocumentation {
  return async function _uploadDocumentation(
    abortController: AbortController,
    uploader: IDocumentationUploader,
    documentation: DocumentationModel,
    url: string
  ): Promise<boolean> {
    let createdDocumentationId;
    try {
      const createdDocumentation = await createDocumentation(
        abortController,
        uploader,
        documentation,
        url
      );
      createdDocumentationId = createdDocumentation._id;

      await addRemoteIdToDocumentation(
        documentation.localId,
        createdDocumentationId
      );
      await setDocumentationSync(
        documentation.localId,
        SyncStatus.SUCCESS
      );
    } catch (e) {
      await setDocumentationSync(documentation.localId, SyncStatus.FAIL);
      if (isAbortError(e)) {
        await hardRemoveDocumentation(documentation.localId);
      }

      throw e;
    }

    const version = documentation.versions[0];
    if (!version.localData) {
      throw new Error('No documentation data to upload.');
    }
    const imageData: Response = await fetch(version.localData).catch(
      async () => {
        await hardRemoveDocumentation(documentation.localId);
        throw new Error('Cannot access documentation data to upload.');
      }
    );
    const imageBlob: Blob = await imageData.blob();
    const fileName = documentation.name;
    const file = new File([imageBlob], fileName, {
      type: version.type,
    });

    const signedData = await getSignedUrlForUpload(
      uploader,
      `${url}/${createdDocumentationId}/upload`,
      version
    );
    const versionId = signedData.dto.versions[0]._id;

    try {
      const result = await uploadFile(
        abortController,
        uploader.POST_FILE,
        signedData.presignedPost.fields,
        signedData.presignedPost.url,
        file
      ).then((response) => {
        if (response.ok) {
          return {
            remoteVersionId: response.headers.get('x-amz-version-id'),
          };
        }
        throw new Error('Post file failed.');
      });

      await confirmUpload(
        uploader,
        `${url}/${createdDocumentationId}/version/${versionId}`,
        result.remoteVersionId
      );
      await setUploadStatus(documentation.localId, UploadStatus.success);
      await setVersionSync(documentation.localId, SyncStatus.SUCCESS);
      broadcastUploadedDocumentation({
        localId: documentation.localId,
        _id: createdDocumentationId,
      });
      return true;
    } catch (e) {
      await setVersionSync(documentation.localId, SyncStatus.FAIL);
      await failUpload(
        uploader,
        `${url}/${createdDocumentationId}/version/${versionId}`
      );
      if (isAbortError(e)) {
        await hardRemoveDocumentation(documentation.localId);
        return false;
      }
      broadcastFailUploadedDocumentation(documentation.localId);
    }
    return false;
  };
}

export const uploadDocumentation: UploadDocumentation =
  makeUploadDocumentation(
    addRemoteIdToDocumentation,
    setDocumentationSync,
    setVersionSync
  );

function getSignedUrlForUpload(
  uploader: IDocumentationUploader,
  url: string,
  file: { extension: string }
): Promise<any> {
  const body = {
    extension: file.extension,
  };

  return uploader.PUT(url, body);
}

function createDocumentation(
  abortController: AbortController,
  uploader: IDocumentationUploader,
  documentation: DocumentationModel,
  url: string
): Promise<any> {
  const parentIdObj = documentation.parentId
    ? { parentId: documentation.parentId }
    : {};

  return uploader.POST(
    url,
    {
      name: documentation.name,
      number: documentation.number,
      ...parentIdObj,
    },
    abortController
  );
}

function confirmUpload(
  uploader: IDocumentationUploader,
  url: string,
  remoteVersionId: string
): Promise<any> {
  return uploader.PUT(`${url}/uploadConfirmation`, { remoteVersionId });
}
