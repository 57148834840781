import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  isImage,
  isPdf,
  isVideo,
} from 'components/common/graphicUploader/helpers';
import { DrawingType } from 'components/common/graphicUploader/types';
import { useBlobFileStorage } from 'components/core/BlobFileStorage';
import { GetAppOutlined } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as CouldntPreviewIcon } from 'assets/icons/file.svg';
import { ButtonContained } from 'components/general';
import downloadFile from 'helpers/fileDownload';
import { Spinner } from 'components/core';
import { useGalleryContext } from '.';
import { identitySelector } from './selectors';
import { ImageConvertStatus } from 'shared/domain/imageConvertStatus/imageConvertStatus';
import { createUniqueId } from 'shared/utils/id/id';
import { useDispatch } from 'react-redux';
import { genericErrorToaster } from 'redux/actions/toasterActions';
import { ImageDisplay } from './imageDisplay';

type FileDisplayProps = {
  drawing: DrawingType;
};
const FileDisplay: React.FC<FileDisplayProps> = (props) => {
  const [file] =
    useGalleryContext().useMainDisplayedFile(identitySelector);
  const ImageDisplayOverlay = useGalleryContext().ImageDisplayOverlay;
  const blobFileStorage = useBlobFileStorage();
  const { drawing } = props;
  const dispatch = useDispatch();

  const storedBlobUrl =
    (drawing?.mergedImageUrl &&
      blobFileStorage.getItem(drawing?.mergedImageUrl)) ||
    (file?._id && blobFileStorage.getItem(file?._id));

  const handleDownloadFileClick = useCallback(() => {
    downloadFile(
      storedBlobUrl || file?.downloadSrc,
      file?.title,
      file?.data?.extension
    ).catch(() => dispatch(genericErrorToaster));
  }, [file, storedBlobUrl, dispatch]);

  const key = useMemo(() => {
    return createUniqueId();
    // we want key to change when 'file' changes even thou we don't use it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const { rightPanelControls } = useGalleryContext();
  const [rightPanelVisible, setRightPanelVisible] = useState(
    rightPanelControls?.getVisibleStore().get()
  );
  useEffect(() => {
    if (!rightPanelControls) return;
    const action = (): void =>
      setRightPanelVisible(rightPanelControls.getVisibleStore().get());
    action();
    return rightPanelControls.getVisibleStore().subscribe(action);
  }, [rightPanelControls]);

  if (!file) {
    return null;
  }

  if (file.loading) {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: '100%',
        }}
      >
        <Spinner reason='FileDisplay file.loading' />
      </div>
    );
  }

  if (isVideo(file)) {
    return (
      <div className='file-preview'>
        <video
          //to rerender tag on file change https://stackoverflow.com/questions/57235013/html-video-tag-not-updating-source-on-re-render
          key={key}
          // s3 + chrome - big issue around Origin and CORS - just use 'use-credentials' for every request to s3 PT-2785
          crossOrigin='use-credentials'
          controls
        >
          <source src={file.downloadSrc} type={file.type} />
        </video>
      </div>
    );
  }

  if (isImage(file)) {
    const key = `${
      storedBlobUrl || drawing?.mergedImageUrl || file.downloadSrc
    },${rightPanelVisible}`;
    return (
      <ImageDisplay
        key={key}
        source={
          storedBlobUrl || drawing?.mergedImageUrl || file.downloadSrc
        }
        alt={file.description || file.title}
        ImageDisplayOverlay={ImageDisplayOverlay}
      />
    );
  }

  if (
    isPdf(file) &&
    file.imageConvertStatus === ImageConvertStatus.SUCCESS &&
    file.signedRequest?.imageSignedRequest
  ) {
    return (
      <ImageDisplay
        key={`${file.signedRequest?.imageSignedRequest},${rightPanelVisible}`}
        source={file.signedRequest?.imageSignedRequest}
        alt={file.description || file.title}
        ImageDisplayOverlay={ImageDisplayOverlay}
      />
    );
  }

  if (isPdf(file)) {
    return (
      <div className='file-preview'>
        <object
          width='100%'
          height='100%'
          data={file.downloadSrc}
          type='application/pdf'
          key={key}
        >
          <div className='file-no-preview'>
            <CouldntPreviewIcon />

            <p className='couldnt-preview-label'>
              <FormattedMessage id='issue_preview_gallery_cant_preview_message' />
            </p>
            <ButtonContained
              onClick={handleDownloadFileClick}
              startIcon={<GetAppOutlined />}
            >
              <FormattedMessage id='issue_preview_gallery_file_download_label' />
            </ButtonContained>
          </div>
        </object>
      </div>
    );
  }

  return (
    <div className='file-no-preview'>
      <CouldntPreviewIcon />

      <p className='couldnt-preview-label'>
        <FormattedMessage id='issue_preview_gallery_cant_preview_message' />
      </p>
      <ButtonContained
        onClick={handleDownloadFileClick}
        startIcon={<GetAppOutlined />}
      >
        <FormattedMessage id='issue_preview_gallery_file_download_label' />
      </ButtonContained>
    </div>
  );
};

export default FileDisplay;
