import { DependencyKeys } from './types';

export function makeSingleDependencySetter(
  key: DependencyKeys,
  value: any
): any {
  return function depsSetter(prev: any): any {
    return {
      ...prev,
      [key]: value,
    };
  };
}

export function sanitizeDateFilterValue(
  filter: ((timezone: string) => string) | string,
  timezone: string
): string {
  return typeof filter === 'function' ? filter(timezone) : filter;
}
