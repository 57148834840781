import { useMediaQuery, useTheme } from '@mui/material';

export const MobileTreshold = 1024;

const useIsMobile = (): boolean => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down(MobileTreshold));
};

export default useIsMobile;
