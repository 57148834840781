import { ConfigData, get as getDbConfig } from 'serviceWorker/db/config';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

class TokenHolder {
  private expiration = 0;
  private token = '';

  constructor() {
    self.addEventListener(DomainMessagesTypes.logout, () => {
      this.setToken('');
      this.setExpiration(0);
    });
  }

  setToken(token: string): void {
    this.token = token;
  }
  getToken(): string {
    return this.token;
  }
  getExpiration(): number {
    return this.expiration;
  }
  setExpiration(expiration: number): void {
    this.expiration = expiration;
  }
}

class TokenRequester {
  private awaiting: boolean = false;
  private currentPromise: Promise<any> = Promise.resolve();

  constructor(private tokenHolder: TokenHolder) {}

  onTokenExpired(): Promise<any> {
    if (this.awaiting) {
      return this.currentPromise;
    }
    this.awaiting = true;
    this.currentPromise = this.awaitToken();
    return this.currentPromise;
  }

  private awaitToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      const broadcast = new BroadcastChannel(ChannelNames.tokenChannel);

      const interval = setInterval(() => {
        broadcast.postMessage({
          type: DomainMessagesTypes.getToken,
        });
      }, 1000);
      const timeout = setTimeout(() => {
        reject('Timeout');
        clearInterval(interval);
        this.awaiting = false;
      }, 30000);

      broadcast.onmessage = (event: Message) => {
        if (event.type === DomainMessagesTypes.token && event.data) {
          clearTimeout(timeout);
          clearInterval(interval);
          this.awaiting = false;
          this.tokenHolder.setToken(event.data.token);
          this.tokenHolder.setExpiration(event.data.tokenExpiration);
          resolve(undefined);
        }
      };

      broadcast.postMessage({
        type: DomainMessagesTypes.getToken,
      });
    });
  }
}

const tokenHolder = new TokenHolder();
const tokenRequester = new TokenRequester(tokenHolder);

export async function getFetchConfig(): Promise<ConfigData | undefined> {
  const expiration = tokenHolder.getExpiration();
  const nowPlus1Minute = Date.now() + 60 * 1000;

  if (!expiration || expiration < nowPlus1Minute) {
    await tokenRequester.onTokenExpired();
  }

  return getDbConfig();
}

export function getToken(): string {
  return tokenHolder.getToken();
}
