import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { startProjectDataSynchronization } from 'shared/domain/project/startProjectDataSynchronization';
import { isStandard } from 'shared/domain/role/isStandard';
import { useCurrentUserRoleInSelectedProject } from 'hooks/useCurrentUserRoleInSelectedProject';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { useAuth0 } from 'services/auth0/react-auth0.spa';
import { ChannelNames } from 'shared/domain/channelNames';
import { listenToHasAll } from './model';
import { useDataFlushedListener } from '../../broadcastChannelListeners/useDataFlushedListener';
import { debugLog } from 'shared/logger/debugLog';
import { isProjectDataFlushedMessage } from 'components/core/WithDataFlushedSubscriber/model';
import { BroadcastChannel } from 'broadcast-channel';
import { useMountedRef } from 'hooks/useMountRef';

const SYNC_INTERVAL_WHEN_LOADING = 25000;
type WithIsProjectLoadingProps = { children: ReactElement };
type IsProjectLoadingContextType = {
  loading: boolean;
};
const IsProjectLoadingContext = React.createContext<
  IsProjectLoadingContextType | undefined
>(undefined);

function WithIsProjectLoading({
  children,
}: WithIsProjectLoadingProps): ReactElement {
  const mountedRef = useMountedRef();
  const { isAuthenticated } = useAuth0();
  const { _id: projectId } = useSelector(projectDataSelector);
  const role = useCurrentUserRoleInSelectedProject();
  const [issuesLoading, setIssuesLoading] = useState(true);
  const [templatesLoading, setTemplatesLoading] = useState(true);
  const [inspectionsLoading, setInspectionsLoading] = useState(true);
  const [companiesLoading, setCompaniesLoading] = useState(true);
  const [contractsLoading, setContractsLoading] = useState(true);
  const [usersLoading, setUsersLoading] = useState(true);
  const [sitesLoading, setSitesLoading] = useState(true);
  const [levelsLoading, setLevelsLoading] = useState(true);
  const [documentationsLoading, setDocumentationsLoading] = useState(true);
  const [directoriesLoading, setDirectoriesLoading] = useState(true);
  const [issueFormsLoading, setIssueFormsLoading] = useState(true);
  const [visibleFieldsLoading, setVisibleFieldsLoading] = useState(true);
  const authenticatedRef = useRef(isAuthenticated);
  const projectRef = useRef(projectId);

  useEffect(() => {
    authenticatedRef.current = isAuthenticated;
  }, [isAuthenticated]);
  useEffect(() => {
    projectRef.current = projectId;
  }, [projectId]);

  useDataFlushedListener(
    mountedRef,
    useCallback(() => {
      if (!isStandard(role)) {
        setTemplatesLoading(true);
        setInspectionsLoading(true);
      }
      setIssuesLoading(true);
      setCompaniesLoading(true);
      setContractsLoading(true);
      setUsersLoading(true);
      setSitesLoading(true);
      setLevelsLoading(true);
      setDocumentationsLoading(true);
      setDirectoriesLoading(true);
      setIssueFormsLoading(true);
      setVisibleFieldsLoading(true);
    }, [role])
  );

  useEffect(() => {
    return listenToHasAll(
      setUsersLoading,
      ChannelNames.userChannel,
      DomainMessagesTypes.allUsers
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setIssuesLoading,
      ChannelNames.issueChannel,
      DomainMessagesTypes.allIssues
    );
  }, []);

  useEffect(() => {
    if (isStandard(role)) {
      return setTemplatesLoading(false);
    }

    return listenToHasAll(
      setTemplatesLoading,
      ChannelNames.inspectionTemplateChannel,
      DomainMessagesTypes.allInspectionTemplates
    );
  }, [role]);

  useEffect(() => {
    if (isStandard(role)) {
      return setInspectionsLoading(false);
    }

    return listenToHasAll(
      setInspectionsLoading,
      ChannelNames.inspectionChannel,
      DomainMessagesTypes.allInspections
    );
  }, [role]);

  useEffect(() => {
    return listenToHasAll(
      setCompaniesLoading,
      ChannelNames.companyChannel,
      DomainMessagesTypes.allCompanies
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setContractsLoading,
      ChannelNames.contractChannel,
      DomainMessagesTypes.allContracts
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setSitesLoading,
      ChannelNames.siteChannel,
      DomainMessagesTypes.allSites
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setLevelsLoading,
      ChannelNames.levelChannel,
      DomainMessagesTypes.allLevels
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setDocumentationsLoading,
      ChannelNames.documentationChannel,
      DomainMessagesTypes.hasAllDocumentations
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setDirectoriesLoading,
      ChannelNames.directoryChannel,
      DomainMessagesTypes.allDirectories
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setIssueFormsLoading,
      ChannelNames.issueFormChannel,
      DomainMessagesTypes.allIssueForm
    );
  }, []);

  useEffect(() => {
    return listenToHasAll(
      setVisibleFieldsLoading,
      ChannelNames.visibleFieldChannel,
      DomainMessagesTypes.allVisibleFields
    );
  }, []);
  const isLoading =
    issuesLoading ||
    templatesLoading ||
    inspectionsLoading ||
    companiesLoading ||
    contractsLoading ||
    usersLoading ||
    sitesLoading ||
    levelsLoading ||
    documentationsLoading ||
    directoriesLoading ||
    issueFormsLoading ||
    visibleFieldsLoading;

  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;

  const apiChannelBroadcast = useMemo(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
    broadcast.onmessage = (event: Message): void => {
      if (
        isProjectDataFlushedMessage(event) &&
        isLoadingRef.current &&
        projectRef.current &&
        authenticatedRef.current
      ) {
        startProjectDataSynchronization();
        return;
      }
    };
    return broadcast;
  }, []);

  useEffect(() => {
    const b = apiChannelBroadcast;
    return (): void => {
      b.close();
    };
  }, [apiChannelBroadcast]);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (isLoading && projectId && isAuthenticated) {
      setTimeout(startProjectDataSynchronization, 1000);
      interval = setInterval(() => {
        startProjectDataSynchronization();
      }, SYNC_INTERVAL_WHEN_LOADING);
    }

    return (): void => {
      clearInterval(interval);
    };
  }, [isLoading, projectId, isAuthenticated]);

  debugLog(
    'Loading project state, waiting for:',
    issuesLoading ? `\nissues` : '',
    templatesLoading ? `\ntemplates` : '',
    inspectionsLoading ? `\ninspections` : '',
    companiesLoading ? `\ncompanies` : '',
    contractsLoading ? `\ncontracts` : '',
    usersLoading ? `\nusers` : '',
    sitesLoading ? `\nsites` : '',
    levelsLoading ? `\nlevels` : '',
    documentationsLoading ? `\ndocumentations` : '',
    directoriesLoading ? `\ndirectories` : '',
    issueFormsLoading ? `\nissueForms` : '',
    visibleFieldsLoading ? `\nvisibleFields` : ''
  );

  const ctx = useMemo(
    () => ({
      loading: isLoading,
    }),
    [isLoading]
  );

  return (
    <IsProjectLoadingContext.Provider value={ctx}>
      {children}
    </IsProjectLoadingContext.Provider>
  );
}

const withIsProjectLoading =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithIsProjectLoading>
      <Component {...props} />
    </WithIsProjectLoading>
  );

function useIsProjectLoading(): IsProjectLoadingContextType {
  const context = React.useContext(IsProjectLoadingContext);
  if (context === undefined) {
    throw new Error(
      'useIsProjectLoading must be used within an IsProjectLoadingContextProvider'
    );
  }
  return context;
}

export { WithIsProjectLoading, withIsProjectLoading, useIsProjectLoading };
