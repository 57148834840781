import React, { CSSProperties, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { ButtonContained, ButtonText } from '../../../general';
import { styles } from './dialogStyles';
import useIsMobile from 'hooks/useIsMobile';
import {
  Dialog as MaterialDialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Divider,
} from '@mui/material';
import { debugLog } from 'shared/logger/debugLog';

export enum DialogType {
  info = 'info',
}

export interface DialogOptions {
  title?: string | React.ReactNode;
  controllersOff?: boolean;
  description?: string | React.ReactNode;
  catchOnCancel?: boolean;
  customControllerLabels?: (string | null)[];
  fullWidth?: boolean;
  controllerStyles?: CSSProperties;
  submitContained?: boolean;
  type?: string;
  onDialogSubmit?: () => void;
  onDialogClose?: () => void;
  submitLabel?: string;
  rejectOnOffline?: boolean;
}

interface DialogProps extends DialogOptions {
  open: boolean;
  onSubmit?: () => void;
  onClose?: () => void;
  submitDisabled?: boolean;
  cancelLabel?: string;
}

const dialogProps = (isMobile: boolean): object => ({
  style: {
    zIndex: 40000,
  },
  PaperProps: {
    style: {
      minWidth: isMobile ? 'min(95%, 300px)' : 500,
      maxWidth: 800,
    },
  },
});

const Dialog: React.FC<DialogProps> = ({
  open,
  title,
  type,
  description,
  fullWidth,
  customControllerLabels,
  controllerStyles,
  submitContained,
  onSubmit,
  onClose,
  onDialogSubmit,
  onDialogClose,
  submitDisabled,
  controllersOff,
  cancelLabel,
  submitLabel,
}) => {
  const isMobile = useIsMobile();
  const intl = useIntl();
  const CANCEL = intl.formatMessage({
    id: cancelLabel || customControllerLabels?.[0] || 'general_cancel',
  });
  const SUBMIT = intl.formatMessage({
    id: submitLabel || customControllerLabels?.[1] || 'general_yes',
  });

  const handleClose = useCallback((): void => {
    if (onDialogClose) {
      onDialogClose();
    }

    onClose && onClose();
  }, [onClose, onDialogClose]);

  const handleSubmit = (): void => {
    if (onDialogSubmit) {
      onDialogSubmit();
    }

    onSubmit && onSubmit();
  };

  const SubmitButton = submitContained ? ButtonContained : ButtonText;

  return (
    <MaterialDialog open={open} {...dialogProps(isMobile)}>
      <div style={styles.dialogHeader}>
        {title && (
          <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        )}
      </div>
      <Divider />
      <DialogContent
        style={{
          ...styles.dialogContent,
          ...(fullWidth && styles.fullWidth),
        }}
      >
        {description}
      </DialogContent>
      {!controllersOff && (
        <DialogActions
          style={{ ...styles.dialogActions, ...controllerStyles }}
        >
          {type !== DialogType.info && (
            <ButtonText
              onClick={handleClose}
              data-qa='dialog-discard-button'
            >
              {CANCEL}
            </ButtonText>
          )}
          <ButtonContained
            onClick={handleSubmit}
            disabled={submitDisabled}
            data-qa='dialog-confirm-button'
          >
            {SUBMIT}
          </ButtonContained>
        </DialogActions>
      )}
    </MaterialDialog>
  );
};

export default Dialog;
