import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  OrganizationCreateCustomEvent,
  OrganizationEditCustomEvent,
} from 'shared/domain/messages/organization/eventMessages';
import { ConfigData } from 'serviceWorker/api/types';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as organizations from 'serviceWorker/db/organizations';
import * as organizationsService from 'serviceWorker/db/organizationsService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { getFinishedServiceStateWithoutSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { Pull } from '../factories/types';
import { organizationSaver } from './addOrganization';
import { organizationEditor } from './editOrganization';
import { fetchOrganizations } from './fetchOrganizations';
import { BroadcastChannel } from 'broadcast-channel';

const broadcasters: CallableFunction[] = [];
//   () => {
//     const broadcast = new BroadcastChannel(
//       ChannelNames.organizationChannel
//     );
//     debugLog('Broadcasting organization state');
//     broadcast.postMessage({
//       data: { isDownloaded: true },
//       type: DomainMessagesTypes.state,
//     });
//     broadcast.close();
//   },
// ];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullOrganizations: Pull = makePullEntity({
  fetch: fetchOrganizations,
  channelName: ChannelNames.organizationChannel,
  entityName: 'organizations',
  entityRepository: organizations,
  entityService: organizationsService,
  finishedServiceStateFactory: getFinishedServiceStateWithoutSyncKey,
  emitAllBroadcasts,
  withoutSyncKey: true,
});

const customRequirements: (keyof ConfigData)[] = [
  'locale',
  'frontendVersion',
  'api',
];
const pullOrganizationsHandler = makePullHandler(
  config,
  organizations,
  organizationsService,
  pullOrganizations,
  clearBroadcasts,
  customRequirements,
  true
);

export const init = (): void => {
  const debounced = debounce(pullOrganizationsHandler, 250);

  self.addEventListener(DomainMessagesTypes.getAllOrganizations, (e) => {
    debugLog(
      'received domain getAllOrganizations event in SW service layer',
      e
    );
    debounced();
  });
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addOrganization,
    async function onCreateOrganization(
      e: OrganizationCreateCustomEvent
    ): Promise<void> {
      debugLog('addOrganization event', e);
      organizationSaver.execute(
        e.detail.organizationCreateModel,
        e.detail.uniqueId
      );
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editOrganization,
    async function onEditOrganization(
      e: OrganizationEditCustomEvent
    ): Promise<void> {
      debugLog('editOrganization event', e);
      organizationEditor.execute(
        e.detail.organizationEditModel,
        e.detail.uniqueId
      );
    }
  );
};
