import { ChecklistItemError } from 'views/template/wizard/form/types';

function hasError(error: ChecklistItemError): boolean {
  return Boolean(error.summary) || Boolean(error.description);
}

export function shouldBeExpanded(
  error: ChecklistItemError,
  expanded: boolean
): boolean {
  return hasError(error) || expanded;
}

export function add0ToSingleDigitNumber(number: number): string {
  return number < 10 ? '0' + number : `${number}`;
}
