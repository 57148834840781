import { DocumentEntity } from 'serviceWorker/repository/document/entity';

import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import {
  DocumentModel,
  DocumentParentBond,
} from 'shared/domain/document/documentModel';
import { DocumentUrlsModel } from 'shared/domain/document/documentUrlsModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  CreateDocumentCustomEvent,
  CreateDrawingCustomEvent,
  DeleteDocumentCustomEvent,
  EditDocumentCustomEvent,
  GetDocumentCustomEvent,
  GetDocumentsCustomEvent,
  GetDocumentsCustomEventDetail,
} from 'shared/domain/messages/document/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { documentEntityToModel } from 'serviceWorker/repository/document/documentEntityToModel';
import { isGetByIds, isGetByQuery } from 'serviceWorker/api/typeGuards';
import {
  broadcastDocument,
  broadcastDocuments,
  broadcastDocumentUrls,
} from 'serviceWorker/broadcasts/documents';
import * as documents from 'serviceWorker/db/documents';
import { UploadStatus } from 'shared/types/uploadStatus';
import { debugLog } from 'shared/logger/debugLog';
import { getFetchConfig } from '../config';
import documentSaver from './addDocument';
import drawingSaver from './addDrawing';
import documentDeleter from './deleteDocument';
import documentEditor, { DocumentChanges } from './editDocument';
import { resolveDocumentRemoteSource } from './resolveDocumentUrl';

function getDocumentsListByIds(ids: number[]): Promise<DocumentModel[]> {
  return documents
    .getByIds(ids)
    .then((docs) => docs.map(documentEntityToModel));
}

function getDocumentsListByQuery(
  query: DocumentParentBond
): Promise<DocumentEntity[]> {
  return documents
    .getByQuery(query)
    .then((docs) => docs.map(documentEntityToModel));
}

function getDocuments(
  detail: GetDocumentsCustomEventDetail
): Promise<DocumentEntity[]> {
  if (isGetByIds(detail)) return getDocumentsListByIds(detail.ids);
  if (isGetByQuery(detail)) return getDocumentsListByQuery(detail.query);
  throw new Error('Function getDocuments recieved unknown type.');
}

export async function getDocument(id: number): Promise<DocumentModel> {
  const document = await documents.getOne(id);
  if (!document) {
    throw new Error('Cannot find document with id ' + id);
  }
  return documentEntityToModel(document);
}

export function updateDocument(
  id: number,
  changes: DocumentChanges,
  options?: { skipModifyTime: boolean }
): Promise<number> {
  return documents.updateOne(id, changes, options);
}

async function getDocumentUrls(
  document: DocumentEntity
): Promise<DocumentUrlsModel> {
  if (
    document._id &&
    document.data?.uploadStatus === UploadStatus.success &&
    document.syncStatus === SyncStatus.SUCCESS
  ) {
    const setup = await getFetchConfig();

    if (!setup) {
      return Promise.reject(new Error('Cannot get config setup.'));
    }

    if (
      document.data?.isDrawn &&
      (document.data?.mergedUploadStatus !== UploadStatus.success ||
        document.drawingSyncStatus !== SyncStatus.SUCCESS)
    ) {
      const signedRequest = await resolveDocumentRemoteSource(
        setup,
        document
      );

      if (!signedRequest.signedRequest && !document.data?.mergedSrc) {
        return Promise.reject(
          new Error('Document source data is undefined.')
        );
      }

      return Promise.resolve({
        signedRequest: signedRequest.signedRequest,
        thumbnailSignedRequest: signedRequest.thumbnailSignedRequest,
        mergedSignedRequest:
          document.data?.mergedSrc || signedRequest.mergedSignedRequest,
        mergedThumbnailSignedRequest:
          document.data?.thumbnailMergedSrc ||
          signedRequest.mergedThumbnailSignedRequest,
        drawingSignedRequest: signedRequest.drawingSignedRequest,
      });
    }

    return resolveDocumentRemoteSource(setup, document);
  } else {
    if (!document.localData) {
      return Promise.reject(
        new Error('Document source data is undefined.')
      );
    }

    if (document.data?.isDrawn) {
      if (!document.data.mergedSrc) {
        return Promise.reject(
          new Error('Document drawing source data is undefined.')
        );
      }

      return Promise.resolve({
        signedRequest: document.localData,
        mergedSignedRequest: document.data.mergedSrc,
        mergedThumbnailSignedRequest: document.data.thumbnailMergedSrc,
        drawingSignedRequest: document.data.drawingSrc,
      });
    } else {
      return Promise.resolve({
        signedRequest: document.localData,
      });
    }
  }
}

export const init = (): void => {
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getDocuments,
    async function onGetDocuments(
      e: GetDocumentsCustomEvent
    ): Promise<void> {
      try {
        const documents = await getDocuments(e.detail);
        broadcastDocuments(e, documents);
      } catch (error) {
        debugLog('documents error', error);
      }
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    DomainMessagesTypes.getDocument,
    async function onGetDocument(
      e: GetDocumentCustomEvent
    ): Promise<void> {
      const document = await getDocument(e.detail.id);
      broadcastDocument(document);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getDocumentUrls,
    async function onGetDocumentUrls(
      e: GetDocumentCustomEvent
    ): Promise<void> {
      const document = await getDocument(e.detail.id);
      if (!document) {
        throw new Error('Cannot find document.');
      }
      const documentUrls = await getDocumentUrls(document).catch(() => {
        return {
          signedRequest: DOCUMENT_ERROR_SOURCE,
          mergedSignedRequest: DOCUMENT_ERROR_SOURCE,
          drawingSignedRequest: DOCUMENT_ERROR_SOURCE,
          mergedThumbnailSignedRequest: DOCUMENT_ERROR_SOURCE,
        };
      });

      broadcastDocumentUrls({
        localId: document.localId,
        documentUrls,
      });
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addDocument,
    async function onCreateDocument(
      e: CreateDocumentCustomEvent
    ): Promise<void> {
      debugLog('addDocument event', e);
      documentSaver.execute(
        e.detail.baseUrl,
        e.detail.document,
        e.detail.uniqueId
      );
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editDocument,
    async function onEditDocument(
      e: EditDocumentCustomEvent
    ): Promise<void> {
      debugLog('editDocument event', e);
      documentEditor.execute(e.detail.localId, e.detail);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteDocument,
    async function onDeleteDocument(
      e: DeleteDocumentCustomEvent
    ): Promise<void> {
      debugLog('deleteDocument event', e);
      documentDeleter.execute(e.detail.localId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addDrawing,
    async function onAddDrawing(
      e: CreateDrawingCustomEvent
    ): Promise<void> {
      debugLog('addDrawing event', e);
      drawingSaver.execute(e.detail.drawing, e.detail.localId);
    }
  );
};
