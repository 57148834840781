import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { CompanyInDto } from 'shared/dtos/in/company';
import {
  broadcastUploadedCompanyError,
  broadcastCreatedCompanyError,
} from 'serviceWorker/broadcasts/company/error';
import {
  broadcastCreatedCompany,
  broadcastDeletedCompany,
  broadcastUpdatedCompany,
} from 'serviceWorker/broadcasts/company/one';
import { debugLog } from 'shared/logger/debugLog';

export function broadcastCompanyResponse(
  request: HttpRequestEntity,
  response: CompanyInDto | any
): void {
  if (response.error) {
    return broadcastUploadedCompanyError(response.error);
  }
  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastCreatedCompany(response, request.data.uniqueId) as any;
  }
  if (request.method === 'PUT') {
    debugLog(
      'broadcasting updated company',
      response,
      request.data.uniqueId
    );
    return broadcastUpdatedCompany(response, request.data.uniqueId) as any;
  }
  if (request.method === 'DELETE') {
    return broadcastDeletedCompany(response, request.data.uniqueId);
  }
}

export function broadcastCompanyError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastCreatedCompanyError(error);
}
