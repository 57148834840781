export enum ImageContent {
  none = 'none',
  main = 'main',
  all = 'all',
}

type ImageContentLabelsType = {
  [key in ImageContent]: string;
};
export const IMAGE_CONTENT_LABELS: ImageContentLabelsType = {
  [ImageContent.all]: 'report_image_content_all',
  [ImageContent.main]: 'report_image_content_main',
  [ImageContent.none]: 'report_image_content_none',
};
export enum ReportFieldsContent {
  all = 'all_fields',
  filled = 'only_fields_with_value',
  selected = 'only_selected_fields',
}
type ReportFieldsContentLabelsType = {
  [key in ReportFieldsContent]: string;
};

export const REPORT_FIELDS_CONTENT_LABELS: ReportFieldsContentLabelsType =
  {
    [ReportFieldsContent.all]: 'report_field_content_all_fields',
    [ReportFieldsContent.filled]:
      'report_field_content_only_fields_with_value',
    [ReportFieldsContent.selected]:
      'report_field_content_only_selected_fields',
  };

export type IssueListReportModel = {
  issues: string[];
  imageContent: ImageContent;
  fieldsContent: string;
  selectedFields: string[];
} & BaseReportModel;

export type BaseReportModel = {
  recipients: string[];
  name: string;
  message: string;
  localeCode: string;
};
