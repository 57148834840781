import { PromiseExtended } from 'dexie';
import { organizationInDtoToEntity } from 'shared/domain/organization/mapping/toEntity';
import { OrganizationInDto } from 'shared/domain/organization/types/dto';
import { OrganizationEntity } from 'shared/domain/organization/types/entity';
import { Changes } from 'shared/types/commonEntities';
import { LogLevel } from 'shared/types/logger';
import {
  makeDefaultAddBatch,
  makeDefaultAddBatchMapped,
  makeDefaultClear,
  makeDefaultGetMany,
  makeDefaultGetOne,
} from './defaultDaoFactories';
import { Operation, getErrorMsg, logWithRethrow } from './helpers';
import db from './index';
import { wrapQuery } from './queryWrapper';

export const clear = makeDefaultClear(db, db.organizations);

export const getOne = makeDefaultGetOne<string, OrganizationEntity>(
  db,
  db.organizations,
  '_id'
);

export const get = makeDefaultGetMany<OrganizationEntity>(
  db,
  db.organizations
);

export const addBatch = makeDefaultAddBatchMapped<
  OrganizationInDto,
  OrganizationEntity
>(db, db.organizations, organizationInDtoToEntity);

export const updateBatch = addBatch;

export const updateLocalBatch = makeDefaultAddBatch<OrganizationEntity>(
  db,
  db.organizations
);

export const updateOne = wrapQuery(
  db,
  (
    organizationId: string,
    changes: Changes<OrganizationEntity>
  ): PromiseExtended => {
    return db.organizations.update(organizationId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'organizations'),
        errorObj: e,
        additionalInfo: { query: { data: { organizationId, changes } } },
      })
    );
  }
);
