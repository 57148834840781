import React from 'react';
import ToggleIcon from '@mui/icons-material/ArrowForwardIos';
import { FormattedMessage } from 'react-intl';
import { styles } from '../FilterSidebarStyles';
import { Typography } from '@mui/material';

type Props = {
  onClose: () => void;
  onReset: () => void;
};

const Header: React.FC<Props> = ({ onClose, onReset }) => {
  const classes = styles();

  return (
    <div className={classes.headerWrapper}>
      <ToggleIcon
        onClick={onClose}
        className={classes.toggleIcon}
        data-qa='filter-sidebar-close-button'
      />
      <Typography
        onClick={onReset}
        className={classes.headerTitle}
        data-qa='filter-sidebar-reset-button'
      >
        <FormattedMessage id='filters_reset_label' />
      </Typography>
    </div>
  );
};

export default Header;
