import useIsMobile from 'hooks/useIsMobile';
import { useIsVisible } from 'hooks/useIsVisible';
import { ReactElement, useMemo, useRef } from 'react';
import { DocumentationBaseIds } from 'shared/types/documentation';
import { useGalleryContext } from '.';
import { FileType } from '../graphicUploader/types';
import { DisplayedFile } from './filesStore';
import { slideSelector } from './galleryStateStore';
import { Miniature, MiniatureText } from './miniature';
import styles from './styles';
import { MiniatureControllerProps, MiniaturesProps } from './types';

function ensureString(s?: string): string {
  return s ? `${s}` : '';
}

function filesStringSelector<
  T extends Partial<FileType> & DocumentationBaseIds,
>(x: T[]): string {
  return x
    .map(
      (x: T) =>
        x.localId ||
        x._id ||
        `${ensureString(x.documentationId)}${ensureString(x.versionId)}`
    )
    .join(',');
}

export function Miniatures({ drawings }: MiniaturesProps): ReactElement {
  const { useDisplayedFiles, useGalleryState } = useGalleryContext();
  const [files] = useDisplayedFiles(filesStringSelector);
  const [slide] = useGalleryState(slideSelector);

  return (
    <>
      {files.split(',').map((key, i) => (
        <MiniatureController
          isActive={slide === i}
          key={`${key}-${i}`}
          drawing={drawings[i]}
          i={i}
        />
      ))}
    </>
  );
}

function MiniatureController({
  isActive,
  i,
  drawing,
}: MiniatureControllerProps): ReactElement | null {
  const ref = useRef<HTMLDivElement>(null);
  const singleFileSelector = useMemo(() => {
    return function selectFile(
      files: DisplayedFile[]
    ): DisplayedFile | undefined {
      return files[i];
    };
  }, [i]);
  const { useDisplayedFiles } = useGalleryContext();
  const [file] = useDisplayedFiles(singleFileSelector);
  const isVisible = useIsVisible(ref);

  const isMobile = useIsMobile();
  const cs = styles({ isMobile });

  if (!file) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={cs.miniatureWithDescription}
      key={`${file.localId}-${i}`}
    >
      <Miniature
        key={`${file.localId}-${i}-miniature`}
        index={i}
        uniqueKey={`${file.localId}-${i}`}
        drawing={drawing}
        isVisible={isVisible}
        isActive={isActive}
      />
      <MiniatureText
        key={`${file.localId}-${i}-miniature-text`}
        text={file.description || ' '}
        isActive={isActive}
      />
    </div>
  );
}
