import startGetReportSignedRequestUseCase from 'shared/domain/report/startGetReportSignedRequest';
import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';
import { ReportDownloadContextType } from './types';

import {
  useServiceWorkerReady,
  withServiceWorkerReady,
} from '../withServiceWorkerReady';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useMountedRef } from 'hooks/useMountRef';
import { downloadZip } from './downloadZip';
import useFormEdit from 'views/issue/forms/useFormEdit';

type MultiReportDownloadData = {
  type: 'multi';
  signedRequests: string[];
  statusCode: number;
  reportId: string;
};
type SingleReportDownloadData = {
  type: 'single';
  signedRequest: string;
  statusCode: number;
  reportId: string;
};

const ReportDownloadContext = React.createContext<
  ReportDownloadContextType | undefined
>(undefined);

const _WithReportDownload: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { setEditing } = useFormEdit();
  const mountedRef = useMountedRef();
  const serviceWorkerReady = useServiceWorkerReady();
  const [statusCode, setStatusCode] = useState<number | undefined>();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [signedRequest, setSignedRequest] = useState<
    string | string[] | undefined
  >();
  const [reportId, setReportId] = useState<string | undefined>();
  const [token, setToken] = useState<string | undefined>();

  const startDownloadingReport = useCallback(
    (id: string, token: string) => {
      setReportId(id);
      setToken(token);
      setIsDownloading(true);
      setStatusCode(undefined);
    },
    []
  );

  useEffect(() => {
    if (!reportId || !serviceWorkerReady) {
      return;
    }

    const broadcast = new BroadcastChannel(
      ChannelNames.signedRequestChannel
    );

    broadcast.onmessage = (
      event: Message<MultiReportDownloadData | SingleReportDownloadData>
    ): void => {
      if (!mountedRef.current) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.signedRequest &&
        event.data.statusCode === 200 &&
        event.data.reportId === reportId
      ) {
        debugLog(
          'broadcast close recieved report signed request',
          event.data
        );
        const signedRequest =
          event.data.type === 'single'
            ? event.data.signedRequest
            : event.data.signedRequests;
        setSignedRequest(signedRequest);
        setStatusCode(event.data.statusCode);
        setIsDownloading(false);
        broadcast.close();
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.signedRequest &&
        event.error &&
        event.data.reportId === reportId
      ) {
        debugLog('signed request recieval ERROR', event);
        setStatusCode(event.data.statusCode);
        setIsDownloading(false);
      }
    };

    debugLog('broadcast signed request listening');

    return (): void => {
      broadcast.close();
    };
  }, [reportId, serviceWorkerReady, mountedRef]);

  useEffect(() => {
    if (!reportId || !token || !serviceWorkerReady) {
      return;
    }

    startGetReportSignedRequestUseCase({ id: reportId, token });
  }, [reportId, token, serviceWorkerReady]);

  const onZipDone = useCallback(() => {
    setEditing(false, 'reportDownload');
  }, [setEditing]);

  const downloadSignedRequest = useCallback(() => {
    if (!signedRequest) {
      return;
    }

    if (typeof signedRequest === 'string') {
      const link = document.createElement('a');
      link.href = signedRequest;
      link.dispatchEvent(new MouseEvent('click'));
      return;
    }

    if (Array.isArray(signedRequest)) {
      setEditing(true, 'reportDownload');
      downloadZip(signedRequest, onZipDone);
      return;
    }
  }, [signedRequest, onZipDone, setEditing]);

  useEffect(() => {
    downloadSignedRequest();
  }, [downloadSignedRequest]);

  const ctx = {
    statusCode,
    startDownloadingReport,
    isDownloading,
    downloadSignedRequest,
  };

  return (
    <ReportDownloadContext.Provider value={ctx}>
      {children}
    </ReportDownloadContext.Provider>
  );
};

const WithReportDownload = withServiceWorkerReady(_WithReportDownload);

const withReportDownload =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithReportDownload>
      <Component {...props} />
    </WithReportDownload>
  );

function useReportDownload(): ReportDownloadContextType {
  const context = React.useContext(ReportDownloadContext);
  if (context === undefined) {
    throw new Error(
      'useReportDownload must be used within a ReportDownloadContextProvider'
    );
  }
  return context;
}

export { WithReportDownload, withReportDownload, useReportDownload };
