import { getUploadedMessageListener } from 'shared/domain/commonModel';
import { createLevelOnViewToCreateModel } from 'shared/domain/level/mapping/toModel';
import { startLevelCreate } from 'shared/domain/level/startLevelCreate';
import { LevelModel } from 'shared/domain/level/types/model';
import {
  CreateLevelOnView,
  LevelOnView,
} from 'shared/domain/level/types/view';
import { projectIdSelector } from 'helpers/misc';
import { useGetAllLevels } from 'hooks/useGetAllLevels';
import { useGetAllSites } from 'hooks/useGetAllSites';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { currentUserSelector } from 'redux/selectors/users';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { nowTimezonedDateTime } from 'shared/utils/date/dates';
import { createUniqueId } from 'shared/utils/id/id';
import { createEmptyLevel } from 'views/level/model';
import {
  CreateLevelContext,
  OpenCreateLevelDialog,
  OpenCreateLevelDialogParams,
} from '../types';
import { handleFormValidation, validateLevel } from '../validation';

const SUBMIT_EVENT_TYPE = 'submit-level';
const WithCreateLevelContext = createContext<
  CreateLevelContext | undefined
>(undefined);
const formName = 'level';

function WithCreateLevel({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const { getAll: getAllSites } = useGetAllSites();
  const fetchSites = getAllSites;
  const { getAll: getAllLevels } = useGetAllLevels();
  const fetchLevels = getAllLevels;
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const projectId = useSelector(projectIdSelector);

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const { data: currentUser } = useSelector(currentUserSelector);
  const { timezone } = useSelector(projectDataSelector);

  const emptyLevel = createEmptyLevel(
    nowTimezonedDateTime(timezone),
    currentUser
  );
  const [initialValues, setInitialValues] =
    useState<LevelOnView>(emptyLevel);

  const [open, setOpen] = useState(false);

  const openDialog: OpenCreateLevelDialog = useCallback(
    ({ siteId, freeSoloLabel }: OpenCreateLevelDialogParams) => {
      setOpen(true);
      const currentValues = { ...initialValues, site: siteId };
      if (freeSoloLabel) {
        currentValues.label = freeSoloLabel;
      }
      setInitialValues(currentValues);
    },
    [initialValues]
  );
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: CreateLevelOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const levelCreateModel = createLevelOnViewToCreateModel(
        values,
        projectId
      );

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUploadedMessageListener(
          (message: LevelModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchSites();
            fetchLevels();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.levelChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateLevel: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startLevelCreate(levelCreateModel, uniqueId);
      })
        .then((level: unknown): any => {
          if (level && (level as Identificable)._id) {
            setOpen(false);
            return level;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchLevels, projectId, fetchSites]
  );

  const ctx: CreateLevelContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateLevel,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);

  return (
    <WithCreateLevelContext.Provider value={ctx}>
      {children}
    </WithCreateLevelContext.Provider>
  );
}

function useCreateLevel(): CreateLevelContext {
  const context = useContext(WithCreateLevelContext);
  if (context === undefined) {
    throw new Error(
      'useCreateLevel must be used within an CreateLevelContext'
    );
  }
  return context;
}

const withCreateLevel =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateLevel>
      <Component {...props} />
    </WithCreateLevel>
  );

export { WithCreateLevel, useCreateLevel, withCreateLevel };
