import { AtomicCall } from './types';
import { FieldSize } from 'shared/types/fieldSize';

export const MAX_FILE_DESCRIPTION_LENGTH = FieldSize.M;

export const atomicCall: AtomicCall = (
  config,
  actionName,
  params,
  fallback
) => {
  if (!config.atomicChanges) {
    return;
  }
  if (config.actions && config.actions[actionName]) {
    //@ts-ignore TS does not understand that we checked if cnf.actions[actName] is defined
    config.actions[actionName](...params);
    return;
  }
  if (config.baseUrl) {
    fallback();
  }
  return;
};

type FileWithType = {
  type: string;
};
export const isVideo = (file: FileWithType): boolean =>
  file.type.indexOf('video') > -1;

export const isPdf = (file: FileWithType): boolean =>
  file.type.indexOf('pdf') > -1;

export const isImage = (file: FileWithType): boolean =>
  file.type.indexOf('image') > -1;

export function areEqual(prevArray: any[], currArray: any[]): boolean {
  if (prevArray.length !== currArray.length) {
    return false;
  }

  return prevArray.every((prevFile) =>
    currArray.some((currFile) => {
      return currFile.localId === prevFile.localId;
    })
  );
}
