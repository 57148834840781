import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { makeDefaultSync } from './apiFactories';
import { GetInspectionTemplateEventDetail } from './types';

const sync = makeDefaultSync(
  RepositoryMessagesTypes.syncInspectionTemplates
);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetInspectionTemplateEventDetail>(
      DomainMessagesTypes.getInspectionTemplate,
      {
        detail: { id: e.id },
      }
    )
  );
};

export default { sync, getOne };
