import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import {
  CreateDirectoryCustomEvent,
  EditDirectoryCustomEvent,
} from 'shared/domain/messages/directory/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { DirectoryEntity } from 'serviceWorker/repository/directory/entity';
import { directoryEntityToModel } from 'serviceWorker/repository/directory/mappings';
import { broadcastAllDirectories } from 'serviceWorker/broadcasts/directories';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as directories from 'serviceWorker/db/directories';
import * as directoriesService from 'serviceWorker/db/directoriesService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import { getUsersSynchronized } from '../users/getUsers';
import { directorySaver } from './addDirectory';
import { directoryEditor } from './editDirectory';
import {
  fetchDirectories,
  fetchUpdatedDirectories,
} from './fetchDirectories';

export async function getDirectory(
  localId: number
): Promise<DirectoryModel> {
  const [directory, users] = await Promise.all([
    directories.getOne(localId),
    getUsersSynchronized(),
  ]);
  if (!directory) {
    throw new Error(`Cannot find directory with localId ${localId}`);
  }
  return directoryEntityToModel(directory, users);
}

export async function getDirectories(
  ids: number[]
): Promise<DirectoryEntity[]> {
  return directories.getByIds(ids);
}

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullDirectories: Pull = makePullEntity({
  fetch: fetchDirectories,
  channelName: ChannelNames.directoryChannel,
  entityName: 'directories',
  entityRepository: { ...directories, addBatch: directories.fastAddBatch },
  entityService: directoriesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullDirectoriesHandler = makePullHandler(
  config,
  directories,
  directoriesService,
  pullDirectories,
  clearBroadcasts
);

const synchronizeDirectories = makeSynchronizeEntity({
  configRepository: config,
  entityService: directoriesService,
  entityRepository: directories,
  pullEntityHandler: pullDirectoriesHandler,
  fetchUpdatedEntities: fetchUpdatedDirectories,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'directories',
  channelName: ChannelNames.directoryChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeDirectories.bind(null, broadcastAllDirectories),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncDirectories,
    syncDebounced
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addDirectory,
    async function onCreateDirectory(
      e: CreateDirectoryCustomEvent
    ): Promise<void> {
      debugLog('addDirectory event', e);
      directorySaver.execute(e.detail.directory, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editDirectory,
    async function onEditDirectory(
      e: EditDirectoryCustomEvent
    ): Promise<void> {
      debugLog('editDirectory event', e);
      directoryEditor.execute(e.detail.directory, e.detail.uniqueId);
    }
  );
};
