import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { DrawingModel } from './drawingModel';

function startDrawingCreate(drawing: DrawingModel, localId: number): void {
  debugLog('startDrawingCreate', drawing, localId);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DRAWINGS,
    method: ServiceMethods.CREATE,
    data: {
      drawing,
      localId,
    },
  });
  broadcast.close();
}

export default startDrawingCreate;
