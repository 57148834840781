import { DirectoryFields } from 'components/dataCreationForms/withDirectoryForm/types';
import {
  ErrorPresentation,
  requiredValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';

type DirectoryFormValidation = {
  [key in keyof DirectoryFields]: (value: any) => ErrorPresentation;
};
const validation: DirectoryFormValidation = {
  name: nameFieldValidation,
};

export function validate(
  key: keyof DirectoryFields,
  value: DirectoryFields[keyof DirectoryFields]
): ErrorPresentation {
  return validation[key](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: DirectoryFields
): boolean {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = validate(key as keyof DirectoryFields, value);
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}

function nameFieldValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) ||
    stringMinMaxValidation(FieldSize.CUSTOM_DOCUMENTATION_NAME, value)
  );
}
