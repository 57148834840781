import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import {
  levelCreateModelToChangeOutDto,
  levelCreateModelToOutDto,
} from 'shared/domain/level/mapping/toDto';
import { LevelCreateModel } from 'shared/domain/level/types/model';
import { AddLevelUseCase } from 'shared/domain/level/types/useCases';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { LevelHttpCreateRequestModel } from '../httpQueue/level/types';

export class LevelSaver implements AddLevelUseCase {
  constructor(
    private addRequest: (
      request: LevelHttpCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    levelCreateModel: LevelCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddLevelUseCase', levelCreateModel);

    const levelCreateOutDto = levelCreateModelToOutDto(levelCreateModel);
    const afterLevelCreateOutDto =
      levelCreateModelToChangeOutDto(levelCreateModel);
    const url = `/project/${levelCreateModel.projectId}/level`;

    const request: LevelHttpCreateRequestModel = {
      createdAt: Date.now(),
      method: 'POST',
      data: {
        levelCreateOutDto,
        afterCreateLevelChangeOutDto: afterLevelCreateOutDto,
        projectId: levelCreateModel.projectId,
        uniqueId: uniqueId,
      },
      url,
      entityType: HttpRequestModelType.level,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const levelSaver = new LevelSaver(addRequest);
