import { useMemo, useEffect, useCallback } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

type GetSite = {
  getSite: () => void;
};

export function useGetSite(id: string, withAutoCall?: boolean): GetSite {
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const getSite = useCallback(() => {
    broadcast.postMessage({
      service: Services.SITES,
      method: ServiceMethods.GET_ONE,
      data: {
        id: id,
      },
    });
  }, [broadcast, id]);

  useEffect(() => {
    if (withAutoCall) {
      getSite();
    }
  }, [getSite, withAutoCall]);

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getSite,
  };
}
