import { Box, ClickAwayListener, List } from '@mui/material';
import FeedbackButton from 'components/core/Feedback/FeedbackButton';
import useIsMobile from 'hooks/useIsMobile';
import React, { FC, ReactElement, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FrontVersion } from '../../index';
import NavigationItemButton from '../NavigationItem/NavigationItem';
import useStyles, { drawerCollapsed, drawerExpanded } from './styles';
import { NavigationItemProps, PresentationalProps } from './types';
import { HeaderLogo } from '../NavigationHeaderLogo/HeaderLogo';
export const NAVIGATION_DRAWER_ZINDEX = 1200;
function toReactNavigationItem(
  navigationItemProps: NavigationItemProps,
  collapse: () => void
): ReactElement {
  return navigationItemProps.redirectAddress ? (
    <Link
      className={navigationItemProps.disabled ? 'disabled-link' : ''}
      to={navigationItemProps.redirectAddress}
      key={navigationItemProps.componentKey}
      onClick={(): void => {
        if (navigationItemProps.disabled) {
          return;
        }
        collapse();
      }}
    >
      <NavigationItemButton
        {...navigationItemProps}
        key={navigationItemProps.componentKey}
      />
    </Link>
  ) : (
    <Box
      key={navigationItemProps.componentKey}
      className={navigationItemProps.disabled ? 'disabled-link' : ''}
    >
      <NavigationItemButton
        {...navigationItemProps}
        key={navigationItemProps.componentKey}
      />
    </Box>
  );
}

const NavigationDrawerPresentational: FC<PresentationalProps> = ({
  navigationItemsProps,
  setExpanded,
  toggleNavigationExpanded,
  setCollapsed,
  isNavigationExpanded,
}): ReactElement => {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const navigationButtons = navigationItemsProps.map((elem) =>
    toReactNavigationItem(elem, setCollapsed)
  );

  return (
    <ClickAwayListener onClickAway={setCollapsed}>
      <Box
        onMouseEnter={setExpanded}
        onMouseMove={setExpanded}
        onTouchStart={setExpanded}
        onMouseLeave={setCollapsed}
        id='navigationDrawer'
        sx={{
          zIndex: NAVIGATION_DRAWER_ZINDEX,
          position: 'absolute',
          top: 0,
          height: '100%',
          ...(isNavigationExpanded ? drawerExpanded : drawerCollapsed),
        }}
      >
        <HeaderLogo />

        <List className={classes.navigationButtonsWrapper}>
          <Box className='top-buttons'>{navigationButtons}</Box>
          <Box className='bottom-buttons'>
            <FeedbackButton
              isNavigationExpanded={isNavigationExpanded}
              onFeedbackDialogClose={setCollapsed}
            />
          </Box>
        </List>
        <FrontVersion />
      </Box>
    </ClickAwayListener>
  );
};

export default React.memo(NavigationDrawerPresentational);
