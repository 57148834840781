import React, { ReactElement, useCallback, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { ThumbnailCardDescription } from './thumbnailCardDescription';

import styles, { DEFAULT_THUMBNAIL_CARD_SIZE } from './styles';
import { useGraphicUploaderContext } from './index';
type ThumbnailCardProps = {
  isLoaded: boolean;
  description: string;
  children: ReactElement;
  id: number;
};

function ThumbnailCard({
  isLoaded,
  description,
  children,
  id,
}: ThumbnailCardProps): ReactElement {
  const {
    config: { thumbnailSize },
  } = useGraphicUploaderContext();
  const classes = styles(thumbnailSize ?? DEFAULT_THUMBNAIL_CARD_SIZE);

  const [showActions, setShowActions] = useState(false);
  const onMouseEnter = useCallback(() => setShowActions(true), []);
  const onMouseOver = useCallback(() => setShowActions(true), []);
  const onMouseMove = useCallback(() => setShowActions(true), []);
  const onMouseLeave = useCallback(() => setShowActions(false), []);

  return (
    <div
      className={classes.thumbnailCard}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseOver}
    >
      {!isLoaded && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
      {children}
      {isLoaded && (
        <ThumbnailCardDescription
          showActions={showActions}
          description={description}
          id={id}
          onMouseLeave={onMouseLeave}
        />
      )}
    </div>
  );
}

export const MemoThumbnailCard = React.memo(ThumbnailCard);
