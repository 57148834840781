import { DialogType } from 'components/core/Dialog/common/Dialog';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { createUserRoleDropdownOptions } from 'components/dataCreationForms/user/model';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { useSites } from 'components/dataProviders/withSites';
import {
  FormFieldProps,
  MultiChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import { toLabelledEntities } from 'helpers/misc';
import { useCurrentUserRoleInSelectedProject } from 'hooks/useCurrentUserRoleInSelectedProject';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { currentUserSelector } from 'redux/selectors/users';
import { LabelledEntity } from 'shared/types/commonView';
import { CreatableUserRole, UserRole } from 'shared/types/userRole';
import { UserOnView } from 'shared/domain/user/types/view';
import { UserFieldsPresentational } from './presentational';
import { UserFormProps } from './types';

function UserForm({
  isDialog,
  editedUserId,
  existingRoleId,
}: UserFormProps): React.ReactElement {
  const intl = useIntl();
  const author = useSelector(currentUserSelector);
  const authorRole = useCurrentUserRoleInSelectedProject();
  const { values, setValues } = useInputForm();
  const roleValue: { _id: CreatableUserRole } = values['role'];
  const sitesValue = values['sites'];
  const processesValue = values['processes'];

  const disabledSelfChange = Boolean(editedUserId)
    ? author.data._id === editedUserId
    : false;

  const [previouslyChosenSites, setPreviouslyChosenSites] =
    useState<LabelledEntity[]>(sitesValue);
  const [previouslyChosenProcesses, setPreviouslyChosenProcesses] =
    useState<LabelledEntity[]>(processesValue);
  const [adminSelected, setAdminSelected] = useState<
    CreatableUserRole | undefined
  >();

  const { processes } = useSelector(projectDataSelector);

  const {
    sites: { items: sites },
  } = useSites();
  const availableSites = useMemo(
    () => toLabelledEntities(sites.filter(keepUndeleted)),
    [sites]
  );

  const availableProcesses = useMemo(
    () => toLabelledEntities(processes),
    [processes]
  );

  const createDialog = useDialog();

  const handleAdminSelection = useCallback(
    (role) => {
      createDialog({
        title: intl.formatMessage({
          id:
            role === UserRole.admin
              ? 'set_admin_role_dialog_title'
              : 'set_client_admin_role_dialog_title',
        }),
        description: (
          <span>
            {intl.formatMessage({
              id:
                role === UserRole.admin
                  ? 'set_admin_role_dialog_body'
                  : 'set_client_admin_role_dialog_body',
            })}
          </span>
        ),
        type: DialogType.info,
        submitLabel: 'general_ok',
      }).then(() => {
        setAdminSelected(role);

        setPreviouslyChosenProcesses(processesValue);
        setPreviouslyChosenSites(sitesValue);

        setValues('sites', availableSites);
        setValues('processes', availableProcesses);
      });
    },
    [
      processesValue,
      sitesValue,
      availableSites,
      availableProcesses,
      setValues,
      createDialog,
      intl,
    ]
  );

  const handleAdminDeselection = useCallback(() => {
    setAdminSelected(undefined);
    setValues('sites', previouslyChosenSites);
    setValues('processes', previouslyChosenProcesses);
  }, [setValues, previouslyChosenProcesses, previouslyChosenSites]);

  useEffect(() => {
    if (
      roleValue?._id === existingRoleId ||
      roleValue?._id === UserRole.standard ||
      roleValue?._id === UserRole.viewer ||
      roleValue?._id === UserRole.manager ||
      roleValue?._id === adminSelected
    ) {
      return;
    }

    handleAdminSelection(roleValue?._id);
  }, [roleValue, adminSelected, handleAdminSelection, existingRoleId]);

  useEffect(() => {
    if (
      !adminSelected ||
      roleValue?._id === UserRole.admin ||
      roleValue?._id === UserRole.clientAdmin
    ) {
      return;
    }
    handleAdminDeselection();
  }, [roleValue, adminSelected, handleAdminDeselection]);

  const labelFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'label',
      localStorageKey: 'user-label',
      required: true,
      labelId: 'access_management_name',
      fieldName: 'text-field-name',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'access_management_name_field',
    }),
    [isDialog]
  );

  const emailFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'email',
      required: true,
      labelId: 'user_email',
      fieldName: 'text-field-email',
      disabled: !!editedUserId,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'access_management_email_field',
    }),
    [editedUserId, isDialog]
  );

  const roleFieldProps: FormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'role',
      required: true,
      labelId: 'access_management_role',
      fieldName: 'singleselect-role',
      available: createUserRoleDropdownOptions(authorRole, intl),
      getOptionLabel: (option): string =>
        intl.formatMessage({ id: option.label }),
      disabled: disabledSelfChange
        ? { reason: 'tooltip_access_self_edit_unavailable' }
        : false,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'access_management_role_field',
    }),
    [intl, authorRole, disabledSelfChange, isDialog]
  );

  const sitesFieldProps: MultiChoiceFormFieldProps<UserOnView> = useMemo(
    () => ({
      formKey: 'sites',
      required: true,
      labelId: 'filters_filter_type_site',
      fieldName: 'multiselect-site',
      available: availableSites,
      entityName: EditableStandardEntityName.location,
      disabled: adminSelected
        ? { reason: 'user_form_admin_all_sites_all_processes_info' }
        : disabledSelfChange
          ? { reason: 'tooltip_access_self_edit_unavailable' }
          : false,
      reserveSpaceForHelperText: !isDialog,
      dense: true,
      'data-qa': 'user_sites',
    }),
    [availableSites, adminSelected, disabledSelfChange, isDialog]
  );

  const processesFieldProps: MultiChoiceFormFieldProps<UserOnView> =
    useMemo(
      () => ({
        formKey: 'processes',
        required: true,
        labelId: 'filters_filter_type_process',
        fieldName: 'multiselect-processes',
        disabled: adminSelected
          ? { reason: 'user_form_admin_all_sites_all_processes_info' }
          : disabledSelfChange
            ? { reason: 'tooltip_access_self_edit_unavailable' }
            : false,
        available: availableProcesses,
        entityName: '',
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'user_processes',
      }),
      [availableProcesses, adminSelected, disabledSelfChange, isDialog]
    );

  return (
    <UserFieldsPresentational
      labelFieldProps={labelFieldProps}
      emailFieldProps={emailFieldProps}
      roleFieldProps={roleFieldProps}
      sitesFieldProps={sitesFieldProps}
      processesFieldProps={processesFieldProps}
    />
  );
}

export const MemoUserForm = memo(UserForm);
