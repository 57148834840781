import db, {
  HustroDbEntityTablesUnion,
  HustroDbTablesUnion,
} from './index';
import { logWithRethrow } from './helpers';
import { PromiseExtended, Table } from 'dexie';
import { wrapQuery } from './queryWrapper';
import { clear as dbInspectionsClear } from './inspections';
import { clear as dbInspectionTemplatesClear } from './inspectionTemplates';
import { clear as dbCompaniesClear } from './companies';
import { clear as dbContractsClear } from './contracts';
import { clear as dbProjectsClear } from './projects';
import { clear as dbOrganizationsClear } from './organizations';
import { clear as dbUsersClear } from './users';
import { clear as dbIssuesClear } from './issues';
import { clear as dbSitesClear } from './sites';
import { clear as dbWorktypesClear } from './worktypes';
import { clear as dbHazardCategoriesClear } from './hazardCategories';
import { clear as dbEnvironmentalAspectsClear } from './environmentalAspects';
import { clear as dbCorrectiveActionTypesClear } from './correctiveActionTypes';
import { clear as dbLevelsClear } from './levels';
import { clear as dbDocumentationsClear } from './documentations';
import { clear as dbDirectoriesClear } from './directories';
import { clear as dbIssueFormClear } from './issueForm';
import { clear as dbVisibleFieldsClear } from './visibleFields';
import { LogLevel } from 'shared/types/logger';
import { debugLog } from 'shared/logger/debugLog';

const SKIP_ON_CLEAR = ['config', 'permissions'];
const SKIP_ON_PROJECT_CLEAR = [
  ...SKIP_ON_CLEAR,
  'selectedProject',
  'chartFilters',
  'projects',
  'projectsService',
  'httpRequests',
];

const ENTITY_DATABASE: {
  [key in HustroDbEntityTablesUnion]: CallableFunction;
} = {
  issues: dbIssuesClear,
  inspections: dbInspectionsClear,
  inspectionTemplates: dbInspectionTemplatesClear,
  companies: dbCompaniesClear,
  contracts: dbContractsClear,
  projects: dbProjectsClear,
  organizations: dbOrganizationsClear,
  users: dbUsersClear,
  sites: dbSitesClear,
  worktypes: dbWorktypesClear,
  hazardCategories: dbHazardCategoriesClear,
  environmentalAspects: dbEnvironmentalAspectsClear,
  correctiveActionTypes: dbCorrectiveActionTypesClear,
  levels: dbLevelsClear,
  documentations: dbDocumentationsClear,
  directories: dbDirectoriesClear,
  issueForm: dbIssueFormClear,
  visibleFields: dbVisibleFieldsClear,
};
const ENTITY_DATABASE_NAMES = Object.keys(ENTITY_DATABASE);

function isHustroDbEntityTable(
  name: string
): name is HustroDbEntityTablesUnion {
  return ENTITY_DATABASE_NAMES.includes(name);
}

const clearDb = wrapQuery(
  db,
  (tableToClear: Table): PromiseExtended<void> => {
    if (isHustroDbEntityTable(tableToClear.name)) {
      return ENTITY_DATABASE[tableToClear.name]();
    }
    return tableToClear.clear().catch((e) => {
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: 'Could not clear database table',
        errorObj: e,
        additionalInfo: null,
      });
    });
  }
);

const flush = wrapQuery(db, (): Promise<any> => {
  const promises: Promise<any>[] = [];
  db.tables.forEach((table) => {
    debugLog('flushing table:', table);

    const name = table.core.name;
    // @ts-ignore don't know the exact type of db
    db[name] && promises.push(clearDb(db[name]));
  });
  return Promise.all(promises);
});

export const flushProjectData = wrapQuery(db, (): Promise<any> => {
  const clearPromises: Promise<any>[] = [];
  db.tables.forEach((table) => {
    const name = table.core.name as HustroDbTablesUnion;
    if (
      !db[name] ||
      SKIP_ON_PROJECT_CLEAR.includes(name) ||
      // services are cleared when entity repository is cleared
      name.endsWith('Service')
    ) {
      return;
    }
    clearPromises.push(clearDb(db[name]));
  });
  return Promise.all(clearPromises);
});

export const flushTableWithService = wrapQuery(
  db,
  (tableName: HustroDbEntityTablesUnion): Promise<any> => {
    return clearDb(db[tableName]);
  }
);

export const flushData = wrapQuery(db, (): Promise<any> => {
  const clearPromises: Promise<any>[] = [];
  db.tables.forEach((table) => {
    const name = table.core.name as HustroDbTablesUnion;
    if (!SKIP_ON_CLEAR.includes(name) && db[name]) {
      clearPromises.push(clearDb(db[name]));
    }
  });
  return Promise.all(clearPromises);
});

export default flush;
