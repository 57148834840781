import {
  useIssueCount,
  withIssueCount,
} from 'components/dataProviders/withIssueCount';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { useCreateStore, Store } from 'hooks/createStore';
import { useGetIssuesFiltered } from 'hooks/useGetIssuesFiltered';
import { useMountedRef } from 'hooks/useMountRef';
import {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { activeFiltersSelector } from 'redux/selectors/issues';
import { projectDataSelector } from 'redux/selectors/project';
import { FilterMapped, standariseFilters } from 'shared/utils/filters';
import { useIssueFilters } from '../withIssueFilters';

type IssuesOnMapContextType = {
  issuesStore: Store<IssueModel[]>;
  setLevels: (map: string | undefined) => void;
};

const IssuesOnMapContext = createContext<
  IssuesOnMapContextType | undefined
>(undefined);

const _WithIssuesOnMap: FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const mountedRef = useMountedRef();
  const issuesStore = useCreateStore<IssueModel[]>([]);
  const { filters, archived } = useIssueFilters();
  const archivedRef = useRef(archived);
  const filtersStore = useCreateStore<FilterMapped[]>(
    standariseFilters(activeFiltersSelector(filters))
  );
  const levelsStore = useCreateStore<string | undefined>(undefined);
  const { timezone } = useSelector(projectDataSelector);
  const { get: getIssuesFiltered } = useGetIssuesFiltered();
  const { setCount, setLoading: setIssueCountLoading } = useIssueCount();

  const synchronizeIssuesOnMap = useCallback(() => {
    const levelsState = levelsStore.get();
    const archivedState = archivedRef.current;
    if (!levelsState) {
      return;
    }
    let levels;
    try {
      levels = JSON.parse(levelsState);
    } catch (e) {
      return;
    }

    const filtersState = filtersStore.get();
    const filtersStringified = JSON.stringify({
      filters: [
        {
          path: 'primaryData.level',
          type: 'direct',
          value: levels,
        },
        ...filtersState,
      ],
      search: [],
      archived: archivedState,
      timezone,
    });
    setIssueCountLoading(true);

    getIssuesFiltered(filtersStringified)
      .then((issuesResponse) => {
        if (!mountedRef.current) {
          return;
        }
        if (
          filtersState !== filtersStore.get() ||
          levelsState !== levelsStore.get() ||
          archivedState !== archivedRef.current
        ) {
          return;
        }
        issuesStore.set(issuesResponse.items);
        setCount(issuesResponse.total);
        setIssueCountLoading(false);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        if (
          filtersState !== filtersStore.get() ||
          levelsState !== levelsStore.get() ||
          archivedState !== archivedRef.current
        ) {
          return;
        }
        setCount(0);
        setIssueCountLoading(false);
        displayGenericErrorToaster(dispatch);
      });
  }, [
    dispatch,
    filtersStore,
    getIssuesFiltered,
    issuesStore,
    levelsStore,
    mountedRef,
    setCount,
    setIssueCountLoading,
    timezone,
  ]);

  useEffect(() => {
    archivedRef.current = archived;
    synchronizeIssuesOnMap();
  }, [archived, synchronizeIssuesOnMap]);

  useEffect(() => {
    filtersStore.set(standariseFilters(activeFiltersSelector(filters)));
  }, [filters, filtersStore]);

  useEffect(() => {
    return filtersStore.subscribe(() => {
      synchronizeIssuesOnMap();
    });
  }, [filtersStore, synchronizeIssuesOnMap]);

  useEffect(() => {
    return levelsStore.subscribe(() => {
      synchronizeIssuesOnMap();
    });
  }, [levelsStore, synchronizeIssuesOnMap]);

  const ctx: IssuesOnMapContextType = useMemo(() => {
    return {
      issuesStore,
      setLevels: levelsStore.set,
    };
  }, [issuesStore, levelsStore]);

  return (
    <IssuesOnMapContext.Provider value={ctx}>
      {children}
    </IssuesOnMapContext.Provider>
  );
};

const WithIssuesOnMap = withIssueCount(_WithIssuesOnMap);

const withIssuesOnMap =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithIssuesOnMap>
      <Component {...props} />
    </WithIssuesOnMap>
  );

function useIssuesOnMap(): IssuesOnMapContextType {
  const context = useContext(IssuesOnMapContext);
  if (context === undefined) {
    throw new Error(
      'useIssuesOnMap must be used within a IssuesOnMapContextProvider'
    );
  }
  return context;
}

export { useIssuesOnMap, WithIssuesOnMap, withIssuesOnMap };
