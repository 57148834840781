import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  checkProcessesEquality,
  toProcessesObject,
} from 'redux/selectors/processes';
import { InspectionTemplateItem } from './types';
import { toInspectionTemplateItem } from './model';
import { useRedirectContext } from '../../common/withRedirect';
import {
  useInspectionTemplate,
  withInspectionTemplate,
} from '../withInspectionTemplate';
import { DocumentsOnTemplateView } from 'presentation/document/documentsOnTemplateView';

type RequiredTemplateContextType = {
  template: InspectionTemplateItem | undefined;
  inspectionTemplateDocuments: DocumentsOnTemplateView;
  loading: boolean;
};

const RequiredInspectionTemplateContext = React.createContext<
  RequiredTemplateContextType | undefined
>(undefined);

type TemplateContainer = {
  template?: InspectionTemplateItem;
  templateId?: string;
};

const WithRequiredInspectionTemplate: React.FC<
  PropsWithChildren<{
    onTemplateFailed?: () => void;
  }>
> = withInspectionTemplate(({ children, onTemplateFailed }) => {
  const history = useHistory<TemplateContainer>();
  const stateTemplate = history.location.state?.template;
  const stateTemplateId = history.location.state?.templateId;
  const { templateId: paramsTemplateId } = useParams<{
    templateId: string;
  }>();
  const locationTemplateId = stateTemplateId || paramsTemplateId;

  const { redirect, setCanRedirect, setRedirection } =
    useRedirectContext();
  const dispatch = useDispatch();
  const processes = useSelector(toProcessesObject, checkProcessesEquality);
  const [template, _setTemplate] = useState<
    InspectionTemplateItem | undefined
  >(stateTemplate);

  const templateId = locationTemplateId || template?._id;

  const {
    inspectionTemplate,
    inspectionTemplateDocuments,
    loading,
    setId: setIdForListener,
  } = useInspectionTemplate();

  useEffect(() => {
    if (templateId) {
      setIdForListener(templateId);
    }
    // run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (inspectionTemplate) {
      _setTemplate(
        toInspectionTemplateItem(processes, inspectionTemplate)
      );
    }
  }, [dispatch, inspectionTemplate, processes]);

  useEffect(() => {
    if (!stateTemplate && !templateId) {
      if (onTemplateFailed) {
        onTemplateFailed();
      } else {
        setCanRedirect(true);
        setRedirection({ path: '/inspectionTemplate', state: undefined });
        redirect();
      }
    }
    // run once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ctx = {
    template,
    inspectionTemplateDocuments,
    loading: loading,
  };

  return (
    <RequiredInspectionTemplateContext.Provider value={ctx}>
      {children}
    </RequiredInspectionTemplateContext.Provider>
  );
});

export function useRequiredInspectionTemplate(): RequiredTemplateContextType {
  const context = React.useContext(RequiredInspectionTemplateContext);
  if (context === undefined) {
    throw new Error(
      'useRequiredInspectionTemplate must be used within a RequiredInspectionTemplateContextProvider'
    );
  }
  return context;
}

export const withRequiredInspectionTemplate =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithRequiredInspectionTemplate>
      <Component {...props} />
    </WithRequiredInspectionTemplate>
  );
