import { LevelModel } from 'shared/domain/level/types/model';
import { LabelledEntity } from 'shared/types/commonView';

export function findDocumentationOnLevelsAndSites(
  levels: LevelModel[],
  sites: LabelledEntity[],
  documentationId: string | undefined,
  versionId: string | undefined
): { levelsValue: LabelledEntity[]; sitesValue: LabelledEntity[] } {
  const siteIds: string[] = [];

  const levelsValue = levels.reduce(
    (result: LabelledEntity[], level): LabelledEntity[] => {
      const isDocumentationOnLevel = level.maps.some((map) => {
        return (
          map.documentationId === documentationId &&
          map.versionId === versionId
        );
      });
      if (isDocumentationOnLevel) {
        result.push({ _id: level._id, label: level.label });
        siteIds.push(level.site);
      }

      return result;
    },
    [] as LabelledEntity[]
  );

  const sitesValue = sites.reduce(
    (result: LabelledEntity[], site: LabelledEntity): LabelledEntity[] => {
      if (siteIds.includes(site._id)) {
        result.push({ _id: site._id, label: site.label });
      }
      return result;
    },
    []
  );

  return { levelsValue, sitesValue };
}
