import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import classNames from 'classnames';
import { useStyles } from './styles';

import {
  InspectionStatusLabel,
  InspectionStatusProps,
  InspectionStatusSize,
} from './types';

function InspectionStatus(props: InspectionStatusProps): ReactElement {
  const classes = useStyles();
  const intl = useIntl();
  const { status, size } = props;

  return (
    <div
      className={classNames(
        classes.inspectionStatus,
        classes[size || InspectionStatusSize.small],
        classes[status]
      )}
    >
      {intl.formatMessage({ id: InspectionStatusLabel[status] })}
    </div>
  );
}

export const MemoInspectionStatus = React.memo(InspectionStatus);
