import { ApexOptions } from 'apexcharts';
import { IntlShape } from 'react-intl';

export function aggregateSeries(
  series: number[],
  maxSeriesLength: number
): number[] {
  if (series.length <= maxSeriesLength || maxSeriesLength < 1) {
    return series;
  }

  const aggregatedSum = series
    .slice(maxSeriesLength - 1)
    .reduce((prev, curr) => prev + curr, 0);

  return [...series.slice(0, maxSeriesLength - 1), aggregatedSum];
}

export function createOptionsWithAggregatedLabels(
  options: ApexOptions,
  intl: IntlShape,
  maxLabelsLength: number
): ApexOptions {
  const labels = options.labels;

  if (
    labels !== undefined &&
    labels.length > maxLabelsLength &&
    maxLabelsLength >= 1
  ) {
    return {
      ...options,
      labels: [
        ...labels.slice(0, maxLabelsLength - 1),
        intl.formatMessage({ id: 'chart_legend_other' }),
      ],
    };
  }

  return options;
}

export function mutatingTranslateLabels(
  options: ApexOptions,
  intl: IntlShape
): void {
  const labels = options.labels;
  if (labels) {
    const translated = labels.map((theLabel) => {
      if (typeof theLabel !== 'string') {
        return intl.formatMessage({
          id: (theLabel as { labelId: string }).labelId,
        });
      }
      return theLabel;
    });
    options.labels = translated;
  }
}
