import { Box, ClickAwayListener } from '@mui/material';
import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import {
  HIGHEST_LOCATION_ZINDEX,
  MAXIMUM_LOCATION_AREAS,
} from 'components/dataProviders/withLocation/constants';
import { coordinatesToPercentageString } from 'components/location/area/common';
import { useClickDetection } from 'hooks/clickDetection/useClickDetection';
import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Color } from 'shared/utils/colors';
import { IndexMarkerSvg } from '../../documentation/DocumentationMapOverlay/IndexMarkerSvg';
import { InteractiveSelectedPinIcon } from './InteractiveSelectedPinIcon';
import { InteractiveUnselectedPinIcon } from './InteractiveUnselectedPinIcon';
import {
  getScaledPinHeight,
  useScaledPinWidth,
  getViewBox,
  pinTransition,
} from './common';
import useIsMobile from 'hooks/useIsMobile';

export function SelectablePin({
  x,
  y,
  color,
  _id,
  tooltip,
  addIndexMarker,
}: {
  x: number;
  y: number;
  color: Color;
  _id: string;
  tooltip: string;
  addIndexMarker: boolean;
}): ReactElement {
  const { selectedStore, select } = useSelectedStoreContext();
  const [isSelected, setIsSelected] = useState(
    selectedStore.get() === _id
  );
  const isMobile = useIsMobile();

  useEffect(() => {
    setIsSelected(selectedStore.get() === _id);
    return selectedStore.subscribe(() => {
      setIsSelected(selectedStore.get() === _id);
    });
  }, [selectedStore, _id, setIsSelected]);
  const width = useScaledPinWidth({ isSelected, enlarged: isMobile });
  const height = getScaledPinHeight(width, isSelected);

  const onClick: MouseEventHandler = useCallback(
    () => select(_id),
    [select, _id]
  );

  const viewBox = getViewBox(isSelected);

  const left = coordinatesToPercentageString(x);
  const top = coordinatesToPercentageString(y);
  const [isHovered, setIsHovered] = useState(false);
  const onMouseOver = useCallback(
    () => !isSelected && setIsHovered(true),
    [isSelected, setIsHovered]
  );
  const onMouseLeave = useCallback(
    () => !isSelected && setIsHovered(false),
    [isSelected, setIsHovered]
  );
  const { handleMouseDown, handleMouseMove, handleMouseUp } =
    useClickDetection(onClick);

  const onClickAway = useCallback(() => {
    setIsHovered(false);
    isSelected && select(undefined);
  }, [isSelected, select]);

  const currentColor = isHovered || isSelected ? Color.purple : color;
  // ClickAwayListener needs this Box
  // https://stackoverflow.com/questions/67832882/clickawaylistener-component-not-working-with-dragdropcontext
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <svg
          style={{
            width,
            height,
            position: 'absolute',
            left,
            top,
            //moves svg half left and full height up
            //so the reference point is on the middle of bottom edge instead of top left corner
            transform: `translate(-50%, -100%)`,
            transition: pinTransition,
            // highest z-index puts selected pin always on front
            zIndex: isSelected ? HIGHEST_LOCATION_ZINDEX : 'unset',
          }}
          // pointerEvents noticed on lower level (<g>) are more precise
          // if you set pointerEvents="all" here, cursor changes to pointer even when it's not exactly above visible pin but fits in the svg viewbox
          pointerEvents='none'
          data-qa='documentation-pin'
          preserveAspectRatio='xMidYMax'
          viewBox={viewBox}
        >
          {isSelected && (
            <InteractiveSelectedPinIcon
              tooltip={tooltip}
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
            />
          )}
          {!isSelected && (
            <InteractiveUnselectedPinIcon
              handleMouseDown={handleMouseDown}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
              onMouseOver={onMouseOver}
              onMouseLeave={onMouseLeave}
              color={currentColor}
              tooltip={tooltip}
            />
          )}
        </svg>

        {addIndexMarker && (
          <IndexMarkerSvg
            color={currentColor}
            id={_id}
            width={width}
            left={left}
            top={top}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseLeave={onMouseLeave}
            drawLineFrom='bottom'
            zIndex={
              // highest z-index puts selected pin always on front
              isSelected || isHovered
                ? MAXIMUM_LOCATION_AREAS + 1
                : 'unset'
            }
            tooltip={tooltip}
          />
        )}
      </Box>
    </ClickAwayListener>
  );
}
