import { Theme as DefaultTheme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  (theme: DefaultTheme) =>
    createStyles({
      card: {
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
        width: ({ isMobile }): string =>
          isMobile ? 'calc(100% - 64px)' : '289px',
        left: '0px',
        top: '0px',
        borderRadius: '3px',
        marginLeft: '16px',
        marginRight: '16px',
        backgroundColor: '#FFFFFF',
        marginTop: '7px',
        // paddings are 2px smaller than on figma because of border 2px.
        paddingLeft: '9px',
        paddingRight: '15px',
        paddingTop: '11px',
        paddingBottom: '14px',
        border: '2px solid #FFFFFF',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.15)',
        transition:
          'box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        '&:hover': {
          boxShadow:
            '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
        },
        '&.active': {
          borderColor: theme.palette.primary.main,
        },
        '&.additional': {
          opacity: 0.6,
        },
      },
      cardTitle: {
        fontFamily: 'Archivo',
        fontSize: '0.875rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '17px',
        letterSpacing: '0em',
        textAlign: 'left',
        margin: 0,
        marginBottom: '18px',
      },
      cardBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      cardBottomGroup: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      cardChip: {
        height: '19px',
        borderRadius: '20px',
        marginLeft: '8px',
        '& span': {
          fontFamily: 'Archivo',
          fontSize: '0.625rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '11px',
          letterSpacing: '0em',
          textAlign: 'center',
        },
      },
    })
);
