import { useIsOffline } from 'components/common/withIsOffline';
import {
  useRef,
  useState,
  Fragment,
  useContext,
  useCallback,
  createContext,
  PropsWithChildren,
} from 'react';
import Dialog, { DialogOptions } from './Dialog';

export type DialogFactory = (options: DialogOptions) => Promise<void>;

const DialogServiceContext = createContext<DialogFactory>(Promise.resolve);

export function useDialog(): DialogFactory {
  const context = useContext(DialogServiceContext);
  if (context === undefined) {
    throw new Error(
      'useDialog must be used within an DialogServiceContextProvider'
    );
  }
  return context;
}

const DialogContext = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const [dialogState, setDialogState] = useState<DialogOptions | null>(
    null
  );
  const [isOpen, setIsOpen] = useState(false);
  const isOffline = useIsOffline();

  const awaitingPromiseRef = useRef<{
    resolve: (data?: any) => void;
    reject: (error?: any) => void;
  }>({
    resolve: Promise.resolve,
    reject: Promise.reject,
  });

  const openDialog = useCallback(
    (options: DialogOptions): Promise<void> => {
      setDialogState(options);
      setIsOpen(true);

      return new Promise<void>((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject };
      });
    },
    []
  );

  const handleClose = useCallback((): void => {
    setIsOpen(false);
    const promise = awaitingPromiseRef?.current;
    setDialogState((prev) => {
      if (prev && prev.catchOnCancel && promise) {
        promise.reject();
      }
      return { ...prev };
    });
  }, []);

  const handleSubmit = useCallback((): void => {
    setIsOpen(false);
    if (isOffline && dialogState?.rejectOnOffline) {
      awaitingPromiseRef.current.reject(new Error('offline'));
    } else {
      awaitingPromiseRef.current.resolve();
    }

    setDialogState((prev) => {
      return { ...prev };
    });
  }, [isOffline, dialogState]);

  return (
    <Fragment>
      <DialogServiceContext.Provider
        value={openDialog}
        children={children}
      />
      <Dialog
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...dialogState}
      />
    </Fragment>
  );
};

export default DialogContext;
