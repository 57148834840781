import { SiteInDto } from 'shared/dtos/in/site';
import { SiteCreateOutDto, SiteEditOutDto } from 'shared/dtos/out/site';

export interface ISiteUploader {
  POST: (url: string, data: any) => Promise<any>;
  PUT: (url: string, data: any) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadSiteChanges {
  (
    uploader: ISiteUploader,
    body: SiteEditOutDto,
    url: string
  ): Promise<unknown>;
}

export interface UploadSite {
  (
    uploader: ISiteUploader,
    body: SiteCreateOutDto,
    url: string
  ): Promise<SiteInDto>;
}

export interface UploadSiteDelete {
  (uploader: ISiteUploader, url: string): Promise<unknown>;
}

export interface UploadSiteRestore {
  (uploader: ISiteUploader, url: string): Promise<unknown>;
}

export function uploadSiteChanges(
  uploader: ISiteUploader,
  body: SiteEditOutDto,
  url: string
): Promise<unknown> {
  return uploader.PUT(url, body);
}

export function uploadSite(
  uploader: ISiteUploader,
  body: SiteCreateOutDto,
  url: string
): Promise<SiteInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadSiteDelete(
  uploader: ISiteUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}

export function uploadSiteRestore(
  uploader: ISiteUploader,
  url: string
): Promise<unknown> {
  return uploader.GET(url);
}
