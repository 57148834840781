import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { broadcastAllHazardCategories } from 'serviceWorker/broadcasts/hazardCategories';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as hazardCategories from 'serviceWorker/db/hazardCategories';
import * as hazardCategoriesService from 'serviceWorker/db/hazardCategoriesService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import {
  fetchHazardCategories,
  fetchUpdatedHazardCategories,
} from './fetchHazardCategories';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullHazardCategories: Pull = makePullEntity({
  fetch: fetchHazardCategories,
  channelName: ChannelNames.hazardCategoryChannel,
  entityName: 'hazardCategories',
  entityRepository: hazardCategories,
  entityService: hazardCategoriesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullHazardCategoriesHandler = makePullHandler(
  config,
  hazardCategories,
  hazardCategoriesService,
  pullHazardCategories,
  clearBroadcasts
);

const synchronizeHazardCategories = makeSynchronizeEntity({
  configRepository: config,
  entityService: hazardCategoriesService,
  entityRepository: hazardCategories,
  pullEntityHandler: pullHazardCategoriesHandler,
  fetchUpdatedEntities: fetchUpdatedHazardCategories,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'hazardCategories',
  channelName: ChannelNames.hazardCategoryChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeHazardCategories.bind(null, broadcastAllHazardCategories),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncHazardCategories,
    syncDebounced
  );
};
