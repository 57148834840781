import { InspectionTemplateItem } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { INSPECTION_TEMPLATE_CARD_PREFIX } from 'components/template/Card';

import { TemplateListError, TemplateListErrorText } from './types';

import { useBacktrack } from 'components/common/withBacktrack';
import { InspectionTemplateInDto } from 'shared/dtos/in/template';
import { useGetOneInspectionTemplate } from 'hooks/useGetInspectionTemplate';
import useIsMobile from 'hooks/useIsMobile';
import { useMountedRef } from 'hooks/useMountRef';
import qs from 'query-string';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { Identificable } from 'shared/types/commonView';

function findLastClickedItem(
  lastClickedItemId: string | null,
  list: InspectionTemplateItem[]
): InspectionTemplateItem | undefined {
  return list.find((item) => item._id === lastClickedItemId);
}

function scrollToElement(element: Element | null): void {
  element &&
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
}

export function scrollToActiveCard(id: string): void {
  scrollToElement(
    document.querySelector(`#${INSPECTION_TEMPLATE_CARD_PREFIX}-${id}`)
  );
}

function createTemplateError(
  error: TemplateListErrorText
): TemplateListError {
  return {
    templateErrorType: error,
    helperText: error + '_helper_text',
  };
}

export const NOT_FOUND_ERROR = createTemplateError(
  TemplateListErrorText.notFound
);

export const NO_TEMPLATES_ERROR = createTemplateError(
  TemplateListErrorText.noTemplates
);
export const NO_FILTERED_TEMPLATES_ERROR = {
  templateErrorType: TemplateListErrorText.noFilteredTemplates,
  helperText: 'list_search_empty_subtitle',
};

type LastSelectedProps = {
  templates: InspectionTemplateItem[];
  projectId: string;
};
export function useLastSelectedInspectionTemplate({
  templates,
  projectId,
}: LastSelectedProps): {
  lastSelected: InspectionTemplateItem | undefined;
  loading: boolean;
} {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();
  const [lastSelected, setLastSelected] = useState<
    InspectionTemplateItem | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [lastSelectedDto, setLastSelectedDto] = useState<
    InspectionTemplateInDto | undefined
  >(undefined);

  const setTemplate = useCallback((template: any) => {
    setLastSelectedDto(template);
  }, []);

  const { get: getInspectionTemplate } = useGetOneInspectionTemplate();

  useEffect(() => {
    if (!projectId) {
      return;
    }
    const id = localStorage.getItem(`viewed_template_${projectId}`);
    if (!id || id === 'undefined') {
      setLoading(false);
      return;
    }

    const lastPreviewedItem = findLastClickedItem(id, templates);
    if (lastPreviewedItem) {
      setLastSelected(lastPreviewedItem);
      setLoading(false);
      return;
    }

    getInspectionTemplate(id)
      .then((templateModel) => {
        if (!mountedRef.current) {
          return;
        }
        setTemplate(templateModel);
        setLoading(false);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        displayGenericErrorToaster(dispatch);
      });
  }, [
    templates,
    projectId,
    setTemplate,
    getInspectionTemplate,
    dispatch,
    mountedRef,
  ]);

  useEffect(() => {
    if (lastSelectedDto) {
      const id = lastSelectedDto._id;
      const lastClickedItem = findLastClickedItem(id, templates);
      if (lastClickedItem) {
        setLastSelected(lastClickedItem);
        setLoading(false);
      }
    }
  }, [lastSelectedDto, templates]);

  return {
    lastSelected,
    loading,
  };
}

export function canFindOnTemplatesList(
  templates: Identificable[],
  selected: string
): boolean {
  return templates.some((template) => template._id === selected);
}

const LOCAL_STORAGE_TEMPLATE_PREFIX = 'viewed_template';
export function getLastSelectedTemplateId(
  projectId: string
): string | null {
  return localStorage.getItem(
    `${LOCAL_STORAGE_TEMPLATE_PREFIX}_${projectId}`
  );
}

export function setLastSelectedTemplateId(
  projectId: string,
  value: string
): void {
  localStorage.setItem(
    `${LOCAL_STORAGE_TEMPLATE_PREFIX}_${projectId}`,
    value
  );
}

type UseShowList = {
  setObserverOn: () => void;
  showList: boolean;
  setShowList: Dispatch<SetStateAction<boolean>>;
};

export function useShowList(
  activeTemplateId: string | undefined,
  activeRef: { current: string | undefined },
  searchPhrase: string,
  loading: boolean
): UseShowList {
  const prevSearchPhraseRef = useRef<string | undefined>('');
  const history = useHistory();
  const { projectId: urlProjectId, id: idInRoute } = useParams<{
    id: string;
    projectId?: string;
  }>();
  const urlPrefix = useMemo(() => {
    return urlProjectId ? `/project/${urlProjectId}` : '';
  }, [urlProjectId]);
  const isMobile = useIsMobile();
  const [observing, setObserving] = useState<boolean>(false);
  const { back: goBack } = useBacktrack();

  const location = useLocation();
  const { preview } = qs.parse(location.search);
  const [showList, setShowList] = useState(
    location.pathname.includes('document') ||
      location.pathname.includes('checklist')
      ? false
      : preview !== 'true'
  );

  const setObserverOn = useCallback(() => {
    if (isMobile) {
      setObserving(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (loading) {
      return;
    }
    if (idInRoute && !activeRef.current) {
      if (!showList) {
        goBack();
      }
    }
  }, [
    activeTemplateId,
    activeRef,
    idInRoute,
    activeTemplateId,
    history,
    showList,
    goBack,
    urlPrefix,
    loading,
  ]);

  useEffect(() => {
    if (!activeRef.current || loading) {
      return;
    }

    const shouldIdInRouteChange =
      idInRoute && idInRoute !== activeRef.current;

    if (!idInRoute || shouldIdInRouteChange) {
      history.replace(
        `${urlPrefix}/inspectionTemplate/${activeRef.current}`
      );
    }
  }, [
    history,
    activeRef,
    idInRoute,
    urlPrefix,
    loading,
    activeTemplateId,
  ]);

  useEffect(() => {
    if (!observing || !activeRef.current || !isMobile || loading) {
      return;
    }

    history.push(
      `${urlPrefix}/inspectionTemplate/${
        activeRef.current
      }?preview=${!showList}`
    );
    setObserving(false);
  }, [
    activeTemplateId,
    observing,
    history,
    activeRef,
    activeTemplateId,
    isMobile,
    showList,
    urlPrefix,
    loading,
  ]);

  useEffect(() => {
    const preview = prevSearchPhraseRef.current === searchPhrase;

    if (!preview) {
      setShowList(true);
      prevSearchPhraseRef.current = searchPhrase;
    }
  }, [searchPhrase, activeTemplateId, history]);

  useEffect(() => {
    return history.listen((location, type) => {
      if (type !== 'PUSH') {
        return;
      }
      const { preview } = qs.parse(location.search);
      setShowList(preview !== 'true');
    });
  }, [history]);

  return { setObserverOn, showList, setShowList };
}
