import { DocumentModel } from 'shared/domain/document/documentModel';
import { addToHashMap } from 'helpers/mappings';
import { modifiedAtSort } from 'helpers/sort';
import { HashMap } from 'shared/types/commonView';
import { documentModelToDocumentOnView } from './documentModelToDocumentOnView';
import { DocumentOnView } from './documentOnView';
import { isDocumentRemoteAndUnavailable } from './documentRemoteAndUnavailable';

export type DocumentsOnInspectionView = HashMap<DocumentOnView[]>;
export function documentsToDocumentsOnInspectionView(
  documents: DocumentModel[]
): DocumentsOnInspectionView {
  const protocolDocuments: HashMap<DocumentOnView[]> = {};

  documents.sort(modifiedAtSort).forEach((document) => {
    if (document.deleted) {
      return;
    }

    if (isDocumentRemoteAndUnavailable(document)) {
      return;
    }

    const documentOnInspectionView =
      documentModelToDocumentOnView(document);
    if (!documentOnInspectionView) {
      return;
    }
    if (document.protocolId) {
      addToHashMap(
        protocolDocuments,
        documentOnInspectionView,
        document.protocolId
      );
    }
  });

  return protocolDocuments;
}
