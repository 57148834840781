import { logWithRethrow } from '../db/helpers';
import { LogLevel } from 'shared/types/logger';
import { HttpError } from './httpError';
import { getToken } from 'serviceWorker/services/config';
import {
  NormalizedEntityResponseData,
  NormalizedPagedEntityResponseData,
  HeadersData,
} from 'serviceWorker/services/factories/types';

type ResponseToNormalize<T = unknown> = NormalizedEntityResponseData<T> & {
  [key: string]: any[];
};

type PagedResponseToNormalize<T = unknown> =
  NormalizedPagedEntityResponseData<T> & {
    [key: string]: any[];
  };

export const parseResponse = <T = unknown>(
  response: Response
): Promise<T> => {
  if (response.ok) {
    return response.json().catch((e) =>
      logWithRethrow({
        msg: 'Could not parse response',
        logLevel: LogLevel.ERROR,
        errorObj: e,
        additionalInfo: {
          response: response,
        },
      })
    );
  }
  throw new HttpError('Something went wrong', response.status);
};

export function makeNormalizeResponse<T = unknown>(
  key: string
): (response: Response) => Promise<NormalizedEntityResponseData<T>> {
  return function parseWithNormalizeResponse(
    response: Response
  ): Promise<NormalizedEntityResponseData<T>> {
    return parseResponse<ResponseToNormalize<T>>(response).then(
      (responseToNormalize: ResponseToNormalize<T>) => {
        if (key) {
          responseToNormalize.items = responseToNormalize[key];
        }
        return responseToNormalize;
      }
    );
  };
}

export function makeNormalizePagedResponse<T = unknown>(
  key: string
): (response: Response) => Promise<NormalizedPagedEntityResponseData<T>> {
  return function parseWithNormalizeResponse(
    response: Response
  ): Promise<NormalizedPagedEntityResponseData<T>> {
    return parseResponse<PagedResponseToNormalize<T>>(response).then(
      (responseToNormalize: PagedResponseToNormalize<T>) => {
        if (key) {
          responseToNormalize.items = responseToNormalize[key];
        }
        return responseToNormalize;
      }
    );
  };
}

export const defaultConfig: RequestInit = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
};

export const generateHeaders = (
  data: Pick<HeadersData, 'locale' | 'frontendVersion'>
): {
  [key: string]: string;
} => {
  const token = getToken();

  if (!token) {
    throw new Error(`Token undefined.`);
  }
  return {
    'Content-Type': 'application/json',
    'Accept-Language': data.locale,
    Authorization: `Bearer ${token}`,
    'hustro-client': 'web',
    'hustro-client-version': data.frontendVersion,
  };
};
