import { Identificable } from 'shared/types/commonView';

// ts reports Array.findLastIndex as undefined.
export function findLastIndex<T>(
  predicate: (element: T, index: number, array: T[]) => boolean,
  array: T[]
): number {
  const arrayReversed = [...array].reverse();
  let index = arrayReversed.length - 1;
  for (const element of arrayReversed) {
    const predicateResult = predicate(element, index, arrayReversed);
    if (predicateResult) {
      return index;
    }
    index -= 1;
  }
  return -1;
}

export function filterLanugagesToChoose(values: string[]) {
  return (entity: Identificable) => {
    return !values.includes(entity._id);
  };
}

export function getLanguageSelectDisabledReason({
  isHustroDefault,
  isRemote,
  hasNext,
}: any): string | undefined {
  if (isHustroDefault) {
    return 'disabled_cannot_change_hustro_default';
  }
  if (isRemote) {
    return 'disabled_cannot_change_saved_translation';
  }
  if (hasNext) {
    return 'disabled_cannot_change_language_after_add_next';
  }
}

export function getLabelChangeDisabledReason({
  isHustroDefault,
}: any): string | undefined {
  if (isHustroDefault) {
    return 'disabled_cannot_change_hustro_default';
  }
}
