import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { organizationEditModelToEditOutDto } from 'shared/domain/organization/mapping/toDto';
import { OrganizationEditModel } from 'shared/domain/organization/types/model';
import { EditOrganizationUseCase } from 'shared/domain/organization/types/useCases';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { OrganizationHttpEditRequestModel } from '../httpQueue/organization/types';

export class OrganizationEditor implements EditOrganizationUseCase {
  constructor(
    private addRequest: (
      request: OrganizationHttpEditRequestModel
    ) => Promise<any>
  ) {}

  async execute(
    organizationEditModel: OrganizationEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditOrganizationUseCase', organizationEditModel);

    const organizationEditOutDto = organizationEditModelToEditOutDto(
      organizationEditModel
    );
    const url = `/client/${organizationEditModel._id}`;
    const request: OrganizationHttpEditRequestModel = {
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        organizationEditOutDto,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.organization,
      status: HttpRequestStatus.NEW,
      url,
    };

    await this.addRequest(request);
  }
}

export const organizationEditor = new OrganizationEditor(addRequest);
