import { PromiseExtended } from 'dexie';
import db from './index';
import { clear as sitesServiceClear } from './sitesService';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { broadcastClearSites } from 'serviceWorker/broadcasts/sites';
import { entityDbClearFactory } from './entityDbClearFactory';
import { SiteInDto } from 'shared/dtos/in/site';
import { Changes } from 'shared/types/commonEntities';
import {
  makeDefaultAddBatchMapped,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';
import { SiteEntity } from 'shared/domain/site/types/entity';
import { siteInDtoToEntity } from 'shared/domain/site/mapping/toEntity';

export const clear = entityDbClearFactory(
  db,
  ['sites', 'sitesService'],
  () => db.sites.clear(),
  sitesServiceClear,
  broadcastClearSites
);

export const quantity = makeDefaultCount<SiteEntity>(db, db.sites);

export const get = makeDefaultGetMany<SiteEntity>(db, db.sites);

export const getOne = makeDefaultGetOne<string, SiteEntity>(
  db,
  db.sites,
  '_id'
);

export const addBatch = makeDefaultAddBatchMapped<SiteInDto, SiteEntity>(
  db,
  db.sites,
  siteInDtoToEntity
);

export const updateBatch = addBatch;

export const updateOne = wrapQuery(
  db,
  (siteId: string, changes: Changes<SiteEntity>): PromiseExtended => {
    return db.sites.update(siteId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'sites'),
        errorObj: e,
        additionalInfo: { query: { data: { siteId, changes } } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<string, SiteEntity>(
  db,
  db.sites,
  '_id'
);
