import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

type ChecklistItemHeaderTextProps = {
  checklistItemTitle: string;
};
function ChecklistItemHeaderText(
  props: ChecklistItemHeaderTextProps
): ReactElement {
  const intl = useIntl();
  const { checklistItemTitle } = props;
  return (
    <div className='checklist-item-title'>
      <span
        style={{
          width: 0,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          flexGrow: 1,
          textOverflow: 'ellipsis',
        }}
      >
        {checklistItemTitle ? (
          checklistItemTitle
        ) : (
          <b>{intl.formatMessage({ id: 'controlled_element' })}</b>
        )}
      </span>
    </div>
  );
}

export const MemoChecklistItemHeaderText = React.memo(
  ChecklistItemHeaderText
);
