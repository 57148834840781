import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startSelectedProjectSynchronization(): void {
  debugLog('startSelectedProjectSynchronization');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.SELECTED_PROJECT,
    method: ServiceMethods.SYNC,
  });
  broadcast.close();
}
