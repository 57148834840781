import { withFetcherReady } from 'components/common/withFetcherReady';
import { useIsOffline } from 'components/common/withIsOffline';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoTableAnchor } from 'components/tableAnchor';
import { Permission, validateCreatePermission } from 'helpers/permission';
import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { toUserRole } from 'redux/selectors/role';
import { CREATE_USER_PERMISSIONS } from 'setup/permissions';
import { makeSearchInput } from 'components/common/SearchInput';
import { EntityLabel } from 'components/common/SearchInput/types';
import { useUsers, withUsers } from 'components/dataProviders/withUsers';
import { UserTable } from 'components/user/table';
import { useInitialSearchEffect } from '../../../hooks/search/useInitialSearch';
import { useSearchUpdate } from '../../../hooks/search/useSearchUpdate';

const UserSearchInput = makeSearchInput(EntityLabel.user);

const header = {
  id: 'create-user-button',
  textId: 'button_create_user',
  dataQaId: 'create-user-button',
};

function Users(): ReactElement {
  const history = useHistory();
  const isOffline = useIsOffline();
  const userRole = useSelector(toUserRole);
  const { loading, setSearchPhrase } = useUsers();

  const canCreate = useCallback((): Permission => {
    return validateCreatePermission(
      CREATE_USER_PERMISSIONS[userRole],
      isOffline
    );
  }, [userRole, isOffline]);

  const { _id: projectId } = useSelector(projectDataSelector);
  useSearchUpdate(EntityLabel.user, setSearchPhrase);
  useInitialSearchEffect(projectId, EntityLabel.user, setSearchPhrase);
  const userCreation = useCallback(() => {
    if (canCreate().permission) {
      history.push('/create-user');
    }
  }, [history, canCreate]);

  return (
    <MemoTableAnchor
      canCreate={canCreate}
      creationAction={userCreation}
      TableComponent={UserTable}
      loading={loading}
      headerWithCreation={header}
      SearchInput={UserSearchInput}
    />
  );
}

export const MemoUsers = React.memo(
  withFetcherReady(withProcessesData(withUsers(Users)))
);
