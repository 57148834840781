import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import { statusToLabel } from './helpers';
import {
  InspectionTemplateStatusProps,
  InspectionTemplateStatusSize,
} from './types';

function InspectionTemplateStatus(
  props: InspectionTemplateStatusProps
): ReactElement {
  const classes = useStyles();
  const intl = useIntl();
  const { status, size, displayArchived } = props;

  return (
    <div
      className={classNames(
        classes.templateStatus,
        classes[size || InspectionTemplateStatusSize.small],
        !displayArchived && classes[status]
      )}
    >
      {statusToLabel(intl.formatMessage, status)}
    </div>
  );
}

export const MemoInspectionTemplateStatus = React.memo(
  InspectionTemplateStatus
);
