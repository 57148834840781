import {
  correspondenceAddressStreetAndNumberAccessor,
  registeredAddressStreetAndNumberAccessor,
} from 'components/table/accessors';
import { SORT_TYPE } from 'shared/filter/sort/types';
import {
  correspondenceAddressMergeGroup,
  registeredAddressMergeGroup,
} from './mergeGroup';
import { ColumnShapeSkeleton } from '../../types';

const base = {
  initialWidth: 192,
  flexGrow: 0,
  flexShrink: 0,
  sortable: true,
  sortType: SORT_TYPE.STRING,
  labelId: 'address_street_and_number',
  style: {
    justifyContent: 'flex-start',
  },
};

export function getCorrespondenceStreetAndNumberColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: correspondenceAddressStreetAndNumberAccessor,
    dataKey: 'correspondenceAddress.streetAndNumber',
    mergeGroup: correspondenceAddressMergeGroup,
  };
}

export function getRegisteredStreetAndNumberColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: registeredAddressStreetAndNumberAccessor,
    dataKey: 'registeredOfficeAddress.streetAndNumber',
    mergeGroup: registeredAddressMergeGroup,
  };
}
