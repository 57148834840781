import { MutableRefObject, useCallback, useMemo, useRef } from 'react';

type EqualityCheck = (
  value: string,
  currentDirectoryStore: MutableRefObject<string>
) => boolean;
export type CurrentDirectoryStore = {
  get: () => string;
  set: (value: string, equalityCheck?: EqualityCheck) => void;
  subscribe: (callback: () => void) => () => void;
};
function defaultEqualityCheck(
  folderId: string,
  currentDirectoryStore: MutableRefObject<string>
): boolean {
  return (
    typeof folderId !== 'string' ||
    currentDirectoryStore.current === folderId
  );
}

export function useCurrentDirectoryStore(): CurrentDirectoryStore {
  const currentDirectoryStore = useRef('');
  const subscribers = useRef(new Set<() => void>());

  const get = useCallback(() => {
    return currentDirectoryStore.current;
  }, []);

  const set = useCallback(
    (folderId: string, customEqualityCheck?: EqualityCheck) => {
      const isEqual =
        typeof customEqualityCheck === 'function'
          ? customEqualityCheck
          : defaultEqualityCheck;
      if (isEqual(folderId, currentDirectoryStore)) {
        return;
      }
      currentDirectoryStore.current = folderId;
      subscribers.current.forEach((callback) => callback());
    },
    []
  );

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return useMemo(
    () => ({
      get,
      set,
      subscribe,
    }),
    [get, set, subscribe]
  );
}
