import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import {
  ArchiveDocumentationCustomEvent,
  CreateDocumentationCustomEvent,
  EditDocumentationCustomEvent,
  MoveDocumentationCustomEvent,
} from 'shared/domain/messages/documentation/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { DocumentationEntity } from 'serviceWorker/repository/documentation/entity';
import { documentationEntityToModel } from 'serviceWorker/repository/documentation/mappings';
import { broadcastHasAllDocumentations } from 'serviceWorker/broadcasts/documentations';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as documentations from 'serviceWorker/db/documentations';
import * as documentationsService from 'serviceWorker/db/documentationsService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullHandler } from '../factories/makePullHandler';
import { makePullPagedEntity } from '../factories/makePullPagedEntity';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import { getUsersSynchronized } from '../users/getUsers';
import { documenationSaver } from './addDocumentation';
import { documentationDeleter } from './deleteDocumentation';
import { documenationEditor } from './editDocumentation';
import {
  fetchDocumentationsPaged,
  fetchUpdatedDocumentations,
} from './fetchDocumentations';
import { documentationMover } from './moveDocumentation';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullDocumentations: Pull = makePullPagedEntity({
  fetchPaged: fetchDocumentationsPaged,
  channelName: ChannelNames.documentationChannel,
  entityName: 'documentations',
  entityRepository: {
    ...documentations,
    addBatch: documentations.fastAddBatch,
  },
  entityService: documentationsService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullDocumentationsHandler = makePullHandler(
  config,
  documentations,
  documentationsService,
  pullDocumentations,
  clearBroadcasts
);

const synchronizeDocumentations = makeSynchronizeEntity({
  configRepository: config,
  entityService: documentationsService,
  entityRepository: documentations,
  pullEntityHandler: pullDocumentationsHandler,
  fetchUpdatedEntities: fetchUpdatedDocumentations,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'documentations',
  channelName: ChannelNames.documentationChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export async function getDocumentation(
  id: number
): Promise<DocumentationModel> {
  const [documentation, users] = await Promise.all([
    documentations.getOne(id),
    getUsersSynchronized(),
  ]);
  if (!documentation) {
    throw new Error('Cannot find documentation with id ' + id);
  }
  return documentationEntityToModel(documentation, users);
}

export async function getDocumentations(
  ids: number[]
): Promise<DocumentationEntity[]> {
  return documentations.getByIds(ids);
}

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeDocumentations.bind(null, broadcastHasAllDocumentations),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncDocumentations,
    syncDebounced
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addDocumentation,
    async function onCreateDocumentation(
      e: CreateDocumentationCustomEvent
    ): Promise<void> {
      debugLog('addDocumentation event', e);
      documenationSaver.execute(e.detail.documentation, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editDocumentation,
    async function onEditDocumentation(
      e: EditDocumentationCustomEvent
    ): Promise<void> {
      debugLog('editDocumentation event', e);
      documenationEditor.execute(e.detail);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.setParent,
    async function onMoveDocumentation(
      e: MoveDocumentationCustomEvent
    ): Promise<void> {
      debugLog('moveDocumentation event', e);
      documentationMover.execute(
        e.detail.parentId,
        e.detail.documentations,
        e.detail.directories
      );
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteDocumentations,
    async function onDeleteDocumentation(
      e: ArchiveDocumentationCustomEvent
    ): Promise<void> {
      debugLog('deleteDocumentation event', e);
      documentationDeleter.execute(
        e.detail.documentations,
        e.detail.directories
      );
    }
  );
};
