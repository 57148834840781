import { ReactElement } from 'react';
import { documentationEmpty } from 'assets/icons';
import { InfoBanner } from 'components/general/InfoBanner';
import { openDocumentationInput } from 'shared/domain/documentation/openDocumentationInput';
import { ButtonContained, ButtonText } from 'components/general';
import { useIntl } from 'react-intl';
import { openDirectoryForm } from 'presentation/directory/openDirectoryInput';
import {
  EmptyListReason,
  EmptyTableInfo,
} from 'components/common/noTableContentInfo/NoTableContentInfo';

export function DocumentationEmpty({
  searchPhrase,
}: {
  searchPhrase: string;
}): ReactElement {
  const intl = useIntl();

  return searchPhrase ? (
    <EmptyTableInfo
      emptyTableReason={EmptyListReason.SEARCHPHRASE_MISMATCH}
    />
  ) : (
    <InfoBanner
      icon={documentationEmpty}
      messageId='documentation_empty_info'
      dataQa='documentation_empty'
    >
      <div
        style={{
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 0,
          justifyContent: 'center',
          gap: '0.75rem',
          maxWidth: 'max-content',
          alignItems: 'center',
        }}
      >
        <ButtonContained onClick={() => openDocumentationInput()}>
          {intl.formatMessage({ id: 'create_documentation' })}
        </ButtonContained>
        <p style={{ marginBottom: 0 }}>
          {intl.formatMessage({ id: 'general_or' })}
        </p>
        <ButtonText
          style={{ backgroundColor: '#FFF' }}
          onClick={() => openDirectoryForm()}
        >
          {intl.formatMessage({ id: 'create_directory' })}
        </ButtonText>
      </div>
    </InfoBanner>
  );
}
