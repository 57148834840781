export const NOT_APPLICABLE_FIELD_INDICATOR = 'N/A';

export const INVALID_SYNCKEY = 499;
export const VERSION_MISMATCH = 498;
export const REQUIRED_RESOURCE_NEEDS_AUTHORIZATION = 497;
export const TOKEN_EXPIRED = 496;
export const UNPROCESSABLE_ENTITY = 422;
export const CONFLICT = 409;
export const NOT_FOUND = 404;
export const FORBIDDEN = 403;
export const UNAUTHORIZED = 401;
export const BAD_REQUEST = 400;

export const OK = 200;
