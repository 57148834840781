import React from 'react';
import NavigationPrompt from './NavigationPrompt';
import LeaveDialog from 'components/core/LeaveDialog/LeaveDialog';

const NavigationController = (): React.ReactElement => {
  return (
    <NavigationPrompt>
      {({ isActive, onConfirm, onCancel }): React.ReactElement => (
        <LeaveDialog
          open={isActive}
          onCancel={onCancel}
          onConfirm={onConfirm}
        />
      )}
    </NavigationPrompt>
  );
};

export default NavigationController;
