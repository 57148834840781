import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { OnMessage } from '../types';
import { resyncCurrentProject } from './one';
import { broadcastState } from './state';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';

export const initProjectsListener = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.projectChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }
      case DomainMessagesTypes.getAllProjects: {
        self.dispatchEvent(
          new Event(RepositoryMessagesTypes.getAllProjects)
        );
        resyncCurrentProject();
        return;
      }
    }
  };

  broadcast.onmessage = handler;
};
