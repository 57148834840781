import { IssueWizardProps, SetableIssueFields } from './types';
import { ProcessType } from 'shared/types/form';
import { toLabelledEntity } from 'components/inspection/Form/model';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { HashMap } from 'shared/types/commonView';
import { Store } from 'hooks/createStore';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  nowTimezonedDateTime,
  setDayStart,
} from '../../../shared/utils/date/dates';
import { DateTime } from 'luxon';

const emptyIssue: SetableIssueFields = {
  process: undefined,
  site: undefined,
  level: undefined,
  title: undefined,
};

export function createInitialValues(
  initialValues: IssueWizardProps['initialValues'],
  processCode: IssueWizardProps['processCode'],
  processes: ProcessType[]
): SetableIssueFields {
  if (initialValues) {
    const matchingProcess = processes.find(
      (process) => process.code === processCode
    );

    const { site } = initialValues;

    return {
      ...emptyIssue,
      site,
      process: matchingProcess
        ? toLabelledEntity(matchingProcess)
        : undefined,
    };
  } else {
    return emptyIssue;
  }
}

export function addDetectionDate(
  fieldVisibilityStore: Store<HashMap<VisibleFieldModel[]>>,
  timezone: string,
  processId: string
): DateTime | undefined {
  const visibleFields = fieldVisibilityStore.get()[processId];

  const detectionDateField = visibleFields.find(
    (field) => field.fieldName === IssueFieldNames.detectionDate
  );
  if (detectionDateField)
    return setDayStart(nowTimezonedDateTime(timezone));

  return undefined;
}
