import { CompanyEntity } from 'shared/domain/company/types/entity';
import {
  CreateCompanyCustomEvent,
  EditCompanyCustomEvent,
  GetCompanyCustomEvent,
} from 'shared/domain/messages/company/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { broadcastAllCompanies } from 'serviceWorker/broadcasts/company/all';
import { broadcastCompany } from 'serviceWorker/broadcasts/company/one';
import { ChannelNames } from 'shared/domain/channelNames';
import * as companies from 'serviceWorker/db/companies';
import * as companiesService from 'serviceWorker/db/companiesService';
import * as config from 'serviceWorker/db/config';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { awaitEntities } from '../factories/awaitEntities';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import { waitForMessage } from '../factories/waitForMessage';
import { companySaver } from './addCompany';
import { companyEditor } from './editCompany';
import { fetchCompanies, fetchUpdatedCompanies } from './fetchCompanies';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const waitForCompanies = waitForMessage(
  ChannelNames.companyChannel,
  DomainMessagesTypes.allCompanies
);

const pullCompanies: Pull = makePullEntity({
  fetch: fetchCompanies,
  channelName: ChannelNames.companyChannel,
  entityName: 'companies',
  entityRepository: companies,
  entityService: companiesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullCompaniesHandler = makePullHandler(
  config,
  companies,
  companiesService,
  pullCompanies,
  clearBroadcasts
);

const synchronizeCompanies = makeSynchronizeEntity({
  configRepository: config,
  entityService: companiesService,
  entityRepository: companies,
  pullEntityHandler: pullCompaniesHandler,
  fetchUpdatedEntities: fetchUpdatedCompanies,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'companies',
  channelName: ChannelNames.companyChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export async function getCompaniesByIds(
  ids: string[]
): Promise<CompanyEntity[]> {
  await awaitEntities(companiesService, waitForCompanies);
  return companies.getByIds(ids);
}

export async function getCompanies(): Promise<CompanyEntity[]> {
  await awaitEntities(companiesService, waitForCompanies);
  return companies.get();
}

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeCompanies.bind(null, broadcastAllCompanies),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncCompanies,
    syncDebounced
  );
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getCompany,
    function onGetCompany(e: GetCompanyCustomEvent): void {
      synchronizeCompanies(broadcastCompany.bind(null, e));
    }
  );
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addCompany,
    function onAddCompany(e: CreateCompanyCustomEvent): void {
      debugLog('addCompany event', e);
      companySaver.execute(e.detail.companyCreateModel, e.detail.uniqueId);
    }
  );
};

// @ts-ignore ts does not like custom event here. Can't properly type it.
self.addEventListener(
  RepositoryMessagesTypes.editCompany,
  async function onEditLevel(e: EditCompanyCustomEvent): Promise<void> {
    debugLog('editCompany event', e);
    companyEditor.execute(e.detail.companyEditModel, e.detail.uniqueId);
  }
);
