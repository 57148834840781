import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { updateOne as updateDirectory } from 'serviceWorker/db/directories';

interface AddRemoteIdToDirectory {
  (localId: number, remoteId: string): Promise<number>;
}

export const addRemoteIdToDirectory: AddRemoteIdToDirectory =
  function _addRemoteIdToDirectory(localId, remoteId) {
    return updateDirectory(
      localId,
      { _id: remoteId, syncStatus: SyncStatus.SUCCESS },
      { skipModifyTime: true }
    );
  };
