import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  card: {
    height: '100%',
    minHeight: '428px',
    display: 'flex',
    flexDirection: 'column' as 'column',
    borderRadius: '4px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
  },
  actionArea: {
    padding: '24px',
    height: '100%',
  },
  childrenContainer: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
});
