import { IntlShape } from 'react-intl';

type TranslatedLabels = {
  NAME_LABEL: string;
  LANGUAGE_AND_LOCATION: string;
  PROJECT_LANGUAGE: string;
  TIMEZONE: string;
  DEADLINE_AND_BUDGET: string;
  PROJECT_START_DATE: string;
  PROJECT_END_DATE: string;
  PROJECT_CURRENCY: string;
  PROJECT_VALUE: string;
};

export const createTranslatedLabels = (
  intl: IntlShape
): TranslatedLabels => {
  const NAME_LABEL = intl.formatMessage({
    id: 'project_short_name',
  });

  const LANGUAGE_AND_LOCATION = intl.formatMessage({
    id: 'language_and_location',
  });

  const PROJECT_LANGUAGE = intl.formatMessage({
    id: 'project_language',
  });

  const TIMEZONE = intl.formatMessage({
    id: 'timezone',
  });

  const DEADLINE_AND_BUDGET = intl.formatMessage({
    id: 'deadline_and_budget',
  });

  const PROJECT_START_DATE = intl.formatMessage({
    id: 'project_start_date',
  });

  const PROJECT_END_DATE = intl.formatMessage({ id: 'project_end_date' });

  const PROJECT_CURRENCY = intl.formatMessage({ id: 'project_currency' });

  const PROJECT_VALUE = intl.formatMessage({ id: 'project_value' });

  return {
    NAME_LABEL,
    LANGUAGE_AND_LOCATION,
    PROJECT_LANGUAGE,
    TIMEZONE,
    DEADLINE_AND_BUDGET,
    PROJECT_START_DATE,
    PROJECT_END_DATE,
    PROJECT_CURRENCY,
    PROJECT_VALUE,
  };
};
