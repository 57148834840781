import React, { useContext, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { HeaderContext } from 'components/core/Layout/HeaderContext';
import { StoreState } from '../../setup/types/core';

import { ButtonContained } from 'components/general';
import { TextField, Theme } from '@mui/material';
import { StyleRules } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import { ClientContext } from '../../with-client';
import useFormEdit from 'views/issue/forms/useFormEdit';

const styles = (theme: Theme): StyleRules =>
  createStyles({
    userWrapper: {
      display: 'flex',
      flexDirection: 'column' as 'column',
      width: '50%',
      maxWidth: '400px',
      margin: '0 auto',
      height: '100%',
      justifyContent: 'center',
    },
    saveChangesButton: {
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  });

const CurrentUser = ({ classes }: any): React.ReactElement => {
  const formName = 'currentUser';
  const { setRouteFor } = React.useContext(HeaderContext);
  const user = useSelector((state: StoreState) => state.user);
  const { setEditing } = useFormEdit();

  const intl = useIntl();
  const client = useContext(ClientContext);

  const [userLabel, setUserLabel] = React.useState(user.data.label);
  const [userLabelLoaded, setUserLabelLoaded] = React.useState(
    userLabel !== ''
  );

  useEffect(() => {
    if (!userLabelLoaded && user.data.label) {
      setUserLabel(user.data.label);
      setUserLabelLoaded(true);
    }
    setRouteFor('userEdit');
  }, [userLabelLoaded, user.data.label, setRouteFor]);

  const checkIfUserDataModified = (): boolean => {
    return userLabel !== user.data.label && userLabel !== '';
  };

  const handleNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setEditing(true, formName);
    setUserLabel(event.target.value);
  };

  const handleModifyUser = (): void => {
    if (user && userLabelLoaded) {
      client.updateUser({ label: userLabel }).then(() => {
        setEditing(false, formName);
      });
    }
  };

  const didUserDataModified = checkIfUserDataModified();

  return (
    <div className={classes.userWrapper}>
      <h2>
        {' '}
        <FormattedMessage id='user_view_header' />
      </h2>
      <form style={{ display: 'inline-grid' }}>
        <TextField
          margin='normal'
          variant='outlined'
          value={userLabel || ''}
          id='outlined-helperText'
          onChange={handleNameChange}
          data-qa='user-view-full-name-input'
          label={intl.formatMessage({ id: 'user_view_name_input_label' })}
        />
        <ButtonContained
          onClick={handleModifyUser}
          disabled={!didUserDataModified}
          data-qa='user-view-save-button'
          className={classes.saveChangesButton}
        >
          <FormattedMessage id='general_save' />
        </ButtonContained>
      </form>
    </div>
  );
};

export default withStyles(styles)(CurrentUser);
