import { add as addRequest } from 'serviceWorker/db/httpRequests';
import {
  HttpRequestModel,
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { debugLog } from 'shared/logger/debugLog';
import { ProjectHttpCreateRequestModel } from '../httpQueue/project/types';
import { ProjectCreateModel } from 'shared/domain/project/types/model';
import { projectCreateModelToCreateOutDto } from 'shared/domain/project/mapping/toDto';
import { AddProjectUseCase } from 'shared/domain/project/types/useCase';

export class ProjectSaver implements AddProjectUseCase {
  constructor(
    private addRequest: (
      request: ProjectHttpCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    projectCreateModel: ProjectCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddProjectUseCase', projectCreateModel);
    const projectCreateOutDto =
      projectCreateModelToCreateOutDto(projectCreateModel);
    const request: ProjectHttpCreateRequestModel = {
      createdAt: Date.now(),
      method: 'POST',
      url: `project`,
      data: {
        projectCreateOutDto,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.project,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const projectSaver = new ProjectSaver(addRequest);
