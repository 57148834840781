import { listenToFieldValuesChangedMessage } from 'shared/domain/fieldValue/listenToFieldValuesChange';
import { listenToFieldValuesCreatedMessage } from 'shared/domain/fieldValue/listenToFieldValuesCreate';
import { startFieldValuesChange } from 'shared/domain/fieldValue/startFieldValueChange';
import { startFieldValuesCreate } from 'shared/domain/fieldValue/startFieldValueCreate';
import {
  CreateFieldValueOnView,
  EditFieldValueOnView,
} from 'presentation/fieldValue/fieldValueOnView';
import {
  ComponentType,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import useFormEdit from 'views/issue/forms/useFormEdit';
import { useCustomizedField } from '../../dataProviders/withCustomizedField';
import { createFieldsPromise, parseChanges } from './model';
import { Process } from 'shared/types/process';

type AddFieldValue = (fieldValue: CreateFieldValueOnView) => void;
type EditFieldValue = (fieldValue: EditFieldValueOnView) => void;

type FieldValuesFormContext = {
  submitForm: () => Promise<void>;
  addFieldValue: AddFieldValue;
  editFieldValue: EditFieldValue;
  isPosting: boolean;
  newFields: CreateFieldValueOnView[];
  fieldChanges: EditFieldValueOnView[];
  disableValue: (id: string | number) => void;
  enableValue: (id: string | number) => void;
  hasChanges: boolean;
  formName: 'fieldValues';
};

const WithFieldValuesFormContext = createContext<
  FieldValuesFormContext | undefined
>(undefined);
const WithFieldValuesForm: FC<{
  children?: ReactNode;
  instantSubmit: boolean;
}> = ({ children, instantSubmit }) => {
  const formName = 'fieldValues';
  const dispatch = useDispatch();
  const { fieldName, useContext } = useCustomizedField();
  const { resync, allItems } = useContext();
  const [newFields, setNewFields] = useState<CreateFieldValueOnView[]>([]);
  const [fieldChanges, setFieldChanges] = useState<EditFieldValueOnView[]>(
    []
  );
  const { setEditing } = useFormEdit();

  const hasChanges = useMemo(
    () => Boolean(newFields.length || fieldChanges.length),
    [newFields, fieldChanges]
  );
  const submitForm = useCallback(
    (providedNewFields?: any) => {
      setIsPosting(true);
      const fields = providedNewFields || newFields;

      const newFieldsModel = {
        uniqueId: `${JSON.stringify(fields)}${Date.now()}`,
        data: fields,
      };

      const fieldChangesModel = {
        uniqueId: `${JSON.stringify(fieldChanges)}${Date.now()}`,
        data: parseChanges(allItems, fieldChanges),
      };

      const newFieldsPromise = createFieldsPromise({
        fields: newFieldsModel,
        listener: listenToFieldValuesCreatedMessage,
        setIsPosting,
        dispatch,
        emitFields: startFieldValuesCreate,
        fieldName,
      });

      const fieldChangesPromise = createFieldsPromise({
        fields: fieldChangesModel,
        listener: listenToFieldValuesChangedMessage,
        setIsPosting,
        dispatch,
        emitFields: startFieldValuesChange,
        fieldName,
      });

      return Promise.all([newFieldsPromise, fieldChangesPromise])
        .then(() => {
          resync(fieldName);
          setNewFields([]);
          setFieldChanges([]);
        })
        .catch(() => {
          resync(fieldName);
          setNewFields([]);
          setFieldChanges([]);
        })
        .then(() => {
          setEditing(false, formName);
        });
    },
    [
      dispatch,
      newFields,
      fieldChanges,
      fieldName,
      allItems,
      setEditing,
      resync,
    ]
  );
  const addFieldValue: AddFieldValue = useCallback(
    (fieldValue) => {
      setEditing(true, formName);
      setNewFields((prev) => {
        const index = prev.findIndex((createFieldValue) => {
          return createFieldValue.localId === fieldValue.localId;
        });

        if (index === -1) {
          return [...prev, fieldValue];
        }

        const newFields = [...prev];
        newFields[index] = fieldValue;
        return newFields;
      });
      if (instantSubmit) {
        submitForm([fieldValue]);
      }
    },
    [setEditing, instantSubmit, submitForm]
  );
  const editFieldValue: EditFieldValue = useCallback(
    (fieldValue) => {
      setFieldChanges((prev) => {
        const fieldIndex = prev.findIndex(
          (field) => field._id === fieldValue._id
        );
        fieldIndex < 0
          ? (prev[prev.length] = fieldValue)
          : (prev[fieldIndex] = {
              ...prev[fieldIndex],
              ...fieldValue,
            });

        return [...prev];
      });
      setEditing(true, formName);
    },
    [setEditing]
  );

  const disableValue = useCallback(
    (id: string | number) => {
      if (typeof id === 'string') {
        const field = fieldChanges.find((field) => field._id === id);
        field
          ? editFieldValue({
              ...field,
              processes: [],
            })
          : editFieldValue({
              _id: id,
              processes: [],
            });
      } else if (typeof id === 'number') {
        setNewFields((prev) => {
          prev[id].processes = [];
          return [...prev];
        });
      }
    },
    [fieldChanges, editFieldValue]
  );

  const enableValue = useCallback(
    (id: string | number) => {
      if (typeof id === 'string') {
        const field = fieldChanges.find((field) => field._id === id);
        field
          ? editFieldValue({
              ...field,
              processes: [
                Process.HS,
                Process.ENV,
                Process.QA,
                Process.TSK,
              ],
            })
          : editFieldValue({
              _id: id,
              processes: [
                Process.HS,
                Process.ENV,
                Process.QA,
                Process.TSK,
              ],
            });
      } else if (typeof id === 'number') {
        setNewFields((prev) => {
          prev[id].processes = [
            Process.HS,
            Process.ENV,
            Process.QA,
            Process.TSK,
          ];
          return [...prev];
        });
      }
    },
    [fieldChanges, editFieldValue]
  );

  const [isPosting, setIsPosting] = useState(false);

  const ctx: FieldValuesFormContext = useMemo(() => {
    return {
      submitForm,
      isPosting,
      editFieldValue,
      addFieldValue,
      fieldChanges,
      newFields,
      enableValue,
      disableValue,
      hasChanges,
      formName,
    };
  }, [
    submitForm,
    isPosting,
    fieldChanges,
    newFields,
    editFieldValue,
    addFieldValue,
    enableValue,
    disableValue,
    hasChanges,
  ]);

  return (
    <WithFieldValuesFormContext.Provider value={ctx}>
      {children}
    </WithFieldValuesFormContext.Provider>
  );
};

function useFieldValuesForm(): FieldValuesFormContext {
  const context = useContext(WithFieldValuesFormContext);
  if (context === undefined) {
    throw new Error(
      'useFieldValuesForm must be used within an FieldValuesFormContext'
    );
  }
  return context;
}

const withFieldValuesForm =
  (Component: ComponentType<any>, instantSubmit?: boolean) =>
  ({ ...props }): ReactElement => (
    <WithFieldValuesForm instantSubmit={!!instantSubmit}>
      <Component {...props} />
    </WithFieldValuesForm>
  );

export { useFieldValuesForm, withFieldValuesForm, WithFieldValuesForm };
