import {
  HttpRequestEntity,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { debugLog } from 'shared/logger/debugLog';
import { neverReached } from 'shared/utils/neverReached';
import { broadcastCompanyResponse } from './company';
import { broadcastContractResponse } from './contract';
import { broadcastLevelResponse } from './level';
import { broadcastProjectResponse } from './project';
import { broadcastSiteResponse } from './site';
import { broadcastUserResponse } from './user';
import { broadcastIssueResponse } from './issue';
import { broadcastReportResponse } from './report';
import { broadcastVisibleFieldResponse } from './visibleField';
import {
  broadcastDocumentationMoveResponse,
  broadcastDocumentationResponse,
} from './documentation';
import { broadcastOrganizationResponse } from './organization';

export function emitResponseOnProperChannel(
  request: HttpRequestEntity,
  response: any
): void {
  debugLog('emitResponseOnProperChannel', request.entityType);
  switch (request.entityType) {
    case HttpRequestModelType.issue:
      return broadcastIssueResponse(request, response);
    case HttpRequestModelType.report:
      return broadcastReportResponse(request, response);
    case HttpRequestModelType.documentation:
      return broadcastDocumentationResponse(request, response);
    case HttpRequestModelType.documentationMove:
      return broadcastDocumentationMoveResponse(request, response);
    case HttpRequestModelType.level:
      return broadcastLevelResponse(request, response);
    case HttpRequestModelType.site:
      return broadcastSiteResponse(request, response);
    case HttpRequestModelType.directory:
    case HttpRequestModelType.document:
    case HttpRequestModelType.documentationDelete:
    case HttpRequestModelType.drawing:
      return;
    case HttpRequestModelType.visibleField:
      return broadcastVisibleFieldResponse(request, response);
    case HttpRequestModelType.project:
      return broadcastProjectResponse(request, response);
    case HttpRequestModelType.organization:
      return broadcastOrganizationResponse(request, response);
    case HttpRequestModelType.company:
      return broadcastCompanyResponse(request, response);
    case HttpRequestModelType.contract:
      return broadcastContractResponse(request, response);
    case HttpRequestModelType.user:
      return broadcastUserResponse(request, response);
    default:
      // ensure every case is handled when we add new type.
      neverReached(request.entityType);
  }
}
