import { useMemo, useCallback, useEffect } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { extractFiltersValues } from './useChartFilters';
import { useIntl } from 'react-intl';
import { AnyFilters, Chart } from 'shared/types/analytics';

type GetChart = {
  getChart: (forceUpdate?: boolean) => void;
};

export function useGetSingleChart(
  chart: Chart,
  filters: AnyFilters<any> | undefined,
  additionalParams: any | undefined
): GetChart {
  const intl = useIntl();
  const locale = intl.locale;
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const getChart = useCallback(
    (forceUpdate?: boolean) => {
      if (!filters || hasParamsButUndefined(additionalParams)) {
        return;
      }

      broadcast.postMessage({
        service: Services.ANALYTICS,
        method: ServiceMethods.GET_ONE,
        data: {
          chart: chart,
          filters: extractFiltersValues(filters),
          locale,
          additionalParams,
          forceUpdate,
        },
      });
    },
    [broadcast, filters, chart, locale, additionalParams]
  );

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getChart,
  };
}

function hasParamsButUndefined(
  additionalParams: any | undefined
): boolean {
  return (
    typeof additionalParams === 'object' &&
    Object.values(additionalParams).some((value) => value === undefined)
  );
}
