import { PropsWithChildren, ReactElement } from 'react';
import { useStyles } from './styles';

export function FieldBox({
  children,
  dataQa,
}: PropsWithChildren<{ dataQa?: string }>): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.fieldBox} data-qa={dataQa}>
      <div className='w-100 flex-between'>{children}</div>
    </div>
  );
}
