import { useCompanyChannelListener } from 'components/broadcastChannelListeners/withCompanyChannelListener';
import { filterBySearchPhrase } from 'components/common/SearchInput/helpers';
import { EmptyListReason } from 'components/common/noTableContentInfo/NoTableContentInfo';
import { useEntityDataSubscription } from 'components/common/useEntityDataSubscription';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { CompanyModel } from 'shared/domain/company/types/model';
import { useGetAllCompanies } from 'hooks/useGetAllCompanies';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { setModelsAfterDeletion, setModelsAfterRestore } from '../common';
import { CompaniesContextType, CompaniesResponse } from './types';
import { startCompanyDelete } from 'shared/domain/company/startCompanyDelete';
import { startCompanyRestore } from 'shared/domain/company/startCompanyRestore';

const initialCompanies = { items: [], total: 0 };
const companySearchableFields = [
  'shortLabel',
  'longLabel',
  'taxNumber',
  'correspondenceAddress.postalCode',
  'correspondenceAddress.streetAndNumber',
  'registeredOfficeAddress.postalCode',
  'registeredOfficeAddress.streetAndNumber',
];
const CompaniesContext = React.createContext<
  CompaniesContextType | undefined
>(undefined);

const WithCompanies: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [companies, setCompanies] =
    useState<CompaniesResponse>(initialCompanies);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [emptyListReason, setEmptyListReason] = useState<
    EmptyListReason | undefined
  >();
  const createDialog = useDialog();

  const [loading, setLoading] = useState<boolean>(true);
  const filtered: CompanyModel[] = useMemo(() => {
    return filterBySearchPhrase(
      companies.items,
      searchPhrase,
      companySearchableFields
    );
  }, [searchPhrase, companies]);

  const { subscribe } = useCompanyChannelListener();
  const { getAll: getAllCompanies } = useGetAllCompanies();
  const { resync } = useEntityDataSubscription({
    subscribe,
    getAll: getAllCompanies,
    setEntity: setCompanies,
    setLoading,
    entityName: 'companies',
  });
  useEffect(() => {
    if (companies.total === 0) {
      if (searchPhrase.length) {
        return setEmptyListReason(EmptyListReason.SEARCHPHRASE_MISMATCH);
      }
      return setEmptyListReason(EmptyListReason.NOTHING_IN_DB);
    }
    setEmptyListReason(undefined);
  }, [companies, searchPhrase]);

  // useEffect(() => {
  //   const broadcast = new BroadcastChannel(ChannelNames.companyChannel);
  //   let mounted = true;

  //   broadcast.onmessage = (event: Message): void => {
  //     if (!mounted) {
  //       return;
  //     }

  //     if (event.data && event.type === MessagesTypes.allCompanies) {
  //       const hasData = event.data.hasAll;
  //       if (hasData) {
  //         setCompanies({
  //           items: event.data.items,
  //           total: event.data.items.length,
  //         });
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   broadcast.postMessage({
  //     type: MessagesTypes.getState,
  //   });

  //   return (): void => {
  //     broadcast.close();
  //     mounted = false;
  //   };
  // }, []);

  const deleteCompany = useCallback(
    (companyToDelete: LabelledEntity) => {
      createDialog({
        title: (
          <FormattedMessage id='dialog_confirmation_delete_company_title' />
        ),
        description: (
          <FormattedMessage
            id='dialog_confirmation_delete_company_description'
            values={{ companyName: companyToDelete.label }}
          />
        ),
        customControllerLabels: ['general_cancel', 'general_archive'],
      }).then(() => {
        startCompanyDelete(companyToDelete._id, companyToDelete._id);
        setCompanies((prev) =>
          setModelsAfterDeletion(prev, companyToDelete)
        );
      });
    },
    [createDialog]
  );

  const restoreCompany = useCallback(
    (companyToRestore: LabelledEntity) => {
      startCompanyRestore(companyToRestore._id, companyToRestore._id);
      setCompanies((prev) =>
        setModelsAfterDeletion(prev, companyToRestore)
      );
      (prev) => setModelsAfterRestore(prev, companyToRestore);
    },
    []
  );

  const ctx = {
    companies: { items: filtered, total: filtered.length },
    loading,
    resync,
    deleteCompany,
    restoreCompany,
    emptyListReason,
    setSearchPhrase,
  };

  return (
    <CompaniesContext.Provider value={ctx}>
      {children}
    </CompaniesContext.Provider>
  );
};

const withCompanies =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithCompanies>
      <Component {...props} />
    </WithCompanies>
  );

function useCompanies(): CompaniesContextType {
  {
    const context = React.useContext(CompaniesContext);
    if (context === undefined) {
      throw new Error(
        'useCompanies must be used within a CompaniesContextProvider'
      );
    }
    return context;
  }
}

export { WithCompanies, useCompanies, withCompanies };
