import { DateRangeObject } from 'shared/types/analytics';
import { HazardTypeFilters } from 'shared/types/analytics';
import { get as getHazardCategories } from 'serviceWorker/db/hazardCategories';
import { ApexSeriesAndLabels } from 'shared/types/analytics';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByImpact,
  filterByProcess,
  filterBySite,
  filterBySubcontractor,
  filterByWorktype,
  increaseEntitySeries,
  increaseNoneSeries,
  toSortedApexSeries,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: HazardTypeFilters,
  timezone: string,
  additionalParams: { visibleProcesses: string[] }
): Promise<ApexSeriesAndLabels> {
  if (!additionalParams.visibleProcesses) {
    throw new Error('Missing visibleProcesses param');
  }
  const hazardTypesLabels = {};
  const hazardCategoriesMap = (await getHazardCategories()).reduce(
    (map, hazardCategory) => {
      map.set(hazardCategory._id, {
        _id: hazardCategory._id,
        label: hazardCategory.label,
      });
      return map;
    },
    new Map<string, LabelledEntity>()
  );

  const [
    dateRangeFilter,
    originatorsFilter,
    siteFilter,
    workTypeFilter,
    coordinatorFilter,
    impactFilter,
    processesFilter,
  ] = filters.dataScope.filters;

  const filteredIssues = issues.filter((issue) => {
    if (!additionalParams.visibleProcesses.includes(issue.process)) {
      return false;
    }

    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedOriginators = filterBySubcontractor(
      originatorsFilter.value as string[],
      issue
    );
    if (!passedOriginators) {
      return false;
    }

    const passedSite = filterBySite(siteFilter.value as string[], issue);
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterByWorktype(
      workTypeFilter.value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      coordinatorFilter.value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    const passedImpact = filterByImpact(
      impactFilter.value as string[],
      issue
    );
    if (!passedImpact) {
      return false;
    }

    const passedProcesses = filterByProcess(
      processesFilter.value as string[],
      issue
    );
    if (!passedProcesses) {
      return false;
    }

    return true;
  });

  const reducedToHazardTypeSummary = filteredIssues.reduce<
    HashMap<number>
  >((result, issue) => {
    const { hazardCategory } = issue.extendedData;
    if (hazardCategory && hazardCategory.length > 0) {
      hazardCategory.forEach((category) => {
        increaseEntitySeries(
          result,
          hazardTypesLabels,
          hazardCategoriesMap.get(category)!
        );
      });
    } else {
      increaseNoneSeries(result, hazardTypesLabels);
    }

    return result;
  }, {});

  return Promise.resolve(
    toSortedApexSeries(reducedToHazardTypeSummary, hazardTypesLabels)
  );
}
