import { CompanyWithFinances } from 'shared/domain/company/types/financialData';
import { ColumnShapeSkeleton } from 'components/table/columnShapes/types';
import { CostValueCellRenderer } from 'components/table/renderers/cell/costValue';
import { LoadingCellRenderer } from 'components/table/renderers/cell/loading';
import { keepNumberIfDefined } from 'components/table/sort';
import { SORT_TYPE } from 'shared/filter/sort/types';

export function getEstimatedCostColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 180,
    flexGrow: 0,
    flexShrink: 0,
    sortable: true,
    sortType: SORT_TYPE.NUMBERS,
    accessor: (entity: CompanyWithFinances): number | null =>
      entity?.financialData?.estimatedCost?.cost ||
      keepNumberIfDefined(entity?.financialData?.estimatedCost?.cost),
    cellRenderer: LoadingCellRenderer<CompanyWithFinances>(
      'financialData',
      CostValueCellRenderer({ decimalPlaces: 2 })
    ),
    labelId: 'extendedField_Estimated_cost',
    dataKey: 'financialData.estimatedCost.cost',
    style: {
      justifyContent: 'flex-start',
    },
  };
}
