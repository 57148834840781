export function isSameAsPrev(
  curr: {
    hashString: string;
    itemsString: string;
  },
  prev: {
    hashString: string;
    itemsString: string;
  }
): boolean {
  return (
    curr.hashString === prev.hashString &&
    curr.itemsString === prev.itemsString
  );
}
