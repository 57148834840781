import React from 'react';
import cn from 'classnames';
import styles from './styles';

type Props = {
  onError?: () => void;
  onClick: (e: React.SyntheticEvent) => void;
  setLoaded: () => void;
  description: string;
  src: string;
} & {
  className: string;
};

const Image: React.FC<Props> = (props) => {
  const classes = styles();
  const { onClick, className, description, setLoaded, onError, src } =
    props;

  return (
    <div
      onClick={onClick}
      className={cn(classes.imageContainer, className)}
    >
      <img
        // s3 + chrome - big issue around Origin and CORS - just use 'use-credentials' for every request to s3 PT-2785
        crossOrigin='use-credentials'
        className={classes.image}
        alt={description}
        onLoad={setLoaded}
        onError={onError}
        src={src}
      />
    </div>
  );
};
export default Image;
