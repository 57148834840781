import ExpandUp from '@mui/icons-material/ExpandLess';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
} from '@mui/material';
import cn from 'classnames';
import { ProcessIcon } from 'components/common/ProcessIcon';
import { ProcessIconSize } from 'components/common/ProcessIcon/types';
import { useIssueForm } from 'components/common/withIssueForm';
import { useCompanies } from 'components/dataProviders/withCompanies';
import { useFieldVisibilityMap } from 'components/dataProviders/withVisibleFields/withVisibleFieldsMap';
import { ButtonIcon } from 'components/general';
import { MemoIssueStage } from 'components/general/IssueStage';
import { IssueStageSize } from 'components/general/IssueStage/types';
import { MemoIssueCardChip } from 'components/inspection/IssueCardPreview/Chip';
import { displayDate } from 'components/table/renderers/cell/dates';
import { toIssueCardNumberFormat } from 'presentation/issue/issueCardNumberFormat';
import {
  Dispatch,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  SetStateAction,
  TouchEvent,
  TouchEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  checkProcessesEquality,
  toProcessesObject,
} from 'redux/selectors/processes';
import { projectDataSelector } from 'redux/selectors/project';
import { delayCalculatorWithAhead } from 'shared/domain/issue/calculateDelayField';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { StageInternalNames } from 'shared/types/stages';
import { Color } from 'shared/utils/colors';
import {
  DateFieldValueGrid,
  DelayValuesGrid,
  ExpandableField,
  StaticField,
  TextValueField,
} from './fields';
import { ArrowDotted, ArrowSolid } from './icons';
import { useLastEditString } from './model';
import { useStyles } from './styles';

const stopPropagation: TouchEventHandler = (e) => e.stopPropagation();

export function IssueCardPresentational({
  issueId,
  hashtag,
  processCode,
  processColor,
  title,
  company,
  companyFieldLabel,
  plannedDatesLabel,
  plannedDates,
  completionDateLabel,
  completionDates,
  delayLabel,
  completionAmount,
  delays,
  deleted,
  stage,
  issueModel,
  onClick,
  isSelected,
  lastEditString,
  isExpanded,
  setIsExpanded,
}: {
  issueId: string;
  hashtag: string;
  processCode: string;
  processColor: Color;
  title: string;
  company: string;
  companyFieldLabel: string;
  plannedDatesLabel: string | undefined;
  plannedDates: (string | undefined | null)[] | undefined;
  completionDateLabel: string | undefined;
  completionDates: (string | undefined | null)[] | undefined;
  delayLabel: string | undefined;
  completionAmount: string;
  delays: (number | null)[];
  deleted: boolean;
  stage: StageInternalNames;
  lastEditString: string;
  issueModel: IssueModel;
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  onClick?: (issue: IssueModel) => void;
  isSelected?: boolean;
}): ReactElement {
  const cs = useStyles();
  const cardElementRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (isSelected) {
      cardElementRef.current?.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      });
    }
  }, [isSelected]);
  const arrowDotted = useCallback(
    () => <ArrowDotted htmlColor='#979797' className={cs.cardDateArrow} />,
    [cs]
  );
  const onCardClick = useCallback(
    (e: TouchEvent | MouseEvent) => {
      //ensures proper behavior of clickAwayListener in Documentation pins and areas
      e.stopPropagation();
      if (typeof onClick === 'function') onClick(issueModel);
    },
    [onClick, issueModel]
  );

  const expand: MouseEventHandler = useCallback(
    (e) => {
      e.stopPropagation();
      setIsExpanded((p) => !p);
    },
    [setIsExpanded]
  );

  const hasPlannedDatesValue = useMemo(() => {
    return Boolean((plannedDates || []).filter((x) => x).length);
  }, [plannedDates]);

  return (
    <Box
      ref={cardElementRef}
      sx={{
        minWidth: 'min(380px, 95%)',
        width: '100%',
      }}
    >
      <Card
        className={cn(cs.card, isSelected ? 'selected' : '')}
        variant='outlined'
        style={{ pointerEvents: 'all' }}
      >
        <CardActionArea onTouchEnd={stopPropagation} onClick={onCardClick}>
          <div
            className={(deleted && cs.deleted) || ''}
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '0.5rem',
              paddingLeft: '10px',
            }}
          >
            <ProcessIcon
              code={processCode}
              color={processColor}
              size={ProcessIconSize.issueCard}
            />
            <div className={cs.cardHeader}>{title}</div>
            <ButtonIcon
              style={{
                transform: isExpanded
                  ? 'rotate(-360deg)'
                  : 'rotate(540deg)',
                transition: 'transform .1s',
              }}
              //ensures proper behavior of clickAwayListener in Documentation pins and areas
              onTouchEnd={stopPropagation}
              onClick={expand}
              component='span'
            >
              <ExpandUp htmlColor='black' />
            </ButtonIcon>
          </div>
          <CardContent
            className={(deleted && cs.deleted) || ''}
            style={{
              padding: '10px',
              paddingTop: '0px',
              fontSize: '0.75rem',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
                gap: '0.25rem',
              }}
            >
              <MemoIssueCardChip
                key={hashtag}
                values={[
                  isSelected
                    ? {
                        value: `#${hashtag}`,
                        color: '#FFFFFF',
                        textColor: '#000000',
                        border: '1px solid rgb(142, 36, 170)',
                      }
                    : `#${hashtag}`,
                ]}
              />
              <span style={{ color: '#757575' }}>{lastEditString}</span>
            </div>
            <div
              style={{
                display: 'grid',
                justifyContent: 'stretch',
                flexDirection: 'column',
                alignItems: 'flex-start',
                width: '100%',
              }}
            >
              <StaticField
                label={companyFieldLabel}
                isExpanded={isExpanded}
                hasValue={Boolean(company)}
              >
                <TextValueField text={company} isExpanded={isExpanded} />
              </StaticField>

              <StaticField
                label={plannedDatesLabel}
                isExpanded={isExpanded}
                hasValue={hasPlannedDatesValue}
              >
                <DateFieldValueGrid
                  dates={plannedDates || []}
                  delays={delays}
                  isExpanded={isExpanded}
                  Arrow={arrowDotted}
                />
              </StaticField>

              <ExpandableField
                label={completionDateLabel}
                isExpanded={isExpanded}
              >
                <DateFieldValueGrid
                  dates={completionDates || []}
                  delays={[]}
                  isExpanded={isExpanded}
                  Arrow={() => (
                    <ArrowSolid
                      htmlColor='#979797'
                      className={cs.cardDateArrow}
                    />
                  )}
                />
              </ExpandableField>

              <ExpandableField label={delayLabel} isExpanded={isExpanded}>
                <DelayValuesGrid isExpanded={isExpanded} delays={delays} />
              </ExpandableField>
            </div>
          </CardContent>
          <Divider
            style={{
              borderBottomWidth: 'none',
              backgroundColor: 'unset',
            }}
          />
          <CardContent
            className={(deleted && cs.deleted) || ''}
            style={{
              padding: '10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <span className={cs.completionField}>{completionAmount}</span>
            <MemoIssueStage
              stage={stage}
              issueArchived={deleted}
              size={IssueStageSize.issueCard}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
}

export function IssueCard({
  issue,
  onClick,
  isSelected,
}: {
  issue: IssueModel;
  onClick?: (issue: IssueModel) => void;
  isSelected?: boolean;
}): ReactElement {
  const intl = useIntl();
  const { issueForm } = useIssueForm();
  const { companies } = useCompanies();
  const { timezone } = useSelector(projectDataSelector);
  const processes = useSelector(toProcessesObject, checkProcessesEquality);
  const process = processes[issue.process];

  const { fieldVisibilityMapStore } = useFieldVisibilityMap();

  const [visibleFields, setVisibleFields] = useState(
    fieldVisibilityMapStore.get()[issue.process]
  );
  useEffect(() => {
    setVisibleFields(fieldVisibilityMapStore.get()[issue.process]);
    return fieldVisibilityMapStore.subscribe(() => {
      setVisibleFields(fieldVisibilityMapStore.get()[issue.process]);
    });
  }, [fieldVisibilityMapStore, issue.process]);

  const hashtag = issue._id.slice(-4);
  const mappedForm = useMemo(() => {
    const flattened = {};
    issueForm.forEach((field) => {
      flattened[field.name] = field;
    });
    return flattened;
  }, [issueForm]);

  const companiesField = useMemo(() => {
    const executionCompanyField =
      mappedForm[IssueFieldNames.executedByCompanies];
    if (
      visibleFields[IssueFieldNames.executedByCompanies] &&
      executionCompanyField
    ) {
      return {
        values: issue.extendedData.executedByCompanies || [],
        label: executionCompanyField.label,
      };
    } else if (
      visibleFields[IssueFieldNames.subcontractors] &&
      mappedForm[IssueFieldNames.subcontractors]
    ) {
      return {
        values: issue.primaryData.subcontractors || [],
        label: mappedForm[IssueFieldNames.subcontractors].label,
      };
    }
  }, [visibleFields, issue, mappedForm]);

  const companyString = useMemo(() => {
    if (!companiesField) return '';
    return companiesField.values
      .map((subcontractorId) => {
        const foundCompany = companies.items.find(
          (company) => company._id === subcontractorId
        );
        return foundCompany?.shortLabel;
      })
      .filter((x) => x)
      .join(', ');
  }, [companiesField, companies.items]);

  const completionAmountString = useMemo(() => {
    if (
      !visibleFields[IssueFieldNames.completedAmount] ||
      !visibleFields[IssueFieldNames.targetAmount] ||
      !mappedForm[IssueFieldNames.completedAmount] ||
      !mappedForm[IssueFieldNames.targetAmount] ||
      !issue.extendedData.targetAmount
    ) {
      return '';
    }
    const completedAmount = issue.extendedData.completedAmount || 0;

    return `${Math.trunc(
      (completedAmount * 100) / issue.extendedData.targetAmount
    )}% - ${toIssueCardNumberFormat(
      completedAmount
    )}/${toIssueCardNumberFormat(issue.extendedData.targetAmount)}`;
  }, [issue, mappedForm, visibleFields]);

  const plannedDatesWithLabel = useMemo(() => {
    if (
      visibleFields[IssueFieldNames.targetStartDate] &&
      visibleFields[IssueFieldNames.finalStartDate] &&
      mappedForm[IssueFieldNames.targetStartDate] &&
      mappedForm[IssueFieldNames.finalStartDate]
    ) {
      return {
        label: intl.formatMessage({
          id: 'issue_card_planned_execution_time',
        }),
        values: [
          displayDate(issue.extendedData.targetStartDate, timezone),
          displayDate(issue.extendedData.targetCompletionDate, timezone),
        ],
      };
    }

    if (visibleFields[IssueFieldNames.targetCompletionDate]) {
      return {
        label: intl.formatMessage({
          id: 'issue_card_planned_completion_date',
        }),
        values: [
          displayDate(issue.extendedData.targetCompletionDate, timezone),
        ],
      };
    }
  }, [mappedForm, issue, timezone, intl, visibleFields]);

  const finalDatesWithLabel = useMemo(() => {
    if (
      visibleFields[IssueFieldNames.targetStartDate] &&
      visibleFields[IssueFieldNames.finalStartDate] &&
      mappedForm[IssueFieldNames.targetStartDate] &&
      mappedForm[IssueFieldNames.finalStartDate]
    ) {
      return {
        label: intl.formatMessage({
          id: 'issue_card_final_execution_time',
        }),
        values: [
          displayDate(issue.extendedData.finalStartDate, timezone),
          displayDate(issue.extendedData.finalCompletionDate, timezone),
        ],
      };
    }

    if (visibleFields[IssueFieldNames.finalCompletionDate]) {
      return {
        label: intl.formatMessage({
          id: 'issue_card_final_completion_date',
        }),
        values: [
          displayDate(issue.extendedData.finalCompletionDate, timezone),
        ],
      };
    }
  }, [mappedForm, issue, timezone, intl, visibleFields]);

  const delays = useMemo(() => {
    if (
      visibleFields[IssueFieldNames.targetStartDate] &&
      visibleFields[IssueFieldNames.finalStartDate] &&
      mappedForm[IssueFieldNames.targetStartDate] &&
      mappedForm[IssueFieldNames.finalStartDate]
    ) {
      const {
        targetCompletionDate,
        finalCompletionDate,
        targetStartDate,
        finalStartDate,
      } = issue.extendedData;
      const completionDelay = delayCalculatorWithAhead.execute(
        targetCompletionDate,
        finalCompletionDate,
        timezone
      );
      const startDelay = delayCalculatorWithAhead.execute(
        targetStartDate,
        finalStartDate,
        timezone
      );

      return [startDelay, completionDelay];
    }

    if (
      visibleFields[IssueFieldNames.finalCompletionDate] &&
      visibleFields[IssueFieldNames.targetCompletionDate]
    ) {
      const { targetCompletionDate, finalCompletionDate } =
        issue.extendedData;
      const completionDelay = delayCalculatorWithAhead.execute(
        targetCompletionDate,
        finalCompletionDate,
        timezone
      );

      return [completionDelay];
    }
    return [];
  }, [mappedForm, issue, timezone, visibleFields]);

  const [isExpanded, setIsExpanded] = useState(false);

  const lastEditString = useLastEditString(
    intl,
    issue.modifiedAt,
    issue.modifiedBy,
    isExpanded,
    visibleFields
  );

  return (
    <IssueCardPresentational
      issueId={issue._id}
      key={issue._id}
      issueModel={issue}
      processCode={process.code}
      processColor={process.color}
      title={issue.primaryData.title}
      hashtag={hashtag}
      company={companyString}
      companyFieldLabel={companiesField?.label}
      completionAmount={completionAmountString}
      plannedDates={plannedDatesWithLabel?.values}
      plannedDatesLabel={plannedDatesWithLabel?.label}
      completionDates={finalDatesWithLabel?.values}
      completionDateLabel={finalDatesWithLabel?.label}
      delayLabel={
        visibleFields[IssueFieldNames.finalCompletionDate] &&
        visibleFields[IssueFieldNames.targetCompletionDate]
          ? intl.formatMessage({ id: 'issue_card_delay' })
          : undefined
      }
      delays={delays}
      deleted={issue.deleted}
      stage={issue.stage}
      onClick={onClick}
      isSelected={isSelected}
      lastEditString={lastEditString}
      isExpanded={isExpanded}
      setIsExpanded={setIsExpanded}
    />
  );
}
