import { WithInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { MemoTemplateWizardCommunicator } from './communicator';
import { withInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';

function TemplateWizard(): ReactElement {
  const { id } = useParams<{ id: string }>();

  return (
    <WithInspectionTemplate key={id} inspectionTemplateId={id}>
      <MemoTemplateWizardCommunicator />
    </WithInspectionTemplate>
  );
}

export const MemoTemplateWizard = React.memo(
  withInspectionTemplates(TemplateWizard)
);
