type StartProps = { page: number; rowsPerPage: number };
type EndProps = StartProps & { items: number };

export const start = ({ page, rowsPerPage }: StartProps): number => {
  return page * rowsPerPage + 1;
};

export const end = ({ page, rowsPerPage, items }: EndProps): number => {
  const end = (page + 1) * rowsPerPage;
  return end > items ? items : end;
};
