import { getEnvironment } from 'shared/utils/environment';
import { ENVIRONMENTS } from 'shared/enums';
import { CacheLocation } from '@auth0/auth0-spa-js';

type Auth0Config = {
  domain: string;
  clientId: string;
  redirectUri: string;
  redirectUnauthorizedUrl: string;
  audience: string;
  cacheLocation?: CacheLocation;
};
const devdomain = process.env.REACT_APP_AUTH0_DEV_DOMAIN;

const auth0StagingDomain = 'hustro-staging.eu.auth0.com';
const auth0IntegrationDomain = 'hustro-integration.eu.auth0.com';
const auth0ProdDomain = 'auth.hustro.com';
const devEnvDomain = 'http://localhost:3000';
const integrationEnvDomain = 'https://integ.o.hustroapp.com';
const stagingEnvDomain = 'https://stage.o.hustroapp.com';
const prodEnvDomain = 'https://app.hustro.com';
const auth0DevAudience = `https://${devdomain}/api/v2/`;
const auth0IntegrationAudience = `https://${auth0IntegrationDomain}/api/v2/`;
const auth0StagingAudience = `https://${auth0StagingDomain}/api/v2/`;
// This is because even if you set a custom domain the API identifier for
// the /userinfo endpoint remains https://{YOUR_ORIGINAL_AUTH0_DOMAIN}/userinfo.
// https://auth0.com/docs/custom-domains/troubleshoot-custom-domains#-service-not-found-
const auth0ProdAudience = `https://hustro.eu.auth0.com/api/v2/`;

export const getAuth0Config = (): Auth0Config => {
  const environment = getEnvironment();
  switch (environment) {
    case ENVIRONMENTS.DEVELOPMENT:
      const clientId = process.env.REACT_APP_AUTH0_DEV_CLIENT_ID;
      if (!devdomain) {
        throw new Error('Auth0 domain is required on this env');
      }
      if (!clientId) {
        throw new Error('Auth0 clientId is required on this env');
      }
      return {
        domain: devdomain,
        clientId,
        redirectUri: `${devEnvDomain}/dashboard`,
        redirectUnauthorizedUrl: `${devEnvDomain}/redirect`,
        audience: auth0DevAudience,
      };
    case ENVIRONMENTS.INTEGRATION_DEV:
      return {
        domain: auth0IntegrationDomain,
        clientId: 'VHjvX2xd1UMq6r2JSEeqPYSfEjaEtRKy',
        redirectUri: `${devEnvDomain}/dashboard`,
        redirectUnauthorizedUrl: `${devEnvDomain}/redirect`,
        audience: auth0IntegrationAudience,
      };
    case ENVIRONMENTS.STAGING_DEV:
      return {
        domain: auth0StagingDomain,
        clientId: 'DlTNCMemuNlWHBBjPWSNZj2178NbaE3t',
        redirectUri: `${devEnvDomain}/dashboard`,
        redirectUnauthorizedUrl: `${devEnvDomain}/redirect`,
        audience: auth0StagingAudience,
      };
    case ENVIRONMENTS.INTEGRATION:
      return {
        domain: auth0IntegrationDomain,
        clientId: 'ti3gdhPnCQKuoW81gkDLDVoMRa9k9AqN',
        redirectUri: `${integrationEnvDomain}/dashboard`,
        redirectUnauthorizedUrl: `${integrationEnvDomain}/redirect`,
        audience: auth0IntegrationAudience,
      };
    case ENVIRONMENTS.STAGING:
      return {
        domain: auth0StagingDomain,
        clientId: 'JBGpEqyS22aKjZw1gvWA6arDhkAhZx89',
        redirectUri: `${stagingEnvDomain}/dashboard`,
        redirectUnauthorizedUrl: `${stagingEnvDomain}/redirect`,
        audience: auth0StagingAudience,
      };
    case ENVIRONMENTS.PRODUCTION:
      return {
        domain: auth0ProdDomain,
        clientId: 'j7JIH7n5Nw5KvQyu72dWPIFVk0qhy5s2',
        redirectUri: `${prodEnvDomain}/dashboard`,
        redirectUnauthorizedUrl: `${prodEnvDomain}/redirect`,
        audience: auth0ProdAudience,
      };
  }
};
