import { createStyles, makeStyles } from '@mui/styles';
import { StageColor } from 'shared/domain/issue/issueModel';
import { StageInternalNames } from 'shared/types/stages';

export const useStyles = makeStyles(() =>
  createStyles({
    issueStatus: {
      fontFamily: 'Archivo',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0em',
      textTransform: 'capitalize',
      alignSelf: 'center',
      color: '#8A8A8A',
    },
    small: {
      fontSize: '0.875rem',
      lineHeight: '11px',
    },
    [StageInternalNames.draft]: {
      color: StageColor.draft,
    },
    [StageInternalNames.consultation]: {
      color: StageColor.consultation,
    },
    [StageInternalNames.execution]: {
      color: StageColor.execution,
    },
    [StageInternalNames.resolved]: {
      color: StageColor.resolved,
    },
    issueCard: {
      fontSize: '0.75rem',
    },
  })
);
