import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { SiteHttpCreateRequestModel } from '../httpQueue/site/types';
import { SiteCreateModel } from 'shared/domain/site/types/model';
import { AddSiteUseCase } from 'shared/domain/site/types/useCases';

export class SiteSaver implements AddSiteUseCase {
  constructor(
    private addRequest: (
      request: SiteHttpCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    siteCreateModel: SiteCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddSiteUseCase', siteCreateModel);

    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      data: {
        siteCreateModel: siteCreateModel,
        uniqueId: uniqueId,
      },
      url: `/project/${siteCreateModel.projectId}/site`,
      entityType: HttpRequestModelType.site,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const siteSaver = new SiteSaver(addRequest);
