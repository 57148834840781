import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';

import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { isServiceWorkerCheckMessage } from 'components/serviceWorkerCheck/model';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { Message } from 'shared/domain/messages/message';

type ServiceWorkerReadyContextType = boolean;

const ServiceWorkerReadyContext = React.createContext<
  ServiceWorkerReadyContextType | undefined
>(undefined);

const WithServiceWorkerReady: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [serviceWorkerReady, setServiceWorkerReady] =
    useState<boolean>(false);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

    let interval: ReturnType<typeof setInterval>;

    broadcast.onmessage = (event: Message): void => {
      if (!isServiceWorkerCheckMessage(event)) {
        return;
      }
      broadcast.close();
      setServiceWorkerReady(true);
      clearInterval(interval);
    };

    broadcast.postMessage({
      service: Services.CONFIG,
      method: ServiceMethods.SERVICE_WORKER_CHECK,
    });

    interval = setInterval(() => {
      !broadcast.isClosed &&
        broadcast.postMessage({
          service: Services.CONFIG,
          method: ServiceMethods.SERVICE_WORKER_CHECK,
        });
    }, 2000);

    return (): void => {
      clearInterval(interval);
      broadcast.close();
    };
  }, []);

  const ctx = serviceWorkerReady;

  return (
    <ServiceWorkerReadyContext.Provider value={ctx}>
      {children}
    </ServiceWorkerReadyContext.Provider>
  );
};

const withServiceWorkerReady =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithServiceWorkerReady>
      <Component {...props} />
    </WithServiceWorkerReady>
  );

function useServiceWorkerReady(): ServiceWorkerReadyContextType {
  const context = React.useContext(ServiceWorkerReadyContext);
  if (context === undefined) {
    throw new Error(
      'useServiceWorkerReady must be used within a ServiceWorkerReadyContextProvider'
    );
  }
  return context;
}

export {
  WithServiceWorkerReady,
  withServiceWorkerReady,
  useServiceWorkerReady,
};
