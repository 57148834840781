import { makeStyles } from '@mui/styles';
import splashBackground from './assets/splash_background.webp';

export const useLoginPageStyles = makeStyles({
  container: {
    background: `url('${splashBackground}') no-repeat center center fixed`,
    backgroundSize: 'cover',
    display: 'flex',
    position: 'absolute' as 'absolute',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  footerContainer: {
    position: 'fixed',
    bottom: '0px',
    left: '0px',
    display: 'flex',
    flexDirection: 'row',
    color: '#BFBFBF',
    width: '100%',
    fontSize: '0.75rem',
    height: '50px',
    alignItems: 'center',
    borderTop: '1px solid rgba(191, 191, 191, 0.17)',
  },
  leftFooterSection: {
    display: 'flex',
    flexGrow: 1,
  },
  leftFooterItem: {
    marginLeft: '3%',
  },
  rightFooterItem: {
    marginRight: '20px',
    textAlign: 'right',
  },
});
