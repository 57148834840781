import { CompanyWithFinances } from 'shared/domain/company/types/financialData';
import { CostValueCellRenderer } from 'components/table/renderers/cell/costValue';
import { SORT_TYPE } from 'shared/filter/sort/types';
import { ColumnShapeSkeleton } from '../../types';
import { LoadingCellRenderer } from 'components/table/renderers/cell/loading';
import { keepNumberIfDefined } from 'components/table/sort';

export function getImposedFineColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 180,
    flexGrow: 0,
    flexShrink: 0,
    sortable: true,
    sortType: SORT_TYPE.NUMBERS,
    accessor: (entity: CompanyWithFinances): number | null =>
      entity.financialData?.imposedFine ||
      keepNumberIfDefined(entity.financialData?.imposedFine),
    cellRenderer: LoadingCellRenderer<CompanyWithFinances>(
      'financialData',
      CostValueCellRenderer({ decimalPlaces: 2 })
    ),
    labelId: 'extendedField_Imposed_fine',
    dataKey: 'financialData.imposedFine',
    style: {
      justifyContent: 'flex-start',
    },
  };
}
