import { SvgIcon } from '@mui/material';
import { ReactElement } from 'react';

export function ArrowDotted(props): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 17 8'>
      <path d='M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H1V3.5H0V4.5ZM3 4.5H5V3.5H3V4.5ZM7 4.5H9V3.5H7V4.5ZM11 4.5H13V3.5H11V4.5ZM15 4.5H16V3.5H15V4.5Z' />
    </SvgIcon>
  );
}

export function ArrowSolid(props): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 17 8'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M16.3536 4.35355C16.5488 4.15829 16.5488 3.84171 16.3536 3.64645L13.1716 0.464466C12.9763 0.269204 12.6597 0.269204 12.4645 0.464466C12.2692 0.659728 12.2692 0.976311 12.4645 1.17157L15.2929 4L12.4645 6.82843C12.2692 7.02369 12.2692 7.34027 12.4645 7.53553C12.6597 7.7308 12.9763 7.7308 13.1716 7.53553L16.3536 4.35355ZM0 4.5H16V3.5H0V4.5Z'
      />
    </SvgIcon>
  );
}

export function DelayIcon(props): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 64 64'>
      <path d='M42.36 16L32 26.36L21.64 16L16 21.64L26.36 32L16 42.36L21.64 48L32 37.64L42.36 48L48 42.36L37.64 32L48 21.64L42.36 16ZM32 64C14.36 64 0 49.64 0 32C0 14.36 14.36 0 32 0C49.64 0 64 14.36 64 32C64 49.64 49.64 64 32 64Z' />
    </SvgIcon>
  );
}

export function OnTime(props): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 64 64'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M27.3351 46.7301L49.6677 24.3974L45.2428 20.0061L27.3351 37.9138L18.4618 29.0405L14.1141 33.4319L27.3351 46.7301ZM31.9842 64C27.5844 64 23.4402 63.1662 19.5517 61.4985C15.6632 59.8309 12.2663 57.5444 9.36093 54.6391C6.45559 51.7337 4.16911 48.3388 2.50146 44.4543C0.833821 40.5698 0 36.4276 0 32.0278C0 27.5766 0.833821 23.4067 2.50146 19.5181C4.16911 15.6297 6.45333 12.2449 9.35414 9.36371C12.2549 6.48257 15.6486 4.20166 19.5352 2.521C23.4218 0.840333 27.5663 0 31.9685 0C36.4222 0 40.5951 0.839201 44.487 2.5176C48.379 4.196 51.7644 6.47384 54.6434 9.35112C57.5224 12.2284 59.8016 15.6119 61.4809 19.5015C63.1603 23.3912 64 27.5646 64 32.0218C64 36.4256 63.1597 40.5716 61.479 44.4596C59.7983 48.3476 57.5174 51.7425 54.6363 54.6443C51.7551 57.5462 48.3723 59.8309 44.4879 61.4985C40.6034 63.1662 36.4355 64 31.9842 64Z'
      />
    </SvgIcon>
  );
}
