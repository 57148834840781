import { customAccessFunction } from 'charts/shared/access';
import { useGetChart } from 'charts/shared/hooks';
import { listViewDonutChartOptions } from 'charts/shared/options/donutChart';
import { GenericChartState } from 'charts/shared/types';
import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { ApexSeriesAndLabels, Chart } from 'shared/types/analytics';
import { ChartType } from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { useOriginatorFilters } from './filters';
import { detailedViewOriginatorsChartOptions } from './options';
import { OriginatorChartContext } from './types';

export function useOriginator(): OriginatorChartContext {
  const processesWithOriginator = useProcessesWithField(
    IssueFieldNames.subcontractors
  );
  const access = useCallback(() => {
    return Boolean(processesWithOriginator.length);
  }, [processesWithOriginator]);
  const filters = useOriginatorFilters();
  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(Chart.originator, filters);

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewOriginatorsChartOptions
    : listViewDonutChartOptions;

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'data_scope_originators',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: customAccessFunction(access),
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
