import { Collection, IndexableType, Table } from 'dexie';
import { HustroDb, HustroDbTablesUnion } from '.';
import { logWithRethrow, getErrorMsg, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { LogLevel } from 'shared/types/logger';

export function makeDefaultClear(
  db: HustroDb,
  table: Table
): () => Promise<void> {
  return wrapQuery(db, (): Promise<void> => {
    return table.clear().catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(
          Operation.clear,
          table.name as HustroDbTablesUnion
        ),
        errorObj: e,
        additionalInfo: null,
      })
    );
  });
}

type DaoQuery<T> = { [key in keyof Partial<T>]: T[key] | T[key][] };

export function makeDefaultGetMany<
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  baseQuery?: DaoQuery<T>
): (query?: DaoQuery<T>) => Promise<T[]> {
  return wrapQuery(db, (localQuery?: DaoQuery<T>): Promise<T[]> => {
    const filterResult = filterTable(table, baseQuery, localQuery);

    return filterResult.toArray().catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.get, table.name as HustroDbTablesUnion),
        errorObj: e,
        additionalInfo: {
          query: { baseQuery, localQuery },
        },
      })
    );
  });
}

export function makeDefaultCount<
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  baseQuery?: DaoQuery<T>
): (query?: DaoQuery<T>) => Promise<number> {
  return wrapQuery(db, (localQuery?: DaoQuery<T>): Promise<number> => {
    const filterResult = filterTable(table, baseQuery, localQuery);

    return filterResult.count().catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(
          Operation.count,
          table.name as HustroDbTablesUnion
        ),
        errorObj: e,
        additionalInfo: {
          query: { baseQuery, localQuery },
        },
      })
    );
  });
}

export function makeDefaultGetOne<
  TValue extends IndexableType,
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  key: keyof T
): (id: TValue) => Promise<T | undefined> {
  return wrapQuery(db, (id: TValue): Promise<T | undefined> => {
    return table
      .where(key as string)
      .equals(id)
      .first()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(
            Operation.getOne,
            table.name as HustroDbTablesUnion
          ),
          errorObj: e,
          additionalInfo: {
            query: { key, value: id },
          },
        })
      );
  });
}

export function makeDefaultAddBatch<
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(db: HustroDb, table: Table<T>): (data: T[]) => Promise<IndexableType> {
  return wrapQuery(db, (data: T[]): Promise<IndexableType> => {
    return table.bulkPut(data).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(
          Operation.addBatch,
          table.name as HustroDbTablesUnion
        ),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  });
}

export function makeDefaultAddBatchMapped<
  K = unknown,
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  mapper: (item: K) => T
): (data: K[]) => Promise<IndexableType> {
  return wrapQuery(db, (data: K[]): Promise<IndexableType> => {
    return table.bulkPut(data.map(mapper)).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(
          Operation.addBatch,
          table.name as HustroDbTablesUnion
        ),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  });
}

export function makeDefaultAddOne<
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(db: HustroDb, table: Table<T>): (data: T) => Promise<IndexableType> {
  return wrapQuery(db, (data: T): Promise<IndexableType> => {
    return table.put(data).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.add, table.name as HustroDbTablesUnion),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  });
}

export function makeDefaultRemoveBatch<
  TValue extends IndexableType,
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  key: keyof T
): (ids: TValue[]) => Promise<TValue[]> {
  return wrapQuery(db, async (ids: TValue[]): Promise<TValue[]> => {
    await table
      .where(key as string)
      .anyOf(ids)
      .delete()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(
            Operation.removeBatch,
            table.name as HustroDbTablesUnion
          ),
          errorObj: e,
          additionalInfo: {
            query: {
              ids: ids,
            },
          },
        })
      );
    return ids;
  });
}

export function makeDefaultGetManyByIds<
  TValue extends IndexableType,
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  key: keyof T
): (ids: TValue[]) => Promise<T[]> {
  return wrapQuery(db, (ids: TValue[]): Promise<T[]> => {
    return table
      .where(key as string)
      .anyOf(ids)
      .toArray()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(
            Operation.getByIds,
            table.name as HustroDbTablesUnion
          ),
          errorObj: e,
          additionalInfo: {
            query: {
              ids: ids,
              key,
            },
          },
        })
      );
  });
}

export function makeGetManyUndeleted<
  TValue extends IndexableType,
  S = never, // enforce providing generic type explicitly
  T extends S = S,
>(
  db: HustroDb,
  table: Table<T>,
  key: keyof T
): (id: TValue) => Promise<T[]> {
  return wrapQuery(db, (id: TValue): Promise<T[]> => {
    return table
      .where(key as any)
      .equals(id)
      .toArray()
      .then((docs) => {
        return docs.filter((doc) => (doc as any).deleted === 0);
      })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(
            Operation.getByQuery,
            table.name as HustroDbTablesUnion
          ),
          errorObj: e,
          additionalInfo: {
            query: {
              parentId: id,
              key,
            },
          },
        })
      );
  });
}

function filterTable<T extends Table>(
  table: T,
  baseFilter: {} | undefined,
  localFilter: {} | undefined
): Collection | T {
  return localFilter || baseFilter
    ? table.where({ ...(baseFilter || {}), ...(localFilter || {}) })
    : table;
}
