import { DocumentModel } from 'shared/domain/document/documentModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { uploadFile } from 'serviceWorker/repository/file/postFile';
import { UploadStatus } from 'shared/types/uploadStatus';
import { setDocumentDrawingUploadStatus } from './setDocumentDrawingUploadStatus';
import { confirmUpload, failUpload } from './uploadConfirm';
import { IDocumentUploader } from './uploadDocument';

type DrawingData = { isDrawn: boolean; drawingSrc: string };

export interface UploadDrawing {
  (
    uploader: IDocumentUploader,
    document: DocumentModel,
    url: string,
    data: DrawingData
  ): Promise<boolean>;
}

export const uploadDrawing: UploadDrawing = async function _uploadDrawing(
  uploader: IDocumentUploader,
  document: DocumentModel,
  url: string,
  data: DrawingData
): Promise<boolean> {
  const signedData = await uploader.PUT(url, { isDrawn: data.isDrawn });

  const imageData: Response = await fetch(data.drawingSrc);
  const imageBlob: Blob = await imageData.blob();
  const file = new File([imageBlob], `drawing_${document._id}`, {
    type: document.type,
  });
  try {
    await uploadFile(
      new AbortController(),
      uploader.POST_FILE,
      signedData.signedRequest.fields,
      signedData.signedRequest.url,
      file
    );
    await confirmUpload(uploader, url);
    await setDocumentDrawingUploadStatus(
      document.localId,
      UploadStatus.success,
      SyncStatus.SUCCESS
    );
    return true;
  } catch (e) {
    await failUpload(uploader, url);
    await setDocumentDrawingUploadStatus(
      document.localId,
      UploadStatus.failed,
      SyncStatus.FAIL
    );
    return false;
  }
};
