import {
  HttpDeleteRequestModel,
  HttpGetRequestModel,
  HttpPostRequestModel,
  HttpPutRequestModel,
  __HttpRequestModel__,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { ContractInDto } from 'shared/dtos/in/contract';
import {
  IContractUploader,
  UploadContract,
  UploadContractChanges,
  UploadContractDelete,
  UploadContractRestore,
} from 'serviceWorker/repository/contract/uploadContract';
import { IRequestRunner } from './httpRequestRunner';
import {
  ContractCreateOutDto,
  ContractEditOutDto,
} from 'shared/dtos/out/contract';

export type ContractCreateRequestModel = HttpPostRequestModel<
  {
    contractCreateOutDto: ContractCreateOutDto;
    projectId?: string;
    uniqueId: string;
  },
  HttpRequestModelType.contract
>;

export type ContractEditRequestModel = HttpPutRequestModel<
  {
    contractEditOutDto: ContractEditOutDto;
    projectId?: string;
    uniqueId: string;
  },
  HttpRequestModelType.contract
>;

export type ContractDeleteRequestModel = HttpDeleteRequestModel<
  {
    contractId: string;
    projectId?: string;
    uniqueId: string;
  },
  HttpRequestModelType.contract
>;

export type ContractRestoreRequestModel = HttpGetRequestModel<
  {
    contractId: string;
    projectId?: string;
    uniqueId: string;
  },
  HttpRequestModelType.contract
>;
export class ContractRequestRunner implements IRequestRunner {
  constructor(
    private contractUploader: IContractUploader,
    private uploadContractUseCase: UploadContract,
    private saveContract: (data: ContractInDto) => Promise<unknown>,
    private uploadContractChangesUseCase: UploadContractChanges,
    private uploadContractDeleteUseCase: UploadContractDelete,
    private deleteContract: (contractId: string) => Promise<unknown>,
    private uploadContractRestoreUseCase: UploadContractRestore,
    private restoreContract: (contractId: string) => Promise<unknown>
  ) {}
  async execute(
    request:
      | __HttpRequestModel__
      | ContractCreateRequestModel
      | ContractEditRequestModel
      | ContractDeleteRequestModel
      | ContractRestoreRequestModel
  ): Promise<unknown> {
    switch (request.method) {
      case 'POST': {
        return this.uploadContract(request as ContractCreateRequestModel);
      }
      case 'PUT': {
        return this.uploadContractChanges(
          request as ContractEditRequestModel
        );
      }
      case 'DELETE': {
        return this.uploadContractDelete(
          request as ContractDeleteRequestModel
        );
      }
      case 'GET': {
        return this.uploadContractRestore(
          request as ContractRestoreRequestModel
        );
      }
    }
  }

  private async uploadContract(
    request: ContractCreateRequestModel
  ): Promise<ContractInDto> {
    const contractInDto = await this.uploadContractUseCase(
      this.contractUploader,
      request.data.contractCreateOutDto,
      request.url
    );
    const result = await this.saveContract(contractInDto);
    return contractInDto;
  }

  private async uploadContractChanges(
    request: ContractEditRequestModel
  ): Promise<unknown> {
    return this.uploadContractChangesUseCase(
      this.contractUploader,
      request.data.contractEditOutDto,
      request.url
    );
  }

  private async uploadContractDelete(
    request: ContractDeleteRequestModel
  ): Promise<boolean> {
    await this.uploadContractDeleteUseCase(
      this.contractUploader,
      request.url
    );
    await this.deleteContract(request.data.contractId);
    return true;
  }

  private async uploadContractRestore(
    request: ContractRestoreRequestModel
  ): Promise<boolean> {
    // const url = `/project/${request.data.projectId}/contract/${request.data.contractId}/restore`;

    await this.uploadContractRestoreUseCase(
      this.contractUploader,
      request.url
    );
    await this.restoreContract(request.data.contractId);
    return true;
  }
}
