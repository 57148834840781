import React, { useEffect, useRef, useState } from 'react';
import Dispatcher from '../../helpers/dispatcher';
import useFormEdit from '../../views/issue/forms/useFormEdit';
import { useHistory } from 'react-router-dom';
import { useStore } from 'react-redux';
import { Action, Location } from 'history';
import { flushLogs } from 'setup/logger/flushLogs';

type NavigationPromptState = {
  action: null | Action;
  isActive: boolean;
  customConfirm: boolean;
  onConfirm: () => void;
  nextLocation: { pathname: string | null };
  unblock: () => void;
};

const initState: NavigationPromptState = {
  action: null,
  isActive: false,
  customConfirm: false,
  onConfirm: (): void => {},
  nextLocation: { pathname: '/' },
  unblock: (): void => {},
};

export const leaveDialogDispatcher = new Dispatcher();

type NavigationPromptProps = {
  children: (arg: {
    isActive: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  }) => React.ReactElement;
};

const NavigationPrompt = ({
  children,
}: NavigationPromptProps): null | React.ReactElement => {
  const [cState, setComponentState] =
    useState<NavigationPromptState>(initState);
  const _isMounted = useRef(false);
  const history = useHistory();
  const store = useStore();
  const { clearAllForms } = useFormEdit();

  const onBeforeUnload = (event: {
    returnValue: string;
  }): string | void => {
    const {
      ui: { isFormEditing },
    } = store.getState();

    if (Object.values(isFormEditing).every((value) => !value)) {
      return;
    }

    const msg =
      'Do you want to leave this site?\n\nChanges you made may not be saved.';
    event.returnValue = msg;

    flushLogs();
    return msg;
  };

  useEffect(() => {
    leaveDialogDispatcher.addListener('show', showDialog);

    return (): void => {
      leaveDialogDispatcher.removeListener('show', showDialog);
    };
  }, []);

  const showDialog = ({ onConfirm }: { onConfirm: () => void }): void => {
    setComponentState((currentState) => ({
      ...currentState,
      onConfirm,
      isActive: true,
      customConfirm: true,
      nextLocation: { pathname: null },
    }));
  };

  useEffect(() => {
    _isMounted.current = true;
    window.addEventListener('beforeunload', onBeforeUnload);
    setComponentState((s) => ({ ...s, unblock: history.block(block) }));

    return (): void => {
      _isMounted.current = false;

      cState.unblock();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };

    // eslint-disable-next-line
  }, []);

  const block = (
    nextLocation: Location,
    action: Action
  ): false | void | string => {
    const {
      ui: { isFormEditing },
    } = store.getState();

    if (Object.values(isFormEditing).some((value) => value)) {
      setComponentState((s) => ({
        ...s,
        action,
        nextLocation,
        isActive: true,
      }));

      return false;
    }
  };

  const unblock = (): void => {
    history.block(block);
  };

  const navigateToNextLocation = (): void => {
    const { nextLocation } = cState;

    unblock();
    if (_isMounted.current) {
      setComponentState((componentState) => ({
        ...componentState,
        action: null,
        isActive: false,
        unblock: history.block(block),
      }));

      if (nextLocation.pathname) {
        clearAllForms();
        history.push(nextLocation.pathname);
      }
    }
  };

  const onCancel = (): void => {
    setComponentState(initState);
  };

  const onConfirm = (): void => {
    if (cState.customConfirm) {
      setComponentState(initState);
      cState.onConfirm();

      return;
    }

    navigateToNextLocation();
  };

  if (!cState.isActive) {
    return null;
  }

  return (
    <div>
      {children({
        isActive: cState.isActive,
        onConfirm,
        onCancel,
      })}
    </div>
  );
};

export default NavigationPrompt;
