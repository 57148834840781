import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  ApexSeriesAndLabels,
  Chart,
  ChartType,
} from 'shared/types/analytics';
import { ChartDetailsContext } from '../../views/analytics/chartDetails/ChartDetailsProvider';
import { customAccessFunction } from '../shared/access';
import { useGetChart } from '../shared/hooks';
import { listViewDonutChartOptions } from '../shared/options/donutChart';
import { GenericChartState } from '../shared/types';
import { useEnvironmentalAspectFilters } from './filters';
import { detailedViewEnvironmentalAspectChartOptions } from './options';
import { EnvironmentalAspectChartContext } from './types';

export function useEnvironmentalAspect(): EnvironmentalAspectChartContext {
  const processesWithEnvAspect = useProcessesWithField(
    IssueFieldNames.environmentalAspect
  );
  const access = useCallback(() => {
    return Boolean(processesWithEnvAspect.length);
  }, [processesWithEnvAspect]);
  const filters = useEnvironmentalAspectFilters(processesWithEnvAspect);
  const additionalParams = useMemo(
    () => ({
      visibleProcesses: processesWithEnvAspect.map(
        (process) => process._id
      ),
    }),
    [processesWithEnvAspect]
  );
  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(
      Chart.environmentalAspect,
      filters,
      additionalParams
    );

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewEnvironmentalAspectChartOptions
    : listViewDonutChartOptions;

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'environmental_aspect_chart',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: customAccessFunction(access),
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
