import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface OrganizationsServiceData extends ServiceData {}
const { add, clear, get, reset } =
  entityServiceFactory<OrganizationsServiceData>(
    db,
    'organizationsService'
  );

export { add, clear, get, reset };
