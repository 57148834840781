export function retry<T>(
  fn: () => Promise<T>,
  maxRetries = 5,
  delay = 100
): Promise<T> {
  return fn().catch((err: any) => {
    if (maxRetries === 0) {
      throw err;
    }
    return new Promise((resolve) => setTimeout(resolve, delay)).then(
      () => {
        return retry(fn, --maxRetries, delay);
      }
    );
  });
}
