import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
} from 'react';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { shouldChangeProject } from 'shared/domain/project/shouldChangeProject';
import { useLocation, useParams } from 'react-router-dom';
import { changeConfirmationDialog } from 'views/projects/selection/selectionConfirmationDialog';
import {
  useSelectedProject,
  withSelectedProject,
} from '../../dataProviders/withSelectedProject';
import { EntityOnProjectRoute, chooseEntity } from './model';
import { useDispatch } from 'react-redux';
import { useProjects } from 'components/dataProviders/withProjects';

const WithProjectChangeDialog: React.FC<PropsWithChildren<{}>> =
  withSelectedProject(({ children }) => {
    const { selectedProject } = useSelectedProject();
    const { projects } = useProjects();
    const selectedProjectId = selectedProject?._id;
    const createDialog = useDialog();
    const { projectId } = useParams<{
      projectId: string;
    }>();
    const location = useLocation();
    const dispatch = useDispatch();

    const openSwitchProjectDialog = useCallback(
      (
        projectIdFromParams: string,
        projectName: string | undefined,
        entity: EntityOnProjectRoute
      ) => {
        changeConfirmationDialog(
          createDialog,
          dispatch,
          projectIdFromParams,
          projectName,
          entity
        );
      },
      [createDialog, dispatch]
    );

    useEffect(() => {
      if (!selectedProjectId) {
        return;
      }

      if (!projects.items.length) {
        return;
      }

      const foundProject = projects.items.find(
        (project) => project._id === projectId
      );

      if (
        foundProject &&
        shouldChangeProject(selectedProjectId, projectId)
      ) {
        const entity = chooseEntity(location);
        if (!entity) return;
        openSwitchProjectDialog(projectId, foundProject.name, entity);
      }
    }, [
      projectId,
      location,
      selectedProjectId,
      projects,
      openSwitchProjectDialog,
    ]);

    return <>{children}</>;
  });

const withProjectChangeDialog =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithProjectChangeDialog>
      <Component {...props} />
    </WithProjectChangeDialog>
  );

export { withProjectChangeDialog };
