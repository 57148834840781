import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { makeDefaultSync } from './apiFactories';
import { GetInspectionEventDetail } from './types';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncInspections);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetInspectionEventDetail>(
      DomainMessagesTypes.getInspection,
      {
        detail: { id: e.id },
      }
    )
  );
};

export default { sync, getOne };
