import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { asyncLog } from 'shared/logger/asyncLog';
import { sharedLog } from 'shared/logger/sharedLog';
import {
  AdditionalSwLogInfo,
  LogLevel,
  SwLogger,
} from 'shared/types/logger';
import { swSentryLog } from './initSentryInSw';
import { swSumoLogicLogger } from './swSumoLogicLogger';

function makeSwLogger(): SwLogger {
  const broadcast = new BroadcastChannel(ChannelNames.currentUserChannel);
  let user_id = 'unknown';
  let user_email = 'unknown';
  broadcast.onmessage = (event: Message): void => {
    if (event.type === DomainMessagesTypes.currentUserPermissions) {
      user_id = event?.data?.userData?._id;
      user_email = event?.data?.userData?.email;
    }
  };

  const swLog = (
    msg: string,
    level: LogLevel,
    errorObject: any,
    additionalInfo: AdditionalSwLogInfo | null
  ): void => {
    // to be able to match a log with an async log we create logId by 'Date.now()'
    // this is not ideal, but it should be good enough for now.
    const logId = `${Date.now()}`;
    const full_error_object =
      errorObject instanceof Error
        ? JSON.stringify(
            errorObject,
            Object.getOwnPropertyNames(errorObject)
          )
        : errorObject;
    sharedLog({
      user_id,
      user_email,
      logId,
      msg,
      level,
      full_error_object,
      additionalInfo,
      loggers: [swSumoLogicLogger, { log: swSentryLog }],
    });

    asyncLog(swSumoLogicLogger, logId, user_id, user_email).catch((e) =>
      // eslint-disable-next-line no-console
      console.log('Could not async log', e)
    );
  };

  return swLog;
}

export const swLog = makeSwLogger();
