import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { UserEntity } from 'shared/domain/user/types/entity';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import * as users from 'serviceWorker/db/users';
import * as usersService from 'serviceWorker/db/usersService';

export async function getUsersSynchronized(): Promise<UserEntity[]> {
  const status = await usersService.get();

  if (!status?.isDownloaded) {
    self.dispatchEvent(new Event(RepositoryMessagesTypes.allUsers));
    await waitForAllUsers();
  }

  return users.get();
}

export async function getCurrentUsers(): Promise<UserEntity[]> {
  return users.get();
}

export async function waitForAllUsers(): Promise<void> {
  return new Promise((resolve) => {
    const broadcast = new BroadcastChannel(ChannelNames.userChannel);
    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.allUsers) {
        broadcast.close();
        resolve();
      }
    };
  });
}
