import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, ButtonProps, alpha } from '@mui/material';
import classNames from 'classnames';

const useStyles = makeStyles({
  textIconButton: {
    textTransform: 'none' as 'none',
    fontSize: '1rem',
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 2,
    // color: '#000000',
    '&:hover': {
      backgroundColor: alpha('#000000', 0.05),
    },
    flexShrink: 0,
    '& .MuiButton-startIcon': {
      marginRight: 8,
    },
    '&:active': {
      backgroundColor: alpha('#000000', 0.15),
    },
  },
  label: {
    top: 1,
    position: 'relative',
  },
  icon: {
    width: 24,
    height: 24,
  },
});

type ButtonTextIconProps = PropsWithChildren<
  Omit<ButtonProps, 'startIcon'> & {
    'data-qa'?: string;
    startIcon?: React.FC;
    startIconProps?: any;
  }
>;

const ButtonTextIcon = ({
  children,
  startIcon,
  startIconProps,
  className,
  ...props
}: ButtonTextIconProps): JSX.Element => {
  const classes = useStyles();
  const StartIcon = startIcon;

  return (
    <Button
      variant='text'
      color='secondary'
      className={classNames(classes.textIconButton, className)}
      startIcon={
        StartIcon && (
          <StartIcon
            {...startIconProps}
            className={classNames(classes.icon, startIconProps?.className)}
          />
        )
      }
      {...props}
    >
      <span className={classes.label}>{children}</span>
    </Button>
  );
};

export default ButtonTextIcon;
