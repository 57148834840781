import { CompanyEntity } from 'shared/domain/company/types/entity';
import { CompanyModel } from 'shared/domain/company/types/model';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { CompanyInDto } from 'shared/dtos/in/company';
import { UserEntity } from 'shared/domain/user/types/entity';

export function companyEntityToModel(
  entity: CompanyEntity,
  users: UserEntity[]
): CompanyModel {
  const {
    _id,
    contracts,
    createdAt,
    deleted,
    longLabel,
    modifiedAt,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    createdBy,
    modifiedBy,
    registeredOfficeAddress,
    taxNumber,
  } = entity;

  return {
    _id,
    // contracts,
    createdAt,
    deleted: Boolean(deleted),
    longLabel,
    modifiedAt,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    createdBy: toLabelledUser(
      users.find((user) => user._id === createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === modifiedBy)
    ),
    registeredOfficeAddress,
    taxNumber,
  };
}

export function companyInDtoToEntity(inDto: CompanyInDto): CompanyEntity {
  const {
    _id,
    contracts,
    createdAt,
    deleted,
    longLabel,
    modifiedAt,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    createdBy,
    modifiedBy,
    registeredOfficeAddress,
    taxNumber,
  } = inDto;

  return {
    _id,
    contracts,
    createdAt,
    deleted: deleted ? 1 : 0,
    longLabel,
    modifiedAt,
    projectInvolvementType,
    shortLabel,
    sites,
    correspondenceAddress,
    createdBy,
    modifiedBy,
    registeredOfficeAddress,
    taxNumber,
  };
}
