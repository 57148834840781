import { PropsWithChildren, ReactElement, useMemo } from 'react';
import { useDetectedResolved } from 'charts/detectedResolved/chart';
import { createContext, useContext } from 'react';
import { ChartContext, ContextRecord } from './types';
import { ChartFiltersUnion } from 'shared/types/analytics';
import { useSelector } from 'react-redux';
import { DetectedResolvedChartContext } from 'charts/detectedResolved/types';
import { useOriginator } from 'charts/originator/chart';
import { OriginatorChartContext } from 'charts/originator/types';
import { useEnvironmentalAspect } from 'charts/environmentalAspect/chart';
import { EnvironmentalAspectChartContext } from 'charts/environmentalAspect/types';
import { useHsEffect } from 'charts/hsEffect/chart';
import { HsEffectChartContext } from 'charts/hsEffect/types';
import { useHsHazardType } from 'charts/hsHazardType/chart';
import { HazardTypeChartContext } from 'charts/hsHazardType/types';
import { useImpact } from 'charts/impact/chart';
import { ImpactChartContext } from 'charts/impact/types';
import { useRootCause } from 'charts/rootCause/chart';
import { RootCauseChartContext } from 'charts/rootCause/types';
import { useWorkType } from 'charts/workType/chart';
import { WorkTypeChartContext } from 'charts/workType/types';
import { currentProjectUserSelector } from '../../redux/selectors/users';
import { UserInDto } from 'shared/dtos/in/user';
import { useResolutionTime } from '../../charts/resolutionTime/chart';
import { ResolutionTimeChartContext } from '../../charts/resolutionTime/types';

const ChartsContext = createContext<ContextRecord>({});

export function useChartsContext(): ContextRecord {
  const context: ContextRecord = useContext(ChartsContext);
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);

  return Object.entries(context).reduce<ContextRecord>(
    (filteredContext, [chartKey, chartContext]) => {
      if (chartContext.canAccess(currentUser)) {
        filteredContext[chartKey] = chartContext;
      }

      return filteredContext;
    },
    {}
  );
}

export function useChartContext(
  id: string
): ChartContext<ChartFiltersUnion> | undefined {
  const context = useContext(ChartsContext);
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);

  const chartContext = context[id];
  if (chartContext && chartContext.canAccess(currentUser))
    return context[id];
}

export function ChartsProvider({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const detectedResolved: DetectedResolvedChartContext =
    useDetectedResolved();
  const resolutionTime: ResolutionTimeChartContext = useResolutionTime();
  const originator: OriginatorChartContext = useOriginator();
  const rootCause: RootCauseChartContext = useRootCause();
  const impact: ImpactChartContext = useImpact();
  const environmentalAspect: EnvironmentalAspectChartContext =
    useEnvironmentalAspect();
  const workType: WorkTypeChartContext = useWorkType();
  const hsEffect: HsEffectChartContext = useHsEffect();
  const hazardType: HazardTypeChartContext = useHsHazardType();

  const theValue: ContextRecord = useMemo(
    (): ContextRecord => ({
      detectedResolved,
      resolutionTime,
      rootCause,
      originator,
      impact,
      environmentalAspect,
      workType,
      hsEffect,
      hazardType,
    }),
    [
      detectedResolved,
      resolutionTime,
      originator,
      rootCause,
      impact,
      environmentalAspect,
      workType,
      hsEffect,
      hazardType,
    ]
  );

  return (
    <ChartsContext.Provider value={theValue}>
      {children}
    </ChartsContext.Provider>
  );
}
