import { ReactElement, useMemo } from 'react';

import DNSIcon from '@mui/icons-material/DnsOutlined';
import { Box, Button, Typography } from '@mui/material';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { useIsOffline } from '../../../common/withIsOffline';
import ForbiddenTooltip from '../../../general/ForbiddenTooltip/ForbiddenTooltip';
const sx = { display: { xs: 'none', sm: 'inline' } };

function getXsFontSize(projectName: string): number {
  const length = projectName.length;

  const fontSizeMap = [
    { maxLength: 10, fontSize: 16 },
    { maxLength: 20, fontSize: 14 },
    { maxLength: Infinity, fontSize: 12 },
  ];

  for (const range of fontSizeMap) {
    if (length <= range.maxLength) {
      return range.fontSize;
    }
  }
  return 12;
}
function getMdFontSize(projectName: string): number {
  const length = projectName.length;

  const fontSizeMap = [
    { maxLength: 10, fontSize: 18 },
    { maxLength: 20, fontSize: 16 },
    { maxLength: 30, fontSize: 14 },
    { maxLength: Infinity, fontSize: 12 },
  ];

  for (const range of fontSizeMap) {
    if (length <= range.maxLength) {
      return range.fontSize;
    }
  }
  return 12;
}

function CurrentProjectButton(): ReactElement {
  const isOffline = useIsOffline();
  const { name: projectName, organizationId } = useSelector(
    projectDataSelector
  );

  const { organizationsMap } = useOrganizations();
  const logoUsedInPdfHeadersSignedUrl =
    organizationsMap.get(organizationId)?.logoUsedInPdfHeadersSignedUrl;
  const history = useHistory();
  const selectProject = (): void => {
    history.push('/select-project');
  };

  const disabled = isOffline;
  const startIcon = useMemo(() => {
    if (logoUsedInPdfHeadersSignedUrl) {
      return (
        <Box sx={sx}>
          <img
            style={{
              maxHeight: '48px',
              maxWidth: '96px',
              backgroundColor: 'white',
              borderRadius: '4px',
              display: 'block',
            }}
            src={logoUsedInPdfHeadersSignedUrl}
          />
        </Box>
      );
    }
    return <DNSIcon sx={sx} />;
  }, [logoUsedInPdfHeadersSignedUrl]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        flex: '1 0 200px',
        height: '100%',
        width: '100%',
      }}
    >
      <ForbiddenTooltip
        visibleWhen={isOffline}
        reason='general_offline_operation_unavailable'
      >
        <Button
          startIcon={startIcon}
          onClick={selectProject}
          disabled={disabled}
          data-qa='current-project-button'
          sx={{
            display: 'inline-flex',
            maxWidth: { xs: '60%', md: '40%' },
            alignItems: 'center',
            justifyContent: 'end',
          }}
        >
          <Typography
            sx={{
              textTransform: 'none',
              fontWeight: 600,
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
              fontSize: {
                md: getMdFontSize(projectName),
                xs: getXsFontSize(projectName),
                // on lg user label is shown and we want to keep them same size
                // lg: 12,
              },
              mx: 1,
              overflow: 'hidden',
              maxWidth: '100%',
            }}
          >
            {projectName}
          </Typography>
        </Button>
      </ForbiddenTooltip>
    </Box>
  );
}

export default CurrentProjectButton;
