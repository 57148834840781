import { HashMap, Identificable } from 'shared/types/commonView';

export const findInsertionIndex = (
  orderedArray: string[],
  toInsert: string
): number => {
  let index = 0;

  orderedArray.forEach((el) => {
    if (el.localeCompare(toInsert) >= 0) {
      return index;
    }

    index += 1;
  });

  return index;
};

export const insertAt = <T>(
  array: T[],
  index: number,
  element: T
): void => {
  array.splice(index, 0, element);
};

export const transformArrayToHashMap = <T extends Identificable>(
  list: T[]
): HashMap<T> =>
  list.reduce((result: HashMap<T>, currentValue: T) => {
    result[currentValue._id] = currentValue;
    return result;
  }, {});
