import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { ChannelNames } from 'shared/domain/channelNames';
import { Broadcaster, OnMessage } from './types';

const broadcastRecalcuation: Broadcaster = (_): void => {
  self.dispatchEvent(
    new Event(RepositoryMessagesTypes.calculateAnalytics)
  );
};

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.allIssues: {
        broadcastRecalcuation(broadcast);
        return;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;
