import db from './index';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { UserPermissions } from 'serviceWorker/services/permissions/types';
import { wrapQuery } from './queryWrapper';
import {
  makeDefaultClear,
  makeDefaultGetOne,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

export type PermissionsData = {
  _id: string;
} & UserPermissions;

const clear = makeDefaultClear(db, db.permissions);

export const getOne = makeDefaultGetOne<string, PermissionsData>(
  db,
  db.permissions,
  '_id'
);

export const addBatch = wrapQuery(
  db,
  async (data: PermissionsData[]): Promise<void> => {
    db.transaction(
      'rw',
      db.permissions,
      async function permissionsAddTransaction() {
        await clear();
        return db?.permissions?.bulkPut(data).catch((e) =>
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.addBatch, 'permissions'),
            errorObj: e,
            additionalInfo: null,
          })
        );
      }
    );
  }
);
