import { AppBar, Toolbar } from '@mui/material';
import React from 'react';
import { Navigator } from '../index';
import HeaderInfo from './HeaderInfo/HeaderInfo';
import { MENU_BAR_HEADER_HEIGHT_PX } from './styles';

const MenuBar = (): React.ReactElement => {
  return (
    <AppBar elevation={1} data-qa='menubar'>
      <Toolbar
        disableGutters={true}
        sx={{
          height: MENU_BAR_HEADER_HEIGHT_PX,
        }}
      >
        <Navigator />
        <HeaderInfo />
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(MenuBar);
