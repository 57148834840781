import { ReactElement, useMemo } from 'react';

import { MemoStatefulAutocompleteWithText } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import {
  InspectionProtocolFieldProps,
  ProtocolItemFormProps,
} from './types';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import {
  COMPLIANCE_CHECK_RESULT_PREFIX,
  COMPLIANCE_CHECK_FORM_PREFIX,
} from '../ComplianceCheck';
import { MemoComplianceCheckFormNotPassed } from './notPassed';
import { InputFormGraphicUploader } from 'components/common/graphicUploader';
import { useInspection } from 'components/dataProviders/withInspection';
import { createComplianceCheckDocumentsBaseUrl } from 'views/inspections/single/helpers';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { DocumentToProtocolFromUrlBinder } from 'shared/domain/document/documentBinder';
import { PROTOCOL_ITEM_UPLOADER_PREFIX } from 'components/dataProviders/withInspectionSubmit/model';

export function ComplianceCheckForm({
  checklistItemId,
}: ProtocolItemFormProps): ReactElement {
  const { values } = useInputForm();
  const { inspection } = useInspection();
  const protocolItem = inspection?.protocol?.find((protocol) => {
    return protocol.templateChecklistItem._id === checklistItemId;
  });
  const resultKey = `${COMPLIANCE_CHECK_RESULT_PREFIX}-${checklistItemId}`;
  const result: ComplianceCheckResult | undefined = values[resultKey];
  const commentFieldProps: InspectionProtocolFieldProps = useMemo(() => {
    return {
      formKey: `${COMPLIANCE_CHECK_FORM_PREFIX}-comment-${checklistItemId}`,
      required: false,
      labelId: 'inspection_comment',
      fieldName: `text-field-${COMPLIANCE_CHECK_FORM_PREFIX}-comment-${checklistItemId}`,
      disabled: false,
      localStorageKey: 'inspection-checklistItem-comment',
      reserveSpaceForHelperText: true,
      dense: true,
      'data-qa': 'inspection_checklistItem_comment_field',
    };
  }, [checklistItemId]);

  const documentsKey = `${COMPLIANCE_CHECK_FORM_PREFIX}-documents-${checklistItemId}`;
  const GU = useGraphicUploader(values[documentsKey], {
    baseUrl: createComplianceCheckDocumentsBaseUrl(
      inspection?._id || '',
      protocolItem?._id || ''
    ),
    dataQaPrefix: `protocol-item-of-${checklistItemId}-`,
    documentBinderFactory: (baseUrl?: string) => {
      if (!baseUrl) throw new Error('From url binder requires URL');
      return new DocumentToProtocolFromUrlBinder(baseUrl);
    },
  });

  if (!result || result === ComplianceCheckResult.unset) {
    return <div></div>;
  }

  if (result === ComplianceCheckResult.notPassed) {
    return (
      <MemoComplianceCheckFormNotPassed
        checklistItemId={checklistItemId}
        resultKey={resultKey}
      />
    );
  }

  return (
    <div>
      <MemoStatefulAutocompleteWithText {...commentFieldProps} />
      <InputFormGraphicUploader
        uploaderKey={`${PROTOCOL_ITEM_UPLOADER_PREFIX}-${checklistItemId}`}
        GU={GU}
      />
    </div>
  );
}
