import { Button } from '@mui/material';
import { MemoPlusIcon } from 'components/core/PlusIcon';
import useIsMobile from 'hooks/useIsMobile';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import ButtonIcon from '../ButtonIcon/ButtonIcon';
import { MemoForbiddenTooltip } from '../ForbiddenTooltip/ForbiddenTooltip';
import { useStyles } from './styles';
import { ButtonPlusCreateProps } from './types';

function ButtonPlusCreate(props: ButtonPlusCreateProps): ReactElement {
  const isMobile = useIsMobile();
  const { id, textId, canCreate, creationAction, dataQaId } = props;
  const classes = useStyles({ isMobile });
  const intl = useIntl();

  const { reason, permission } = canCreate();
  const disabled = !permission;
  const CREATE_TEXT = intl.formatMessage({ id: textId });

  return (
    <MemoForbiddenTooltip visibleWhen={disabled} reason={reason} inlined>
      {isMobile ? (
        <ButtonIcon
          disabled={disabled}
          id={id}
          data-qa={dataQaId}
          onClick={creationAction}
          className={classes.iconButton}
        >
          <MemoPlusIcon disabled={disabled} />
        </ButtonIcon>
      ) : (
        <Button
          className={classes.button}
          disabled={disabled}
          variant='text'
          id={id}
          data-qa={dataQaId}
          onClick={creationAction}
          startIcon={<MemoPlusIcon disabled={disabled} />}
        >
          {CREATE_TEXT}
        </Button>
      )}
    </MemoForbiddenTooltip>
  );
}

export const MemoButtonPlusCreate = React.memo(ButtonPlusCreate);
