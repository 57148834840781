import { SitesRenderer } from 'components/table/renderers/cell/sites';
import { ColumnShapeSkeleton } from '../types';

export function getSitesColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 192,
    flexGrow: 0,
    flexShrink: 0,
    sortable: false,
    resizable: true,
    cellRenderer: SitesRenderer,
    labelId: 'data_scope_sites',
    dataKey: 'sites',
    style: {
      justifyContent: 'flex-start',
    },
  };
}
