import {
  DocumentationCreateModel,
  DocumentationDeleteModel,
  DocumentationEditModel,
  DocumentationMoveModel,
} from 'shared/domain/documentation/documentationModel';
import {
  CreateDocumentationCustomEventDetail,
  ArchiveDocumentationCustomEventDetail as DeleteDocumentationCustomEventDetail,
  EditDocumentationCustomEventDetail,
  MoveDocumentationCustomEventDetail,
} from 'shared/domain/messages/documentation/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncDocumentations);

const create = (e: {
  documentation: DocumentationCreateModel;
  uniqueId: string;
}): void => {
  debugLog('CREATE DOCUMENTATION EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateDocumentationCustomEventDetail>(
      RepositoryMessagesTypes.addDocumentation,
      {
        detail: { ...e },
      }
    )
  );
};

const edit = (e: DocumentationEditModel): void => {
  debugLog('EDIT DOCUMENTATION EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditDocumentationCustomEventDetail>(
      RepositoryMessagesTypes.editDocumentation,
      {
        detail: { ...e },
      }
    )
  );
};

const setParent = (e: DocumentationMoveModel): void => {
  debugLog('SET PARENT DOCUMENTATION EVENT', e);
  self.dispatchEvent(
    new CustomEvent<MoveDocumentationCustomEventDetail>(
      RepositoryMessagesTypes.setParent,
      {
        detail: {
          parentId: e.parentId,
          directories: e.directories,
          documentations: e.documentations,
        },
      }
    )
  );
};

const remove = (e: DocumentationDeleteModel): void => {
  debugLog('DELETE DOCUMENTATION EVENT', e);
  self.dispatchEvent(
    new CustomEvent<DeleteDocumentationCustomEventDetail>(
      RepositoryMessagesTypes.deleteDocumentations,
      {
        detail: {
          directories: e.directories,
          documentations: e.documentations,
        },
      }
    )
  );
};

export default { sync, create, edit, remove, setParent };
