import { Dispatch, SetStateAction } from 'react';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';
import {
  ErrorPresentation,
  requiredValidation,
  stringMinMaxValidation,
} from '../../../helpers/validators';
import { SetableIssueFields } from './types';

export const handleFieldValidation = (
  key: keyof SetableIssueFields,
  value: any
): ErrorPresentation => {
  const val: Record<
    keyof SetableIssueFields,
    (value: any, ...args: any) => ErrorPresentation
  > = {
    process: requiredValidation,
    site: requiredValidation,
    level: requiredValidation,
    title: (value) =>
      requiredValidation(value) ||
      stringMinMaxValidation(FieldSize.M, value),
  };

  return val[key] && val[key](value);
};

export const handleFormValidation = (
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: SetableIssueFields
): boolean => {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = handleFieldValidation(
        key as keyof SetableIssueFields,
        value
      );
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
};
