import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import hashSum from 'hash-sum';
import { ChannelNames } from 'shared/domain/channelNames';
import { ConfigData as ConfigRepositoryData } from 'serviceWorker/db/config';
import { ServiceData } from 'serviceWorker/db/service';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { LogLevel } from 'shared/types/logger';
import { isSameAsPrev } from './isSameAsPrev';
import { HeadersData, NormalizedEntityResponseData } from './types';

type MakeSynchronizeEntityProps<T = unknown> = {
  configRepository: {
    get: () => Promise<void | ConfigRepositoryData>;
  };
  entityRepository: {
    clear: () => Promise<void>;
    removeBatch: (batch: any[]) => Promise<any>;
    updateBatch: (batch: T[]) => Promise<any>;
    quantity: () => Promise<number>;
  };
  entityService: {
    get: () => Promise<undefined | ServiceData>;
    reset: () => Promise<void>;
    add: (data: Partial<ServiceData>) => Promise<void>;
    addSync: (data: Partial<ServiceData>) => Promise<void>;
    clear: () => Promise<void>;
  };
  pullEntityHandler: () => Promise<void>;
  fetchUpdatedEntities: (
    data: HeadersData,
    syncKey: string,
    abortController: AbortController
  ) => Promise<NormalizedEntityResponseData<T>> | undefined;
  finishedServiceStateFactory: (
    total: number,
    syncKey: string
  ) => Partial<ServiceData>;
  entityName: string;
  channelName: ChannelNames;
  addBroadcast: (broadcast: CallableFunction) => void;
  emitAllBroadcasts: () => void;
  clearBroadcasts: () => void;
  canSyncronize?: () => Promise<boolean>;
};

export function makeSynchronizeEntity<T = unknown>({
  configRepository,
  entityService,
  entityRepository,
  pullEntityHandler,
  fetchUpdatedEntities,
  finishedServiceStateFactory,
  entityName,
  channelName,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
  canSyncronize,
}: MakeSynchronizeEntityProps<T>): (
  broadcaster: CallableFunction
) => Promise<void> {
  if (!canSyncronize) {
    canSyncronize = () => Promise.resolve(true);
  }

  const prevUpdate = {
    hashString: '',
    itemsString: '',
  };

  return async function synchronize(
    broadcaster: CallableFunction
  ): Promise<void> {
    if (!(await canSyncronize!())) {
      return;
    }

    addBroadcast(broadcaster);

    const [setup, service] = await Promise.all([
      configRepository.get(),
      entityService.get(),
    ]);
    const syncKey = service?.syncKey;

    if (setup && !service?.isDownloading && !syncKey) {
      await pullEntityHandler();
      return;
    }

    if (syncKey && setup) {
      const newState = {
        ...service,
        isDownloading: true,
      };
      await entityService.addSync(newState);
      const broadcast = new BroadcastChannel(channelName);
      broadcast.postMessage({
        data: newState,
        type: DomainMessagesTypes.state,
      });
      const abortController = new AbortController();

      const promise = fetchUpdatedEntities(
        setup,
        syncKey,
        abortController
      );

      if (!promise) {
        return;
      }
      const abort = (): void => {
        abortController.abort();
        clearBroadcasts();
      };
      self.addEventListener(DomainMessagesTypes.clearData, abort);
      self.addEventListener(
        RepositoryMessagesTypes.clearSelectedProjectData,
        abort
      );
      self.addEventListener(DomainMessagesTypes.logout, abort);

      return promise
        .then(async (data: NormalizedEntityResponseData<T>) => {
          self.removeEventListener(DomainMessagesTypes.clearData, abort);
          self.removeEventListener(
            RepositoryMessagesTypes.clearSelectedProjectData,
            abort
          );
          self.removeEventListener(DomainMessagesTypes.logout, abort);
          // Request was made without required values like projectId
          if (!data || abortController.signal.aborted) {
            return;
          }

          if (data?.entitiesWithoutAccess.length) {
            await entityRepository.removeBatch(data.entitiesWithoutAccess);
          }
          if (data?.items.length) {
            const itemsString = JSON.stringify(data.items);
            const hashString = hashSum(data.items);

            if (!isSameAsPrev({ itemsString, hashString }, prevUpdate)) {
              prevUpdate.hashString = hashString;
              prevUpdate.itemsString = itemsString;
              await entityRepository.updateBatch(data.items);
            }
          }
          const quantity = await entityRepository.quantity();
          const finishedState = finishedServiceStateFactory(
            quantity,
            data?.syncKey
          );
          await entityService.addSync(finishedState);
          emitAllBroadcasts();
          broadcast.postMessage({
            data: finishedState,
            type: DomainMessagesTypes.state,
          });
          broadcast.close();
          return;
        })
        .catch((e: any) => {
          prevUpdate.hashString = '';
          prevUpdate.itemsString = '';
          broadcast.close();
          entityService.addSync({ isDownloading: false });
          swLog(
            `Problem occured when syncing ${entityName}`,
            LogLevel.ERROR,
            e,
            null
          );
        });
    }
  };
}
