import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { useCreateUser } from 'components/dataCreationForms/user/withCreateUser';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { MemoUserForm } from 'components/user/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

function CreateUserWizardDialog(): React.ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
    formName,
  } = useCreateUser();

  useFormCleanupOnUnmount(formName);

  return (
    <Dialog open={open} fullWidth onClose={closeDialog}>
      <WithInputForm
        values={initialValues}
        errors={{}}
        handleFormValidation={handleFormValidation}
        handleFieldValidation={validate}
        onSubmit={submitForm}
        eventType={SUBMIT_EVENT_TYPE}
        dispatchSubmit={releaseSubmitEvent}
        formName={formName}
      >
        <DialogTitle>
          <FormattedMessage id='add_user_section' />
        </DialogTitle>

        <Divider />

        <DialogContent>
          <MemoUserForm
            isDialog={true}
            editedUserId={null}
            existingRoleId={null}
          />
        </DialogContent>

        <Divider />

        <DialogActions>
          <DialogButtons
            isPosting={isPosting}
            onSubmit={releaseSubmitEvent}
            onCancel={closeDialog}
          />
        </DialogActions>
      </WithInputForm>
    </Dialog>
  );
}

export const MemoCreateUserWizardDialog = memo(CreateUserWizardDialog);
