// We keep this commented to know how in the future.

const paths = [
  // {
  //   path: new RegExp('.*/project/[a-z,0-9]*/company'),
  //   handler: companyHandler,
  // },
  // {
  //   path: new RegExp('.*/project/[a-z,0-9]*/contract/[a-z,0-9]*'),
  //   handler: contractHandler,
  // },
];

export const init = (): void => {
  // self.addEventListener('fetch', async (event: any) => {
  //   const { pathname } = new URL(event.request.url);
  //   const handler = paths.find(path => pathname.match(path.path));
  //   if (handler && event.request.method === 'GET') {
  //     event.respondWith(
  //       new Promise(async resolve => {
  //         const resp = await handler.handler(pathname);
  //         if (!resp) {
  //           return resolve(fetch(event.request));
  //         }
  //         const blob = new Blob([JSON.stringify(resp, null, 2)], {
  //           type: 'application/json',
  //         });
  //         const response = new Response(blob);
  //         resolve(response);
  //       })
  //     );
  //   }
  // });
};
