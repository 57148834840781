import { DocumentModel } from 'shared/domain/document/documentModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { UploadStatus } from 'shared/types/uploadStatus';
import { setDocumentDrawingUploadStatus } from './setDocumentDrawingUploadStatus';
import { IDocumentUploader } from './uploadDocument';

export interface UploadClearDrawing {
  (
    uploader: IDocumentUploader,
    document: DocumentModel,
    url: string
  ): Promise<boolean>;
}

export const uploadClearDrawing: UploadClearDrawing =
  async function _uploadClearDrawing(
    uploader: IDocumentUploader,
    document: DocumentModel,
    url: string
  ): Promise<boolean> {
    try {
      await uploader.PUT(url, { isDrawn: false });
      await setDocumentDrawingUploadStatus(
        document.localId,
        UploadStatus.success,
        SyncStatus.SUCCESS
      );
      return true;
    } catch (e) {
      await setDocumentDrawingUploadStatus(
        document.localId,
        UploadStatus.failed,
        SyncStatus.FAIL
      );
      return false;
    }
  };
