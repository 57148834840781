import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';

export const useLabelledCardListStyles = makeStyles<DefaultTheme>(() =>
  createStyles({
    root: {
      minHeight: '100%',
    },
    fieldBoxes: {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px',
      marginTop: '24px',
      alignItems: 'center',
      gap: '8px',
    },
  })
);
