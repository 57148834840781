import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import React, { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import useIsMobile from '../../../../hooks/useIsMobile';
import { ArrowDropDown } from '@mui/icons-material';
import { Divider, ListItemText, Menu, MenuItem } from '@mui/material';
import { usePopover } from '../../../../hooks/usePopover';

const useStyles = makeStyles({
  submitButtonSpacing: { marginRight: '19px' },
  divider: { margin: '0px 16px' },
});

function ButtonWithPopover(props: {
  posting: boolean;
  onSaveDraft: () => void;
  onPublish: () => void;
}): React.ReactElement {
  const { posting, onSaveDraft, onPublish } = props;
  const classes = useStyles();
  const { attachPopover, closePopover, popoverOpen, anchorEl } =
    usePopover();

  const handleSaveDraftClick = useCallback(() => {
    closePopover();
    onSaveDraft();
  }, [closePopover, onSaveDraft]);

  const handlePublishClick = useCallback(() => {
    closePopover();
    onPublish();
  }, [closePopover, onPublish]);

  return (
    <>
      <MemoButtonContained
        onClick={attachPopover}
        endIcon={
          posting ? <CircularProgress size={16} /> : <ArrowDropDown />
        }
        disabled={posting}
      >
        <FormattedMessage id='general_save' />
      </MemoButtonContained>
      <Menu open={popoverOpen} anchorEl={anchorEl} onClose={closePopover}>
        <MenuItem onClick={handleSaveDraftClick}>
          <ListItemText>
            <FormattedMessage id='general_save_as_draft' />
          </ListItemText>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem onClick={handlePublishClick}>
          <ListItemText>
            <FormattedMessage id='general_publish' />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export function SubmitButtons(props: {
  onSaveDraft: () => void;
  uploading: boolean;
  publishing: boolean;
  onPublish: () => void;
}): React.ReactElement {
  const classes = useStyles();
  const intl = useIntl();
  const isMobile = useIsMobile();
  const { onSaveDraft, publishing, onPublish, uploading } = props;
  const posting = uploading || publishing;

  const mobileButton = (
    <ButtonWithPopover
      posting={posting}
      onSaveDraft={onSaveDraft}
      onPublish={onPublish}
    />
  );

  const desktopButtons = (
    <>
      <Button
        onClick={onSaveDraft}
        className={classes.submitButtonSpacing}
        endIcon={uploading ? <CircularProgress size={16} /> : undefined}
        disabled={posting}
      >
        {intl.formatMessage({ id: 'general_save_as_draft' })}
      </Button>
      <MemoButtonContained
        onClick={onPublish}
        endIcon={publishing ? <CircularProgress size={16} /> : undefined}
        disabled={posting}
      >
        {intl.formatMessage({ id: 'general_publish' })}
      </MemoButtonContained>
    </>
  );

  return isMobile ? mobileButton : desktopButtons;
}
