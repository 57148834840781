import { customAccessFunction } from 'charts/shared/access';
import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import { useCallback, useContext, useEffect, useState } from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { ApexSeriesAndLabels, Chart } from 'shared/types/analytics';
import { ChartType } from 'shared/types/analytics';
import { ChartDetailsContext } from '../../views/analytics/chartDetails/ChartDetailsProvider';
import { useGetChart } from '../shared/hooks';
import { listViewDonutChartOptions } from '../shared/options/donutChart';
import { GenericChartState } from '../shared/types';
import { useImpactFilters } from './filters';
import { detailedViewImpactChartOptions } from './options';
import { ImpactChartContext } from './types';

export function useImpact(): ImpactChartContext {
  const processesWithImpact = useProcessesWithField(
    IssueFieldNames.impact
  );
  const access = useCallback(() => {
    return Boolean(processesWithImpact.length);
  }, [processesWithImpact]);
  const filters = useImpactFilters();
  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(Chart.impact, filters);

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewImpactChartOptions
    : listViewDonutChartOptions;

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'impact_chart',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: customAccessFunction(access),
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
