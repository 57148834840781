import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';
import { BroadcastChannel } from 'broadcast-channel';
import { store } from 'setup/store';
import { apiUrl } from 'shared/apiUrl';
import { VERSION_MISMATCH } from 'shared/contants';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { syncOnUprocessableEntity } from 'shared/domain/synchronization/syncOnUnprocessable';
import { TOASTER_TYPES } from 'shared/enums';
import { LOCALE_LANGUAGES } from 'shared/types/locale';
import { AppLocales } from '../../intl/IntlProviderWrapper';
import { StoreState } from '../../setup/types/core';
import { VERSION_MISMATCH_ACTION_TYPE } from '../actionTypes';
import { showToaster } from './toasterActions';
import { frontendVersionWithBuildNumber } from 'shared/version';

enum ENTITY {
  PROJECT = 'project',
  EVENT = 'event',
  DOCUMENT = 'document',
}

const NOTFOUND_STATUS = 404;

export const apiInstance = axios.create({
  withCredentials: true,
  validateStatus: (status) => status >= 200 && status < 300,
});

axiosRetry(apiInstance, {
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
  retries: 3,
});

export const getCurrentLocale = (): AppLocales => {
  const persistedUIState = localStorage.getItem('persist:ui');

  if (!persistedUIState) {
    return AppLocales.en;
  }

  const { currentLocale } = JSON.parse(persistedUIState);
  return currentLocale.replace(/"/g, '');
};
let currentPutRequest = Promise.resolve();
let resolveCurrentPutRequest: (value: void | PromiseLike<void>) => void;
const addToQueue = (
  requestConfig: InternalAxiosRequestConfig
): Promise<InternalAxiosRequestConfig> => {
  return currentPutRequest.then(() => {
    currentPutRequest = new Promise((resolve) => {
      resolveCurrentPutRequest = resolve;
    });
    return requestConfig;
  });
};

type QueuedMethodType = 'put';
const QUEUED_METHOD: QueuedMethodType = 'put';

apiInstance.interceptors.request.use(async (requestConfig) => {
  const { ui, projectData } = store.getState() as StoreState;
  const nowPlus1Minute = Date.now() + 60 * 1000;
  const expiration = localStorage.getItem('auth0_token_expiration') || '0';

  if (
    !expiration ||
    (expiration && nowPlus1Minute > parseFloat(expiration))
  ) {
    await getNewToken();
  }
  const token = localStorage.getItem('auth0_token');
  requestConfig.headers.set('Authorization', `Bearer ${token}`);
  requestConfig.headers.set(
    'Accept-Language',
    LOCALE_LANGUAGES[ui.currentLocale]
  );
  requestConfig.headers.set('hustro-client', 'web');
  requestConfig.headers.set(
    'hustro-client-version',
    frontendVersionWithBuildNumber
  );

  requestConfig.baseURL = `${apiUrl}/v2/project/${projectData._id}`;

  if (requestConfig.method === QUEUED_METHOD) {
    return addToQueue(requestConfig);
  }

  return requestConfig;
});

const responseInterceptor = (
  responseConfig: AxiosResponse,
  action?: Function
): AxiosResponse => {
  if (responseConfig?.config?.method === QUEUED_METHOD) {
    if (typeof resolveCurrentPutRequest === 'function') {
      resolveCurrentPutRequest();
      if (typeof action === 'function') {
        action();
      }
    }
  }

  return responseConfig;
};

const showErrorToaster = (): void => {
  store.dispatch(
    showToaster({
      type: TOASTER_TYPES.FAILURE,
      message: { id: 'toaster_something_went_wrong' },
      toasterPosition: { vertical: 'bottom', horizontal: 'center' },
      hideDelay: 3000,
    })
  );
};

apiInstance.interceptors.response.use(
  responseInterceptor,
  (responseConfig) => {
    const status = responseConfig?.response?.status;

    if (
      status === VERSION_MISMATCH &&
      !store.getState().ui.mismatchedVersion
    ) {
      store.dispatch({ type: VERSION_MISMATCH_ACTION_TYPE });
    }
    syncOnUprocessableEntity({ status });

    return Promise.reject(
      responseInterceptor(responseConfig, showErrorToaster)
    );
  }
);

type PromiseStateType = {
  state: 'fulfilled' | 'rejected';
  value?: string;
  reason?: string;
};

function getNewToken(): Promise<any> {
  return new Promise((resolve, reject) => {
    const broadcast = new BroadcastChannel(ChannelNames.tokenChannel);

    const timeout = setTimeout(() => {
      reject('Timeout when obtaining token');
    }, 30000);

    broadcast.onmessage = (event: Message) => {
      if (event.type === DomainMessagesTypes.token && event.data) {
        clearTimeout(timeout);
        resolve(event.data.token);
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getToken,
    });
  });
}
