import { CompanyWithFinances } from 'shared/domain/company/types/financialData';
import { ColumnShapeSkeleton } from 'components/table/columnShapes/types';
import { CostValueCellRenderer } from 'components/table/renderers/cell/costValue';
import { LoadingCellRenderer } from 'components/table/renderers/cell/loading';
import { SORT_TYPE } from 'shared/filter/sort/types';
import { mergeGroup } from './mergeGroup';
import { keepNumberIfDefined } from 'components/table/sort';

export function getFinalCostSettledColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 192,
    flexGrow: 0,
    flexShrink: 0,
    sortable: true,
    sortType: SORT_TYPE.NUMBERS,
    accessor: (entity: CompanyWithFinances): number | null =>
      entity?.financialData?.finalCost?.settled ||
      keepNumberIfDefined(entity?.financialData?.finalCost?.settled),
    cellRenderer: LoadingCellRenderer<CompanyWithFinances>(
      'financialData',
      CostValueCellRenderer({ decimalPlaces: 2 })
    ),
    labelId: 'form_field_cost_settled',
    dataKey: 'financialData.finalCost.settled',
    style: {
      justifyContent: 'flex-start',
    },
    mergeGroup,
  };
}
