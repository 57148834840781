import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useSyncSelectedProject } from 'hooks/useSyncSelectedProject';
import { useMountedRef } from 'hooks/useMountRef';
import { ProjectModel } from 'shared/domain/project/types/model';

export type SelectedProjectContextType = {
  selectedProject: ProjectModel | undefined;
  loading: boolean;
};

const SelectedProjectContext = React.createContext<
  SelectedProjectContextType | undefined
>(undefined);

const WithSelectedProject = ({
  children,
}: PropsWithChildren<{}>): ReactElement => {
  const [selectedProject, setSelectedProject] = useState<
    ProjectModel | undefined
  >(undefined);
  const [loading, setLoading] = useState<boolean>(true);
  const mountedRef = useMountedRef();

  useEffect(() => {
    const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);
    const responseBroadcast = new BroadcastChannel(
      ChannelNames.selectedProjectChannel
    );

    responseBroadcast.onmessage = (event: Message): void => {
      if (!mountedRef.current) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.selectedProject
      ) {
        responseBroadcast.close();
        setSelectedProject(event.data.project);
        setLoading(false);
      }
    };

    apiBroadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      responseBroadcast.close();
      apiBroadcast.close();
    };
  }, [mountedRef]);

  useSyncSelectedProject(true);

  const ctx = {
    selectedProject,
    loading: loading,
  };

  return (
    <SelectedProjectContext.Provider value={ctx}>
      {children}
    </SelectedProjectContext.Provider>
  );
};

const withSelectedProject =
  (Component: React.ComponentType<PropsWithChildren<{}>>) =>
  ({ ...props }): ReactElement => (
    <WithSelectedProject>
      <Component {...props} />
    </WithSelectedProject>
  );

function useSelectedProject(): SelectedProjectContextType {
  const context = React.useContext(SelectedProjectContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedProject must be used within a SelectedProjectContextProvider'
    );
  }
  return context;
}

export { withSelectedProject, useSelectedProject };
