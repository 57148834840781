import { MessageDescriptor } from 'react-intl';
import { StageInternalNames } from 'shared/types/stages';

export enum IssueStageSize {
  small = 'small',
  default = 'default',
  issueCard = 'issueCard',
}

export const StageLabel: { [key in StageInternalNames]: string } = {
  [StageInternalNames.draft]: 'general_stage_draft',
  [StageInternalNames.consultation]: 'general_stage_consultation',
  [StageInternalNames.execution]: 'general_stage_execution',
  [StageInternalNames.resolved]: 'general_stage_resolved',
};

export type StatusFormatter = (descriptor: MessageDescriptor) => string;

export type IssueStageProps = {
  stage: StageInternalNames;
  issueArchived: boolean;
  size?: IssueStageSize;
};
