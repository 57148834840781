import { AxiosResponse } from 'axios';
import React, { ReactElement, useCallback, useContext } from 'react';
import { ClientContext } from 'with-client';
import {
  createInspectionTemplateGeneralNormDocumentsBaseUrl,
  createTemplateChecklistItemDocumentsBaseUrl,
  toChecklistItemDto,
  toCreateChecklistItemsDto,
  toUploaders,
} from './helpers';
import { FormChecklistItem, TemplateValidItem } from '../form/types';
import { MemoTemplateWizardPopulator } from '../populator';
import { createChecklistChangeObject, hasChanges } from './helpers';
import { IdentifiableUploader } from './types';
import { GU } from 'components/common/graphicUploader/types';
import { TemplateDefaultValues } from '../populator/types';
import { InspectionTemplateInDto } from 'shared/dtos/in/template';
import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';
import { InspectionTemplateOutDto } from 'shared/dtos/out/template';

function uploadTemplateGeneralNormFiles(
  uploader: GU
): (
  templateResponse: AxiosResponse<InspectionTemplateInDto>
) => Promise<AxiosResponse<InspectionTemplateInDto>> {
  return (
    templateResponse: AxiosResponse<InspectionTemplateInDto>
  ): Promise<AxiosResponse<InspectionTemplateInDto>> => {
    return uploader.uploadFiles(
      createInspectionTemplateGeneralNormDocumentsBaseUrl(
        templateResponse.data._id
      ),
      () => templateResponse
    );
  };
}

function TemplateWizardCommunicator(): ReactElement {
  const client = useContext(ClientContext);

  const updateTemplate = useCallback(
    (
      template: TemplateValidItem,
      uploader: GU,
      checklist: FormChecklistItem[],
      templateId: string,
      previousValues: Readonly<TemplateDefaultValues>
    ) => {
      const templateBody = {
        process: template.process,
        summary: template.summary,
        status: InspectionTemplateStatus.draft,
        generalNorm: {
          description: template.generalNorm,
        },
      };

      // Unknown, because we dont care about the result, after promise all we fetch the template manually.
      const checklistChangesPromises: Promise<unknown>[] = [];
      const templateChange = hasChanges(previousValues, templateBody);
      if (templateChange) {
        checklistChangesPromises.push(
          client.updateTemplate(templateId, templateBody)
        );
      }

      const checklistChanges = createChecklistChangeObject(
        previousValues.checklist,
        checklist
      );

      const checklistItemsToCreateBody = toCreateChecklistItemsDto(
        checklistChanges.created
      );

      checklistChanges.changed.forEach((checklistChange) => {
        checklistChangesPromises.push(
          client.updateChecklistItem(
            templateId,
            checklistChange._id!,
            toChecklistItemDto(checklistChange)
          )
        );
      });

      // We ignore uploader in this case since this item will be deleted.
      checklistChanges.deleted.forEach((checklistItemToDelete) => {
        checklistChangesPromises.push(
          client.deleteChecklistItem(
            templateId,
            checklistItemToDelete._id!
          )
        );
      });

      const createNewChecklistItems = checklistItemsToCreateBody.length
        ? client
            .createChecklistItems(templateId, checklistItemsToCreateBody)
            .then((response) => {
              return Promise.all(
                response.data.map((checklistItemResponse, index) => {
                  return checklistChanges.created[
                    index
                  ].graphicUploader?.uploadFiles(
                    createTemplateChecklistItemDocumentsBaseUrl(
                      templateId,
                      checklistItemResponse._id
                    ),
                    () => Promise.resolve()
                  );
                })
              );
            })
        : Promise.resolve();

      return Promise.all([
        createNewChecklistItems,
        ...checklistChangesPromises,
      ])
        .then(() => {
          const identifiableUploaders: IdentifiableUploader[] = checklist
            .map(
              (item) =>
                item._id && {
                  _id: item._id,
                  graphicUploader: item.graphicUploader,
                }
            )
            .filter(
              (uploader): uploader is IdentifiableUploader => !!uploader
            );

          const fileUploadPromises = [
            uploader.uploadFiles(
              createInspectionTemplateGeneralNormDocumentsBaseUrl(
                templateId
              ),
              () => Promise.resolve()
            ),

            ...identifiableUploaders.map((identifiableUploader) => {
              return identifiableUploader.graphicUploader.uploadFiles(
                createTemplateChecklistItemDocumentsBaseUrl(
                  templateId,
                  identifiableUploader._id
                ),
                () => Promise.resolve()
              );
            }),
          ];

          return Promise.all(fileUploadPromises).then(
            () => identifiableUploaders
          );
        })
        .then((identifiableUploaders: IdentifiableUploader[]) => {
          const templateChangeset = uploader.executeChangeset();

          const checklistChangeset = identifiableUploaders.flatMap(
            (identifiableUploader) =>
              identifiableUploader.graphicUploader.executeChangeset()
          );

          return Promise.all([
            ...templateChangeset,
            ...checklistChangeset,
          ]);
        })
        .then(() => {
          return client.fetchTemplate(templateId);
        });
    },
    [client]
  );

  const createTemplate = useCallback(
    (
      template: TemplateValidItem,
      uploader: GU,
      checklist: FormChecklistItem[]
    ): Promise<AxiosResponse<InspectionTemplateInDto>> => {
      const templateBody: InspectionTemplateOutDto = {
        process: template.process,
        summary: template.summary,
        status: InspectionTemplateStatus.draft,
        generalNorm: {
          description: template.generalNorm,
        },
      };
      const checklistBody = checklist.map(toChecklistItemDto);
      const checklistUploaders = checklist.map(toUploaders);
      return client
        .createTemplate(templateBody)
        .then(uploadTemplateGeneralNormFiles(uploader))
        .then(
          (templateResponse: AxiosResponse<InspectionTemplateInDto>) => {
            return client
              .createChecklistItems(
                templateResponse.data._id,
                checklistBody
              )
              .then((checklistItemResponse) =>
                Promise.all(
                  checklistItemResponse.data.map((item, idx) => {
                    checklistUploaders[idx].uploadFiles(
                      createTemplateChecklistItemDocumentsBaseUrl(
                        templateResponse.data._id,
                        item._id
                      ),
                      () => Promise.resolve()
                    );
                  })
                )
              )
              .then(
                (): AxiosResponse<InspectionTemplateInDto> =>
                  templateResponse
              );
          }
        );
    },
    [client]
  );

  const publishTemplate = useCallback(
    (id: string) => {
      return client.publishTemplate(id);
    },
    [client]
  );

  return (
    <MemoTemplateWizardPopulator
      submitCreation={createTemplate}
      submitEdition={updateTemplate}
      publish={publishTemplate}
    />
  );
}

export const MemoTemplateWizardCommunicator = React.memo(
  TemplateWizardCommunicator
);
