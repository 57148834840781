import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import React, { FC, ReactElement, useMemo } from 'react';
import {
  useCorrectiveActionTypes,
  WithCorrectiveActionTypes,
} from '../withCorrectiveActionTypes';
import { FieldValueContext } from '../withCustomizedField';
import {
  useEnvironmentalAspects,
  WithEnvironmentalAspects,
} from '../withEnvironmentalAspects';
import {
  useHazardCategories,
  WithHazardCategories,
} from '../withHazardCategories';
import { useWorktypes, WithWorktypes } from '../withWorktypes';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';

type CustomizedFieldsContextType = {
  [key in EditableFieldValuesEntityName]: {
    Provider: FC<any>;
    useContext: () => FieldValueContext;
    fieldLabelId: string;
    fieldName: EditableFieldValuesEntityName;
  };
};

export const CustomizedFieldsContext = React.createContext<
  CustomizedFieldsContextType | undefined
>(undefined);

// react 18 types
// @ts-ignore
const WithCustomizedFields: React.FC = ({ children }) => {
  const ctx: CustomizedFieldsContextType = useMemo(
    () => ({
      workTypes: {
        Provider: WithWorktypes,
        useContext: useWorktypes,
        fieldLabelId: 'extendedField_Work_type',
        fieldName: EditableFieldValuesEntityName.workTypes,
      },
      hazardCategory: {
        Provider: WithHazardCategories,
        useContext: useHazardCategories,
        fieldLabelId: 'extendedField_Hazard_category',
        fieldName: EditableFieldValuesEntityName.hazardCategory,
      },
      environmentalAspect: {
        Provider: WithEnvironmentalAspects,
        useContext: useEnvironmentalAspects,
        fieldLabelId: 'extendedField_Environmental_aspect_category',
        fieldName: EditableFieldValuesEntityName.environmentalAspect,
      },
      proposedCorrectiveAction: {
        Provider: WithCorrectiveActionTypes,
        useContext: useCorrectiveActionTypes,
        fieldLabelId: 'extendedField_Proposed_corrective_action',
        fieldName: EditableFieldValuesEntityName.proposedCorrectiveAction,
      },
    }),
    []
  );

  return (
    <CustomizedFieldsContext.Provider value={ctx}>
      {children}
    </CustomizedFieldsContext.Provider>
  );
};

const withCustomizedFields =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithCustomizedFields>
      <Component {...props} />
    </WithCustomizedFields>
  );

function useCustomizedFields(): CustomizedFieldsContextType {
  const context = React.useContext(CustomizedFieldsContext);
  if (context === undefined) {
    throw new Error(
      'useCustomizedFields must be used within a CustomizedFieldsContextProvider'
    );
  }
  return context;
}

export { WithCustomizedFields, withCustomizedFields, useCustomizedFields };
