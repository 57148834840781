import { Theme as DefaultTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  (theme: DefaultTheme) =>
    createStyles({
      root: {
        backgroundColor: '#F2F2F2',
        padding: '0 22px 120px 22px',
        display: 'flex',
        flexFlow: 'column nowrap',
        minHeight: '100%',
        '& h1': {
          width: 'fit-content',
          flexShrink: 0,
          marginTop: '22px',
          marginBottom: ({ isMobile }): string =>
            isMobile ? '0px' : '22px',
          [theme.breakpoints.down(500)]: {
            fontSize: '1.5rem',
          },
        },
      },
      flex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
      flexEnd: {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        marginBottom: ({ isMobile }): string => (isMobile ? '8px' : '0px'),
        justifyContent: ({ isMobile }): string =>
          isMobile ? 'space-between' : 'flex-end',
        width: '100%',
        '& button': {
          height: 'fit-content',
        },
      },
      mr: {
        marginRight: '12px',
      },
      capitalize: { textTransform: 'capitalize' },
    })
);
