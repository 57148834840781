import React, { useEffect } from 'react';
import { Spinner } from 'components/core';
import { useRedirect } from 'hooks/useRedirect';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getErrorToasterAction } from 'redux/actions/toasterActions';
import {
  UNPROCESSABLE_ENTITY,
  BAD_REQUEST,
  REQUIRED_RESOURCE_NEEDS_AUTHORIZATION,
  FORBIDDEN,
  NOT_FOUND,
  OK,
  TOKEN_EXPIRED,
} from 'shared/contants';
import qs from 'query-string';
import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import {
  DownloadingInfo,
  NotFoundInfo,
  LinkExpiredInfo,
} from 'views/reports/statusComponents';
import { useReportDownload } from 'components/common/withReportDownload';

const statusCodeComponents: Record<number, React.FC> = {
  [FORBIDDEN]: InsufficientPermissionsPage,
  [NOT_FOUND]: NotFoundInfo,
  [TOKEN_EXPIRED]: LinkExpiredInfo,
  [OK]: DownloadingInfo,
}; //TODO https://hustro.atlassian.net/browse/PT-3862

export function ReportDownload(): React.ReactElement {
  const { isDownloading, statusCode, startDownloadingReport } =
    useReportDownload();

  const dispatch = useDispatch();
  const intl = useIntl();
  const redirect = useRedirect();

  const location = useLocation();
  const { reportId } = useParams<{ reportId: string }>();
  const { token } = qs.parse(location.search);

  useEffect(() => {
    if (!reportId || typeof token !== 'string') {
      return;
    }
    startDownloadingReport(reportId, token);
  }, [startDownloadingReport, reportId, token]);

  useEffect(() => {
    if (
      statusCode === undefined ||
      ![UNPROCESSABLE_ENTITY, BAD_REQUEST].includes(statusCode)
    ) {
      return;
    }

    dispatch(getErrorToasterAction(intl));
  }, [statusCode, dispatch, intl]);

  useEffect(() => {
    if (statusCode !== REQUIRED_RESOURCE_NEEDS_AUTHORIZATION) {
      return;
    }

    const redirectLink = `${location.pathname}${location.search}`;
    localStorage.setItem('redirect_link', redirectLink);
    redirect('/login');
  }, [statusCode, location.pathname, location.search, redirect]);

  if (isDownloading || !statusCode || !statusCodeComponents[statusCode]) {
    return (
      <Spinner
        position='static'
        reason={`ReportDownload isDownloading: ${isDownloading} || !statusCode: ${!statusCode} || !statusCodeComponents[statusCode]`}
      />
    );
  }

  const DisplayedComponent = statusCodeComponents[statusCode];

  return <DisplayedComponent />;
}
