import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const HEADER_HEIGHT = 48;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'absolute',
      width: '300px',
      maxHeight: `min(calc(40vh + ${HEADER_HEIGHT}px), 320px)`,
      flexDirection: 'column',
      borderRadius: '6px 6px 0 0',
      zIndex: 1198,
    },
    header: {
      padding: 0,
      margin: 0,
      width: '100%',
      height: `${HEADER_HEIGHT}px`,
      backgroundColor: '#000',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderRadius: '6px 6px 0 0',
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
    },
    dragger: {
      flex: 1,
      cursor: 'move',
      alignSelf: 'stretch',
    },
    listContainer: {
      flexGrow: 1,
      backgroundColor: '#FFF',
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      border: '1px solid #CCC',
    },
    listElement: {
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      height: '48px',
      borderBottom: 'solid 1px #EEEEEE',
      paddingLeft: '12px',
      paddingRight: '16px',
      cursor: 'pointer',
      flexShrink: 0,
      fontSize: '0.875rem',
      gap: '8px',
      '& > span': {
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
  })
);
