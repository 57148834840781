import { ExpandMore } from '@mui/icons-material';
import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ButtonIcon } from 'components/general';
import { MouseEventHandler, ReactElement } from 'react';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '2.5rem',
      height: '2.5rem',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}C0`,
      },
      position: 'absolute',
      left: '-2.5rem',
      borderRadius: '50% 0 0 50%',
    },
    icon: {
      width: '1.25rem',
      height: '1.25rem',
    },
  })
);

export function DocumentationProgressExpandLockButton({
  onClick,
  active,
  expanded,
}: {
  active: boolean;
  expanded: boolean;
  onClick: MouseEventHandler;
}): ReactElement | null {
  const classes = useStyles();
  if (!active) {
    return null;
  }

  return (
    <ButtonIcon
      id='expand-lock-button'
      className={classes.root}
      onClick={onClick}
    >
      <ExpandMore
        className={classes.icon}
        style={{
          transform: expanded ? 'rotate(-90deg)' : 'rotate(90deg)',
        }}
      />
    </ButtonIcon>
  );
}
