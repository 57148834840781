import { useEffect, useState } from 'react';
import GDPRError from './GDPRError';
import { getGDPR } from './actions';
import { Spinner } from '../index';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    width: '100%',
    padding: 16,
    boxSizing: 'border-box',
  },
});

const initialState = {
  gdprClause: '',
  loading: true,
  error: false,
};

const tidyHTML = (html: string): string => {
  const d = document.createElement('div');
  d.innerHTML = html;

  return d.innerHTML;
};

const GDRPInfo = (): JSX.Element => {
  const classes = useStyles();
  const [state, setState] = useState(initialState);

  const handleGDPRresponse = (gdprClause: string): void => {
    setState({ gdprClause, loading: false, error: false });
  };

  const handleErrors = (): void => {
    setState({ gdprClause: '', loading: false, error: true });
  };

  useEffect(() => {
    getGDPR().then(handleGDPRresponse).catch(handleErrors);
  }, []);

  if (state.error) return <GDPRError />;
  if (state.loading) return <Spinner reason='GDPRInfo state.loading' />;

  return (
    <div className={classes.wrapper}>
      <div
        dangerouslySetInnerHTML={{
          __html: tidyHTML(state.gdprClause),
        }}
      />
    </div>
  );
};

export default GDRPInfo;
