import React, { ChangeEvent, useCallback, useEffect, useRef } from 'react';
import { Chip, Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import classNames from 'classnames';
import { useDebounce } from 'helpers/misc';
import { ContentClamp } from 'components/core/ContentClamp/ContentClamp';
import ToggleFilter from '@mui/icons-material/ArrowDropUp';
import DoneIcon from '@mui/icons-material/Done';
import { GeneralFilter } from 'redux/selectors/filters';
import {
  optionalSort,
  optionFilter,
  filterSelected,
} from '../FiltersHelpers';
import { LabelledEntity } from 'shared/types/commonView';
import { useIssueFilters } from 'components/dataProviders/withIssueFilters';
import { CLOSE_FILTER_ITEM, getFilterPresentedLabel } from './model';
import { useIntl } from 'react-intl';

const FilterItem = ({
  classes,
  label,
  filterName,
  options,
  filterValues,
}: GeneralFilter & WithStyles<any>): React.ReactElement => {
  const intl = useIntl();
  const filterValuesStringRef = useRef(
    filterValues.map((value) => value._id).join(',')
  );
  const mountedRef = useRef(false);
  const [filterExpanded, setExpandedState] =
    React.useState<boolean>(false);
  const [visibleOptions, setVisibleOptions] =
    React.useState<LabelledEntity[]>(options);
  const [selectedOptions, setSelectedOptions] =
    React.useState<LabelledEntity[]>(filterValues);
  const [searchPhrase, setSearchPhrase] = React.useState<string>('');

  const searchInput = useRef<HTMLInputElement>(null);
  const searchPhraseDebounced = useDebounce(searchPhrase, 200);

  const { setFilter } = useIssueFilters();

  const filterDisabled = !visibleOptions?.length;

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }
    const filterValuesString = filterValues
      .map((value) => value._id)
      .join(',');
    if (filterValuesStringRef.current !== filterValuesString) {
      filterValuesStringRef.current = filterValuesString;
      setSelectedOptions(filterValues);
    }
  }, [filterValues]);

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }

    const values = selectedOptions.map((option) => ({
      _id: option._id,
    }));
    setFilter(filterName, values);
    setSearchPhrase('');
  }, [setFilter, selectedOptions, filterName]);

  useEffect(() => {
    const updateVisibleOptions = (): void => {
      const optionsFiltered = optionalSort(
        filterName !== 'stage',
        options.filter(optionFilter(searchPhraseDebounced))
      );
      setVisibleOptions(optionsFiltered);
    };

    updateVisibleOptions();
  }, [searchPhraseDebounced, options, filterName]);

  const isOptionSelected = useCallback(
    (option: LabelledEntity): boolean => {
      return selectedOptions.some(filterSelected(option, true));
    },
    [selectedOptions]
  );

  const toggleSelected = useCallback((option): void => {
    setSelectedOptions((options) =>
      options.find((o) => o._id === option._id)
        ? [...options.filter(filterSelected(option))]
        : [...options, option]
    );
  }, []);

  const toggleFilterExpanded = useCallback((): void => {
    setExpandedState((isExpanded) => !isExpanded);
  }, []);

  const handleBoxClick = useCallback((): void => {
    searchInput.current && searchInput.current.focus();

    if (!filterDisabled) {
      setExpandedState(true);
    }
  }, [filterDisabled]);

  const handleFilterSearch = (
    event: ChangeEvent<HTMLInputElement>
  ): void => {
    setSearchPhrase(event.target.value);
  };

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  useEffect(() => {
    const close = (): void => {
      setExpandedState(false);
    };
    window.addEventListener(CLOSE_FILTER_ITEM, close, false);
    return (): void => {
      window.removeEventListener(CLOSE_FILTER_ITEM, close);
    };
  }, []);

  const presentedLabel = getFilterPresentedLabel(label, intl);

  return (
    <div className={classes.filter}>
      <div className={classes.filterController}>
        <Typography className={classes.filterLabel}>
          {presentedLabel}
        </Typography>
        <ToggleFilter
          onClick={toggleFilterExpanded}
          data-qa={`filter-sidebar-dropdown-toggler-${filterName}`}
          className={classNames('toggler', {
            filterDisabled,
            expanded: filterExpanded,
          })}
        />
      </div>
      <div
        onClick={handleBoxClick}
        className={classNames(
          classes.filterBox,
          filterExpanded && 'active'
        )}
      >
        {selectedOptions.map((option) => {
          return (
            <Chip
              key={option.label}
              size='small'
              className={classes.filterChip}
              label={option.label}
              onDelete={(): void => toggleSelected(option)}
            />
          );
        })}
        <input
          value={searchPhrase}
          ref={searchInput}
          onChange={handleFilterSearch}
          className={classes.filterSearch}
          data-qa={`filter-sidebar-filter-input-${filterName}`}
        />
      </div>
      <ul
        className={classNames(
          classes.filterList,
          filterExpanded && visibleOptions.length && 'open'
        )}
      >
        {visibleOptions.map((option) => {
          const optionSelected = isOptionSelected(option);

          return (
            <li
              key={option._id}
              onClick={(): void => toggleSelected(option)}
              data-qa={`filter-sidebar-dropdown-option-${filterName}-${option._id}`}
              className={classNames(classes.optionWrapper, {
                optionSelected,
              })}
            >
              <ContentClamp maxRows={2}>
                <span>{option.label}</span>
              </ContentClamp>
              <div style={{ marginLeft: 'auto' }}>
                {optionSelected && <DoneIcon />}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FilterItem;
