import { useEffect } from 'react';
import { EntityLabel } from 'components/common/SearchInput/types';
import {
  releaseSearchPhraseChangeEvent,
  saveSearchPhraseInStorage,
} from 'components/common/SearchInput/helpers';

export function useSaveSearchPhrase(
  projectId: string,
  entityLabel: EntityLabel,
  searchPhraseState: string
): void {
  useEffect(() => {
    if (!projectId) {
      return;
    }

    saveSearchPhraseInStorage(projectId, entityLabel, searchPhraseState);
    releaseSearchPhraseChangeEvent(searchPhraseState, entityLabel);
  }, [projectId, searchPhraseState, entityLabel]);
}
