import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';

export function startSetDocumentationParent(
  parentId: string,
  documentations: number[],
  directories: number[]
): void {
  debugLog('startSetParent', parentId, documentations, directories);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTATIONS,
    method: ServiceMethods.SET_PARENT,
    data: {
      parentId,
      documentations,
      directories,
    },
  });
  broadcast.close();
}
