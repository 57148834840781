import { keepUndeleted } from 'shared/domain/deletable/filters';
import { LevelOnView } from 'shared/domain/level/types/view';
import { HashMap, LabelledEntity } from 'shared/types/commonView';

export function levelsExtractor(
  levels: HashMap<LevelOnView[]>,
  site: LabelledEntity
): LabelledEntity[] {
  const levelsOnSite = levels[site._id];
  return Array.isArray(levelsOnSite)
    ? levelsOnSite.filter(keepUndeleted)
    : [];
}
