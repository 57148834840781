import { useGalleryContext } from 'components/common/gallery';
import { IndexMarker } from 'components/progress/SimpleIndexMarker';
import {
  MouseEventHandler,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Color } from 'shared/utils/colors';

export function IndexMarkerSvg({
  id,
  onClick,
  onMouseOver,
  onMouseLeave,
  color,
  drawLineFrom,
  width,
  left,
  top,
  zIndex,
  tooltip,
}: {
  id: string;
  onClick: MouseEventHandler;
  onMouseOver: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  color: Color;
  drawLineFrom: 'top' | 'bottom';
  width: number;
  left: string;
  top: string;
  zIndex: number | 'unset';
  tooltip: string;
}): ReactElement | null {
  const { rightPanelControls } = useGalleryContext();

  const initial = !rightPanelControls
    ? false
    : rightPanelControls?.getActivityStore().get() &&
      rightPanelControls?.getVisibleStore().get();
  const [showProgressMarkers, setShowProgressMarkers] = useState(initial);

  useEffect(() => {
    if (!rightPanelControls) {
      return;
    }
    const action = (): void => {
      const next =
        rightPanelControls?.getActivityStore().get() &&
        rightPanelControls?.getVisibleStore().get();

      setShowProgressMarkers(next);
    };

    action();
    const visibleUnsubscribe = rightPanelControls
      ?.getVisibleStore()
      .subscribe(action);
    const activeUnsubscribe = rightPanelControls
      ?.getActivityStore()
      .subscribe(action);

    return () => {
      visibleUnsubscribe();
      activeUnsubscribe();
    };
  }, [rightPanelControls]);

  const index = rightPanelControls && rightPanelControls.getIndex(id);

  if (
    !rightPanelControls ||
    !showProgressMarkers ||
    rightPanelControls.getIndex(id) === -1
  ) {
    return null;
  }

  return (
    <svg
      viewBox={'0 0 20 14'}
      preserveAspectRatio={
        drawLineFrom === 'bottom' ? 'xMidYMin' : 'xMidYMax'
      }
      style={{
        width,
        height: width * 1.62,
        left,
        top,
        zIndex,
        position: 'absolute',
        transform:
          drawLineFrom === 'bottom'
            ? `translate(-50%, -2%)`
            : 'translate(-50%, -100%)',
      }}
    >
      <IndexMarker
        drawLineFrom={drawLineFrom}
        index={(index as number) + 1}
        color={color}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        tooltip={tooltip}
      />
    </svg>
  );
}
