import { InspectionTemplateItem } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { Permission } from 'helpers/permission';

export type TemplateListError = {
  templateErrorType: string;
  helperText: string;
};

type ClickHandler = (item: InspectionTemplateItem) => void;

export type TemplateListPresentationalProps = {
  templates: InspectionTemplateItem[];
  activeId: string | undefined;
  handleClick: ClickHandler;
  error: TemplateListError | undefined;
  loaded: boolean;
  canCreateTemplate: () => Permission;
  createTemplate: () => void;
  canEditTemplate: (template: InspectionTemplateItem) => Permission;
  editTemplate: (template: InspectionTemplateItem) => void;
  canRemoveTemplate: () => Permission;
  removeTemplate: (templateId: string, templateSummary: string) => void;
  setCanScroll: (param: boolean) => void;
  searchChange: (phrase: string) => void;
  searchClear: () => void;
  searchPhrase: string;
  startInspection: (template: InspectionTemplateItem) => void;
  canStartInspection: (template: InspectionTemplateItem) => Permission;
  showList: boolean;
};

export enum TemplateListErrorText {
  notFound = 'error_template_not_found',
  other = 'error_template_other',
  noTemplates = 'error_template_no_templates',
  noFilteredTemplates = 'list_search_empty_title',
}
