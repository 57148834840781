import { PromiseExtended } from 'dexie';
import db from './index';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { Identificable } from 'shared/types/commonView';
import { wrapQuery } from './queryWrapper';
import { makeDefaultClear } from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

export const get = wrapQuery(
  db,
  (): PromiseExtended<Identificable | undefined> => {
    return db.selectedProject
      .toCollection()
      .first()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.get, 'selectedProject'),
          errorObj: e,
          additionalInfo: null,
        })
      );
  }
);

export const clear = makeDefaultClear(db, db.selectedProject);

export const set = wrapQuery(
  db,
  (newSelection: Identificable): Promise<Identificable> => {
    return db.transaction(
      'rw',
      db.selectedProject,
      async function setSelectedProjectTransaction(): Promise<Identificable> {
        await clear();
        return db.selectedProject.add(newSelection).catch((e) => {
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.set, 'selectedProject'),
            errorObj: e,
            additionalInfo: null,
          });
        });
      }
    );
  }
);
