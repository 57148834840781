import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { ProjectInDto } from 'shared/domain/project/types/dto';
import {
  broadcastUploadedProjectError,
  broadcastCreatedProjectError,
} from 'serviceWorker/broadcasts/project/error';
import {
  broadcastCreatedProject,
  broadcastDeletedProject,
  broadcastUpdatedProject,
} from 'serviceWorker/broadcasts/project/one';

export function broadcastProjectResponse(
  request: HttpRequestEntity,
  response: ProjectInDto | any
): void {
  if (response.error) {
    return broadcastUploadedProjectError(response.error);
  }
  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastCreatedProject(response, request.data.uniqueId) as any;
  }
  if (request.method === 'PUT') {
    return broadcastUpdatedProject(response, request.data.uniqueId) as any;
  }
  if (request.method === 'DELETE') {
    return broadcastDeletedProject(response, request.data.uniqueId);
  }
}

export function broadcastProjectError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastCreatedProjectError(error);
}
