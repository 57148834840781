import { Box, CircularProgress, Divider } from '@mui/material';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonContained from '../ButtonContained/ButtonContained';
import ButtonText from '../ButtonText/ButtonText';
import {
  leftMainContainerPosition,
  moduleContainerWidth,
} from 'components/core/Layout/layoutStyles';

export function FormBottonButtons({
  onCancel,
  onSubmit,
  isPosting,
}): ReactElement {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        boxShadow: '0px -4px 4px -2px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: moduleContainerWidth,
        left: leftMainContainerPosition,
        boxSizing: 'border-box',
        px: 2,
        py: 1,
        height: '64px',
        backgroundColor: 'white',
        zIndex: 1,
      }}
    >
      <ButtonText
        sx={{ minWidth: '80px' }}
        onClick={onCancel}
        disabled={isPosting}
      >
        <FormattedMessage id='general_cancel' />
      </ButtonText>
      <ButtonContained
        sx={{ minWidth: '80px' }}
        onClick={onSubmit}
        disabled={isPosting}
      >
        {isPosting && (
          <CircularProgress
            size={13}
            thickness={4}
            disableShrink
            color='secondary'
          />
        )}
        <FormattedMessage id='general_save' />
      </ButtonContained>
    </Box>
  );
}

export function DialogButtons({
  onCancel,
  onSubmit,
  isPosting,
}): ReactElement {
  return (
    <Box>
      <ButtonText
        sx={{ mx: 2, my: 1 }}
        onClick={onCancel}
        disabled={isPosting}
      >
        <FormattedMessage id='general_cancel' />
      </ButtonText>
      <ButtonContained
        sx={{ mr: 2, ml: 1, my: 1 }}
        onClick={onSubmit}
        disabled={isPosting}
      >
        {isPosting && (
          <CircularProgress
            size={13}
            thickness={4}
            disableShrink
            color='secondary'
          />
        )}
        <FormattedMessage id='general_save' />
      </ButtonContained>
    </Box>
  );
}
