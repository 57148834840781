import { TableState } from './types';

const initialState: TableState = {
  currentPageLoaded: 1,
  items: [],
  sorted: false,
};

export class TableStatePreserver {
  private state: TableState = initialState;

  getState(): TableState {
    return this.state;
  }

  setState(newState: TableState): void {
    this.state = newState;
  }
}
