import { OrganizationInDto } from '../types/dto';
import { OrganizationEntity } from '../types/entity';

export function organizationInDtoToEntity(
  organization: OrganizationInDto
): OrganizationEntity {
  return {
    _id: organization._id,
    createdAt: organization.createdAt,
    createdBy: organization.createdBy,
    modifiedAt: organization.modifiedAt,
    modifiedBy: organization.modifiedBy,
    label: organization.label,
    name: organization.name,
    contactEmail: organization.contactEmail,
    logoUsedInPdfHeaders: organization.logoUsedInPdfHeaders,
  };
}
