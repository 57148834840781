const OPEN_DOCUMENTATION_EVENT_TYPE = 'open-documentation-files-input';
export function openDocumentationInput(): void {
  window.dispatchEvent(new Event(OPEN_DOCUMENTATION_EVENT_TYPE));
}

export function listenToOpenDocumentationInput(
  callback: (ev: unknown) => void
): () => void {
  window.addEventListener(OPEN_DOCUMENTATION_EVENT_TYPE, callback);

  return () =>
    window.removeEventListener(OPEN_DOCUMENTATION_EVENT_TYPE, callback);
}
