import { IntlShape } from 'react-intl';
import { ColumnShape } from 'react-base-table';

export enum ColumnKey {
  no = 'no',
  correspondenceAddressStreetAndNumber = 'correspondence_address_street_and_number',
  correspondenceAddressPostalCode = 'correspondence_address_postal_code',
  correspondenceAddressCountry = 'correspondence_address_country',

  registeredAddressStreetAndNumber = 'registeredOffice_address_street_and_number',
  registeredAddressPostalCode = 'registeredOffice_address_postal_code',
  registeredAddressCountry = 'registeredOffice_address_country',

  finalCostOutstanding = 'final_cost_outstanding',
  finalCostSettled = 'final_cost_settled',
  estimatedCost = 'estimated_cost',

  expectedFine = 'expected_fine',
  imposedFine = 'imposed_fine',

  createdAt = 'createdAt',
  modifiedAt = 'modifiedAt',

  label = 'label',
  longLabel = 'long_label',
  shortLabel = 'short_label',

  sites = 'sites',

  taxNumber = 'tax_number',

  projectInvolvementType = 'project_involvement_type',
  createdBy = 'createdBy',
  process = 'process',
  summary = 'summary',
  status = 'status',
  executionDate = 'executionDate',
  author = 'author',
  controlledParty = 'controlledParty',
  workTypes = 'workTypes',
  email = 'email',
  role = 'role',
}

export enum InitialColumnWidth {
  small = 50,
  medium = 100,
  large = 200,
  extraLarge = 400,
}

export type ColumnShapeParams = {
  columnKey: ColumnKey;
  savedWidth: number | undefined;
  formatMessage: IntlShape['formatMessage'];
};

export type ColumnShapeSkeleton = Omit<
  ColumnShape,
  'width' | 'key' | '_id' | 'resizable' | 'resizable'
> & {
  labelId: string;
  mergeGroup?: MergeGroupSkeleton;
  initialWidth: number;
};

export type MergeGroupSkeleton = {
  _id: string;
  labelId: string;
};

export enum AddressType {
  correspondence = 'correspondence',
  registered = 'registered',
}
