import React, { memo, useEffect } from 'react';

import { Spinner } from 'components/core';
import { SingleOrganization } from 'components/organization/single';
import {
  useSelectedProject,
  withSelectedProject,
} from 'components/dataProviders/withSelectedProject';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { organizationModelToOrganizationOnView } from 'shared/domain/organization/mapping/toView';

const OrganizationGeneralData = (): React.ReactElement => {
  const { selectedProject, loading: loadingSelectedProject } =
    useSelectedProject();
  const { organizationsMap, loading: loadingOrganizations } =
    useOrganizations();
  const organizationModel =
    selectedProject?.organizationId &&
    organizationsMap.get(selectedProject?.organizationId);
  const organizationOnView =
    organizationModel &&
    organizationModelToOrganizationOnView(organizationModel);

  if (
    loadingSelectedProject ||
    loadingOrganizations ||
    !organizationOnView
  ) {
    return (
      <Spinner reason='OrganizationGeneralData loading || loadingOrganizations || !Organization' />
    );
  }

  return <SingleOrganization {...organizationOnView} />;
};

export const MemoOrganizationGeneralData = memo(
  withSelectedProject(OrganizationGeneralData)
);
