import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGetAll } from './makeUseGetAll';
import { useLevelChannelListener } from 'components/broadcastChannelListeners/withLevelChannelListener';
import { LevelModel } from 'shared/domain/level/types/model';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allLevels
  );
}

export const useGetAllLevels = makeUseGetAll<LevelModel>({
  useChannelListener: useLevelChannelListener,
  postMessageChannel: ChannelNames.levelChannel,
  postMessageType: DomainMessagesTypes.getAllLevels,
  isMatchingMessage,
  entityName: 'levels',
});
