import { HashMap, Identificable } from 'shared/types/commonView';
import { Process } from 'shared/types/process';
import { VisibleFieldModel } from './visibleFieldModel';

export function createAvailableFieldsSet(
  visibleFields: HashMap<VisibleFieldModel[]>,
  restFields: { fieldName: string; processes: Process[] }[],
  processes: Identificable[]
): Set<string> {
  const fieldNameSet = new Set<string>();
  processes.forEach((process) => {
    if (!visibleFields[process._id]) return;
    visibleFields[process._id].forEach((field) => {
      fieldNameSet.add(field.fieldName);
    });
    restFields.forEach((field) => {
      if (field.processes.includes(process._id as Process)) {
        fieldNameSet.add(field.fieldName);
      }
    });
  });

  return fieldNameSet;
}
