import { IntlShape } from 'react-intl';
import { SiteModel } from 'shared/domain/site/types/model';
import { UserOnView } from 'shared/domain/user/types/view';
import {
  CreatableUserRole,
  USER_ROLE_LABEL_ID,
} from 'shared/types/userRole';
import { idsToLabelledEntities } from 'shared/utils/id/idsToLabelledEntities';
import { UserModel } from '../userModel';
import { ProcessType } from 'shared/types/form';

export function userModelToUserOnView({
  userModel,
  sites,
  processes,
  intl,
}: {
  userModel: UserModel;
  sites: SiteModel[];
  processes: ProcessType[];
  intl: IntlShape;
}): UserOnView {
  return {
    _id: userModel._id,
    label: userModel.label,
    email: userModel.email,
    role: {
      // superadmin won't be visible on any view
      _id: userModel.role as CreatableUserRole,
      label: intl.formatMessage({
        id: `${USER_ROLE_LABEL_ID[userModel.role]}`,
      }),
    },
    processes: idsToLabelledEntities(userModel.processes, processes),
    sites: idsToLabelledEntities(userModel.sites, sites),
  };
}
