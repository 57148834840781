import { useIssueForm } from 'components/common/withIssueForm';
import { useSites } from 'components/dataProviders/withSites';
import { useVisibleFieldsState } from 'components/dataProviders/withVisibleFields';
import { useWorktypes } from 'components/dataProviders/withWorktypes';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  StaticField,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { createAvailableFieldsSet } from 'shared/domain/visibleField/createAvailableFieldsSet';
import { TOASTER_TYPES } from 'shared/enums';
import { DateRangeObject, FilterTypes } from 'shared/types/analytics';
import { ChartType } from 'shared/types/analytics';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { Process } from 'shared/types/process';
import { DayMomentValidation } from 'shared/types/time';
import { showToaster } from '../../redux/actions/toasterActions';
import { impactSelector } from '../../redux/selectors/helpers';
import { StoreState } from '../../setup/types/core';
import {
  shortISOStringToProjectTimezonedDate,
  shortISOStringToProjectTimezonedString,
} from '../../shared/utils/date/dates';
import { ChartYAxis } from '../../views/analytics/ChartYAxis';
import { fromDate1MonthEarlier, palette, toDateToday } from '../shared';
import {
  createLabels,
  setMultiselectFilterValue,
} from '../shared/filters';
import { useAssignees, useSubcontractors } from '../shared/hooks';
import { SetingsSaver, useSettingsSaver } from '../shared/settingsSaver';
import { getOutDtoSegments } from './model';
import { ResolutionTimeFilters, Segments } from './types';

const initialSegments: Segments = [
  { color: palette.lightgreen },
  { color: palette.darkblue },
  { color: palette.orange },
  { color: palette.red },
  { color: palette.purple },
];

const isDateDifferenceInvalid = (
  dateFrom: string,
  dateTo: string,
  timezone: string
): boolean => {
  const startL = shortISOStringToProjectTimezonedDate(
    dateFrom,
    timezone,
    DayMomentValidation.start
  );
  const endL = shortISOStringToProjectTimezonedDate(
    dateTo,
    timezone,
    DayMomentValidation.end
  );
  const difference = endL.diff(startL, 'days');

  return difference.days > 366;
};

export function useResolutionTimeFilters(): ResolutionTimeFilters {
  const { items: workTypes } = useWorktypes();
  const {
    timezone,
    processes: allProcesses,
    _id: projectId,
  } = useSelector((state: StoreState) => {
    return state.projectData;
  });

  const processes = useMemo(() => {
    return allProcesses.filter((process) => {
      return process._id !== Process.TSK;
    });
  }, [allProcesses]);

  const subcontractors = useSubcontractors();

  const { issueForm } = useIssueForm();
  const impacts = useMemo(() => impactSelector(issueForm), [issueForm]);
  const assignees = useAssignees();

  const {
    sites: { items: sites },
  } = useSites();

  const dispatch = useDispatch();

  const showTooLargeIntervalErrorToast = useCallback(() => {
    dispatch(
      showToaster({
        type: TOASTER_TYPES.FAILURE,
        message: { id: 'date_range_interval_too_large_error' },
        toasterPosition: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        hideDelay: 6000,
      })
    );
  }, [dispatch]);

  const saver = useSettingsSaver(`${projectId}_resolution_time`);
  const visibleFields = useVisibleFieldsState();
  const visibleFieldsSet = useMemo(
    () =>
      createAvailableFieldsSet(
        visibleFields,
        getAvailableFields().filter(
          (field) => !field.canBeDisabled
        ) as StaticField[],
        processes
      ),
    [visibleFields, processes]
  );

  const [filters, setFilters] = useState<ResolutionTimeFilters>();
  const defaultFilters = useMemo(() => {
    if (filters) return;
    return createFilters({
      setFilters: setFilters as Dispatch<
        SetStateAction<ResolutionTimeFilters>
      >,
      saver,
      subcontractors,
      processes,
      timezone,
      showTooLargeIntervalErrorToast,
      sites,
      assignees,
      workTypes,
      impacts,
      visibleFieldsSet,
    });
  }, [
    filters,
    saver,
    subcontractors,
    processes,
    timezone,
    showTooLargeIntervalErrorToast,
    sites,
    assignees,
    workTypes,
    impacts,
    visibleFieldsSet,
  ]);

  useEffect(() => {
    setFilters((prev) => {
      if (!prev) return defaultFilters;
      return prev;
    });
  }, [defaultFilters]);

  return (filters || defaultFilters) as ResolutionTimeFilters;
}

function createFilters({
  setFilters,
  saver,
  subcontractors,
  processes,
  timezone,
  showTooLargeIntervalErrorToast,
  sites,
  assignees,
  workTypes,
  impacts,
  visibleFieldsSet,
}: {
  setFilters: Dispatch<SetStateAction<ResolutionTimeFilters>>;
  saver: SetingsSaver;
  subcontractors: LabelledEntity[];
  processes: ProcessType[];
  timezone: string;
  showTooLargeIntervalErrorToast: CallableFunction;
  sites: LabelledEntity[];
  assignees: LabelledEntity[];
  workTypes: LabelledEntity[];
  impacts: LabelledEntity[];
  visibleFieldsSet: Set<string>;
}): ResolutionTimeFilters {
  return {
    chartSettings: {
      labelId: 'chart_settings_tab',
      filters: [
        {
          labelId: 'chart_type',
          type: FilterTypes.radio,
          available: [ChartType.line, ChartType.area],
          labels: {
            [ChartType.line]: 'chart_type_line',
            [ChartType.area]: 'chart_type_area',
          },
          value: saver.get(`chart_settings_chart_type`) || ChartType.line,
          setter: (value: ChartType): void => {
            saver.set(`chart_settings_chart_type`, value);
            setFilters((prev) => {
              if (!prev) return prev;
              const result = {
                ...prev,
                chartSettings: {
                  ...prev.chartSettings,
                  filters: [...prev.chartSettings.filters],
                },
              };
              result.chartSettings.filters[0] = {
                ...prev.chartSettings.filters[0],
                value: value,
              };
              return result;
            });
          },
        },
        {
          labelId: ' ',
          type: FilterTypes.checkbox,
          labels: {
            hasDataLabels: 'chart_settings_data_labels',
          },
          value: saver.getObject<{ hasDataLabels: boolean }>(
            `chart_settings_data_labels`
          ) || {
            hasDataLabels: false,
          },
          setter: (value: { hasDataLabels: boolean }): void => {
            saver.set(`chart_settings_data_labels`, value);
            setFilters((prev) => {
              if (!prev) return prev;
              const result = {
                ...prev,
                chartSettings: {
                  ...prev.chartSettings,
                  filters: [...prev.chartSettings.filters],
                },
              };
              result.chartSettings.filters[1] = {
                ...prev.chartSettings.filters[1],
                value: value,
              };
              return result;
            });
          },
        },
        {
          labelId: 'chart_y_axis',
          type: FilterTypes.radio,
          available: [ChartYAxis.percentage, ChartYAxis.days],
          labels: {
            [ChartYAxis.percentage]: 'chart_y_axis_percentage',
            [ChartYAxis.days]: 'chart_y_axis_days',
          },
          value: saver.get(`chart_settings_y_axis`) || ChartYAxis.days,
          setter: (value: ChartType): void => {
            saver.set(`chart_settings_y_axis`, value);
            setFilters((prev) => {
              if (!prev) return prev;
              const result = {
                ...prev,
                chartSettings: {
                  ...prev.chartSettings,
                  filters: [...prev.chartSettings.filters],
                },
              };
              result.chartSettings.filters[2] = {
                ...prev.chartSettings.filters[2],
                value: value,
              };
              return result;
            });
          },
        },
      ],
    },
    presented: {
      labelId: 'presented_tab',
      filters: [
        {
          labelId: 'presented_segments',
          type: FilterTypes.segments,
          value:
            saver.getObject<Segments>('presented_segments') ||
            initialSegments,
          setter: (value: Segments): void => {
            saver.set('presented_segments', value);
            setFilters((prev) => {
              if (!prev) return prev;
              return {
                ...prev,
                presented: {
                  ...prev.presented,
                  filters: [
                    {
                      ...prev.presented.filters[0],
                      value: [...value],
                    },
                  ],
                },
              };
            });
          },
          toUrlQuery: (value: Segments): string => {
            const outDtoSegments = getOutDtoSegments(value);
            return outDtoSegments.length > 0
              ? `&segments=${JSON.stringify(outDtoSegments)}`
              : '';
          },
        },
      ],
    },
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_originators',
          type: FilterTypes.multiselect,
          available: subcontractors,
          labels: createLabels(subcontractors),
          value:
            saver.getObject<string[]>(`data_scope_originators`) ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&originator=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.subcontractors)) {
              value = [];
            }
            saver.set(`data_scope_originators`, value);
            setFilters(
              (prev): ResolutionTimeFilters =>
                setMultiselectFilterValue(prev, 0, value)
            );
          },
          fieldRelation: IssueFieldNames.subcontractors,
        },
        {
          labelId: 'data_scope_date_range',
          type: FilterTypes.daterange,
          value: saver.getObject<DateRangeObject>(
            'data_scope_date_range',
            (value) => ({
              from: value.from,
              to: value.to,
            })
          ) || {
            from: fromDate1MonthEarlier(timezone),
            to: toDateToday(timezone),
          },
          toUrlQuery: (value: DateRangeObject): string => {
            return value.from && value.to
              ? `&from=${shortISOStringToProjectTimezonedString(
                  value.from,
                  timezone,
                  DayMomentValidation.start
                ).replace(
                  '+',
                  '%2B'
                )}&to=${shortISOStringToProjectTimezonedString(
                  value.to,
                  timezone,
                  DayMomentValidation.end
                ).replace('+', '%2B')}`
              : '';
          },
          setter: (value: DateRangeObject): void => {
            setFilters((prev): ResolutionTimeFilters => {
              if (!prev) return prev;
              const { from, to } = value;
              if (isDateDifferenceInvalid(from, to, timezone)) {
                showTooLargeIntervalErrorToast();
                return prev;
              }

              saver.set('data_scope_date_range', value);
              const result = {
                ...prev,
                dataScope: {
                  ...prev.dataScope,
                  filters: [...prev.dataScope.filters],
                },
              };
              result.dataScope.filters[1] = {
                ...prev.dataScope.filters[1],
                value: value,
              };
              return result;
            });
          },
        },
        {
          labelId: 'general_process',
          type: FilterTypes.checkbox,
          labels: processes.reduce<HashMap<string>>((labels, process) => {
            labels[process._id] = process.label;
            return labels;
          }, {}),
          value:
            saver.getObject<HashMap<boolean>>('data_scope_processes') ||
            Object.fromEntries(
              Object.values(processes).map((process) => [
                process._id,
                true,
              ])
            ),
          toUrlQuery: (value: HashMap<boolean>): string => {
            const selectedProcesses = Object.entries(value).reduce<
              string[]
            >((prev, [key, value]) => {
              if (value) {
                prev.push(key);
              }

              return prev;
            }, []);

            return selectedProcesses.length > 0
              ? `&process=[${selectedProcesses.join(',')}]`
              : '';
          },
          setter: (value: HashMap<boolean>): void => {
            saver.set('data_scope_processes', value);
            setFilters((prev) => {
              if (!prev) return prev;
              const result = {
                ...prev,
                dataScope: {
                  ...prev.dataScope,
                  filters: [...prev.dataScope.filters],
                },
              };
              result.dataScope.filters[2] = {
                ...prev.dataScope.filters[2],
                value: { ...value },
              };
              return result;
            });
          },
        },
        {
          labelId: 'data_scope_sites',
          type: FilterTypes.multiselect,
          available: sites,
          labels: createLabels(sites),
          value:
            saver.getObject<string[]>('data_scope_sites') ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&site=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.site)) {
              value = [];
            }
            saver.set('data_scope_sites', value);
            setFilters(
              (prev): ResolutionTimeFilters =>
                setMultiselectFilterValue(prev, 3, value)
            );
          },
          fieldRelation: IssueFieldNames.site,
        },
        {
          labelId: 'filters_filter_type_assignee',
          type: FilterTypes.multiselect,
          available: assignees,
          labels: createLabels(assignees),
          value:
            saver.getObject<string[]>(`data_scope_assignees`) ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&coordinator=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.assignee)) {
              value = [];
            }
            setFilters(
              (prev): ResolutionTimeFilters =>
                setMultiselectFilterValue(prev, 4, value)
            );
          },
          fieldRelation: IssueFieldNames.assignee,
        },
        {
          labelId: 'extendedField_Work_type',
          type: FilterTypes.multiselect,
          available: workTypes,
          labels: createLabels(workTypes),
          value:
            saver.getObject<string[]>(`data_scope_worktypes`) ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&workType=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.workTypes)) {
              value = [];
            }
            saver.set(`data_scope_worktypes`, value);
            setFilters(
              (prev): ResolutionTimeFilters =>
                setMultiselectFilterValue(prev, 5, value)
            );
          },
          fieldRelation: IssueFieldNames.workTypes,
        },
        {
          labelId: 'extendedField_Impact',
          type: FilterTypes.multiselect,
          available: impacts,
          labels: createLabels(impacts),
          value:
            saver.getObject<string[]>(`data_scope_impacts`) ||
            ([] as string[]),
          toUrlQuery: (value: string[]): string => {
            return value.length ? `&impact=[${value.join(',')}]` : '';
          },
          setter: (value: string[]): void => {
            if (!visibleFieldsSet.has(IssueFieldNames.impact)) {
              value = [];
            }
            saver.set(`data_scope_impacts`, value);
            setFilters(
              (prev): ResolutionTimeFilters =>
                setMultiselectFilterValue(prev, 6, value)
            );
          },
          fieldRelation: IssueFieldNames.impact,
        },
      ],
    },
  };
}
