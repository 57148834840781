import { DeleteDocumentUseCase } from 'shared/domain/document/removeDocument';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { DocumentEntity } from 'serviceWorker/repository/document/entity';
import { broadcastRemovedDocument } from 'serviceWorker/broadcasts/documents';
import {
  removeBatch as deleteDocuments,
  getOne as getDocument,
  updateOne as updateDocument,
} from 'serviceWorker/db/documents';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';

// exported for tests, do we need them thou?
export class DocumentDeleter implements DeleteDocumentUseCase {
  constructor(
    private getDocument: (
      localId: number
    ) => Promise<DocumentEntity | undefined>,
    private hardDelete: (localId: number[]) => Promise<any>,
    private softDelete: (localId: number) => Promise<any>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastRemovedDocument: (id: number) => void
  ) {}
  async execute(localId: number): Promise<void> {
    const document = await this.getDocument(localId);
    if (!document) {
      throw new Error(`Cannot find document with id ${localId}.`);
    }
    if (!document._id) {
      const ids: [number] = await this.hardDelete([localId]);
      this.broadcastRemovedDocument(ids[0]);
    } else {
      const currentProject = await this.getCurrentProject();
      if (!currentProject) {
        throw new Error('Cannot get current project.');
      }

      await this.softDelete(localId);
      await this.addRequest({
        createdAt: Date.now(),
        entityType: HttpRequestModelType.document,
        data: {
          localId,
          _id: document._id,
          projectId: currentProject._id,
        },
        method: 'DELETE',
        status: HttpRequestStatus.NEW,
      });
      this.broadcastRemovedDocument(localId);
    }
  }
}

const documentDeleter = new DocumentDeleter(
  getDocument,
  deleteDocuments,
  (localId: number) =>
    updateDocument(localId, {
      deleted: true,
      syncStatus: SyncStatus.PENDING_DELETE,
    }),
  addRequest,
  getCurrentProject,
  broadcastRemovedDocument
);

export default documentDeleter;
