export enum SORT_TYPE {
  STRING = 'string',
  NUMBERS = 'number',
}

export enum SortingOrder {
  desc = 'desc',
  asc = 'asc',
}

export type Sorting = {
  key: string;
  order: SortingOrder;
};
