import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { Suspender } from 'serviceWorker/api/apiSuspender/suspender';
import { ISuspender } from 'serviceWorker/api/apiSuspender/types';
import { ChannelNames } from 'shared/domain/channelNames';

export function createApiSuspender(): ISuspender {
  return new Suspender(
    [
      RepositoryMessagesTypes.languageChange,
      RepositoryMessagesTypes.clearSelectedProjectData,
      DomainMessagesTypes.clearData,
      DomainMessagesTypes.logout,
    ],
    [
      {
        message: DomainMessagesTypes.projectDataFlushed,
        afterStopSuspension: (): void => {
          const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
          broadcast.postMessage({
            type: DomainMessagesTypes.projectDataFlushed,
          });
          broadcast.close();
        },
      },
      {
        message: DomainMessagesTypes.dataFlushed,
        afterStopSuspension: (): void => {
          const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
          broadcast.postMessage({
            type: DomainMessagesTypes.dataFlushed,
          });
          broadcast.close();
        },
      },
    ]
  );
}
