import {
  emailValidation as _emailValidation,
  ErrorPresentation,
  notEmptyArrayValidation,
  requiredValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import {
  UserCreateOnView,
  UserEditOnView,
} from 'shared/domain/user/types/view';
import { ValidationSchema } from '../types';
import { noValidation, validate } from '../validation';
import { FieldSize } from 'shared/types/fieldSize';

export const editUserValidationSchema: ValidationSchema<UserEditOnView> = {
  _id: noValidation,
  label: labelValidation,
  email: emailValidation,
  role: roleValidation,
  sites: sitesValidation,
  processes: processesValidation,
};

export function validateEditUser(
  key: keyof UserEditOnView,
  value: UserEditOnView[keyof UserEditOnView]
): ErrorPresentation {
  return validate(key, value, editUserValidationSchema);
}

export function getCreateUserValidationSchema(
  emails
): ValidationSchema<UserCreateOnView> {
  return {
    label: labelValidation,
    email: makeUniqueEmailValidation(emails),
    role: roleValidation,
    sites: sitesValidation,
    processes: processesValidation,
  };
}

// export function makeValidate<T extends keyof UserOnView>(
//   isEdit: boolean,
//   emails: string[]
// ): (key: T, value: UserOnView[T]) => ErrorPresentation {
//   return function validate<T extends keyof UserOnView>(
//     key: T,
//     value: UserOnView[T]
//   ): ErrorPresentation {
//     return validation[key](value);
//   };
// }

function labelValidation(value: unknown): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.M, value)
  );
}

function emailValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value) || _emailValidation(value);
}

function roleValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value);
}

function sitesValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value) || notEmptyArrayValidation(value);
}

function processesValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value) || notEmptyArrayValidation(value);
}

function makeUniqueEmailValidation(
  emails: string[]
): (value: unknown) => ErrorPresentation {
  const uniqueValidation = (value: string): ErrorPresentation => {
    if (emails.includes(value.toLocaleLowerCase())) {
      return {
        helperText: { id: 'validation_user_already_exists' },
      };
    }
    return undefined;
  };

  return function uniqueEmailValidation(
    value: unknown
  ): ErrorPresentation {
    return emailValidation(value) || uniqueValidation(value as string);
  };
}
