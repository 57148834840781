import { useVisibleFieldsChannelListener } from 'components/broadcastChannelListeners/withVisibleFieldsChannelListener';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { Store, useCreateStore } from 'hooks/createStore';
import { useGetAllVisibleFields } from 'hooks/useGetAllVisibleFields';
import { useMountedRef } from 'hooks/useMountRef';
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
  useSyncExternalStore,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { processesSelector } from 'redux/selectors/processes';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { useEntityDataSubscription } from '../../common/useEntityDataSubscription';

type FieldVisibilityContextType = {
  fieldVisibilityStore: Store<HashMap<VisibleFieldModel[]>>;
  loading: boolean;
  resync: () => void;
};
const FieldVisibilityContext = React.createContext<
  FieldVisibilityContextType | undefined
>(undefined);

const WithFieldVisibility = ({ children }): ReactElement => {
  const dispatch = useDispatch();
  const mountedRef = useMountedRef();
  const processes = useSelector(processesSelector);

  const fieldVisibilityStore = useCreateStore(
    createInitialFieldVisibility(processes)
  );
  const [loading, setLoading] = useState(true);

  const { subscribe } = useVisibleFieldsChannelListener();
  const { getAll } = useGetAllVisibleFields();

  const setVisibleFields = useCallback(
    (res: { items: VisibleFieldModel[] }) => {
      if (!mountedRef.current) return;

      const visibleFields = res.items.reduce(
        (result, visibleField: VisibleFieldModel) => {
          if (!result[visibleField.processId]) {
            result[visibleField.processId] = [];
          }
          result[visibleField.processId].push(visibleField);
          return result;
        },
        createInitialFieldVisibility(processes)
      );
      fieldVisibilityStore.set(visibleFields);
    },
    [fieldVisibilityStore, mountedRef, processes]
  );

  useEntityDataSubscription({
    subscribe,
    getAll,
    setEntity: setVisibleFields,
    setLoading,
    entityName: 'visible fields',
  });

  const resync = useCallback(() => {
    getAll()
      .then((res) => {
        setVisibleFields(res);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        displayGenericErrorToaster(dispatch);
      });
  }, [dispatch, getAll, mountedRef, setVisibleFields]);

  const ctx: FieldVisibilityContextType = useMemo(
    () => ({
      fieldVisibilityStore,
      loading,
      resync,
    }),
    [fieldVisibilityStore, loading, resync]
  );

  return (
    <FieldVisibilityContext.Provider value={ctx}>
      {children}
    </FieldVisibilityContext.Provider>
  );
};

const withFieldVisibility =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithFieldVisibility>
      <Component {...props} />
    </WithFieldVisibility>
  );

function useFieldVisibility(): FieldVisibilityContextType {
  const context = React.useContext(FieldVisibilityContext);
  if (context === undefined) {
    throw new Error(
      'useFieldVisibility must be used within a FieldVisibilityContextProvider'
    );
  }
  return context;
}

function useVisibleFieldsState(): HashMap<VisibleFieldModel[]> {
  const { fieldVisibilityStore } = useFieldVisibility();
  const visibleFields = useSyncExternalStore(
    fieldVisibilityStore.subscribe,
    fieldVisibilityStore.get
  );

  return visibleFields;
}

export {
  WithFieldVisibility,
  useFieldVisibility,
  useVisibleFieldsState,
  withFieldVisibility,
};

function createInitialFieldVisibility(
  processes: ProcessType[]
): HashMap<VisibleFieldModel[]> {
  return processes.reduce((result, process) => {
    result[process._id] = [] as VisibleFieldModel[];
    return result;
  }, {});
}
