import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
type ContentProps = {
  isMobile: boolean;
};

const miniatureWithDescriptionHeight = 132;
const miniaturesMargin = 8;

export const height = {
  topBar: 50,
  bottom: miniatureWithDescriptionHeight + miniaturesMargin,
};

// sublocation dropdown items stay visible after click on map and wasn't able to fix this, their zIndex is 1300
export const galleryRootZIndex = 1200;
const controllerButtonsZIndex = galleryRootZIndex + 2;

const portalStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    position: 'fixed',
    background: '#000',
    width: '100%',
    height: ({ height }: { height: number }) => `${height}px`,
    top: '0px',
    left: '0px',
    zIndex: galleryRootZIndex,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'fit-content',
  },
}));

const styles = makeStyles((theme: Theme) => ({
  description: {
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: `calc(100% - ${height.topBar}px - ${height.bottom}px)`,
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    userSelect: 'none',
  },
  drawing: {
    display: 'block',
    width: '100%',
    height: `100%`,
  },
  mainFileDisplay: {
    height: '100%',
    flexBasis: '100%',
    '& .file-preview': {
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      '& .transform-image-class': {
        overflow: 'unset !important',
      },
      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'scale-down',
      },
      '& video': {
        maxWidth: '100%',
        height: '100%',
        objectFit: 'contain',
      },
    },
    '& .file-no-preview': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      height: '100%',
      '& .couldnt-preview-label': {
        color: '#FFFFFF',
        margin: '32px 0',
        fontSize: '1.5rem',
      },
    },
  },
  drawingDisplay: {
    objectFit: 'contain',
    maxWidth: '100%',
    maxHeight: 'calc(100% - 90px)',
  },
  topBarContainer: {
    display: `flex`,
    flexDirection: `column`,
  },
  topBar: {
    color: '#fff',
    height: `${height.topBar}px`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexShrink: 0,
    flexGrow: 0,
    flexBasis: `${height.topBar}px`,
  },
  title: {
    marginLeft: '16px',
    fontFamily: 'Archivo',
    fontSize: '0.875rem',
    fontWeight: 700,
    lineHeight: '15px',
    letterSpacing: '0em',
    textAlign: 'left',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  bottom: {
    display: 'flex',
    flexDirection: `column`,
    flexShrink: 0,
    flexGrow: 0,
    position: 'relative',
  },
  miniaturesWithControl: {
    position: 'relative',
    overflow: 'hidden',
  },
  miniatureWithDescription: {
    display: 'flex',
    flexDirection: 'column',
    height: `${miniatureWithDescriptionHeight}px`,
  },
  miniatureText: {
    fontFamily: 'Archivo',
    fontSize: '0.75rem',
    fontWeight: 100,
    lineHeight: '13px',
    color: '#FFF',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100px',
    margin: '0 5px',
  },
  miniatures: {
    marginTop: `${miniaturesMargin}px`,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    transition: 'transform 0.15s ease-in-out',
    padding: '0 calc(50% - 57px)',
    overflowX: 'auto',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': { display: 'none' },
  },
  miniatureImage: {
    backgroundColor: 'transparent !important',
  },
  miniaturesGradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backgroundImage:
      'linear-gradient(90deg, rgba(0, 0, 0, 0.8) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgba(0, 0, 0, 0.8) 100%)',
    zIndex: controllerButtonsZIndex - 1,
    pointerEvents: 'none',
  },

  textButton: {
    border: 'none',
    background: 'none',
    color: '#fff',
    fontSize: '1rem',
    padding: '5px 10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Archivo',
  },

  topElement: {
    minWidth: ({ isMobile }: ContentProps): string =>
      isMobile ? '50px' : '150px',
  },

  actionsBtn: {
    float: 'right',
    padding: '0 10px',
    cursor: 'pointer',
  },

  dropdown: {
    background: '#fff',
    borderRadius: '4px',
    position: 'absolute',
    height: 0,
    overflow: 'hidden',
    opacity: 0,
    transition: 'opacity 0.2s linear',
    minWidth: '200px',
    right: '20px',
    marginTop: '20px',
    zIndex: 19,
  },
  dropdownOpen: {
    height: 'auto',
    opacity: 1,
    overflow: 'visible',
  },
  actionsDesktop: {
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    marginRight: '16px',
    '& > span': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      padding: '6px',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },
    },
    '& li': {
      width: '24px',
      height: '24px',
      borderRadius: '50%',
      padding: '6px',
      listStyleType: 'none',
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        },
      },
      '& > span': {
        display: 'none',
      },
    },
  },
  actions: {
    listStyle: 'none',
    padding: 0,
    zIndex: 20,

    '& li': {
      background: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      cursor: 'pointer',
      padding: '10px 20px',
      fontSize: '1rem',
      transition: 'background 0.2s linear',
      color: '#000',
      zIndex: 20,

      '&:hover': {
        background: 'rgba(0,0,0,0.1)',
      },
    },
    '& svg': {
      padding: '0 15px 0 0',
    },
  },

  dropdownDisabled: {
    color: 'rgba(0,0,0,0.4) !important',
    cursor: 'no-drop !important',
  },
  desktopIconDisabled: {
    color: 'rgba(255,255,255,0.4) !important',
    cursor: 'no-drop !important',
    '&:hover': {
      color: 'rgba(0,0,0,0.4) !important',
    },
  },

  editDescriptionRoot: {
    '& .MuiFormControl-root': {
      width: '100%',
    },
    padding: '24px 16px',
    paddingBottom: 0,
    gap: '0.5rem',
    display: 'flex',
    flexDirection: 'column',
  },
  editDescriptionPadding: {
    padding: '24px 16px',
  },
  buttonsContainer: {
    padding: '24px 0 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& div': {
      cursor: 'pointer',
      padding: '0 16px',
    },
  },
  video: {
    margin: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100px',
    height: '100px',
    objectFit: 'cover',
    cursor: 'pointer',
    border: '2px solid transparent',
    backgroundColor: '#EEE',
  },
  active: {
    borderColor: theme.palette.primary.main,
  },
  activeText: {
    color: theme.palette.primary.main,
  },
  controller: {
    top: '50%',
    color: '#fff',
    position: 'absolute',
    marginTop: '-10px',
    background: '#000',
    borderRadius: '50%',
    width: '36px',
    height: '36px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    zIndex: controllerButtonsZIndex,
    '&.left': {
      left: '20px',
    },
    '&.right': {
      right: '20px',
    },
  },
  iconContainer: {
    background: '#00AEEF',
    borderRadius: '20px',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 10px 0 0',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  miniatureSkeleton: {
    '&.MuiSkeleton-root': {
      backgroundColor: 'rgba(255, 255, 255, 0.41)',
    },
  },
}));

const FOOTER_HEIGHT = 56;
const useDrawComponentStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawContainer: {
      position: 'absolute',
      top: '75px',
    },
    drawFooter: {
      position: 'absolute',
      bottom: '0',
      backgroundColor: 'white',
      display: 'flex',
      width: '100%',
      height: `${FOOTER_HEIGHT}px`,
      justifyContent: 'space-between',
      alignContent: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    drawButtons: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      width: '48px',
      right: '26px',
      bottom: `${FOOTER_HEIGHT + 26}px`,
      zIndex: 20,
      '& > *': {
        borderRadius: '50%',
        marginTop: '11px',
      },
      '& > .toggle .MuiButtonBase-root': {
        background: '#FFFFFF',
        borderRadius: '50%',
        '&.active': {
          background: theme.palette.primary.main,
          '& svg': {
            fill: theme.palette.primary.contrastText,
          },
        },
      },
      '& > .button': {
        background: '#FFFFFF',
        '& .MuiSvgIcon-root': {
          fill: '#000000',
        },
        '& .MuiIconButton-label svg': {
          fill: '#000000',
        },
      },
    },
  })
);

export { useDrawComponentStyles, portalStyles };
export default styles;
