import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { WithStyles, withStyles } from '@mui/styles';
import { Typography, TypographyProps } from '@mui/material';

const subheaderStyles = {
  subheading: {
    fontSize: '1.5rem',
    lineHeight: '1.625rem',
    fontWeight: 600,
  },
};

type SubheaderTextProps = PropsWithChildren<
  WithStyles<any> & TypographyProps
>;

const SubheaderText = ({
  children,
  classes,
  ...props
}: SubheaderTextProps): JSX.Element => (
  //@ts-ignore it doesn't know that there is a prop 'component' and we can pass a string to it
  <Typography
    {...props}
    component='h2'
    color='textPrimary'
    className={classNames(props.className, classes.subheading)}
  >
    {children}
  </Typography>
);

export default withStyles(subheaderStyles)(SubheaderText);
