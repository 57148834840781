import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { GetReportSignedRequestModel } from './getReportModel';

function startGetReportSignedRequestUseCase(
  params: GetReportSignedRequestModel
): void {
  debugLog('startGetReportSignedRequestUseCase');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.REPORTS,
    method: ServiceMethods.GET_ONE,
    data: params,
  });
  broadcast.close();
}

export default startGetReportSignedRequestUseCase;
