import { toLabelledInvolvementType } from 'shared/domain/company/mapping';
import { CompanyModel } from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { SiteModel } from 'shared/domain/site/types/model';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { IntlShape } from 'react-intl';
import { idsToLabelledEntities } from 'shared/utils/id/idsToLabelledEntities';

export function companyModelToCompanyOnView(
  model: CompanyModel,
  // contractNumbers: LabelledEntity[],
  sites: SiteModel[],
  intl: IntlShape
): CompanyOnView {
  return {
    createdAt: stringToViewDate(model.createdAt),
    modifiedAt: stringToViewDate(model.modifiedAt),
    _id: model._id,
    deleted: model.deleted,
    createdBy: model.createdBy,
    modifiedBy: model.modifiedBy,
    longLabel: model.longLabel,
    projectInvolvementType: toLabelledInvolvementType(
      model.projectInvolvementType,
      intl
    ),
    shortLabel: model.shortLabel,
    taxNumber: model.taxNumber,
    // contracts: idToLabelledEntities(model.contracts, contractNumbers),
    sites: idsToLabelledEntities(model.sites, sites),
  };
}
