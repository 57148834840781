import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { useCreateLevel } from 'components/dataCreationForms/level/withCreateLevel';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { MemoLevelForm } from 'components/level/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FORM_NAME } from 'views/level/model';

function CreateLevelWizardDialog(): React.ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useCreateLevel();
  useFormCleanupOnUnmount(FORM_NAME);

  return (
    <Dialog open={open} fullWidth onClose={closeDialog}>
      <WithInputForm
        values={initialValues}
        errors={{}}
        handleFormValidation={handleFormValidation}
        handleFieldValidation={validate}
        onSubmit={submitForm}
        eventType={SUBMIT_EVENT_TYPE}
        dispatchSubmit={releaseSubmitEvent}
        formName={FORM_NAME}
      >
        <DialogTitle>
          <FormattedMessage id='create_level' />
        </DialogTitle>
        <Divider />

        <DialogContent>
          <MemoLevelForm />
        </DialogContent>
        <Divider />
        <DialogActions>
          <DialogButtons
            isPosting={isPosting}
            onSubmit={releaseSubmitEvent}
            onCancel={closeDialog}
          />
        </DialogActions>
      </WithInputForm>
    </Dialog>
  );
}

export const MemoCreateLevelWizardDialog = memo(CreateLevelWizardDialog);
