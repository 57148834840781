import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceData } from 'serviceWorker/db/service';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { Broadcaster, SingleEntityBroadcaster } from './types';
import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { LogLevel } from 'shared/types/logger';

export function makeBroadcastAll<T>(
  entityService: { get: () => Promise<ServiceData | undefined> },
  entityRepository: { get: () => Promise<T | undefined> },
  channel: ChannelNames,
  messageType: DomainMessagesTypes
): Broadcaster {
  return async function allBroadcaster(): Promise<void> {
    const [status, items] = await Promise.all([
      entityService.get(),
      entityRepository.get(),
    ]);

    const hasAll = status?.isDownloaded;
    const data = {
      hasAll,
      items,
    };
    const broadcast = new BroadcastChannel(channel);
    broadcast.postMessage({ data, type: messageType });
    broadcast.close();
  };
}

export function makeBroadcastAllDeleted(
  entityService: { get: () => Promise<ServiceData | undefined> },
  entityRepository: { get: (query: any) => Promise<any | undefined> },
  channel: ChannelNames,
  messageType: DomainMessagesTypes
): Broadcaster {
  return async function allBroadcaster(): Promise<void> {
    const [status, items] = await Promise.all([
      entityService.get(),
      entityRepository.get({ deleted: 1 }),
    ]);

    const hasAll = status?.isDownloaded;
    const data = {
      hasAll,
      items,
    };
    const broadcast = new BroadcastChannel(channel);
    broadcast.postMessage({ data, type: messageType });
    broadcast.close();
  };
}

export function makeBroadcastEntity<T>(
  entityRepository: {
    getOne: (id: string) => Promise<T | undefined>;
  },
  channel: ChannelNames,
  messageType: DomainMessagesTypes
): SingleEntityBroadcaster {
  return async function singleEntityBroadcaster(e): Promise<void> {
    const broadcast = new BroadcastChannel(channel);
    debugLog('singleEntityBroadcaster', e, channel, messageType);
    try {
      const entity = await entityRepository.getOne(e.detail.id);
      debugLog('entity', entity);
      broadcast.postMessage({
        data: entity,
        type: messageType,
      });
    } catch (error) {
      swLog(
        'Error occured when broadcasting entity',
        LogLevel.INFO,
        error,
        {
          entityName: broadcast.name,
        }
      );
      broadcast.postMessage({
        error: {
          id: e.detail.id,
          errorObject: error,
        },
        type: messageType,
      });
    }
    broadcast.close();
  };
}

export function makeBroadcastClear(channel: ChannelNames): Broadcaster {
  return async function entityClearBroadcaster(): Promise<void> {
    const broadcast = new BroadcastChannel(channel);
    debugLog(
      'Broadcasting clear data',
      channel,
      DomainMessagesTypes.clearData
    );
    broadcast.postMessage({ type: DomainMessagesTypes.clearData });
    broadcast.close();
  };
}

export function makeSimpleBroadcastError(channel: ChannelNames): any {
  return function simpleBroadcastError(
    type: DomainMessagesTypes,
    error: any
  ): void {
    const broadcast = new BroadcastChannel(channel);
    broadcast.postMessage({
      error,
      type,
    });
    broadcast.close();
  };
}

export function makeSimpleBroadcast<T>(channel: ChannelNames): any {
  return function simpleBroadcast(
    type: DomainMessagesTypes,
    data: T
  ): void {
    const broadcast = new BroadcastChannel(channel);
    broadcast.postMessage({
      data,
      type,
    });
    broadcast.close();
  };
}
