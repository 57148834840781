import { ServiceDataShape } from './types';

export function getFinishedServiceStateWithSyncKey(
  totalCount: number,
  syncKey: string
): ServiceDataShape {
  return {
    total: totalCount,
    isDownloading: false,
    isDownloaded: true,
    syncKey: syncKey,
  };
}

export function getFinishedServiceStateWithoutSyncKey(
  totalCount: number
): ServiceDataShape {
  return {
    total: totalCount,
    isDownloading: false,
    isDownloaded: true,
  };
}
