import { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import { DisplayedFile } from './filesStore';

export type MainDisplayedFile<T = object> = DisplayedFile<T>;
type MainFileStore<T = object> = {
  get: () => MainDisplayedFile<T> | undefined;
  set: (value: MainDisplayedFile<T>) => void;
  subscribe: (callback: () => void) => () => void;
};

export function useStoreMainDisplayedFile<T = object>(): MainFileStore<T> {
  const mainFileRef = useRef<MainDisplayedFile<T> | undefined>({
    loading: true,
  } as MainDisplayedFile<T>);
  const get = useCallback(() => mainFileRef.current, []);

  const subscribers = useRef(new Set<() => void>());

  const set = useCallback((value: MainDisplayedFile<T>) => {
    mainFileRef.current = value;
    subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return useMemo(
    () => ({
      get,
      set,
      subscribe,
    }),
    [get, set, subscribe]
  );
}

export function makeMainFileStore<K = object>(
  mainFileStore: MainFileStore<K>
) {
  return function mainFileWithSelector<T>(
    selector: (store: MainDisplayedFile<K> | undefined) => T
  ): [T, (value: MainDisplayedFile<K>) => void] {
    const [state, setState] = useState(selector(mainFileStore.get()));

    useEffect(() => {
      return mainFileStore.subscribe(() =>
        setState(selector(mainFileStore.get()))
      );
    }, [selector]);

    return [state, mainFileStore.set];
  };
}
