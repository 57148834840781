import React, { ReactElement } from 'react';
import { useStyles } from './styles';
import classNames from 'classnames';
import { ProcessIconProps } from './types';

function ProcessIcon(props: ProcessIconProps): ReactElement {
  const { color, code, size, className } = props;
  const classes = useStyles();
  const processIconStyle = React.useMemo(
    () => ({ backgroundColor: color as string }),
    [color]
  );
  return (
    <div
      className={classNames(
        classes.processIcon,
        classes[size || 'default'],
        className
      )}
      style={processIconStyle}
    >
      <span>{code}</span>
    </div>
  );
}

export { ProcessIcon };
export const MemoProcessIcon = React.memo(ProcessIcon);
