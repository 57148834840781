import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projectDataSelector } from 'redux/selectors/project';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ContractModel } from 'shared/domain/contract/types/model';

type ContractContextType = {
  contract: ContractModel | undefined;
  loading: boolean;
};

const ContractContext = React.createContext<
  ContractContextType | undefined
>(undefined);

const WithContract: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const projectData = useSelector(projectDataSelector);

  const [contract, setContract] = useState<ContractModel | undefined>(
    undefined
  );

  const [loading, setLoading] = useState<boolean>(Boolean(id));

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.contractChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.contract &&
        event.data._id === id
      ) {
        broadcast.close();
        setContract(event.data);
        setLoading(false);
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, [id, projectData]);

  const ctx = {
    contract: contract,
    loading: loading,
  };

  return (
    <ContractContext.Provider value={ctx}>
      {children}
    </ContractContext.Provider>
  );
};

export function useContract(): ContractContextType {
  const context = React.useContext(ContractContext);
  if (context === undefined) {
    throw new Error(
      'useContract must be used within an ContractContextProvider'
    );
  }
  return context;
}

export const withContract =
  (Component: React.ComponentType) =>
  ({ ...props }): React.ReactElement => (
    <WithContract>
      <Component {...props} />
    </WithContract>
  );
