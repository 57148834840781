import { EmptyTableInfo } from 'components/common/noTableContentInfo/NoTableContentInfo';
import { Nothing } from 'components/general/Nothing';
import { ColumnKey } from 'components/table/columnShapes/types';
import { toReactColumn } from 'components/table/skeleton';
import {
  sortTableEntities,
  useColumnSortHandler,
} from 'components/table/sort';
import { useSavedTableColumnWidths } from 'hooks/table/useSavedTableColumnWidths';
import React, { ReactElement, useMemo, useState } from 'react';
import BaseTable from 'react-base-table';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { USER_ROLE_LABEL_ID } from 'shared/types/userRole';
import { filterOutSuperAdmin } from 'shared/domain/user/filterSuperAdmin';
import { UserModel } from 'shared/domain/user/userModel';
import { Sorting, SortingOrder } from 'shared/filter/sort/types';
import { Identificable } from 'shared/types/commonView';
import { useUsers } from '../../dataProviders/withUsers';
import { TableWidthStorageKey } from '../../table/types';
import { UserColumnsFactory } from './columns';
import { useStyles } from './styles';

type UserWithTranslatedData = Omit<UserModel, 'role'> & { role: string };

export function UserTable({
  width,
  height,
}: {
  width: number;
  height: number;
}): ReactElement {
  const { saveColumnWidth } =
    useSavedTableColumnWidths<UserWithTranslatedData>(
      TableWidthStorageKey.user
    );
  const {
    filtered: { items: users },
    emptyListReason,
  } = useUsers();
  const usersWithoutSuperAdmins = useMemo(
    () => users.filter(filterOutSuperAdmin),
    [users]
  );

  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const rowEventHandlers = React.useMemo(() => {
    return {
      onClick: ({ rowData }: { rowData: Identificable }): void => {
        history.push(`user/${rowData._id}`);
      },
    };
  }, [history]);
  const { columnWidths } = useSavedTableColumnWidths(
    TableWidthStorageKey.user
  );

  const columns = React.useMemo(
    () =>
      new UserColumnsFactory(intl.formatMessage, columnWidths).create(),
    [intl.formatMessage, columnWidths]
  );

  const [sortBy, setSortBy] = useState<Sorting>({
    key: ColumnKey.label,
    order: SortingOrder.asc,
  });
  const usersWithTranslatedRole = useMemo(
    () =>
      usersWithoutSuperAdmins.map((user) => {
        return {
          ...user,
          role: intl.formatMessage({
            id: `${USER_ROLE_LABEL_ID[user.role]}`,
          }),
        };
      }),
    [usersWithoutSuperAdmins, intl]
  );

  const sortedUsers = useMemo(
    () => sortTableEntities(usersWithTranslatedRole, columns, sortBy),
    [usersWithTranslatedRole, columns, sortBy]
  );

  const onColumnSort = useColumnSortHandler(setSortBy);

  if (emptyListReason) {
    return <EmptyTableInfo emptyTableReason={emptyListReason} />;
  }
  const reactColumns = columns.map(toReactColumn);
  return (
    <BaseTable
      fixed
      rowKey='_id'
      width={width}
      height={height}
      data={sortedUsers}
      sortBy={sortBy}
      onColumnSort={onColumnSort}
      className={classes.noOverflow}
      rowEventHandlers={rowEventHandlers}
      onColumnResizeEnd={saveColumnWidth}
      components={{
        SortIndicator: Nothing,
      }}
    >
      {reactColumns}
    </BaseTable>
  );
}
