import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Color } from 'shared/utils/colors';
import { useLogout } from '../WithLogout';

export const SomethingWentWrongPage = (): React.ReactElement => {
  const { logout } = useLogout();

  const onLogoutClick = (e: React.MouseEvent): void => {
    e.preventDefault();
    logout();
  };
  return (
    <Box
      margin='dense'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
      sx={{ p: 4 }}
    >
      <Typography sx={{ py: 4 }} variant='h3' component='span'>
        <FormattedMessage id='gdpr_clause_error_title' />
      </Typography>
      <Typography
        sx={{ textAlign: 'center' }}
        variant='h5'
        component='span'
      >
        <FormattedMessage id='errorPage_unexpectedError' />
      </Typography>
      <Button
        // errorboundary page is defined before theme is loaded so we have to style it manually
        sx={{ m: 4, backgroundColor: Color.primaryOrange, color: 'black' }}
        size='large'
        variant='contained'
        onClick={onLogoutClick}
      >
        <FormattedMessage id='general_log_out' />
      </Button>
    </Box>
  );
};
