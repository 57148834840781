import { updateOne as updateDocumentation } from 'serviceWorker/db/documentations';

export interface AddRemoteIdToDocumentation {
  (localId: number, remoteId: string): Promise<number>;
}

export const addRemoteIdToDocumentation: AddRemoteIdToDocumentation =
  function _addRemoteIdToDocumentation(localId, remoteId) {
    return updateDocumentation(
      localId,
      { _id: remoteId },
      { skipModifyTime: true }
    );
  };
