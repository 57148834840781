import { BroadcastChannel } from 'broadcast-channel';
import { CompanyEntity } from 'shared/domain/company/types/entity';
import { ContractModel } from 'shared/domain/contract/types/model';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ContractEntity } from 'serviceWorker/repository/contract/entity';
import { contractEntityToModel } from 'serviceWorker/repository/contract/mapping';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { getCompaniesByIds } from 'serviceWorker/services/companies';
import {
  getCurrentUsers,
  getUsersSynchronized,
} from 'serviceWorker/services/users/getUsers';
import { debugLog } from 'shared/logger/debugLog';
import * as dbCompanies from '../../db/companies';
import * as dbContracts from '../../db/contracts';
import * as dbContractsService from '../../db/contractsService';
import { makeBroadcastAll } from '../factories';

const getAllContractPartiesIds = function (
  contractEntities: ContractEntity[]
): string[] {
  const result: string[] = [];
  contractEntities.forEach((contractEntity) =>
    contractEntity.parties.forEach((party) => result.push(party.companyId))
  );
  return result;
};

export async function getAllContractPartyEntities(
  contractEntities: ContractEntity[]
): Promise<CompanyEntity[]> {
  const allContractPartiesIds = getAllContractPartiesIds(contractEntities);
  return getCompaniesByIds(allContractPartiesIds);
}

const getAll = async (): Promise<ContractModel[]> => {
  const contractEntities = await dbContracts.get();
  const [allContractPartiesEntities, users] = await Promise.all([
    getAllContractPartyEntities(contractEntities),
    getUsersSynchronized(),
  ]);
  return contractEntities.map((contractEntity) =>
    contractEntityToModel(
      contractEntity,
      allContractPartiesEntities,
      users
    )
  );
};

export const getAndBroadcastAllContracts = makeBroadcastAll<
  ContractModel[]
>(
  dbContractsService,
  { get: getAll },
  ChannelNames.contractChannel,
  DomainMessagesTypes.allContracts
);

export async function getAllCurrentContracts(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const [contracts, companies, users] = await Promise.all([
    dbContracts.get(),
    dbCompanies.get(),
    getCurrentUsers(),
  ]);
  const end = Date.now();
  debugLog('getAllCurrentContracts time:', end - start);
  const currentContracts = contracts.map((contract) =>
    contractEntityToModel(contract, companies, users)
  );
  broadcast.postMessage({
    data: { items: currentContracts },
    type: DomainMessagesTypes.allContracts,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.CONTRACTS,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
