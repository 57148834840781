import { Spinner } from 'components/core';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';

export const withProcessesData =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithProcessesData>
      <Component {...props} />
    </WithProcessesData>
  );

type WithProcessesDataType = { children: ReactElement };

function WithProcessesData({
  children,
}: WithProcessesDataType): ReactElement {
  const processes = useSelector(processesSelector);

  if (!processes.length) {
    return <Spinner reason={`!processes.length: ${processes}`} />;
  }

  return children;
}
