import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { getCompanies } from 'serviceWorker/services/companies';
import {
  ApexSeriesAndLabels,
  DateRangeObject,
  OriginatorFilters,
} from 'shared/types/analytics';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByImpact,
  filterByProcess,
  filterBySite,
  filterByWorktype,
  increaseEntitySeries,
  increaseNoneSeries,
  toSortedApexSeries,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: OriginatorFilters,
  timezone: string
): Promise<ApexSeriesAndLabels> {
  const originators = {};
  const companiesMap = (await getCompanies()).reduce((map, company) => {
    map.set(company._id, { _id: company._id, label: company.shortLabel });
    return map;
  }, new Map<string, LabelledEntity>());

  const [
    dateRangeFilter,
    processFilter,
    siteFilter,
    workTypeFilter,
    impactFilter,
    assigneeFilter,
  ] = filters.dataScope.filters;

  const filteredIssues = issues.filter((issue) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedProcess = filterByProcess(
      processFilter.value as string[],
      issue
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterBySite(siteFilter.value as string[], issue);
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterByWorktype(
      workTypeFilter.value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedImpact = filterByImpact(
      impactFilter.value as string[],
      issue
    );
    if (!passedImpact) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      assigneeFilter.value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    return true;
  });

  const reducedToOriginatorsSummary = filteredIssues.reduce<
    HashMap<number>
  >((result, issue) => {
    const subcontractors = issue.primaryData.subcontractors || [];

    if (!subcontractors.length) {
      increaseNoneSeries(result, originators);
    } else {
      subcontractors.forEach((subcontractor) =>
        increaseEntitySeries(
          result,
          originators,
          companiesMap.get(subcontractor) as LabelledEntity
        )
      );
    }

    return result;
  }, {});

  return toSortedApexSeries(reducedToOriginatorsSummary, originators);
}
