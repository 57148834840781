import Popper, { PopperProps } from '@mui/material/Popper';
import { FormFieldProps } from 'components/inspection/Form/types';
import { BaseReportModel } from 'shared/domain/report/reportModel';
import { LANGUAGE_LABELS, LOCALE_LANGUAGES } from 'shared/types/locale';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { LabelledEntity } from 'shared/types/commonView';
import { ReportFormPresentational } from './presentational';

function DialogPopper(
  props: PopperProps & { anchorEl: any }
): JSX.Element {
  const [zIndex, setZIndex] = useState<number | undefined>();
  useEffect(() => {
    const context = props.anchorEl.closest('[style*=z-index]');
    if (context) {
      setZIndex(parseInt(context.style.zIndex) + 1);
    }
  }, [props.anchorEl]);

  const style = useMemo(() => {
    return { ...props.style, zIndex: zIndex };
  }, [props.style, zIndex]);
  return <Popper {...props} placement='bottom' style={style} />;
}

export function ReportForm(props: {
  onSubmit: () => void;
  onCancel: () => void;
  isPosting: boolean;
  hasImageContent?: boolean;
  ContentSelection: () => ReactElement | null;
}): ReactElement {
  const { onSubmit, onCancel, isPosting, ContentSelection } = props;

  const recipientsFieldProps: FormFieldProps<BaseReportModel> = useMemo(
    () => ({
      formKey: 'recipients',
      required: true,
      labelId: 'send_report_label_to',
      fieldName: 'text-field-report-recipients',
      PopperComponent: DialogPopper,
      dense: false,
      reserveSpaceForHelperText: false,
      'data-qa': 'report_recipients_field',
    }),
    []
  );
  const nameFieldProps: FormFieldProps<BaseReportModel> = useMemo(
    () => ({
      formKey: 'name',
      required: true,
      labelId: 'send_report_label_name',
      fieldName: 'text-field-report-name',
      localStorageKey: 'report-name',
      dense: false,
      reserveSpaceForHelperText: false,
      'data-qa': 'report_name_field',
    }),
    []
  );
  const messageFieldProps: FormFieldProps<BaseReportModel> = useMemo(
    () => ({
      formKey: 'message',
      labelId: 'send_report_label_message',
      required: false,
      fieldName: 'text-field-report-message',
      minRows: 3,
      localStorageKey: 'report-emailMessage',
      dense: false,
      reserveSpaceForHelperText: false,
      'data-qa': 'report_message_field',
    }),
    []
  );

  const localeCodeFieldProps: FormFieldProps<BaseReportModel> = useMemo(
    () => ({
      formKey: 'localeCode',
      labelId: 'send_report_language',
      required: true,
      fieldName: 'select-language-code',
      available: Object.keys(LANGUAGE_LABELS).map((key: string) => ({
        _id: LOCALE_LANGUAGES[key],
        label: LANGUAGE_LABELS[key],
      })),
      getOptionLabel: (option) => option.label,
      getOptionSelected: (
        option: LabelledEntity,
        value: LabelledEntity
      ): boolean => option._id === value._id,
      minRows: 3,
      dense: false,
      reserveSpaceForHelperText: false,
      'data-qa': 'report_language_field',
    }),
    []
  );

  return (
    <ReportFormPresentational
      onSubmit={onSubmit}
      onCancel={onCancel}
      isPosting={isPosting}
      recipientsFieldProps={recipientsFieldProps}
      nameFieldProps={nameFieldProps}
      messageFieldProps={messageFieldProps}
      localeCodeFieldProps={localeCodeFieldProps}
      ContentSelection={ContentSelection}
    />
  );
}
