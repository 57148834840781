import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { updateOne as updateDirectory } from 'serviceWorker/db/directories';

export interface SetDirectorySync {
  (localId: number, syncStatus: SyncStatus): Promise<number>;
}

export const setDirectorySync: SetDirectorySync =
  async function _setDirectorySync(localId, syncStatus) {
    return updateDirectory(localId, { syncStatus });
  };
