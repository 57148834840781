import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  shortLogo: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  shortLogoContainer: {
    width: '64px',
    height: '64px',
    boxSizing: 'border-box',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    flexShrink: 0,
    // '&:hover': {
    //   cursor: 'pointer',
    // },
  },
}));

export default useStyles;
