import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { OrganizationCreateModel } from './types/model';

export function startOrganizationCreate(
  data: OrganizationCreateModel,
  uniqueId: string
): void {
  debugLog('startOrganizationCreate', data);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.ORGANIZATIONS,
    method: ServiceMethods.CREATE,
    data: { organizationCreateModel: data, uniqueId },
  });
  broadcast.close();
}
