import React, { ReactElement } from 'react';

import ViewWrapper from 'components/core/Layout/ViewWrapper';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoChartDetails } from './chartDetails';
import { MemoAnalyticsDashboard } from './dashboard';
import { useSyncIssues } from 'hooks/useSyncIssues';

type AnalyticsParams = {
  id: string;
};
function Analytics({ id }: AnalyticsParams): ReactElement {
  //TODO https://hustro.atlassian.net/browse/PT-2401
  useSyncIssues(true);
  return (
    <ViewWrapper>
      {id === undefined ? (
        <MemoAnalyticsDashboard />
      ) : (
        <MemoChartDetails id={id} />
      )}
    </ViewWrapper>
  );
}

export const MemoAnalytics = React.memo(
  withFetcherReady(withProcessesData(Analytics))
);
