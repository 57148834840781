import { useCallback } from 'react';
import { WarnableLevelChanges } from 'shared/domain/documentation/documentationAsMapWarnings';
import { LabelledEntity } from 'shared/types/commonView';
import { LevelModel } from 'shared/domain/level/types/model';
import { DocumentationBaseIds } from 'shared/types/documentation';

export function useWarningsOnLevelChange(
  check: WarnableLevelChanges
): WarnableLevelChanges {
  return useCallback(
    (
      values: LabelledEntity[] | undefined,
      levels: LevelModel[],
      currentIds: DocumentationBaseIds
    ) => {
      return check(values, levels, currentIds);
    },
    [check]
  );
}
