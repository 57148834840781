import { IntlShape } from 'react-intl';
import {
  CreatableUserRole,
  USER_ROLE_LABEL_ID,
  UserRole,
} from 'shared/types/userRole';

const userRolesOrder: Record<CreatableUserRole, number> = {
  [UserRole.standard]: 0,
  [UserRole.viewer]: 1,
  [UserRole.manager]: 2,
  [UserRole.admin]: 3,
  [UserRole.clientAdmin]: 4,
};

export const createUserRoleDropdownOptions = (
  authorRole: UserRole,
  intl: IntlShape
): {
  _id: CreatableUserRole;
  label: string;
}[] => {
  const creatableRoles: CreatableUserRole[] =
    getCreatableRoles(authorRole);

  return creatableRoles
    .sort(orderByRoleAsc)
    .map((value) => toLabelledUserRole(value, intl));
};

function getCreatableRoles(authorRole: UserRole): CreatableUserRole[] {
  return authorRole === UserRole.superAdmin ||
    authorRole === UserRole.clientAdmin
    ? [
        UserRole.clientAdmin,
        UserRole.admin,
        UserRole.manager,
        UserRole.standard,
        UserRole.viewer,
      ]
    : [
        UserRole.admin,
        UserRole.manager,
        UserRole.standard,
        UserRole.viewer,
      ];
}

function orderByRoleAsc(
  role1: CreatableUserRole,
  role2: CreatableUserRole
): number {
  return userRolesOrder[role1] - userRolesOrder[role2];
}

export function toLabelledUserRole(
  role: CreatableUserRole,
  intl: IntlShape
): { _id: CreatableUserRole; label: string } {
  return {
    _id: role,
    label: intl.formatMessage({
      id: `${USER_ROLE_LABEL_ID[role]}`,
    }),
  };
}
