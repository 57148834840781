import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../setup/types/core';
import { ProcessType } from 'shared/types/form';

type GeneratedProcessIconProps = {
  processId: string;
  className?: string;
  style?: { fontSize?: string };
};

type StylesProps = {
  process: ProcessType;
};

const useStyles = makeStyles({
  icon: (props: StylesProps) => ({
    backgroundColor: props.process.color as string,
    width: '100%',
    height: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  processCode: {
    color: '#FFF',
    fontSize: '1rem',
    fontFamily: 'Archivo',
  },
});

const GeneratedProcessIcon = (
  props: GeneratedProcessIconProps
): React.ReactElement => {
  const { processId, className, style } = props;

  const processes = useSelector(
    (state: StoreState) => state.projectData.processes
  );
  const currentProcess = processes.find(
    (process) => process._id === processId
  )!;

  const classes = useStyles({ process: currentProcess });

  return (
    <div className={classes.icon}>
      <span
        className={classNames(classes.processCode, className)}
        style={style}
      >
        {currentProcess.code}
      </span>
    </div>
  );
};

export default GeneratedProcessIcon;
