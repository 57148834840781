import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface CompaniesServiceData extends ServiceData {}

const { add, clear, addSync, get, reset } =
  entityServiceFactory<CompaniesServiceData>(db, 'companiesService');

export { add, clear, addSync, get, reset };
