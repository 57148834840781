import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { LOCALE_LANGUAGES } from 'shared/types/locale';
import { frontendVersionWithBuildNumber } from 'shared/version';
import { apiUrl } from './apiUrl';

export const setServiceWorkerConfig = ({
  locale,
  token,
  projectId,
  timezone,
}: any): void => {
  const data = {
    frontendVersion: frontendVersionWithBuildNumber,
    api: apiUrl,
    locale: LOCALE_LANGUAGES[locale],
    token,
    projectId,
    timezone,
  };

  const isEmpty = Object.values(data).find((elem) => !elem);
  // doesn't have full dataset, nothing to send
  if (isEmpty) {
    return;
  }

  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    data,
    service: Services.CONFIG,
    method: ServiceMethods.SET,
    source: 'serviceWorkerClient',
  });

  broadcast.close();
};
