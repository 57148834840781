import { DateRangeObject } from 'shared/types/analytics';
import { DayMomentValidation } from 'shared/types/time';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { FieldTypes } from 'shared/domain/issueForm/types/fieldTypes';
import { ApexSeriesAndLabels } from 'shared/types/analytics';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  HashMap,
  LabelledEntity,
  UntranslatedLabelledEntity,
} from 'shared/types/commonView';
import { isoToProjectTimezonedDate } from 'shared/utils/date/dates';

const noneId = 'issue_field_placeholder_empty';
type Issue = IssueEntity;

export function increaseEntitySeries(
  seriesObject: HashMap<number>,
  labelsObject: HashMap<LabelledEntity | UntranslatedLabelledEntity>,
  value: LabelledEntity
): void {
  const { _id, label } = value;

  if (seriesObject[_id]) {
    seriesObject[_id] += 1;
  } else {
    seriesObject[_id] = 1;
    labelsObject[_id] = {
      _id,
      label,
    };
  }
}

export function increaseNoneSeries(
  seriesObject: HashMap<number>,
  labelsObject: HashMap<UntranslatedLabelledEntity | LabelledEntity>
): void {
  if (seriesObject[noneId]) {
    seriesObject[noneId] += 1;
  } else {
    seriesObject[noneId] = 1;
    labelsObject[noneId] = {
      _id: noneId,
      labelId: noneId,
    };
  }
}

function canCheckLength(value: unknown): value is { length: number } {
  return Array.isArray(value) || typeof value === 'string';
}

function isLabelledEntity(value: unknown): value is LabelledEntity {
  // @ts-ignore duck typing
  return typeof value === 'object' && value && value._id && value.label;
}

export function filterByDateRange(
  dateRange: DateRangeObject,
  issueDetectionDate: string,
  timezone: string
): boolean {
  const detectionDateTime = isoToProjectTimezonedDate(
    issueDetectionDate,
    timezone
  ).valueOf();

  const fromTimeTimezoned = isoToProjectTimezonedDate(
    dateRange.from,
    timezone
  ).valueOf();
  const toTimeTimezoned = isoToProjectTimezonedDate(
    dateRange.to,
    timezone,
    DayMomentValidation.end
  ).valueOf();

  return (
    detectionDateTime >= fromTimeTimezoned &&
    detectionDateTime <= toTimeTimezoned
  );
}

function filterByMultiselect(
  field: string[],
  filterValue: string[],
  issue: Issue
): boolean {
  if (filterValue.length === 0) {
    return true;
  }

  const issueFieldValue: unknown = field.reduce(
    (fieldValue, currentFieldName) => {
      return fieldValue[currentFieldName];
    },
    issue as HashMap<any>
  );

  if (canCheckLength(issueFieldValue) && issueFieldValue.length === 0) {
    return false;
  }

  if (Array.isArray(issueFieldValue) && issueFieldValue.length !== 0) {
    return filterValue.some((item1) =>
      issueFieldValue.some((item2) => item1 === item2._id)
    );
  }

  if (
    typeof issueFieldValue === 'string' &&
    issueFieldValue.length !== 0
  ) {
    return filterValue.includes(issueFieldValue);
  }

  if (isLabelledEntity(issueFieldValue)) {
    return filterValue.some((value) => value === issueFieldValue._id);
  }

  return false;
}

type MultiselectFilter = (filterValue: string[], issue: Issue) => boolean;

export const filterBySubcontractor: MultiselectFilter = (
  filterValue,
  issue
) =>
  filterByMultiselect(
    [FieldTypes.primaryData, IssueFieldNames.subcontractors],
    filterValue,
    issue
  );
export const filterByProcess: MultiselectFilter = (filterValue, issue) =>
  filterByMultiselect(['process'], filterValue, issue);

export const filterBySite: MultiselectFilter = (filterValue, issue) =>
  filterByMultiselect(
    [FieldTypes.primaryData, IssueFieldNames.site],
    filterValue,
    issue
  );

export const filterByWorktype: MultiselectFilter = (filterValue, issue) =>
  filterByMultiselect(
    [FieldTypes.extendedData, IssueFieldNames.workTypes],
    filterValue,
    issue
  );

export const filterByAssignee: MultiselectFilter = (filterValue, issue) =>
  filterByMultiselect(
    [FieldTypes.primaryData, IssueFieldNames.assignee],
    filterValue,
    issue
  );

export const filterByImpact: MultiselectFilter = (filterValue, issue) =>
  filterByMultiselect(
    [FieldTypes.extendedData, IssueFieldNames.impact],
    filterValue,
    issue
  );

export function toSortedApexSeries(
  series: HashMap<number>,
  labels: HashMap<LabelledEntity | UntranslatedLabelledEntity>
): ApexSeriesAndLabels {
  const sortedSeries = Object.entries(series).sort(
    (entryA: [string, number], entryB: [string, number]) => {
      return entryB[1] - entryA[1];
    }
  );

  return sortedSeries.reduce(
    (result: [number[], string[]], sortedEntry: [string, number]) => {
      result[0].push(sortedEntry[1]);
      result[1].push(
        // @ts-ignore label is of type string | { labelId } so we know when to translate. Don't know how to properly type it yet.
        labels[sortedEntry[0]].label || {
          // @ts-ignore label is of type string | { labelId } so we know when to translate. Don't know how to properly type it yet.
          labelId: labels[sortedEntry[0]].labelId,
        }
      );
      return result;
    },
    [[], []]
  );
}
