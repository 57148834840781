import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useCreateStore, Store } from 'hooks/createStore';
import { useLevels } from 'components/dataProviders/withLevels';
import { findDocumentationOnLevelsAndSites } from 'shared/domain/documentation/findDocumentationOnLevels';
import { DocumentationBaseIds } from 'shared/types/documentation';
import { projectIdSelector } from 'helpers/misc';
import { useSelector } from 'react-redux';

type File = DocumentationBaseIds & { loading: boolean };
type IssueOverlayControlsContextType = {
  issuesVisibleStore: Store<boolean>;
  setFile: (file: File | undefined) => void;
  toggleVisible: () => void;
  toggleFiltersVisible: () => void;
  disabled: boolean;
  filtersDisabled: boolean;
  filtersDisabledReason: string;
  filtersVisibleStore: Store<boolean>;
};
const IssueOverlayControlsContext = createContext<
  IssueOverlayControlsContextType | undefined
>(undefined);

const WithIssueOverlayControls: FC<
  PropsWithChildren<{
    issuesVisibleStore: Store<boolean>;
    filtersVisibleStore: Store<boolean>;
  }>
> = ({ children, issuesVisibleStore, filtersVisibleStore }) => {
  const projectId = useSelector(projectIdSelector);
  const disabledRef = useRef(true);
  const filtersDisabledRef = useRef(true);
  const [file, setFile] = useState<File | undefined>(undefined);

  const { loading: loadingLevels, levels } = useLevels();
  const documentationId = file?.documentationId;
  const versionId = file?.versionId;

  const { levelsValue } = useMemo(
    () =>
      findDocumentationOnLevelsAndSites(
        levels.items,
        [],
        documentationId,
        versionId
      ),
    [levels.items, documentationId, versionId]
  );
  const isUsedAsLevel = levelsValue.length > 0;

  const disabled = useMemo(() => {
    const isDisabled =
      !file || !isUsedAsLevel || loadingLevels || file?.loading;
    disabledRef.current = isDisabled;
    return isDisabled;
  }, [isUsedAsLevel, loadingLevels, file]);

  const [filtersDisabled, setFiltersDisabled] = useState(
    disabled || !issuesVisibleStore.get()
  );

  useEffect(() => {
    const action = (): void => {
      setFiltersDisabled(disabledRef.current || !issuesVisibleStore.get());
    };
    action();
    return issuesVisibleStore.subscribe(action);
  }, [issuesVisibleStore]);
  filtersDisabledRef.current = filtersDisabled;
  const filtersDisabledReason = disabled
    ? 'documentation_file_not_used_as_map'
    : 'documentation_filtering_unavailable';

  const toggleVisible = useCallback(() => {
    if (disabledRef.current) {
      return;
    }
    const next = !issuesVisibleStore.get();
    localStorage.setItem(
      `documentation_issues_visible_${projectId}`,
      next ? 'true' : ''
    );
    issuesVisibleStore.set(next);
  }, [issuesVisibleStore, projectId]);

  const toggleFiltersVisible = useCallback(() => {
    if (disabledRef.current || filtersDisabledRef.current) {
      return;
    }
    filtersVisibleStore.set(!filtersVisibleStore.get());
  }, [filtersVisibleStore]);

  useEffect(() => {
    const visibilityStateOnFileChange =
      normalizeSavedStateFromLocalStorage(
        getDocumentationIssuesSavedState(projectId)
      );
    issuesVisibleStore.set(false);
    filtersVisibleStore.set(false);
    // Timeout prevents filcker of areas & pins overlay;
    setTimeout(() => {
      issuesVisibleStore.set(visibilityStateOnFileChange);
    }, 250);
  }, [file, issuesVisibleStore, filtersVisibleStore, projectId]);

  const ctx: IssueOverlayControlsContextType = useMemo(() => {
    return {
      issuesVisibleStore,
      filtersVisibleStore,
      setFile,
      toggleVisible,
      toggleFiltersVisible,
      disabled,
      filtersDisabled,
      filtersDisabledReason,
    };
  }, [
    issuesVisibleStore,
    toggleVisible,
    toggleFiltersVisible,
    disabled,
    filtersDisabled,
    filtersDisabledReason,
    filtersVisibleStore,
  ]);

  return (
    <IssueOverlayControlsContext.Provider value={ctx}>
      {children}
    </IssueOverlayControlsContext.Provider>
  );
};

function useIssueOverlayControls(): IssueOverlayControlsContextType {
  const context = useContext(IssueOverlayControlsContext);
  if (context === undefined) {
    throw new Error(
      'useIssueOverlayControls must be used within an IssueOverlayControlsContextProvider'
    );
  }
  return context;
}

export { WithIssueOverlayControls, useIssueOverlayControls };

function normalizeSavedStateFromLocalStorage(
  value: string | null
): boolean {
  return value === null || Boolean(value);
}
function getDocumentationIssuesSavedState(
  projectId: string
): string | null {
  return localStorage.getItem(`documentation_issues_visible_${projectId}`);
}

export function useCreateIssuesVisibleStore(): Store<boolean> {
  const projectId = useSelector(projectIdSelector);
  const savedValue = normalizeSavedStateFromLocalStorage(
    getDocumentationIssuesSavedState(projectId)
  );
  return useCreateStore(savedValue);
}
