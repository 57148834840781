import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { UserInDto } from 'shared/dtos/in/user';
import React, { useMemo, memo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { FieldsSettings } from 'views/fieldsSettings/list';
import { FieldSettings } from 'views/fieldsSettings/single';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';

export const AuthorizableFieldsSettings = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAdmin = useMemo(() => {
      return isAdmin(currentUser.role);
    }, [currentUser]);

    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAdmin}
        unauthorized={!isCurrentUserAdmin}
        authorizedComponent={memo(FieldsSettings)}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableFieldSettings = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAdmin = useMemo(() => {
      return isAdmin(currentUser.role);
    }, [currentUser]);

    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAdmin}
        unauthorized={!isCurrentUserAdmin}
        authorizedComponent={FieldSettings}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
