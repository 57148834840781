import { ProtocolOutDtoWithChecklistId } from 'components/dataProviders/withInspection/types';
import {
  // TODO - https://hustro.atlassian.net/browse/PT-3433
  // currently I - @Szlupka am not aware how to remove this dependency, but it should not be here.
  // I tried to remove this dependency but everythig I made just made things worse in readability
  COMPLIANCE_CHECK_RESULT_PREFIX,
  COMPLIANCE_CHECK_FORM_PREFIX,
} from 'components/inspection/ComplianceCheck';
import {
  ComplianceCheckModel,
  ComplianceCheckResult,
} from 'shared/domain/inspection/inspectionModel';
import { ComplianceCheckOutDto } from 'shared/dtos/out/complianceCheck';
import { DocumentsOnInspectionView } from 'presentation/document/documentsToDocumentsOnInspectionView';
import { InspectionOnView } from 'presentation/inspection/inspectionOnView';
import { HashMap, LabelledEntity } from 'shared/types/commonView';

export type InspectionFlatForm = {
  inspectionDate?: string;
  workTypes?: LabelledEntity[];
  site?: LabelledEntity;
  levels?: LabelledEntity[];
  controlledParty?: LabelledEntity[];
  contracts?: LabelledEntity[];
  comment?: string;
  [key: string]: any;
};

export function toProtocolItems(
  form: InspectionFlatForm
): ProtocolOutDtoWithChecklistId[] {
  const keys = Object.keys(form);

  const processedIds: string[] = [];
  const result = keys.reduce((protocolItems, currentKey) => {
    if (currentKey.includes(COMPLIANCE_CHECK_RESULT_PREFIX)) {
      // to extract ID the current key must have a prefix of COMPLIANCE_CHECK_RESULT_PREFIX therefore it MUST have the id
      const id = currentKey.split('-').pop()!;
      if (!processedIds.includes(id)) {
        const protocolItem = {
          checklistId: id,
          complianceCheck: toComplianceCheckOutDto(
            form,
            id,
            currentKey,
            keys
          ),
        };
        processedIds.push(id);
        protocolItems.push(protocolItem);
      }
    }
    return protocolItems;
  }, [] as ProtocolOutDtoWithChecklistId[]);
  return result;
}

function complianceCheckWithComment(
  form: InspectionFlatForm,
  id: string,
  key: string,
  keys: string[]
): {
  result: ComplianceCheckResult;
  comment: string;
} {
  const commentKey = keys.find((key: string) => {
    return key.includes(`comment-${id}`);
  });
  return {
    result: form[key],
    comment: commentKey ? form[commentKey] : '',
  };
}

function complianceUnset(): { result: ComplianceCheckResult.unset } {
  return {
    result: ComplianceCheckResult.unset,
  };
}

function complianceNotPassed(): {
  result: ComplianceCheckResult.notPassed;
} {
  return {
    result: ComplianceCheckResult.notPassed,
  };
}

const complianceCheckOutDtos = {
  [ComplianceCheckResult.unset]: complianceUnset,
  [ComplianceCheckResult.passed]: complianceCheckWithComment,
  [ComplianceCheckResult.notApplicable]: complianceCheckWithComment,
  [ComplianceCheckResult.notPassed]: complianceNotPassed,
};

function toComplianceCheckOutDto(
  form: InspectionFlatForm,
  id: string,
  key: string,
  keys: string[]
): ComplianceCheckOutDto {
  const complianceResult: ComplianceCheckResult = form[key];
  return complianceCheckOutDtos[complianceResult](form, id, key, keys);
}

export function inspectionToFlatForm(
  inspection: InspectionOnView | undefined,
  inspectionDocuments: DocumentsOnInspectionView
): InspectionFlatForm {
  if (!inspection) {
    return {} as InspectionFlatForm;
  }

  const protocols: HashMap<any> = inspection.protocol.reduce(
    (protocolsObject, protocol) => {
      Object.keys(protocol.complianceCheck).forEach((key): void => {
        const protocolFormKey = `${COMPLIANCE_CHECK_FORM_PREFIX}-${key}-${protocol.templateChecklistItem._id}`;
        protocolsObject[protocolFormKey] =
          protocol.complianceCheck[key as keyof ComplianceCheckModel];
      });
      const protocolDocumentsFormKey = `${COMPLIANCE_CHECK_FORM_PREFIX}-documents-${protocol.templateChecklistItem._id}`;
      protocolsObject[protocolDocumentsFormKey] =
        inspectionDocuments[protocol._id] || [];

      return protocolsObject;
    },
    {} as HashMap<any>
  );

  return {
    comment: inspection.comment,
    contracts: inspection.contracts,
    controlledParty: inspection.controlledParty,
    inspectionDate: inspection.inspectionDate.toISO(),
    levels: inspection.levels,
    site: inspection.site,
    workTypes: inspection.workTypes,
    ...protocols,
  };
}

export const SUBMIT_EVENT_TYPE = 'submit-inspection';
