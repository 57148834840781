import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { useSite, withSite } from 'components/dataProviders/withSite';
import { SingleSite } from 'components/site/single';
import { useGetSite } from '../../../hooks/useGetSite';
import { Spinner } from 'components/core';
import { useLevels } from 'components/dataProviders/withLevels';
import { MemoCreateLevelWizardDialog } from 'views/level/wizard/create/dialog';
import { MemoEditLevelWizardDialog } from 'views/level/wizard/edit/dialog';
import {
  useCreateLevel,
  withCreateLevel,
} from 'components/dataCreationForms/level/withCreateLevel';
import {
  useEditLevel,
  withEditLevel,
} from 'components/dataCreationForms/level/withEditLevel';

const SiteView = (): React.ReactElement | null => {
  const { id } = useParams<{ id: string }>();
  const { site, loading } = useSite();
  const { deleteLevel, restoreLevel, canDeleteLevel } = useLevels();
  useGetSite(id, true);
  const { openDialog: openCreateLevelDialog } = useCreateLevel();
  const { openDialog: openEditLevelDialog } = useEditLevel();

  if (loading) {
    return <Spinner reason='SiteView loading' />;
  }

  if (!site) {
    return null;
  }

  return (
    <>
      <SingleSite
        {...site}
        openCreateLevelDialog={openCreateLevelDialog}
        openEditLevelDialog={openEditLevelDialog}
        deleteLevel={deleteLevel}
        canDeleteLevel={canDeleteLevel}
        restoreLevel={restoreLevel}
      />
      <MemoCreateLevelWizardDialog />
      <MemoEditLevelWizardDialog />
    </>
  );
};

export const MemoSiteView = memo(
  withFetcherReady(withSite(withCreateLevel(withEditLevel(SiteView))))
);
