// This MUST be in ORDER from lowest to greatest Stage
export enum StageInternalNames {
  draft = 'DRAFT',
  consultation = 'CONSULTATION',
  execution = 'EXECUTION',
  resolved = 'RESOLVED',
}

export const STAGES_INDEXED = Object.freeze(
  Object.values(StageInternalNames)
);
