import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    doubleHeaderNoOverflow: {
      overflow: 'hidden',
      '& .BaseTable__header-row:first-child': {
        borderBottom: 'none',
        alignItems: 'unset',
      },
    },
  })
);
