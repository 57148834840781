import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { makeSimpleBroadcastError } from '../factories';

export function broadcastCreatedOrganizationError(error: any): void {
  debugLog('broadcastCreatedOrganizationError', error);
  broadcastOrganizationError(DomainMessagesTypes.createdEntity, error);
}
const broadcastOrganizationError = makeSimpleBroadcastError(
  ChannelNames.organizationChannel
);

export const broadcastUploadedOrganizationError = (error: any): void => {
  debugLog('broadcastUploadedOrganizationError', error);
  broadcastOrganizationError(
    DomainMessagesTypes.failUploadedEntity,
    error
  );
};
