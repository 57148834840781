import {
  ErrorPresentation,
  notEmptyArrayValidation,
  requiredValidation,
  stringMaxValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';
import { ValidationSchema } from '../types';
import { onViewValidation } from '../validation';

const validation: ValidationSchema<CompanyOnView> = {
  ...onViewValidation,
  shortLabel: shortLabelValidation,
  longLabel: longLabelValidation,
  taxNumber: taxNumberValidation,
  sites: sitesValidation,
  projectInvolvementType: projectInvolvementTypeValidation,
};

export function validate(
  key: keyof CompanyOnView,
  value: CompanyOnView[keyof CompanyOnView]
): ErrorPresentation {
  return validation[key](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: CompanyOnView
): boolean {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = validate(key as keyof CompanyOnView, value);
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}

function shortLabelValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.S, value)
  );
}

function longLabelValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.M, value)
  );
}

function taxNumberValidation(value: any): ErrorPresentation {
  return stringMaxValidation(FieldSize.S, value);
}

function sitesValidation(value: any): ErrorPresentation {
  return requiredValidation(value) || notEmptyArrayValidation(value);
}

function projectInvolvementTypeValidation(value: any): ErrorPresentation {
  return requiredValidation(value);
}
