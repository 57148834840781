import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  GetChartFiltersEventDetail,
  SetChartFiltersEventDetail,
} from './types';

const getOne = (data: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetChartFiltersEventDetail>(
      RepositoryMessagesTypes.getChartFilters,
      {
        detail: { _id: data._id },
      }
    )
  );
};

const set = (data: any): void => {
  self.dispatchEvent(
    new CustomEvent<SetChartFiltersEventDetail>(
      RepositoryMessagesTypes.setChartFilters,
      {
        detail: { _id: data._id, filters: data.filters },
      }
    )
  );
};

export default { getOne, set };
