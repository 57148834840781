import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { ISuspender, SuspendUntil } from './types';

export class Suspender implements ISuspender {
  private suspended: boolean = false;
  constructor(
    suspendOnMessageTypes: (
      | DomainMessagesTypes
      | RepositoryMessagesTypes
    )[],
    suspendUntil: SuspendUntil[],
    suspendedOnCreation?: true
  ) {
    if (suspendedOnCreation) {
      this.suspended = true;
    }

    suspendOnMessageTypes.forEach((message) => {
      self.addEventListener(message, () => {
        debugLog('START MESSAGE SUSPENDING', message);
        this.suspended = true;

        // // TEST WORKAROUND
        // setTimeout(() => {
        //   if (this.suspended) {
        //     debugLog('ENFORCING STOP MESSAGE SUSPENDING', message);
        //     this.suspended = false;
        //   }
        // }, 10000);
      });
    });

    suspendUntil.forEach(({ message, afterStopSuspension }): void => {
      self.addEventListener(message, () => {
        debugLog('STOP MESSAGE SUSPENDING', message);
        this.suspended = false;
        afterStopSuspension();
      });
    });
  }

  isSuspended(): boolean {
    return this.suspended;
  }
}
