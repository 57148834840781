import { ProjectInvolvementTypeCell } from 'components/table/renderers/cell/projectInvolvementType';
import { SORT_TYPE } from 'shared/filter/sort/types';
import { ColumnShapeSkeleton } from '../types';
import {
  CompanyModel,
  projectInvolvementTypeLabelIds,
} from 'shared/domain/company/types/model';

export function getProjectInvolvementTypeColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 192,
    flexGrow: 0,
    flexShrink: 0,
    sortable: true,
    resizable: true,
    sortType: SORT_TYPE.STRING,
    cellRenderer: ProjectInvolvementTypeCell,
    accessor: (entity: CompanyModel): string =>
      projectInvolvementTypeLabelIds[entity.projectInvolvementType],
    labelId: 'company_involvement_type_field_label',
    dataKey: 'projectInvolvementType',
    style: {
      justifyContent: 'flex-start',
    },
  };
}
