import { FileServicesUnion } from 'shared/domain/messages/httpQueue/eventMessages';
import { useCreateStore, Store } from 'hooks/createStore';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

export enum Progress {
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
  FAILED = 'FAILED',
}
export type UploadProgresStatus =
  | Progress.PENDING
  | Progress.UPLOADED
  | Progress.FAILED;

export type UploadProgresFile = {
  uniqueFileId: string;
  status: UploadProgresStatus;
  localId?: number;
  type: FileServicesUnion;
  title: string;
};
type UploadProgressStoreContextType = {
  filesStore: Store<UploadProgresFile[]>;
};

const UploadProgressStoreContext = createContext<
  UploadProgressStoreContextType | undefined
>(undefined);

export const WithUploadProgressStore: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const filesStore = useCreateStore<UploadProgresFile[]>([]);

  const ctx: UploadProgressStoreContextType = useMemo(() => {
    return { filesStore };
  }, [filesStore]);
  return (
    <UploadProgressStoreContext.Provider value={ctx}>
      {children}
    </UploadProgressStoreContext.Provider>
  );
};

export function useUploadProgressStore(): UploadProgressStoreContextType {
  const context = useContext(UploadProgressStoreContext);
  if (context === undefined) {
    throw new Error(
      'useUploadProgressStore must be used within an UploadProgressStoreContextProvider'
    );
  }
  return context;
}
