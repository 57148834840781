import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { DirectoryInDto } from 'shared/dtos/in/directory';
import { PostDirectoryOutDto } from 'shared/dtos/out/directory';
import { broadcastUploadedDirectory } from 'serviceWorker/broadcasts/directories';
import { addRemoteIdToDirectory } from './addRemoteIdToDirectory';
import { setDirectorySync } from './setDirectorySync';

export interface IDirectoryUploader {
  POST: (url: string, data: PostDirectoryOutDto) => Promise<any>;
  GET: (url: string) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  PUT: (url: string, data: any) => Promise<any>;
}

export interface UploadDirectory {
  (
    uploader: IDirectoryUploader,
    directory: DirectoryModel,
    url: string,
    uniqueId?: string
  ): Promise<boolean>;
}

export async function uploadDirectory(
  uploader: IDirectoryUploader,
  directory: DirectoryModel,
  url: string,
  uniqueId?: string
): Promise<any> {
  try {
    const createdDirectory = await createDirectory(
      uploader,
      directory,
      url
    );
    await addRemoteIdToDirectory(directory.localId, createdDirectory._id);
    broadcastUploadedDirectory(directory.localId, uniqueId);
  } catch (e) {
    await setDirectorySync(directory.localId, SyncStatus.FAIL);
    throw e;
  }
}

function createDirectory(
  uploader: IDirectoryUploader,
  directory: DirectoryModel,
  url: string
): Promise<DirectoryInDto> {
  const parentIdObj = directory.parentId
    ? { parentId: directory.parentId }
    : {};

  return uploader.POST(url, {
    name: directory.name,
    ...parentIdObj,
  });
}
