import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { UserModel } from 'shared/domain/user/userModel';
import { userEntityToModel } from 'serviceWorker/repository/user/mappings';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { getCurrentUsers } from 'serviceWorker/services/users/getUsers';
import { debugLog } from 'shared/logger/debugLog';
import * as dbUsers from '../../db/users';
import * as dbUsersService from '../../db/usersService';
import { makeBroadcastAll } from '../factories';
import { Broadcaster } from '../types';

export const broadcastAllUsers: Broadcaster = makeBroadcastAll<
  UserModel[]
>(
  dbUsersService,
  {
    get: async () => {
      const users = await dbUsers.get();
      return users.map((user) => userEntityToModel(user, users));
    },
  },
  ChannelNames.userChannel,
  DomainMessagesTypes.allUsers
);

export async function getAndBroadcastAllUsers(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const users = await getCurrentUsers();
  const end = Date.now();
  debugLog('getAllCurrentUsers time:', end - start);
  const currentUsers = users.map((user) => userEntityToModel(user, users));
  broadcast.postMessage({
    data: { items: currentUsers },
    type: DomainMessagesTypes.allUsers,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.USERS,
    method: ServiceMethods.PULL,
  });

  apiBroadcast.close();
}
