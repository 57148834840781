import listenToDocumentUpdatedMessage from 'shared/domain/document/listenToDocumentUpdatedMessage';
import startDocumentEdit from 'shared/domain/document/startDocumentEdit';
import { Message } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export interface AddRemoteIdToDocument {
  (localId: number, remoteId: string): Promise<number>;
}

export const addRemoteIdToDocument: AddRemoteIdToDocument =
  function _addRemoteIdToDocument(
    localId: number,
    remoteId: string
  ): Promise<number> {
    return new Promise((resolve, reject): void => {
      const broadcast = listenToDocumentUpdatedMessage(
        (success: any): void => {
          broadcast.close();
          debugLog('addRemoteIdToDocument success', success);
          resolve(success);
        },
        (error: any): void => {
          broadcast.close();
          debugLog('addRemoteIdToDocument error', error);
          reject(error);
        },
        (event: Message): boolean => {
          return event.data === localId;
        }
      );

      startDocumentEdit({ localId, _id: remoteId });
    });
  };
