import React from 'react';
import { Chip } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Cancel } from '@mui/icons-material';
import { LabelledEntity } from 'shared/types/commonView';

const styles = {
  chip: {
    marginRight: '0.5rem',
    marginBottom: '0.5rem',
    zIndex: 1,
    position: 'relative' as 'relative',
    maxWidth: '90%',
  },
};

type ChipListProps = WithStyles<typeof styles> & {
  items: LabelledEntity[];
  fieldName: string;
  onItemDelete?: (
    event: any,
    fieldName: string,
    element: LabelledEntity
  ) => void;
  editable?: boolean;
};

const ChipList = ({
  items,
  onItemDelete,
  editable = true,
  fieldName,
  classes,
}: ChipListProps): JSX.Element | null => {
  if (!items) {
    return null;
  }

  const handleDelete = (e: any, element: LabelledEntity): void => {
    e.stopPropagation();
    onItemDelete && onItemDelete(e, fieldName, element);
  };

  return (
    <>
      {items.map((element, ndx) => (
        <Chip
          key={ndx}
          size='small'
          label={element.label}
          className={classes.chip}
          {...(editable && {
            onDelete: (e): void => handleDelete(e, element),
            deleteIcon: (
              <Cancel
                data-qa={`chip-${fieldName}-delete-button-${element._id}`}
              />
            ),
          })}
        />
      ))}
    </>
  );
};

export default withStyles(styles)(ChipList);
