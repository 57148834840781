import React, { ReactElement } from 'react';
import { BaseTableProps, ColumnShape } from 'react-base-table';
import { CompanyWithFinances } from 'shared/domain/company/types/financialData';
import { CompanyModel } from 'shared/domain/company/types/model';

function createColumnGroup(
  message: string,
  cell: ReactElement,
  column: ColumnShape<CompanyModel>,
  columns: ColumnShape<CompanyModel>[]
): ReactElement {
  const totalWidth = columns
    .filter(
      (col) =>
        col.mergeGroup && col.mergeGroup._id === column.mergeGroup._id
    )
    .map((col) => col.width ?? 0)
    .reduce((prev, curr) => prev + curr);

  return React.cloneElement(
    cell,
    {
      style: {
        ...cell.props.style,
        pointerEvents: 'none',
        paddingLeft: '16px',
        width: totalWidth,
      },
    },
    <span>{message}</span>
  );
}

function lockCellAndClearText(cell: ReactElement): ReactElement {
  return React.cloneElement(
    cell,
    { style: { ...cell.props.style, pointerEvents: 'none' } },
    null
  );
}

function spanCellTo2Rows(cell: ReactElement): ReactElement {
  return React.cloneElement(cell, {
    style: {
      ...cell.props.style,
      height: '100px',
    },
  });
}

function createFirstRowCells(
  columns: ColumnShape<CompanyModel>[],
  cells: React.ReactNode[]
): ReactElement[] {
  const groups: string[] = [];

  return columns.reduce<ReactElement[]>(
    (
      result: ReactElement[],
      col,
      columnIndex,
      columnsArray
    ): ReactElement[] => {
      const currentCell = cells[columnIndex] as ReactElement;
      if (!col.mergeGroup) {
        const newCell = spanCellTo2Rows(currentCell);
        result.push(newCell);
        return result;
      }

      if (groups.includes(col.mergeGroup._id)) {
        return result;
      }

      groups.push(col.mergeGroup._id);
      const newCell = createColumnGroup(
        col.mergeGroup.label,
        currentCell,
        col,
        columnsArray
      );
      result.push(newCell);
      return result;
    },
    []
  );
}

function createSecondRowCells(
  columns: ColumnShape<CompanyModel>[],
  cells: React.ReactNode[]
): ReactElement[] {
  return columns.map((col, columnIndex) => {
    const columnCell = cells[columnIndex] as ReactElement;

    if (col.mergeGroup) {
      return columnCell;
    } else {
      return lockCellAndClearText(columnCell);
    }
  });
}

export const headerRenderer: BaseTableProps<
  CompanyModel | CompanyWithFinances
>['headerRenderer'] = ({ headerIndex, cells, columns }) => {
  const columnsAsList = columns as unknown as ColumnShape<
    CompanyModel | CompanyWithFinances
  >[]; //wrong TS declaration for Base Table - columns are in fact a list

  if (headerIndex === 0) {
    return createFirstRowCells(columnsAsList, cells);
  } else {
    return createSecondRowCells(columnsAsList, cells);
  }
};
