import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { updateOne as updateDocumentation } from 'serviceWorker/db/documentations';

export interface SetDocumentationSync {
  (localId: number, syncStatus: SyncStatus): Promise<number>;
}

export const setDocumentationSync: SetDocumentationSync =
  async function _setDocumentationSync(localId, syncStatus) {
    return updateDocumentation(localId, { syncStatus });
  };
