import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useWorktypeChannelListener } from 'components/broadcastChannelListeners/channelListener/worktypeChannelListener';
import { WorktypeModel } from 'shared/domain/worktype/worktypeModel';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allWorktypes
  );
}

export const useGetAllWorktypes = makeUseGetAll<WorktypeModel>({
  useChannelListener: useWorktypeChannelListener,
  postMessageChannel: ChannelNames.worktypeChannel,
  postMessageType: DomainMessagesTypes.getAllWorktypes,
  isMatchingMessage,
  entityName: 'worktypes',
});
