import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import listenToIssueCreateMessage from 'shared/domain/issue/listenToIssueCreateMessage';
import { HashMap } from 'shared/types/commonView';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { debugLog } from 'shared/logger/debugLog';
import { noop } from 'shared/utils/function';

type Unsubscribe = () => void;
type IssueCreateSuccessAction = (issueModel: IssueModel) => void;
type IssueCreateErrorAction = (error: any) => void;
type IssueCreateAction = {
  successAction: IssueCreateSuccessAction;
  errorAction: IssueCreateErrorAction;
};

type IssueCreateMessageContext = {
  subscribe: (
    action?: IssueCreateSuccessAction,
    errorAction?: IssueCreateErrorAction
  ) => Unsubscribe;
};
const IssueCreateMessage = React.createContext<
  IssueCreateMessageContext | undefined
>(undefined);

// react 18 types
// @ts-ignore
const WithIssueCreateMessage: React.FC = ({ children }) => {
  const uniqueKeyRef = useRef(1);
  const subscriptions = useRef<HashMap<IssueCreateAction>>({});
  const [lastCreated, setLastCreated] = useState<IssueModel>();
  const [error, setError] = useState<any | undefined>();

  useEffect((): (() => void) => {
    const setParsed = (issueModel: IssueModel): void => {
      setLastCreated(issueModel);
    };
    const broadcast = listenToIssueCreateMessage(setParsed, setError);
    return (): void => {
      broadcast.close();
    };
  }, []);

  useEffect(() => {
    if (!lastCreated) {
      return;
    }
    debugLog('lastCreated changed', subscriptions);
    Object.values(subscriptions.current).forEach(({ successAction }) => {
      successAction(lastCreated);
    });
  }, [lastCreated]);

  useEffect(() => {
    if (!error) {
      return;
    }
    Object.values(subscriptions.current).forEach(({ errorAction }) => {
      errorAction(error);
    });
  }, [error]);

  const subscribe = useCallback(
    (
      action: IssueCreateSuccessAction = noop,
      errorAction: IssueCreateErrorAction = noop
    ): Unsubscribe => {
      const key = uniqueKeyRef.current;
      subscriptions.current[key] = {
        successAction: action,
        errorAction: errorAction,
      };
      uniqueKeyRef.current += 1;

      return (): void => {
        delete subscriptions.current[key];
      };
    },
    []
  );

  const ctx = {
    subscribe,
  };

  return (
    <IssueCreateMessage.Provider value={ctx}>
      {children}
    </IssueCreateMessage.Provider>
  );
};

const withIssueCreateMessage =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithIssueCreateMessage>
      <Component {...props} />
    </WithIssueCreateMessage>
  );

function useIssueCreateMessage(): IssueCreateMessageContext {
  const context = React.useContext(IssueCreateMessage);
  if (context === undefined) {
    throw new Error(
      'useIssueCreateMessage must be used within a IssueCreateMessageContextProvider'
    );
  }
  return context;
}

export {
  WithIssueCreateMessage,
  withIssueCreateMessage,
  useIssueCreateMessage,
};
