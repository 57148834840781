import { BroadcastChannel } from 'broadcast-channel';
import { EditFieldValueModel } from 'shared/domain/fieldValue/fieldValueModel';
import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import { FieldValueTranslationModel } from 'shared/domain/fieldValueTranslation/fieldValueTranslationModel';
import { Message } from 'shared/domain/messages/message';
import {
  EditFieldValueOnView,
  FieldValueOnView,
} from 'presentation/fieldValue/fieldValueOnView';
import { Dispatch } from 'redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';

function registerListener({
  listener,
  resolve,
  reject,
  setIsPosting,
  dispatch,
  uniqueId,
}: {
  listener: (
    successCallback: CallableFunction,
    errorCallback: CallableFunction,
    messageFilter: (event: Message) => boolean
  ) => BroadcastChannel;
  resolve: (value: unknown) => void;
  reject: (value: unknown) => void;
  setIsPosting: (bool: boolean) => void;
  dispatch: Dispatch;
  uniqueId: string;
}): void {
  let timeout: ReturnType<typeof setTimeout>;
  const broadcast = listener(
    (message: Message) => {
      broadcast.close();
      resolve(message);
      setIsPosting(false);
      clearTimeout(timeout);
    },
    (error: Error) => {
      broadcast.close();
      reject(error);
      setIsPosting(false);
      displayGenericErrorToaster(dispatch);
      clearTimeout(timeout);
    },
    (message: Message) => {
      return message.uniqueId === uniqueId;
    }
  );
  timeout = setTimeout(() => {
    broadcast.close();
    reject(new Error('WithFieldValuesForm: Timeout.'));
    setIsPosting(false);
    displayGenericErrorToaster(dispatch);
  }, 15000);
}

type Fields = { uniqueId: string; data: any[] };
type CreateFieldsPromiseProps = {
  fields: Fields;
  fieldName: EditableFieldValuesEntityName;
  listener: (
    successCallback: CallableFunction,
    errorCallback: CallableFunction,
    messageFilter: (event: Message) => boolean
  ) => BroadcastChannel;
  setIsPosting: (bool: boolean) => void;
  dispatch: Dispatch;
  emitFields: (
    fieldName: EditableFieldValuesEntityName,
    fieldValues: any[],
    uniqueId: string
  ) => void;
};
export function createFieldsPromise({
  fields,
  fieldName,
  listener,
  setIsPosting,
  dispatch,
  emitFields,
}: CreateFieldsPromiseProps): Promise<any> {
  return fields.data.length
    ? new Promise((resolve, reject) => {
        registerListener({
          listener,
          resolve,
          reject,
          setIsPosting,
          dispatch,
          uniqueId: fields.uniqueId,
        });
        emitFields(fieldName, fields.data, fields.uniqueId);
      })
    : Promise.resolve();
}

export function parseChanges(
  allItems: FieldValueOnView[],
  fieldChanges: EditFieldValueOnView[]
): EditFieldValueModel[] {
  return fieldChanges.map((fieldChange) => {
    if (!fieldChange.valueTranslations) {
      return {
        processes: fieldChange.processes,
        _id: fieldChange._id,
      };
    }

    const originalField = allItems.find((item) => {
      return item._id === fieldChange._id;
    })!;

    const newTranslations = fieldChange.valueTranslations.map(
      (possibleChange) => {
        const originalTranslation = originalField.valueTranslations.find(
          (translation) => {
            return possibleChange.localeCode === translation.localeCode;
          }
        );

        if (!originalTranslation) {
          return possibleChange;
        } else if (
          originalTranslation &&
          originalTranslation.value !== possibleChange.value
        ) {
          return possibleChange;
        }

        return undefined;
      }
    );

    return {
      valueTranslations: newTranslations.filter(
        (x) => x
      ) as FieldValueTranslationModel[],
      processes: fieldChange.processes,
      _id: fieldChange._id,
    };
  });
}
