import { BroadcastChannel } from 'broadcast-channel';
import { isCreatedMessage, Message } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';

function listenToReportCreatedMessage(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean
): BroadcastChannel {
  const broadcast = new BroadcastChannel(ChannelNames.reportChannel);

  broadcast.onmessage = (event: Message): void => {
    if (!isCreatedMessage(event) || !messageFilter(event)) {
      return;
    }

    if (event.error) {
      return errorCallback(event.error);
    }

    if (event.data) {
      return successCallback(event.data);
    }
  };

  return broadcast;
}

export default listenToReportCreatedMessage;
