import { ReactElement, useContext, useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ClientContext } from 'with-client';
import { isProjectChangeMessage } from './model';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { Message } from 'shared/domain/messages/message';

type WithProjectChangeSubscriberProps = { children: ReactElement };

export function WithProjectChangeSubscriber({
  children,
}: WithProjectChangeSubscriberProps): ReactElement {
  const client = useContext(ClientContext);
  const { _id: projectId } = useSelector(projectDataSelector);

  useEffect(() => {
    const broadcast = new BroadcastChannel(
      ChannelNames.selectedProjectChannel
    );

    broadcast.onmessage = (event: Message): void => {
      if (!isProjectChangeMessage(event)) {
        return;
      }

      if (event.data.project && event.data.project._id === projectId) {
        return;
      }

      if (!event.data.project && projectId) {
        client.dispatchSelectedProjectInStore(event.data.project);
        return;
      }

      client.dispatchSelectedProjectInStore(event.data.project);
    };

    return (): void => {
      broadcast.close();
    };
  }, [client, projectId]);

  return children;
}
