import { OpenCreateDialogParams } from 'components/dataCreationForms/types';
import { useSites } from 'components/dataProviders/withSites';
import { getCreatedMessageListener } from 'shared/domain/commonModel';
import {
  companyOnViewToCompanyCreateModel,
  createEmptyCompany,
} from 'shared/domain/company/mapping';
import { startCompanyCreate } from 'shared/domain/company/startCompanyCreate';
import { CompanyModel } from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { projectIdSelector, toLabelledEntities } from 'helpers/misc';
import { useGetAllCompanies } from 'hooks/useGetAllCompanies';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { currentUserSelector } from 'redux/selectors/users';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { nowTimezonedDateTime } from 'shared/utils/date/dates';
import { createUniqueId } from 'shared/utils/id/id';
import { CreateCompanyContext } from '../types';
import { handleFormValidation, validate } from '../validation';
const formName = 'company';
const SUBMIT_EVENT_TYPE = 'submit-company';
const WithCreateCompanyContext = createContext<
  CreateCompanyContext | undefined
>(undefined);

function WithCreateCompany({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const history = useHistory();
  const intl = useIntl();
  const { getAll: getAllCompanies } = useGetAllCompanies();
  const fetchCompanies = getAllCompanies;
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const availableSites = useMemo(
    () => toLabelledEntities(sites.filter(keepUndeleted)),
    [sites]
  );
  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const { data: currentUser } = useSelector(currentUserSelector);
  const { timezone } = useSelector(projectDataSelector);
  const projectId = useSelector(projectIdSelector);

  const emptyCompany = createEmptyCompany(
    intl,
    nowTimezonedDateTime(timezone),
    currentUser
  );
  const [initialValues, setInitialValues] =
    useState<CompanyOnView>(emptyCompany);

  const [open, setOpen] = useState(false);
  const openDialog = useCallback(
    ({ freeSoloLabel }: OpenCreateDialogParams) => {
      setOpen(true);
      initialValues &&
        freeSoloLabel &&
        setInitialValues({ ...initialValues, shortLabel: freeSoloLabel });
    },
    [initialValues]
  );
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );

  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: CompanyOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const companyCreateModel = companyOnViewToCompanyCreateModel(
        values,
        projectId
      );

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getCreatedMessageListener(
          (message: CompanyModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchCompanies();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.companyChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateCompany: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startCompanyCreate(companyCreateModel, uniqueId);
      })
        .then((company: unknown): any => {
          if (company && (company as Identificable)._id) {
            const redirectAfterCreation =
              window.location.href.indexOf('create-company') > 0;
            redirectAfterCreation
              ? history.push(`/company/${(company as Identificable)._id}`)
              : setOpen(false);
            // setInitialValues(emptyCompany);
            return company;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchCompanies, history, projectId]
  );

  const ctx: CreateCompanyContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate,
      handleFormValidation,
      availableSites,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    availableSites,
    onSuccess,
  ]);

  return (
    <WithCreateCompanyContext.Provider value={ctx}>
      {children}
    </WithCreateCompanyContext.Provider>
  );
}

function useCreateCompany(): CreateCompanyContext {
  const context = useContext(WithCreateCompanyContext);
  if (context === undefined) {
    throw new Error(
      'useCreateCompany must be used within an CreateCompanyContext'
    );
  }
  return context;
}

const withCreateCompany =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateCompany>
      <Component {...props} />
    </WithCreateCompany>
  );

export { WithCreateCompany, useCreateCompany, withCreateCompany };
