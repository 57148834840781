import React, { ReactElement } from 'react';
import { useStyles } from './styles';
import { Divider, Grid } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import { MemoPreviewField } from 'components/general/PreviewField';
import { MemoProtocolPreview } from '../ProtocolPreview';
import { MemoInspectionStatus } from '../Status';
import { InspectionStatusSize } from '../Status/types';
import { createInspectionTemplateGeneralNormDocumentsBaseUrl } from 'views/template/wizard/communicator/helpers';
import { toLabel } from './helpers';
import classNames from 'classnames';
import { InspectionPdfButton } from 'components/reports/inspection/InspectionPdfButton';
import ViewWrapper from '../../core/Layout/ViewWrapper';
import useIsMobile from 'hooks/useIsMobile';
import { GeneralNormPreview } from '../GeneralNormPreview';
import { ProtocolHeader } from '../ProtocolHeader';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { ButtonTextIcon } from 'components/general';
import { Permission } from 'helpers/permission';
import { DocumentOnView } from 'presentation/document/documentOnView';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { InspectionOnView } from 'presentation/inspection/inspectionOnView';
import { DocumentsOnInspectionView } from 'presentation/document/documentsToDocumentsOnInspectionView';
import { MemoProcessIcon } from 'components/common/ProcessIcon';
import { projectIdSelector } from 'helpers/misc';
import { useSelector } from 'react-redux';

type SingleInspectionPresentationalProps = {
  inspection: InspectionOnView;
  generalNormDocuments: DocumentOnView[];
  inspectionDocuments: DocumentsOnInspectionView;
  edit: () => void;
  editPermission: Permission;
};
function SingleInspectionPresentational(
  props: SingleInspectionPresentationalProps
): ReactElement {
  const projectId = useSelector(projectIdSelector);
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const intl = useIntl();
  const {
    inspection,
    edit,
    editPermission,
    generalNormDocuments,
    inspectionDocuments,
  } = props;
  const GU = useGraphicUploader(
    generalNormDocuments,
    {
      baseUrl: createInspectionTemplateGeneralNormDocumentsBaseUrl(
        inspection.template._id
      ),
      previewOnly: true,
      internalEntityBaseUrl: (): string =>
        `/project/${projectId}/inspection/${inspection._id}/template/${inspection.template._id}`,
      // TODO: PT-3769 should we have binder on preview?
    },
    true
  );

  const statusComponent = React.useMemo(() => {
    return (
      <MemoInspectionStatus
        status={inspection.status}
        size={InspectionStatusSize.default}
      />
    );
  }, [inspection]);
  const levels = React.useMemo(() => {
    return inspection.levels.map(toLabel);
  }, [inspection]);
  const controlledParties = React.useMemo(() => {
    return inspection.controlledParty.map(toLabel);
  }, [inspection]);
  const contracts = React.useMemo(() => {
    return inspection.contracts.map(toLabel);
  }, [inspection]);
  const workTypes = React.useMemo(() => {
    return inspection.workTypes.map(toLabel);
  }, [inspection]);

  const renderableProcess = React.useMemo(() => {
    return {
      color: inspection.renderableProcess.color,
      code: inspection.renderableProcess.code,
    };
  }, [inspection]);

  const protocolIssues = React.useMemo(() => {
    return inspection.protocol.reduce<number>(
      (count: number, protocolItem) =>
        count + protocolItem.complianceCheck.issues.length,
      0
    );
  }, [inspection]);

  const editDisabledReason = editPermission.reason;
  const isEditButtonDisabled = !editPermission.permission;

  //TODO https://hustro.atlassian.net/browse/PT-2401
  return (
    <ViewWrapper>
      <div className={classes.singleContainer}>
        <div className={classes.header}>
          <MemoProcessIcon
            color={renderableProcess.color}
            code={renderableProcess.code}
          />
          <span>{inspection.code}</span>
        </div>
        <Divider />
        <div className={classes.summaryWithActions}>
          <h1>{inspection.template.summary}</h1>
          <div
            className={classNames(
              classes.wrappedContent,
              classes.actionsContainer
            )}
          >
            <div>
              <MemoForbiddenTooltip
                visibleWhen={isEditButtonDisabled}
                reason={editDisabledReason}
                inlined
              >
                <ButtonTextIcon
                  disabled={isEditButtonDisabled}
                  startIcon={EditOutlined}
                  onClick={edit}
                >
                  {intl.formatMessage({
                    id: 'inspection_template_action_edit',
                  })}
                </ButtonTextIcon>
              </MemoForbiddenTooltip>
            </div>
            <div>
              <InspectionPdfButton />
            </div>
          </div>
        </div>
        <Divider sx={{ mb: 2 }} />
        {/* <div className={classes.propertiesContainer}> */}
        <Grid container spacing={2}>
          <Grid item xs={8} sm={4} md={3}>
            <MemoPreviewField
              isHalf
              label={intl.formatMessage({
                id: 'inspection_execution_date',
              })}
              value={inspection.inspectionDate}
              dense={true}
            />
          </Grid>
          <Grid item xs={8} sm={4} md={3}>
            <MemoPreviewField
              isHalf
              label={intl.formatMessage({
                id: 'inspection_status',
              })}
              component={statusComponent}
              dense={true}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} sm={3}>
            <MemoPreviewField
              isHalf
              label={intl.formatMessage({
                id: 'primaryField_Site',
              })}
              value={inspection.site.label}
              dense={true}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <MemoPreviewField
              isHalf
              label={intl.formatMessage({
                id: 'primaryField_Level',
              })}
              value={levels}
              dense={true}
            />
          </Grid>
          <Grid item xs={8} sm={4}>
            <MemoPreviewField
              isHalf
              label={intl.formatMessage({
                id: 'inspection_controlled_party',
              })}
              value={controlledParties}
              dense={true}
            />
          </Grid>
          <Grid item xs={8} sm={4} md={3}>
            <MemoPreviewField
              isHalf
              label={intl.formatMessage({
                id: 'inspection_contract_number',
              })}
              value={contracts}
              dense={true}
            />
          </Grid>
          <Grid item xs={8} sm={4} md={3}>
            <MemoPreviewField
              label={intl.formatMessage({
                id: 'extendedField_Work_type',
              })}
              value={workTypes}
              dense={true}
            />
          </Grid>
          <Grid item xs={12}>
            <MemoPreviewField
              label={intl.formatMessage({
                id: 'inspection_comment',
              })}
              value={inspection.comment || ''}
              dense={true}
            />
          </Grid>
          <Grid item xs={8} sm={4} md={3}>
            <MemoPreviewField
              label={intl.formatMessage({
                id: 'inspection_preview_created_by',
              })}
              value={
                inspection.createdBy?.label ||
                intl.formatMessage({
                  id: 'general_user_deleted',
                })
              }
              dense={true}
            />
          </Grid>
        </Grid>

        <Divider sx={{ my: 2 }} />
        <GeneralNormPreview
          generalNormDescription={
            inspection.template.generalNorm.description
          }
          GU={GU}
        />
        <Divider />
        <div className={classes.protocolContainer}>
          <ProtocolHeader protocolIssues={protocolIssues} />

          <div style={{ marginBottom: '85px' }}>
            <MemoProtocolPreview
              protocol={inspection.protocol}
              renderableProcess={renderableProcess}
            />
          </div>
        </div>
      </div>
    </ViewWrapper>
  );
}

export const MemoSingleInspectionPresentational = React.memo(
  SingleInspectionPresentational
);
