import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      background: '#F2F2F2',
      height: '100%',
      gap: '100px',
    },
    projectBox: {
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'column',
      justifyContent: 'center',
      gap: '15px',
      position: 'absolute',
      transform: 'translate(0,-100%)',
      top: '-100px',
    },
    projectLabel: {
      fontFamily: 'Archivo',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '0em',
      textAlign: 'center',
    },
    projectName: {
      fontFamily: 'Archivo',
      fontSize: '1.5rem',
      fontWeight: 400,
      lineHeight: '26px',
      letterSpacing: '0em',
      textAlign: 'center',
    },
    loadingBox: {
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '33px',
      width: '100%',
      position: 'relative',
    },
    loadingLabel: {
      fontFamily: 'Archivo',
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0em',
      textAlign: 'center',
    },
    progressBox: {
      width: 'min(90%, 340px)',
      textAlign: 'center',
    },
  })
);
