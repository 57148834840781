import {
  ErrorPresentation,
  requiredValidation,
  customStringMinMaxValidation,
} from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { HashMap } from 'shared/types/commonView';
import { SiteOnView } from 'shared/domain/site/types/view';
import { ValidationSchema } from '../types';
import { noValidation, onViewValidation } from '../validation';
import { FieldSize } from 'shared/types/fieldSize';

const validation: ValidationSchema<SiteOnView> = {
  ...onViewValidation,
  levels: noValidation,
  label: labelFieldValidation,
  code: codeFieldValidation,
};

export function validate(
  key: keyof SiteOnView,
  value: SiteOnView[keyof SiteOnView]
): ErrorPresentation {
  return validation[key](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: SiteOnView
): boolean {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = validate(key as keyof SiteOnView, value);
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}

function labelFieldValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) ||
    customStringMinMaxValidation(1, FieldSize.S, value)
  );
}

function codeFieldValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) ||
    customStringMinMaxValidation(1, FieldSize.CODE, value)
  );
}
