import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isClientAdmin } from 'shared/domain/role/isClientAdmin';
import { UserInDto } from 'shared/dtos/in/user';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoEditOrganizationWizardContainer } from 'views/organization/wizard/edit/container';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';
import { isSuperAdmin } from 'shared/domain/role/isSuperAdmin';

export const AuthorizableEditOrganizationWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserClientAdmin = useMemo(() => {
      return (
        isClientAdmin(currentUser.role) || isSuperAdmin(currentUser.role)
      );
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserClientAdmin}
        unauthorized={!isCurrentUserClientAdmin}
        authorizedComponent={MemoEditOrganizationWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
