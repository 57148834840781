import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  ServiceMethods,
} from 'shared/domain/messages/message';
import { ISuspender } from 'serviceWorker/api/apiSuspender/types';
import { debugLog } from 'shared/logger/debugLog';
import analytics from './analytics';
import chartFilters from './chartFilters';
import companies from './companies';
import config from './config';
import contracts from './contracts';
import correctiveActionTypes from './correctiveActionTypes';
import directories from './directories';
import documentations from './documentations';
import documents from './documents';
import drawings from './drawings';
import environmentalAspects from './environmentalAspects';
import fieldValues from './fieldValues';
import hazardCategories from './hazardCategories';
import httpQueue from './httpQueue';
import inspectionTemplates from './inspectionTemplates';
import inspections from './inspections';
import issueForms from './issueForms';
import issues from './issues';
import language from './language';
import levels from './levels';
import organizations from './organizations';
import permissions from './permissions';
import projects from './projects';
import reports from './reports';
import selectedProject from './selectedProject';
import sites from './sites';
import { HandlerProps } from './types';
import users from './users';
import visibleFields from './visibleFields';
import worktypes from './worktypes';

const messages: {
  [key: string]: any;
} = {
  config,
  issues,
  companies,
  contracts,
  permissions,
  users,
  language,
  analytics,
  chartFilters,
  projects,
  organizations,
  selectedProject,
  inspectionTemplates,
  inspections,
  documents,
  drawings,
  sites,
  levels,
  reports,
  worktypes,
  hazardCategories,
  fieldValues,
  environmentalAspects,
  correctiveActionTypes,
  documentations,
  directories,
  httpQueue,
  issueForms,
  visibleFields,
};

export const init = (suspender: ISuspender): void => {
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

  const handler = async (handlerProps: HandlerProps): Promise<void> => {
    const { service, method, data } = handlerProps;
    if (!messages[service] || !messages[service][method]) {
      //@ts-ignore
      if (handlerProps.type === DomainMessagesTypes.serviceWorkerCheck) {
        return;
      }
      debugLog('unknown service: ', service, ' or method: ', method);
      debugLog('props provided: ', handlerProps);
      return;
    }
    if (
      suspender.isSuspended() &&
      (method === ServiceMethods.SYNC || method === ServiceMethods.PULL)
    ) {
      debugLog(
        'suspending service: ',
        service,
        'with method: ',
        method,
        'with data: ',
        data
      );
      return;
    }
    debugLog(
      'executing service: ',
      service,
      'with method: ',
      method,
      'with data: ',
      data,
      'all props: ',
      handlerProps
    );
    messages[service][method](data);
  };

  broadcast.onmessage = handler;
};
