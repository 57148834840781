export function isMobileDevice(agent = ''): boolean {
  const mobileDevices = [
    'iPhone',
    'webOS',
    'Android',
    'iPad',
    'iPod',
    'BlackBerry',
    'Windows Phone',
  ];
  const query = new RegExp(mobileDevices.join('|'), 'i');
  return !!agent.match(query);
}
