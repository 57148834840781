import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { AdditionalSwLogInfo, LogLevel } from 'shared/types/logger';
import { HustroDbTablesUnion } from './index';

type LogWithRethrowParams = {
  msg: string;
  errorObj: any;
  logLevel?: LogLevel;
  // it's mandatory, so we don't forget to put additional info to logs
  additionalInfo: AdditionalSwLogInfo | null;
};
export function logWithRethrow(params: LogWithRethrowParams): never {
  const level = params.logLevel || LogLevel.ERROR;
  const err = params.errorObj || new Error(params.msg);
  swLog(params.msg, level, err, params.additionalInfo);
  throw err;
}

export enum Operation {
  add = 'add',
  addBatch = 'addBatch',
  updateOne = 'updateOne',
  updateDrawing = 'updateDrawing',
  updateBatch = 'updateBatch',
  removeBatch = 'removeBatch',
  upsertBatch = 'upsertBatch',
  get = 'get',
  getOne = 'getOne',
  getByIds = 'getByIds',
  getByQuery = 'getByQuery',
  clear = 'clear',
  count = 'count',
  filter = 'filter',
  put = 'put',
  set = 'set',
  first = 'first',
  remove = 'remove',
}

export function getErrorMsg(
  operation: Operation,
  dbEntity: HustroDbTablesUnion
): string {
  return `DB: Problem occurred during operation: ${operation} ${dbEntity}`;
}

export function getErrorTransactionMsg(
  operations: Operation[],
  dbEntities: HustroDbTablesUnion[]
): string {
  return `DB: Problem occurred during transaction: ${operations.join(
    ', '
  )}; on tables ${dbEntities.join(', ')};`;
}

export const invalidSyncKeyErrorMsg = (
  entity: HustroDbTablesUnion
): string => `Invalid sync key on GET ${entity}`;
