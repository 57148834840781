import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { isStandard } from 'shared/domain/role/isStandard';
import { isViewer } from 'shared/domain/role/isViewer';
import { UserInDto } from 'shared/dtos/in/user';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoUser } from 'views/users/single';
import { MemoUsers } from 'views/users/table';
import { MemoCreateUserWizardContainer } from 'views/users/wizard/create/container';
import { MemoEditUserWizardContainer } from 'views/users/wizard/edit/container';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';
export const AuthorizableUsers = React.memo((): React.ReactElement => {
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);
  const isCurrentUserStandardOrViewer = useMemo(() => {
    return isStandard(currentUser.role) || isViewer(currentUser.role);
  }, [currentUser]);
  return (
    <MemoGenericRoleAuthorizer
      authorized={isManagerOrAdmin(currentUser.role)}
      unauthorized={isCurrentUserStandardOrViewer}
      authorizedComponent={MemoUsers}
      unauthorizedComponent={InsufficientPermissionsPage}
    />
  );
});

export const AuthorizableUser = React.memo((): React.ReactElement => {
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);

  const isCurrentUserStandardOrViewer = useMemo(() => {
    return isStandard(currentUser.role) || isViewer(currentUser.role);
  }, [currentUser]);
  return (
    <MemoGenericRoleAuthorizer
      authorized={isManagerOrAdmin(currentUser.role)}
      unauthorized={isCurrentUserStandardOrViewer}
      authorizedComponent={MemoUser}
      unauthorizedComponent={InsufficientPermissionsPage}
    />
  );
});

export const AuthorizableCreateUserWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserAdmin = useMemo(() => {
      return isAdmin(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAdmin}
        unauthorized={!isCurrentUserAdmin}
        authorizedComponent={MemoCreateUserWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableEditUserWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserAdmin = useMemo(() => {
      return isAdmin(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAdmin}
        unauthorized={!isCurrentUserAdmin}
        authorizedComponent={MemoEditUserWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
