import React from 'react';
import { useIntl } from 'react-intl';
import { TOASTER_TYPES } from 'shared/enums';
import { ToasterMessageProp } from '../../../setup/types/core';
import { WithStyles } from '@mui/styles';

type SnackBarStyles = {
  root: string;
  message: string;
};

export const MessageToMarkdown = (
  message: ToasterMessageProp | string,
  classes: WithStyles<any>['classes']
): JSX.Element | null => {
  const intl = useIntl();

  if (!message) {
    return null;
  }

  if (typeof message === 'string') {
    return (
      <span className={classes.message}>
        <span>{message}</span>
      </span>
    );
  }

  const formattedMessage = intl.formatMessage(
    { id: message.id },
    {
      ...message.messageParams,
      content: message.content ? `<>${message.content}<>` : null,
    }
  );

  const [partA, partStrong, partB] = formattedMessage.split('<>');

  return (
    <span className={classes.message}>
      {partA}
      <span className={classes.messageStrong}>{partStrong}</span>
      {partB}
    </span>
  );
};
export const getSnackBarStyles = (
  classes: WithStyles<any>['classes'],
  type?: number
): SnackBarStyles => ({
  message: classes.message,
  root:
    type === TOASTER_TYPES.FAILURE
      ? classes.snackbarError
      : classes.snackbar,
});
