import { DocumentModel } from 'shared/domain/document/documentModel';
import { addToHashMap } from 'helpers/mappings';
import { modifiedAtSort } from 'helpers/sort';
import { HashMap } from 'shared/types/commonView';
import { documentModelToDocumentOnView } from './documentModelToDocumentOnView';
import { DocumentOnView } from './documentOnView';
import { isDocumentRemoteAndUnavailable } from './documentRemoteAndUnavailable';
import { DocumentsOnTemplateView } from './documentsOnTemplateView';

export function documentsToDocumentsOnTemplateView(
  documents: DocumentModel[]
): DocumentsOnTemplateView {
  const templateDocuments: DocumentOnView[] = [];
  const checklistDocuments: HashMap<DocumentOnView[]> = {};

  documents.sort(modifiedAtSort).forEach((document) => {
    if (document.deleted) {
      return;
    }

    if (isDocumentRemoteAndUnavailable(document)) {
      return;
    }

    const documentOnTemplateView = documentModelToDocumentOnView(document);
    if (!documentOnTemplateView) {
      return;
    }
    if (document.checklistId) {
      addToHashMap(
        checklistDocuments,
        documentOnTemplateView,
        document.checklistId
      );
    } else {
      templateDocuments.push(documentOnTemplateView);
    }
  });

  return {
    templateDocuments,
    checklistDocuments,
  };
}
