import { useRef, useCallback, useEffect, useMemo } from 'react';

import {
  GalleryContextType,
  GalleryState,
  HideActions,
} from 'components/common/gallery';
import {
  DisplayedFilesStore,
  FileSetForDisplay,
} from 'components/common/gallery/filesStore';
import {
  useStoreGalleryState,
  makeGalleryStatesStore,
  GalleryStateStore,
} from 'components/common/gallery/galleryStateStore';
import {
  useStoreMainDisplayedFile,
  makeMainFileStore,
} from 'components/common/gallery/mainDisplayedFile';
import { useDisplayedFilesContext } from 'components/dataProviders/withDisplayedFiles';
import { DocumentationBaseIds } from 'shared/types/documentation';
import { DocumentationEditDialog } from 'views/documentations/edit/dialog';
import { useDocumentationForm } from 'components/dataCreationForms/withDocumentationForm';
import { startDocumentationDelete } from 'shared/domain/documentation/startDocumentationDelete';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { documentationDeleteDialog } from '../documentationDeleteDialog';
import { DocumentationMapOverlay } from '../DocumentationMapOverlay';
import {
  DocumentationIssuesActions,
  DisabledDocumentationIssuesActions,
} from './DocumentationGalleryActions';
import { DocumentationMiniaturesOverlay } from '../DocumentationMiniaturesOverlay';
import { useDocumentationProgressComponent } from '../DocumentationProgress/useDocumentationProgressComponent';
import { useCreateStore } from 'hooks/createStore';
import { useIssueOverlayControls } from './WithIssueOverlayControls';
import { useDocumentationProgressActivityStore } from '../DocumentationProgress/useDocumentationProgressActivityStore';
import { DocumentationProgressControls } from '../DocumentationProgress/DocumentationProgressControls';
import { ProgressModel } from '../DocumentationProgress/types';

const drawings: any[] = [];
const addDrawing = (...args: any[]): void => {};
export function useDocumentationGallery(): GalleryContextType<DocumentationBaseIds> & {
  filesStore: DisplayedFilesStore<DocumentationBaseIds>;
  galleryStateStore: GalleryStateStore;
} {
  const createDialog = useDialog();
  const { openDialog } = useDocumentationForm();
  const hideActions: HideActions = useMemo(() => ['REMOVE', 'DRAW'], []);
  const { filesStore, downloadFile, useDisplayedFiles } =
    useDisplayedFilesContext<DocumentationBaseIds>();
  const wasOpenRef = useRef(false);
  const mainDisplayedFileStore =
    useStoreMainDisplayedFile<DocumentationBaseIds>();
  const galleryStateStore = useStoreGalleryState();

  const useMainDisplayedFile = useMemo(
    () => makeMainFileStore(mainDisplayedFileStore),
    [mainDisplayedFileStore]
  );
  const useGalleryState = useMemo(
    () => makeGalleryStatesStore(galleryStateStore),
    [galleryStateStore]
  );

  const changeGallery = useCallback(
    (key: keyof GalleryState, value: GalleryState[keyof GalleryState]) => {
      const galleryState = galleryStateStore.get();
      if (galleryState[key] === value) {
        return;
      }
      galleryStateStore.set({
        ...galleryState,
        [key]: value,
      });
    },
    [galleryStateStore]
  );

  const setFiles = useCallback(
    (files: FileSetForDisplay<DocumentationBaseIds>[]) => {
      filesStore.set(
        files.map((file) => {
          return {
            ...file,
            loading: file.signedRequest ? false : true,
          };
        })
      );
    },
    [filesStore]
  );

  useSubscription(
    filesStore.subscribe,
    useCallback(() => {
      const files = filesStore.get();
      if (!files || !files.length || wasOpenRef.current) {
        return;
      }
      const index = files.findIndex((file) => {
        if (!file.versionId) return;
        return window.location.pathname.includes(file.versionId);
      });
      if (index > -1) {
        galleryStateStore.set({ open: true, slide: index });
      }
    }, [filesStore, galleryStateStore])
  );

  useSubscription(
    filesStore.subscribe,
    useCallback(() => {
      mainDisplayedFileStore.set(
        filesStore.get()[galleryStateStore.get()['slide']]
      );
    }, [mainDisplayedFileStore, filesStore, galleryStateStore])
  );

  useSubscription(
    galleryStateStore.subscribe,
    useCallback(() => {
      const galleryState = galleryStateStore.get();

      if (galleryState.open) {
        wasOpenRef.current = true;
      }

      const mainFile = filesStore.get()[galleryState.slide];
      mainDisplayedFileStore.set(mainFile);
    }, [galleryStateStore, filesStore, mainDisplayedFileStore])
  );

  useSubscription(
    mainDisplayedFileStore.subscribe,
    useCallback(() => {
      const file = mainDisplayedFileStore.get();
      if (!file) return;
      downloadFile(file);
    }, [mainDisplayedFileStore, downloadFile])
  );

  const removeFile = useCallback(() => {
    const file = mainDisplayedFileStore.get();

    createDialog(documentationDeleteDialog(1)).then(() => {
      if (!file || file.localId === undefined) return;
      startDocumentationDelete([file.localId], []);
    });
  }, [createDialog, mainDisplayedFileStore]);

  const additionalActions = useMemo(() => {
    return {
      PrefixedActionsComponent: DocumentationIssuesActions,
      SuffixedActionsComponent: DisabledDocumentationIssuesActions,
    };
  }, []);

  const rightPanelVisibleStore = useCreateStore(false);
  const progressModelsStore = useCreateStore<ProgressModel[]>([]);
  const { issuesVisibleStore } = useIssueOverlayControls();
  const activityStore =
    useDocumentationProgressActivityStore(issuesVisibleStore);
  const documentationProgressControls = useRef(
    new DocumentationProgressControls(
      rightPanelVisibleStore,
      activityStore,
      progressModelsStore
    )
  );
  const DocumentationProgressComponent = useDocumentationProgressComponent(
    rightPanelVisibleStore,
    activityStore,
    progressModelsStore
  );

  const ctx = useMemo(
    () => ({
      filesStore,
      setFiles,
      drawings,
      addDrawing,
      changeGallery,
      setGallery: galleryStateStore.set,
      removeFile,
      preview: false,
      useDisplayedFiles,
      useMainDisplayedFile,
      useGalleryState,
      downloadFile,
      openEditComponent: openDialog,
      EditComponent: DocumentationEditDialog,
      editTitleId: 'edit_documentation',
      hideActions: hideActions,
      ImageDisplayOverlay: DocumentationMapOverlay,
      additionalActions,
      MiniaturesOverlay: DocumentationMiniaturesOverlay,
      galleryStateStore,
      RightPanel: DocumentationProgressComponent,
      rightPanelControls: documentationProgressControls.current,
    }),
    [
      filesStore,
      galleryStateStore,
      changeGallery,
      removeFile,
      setFiles,
      useDisplayedFiles,
      useMainDisplayedFile,
      useGalleryState,
      downloadFile,
      hideActions,
      openDialog,
      additionalActions,
      DocumentationProgressComponent,
    ]
  );

  return ctx;
}

type SubscriptionCallback = () => void;
type Subscribe = (callback: SubscriptionCallback) => Unsubscribe;
type Unsubscribe = () => void;

function useSubscription(
  subscribe: Subscribe,
  callback: () => void
): void {
  useEffect(() => {
    const unsubscribe = subscribe(callback);
    return unsubscribe;
  }, [subscribe, callback]);
}
