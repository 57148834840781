import {
  useRef,
  useCallback,
  useMemo,
  useEffect,
  useState,
  MutableRefObject,
} from 'react';
import { FileType } from '../graphicUploader/types';
import { MainImage } from '../mainImage';

export type FileSetForDisplay<T = object> = FileType & T;
export type DisplayedFile<T = object> = FileType & {
  loading: boolean;
} & T;
export type DisplayedFilesStore<T = object> = {
  get: () => DisplayedFile<T>[];
  set: (value: (FileType & T)[]) => void;
  subscribe: (callback: () => void) => () => void;
};

export function useStoreFiles<T>(
  initialFiles?: DisplayedFile<T>[]
): DisplayedFilesStore<T> {
  const filesRef = useRef<DisplayedFile<T>[]>(initialFiles || []);
  const get = useCallback(() => {
    return filesRef.current;
  }, []);

  const subscribers = useRef(new Set<() => void>());

  const set = useCallback((value: (FileType & T)[]) => {
    const valueWithLoading: DisplayedFile<T>[] = value.map((file) => {
      if ((file as DisplayedFile<T>).loading === undefined) {
        (file as DisplayedFile<T>).loading = !file.signedRequest;
      }
      return file as DisplayedFile<T>;
    });
    filesRef.current = valueWithLoading;
    subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return useMemo(
    () => ({
      get,
      set,
      subscribe,
    }),
    [get, set, subscribe]
  );
}

function identity<T>(x: T): T {
  return x;
}

export function makeDisplayedFilesStore<K = object>(
  filesStore: DisplayedFilesStore<K>,
  sort: (files: DisplayedFile<K>[]) => DisplayedFile<K>[] = identity,
  mainImageHandlerRef?: MutableRefObject<MainImage> | undefined
) {
  return function displayFilesWithSelector<T>(
    selector: (store: DisplayedFile<K>[]) => T
  ): [T, (value: DisplayedFile<K>[]) => void] {
    const [state, setState] = useState(selector(sort(filesStore.get())));

    useEffect(() => {
      setState(selector(sort(filesStore.get())));
      return filesStore.subscribe(() =>
        setState(selector(sort(filesStore.get())))
      );
    }, [selector]);

    useEffect(() => {
      if (!mainImageHandlerRef) return;
      setState(selector(sort(filesStore.get().slice())));
      return mainImageHandlerRef.current
        .getMainImageStore()
        .subscribe(() =>
          setState(selector(sort(filesStore.get().slice())))
        );
    }, [selector]);

    return [state, filesStore.set];
  };
}
