import { DocumentEntity } from 'serviceWorker/repository/document/entity';

export function createUrlFromChecklistDocument(
  document: DocumentEntity
): string {
  return `/checklistItem/${document.checklistId}/norm`;
}

export function createUrlFromTemplateDocument(
  document: DocumentEntity
): string {
  const url = `/inspectionTemplate/${document.templateId}`;
  if (document.checklistId) {
    return url + createUrlFromChecklistDocument(document);
  }

  return url + '/generalNorm';
}
