import {
  HttpDeleteRequestModel,
  HttpPostRequestModel,
  HttpPutRequestModel,
  __HttpRequestModel__,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { UserInDto } from 'shared/dtos/in/user';
import { UserEditOutDto, UserCreateOutDto } from 'shared/dtos/out/user';
import {
  IUserUploader,
  UploadUser,
  UploadUserChange,
  UploadUserDelete,
} from 'serviceWorker/repository/user/uploadUser';
import { IRequestRunner } from '../httpRequestRunner';
import {
  UserHttpCreateRequestModel,
  UserHttpEditRequestModel,
  UserHttpDeleteRequestModel,
} from './types';

export class UserRequestRunner implements IRequestRunner {
  constructor(
    private userUploader: IUserUploader,
    private uploadUserUseCase: UploadUser,
    private saveUser: (data: UserInDto) => Promise<unknown>,
    private uploadUserChangesUseCase: UploadUserChange,
    private uploadUserDeleteUseCase: UploadUserDelete,
    private deleteUser: (userId: string) => Promise<unknown>
  ) {}
  async execute(request: __HttpRequestModel__): Promise<unknown> {
    switch (request.method) {
      case 'POST': {
        return this.uploadUser(request as UserHttpCreateRequestModel);
      }
      case 'PUT': {
        return this.uploadUserChanges(request as UserHttpEditRequestModel);
      }
      case 'DELETE': {
        return this.uploadUserDelete(
          request as UserHttpDeleteRequestModel
        );
      }
    }
  }

  private async uploadUser(
    request: UserHttpCreateRequestModel
  ): Promise<UserInDto> {
    const userInDto = await this.uploadUserUseCase(
      this.userUploader,
      request.data.userCreateOutDto,
      request.url
    );
    const result = await this.saveUser(userInDto);
    return userInDto;
  }

  private async uploadUserChanges(
    request: UserHttpEditRequestModel
  ): Promise<unknown> {
    return this.uploadUserChangesUseCase(
      this.userUploader,
      request.data.userEditOutDto,
      request.url
    );
  }

  private async uploadUserDelete(
    request: UserHttpDeleteRequestModel
  ): Promise<boolean> {
    await this.uploadUserDeleteUseCase(this.userUploader, request.url);
    await this.deleteUser(request.data.userId);
    return true;
  }
}
