import { StandardHeader } from 'components/table/ColumnHeader';
import { LPRenderer } from 'components/table/renderers/cell/orderNumber';
import { ColumnShapeSkeleton } from '../types';

export function getOrderNumberColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    index: 0,
    initialWidth: 64,
    flexGrow: 0,
    flexShrink: 0,
    sortable: false,
    headerRenderer: StandardHeader,
    cellRenderer: LPRenderer,
    labelId: 'table_header_lp',
    style: {
      padding: '0 16px',
      justifyContent: 'center',
    },
  };
}
