import { BroadcastChannel } from 'broadcast-channel';
import { Message } from 'shared/domain/messages/message';
import { listenToDirectoryUpdatedMessage } from 'presentation/directory/listenToDirectoryUpdatedMessage';
import { listenToDirectoryUploadedMessage } from 'presentation/directory/listenToDirectoryUploadedMessage';
import { DirectoryInForm } from './types';

export function getFormResultListener(
  successCallback: () => void,
  uniqueId: string | undefined,
  onError: () => void,
  directory?: DirectoryInForm
): BroadcastChannel {
  if (!directory) {
    return listenToDirectoryUploadedMessage(
      successCallback,
      onError,
      (message: Message) => message.uniqueId === uniqueId
    );
  }

  return listenToDirectoryUpdatedMessage(
    successCallback,
    onError,
    (message: Message) => message.uniqueId === uniqueId
  );
}
