import { memo, ReactElement } from 'react';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { useParams } from 'react-router-dom';
import { MemoSingleContract } from 'components/contract/Single';
import {
  useContract,
  withContract,
} from 'components/dataProviders/withContract';
import { withContracts } from 'components/dataProviders/withContracts';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { useGetContract } from 'hooks/useGetContract';
import { Spinner } from 'components/core';

function SingleContractView(): ReactElement {
  const { id } = useParams<{ id: string }>();
  const { contract, loading } = useContract();

  useGetContract(id, true);

  if (loading || !contract) {
    return <Spinner reason='SingleContractView loading || !contract' />;
  }

  return <MemoSingleContract contract={contract} />;
}

export const MemoContract = memo(
  withFetcherReady(
    withProcessesData(
      withCompanies(withContracts(withContract(SingleContractView)))
    )
  )
);
