import { PromiseExtended } from 'dexie';
import { CompanyEntity } from 'shared/domain/company/types/entity';
import { CompanyInDto } from 'shared/dtos/in/company';
import { Changes } from 'shared/types/commonEntities';
import { companyInDtoToEntity } from 'serviceWorker/repository/company/mapping';
import { makeBroadcastClear } from 'serviceWorker/broadcasts/factories';
import { ChannelNames } from 'shared/domain/channelNames';
import { LogLevel } from 'shared/types/logger';
import { clear as companiesServiceClear } from './companiesService';
import {
  makeDefaultAddBatchMapped,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { entityDbClearFactory } from './entityDbClearFactory';
import { Operation, getErrorMsg, logWithRethrow } from './helpers';
import db from './index';
import { wrapQuery } from './queryWrapper';

export const clear = entityDbClearFactory(
  db,
  ['companies', 'companiesService'],
  () => db.companies.clear(),
  companiesServiceClear,
  makeBroadcastClear(ChannelNames.companyChannel)
);

export const quantity = makeDefaultCount<CompanyEntity>(db, db.companies);

export const get = makeDefaultGetMany<CompanyEntity>(db, db.companies, {
  deleted: 0,
});

export const getByIds = makeDefaultGetManyByIds<string, CompanyEntity>(
  db,
  db.companies,
  '_id'
);

export const getOne = makeDefaultGetOne<string, CompanyEntity>(
  db,
  db.companies,
  '_id'
);

export const addBatch = makeDefaultAddBatchMapped<
  CompanyInDto,
  CompanyEntity
>(db, db.companies, companyInDtoToEntity);

export const updateBatch = addBatch;

export const updateOne = wrapQuery(
  db,
  (
    companyId: string,
    changes: Changes<CompanyEntity>
  ): PromiseExtended => {
    return db.companies.update(companyId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'companies'),
        errorObj: e,
        additionalInfo: { query: { data: { companyId, changes } } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<string, CompanyEntity>(
  db,
  db.companies,
  '_id'
);
