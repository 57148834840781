import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { ConfigData } from 'serviceWorker/api/types';
import { ConfigData as ConfigRepositoryData } from 'serviceWorker/db/config';
import { ServiceData } from 'serviceWorker/db/service';
import { debugLog } from 'shared/logger/debugLog';
import { Pull } from './types';

export function makePullHandler(
  configRepository: {
    get: () => Promise<void | ConfigRepositoryData>;
  },
  entityRepository: { clear: () => Promise<void> },
  entityService: {
    get: () => Promise<undefined | ServiceData>;
    reset: () => Promise<void>;
    add: (data: Partial<ServiceData>) => Promise<void>;
  },
  resync: Pull,
  clearBroadcasts: () => void,

  customRequirements?: (keyof ConfigData)[],
  withoutSyncKey?: boolean
) {
  return async (): Promise<void> => {
    const setup = await configRepository.get();
    const status = await entityService.get();
    const required: (keyof ConfigData)[] = customRequirements || [
      'locale',
      'frontendVersion',
      'api',
      'projectId',
    ];
    const hasRequired =
      required.map((elem) => setup && setup[elem]).filter((e) => !e)
        .length === 0;
    const shouldReset = status?.shouldReset;

    const shouldPullWhenSyncKeyEnabled =
      hasRequired && !status?.isDownloading && !status?.isDownloaded;
    const shouldExecuteWhenSyncKeyEnabled =
      shouldPullWhenSyncKeyEnabled || shouldReset;

    const shouldExecuteWhenSyncKeyDisabled =
      hasRequired && !status?.isDownloading;

    withoutSyncKey &&
      debugLog(
        'Dispatching pull?, sync key is disabled',
        shouldExecuteWhenSyncKeyDisabled,
        hasRequired,
        status
      );

    if (!setup) {
      debugLog('could not dispatch pull, no setup data');
      return;
    }
    const shouldExecute = withoutSyncKey
      ? shouldExecuteWhenSyncKeyDisabled
      : shouldExecuteWhenSyncKeyEnabled;

    if (shouldExecute) {
      const abortController = new AbortController();
      const abort = (): void => {
        abortController.abort();
        clearBroadcasts();
      };
      self.addEventListener(DomainMessagesTypes.clearData, abort);
      self.addEventListener(
        RepositoryMessagesTypes.clearSelectedProjectData,
        abort
      );
      self.addEventListener(DomainMessagesTypes.logout, abort);
      await entityService.reset();
      const initialState = withoutSyncKey
        ? {
            total: undefined,
            isDownloading: true,
            isDownloaded: false,
          }
        : {
            total: undefined,
            isDownloading: true,
            isDownloaded: false,
            syncKey: undefined,
          };
      await entityService.add(initialState);
      await entityRepository.clear();
      const promise = resync(setup, abortController);
      if (!promise) {
        self.removeEventListener(DomainMessagesTypes.clearData, abort);
        self.removeEventListener(
          RepositoryMessagesTypes.clearSelectedProjectData,
          abort
        );
        self.removeEventListener(DomainMessagesTypes.logout, abort);
        return;
      }

      return promise.then((res) => {
        self.removeEventListener(DomainMessagesTypes.clearData, abort);
        self.removeEventListener(
          RepositoryMessagesTypes.clearSelectedProjectData,
          abort
        );
        self.removeEventListener(DomainMessagesTypes.logout, abort);
        return res;
      });
    }
  };
}
