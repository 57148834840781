import { useRef, useCallback, useMemo, useEffect, useState } from 'react';

export type GalleryState = {
  open: boolean;
  slide: number;
};
export type GalleryStateStore = {
  get: () => GalleryState;
  set: (value: GalleryState) => void;
  subscribe: (callback: () => void) => () => void;
};

export function useStoreGalleryState(): GalleryStateStore {
  const galleryStateRef = useRef<GalleryState>({
    open: false,
    slide: 0,
  });
  const get = useCallback(() => galleryStateRef.current, []);

  const subscribers = useRef(new Set<() => void>());

  const set = useCallback((value: GalleryState) => {
    galleryStateRef.current = value;
    subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return useMemo(
    () => ({
      get,
      set,
      subscribe,
    }),
    [get, set, subscribe]
  );
}

export function makeGalleryStatesStore(galleryStore: GalleryStateStore) {
  return function galleryStateWithSelector<T>(
    selector: (store: GalleryState) => T
  ): [T, (value: GalleryState) => void] {
    const [state, setState] = useState(selector(galleryStore.get()));

    useEffect(() => {
      return galleryStore.subscribe(() =>
        setState(selector(galleryStore.get()))
      );
    }, [selector]);

    return [state, galleryStore.set];
  };
}

export function slideSelector(galleryState: GalleryState): number {
  return galleryState.slide;
}

export function isOpenSelector(galleryState: GalleryState): boolean {
  return galleryState.open;
}
