import React, { ReactElement, useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { useStylesRadio } from './styles';
import { ChartFilter } from 'shared/types/analytics';

export function RadioControl(props: {
  filter: ChartFilter<unknown>;
  localSetter: (value: any) => void;
  localValue: string;
  disabled: boolean;
}): ReactElement {
  const intl = useIntl();
  const classes = useStylesRadio();
  const { available, labelId, labels } = props.filter;
  const localSetter = props.localSetter;
  const localValue = props.localValue;

  const handleChange = useCallback(
    (e, v) => {
      localSetter(v);
    },
    [localSetter]
  );

  return (
    <div className={classes.root}>
      <FormControl
        variant='standard'
        component='fieldset'
        disabled={props.disabled}
      >
        <FormLabel component='legend'>
          {intl.formatMessage({ id: labelId })}
        </FormLabel>
        <RadioGroup
          aria-label='chart-type'
          name='chart-type'
          value={localValue}
          onChange={handleChange}
        >
          {available?.map((availableValue: any) => (
            <FormControlLabel
              value={availableValue}
              control={<Radio color='primary' />}
              label={intl.formatMessage({
                id: labels?.[availableValue],
                defaultMessage: labels?.[availableValue],
              })}
              key={availableValue}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
}
