import {
  noPermission,
  noPermissionInOffline,
  Permission,
} from 'helpers/permission';
import { memo, ReactElement, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { toUserRole } from 'redux/selectors/role';
import { EDIT_COMPANY_PERMISSIONS } from 'setup/permissions';
import { useIsOffline } from '../../common/withIsOffline';
import { SingleContractPresentational } from './presentational';
import { SingleContractProps } from './types';

function SingleContract({ contract }: SingleContractProps): ReactElement {
  const userRole = useSelector(toUserRole);
  const isOffline = useIsOffline();
  const history = useHistory();

  const editPermission = useMemo((): Permission => {
    if (!EDIT_COMPANY_PERMISSIONS[userRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [userRole, isOffline]);

  const edit = useCallback(() => {
    history.push(`/contract/${contract._id}/edit`);
  }, [contract, history]);

  return (
    <SingleContractPresentational
      label={contract.label}
      parties={contract.parties}
      editDisabledReason={editPermission.reason}
      isEditButtonDisabled={!editPermission.permission}
      edit={edit}
    />
  );
}

export const MemoSingleContract = memo(SingleContract);
