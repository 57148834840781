import { StandardHeader } from 'components/table/ColumnHeader';
import { processCodeRenderer } from 'components/table/renderers/cell/processCode';
import { SORT_TYPE } from 'shared/filter/sort/types';
import { ColumnShapeSkeleton } from '../types';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';

export function getProcessColumnShapeSkeleton(
  processes: HashMap<ProcessType>
): ColumnShapeSkeleton {
  return {
    index: 1,
    initialWidth: 180,
    flexGrow: 0,
    flexShrink: 0,
    sortable: false,
    sortType: SORT_TYPE.STRING,
    headerRenderer: StandardHeader,
    labelId: 'general_process',
    cellRenderer: processCodeRenderer(processes),
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
