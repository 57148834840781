import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import {
  broadcastCreateReportError,
  broadcastReportCreated,
} from 'serviceWorker/broadcasts/reports';

export function broadcastReportResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastCreateReportError(request, response.error);
  }

  broadcastReportCreated(response, request.data.uniqueId);
}
