import { ServiceData } from 'serviceWorker/db/service';

export async function awaitEntities(
  service: { get: () => Promise<ServiceData> },
  entityAwaiter: () => Promise<void>
): Promise<void> {
  const serviceStatus = await service.get();
  if (!serviceStatus?.isDownloaded || serviceStatus?.isDownloading) {
    return entityAwaiter();
  }
}
