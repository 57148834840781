import { memo, ReactElement, useEffect } from 'react';
import { ChartContext } from '../types';
import { ChartFiltersUnion } from 'shared/types/analytics';

type ChartReloaderProps = {
  children: ReactElement;
  context: ChartContext<ChartFiltersUnion>;
};

function ChartReloader({
  context,
  children,
}: ChartReloaderProps): ReactElement {
  const reloadChartData = context && context.load;
  useEffect(() => {
    reloadChartData && reloadChartData();
  }, [reloadChartData]);

  return children;
}

export const MemoChartReloader = memo(ChartReloader);
