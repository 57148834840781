import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';

export const boxStyle = {
  width: '100%',
  backgroundColor: 'gray',
  height: '200px',
};

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      fullWidth: {
        width: '100%',
      },
      divider: {
        width: ({ isMobile }): string =>
          isMobile ? 'calc(100% + 32px)' : '100%',
        position: ({ isMobile }): 'relative' | 'unset' =>
          isMobile ? 'relative' : 'unset',
        left: ({ isMobile }): string => (isMobile ? '-16px' : 'unset'),
      },
      accordionIndex: { marginRight: '14px', marginTop: '4px' },
      contentBoxAccordion: {
        margin: ({ isMobile }): string => (isMobile ? '0' : '0 13px'),
      },
      dragFill: {
        fill: '#C4C4C4',
        marginTop: '1px',
      },
      standardBorderRadius: {
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '4px',
        },
      },
      flexColumn: {
        marginTop: '4px',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingTop: '8px',
      },
      hustroTemplateAccordion: {
        '&.MuiAccordion-root:before': { opacity: 0 },
        '&.MuiPaper-elevation1': {
          boxShadow: 'unset',
        },
        '&.MuiAccordion-root.Mui-expanded:before': { opacity: 0 },
        '& .MuiAccordionSummary-root': {
          padding: 0,
          alignItems: 'flex-start',
          '&.Mui-expanded': {
            minHeight: 0,
          },
        },
        '& .MuiAccordionDetails-root': {
          padding: 0,
        },
        '& .MuiAccordionSummary-content': {
          display: 'flex',
          justifyContent: 'space-between',
          '& .accordionTitle': {
            display: 'flex',
            flexDirection: 'row' as 'row',
            alignItems: 'flex-start',
            flexGrow: 1,
            '& .checklist-item-title': {
              margin: '4px 14px 0 14px',
              width: 0,
              flexGrow: 1,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              '& span': {
                whiteSpace: 'nowrap',
              },
            },
          },
          '&.Mui-expanded': {
            margin: '12px 0',
            '& .accordionTitle .checklist-item-title span': {
              whiteSpace: 'normal',
            },
          },
        },
      },
      checklistItemSummaryMargin: { marginBottom: '23px' },
      checklistItemDescriptionMargin: { marginBottom: '17px' },
      checkIconContainer: {
        border: '1px solid #C4C4C4',
        borderRadius: '50%',
        width: '24px',
        height: '24px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
      },
      checkIcon: {
        fill: '#C4C4C4',
        height: '16px',
      },
      deleteIconContainer: {
        display: 'flex',
        alignSelf: 'flex-end',
        marginTop: '8px',
        marginBottom: '21px',
      },
      checklistItemGraphicUploader: {
        marginTop: ({ isMobile }): string => (isMobile ? '0' : '40px'),
      },
    })
);
