import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { ProjectCreateModel } from './types/model';

export function startProjectCreate(
  data: ProjectCreateModel,
  uniqueId: string
): void {
  debugLog('startProjectCreate', data);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.PROJECTS,
    method: ServiceMethods.CREATE,
    data: { projectCreateModel: data, uniqueId },
  });
  broadcast.close();
}
