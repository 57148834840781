import { ReactElement } from 'react';
import { DocumentationOnView } from 'components/dataProviders/withDocumentationController/types';
import { DocumentationRowIcon } from '../DocumentationIcon';

export function DocumentationNameWithIconCell({
  cellData: name,
  rowData,
}: {
  cellData: string;
  rowData: DocumentationOnView;
}): ReactElement {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '0.75rem',
        textOverflow: 'ellipsis',
        flexShrink: 0,
        flexGrow: 0,
        flexWrap: 'nowrap',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        width: '100%',
      }}
    >
      <DocumentationRowIcon type={rowData.type} />
      <span
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {name}
      </span>
    </div>
  );
}
