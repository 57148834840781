import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      flexGrow: 1,
      marginBottom: '32px',
      marginTop: '24px',
    },
    iconText: {
      fontFamily: 'Archivo',
      fontSize: '1.5rem',
      fontWeight: 700,
      lineHeight: '26px',
    },
    helpers: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      flexDirection: 'column',
      '& span': {
        fontFamily: 'Archivo',
        fontSize: '1rem',
        fontWeight: 400,
        textAlign: 'center',
        color: '#757575',
      },
    },
  })
);
