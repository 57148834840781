import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface HazardCategoriesServiceData extends ServiceData {}
const { add, clear, addSync, get, reset } =
  entityServiceFactory<HazardCategoriesServiceData>(
    db,
    'hazardCategoriesService'
  );

export { add, clear, addSync, get, reset };
