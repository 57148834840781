import { ApexOptions } from 'apexcharts';
import { palette } from '../shared';
import { chartMenu } from '../shared/toolbar';
import { IntlShape } from 'react-intl';

const chartPalette = [
  palette.purple,
  palette.darkblue,
  palette.darkgreen,
  palette.green,
  palette.aquamarine,
  palette.yellow,
];

export function listViewHsEffectChartOptions(
  intl: IntlShape
): ApexOptions {
  return {
    series: [
      {
        data: [],
      },
    ],
    legend: {
      inverseOrder: true,
      offsetY: -8,
    },
    tooltip: {
      y: {
        title: {
          formatter: function (seriesName: string): string {
            return `${intl.formatMessage({ id: seriesName })}: `;
          },
        },
        formatter: function (value, { w }): string {
          const total = w.globals.seriesTotals[0];
          if (total === 0) {
            return '0 %';
          }
          return `${((value / total) * 100).toFixed()} %`;
        },
      },
    },
    yaxis: {
      max: (max: number): number => max + 1,
      forceNiceScale: true,
      labels: {
        show: false,
      },
    },
    colors: chartPalette,
    chart: {
      fontFamily: 'Archivo, Arial',
      type: 'bar',
      toolbar: { show: false },
    },
    plotOptions: {
      bar: {
        barHeight: '90%',
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    grid: {
      padding: {
        bottom: 8,
        left: -5,
      },
    },
    dataLabels: {
      style: {
        colors: ['#000000'],
        fontWeight: 400,
        fontSize: '1rem',
      },
      enabled: true,
      textAnchor: 'start',
      offsetX: 20,
    },
  };
}

export function detailedViewHsEffectChartOptions(
  intl: IntlShape
): ApexOptions {
  const options = listViewHsEffectChartOptions(intl);
  return {
    ...options,
    chart: {
      ...options.chart,
      toolbar: {
        show: true,
        tools: {
          download: `<img src="${chartMenu}" width="24">`,
        },
        export: {
          csv: {
            filename: 'effect',
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
          },
        },
      },
    },
  };
}
