import { MouseEventHandler, ReactElement } from 'react';
import { Close } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import cn from 'classnames';
import { ButtonIcon } from 'components/general';
import { Theme } from '@mui/material';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '2.5rem',
      height: '2.5rem',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main}C0`,
      },
      transform: 'rotate(-45deg)',
      transition: 'transform .1s',
      '&.active': {
        transform: 'rotate(360deg)',
      },
    },
    close: {
      width: '1rem',
      height: '1rem',
    },
  })
);

export function DocumentationProgressActivationButton({
  onClick,
  active,
}: {
  active: boolean;
  onClick: MouseEventHandler;
}): ReactElement {
  const classes = useStyles();
  return (
    <ButtonIcon
      className={cn(classes.root, active ? 'active' : '')}
      onClick={onClick}
    >
      <Close className={classes.close} />
    </ButtonIcon>
  );
}
