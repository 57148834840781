import {
  endpoint,
  sourceName,
} from 'shared/logger/config/sumoLogicBaseConfig';

// Sumologger doesn't work in service worker - XHR is not supported
export const swSumoLogicLogger = {
  log: (body: object): void => {
    fetch(endpoint, {
      body: JSON.stringify(body),
      method: 'POST',
      // @ts-ignore specific sumo headers
      headers: {
        'X-Sumo-Name': sourceName,
        'X-Sumo-Category': 'Service worker',
      },
      // eslint-disable-next-line no-console
    }).catch((e) => console.log('could not log error to sumologic', e));
  },
};
