import React, { ReactElement, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DefaultCheckIcon from '@mui/icons-material/Check';

import { useStyles } from './styles';
import Divider from '@mui/material/Divider';
import { MemoPreviewField } from '../../general/PreviewField';

import classNames from 'classnames';
import { ChecklistItemPreviewProps } from './types';
import { GraphicUploader } from 'components/common/graphicUploader';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { createTemplateChecklistItemDocumentsBaseUrl } from 'views/template/wizard/communicator/helpers';
import useScrollToOnInit from 'hooks/scrollToOnInit';
import { ChecklistItem } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { useInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import {
  DocumentToChecklistBinder,
  IDocumentBinder,
} from 'shared/domain/document/documentBinder';
import { useHistory } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { DEFAULT_THUMBNAIL_CARD_SIZE } from 'components/common/graphicUploader/styles';

const CHECKLISTPREVIEW_PREFIX_DOM_ELEMENT_ID = 'checklistpreview-';
function add0ToSingleDigitNumber(number: number): string {
  return number < 10 ? '0' + number : `${number}`;
}

function shouldBeExpandedBasedOnUrl(
  checklistItem: ChecklistItem
): boolean {
  return window.location.pathname.includes(checklistItem._id);
}

function ChecklistItemPreview(
  props: ChecklistItemPreviewProps
): ReactElement {
  const {
    checklistItem,
    index,
    children,
    CheckIcon,
    error,
    classes,
    prefix,
    canChangeUrl,
    disableDocumentInternalUrl,
  } = props;
  const indexPlusOne = index + 1;
  const cs = useStyles({ classes });
  const intl = useIntl();
  const { inspectionTemplateDocuments, loading: loadingDocuments } =
    useInspectionTemplate();
  const history = useHistory();
  const internalEntityBaseUrl = useMemo(() => {
    if (disableDocumentInternalUrl) {
      return undefined;
    }
    return (): string =>
      `${prefix}/inspectionTemplate/${checklistItem.templateId}/checklist/${checklistItem._id}`;
  }, [
    disableDocumentInternalUrl,
    prefix,
    checklistItem.templateId,
    checklistItem._id,
  ]);

  const autoOpenCheck = useMemo(() => {
    return (_: string, file: any, __?: string | undefined) => {
      if (file && file._id) {
        return history.location.pathname.includes(`document/${file._id}`);
      }
      return false;
    };
  }, [history]);

  const config = React.useMemo(
    () => ({
      baseUrl: createTemplateChecklistItemDocumentsBaseUrl(
        checklistItem.templateId,
        checklistItem._id
      ),
      previewOnly: true as true,
      internalEntityBaseUrl,
      documentBinderFactory: (): IDocumentBinder =>
        new DocumentToChecklistBinder({
          templateId: checklistItem.templateId,
          checklistId: checklistItem._id,
        }),
      autoOpenCheck: autoOpenCheck,
    }),
    [
      checklistItem.templateId,
      checklistItem._id,
      internalEntityBaseUrl,
      autoOpenCheck,
    ]
  );
  const GU = useGraphicUploader(
    inspectionTemplateDocuments.checklistDocuments[checklistItem._id],
    config
  );

  const [expanded, setExpanded] = React.useState<boolean>(
    shouldBeExpandedBasedOnUrl(checklistItem)
  );
  const handleExpanded = useCallback(
    (_, isExpanded: boolean) => {
      if (isExpanded && canChangeUrl) {
        history.replace(
          `${prefix}/inspectionTemplate/${checklistItem.templateId}/checklist/${checklistItem._id}`
        );
      }
      setExpanded(isExpanded);
    },
    [
      canChangeUrl,
      history,
      checklistItem.templateId,
      checklistItem._id,
      prefix,
    ]
  );
  const expandIcon = React.useMemo(() => <ExpandMoreIcon />, []);

  useScrollToOnInit(
    expanded,
    `#${CHECKLISTPREVIEW_PREFIX_DOM_ELEMENT_ID}${index}`
  );

  return (
    <div>
      <div>
        <Accordion
          id={`${CHECKLISTPREVIEW_PREFIX_DOM_ELEMENT_ID}${index}`}
          className={cs.hustroTemplateAccordion}
          expanded={expanded}
          onChange={handleExpanded}
        >
          <AccordionSummary
            id={`checklistItemPreview${index}`}
            expandIcon={expandIcon}
            aria-label='Expand'
            data-qa='checklist-item-preview-expand'
          >
            <span
              className={classNames(
                'accordionTitle',
                expanded ? 'bold' : null,
                error ? 'error' : null
              )}
            >
              <span className={cs.accordionIndex}>
                {add0ToSingleDigitNumber(indexPlusOne)}
              </span>
              <span
                className={classNames(
                  'checklistItemSummary',
                  expanded ? 'expanded' : null
                )}
              >
                {checklistItem.summary}
              </span>
            </span>
            <div
              className={classNames(
                cs.checkIconContainer,
                error ? cs.checkIconError : null
              )}
            >
              {CheckIcon ? (
                <CheckIcon className={cs.checkIcon} />
              ) : (
                <DefaultCheckIcon className={cs.checkIcon} />
              )}
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={cs.flexColumn}>
              <MemoPreviewField
                label={intl.formatMessage({
                  id: 'checklist_item_preview_field_label_description',
                })}
                value={checklistItem.norm.description || ''}
                dense={true}
              />
              <div className={cs.graphicUploaderWrapper}>
                {loadingDocuments && (
                  <div
                    style={{
                      display: 'grid',
                      gridTemplateColumns: `repeat(auto-fill, ${DEFAULT_THUMBNAIL_CARD_SIZE}px)`,
                      gridGap: '16px',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                  >
                    <Skeleton
                      variant='rectangular'
                      width={DEFAULT_THUMBNAIL_CARD_SIZE}
                      height={DEFAULT_THUMBNAIL_CARD_SIZE}
                    />
                    <Skeleton
                      variant='rectangular'
                      width={DEFAULT_THUMBNAIL_CARD_SIZE}
                      height={DEFAULT_THUMBNAIL_CARD_SIZE}
                    />
                  </div>
                )}
                {!loadingDocuments && <GraphicUploader {...GU} />}
              </div>
              {children}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider className={cs.divider} />
    </div>
  );
}

export const MemoChecklistItemPreview = React.memo(ChecklistItemPreview);
