import { EventOutlined } from '@mui/icons-material';
import {
  InputAdornment,
  InputProps,
  Popover,
  TextField,
  useTheme,
} from '@mui/material';
import { ButtonIcon } from 'components/general';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import React, {
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import {
  DateRangePicker,
  Range,
  StaticRange,
  createStaticRanges,
  defaultStaticRanges,
} from 'react-date-range';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { ChartFilter, DateRangeObject } from 'shared/types/analytics';
import { DayMomentValidation } from 'shared/types/time';
import useIsMobile from '../../../../hooks/useIsMobile';
import { IntlContext } from '../../../../intl/IntlProviderWrapper';
import {
  datepickerDateToProjectTimezonedString,
  isoStringToShortISO,
  isoToLocalDate,
  toStandardDateDisplayStringFormat,
} from '../../../../shared/utils/date/dates';
import { useStylesDateRange } from './styles';

const getInputProps = (disabled: boolean): Partial<InputProps> => ({
  readOnly: true,
  endAdornment: (
    <InputAdornment position='end'>
      <ButtonIcon disabled={disabled}>
        <EventOutlined />
      </ButtonIcon>
    </InputAdornment>
  ),
});

const origin = {
  vertical: 'bottom' as 'bottom',
  horizontal: 'left' as 'left',
};
const timeoutValue = 10;
const startOfLastMonth = startOfMonth(addMonths(new Date(), -1));
const endOfLastMonth = endOfMonth(addMonths(new Date(), -1));
const threeMonthsBefore = startOfMonth(addMonths(startOfLastMonth, -2));
const sixMonthsBefore = startOfMonth(addMonths(startOfLastMonth, -5));

export function DaterangeControl(props: {
  filter: ChartFilter<unknown>;
  localSetter: (value: any) => void;
  localValue: DateRangeObject;
  disabled: boolean;
}): ReactElement {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const classes = useStylesDateRange({ isMobile });
  const { timezone } = useSelector(projectDataSelector);
  const value = props.localValue;
  const localSetter = props.localSetter;
  const { from, to } = value;
  const { calendarLocales } = useContext(IntlContext);
  const theme = useTheme();
  const input = useRef<HTMLInputElement>(null);

  // unable to find a proper mounting time to focus the input field without a timeout.
  const onPickerChange = useCallback((node) => {
    if (node) {
      setTimeout(() => {
        input.current?.parentElement?.classList.add('Mui-focused');
      }, timeoutValue);
    } else {
      setTimeout(() => {
        input.current?.blur();
      }, timeoutValue);
    }
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const openPanel = useCallback(
    (event) => {
      if (props.disabled) {
        return;
      }
      setAnchorEl(event.currentTarget);
    },
    [props.disabled]
  );

  const closePanel = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const selectionRange = {
    startDate: isoToLocalDate(from),
    endDate: isoToLocalDate(to),
    key: 'selection',
  };

  const textValue =
    from && to
      ? `${toStandardDateDisplayStringFormat(
          from,
          timezone
        )} - ${toStandardDateDisplayStringFormat(to, timezone)}`
      : '';

  const handleSelect = useCallback(
    (ranges) => {
      localSetter({
        from: isoStringToShortISO(
          datepickerDateToProjectTimezonedString(
            ranges.selection.startDate,
            timezone,
            DayMomentValidation.start
          )
        ),
        to: isoStringToShortISO(
          datepickerDateToProjectTimezonedString(
            ranges.selection.endDate,
            timezone,
            DayMomentValidation.end
          )
        ),
      });
    },
    [localSetter, timezone]
  );

  const staticRanges = useMemo((): StaticRange[] => {
    const defaultRanges = defaultStaticRanges.map((range) => {
      const result = { ...range };
      result.label = intl.formatMessage({
        // TODO range.label is possibly undefined
        id: `range_${range.label?.replace(' ', '_').toLocaleLowerCase()}`,
      });
      return result;
    });

    const additionalRanges: StaticRange[] = createStaticRanges([
      // @ts-ignore bad typings from definitely typed: createStaticRanges should take <Parial<StaticRange>>[]
      {
        label: intl.formatMessage({ id: 'range_last_three_months' }),
        range: (): Range => ({
          startDate: threeMonthsBefore,
          endDate: endOfLastMonth,
        }),
      },
      // @ts-ignore bad typings from definitely typed: createStaticRanges should take <Parial<StaticRange>>[]
      {
        label: intl.formatMessage({ id: 'range_last_six_months' }),
        range: (): Range => ({
          startDate: sixMonthsBefore,
          endDate: endOfLastMonth,
        }),
      },
    ]);

    return [...defaultRanges, ...additionalRanges];
  }, [intl]);

  return (
    <div className={classes.dateRange}>
      <TextField
        id='outlined-required'
        label={intl.formatMessage({ id: 'range' })}
        onClick={openPanel}
        variant='outlined'
        value={textValue || ''}
        InputProps={getInputProps(props.disabled)}
        inputRef={input}
        disabled={props.disabled}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={closePanel}
        anchorOrigin={origin}
        className={classes.popoverRangeControl}
      >
        {/* // react 18 types
            // @ts-ignore */}
        <DateRangePicker
          direction='horizontal'
          ref={onPickerChange}
          ranges={[selectionRange]}
          rangeColors={[theme.palette.primary.main]}
          onChange={handleSelect}
          locale={calendarLocales}
          staticRanges={staticRanges}
        />
      </Popover>
    </div>
  );
}
