import { BroadcastChannel } from 'broadcast-channel';
import React, { ReactElement, useEffect, useState } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { Finances } from 'shared/types/analytics';

type FinancialDataContextType = {
  financialData: FinancialDataResponse;
  loading: boolean;
  recievedAt: number;
};
type FinancialDataResponse = Finances | undefined;

export const FinancialDataContext =
  React.createContext<FinancialDataContextType>({
    financialData: undefined,
    loading: true,
    recievedAt: 0,
  });

// react 18 types
// @ts-ignore
const WithFinancialData: React.FC = ({ children }) => {
  const [financialData, setFinancialData] =
    useState<FinancialDataResponse>(undefined);
  const [recievedAt, setRecievedAt] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.analyticsChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (event.data && event.type === DomainMessagesTypes.financialData) {
        setFinancialData(event.data);
        setRecievedAt(Date.now());
        setLoading(false);
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, []);

  const ctx = {
    financialData: financialData,
    loading: loading,
    recievedAt: recievedAt,
  };

  return (
    <FinancialDataContext.Provider value={ctx}>
      {children}
    </FinancialDataContext.Provider>
  );
};

export const withFinancialData =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithFinancialData>
      <Component {...props} />
    </WithFinancialData>
  );
