import * as dbVisibleFieldsService from 'serviceWorker/db/visibleFieldsService';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  makeBroadcastAll,
  makeBroadcastClear,
  makeSimpleBroadcastError,
} from './factories';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { OnMessage } from './types';
import { BroadcastChannel } from 'broadcast-channel';
import { debugLog } from 'shared/logger/debugLog';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { getAllCurrentVisibleFieldsPromise } from 'serviceWorker/services/visibleFields/getVisibleFieldsPromise';

export const broadcastClearVisibleFields = makeBroadcastClear(
  ChannelNames.visibleFieldChannel
);

export const broadcastAllVisibleFields = makeBroadcastAll<
  VisibleFieldModel[]
>(
  dbVisibleFieldsService,
  {
    get: getAllCurrentVisibleFieldsPromise,
  },
  ChannelNames.visibleFieldChannel,
  DomainMessagesTypes.allVisibleFields
);

const broadcastVisibleFieldError = makeSimpleBroadcastError(
  ChannelNames.visibleFieldChannel
);

export const broadcastUploadedVisibleFieldError = (error: any): void => {
  debugLog('broadcastUploadedVisibleFieldError', error);
  broadcastVisibleFieldError(
    DomainMessagesTypes.failUploadedEntity,
    error
  );
};

export const broadcastUploadedVisibleField = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.visibleFieldChannel
);

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.visibleFieldChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllVisibleFields: {
        await getAllCurrentVisibleFields(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentVisibleFields(
  broadcast: BroadcastChannel
): Promise<void> {
  const currentVisibleFields = await getAllCurrentVisibleFieldsPromise();
  broadcast.postMessage({
    data: { items: currentVisibleFields },
    type: DomainMessagesTypes.allVisibleFields,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);
  apiBroadcast.postMessage({
    service: Services.VISIBLE_FIELDS,
    method: ServiceMethods.SYNC,
  });
  apiBroadcast.close();
}
