import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { DocumentEditData } from './editDocument';

function startDocumentEdit(data: DocumentEditData): void {
  debugLog('startDocumentEdit', data);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTS,
    method: ServiceMethods.EDIT,
    data,
  });
  broadcast.close();
}

export default startDocumentEdit;
