import { DateRangeObject } from 'shared/types/analytics';
import { EnvironmentalAspectFilters } from 'shared/types/analytics';
import { get as getEnvironmentalAspects } from 'serviceWorker/db/environmentalAspects';
import { ApexSeriesAndLabels } from 'shared/types/analytics';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByImpact,
  filterByProcess,
  filterBySite,
  filterBySubcontractor,
  filterByWorktype,
  increaseEntitySeries,
  increaseNoneSeries,
  toSortedApexSeries,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: EnvironmentalAspectFilters,
  timezone: string,
  additionalParams: { visibleProcesses: string[] }
): Promise<ApexSeriesAndLabels> {
  if (!additionalParams.visibleProcesses) {
    throw new Error('Missing visibleProcesses param');
  }
  const environmentalAspects = {};
  const environmentalAspectsMap = (await getEnvironmentalAspects()).reduce(
    (map, environmentalAspect) => {
      map.set(environmentalAspect._id, {
        _id: environmentalAspect._id,
        label: environmentalAspect.label,
      });
      return map;
    },
    new Map<string, LabelledEntity>()
  );

  const [
    dateRangeFilter,
    originatorsFilter,
    impactFilter,
    siteFilter,
    workTypeFilter,
    coordinatorFilter,
    processesFilter,
  ] = filters.dataScope.filters;

  const filteredIssues = issues.filter((issue) => {
    if (!additionalParams.visibleProcesses.includes(issue.process)) {
      return false;
    }

    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedOriginators = filterBySubcontractor(
      originatorsFilter.value as string[],
      issue
    );
    if (!passedOriginators) {
      return false;
    }

    const passedImpact = filterByImpact(
      impactFilter.value as string[],
      issue
    );
    if (!passedImpact) {
      return false;
    }

    const passedSite = filterBySite(siteFilter.value as string[], issue);
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterByWorktype(
      workTypeFilter.value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      coordinatorFilter.value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    const passedProcesses = filterByProcess(
      processesFilter.value as string[],
      issue
    );
    if (!passedProcesses) {
      return false;
    }

    return true;
  });

  const reducedToEnvironmentalAspectSummary = filteredIssues.reduce<
    HashMap<number>
  >((result, issue) => {
    const { environmentalAspect } = issue.extendedData;

    if (!environmentalAspect) {
      increaseNoneSeries(result, environmentalAspects);
    } else {
      increaseEntitySeries(
        result,
        environmentalAspects,
        environmentalAspectsMap.get(environmentalAspect)!
      );
    }
    return result;
  }, {});

  return toSortedApexSeries(
    reducedToEnvironmentalAspectSummary,
    environmentalAspects
  );
}
