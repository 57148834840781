import React, { ReactElement, useEffect } from 'react';
import { getSortIcon } from '../../views/issueTable/tableView/helpers';
import ForbiddenTooltip from '../general/ForbiddenTooltip/ForbiddenTooltip';
import BaseTable, { ColumnShape } from 'react-base-table';
import { Labelled } from 'shared/types/commonView';
import { WithStyles, createStyles, withStyles } from '@mui/styles';

const styles = createStyles(() => ({
  head: {
    alignSelf: 'stretch',
    padding: '0 16px',
    width: '100%',
  },
}));

const tooltipStyle = { height: '100%' };

type ColumnHeaderProps = WithStyles<any> & {
  container: BaseTable;
  column: ColumnShape;
  sortable?: boolean;
};

const ColumnHeader = ({
  classes,
  container,
  column,
  sortable,
}: ColumnHeaderProps): React.ReactElement => {
  const SortIcon = getSortIcon(container, column);
  const myself = document.getElementById(`headerCol-${column._id}`);

  const isSortable = sortable !== undefined ? sortable : column.sortable;

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    const delay = 200;

    if (myself) {
      myself.addEventListener(
        'click',
        function (ev) {
          const clickByUser = ev.isTrusted;

          if (clickByUser) {
            ev.stopImmediatePropagation();
            ev.stopPropagation();

            clearTimeout(timer);
            timer = setTimeout(function () {
              myself.click();
            }, delay);
          }
        },
        true
      );
    }
  }, [myself]);

  return (
    <ForbiddenTooltip
      visibleWhen={!isSortable}
      reason='tooltip_multiselect_is_not_sortable'
      style={tooltipStyle}
    >
      <div
        className={classes.head}
        id={`headerCol-${column._id}`}
        style={{
          display: 'inline-flex',
          alignItems: 'center',
        }}
      >
        <span data-qa={`table-view-header-${column._id}`}>
          {column.label}
        </span>
        {SortIcon}
      </div>
    </ForbiddenTooltip>
  );
};

export function StandardHeader(props: { column: Labelled }): ReactElement {
  const { column } = props;
  return <span>{column.label}</span>;
}

export default withStyles(styles)(ColumnHeader);
