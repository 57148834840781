import * as dbDirectories from 'serviceWorker/db/directories';
import * as dbDirectoriesService from 'serviceWorker/db/directoriesService';
import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeBroadcastClear, makeBroadcastAll } from './factories';
import { OnMessage } from './types';
import { directoryEntityToModel } from 'serviceWorker/repository/directory/mappings';
import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { LogLevel } from 'shared/types/logger';
import { getUsersSynchronized } from 'serviceWorker/services/users/getUsers';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { UserEntity } from 'shared/domain/user/types/entity';

export const broadcastClearDirectories = makeBroadcastClear(
  ChannelNames.directoryChannel
);

export const broadcastAllDirectories = makeBroadcastAll<DirectoryModel[]>(
  dbDirectoriesService,
  {
    get: async () => {
      const [directories, users] = await Promise.all([
        dbDirectories.get(),
        getUsersSynchronized(),
      ]);

      return directories.map((entity) =>
        directoryEntityToModel(entity, users)
      );
    },
  },
  ChannelNames.directoryChannel,
  DomainMessagesTypes.allDirectories
);

export const broadcastCreatedDirectory = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdEntity,
  ChannelNames.directoryChannel
);

export const broadcastUploadedDirectory = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.directoryChannel
);

export const broadcastDirectoryMoved = makeSimpleBroadcastEntity(
  DomainMessagesTypes.documentationMoved,
  ChannelNames.directoryChannel
);

export const broadcastDirectoryDeleted = makeSimpleBroadcastEntity(
  DomainMessagesTypes.documentationDeleted,
  ChannelNames.directoryChannel
);

export const broadcastDirectoryEdited = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.directoryChannel
);

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllDirectories: {
        const serviceData = await dbDirectoriesService.get();
        if (!serviceData.isDownloaded) {
          resync();
          return;
        }
        broadcastAllDirectories();
        resync();
        return;
      }
      case DomainMessagesTypes.getDirectories: {
        broadcastDirectoriesByQuery(
          event.data.parentId || '',
          event.uniqueId
        );
        return;
      }
      case DomainMessagesTypes.findRoots: {
        broadcastDirectoryRoots(event.data.parentId, event.uniqueId);
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function broadcastDirectoriesByQuery(
  value: string,
  uniqueId?: string
): Promise<void> {
  const directories = await getDirectoriesByQuery(value);

  const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);

  broadcast.postMessage({
    data: {
      items: directories,
      query: value,
    },
    type: DomainMessagesTypes.directories,
    uniqueId,
  });
  broadcast.close();
}

// Imports break when we try to put this function in services.
async function getDirectoriesByQuery(
  value: string
): Promise<DirectoryModel[]> {
  const [dirs, users] = await Promise.all([
    dbDirectories.getByQuery({ parentId: value }),
    getUsersSynchronized(),
  ]);
  return dirs.map((dir) => directoryEntityToModel(dir, users));
}

async function broadcastDirectoryRoots(
  value: string,
  uniqueId?: string
): Promise<void> {
  const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);

  try {
    const users = await getUsersSynchronized();
    const roots = await findRoots(users, [], value);
    broadcast.postMessage({
      data: {
        items: roots,
      },
      type: DomainMessagesTypes.directoryRoots,
      uniqueId,
    });
  } catch (e) {
    if (e instanceof Error) {
      swLog(
        e.message,
        LogLevel.INFO,
        {
          functionName: 'broadcastDirectoryRoots',
          params: [
            ['value', value],
            ['uniqueId', uniqueId],
          ],
        },
        null
      );
    }
  }
  broadcast.close();
}

async function findRoots(
  users: UserEntity[],
  results: DirectoryModel[],
  value: string
): Promise<DirectoryModel[]> {
  if (!value) return [];
  const directory = await dbDirectories.getOneByRemote(value);
  if (!directory) {
    return results;
  }
  results.push(directoryEntityToModel(directory, users));
  if (directory.parentId) {
    return findRoots(users, results, directory.parentId);
  }
  return results.reverse();
}

function resync(): void {
  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.DIRECTORIES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
