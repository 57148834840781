import { CreateCSSProperties, makeStyles } from '@mui/styles';
import { Color } from 'shared/utils/colors';

const buttonHoverWhenExpanded = {
  '& span': {
    color: '#000000',
  },
  '& svg': {
    fill: '#000000',
  },
};
const subItemButtonHoverWhenExpanded = {
  '&:hover': {
    ...buttonHoverWhenExpanded,
    background:
      'linear-gradient(to right, #757575 77%, transparent 20%), radial-gradient(circle at left, #757575 44%, transparent 45%) 90% 0/15.5% 100% no-repeat',
  },
};

const itemButtonHoverWhenExpanded = {
  '&:hover': {
    ...buttonHoverWhenExpanded,
    background:
      'linear-gradient(to right, #757575 71%, transparent 20%), radial-gradient(circle at left, #757575 46%,transparent 45%) 81% 0/15.5% 100% no-repeat',
  },
};

const button = {
  marginTop: '13px',
  paddingLeft: '13px',
  display: 'flex',
  alignItems: 'center',
  minWidth: '200px',
  'overflow-x': 'hidden',
  width: '225px',
};

const clickableButton = {
  ...button,
  cursor: 'pointer',
};
const clickableSubItemButton = {
  ...clickableButton,
  paddingLeft: '28px',
};

const clickableSubItemButtonNotActiveLocation = {
  ...clickableSubItemButton,
  ...subItemButtonHoverWhenExpanded,
};

const clickableItemButtonNotActiveLocation = {
  ...clickableButton,
  ...itemButtonHoverWhenExpanded,
};

function getActiveLocationStyle(): CreateCSSProperties {
  return {
    wordWrap: 'break-word',
    '& span': {
      color: Color.black as string,
    },
    '& svg': {
      fill: Color.black as string,
    },
    background: Color.primaryOrange as string,
  };
}

const svg = {
  fill: '#757575',
  width: '28px',
  height: '28px',
  border: '5px solid transparent',
  transition: 'fill 0ms',
};

function getSvgWhenCollapsedAndCurrentLocationStyle(): CreateCSSProperties {
  return {
    ...svg,
    fill: Color.black as string,
    background: Color.primaryOrange,
    borderRadius: '50%',
  };
}
const useStyles = makeStyles(() => ({
  button,
  clickableButton,
  clickableSubItemButton,
  itemButtonHoverWhenExpanded,
  subItemButtonHoverWhenExpanded,
  clickableSubItemButtonNotActiveLocation,
  clickableSubItemButtonActiveLocation: {
    ...clickableSubItemButton,
    ...getActiveLocationStyle(),
  },
  clickableItemButtonNotActiveLocation,
  clickableItemButtonActiveLocation: {
    ...clickableButton,
    ...getActiveLocationStyle(),
  },
  svg,
  svgWhenCollapsedAndCurrentLocation:
    getSvgWhenCollapsedAndCurrentLocationStyle(),
  text: {
    '&:hover': {
      fontColor: '#000000',
    },
    width: '50%',
  },
}));

export default useStyles;
