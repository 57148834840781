import { BroadcastChannel } from 'broadcast-channel';
import { CompanyModel } from 'shared/domain/company/types/model';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { CompanyInDto } from 'shared/dtos/in/company';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { companyEntityToModel } from 'serviceWorker/repository/company/mapping';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUsersSynchronized } from 'serviceWorker/services/users/getUsers';
import * as dbCompanies from '../../db/companies';
import { makeBroadcastEntity } from '../factories';
import { broadcastCreatedCompanyError } from './error';

export const broadcastCompany = makeBroadcastEntity(
  {
    getOne: async (id) => {
      const [entity, users] = await Promise.all([
        dbCompanies.getOne(id),
        getUsersSynchronized(),
      ]);
      if (!entity) {
        throw new Error(`Cannot find company ${id}`);
      }
      return companyEntityToModel(entity, users);
    },
  },
  ChannelNames.companyChannel,
  DomainMessagesTypes.company
);

export const broadcastUploadedCompany = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.companyChannel
);

export const broadcastDeletedCompany = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.companyChannel
);

export const broadcastCreatedCompany = async (
  companyInDto: CompanyInDto,
  uniqueId: string
): Promise<void> => {
  try {
    await dbCompanies.addBatch([companyInDto]);
    const companyEntity = await dbCompanies.getOne(companyInDto._id);
    if (!companyEntity) {
      throw new Error(`Cannot find company with id ${companyInDto._id}`);
    }
    const users = await getUsersSynchronized();
    broadcastCompanySuccess(
      DomainMessagesTypes.createdEntity,
      companyEntityToModel(companyEntity, users),
      uniqueId
    );
  } catch (error) {
    broadcastCreatedCompanyError(error);
  }
};

export const broadcastUpdatedCompany = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.companyChannel
);

function broadcastCompanySuccess(
  type: DomainMessagesTypes,
  data: CompanyModel,
  uniqueId?: string
): void {
  const broadcast = new BroadcastChannel(ChannelNames.companyChannel);
  broadcast.postMessage({
    data,
    type,
    uniqueId,
  });
  broadcast.close();
}
