import { useDispatch } from 'react-redux';
import {
  CLEAR_FORMS_EDITING,
  SET_FORM_EDITING,
} from '../../../redux/actionTypes';
import { useCallback, useMemo } from 'react';
import { FormName } from 'presentation/forms';

type SetEditing = (isFormEditing: boolean, form: FormName) => void;
type ClearAllForms = () => void;
export type FormEdit = {
  setEditing: SetEditing;
  clearAllForms: ClearAllForms;
};
const useFormEdit = (): FormEdit => {
  const dispatch = useDispatch();

  const setEditing: SetEditing = useCallback(
    (isFormEditing: boolean, form: FormName): void => {
      dispatch({ type: SET_FORM_EDITING, form, isFormEditing });
    },
    [dispatch]
  );

  const clearAllForms = useCallback((): void => {
    dispatch({ type: CLEAR_FORMS_EDITING });
  }, [dispatch]);

  return useMemo(
    (): FormEdit => ({
      setEditing,
      clearAllForms,
    }),
    [setEditing, clearAllForms]
  );
};

export default useFormEdit;
