import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from '../styles';

import { innerIconBig } from '..';

import { BorderedChip } from '../../../general';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

export const ProtocolItemResultLabel: Record<
  ComplianceCheckResult,
  string
> = {
  [ComplianceCheckResult.unset]: 'protocol_item_result_unset',
  [ComplianceCheckResult.notApplicable]:
    'protocol_item_result_not_applicable',
  [ComplianceCheckResult.notPassed]: 'protocol_item_result_not_passed',
  [ComplianceCheckResult.passed]: 'protocol_item_result_passed',
};

type ProtocolItemResultChipProps = {
  status: ComplianceCheckResult;
};

function ProtocolItemResultChip(
  props: ProtocolItemResultChipProps
): ReactElement {
  const { status } = props;
  const classes = useStyles();
  const intl = useIntl();
  return (
    <BorderedChip
      className={classes[status]}
      icon={innerIconBig[status]}
      text={intl.formatMessage({ id: ProtocolItemResultLabel[status] })}
    />
  );
}

export const MemoProtocolItemResultChip = React.memo(
  ProtocolItemResultChip
);
