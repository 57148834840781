import { MemoInspectionStatus } from 'components/inspection/Status';
import { InspectionStatusSize } from 'components/inspection/Status/types';
import { InspectionStatus } from 'shared/domain/inspection/inspectionModel';
import { ReactElement } from 'react';

export function InspectionStatusRenderer({
  rowData,
}: {
  rowData: { status: InspectionStatus };
}): ReactElement {
  return (
    <MemoInspectionStatus
      status={rowData.status}
      size={InspectionStatusSize.table}
    />
  );
}
