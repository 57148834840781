import { BroadcastChannel } from 'broadcast-channel';
import { VERSION_MISMATCH_ACTION_TYPE } from 'redux/actionTypes';
import { logFetchError } from 'setup/logger/logFetchError';
import { logFetchInfo } from 'setup/logger/logFetchInfo';
import { store } from 'setup/store';
import { apiUrl } from 'shared/apiUrl';
import { UNAUTHORIZED, VERSION_MISMATCH } from 'shared/contants';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { syncOnUprocessableEntity } from 'shared/domain/synchronization/syncOnUnprocessable';
import { FetchErrorDetails, FetchMethod } from 'shared/types/logger';
import { CreateFetchErrorProps, FetchConfig, UrlBuilder } from './types';

const defaultUrlBuilder: UrlBuilder = (apiUrl, url) => {
  const { projectData } = store.getState();

  return `${apiUrl}/v2/project/${projectData._id}${url}`;
};

export const buildUrl = (
  url: string,
  urlBuilder: UrlBuilder = defaultUrlBuilder
): string => {
  return urlBuilder(apiUrl, url);
};

interface ErrorWithDetails extends Error {
  details?: string;
}

export class FetchError extends Error {
  readonly status: number;
  readonly error: ErrorWithDetails;

  constructor(status: number, error: ErrorWithDetails) {
    super('Fetch error');
    this.status = status;
    this.error = error;
  }
}

function isAbortedByUser(resp: Error): boolean {
  return resp.name === 'AbortError';
}

export const makeStatusHandler = (
  url: string,
  method: FetchMethod,
  data?: any,
  requestInit?: RequestInit,
  fetchConfig?: FetchConfig
) => {
  return async (response: Response | FetchError): Promise<unknown> => {
    if (response instanceof FetchError || !response.ok) {
      if (response.status === VERSION_MISMATCH) {
        store.dispatch({ type: VERSION_MISMATCH_ACTION_TYPE });
        return;
      }

      if (response.status === UNAUTHORIZED) {
        const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
        broadcast.postMessage({
          service: Services.CONFIG,
          method: ServiceMethods.LOGOUT,
        });
        return;
      }

      syncOnUprocessableEntity(response);

      const error =
        response instanceof Response
          ? (await response.json?.())?.error
          : response.error;
      const fetchErrorLogDetails = createFetchErrorLogObject({
        error,
        data,
        config: requestInit,
        url,
        method,
        response,
      });

      // FetchError extends Error. We care about the message and name properties.
      if (!isAbortedByUser(response as Error)) {
        logFetchError(
          'Error occurred when fetching data',
          fetchErrorLogDetails
        );
      } else {
        logFetchInfo('Request aborted by user', fetchErrorLogDetails);
      }

      throw new FetchError(response.status, error);
    }

    return response.json();
  };
};

function createFetchErrorLogObject({
  error,
  data,
  config,
  url,
  method,
  response,
}: CreateFetchErrorProps): FetchErrorDetails {
  return {
    requestError: error,
    requestData: data,
    requestConfig: config,
    url,
    method,
    response,
  };
}
