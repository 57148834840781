import React, { PropsWithChildren, ReactElement, useEffect } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { ProjectLoading } from 'views/projectLoading';
import { useIsProjectLoading } from '../withIsProjectLoading';
import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';

const WithProjectLoading: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { loading } = useIsProjectLoading();

  return loading ? (
    <ProjectLoading />
  ) : (
    <RegisterDbListeners>{children}</RegisterDbListeners>
  );
};

export { WithProjectLoading };

function RegisterDbListeners({
  children,
}: PropsWithChildren<{}>): ReactElement {
  useEffect(() => {
    const broadcast = new BroadcastChannel(
      ChannelNames.dbChangesListenerChannel
    );
    broadcast.postMessage({
      type: DomainMessagesTypes.registerDbChangesListeners,
    });
  }, []);

  return <>{children}</>;
}
