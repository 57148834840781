import { IssueOnSingleView } from 'components/dataProviders/withIssue/model';
import { Issue } from 'setup/types/core';
import { FieldSkeleton, IssueForm, ProcessType } from 'shared/types/form';
import { ColumnValue } from 'components/table/types';
import { ColumnKey } from 'components/table/columnShapes/types';

export type TableState = {
  items: Issue[];
  currentPageLoaded: number;
  sorted: boolean;
};

export const fixedColumnsIds = [ColumnKey.no, ColumnKey.process, 'stage'];

export type ExtractFieldData = (
  processes: ProcessType[],
  issueForm: IssueForm,
  duplicates: string[]
) => { pri: FieldSkeleton[]; ext: FieldSkeleton[] };

export type IssueData =
  | IssueOnSingleView['primaryData']
  | IssueOnSingleView['extendedData'];

export type AccessorCreator = (
  data: IssueData,
  field: FieldSkeleton
) => ColumnValue;
