import { GetInspectionCustomEvent } from 'serviceWorker/api/types';
import {
  broadcastAllInspections,
  broadcastInspection,
} from 'serviceWorker/broadcasts/inspections';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as inspections from 'serviceWorker/db/inspections';
import * as inspectionsService from 'serviceWorker/db/inspectionsService';
import { getOne as getPermissionOnProject } from 'serviceWorker/db/permissions';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';

import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { UserRole } from 'shared/types/userRole';
import { getSelectedProject } from '../selectedProject';
import {
  fetchInspections,
  fetchUpdatedInspections,
} from './fetchInspections';

import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullInspections: Pull = makePullEntity({
  fetch: fetchInspections,
  channelName: ChannelNames.inspectionChannel,
  entityName: 'inspections',
  entityRepository: inspections,
  entityService: inspectionsService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullInspectionsHandler = makePullHandler(
  config,
  inspections,
  inspectionsService,
  pullInspections,
  clearBroadcasts
);

const synchronizeInspections = makeSynchronizeEntity({
  configRepository: config,
  entityService: inspectionsService,
  entityRepository: inspections,
  pullEntityHandler: pullInspectionsHandler,
  fetchUpdatedEntities: fetchUpdatedInspections,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'inspections',
  channelName: ChannelNames.inspectionChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
  canSyncronize: canSyncInspections,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeInspections.bind(null, broadcastAllInspections),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncInspections,
    syncDebounced
  );
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    DomainMessagesTypes.getInspection,
    function onGetInspection(e: GetInspectionCustomEvent): void {
      debugLog('onGetInspection', e);
      synchronizeInspections(broadcastInspection.bind(null, e));
    }
  );
};

async function canSyncInspections(): Promise<boolean> {
  const selectedProject = await getSelectedProject();
  if (!selectedProject) {
    return false;
  }
  const currentProjectPermissions = await getPermissionOnProject(
    selectedProject._id
  );
  if (!currentProjectPermissions) {
    return false;
  }

  return currentProjectPermissions.role !== UserRole.standard;
}
