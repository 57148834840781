import { FormattedMessage } from 'react-intl';
import React from 'react';
import useStyles from './styles';
import { NavigationItemProps } from '../NavigationDrawer/types';
import { Box, Typography } from '@mui/material';

const NavigationItemButton = ({
  isSubItem,
  componentKey,
  redirectAddress,
  textId,
  isButtonRedirectLocationCurrentLocation,
  Icon,
  isNavigationExpanded,
  onClick,
  disabled,
}: NavigationItemProps): JSX.Element => {
  const classes = useStyles();

  let buttonClass = classes.button;

  const clickable = Boolean(!disabled && (redirectAddress || onClick));

  if (isNavigationExpanded) {
    if (clickable) {
      if (isSubItem) {
        if (isButtonRedirectLocationCurrentLocation) {
          buttonClass = classes.clickableSubItemButtonActiveLocation;
        } else {
          buttonClass = classes.clickableSubItemButtonNotActiveLocation;
        }
      } else {
        if (isButtonRedirectLocationCurrentLocation) {
          buttonClass = classes.clickableItemButtonActiveLocation;
        } else {
          buttonClass = classes.clickableItemButtonNotActiveLocation;
        }
      }
    }

    return (
      <Box className={buttonClass} onClick={onClick}>
        <Icon fill='#757575' className={classes.svg} />

        <Typography variant='h4' component='span' className={classes.text}>
          <FormattedMessage id={textId} />
        </Typography>
      </Box>
    );
  } else {
    return (
      <div
        className={classes.button}
        data-qa={`navigation-item-${componentKey}`}
        onClick={onClick}
      >
        <Icon
          className={
            isButtonRedirectLocationCurrentLocation
              ? classes.svgWhenCollapsedAndCurrentLocation
              : classes.svg
          }
        />
      </div>
    );
  }
};

export default React.memo(NavigationItemButton);
