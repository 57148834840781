import { DocumentationFile } from 'components/dataProviders/withDocumentationController/types';
import { useLevels } from 'components/dataProviders/withLevels';
import { useSites } from 'components/dataProviders/withSites';
import { MemoPreviewField } from 'components/general/PreviewField';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { LabelledEntity } from 'shared/types/commonView';
import { toStandardDateDisplayStringFormat } from 'shared/utils/date/dates';
import Skeleton from '@mui/material/Skeleton';
import { findDocumentationOnLevelsAndSites } from 'shared/domain/documentation/findDocumentationOnLevels';

export function DocumentationInformationPreview({
  file,
}: {
  file: DocumentationFile;
}): ReactElement {
  const intl = useIntl();
  const { loading: loadingSites, sites } = useSites();
  const { loading: loadingLevels, levels } = useLevels();
  const { timezone } = useSelector(projectDataSelector);

  if (loadingSites || loadingLevels) {
    return (
      <>
        <div className='row'>
          <Skeleton variant='text' width={'100%'} height={48} />
        </div>
        <div className='row'>
          <Skeleton variant='text' width={'100%'} height={48} />
        </div>
        <div className='row'>
          <Skeleton variant='text' width={'100%'} height={48} />
        </div>
        <div className='row'>
          <Skeleton variant='text' width={'100%'} height={48} />
        </div>
      </>
    );
  }

  const documentationId = file.documentationId;
  const versionId = file.versionId;

  const { sitesValue, levelsValue } = findDocumentationOnLevelsAndSites(
    levels.items,
    sites.items,
    documentationId,
    versionId
  );

  return (
    <>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({ id: 'primaryField_Site' })}
          value={sitesValue.map((site: LabelledEntity) => site.label)}
          dense={false}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({ id: 'primaryField_Level' })}
          value={levelsValue.map((level: LabelledEntity) => level.label)}
          dense={false}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({ id: 'documentation_creation_date' })}
          dense={true}
          value={toStandardDateDisplayStringFormat(
            file.createdAt,
            timezone
          )}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({ id: 'documentation_author' })}
          value={file.createdBy?.label || ' '}
          dense={false}
        />
      </div>
    </>
  );
}
