import { Process } from 'shared/types/process';
import { getStaticFieldsOnProcess } from '../visibleField/availableFields';
import { getIssueFormSchema } from './getIssueFormSchema';
import { IssueFormSchema } from './types/issueFormSchema';

export function getIssueFormSchemaWithRemovedUnknown(
  visibleFields: { fieldName: string }[],
  process: Process
): IssueFormSchema {
  return removeUnknown(getIssueFormSchema(), visibleFields, process);
}

function removeUnknown(
  schema: IssueFormSchema,
  visibleFields: { fieldName: string }[],
  process: Process
): IssueFormSchema {
  const fields = [...visibleFields, ...getStaticFieldsOnProcess(process)];

  const filteredSchema = schema.reduce<IssueFormSchema>(
    (result, section) => {
      const sectionFields = section.fields.filter((sectionFieldName) => {
        return fields.find(
          (field) => field.fieldName === sectionFieldName
        );
      });
      if (sectionFields.length) {
        result.push({
          sectionTitleId: section.sectionTitleId,
          fields: sectionFields,
        });
      }
      return result;
    },
    []
  );

  return filteredSchema;
}
