import { LevelCreateModel, LevelEditModel } from '../types/model';
import { CreateLevelOnView, LevelOnView } from '../types/view';

export function createLevelOnViewToCreateModel(
  fields: CreateLevelOnView,
  projectId: string
): LevelCreateModel {
  return {
    deleted: fields.deleted,
    label: fields.label,
    site: fields.site,
    index: 0,
    createdAt: fields.createdAt.toISO(),
    modifiedAt: fields.modifiedAt.toISO(),
    projectId,
    map: fields.map,
  };
}

export function levelOnViewToEditModel(
  fields: LevelOnView,
  projectId: string
): LevelEditModel {
  return {
    _id: fields._id,
    label: fields.label,
    projectId,
    map: fields.map,
  };
}
