import { ReactElement } from 'react';

import { useIntl } from 'react-intl';
import {
  useCreateSingleInspectionReport,
  withCreateSingleInspectionReport,
} from 'components/dataCreationForms/withCreateSingleInspectionReport';
import { ButtonTextIcon } from 'components/general';
import { ReportIcon } from 'components/reports/ReportIcon';

function _InspectionPdfButton(): ReactElement {
  const intl = useIntl();
  const TEXT = intl.formatMessage({ id: 'pdf_button_print_label' });

  const { openDialog } = useCreateSingleInspectionReport();

  return (
    <ButtonTextIcon
      startIcon={ReportIcon}
      aria-label='Generate pdf'
      onClick={openDialog}
    >
      {TEXT}
    </ButtonTextIcon>
  );
}

export const InspectionPdfButton = withCreateSingleInspectionReport(
  _InspectionPdfButton
);
