import { ApexAxisChartSeries } from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { IntlShape } from 'react-intl';

const combinedDangerousConditionsActionsId =
  'hs_effect_chart_dangerous_conditions_actions';

const sortedEffectIds: string[] = [
  '5f8eb44901c3328428b6623c', //fatal accident
  '5f8eb44f34a63e6c455937c9', //severe injury
  '5f8eb456b5441742fb4be051', //light injury
  '5f8eb46a83e578eb8f2bc2e6', //medical event
  '5f8eb5c54de26f3e02c41971', //near miss
  '5f8eb45df67db5b0e913af3d', //dangerous conditions
  '5f8eb463f6a6164a9c42fb77', //dangerous actions
];

const sortedAndCombinedEffectIds = sortedEffectIds
  .slice(0, -2)
  .concat(combinedDangerousConditionsActionsId);

const EMPTY: [ApexAxisChartSeries, []] = [[], []];

export function createCategories(
  effectFieldValues: LabelledEntity[],
  intl: IntlShape
): string[] {
  const notCombinedEffectIds = sortedAndCombinedEffectIds.slice(0, -1);
  const result = notCombinedEffectIds.map((effectId) => {
    return effectFieldValues.find(({ _id }) => _id === effectId)!.label;
  });

  result.push(
    intl.formatMessage({
      id: combinedDangerousConditionsActionsId,
    })
  );

  return result;
}
