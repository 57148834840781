import Dexie, { ObservabilitySet } from 'dexie';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

const tablesToObserve: { [key: string]: ChannelNames } = {
  'idb://hustro/issues/': ChannelNames.issueChannel,
  'idb://hustro/levels/': ChannelNames.levelChannel,
  'idb://hustro/sites/': ChannelNames.siteChannel,
  'idb://hustro/worktypes/': ChannelNames.worktypeChannel,
  'idb://hustro/hazardCategories/': ChannelNames.hazardCategoryChannel,
  'idb://hustro/environmentalAspects/':
    ChannelNames.environmentalAspectChannel,
  'idb://hustro/correctiveActionTypes/':
    ChannelNames.correctiveActionTypeChannel,
  'idb://hustro/companies/': ChannelNames.companyChannel,
  'idb://hustro/contracts/': ChannelNames.contractChannel,
  'idb://hustro/issueForm/': ChannelNames.issueFormChannel,
  'idb://hustro/inspections/': ChannelNames.inspectionChannel,
  'idb://hustro/inspectionTemplates/':
    ChannelNames.inspectionTemplateChannel,
  'idb://hustro/visibleFields/': ChannelNames.visibleFieldChannel,
};

class DbChangeListener {
  constructor() {
    this.registerUnsubs();
    this.registerSubs();
  }

  private setupListeners(): void {
    this.unsubscribeAll();
    Dexie.on('storagemutated', onStorageMutated);
  }

  private registerUnsubs(): void {
    let projectId = '';
    const projectChangeBroadcast = new BroadcastChannel(
      ChannelNames.selectedProjectChannel
    );

    projectChangeBroadcast.onmessage = (event: Message): void => {
      if (
        event.data &&
        event.type === DomainMessagesTypes.selectedProject &&
        projectId &&
        event.data.project?._id !== projectId
      ) {
        projectId = event.data.project?._id;
        this.unsubscribeAll();
      }
    };

    const logoutBroadcast = new BroadcastChannel(
      ChannelNames.logoutChannel
    );
    logoutBroadcast.onmessage = () => {
      this.unsubscribeAll();
    };
  }

  private unsubscribeAll(): void {
    Dexie.on('storagemutated').unsubscribe(onStorageMutated);
  }

  private registerSubs(): void {
    const broadcast = new BroadcastChannel(
      ChannelNames.dbChangesListenerChannel
    );
    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.registerDbChangesListeners) {
        this.setupListeners();
      }
    };
  }
}

function onStorageMutated(parts: ObservabilitySet): void {
  const keys = Object.keys(parts);
  const table = keys.find((key) => tablesToObserve[key]);
  if (!table) return;
  const broadcast = new BroadcastChannel(tablesToObserve[table]);
  broadcast.postMessage({
    type: DomainMessagesTypes.tableChanged,
  });
  broadcast.close();
}

export const init = (): void => {
  new DbChangeListener();
};
