import { useCompanyChannelListener } from 'components/broadcastChannelListeners/withCompanyChannelListener';
import { CompanyModel } from 'shared/domain/company/types/model';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allCompanies
  );
}

export const useGetAllCompanies = makeUseGetAll<CompanyModel>({
  useChannelListener: useCompanyChannelListener,
  postMessageChannel: ChannelNames.companyChannel,
  postMessageType: DomainMessagesTypes.getAllCompanies,
  isMatchingMessage,
  entityName: 'companies',
});
