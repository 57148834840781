import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { Identificable } from 'shared/types/commonView';
import { makeDefaultSync } from './apiFactories';
import { SetSelectedProjectEventDetail } from './types';

const sync = makeDefaultSync(RepositoryMessagesTypes.getSelectedProject);

const set = (data: Identificable): void => {
  self.dispatchEvent(
    new CustomEvent<SetSelectedProjectEventDetail>(
      RepositoryMessagesTypes.selectedProjectSet,
      {
        detail: { _id: data._id },
      }
    )
  );
};

const clearProjectData = (): void => {
  self.dispatchEvent(
    new Event(RepositoryMessagesTypes.clearSelectedProjectData)
  );
};

export default { sync, set, clearProjectData };
