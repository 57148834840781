import { Box } from '@mui/material';
import {
  MemoStatefulAutocompleteWithText,
  MemoStatefulMultiselect,
  MemoStatefulSingleSelect,
} from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import React from 'react';
import { CompanyFieldsPresentationalProps } from './types';

export function CompanyFieldsPresentational({
  shortLabelFieldProps,
  longLabelFieldProps,
  sitesFieldProps,
  projectInvolvementTypeFieldProps,
  taxNumberFieldProps,
}: CompanyFieldsPresentationalProps): React.ReactElement {
  return (
    <Box>
      <MemoStatefulAutocompleteWithText {...shortLabelFieldProps} />
      {longLabelFieldProps && (
        <MemoStatefulAutocompleteWithText {...longLabelFieldProps} />
      )}
      <MemoStatefulMultiselect {...sitesFieldProps} />
      {projectInvolvementTypeFieldProps && (
        <MemoStatefulSingleSelect {...projectInvolvementTypeFieldProps} />
      )}
      {taxNumberFieldProps && (
        <MemoStatefulAutocompleteWithText {...taxNumberFieldProps} />
      )}
    </Box>
  );
}
