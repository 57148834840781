import { ReactElement, useState } from 'react';
import { useIntl } from 'react-intl';

import { useStyles } from './styles';
import useIsMobile from '../../../hooks/useIsMobile';

import SearchIcon from '@mui/icons-material/Search';
import { HighlightOff as ClearIcon } from '@mui/icons-material';

import { SearchInputPresentationalProps } from './types';
import cn from 'classnames';
import useOnClickOutside from '../../../hooks/clickOutside';

export function SearchInputPresentational({
  ref,
  className,
  value,
  dataQa,
  placeholderTextId,
  handleInputChange,
  handleSearchClear,
}: SearchInputPresentationalProps): ReactElement {
  const isMobile = useIsMobile();
  const [hideInput, setHideInput] = useState(isMobile && !value);
  useOnClickOutside(ref, () => setHideInput(isMobile && !value));
  const classes = useStyles({ isMobile });
  const intl = useIntl();
  return (
    <div className={cn(classes.searchWrap, className)} ref={ref}>
      {hideInput && (
        <div
          className={classes.searchButton}
          onClick={(): void => {
            setHideInput(false);
          }}
        >
          <SearchIcon className={classes.searchIcon} />
        </div>
      )}
      {!hideInput && (
        <div className={classes.searchInputWrap}>
          <input
            value={value}
            onChange={handleInputChange}
            data-qa={dataQa}
            className={classes.searchInput}
            placeholder={intl.formatMessage({
              id: placeholderTextId,
            })}
          />
          {value.length ? (
            <ClearIcon
              onClick={handleSearchClear}
              className={classes.clearIcon}
              data-qa={`${dataQa}-clear-search`}
            />
          ) : (
            <SearchIcon className={classes.searchIcon} />
          )}
        </div>
      )}
    </div>
  );
}
