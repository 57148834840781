import { LabelledEntity } from 'shared/types/commonView';

export function anyIdToLabelledEntity(
  id: string,
  labelledEntities: LabelledEntity[]
): LabelledEntity | undefined {
  return labelledEntities.find(
    (labelledEntity) => labelledEntity._id === id
  );
}
