import React, {
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { useGetDocuments } from 'hooks/useGetDocuments';
import { isEqualQuery } from './model';
import { DocumentModel } from 'shared/domain/document/documentModel';
import { debugLog } from 'shared/logger/debugLog';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useMountedRef } from 'hooks/useMountRef';

type DocumentsContext = {
  documents: DocumentModel[];
  loading: boolean;
  setQuery: Dispatch<SetStateAction<any>>;
};
const WithDocumentsContext = React.createContext<
  DocumentsContext | undefined
>(undefined);

const WithDocuments: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const mountedRef = useMountedRef();
  const [documents, setDocuments] = useState<DocumentModel[]>([]);
  const [query, setQuery] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const { getDocuments } = useGetDocuments(query);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.documentChannel);

    broadcast.onmessage = (
      event: Message<{
        items: DocumentModel[];
        query?: string;
        ids?: string[];
      }>
    ): void => {
      if (
        !mountedRef.current ||
        event.type !== DomainMessagesTypes.documents
      ) {
        return;
      }

      if (event.data && isEqualQuery(event.data.query, query)) {
        setDocuments(event.data.items);
        debugLog('WithDocuments documents', event.data.items);
        setLoading(false);
      }

      if (event.data && isEqualQuery(event.data.ids, query)) {
        setDocuments(event.data.items);
        setLoading(false);
      }
    };

    getDocuments(setLoading);

    return (): void => {
      broadcast.close();
    };
  }, [query, getDocuments, mountedRef]);

  const ctx: DocumentsContext = {
    documents,
    loading,
    setQuery,
  };

  return (
    <WithDocumentsContext.Provider value={ctx}>
      {children}
    </WithDocumentsContext.Provider>
  );
};

function useDocuments(): DocumentsContext {
  const context = React.useContext(WithDocumentsContext);
  if (context === undefined) {
    throw new Error(
      'useDocuments must be used within an DocumentsContext'
    );
  }
  return context;
}

const withDocuments =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithDocuments>
      <Component {...props} />
    </WithDocuments>
  );

export { useDocuments, withDocuments };
