import React, { useState, useRef, useCallback, ReactElement } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import styles from './styles';
import useOnClickOutside from 'hooks/clickOutside';
import fileDownload from 'helpers/fileDownload';
import { ActionsType } from './types';
import useIsMobile from '../../../hooks/useIsMobile';
import { DeleteIcon, DrawIcon, DownloadIcon, InfoIcon } from './icons';
import { useGalleryContext } from '.';
import { identitySelector } from './selectors';
import { useDispatch } from 'react-redux';
import { genericErrorToaster } from 'redux/actions/toasterActions';
type CustomEditComponentProps = {
  file: {
    title: string;
    description?: string | null | undefined;
    localId?: number;
  };
  close: () => void;
};
type Props = {
  className?: string;
  idx: number;
  actions: ActionsType;
  setDrawMode: (bool: boolean) => void;
  disabled: boolean;
  EditComponent?: (props: CustomEditComponentProps) => ReactElement;
  titleId?: string;
  PrefixedActions: ({
    disabledClass,
  }: {
    disabledClass: string;
  }) => ReactElement | null;
  SuffixedActions: ({
    disabledClass,
  }: {
    disabledClass: string;
  }) => ReactElement | null;
};

function drawableExtension(extension?: string): boolean {
  return (
    Boolean(extension) &&
    ['jpeg', 'jpg', 'png', 'webp'].includes(extension!)
  );
}

const Actions: React.FC<Props> = ({
  className,
  idx,
  actions,
  setDrawMode,
  disabled,
  PrefixedActions,
  SuffixedActions,
}) => {
  const dispatch = useDispatch();
  const [file] =
    useGalleryContext().useMainDisplayedFile(identitySelector);
  const isMobile = useIsMobile();
  const intl = useIntl();
  const [open, setOpen] = useState(false);
  const cs = styles({ isMobile });
  const ref = useRef(null);
  const { preview, drawings, hideActions, openEditComponent } =
    useGalleryContext();
  const canDraw =
    !preview &&
    file?.type?.includes('image') &&
    drawableExtension(file?.data?.extension);

  const handleClickOutside = useCallback((): void => {
    setOpen(false);
  }, []);

  useOnClickOutside(ref, handleClickOutside);

  const onDrawingClick = useCallback(() => {
    if (!canDraw) {
      return;
    }
    setDrawMode(true);
    setOpen(false);
  }, [setDrawMode, canDraw]);

  const REMOVE = intl.formatMessage({
    id: 'issue_preview_gallery_file_remove_label',
  });
  const DOWNLOAD = intl.formatMessage({
    id: 'issue_preview_gallery_file_download_label',
  });
  const EDIT_DESCRIPTION = intl.formatMessage({
    id: 'issue_preview_gallery_description_edit_label',
  });
  const DRAW = intl.formatMessage({
    id: 'issue_preview_gallery_drawing_label',
  });

  const handleDescriptionEdit = (): void => {
    if (preview || !file) {
      return;
    }

    openEditComponent();
    setOpen(false);
  };

  const handleRemoveFile = (): void => {
    if (preview) {
      return;
    }
    actions.removeFile(idx);
  };

  const toggleActionsMenu = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  if (disabled) {
    return null;
  }

  return isMobile ? (
    <div className={className ?? cs.actionsContainer} ref={ref}>
      <div className={cs.actionsDesktop} style={{ marginRight: 0 }}>
        <PrefixedActions disabledClass={cs.desktopIconDisabled} />
      </div>
      <div>
        <MoreVertIcon
          className={cs.actionsBtn}
          onClick={toggleActionsMenu}
        />
        <div className={cn(cs.dropdown, { [cs.dropdownOpen]: open })}>
          <ul className={cs.actions}>
            <li
              className={cn(!canDraw && cs.dropdownDisabled)}
              style={
                hideActions && hideActions.includes('DRAW')
                  ? { display: 'none' }
                  : undefined
              }
              onClick={onDrawingClick}
            >
              <DrawIcon />
              {DRAW}
            </li>
            <li
              className={cn(preview && cs.dropdownDisabled)}
              onClick={handleDescriptionEdit}
            >
              <InfoIcon />
              {EDIT_DESCRIPTION}
            </li>
            <li
              onClick={(): void => {
                if (!file) return;
                fileDownload(
                  drawings[idx]?.mergedImageUrl || file.downloadSrc,
                  file.title,
                  file.data?.extension
                ).catch(() => dispatch(genericErrorToaster));
              }}
            >
              <DownloadIcon />
              {DOWNLOAD}
            </li>
            <li
              className={cn(preview && cs.dropdownDisabled)}
              onClick={handleRemoveFile}
              style={
                hideActions && hideActions.includes('REMOVE')
                  ? { display: 'none' }
                  : undefined
              }
            >
              <DeleteIcon />
              {REMOVE}
            </li>
            <SuffixedActions disabledClass={cs.dropdownDisabled} />
          </ul>
        </div>
      </div>
    </div>
  ) : (
    <div className={cs.actionsDesktop}>
      <PrefixedActions disabledClass={cs.desktopIconDisabled} />
      <span
        className={cn(!canDraw && cs.desktopIconDisabled)}
        onClick={onDrawingClick}
        style={
          hideActions && hideActions.includes('DRAW')
            ? { display: 'none' }
            : undefined
        }
      >
        <DrawIcon />
      </span>
      <span
        className={cn(preview && cs.desktopIconDisabled)}
        onClick={handleDescriptionEdit}
      >
        <InfoIcon />
      </span>
      <span
        onClick={(): void => {
          if (!file) return;
          fileDownload(
            drawings[idx]?.mergedImageUrl || file.downloadSrc,
            file.title,
            file.data?.extension
          ).catch(() => dispatch(genericErrorToaster));
        }}
      >
        <DownloadIcon />
      </span>
      <span
        className={cn(preview && cs.desktopIconDisabled)}
        onClick={handleRemoveFile}
        style={
          hideActions && hideActions.includes('REMOVE')
            ? { display: 'none' }
            : undefined
        }
      >
        <DeleteIcon />
      </span>
      <SuffixedActions disabledClass={cs.desktopIconDisabled} />
    </div>
  );
};

export default Actions;
