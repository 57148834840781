import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { useCallback, useEffect, useMemo } from 'react';

type GetContract = {
  getContract: () => void;
};

export function useGetContract(
  id: string,
  withAutoCall?: boolean
): GetContract {
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const getContract = useCallback(() => {
    broadcast.postMessage({
      service: Services.CONTRACTS,
      method: ServiceMethods.GET_ONE,
      data: {
        id: id,
      },
    });
  }, [broadcast, id]);

  useEffect(() => {
    if (withAutoCall) {
      getContract();
    }
  }, [getContract, withAutoCall]);

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getContract,
  };
}
