import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startSiteDelete(
  siteId: string,
  projectId: string,
  uniqueId: string
): void {
  debugLog('startSiteDelete', siteId, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      siteId: string;
      projectId: string;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.SITES,
    method: ServiceMethods.REMOVE,
    data: {
      siteId,
      uniqueId,
      projectId,
    },
  });
  broadcast.close();
}
