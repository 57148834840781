import { ApiChannelMessage } from 'shared/domain/messages/message';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';

export function startProjectDelete(
  projectId: string,
  uniqueId: string
): void {
  debugLog('startProjectDelete', projectId, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{ projectId: string; uniqueId: string }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.PROJECTS,
    method: ServiceMethods.REMOVE,
    data: {
      projectId,
      uniqueId,
    },
  });
  broadcast.close();
}
