import { VisibleFieldsEditOutDto } from 'shared/dtos/out/visibleField';

export interface IVisibleFieldUploader {
  PUT: (url: string, data: any) => Promise<any>;
}

export interface UploadVisibleFieldChanges {
  (
    uploader: IVisibleFieldUploader,
    body: VisibleFieldsEditOutDto,
    url: string
  ): Promise<any>;
}

export function uploadVisibleFieldChanges(
  uploader: IVisibleFieldUploader,
  body: VisibleFieldsEditOutDto,
  url: string
): Promise<unknown> {
  return uploader.PUT(url, body);
}
