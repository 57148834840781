import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';

const IsOfflineContext = React.createContext<boolean | undefined>(
  undefined
);

export function useIsOffline(): boolean {
  const context = useContext(IsOfflineContext);
  if (context === undefined) {
    throw new Error(
      'useIsOffline must be used within IsOfflineContextProvider'
    );
  }

  return context;
}

export const WithIsOffline: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [isOfflineState, setIsOfflineState] = useState<boolean>(false);

  useEffect(() => {
    //TODO this is just a naive, mocked implementation that should be fixed in PT-3470 ~SM
    const setOffline = (): void => {
      setIsOfflineState(true);
    };
    const setOnline = (): void => {
      setIsOfflineState(false);
    };

    window.addEventListener('online', setOnline);
    window.addEventListener('offline', setOffline);

    return (): void => {
      window.removeEventListener('online', setOnline);
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <IsOfflineContext.Provider value={isOfflineState}>
      {children}
    </IsOfflineContext.Provider>
  );
};
