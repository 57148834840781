import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';

export enum EditableStandardEntityName {
  company = 'company',
  contract = 'contract',
  location = 'location',
  level = 'level',
  user = 'user',
  organization = 'organization',
}

export const editableStandardEntityNames = Object.values(
  EditableStandardEntityName
);

export enum NonEditableStandardEntityName {
  process = 'process',
  field = 'field',
}

export const nonEditableStandardEntityNames = Object.values(
  NonEditableStandardEntityName
);
export enum EditableFieldValuesEntityName {
  workTypes = IssueFieldNames.workTypes,
  hazardCategory = IssueFieldNames.hazardCategory,
  environmentalAspect = IssueFieldNames.environmentalAspect,
  proposedCorrectiveAction = IssueFieldNames.proposedCorrectiveAction,
}

export const editableFieldValuesEntityNames = Object.values(
  EditableFieldValuesEntityName
);

enum NonEditableFieldValuesEntityNames {
  impact = IssueFieldNames.impact,
  solutionMethod = IssueFieldNames.solutionMethod,
}
export type EntityName =
  | EditableStandardEntityName
  | EditableFieldValuesEntityName
  | NonEditableFieldValuesEntityNames
  | NonEditableStandardEntityName;

export const nonEditableFieldValuesEntityNames = Object.values(
  NonEditableFieldValuesEntityNames
);

export type CreatableModelName = EditableFieldValuesEntityName;

export function toEntityName(value?: string): string | undefined {
  switch (value) {
    case IssueFieldNames.subcontractors:
    case IssueFieldNames.executedByCompanies:
      return EditableStandardEntityName.company;
    case IssueFieldNames.contractNumbers:
      return EditableStandardEntityName.contract;
    case IssueFieldNames.assignee:
    case IssueFieldNames.executor:
      return EditableStandardEntityName.user;
    case IssueFieldNames.level:
      return EditableStandardEntityName.level;
    case IssueFieldNames.site:
      return EditableStandardEntityName.location;
    case IssueFieldNames.workTypes:
      // @ts-ignore TODO MUI5
      return EditableFieldValuesEntityName.workTypes;
    case IssueFieldNames.hazardCategory:
      // @ts-ignore TODO MUI5
      return EditableFieldValuesEntityName.hazardCategory;
    case IssueFieldNames.environmentalAspect:
      // @ts-ignore TODO MUI5
      return EditableFieldValuesEntityName.environmentalAspect;
    case IssueFieldNames.proposedCorrectiveAction:
      // @ts-ignore TODO MUI5
      return EditableFieldValuesEntityName.proposedCorrectiveAction;
    default:
      return undefined;
  }
}
