import { ReactElement, useMemo, memo } from 'react';

import { Divider } from '@mui/material';
import {
  useProtocolItemStatus,
  withProtocolItemStatus,
} from 'components/common/withProtocolItemStatus';
import { MemoChecklistItemPreview } from 'components/template/ChecklistItemPreview';
import { ComplianceCheck } from '../ComplianceCheck';
import {
  MemoProtocolItemResultIcon,
  ProtocolItemErrorIcon,
} from '../ComplianceCheckResult/Icon';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { findError } from './model';
import { ProtocolItemProps } from './types';

function ProtocolItem({
  checklistItem,
  index,
  classes,
}: ProtocolItemProps): ReactElement {
  const { status } = useProtocolItemStatus();
  const { errors } = useInputForm();

  const error = findError(errors, checklistItem._id);

  const CheckIcon = useMemo(() => {
    return error
      ? (): ReactElement => <ProtocolItemErrorIcon />
      : (): ReactElement => <MemoProtocolItemResultIcon status={status} />;
  }, [status, error]);

  return (
    <MemoChecklistItemPreview
      key={checklistItem._id}
      checklistItem={checklistItem}
      index={index}
      CheckIcon={CheckIcon}
      error={error}
      classes={classes}
      prefix={''}
      disableDocumentInternalUrl
    >
      <div>
        <Divider />
        <ComplianceCheck checklistItemId={checklistItem._id} />
      </div>
    </MemoChecklistItemPreview>
  );
}
//@ts-ignore
export const MemoProtocolItem = withProtocolItemStatus(memo(ProtocolItem));
