import { add as addRequest } from 'serviceWorker/db/httpRequests';
import {
  HttpRequestModel,
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { debugLog } from 'shared/logger/debugLog';
import { OrganizationCreateModel } from 'shared/domain/organization/types/model';
import { AddOrganizationUseCase } from 'shared/domain/organization/types/useCases';
import { OrganizationHttpCreateRequestModel } from '../httpQueue/organization/types';
import { organizationCreateModelToCreateOutDto } from 'shared/domain/organization/mapping/toDto';

export class OrganizationSaver implements AddOrganizationUseCase {
  constructor(
    private addRequest: (
      request: OrganizationHttpCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    organizationCreateModel: OrganizationCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddOrganizationUseCase', organizationCreateModel);
    const organizationCreateOutDto = organizationCreateModelToCreateOutDto(
      organizationCreateModel
    );

    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      url: `/client`,
      data: {
        organizationCreateOutDto,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.organization,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const organizationSaver = new OrganizationSaver(addRequest);
