import React, { ReactElement } from 'react';

import { useIntl } from 'react-intl';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import classNames from 'classnames';

import { MemoChecklistWizard } from 'components/template/ChecklistWizard';

import { useStyles } from './style';

import { ProcessType } from 'shared/types/form';
import { TemplateWizardPresentationalProps } from './types';
import {
  GENERAL_NORM_DOM_ELEMENT_ID,
  PROCESSES_DOM_ELEMENT_ID,
  SUMMARY_DOM_ELEMENT_ID,
} from './helpers';
import { GraphicUploader } from 'components/common/graphicUploader';
import useIsMobile from 'hooks/useIsMobile';
import { SubmitButtons } from './submitButtons';
import { MemoProcessIcon } from 'components/common/ProcessIcon';

const MemoTextField = React.memo(TextField);
function toReactProcessButton() {
  return function ProcessButton(process: ProcessType): ReactElement {
    return (
      <ToggleButton
        disableRipple
        key={process._id}
        className='processButton'
        value={process._id}
      >
        <MemoProcessIcon
          color={process.color}
          code={process.code}
          className='processIcon'
        />
        <span className='toggleButtonText'>{process.label}</span>
      </ToggleButton>
    );
  };
}

function Presentational(
  props: TemplateWizardPresentationalProps
): ReactElement {
  const isMobile = useIsMobile();
  const intl = useIntl();
  const classes = useStyles({ isMobile });

  const {
    title,
    defaultValues,
    process,
    processes,
    checklist,
    errors,
    isUploading,
    isPublishing,
    handleSaveDraft,
    handlePublish,
    handleCancel,
    handleProcessChange,
    handleTemplateSummaryChange,
    handleGeneralNormDescriptionChange,
    canAdd,
    handleChecklistItemAdd,
    handleChecklistItemRemove,
    handleChecklistItemSummaryChange,
    handleChecklistItemNormDescriptionChange,
    graphicUploader,
  } = props;

  const processButtons = React.useMemo(
    () => processes.map(toReactProcessButton()),
    [processes]
  );

  return (
    <>
      <form className={classes.root} noValidate autoComplete='off'>
        <h1 className={classes.h1Margin}>{intl.formatMessage(title)}</h1>

        <div className={classes.contentBox}>
          <MemoTextField
            multiline
            className={classNames(
              classes.standardBorderRadius,
              classes.fullWidth,
              classes.summaryMargin
            )}
            id={`${SUMMARY_DOM_ELEMENT_ID}`}
            label={intl.formatMessage({
              id: 'inspection_template_wizard_summary',
            })}
            variant='outlined'
            size='small'
            onChange={handleTemplateSummaryChange}
            required
            error={Boolean(errors.summary)}
            helperText={
              errors.summary &&
              intl.formatMessage(
                { id: errors.summary.helperText.id },
                errors.summary.values
              )
            }
            defaultValue={defaultValues.summary}
          />
          <ToggleButtonGroup
            id={`${PROCESSES_DOM_ELEMENT_ID}`}
            color='primary'
            aria-label='outlined primary button group'
            className={classNames(
              classes.processGroup,
              errors.process && 'error'
            )}
            value={process}
            exclusive
            onChange={handleProcessChange}
            orientation='horizontal'
          >
            {processButtons}
          </ToggleButtonGroup>

          {errors.process && (
            <p className='MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-marginDense'>
              {intl.formatMessage({ id: errors.process.helperText.id })}
            </p>
          )}
        </div>
        <Divider className={classes.divider} />
        <h2>
          {intl.formatMessage({
            id: 'inspection_template_wizard_general_applied_standards',
          })}
        </h2>
        <h4 className={classes.generalAppliedStandardsDescriptionMargin}>
          {intl.formatMessage({
            id: 'inspection_template_wizard_general_applied_standards_description',
          })}
        </h4>

        <div className={classes.contentBox}>
          <MemoTextField
            multiline
            id={`${GENERAL_NORM_DOM_ELEMENT_ID}`}
            className={classNames(
              classes.standardBorderRadius,
              classes.fullWidth,
              classes.standardDescriptionMargin
            )}
            label={intl.formatMessage({
              id: 'inspection_template_wizard_standard_description',
            })}
            variant='outlined'
            size='small'
            onChange={handleGeneralNormDescriptionChange}
            error={Boolean(errors.description)}
            helperText={
              errors.description &&
              intl.formatMessage(
                { id: errors.description.helperText.id },
                errors.description.values
              )
            }
            defaultValue={defaultValues.generalNorm.description}
          />
          <GraphicUploader
            {...graphicUploader}
            classes={{
              buttonContainer: classes.generalNormGraphicUploader,
            }}
          />
        </div>
        <Divider className={classes.divider} />

        <h2>
          {intl.formatMessage({
            id: 'inspection_template_wizard_checklist',
          })}
        </h2>
        <h4>
          {intl.formatMessage({
            id: 'inspection_template_wizard_checklist_description',
          })}
        </h4>

        <Divider className={classes.divider} />
        <MemoChecklistWizard
          canAdd={canAdd}
          checklist={checklist}
          defaultValues={defaultValues.checklist}
          handleChecklistItemAdd={handleChecklistItemAdd}
          handleChecklistItemRemove={handleChecklistItemRemove}
          handleChecklistItemSummaryChange={
            handleChecklistItemSummaryChange
          }
          handleChecklistItemNormDescriptionChange={
            handleChecklistItemNormDescriptionChange
          }
        />
        <div className={classes.footerBlankSpaceMargin} />
      </form>

      <div
        className={classNames(
          classes.issueTemplateSubmitFooter,
          classes.castShadow
        )}
      >
        <Divider className={classes.divider} />
        <div className={classes.footerPadding}>
          <Button
            onClick={handleCancel}
            disabled={isUploading || isPublishing}
          >
            {intl.formatMessage({ id: 'general_cancel' })}
          </Button>
          <div>
            <SubmitButtons
              onSaveDraft={handleSaveDraft}
              uploading={isUploading}
              publishing={isPublishing}
              onPublish={handlePublish}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export const MemoPresentational = React.memo(Presentational);
