import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
} from 'react';

export const ChartDetailsContext = createContext(false);

export function ChartDetailsProvider({
  children,
  isDetailsView,
}: PropsWithChildren<{ isDetailsView: boolean }>): ReactElement {
  return (
    <ChartDetailsContext.Provider value={isDetailsView}>
      {children}
    </ChartDetailsContext.Provider>
  );
}
