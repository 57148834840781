import React from 'react';
import Dialog from '../Dialog/common/Dialog';
import Feedback from './Feedback';
import { useIntl } from 'react-intl';

type FeedbackDialogProps = {
  onClose: () => void;
};

const FeedbackDialog = ({
  onClose,
}: FeedbackDialogProps): React.ReactElement => {
  const feedbackRef = React.useRef<React.ReactElement>();
  const intl = useIntl();

  const handleClose = (): void => {
    //@ts-ignore
    const onFeedbackClose = feedbackRef?.current?.handleClose;

    onFeedbackClose();
  };

  return (
    <Dialog
      controllersOff
      open={true}
      fullWidth={true}
      catchOnCancel={false}
      submitContained={true}
      onClose={handleClose}
      title={intl.formatMessage({ id: 'feedback_title' })}
      description={<Feedback ref={feedbackRef} onClose={onClose} />}
    />
  );
};

export default FeedbackDialog;
