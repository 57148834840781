import { Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import { minMaxRand } from 'shared/utils/number';

export function DocumentationBrowserSkeleton(): ReactElement {
  return (
    <div
      style={{
        height: '240px',
        overflowY: 'hidden',
      }}
    >
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '1rem',
          alignItems: 'center',
          height: '40px',
          paddingLeft: '1rem',
        }}
      >
        <Skeleton variant='rectangular' width='24px' height='24px' />
        <Skeleton
          variant='rectangular'
          width={`${minMaxRand(36, 80)}%`}
          height='24px'
        />
      </div>
    </div>
  );
}
