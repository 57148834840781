import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debuglog } from 'util';

type GetProject = {
  getProject: () => void;
  setId: (id: string) => void;
};

export function useGetProject(
  paramId?: string,
  withAutoCall?: boolean
): GetProject {
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );
  const [id, setId] = useState(paramId);

  const getProject = useCallback(() => {
    if (id) {
      broadcastGetOneProject(broadcast, id);
    }
  }, [broadcast, id]);

  useEffect(() => {
    if (withAutoCall) {
      getProject();
    }
  }, [getProject, withAutoCall]);

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getProject,
    setId,
  };
}

function broadcastGetOneProject(
  broadcast: BroadcastChannel,
  id: string
): void {
  debuglog('send broadcastGetOneProject');
  broadcast.postMessage({
    service: Services.PROJECTS,
    method: ServiceMethods.GET_ONE,
    data: {
      id: id,
    },
  });
}
