import { LabelledEntity } from 'shared/types/commonView';
import { anyIdToLabelledEntity } from './anyIdToLabelledEntity';

export function idsToLabelledEntities(
  ids: string[],
  labelledEntities: LabelledEntity[]
): LabelledEntity[] {
  return ids
    .map((id) => {
      const foundEntity = anyIdToLabelledEntity(id, labelledEntities);
      return foundEntity
        ? {
            _id: foundEntity._id,
            label: foundEntity.label,
          }
        : undefined;
    })
    .filter((x): x is LabelledEntity => Boolean(x));
}
