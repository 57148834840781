import { useSiteChannelListener } from 'components/broadcastChannelListeners/withSiteChannelListener';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { startSiteDelete } from 'shared/domain/site/startSiteDelete';
import { startSiteRestore } from 'shared/domain/site/startSiteRestore';
import { projectIdSelector } from 'helpers/misc';
import { useGetAllSites } from 'hooks/useGetAllSites';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { LabelledEntity } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { useEntityDataSubscription } from '../../common/useEntityDataSubscription';
import { setModelsAfterDeletion, setModelsAfterRestore } from '../common';
import { SitesContextType, SitesResponse } from './types';

const initialSites = { items: [], total: 0 };

const SitesContext = React.createContext<SitesContextType | undefined>(
  undefined
);

const WithSites: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const createDialog = useDialog();
  const [sites, setSites] = useState<SitesResponse>(initialSites);
  const [loading, setLoading] = useState<boolean>(true);
  const { subscribe } = useSiteChannelListener();
  const projectId = useSelector(projectIdSelector);

  const { getAll: getAllSites } = useGetAllSites();
  const { resync } = useEntityDataSubscription({
    subscribe,
    getAll: getAllSites,
    setEntity: setSites,
    setLoading,
    entityName: 'sites',
  });

  const deleteSite = useCallback(
    (site: LabelledEntity) => {
      createDialog({
        title: (
          <FormattedMessage id='dialog_confirmation_delete_site_title' />
        ),
        description: (
          <FormattedMessage
            id='dialog_confirmation_delete_site_description'
            values={{ siteName: site.label }}
          />
        ),
        customControllerLabels: ['general_cancel', 'general_archive'],
      }).then(() => {
        startSiteDelete(site._id, projectId, createUniqueId());
        setSites((prev) => setModelsAfterDeletion(prev, site));
      });
    },
    [createDialog, projectId]
  );

  const restoreSite = useCallback(
    (site: LabelledEntity) => {
      startSiteRestore(site._id, projectId, createUniqueId());
      setSites((prev) => setModelsAfterRestore(prev, site));
    },
    [projectId]
  );

  const ctx: SitesContextType = useMemo(
    () => ({
      sites: sites,
      loading: loading,
      resync: resync,
      deleteSite,
      restoreSite,
    }),
    [sites, loading, resync, deleteSite, restoreSite]
  );

  return (
    <SitesContext.Provider value={ctx}>{children}</SitesContext.Provider>
  );
};

const withSites =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithSites>
      <Component {...props} />
    </WithSites>
  );

function useSites(): SitesContextType {
  const context = React.useContext(SitesContext);
  if (context === undefined) {
    throw new Error('useSites must be used within a SitesContextProvider');
  }
  return context;
}

export { WithSites, useSites, withSites };
