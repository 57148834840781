import { Box, Grid } from '@mui/material';
import {
  MemoStatefulAutocompleteWithText,
  MemoStatefulMultiselect,
  MemoStatefulSingleSelect,
  MemoStatefulTextField,
} from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import React from 'react';
import { UserFieldsPresentationalProps } from './types';

export function UserFieldsPresentational({
  labelFieldProps,
  emailFieldProps,
  roleFieldProps,
  sitesFieldProps,
  processesFieldProps,
}: UserFieldsPresentationalProps): React.ReactElement {
  return (
    <Box>
      <Grid container sx={{ m: 2 }} columnSpacing={2} rowSpacing={0}>
        <Grid item xs={11} lg={5}>
          <MemoStatefulTextField {...emailFieldProps} />
        </Grid>
        <Grid item xs={11} lg={5}>
          <MemoStatefulAutocompleteWithText {...labelFieldProps} />
        </Grid>

        <Grid item xs={11} lg={5}>
          <MemoStatefulSingleSelect {...roleFieldProps} />
        </Grid>
        <Grid item xs={11} lg={5}>
          <MemoStatefulMultiselect {...sitesFieldProps} />
        </Grid>
        <Grid item xs={11} lg={5}>
          <MemoStatefulMultiselect {...processesFieldProps} />
        </Grid>
      </Grid>
    </Box>
  );
}
