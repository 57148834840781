import { AddDocumentationUseCase } from 'shared/domain/documentation/addDocumentation';
import { DocumentationCreateModel } from 'shared/domain/documentation/documentationModel';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { DocumentationLocalInsertEntity } from 'serviceWorker/repository/documentation/entity';
import { documentationCreateModelToLocalInsertEntity } from 'serviceWorker/repository/documentation/mappings';
import { broadcastCreatedDocumentation } from 'serviceWorker/broadcasts/documentations';
import { addLocal as addDocumentation } from 'serviceWorker/db/documentations';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';

class DocumentationSaver implements AddDocumentationUseCase {
  constructor(
    private addDocumentation: (
      data: DocumentationLocalInsertEntity
    ) => Promise<number>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDocumentationSaved: (
      documentation: [number],
      uniqueId: string
    ) => void
  ) {}
  async execute(
    documentationCreateModel: DocumentationCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddDocumentationUseCase', documentationCreateModel);
    const documentationToAdd: DocumentationLocalInsertEntity =
      documentationCreateModelToLocalInsertEntity(
        documentationCreateModel
      );
    const savedDocumentation =
      await this.addDocumentation(documentationToAdd);

    this.broadcastDocumentationSaved([savedDocumentation], uniqueId);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      data: {
        localId: savedDocumentation,
        projectId: currentProject?._id,
      },
      entityType: HttpRequestModelType.documentation,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const documenationSaver = new DocumentationSaver(
  addDocumentation,
  addRequest,
  getCurrentProject,
  broadcastCreatedDocumentation
);
