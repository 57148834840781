import { Action } from 'history';
import { useCreateStore } from 'hooks/createStore';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { noop } from 'shared/utils/function';
import {
  isRelevantLocationChange,
  canReturnToLocation,
  buildUrl,
  resolveTitle,
} from './model';
import { Backtrack, Location } from './types';

const BacktrackContext = React.createContext<Backtrack | undefined>(
  undefined
);

const WithBacktrack: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const history = useHistory();

  const titleStore = useCreateStore<string | undefined>(undefined);
  const [newLocation, addLocation] = useState<Location | undefined>(
    undefined
  );
  const locationsRef = useRef<Location[]>([
    { pathname: '/issue', state: undefined },
  ]);

  const backHandler = useCallback(() => {
    const currentLocation = locationsRef.current.pop();
    const prevLocation = locationsRef.current.pop();
    if (!prevLocation) {
      history.push('/issue');
    } else if (canReturnToLocation(prevLocation)) {
      history.push(buildUrl(prevLocation), prevLocation.state);
    } else {
      backHandler();
    }
  }, [history]);

  const pushLocation = useCallback(
    (location) => {
      if (!isRelevantLocationChange(location, locationsRef.current)) {
        return;
      }
      locationsRef.current.push(location);
      titleStore.set(
        resolveTitle(
          locationsRef.current.slice(-1)[0],
          locationsRef.current
        )
      );
    },
    [titleStore]
  );

  const replaceLocation = useCallback(
    (location: Location) => {
      if (!isRelevantLocationChange(location, locationsRef.current)) {
        return;
      }
      locationsRef.current[locationsRef.current.length - 1] = location;
      titleStore.set(
        resolveTitle(
          locationsRef.current.slice(-1)[0],
          locationsRef.current
        )
      );
    },
    [titleStore]
  );

  const actionHandlers = useMemo<
    Record<Action, (location: Location) => void>
  >(
    () => ({
      POP: noop,
      PUSH: pushLocation,
      REPLACE: replaceLocation,
    }),
    [pushLocation, replaceLocation]
  );

  useEffect(() => {
    let mounted = true;
    const locationUnsubscribe = history.listen((location, action) => {
      if (!mounted) {
        return;
      }

      actionHandlers[action](location);
    });

    return (): void => {
      mounted = false;
      locationUnsubscribe();
    };
  }, [history, pushLocation, replaceLocation, actionHandlers]);

  useEffect(() => {
    if (!newLocation) {
      return;
    }
    locationsRef.current.push(newLocation);
    titleStore.set(
      resolveTitle(locationsRef.current.slice(-1)[0], locationsRef.current)
    );
  }, [newLocation, pushLocation, titleStore]);

  const ctx: Backtrack = useMemo(
    () => ({
      titleStore,
      back: backHandler,
      pushLocation: addLocation,
    }),
    [backHandler, titleStore]
  );

  return (
    <BacktrackContext.Provider value={ctx}>
      {children}
    </BacktrackContext.Provider>
  );
};

const withBacktrack =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithBacktrack>
      <Component {...props} />
    </WithBacktrack>
  );

function useBacktrack(): Backtrack {
  const context = React.useContext(BacktrackContext);
  if (context === undefined) {
    throw new Error(
      'useBacktrack must be used within a BacktrackContextProvider'
    );
  }
  return context;
}

export { WithBacktrack, useBacktrack };
