import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';
import { useGetProject } from 'hooks/useGetProject';
import { modelToSingleView } from './model';
import { ProjectContextType, ProjectOnSingleView } from './types';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';

const ProjectContext = React.createContext<ProjectContextType | undefined>(
  undefined
);

const WithProject: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const [project, setProject] = useState<
    ProjectOnSingleView | undefined
  >();

  const [projectId, setProjectId] = useState<string | undefined>();

  const [loading, setLoading] = useState<boolean>(true);

  const { setId: setGetProjectId } = useGetProject(projectId, true);

  useEffect(() => {
    if (!projectId) {
      return;
    }
    const broadcast = new BroadcastChannel(ChannelNames.projectChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.project &&
        (event.data._id === projectId || event.data.localId === projectId)
      ) {
        debugLog('broadcast close received project');
        broadcast.close();
        setProject(modelToSingleView(event.data));
        setLoading(false);
      }

      if (
        event.type === DomainMessagesTypes.project &&
        event.error &&
        event.error.id === projectId
      ) {
        debugLog('project reception ERROR', event);
      }
    };

    debugLog('broadcast getProject listening');

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    setGetProjectId(projectId);

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, [setGetProjectId, projectId]);

  const ctx = {
    project,
    loading,
    setId: setProjectId,
  };

  return (
    <ProjectContext.Provider value={ctx}>
      {children}
    </ProjectContext.Provider>
  );
};

const withProject =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithProject>
      <Component {...props} />
    </WithProject>
  );

function useProject(): ProjectContextType {
  const context = React.useContext(ProjectContext);
  if (context === undefined) {
    throw new Error(
      'useProject must be used within a ProjectContextProvider'
    );
  }
  return context;
}

export { WithProject, withProject, useProject };
