import {
  AbortUploadCustomEventDetail,
  FileServicesUnion,
} from 'shared/domain/messages/httpQueue/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';

const abortUpload = (e: {
  localId: number;
  type: FileServicesUnion;
}): void => {
  self.dispatchEvent(
    new CustomEvent<AbortUploadCustomEventDetail>(
      RepositoryMessagesTypes.abortUpload,
      {
        detail: { localId: e.localId, type: e.type },
      }
    )
  );
};

export default { abortUpload };
