import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { CompanyTable } from 'components/company/Table';
import {
  useCompanies,
  withCompanies,
} from 'components/dataProviders/withCompanies';
import { withFinancialData } from 'components/dataProviders/withFinancialData';
import { withSites } from 'components/dataProviders/withSites';
import { MemoTableAnchor } from 'components/tableAnchor';
import { Permission, validateCreatePermission } from 'helpers/permission';
import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { projectDataSelector } from 'redux/selectors/project';
import { toUserRole } from 'redux/selectors/role';
import { makeSearchInput } from 'components/common/SearchInput';
import { EntityLabel } from 'components/common/SearchInput/types';
import { useIsOffline } from 'components/common/withIsOffline';
import { useInitialSearchEffect } from '../../../hooks/search/useInitialSearch';
import { useSearchUpdate } from '../../../hooks/search/useSearchUpdate';
import { CREATE_COMPANY_PERMISSIONS } from 'setup/permissions';

const CompanySearchInput = makeSearchInput(EntityLabel.company);

const header = {
  id: 'create-company-button',
  textId: 'button_create_company',
  dataQaId: 'create-company-button',
};

function Companies(): ReactElement {
  const { loading, setSearchPhrase } = useCompanies();
  const history = useHistory();
  const isOffline = useIsOffline();
  const userRole = useSelector(toUserRole);
  const { _id: projectId } = useSelector(projectDataSelector);

  useSearchUpdate(EntityLabel.company, setSearchPhrase);
  useInitialSearchEffect(projectId, EntityLabel.company, setSearchPhrase);

  const canCreate = useCallback((): Permission => {
    return validateCreatePermission(
      CREATE_COMPANY_PERMISSIONS[userRole],
      isOffline
    );
  }, [userRole, isOffline]);

  const companyCreation = useCallback(() => {
    if (canCreate().permission) {
      history.push('/create-company');
    }
  }, [canCreate, history]);

  return (
    <MemoTableAnchor
      canCreate={canCreate}
      creationAction={companyCreation}
      TableComponent={CompanyTable}
      loading={loading}
      headerWithCreation={header}
      SearchInput={CompanySearchInput}
    />
  );
}

export const MemoCompanies = React.memo(
  withFetcherReady(
    withProcessesData(
      withSites(withFinancialData(withCompanies(Companies)))
    )
  )
);
