import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    plus: {
      height: '24px',
      width: '24px',
      display: 'flex',
      backgroundColor: 'black',
      color: 'white',
      fontSize: '1rem !important',
      lineHeight: '22px',
      textAlign: 'center',
      borderRadius: '50%',
      fontSmooth: 'never',
      justifyContent: 'center',
      alignItems: 'center',
      '& > .MuiSvgIcon-root': {
        width: '16px',
        height: '16px',
      },
    },
    disabled: {
      backgroundColor: 'rgba(0,0,0,.26)',
    },
  })
);
