import {
  Autocomplete,
  Box,
  FilterOptionsState,
  FormHelperText,
} from '@mui/material';
import { OpenCreateLevelDialog } from 'components/dataCreationForms/level/types';
import { OpenCreateDialog } from 'components/dataCreationForms/types';
import { Disabled } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper/types';
import React, {
  ReactElement,
  Ref,
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import {
  EditableFieldValuesEntityName,
  EditableStandardEntityName,
} from 'shared/domain/fieldValue/fields';
import {
  Identificable,
  Labelled,
  LabelledEntity,
} from 'shared/types/commonView';
import {
  getAutocompleteRenderInput,
  getFreeSoloDialogAdditionalParams,
  getOnMultipleFreeSoloChangeHandler,
  getSiteIdFromForm,
  isOptionEqualToValue,
  multipleFreeSoloFilterOptions,
  unifiedGetOptionKey,
  unifiedGetOptionLabel,
} from '../common';
import {
  FormValuesGetter,
  FreeSoloMultiAutocompleteValue,
} from '../types';

type MultiAutocompleteProps = {
  available: LabelledEntity[];
  localisedLabel: string;
  required: boolean;
  disabled?: Disabled;
  handleChange: (_: any, value: FreeSoloMultiAutocompleteValue[]) => void;
  error?: string;
  ref: Ref<HTMLDivElement>;
  value: LabelledEntity[] | null;
  id: string;
  disableClearable?: Boolean;
  helperText?: string;
  dense: boolean;
  reserveSpaceForHelperText: boolean;
  getOptionLabel?: (option: any) => string;
  DialogComponent?: ReactElement;
  useProvider?: () => {
    openDialog: OpenCreateDialog | OpenCreateLevelDialog;
  };
  entityName?:
    | string
    | EditableStandardEntityName
    | EditableFieldValuesEntityName;
  formValuesGetter?: FormValuesGetter;
};

const MultiAutocomplete = (
  {
    available,
    value,
    handleChange,
    disabled,
    helperText,
    id,
    required,
    localisedLabel,
    error,
    disableClearable,
    useProvider,
    DialogComponent,
    dense,
    reserveSpaceForHelperText,
    entityName,
    formValuesGetter,
  }: MultiAutocompleteProps,
  ref: Ref<HTMLDivElement>
): React.ReactElement => {
  const intl = useIntl();
  const multiAutocompleteStandardHandleChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      values: FreeSoloMultiAutocompleteValue[]
    ) => {
      handleChange(event, values);
    },
    [handleChange]
  );
  let onChange = multiAutocompleteStandardHandleChange;
  let filterOptions;
  let freeSolo = false;
  let siteId = '';
  // TODO: very nasty workaround until we disable fields properly on form level based on dependsOn values
  // we don't want it on use documentation as map screen
  if (
    entityName === EditableStandardEntityName.level &&
    window.location.href.indexOf('documentation') === -1
  ) {
    siteId = getSiteIdFromForm(formValuesGetter);
    if (!siteId) {
      disabled = true;
      helperText = intl.formatMessage(
        { id: 'tooltip_issue_form_depended_not_filled' },
        { fieldLabel: intl.formatMessage({ id: 'primaryField_Site' }) }
      );
    }
  }
  if (useProvider) {
    const { openDialog } = useProvider();
    freeSolo = true;
    filterOptions = useCallback(
      (
        options: FreeSoloMultiAutocompleteValue[],
        params: FilterOptionsState<
          Identificable<string> &
            Labelled & { inputValue?: string | undefined } & {
              inputValue?: string | undefined;
            }
        >
      ) => multipleFreeSoloFilterOptions(options, params, intl),
      [intl]
    );
    const additionalParams = getFreeSoloDialogAdditionalParams(siteId);

    onChange = getOnMultipleFreeSoloChangeHandler(
      openDialog,
      handleChange,
      additionalParams
    );
  }

  const inputRenderer = getAutocompleteRenderInput({
    required,
    disabled: Boolean(disabled),
    error,
    label: localisedLabel,
    id: `${id}-textField`,
    placeholder: '',
    dense,
  });

  return (
    <Box>
      <Autocomplete
        options={available}
        multiple
        disableCloseOnSelect={true}
        disabled={Boolean(disabled)}
        value={value || []}
        id={`${id}-multiAutocomplete`}
        isOptionEqualToValue={isOptionEqualToValue}
        ref={ref}
        disableClearable={!!disableClearable}
        getOptionLabel={unifiedGetOptionLabel}
        renderInput={inputRenderer}
        getOptionKey={unifiedGetOptionKey}
        freeSolo={freeSolo}
        clearOnBlur={true}
        // @ts-ignore
        // option added after adding and hitting enter directly in input is indeed string
        // (when reason is "createOption", not "selectOption").
        // "selectOption" reason occurs when "Add something is clicked")
        onChange={onChange}
        filterOptions={filterOptions}
      />
      {DialogComponent}
      <FormHelperText>
        {helperText || (reserveSpaceForHelperText && ' ')}
      </FormHelperText>
    </Box>
  );
};

const ForwardedMultiAutoComplete = forwardRef<
  HTMLDivElement,
  MultiAutocompleteProps
>(
  MultiAutocomplete
) as React.ForwardRefExoticComponent<MultiAutocompleteProps>;

export const MemoMultiAutocomplete = memo(ForwardedMultiAutoComplete);
