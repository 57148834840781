import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { OrganizationEditModel } from './types/model';

export function startOrganizationEdit(
  organization: OrganizationEditModel,
  uniqueId: string
): void {
  debugLog('startOrganizationEdit', organization, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      organizationEditModel: OrganizationEditModel;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.ORGANIZATIONS,
    method: ServiceMethods.EDIT,
    data: {
      organizationEditModel: organization,
      uniqueId,
    },
  });
  broadcast.close();
}
