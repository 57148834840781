import {
  FormFieldProps,
  MultiChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import React, { useMemo } from 'react';
import { ContractFormPresentational } from './presentational';
import { ContractFormProps } from './types';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import { ContractOnView } from 'shared/domain/contract/types/view';

function ContractForm({
  companies,
  isDialog,
}: ContractFormProps): React.ReactElement {
  const labelFieldProps: FormFieldProps<ContractOnView> = useMemo(
    () => ({
      formKey: 'label',
      required: true,
      labelId: 'inspection_contract_number',
      fieldName: 'text-field-contract-number',
      localStorageKey: 'contract-number',
      reserveSpaceForHelperText: !isDialog,
      dense: true,
      'data-qa': 'contract_number_field',
    }),
    [isDialog]
  );

  const partiesFieldProps: MultiChoiceFormFieldProps<ContractOnView> =
    useMemo(
      () => ({
        formKey: 'parties',
        required: true,
        labelId: 'contract_parties',
        fieldName: 'multiselect-parties',
        available: companies,
        reserveSpaceForHelperText: !isDialog,
        dense: true,
        entityName: EditableStandardEntityName.company,
        'data-qa': 'contract_parties_field',
      }),
      [isDialog, companies]
    );

  return (
    <ContractFormPresentational
      labelFieldProps={labelFieldProps}
      partiesFieldProps={partiesFieldProps}
    />
  );
}

export const MemoContractForm = React.memo(ContractForm);
