import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
import { CONFIG_TYPE } from './types';
type ThumbnailSize = NonNullable<CONFIG_TYPE['thumbnailSize']>;

export const DEFAULT_THUMBNAIL_CARD_SIZE = 182;
const thumbnailCardTitleSize = 40;
//@ts-ignore - ThumbnailSize is expected to be Object by makeStyles.d.ts but is used as a number here.
const styles = makeStyles<DefaultTheme, ThumbnailSize, string>({
  image: {
    cursor: 'pointer',
  },
  video: (thumbnailSize: number) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `${thumbnailSize}px`,
    height: `${thumbnailSize}px`,
    backgroundColor: 'rgb(242, 242, 242)',
  }),

  pdf: (thumbnailSize: number) => ({
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: `${thumbnailSize}px`,
    height: `${thumbnailSize}px`,
    backgroundColor: 'rgb(242, 242, 242)',
  }),
  FileName: {
    flex: 5,
  },

  field: {
    '& .MuiInputBase-input': {
      padding: '0px !important',
      fontSize: '0.875rem',
    },
    '& .MuiInputBase-root': {
      padding: '0px',
      color: '#000000',
    },
    borderColor: 'transparent',
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '&input::-webkit-outer-spin-button, input::-webkit-inner-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0,
        padding: '6px 14px',
      },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },

  ImageListElem: {
    backgroundColor: '#F2F2F2',
    borderRadius: '5px',
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '0.875rem',
    margin: '0 0 10px',
  },

  delete: {
    cursor: 'pointer',
    border: 'none',
  },

  fileInput: {
    display: 'none',
  },

  buttonContainer: {
    width: '100%',
  },

  buttonBox: {
    flex: '0 1 auto',
    marginLeft: '16px',
  },

  dropFilesPrompt: {
    flex: '1 1 fit-content',
    margin: '12px 0 12px 16px',
  },
  buttonSubtext: {
    fontSize: '0.625rem',
    margin: 0,
    padding: 0,
  },

  thumbContainer: (thumbnailSize: number) => ({
    display: 'grid',
    gridTemplateColumns: `repeat(auto-fill, ${thumbnailSize}px)`,
    gap: '16px',
    width: '100%',
    marginBottom: '8px',
    marginTop: '8px',
  }),

  thumbnailCardLoading: (thumbnailSize: number) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    width: `${thumbnailSize}px`,
    height: `${thumbnailSize}px`,
  }),

  thumbnailCard: (thumbnailSize: number) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #DADADA',
    borderRadius: '5px',
    width: `${thumbnailSize}px`,
    height: `${thumbnailSize}px`,
    boxSizing: 'border-box',
    overflow: 'hidden',
    backgroundColor: '#FFFFFF',
    '& .hiddenOpacity': {
      opacity: 0,
    },
    '& > .thumbnail': {
      height: `calc(100% - ${thumbnailCardTitleSize}px)`,
    },
    '& > .thumbnail.spinner': {
      height: `100%`,
    },
    '& > .description': {
      flexBasis: '40px',
      borderTop: '1px solid #DADADA',
      padding: '12px',
      margin: 0,
      flexShrink: 0,
      flexGrow: 0,
      boxSizing: 'border-box',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontSize: '0.875rem',
    },
    '& > .description.actions': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      borderTop: '1px solid #DADADA',
      padding: 0,
      margin: 0,
      flexShrink: 0,
      flexGrow: 0,
      boxSizing: 'border-box',
      textOverflow: 'ellipsis',
      paddingRight: '9px',
      '& .MuiIconButton-root': {
        padding: '6px',
      },
    },
  }),
  spinner: {
    display: 'flex',
    position: 'absolute',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    zIndex: 1000,
    left: 0,
    top: 0,
    backgroundColor: '#F2F2F2',
  },
});

export default styles;
