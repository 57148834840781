import * as inspectionTemplates from 'serviceWorker/db/inspectionTemplates';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { getUsersSynchronized } from '../users/getUsers';
import { inspectionTemplateEntityToModel } from 'serviceWorker/repository/inspectionTemplate/inspectionTemplateEntityToModel';

export async function getAllInspectionTemplates(): Promise<
  InspectionTemplateModel[]
> {
  const [templates, users] = await Promise.all([
    inspectionTemplates.get(),
    getUsersSynchronized(),
  ]);
  return templates.map((template) =>
    inspectionTemplateEntityToModel(template, users)
  );
}
