import React, { useCallback, useState } from 'react';
import { Dialog } from '../index';
import AddIcon from '@mui/icons-material/Add';
import cs from 'classnames';
import { useStyles } from './PanelControllerStyles';
import { LabelledEntity } from 'shared/types/commonView';

type PanelControllerType = {
  label?: string;
  fieldName: string;
  elementTypes: LabelledEntity[];
  currentItems: LabelledEntity[] | null;
  onSelection: (fieldName: string, items: LabelledEntity[]) => void;
  onClose?: () => void;
  error?: boolean;
  disabled?: boolean;
  className: any;
};

const PanelController = ({
  label,
  fieldName,
  elementTypes,
  currentItems,
  onSelection,
  onClose,
  error,
  disabled,
  className,
}: PanelControllerType): JSX.Element => {
  const [dialogOpenState, setDialogState] = useState(false);
  const classes = useStyles();

  const handleDialogClose = useCallback((): void => {
    setDialogState(false);
    onClose && onClose();
  }, [onClose]);

  const handleSelection = useCallback(
    (items: LabelledEntity[]): void => {
      onSelection(fieldName, items);
      setDialogState(false);

      onClose && onClose();
    },
    [onClose, onSelection, fieldName]
  );

  const handleClick = useCallback(() => {
    if (!disabled) {
      setDialogState(true);
    }
  }, [disabled]);

  return (
    <React.Fragment>
      <div
        className={cs(
          classes.controllerWrapper,
          className,
          { disabled },
          { error }
        )}
        onClick={handleClick}
      >
        <AddIcon className={cs(classes.controllerAdornment, { error })} />
      </div>
      <Dialog
        label={label}
        list={elementTypes}
        currentItems={currentItems || []}
        isOpen={dialogOpenState}
        onClose={handleDialogClose}
        onItemsSelection={handleSelection}
      />
    </React.Fragment>
  );
};

export default PanelController;
