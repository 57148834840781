import { useCorrectiveActionTypesChannelListener } from 'components/broadcastChannelListeners/channelListener/correctiveActionTypesChannelListener';
import { CorrectiveActionTypeModel } from 'shared/domain/correctiveActionType/correctiveActiontypeModel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allCorrectiveActionTypes
  );
}

export const useGetAllCorrectiveActionTypes =
  makeUseGetAll<CorrectiveActionTypeModel>({
    useChannelListener: useCorrectiveActionTypesChannelListener,
    postMessageChannel: ChannelNames.correctiveActionTypeChannel,
    postMessageType: DomainMessagesTypes.getAllCorrectiveActionTypes,
    isMatchingMessage,
    entityName: 'correctiveActionTypes',
  });
