import { HashMap, Identificable } from 'shared/types/commonView';
import { DateType } from 'setup/types/core';

export type FilterDateType = {
  fromDate: DateType;
  toDate: DateType;
};

export type FilterGeneral = Identificable;
export type IssueFilters = HashMap<FilterGeneral[] | FilterDateType>;

export enum IssueFilersActions {
  SET_ALL = 'SET_ALL',
  SET_FILTER = 'SET_FILTER',
}
