import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useHazardCategoryChannelListener } from 'components/broadcastChannelListeners/channelListener/hazardCategoryChannelListener';
import { HazardCategoryModel } from 'shared/domain/hazardCategory/hazardCategoryModel';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allHazardCategories
  );
}

export const useGetAllHazardCategories =
  makeUseGetAll<HazardCategoryModel>({
    useChannelListener: useHazardCategoryChannelListener,
    postMessageChannel: ChannelNames.hazardCategoryChannel,
    postMessageType: DomainMessagesTypes.getAllHazardCategories,
    isMatchingMessage,
    entityName: 'hazardCategories',
  });
