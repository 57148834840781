import { makeStyles } from '@mui/styles';

export const creationDateFilterStyles = makeStyles({
  container: {
    display: 'flex',
    marginTop: '5px',
  },
  textFieldRoot: {
    marginTop: 0,
    marginBottom: 0,
    '& .MuiInputBase-input': {
      fontSize: '0.75em',
      paddingLeft: 8,
      position: 'relative',
      top: '2px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '4px',
      height: '42px',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
      transform: 'translate(14px, 15px) scale(1)',
    },
    '&. .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      backgroundColor: 'red',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: 0,
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderWidth: '1px',
      },
  },
  textFieldLabel: {
    fontSize: '0.75em',
    lineHeight: '1.25em',
    transitionProperty: 'font-size, color, transform',
    transitionDuration: '200ms',
    transitionTimingFunction: 'cubic-bezier(0.0, 0, 0.2, 1)',
    transitionDelay: '0ms',
  },
  textFieldLabelShrink: {
    fontSize: 'initial',
    lineHeight: 'initial',
    transform: 'translate(13px, -6px) scale(0.75) !important',
  },
  popover: {
    width: 310,
    '& .MuiIconButton-label': {
      fontFamily: 'Archivo',
    },
  },
});
