import { InspectionTemplateItem } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import useIsMobile from 'hooks/useIsMobile';
import React, { ReactElement, useEffect } from 'react';
import { MemoInspectionTemplateCard } from '../Card';
import { MemoSingleTemplateError } from '../SingleError';
import { CardListProps, ClickHandler } from './types';

function makeToReactElement(
  handleClick: ClickHandler,
  activeId: string | undefined
): (template: InspectionTemplateItem) => ReactElement {
  return function toReactElement(
    template: InspectionTemplateItem
  ): ReactElement {
    return (
      <MemoInspectionTemplateCard
        key={template._id}
        isActive={template._id === activeId}
        template={template}
        handleClick={handleClick}
      />
    );
  };
}

export const CARD_LIST_ID = 'hustro-card-list';

const customMobileStyle = (isMobile: boolean): object => {
  if (!isMobile) {
    return {};
  }
  return {
    alignItems: 'center',
  };
};

const listStyle = (isMobile: boolean): object => ({
  display: 'flex',
  flexDirection: 'column' as 'column',
  minWidth: isMobile ? '100%' : '366px',
  width: isMobile ? '100%' : 'unset',
  backgroundColor: '#f2f2f2',
  paddingTop: '19px',
  paddingBottom: '20px',
  overflowY: 'auto' as 'auto',
  flexShrink: 0,
  ...customMobileStyle(isMobile),
});

function CardList(props: CardListProps): ReactElement {
  const { list, activeId, handleClick, setCanScroll, error } = props;
  const cards = list.map(makeToReactElement(handleClick, activeId));
  const isMobile = useIsMobile();

  useEffect(
    () => setCanScroll(true),
    // we want to scroll just once after initial render
    // eslint-disable-next-line
    []
  );

  return (
    <div id={CARD_LIST_ID} style={listStyle(isMobile)}>
      {error ? <MemoSingleTemplateError error={error} /> : cards}
    </div>
  );
}

export const MemoCardList = React.memo(CardList);
