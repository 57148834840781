import { ApexOptions } from 'apexcharts';
import {
  createDonutChartToolbarOptions,
  sharedDonutChartOptions,
} from '../shared/options/donutChart';

export const detailedViewWorkTypeChartOptions: ApexOptions = {
  ...sharedDonutChartOptions,
  chart: {
    fontFamily: 'Archivo, Arial',
    toolbar: createDonutChartToolbarOptions('work_type'),
    redrawOnParentResize: true,
  },
};
