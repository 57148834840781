import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { makeSimpleBroadcastError } from '../factories';

const broadcastUserError = makeSimpleBroadcastError(
  ChannelNames.userChannel
);

export const broadcastUploadedUserError = (error: any): void => {
  debugLog('broadcastUploadedUserError', error);
  broadcastUserError(DomainMessagesTypes.failUploadedEntity, error);
};
