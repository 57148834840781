import { HashMap, LabelledEntity } from 'shared/types/commonView';

export function toLabelledEntity(value: any): LabelledEntity {
  return {
    _id: value._id,
    label: value.label,
  };
}

export function contractsExtractor(
  contracts: HashMap<LabelledEntity[]>,
  company: LabelledEntity
): LabelledEntity[] | undefined {
  return contracts[company._id];
}

export function controlledPartiesExtractor(
  companiesOnSite: HashMap<LabelledEntity[]>,
  site: LabelledEntity
): LabelledEntity[] | undefined {
  return companiesOnSite[site._id];
}
