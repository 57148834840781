import db from './index';
import {
  makeDefaultAddOne,
  makeDefaultGetOne,
} from './defaultDaoFactories';

export type ChartFiltersEntity = {
  filters: any;
  _id: string;
};

export const getOne = makeDefaultGetOne<string, ChartFiltersEntity>(
  db,
  db.chartFilters,
  '_id'
);

export const add = makeDefaultAddOne<ChartFiltersEntity>(
  db,
  db.chartFilters
);
