import { MoveDocumentationUseCase } from 'shared/domain/documentation/moveDocumentation';
import { Identificable } from 'shared/types/commonView';
import { updateOne as editDocumentation } from 'serviceWorker/db/documentations';
import { updateOne as editDirectory } from 'serviceWorker/db/directories';
import { add as addRequest } from 'serviceWorker/db/httpRequests';

import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import {
  HttpRequestModel,
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { broadcastDirectoryMoved } from 'serviceWorker/broadcasts/directories';
import { broadcastDocumentationMoved } from 'serviceWorker/broadcasts/documentations';

class DocumentationMover implements MoveDocumentationUseCase {
  constructor(
    private setDirectoryParent: (
      directoryLocalId: number,
      parentId: string
    ) => Promise<unknown>,
    private setDocumentationParent: (
      documenationLocalId: number,
      parentId: string
    ) => Promise<unknown>,
    private addRequest: (
      request: __HttpRequestModel__
    ) => Promise<unknown>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDocumentationMoved: (
      documentations: number[],
      directories: number[]
    ) => void
  ) {}

  async execute(
    parentId: string,
    documentations: number[],
    directories: number[]
  ): Promise<void> {
    await Promise.all([
      ...documentations.map((docLocalId) =>
        this.setDocumentationParent(docLocalId, parentId)
      ),
      ...directories.map((dirLocalId) =>
        this.setDirectoryParent(dirLocalId, parentId)
      ),
    ]);
    this.broadcastDocumentationMoved(documentations, directories);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        documentations,
        directories,
        parentId,
        projectId: currentProject?._id,
      },
      entityType: HttpRequestModelType.documentationMove,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const documentationMover = new DocumentationMover(
  (directoryLocalId, parentId) =>
    editDirectory(directoryLocalId, { parentId }),
  (documentationLocalId, parentId) =>
    editDocumentation(documentationLocalId, { parentId }),
  addRequest,
  getCurrentProject,
  (documentations: number[], directories: number[]) => {
    broadcastDocumentationMoved(documentations);
    broadcastDirectoryMoved(directories);
  }
);
