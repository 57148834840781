import React, { CSSProperties, useCallback } from 'react';
import { Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import useIsMobile from 'hooks/useIsMobile';
import { useDispatch } from 'react-redux';
import { showToaster } from 'redux/actions/toasterActions';
import { TOASTER_TYPES } from 'shared/enums';

type ForbiddenTooltipProps = {
  visibleWhen: boolean;
  children: React.ReactElement;
  title?: string;
  reason?: string;
  inlined?: boolean;
  style?: CSSProperties;
  placement?: TooltipProps['placement'];
  values?: any;
};

const childrenContainerStyle = { display: 'inherit' };
const inlineStyle = { display: 'inline-block' };

const ForbiddenTooltip = ({
  visibleWhen,
  children,
  title,
  style,
  reason = 'tooltip_action_forbidden',
  inlined,
  placement,
  values,
}: ForbiddenTooltipProps): JSX.Element => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const onClick = useCallback(() => {
    if (!isMobile) {
      return;
    }
    dispatch(
      showToaster({
        message:
          title ??
          intl
            .formatMessage({ id: reason }, values)
            .replaceAll('%d', values?.['%d'])
            .replaceAll('%s', values?.['%s']),
        type: TOASTER_TYPES.SUCCESS,
      })
    );
  }, [isMobile, dispatch, intl, reason, values, title]);

  if (!visibleWhen) {
    return children;
  }

  return (
    <Tooltip
      arrow
      enterTouchDelay={0}
      title={
        title ??
        intl
          .formatMessage({ id: reason }, values)
          .replaceAll('%d', values?.['%d'])
          .replaceAll('%s', values?.['%s'])
      }
      placement={placement}
      disableTouchListener
    >
      <div
        onClick={onClick}
        style={{
          ...(inlined ? inlineStyle : childrenContainerStyle),
          ...style,
        }}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export const MemoForbiddenTooltip = React.memo(ForbiddenTooltip);
export default ForbiddenTooltip;
