import axios, { AxiosResponse } from 'axios';
import { FileInfo } from '../setup/types/core';
import { FileType } from 'components/common/graphicUploader/types';
import { UploadStatus } from 'shared/types/uploadStatus';
import { DocumentTypes } from 'shared/types/document';
import { DocumentOnView } from 'presentation/document/documentOnView';
import { DocumentModel } from 'shared/domain/document/documentModel';
import MIMETypes from 'mime-types';

export const downloadBlob = async (request: string): Promise<Blob> => {
  const response: AxiosResponse<Blob> = await axios(request, {
    responseType: 'blob',
  });

  return new Blob([response.data]);
};

export const saveBlobOnUsersDevice = (
  blob: Blob,
  fileName: string
): Promise<void> => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = url;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  return Promise.resolve();
};

export const canDocumentBeDownloadedAndIsImage = (
  document: DocumentModel | DocumentOnView | FileType
): boolean => {
  const documentExtension = MIMETypes.extension(
    document.data?.type || ''
  ) as string;
  return (
    Boolean(document._id) &&
    !document.deleted &&
    document.data?.documentType === DocumentTypes.file &&
    (document.data as FileInfo).uploadStatus === UploadStatus.success &&
    ['jpg', 'jpeg', 'png', 'gif', 'webp'].includes(documentExtension)
  );
};
