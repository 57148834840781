import { Box } from '@mui/material';
import {
  FormFieldProps,
  MultiChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import React from 'react';
import {
  MemoStatefulAutocompleteWithText,
  MemoStatefulDatepicker,
  MemoStatefulMultiselect,
  MemoStatefulSingleSelect,
  MemoStatefulTextField,
} from '../../dataCreationForms/withInputForm/statefulInputsWrapper';
import { ProjectCreateOnView } from 'shared/domain/project/types/view';

type BaseProjectForm = {
  nameFieldProps: FormFieldProps<ProjectCreateOnView>;
  processesFieldProps: MultiChoiceFormFieldProps<ProjectCreateOnView>;
  currencyFieldProps: FormFieldProps<ProjectCreateOnView>;
  languageFieldProps: FormFieldProps<ProjectCreateOnView>;
  timezoneFieldProps: FormFieldProps<ProjectCreateOnView>;
  budgetFieldProps: FormFieldProps<ProjectCreateOnView>;
  projectStartDateFieldProps: FormFieldProps<ProjectCreateOnView>;
  projectEndDateFieldProps: FormFieldProps<ProjectCreateOnView>;
};
type ProjectCreateFormPresentationalProps = {
  organizationFieldProps: FormFieldProps<ProjectCreateOnView>;
} & BaseProjectForm;
type ProjectEditFormPresentationalProps = BaseProjectForm;
export function ProjectCreateFormPresentational(
  props: ProjectCreateFormPresentationalProps
): React.ReactElement {
  const {
    nameFieldProps,
    organizationFieldProps,
    processesFieldProps,
    currencyFieldProps,
    languageFieldProps,
    timezoneFieldProps,
    budgetFieldProps,
    projectStartDateFieldProps,
    projectEndDateFieldProps,
  } = props;
  return (
    <Box>
      <MemoStatefulSingleSelect {...organizationFieldProps} />
      <MemoStatefulAutocompleteWithText {...nameFieldProps} />
      <MemoStatefulMultiselect {...processesFieldProps} />
      <MemoStatefulSingleSelect {...currencyFieldProps} />
      <MemoStatefulSingleSelect {...languageFieldProps} />
      <MemoStatefulSingleSelect {...timezoneFieldProps} />
      <MemoStatefulTextField {...budgetFieldProps} />
      <MemoStatefulDatepicker {...projectStartDateFieldProps} />
      <MemoStatefulDatepicker {...projectEndDateFieldProps} />
    </Box>
  );
}
export function ProjectEditFormPresentational(
  props: ProjectEditFormPresentationalProps
): React.ReactElement {
  const {
    nameFieldProps,
    processesFieldProps,
    currencyFieldProps,
    languageFieldProps,
    timezoneFieldProps,
    budgetFieldProps,
    projectStartDateFieldProps,
    projectEndDateFieldProps,
  } = props;
  return (
    <Box>
      <MemoStatefulTextField {...nameFieldProps} />
      {/* <MemoStatefulMultiselect {...processesFieldProps} /> */}
      <MemoStatefulSingleSelect {...currencyFieldProps} />
      <MemoStatefulSingleSelect {...languageFieldProps} />
      <MemoStatefulSingleSelect {...timezoneFieldProps} />
      <MemoStatefulTextField {...budgetFieldProps} />
      <MemoStatefulDatepicker {...projectStartDateFieldProps} />
      <MemoStatefulDatepicker {...projectEndDateFieldProps} />
    </Box>
  );
}
