import { Theme as DefaultTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useCommonSingleEntityViewStyles = makeStyles<
  DefaultTheme,
  { isMobile: boolean }
>(() =>
  createStyles({
    root: {
      margin: ({ isMobile }): string =>
        isMobile ? '0 16px' : '29px 0 0 54px',
      maxWidth: ({ isMobile }): string => (isMobile ? '100%' : '854px'),
      '& h1': {
        margin: '28px 0',
      },
      '& .row': {
        marginTop: '28px',
      },
    },
    actionsContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '16px',
    },
  })
);
