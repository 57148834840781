import { Box } from '@mui/material';
import { FormFieldProps } from 'components/inspection/Form/types';
import { OrganizationCreateModel } from 'shared/domain/organization/types/model';
import React from 'react';
import {
  MemoStatefulAutocompleteWithText,
  MemoStatefulTextField,
} from '../../dataCreationForms/withInputForm/statefulInputsWrapper';

type OrganizationFormPresentationalProps = {
  nameFieldProps: FormFieldProps<OrganizationCreateModel>;
  labelFieldProps: FormFieldProps<OrganizationCreateModel>;
  contactEmailFieldProps: FormFieldProps<OrganizationCreateModel>;
};
export function OrganizationFormPresentational(
  props: OrganizationFormPresentationalProps
): React.ReactElement {
  const { nameFieldProps, labelFieldProps, contactEmailFieldProps } =
    props;
  return (
    <Box>
      <MemoStatefulAutocompleteWithText {...nameFieldProps} />
      <MemoStatefulAutocompleteWithText {...labelFieldProps} />
      <MemoStatefulAutocompleteWithText {...contactEmailFieldProps} />
    </Box>
  );
}
