import { WithCorrectiveActionTypesChannelListener } from 'components/broadcastChannelListeners/channelListener/correctiveActionTypesChannelListener';
import { WithEnvironmentalAspectChannelListener } from 'components/broadcastChannelListeners/channelListener/environmentalAspectChannelListener';
import { WithHazardCategoryChannelListener } from 'components/broadcastChannelListeners/channelListener/hazardCategoryChannelListener';
import { WithWorktypeChannelListener } from 'components/broadcastChannelListeners/channelListener/worktypeChannelListener';
import { WithCompanyChannelListener } from 'components/broadcastChannelListeners/withCompanyChannelListener';
import { WithContractChannelListener } from 'components/broadcastChannelListeners/withContractChannelListener';
import { WithInspectionChannelListener } from 'components/broadcastChannelListeners/withInspectionChannelListener';
import { WithInspectionTemplateChannelListener } from 'components/broadcastChannelListeners/withInspectionTemplateChannelListener';
import { WithIssueFormChannelListener } from 'components/broadcastChannelListeners/withIssueFormChannelListener';
import { WithSiteChannelListener } from 'components/broadcastChannelListeners/withSiteChannelListener';
import { WithVisibleFieldsChannelListener } from 'components/broadcastChannelListeners/withVisibleFieldsChannelListener';
import { WithSelectProject } from 'components/common/withSelectProject';
import { WithLevelChannelListener } from 'components/broadcastChannelListeners/withLevelChannelListener';
import React from 'react';
import { RouteProps } from 'react-router';
import { Redirect, Route } from 'react-router-dom';
import { useAuth0 } from '../../services/auth0/react-auth0.spa';
import { WithUserChannelListener } from 'components/broadcastChannelListeners/withUserChannelListener';
import { WithOrganizationChannelListener } from 'components/broadcastChannelListeners/withOrganizationChannelListener';
import { debugLog } from 'shared/logger/debugLog';

type PrivateRouteProps = Omit<RouteProps, 'component'> & {
  component: React.ComponentType<any>;
};
const PrivateRoute = ({
  component: Component,
}: PrivateRouteProps): React.ReactElement | null => {
  const { isAuthenticated } = useAuth0();
  debugLog('PrivateRoute: is user authenticated? ', isAuthenticated);
  if (isAuthenticated === undefined) {
    return null;
  }

  return (
    // react 18 types
    // @ts-ignore
    <Route
      render={(props): React.ReactElement =>
        isAuthenticated ? (
          <WithOrganizationChannelListener>
            <WithUserChannelListener>
              <WithSiteChannelListener>
                <WithLevelChannelListener>
                  <WithInspectionTemplateChannelListener>
                    <WithInspectionChannelListener>
                      <WithCompanyChannelListener>
                        <WithContractChannelListener>
                          <WithWorktypeChannelListener>
                            <WithHazardCategoryChannelListener>
                              <WithEnvironmentalAspectChannelListener>
                                <WithCorrectiveActionTypesChannelListener>
                                  <WithIssueFormChannelListener>
                                    <WithVisibleFieldsChannelListener>
                                      <WithSelectProject>
                                        <Component {...props} />
                                      </WithSelectProject>
                                    </WithVisibleFieldsChannelListener>
                                  </WithIssueFormChannelListener>
                                </WithCorrectiveActionTypesChannelListener>
                              </WithEnvironmentalAspectChannelListener>
                            </WithHazardCategoryChannelListener>
                          </WithWorktypeChannelListener>
                        </WithContractChannelListener>
                      </WithCompanyChannelListener>
                    </WithInspectionChannelListener>
                  </WithInspectionTemplateChannelListener>
                </WithLevelChannelListener>
              </WithSiteChannelListener>
            </WithUserChannelListener>
          </WithOrganizationChannelListener>
        ) : (
          // react 18 types
          // @ts-ignore
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );
};

export const AnalyticsPrivateRoute = ({
  component: Component,
}: PrivateRouteProps): React.ReactElement | null => {
  const { isAuthenticated } = useAuth0();
  !isAuthenticated &&
    debugLog(
      'AnalyticsPrivateRoute: user is not authenticated ',
      isAuthenticated
    );

  if (isAuthenticated === undefined) {
    return null;
  }

  return (
    // react 18 types
    // @ts-ignore
    <Route
      render={(props): React.ReactElement =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          // react 18 types
          // @ts-ignore
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  );
};

export default PrivateRoute;
