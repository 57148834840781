import {
  ReactElement,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { Store } from 'hooks/createStore';
import { createStyles, makeStyles } from '@mui/styles';
import { useIssueOverlayControls } from '../DocumentationTable/WithIssueOverlayControls';
import Controllers from 'components/common/gallery/controllers';
import { projectIdSelector } from 'helpers/misc';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import { singleIssueUrl } from 'helpers/generators';
import { SelectableIssueCard } from './SelectableIssueCard';
import { debugLog } from 'shared/logger/debugLog';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      position: 'absolute',
      bottom: 0,
      overflowX: 'hidden',
      overflowY: 'visible',
      width: '100%',
      height: '130px',
      background: 'linear-gradient(#00000055, #000000f0)',
      zIndex: 1203,
      paddingBottom: '6px',
    },
    scrollableContainer: {
      padding: '2px calc(50% - 190px)', // 50% - half card size;
      position: 'absolute',
      display: 'flex',
      alignItems: 'flex-end',
      overflowX: 'scroll',
      pointerEvents: 'none',
      width: '100%',
      boxSizing: 'border-box',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': { display: 'none' },
    },
  })
);

function _IssueCards(
  {
    issuesStore,
  }: {
    issuesStore: Store<IssueModel[]>;
  },
  ref: any
): ReactElement {
  const history = useHistory();
  const cs = useStyles();
  const scrollableTarget = useRef<HTMLDivElement | null>(null);
  const [issues, setIssues] = useState(issuesStore.get());
  const projectId = useSelector(projectIdSelector);

  useEffect(() => {
    return issuesStore.subscribe(() => {
      setIssues(issuesStore.get());
    });
  }, [issuesStore]);

  const { issuesVisibleStore } = useIssueOverlayControls();
  const [_, setVisible] = useState(issuesVisibleStore.get());

  const { select, selectedStore } = useSelectedStoreContext<string>();
  const onClick = useCallback(
    (issueModel: IssueModel) => {
      const current = selectedStore.get();
      if (current === issueModel._id) {
        history.push(singleIssueUrl(projectId, issueModel._id));
      } else {
        select(issueModel._id);
      }
    },
    [selectedStore, history, projectId, select]
  );

  useEffect(() => {
    return issuesVisibleStore.subscribe(() => {
      setVisible(issuesVisibleStore.get());
    });
  }, [issuesVisibleStore]);

  const issueCards = useMemo(() => {
    const cards = issues.map((issue) => (
      <SelectableIssueCard
        key={`${issue._id}`}
        issue={issue}
        onClick={onClick}
      />
    ));
    return cards;
  }, [issues, onClick]);

  const convertVerticalScroll = useCallback((evt) => {
    if (!scrollableTarget.current) {
      return;
    }
    scrollableTarget.current.scrollBy(evt.deltaY, 0);
  }, []);

  const scrollInDirection = useCallback((direction: number) => {
    if (
      !scrollableTarget.current ||
      !scrollableTarget.current.childElementCount
    ) {
      return;
    }
    const card = scrollableTarget.current.firstElementChild!;
    scrollableTarget.current.scrollBy({
      left: direction * card.clientWidth,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        bottom: '0',
        overflowX: 'visible',
        overflowY: 'visible',
        width: '100%',
        background: 'linear-gradient(#00000055, #000000f0)',
        zIndex: 1203,
        scrollbarWidth: 'none',
        paddingBottom: '6px',
      }}
    >
      <div
        style={{
          position: 'relative',
          maxHeight: '130px',
          overflowX: 'visible',
          overflowY: 'visible',
          display: 'flex',
          alignItems: 'flex-end',
          width: '100%',
          scrollbarWidth: 'none',
          height: '130px',
        }}
        onWheelCapture={convertVerticalScroll}
      >
        <div ref={scrollableTarget} className={cs.scrollableContainer}>
          {issueCards}
        </div>
        <Controllers
          action={scrollInDirection}
          showControllers={issueCards.length > 1}
          opacity={0.5}
        />
      </div>
    </div>
  );
}

export const IssueCards = forwardRef(_IssueCards);
