import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      height: '21px',
      borderRadius: '20px',
      paddingLeft: '6px',
      paddingRight: '6px',
      fontFamily: 'Archivo',
      fontSize: '0.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '13px',
      letterSpacing: '0em',
      textAlign: 'left',
      backgroundColor: '#F2F2F2',
      flexGrow: 0,
    },
  })
);
