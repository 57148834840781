import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import { CompanyInDto } from 'shared/dtos/in/company';
import {
  ICompanyUploader,
  UploadCompany,
  UploadCompanyChanges,
  UploadCompanyDelete,
  UploadCompanyRestore,
} from 'serviceWorker/repository/company/uploadCompany';
import { IRequestRunner } from '../httpRequestRunner';
import {
  CompanyHttpCreateRequestModel,
  CompanyHttpDeleteRequestModel,
  CompanyHttpEditRequestModel,
  CompanyHttpRestoreRequestModel,
} from './types';

export class CompanyRequestRunner implements IRequestRunner {
  constructor(
    private companyUploader: ICompanyUploader,
    private uploadCompanyUseCase: UploadCompany,
    private saveCompany: (data: CompanyInDto) => Promise<unknown>,
    private uploadCompanyChangesUseCase: UploadCompanyChanges,
    private uploadCompanyDeleteUseCase: UploadCompanyDelete,
    private deleteCompany: (companyId: string) => Promise<unknown>,
    private uploadCompanyRestoreUseCase: UploadCompanyRestore,
    private restoreCompany: (companyId: string) => Promise<unknown>
  ) {}
  async execute(
    request:
      | __HttpRequestModel__
      | CompanyHttpCreateRequestModel
      | CompanyHttpEditRequestModel
      | CompanyHttpDeleteRequestModel
      | CompanyHttpRestoreRequestModel
  ): Promise<unknown> {
    switch (request.method) {
      case 'POST': {
        return this.uploadCompany(
          request as CompanyHttpCreateRequestModel
        );
      }
      case 'PUT': {
        return this.uploadCompanyChanges(
          request as CompanyHttpEditRequestModel
        );
      }
      case 'DELETE': {
        return this.uploadCompanyDelete(
          request as CompanyHttpDeleteRequestModel
        );
      }
      case 'GET': {
        return this.uploadCompanyRestore(
          request as CompanyHttpRestoreRequestModel
        );
      }
    }
  }

  private async uploadCompany(
    request: CompanyHttpCreateRequestModel
  ): Promise<CompanyInDto> {
    const companyInDto = await this.uploadCompanyUseCase(
      this.companyUploader,
      request.data.companyCreateOutDto,
      request.url
    );
    const result = await this.saveCompany(companyInDto);
    return companyInDto;
  }

  private async uploadCompanyChanges(
    request: CompanyHttpEditRequestModel
  ): Promise<unknown> {
    return this.uploadCompanyChangesUseCase(
      this.companyUploader,
      request.data.companyEditOutDto,
      request.url
    );
  }

  private async uploadCompanyDelete(
    request: CompanyHttpDeleteRequestModel
  ): Promise<boolean> {
    await this.uploadCompanyDeleteUseCase(
      this.companyUploader,
      request.url
    );
    await this.deleteCompany(request.data.companyId);
    return true;
  }

  private async uploadCompanyRestore(
    request: CompanyHttpRestoreRequestModel
  ): Promise<boolean> {
    await this.uploadCompanyRestoreUseCase(
      this.companyUploader,
      request.url
    );
    await this.restoreCompany(request.data.companyId);
    return true;
  }
}
