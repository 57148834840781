import { useMemo } from 'react';

export type SetingsSaver = {
  get: (setting: string) => string | null;
  set: (setting: string, value: unknown) => void;
  getObject: <T>(
    setting: string,
    transformFunction?: (value: any) => T
  ) => T | null;
};

export function useSettingsSaver(id: string): SetingsSaver {
  function get(setting: string): string | null {
    return localStorage.getItem(`${id}_${setting}`);
  }

  function set(setting: string, value: unknown): void {
    return localStorage.setItem(
      `${id}_${setting}`,
      typeof value === 'string' ? value : JSON.stringify(value)
    );
  }

  function getObject<T>(
    setting: string,
    transformFunction?: (value: any) => T
  ): T | null {
    const value = JSON.parse(get(setting) || 'null');

    if (value === null) {
      return null;
    }

    return transformFunction ? transformFunction(value) : value;
  }

  return useMemo(() => {
    return {
      get,
      set,
      getObject,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
}
