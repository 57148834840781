import { BroadcastChannel } from 'broadcast-channel';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { setServiceWorkerConfig } from 'shared/setSWConfig';
import { useAuth0 } from 'services/auth0/react-auth0.spa';
import { StoreState } from 'setup/types/core';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';

type PromiseHandlers = {
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
};

export function useSetServiceWorkerConfig(): void {
  const { token, isAuthenticated } = useAuth0();
  const promiseRef = useRef<PromiseHandlers | null>(null);

  const locale = useSelector(
    (state: StoreState) => state.ui.currentLocale
  );
  const { _id: projectId, timezone } = useSelector(projectDataSelector);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.configChannel);

    broadcast.onmessage = (event: Message) => {
      if (event.type === DomainMessagesTypes.configSet) {
        if (promiseRef.current) {
          promiseRef.current.resolve();
        }
      }
    };

    return () => {
      broadcast.close();
    };
  }, []);

  const createPromise = useCallback(() => {
    const promise = new Promise<void>((resolve, reject) => {
      const timeout = setTimeout(
        () => reject(new Error('Timeout on set SW config.')),
        1000
      );

      promiseRef.current = {
        resolve: (value?: any) => {
          clearTimeout(timeout);
          resolve(value);
        },
        reject,
      };
    });

    setServiceWorkerConfig({
      locale,
      token,
      projectId,
      timezone,
    });

    return promise.catch((e) => {
      return createPromise();
    });
  }, [locale, projectId, timezone, token]);

  useEffect(() => {
    if (!isAuthenticated || !token) {
      return;
    }

    createPromise();
  }, [locale, token, projectId, timezone, isAuthenticated, createPromise]);
}
