import { Message } from 'shared/domain/messages/message';
import { useCallback } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { broadcastToSubscriptionAdapter } from './broadcastToSubscriptionAdapter';
import { createEntityPromise } from './createEntityPromise';
import { BroadcastChannel } from 'broadcast-channel';

type MakeUseGetProps<T, K> = {
  listenMessageChannel: ChannelNames;
  isMatchingMessage: (id: K, message: Message) => boolean;
  postMessage: (id: K) => void;
  entityName: string;
};

export function makeUseGet<T, K>({
  listenMessageChannel,
  isMatchingMessage,
  postMessage,
  entityName,
}: MakeUseGetProps<T, K>) {
  return function useGet(): {
    get: (id: K) => Promise<T>;
  } {
    const get = useCallback((id: K) => {
      const broadcast = new BroadcastChannel(listenMessageChannel);
      return createEntityPromise<T>({
        subscribe: broadcastToSubscriptionAdapter(broadcast),
        isMatchingMessage: (event) => isMatchingMessage(id, event),
        postMessage: () => postMessage(id),
        entityName,
      });
    }, []);

    return {
      get: get,
    };
  };
}
