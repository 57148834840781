import {
  DocumentEditData,
  EditDocumentUseCase,
} from 'shared/domain/document/editDocument';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { broadcastUpdatedDocument } from 'serviceWorker/broadcasts/documents';
import { updateOne as editDocument } from 'serviceWorker/db/documents';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { UploadStatus } from 'shared/types/uploadStatus';

export type DocumentChanges = {
  description?: string | undefined;
  _id?: string | undefined;
  deleted?: boolean;
  localData?: undefined;
  drawingUploadStatus?: UploadStatus | undefined;
  syncStatus?: SyncStatus | undefined;
};
// exported for tests, do we need them thou?
export class DocumentEditor implements EditDocumentUseCase {
  constructor(
    private editDocument: (
      localId: number,
      data: DocumentChanges
    ) => Promise<any>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDocumentUpdated: (localId: number) => void
  ) {}
  async execute(
    localId: number,
    editObject: DocumentEditData
  ): Promise<void> {
    await this.editDocument(localId, this.createChangesObject(editObject));
    this.broadcastDocumentUpdated(localId);

    if (editObject.description) {
      const currentProject = await this.getCurrentProject();
      await this.addRequest({
        createdAt: Date.now(),
        entityType: HttpRequestModelType.document,
        data: {
          localId,
          projectId: currentProject?._id,
          changes: {
            description: editObject.description,
          },
        },
        method: 'PUT',
        status: HttpRequestStatus.NEW,
      });
    }
  }

  private createChangesObject(
    editObject: DocumentEditData
  ): DocumentChanges {
    // TODO: PT-4149 TYPESAFETY
    // const keys: (keyof Required<DocumentEditData>)[] = [
    //   'localId',
    //   'description',
    //   '_id',
    //   'clearLocalData',
    //   'drawingUploadStatus',
    //   'deleted',
    //   'syncStatus',
    //   'drawingSyncStatus',
    // ];

    const descriptionObject = editObject.description
      ? { description: editObject.description }
      : {};
    const idObject = editObject._id ? { _id: editObject._id } : {};
    const deletedObject = editObject.deleted
      ? { deleted: editObject.deleted }
      : {};
    const localDataObject = editObject.clearLocalData
      ? { localData: undefined }
      : {};
    const drawingUploadStatusObject = editObject.drawingUploadStatus
      ? { 'data.drawnUploadStatus': editObject.drawingUploadStatus }
      : {};
    const syncStatus = editObject.syncStatus
      ? { syncStatus: editObject.syncStatus }
      : {};
    const drawingSyncStatus = editObject.drawingSyncStatus
      ? { drawingSyncStatus: editObject.drawingSyncStatus }
      : {};

    return {
      ...descriptionObject,
      ...idObject,
      ...localDataObject,
      ...deletedObject,
      ...drawingUploadStatusObject,
      ...syncStatus,
      ...drawingSyncStatus,
    };
  }
}

const documentEditor = new DocumentEditor(
  editDocument,
  addRequest,
  getCurrentProject,
  broadcastUpdatedDocument
);

export default documentEditor;
