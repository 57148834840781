import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { UploadStatus } from 'shared/types/uploadStatus';

export function isSuccessUpload(
  documentation: DocumentationModel
): boolean {
  const file = documentation.versions[0];
  if (!file) {
    return false;
  }
  if (file.uploadStatus === UploadStatus.failed) {
    return false;
  }
  if (file.uploadStatus !== UploadStatus.success && !file.localData) {
    return false;
  }

  return true;
}
