import Skeleton from '@mui/material/Skeleton';
import { DocumentationFields } from 'components/dataCreationForms/withDocumentationForm/types';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { MemoStatefulMultiselect } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import { DocumentationFile } from 'components/dataProviders/withDocumentationController/types';
import { useLevels } from 'components/dataProviders/withLevels';
import { useLevelsToSelect } from 'components/dataProviders/withLevelsToSelect';
import { MemoPreviewField } from 'components/general/PreviewField';
import { MultiChoiceFormFieldProps } from 'components/inspection/Form/types';
import { isSupportedAsMap } from 'shared/domain/documentation/availableExtensionsAsMap';
import { findDocumentationOnLevelsAndSites } from 'shared/domain/documentation/findDocumentationOnLevels';
import {
  ImageConvertStatus,
  ImageConvertStatusNegative,
} from 'shared/domain/imageConvertStatus/imageConvertStatus';
import { ReactElement, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { getConvertInfo, getStatusIcon, getStatusTextId } from './model';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import { LevelModel } from 'shared/domain/level/types/model';

export function DocumentationInformationForm({
  file,
}: {
  file: DocumentationFile;
}): ReactElement {
  const intl = useIntl();
  const { loading: loadingLevels, levels } = useLevels();
  const { loading: loadingLevelsToSelect, levels: levelsToSelect } =
    useLevelsToSelect();
  const loading = loadingLevels || loadingLevelsToSelect;
  const { setValues } = useInputForm();

  const documentationId = file.documentationId;
  const versionId = file.versionId;

  const { levelsValue } = useMemo(
    () =>
      findDocumentationOnLevelsAndSites(
        levels.items,
        [],
        documentationId,
        versionId
      ),
    [levels.items, documentationId, versionId]
  );

  const levelsFieldProps: MultiChoiceFormFieldProps<DocumentationFields> =
    useMemo(
      () => ({
        formKey: 'levels',
        required: false,
        labelId: 'documentation_use_as_map',
        fieldName: 'multiselect-levels',
        available: levelsToSelect.reduce<LabelledEntity[]>(
          (available: LabelledEntity[], level: LevelModel) => {
            if (!level.deleted) {
              available.push({
                _id: level._id,
                label: `${level.label}`,
              });
            }
            return available;
          },
          []
        ),
        defaultValue: levelsValue,
        entityName: EditableStandardEntityName.level,
        reserveSpaceForHelperText: false,
        dense: true,
        'data-qa': 'documentations_levels',
      }),
      [levelsToSelect, levelsValue]
    );

  useEffect(() => {
    setValues('levels', levelsValue, {
      skipValidation: true,
      skipFormEdit: true,
    });
  }, [setValues, levelsValue]);

  const extension = file.data?.extension || '';
  const isSupported = isSupportedAsMap(extension, file.imageConvertStatus);

  return (
    <>
      <div className='row'>
        <LevelsMultiselectOrInfo
          remoteId={versionId}
          extension={extension}
          isSupported={isSupported}
          loading={loading}
          file={file}
          levelsFieldProps={levelsFieldProps}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({ id: 'documentation_creation_date' })}
          disabled={true}
          value={file.createdAt}
          dense={true}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({ id: 'documentation_author' })}
          disabled={true}
          value={file.createdBy?.label || ' '}
          dense={false}
        />
      </div>
    </>
  );
}

function UnsupportedFileWarning({
  status,
  extension,
}: {
  status: ImageConvertStatusNegative | undefined | null;
  extension: string;
}): ReactElement {
  const intl = useIntl();
  const convertedInfo = getConvertInfo(status);
  const statusTextId = getStatusTextId(status);
  const Icon = getStatusIcon(status);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        color: `rgb(113,113,113)`,
        fontSize: '0.8em',
      }}
    >
      <Icon />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '8px',
        }}
      >
        {Boolean(convertedInfo) && (
          <span>
            {intl.formatMessage({ id: convertedInfo }, { extension })}
          </span>
        )}
        <span>{intl.formatMessage({ id: statusTextId })}</span>
      </div>
    </div>
  );
}

function LevelsMultiselectOrInfo({
  remoteId,
  extension,
  levelsFieldProps,
  file,
  isSupported,
  loading,
}: {
  remoteId: string | undefined;
  extension: string;
  levelsFieldProps: MultiChoiceFormFieldProps<DocumentationFields>;
  file: DocumentationFile;
  isSupported: boolean;
  loading: boolean;
}): ReactElement {
  if (!remoteId) {
    return (
      <UnsupportedFileWarning
        extension={extension}
        status={ImageConvertStatus.PENDING}
      />
    );
  }

  if (!isSupported) {
    return (
      <UnsupportedFileWarning
        extension={extension}
        status={file.imageConvertStatus as ImageConvertStatusNegative}
      />
    );
  }

  return loading ? (
    <div className='row'>
      <Skeleton variant='text' width={'100%'} height={100} />
    </div>
  ) : (
    <MemoStatefulMultiselect {...levelsFieldProps} />
  );
}
