import { useState, useEffect } from 'react';

export type WindowSize = {
  width: number;
  height: number;
};

function getWindowSize(): { width: number; height: number } {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width: width || 0,
    height: height || 0,
  };
}

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] =
    useState<WindowSize>(getWindowSize());

  useEffect(() => {
    const handleResize = (): void => {
      setWindowSize(getWindowSize);
    };
    window.addEventListener('resize', handleResize);
    return (): void => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

export default useWindowSize;
