import { ReactElement, useCallback, useMemo } from 'react';

import { Spinner } from 'components/core';
import ViewWrapper from 'components/core/Layout/ViewWrapper';

import { useFieldSettingsStyles } from './styles';
import { useStyles } from 'components/common/forms/formStyles/styles';

import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

import { useIntl } from 'react-intl';

import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import { FieldValuesSwitch } from '../switch';

import cn from 'classnames';
import { FieldBoxProps, FieldSettingsPresentationalProps } from './types';
import { ReactComponent as HustroFieldIcon } from 'assets/icons/hustro_field_icon.svg';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { FieldBox } from 'components/fieldBox';

export function FieldSettingsPresentational({
  sorted,
  loading,
  onFieldBoxClick,
  isPosting,
  fieldChanges,
  onCancel,
  onSubmit,
  hasChanges,
  titleLabelId,
  addNewOption,
}: FieldSettingsPresentationalProps): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  const specificClasses = useFieldSettingsStyles();

  const submitDisabledReason = useMemo(() => {
    if (isPosting) return 'disabled_save_in_progress';
    if (!hasChanges) return 'disabled_nothing_to_save';
  }, [isPosting, hasChanges]);

  return (
    <>
      <ViewWrapper>
        <div className={specificClasses.root}>
          <div className={specificClasses.titleContainer}>
            <div className={specificClasses.titleTextContainer}>
              <h2 className={specificClasses.title}>
                {intl.formatMessage({ id: titleLabelId })}
              </h2>
            </div>
          </div>
          <div className={specificClasses.dividerContainer}>
            <Divider />
          </div>

          <div className={specificClasses.addButtonContainer}>
            <MemoButtonContained onClick={addNewOption}>
              {intl.formatMessage({ id: 'field_values_add_option' })}
            </MemoButtonContained>
          </div>

          <div className={specificClasses.fieldBoxes}>
            {loading ? (
              <Spinner
                position='static'
                reason='FieldSettings Single Presentational loading'
              />
            ) : (
              Object.values(sorted).map((fieldValue, index) => (
                <FieldSettingBox
                  key={fieldValue._id || JSON.stringify(fieldValue)}
                  disabled={isPosting}
                  onFieldBoxClick={onFieldBoxClick}
                  fieldValue={fieldValue}
                  fieldChanges={fieldChanges}
                  index={index}
                  icon={
                    fieldValue.canEditTranslations ? undefined : (
                      <HustroFieldIcon />
                    )
                  }
                />
              ))
            )}
          </div>
          <div className={specificClasses.bottomSpace} />
        </div>
      </ViewWrapper>
      <div className={cn(classes.submitFooter, classes.castShadow)}>
        <Divider className={cn(classes.fullWidth)} />
        <div className={classes.footerPadding}>
          <Button onClick={onCancel} disabled={Boolean(isPosting)}>
            {intl.formatMessage({ id: 'general_cancel' })}
          </Button>
          <div>
            <MemoForbiddenTooltip
              visibleWhen={Boolean(isPosting) || !hasChanges}
              reason={submitDisabledReason}
            >
              <MemoButtonContained
                onClick={onSubmit}
                endIcon={
                  isPosting ? <CircularProgress size={16} /> : undefined
                }
                disabled={isPosting || !hasChanges}
              >
                {intl.formatMessage({ id: 'general_save' })}
              </MemoButtonContained>
            </MemoForbiddenTooltip>
          </div>
        </div>
      </div>
    </>
  );
}

function FieldSettingBox({
  fieldValue,
  onFieldBoxClick,
  disabled,
  fieldChanges,
  index,
  icon,
}: FieldBoxProps): ReactElement {
  const specificClasses = useFieldSettingsStyles();
  const { locale } = useIntl();
  const onClick = useCallback(() => {
    if (disabled) return;
    const changes = fieldChanges.find(
      (field) => field._id === fieldValue._id
    );
    onFieldBoxClick({
      ...fieldValue,
      ...changes,
    });
  }, [disabled, fieldChanges, onFieldBoxClick, fieldValue]);

  const label = useMemo(() => {
    const changes = fieldChanges.find(
      (field) => field._id === fieldValue._id
    );
    if (changes && Array.isArray(changes.valueTranslations)) {
      const currentLanguageTranslation =
        changes.valueTranslations.find((translation) => {
          return translation.localeCode.includes(locale);
        }) || changes.valueTranslations[0];
      return currentLanguageTranslation.value;
    }
    return fieldValue.label;
  }, [fieldChanges, fieldValue, locale]);

  return (
    <FieldBox>
      <div className={specificClasses.flexRowCenter} onClick={onClick}>
        {icon}
        <span className={specificClasses.fieldLabel}>{label}</span>
      </div>
      <FieldValuesSwitch
        checked={Boolean(fieldValue.processes.length)}
        _id={fieldValue._id || index}
        disabled={disabled}
      />
    </FieldBox>
  );
}
