import { initAllServices } from 'serviceWorker/services';
import { debugLog } from 'shared/logger/debugLog';

class ServiceWorkerOnMainThreadStarter {
  private running = false;
  constructor() {}

  initialize(): void {
    if (this.running) {
      return;
    }
    debugLog('Starting worker code on main thread');
    this.running = true;
    initAllServices(undefined as any);
  }
}

export const serviceWorkerOnMainThreadStarter =
  new ServiceWorkerOnMainThreadStarter();
