import * as dbCorrectiveActionTypes from '../db/correctiveActionTypes';
import * as dbCorrectiveActiontypesService from '../db/correctiveActionTypesService';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeBroadcastAll, makeBroadcastClear } from './factories';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { correctiveActionTypeEntityToModel } from 'serviceWorker/repository/correctiveActionType/correctiveActionTypeEntityToModel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { OnMessage } from './types';
import { BroadcastChannel } from 'broadcast-channel';

export const broadcastClearCorrectiveActionTypes = makeBroadcastClear(
  ChannelNames.correctiveActionTypeChannel
);

export const broadcastAllCorrectiveActionTypes = makeBroadcastAll(
  dbCorrectiveActiontypesService,
  {
    get: async () => {
      const correctiveActionTypes = await dbCorrectiveActionTypes.get();
      return correctiveActionTypes.map(correctiveActionTypeEntityToModel);
    },
  },
  ChannelNames.correctiveActionTypeChannel,
  DomainMessagesTypes.allCorrectiveActionTypes
);

const init = (): void => {
  const broadcast = new BroadcastChannel(
    ChannelNames.correctiveActionTypeChannel
  );

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllCorrectiveActionTypes: {
        await getAllCurrentCorrectiveActionTypes(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentCorrectiveActionTypes(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const items = await dbCorrectiveActionTypes.get();
  const end = Date.now();
  debugLog('getAllCurrentCorrectiveActionTypes time:', end - start);
  broadcast.postMessage({
    data: { items },
    type: DomainMessagesTypes.allCorrectiveActionTypes,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.CORRECTIVE_ACTION_TYPES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
