const styles = {
  dialogWrapper: {
    style: {
      minWidth: '500px',
      width: '500px',
    },
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '10px',
    alignItems: 'center',
  },
  dialogContent: {
    fontSize: '1rem',
    padding: '15px 30px',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: 16,
    fontFamily: 'Archivo',
  },
  icon: {
    opacity: 0.54,
    cursor: 'pointer',
  },
  fullWidth: {
    padding: 0,
  },
};

export { styles };
