import { AdditionalAppLogInfo } from 'shared/types/logger';
import { frontendVersionWithBuildNumber } from 'shared/version';

export function provideLogInfo(): AdditionalAppLogInfo {
  return {
    user_email: localStorage.getItem('user_email') || '',
    user_id: localStorage.getItem('user_id') || '',
    client_version: frontendVersionWithBuildNumber || null,
  };
}
