import { createStyles, makeStyles } from '@mui/styles';

export const useSkeletonStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      flexGrow: 1,
      backgroundColor: '#FFFFFF',
    },
    content: {
      width: '95%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    items: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 12,
      '& > div': {
        height: 44,
        marginRight: 4,
      },
    },
  })
);
