import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& h3': {
        marginBottom: '24px',
      },
      '& > * + *': {
        marginBottom: '24px',
      },
    },
  })
);
