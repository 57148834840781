import { Reducer, combineReducers } from 'redux';
import { createMigrate, persistReducer } from 'redux-persist';
import { MigrationManifest } from 'redux-persist/es/types';
import storage from 'redux-persist/lib/storage';
import { USER_LOGOUT } from './actionTypes';
import { issueMigrations, uiMigrations } from './migrations';

import { icons, issue, projectData, ui, user } from './reducers/';

const rootPersistConfig = {
  storage,
  key: 'root',
  blacklist: ['user', 'manager', 'issue', 'ui', 'users', 'projectData'],
};

const issuePersistConfig = {
  storage,
  key: 'issue',
  blacklist: [
    'loading',
    'error',
    'list',
    'syncData',
    'hasMore',
    'fetchedDeleted',
    'fetchedActive',
  ],
  version: 1,
  migrate: createMigrate(issueMigrations as MigrationManifest, {
    debug: false,
  }),
};

const iconsPersistConfig = {
  storage,
  key: 'icons',
  blacklist: ['loading', 'error'],
};

const uiPersistConfig = {
  storage,
  key: 'ui',
  blacklist: ['sidebarOpen', 'isFormEditing', 'mismatchedVersion'],
  whitelist: ['currentLocale', 'issueView', 'visibleIssuesType'],
  version: 5,
  //@ts-ignore
  migrate: createMigrate(uiMigrations, { debug: false }),
};

const appReducer = combineReducers({
  user,
  projectData,
  ui: persistReducer(uiPersistConfig, ui),
  issue: persistReducer(issuePersistConfig, issue),
  icons: persistReducer(iconsPersistConfig, icons),
});

// TODO: add return type
// eslint-disable-next-line
const rootReducer = (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    localStorage.clear();
  }

  return appReducer(state, action);
};

export const persistedReducer: Reducer = persistReducer(
  rootPersistConfig,
  rootReducer
);
