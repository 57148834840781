import { ReactElement, useEffect, useState } from 'react';
import { useGalleryContext } from 'components/common/gallery';
import { isOpenSelector } from 'components/common/gallery/galleryStateStore';
import { identitySelector } from 'components/common/gallery/selectors';
import { DocumentationBaseIds } from 'shared/types/documentation';
import { DocumentationIssueCards } from '../DocumentationIssueCards';
import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import { useIssueOverlayControls } from '../DocumentationTable/WithIssueOverlayControls';

export function DocumentationMiniaturesOverlay(): ReactElement | null {
  const { issuesVisibleStore } = useIssueOverlayControls();
  const [issuesVisible, setIssuesVisible] = useState(
    issuesVisibleStore.get()
  );

  useEffect(() => {
    const action = (): void => {
      setIssuesVisible(issuesVisibleStore.get());
    };
    action();
    return issuesVisibleStore.subscribe(action);
  }, [issuesVisibleStore]);

  const [file] =
    useGalleryContext<DocumentationBaseIds>().useMainDisplayedFile(
      identitySelector
    );
  const [isOpen] = useGalleryContext().useGalleryState(isOpenSelector);

  const { selectedStore } = useSelectedStoreContext();
  const [isSelected, setSelected] = useState(Boolean(selectedStore.get()));
  useEffect(() => {
    setSelected(Boolean(selectedStore.get()));
    return selectedStore.subscribe(() => {
      setSelected(Boolean(selectedStore.get()));
    });
  }, [selectedStore]);

  useEffect(() => {
    selectedStore.set(undefined);
  }, [file, selectedStore]);

  if (!file || !isOpen || !issuesVisible) return null;
  return <DocumentationIssueCards file={file} visible={isSelected} />;
}
