import { useEffect } from 'react';

type UseKeyPress = (
  key: string | number,
  action: Function,
  options?: {
    filter?: (event: KeyboardEvent) => boolean;
    stopPreventDefault?: boolean;
  }
) => void;

const useKeyPress: UseKeyPress = (
  key,
  action,
  { filter, stopPreventDefault } = {}
) => {
  useEffect(() => {
    const handleKey = (event: KeyboardEvent): void => {
      if (typeof filter === 'function' && !filter(event)) {
        return;
      }
      const pressed = event.key;
      if (pressed === key) {
        if (!stopPreventDefault) {
          event.preventDefault();
        }
        action(event);
      }
    };
    window.addEventListener('keydown', handleKey);

    return (): void => {
      window.removeEventListener('keydown', handleKey);
    };
  }, [action, key, filter, stopPreventDefault]);

  return;
};

export default useKeyPress;
export { useKeyPress };
