import { Theme as DefaultTheme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      // wrappedContent: {
      //   // margin: ({ isMobile }): string =>
      //   //   isMobile ? '0' : '0 16px 0 16px',
      // },
      singleContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        padding: ({ isMobile }): string =>
          isMobile ? '0 16px' : '0 48px',
        overflowY: 'auto',
        '& .MuiDivider-root': {
          width: ({ isMobile }): string =>
            isMobile ? 'calc(100% + 32px)' : '100%',
          position: ({ isMobile }): 'relative' | 'static' =>
            isMobile ? 'relative' : 'static',
          left: ({ isMobile }): string | undefined =>
            isMobile ? '-16px' : undefined,
        },
      },
      header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > span': {
          fontFamily: 'Archivo',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          margin: ({ isMobile }): string =>
            isMobile ? '24px 0 24px 8px' : '30px 0 30px 8px',
        },
      },
      summaryWithActions: {
        marginBottom: '20px',
        '& h1': {
          fontFamily: 'Archivo',
          fontSize: '1.5rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '29px',
          letterSpacing: '0em',
          textAlign: 'left',
        },
      },
      actionsContainer: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
        '& .actions-box': {
          marginTop: '8px',
          flexShrink: 0,
        },
      },
      propertiesContainer: {
        marginBottom: '24px',
        display: 'flex',
        flexFlow: 'row wrap',
        marginLeft: ({ isMobile }): string => (isMobile ? '0px' : '16px'),
        '& .preview-field': {
          width: '50%',
          minWidth: '200px',
          marginTop: '24px',
        },
      },
      generalNormContainer: {
        fontFamily: 'Archivo',
        textAlign: 'left',
        '& h2': {
          fontSize: '1.5rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '29px',
          letterSpacing: '0em',
          marginTop: '26px',
        },
      },
      checklistContainer: {
        fontFamily: 'Archivo',
        textAlign: 'left',
        '& h2': {
          fontSize: '1.5rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '29px',
          letterSpacing: '0em',
          marginTop: '26px',
        },
        '& .MuiAccordion-root': {
          margin: ({ isMobile }): string => (isMobile ? '0px' : '0 16px'),
        },
      },
      hidden: {
        visibility: 'hidden',
      },
      graphicUploaderWrapper: {
        width: '100%',
        marginTop: '22px',
        marginBottom: '24px',
      },
    })
);
