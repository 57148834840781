import React, { PropsWithChildren } from 'react';
import { useLabelledCardListStyles } from './styles';

export const LabelledCardList = ({
  children,
}: PropsWithChildren<{}>): React.ReactElement => {
  const specificClasses = useLabelledCardListStyles();
  return (
    <div className={specificClasses.root}>
      <div className={specificClasses.fieldBoxes}>{children}</div>
    </div>
  );
};
