import {
  MutableRefObject,
  Dispatch,
  SetStateAction,
  ReactElement,
  useState,
} from 'react';
import { HashMap } from 'shared/types/commonView';
import { FieldOnView } from 'views/fieldsVisibility';
import { FieldVisibilityBox } from '../Box';

export function FieldWithState({
  field,
  settersRef,
  onChange,
  initialIsChecked,
  label,
  disabled,
}: {
  field: FieldOnView;
  settersRef: MutableRefObject<HashMap<Dispatch<SetStateAction<boolean>>>>;
  onChange: (event: any, value: boolean) => void;
  initialIsChecked: boolean;
  label: string;
  disabled: boolean;
}): ReactElement {
  const [isChecked, setIsChecked] = useState(initialIsChecked);
  settersRef.current[field.fieldName] = setIsChecked;
  return (
    <FieldVisibilityBox
      disabled={disabled}
      fieldName={field.fieldName}
      isChecked={isChecked}
      onChange={onChange}
      label={label}
    />
  );
}
