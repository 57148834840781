import { PromiseExtended } from 'dexie';
import db from './index';
import { clear as inspectionTemplatesServiceClear } from './inspectionTemplatesService';
import {
  getErrorMsg,
  getErrorTransactionMsg,
  logWithRethrow,
  Operation,
} from './helpers';
import { wrapQuery } from './queryWrapper';
import { broadcastClearInspectionTemplates } from 'serviceWorker/broadcasts/inspectionTemplates';
import { entityDbClearFactory } from './entityDbClearFactory';
import { DocumentInDto } from 'shared/dtos/in/document';
import { inDtoToDocumentInsertEntity } from 'serviceWorker/repository/document/documentInDtoToEntity';
import {
  upsertBatch as upsertDocumentsBatch,
  removeBatchByParentId as removeDocumentsBatch,
  removeBatchByParentType as removeDocumentsBatchByType,
} from './documents';
import { InspectionTemplateInDto } from 'shared/dtos/in/template';
import { InspectionTemplateEntity } from 'serviceWorker/repository/inspectionTemplate/entity';
import {
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

const clearTemplates = wrapQuery(
  db,
  (): Promise<void> =>
    db.transaction(
      'rw',
      db.inspectionTemplates,
      db.documents,
      async function transactionClearTemplates(): Promise<void> {
        await Promise.all([
          db.inspectionTemplates.clear().catch((e) =>
            logWithRethrow({
              logLevel: LogLevel.INFO,
              msg: getErrorMsg(Operation.clear, 'inspectionTemplates'),
              errorObj: e,
              additionalInfo: null,
            })
          ),
          removeDocumentsBatchByType('templateId'),
        ]);
      }
    )
);

export const clear = entityDbClearFactory(
  db,
  ['inspectionTemplates', 'inspectionTemplatesService', 'documents'],
  clearTemplates,
  inspectionTemplatesServiceClear,
  broadcastClearInspectionTemplates
);

export const quantity = makeDefaultCount<InspectionTemplateEntity>(
  db,
  db.inspectionTemplates
);

export const get = makeDefaultGetMany<InspectionTemplateEntity>(
  db,
  db.inspectionTemplates
);

export const getOne = makeDefaultGetOne<string, InspectionTemplateEntity>(
  db,
  db.inspectionTemplates,
  '_id'
);

export const addBatch = wrapQuery(
  db,
  (data: InspectionTemplateInDto[]): PromiseExtended<unknown> => {
    return db
      .transaction(
        'rw',
        db.inspectionTemplates,
        db.documents,
        function () {
          const documentsData = data.flatMap(
            (template: InspectionTemplateInDto, templateIndex: number) => {
              const docs = [
                ...template.generalNorm.documents.map(
                  (document: DocumentInDto) =>
                    inDtoToDocumentInsertEntity(document, {
                      templateId: template._id,
                    })
                ),
                ...template.checklist.flatMap(
                  (checklistItem, itemIndex) => {
                    const checklistDocs = checklistItem.norm.documents.map(
                      (document: DocumentInDto) =>
                        inDtoToDocumentInsertEntity(document, {
                          templateId: template._id,
                          checklistId: checklistItem._id,
                        })
                    );
                    data[templateIndex].checklist[
                      itemIndex
                    ].norm.documents = [];
                    return checklistDocs;
                  }
                ),
              ];
              data[templateIndex].generalNorm.documents = [];
              return docs;
            }
          );

          return Promise.all([
            db.inspectionTemplates.bulkPut(data),
            upsertDocumentsBatch(documentsData),
          ]);
        }
      )
      .catch((e) => {
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.addBatch],
            ['inspectionTemplates', 'documents']
          ),
          errorObj: e,
          additionalInfo: {},
        });
      });
  }
);

export const updateBatch = addBatch;

export const removeBatch = wrapQuery(
  db,
  (ids: string[]): Promise<string[]> => {
    return db
      .transaction(
        'rw',
        db.inspectionTemplates,
        db.documents,
        async function () {
          const deleteInspectionTemplates = db.inspectionTemplates
            .where('_id')
            .anyOf(ids)
            .delete()
            .catch((e) =>
              logWithRethrow({
                logLevel: LogLevel.INFO,
                msg: getErrorMsg(
                  Operation.removeBatch,
                  'inspectionTemplates'
                ),
                errorObj: e,
                additionalInfo: {
                  query: {
                    ids: ids,
                  },
                },
              })
            );

          const deleteDocuments = removeDocumentsBatch({
            templateId: ids,
          });

          await Promise.all([deleteInspectionTemplates, deleteDocuments]);
          return ids;
        }
      )
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.removeBatch],
            ['inspectionTemplates', 'documents']
          ),
          errorObj: e,
          additionalInfo: {
            query: {
              ids,
            },
          },
        })
      );
  }
);
