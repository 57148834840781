import {
  ErrorPresentation,
  emailValidation,
  requiredValidation,
  stringMaxValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import {
  OrganizationCreateOnView,
  OrganizationEditOnView,
} from 'shared/domain/organization/types/view';
import { FieldSize } from 'shared/types/fieldSize';
import { ValidationSchema } from '../types';
import {
  makeHandleFormValidation,
  noValidation,
  validate,
} from '../validation';

const organizationEditValidationSchema: ValidationSchema<OrganizationEditOnView> =
  {
    _id: noValidation,
    logoUsedInPdfHeaders: noValidation,
    logoUsedInPdfHeadersSignedUrl: noValidation,
    name: nameValidation,
    label: labelValidation,
    contactEmail: contactEmailValidation,
  };

const organizationCreateValidationSchema: ValidationSchema<OrganizationCreateOnView> =
  {
    logoUsedInPdfHeaders: noValidation,
    logoUsedInPdfHeadersSignedUrl: noValidation,
    name: nameValidation,
    label: labelValidation,
    contactEmail: contactEmailValidation,
  };

function nameValidation(value: any): ErrorPresentation {
  return stringMaxValidation(FieldSize.M, value);
}

function labelValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.S, value)
  );
}

function contactEmailValidation(value: any): ErrorPresentation {
  return requiredValidation(value) || emailValidation(value);
}

export function validateOrganizationEdit(
  key: keyof OrganizationEditOnView,
  value: OrganizationEditOnView[keyof OrganizationEditOnView]
): ErrorPresentation {
  return validate(key, value, organizationEditValidationSchema);
}

export function validateOrganizationCreate(
  key: keyof OrganizationCreateOnView,
  value: OrganizationCreateOnView[keyof OrganizationCreateOnView]
): ErrorPresentation {
  return validate(key, value, organizationCreateValidationSchema);
}

export const handleOrganizationEditFormValidation =
  makeHandleFormValidation<OrganizationEditOnView>(
    organizationEditValidationSchema
  );

export const handleOrganizationCreateFormValidation =
  makeHandleFormValidation<OrganizationCreateOnView>(
    organizationCreateValidationSchema
  );
