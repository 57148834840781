import React from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth0 } from '../../services/auth0/react-auth0.spa';
import { Spinner } from 'components/core';
import { debugLog } from 'shared/logger/debugLog';

const RedirectUnauthorized = (): React.ReactElement => {
  const persistedLink = localStorage.getItem('redirect_link');
  let { isAuthenticated } = useAuth0();

  if (isAuthenticated === undefined) {
    return <Spinner reason={`RedirectUnauthorized, ${isAuthenticated}`} />;
  }

  debugLog(
    'RedirectUnauthorized: is user authenticated? ',
    isAuthenticated
  );
  return <Redirect to={persistedLink ?? '/login'} />;
};

export default RedirectUnauthorized;
