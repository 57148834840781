import { IssueFilters } from './types';

const ISSUE_FILTERS_SUFFIX = 'issue_filters';

export function getEmptyIssueFilters(): IssueFilters {
  return {
    site: [],
    stage: [],
    subcontractors: [],
    createdBy: [],
    assignee: [],
    issueTypes: [],
    creationDate: {
      fromDate: null,
      toDate: null,
    },
    detectionDate: {
      fromDate: null,
      toDate: null,
    },
  };
}

export const INITIAL_FILTERS_STRING = JSON.stringify(
  getEmptyIssueFilters()
);

function getSavedFilters(uniqueId: string): string {
  return (
    localStorage.getItem(`${uniqueId}_${ISSUE_FILTERS_SUFFIX}`) ||
    INITIAL_FILTERS_STRING
  );
}

export function getSavedFiltersObject(uniqueId: string): IssueFilters {
  try {
    return JSON.parse(getSavedFilters(uniqueId));
  } catch (e) {
    return getEmptyIssueFilters();
  }
}

export function saveIssueFilters(uniqueId: string, filters: any): void {
  localStorage.setItem(
    `${uniqueId}_${ISSUE_FILTERS_SUFFIX}`,
    JSON.stringify(filters)
  );
}
