import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  WheelEventHandler,
} from 'react';
import LockScroll from 'hooks/lockScroll';
import styles, { portalStyles } from './styles';
import FileDisplay from './fileDisplay';
import { ContextType, DrawingType } from '../graphicUploader/types';
import { ActionsType } from './types';
import Controllers from './controllers';
import useIsMobile from 'hooks/useIsMobile';
import { TopBar } from './topBar';
import { GalleryDivider } from './galleryDivider';
import { useGalleryContext } from '.';
import { Miniatures } from './miniatures';
import DrawingDisplay from './drawingDisplay';
import { useWindowSize } from 'hooks/useResize';

type Props = {
  changeGallery: ContextType['changeGallery'];
  moveGallery: (direction: number) => void;
  actions: ActionsType;
  idx: number;
  setDrawMode: (bool: boolean) => void;
  drawMode: boolean;
  drawing: DrawingType;
  drawings: DrawingType[];
  addDrawing: ContextType['addDrawing'];
  disableMiniatures?: boolean;
  preview?: boolean;
  additionalActions?: {
    PrefixedActionsComponent: ({
      disabledClass,
    }: {
      disabledClass: string;
    }) => ReactElement | null;
    SuffixedActionsComponent: ({
      disabledClass,
    }: {
      disabledClass: string;
    }) => ReactElement | null;
  };
  MiniaturesOverlay?: () => ReactElement | null;
  RightPanel?: () => ReactElement;
};

function lengthSelector<T>(files: T[]): number {
  return files.length;
}

const Presentational: React.FC<Props> = (props) => {
  const isMobile = useIsMobile();
  const windowSize = useWindowSize();

  const {
    changeGallery,
    actions,
    idx,
    setDrawMode,
    drawMode,
    drawing,
    drawings,
    addDrawing,
    disableMiniatures,
    preview,
    additionalActions,
    MiniaturesOverlay,
    RightPanel,
  } = props;

  const portal = portalStyles({ height: windowSize.height });
  const cs = styles({ isMobile });
  LockScroll();

  const closeGallery = useCallback(() => {
    setDrawMode(false);
    changeGallery('open', false);
  }, [setDrawMode, changeGallery]);

  const { scrollableTarget, handleMiniaturesScroll, moveMiniatures } =
    useMiniaturesScroll(idx, drawMode);

  const [filesLength] =
    useGalleryContext().useDisplayedFiles(lengthSelector);

  const showMiniatures = !drawMode && !disableMiniatures;

  return (
    <div className={portal.wrapper} role='presentation'>
      <TopBar
        actions={actions}
        idx={idx}
        setDrawMode={setDrawMode}
        onCloseClick={closeGallery}
        disableActions={drawMode}
        preview={preview}
        PrefixedActions={
          additionalActions?.PrefixedActionsComponent || NullActions
        }
        SuffixedActions={
          additionalActions?.SuffixedActionsComponent || NullActions
        }
      />
      <div className={cs.content}>
        {!drawMode && (
          <div className={cs.mainFileDisplay}>
            <FileDisplay drawing={drawing} />
          </div>
        )}
        {drawMode && (
          <DrawingDisplay
            drawMode={drawMode}
            setDrawMode={setDrawMode}
            drawing={drawing}
            addDrawing={addDrawing}
            idx={idx}
          />
        )}
        {RightPanel ? <RightPanel /> : null}
      </div>
      {showMiniatures && (
        <div className={cs.bottom}>
          <GalleryDivider />
          <div className={cs.miniaturesWithControl}>
            <Controllers
              action={moveMiniatures}
              showControllers={filesLength > 1}
            />
            <div
              className={cs.miniatures}
              ref={scrollableTarget}
              onWheelCapture={handleMiniaturesScroll}
            >
              <Miniatures drawings={drawings} />
            </div>
          </div>
          <div className={cs.miniaturesGradient}></div>
          {MiniaturesOverlay && <MiniaturesOverlay />}
        </div>
      )}
    </div>
  );
};

export default Presentational;

function useMiniaturesScroll(
  idx: number,
  drawMode: boolean
): {
  scrollableTarget: MutableRefObject<HTMLDivElement | null>;
  moveMiniatures: (dir: number) => void;
  handleMiniaturesScroll: WheelEventHandler<HTMLDivElement>;
} {
  const [behavior, setBehavior] = useState<ScrollBehavior>('auto');
  const MINIATURE_SIZE = 114;
  const scrollableTarget = useRef<HTMLDivElement | null>(null);
  const [miniatureIndex, setMiniatureIndex] = useState(idx);

  useLayoutEffect(() => {
    if (!scrollableTarget.current) {
      return;
    }

    scrollableTarget.current.scrollTo({
      left: miniatureIndex * MINIATURE_SIZE,
      behavior,
    });
  }, [miniatureIndex, behavior, drawMode]);

  useLayoutEffect(() => {
    if (!scrollableTarget.current) {
      return;
    }

    scrollableTarget.current.scrollTo({
      left: miniatureIndex * MINIATURE_SIZE,
      behavior: 'auto',
    });
    // instant scroll on draw mode off
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawMode]);

  useEffect(() => {
    setMiniatureIndex(idx);
  }, [idx]);

  useEffect(() => {
    setBehavior('smooth');
  }, []);

  const moveMiniatures = useCallback((direction: number) => {
    if (!scrollableTarget.current) {
      return;
    }
    scrollableTarget.current.scrollBy({
      left: direction * MINIATURE_SIZE,
      behavior: 'smooth',
    });
  }, []);

  const handleMiniaturesScroll = useCallback((evt) => {
    if (!scrollableTarget.current) {
      return;
    }
    scrollableTarget.current.scrollBy(evt.deltaY, 0);
  }, []);

  return {
    scrollableTarget,
    handleMiniaturesScroll,
    moveMiniatures,
  };
}

function NullActions(...args: any[]): null {
  return null;
}
