import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import ErrorIcon from '@mui/icons-material/Error';
import RemoveIcon from '@mui/icons-material/Remove';
import { Nothing } from 'components/general/Nothing';
import {
  removeIconStyle,
  iconHeight,
  removeIconStyleBig,
  iconHeightBig,
  errorIconStyle,
} from './styles';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

export const innerIcon = {
  [ComplianceCheckResult.unset]: <Nothing />,
  [ComplianceCheckResult.notApplicable]: (
    <RemoveIcon style={removeIconStyle} />
  ),
  [ComplianceCheckResult.notPassed]: <ClearIcon style={iconHeight} />,
  [ComplianceCheckResult.passed]: <CheckIcon style={iconHeight} />,
};

export const errorIcon = <ErrorIcon style={errorIconStyle} />;

export const innerIconBig = {
  [ComplianceCheckResult.unset]: <Nothing />,
  [ComplianceCheckResult.notApplicable]: (
    <RemoveIcon style={removeIconStyleBig} />
  ),
  [ComplianceCheckResult.notPassed]: <ClearIcon style={iconHeightBig} />,
  [ComplianceCheckResult.passed]: <CheckIcon style={iconHeightBig} />,
};
