import { useDataFlushedListener } from 'components/broadcastChannelListeners/useDataFlushedListener';
import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import { isDocumentationDeleted } from 'shared/domain/messages/documentation/isDocumentationDeleted';
import { isDocumentationMoved } from 'shared/domain/messages/documentation/isDocumentationMoved';
import { isUploadOrUpdate, Message } from 'shared/domain/messages/message';
import { useCreateStore } from 'hooks/createStore';
import { useGetAllDirectories } from 'hooks/useGetAllDirectories';
import { useGetDirectories } from 'hooks/useGetDirectories';
import { useMountedRef } from 'hooks/useMountRef';
import {
  ComponentType,
  createContext,
  FC,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { useDirectoryChannelListener } from '../../broadcastChannelListeners/withDirectoryChannelListener';
import { useCurrentDirectoryContext } from '../withCurrentDirectory';
import { DirectoriesContextType } from './types';

const DirectoriesContext = createContext<
  DirectoriesContextType | undefined
>(undefined);

const WithDirectories: FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const mountedRef = useMountedRef();
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const directoriesStore = useCreateStore<DirectoryModel[]>([]);
  const isLoadingDirectoriesStore = useCreateStore<boolean>(true);
  const currentDirectoryFoldersStore = useCreateStore<DirectoryModel[]>(
    []
  );
  const isLoadingCurrentDirFoldersStore = useCreateStore<boolean>(true);

  const { subscribe } = useDirectoryChannelListener();
  const { get: getDirectories } = useGetDirectories();
  const { getAll: getAllDirectories } = useGetAllDirectories();

  useEffect(() => {
    return currentDirectoryStore.subscribe(() => {
      isLoadingCurrentDirFoldersStore.set(true);
    });
  }, [currentDirectoryStore, isLoadingCurrentDirFoldersStore]);

  const syncAllDirectories = useCallback(() => {
    getAllDirectories()
      .then((directories) => {
        if (!mountedRef.current) {
          return;
        }
        directoriesStore.set(directories.items);
        isLoadingDirectoriesStore.set(false);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        displayGenericErrorToaster(dispatch);
      });
  }, [
    directoriesStore,
    dispatch,
    getAllDirectories,
    isLoadingDirectoriesStore,
    mountedRef,
  ]);

  const getCurrentDirectoryFolders = useCallback(() => {
    const folderId = currentDirectoryStore.get();
    getDirectories(folderId)
      .then((directories) => {
        if (!mountedRef.current) {
          return;
        }
        if (currentDirectoryStore.get() !== folderId) {
          return;
        }
        currentDirectoryFoldersStore.set(
          directories.items.filter((item: DirectoryModel) => item._id)
        );
        isLoadingCurrentDirFoldersStore.set(false);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        if (currentDirectoryStore.get() !== folderId) {
          return;
        }
        displayGenericErrorToaster(dispatch);
      });
  }, [
    currentDirectoryStore,
    getDirectories,
    mountedRef,
    currentDirectoryFoldersStore,
    isLoadingCurrentDirFoldersStore,
    dispatch,
  ]);

  useEffect(() => {
    const currentDirUnsubscribe = currentDirectoryStore.subscribe(
      getCurrentDirectoryFolders
    );
    const directoriesUnsubscribe = directoriesStore.subscribe(
      getCurrentDirectoryFolders
    );

    return () => {
      currentDirUnsubscribe();
      directoriesUnsubscribe();
    };
  }, [
    currentDirectoryStore,
    directoriesStore,
    getCurrentDirectoryFolders,
    getDirectories,
  ]);

  useEffect(() => {
    syncAllDirectories();
  }, [syncAllDirectories]);

  useEffect(
    function directoryChangesListener() {
      return subscribe((event: Message) => {
        if (
          (isUploadOrUpdate(event) ||
            isDocumentationMoved(event) ||
            isDocumentationDeleted(event)) &&
          event.data
        ) {
          syncAllDirectories();
        }
      });
    },
    [syncAllDirectories, subscribe]
  );

  useEffect(
    function errorsListener() {
      return subscribe((event: Message) => {
        if (
          (isUploadOrUpdate(event) ||
            isDocumentationMoved(event) ||
            isDocumentationDeleted(event)) &&
          event.error
        ) {
          displayGenericErrorToaster(dispatch);
        }
      });
    },
    [dispatch, subscribe]
  );

  useDataFlushedListener(mountedRef, syncAllDirectories);

  const ctx: DirectoriesContextType = useMemo(
    () => ({
      directoriesStore: directoriesStore,
      currentDirectoryFoldersStore: currentDirectoryFoldersStore,
      isLoadingDirectoriesStore: isLoadingDirectoriesStore,
      isLoadingCurrentDirFoldersStore: isLoadingCurrentDirFoldersStore,
      resync: syncAllDirectories,
    }),
    [
      currentDirectoryFoldersStore,
      directoriesStore,
      isLoadingCurrentDirFoldersStore,
      isLoadingDirectoriesStore,
      syncAllDirectories,
    ]
  );

  return (
    <DirectoriesContext.Provider value={ctx}>
      {children}
    </DirectoriesContext.Provider>
  );
};

function withDirectories<T>(Component: ComponentType<T>) {
  return (props: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithDirectories>
      <Component {...props} />
    </WithDirectories>
  );
}

function useDirectories(): DirectoriesContextType {
  const context = useContext(DirectoriesContext);
  if (context === undefined) {
    throw new Error(
      'useDirectories must be used within a DirectoriesContextProvider'
    );
  }
  return context;
}

export { useDirectories, WithDirectories, withDirectories };
