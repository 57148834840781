import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../setup/types/core';
import { getSnackBarStyles, MessageToMarkdown } from './helpers';
import {
  Snackbar,
  SnackbarContent,
  SnackbarOrigin,
  Theme,
} from '@mui/material';

import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';

const styles = createStyles((theme: Theme) => ({
  root: {
    zIndex: 30000,
  },
  snackbar: {
    borderRadius: 5,
  },
  snackbarError: {
    borderRadius: 5,
    backgroundColor: 'indianred',
  },
  message: {
    margin: '0 auto',
    textAlign: 'center' as 'center',
    whiteSpace: 'pre-line' as 'pre-line',
  },
  messageStrong: {
    color: theme.palette.primary.main,
  },
}));

const Toaster = ({ classes }: WithStyles<any>): JSX.Element | null => {
  const {
    toasterVisible = false,
    toasterMessage,
    toasterType,
    toasterPosition = {
      vertical: 'bottom',
      horizontal: 'center',
    },
  } = useSelector((state: StoreState) => state.ui.toaster);

  return (
    <Snackbar
      open={toasterVisible}
      anchorOrigin={toasterPosition as SnackbarOrigin}
      classes={{ root: classes.root }}
    >
      <SnackbarContent
        message={MessageToMarkdown(toasterMessage!, classes)}
        classes={getSnackBarStyles(classes, toasterType)}
      />
    </Snackbar>
  );
};

export default withStyles(styles)(Toaster);
