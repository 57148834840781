import React, { ReactElement, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import { GraphicUploader } from 'components/common/graphicUploader';
import { GU } from 'components/common/graphicUploader/types';
import { MemoPreviewField } from 'components/general/PreviewField';
import { MemoProtocolItemResultChip } from '../ComplianceCheckResult/Chip';
import { LoadableIssueCard } from '../IssueCardPreview';
import { Nothing } from 'components/general/Nothing';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import { ProtocolOnView } from 'presentation/inspection/inspectionOnView';
import { RenderableProcess } from 'components/common/ProcessIcon/types';
import { useIssuesOnInspection } from 'components/dataProviders/withIssuesOnInspection';
import { projectIdSelector } from 'helpers/misc';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { singleIssueUrl } from 'helpers/generators';
import { IssueModel } from 'shared/domain/issue/issueModel';

type CommentWithUploaderProps = {
  protocolItem: ProtocolOnView;
  graphicUploader: GU;
};
function CommentWithUploader(
  props: CommentWithUploaderProps
): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  const { protocolItem, graphicUploader } = props;
  return (
    <>
      <MemoPreviewField
        label={intl.formatMessage({
          id: 'protocol_item_comment',
        })}
        value={protocolItem.complianceCheck.comment || ''}
        dense={true}
      />
      <div className={classes.graphicUploaderWrapper}>
        <GraphicUploader {...graphicUploader} />
      </div>
    </>
  );
}

function IssueCards({
  protocolItem,
}: {
  protocolItem: ProtocolOnView;
}): ReactElement {
  const projectId = useSelector(projectIdSelector);
  const history = useHistory();
  const classes = useStyles();
  const onClick = useCallback(
    (issueModel: IssueModel) => {
      history.push(singleIssueUrl(projectId, issueModel._id));
    },
    [history, projectId]
  );

  return (
    <div className={classes.cardsContainer}>
      {protocolItem.complianceCheck.issues.map((issue: string) => (
        <LoadableIssueCard
          key={issue}
          issueId={issue}
          useStoreContext={useIssuesOnInspection}
          onClick={onClick}
        />
      ))}
    </div>
  );
}

const selector = {
  [ComplianceCheckResult.notApplicable]: CommentWithUploader,
  [ComplianceCheckResult.passed]: CommentWithUploader,
  [ComplianceCheckResult.notPassed]: IssueCards,
  [ComplianceCheckResult.unset]: Nothing,
};
type ComplianceCheckPreviewProps = {
  protocolItem: ProtocolOnView;
  graphicUploader: GU;
  renderableProcess: RenderableProcess;
};
type DetailsProps = {
  protocolItem: ProtocolOnView;
  graphicUploader: GU;
  renderableProcess: RenderableProcess;
};
function ComplianceCheckPreview(
  props: ComplianceCheckPreviewProps
): ReactElement {
  const { protocolItem, graphicUploader, renderableProcess } = props;
  const status = protocolItem.complianceCheck.result;

  const Details: (props: DetailsProps) => ReactElement = React.useMemo(
    () => selector[status],
    [status]
  );

  return (
    <>
      <MemoProtocolItemResultChip status={status} />
      <Details
        protocolItem={protocolItem}
        graphicUploader={graphicUploader}
        renderableProcess={renderableProcess}
      />
    </>
  );
}

export const MemoComplianceCheckPreview = React.memo(
  ComplianceCheckPreview
);
