import { ReactElement, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { UploadItemsPanel } from 'components/issue';
import { useStylesMultiSelect } from './styles';
import cn from 'classnames';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { ChartFilter } from 'shared/types/analytics';

export function MultiselectControl(props: {
  filter: ChartFilter<unknown>;
  localSetter: (value: any) => void;
  localValue: string[];
  disabled: boolean;
}): ReactElement {
  const intl = useIntl();
  const classes = useStylesMultiSelect();
  const { labelId, available, labels } = props.filter;
  const localSetter = props.localSetter;
  const value = props.localValue;
  const defaultValue = value.map((val: string) => ({
    _id: val,
    label: labels?.[val] ?? '',
  }));

  const handleChange = useCallback(
    (_, newValue: LabelledEntity[]): void => {
      localSetter(newValue.map((v) => v._id));
    },
    [localSetter]
  );

  const handleDelete = useCallback(
    (_, __, toDelete: LabelledEntity, values?: LabelledEntity[]): void => {
      localSetter(
        values?.reduce<string[]>(
          (result: string[], value: LabelledEntity) => {
            if (value._id !== toDelete._id) {
              result.push(value._id);
            }

            return result;
          },
          []
        )
      );
    },
    [localSetter]
  );

  const isFilled = defaultValue.length > 0;

  return (
    <div className={cn(classes.multiselect, { isFilled })}>
      <UploadItemsPanel
        label={intl.formatMessage({ id: labelId })}
        value={defaultValue}
        fieldName={`multiselect-${labelId}`}
        required={false}
        availableItems={available?.filter(keepUndeleted)}
        disabled={props.disabled}
        onDelete={handleDelete}
        onSelection={handleChange}
      />
    </div>
  );
}
