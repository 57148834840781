import { CompanyModel } from 'shared/domain/company/types/model';
import { ContractEditOutDto } from 'shared/dtos/out/contract';
import { LabelledEntity } from 'shared/types/commonView';
import {
  ContractCreateModel,
  ContractEditModel,
  ContractModel,
  ContractPartyModel,
} from './types/model';
import { ContractCreateOnView, ContractEditOnView } from './types/view';

export function contractEditModelToContractEditOutDto(
  model: ContractEditModel
): ContractEditOutDto {
  return {
    label: model.label,
    parties: model.parties,
  };
}

export function contractCreateModelToContractCreateOutDto(
  model: ContractCreateModel
): ContractEditOutDto {
  return {
    label: model.label,
    parties: model.parties,
  };
}

export function createEmptyContract(): ContractCreateOnView {
  return {
    label: '',
    parties: [],
  };
}

function contractPartyModelsToContractPartiesOnView(
  parties: ContractPartyModel[],
  companies: CompanyModel[]
): LabelledEntity[] {
  return parties.reduce<LabelledEntity[]>((prev, { companyId }) => {
    const company = companies.find(
      (company) => company._id === companyId
    ) as CompanyModel;
    if (company) {
      prev.push({ label: company.shortLabel, _id: company._id });
    }

    return prev;
  }, []);
}

export function contractModelToContractOnView(
  contract: ContractModel,
  companies: CompanyModel[]
): ContractEditOnView {
  return {
    _id: contract._id,
    parties: contractPartyModelsToContractPartiesOnView(
      contract.parties,
      companies
    ),
    label: contract.label,
  };
}

export function contractCreateOnViewToContractCreateModel(
  contract: ContractCreateOnView,
  projectId: string
): ContractCreateModel {
  return {
    label: contract.label,
    parties: labelledEntitiesToContractPartyModels(contract.parties),
    projectId,
  };
}

export function contractEditOnViewToContractEditModel(
  contract: ContractEditOnView,
  projectId: string
): ContractEditModel {
  return {
    projectId,
    _id: contract._id,
    label: contract.label,
    parties: labelledEntitiesToContractPartyModels(contract.parties),
  };
}

function labelledEntitiesToContractPartyModels(
  parties: LabelledEntity[]
): ContractPartyModel[] {
  return parties.map((party) => ({
    companyId: party._id,
    companyShortLabel: party.label,
  }));
}
