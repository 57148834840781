import { ErrorPresentation } from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { HashMap } from 'shared/types/commonView';
import { ValidationSchema } from './types';

export function noValidation(_: any): ErrorPresentation {
  return;
}

export const onViewValidation = {
  createdAt: noValidation,
  createdBy: noValidation,
  modifiedAt: noValidation,
  modifiedBy: noValidation,
  deleted: noValidation,
  _id: noValidation,
};

export function validate<ValidatedObject>(
  key: keyof ValidatedObject,
  value: ValidatedObject[keyof ValidatedObject],
  validationSchema: ValidationSchema<ValidatedObject>
): ErrorPresentation {
  if (!validationSchema[key]) return;
  return validationSchema[key](value);
}

export function makeHandleFormValidation<
  ValidatedObjectOnView extends object,
>(
  validationSchema: ValidationSchema<ValidatedObjectOnView>
): (
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: ValidatedObjectOnView
) => boolean {
  return function handleFormValidation(
    errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
    form: ValidatedObjectOnView
  ): boolean {
    const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
      (errors, [key, value]) => {
        const error = validate(
          key as keyof ValidatedObjectOnView,
          value as ValidatedObjectOnView[keyof ValidatedObjectOnView],
          validationSchema
        );
        if (error) {
          errors[key] = error;
        }
        return errors;
      },
      {}
    );
    errorsSetter(errors);
    return Object.keys(errors).length > 0;
  };
}

type Validator<ValidatedObjectOnView> = <
  T extends keyof ValidatedObjectOnView,
>(
  key: T,
  value: ValidatedObjectOnView[T]
) => ErrorPresentation;
