import {
  siteCreateModelToSiteCreateOutDto,
  siteEditModelToSiteChangeOutDto,
} from 'shared/domain/site/mapping/toOutDto';
import { SiteInDto } from 'shared/dtos/in/site';
import {
  ISiteUploader,
  UploadSite,
  UploadSiteChanges,
  UploadSiteDelete,
  UploadSiteRestore,
} from 'serviceWorker/repository/site/uploadSite';
import { IRequestRunner } from '../httpRequestRunner';
import {
  SiteHttpCreateRequestModel,
  SiteHttpDeleteRequestModel,
  SiteHttpEditRequestModel,
  SiteHttpRestoreRequestModel,
} from './types';

export class SiteRequestRunner implements IRequestRunner {
  constructor(
    private siteUploader: ISiteUploader,
    private uploadSiteUseCase: UploadSite,
    private saveSite: (data: SiteInDto) => Promise<unknown>,
    private uploadSiteChangesUseCase: UploadSiteChanges,
    private uploadSiteDeleteUseCase: UploadSiteDelete,
    private deleteSite: (siteId: string) => Promise<unknown>,
    private uploadSiteRestoreUseCase: UploadSiteRestore,
    private restoreSite: (siteId: string) => Promise<unknown>
  ) {}
  async execute(
    request:
      | SiteHttpCreateRequestModel
      | SiteHttpEditRequestModel
      | SiteHttpDeleteRequestModel
      | SiteHttpRestoreRequestModel
  ): Promise<boolean> {
    switch (request.method) {
      case 'POST': {
        return this.uploadSite(request as SiteHttpCreateRequestModel);
      }
      case 'PUT': {
        return this.uploadSiteChanges(request as SiteHttpEditRequestModel);
      }
      case 'DELETE': {
        return this.uploadSiteDelete(
          request as SiteHttpDeleteRequestModel
        );
      }
      case 'GET': {
        return this.uploadSiteRestore(
          request as SiteHttpRestoreRequestModel
        );
      }
    }
  }

  private async uploadSite(
    request: SiteHttpCreateRequestModel
  ): Promise<boolean> {
    if (!request.data?.siteCreateModel) {
      throw new Error('Cannot upload site without values');
    }
    const url = `/project/${request.data.siteCreateModel.projectId}/site`;
    const siteOutDto = siteCreateModelToSiteCreateOutDto(
      request.data.siteCreateModel
    );

    const siteInDto = await this.uploadSiteUseCase(
      this.siteUploader,
      siteOutDto,
      url
    );
    await this.saveSite(siteInDto);
    return true;
  }

  private async uploadSiteChanges(
    request: SiteHttpEditRequestModel
  ): Promise<boolean> {
    if (!request.data.siteEditModel) {
      throw new Error('Cannot update site without values');
    }
    const siteOutDto = siteEditModelToSiteChangeOutDto(
      request.data.siteEditModel
    );
    await this.uploadSiteChangesUseCase(
      this.siteUploader,
      siteOutDto,
      request.url
    );
    return true;
  }

  private async uploadSiteDelete(
    request: SiteHttpDeleteRequestModel
  ): Promise<boolean> {
    await this.uploadSiteDeleteUseCase(this.siteUploader, request.url);
    await this.deleteSite(request.data.siteId);
    return true;
  }

  private async uploadSiteRestore(
    request: SiteHttpRestoreRequestModel
  ): Promise<boolean> {
    const url = `/project/${request.data.projectId}/site/${request.data.siteId}/restore`;

    await this.uploadSiteRestoreUseCase(this.siteUploader, url);
    await this.restoreSite(request.data.siteId);
    return true;
  }
}
