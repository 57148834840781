import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ReactElement } from 'react';

export function DocumentationRowIcon({
  type,
  disabled,
}: {
  type?: 'DIRECTORY';
  disabled?: boolean;
}): ReactElement {
  return type === 'DIRECTORY' ? (
    <FolderOpenIcon htmlColor={disabled ? '#e0e0e0' : '#979797'} />
  ) : (
    <InsertDriveFileOutlinedIcon
      htmlColor={disabled ? '#e0e0e0' : '#979797'}
    />
  );
}
