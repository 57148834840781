import * as actions from '../actionTypes';
import { UiReducerType } from '../../setup/types/core';
import { AnyAction } from 'redux';
import { AppLocales } from '../../intl/IntlProviderWrapper';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { getDefaultLocale } from 'intl/helpers';

const initialHeaders = [
  'lp',
  'issueNumber',
  'process',
  IssueFieldNames.title,
  'stage',
  IssueFieldNames.site,
  IssueFieldNames.creationDate,
  IssueFieldNames.finalCompletionDate,
  IssueFieldNames.modificationDate,
  IssueFieldNames.estimatedCost,
  IssueFieldNames.finalCost,
];

export const initialState: UiReducerType = {
  currentLocale: getDefaultLocale(),
  sidebarOpen: false,
  isFormEditing: {},
  searchPhrase: '',
  editingEventId: '',
  issueView: {
    viewType: 0,
    tableHeaders: initialHeaders,
  },
  toaster: {
    toasterVisible: false,
    toasterMessage: undefined,
    toasterType: undefined,
    hideDelay: undefined,
  },
};

const uiReducer = (
  state = initialState,
  action: AnyAction
): UiReducerType => {
  if (action.type === actions.SHOW_TOASTER) {
    const { type, ...actionBody } = action;
    return {
      ...state,
      toaster: {
        ...actionBody,
        toasterVisible: true,
      },
    };
  }

  if (action.type === actions.HIDE_TOASTER) {
    return {
      ...state,
      toaster: {
        toasterMessage: undefined,
        toasterVisible: false,
        toasterType: undefined,
      },
    };
  }

  if (action.type === actions.TOGGLE_FILTERS_SIDEBAR) {
    return {
      ...state,
      sidebarOpen: !state.sidebarOpen,
    };
  }

  if (action.type === actions.SET_LANGUAGE) {
    return {
      ...state,
      currentLocale: action.payload.locale,
    };
  }

  if (action.type === actions.SET_FORM_EDITING) {
    const form = action.form;
    if (state.isFormEditing[form] === action.isFormEditing) {
      return state;
    }

    return {
      ...state,
      isFormEditing: {
        ...state.isFormEditing,
        [form]: action.isFormEditing,
      },
    };
  }

  if (action.type === actions.CLEAR_FORMS_EDITING) {
    return {
      ...state,
      isFormEditing: {},
    };
  }

  if (action.type === actions.SEARCH_VALUE_SET) {
    return {
      ...state,
      searchPhrase: action.payload,
    };
  }

  if (action.type === actions.TABLE_SET_VISIBLE_COLUMNS) {
    return {
      ...state,
      issueView: {
        ...state.issueView,
        tableHeaders: action.payload,
      },
    };
  }

  if (action.type === actions.TABLE_SET_SORTING) {
    const { headerName, sorting } = action.payload;

    return {
      ...state,
      issueView: {
        ...state.issueView,
        tableSorting: {
          headerName,
          sorting,
        },
      },
    };
  }

  if (action.type === actions.TABLE_CLEAR_SORTING) {
    if (state.issueView.tableSorting) {
      delete state.issueView.tableSorting;
    }

    return state;
  }

  if (action.type === actions.SET_ISSUE_VIEW_TYPE) {
    return {
      ...state,
      issueView: {
        ...state.issueView,
        viewType: action.payload,
      },
    };
  }

  if (action.type === actions.USER_LOGOUT) {
    return {
      ...initialState,
      issueView: {
        ...initialState.issueView,
        viewType: state.issueView.viewType,
      },
    };
  }

  if (action.type === actions.LOCK_EVENT_FOR_EDITING) {
    return {
      ...state,
      editingEventId: action.payload.eventId,
    };
  }

  if (action.type === actions.UNLOCK_EVENT_EDITING) {
    return {
      ...state,
      editingEventId: '',
    };
  }

  if (action.type === actions.VERSION_MISMATCH_ACTION_TYPE) {
    return { ...state, mismatchedVersion: true };
  }

  return state;
};

export default uiReducer;
