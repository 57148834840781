import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface EnvironmentalAspectsServiceData extends ServiceData {}
const { add, clear, addSync, get, reset } =
  entityServiceFactory<EnvironmentalAspectsServiceData>(
    db,
    'environmentalAspectsService'
  );

export { add, clear, addSync, get, reset };
