import {
  createContext,
  ReactElement,
  useEffect,
  useMemo,
  ComponentType,
  useContext,
} from 'react';
import { HashMap } from 'shared/types/commonView';
import { useFieldVisibility } from '.';
import { Store, useCreateStore } from 'hooks/createStore';
import { toVisibleFieldModelMap } from 'shared/domain/visibleField/visibleFieldsMap';

type FieldVisibilityMapContextType = {
  fieldVisibilityMapStore: Store<HashMap<HashMap<boolean>>>;
  loading: boolean;
};
const FieldVisibilityMapContext = createContext<
  FieldVisibilityMapContextType | undefined
>(undefined);

const WithFieldVisibilityMap = ({ children }): ReactElement => {
  const { fieldVisibilityStore, loading } = useFieldVisibility();
  const fieldVisibilityMapStore = useCreateStore(
    toVisibleFieldModelMap(fieldVisibilityStore.get())
  );

  useEffect(() => {
    fieldVisibilityMapStore.set(
      toVisibleFieldModelMap(fieldVisibilityStore.get())
    );
    return fieldVisibilityStore.subscribe(() => {
      fieldVisibilityMapStore.set(
        toVisibleFieldModelMap(fieldVisibilityStore.get())
      );
    });
  }, [fieldVisibilityStore, fieldVisibilityMapStore]);

  const ctx: FieldVisibilityMapContextType = useMemo(
    () => ({
      fieldVisibilityMapStore,
      loading,
    }),
    [fieldVisibilityMapStore, loading]
  );

  return (
    <FieldVisibilityMapContext.Provider value={ctx}>
      {children}
    </FieldVisibilityMapContext.Provider>
  );
};

const withFieldVisibilityMap =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithFieldVisibilityMap>
      <Component {...props} />
    </WithFieldVisibilityMap>
  );

function useFieldVisibilityMap(): FieldVisibilityMapContextType {
  const context = useContext(FieldVisibilityMapContext);
  if (context === undefined) {
    throw new Error(
      'useFieldVisibilityMap must be used within a FieldVisibilityMapContextProvider'
    );
  }
  return context;
}

export {
  WithFieldVisibilityMap,
  withFieldVisibilityMap,
  useFieldVisibilityMap,
};
