import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startProjectDataSynchronization(): void {
  debugLog('startProjectDataSynchronization');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.USERS,
    method: ServiceMethods.PULL,
  });
  broadcast.postMessage({
    service: Services.ISSUES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.COMPANIES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.CONTRACTS,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.INSPECTION_TEMPLATES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.INSPECTIONS,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.SITES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.WORKTYPES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.HAZARD_CATEGORIES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.ENVIRONMENTAL_ASPECTS,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.CORRECTIVE_ACTION_TYPES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.LEVELS,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.DOCUMENTATIONS,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.DIRECTORIES,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.ISSUE_FORMS,
    method: ServiceMethods.SYNC,
  });
  broadcast.postMessage({
    service: Services.VISIBLE_FIELDS,
    method: ServiceMethods.SYNC,
  });
  broadcast.close();
}
