import {
  Autocomplete,
  AutocompleteRenderInputParams,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import React, {
  Ref,
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { DependanceBehavior } from 'shared/types/form';
import {
  getAutocompleteRenderInput,
  getFreeSoloProps,
  getHelperTextFromError,
  getLocalizedLabel,
  getSiteIdFromForm,
  isOptionEqualToValue,
  unifiedGetOptionLabel,
} from '../common';
import { FreeSoloSingleAutocomplete } from './freeSoloSingleAutocomplete';
import { SingleChoiceProps, SingleChoiceValue } from './types';
import { galleryRootZIndex } from 'components/common/gallery/styles';

const SingleChoice = (
  {
    available,
    value,
    handleChange,
    disabled,
    id,
    required,
    labelId,
    localisedLabel,
    error,
    disableClearable,
    hidden,
    field,
    entityName,
    reserveSpaceForHelperText,
    dense,
    formValuesGetter,
  }: SingleChoiceProps,
  ref: Ref<HTMLDivElement>
): React.ReactElement => {
  const intl = useIntl();
  const hideBehaviorSx =
    field?.dependsOn?.behavior === DependanceBehavior.hidden
      ? {
          animation: 'fadeInFromNone 0.33s ease-out',
          height: 'auto',
        }
      : {};
  const hiddenSx = hidden
    ? {
        opacity: 0,
        height: 0,
        display: 'none',
      }
    : {};
  const label = useMemo(
    () => getLocalizedLabel(intl, localisedLabel, labelId),
    [intl, localisedLabel, labelId]
  );
  const autocompleteHandleChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      value: SingleChoiceValue | null
    ) => {
      handleChange(event, value);
    },
    [handleChange]
  );

  const selectHandleChange = useCallback(
    (event: any, _: React.ReactNode) => {
      handleChange(
        event,
        available.find((item) => item._id === event.target.value)
      );
    },
    [handleChange, available]
  );
  let helperText =
    getHelperTextFromError(error, intl) ||
    // TODO MUI5 pomysł jest dobry, ale wyświetla się to od razu przy podlokalizacji na dialogu tworzenia issue - trzeba to przemyśleć
    //  (available.length === 0 &&
    //   intl.formatMessage({ id: 'autocomplete_no_options' })) ||
    undefined;

  let siteId = '';
  if (entityName === EditableStandardEntityName.level) {
    siteId = getSiteIdFromForm(formValuesGetter);
    if (!siteId) {
      // TODO: workaround until we disable fields properly on form level based on dependsOn values
      disabled = true;
      helperText = intl.formatMessage(
        { id: 'tooltip_issue_form_depended_not_filled' },
        { fieldLabel: intl.formatMessage({ id: 'primaryField_Site' }) }
      );
    }
  }
  const sx = { ...hideBehaviorSx, ...hiddenSx };

  const freeSoloProps = entityName && getFreeSoloProps(entityName);

  const autoCompleteInputRenderer = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return getAutocompleteRenderInput({
        required,
        error,
        label,
        disabled: !!disabled,
        id: `${id}-textField`,
        placeholder: '',
        dense,
      })(params);
    },
    [required, error, label, disabled, id, dense]
  );

  const autoCompleteProps = useMemo(
    () => ({
      options: available,
      disabled: Boolean(disabled),
      value: value || undefined,
      isOptionEqualToValue: isOptionEqualToValue,
      id: `${id}-singleAutocomplete`,
      onChange: autocompleteHandleChange,
      ref,
      renderInput: autoCompleteInputRenderer,
      renderOption: (props, item) => (
        <List {...props} key={item._id}>
          <ListItemText>{item.label}</ListItemText>
        </List>
      ),
      getOptionLabel: unifiedGetOptionLabel,
    }),
    [
      available,
      disabled,
      value,
      id,
      autocompleteHandleChange,
      ref,
      autoCompleteInputRenderer,
    ]
  );
  // popper stays open after click on map on issue preview
  // field?.name is provided only on issue form so its nasty workaround
  if (field?.name) {
    //@ts-ignore
    autoCompleteProps.slotProps = {
      popper: { sx: { zIndex: galleryRootZIndex - 1 } },
    };
  }

  const availableForSelect = useMemo(() => {
    const listForSelect: Array<LabelledEntity | ''> = [...available];
    if (listForSelect.length === 0 && value) {
      listForSelect.push(value);
    }
    if (!required) {
      listForSelect.unshift('');
    }
    return listForSelect;
  }, [available, required, value]);
  // on why we provide label twice https://stackoverflow.com/a/67068903
  const displayedLabel = `${label}${required ? ' *' : ''}`;

  if (freeSoloProps) {
    return (
      <freeSoloProps.Provider>
        <FormControl
          data-qa={'form-control-single-freeSolo'}
          fullWidth
          ref={ref}
          sx={sx}
        >
          <FreeSoloSingleAutocomplete
            autoCompleteProps={autoCompleteProps}
            useProvider={freeSoloProps.useProvider}
            siteId={siteId}
          />
          {freeSoloProps.DialogComponent}
          <FormHelperText error={!!error}>
            {helperText || (reserveSpaceForHelperText && ' ')}
          </FormHelperText>
        </FormControl>
      </freeSoloProps.Provider>
    );
  }

  if (available.length > 5) {
    // TODO add empty option like in select below
    return (
      <FormControl
        data-qa={'form-control-singleAutocomplete'}
        fullWidth
        ref={ref}
        sx={sx}
      >
        <Autocomplete {...autoCompleteProps} />
        <FormHelperText error={!!error}>
          {helperText || (reserveSpaceForHelperText && ' ')}
        </FormHelperText>
      </FormControl>
    );
  }

  return (
    <FormControl
      data-qa={'form-control-single-select'}
      fullWidth
      ref={ref}
      sx={sx}
      margin={dense ? 'dense' : 'none'}
    >
      <InputLabel id={`${id}-inputLabel`}>{displayedLabel}</InputLabel>
      <Select
        sx={{
          '&:hover .MuiSvgIcon-root': {
            visibility: 'visible',
          },
        }}
        value={value?._id || ''}
        label={displayedLabel}
        onChange={selectHandleChange}
        id={`${id}-select`}
        disabled={Boolean(disabled)}
        required={!!required}
      >
        {availableForSelect.map((option) => (
          <MenuItem
            sx={{ height: '40px' }}
            key={option ? option._id : 0}
            value={option ? option._id : undefined}
          >
            {option ? option.label : ''}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error}>
        {helperText || (reserveSpaceForHelperText && ' ')}
      </FormHelperText>
    </FormControl>
  );
};

const ForwardedSingleChoice = forwardRef<
  HTMLDivElement,
  SingleChoiceProps
>(SingleChoice) as React.ForwardRefExoticComponent<SingleChoiceProps>;

export const MemoSingleChoice = memo(ForwardedSingleChoice);
