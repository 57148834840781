import { MemoProcessIcon } from 'components/common/ProcessIcon';
import { ProcessIconSize } from 'components/common/ProcessIcon/types';
import { ReactElement } from 'react';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';

const processWithCodeStyles = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  alignItems: 'center',
  textOverflow: 'ellipsis' as 'ellipsis',
  whiteSpace: 'nowrap' as 'nowrap',
  overflow: 'hidden' as 'hidden',
};
const processWithCodeMargin = { marginLeft: '10.5px' };
export function processCodeRenderer(
  processes: HashMap<ProcessType>
): (props: {
  rowData: { process: string; code: string };
}) => ReactElement {
  return ({ rowData }): ReactElement => {
    const process = processes[rowData.process];

    return (
      <div style={processWithCodeStyles}>
        <MemoProcessIcon
          code={process.code}
          color={process.color}
          size={ProcessIconSize.small}
        />
        <span style={processWithCodeMargin}>{rowData.code}</span>
      </div>
    );
  };
}
