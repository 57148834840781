import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { makeSimpleBroadcastError } from '../factories';

export function broadcastCreatedCompanyError(error: any): void {
  debugLog('broadcastCreatedCompanyError', error);
  broadcastCompanyError(DomainMessagesTypes.createdEntity, error);
}
const broadcastCompanyError = makeSimpleBroadcastError(
  ChannelNames.companyChannel
);

export const broadcastUploadedCompanyError = (error: any): void => {
  debugLog('broadcastUploadedCompanyError', error);
  broadcastCompanyError(DomainMessagesTypes.failUploadedEntity, error);
};
