import { LevelOnView } from 'shared/domain/level/types/view';
import { SiteModel } from 'shared/domain/site/types/model';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { HashMap } from 'shared/types/commonView';
import { SiteOnView } from '../../shared/domain/site/types/view';

export function siteModelToSiteOnView(
  model: SiteModel,
  levelsPerSite: HashMap<LevelOnView[]>
): SiteOnView {
  return {
    createdAt: stringToViewDate(model.createdAt),
    modifiedAt: stringToViewDate(model.modifiedAt),
    _id: model._id,
    label: model.label,
    deleted: model.deleted,
    createdBy: {
      _id: model.createdBy?._id || '',
      email: model.createdBy?.email || '',
      label: model.createdBy?.label || '',
    },
    modifiedBy: {
      _id: model.modifiedBy?._id || '',
      email: model.modifiedBy?.email || '',
      label: model.modifiedBy?.label || '',
    },
    code: model.code,
    levels: levelsPerSite[model._id] || [],
  };
}
