import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface CorrectiveActionTypesServiceData extends ServiceData {}
const { add, clear, addSync, get, reset } =
  entityServiceFactory<CorrectiveActionTypesServiceData>(
    db,
    'correctiveActionTypesService'
  );

export { add, clear, addSync, get, reset };
