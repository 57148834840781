import { useCallback, useMemo, useRef } from 'react';

export type Store<T = object> = {
  get: () => T;
  set: (value: T) => void;
  subscribe: (callback: () => void) => () => void;
};

function isEqual(prev: any, current: any): boolean {
  return prev === current;
}

export function useCreateStore<K = object>(
  initialData: K,
  isEqualCheck = isEqual
): Store<K> {
  const storeRef = useRef<K>(initialData);
  const subscribers = useRef(new Set<() => void>());

  const get = useCallback(() => {
    return storeRef.current;
  }, []);

  const set = useCallback(
    (data: K) => {
      if (isEqualCheck(storeRef.current, data)) return;
      storeRef.current = data;
      subscribers.current.forEach((callback) => callback());
    },
    [isEqualCheck]
  );

  const subscribe = useCallback((callback: () => void) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return useMemo(
    () => ({
      get,
      set,
      subscribe,
    }),
    [get, set, subscribe]
  );
}

export function subscribeMany(
  stores: Store<any>[],
  callback: () => any
): () => void {
  const unsubscribeArray = stores.map((store) =>
    store.subscribe(callback)
  );
  return () => {
    unsubscribeArray.forEach((unsubscribe) => unsubscribe());
  };
}
