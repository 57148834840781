import { Switch } from '@mui/material';
import { FieldBox } from 'components/fieldBox';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { ReactElement } from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';

export function FieldVisibilityBox({
  label,
  disabled,
  fieldName,
  isChecked,
  onChange,
}: {
  label: string;
  disabled: boolean;
  fieldName: IssueFieldNames;
  isChecked: boolean;
  onChange: (event: any, value: boolean) => void;
}): ReactElement {
  return (
    <FieldBox dataQa={`field-visibility-box-${fieldName}`}>
      <span
        style={{
          flexGrow: 1,
        }}
      >
        {label}
      </span>
      <ForbiddenTooltip
        visibleWhen={disabled}
        reason='field_visibility_switch_disabled'
      >
        <Switch
          id={fieldName}
          checked={isChecked}
          disabled={disabled}
          color='primary'
          onChange={onChange}
          data-qa={`field-visibility-box-switch-${fieldName}`}
        />
      </ForbiddenTooltip>
    </FieldBox>
  );
}
