import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { Spinner } from 'components/core';
import { useEditLevel } from 'components/dataCreationForms/level/withEditLevel';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { MemoLevelForm } from 'components/level/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { FORM_NAME } from 'views/level/model';

function EditLevelWizardDialog(): React.ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    existingValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useEditLevel();
  useFormCleanupOnUnmount(FORM_NAME);
  return (
    <Dialog
      open={open}
      // sx={{ width: { xs: 1, sm: 2, lg: 3, xl: 4 } }}
      fullWidth
      onClose={closeDialog}
    >
      <DialogTitle>
        <FormattedMessage id='edit_level' />
      </DialogTitle>
      <Divider />
      {existingValues ? (
        <DialogContent>
          <WithInputForm
            values={existingValues}
            errors={{}}
            handleFormValidation={handleFormValidation}
            handleFieldValidation={validate}
            onSubmit={submitForm}
            eventType={SUBMIT_EVENT_TYPE}
            dispatchSubmit={releaseSubmitEvent}
            formName={FORM_NAME}
          >
            <MemoLevelForm levelId={existingValues._id} />
          </WithInputForm>
        </DialogContent>
      ) : (
        <Spinner
          reason={`EditLevelWizardDialog waiting for existing values: ${existingValues}`}
        />
      )}
      <Divider />
      <DialogActions>
        <DialogButtons
          isPosting={isPosting}
          onSubmit={releaseSubmitEvent}
          onCancel={closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}

export const MemoEditLevelWizardDialog = memo(EditLevelWizardDialog);
