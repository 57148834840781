import { Tooltip } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';
import { Color } from 'shared/utils/colors';
import {
  drawingDataForUnselectedPin,
  whiteXMark,
} from './NonInteractivePinIcon';

export function InteractiveUnselectedPinIcon({
  color,
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  tooltip,
  onMouseOver,
  onMouseLeave,
}: {
  color: Color;
  handleMouseDown: MouseEventHandler;
  handleMouseMove: MouseEventHandler;
  handleMouseUp: MouseEventHandler;
  tooltip: string;
  onMouseOver: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
}): ReactElement {
  return (
    <Tooltip title={tooltip}>
      <g
        pointerEvents='all'
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        cursor='pointer'
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <path
          pointerEvents='all'
          fillRule='evenodd'
          clipRule='evenodd'
          d={drawingDataForUnselectedPin}
          fill={color}
        />
        {whiteXMark}
      </g>
    </Tooltip>
  );
}
