import { useOrganizationChannelListener } from 'components/broadcastChannelListeners/withOrganizationChannelListener';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { debugLog } from 'shared/logger/debugLog';

function isMatchingMessage(event: Message): boolean {
  debugLog('isMatchingMessage', event.data, event.type);

  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allOrganizations
  );
}

export const useGetAllOrganizations = makeUseGetAll<OrganizationModel>({
  useChannelListener: useOrganizationChannelListener,
  postMessageChannel: ChannelNames.organizationChannel,
  postMessageType: DomainMessagesTypes.getAllOrganizations,
  isMatchingMessage,
  entityName: 'organizations',
});
