import db from './index';
import { clear as contractsServiceClear } from './contractsService';
import { entityDbClearFactory } from './entityDbClearFactory';
import { ContractEntity } from 'serviceWorker/repository/contract/entity';
import {
  makeDefaultAddBatchMapped,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { ContractInDto } from 'shared/dtos/in/contract';
import { contractInDtoToEntity } from 'serviceWorker/repository/contract/mapping';
import { makeBroadcastClear } from 'serviceWorker/broadcasts/factories';
import { ChannelNames } from 'shared/domain/channelNames';
import { PromiseExtended } from 'dexie';
import { Changes } from 'shared/types/commonEntities';
import { LogLevel } from 'shared/types/logger';
import { logWithRethrow, getErrorMsg, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';

export const clear = entityDbClearFactory(
  db,
  ['contracts', 'contractsService'],
  () => db.contracts.clear(),
  contractsServiceClear,
  makeBroadcastClear(ChannelNames.contractChannel)
);

export const quantity = makeDefaultCount<ContractEntity>(db, db.contracts);

export const get = makeDefaultGetMany<ContractEntity>(db, db.contracts, {
  deleted: 0,
});

export const getByIds = makeDefaultGetManyByIds<string, ContractEntity>(
  db,
  db.contracts,
  '_id'
);

export const getOne = makeDefaultGetOne<string, ContractEntity>(
  db,
  db.contracts,
  '_id'
);

export const addBatch = makeDefaultAddBatchMapped<
  ContractInDto,
  ContractEntity
>(db, db.contracts, contractInDtoToEntity);

export const updateOne = wrapQuery(
  db,
  (
    contractId: string,
    changes: Changes<ContractEntity>
  ): PromiseExtended => {
    return db.contracts.update(contractId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'contracts'),
        errorObj: e,
        additionalInfo: { query: { data: { contractId, changes } } },
      })
    );
  }
);

export const updateBatch = addBatch;

export const removeBatch = makeDefaultRemoveBatch<string, ContractEntity>(
  db,
  db.contracts,
  '_id'
);
