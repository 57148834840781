import { broadcastRemovedDocument } from 'serviceWorker/broadcasts/documents';
import { remove as removeDocument } from 'serviceWorker/db/documents';

interface HardRemoveDocument {
  (localId: number): Promise<unknown>;
}

export const hardRemoveDocument: HardRemoveDocument =
  async function _hardRemoveDocumentation(localId) {
    await removeDocument(localId);
    return broadcastRemovedDocument(localId);
  };
