import {
  IOrganizationUploader,
  OrganizationInDto,
  UploadOrganization,
  UploadOrganizationChanges,
} from 'shared/domain/organization/types/dto';
import { IRequestRunner } from '../httpRequestRunner';
import {
  OrganizationHttpCreateRequestModel,
  OrganizationHttpEditRequestModel,
} from './types';

export class OrganizationRequestRunner implements IRequestRunner {
  constructor(
    private organizationUploader: IOrganizationUploader,
    private uploadOrganizationUseCase: UploadOrganization,
    private saveOrganization: (
      data: OrganizationInDto
    ) => Promise<unknown>,
    private uploadOrganizationChangesUseCase: UploadOrganizationChanges
  ) {}
  async execute(
    request:
      | OrganizationHttpCreateRequestModel
      | OrganizationHttpEditRequestModel
  ): Promise<unknown> {
    switch (request.method) {
      case 'POST': {
        return this.uploadOrganization(
          request as OrganizationHttpCreateRequestModel
        );
      }
      case 'PUT': {
        return this.uploadOrganizationChanges(
          request as OrganizationHttpEditRequestModel
        );
      }
    }
  }

  private async uploadOrganization(
    request: OrganizationHttpCreateRequestModel
  ): Promise<OrganizationInDto> {
    const organizationInDto = await this.uploadOrganizationUseCase(
      this.organizationUploader,
      request.data.organizationCreateOutDto,
      request.url
    );
    const result = await this.saveOrganization(organizationInDto);
    return organizationInDto;
  }

  private async uploadOrganizationChanges(
    request: OrganizationHttpEditRequestModel
  ): Promise<unknown> {
    return this.uploadOrganizationChangesUseCase(
      this.organizationUploader,
      request.data.organizationEditOutDto,
      request.url
    );
  }
}
