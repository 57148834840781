import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { OrganizationInDto } from 'shared/domain/organization/types/dto';
import {
  broadcastUploadedOrganizationError,
  broadcastCreatedOrganizationError,
} from 'serviceWorker/broadcasts/organization/error';
import {
  broadcastCreatedOrganization,
  broadcastUpdatedOrganization,
} from 'serviceWorker/broadcasts/organization/one';

export function broadcastOrganizationResponse(
  request: HttpRequestEntity,
  response: OrganizationInDto | any
): void {
  if (response.error) {
    return broadcastUploadedOrganizationError(response.error);
  }
  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastCreatedOrganization(
      response,
      request.data.uniqueId
    ) as any;
  }
  if (request.method === 'PUT') {
    return broadcastUpdatedOrganization(
      response,
      request.data.uniqueId
    ) as any;
  }
}

export function broadcastOrganizationError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastCreatedOrganizationError(error);
}
