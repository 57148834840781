export enum ChannelNames {
  analyticsChannel = 'analyticsChannel',
  apiChannel = 'apiChannel',
  chartFiltersChannel = 'chartFilters',
  companyChannel = 'companyChannel',
  contractChannel = 'contractChannel',
  documentChannel = 'documentChannel',
  hazardCategoryChannel = 'hazardCategoryChannel',
  inspectionChannel = 'inspectionChannel',
  inspectionTemplateChannel = 'inspectionTemplateChannel',
  issueChannel = 'issueChannel',
  logoutChannel = 'logoutChannel',
  permissionsChannel = 'permissionsChannel',
  projectChannel = 'projectChannel',
  organizationChannel = 'organizationChannel',
  reportChannel = 'reportChannel',
  selectedProjectChannel = 'selectedProjectChannel',
  signedRequestChannel = 'signedRequestChannel',
  siteChannel = 'siteChannel',
  swNewVersionChannel = 'swNewVersionChannel',
  userChannel = 'userChannel',
  worktypeChannel = 'worktypeChannel',
  fieldValuesChannel = 'fieldValuesChannel',
  environmentalAspectChannel = 'environmentalAspectChannel',
  correctiveActionTypeChannel = 'correctiveActionTypeChannel',
  levelChannel = 'levelChannel',
  documentationChannel = 'documentationChannel',
  directoryChannel = 'directoryChannel',
  httpQueueChannel = 'httpQueueChannel',
  dbChangesListenerChannel = 'dbChangesListenerChannel',
  currentUserChannel = 'currentUserChannel',
  issueFormChannel = 'issueFormChannel',
  serviceWorkerRegisterationError = 'serviceWorkerRegisterationError',
  tokenChannel = 'tokenChannel',
  visibleFieldChannel = 'visibleFieldChannel',
  configChannel = 'configChannel',
}
