import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { getWarnableChanges } from 'shared/domain/documentation/documentationAsMapWarnings';
import { listenToDocumentationUpdatedMessage } from 'shared/domain/documentation/listenToDocumentationUpdatedMessage';
import { startDocumentationEdit } from 'shared/domain/documentation/startDocumentationEdit';
import { listenToLevelUpdatedMessage } from 'shared/domain/level/listenToLevelUpdatedMessage';
import { Message } from 'shared/domain/messages/message';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { useCurrentUserRoleInSelectedProject } from 'hooks/useCurrentUserRoleInSelectedProject';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  displayGenericErrorToaster,
  genericErrorToaster,
} from 'redux/actions/toasterActions';
import { DocumentationFile } from '../../dataProviders/withDocumentationController/types';
import { useLevels } from '../../dataProviders/withLevels';
import { useWarningsOnLevelChange } from './model';
import {
  DocumentationFields,
  DocumentationFormContext,
  SubmitForm,
} from './types';
import { showWarningsAsDialog } from './warningsDialog';

const WithDocumentationFormContext = createContext<
  DocumentationFormContext | undefined
>(undefined);

const WithDocumentationForm: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const {
    levels: { items: levels },
  } = useLevels();
  const compileWarnings = useWarningsOnLevelChange(getWarnableChanges);
  const createDialog = useDialog();

  const localIdRef = useRef<number | undefined>(undefined);
  const documentationRef = useRef<DocumentationFile | undefined>(
    undefined
  );

  const formName: 'documentation' = 'documentation';
  const SUBMIT_EVENT_TYPE = 'documentation_submit_event';
  const [isPosting, setIsPosting] = useState(false);
  const afterSubmitSuccess = useRef<CallableFunction | undefined>(
    undefined
  );

  const role = useCurrentUserRoleInSelectedProject();

  const [isOpen, setIsOpen] = useState(false);
  const confirmCancel = useCancelConfirmation('documentation');
  const closeDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setIsOpen(false);
  }, [confirmCancel]);
  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const submitForm: SubmitForm = useCallback(
    (values: DocumentationFields) => {
      if (typeof localIdRef.current !== 'number') {
        displayGenericErrorToaster(dispatch);
        return Promise.reject('LocalId is not a number.');
      }
      if (!documentationRef.current) {
        displayGenericErrorToaster(dispatch);
        return Promise.reject('DocumentationRef is undefined.');
      }
      const warnings = compileWarnings(
        values.levels,
        levels,
        documentationRef.current
      );
      return showWarningsAsDialog(
        createDialog,
        warnings,
        documentationRef.current
      ).then((result) => {
        if (!result.warningsConfirmed) {
          return;
        }
        if (typeof localIdRef.current !== 'number') {
          displayGenericErrorToaster(dispatch);
          return;
        }
        setIsPosting(true);
        const listener = isAdmin(role)
          ? listenToLevelUpdatedMessage
          : listenToDocumentationUpdatedMessage;
        const filter = isAdmin(role)
          ? (_?: any) => true
          : (message: Message) => {
              return message.data === localIdRef.current;
            };

        const broadcast = listener(
          () => {
            clearTimeout(timeout);
            setIsPosting(false);
            if (typeof afterSubmitSuccess.current === 'function') {
              afterSubmitSuccess.current();
            }
          },
          onError,
          filter
        );
        var timeout = setTimeout(onError, 15000);

        function onError(): void {
          broadcast.close();
          displayGenericErrorToaster(dispatch);
          setIsPosting(false);
        }

        startDocumentationEdit({ ...values, localId: localIdRef.current });
      });
    },
    [dispatch, role, compileWarnings, levels, createDialog]
  );
  const releaseSubmitEvent: () => void = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const setLocalId = useCallback((id) => {
    if (typeof id === 'number') localIdRef.current = id;
  }, []);

  const setDocumentation = useCallback((documentation) => {
    documentationRef.current = documentation;
  }, []);

  const setAfterSubmitSuccess = useCallback((action) => {
    if (typeof action === 'function' || typeof action === undefined)
      afterSubmitSuccess.current = action;
  }, []);

  const ctx = useMemo(() => {
    return {
      isOpen,
      closeDialog,
      openDialog,
      submitForm,
      isPosting,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      formName,
      setLocalId,
      setDocumentation,
      setAfterSubmitSuccess,
    };
  }, [
    isOpen,
    closeDialog,
    openDialog,
    isPosting,
    releaseSubmitEvent,
    submitForm,
    setLocalId,
    setDocumentation,
    setAfterSubmitSuccess,
  ]);
  return (
    <WithDocumentationFormContext.Provider value={ctx}>
      {children}
    </WithDocumentationFormContext.Provider>
  );
};

export function useDocumentationForm(): DocumentationFormContext {
  const context = useContext(WithDocumentationFormContext);
  if (context === undefined) {
    throw new Error(
      'useDocumentationForm must be used within an DocumentationFormContext'
    );
  }
  return context;
}

export const withDocumentationForm =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithDocumentationForm>
      <Component {...props} />
    </WithDocumentationForm>
  );
