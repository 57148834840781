import {
  ErrorPresentation,
  isDateTimeValidation,
  manyOfValidation,
  notEmptyArrayValidation,
  oneOfValidation,
  requiredValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import {
  ProjectCreateOnView,
  ProjectEditOnView,
} from 'shared/domain/project/types/view';
import { LabelledEntity } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';
import { ISO6391LanguageCodes } from 'shared/types/locale';
import { Process } from 'shared/types/process';
import { ValidationSchema } from '../types';
import {
  makeHandleFormValidation,
  noValidation,
  validate,
} from '../validation';

// type ValidatedObject = ProjectCreateOnView | ProjectEditOnView;
const commonValidationSchema = {
  name: nameValidation,
  currency: currencyValidation,
  locale: localeCodeValidation,
  timezone: timezoneValidation,
  processes: processesValidation,
  budget: budgetValidation,
  projectEndDate: dateValidation,
  projectStartDate: dateValidation,
};
const projectCreateValidationSchema: ValidationSchema<ProjectCreateOnView> =
  {
    organization: organizationValidation,
    ...commonValidationSchema,
  };

const projectEditProjectValidationSchema: ValidationSchema<ProjectEditOnView> =
  {
    ...commonValidationSchema,
    _id: noValidation,
    organization: noValidation,
  };

function nameValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.L, value)
  );
}

function organizationValidation(value: any): ErrorPresentation {
  return requiredValidation(value) && requiredValidation(value?._id);
}

function currencyValidation(value: LabelledEntity): ErrorPresentation {
  return oneOfValidation(value?._id, ['TRY', 'EUR', 'PLN']);
}

function localeCodeValidation(value: LabelledEntity): ErrorPresentation {
  return oneOfValidation(value._id, ISO6391LanguageCodes);
}

function timezoneValidation(value: any): ErrorPresentation {
  return requiredValidation(value) || requiredValidation(value?._id);
}

function processesValidation(value: LabelledEntity[]): ErrorPresentation {
  return (
    notEmptyArrayValidation(value) ||
    manyOfValidation(
      Object.values(Process),
      value.map((v) => v?._id)
    )
  );
}

function budgetValidation(value: any): ErrorPresentation {
  return typeof value === 'number'
    ? undefined
    : !value
      ? undefined
      : undefined;
}

function dateValidation(value: any): ErrorPresentation {
  if (value === null || value === undefined) {
    return undefined;
  }
  return isDateTimeValidation(value);
}

export function validateProjectEdit(
  key: keyof ProjectEditOnView,
  value: ProjectEditOnView[keyof ProjectEditOnView]
): ErrorPresentation {
  return validate(key, value, projectEditProjectValidationSchema);
}

export function validateProjectCreate(
  key: keyof ProjectCreateOnView,
  value: ProjectCreateOnView[keyof ProjectCreateOnView]
): ErrorPresentation {
  return validate(key, value, projectCreateValidationSchema);
}

export const handleProjectEditFormValidation = makeHandleFormValidation(
  projectEditProjectValidationSchema
);

export const handleProjectCreateFormValidation = makeHandleFormValidation(
  projectCreateValidationSchema
);
