import React, { ReactElement } from 'react';
import {
  MemoStatefulSingleSelect,
  MemoStatefulAutocompleteWithText,
  StatefulEmailInput,
} from '../../dataCreationForms/withInputForm/statefulInputsWrapper';
import { ButtonContained, ButtonText } from 'components/general';
import { CircularProgress } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './styles';
import { FormFieldProps } from 'components/inspection/Form/types';
import { BaseReportModel } from 'shared/domain/report/reportModel';

type ReportFormPresentationalProps = {
  onCancel: () => void;
  onSubmit: () => void;
  isPosting: boolean;
  recipientsFieldProps: FormFieldProps<BaseReportModel>;
  nameFieldProps: FormFieldProps<BaseReportModel>;
  messageFieldProps: FormFieldProps<BaseReportModel>;
  localeCodeFieldProps: FormFieldProps<BaseReportModel>;
  ContentSelection: () => ReactElement | null;
};
export function ReportFormPresentational(
  props: ReportFormPresentationalProps
): React.ReactElement {
  const {
    onCancel,
    onSubmit,
    isPosting,
    recipientsFieldProps,
    nameFieldProps,
    messageFieldProps,
    localeCodeFieldProps,
    ContentSelection,
  } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <StatefulEmailInput {...recipientsFieldProps} />
      <MemoStatefulSingleSelect {...localeCodeFieldProps} />
      <MemoStatefulAutocompleteWithText {...nameFieldProps} />
      <MemoStatefulAutocompleteWithText {...messageFieldProps} />

      <ContentSelection />

      <div className={classes.submitButtons}>
        <ButtonText onClick={onCancel} disabled={isPosting}>
          <FormattedMessage id='general_cancel' />
        </ButtonText>
        <ButtonContained
          onClick={onSubmit}
          disabled={isPosting}
          endIcon={
            isPosting ? (
              <CircularProgress
                size={13}
                thickness={4}
                disableShrink
                color='secondary'
              />
            ) : undefined
          }
        >
          <FormattedMessage id='general_send' />
        </ButtonContained>
      </div>
    </div>
  );
}
