import {
  CreateContractCustomEventDetail,
  EditContractCustomEventDetail,
  GetContractEventDetail,
} from 'shared/domain/messages/contract/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncContracts);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetContractEventDetail>(
      RepositoryMessagesTypes.getContract,
      {
        detail: { id: e.id },
      }
    )
  );
};

const create = (e: CreateContractCustomEventDetail): void => {
  debugLog('CREATE CONTRACT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateContractCustomEventDetail>(
      RepositoryMessagesTypes.addContract,
      {
        detail: {
          contractCreateModel: e.contractCreateModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const edit = (e: EditContractCustomEventDetail): void => {
  debugLog('EDIT CONTRACT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditContractCustomEventDetail>(
      RepositoryMessagesTypes.editContract,
      {
        detail: {
          contractEditModel: e.contractEditModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

export default { edit, sync, getOne, create };
