import React, { ReactElement, useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { isLogoutMessage } from './model';
import { useLogout } from '../WithLogout';
import { Message } from 'shared/domain/messages/message';

type WithLogoutSubscriberProps = { children: ReactElement };

export function WithLogoutSubscriber({
  children,
}: WithLogoutSubscriberProps): ReactElement {
  const { logout } = useLogout();

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.logoutChannel);

    broadcast.onmessage = (event: Message): void => {
      if (!isLogoutMessage(event)) {
        return;
      }
      broadcast.close();
      logout();
    };

    return (): void => {
      if (!broadcast.isClosed) {
        broadcast.close();
      }
    };
  }, [logout]);

  return children;
}
