import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useIssueChannelListener } from '../../broadcastChannelListeners/withIssueChannelListener';
import { useIssueFilters } from '../withIssueFilters';
import { useIssueGetParameters } from '../withIssueGetParameters';
import { activeFiltersSelector } from 'redux/selectors/issues';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { standariseFilters } from 'shared/utils/filters';
import { createUniqueId } from 'shared/utils/id/id';
import { Store, useCreateStore } from 'hooks/createStore';

type IssueAddress = { _id: string; site: string };
type IssueIdListContextType = {
  issueIdsStore: Store<IssueAddress[]>;
  loadingStore: Store<boolean>;
};
const IssueIdListContext = createContext<
  IssueIdListContextType | undefined
>(undefined);

export function WithIssueIdList({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const { subscribe } = useIssueChannelListener();
  const { filters } = useIssueFilters();
  const activeFilters = useMemo(
    () => activeFiltersSelector(filters),
    [filters]
  );
  const uniqueIdRef = useRef<string | undefined>(undefined);

  const { parametersStore } = useIssueGetParameters();
  const projectData = useSelector(projectDataSelector);
  const timezone = projectData.timezone;

  const issueIdsStore = useCreateStore<IssueAddress[]>([]);
  const loadingStore = useCreateStore<boolean>(true);

  useEffect(() => {
    return subscribe((event: Message) => {
      if (
        event.type !== DomainMessagesTypes.filteredIssueIdList ||
        event.uniqueId !== uniqueIdRef.current
      ) {
        return;
      }

      issueIdsStore.set(event.data);
      loadingStore.set(false);
    });
  }, [subscribe, issueIdsStore, loadingStore]);

  useEffect(() => {
    const action = (): void => {
      loadingStore.set(true);
      const uniqueId = createUniqueId();
      uniqueIdRef.current = uniqueId;

      const parameters = parametersStore.get();

      const searchFilter = parameters.search
        ? [{ name: 'search', value: parameters.search }]
        : [];

      const filterSet = standariseFilters(activeFilters);
      const searchSet = standariseFilters(searchFilter);
      const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
      broadcast.postMessage({
        type: DomainMessagesTypes.getFilteredIssueIdList,
        data: {
          filters: filterSet,
          search: searchSet,
          sort: parameters.sort,
          archived: parameters.deleted,
          timezone,
        },
        uniqueId: uniqueId,
      });
      broadcast.close();
    };
    action();
    return parametersStore.subscribe(() => {
      action();
    });
  }, [activeFilters, parametersStore, timezone, loadingStore]);

  const ctx = useMemo(() => {
    return {
      issueIdsStore,
      loadingStore,
    };
  }, [issueIdsStore, loadingStore]);

  return (
    <IssueIdListContext.Provider value={ctx}>
      {children}
    </IssueIdListContext.Provider>
  );
}

export function useIssueIdList(): IssueIdListContextType {
  const context = useContext(IssueIdListContext);
  if (context === undefined) {
    throw new Error(
      'useIssueIdList must be used within a IssueIdListContextProvider'
    );
  }
  return context;
}
