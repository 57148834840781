import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import * as config from 'serviceWorker/db/config';
import { HeadersData } from '../factories/types';
import { FetchRequest } from './fetchRequest';
import { IRequestRunner } from './httpRequestRunner';

export class ReportRequestRunner implements IRequestRunner {
  constructor(
    private getConfig: () => Promise<void | config.ConfigData>,
    private fetch: (
      headersData: HeadersData,
      httpRequest: FetchRequest,
      controller: AbortController
    ) =>
      | Promise<{
          status: number;
        }>
      | undefined
  ) {}
  async execute(request: __HttpRequestModel__): Promise<any> {
    const abortController = new AbortController();
    const setup = await this.getConfig();
    if (!setup) {
      throw new Error('Cannot get config');
    }

    request.url = this.buildUrl(
      request.data.projectId,
      request.data.reportData
    );
    request.data = request.data.reportData;

    const response = this.fetch(
      setup,
      request as FetchRequest,
      abortController
    );
    if (!response) {
      throw new Error('Cannot fetch.');
    }
    return response;
  }

  private buildUrl(
    projectId: string,
    reportData: {
      projectId: string;
      issues?: string[];
      issue?: string;
      inspection?: string;
    }
  ): string {
    if (reportData.inspection) {
      return `report/project/${projectId}/inspection/${reportData.inspection}`;
    }

    if (reportData.issue) {
      return `report/project/${projectId}/issue/${reportData.issue}`;
    }

    if (reportData.issues) {
      return `report/project/${projectId}/issue`;
    }

    throw new Error('Unknown report data.');
  }
}
