import {
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import { withDirectoryChannelListener } from 'components/broadcastChannelListeners/withDirectoryChannelListener';
import { withDocumentationChannelListener } from 'components/broadcastChannelListeners/withDocumentationChannelListener';
import {
  useDocumentationBrowseDialog,
  withDocumentationBrowseDialog,
} from 'components/common/withDocumentationBrowseDialog';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { MemoStatefulAutocompleteWithText } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import {
  useCurrentDirectoryContext,
  withCurrentDirectory,
} from 'components/dataProviders/withCurrentDirectory';
import { withDirectories } from 'components/dataProviders/withDirectories';
import { withDocumentationSearch } from 'components/dataProviders/withDocumentationSearch';
import { withDocumentations } from 'components/dataProviders/withDocumentations';
import { useLevels } from 'components/dataProviders/withLevels';
import { DocumentationBrowseDialog } from 'components/documentation/DocumentationBrowseDialog';
import { ButtonContained } from 'components/general';
import { FormFieldProps } from 'components/inspection/Form/types';
import { useCreateStore } from 'hooks/createStore';
import { useGetDocumentation } from 'hooks/useGetDocumentation';
import {
  ReactElement,
  memo,
  useEffect,
  useState,
  useSyncExternalStore,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

type LevelFormProps = {
  levelId?: string;
};
type LevelFormFC = (props: LevelFormProps) => ReactElement;
function _LevelForm({ levelId }: LevelFormProps): ReactElement {
  const { levels, loading } = useLevels();
  const { openDialog } = useDocumentationBrowseDialog();
  const selectedStore = useCreateStore<string | undefined>(undefined);
  const [mapPath, setMapPath] = useState<{ name: string }[]>([]);
  const intl = useIntl();
  const [documentation, setDocumentation] = useState<
    { name: string } | undefined
  >(undefined);
  const [initialSelect, setInitialSelect] = useState<string | undefined>(
    undefined
  );
  const [initialDirectory, setInitialDirectory] = useState<string>('');

  const { get: getOneDocumentation } = useGetDocumentation();

  const {
    currentDirectoryStore,
    currentDirectoryRootsStore,
    loadingCurrentDirectoryRootsStore,
  } = useCurrentDirectoryContext();

  const loadingRoots = useSyncExternalStore(
    loadingCurrentDirectoryRootsStore.subscribe,
    loadingCurrentDirectoryRootsStore.get
  );

  const isLoading = levelId && (loading || loadingRoots);

  type SetableLevelFields = {
    label: string;
  };

  const labelField: FormFieldProps<SetableLevelFields> = {
    formKey: `label`,
    required: true,
    labelId: 'primaryField_Level',
    fieldName: `level-label`,
    localStorageKey: 'level-label',
    dense: true,
    reserveSpaceForHelperText: true,
    'data-qa': 'level_label_field',
  };

  useEffect((): void => {
    const action = async (): Promise<void> => {
      if (!levelId) return;
      const levelModel = levels.items.find((l) => l._id === levelId);
      const documentationId = levelModel?.maps[0]?.documentationId;
      if (documentationId) {
        const documentation = await getOneDocumentation(documentationId);
        if (!documentation) return;
        currentDirectoryStore.set(documentation.parentId || '');
        setInitialDirectory(documentation.parentId || '');
        setDocumentation({ name: documentation.name });
        const selection = `${documentation.localId}_FILE`;
        selectedStore.set(selection);
        setInitialSelect(selection);
      }
    };
    action();
  }, [
    levels,
    levelId,
    getOneDocumentation,
    currentDirectoryStore,
    selectedStore,
  ]);

  useEffect(() => {
    setMapPath(currentDirectoryRootsStore.get());
    return currentDirectoryRootsStore.subscribe(() =>
      setMapPath(currentDirectoryRootsStore.get())
    );
  }, [currentDirectoryRootsStore]);

  return (
    <Box>
      <MemoStatefulAutocompleteWithText {...labelField} />
      <TextField
        multiline
        maxRows={2}
        variant='outlined'
        placeholder={intl.formatMessage({
          id: 'select_map',
        })}
        value={
          !documentation || isLoading
            ? ''
            : clampString(
                90,
                [...mapPath, documentation].map((v) => v.name).join('/')
              )
        }
        InputProps={{
          readOnly: true,
          style: {
            //@ts-ignore
            '& textarea': {
              overflowY: 'hidden',
            },
          },
          // className: classes.mapInput,
          startAdornment: isLoading ? (
            <CircularProgress
              size={13}
              thickness={4}
              disableShrink
              color='secondary'
            />
          ) : null,
          endAdornment: (
            <InputAdornment position='end'>
              <ButtonContained onClick={openDialog} disabled={loading}>
                <FormattedMessage id='general_select' />
              </ButtonContained>
            </InputAdornment>
          ),
        }}
      />
      <DocumentationBrowseDialog
        formKey='map'
        setValuesContext={useInputForm}
        setFilename={setDocumentation}
        selectedStore={selectedStore}
        title={mapPath[mapPath.length - 1]}
        initialSelect={initialSelect}
        initialDirectory={initialDirectory}
      />
    </Box>
  );
}

export const LevelForm: LevelFormFC = withDocumentationSearch(
  withCurrentDirectory(
    withDirectoryChannelListener(
      withDocumentationChannelListener(
        withDirectories(
          withDocumentations(withDocumentationBrowseDialog(_LevelForm))
        )
      )
    )
  )
);

function clampString(length: number, text: string): string {
  if (text.length > length) {
    return `/... ${text.slice(text.length - length, text.length)}`;
  }
  return text;
}

export const MemoLevelForm = memo(LevelForm);
