import {
  ChecklistBond,
  DocumentBindings,
  IssueBond,
  TemplateBond,
} from './documentModel';

export interface IDocumentBinder {
  binding(): DocumentBindings;
}

export const BINDINGS_BASE: DocumentBindings = {
  issueId: '',
  eventId: '',
  templateId: '',
  inspectionId: '',
  checklistId: '',
  protocolId: '',
};

export class DocumentToTemplateBinder implements IDocumentBinder {
  constructor(private parentBindings: TemplateBond) {}
  binding(): DocumentBindings {
    return { ...BINDINGS_BASE, ...this.parentBindings };
  }
}

export class DocumentToTemplateFromUrlBinder implements IDocumentBinder {
  private templateUrlRegex = RegExp('inspectionTemplate/([a-f0-9]*)/');
  constructor(private baseUrl: string) {}
  binding(): DocumentBindings {
    const result = this.baseUrl.match(this.templateUrlRegex);
    return {
      ...BINDINGS_BASE,
      templateId: result![1]!,
    };
  }
}

export class DocumentToIssueBinder implements IDocumentBinder {
  constructor(private parentBindings: IssueBond) {}
  binding(): DocumentBindings {
    return { ...BINDINGS_BASE, ...this.parentBindings };
  }
}

export class DocumentToEventFromUrlBinder implements IDocumentBinder {
  private issueEventUrlRegex = RegExp(
    'issue/([a-f0-9]*)/event/([a-f0-9]*)'
  );
  constructor(private baseUrl: string) {}
  binding(): DocumentBindings {
    const result = this.baseUrl.match(this.issueEventUrlRegex);
    return {
      ...BINDINGS_BASE,
      issueId: result![1]!,
      eventId: result![2]!,
    };
  }
}

export class DocumentToChecklistBinder implements IDocumentBinder {
  constructor(private parentBindings: TemplateBond & ChecklistBond) {}
  binding(): DocumentBindings {
    return { ...BINDINGS_BASE, ...this.parentBindings };
  }
}

export class DocumentToChecklistFromUrlBinder implements IDocumentBinder {
  private checklistUrlRegex = RegExp(
    'inspectionTemplate/([a-f0-9]*)/checklistItem/([a-f0-9]*)/'
  );
  constructor(private baseUrl: string) {}
  binding(): DocumentBindings {
    const result = this.baseUrl.match(this.checklistUrlRegex);
    return {
      ...BINDINGS_BASE,
      templateId: result![1]!,
      checklistId: result![2]!,
    };
  }
}

export class DocumentToProtocolFromUrlBinder implements IDocumentBinder {
  private protocolUrlRegex = RegExp(
    'inspection/([a-f0-9]*)/protocolItem/([a-f0-9]*)/'
  );
  constructor(private baseUrl: string) {}
  binding(): DocumentBindings {
    const result = this.baseUrl.match(this.protocolUrlRegex);
    return {
      ...BINDINGS_BASE,
      inspectionId: result![1]!,
      protocolId: result![2]!,
    };
  }
}
