import {
  ModifiableModel,
  CreatableModel,
  DeletableModel,
} from 'shared/domain/commonModel';
import { Identificable } from 'shared/types/commonView';

export enum ComplianceCheckResult {
  unset = 'UNSET',
  passed = 'PASSED',
  notPassed = 'NOT_PASSED',
  notApplicable = 'NOT_APPLICABLE',
}

export enum InspectionStatus {
  inProgress = 'IN_PROGRESS',
  completed = 'COMPLETED',
}

export type ComplianceCheckModel = {
  readonly comment?: string;
  readonly issues: string[];
  readonly result: ComplianceCheckResult;
};

export type ProtocolItemModel = {
  complianceCheck: ComplianceCheckModel;
  templateChecklistItem: string;
} & ModifiableModel &
  Identificable;

export type InspectionModel = {
  isCompleted: boolean;
  readonly contracts: string[];
  readonly controlledParty: string[];
  readonly inspectionDate: string;
  readonly levels: string[];
  readonly protocol: ProtocolItemModel[];
  readonly site: string;
  readonly template: string;
  readonly workTypes: string[];
  readonly comment?: string;
} & CreatableModel &
  ModifiableModel &
  DeletableModel &
  Identificable;
