import { ButtonIcon } from 'components/general';
import {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useCreateStore, Store } from 'hooks/createStore';
import { ResponsivePanPinchZoom } from 'components/responsivePanPinchZoom';

const transformOptions = {};
const DefaultImageDisplayOverlay = (): null => null;

export function ImageDisplay({
  source,
  alt,
  ImageDisplayOverlay = DefaultImageDisplayOverlay,
}: {
  source: string | undefined;
  alt: string;
  ImageDisplayOverlay?: () => ReactElement | null;
}): ReactElement | null {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const zoomControlsRef = useRef({
    zoomIn: () => {},
    zoomOut: () => {},
  });
  const centerViewRef = useRef(
    (scale: number, animationDuration: number) => {}
  );

  const scaleStore = useCreateStore({
    scale: 1,
    maxScale: 1,
    minScale: 1,
  });
  const onTransformed = useCallback(
    (ref, { scale }: { scale: number }): void => {
      scaleStore.set({
        scale,
        minScale: ref.instance.props.minScale || 1,
        maxScale: ref.instance.props.maxScale || 1,
      });
      zoomControlsRef.current = {
        zoomIn: ref.zoomIn,
        zoomOut: ref.zoomOut,
      };
      centerViewRef.current = ref.centerView;
    },
    [scaleStore]
  );

  const onImageLoad = useCallback((): void => {
    centerViewRef.current(scaleStore.get().scale, 0);
  }, [scaleStore]);

  const imageRef = useRef(null);

  if (!source) return null;
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={(el: HTMLDivElement | null) => setContainer(el)}
    >
      <ResponsivePanPinchZoom
        onTransformed={onTransformed}
        transformOptions={transformOptions}
        imageRef={imageRef}
        imageSrc={source}
        imageLoading={false}
        imageError={undefined}
        container={container}
        minimumScaleFactor={-0.02}
        onImageLoad={onImageLoad}
      >
        <ImageDisplayOverlay />
      </ResponsivePanPinchZoom>
      <ScaleControllers
        scaleStore={scaleStore}
        zoomRef={zoomControlsRef}
      />
    </div>
  );
}

function ScaleControllers({
  zoomRef,
  scaleStore,
}: {
  zoomRef: { current: { zoomIn: () => void; zoomOut: () => void } };
  scaleStore: Store<{ scale: number; maxScale: number; minScale: number }>;
}): ReactElement {
  const buttonSizePx = 36;
  const [scale, setScale] = useState(scaleStore.get().scale);
  const [minScaleState, setMinScaleState] = useState(
    scaleStore.get().minScale
  );
  const [maxScaleState, setMaxScaleState] = useState(
    scaleStore.get().maxScale
  );

  useEffect(() => {
    scaleStore.subscribe(() => {
      setScale(scaleStore.get().scale);
      setMinScaleState(scaleStore.get().minScale);
      setMaxScaleState(scaleStore.get().maxScale);
    });
  }, [scaleStore]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        left: '1rem',
        top: '5rem',
        gap: '8px',
        width: `${buttonSizePx}px`,
      }}
    >
      <ButtonIcon
        style={{ backgroundColor: '#FFF', height: `${buttonSizePx}px` }}
        disabled={scale >= maxScaleState}
        onClick={() => zoomRef.current.zoomIn()}
      >
        <AddIcon />
      </ButtonIcon>
      <ButtonIcon
        style={{ backgroundColor: '#FFF', height: `${buttonSizePx}px` }}
        disabled={scale <= minScaleState}
        onClick={() => zoomRef.current.zoomOut()}
      >
        <RemoveIcon />
      </ButtonIcon>
    </div>
  );
}
