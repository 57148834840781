import React, { ReactElement } from 'react';
import { ChecklistItem } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import { MemoChecklistItemPreview } from '../ChecklistItemPreview';
import { ChecklistPreviewProps } from './types';
import { useParams } from 'react-router-dom';

function toReactChecklistItemPreview(
  checklistItem: ChecklistItem,
  index: number,
  prefix: string
): ReactElement {
  return (
    <MemoChecklistItemPreview
      key={checklistItem._id}
      checklistItem={checklistItem}
      index={index}
      prefix={prefix}
      canChangeUrl={true}
    />
  );
}

function ChecklistPreview(props: ChecklistPreviewProps): ReactElement {
  const { projectId } = useParams<{ projectId: string }>();
  const { checklist } = props;
  const items = checklist.map((item, index) =>
    toReactChecklistItemPreview(item, index, `/project/${projectId}`)
  );
  return <>{items}</>;
}

export const MemoChecklistPreview = React.memo(ChecklistPreview);
