import db from './index';
import { clear as environmentalAspectsServiceClear } from './environmentalAspectsService';
import { broadcastClearEnvironmentalAspects } from 'serviceWorker/broadcasts/environmentalAspects';
import { entityDbClearFactory } from './entityDbClearFactory';
import { EnvironmentalAspectEntity } from 'serviceWorker/repository/environmentalAspect/entity';
import {
  makeDefaultAddBatch,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';

export const clear = entityDbClearFactory(
  db,
  ['environmentalAspects', 'environmentalAspectsService'],
  () => db.environmentalAspects.clear(),
  environmentalAspectsServiceClear,
  broadcastClearEnvironmentalAspects
);

export const quantity = makeDefaultCount<EnvironmentalAspectEntity>(
  db,
  db.environmentalAspects
);

export const get = makeDefaultGetMany<EnvironmentalAspectEntity>(
  db,
  db.environmentalAspects
);

export const getOne = makeDefaultGetOne<string, EnvironmentalAspectEntity>(
  db,
  db.environmentalAspects,
  '_id'
);

export const addBatch = makeDefaultAddBatch<EnvironmentalAspectEntity>(
  db,
  db.environmentalAspects
);

export const updateBatch = addBatch;

export const removeBatch = makeDefaultRemoveBatch<
  string,
  EnvironmentalAspectEntity
>(db, db.environmentalAspects, '_id');
