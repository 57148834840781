import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import * as permissions from 'serviceWorker/db/permissions';
import { debounceNext } from 'serviceWorker/helpers/debounce';
import { broadcastCurrentUserPermissions } from 'shared/broadcastUserData';
import { LogLevel } from 'shared/types/logger';
import { swLog } from '../../helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { getFetchConfig } from '../config';
import { getSelectedProject } from '../selectedProject';
import { fetchPermisions } from './fetchPermissions';
import { arePermissionsEqual, toUserPermissions } from './model';

async function pullPermissionsHandler(): Promise<void> {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
  });
  const setup = await getFetchConfig();
  if (!setup) {
    return;
  }

  const selectedProject = await getSelectedProject();
  if (!selectedProject) {
    const userData = await fetchPermisions(setup, abortController);
    broadcastCurrentUserPermissions(
      userData,
      'pullPermissionsHandler: selectedProject'
    );
    const currentProjectsPermissions = toUserPermissions(userData);

    return permissions.addBatch(currentProjectsPermissions);
  }

  Promise.all([
    permissions.getOne(selectedProject._id),
    fetchPermisions(setup, abortController),
  ])
    ?.then(async ([previousPermissionToCurrentProject, resData]) => {
      const currentProjectsPermissions = toUserPermissions(resData);
      broadcastCurrentUserPermissions(
        resData,
        'pullPermissionsHandler: notSelectedProject'
      );
      await permissions.addBatch(currentProjectsPermissions);
      if (!previousPermissionToCurrentProject) {
        return;
      }

      const permissionToCurrentProject = currentProjectsPermissions.find(
        (currentPermission) => {
          return (
            currentPermission._id ===
            previousPermissionToCurrentProject?._id
          );
        }
      );

      if (
        !arePermissionsEqual(
          previousPermissionToCurrentProject,
          permissionToCurrentProject
        )
      ) {
        debugLog(
          'Permissions changed',
          previousPermissionToCurrentProject,
          permissionToCurrentProject
        );
        const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
        broadcast.postMessage({
          service: Services.PERMISSIONS,
          method: ServiceMethods.CHANGE,
        });
        broadcast.close();
      }
    })
    .catch((e: any) => {
      const msg = 'Problem occured when fetching permissions';
      swLog(msg, LogLevel.INFO, e, null);
    });
}

export const init = (): void => {
  const pullDebounced = debounceNext(pullPermissionsHandler, 3000);

  // On those events permissions are rechecked.
  self.addEventListener(
    RepositoryMessagesTypes.syncIssues,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncCompanies,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncContracts,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    DomainMessagesTypes.allUsers,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    DomainMessagesTypes.allProjects,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncInspectionTemplates,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncInspections,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncSites,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncLevels,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncCorrectiveActionTypes,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncEnvironmentalAspects,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncHazardCategories,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncWorktypes,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncDocumentations,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.syncVisibleFields,
    pullDebounced.bind(null)
  );
  self.addEventListener(
    RepositoryMessagesTypes.getAllOrganizations,
    pullDebounced.bind(null)
  );
};
