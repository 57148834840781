import React, { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import { useIntl } from 'react-intl';
import { MemoPreviewField } from 'components/general/PreviewField';
import { SingleContractPresentationalProps } from './types';
import { useCommonSingleEntityViewStyles } from '../../common/styles/useCommonSingleEntityViewStyles';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { EditOutlined } from '@mui/icons-material';
import { ButtonTextIcon } from 'components/general';
import useIsMobile from 'hooks/useIsMobile';

export function SingleContractPresentational({
  label,
  parties,
  editDisabledReason,
  isEditButtonDisabled,
  edit,
}: SingleContractPresentationalProps): ReactElement {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const classes = useCommonSingleEntityViewStyles({ isMobile });
  const partiesLabels = parties.map((party) => party.companyShortLabel);

  return (
    <div className={classes.root}>
      <h1>{label}</h1>
      <div className={classes.actionsContainer}>
        <div>
          <MemoForbiddenTooltip
            visibleWhen={isEditButtonDisabled}
            reason={editDisabledReason}
            inlined
          >
            <ButtonTextIcon
              disabled={isEditButtonDisabled}
              startIcon={EditOutlined}
              onClick={edit}
            >
              {intl.formatMessage({
                id: 'general_edit',
              })}
            </ButtonTextIcon>
          </MemoForbiddenTooltip>
        </div>
      </div>

      <Divider />
      <div className='row'>
        <MemoPreviewField
          isHalf
          label={intl.formatMessage({
            id: 'primaryField_Contract_numbers',
          })}
          value={label}
          dense={true}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({
            id: 'contract_parties',
          })}
          value={partiesLabels}
          dense={true}
        />
      </div>
    </div>
  );
}
