import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  imageContainer: {
    position: 'relative',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 1,
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
  imagePlaceholder: {
    background: 'rgba(0,0,0,0.1)',
    color: '#fff',
    position: 'absolute',
    left: 0,
    top: 0,
    fontSize: '0.75rem',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'opacity 0.1s linear',
    padding: '10px',
    boxSizing: 'border-box',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
  },
});

export default styles;
