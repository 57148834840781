import { DirectoryEditModel } from 'shared/domain/directory/directoryModel';
import { EditDirectoryUseCase } from 'shared/domain/directory/editDirectory';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { Changes } from 'shared/types/commonEntities';
import { DirectoryEntity } from 'serviceWorker/repository/directory/entity';
import { directoryEditModelToDirectoryChanges } from 'serviceWorker/repository/directory/mappings';
import { broadcastDirectoryEdited } from 'serviceWorker/broadcasts/directories';
import { updateOne as editDirectory } from 'serviceWorker/db/directories';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';

class DirectoryEditor implements EditDirectoryUseCase {
  constructor(
    private editDirectory: (
      id: number,
      data: Changes<DirectoryEntity>
    ) => Promise<number>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDirectoryEdited: (
      directory: [number],
      uniqueId: string
    ) => void
  ) {}
  async execute(
    directoryEditModel: DirectoryEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditDirectoryUseCase', directoryEditModel);
    const directoryChanges =
      directoryEditModelToDirectoryChanges(directoryEditModel);
    await this.editDirectory(directoryEditModel.localId, directoryChanges);

    this.broadcastDirectoryEdited([directoryEditModel.localId], uniqueId);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        localId: directoryEditModel.localId,
        name: directoryEditModel.name,
        projectId: currentProject?._id,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.directory,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const directoryEditor = new DirectoryEditor(
  editDirectory,
  addRequest,
  getCurrentProject,
  broadcastDirectoryEdited
);
