import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const styles = makeStyles((theme: Theme) => ({
  dropzone: {
    width: '100%',
    minHeight: '67px',
    background: '#F2F2F2',
    left: 0,
    top: 0,
    fontSize: '0.875rem',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '16px 16px 16px 0',
    boxSizing: 'border-box',
    overflowWrap: 'break-word',
    wordWrap: 'break-word',
    borderRadius: '5px',
    border: '2px solid transparent',
    color: '#BFBFBF',
    fontWeight: 400,
  },

  active: {
    borderColor: theme.palette.primary.main,
  },
}));

export default styles;
