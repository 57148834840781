import React from 'react';
import { Drawer } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Spinner } from 'components/core';
import Header from './Header';
import { styles } from '../FilterSidebarStyles';
import IssueTypeSwitch from '../IssueTypeSwitch';
import { FiltersType } from 'redux/selectors/filters';
import { DateFilters, FilterItems } from './FilterElem';
import { IssueCount } from 'components/dataProviders/withIssueCount';
import { CLOSE_FILTER_ITEM } from './model';

export type Props = {
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  isLoading?: boolean;
  resetFilters: () => void;
  createdFilters: FiltersType;
};

const Presentational: React.FC<Props> = ({
  sidebarOpen,
  toggleSidebar,
  isLoading,
  resetFilters,
  createdFilters,
}) => {
  const classes = styles();
  const resultsClass = classNames(classes.filterLabel, classes.results);

  return (
    <Drawer
      anchor='right'
      open={sidebarOpen}
      onClose={toggleSidebar}
      classes={{ paper: classes.drawer }}
    >
      {isLoading && (
        <Spinner
          withBackDrop
          reason='FilterSidebar Presentational isLoading'
        />
      )}
      <Header onClose={toggleSidebar} onReset={resetFilters} />
      <div
        className={classes.contentWrapper}
        onClick={(e) => {
          if ((e.target as HTMLElement).tagName === 'DIV') {
            window.dispatchEvent(new Event(CLOSE_FILTER_ITEM));
          }
        }}
      >
        <div className={classes.filterContent}>
          <div>
            <IssueTypeSwitch />
            <FilterItems elems={createdFilters.generalFields} />
            <DateFilters elems={createdFilters.dateFields} />
          </div>
          <div>
            <span className={resultsClass}>
              <FormattedMessage id='filters_results_label' />{' '}
              <IssueCount />
            </span>
          </div>
        </div>
      </div>
      <div className={classes.filler} />
    </Drawer>
  );
};

export default React.memo(Presentational);
