import { DateTime } from 'luxon';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { DateTypeIndicator } from 'setup/types/core';
import {
  isValidDate,
  isoToMuiDatepickerDate,
} from 'shared/utils/date/dates';
import { useIssueFilters } from '../../../../../dataProviders/withIssueFilters';
import { FilterDateType } from '../../../../../dataProviders/withIssueFilters/types';
import Presentational from './Presentational';
import { calculateDate } from './helpers';
import { debugLog } from 'shared/logger/debugLog';

type DateFilterProps = {
  label: string | { id: string };
  fromDate: string | null;
  toDate: string | null;
  filterName: string;
};

const DateFilter = ({
  label,
  fromDate,
  toDate,
  filterName,
}: DateFilterProps): React.ReactElement => {
  const { timezone } = useSelector(projectDataSelector);
  const { setFilter } = useIssueFilters();

  const displayedFromDate = isoToMuiDatepickerDate(fromDate, timezone);

  const displayedToDate = isoToMuiDatepickerDate(toDate, timezone);

  const toggleFilter = useCallback(
    (filterOptions: FilterDateType) => {
      setFilter(filterName, filterOptions);
    },
    [setFilter, filterName]
  );

  const clearFilter = useCallback(
    (dateType: DateTypeIndicator) => {
      const isFrom = dateType === DateTypeIndicator.FROM;
      toggleFilter({
        toDate: isFrom ? toDate : null,
        fromDate: isFrom ? null : fromDate,
      });
    },
    [fromDate, toDate, toggleFilter]
  );

  const dispatchFilterToggle = useCallback(
    (newDate: DateTime, dateType: DateTypeIndicator) => {
      const calculatedDateObject = calculateDate(
        newDate,
        toDate,
        fromDate,
        dateType
      );
      debugLog('dispatchFilterToggle', calculatedDateObject);
      toggleFilter(calculatedDateObject);
    },
    [fromDate, toDate, toggleFilter]
  );

  const handleDateChange = useCallback(
    (
      newDate: DateTime | null,
      indicator: DateTypeIndicator,
      str: any
    ): void => {
      if (!newDate) {
        clearFilter(indicator);
        return;
      }
      if (!isValidDate(newDate)) {
        return;
      }

      dispatchFilterToggle(newDate, indicator);
    },
    [clearFilter, dispatchFilterToggle]
  );

  const handleDateClear = useCallback(
    (indicator: DateTypeIndicator): void => {
      clearFilter(indicator);
    },
    [clearFilter]
  );

  return (
    <Presentational
      filterName={filterName}
      fromDateObject={displayedFromDate}
      // @ts-ignore
      handleDateChange={handleDateChange}
      handleDateClear={handleDateClear}
      toDateObject={displayedToDate}
      label={label}
    />
  );
};

export default DateFilter;
