import {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { CircularProgress } from '@mui/material';
import CanvasDraw from 'react-canvas-draw';

import useDebounce from 'hooks/useDebounce';
import useResize, { WindowSize } from 'hooks/useResize';

import { BrushOutlined as DrawingIcon } from '@mui/icons-material';

import { useDrawComponentStyles } from './styles';

import {
  ButtonContained,
  ButtonIcon,
  ButtonText,
} from 'components/general';
import { useDispatch } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { DrawingType, FileType } from '../graphicUploader/types';

import { useBlobFileStorage } from 'components/core/BlobFileStorage';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { useIntl } from 'react-intl';
import { logError } from 'setup/logger/logError';
import { noop } from 'shared/utils/function';
import { ClearIcon, UndoIcon } from './icons';

type CanvasDrawElement = CanvasDraw & {
  ctx: { drawing: { canvas: HTMLCanvasElement } };
  image: HTMLImageElement;
  drawImage: () => void;
};
type DrawProps = {
  file: FileType;
  container: HTMLElement;
  setDrawMode: (mode: boolean) => void;
  addDrawing: (drawing: DrawingType, idx: number) => void;
  idx: number;
  drawing: DrawingType;
};

const DEBOUNCE_TIME = 200;
const Draw = ({
  file,
  container,
  setDrawMode,
  addDrawing,
  idx,
  drawing,
}: DrawProps): ReactElement | null => {
  const processingRef = useRef(false);
  const classes = useDrawComponentStyles();
  const resize = useResize();
  const intl = useIntl();
  const dispatch = useDispatch();
  const blobFileStorage = useBlobFileStorage();
  const windowSize = useDebounce(resize, DEBOUNCE_TIME);
  const [data, setData] = useState(drawing?.data || '');
  const [initialDrawing] = useState(drawing?.data || '');

  const [cleared, setCleared] = useState(drawing?.clear || false);
  const [processing, _setProcessing] = useState(false);
  const [src, setSrc] = useState(
    cleared
      ? file.signedRequest?.signedRequest || file.downloadSrc
      : file.downloadSrc
  );
  const setProcessing = useCallback((value: boolean): void => {
    processingRef.current = value;
    _setProcessing(value);
  }, []);

  const [dimensions, setDimensions] = useState<WindowSize>({
    width: 0,
    height: 0,
  });
  const [isPainting, setIsPainting] = useState(true);

  const toggleDrawing = useCallback(() => {
    setIsPainting((prev) => !prev);
  }, []);
  const canvasDrawElementRef = useRef<CanvasDrawElement>(null);

  const drawModeOff = useCallback(() => {
    setDrawMode(false);
  }, [setDrawMode]);

  const stepBack = useCallback(
    () =>
      canvasDrawElementRef.current
        ? canvasDrawElementRef.current.undo()
        : noop(),
    []
  );
  const reset = useCallback(() => {
    canvasDrawElementRef.current
      ? canvasDrawElementRef.current.clear()
      : noop();
    setCleared(true);
    setSrc(file.signedRequest?.signedRequest || file.downloadSrc);
  }, [file]);
  const getData = useCallback(
    () =>
      canvasDrawElementRef.current
        ? canvasDrawElementRef.current.getSaveData()
        : emptyString(),
    []
  );
  const loadData = useCallback(
    (data) =>
      canvasDrawElementRef.current
        ? canvasDrawElementRef.current.loadSaveData(data)
        : noop(),
    []
  );

  useEffect(() => {
    if (initialDrawing) {
      loadData(initialDrawing);
    }
  }, [loadData, initialDrawing]);

  const onSave = useCallback(() => {
    if (!canvasDrawElementRef.current) {
      displayGenericErrorToaster(dispatch);
      setProcessing(false);
      drawModeOff();
      return;
    }

    if (processingRef.current) {
      return;
    }
    setProcessing(true);

    if (cleared || drawing?.clear) {
      file.src =
        file.signedRequest?.thumbnailSignedRequest ||
        file.thumbnail ||
        DOCUMENT_ERROR_SOURCE;
      file.thumbnail =
        file.signedRequest?.thumbnailSignedRequest ||
        file.thumbnail ||
        DOCUMENT_ERROR_SOURCE;
    }

    const lines = getData();
    const linesObject: boolean | number | { lines: any[] } = JSON.parse(
      `${Boolean(lines)}` && `${lines.length}` && lines
    );

    if (fullyCleared(cleared, linesObject)) {
      addDrawing(
        {
          clear: true,
        },
        idx
      );
      file._id && blobFileStorage.removeItem(file._id);
      drawModeOff();
      setProcessing(false);
      file.downloadSrc =
        file.signedRequest?.signedRequest || file.downloadSrc;
      return;
    }

    const drawingCanvasPromise = createDrawingCanvas(
      cleared || drawing?.clear,
      canvasDrawElementRef.current.ctx.drawing.canvas,
      file
    );
    const originalImagePromise = createOriginalImage(
      cleared
        ? file.signedRequest?.signedRequest || file.downloadSrc
        : file.downloadSrc
    );

    return Promise.all([drawingCanvasPromise, originalImagePromise])
      .then((promises) => {
        const [drawingCanvas, originalImage] = promises;
        const { original: mergedWithOriginal, thumbnail } =
          createMergedImageCanvas(originalImage, drawingCanvas);

        return Promise.all([
          canvasToBlob(mergedWithOriginal),
          canvasToBlob(drawingCanvas),
          canvasToBlob(thumbnail),
        ]).then((promisedBlobs) => {
          const [mergedBlob, drawingBlob, thubmnailBlob] = promisedBlobs;
          const mergedImageUrl = URL.createObjectURL(mergedBlob);
          const thumbnailMergedImageUrl =
            URL.createObjectURL(thubmnailBlob);

          const drawingImageUrl = URL.createObjectURL(drawingBlob);
          addDrawing(
            {
              clear: cleared || drawing?.clear,
              data: lines,
              blob: drawingBlob,
              imageUrl: drawingImageUrl,
              mergedImageUrl: mergedImageUrl,
              thumbnailMergedImageUrl: thumbnailMergedImageUrl,
              drawingImageUrl: drawingImageUrl,
            },
            idx
          );
          setProcessing(false);
          drawModeOff();
        });
      })
      .catch((err) => {
        logError('Error when saving a drawing', err);
        drawModeOff();
        setProcessing(false);
        displayGenericErrorToaster(dispatch);
      });
  }, [
    setProcessing,
    cleared,
    drawing?.clear,
    getData,
    file,
    dispatch,
    drawModeOff,
    addDrawing,
    idx,
    blobFileStorage,
  ]);

  useEffect(() => {
    if (container) {
      const dim = getDimensions(container);
      setDimensions(dim);
    }
  }, [container, file]);

  useEffect(() => {
    if (canvasDrawElementRef.current) {
      const drawing = getData();
      setData(drawing);
      // for some reason changing CanvasDraw src does not update the background with new image so we need to force it.
      canvasDrawElementRef.current.drawImage();
    }
  }, [windowSize, getData, src]);

  useEffect(() => {
    if (canvasDrawElementRef.current && data) {
      loadData(data);
    }
    // we only want to do this if dimensions change
    // eslint-disable-next-line
  }, [dimensions]);

  if (!dimensions.width || !dimensions.height) {
    return null;
  }

  return (
    <>
      <div className={classes.drawContainer}>
        {/* // react 18 types
    // @ts-ignore */}
        <CanvasDraw
          ref={canvasDrawElementRef}
          key={dimensions.width}
          brushRadius={1}
          brushColor='red'
          canvasWidth={dimensions.width}
          canvasHeight={dimensions.height}
          imgSrc={src}
          lazyRadius={2}
          disabled={!isPainting}
        />
      </div>
      <div className={classes.drawButtons}>
        <div className='button'>
          <ButtonIcon value='undo' onClick={stepBack}>
            <UndoIcon />
          </ButtonIcon>
        </div>
        <div className='button'>
          <ButtonIcon value='clear' onClick={reset}>
            <ClearIcon />
          </ButtonIcon>
        </div>
        <div className='toggle'>
          <ButtonIcon
            onClick={toggleDrawing}
            className={isPainting ? 'active' : undefined}
          >
            <DrawingIcon />
          </ButtonIcon>
        </div>
      </div>
      <div className={classes.drawFooter}>
        <ButtonText onClick={drawModeOff}>
          {intl.formatMessage({ id: 'general_cancel' })}
        </ButtonText>
        <ButtonContained
          disabled={processing}
          color='primary'
          onClick={onSave}
          endIcon={processing ? <CircularProgress size={16} /> : undefined}
        >
          {intl.formatMessage({ id: 'general_save' })}
        </ButtonContained>
      </div>
    </>
  );
};

function emptyString(): string {
  return '';
}
const getDimensions = (container: HTMLElement | null): WindowSize => {
  const width = container?.offsetWidth || 0;
  const height = container?.offsetHeight || 0;

  return {
    width,
    height,
  };
};

function canvasToBlob(canvas: HTMLCanvasElement): Promise<any> {
  return new Promise((resolve) => canvas.toBlob(resolve));
}

function createMergedImageCanvas(
  image: HTMLImageElement,
  drawing: HTMLCanvasElement
): { original: HTMLCanvasElement; thumbnail: HTMLCanvasElement } {
  const canvas = document.createElement('canvas');
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d')!;

  context.drawImage(image, 0, 0, image.width, image.height);
  context.drawImage(drawing, 0, 0, image.width, image.height);

  const thumbnailCanvas = createThumbnailImageCanvas(image, drawing);

  return { original: canvas, thumbnail: thumbnailCanvas };
}

function createThumbnailImageCanvas(
  image: HTMLImageElement,
  drawing: HTMLCanvasElement
): HTMLCanvasElement {
  const MAX_WIDTH = 300;
  const MAX_HEIGHT = 300;

  let width = image.width;
  let height = image.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height = height * (MAX_WIDTH / width);
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width = width * (MAX_HEIGHT / height);
      height = MAX_HEIGHT;
    }
  }

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const context = canvas.getContext('2d')!;
  context.drawImage(image, 0, 0, width, height);
  context.drawImage(drawing, 0, 0, width, height);
  return canvas;
}

function previousImageNotRelevant(
  cleared: boolean | undefined,
  file: FileType
): boolean {
  return (
    cleared ||
    (!file.data?.drawingSrc &&
      !file.signedRequest?.drawingSignedRequest) ||
    !file.data?.isDrawn
  );
}

function createOriginalImage(
  fileSrc: string | null | undefined
): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const previousImage = new Image();
    // s3 + chrome - big issue around Origin and CORS - just use 'use-credentials' for every request to s3 PT-2785
    previousImage.crossOrigin = 'use-credentials';
    previousImage.onload = function (): void {
      resolve(previousImage);
    };
    previousImage.onerror = function (): void {
      reject(new Error('Could not load image'));
    };
    previousImage.src = fileSrc || '';
  });
}

function createDrawingCanvas(
  cleared: boolean | undefined,
  canvas: HTMLCanvasElement,
  file: FileType
): Promise<HTMLCanvasElement> {
  if (previousImageNotRelevant(cleared, file)) {
    return Promise.resolve(canvas);
  } else {
    return createOriginalImage(selectRemoteOrLocalDrawing(file)).then(
      (previousImage: HTMLImageElement): HTMLCanvasElement => {
        return createMergedImageCanvas(previousImage, canvas).original;
      }
    );
  }
}

function fullyCleared(
  cleared: boolean,
  linesObject: boolean | number | { lines: any[] }
): boolean {
  return (
    cleared &&
    typeof linesObject === 'object' &&
    Array.isArray(linesObject.lines) &&
    !linesObject.lines.length
  );
}

function selectRemoteOrLocalDrawing(file: FileType): string | undefined {
  return file.drawingSyncStatus === SyncStatus.SUCCESS &&
    file.signedRequest?.drawingSignedRequest
    ? file.signedRequest?.drawingSignedRequest
    : file.data?.drawingSrc;
}

export default Draw;
