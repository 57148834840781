import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import {
  broadcastCreatedIssue,
  broadcastUpdatedIssue,
} from 'serviceWorker/broadcasts/issues';
import { broadcastIssueError } from '../errorHandlers';

export function broadcastIssueResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastIssueError(request, response.error);
  }

  if (request.method === 'POST') {
    broadcastCreatedIssue(response);
  } else {
    broadcastUpdatedIssue(response);
  }
}
