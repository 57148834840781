import { ReactElement, forwardRef, useCallback, useMemo } from 'react';
import { ButtonIcon } from 'components/general';
import { MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCreateReport } from 'components/dataCreationForms/withCreateReport';
import { useIssuesContext } from 'components/dataProviders/withIssues';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { useIsOffline } from 'components/common/withIsOffline';
import { ReportIcon } from './ReportIcon';
import { useIntl } from 'react-intl';

const MAX_ISSUE_COUNT = 5000;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.MuiIconButton-root:not(.Mui-disabled)': {
      color: '#000',
    },
  },
}));

export function ReportPdfButton(): ReactElement {
  const classes = useStyles();
  const { issues, loading } = useIssuesContext();
  const isOffline = useIsOffline();
  const disabledReason = isDisabledWithReason(
    loading,
    issues.total,
    isOffline
  );
  const disabled = Boolean(disabledReason);
  const values = useMemo(() => {
    return { count: issues.total };
  }, [issues.total]);

  const { openDialog } = useCreateReport();
  const intl = useIntl();

  return (
    <ForbiddenTooltip
      visibleWhen={disabled}
      reason={disabledReason}
      values={values}
    >
      <ButtonIcon
        disabled={disabled}
        className={classes.root}
        onClick={openDialog}
        title={intl.formatMessage({ id: 'issue_list_send_report_action' })}
      >
        <ReportIcon />
      </ButtonIcon>
    </ForbiddenTooltip>
  );
}

function _ReportPdfMenuItem(
  {
    handleClose,
    text,
  }: {
    handleClose?: () => void;
    text: string;
  },
  ref: any
): any {
  const { issues, loading } = useIssuesContext();
  const isOffline = useIsOffline();
  const disabledReason = isDisabledWithReason(
    loading,
    issues.total,
    isOffline
  );
  const disabled = Boolean(disabledReason);
  const values = useMemo(() => {
    return { count: issues.total };
  }, [issues.total]);

  const { openDialog } = useCreateReport();
  const onClick = useCallback(() => {
    openDialog();
    if (typeof handleClose === 'function') handleClose();
  }, [handleClose, openDialog]);

  return (
    <ForbiddenTooltip
      visibleWhen={disabled}
      reason={disabledReason}
      values={values}
    >
      <MenuItem onClick={onClick} disabled={disabled}>
        <ReportIcon />
        <span style={{ paddingLeft: '1rem' }}>{text}</span>
      </MenuItem>
    </ForbiddenTooltip>
  );
}

export const ReportPdfMenuItem = forwardRef(_ReportPdfMenuItem);

function isDisabledWithReason(
  loading: boolean,
  issuesCount: number,
  isOffline: boolean
): string {
  if (loading) {
    return 'snackbar_create_report_issues_still_loading';
  }

  if (issuesCount === 0) {
    return 'snackbar_create_report_too_less_issues';
  }

  if (issuesCount > MAX_ISSUE_COUNT) {
    return 'snackbar_create_report_too_many_issues';
  }

  if (isOffline) {
    return 'general_offline_operation_unavailable';
  }

  return '';
}
