export enum ImageConvertStatus {
  PENDING = 'PENDING',
  INCORRECT_PAGES = 'INCORRECT_PAGES',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  UNCONVERTABLE_EXTENSION = 'UNCONVERTABLE_EXTENSION',
}
export type ImageConvertStatusNegative =
  | ImageConvertStatus.PENDING
  | ImageConvertStatus.INCORRECT_PAGES
  | ImageConvertStatus.FAILED
  | ImageConvertStatus.UNCONVERTABLE_EXTENSION;
