import React from 'react';
import { useIntl } from 'react-intl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { IssueStateType } from '../../../../../setup/types/core';
import { WithStyles } from '@mui/styles';

type SwitchProps = WithStyles<any> & {
  value: IssueStateType;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => void;
};

const Switch = ({
  classes,
  value,
  onChange,
}: SwitchProps): JSX.Element => {
  const intl = useIntl();

  const REMOVED = intl.formatMessage({ id: 'filters_removed_issues' });
  const ACTIVE = intl.formatMessage({ id: 'filters_active_issues' });
  const classLabel = {
    root: classes.controlRoot,
    label: classes.controlLabel,
  };
  const classRadio = {
    root: classes.radioRoot,
  };

  return (
    <FormControl variant='standard' component='fieldset'>
      <RadioGroup
        name='issues'
        value={value}
        aria-label='issues type'
        onChange={onChange}
      >
        <FormControlLabel
          data-qa='filters_type_radio_active'
          value={IssueStateType.ACTIVE}
          label={ACTIVE}
          control={
            <Radio color='primary' size='small' classes={classRadio} />
          }
          classes={classLabel}
        />
        <FormControlLabel
          data-qa='filters_type_radio_deleted'
          value={IssueStateType.DELETED}
          label={REMOVED}
          control={
            <Radio color='primary' size='small' classes={classRadio} />
          }
          classes={classLabel}
        />
      </RadioGroup>
    </FormControl>
  );
};

export default Switch;
