import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { DocumentCreateModel } from './documentCreateModel';

function startDocumentCreation(
  baseUrl: string,
  document: DocumentCreateModel,
  uniqueId: string
): void {
  debugLog('startDocumentCreate', baseUrl, document, uniqueId);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTS,
    method: ServiceMethods.CREATE,
    data: {
      baseUrl,
      document,
      uniqueId,
    },
  });
  broadcast.close();
}

export default startDocumentCreation;
