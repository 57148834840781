import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import useIsMobile from 'hooks/useIsMobile';
import React, { Fragment, ReactElement } from 'react';
import { useStyles } from './styles';

function TemplateListSkeleton(): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  return (
    <Fragment>
      <div className={classes.head}></div>
      <div className={classes.content}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minWidth: '350px',
            paddingTop: '19px',
            paddingBottom: '20px',
            overflowY: 'auto',
            flexShrink: 0,
            marginLeft: '15px',
          }}
        >
          <Skeleton variant='rectangular' width={'100%'} height={150} />
          <Skeleton variant='text' width={'100%'} height={150} />
          <Skeleton variant='rectangular' width={'100%'} height={150} />
          <Skeleton variant='text' width={'100%'} height={150} />
          <Skeleton variant='rectangular' width={'100%'} height={150} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            marginTop: '20px',
            marginLeft: '51px',
            paddingRight: '45px',
            overflowY: 'auto',
          }}
        >
          <Skeleton variant='text' width={'25%'} height={120} />

          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
          <Skeleton variant='rectangular' width={'100%'} height={120} />
          <Skeleton variant='text' width={'100%'} height={120} />

          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
          <Skeleton variant='rectangular' width={'100%'} height={100} />
          <Skeleton variant='rectangular' width={'50%'} height={100} />

          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
          <Skeleton variant='text' width={'35%'} height={100} />
          <Skeleton variant='text' width={'100%'} height={100} />
          <Skeleton variant='rectangular' width={'100%'} height={250} />

          <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
          <Skeleton variant='text' width={'35%'} height={50} />
          <Skeleton variant='rectangular' width={'100%'} height={50} />
          <Skeleton variant='rectangular' width={'100%'} height={50} />
          <Skeleton variant='rectangular' width={'100%'} height={50} />
          <Skeleton variant='rectangular' width={'100%'} height={50} />
          <Skeleton variant='rectangular' width={'100%'} height={50} />
        </div>
      </div>
    </Fragment>
  );
}

export const MemoTemplateListSkeleton = React.memo(TemplateListSkeleton);
