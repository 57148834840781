import { OpenCreateDialogParams } from 'components/dataCreationForms/types';
import {
  makeHandleFormValidation,
  validate,
} from 'components/dataCreationForms/validation';
import { useContracts } from 'components/dataProviders/withContracts';
import { useSites } from 'components/dataProviders/withSites';
import { getCreatedMessageListener } from 'shared/domain/commonModel';
import {
  contractCreateOnViewToContractCreateModel,
  createEmptyContract,
} from 'shared/domain/contract/mapping';
import { startContractCreate } from 'shared/domain/contract/startContractCreate';
import { ContractModel } from 'shared/domain/contract/types/model';
import { ContractCreateOnView } from 'shared/domain/contract/types/view';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { projectIdSelector, toLabelledEntities } from 'helpers/misc';
import { ErrorPresentation } from 'helpers/validators';
import { useGetAllContracts } from 'hooks/useGetAllContracts';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { CreateContractContext } from '../types';
import { getCreateContractValidationSchema } from '../validation';
const formName = 'contract';
const SUBMIT_EVENT_TYPE = 'submit-contract';
const WithCreateContractContext = createContext<
  CreateContractContext | undefined
>(undefined);

function WithCreateContract({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const history = useHistory();
  const { getAll: getAllContracts } = useGetAllContracts();
  const fetchContracts = getAllContracts;
  const {
    contracts: { items: contracts },
  } = useContracts();
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const availableSites = useMemo(
    () => toLabelledEntities(sites.filter(keepUndeleted)),
    [sites]
  );
  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const projectId = useSelector(projectIdSelector);

  const emptyContract = createEmptyContract();
  const [initialValues, setInitialValues] =
    useState<ContractCreateOnView>(emptyContract);
  const validationSchema = useMemo(() => {
    return getCreateContractValidationSchema(contracts, null);
  }, [contracts]);

  const validateCreateContract = useCallback(
    (
      key: keyof ContractCreateOnView,
      value: ContractCreateOnView[keyof ContractCreateOnView]
    ): ErrorPresentation => {
      return validate(key, value, validationSchema);
    },
    [validationSchema]
  );

  const handleFormValidation = useMemo(() => {
    return makeHandleFormValidation(validationSchema);
  }, [validationSchema]);
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(
    ({ freeSoloLabel }: OpenCreateDialogParams) => {
      setOpen(true);
      initialValues &&
        freeSoloLabel &&
        setInitialValues({ ...initialValues, label: freeSoloLabel });
    },
    [initialValues]
  );
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );

  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: ContractCreateOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const contractCreateModel =
        contractCreateOnViewToContractCreateModel(values, projectId);

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getCreatedMessageListener(
          (message: ContractModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchContracts();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.contractChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateContract: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startContractCreate(contractCreateModel, uniqueId);
      })
        .then((contract: unknown): any => {
          if (contract && (contract as Identificable)._id) {
            const redirectAfterCreation =
              window.location.href.indexOf('create-contract') > 0;
            redirectAfterCreation
              ? history.push(
                  `/contract/${(contract as Identificable)._id}`
                )
              : setOpen(false);
            return contract;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchContracts, history, projectId]
  );

  const ctx: CreateContractContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateCreateContract,
      handleFormValidation,
      availableSites,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    availableSites,
    onSuccess,
    handleFormValidation,
    validateCreateContract,
  ]);

  return (
    <WithCreateContractContext.Provider value={ctx}>
      {children}
    </WithCreateContractContext.Provider>
  );
}

function useCreateContract(): CreateContractContext {
  const context = useContext(WithCreateContractContext);
  if (context === undefined) {
    throw new Error(
      'useCreateContract must be used within an CreateContractContext'
    );
  }
  return context;
}

const withCreateContract =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateContract>
      <Component {...props} />
    </WithCreateContract>
  );

export { WithCreateContract, useCreateContract, withCreateContract };
