import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGetAll } from './makeUseGetAll';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { useVisibleFieldsChannelListener } from 'components/broadcastChannelListeners/withVisibleFieldsChannelListener';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allVisibleFields
  );
}

export const useGetAllVisibleFields = makeUseGetAll<VisibleFieldModel>({
  useChannelListener: useVisibleFieldsChannelListener,
  postMessageChannel: ChannelNames.visibleFieldChannel,
  postMessageType: DomainMessagesTypes.getAllVisibleFields,
  isMatchingMessage,
  entityName: 'visibleFields',
});
