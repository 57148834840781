import { DrawingModel } from 'shared/domain/document/drawingModel';
import { CreateDrawingCustomEventDetail } from 'shared/domain/messages/document/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';

const create = (e: { drawing: DrawingModel; localId: number }): void => {
  debugLog('CREATE DRAWING EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateDrawingCustomEventDetail>(
      RepositoryMessagesTypes.addDrawing,
      {
        detail: { ...e },
      }
    )
  );
};

export default { create };
