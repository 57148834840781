import React from 'react';
import './bouncer.scss';

type LoaderType = {
  backgroundColor?: string;
};

const Bouncer = ({ backgroundColor }: LoaderType): JSX.Element => (
  <div className='loader' style={{ backgroundColor }}>
    <div className='bounce1' />
    <div className='bounce2' />
    <div className='bounce3' />
  </div>
);

export default Bouncer;
