export type FilterMapped = {
  path: string;
  type: string;
  value?: any;
};

const filtersMap: { [key: string]: FilterMapped } = {
  subcontractors: {
    path: 'primaryData.subcontractors',
    type: 'oneOfDirect',
  },
  executor: {
    path: 'primaryData.executor',
    type: 'direct',
  },
  createdBy: {
    path: 'createdBy',
    type: 'direct',
  },
  issueTypes: {
    path: 'process',
    type: 'direct',
  },
  createdAt: {
    path: 'createdAt',
    type: 'dateRange',
  },
  detectionDate: {
    path: 'primaryData.detectionDate',
    type: 'dateRange',
  },
  stage: {
    path: 'stage',
    type: 'direct',
  },
  site: {
    path: 'primaryData.site',
    type: 'direct',
  },
  contractNumbers: {
    path: 'primaryData.contractNumbers',
    type: 'oneOfDirect',
  },
  assignee: {
    path: 'primaryData.assignee',
    type: 'direct',
  },
  level: {
    path: 'primaryData.level',
    type: 'direct',
  },
  workTypes: {
    path: 'extendedData.workTypes',
    type: 'oneOfDirect',
  },
  rootCauses: {
    path: 'extendedData.rootCauses',
    type: 'oneOfDirect',
  },
  environmentalAspect: {
    path: 'extendedData.environmentalAspect',
    type: 'direct',
  },
  impact: {
    path: 'extendedData.impact',
    type: 'direct',
  },
  circumstances: {
    path: 'extendedData.circumstances',
    type: 'direct',
  },
  spilledSubstance: {
    path: 'extendedData.spilledSubstance',
    type: 'direct',
  },
  proposedCorrectiveAction: {
    path: 'extendedData.proposedCorrectiveAction',
    type: 'direct',
  },
  effect: {
    path: 'extendedData.effect',
    type: 'direct',
  },
  hazardCategory: {
    path: 'extendedData.hazardCategory',
    type: 'oneOfDirect',
  },
  decisionToImposeFine: {
    path: 'extendedData.decisionToImposeFine',
    type: 'direct',
  },
  targetCompletionDate: {
    path: 'extendedData.targetCompletionDate',
    type: 'dateRange',
  },
  finalCompletionDate: {
    path: 'extendedData.finalCompletionDate',
    type: 'dateRange',
  },
  finalStartDate: {
    path: 'extendedData.finalStartDate',
    type: 'dateRange',
  },
  targetStartDate: {
    path: 'extendedData.targetStartDate',
    type: 'dateRange',
  },
  inspection: {
    path: 'inspection',
    type: 'direct',
  },
  executedByCompanies: {
    path: 'extendedData.executedByCompanies',
    type: 'oneOfDirect',
  },
  search: {
    path: '',
    type: 'search',
  },
};

type OldFilter = {
  name: string;
  value:
    | { _id?: string }[]
    | { fromDate: string | null; toDate: string | null }
    | string;
};

export const standariseFilters = (
  filters: OldFilter[]
): FilterMapped[] => {
  return filters.map((filter) => {
    const mapped = filtersMap[filter.name] || undefined;
    return mapped
      ? { ...mapped, value: filter.value }
      : { path: '', type: 'invalid' };
  });
};
