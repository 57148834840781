import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { SiteModel } from 'shared/domain/site/types/model';
import React, {
  ReactElement,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { ChannelNames } from 'shared/domain/channelNames';
import { siteModelToSiteOnView } from 'views/site/mapping';
import { SiteOnView } from 'shared/domain/site/types/view';
import { WithLevels, useLevels } from '../withLevels';

type SiteContextType = {
  site: SiteOnView | undefined;
  loading: boolean;
};

const SiteContext = React.createContext<SiteContextType | undefined>(
  undefined
);

const WithSite: React.FC<{ children?: ReactNode }> = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const { levelsPerSite: levelsPerSite, loading: loadingLevels } =
    useLevels();

  const [site, setSite] = useState<SiteOnView | undefined>(undefined);
  const [loadingSite, setLoadingSite] = useState<boolean>(Boolean(id));
  const [siteModel, setSiteModel] = useState<SiteModel | undefined>();

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.siteChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.site &&
        event.data._id === id
      ) {
        broadcast.close();
        setSiteModel(event.data);
        setLoadingSite(false);
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, [id]);

  useEffect(() => {
    if (!siteModel || loadingLevels) {
      return;
    }

    setSite(siteModelToSiteOnView(siteModel, levelsPerSite));
  }, [siteModel, loadingLevels, levelsPerSite]);

  const ctx = {
    site: site,
    loading: loadingSite || loadingLevels,
  };

  return (
    <SiteContext.Provider value={ctx}>{children}</SiteContext.Provider>
  );
};

export function useSite(): SiteContextType {
  const context = React.useContext(SiteContext);
  if (context === undefined) {
    throw new Error('useSite must be used within an SiteContextProvider');
  }
  return context;
}

export const withSite =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithLevels>
      <WithSite>
        <Component {...props} />
      </WithSite>
    </WithLevels>
  );
