import {
  IssueLocationType,
  IssueModel,
} from 'shared/domain/issue/issueModel';
import { Store } from 'hooks/createStore';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';
import { SelectablePin } from '../../location/pin/SelectablePin';
import { PinType, ProcessColors } from './types';

export function IssuePins({
  issuesStore,
  processesWithPositionOnMap,
}: {
  issuesStore: Store<IssueModel[]>;
  processesWithPositionOnMap: string[];
}): ReactElement {
  const processes = useSelector(processesSelector);
  const colors: ProcessColors = useMemo(() => {
    return processes.reduce((result: ProcessColors, process) => {
      result[process._id] = process.color;
      return result;
    }, {});
  }, [processes]);

  const [pins, setPins] = useState<PinType[]>(
    issuesToPins(issuesStore.get(), colors, processesWithPositionOnMap)
  );

  useEffect(() => {
    const action = (): void => {
      setPins(
        issuesToPins(issuesStore.get(), colors, processesWithPositionOnMap)
      );
    };
    return issuesStore.subscribe(action);
  }, [issuesStore, colors, processesWithPositionOnMap]);

  return (
    <>
      {pins.map((pin) => {
        return (
          <SelectablePin
            key={pin.key}
            x={pin.x}
            y={pin.y}
            color={pin.color}
            _id={pin.key}
            tooltip={pin.tooltip}
            addIndexMarker={true}
          />
        );
      })}
    </>
  );
}

function issuesToPins(
  issues: IssueModel[],
  colors: ProcessColors,
  processesWithPositionOnMap: string[]
): PinType[] {
  return issues.reduce((result: PinType[], issue: IssueModel) => {
    if (
      issue.primaryData.selectedLocationType !== IssueLocationType.pin ||
      !processesWithPositionOnMap.includes(issue.process)
    ) {
      return result;
    }
    const { x, y } = issue.primaryData.positionOnMap || { x: 0.5, y: 0.5 };
    result.push({
      x,
      y,
      key: issue._id,
      color: colors[issue.process],
      tooltip: issue.primaryData.title,
    });
    return result;
  }, []);
}
