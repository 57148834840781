import { useMemo, useEffect, useCallback } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

type SyncIssues = {
  syncIssues: () => void;
};

export function useSyncIssues(withEffect?: boolean): SyncIssues {
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const syncIssues = useCallback(() => {
    broadcast.postMessage({
      service: Services.ISSUES,
      method: ServiceMethods.SYNC,
    });
  }, [broadcast]);

  useEffect(() => {
    if (withEffect) {
      syncIssues();
    }
  }, [syncIssues, withEffect]);

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    syncIssues,
  };
}
