import {
  Autocomplete,
  AutocompleteChangeReason,
  FilterOptionsState,
} from '@mui/material';
import {
  ReactElement,
  SyntheticEvent,
  useCallback,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import {
  getFreeSoloDialogAdditionalParams,
  getOnSingleFreeSoloChangeHandler,
  singleFreeSoloFilterOptions,
} from '../common';
import { SingleChoiceValue } from './types';

export function FreeSoloSingleAutocomplete({
  autoCompleteProps,
  useProvider,
  siteId,
}): ReactElement {
  const intl = useIntl();
  const { openDialog } = useProvider();
  const [value, setValue] = useState(autoCompleteProps.value);
  const additionalParams = getFreeSoloDialogAdditionalParams(siteId);

  const onChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newValue: SingleChoiceValue & { inputValue?: string },
      reason: AutocompleteChangeReason
    ) => {
      getOnSingleFreeSoloChangeHandler(
        openDialog,
        autoCompleteProps.onChange,
        setValue,
        additionalParams
      )(event, newValue, reason);
    },
    [openDialog, autoCompleteProps.onChange, setValue, additionalParams]
  );

  const filterOptions = useCallback(
    (
      options: LabelledEntity[],
      params: FilterOptionsState<
        LabelledEntity & { inputValue?: string | undefined }
      >
    ) => singleFreeSoloFilterOptions(options, params, intl),
    [intl]
  );
  return (
    <Autocomplete
      {...autoCompleteProps}
      value={autoCompleteProps.value || null}
      freeSolo={true}
      filterOptions={filterOptions}
      onChange={onChange}
      clearOnBlur={true}
      data-qa={'singleAutocomplete_freeSolo'}
    />
  );
}
