import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addIssueStrategy } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { createIssueModelToOutDto } from '../../../../shared/domain/issue/mappings';
import { AddIssueUseCase } from '../../../../shared/domain/issue/types';

function makeAddIssueUseCase(
  addIssueStrategy: (
    httpRequestEntity: __HttpRequestModel__
  ) => Promise<any>
): AddIssueUseCase {
  return function addIssue(createIssueModel): Promise<void> {
    debugLog('addIssue', createIssueModel);
    const outDto = createIssueModelToOutDto(createIssueModel);

    return addIssueStrategy({
      createdAt: Date.now(),
      method: 'POST',
      url: `project/${createIssueModel.projectId}/issue`,
      data: outDto,
      entityType: HttpRequestModelType.issue,
      status: HttpRequestStatus.NEW,
    });
  };
}

const addIssue = makeAddIssueUseCase(addIssueStrategy);
export default addIssue;
