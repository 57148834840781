import { ReactElement } from 'react';
import { CircledNumber } from './CircledNumber';
import { ProgressIndicator } from './ProgressIndicator';

export function ProgressBox({
  no,
  done,
  max,
  onClick,
  circleColor,
  expanded,
  backgroundColor,
  progressBarBackground,
}: {
  no: string;
  done: number | undefined;
  max: number | undefined;
  onClick: () => void;
  circleColor: string;
  expanded: boolean;
  backgroundColor: string;
  progressBarBackground?: string;
}): ReactElement {
  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: expanded ? 'stretch' : 'center',
        width: '100%',
        height: '42px',
        borderBottom: '1px solid #00000029',
        cursor: 'pointer',
        paddingLeft: expanded ? '0.75rem' : 0,
        boxSizing: 'border-box',
        gap: '0.5rem',
        backgroundColor: backgroundColor,
      }}
    >
      <CircledNumber no={no} color={circleColor} />
      {expanded && (
        <ProgressIndicator
          done={done}
          max={max}
          progressBarBackground={progressBarBackground}
        />
      )}
    </div>
  );
}
