import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import {
  DeleteLevelUseCase,
  ExecuteDeleteLevelUseCaseParams,
} from 'shared/domain/level/types/useCases';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { LevelHttpDeleteRequestModel } from '../httpQueue/level/types';

export class LevelDeleter implements DeleteLevelUseCase {
  constructor(
    private addRequest: (
      request: LevelHttpDeleteRequestModel
    ) => Promise<any>
  ) {}
  async execute({
    levelId,
    projectId,
    uniqueId,
  }: ExecuteDeleteLevelUseCaseParams): Promise<void> {
    debugLog('DeleteLevelUseCase', levelId);
    const url = `/project/${projectId}/level/${levelId}`;
    const request: LevelHttpDeleteRequestModel = {
      createdAt: Date.now(),
      method: 'DELETE',
      data: {
        levelId,
        projectId,
        uniqueId: uniqueId,
      },
      url,
      entityType: HttpRequestModelType.level,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const levelDeleter = new LevelDeleter(addRequest);
