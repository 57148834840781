import { DialogOptions } from 'components/core/Dialog/common/Dialog';
import { FormattedMessage } from 'react-intl';

export function documentationDeleteDialog(
  selectedCount: number
): DialogOptions {
  return {
    catchOnCancel: false,
    title: (
      <FormattedMessage id='dialog_confirmation_delete_documentation_title' />
    ),
    description: (
      <span>
        <FormattedMessage
          id='dialog_confirmation_delete_documentation'
          values={{
            selectedCount,
          }}
        />
      </span>
    ),
  };
}
