import * as actions from '../actionTypes';
import { TOASTER_TYPES } from 'shared/enums';

let timerId: number;

const toasterMiddleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: any): void => {
    if (action.meta?.type === TOASTER_TYPES.SUCCESS) {
      dispatch({
        type: actions.SHOW_TOASTER,
        toasterType: TOASTER_TYPES.SUCCESS,
        toasterMessage: action.meta.message,
        toasterPosition: action.meta.toasterPosition,
        hideDelay: action.meta.hideDelay,
      });

      hideToasterWithDelay(action.meta.hideDelay ?? 3000);

      return next(action);
    }

    if (action.meta?.type === TOASTER_TYPES.FAILURE) {
      dispatch({
        type: actions.SHOW_TOASTER,
        toasterType: TOASTER_TYPES.FAILURE,
        toasterMessage: action.meta.message,
        toasterPosition: action.meta.toasterPosition,
        hideDelay: action.meta.hideDelay,
      });

      hideToasterWithDelay(action.meta.hideDelay ?? 3000);

      return next(action);
    }

    next(action);

    /**
     * auto hide toaster with delay
     */
    function hideToasterWithDelay(delay: number): void {
      if (timerId) {
        clearTimeout(timerId);
      }

      // hide after some delay
      timerId = window.setTimeout(() => {
        dispatch({
          type: actions.HIDE_TOASTER,
        });
      }, delay);
    }
  };

export default toasterMiddleware;
