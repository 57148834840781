import { LabelledUser } from 'shared/domain/commonModel';

export function toLabelledUser<T extends LabelledUser | undefined>(
  item: T
): LabelledUser | undefined {
  if (!item) return undefined;
  return {
    _id: item._id,
    email: item.email,
    label: item.label,
  };
}
