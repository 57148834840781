import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { LevelInDto } from 'shared/dtos/in/level';
import { UserEntity } from 'shared/domain/user/types/entity';
import {
  LevelEntity,
  MapEntity,
} from '../../../shared/domain/level/types/entity';
import { LevelModel, MapModel } from 'shared/domain/level/types/model';

export function levelInDtoToEntity(level: LevelInDto): LevelEntity {
  return {
    _id: level._id,
    createdAt: level.createdAt,
    createdBy: level.createdBy,
    deleted: level.deleted ? 1 : 0,
    index: level.index,
    label: level.label,
    maps: level.maps,
    modifiedAt: level.modifiedAt,
    modifiedBy: level.modifiedBy,
    site: level.site,
  };
}

export function levelEntityToModel(
  level: LevelEntity,
  users: UserEntity[]
): LevelModel {
  return {
    _id: level._id,
    createdAt: level.createdAt,
    deleted: level.deleted ? true : false,
    index: level.index,
    label: level.label,
    maps: level.maps.map((map) => mapEntityToModel(map, users)),
    modifiedAt: level.modifiedAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === level.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === level.modifiedBy)
    ),
    site: level.site,
  };
}

function mapEntityToModel(map: MapEntity, users: UserEntity[]): MapModel {
  const {
    createdAt,
    documentationId,
    modifiedAt,
    versionId,
    _id,
    createdBy,
    modifiedBy,
  } = map;
  return {
    _id,
    createdAt,
    documentationId,
    modifiedAt,
    versionId,
    createdBy: toLabelledUser(
      users.find((user) => user._id === createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === modifiedBy)
    ),
  };
}
