import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useCreateStore, Store } from 'hooks/createStore';
import {
  ComponentType,
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  CurrentDirectoryStore,
  useCurrentDirectoryStore,
} from './currentDirectoryStore';
import { BroadcastChannel } from 'broadcast-channel';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { useDocumentationSearchContext } from '../withDocumentationSearch';

export type DirectoryRoot = { _id: string; name: string };
type CurrentDirectoryContextType = {
  currentDirectoryStore: CurrentDirectoryStore;
  currentDirectoryRootsStore: Store<DirectoryRoot[]>;
  loadingCurrentDirectoryRootsStore: Store<boolean>;
  goUp: () => void;
};
const CurrentDirectoryContext = createContext<
  CurrentDirectoryContextType | undefined
>(undefined);

export function WithCurrentDirectory({
  children,
}: PropsWithChildren<any>): ReactElement {
  const { setSearchPhrase } = useDocumentationSearchContext();
  const currentDirectoryStore = useCurrentDirectoryStore();
  const currentDirectoryRootsStore = useCreateStore<DirectoryRoot[]>([]);
  const loadingCurrentDirectoryRootsStore = useCreateStore(false);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);

    broadcast.onmessage = (event: Message) => {
      if (
        event.type === DomainMessagesTypes.directoryRoots &&
        event.data &&
        event.uniqueId === currentDirectoryStore.get()
      ) {
        currentDirectoryRootsStore.set(
          event.data.items.map((item: DocumentationModel) => ({
            name: item.name,
            _id: item._id,
          }))
        );
        loadingCurrentDirectoryRootsStore.set(false);
      }
    };

    return () => {
      broadcast.close();
    };
  }, [
    currentDirectoryRootsStore,
    currentDirectoryStore,
    loadingCurrentDirectoryRootsStore,
  ]);

  useEffect(() => {
    broadcastFindRoots(currentDirectoryStore.get());
    currentDirectoryStore.subscribe(() => {
      setSearchPhrase('');
      loadingCurrentDirectoryRootsStore.set(true);
      const currentDirectory = currentDirectoryStore.get();
      if (!currentDirectory) {
        currentDirectoryRootsStore.set([]);
        return;
      }
      broadcastFindRoots(currentDirectoryStore.get());
    });
  }, [
    currentDirectoryStore,
    currentDirectoryRootsStore,
    setSearchPhrase,
    loadingCurrentDirectoryRootsStore,
  ]);

  const goUp = useCallback(() => {
    const roots = currentDirectoryRootsStore.get();
    const last = roots[roots.length - 2];
    if (last) {
      currentDirectoryStore.set(last._id);
    } else {
      currentDirectoryStore.set('');
    }
  }, [currentDirectoryRootsStore, currentDirectoryStore]);

  const ctx: CurrentDirectoryContextType = useMemo(() => {
    return {
      currentDirectoryStore,
      currentDirectoryRootsStore,
      goUp,
      loadingCurrentDirectoryRootsStore,
    };
  }, [
    currentDirectoryStore,
    currentDirectoryRootsStore,
    goUp,
    loadingCurrentDirectoryRootsStore,
  ]);

  return (
    <CurrentDirectoryContext.Provider value={ctx}>
      {children}
    </CurrentDirectoryContext.Provider>
  );
}

export function withCurrentDirectory<T>(Component: ComponentType<T>) {
  return (props: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithCurrentDirectory>
      <Component {...props} />
    </WithCurrentDirectory>
  );
}

export function useCurrentDirectoryContext(): CurrentDirectoryContextType {
  const context: CurrentDirectoryContextType | undefined = useContext(
    CurrentDirectoryContext
  );
  if (context === undefined) {
    throw new Error(
      'useCurrentDirectoryContext must be used within a useCurrentDirectoryContextProvider'
    );
  }
  return context;
}

function broadcastFindRoots(currentDirectory: string): void {
  if (!currentDirectory) return;
  const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);
  broadcast.postMessage({
    type: DomainMessagesTypes.findRoots,
    data: {
      parentId: currentDirectory,
    },
    uniqueId: currentDirectory,
  });
  broadcast.close();
}
