import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { makeSimpleBroadcastError } from '../factories';

export function broadcastCreatedProjectError(error: any): void {
  debugLog('broadcastCreatedProjectError', error);
  broadcastProjectError(DomainMessagesTypes.createdEntity, error);
}
const broadcastProjectError = makeSimpleBroadcastError(
  ChannelNames.projectChannel
);

export const broadcastUploadedProjectError = (error: any): void => {
  debugLog('broadcastUploadedProjectError', error);
  broadcastProjectError(DomainMessagesTypes.failUploadedEntity, error);
};
