import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debounceNext } from 'serviceWorker/helpers/debounce';
import {
  OrganizationCreateCustomEventDetail,
  OrganizationEditCustomEventDetail,
} from 'shared/domain/messages/organization/eventMessages';
import { debugLog } from 'shared/logger/debugLog';

const pull = debounceNext((): void => {
  debugLog('GET ORGANIZATION CUSTOM REPOSITORY EVENT');
  self.dispatchEvent(
    new Event(RepositoryMessagesTypes.getAllOrganizations)
  );
}, 1000);

const create = (e: OrganizationCreateCustomEventDetail): void => {
  debugLog('CREATE ORGANIZATION CUSTOM EVENT', e);
  self.dispatchEvent(
    new CustomEvent<OrganizationCreateCustomEventDetail>(
      RepositoryMessagesTypes.addOrganization,
      {
        detail: {
          organizationCreateModel: e.organizationCreateModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const edit = (e: OrganizationEditCustomEventDetail): void => {
  debugLog('EDIT ORGANIZATION CUSTOM EVENT', e);
  self.dispatchEvent(
    new CustomEvent<OrganizationEditCustomEventDetail>(
      RepositoryMessagesTypes.editOrganization,
      {
        detail: {
          organizationEditModel: e.organizationEditModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

export default { pull, create, edit };
