import { Autocomplete, Box, FormHelperText } from '@mui/material';
import { ErrorPresentation } from 'helpers/validators';
import {
  FocusEventHandler,
  ReactElement,
  Ref,
  SyntheticEvent,
  forwardRef,
  memo,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import {
  getAutocompleteRenderInput,
  getHelperTextFromError,
} from './common';

const MemoAutocomplete = memo(Autocomplete);

function get(key: string): string[] {
  const item = localStorage.getItem(key);
  if (item) {
    const parsed = JSON.parse(item);
    if (Array.isArray(parsed)) {
      if (parsed.every((i) => typeof i === 'string')) {
        return parsed;
      }
    }
  }
  return [];
}

function set(key: string, newValue: string[]): void {
  localStorage.setItem(key, JSON.stringify(newValue));
}

function setInLocalStorage(key: string, value: string): void {
  const items = get(key);
  const index = items.indexOf(value);
  if (index === 0) {
    return;
  }
  if (index > 0) {
    // remove from existing position
    items.splice(index, 1);
  }
  // insert on top
  items.unshift(value);
  if (items.length > 50) {
    items.length = 50;
  }
  set(key, items);
}

type AutocompleteWithTextProps = {
  autoFocus?: boolean;
  handleBlur?: FocusEventHandler<HTMLDivElement>;
  handleChange: (event: any, value: string | null | undefined) => void;
  labelId: string;
  disabled?: boolean | { reason: string };
  error: ErrorPresentation;
  required?: boolean;
  minRows?: number;
  localStorageKey: string;
  isHalf?: boolean;
  defaultValue?: string;
  dense: boolean;
  reserveSpaceForHelperText: boolean;
};

function AutocompleteWithText(
  {
    autoFocus,
    labelId,
    required,
    disabled,
    handleChange,
    handleBlur,
    error,
    minRows,
    localStorageKey,
    isHalf,
    defaultValue,
    dense,
    reserveSpaceForHelperText,
  }: AutocompleteWithTextProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const intl = useIntl();
  if (!localStorageKey) {
    throw new Error('local storage key is required');
  }

  const sx = {
    fontFamily: 'Archivo, Arial',
    width: '100%',
    '.MuiFormLabel-root': {
      fontFamily: 'Archivo, Arial',
      color: '#979797',
      bottom: '1rem',
      lineHeight: 1,
    },
    '.MuiInputBase-input': {
      height: '1rem',
    },
    '.MuiFormLabel-root.Mui-focused': {
      color: '#FBCC30',
    },
    '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
      {
        borderColor: '#FBCC30',
      },
  };

  const helperText = getHelperTextFromError(error, intl);
  const id = `autocomplete-${localStorageKey}`;

  const renderInput = getAutocompleteRenderInput({
    autoFocus,
    id,
    required: !!required,
    error,
    disabled: !!disabled,
    label: labelId ? intl.formatMessage({ id: labelId }) : '',
    placeholder: '',
    dense,
  });
  const onBlur: FocusEventHandler<HTMLDivElement> = useCallback(
    (event): void => {
      const value = (event.target as HTMLInputElement).value;
      if (typeof value === 'string' && value.length > 0) {
        setInLocalStorage(id, value);
      }
      handleBlur && handleBlur(event);
    },
    [id, handleBlur]
  );
  const unifiedHandleChange = useCallback(
    (event: SyntheticEvent<Element, Event>, value: unknown) => {
      handleChange(event, value as string);
    },
    [handleChange]
  );
  return (
    <Box>
      <MemoAutocomplete
        value={defaultValue}
        options={get(id)}
        id={id}
        disabled={Boolean(disabled)}
        freeSolo
        onChange={unifiedHandleChange}
        onBlur={onBlur}
        onInputChange={unifiedHandleChange}
        blurOnSelect={true}
        renderInput={renderInput}
        sx={sx}
      />
      <FormHelperText error={!!error}>
        {helperText || (reserveSpaceForHelperText && ' ')}
      </FormHelperText>
    </Box>
  );
}

export const MemoAutocompleteWithText = memo(
  forwardRef(AutocompleteWithText)
);
