import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
export const useStyles = makeStyles<DefaultTheme>(() =>
  createStyles({
    fieldBox: {
      boxSizing: 'border-box',
      padding: '4px',
      paddingLeft: '13px',
      borderRadius: '3px',
      backgroundColor: 'white',
      maxWidth: '758px',
      width: '100%',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
      fontFamily: 'Archivo',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '38px',
      letterSpacing: '0em',
      '& > .w-100': {
        width: '100%',
      },
      '& > .flex-between': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      minHeight: '38px',
    },
  })
);
