import { Box, ClickAwayListener } from '@mui/material';
import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import { Area } from 'components/dataProviders/withLocation';
import { HIGHEST_LOCATION_ZINDEX } from 'components/dataProviders/withLocation/constants';
import { ClosedArea } from 'components/location/area/AreaClosed';
import {
  coordinatesToPercentageString,
  getAreaColors,
} from 'components/location/area/common';
import { useScaledPinWidth } from 'components/location/pin/common';
import { useImageBoundsContext } from 'components/responsivePanPinchZoom';
import useIsMobile from 'hooks/useIsMobile';
import {
  MouseEventHandler,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { LocationAreaType, Point } from 'shared/domain/area/types';
import { IndexMarkerSvg } from './IndexMarkerSvg';

export function DocumentationArea({
  areaType,
  points,
  id,
  onClick,
  tooltip,
}: {
  areaType: LocationAreaType;
  points: Point[];
  id: string;
  onClick: MouseEventHandler<SVGSVGElement>;
  tooltip: string;
}): ReactElement {
  const { width, height } = useImageBoundsContext();
  const { selectedStore, select } = useSelectedStoreContext<
    string | undefined
  >();
  const isMobile = useIsMobile();

  const [isSelected, setIsSelected] = useState(selectedStore.get() === id);
  const [isHovered, setIsHovered] = useState(false);
  const onMouseOver = useCallback(
    () => !isSelected && setIsHovered(true),
    [isSelected, setIsHovered]
  );
  const onMouseLeave = useCallback(
    () => !isSelected && setIsHovered(false),
    [isSelected, setIsHovered]
  );
  const { backgroundColor, patternName, lineColor } = useMemo(
    () => getAreaColors(areaType, isSelected, isHovered),
    [isHovered, areaType, isSelected]
  );
  useEffect(() => {
    setIsSelected(selectedStore.get() === id);
    return selectedStore.subscribe(() => {
      setIsSelected(selectedStore.get() === id);
    });
  }, [selectedStore, id, setIsSelected]);
  const referencePointForIndexMarker = useMemo(
    () => calculateAreaIndexMarkerPlacement(areaType, points),
    [areaType, points]
  );
  const lineFrom =
    areaType === LocationAreaType.planned ? 'top' : 'bottom';
  const onClickAway = useCallback(() => {
    setIsSelected(false);
    setIsHovered(false);
    selectedStore.get() === id && select(undefined);
  }, [setIsSelected, selectedStore, id, select]);
  const indexMarkerWidth = useScaledPinWidth({
    isSelected,
    enlarged: isMobile,
  });
  // ClickAwayListener needs this Box
  // https://stackoverflow.com/questions/67832882/clickawaylistener-component-not-working-with-dragdropcontext
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Box>
        <ClosedArea
          backgroundColor={backgroundColor}
          patternName={patternName}
          lineColor={lineColor}
          points={points}
          widthToHeightRatio={width / height}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          tooltip={tooltip}
          cursor='pointer'
        />

        <IndexMarkerSvg
          drawLineFrom={lineFrom}
          width={indexMarkerWidth}
          left={coordinatesToPercentageString(
            referencePointForIndexMarker.x
          )}
          top={coordinatesToPercentageString(
            referencePointForIndexMarker.y
          )}
          id={id}
          color={lineColor}
          onClick={onClick}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          zIndex={isSelected ? HIGHEST_LOCATION_ZINDEX : 'unset'}
          tooltip={tooltip}
        />
      </Box>
    </ClickAwayListener>
  );
}

function calculateAreaIndexMarkerPlacement(
  type: LocationAreaType,
  points: Point[]
): Point {
  return type === LocationAreaType.planned
    ? getTopLeftPoint(points)
    : getBottomRightPoint(points);
}

function calculateDistance(position: Point, target: Point): number {
  return Math.sqrt(
    Math.pow(position.x - target.x, 2) + Math.pow(position.y - target.y, 2)
  );
}

function minBy<T>(array: T[], getValue: (value: T) => number): T {
  let minElem = array[0];
  let minValue = getValue(minElem);
  for (let i = 1; i < array.length; i++) {
    const value = getValue(array[i]);
    if (value < minValue) {
      minElem = array[i];
      minValue = value;
    }
  }
  return minElem;
}

function getTopLeftPoint(area: Area): Point {
  return getPositionClosestToPoint(area, { x: 0, y: 0 });
}

function getBottomRightPoint(area: Area): Point {
  return getPositionClosestToPoint(area, { x: 1, y: 1 });
}

function getPositionClosestToPoint(area: Area, targetPoint: Point): Point {
  return minBy(area, (point) =>
    calculateDistance({ x: point.x, y: point.y }, targetPoint)
  );
}
