import { BrowserOptions } from '@sentry/react';
import { getEnvironment, isDevEnv } from 'shared/utils/environment';

export const getSentryConfig = (
  applicationType: 'React' | 'ServiceWorker',
  version: string
): BrowserOptions => {
  return {
    dsn: 'https://c63f66769a6f478f93949b84ea43edab@o455099.ingest.sentry.io/5446110',
    environment: getEnvironment(),
    // debug: isDevEnv(),
    initialScope: {
      tags: { applicationType },
    },
    release: `react@${version}`,
  };
};
