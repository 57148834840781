import React, { useMemo } from 'react';
import { DialogContent, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { MemoContractForm } from 'components/contract/Form';
import { useCreateContract } from 'components/dataCreationForms/contract/withCreateContract';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCompanies } from 'components/dataProviders/withCompanies';
import { LabelledEntity } from 'shared/types/commonView';
import { CompanyModel } from 'shared/domain/company/types/model';
import { companyModelToLabelledEntity } from 'shared/domain/company/mapping';

function CreateContractWizardDialog(): React.ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useCreateContract();

  const formName = 'contract';
  useFormCleanupOnUnmount(formName);
  const {
    companies: { items: companies },
  } = useCompanies();

  const companiesAsLabelledEntities: LabelledEntity[] = useMemo(
    () => companies.map(companyModelToLabelledEntity),
    [companies]
  );

  return (
    <Dialog open={open} fullWidth onClose={closeDialog}>
      <WithInputForm
        values={initialValues}
        errors={{}}
        handleFormValidation={handleFormValidation}
        handleFieldValidation={validate}
        onSubmit={submitForm}
        eventType={SUBMIT_EVENT_TYPE}
        dispatchSubmit={releaseSubmitEvent}
        formName={formName}
      >
        <DialogTitle>
          <FormattedMessage id='add_contract_section' />
        </DialogTitle>
        <Divider />

        <DialogContent>
          <MemoContractForm
            companies={companiesAsLabelledEntities}
            isDialog={true}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <DialogButtons
            isPosting={isPosting}
            onSubmit={releaseSubmitEvent}
            onCancel={closeDialog}
          />
        </DialogActions>
      </WithInputForm>
    </Dialog>
  );
}

export const MemoCreateContractWizardDialog = memo(
  CreateContractWizardDialog
);
