import { Box, Typography } from '@mui/material';
import Divider from '@mui/material/Divider';
import { useBacktrack } from 'components/common/withBacktrack';
import { Spinner } from 'components/core';
import {
  useEditProject,
  withEditProject,
} from 'components/dataCreationForms/project/withEditProject';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { FormBottonButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { ProjectForm } from 'components/project/projectForm';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { ReactElement, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

function EditProjectContainer(): ReactElement {
  const {
    submitForm,
    isPosting,
    existingValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
    formName,
  } = useEditProject();
  useFormCleanupOnUnmount(formName);
  const intl = useIntl();

  const { back } = useBacktrack();
  const onCancel = useCallback(() => {
    back();
  }, [back]);

  if (!existingValues) {
    return (
      <Spinner
        reason={`EditProjectWizardContainer Waiting for existing values: ${existingValues}`}
      />
    );
  }

  return (
    <WithInputForm
      values={existingValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {intl.formatMessage({ id: 'edit_project' })}
      </Typography>
      <Divider />
      <Box sx={{ p: 3, mb: 8 }}>
        <ProjectForm isEdit={true} isDialog={false} />
      </Box>
      <FormBottonButtons
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoEditProjectWizardContainer = React.memo(
  withEditProject(EditProjectContainer)
);
