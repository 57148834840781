import React, { useCallback } from 'react';
import Switch from './Switch';
import { IssueStateType } from 'setup/types/core';
import styles from './styles';
import { useIssueFilters } from 'components/dataProviders/withIssueFilters';

type HandleChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  value: string
) => void;

const IssueTypeSwitch: React.FC = () => {
  const classes = styles();
  const { setArchived, archived } = useIssueFilters();

  const handleChange: HandleChange = useCallback(
    (event) => {
      const boolValue =
        event.target.value === IssueStateType.DELETED ? true : false;
      setArchived(boolValue);
    },
    [setArchived]
  );

  return (
    <Switch
      value={archived ? IssueStateType.DELETED : IssueStateType.ACTIVE}
      classes={classes}
      onChange={handleChange}
    />
  );
};

export default IssueTypeSwitch;
