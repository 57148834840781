import * as dbWorktypes from '../db/worktypes';
import * as dbWorktypesService from '../db/worktypesService';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeBroadcastAll, makeBroadcastClear } from './factories';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { OnMessage } from './types';
import { BroadcastChannel } from 'broadcast-channel';
import { debugLog } from 'shared/logger/debugLog';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
export const broadcastClearWorktypes = makeBroadcastClear(
  ChannelNames.worktypeChannel
);

export const broadcastAllWorktypes = makeBroadcastAll(
  dbWorktypesService,
  dbWorktypes,
  ChannelNames.worktypeChannel,
  DomainMessagesTypes.allWorktypes
);

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.worktypeChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllWorktypes: {
        await getAllCurrentWorktypes(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentWorktypes(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const worktypes = await dbWorktypes.get();
  const end = Date.now();
  debugLog('getAllCurrentWorktypes time:', end - start);
  broadcast.postMessage({
    data: { items: worktypes },
    type: DomainMessagesTypes.allWorktypes,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.WORKTYPES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
