import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { projectEntityToModel } from 'shared/domain/project/mapping/toModel';
import { ProjectModel } from 'shared/domain/project/types/model';
import { SetSelectedProjectCustomEvent } from 'serviceWorker/api/types';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import * as projects from 'serviceWorker/db/projects';
import * as projectsService from 'serviceWorker/db/projectsService';
import * as selectedProjectRepository from 'serviceWorker/db/selectedProject';
import { debounce } from 'serviceWorker/helpers/debounce';
import { Identificable } from 'shared/types/commonView';

function sendSelectedProject(
  selectedProject: ProjectModel | undefined
): void {
  const broadcast = new BroadcastChannel(
    ChannelNames.selectedProjectChannel
  );
  broadcast.postMessage({
    type: DomainMessagesTypes.selectedProject,
    data: {
      project: selectedProject,
    },
  });
  broadcast.close();
}

export const getSelectedProject = (): Promise<Identificable | undefined> =>
  selectedProjectRepository.get();

const getSelectedProjectHandler = async (): Promise<void> => {
  const [selectedProjectIdentificable, projects] = await Promise.all([
    selectedProjectRepository.get(),
    getProjects(),
  ]);

  if (!selectedProjectIdentificable) {
    return sendSelectedProject(undefined);
  }

  const selectedProject = projects.find(
    (project) => project._id === selectedProjectIdentificable._id
  );
  if (!selectedProject) {
    selectedProjectRepository.clear();
  }

  return sendSelectedProject(selectedProject);
};

const setSelectedProjectHandler = async (
  event: SetSelectedProjectCustomEvent
): Promise<void> => {
  const identificable = event?.detail;
  if (!identificable) {
    return;
  }
  const projects = await getProjects();
  const selectedProject = projects.find(
    (project) => project._id === identificable._id
  );
  if (!selectedProject) {
    selectedProjectRepository.clear();
    return;
  }

  selectedProjectRepository
    .set({ _id: selectedProject._id })
    .then(() => {
      const clearDataBroadcast = new BroadcastChannel(
        ChannelNames.apiChannel
      );
      clearDataBroadcast.postMessage({
        service: Services.SELECTED_PROJECT,
        method: ServiceMethods.CLEAR_PROJECT_DATA,
      });
      clearDataBroadcast.close();
      sendSelectedProject(selectedProject);
    })
    // currently we dont care if it fails but we dont want an uncaught rejection.
    .catch();
};

export async function getProjects(): Promise<ProjectModel[]> {
  const projectsStatus = await projectsService.get();

  if (!projectsStatus?.isDownloaded || projectsStatus?.isDownloading) {
    return projectsFromEvent();
  }

  return (await projects.get()).map((projectEntity) =>
    projectEntityToModel(projectEntity)
  );
}

export function projectsFromEvent(): Promise<ProjectModel[]> {
  return new Promise((resolve, reject) => {
    const broadcast = new BroadcastChannel(ChannelNames.projectChannel);
    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.allProjects) {
        broadcast.close();
        resolve(event.data.projects);
      }
    };
  });
}

export const init = (): void => {
  const debouncedGet = debounce(getSelectedProjectHandler, 250);

  self.addEventListener(
    RepositoryMessagesTypes.getSelectedProject,
    debouncedGet
  );
  // @ts-ignore custom event
  self.addEventListener(
    RepositoryMessagesTypes.selectedProjectSet,
    setSelectedProjectHandler
  );
};
