import { makeSearchInput } from 'components/common/SearchInput';
import { EntityLabel } from 'components/common/SearchInput/types';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { useIsOffline } from 'components/common/withIsOffline';
import { withProcessesData } from 'components/common/withProcessesData';
import { ContractTable } from 'components/contract/Table';
import {
  useContracts,
  withContracts,
} from 'components/dataProviders/withContracts';
import { MemoTableAnchor } from 'components/tableAnchor';
import React, { ReactElement, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { projectDataSelector } from 'redux/selectors/project';
import { CREATE_COMPANY_PERMISSIONS } from 'setup/permissions';
import {
  Permission,
  validateCreatePermission,
} from '../../../helpers/permission';
import { useInitialSearchEffect } from '../../../hooks/search/useInitialSearch';
import { useSearchUpdate } from '../../../hooks/search/useSearchUpdate';
import { toUserRole } from '../../../redux/selectors/role';
const ContractSearchInput = makeSearchInput(EntityLabel.contract);

const header = {
  id: 'create-contract-button',
  textId: 'button_create_contract',
  dataQaId: 'create-contract-button',
};

function Contracts(): ReactElement {
  const history = useHistory();
  const isOffline = useIsOffline();
  const userRole = useSelector(toUserRole);
  const { loading, setSearchPhrase } = useContracts();
  const { _id: projectId } = useSelector(projectDataSelector);

  useSearchUpdate(EntityLabel.contract, setSearchPhrase);
  useInitialSearchEffect(projectId, EntityLabel.contract, setSearchPhrase);

  const canCreate = useCallback((): Permission => {
    return validateCreatePermission(
      CREATE_COMPANY_PERMISSIONS[userRole],
      isOffline
    );
  }, [userRole, isOffline]);

  const contractCreation = useCallback(() => {
    if (canCreate().permission) {
      history.push('/create-contract');
    }
  }, [canCreate, history]);

  return (
    <MemoTableAnchor
      canCreate={canCreate}
      creationAction={contractCreation}
      TableComponent={ContractTable}
      loading={loading}
      headerWithCreation={header}
      SearchInput={ContractSearchInput}
    />
  );
}

export const MemoContracts = React.memo(
  withFetcherReady(withProcessesData(withContracts(Contracts)))
);
