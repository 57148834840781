import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { LevelModel } from '../types/model';
import { LevelOnView } from '../types/view';

export function levelModelToLevelOnView(model: LevelModel): LevelOnView {
  return {
    createdAt: stringToViewDate(model.createdAt),
    modifiedAt: stringToViewDate(model.modifiedAt),
    _id: model._id,
    label: model.label,
    deleted: model.deleted,
    createdBy: model.createdBy,
    modifiedBy: model.modifiedBy,
    site: model.site,
  };
}
