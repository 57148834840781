import React, { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import { useIntl } from 'react-intl';
import { MemoChecklistItem } from '../ChecklistItem';
import { useStyles } from './styles';
import { ChecklistWizardProps } from './types';
import { FormChecklistItem } from 'views/template/wizard/form/types';
import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { useParams } from 'react-router-dom';
import useIsMobile from '../../../hooks/useIsMobile';

function toReactChecklistItem(
  defaultValues: FormChecklistItem[],
  id: string,
  handleChecklistItemRemove: (itemId: string) => void,
  handleChecklistItemSummaryChange: (
    itemId: string,
    value: string
  ) => void,
  handleChecklistItemNormDescriptionChange: (
    itemId: string,
    value: string
  ) => void
) {
  return function (
    checklistItem: FormChecklistItem,
    index: number
  ): ReactElement {
    return (
      <MemoChecklistItem
        templateId={id}
        key={'checklistItem' + checklistItem.uniqueKeyId}
        errors={checklistItem.errors}
        checklistItem={checklistItem}
        defaultValuesObject={defaultValues[index]}
        index={index}
        handleChecklistItemRemove={handleChecklistItemRemove}
        handleChecklistItemSummaryChange={handleChecklistItemSummaryChange}
        handleChecklistItemNormDescriptionChange={
          handleChecklistItemNormDescriptionChange
        }
      />
    );
  };
}

type ForbiddenTooltipWrapperProps = {
  canAdd: boolean;
  handleChecklistItemAdd: () => void;
};

// Why did you render complained about always rerendering Disabled button with tooltip
// because children is always a new array/object JSX. It had a solution for that
// This component is the solution they presented. Having a pure parent ensures no rerenders.
function ForbiddenTooltipWrapper({
  canAdd,
  handleChecklistItemAdd,
}: ForbiddenTooltipWrapperProps): ReactElement {
  const intl = useIntl();

  return (
    <MemoForbiddenTooltip
      visibleWhen={!canAdd}
      reason='inspection_template_wizard_checklist_item_add_next_disabled_reason'
    >
      <MemoButtonContained
        disabled={!canAdd}
        onClick={handleChecklistItemAdd}
      >
        {intl.formatMessage({
          id: 'inspection_template_wizard_add_next',
        })}
      </MemoButtonContained>
    </MemoForbiddenTooltip>
  );
}
const MemoForbiddenTooltipWrapper = React.memo(ForbiddenTooltipWrapper);

export function ChecklistWizard({
  checklist,
  defaultValues,
  canAdd,
  handleChecklistItemAdd,
  handleChecklistItemRemove,
  handleChecklistItemSummaryChange,
  handleChecklistItemNormDescriptionChange,
}: ChecklistWizardProps): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { id } = useParams<{ id: string }>();

  const items = React.useMemo(
    () =>
      checklist.map(
        toReactChecklistItem(
          defaultValues,
          id,
          handleChecklistItemRemove,
          handleChecklistItemSummaryChange,
          handleChecklistItemNormDescriptionChange
        )
      ),
    [
      checklist,
      id,
      defaultValues,
      handleChecklistItemRemove,
      handleChecklistItemSummaryChange,
      handleChecklistItemNormDescriptionChange,
    ]
  );

  return (
    <>
      {items}
      <div className={classes.contentBox}>
        <span>
          <MemoForbiddenTooltipWrapper
            canAdd={canAdd}
            handleChecklistItemAdd={handleChecklistItemAdd}
          />
        </span>
      </div>
      <Divider className={classes.divider} />
    </>
  );
}

export const MemoChecklistWizard = React.memo(ChecklistWizard);
