import { AddDirectoryUseCase } from 'shared/domain/directory/addDirectory';
import { DirectoryCreateModel } from 'shared/domain/directory/directoryModel';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { DirectoryLocalInsertEntity } from 'serviceWorker/repository/directory/entity';
import { directoryCreateModelToLocalInsertEntity } from 'serviceWorker/repository/directory/mappings';
import { broadcastCreatedDirectory } from 'serviceWorker/broadcasts/directories';
import { addLocal as addDirectory } from 'serviceWorker/db/directories';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';

class DirectorySaver implements AddDirectoryUseCase {
  constructor(
    private addDirectory: (
      data: DirectoryLocalInsertEntity
    ) => Promise<number>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDirectorySaved: (
      directory: [number],
      uniqueId: string
    ) => void
  ) {}
  async execute(
    directoryCreateModel: DirectoryCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddDirectoryUseCase', directoryCreateModel);
    const directoryToAdd: DirectoryLocalInsertEntity =
      directoryCreateModelToLocalInsertEntity(directoryCreateModel);
    const savedDirectory = await this.addDirectory(directoryToAdd);

    this.broadcastDirectorySaved([savedDirectory], uniqueId);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      data: {
        localId: savedDirectory,
        projectId: currentProject?._id,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.directory,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const directorySaver = new DirectorySaver(
  addDirectory,
  addRequest,
  getCurrentProject,
  broadcastCreatedDirectory
);
