import { isNullish } from 'helpers/misc';
import { ISO6391LanguageCode } from 'shared/types/locale';
import React, { useCallback } from 'react';
import { ColumnShape } from 'react-base-table';
import {
  SORT_TYPE,
  Sorting,
  SortingOrder,
} from 'shared/filter/sort/types';
import { Identificable } from 'shared/types/commonView';
import { ColumnSortHandler } from './types';

const collator = new Intl.Collator(ISO6391LanguageCode.en, {
  numeric: true,
  sensitivity: 'case',
});

const sortMethods: {
  [k in SORT_TYPE]: <T>(
    firstAccessedVal: T,
    secondAccessedVal: T,
    order: SortingOrder
  ) => number;
} = {
  [SORT_TYPE.STRING]: (firstAccessedVal, secondAccessedVal, order) => {
    if (isNullish(firstAccessedVal) && isNullish(secondAccessedVal)) {
      return 0;
    } else if (isNullish(firstAccessedVal)) {
      return 1;
    } else if (isNullish(secondAccessedVal)) {
      return -1;
    }

    return toProperOrder(
      collator.compare(
        `${firstAccessedVal}`,
        `${secondAccessedVal}`
      ) as SortNumber,
      order
    );
  },
  [SORT_TYPE.NUMBERS]: (firstAccessedVal, secondAccessedVal, order) => {
    if (firstAccessedVal === secondAccessedVal) {
      return 0;
    } else if (isNullish(firstAccessedVal)) {
      return 1;
    } else if (isNullish(secondAccessedVal)) {
      return -1;
    } else {
      return firstAccessedVal > secondAccessedVal
        ? toProperOrder(-1, order)
        : toProperOrder(1, order);
    }
  },
};

function sortByColumnAccessor<T extends Identificable>(
  list: T[],
  order: SortingOrder,
  column: ColumnShape
): T[] {
  if (!list.length) {
    return [];
  }

  if (!column.sortType) {
    return list;
  }

  const accessor = column.accessor;
  if (!accessor) {
    return list;
  }

  return list.slice().sort((firstElement, secondElement) => {
    const firstAccessedValue = accessor(firstElement);
    const secondAccessedValue = accessor(secondElement);

    if (column.sortType !== undefined) {
      const sortedResult = sortMethods[column.sortType as SORT_TYPE](
        firstAccessedValue,
        secondAccessedValue,
        order
      );

      if (sortedResult !== 0) {
        return sortedResult;
      } else {
        return additionalDefaultSortOrder(
          firstElement,
          secondElement,
          order
        );
      }
    } else {
      return toProperOrder(1, order);
    }
  });
}

export function sortTableEntities<T extends Identificable>(
  entities: T[],
  columns: ColumnShape[],
  sort: Sorting
): T[] {
  if (entities.length === 0) {
    return entities;
  }

  const { key, order } = sort;
  const matchingColumn = columns.find((col) => col.key === key);
  if (!matchingColumn) {
    return entities;
  }

  return sortByColumnAccessor(entities, order, matchingColumn);
}

export function keepNumberIfDefined(value: any): number | null {
  return typeof value === 'number' ? 0 : null;
}

export type SortNumber = -1 | 1 | 0;

export function toProperOrder(
  sortNumber: SortNumber,
  order: SortingOrder
): number {
  if (sortNumber === 0) return 0;
  if (order === SortingOrder.asc) {
    return sortNumber * -1;
  } else {
    return sortNumber;
  }
}

//https://hustro.atlassian.net/browse/PT-3145
// TODO https://hustro.atlassian.net/browse/PT-5124
export function additionalDefaultSortOrder(
  a: { createdAt?: string } & Identificable,
  b: { createdAt?: string } & Identificable,
  order: SortingOrder
): number {
  if (a.createdAt && b.createdAt) {
    return (
      toProperOrder(
        (new Date(b.createdAt).getTime() -
          new Date(a.createdAt).getTime()) as SortNumber,
        order
      ) || toProperOrder(a._id.localeCompare(b._id) as SortNumber, order)
    );
  }
  return toProperOrder(a._id.localeCompare(b._id) as SortNumber, order);
}

export function useColumnSortHandler(
  setSortBy: React.Dispatch<React.SetStateAction<Sorting>>
): ColumnSortHandler<any> {
  return useCallback(
    ({ key, order }) => {
      setSortBy({ key: key.toString(), order: SortingOrder[order] });
    },
    [setSortBy]
  );
}
