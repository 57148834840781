import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IntlContext } from './IntlProviderWrapper';

import { FormControl, MenuItem, Select, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useIsOffline } from 'components/common/withIsOffline';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { StoreState } from '../setup/types/core';
import { getAvailableAppLocales } from './mappings';

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontSize: '0.875rem',
    lineHeight: '2em',
    fontWeight: 'bold' as 'bold',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
  },
  container: {
    display: 'flex',
  },
  select: {
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

export const LanguageSwitch = (): JSX.Element => {
  const isOffline = useIsOffline();
  const { currentLocale } = useSelector((state: StoreState) => state.ui);
  const { setLocale } = React.useContext(IntlContext);
  const classes = useStyles();
  const intl = useIntl();
  const handleLocaleChange = (event: any): void => {
    const locale = event.target.value;

    setLocale(locale);
  };

  return (
    <ForbiddenTooltip
      visibleWhen={isOffline}
      reason='general_offline_operation_unavailable'
    >
      <div className={classes.container}>
        <FormControl
          variant='outlined'
          className={classes.formControl}
          disabled={isOffline}
        >
          <span className={classes.title}>
            <FormattedMessage id='misc_choose_language' />
          </span>
          <Select
            // variant='standard'
            margin='dense'
            id='langSelect'
            value={currentLocale}
            labelId='langSelect-label'
            className={classes.select}
            onChange={handleLocaleChange}
            data-qa='language-select-input'
            MenuProps={{
              style: { zIndex: 20100 },
            }}
          >
            {getAvailableAppLocales().map((locale) => (
              <MenuItem
                value={locale._id}
                key={locale._id}
                data-qa={`language-select-option-${locale}`}
              >
                {locale.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </ForbiddenTooltip>
  );
};
