import React, { useEffect } from 'react';
import { getSearchPhraseFromStorage } from 'components/common/SearchInput/helpers';
import { EntityLabel } from 'components/common/SearchInput/types';

export const useInitialSearchEffect = (
  projectId: string,
  entityLabel: EntityLabel,
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>
): void => {
  useEffect(() => {
    setSearchPhrase(getSearchPhraseFromStorage(projectId, entityLabel));

    return (): void => {
      setSearchPhrase('');
    };
  }, [projectId, setSearchPhrase, entityLabel]);
};
