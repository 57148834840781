import WarningIcon from '@mui/icons-material/Warning';
import InfoIcon from '@mui/icons-material/Info';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import {
  ImageConvertStatusNegative,
  ImageConvertStatus,
} from 'shared/domain/imageConvertStatus/imageConvertStatus';

export function getConvertInfo(
  status: ImageConvertStatusNegative | undefined | null
): string {
  switch (status) {
    case ImageConvertStatus.PENDING:
      return '';
    case ImageConvertStatus.FAILED:
      return 'image_convert_info_failed';
    case ImageConvertStatus.INCORRECT_PAGES:
      return 'image_convert_info_incorrect_pages';
    case ImageConvertStatus.UNCONVERTABLE_EXTENSION:
    case undefined:
    case null:
      return 'image_convert_info_unconvertable';
  }
}

export function getStatusTextId(
  status: ImageConvertStatusNegative | undefined | null
): string {
  switch (status) {
    case ImageConvertStatus.PENDING:
      return 'image_convert_info_pending';
    case ImageConvertStatus.FAILED:
    case ImageConvertStatus.INCORRECT_PAGES:
    case ImageConvertStatus.UNCONVERTABLE_EXTENSION:
    case undefined:
    case null:
      return 'image_convert_info_supported_extensions';
  }
}

export function getStatusIcon(
  status: ImageConvertStatusNegative | undefined | null
): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> {
  switch (status) {
    case ImageConvertStatus.FAILED:
      return WarningIcon;
    case ImageConvertStatus.PENDING:
    case ImageConvertStatus.INCORRECT_PAGES:
    case ImageConvertStatus.UNCONVERTABLE_EXTENSION:
    case undefined:
    case null:
      return InfoIcon;
  }
}
