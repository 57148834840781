import React, { ReactElement, ReactNode } from 'react';
import { useStyles } from './styles';
import cs from 'classnames';

type BigChartCardProps = {
  noMarginTop?: boolean;
  children?: ReactNode;
  noPadding?: boolean;
};
function BigChartCardComponent({
  children,
  noMarginTop,
  noPadding,
}: BigChartCardProps): ReactElement {
  const classes = useStyles();
  return (
    <div
      className={cs(
        classes.root,
        noMarginTop ? 'mt0' : '',
        noPadding ? 'p0' : ''
      )}
    >
      {children}
    </div>
  );
}

export const BigChartCard = React.memo(BigChartCardComponent);
