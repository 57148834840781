import { getInitalLocale } from 'intl/helpers';
import { IntlShape } from 'react-intl';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { findInitialCurrency } from 'shared/domain/project/mapping/toView';
import { ProjectCreateOnView } from 'shared/domain/project/types/view';

export function createEmptyProject(
  intl: IntlShape,
  organization: OrganizationModel
): ProjectCreateOnView {
  const timeZoneString = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    name: '',
    // organizationId: organizationId,
    organization: { _id: organization._id, label: organization.label },
    budget: '',
    processes: [],
    projectEndDate: null,
    projectStartDate: null,
    locale: getInitalLocale(intl),
    currency: findInitialCurrency(
      intl.locale || navigator.language.slice(0, 2) || 'en'
    ),
    timezone: {
      _id: timeZoneString || 'Europe/London',
      label: timeZoneString || 'Europe/London',
    },
  };
}
