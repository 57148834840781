import { DocumentModel } from 'shared/domain/document/documentModel';
import { UploadStatus } from 'shared/types/uploadStatus';

export function isDocumentRemoteAndUnavailable(
  document: DocumentModel
): boolean {
  return (
    Boolean(document._id) &&
    document.data?.uploadStatus !== UploadStatus.success &&
    !document.localData
  );
}
