import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { flushData, flushProjectData } from 'serviceWorker/db/flush';

async function clearData(): Promise<void> {
  await flushData();
  self.dispatchEvent(new Event(RepositoryMessagesTypes.dataFlushed));
}

async function clearProjectData(): Promise<void> {
  await flushProjectData();
  self.dispatchEvent(
    new Event(RepositoryMessagesTypes.projectDataFlushed)
  );
}

export const init = (): void => {
  self.addEventListener(DomainMessagesTypes.clearData, clearData);
  self.addEventListener(
    RepositoryMessagesTypes.clearSelectedProjectData,
    clearProjectData
  );
};
