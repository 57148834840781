import { Issue, IssueProp } from '../setup/types/core';
import { objHasKey } from 'shared/utils/object';

export const createReducer =
  (initialState: any, handlers: any) =>
  (state = initialState, action: any): IssueProp => {
    if (objHasKey(handlers, action.type)) {
      return handlers[action.type](state, action);
    } else {
      return state;
    }
  };

export const updateState = (state: any, stateSlice: any): IssueProp => ({
  ...state,
  ...stateSlice,
});

export const updateInArray = (
  array: any,
  itemId: any,
  updateCallback: any
): Issue[] =>
  array.map((item: any) => {
    if (item._id !== itemId) {
      return item;
    }

    return updateCallback(item);
  });

export const makeAction = (arr: any, action: any): string[] =>
  arr.reduce((a: any, e: any) => {
    a[e] = action;

    return a;
  }, {});
