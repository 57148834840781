const orange500 = '#FBCC30';
const black500 = '#000000';
const t90 = '#FFD8E4';
//https://www.figma.com/design/i5EMdVKJDJhxoDN8cZ8K0x/WEB?node-id=13720-216&t=3IljOL4Nv7s0Hh2m-0
export const enum Color {
  white = '#ffffff',
  purpleWithOpacity = '#8E24AA80',
  purple = '#8E24AA',
  red = '#E53935',
  redWithOpacity = '#E5393510',
  blue = '#315FA3',
  blueWithOpacity = '#315FA335',
  black = '#000000',
  primaryOrange = '#FBCC30',
  primaryLightOrange = '#E4BA2C',

  tertiaryBackground = '#7D5260',
  tertiaryText = '#FFFFFF',

  onTertiaryBackground = '#FFFFFF',
  onTertiaryText = '#7D5260',

  tertiaryContainerText = '#31111D',
  tertiaryContainerBackground = '#FFD8E4',

  onTertiaryContainerText = '#FFD8E4',
  onTertiaryContainerBackground = '#31111D',
}
