import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import {
  broadcastUploadedSiteError,
  broadcastUploadedSite,
  broadcastDeletedSite,
  broadcastUpdatedSite,
} from 'serviceWorker/broadcasts/sites';

export function broadcastSiteResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastUploadedSiteError(response.error);
  }

  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastUploadedSite(response, request.data.uniqueId);
  }

  if (request.method === 'DELETE') {
    return broadcastDeletedSite(response, request.data.uniqueId);
  }

  return broadcastUpdatedSite(response, request.data.uniqueId);
}
