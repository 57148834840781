import {
  CreateLevelCustomEvent,
  EditLevelCustomEvent,
  LevelDeleteCustomEvent,
  LevelRestoreCustomEvent,
} from 'shared/domain/messages/level/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { broadcastAllLevels } from 'serviceWorker/broadcasts/levels';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as levels from 'serviceWorker/db/levels';
import * as levelsService from 'serviceWorker/db/levelsService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import { levelSaver } from './addLevel';
import { levelDeleter } from './deleteLevel';
import { levelEditor } from './editLevel';
import { fetchLevels, fetchUpdatedLevels } from './fetchLevels';
import { levelRestorer } from './restoreLevel';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullLevels: Pull = makePullEntity({
  fetch: fetchLevels,
  channelName: ChannelNames.levelChannel,
  entityName: 'levels',
  entityRepository: levels,
  entityService: levelsService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullLevelsHandler = makePullHandler(
  config,
  levels,
  levelsService,
  pullLevels,
  clearBroadcasts
);

const synchronizeLevels = makeSynchronizeEntity({
  configRepository: config,
  entityService: levelsService,
  entityRepository: levels,
  pullEntityHandler: pullLevelsHandler,
  fetchUpdatedEntities: fetchUpdatedLevels,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'levels',
  channelName: ChannelNames.levelChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeLevels.bind(null, broadcastAllLevels),
    250
  );

  self.addEventListener(RepositoryMessagesTypes.syncLevels, syncDebounced);

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addLevel,
    async function onCreateLevel(
      e: CreateLevelCustomEvent
    ): Promise<void> {
      debugLog('addLevel event', e);
      levelSaver.execute(e.detail.level, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editLevel,
    async function onEditLevel(e: EditLevelCustomEvent): Promise<void> {
      debugLog('editLevel event', e);
      levelEditor.execute(e.detail.level, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteLevel,
    async function onDeleteLevel(
      e: LevelDeleteCustomEvent
    ): Promise<void> {
      debugLog('deleteLevel event', e);
      levelDeleter.execute({
        levelId: e.detail.levelId,
        uniqueId: e.detail.uniqueId,
        projectId: e.detail.projectId,
      });
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.restoreLevel,
    async function onRestoreLevel(
      e: LevelRestoreCustomEvent
    ): Promise<void> {
      debugLog('restoreLevel event', e);
      levelRestorer.execute({
        levelId: e.detail.levelId,
        uniqueId: e.detail.uniqueId,
        projectId: e.detail.projectId,
      });
    }
  );
};
