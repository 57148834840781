import { ReportFields } from 'components/dataCreationForms/withCreateReport';
import { SingleIssueReportFields } from 'components/dataCreationForms/withCreateSingleIssueReport/types';
import {
  ErrorPresentation,
  emailValidation,
  notEmptyArrayValidation,
  requiredValidation,
  stringMaxValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { BaseReportModel } from 'shared/domain/report/reportModel';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';

type Fields = BaseReportModel;
type ReportFormValidation = {
  [key in keyof Fields]: (value: any) => ErrorPresentation;
};
const validation: ReportFormValidation = {
  recipients: recipientsValidation,
  name: nameValidation,
  message: messageValidation,
  localeCode: localeCodeValidation,
};

function nameValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.L, value)
  );
}

function messageValidation(value: any): ErrorPresentation {
  return stringMaxValidation(FieldSize.XL, value);
}

function recipientsValidation(value: any): ErrorPresentation {
  return requiredValidation(value) ||
    notEmptyArrayValidation(value) ||
    value.every((val: any) => emailValidation(val?.email))
    ? { helperText: { id: 'validation_value_must_be_email' } }
    : undefined;
}

function localeCodeValidation(value: any): ErrorPresentation {
  return requiredValidation(value);
}

export function validate(
  key: keyof Fields,
  value: Fields[keyof Fields]
): ErrorPresentation {
  if (!validation[key]) return;
  return validation[key](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: ReportFields & SingleIssueReportFields
): boolean {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = validate(key as keyof Fields, value as any);
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}
