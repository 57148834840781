import { HustroDb } from '.';

type Wrapped<T extends Parameters<any>, U> = (...args: T) => Promise<U>;

export function wrapQuery<T extends Parameters<any>, U>(
  db: HustroDb,
  queryDb: Wrapped<T, U>
): Wrapped<T, U> {
  return async (...args: T): Promise<U> => {
    if (!db.isOpen()) {
      await db.open();
    }
    return queryDb(...args);
  };
}
