import {
  ErrorPresentation,
  customStringMinMaxValidation,
  requiredValidation,
} from 'helpers/validators';
import { noop } from 'shared/utils/function';
import { ValidationSchema } from '../types';
import {
  makeHandleFormValidation,
  onViewValidation,
  validate,
} from '../validation';
import { LevelOnView } from 'shared/domain/level/types/view';
import { FieldSize } from 'shared/types/fieldSize';

const validationSchema: ValidationSchema<LevelOnView> = {
  ...onViewValidation,
  label: labelFieldValidation,
  site: siteFieldValidation,
  map: noop as (value: any) => ErrorPresentation,
};
export const handleFormValidation =
  makeHandleFormValidation(validationSchema);

export function validateLevel(
  key: keyof LevelOnView,
  value: LevelOnView[keyof LevelOnView]
): ErrorPresentation {
  return validate(key, value, validationSchema);
}

function labelFieldValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) ||
    customStringMinMaxValidation(1, FieldSize.S, value)
  );
}

function siteFieldValidation(value: any): ErrorPresentation {
  return requiredValidation(value);
}
