import { PromiseExtended } from 'dexie';
import { HustroDbServiceTablesUnion, HustroDb } from '.';
import { logWithRethrow, getErrorMsg, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { ServiceData } from './service';
import { LogLevel } from 'shared/types/logger';

type DBEntityService<T = ServiceData> = {
  add: (data: any) => Promise<void>;
  clear: () => Promise<void>;
  addSync: (data: any) => Promise<void>;
  get: () => Promise<T>;
  reset: () => Promise<void>;
};

export function entityServiceFactory<T extends ServiceData>(
  db: HustroDb,
  tableName: HustroDbServiceTablesUnion
): DBEntityService<T> {
  const _clear = wrapQuery(db, (): PromiseExtended<void> => {
    return db?.[tableName]?.clear().catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.clear, tableName),
        errorObj: e,
        additionalInfo: null,
      })
    );
  });

  const get = wrapQuery(db, (): PromiseExtended<T> => {
    return (
      db[tableName].toCollection().last() as PromiseExtended<T>
    ).catch((e: any) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.get, tableName),
        errorObj: e,
        additionalInfo: null,
      })
    );
  });

  const add = wrapQuery(db, async (data?: any): Promise<void> => {
    return db.transaction(
      'rw',
      db?.[tableName],
      async function (): Promise<void> {
        await _clear();
        const initialData: Omit<ServiceData, '_id'> = {
          isDownloaded: false,
          isDownloading: false,
          total: 0,
          syncKey: undefined,
        };

        return db?.[tableName]?.put(data || initialData).catch((e) =>
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.add, tableName),
            errorObj: e,
            additionalInfo: { query: { data } },
          })
        );
      }
    );
  });

  const clear = add;

  const addSync = wrapQuery(db, async (data: any): Promise<void> => {
    const currentState = await get();
    if (!currentState || !currentState.isDownloaded) {
      return;
    }
    return add(data);
  });

  const reset = wrapQuery(db, async (): Promise<void> => {
    const data = await get();
    if (data?.shouldReset) {
      return add({ ...data, shouldReset: false });
    }
  });

  return {
    add,
    clear,
    addSync,
    reset,
    get,
  };
}
