import {
  IProjectUploader,
  UploadProject,
  UploadProjectChanges,
  UploadProjectDelete,
  UploadProjectRestore,
} from 'serviceWorker/repository/project/uploadProject';
import { IRequestRunner } from '../httpRequestRunner';
import {
  ProjectHttpCreateRequestModel,
  ProjectHttpDeleteRequestModel,
  ProjectHttpEditRequestModel,
  ProjectHttpRestoreRequestModel,
} from './types';
import { ProjectInDto } from 'shared/domain/project/types/dto';
import { debugLog } from 'shared/logger/debugLog';

export class ProjectRequestRunner implements IRequestRunner {
  constructor(
    private projectUploader: IProjectUploader,
    private uploadProjectUseCase: UploadProject,
    private saveProject: (data: ProjectInDto) => Promise<unknown>,
    private uploadProjectChangesUseCase: UploadProjectChanges,
    private uploadProjectDeleteUseCase: UploadProjectDelete,
    private deleteProject: (projectId: string) => Promise<unknown>,
    private uploadProjectRestoreUseCase: UploadProjectRestore,
    private restoreProject: (projectId: string) => Promise<unknown>
  ) {}
  async execute(
    request:
      | ProjectHttpCreateRequestModel
      | ProjectHttpEditRequestModel
      | ProjectHttpDeleteRequestModel
      | ProjectHttpRestoreRequestModel
  ): Promise<boolean | ProjectInDto> {
    switch (request.method) {
      case 'POST': {
        return this.uploadProject(
          request as ProjectHttpCreateRequestModel
        );
      }
      case 'PUT': {
        return this.uploadProjectChanges(
          request as ProjectHttpEditRequestModel
        );
      }
      case 'DELETE': {
        return this.uploadProjectDelete(
          request as ProjectHttpDeleteRequestModel
        );
      }
      case 'GET': {
        return this.uploadProjectRestore(
          request as ProjectHttpRestoreRequestModel
        );
      }
    }
  }

  private async uploadProject(
    request: ProjectHttpCreateRequestModel
  ): Promise<ProjectInDto> {
    if (!request.data?.projectCreateOutDto) {
      throw new Error('Cannot upload project without values');
    }
    const url = `/project`;

    const projectInDto = await this.uploadProjectUseCase(
      this.projectUploader,
      request.data.projectCreateOutDto,
      url
    );
    await this.saveProject(projectInDto);
    return projectInDto;
  }

  private async uploadProjectChanges(
    request: ProjectHttpEditRequestModel
  ): Promise<ProjectInDto> {
    if (!request.data?.projectEditOutDto) {
      throw new Error('Cannot update project without values');
    }
    const url = `/project/${request.data.projectId}`;

    const projectInDto = await this.uploadProjectChangesUseCase(
      this.projectUploader,
      request.data.projectEditOutDto,
      url
    );
    return projectInDto;
  }

  private async uploadProjectDelete(
    request: ProjectHttpDeleteRequestModel
  ): Promise<boolean> {
    const url = `/project/${request.data.projectId}`;

    await this.uploadProjectDeleteUseCase(this.projectUploader, url);
    await this.deleteProject(request.data.projectId);
    return true;
  }

  private async uploadProjectRestore(
    request: ProjectHttpRestoreRequestModel
  ): Promise<boolean> {
    const url = `/project/${request.data.projectId}`;

    await this.uploadProjectRestoreUseCase(this.projectUploader, url);
    await this.restoreProject(request.data.projectId);
    return true;
  }
}
