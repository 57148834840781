import { Chart } from 'shared/types/analytics';
import {
  makeDefaultAddBatch,
  makeDefaultGetOne,
} from './defaultDaoFactories';
import db from './index';

type ChartData = {
  series: any;
  filters: any;
  projectId: string;
  name: Chart;
  locale: string;
  additionalParams: any;
  updatedAt: number;
};

export type ChartDataEntity = {
  _id: string;
} & ChartData;

export const getOne = makeDefaultGetOne<Chart, ChartDataEntity>(
  db,
  db.analytics,
  'name'
);

export const addBatch = makeDefaultAddBatch<ChartDataEntity>(
  db,
  db.analytics
);
