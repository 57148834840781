import {
  CompanyDeleteCustomEventDetail,
  CompanyRestoreCustomEventDetail,
  CreateCompanyCustomEventDetail,
  EditCompanyCustomEventDetail,
  GetCompanyEventDetail,
} from 'shared/domain/messages/company/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncCompanies);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetCompanyEventDetail>(
      RepositoryMessagesTypes.getCompany,
      {
        detail: { id: e.id },
      }
    )
  );
};

const create = (e: CreateCompanyCustomEventDetail): void => {
  debugLog('CREATE COMPANY EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateCompanyCustomEventDetail>(
      RepositoryMessagesTypes.addCompany,
      {
        detail: {
          companyCreateModel: e.companyCreateModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const edit = (e: EditCompanyCustomEventDetail): void => {
  debugLog('EDIT COMPANY EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditCompanyCustomEventDetail>(
      RepositoryMessagesTypes.editCompany,
      {
        detail: {
          companyEditModel: e.companyEditModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const remove = (e: CompanyDeleteCustomEventDetail): void => {
  debugLog('DELETE COMPANY EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CompanyDeleteCustomEventDetail>(
      RepositoryMessagesTypes.deleteCompany,
      {
        detail: {
          companyId: e.companyId,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const restore = (e: CompanyRestoreCustomEventDetail): void => {
  debugLog('RESTORE COMPANY EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CompanyRestoreCustomEventDetail>(
      RepositoryMessagesTypes.restoreCompany,
      {
        detail: {
          companyId: e.companyId,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

export default { sync, getOne, create, edit, remove, restore };
