import { add as editIssueStrategy } from 'serviceWorker/db/httpRequests';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { UpdateIssueOutDto } from 'shared/dtos/out/issue';
import { LabelledEntity } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';
import {
  EditIssueModel,
  IssueModel,
} from '../../../../shared/domain/issue/issueModel';
import { EditIssueUseCase } from '../../../../shared/domain/issue/types';

function makeEditIssueUseCase(
  editIssueStrategy: (editIssueModel: __HttpRequestModel__) => Promise<any>
): EditIssueUseCase {
  return function editIssue(issueAddress, editIssueModel): Promise<void> {
    debugLog('editIssue', issueAddress, editIssueModel);
    const editIssueOutDto = editIssueModeltoChangesOutDto(editIssueModel);

    return editIssueStrategy({
      createdAt: Date.now(),
      method: 'PUT',
      url: `project/${issueAddress.projectId}/issue/${issueAddress.issueId}`,
      data: editIssueOutDto,
      entityType: HttpRequestModelType.issue,
      status: HttpRequestStatus.NEW,
    });
  };
}

const editIssue = makeEditIssueUseCase(editIssueStrategy);
export default editIssue;

function makeEditIssueModelExtendedDataOutDtoMap(): {
  [key in keyof IssueModel['extendedData']]: (p: any) => any;
} {
  return {
    workTypes: labelledEntitiesToStrings,
    rootCauses: labelledEntitiesToStrings,
    estimatedCost: costs,
    finalCost: costs,
    targetCompletionDate: identity,
    finalCompletionDate: identity,
    solutionProposal: identity,
    impact: labelledEntityToString,
    environmentalAspect: labelledEntityToString,
    effect: labelledEntityToString,
    hazardCategory: labelledEntitiesToStrings,
    expectedFine: identity,
    imposedFine: identity,
    solutionMethod: identity,
    subcontractorRepresentative: identity,
    decisionToImposeFine: labelledEntityToString,
    circumstances: labelledEntityToString,
    proposedCorrectiveAction: labelledEntityToString,
    costCode: identity,
    daysOfInabilityToWork: identity,
    personUnableToWork: identity,
    spilledSubstance: labelledEntityToString,
    waterLeakageScale: identity,
    soilLeakageScale: identity,
    contaminatedSoilScale: identity,
    targetStartDate: identity,
    finalStartDate: identity,
    executedByCompanies: labelledEntitiesToStrings,
    targetAmount: identity,
    completedAmount: identity,
    numberOfEmployees: identity,
  };
}

function makeEditIssueModelPrimaryDataOutDtoMap(): {
  [key in keyof IssueModel['primaryData']]: (p: any) => any;
} {
  return {
    site: labelledEntityToString,
    level: labelledEntityToString,
    title: identity,
    assignee: labelledEntityToString,
    description: identity,
    detectionDate: identity,
    subcontractors: labelledEntitiesToStrings,
    contractNumbers: labelledEntitiesToStrings,
    positionOnMap: identity,
    executor: labelledEntityToString,
    selectedLocationType: identity,
    finalAreas: identity,
    targetAreas: identity,
  };
}

function editIssueModeltoChangesOutDto(
  changesModel: EditIssueModel
): UpdateIssueOutDto {
  const outDto: UpdateIssueOutDto = {};
  if (changesModel.extendedData) {
    const mappings = makeEditIssueModelExtendedDataOutDtoMap();
    outDto.extendedData = {} as UpdateIssueOutDto['extendedData'];
    const keys = Object.keys(changesModel.extendedData);
    // @ts-ignore Object.keys defined badly
    keys.forEach((key: keyof EditIssueModel['extendedData']) => {
      // @ts-ignore Object.keys defined badly
      outDto.extendedData[key] = mappings[key](
        // @ts-ignore Object.keys defined badly
        changesModel.extendedData[key]
      );
    });
  }
  if (changesModel.primaryData) {
    const mappings = makeEditIssueModelPrimaryDataOutDtoMap();
    outDto.primaryData = {};
    const keys = Object.keys(changesModel.primaryData);
    // @ts-ignore Object.keys defined badly
    keys.forEach((key: keyof EditIssueModel['primaryData']) => {
      // @ts-ignore Object.keys defined badly
      outDto.primaryData[key] = mappings[key](
        // @ts-ignore Object.keys defined badly
        changesModel.primaryData[key]
      );
    });
  }

  if (changesModel.stage) {
    outDto.stage = changesModel.stage;
  }
  if (changesModel.mainImage || changesModel.mainImage === null) {
    outDto.mainImage = changesModel.mainImage;
  }

  return outDto;
}

function labelledEntitiesToStrings(
  labelledEntities: LabelledEntity[]
): string[] {
  return labelledEntities.map((le) => le._id);
}

function labelledEntityToString(
  labelledEntity: LabelledEntity | undefined | null
): string | undefined | null {
  if (labelledEntity === null) return labelledEntity;
  return labelledEntity?._id;
}

function costs(costs: any[]): any {
  return costs.map((cost) => {
    return {
      ...cost,
      coveredBy: cost.coveredBy?._id,
    };
  });
}

function identity<T>(x: T): T {
  return x;
}
