import { PropsWithChildren, ReactElement } from 'react';
import { DelayIcon, OnTime } from './icons';
import { useStyles } from './styles';
import { FormattedMessage, useIntl } from 'react-intl';

export function StaticField({
  isExpanded,
  label,
  hasValue,
  children,
}: PropsWithChildren<{
  label: string | undefined;
  isExpanded: boolean;
  hasValue: boolean;
}>): ReactElement {
  if (!label) return <></>;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: hasValue || isExpanded ? '0.75rem' : '0px',
        maxHeight: !hasValue && !isExpanded ? '0' : '100px',
        height: 'max-content',
        transition: 'max-height .2s, margin .2s',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <ExpandableLabel label={label} isExpanded={isExpanded} />
      {children}
    </div>
  );
}

export function ExpandableField({
  isExpanded,
  label,
  children,
}: PropsWithChildren<{
  label: string | undefined;
  isExpanded: boolean;
}>): ReactElement {
  if (!label) return <></>;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        alignItems: 'flex-start',
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        height: 'max-content',
        maxHeight: isExpanded ? '100px' : '0px',
        transition: 'max-height .2s, margin .2s',
        overflow: 'hidden',
        marginTop: isExpanded ? '0.75rem' : '0px',
      }}
    >
      <ExpandableLabel label={label} isExpanded={isExpanded} />
      {children}
    </div>
  );
}

function ExpandableLabel({
  label,
  isExpanded,
}: {
  label: string;
  isExpanded: boolean;
}): ReactElement {
  return (
    <div
      style={{
        boxSizing: 'border-box',
        fontWeight: 700,
        fontSize: '0.75rem',
        color: '#757575',
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        height: 'max-content',
        maxHeight: isExpanded ? '100px' : '0px',
        marginBottom: '4px',
        overflow: 'hidden',
        transition: 'max-height .2s',
      }}
    >
      {label}
    </div>
  );
}

function DelayInfo({
  delay,
  show,
  isExpanded,
}: {
  delay: number;
  isExpanded: boolean;
  show: boolean;
}): ReactElement {
  const intl = useIntl();
  return delay > 0 ? (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        color: '#E53935',
        justifyContent: 'flex-start',
        gap: '4px',
        opacity: show ? '1' : '0',
        transition: 'opacity 0.2s',
      }}
    >
      <DelayIcon
        htmlColor='#E53935'
        style={{
          fontSize: '0.75rem',
        }}
      />
      <span>
        {intl.formatMessage({ id: 'issue_delay_value' }, { days: delay })}
      </span>
    </span>
  ) : (
    <span
      style={{
        display: 'flex',
        alignItems: 'center',
        color: '#00904C',
        justifyContent: 'flex-start',
        gap: '4px',
        opacity: show ? '1' : '0',
        transition: 'opacity 0.2s',
      }}
    >
      <OnTime
        htmlColor='#00904C'
        style={{
          fontSize: '0.75rem',
        }}
      />
      {isExpanded && show && (
        <span>
          {intl.formatMessage({
            id:
              delay < 0
                ? 'issue_delay_before_time'
                : 'issue_delay_on_time',
          })}
        </span>
      )}
    </span>
  );
}

export function TextValueField({ text, isExpanded }): ReactElement {
  if (!text && isExpanded) {
    return <NoDataValue />;
  }

  return (
    <div
      style={{
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      }}
    >
      {text}
    </div>
  );
}

export function DateFieldValueGrid({
  dates,
  Arrow,
  delays,
  isExpanded,
}: {
  dates: (string | undefined | null)[];
  Arrow: () => ReactElement;
  delays: (number | null)[];
  isExpanded: boolean;
}): ReactElement {
  const cs = useStyles();

  const [targetStartDate, targetEndDate] = dates;

  if (dates.length < 2) {
    return (
      <span className={cs.cardDateValueContainer}>
        <DateWithDelayIcon
          date={targetStartDate}
          delay={delays[0]}
          isExpanded={isExpanded}
        />
      </span>
    );
  }

  return (
    <div className={cs.cardDateFieldGrid}>
      <span className={cs.cardDateValueContainer}>
        <DateWithDelayIcon
          date={targetStartDate}
          delay={delays[0]}
          isExpanded={isExpanded}
        />
      </span>
      <Arrow />
      <span className={cs.cardDateValueContainer}>
        <DateWithDelayIcon
          date={targetEndDate}
          delay={delays[1]}
          isExpanded={isExpanded}
        />
      </span>
    </div>
  );
}

function DateWithDelayIcon({
  date,
  delay,
  isExpanded,
}: {
  isExpanded: boolean;
  date?: string | null;
  delay?: number | null;
}): ReactElement | null {
  if (!date) {
    return <NoDataValue />;
  }

  return (
    <>
      <span>{date}</span>
      {typeof delay === 'number' && !isExpanded && (
        <DelayInfo
          delay={delay}
          show={!isExpanded}
          isExpanded={isExpanded}
        />
      )}
    </>
  );
}

export function DelayValuesGrid({
  isExpanded,
  delays,
}: {
  isExpanded: boolean;
  delays: (number | null)[];
}): ReactElement {
  const cs = useStyles();

  const [first, second] = delays;

  if (
    delays.length < 2 &&
    (typeof first !== 'number' || isNaN(first)) &&
    isExpanded
  ) {
    return <NoDataValue />;
  }

  if (delays.length < 2 && typeof first === 'number' && !isNaN(first)) {
    return (
      <div>
        <DelayInfo
          delay={first}
          show={isExpanded}
          isExpanded={isExpanded}
        />
      </div>
    );
  }

  return (
    <div
      className={cs.cardDateFieldGrid}
      style={{
        // https://stackoverflow.com/questions/3508605/how-can-i-transition-height-0-to-height-auto-using-css
        height: 'max-content',
        maxHeight: isExpanded ? '100px' : '0px',
        transition: 'max-height .2s',
        overflow: 'hidden',
      }}
    >
      <div>
        {(typeof first === 'number' && !isNaN(first) && (
          <DelayInfo
            delay={first}
            show={isExpanded}
            isExpanded={isExpanded}
          />
        )) || <NoDataValue />}
      </div>
      <div></div>
      <div>
        {(typeof second === 'number' && !isNaN(second) && (
          <DelayInfo
            delay={second}
            show={isExpanded}
            isExpanded={isExpanded}
          />
        )) || <NoDataValue />}
      </div>
    </div>
  );
}

function NoDataValue(): ReactElement {
  return (
    <span
      style={{
        color: '#979797',
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '100%',
      }}
    >
      <FormattedMessage id='issue_card_no_data' />
    </span>
  );
}
