import { HashMap } from 'shared/types/commonView';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from '@mui/material';
import { useStylesCheckbox } from './styles';
import { ChartFilter } from 'shared/types/analytics';

export function CheckboxControl(props: {
  filter: ChartFilter<unknown>;
  localSetter: (value: any) => void;
  localValue: HashMap<boolean>;
  disabled: boolean;
}): ReactElement {
  const classes = useStylesCheckbox();
  const intl = useIntl();
  const { labelId, labels } = props.filter;
  const localSetter = props.localSetter;
  const localValue = props.localValue;

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    localSetter({
      ...localValue,
      [event.target.name]: event.target.checked,
    });
  };
  return (
    <div className={classes.root}>
      <FormControl
        variant='standard'
        component='fieldset'
        disabled={props.disabled}
      >
        <FormLabel component='legend'>
          {intl.formatMessage({ id: labelId, defaultMessage: labelId })}
        </FormLabel>
        <FormGroup>
          {Object.keys(localValue).map((key) => (
            <FormControlLabel
              key={key}
              control={
                <Checkbox
                  color='primary'
                  checked={localValue[key]}
                  onChange={handleChange}
                  name={key}
                />
              }
              label={intl.formatMessage({
                id: labels?.[key],
                defaultMessage: labels?.[key],
              })}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}
