import { StyleRules, createStyles } from '@mui/styles';
const styles = (): StyleRules =>
  createStyles({
    contentWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      backgroundColor: '#f2f2f2',
      overflow: 'hidden',
    },
    content: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#ffffff',
    },
  });

export { styles };

export const moduleContainerWidth = {
  xs: '100%',
  sm: 'calc(100% - 64px)',
};

export const leftMainContainerPosition = {
  xs: 0,
  sm: '64px',
};
