import {
  makeHandleFormValidation,
  validate,
} from 'components/dataCreationForms/validation';
import { useUsers } from 'components/dataProviders/withUsers';
import { getUploadedMessageListener } from 'shared/domain/commonModel';
import { userCreateOnViewToUserCreateModel } from 'shared/domain/user/mapping/toModel';
import { startUserCreate } from 'shared/domain/user/startUserCreate';
import { UserModel } from 'shared/domain/user/userModel';
import { ErrorPresentation } from 'helpers/validators';
import { useGetAllUsers } from 'hooks/useGetAllUsers';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { UserCreateOnView } from 'shared/domain/user/types/view';
import {
  CreateUserContext,
  OpenCreateUserDialog,
  OpenCreateUserDialogParams,
} from '../types';
import { getCreateUserValidationSchema } from '../validation';
import { createEmptyUser } from './model';
export const formName = 'user';
const SUBMIT_EVENT_TYPE = 'submit-user';
const WithCreateUserContext = createContext<CreateUserContext | undefined>(
  undefined
);

function WithCreateUser({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const { all: allUsers } = useUsers();
  const [isPosting, setIsPosting] = useState<boolean>(false);
  const project = useSelector(projectDataSelector);
  const projectId = project._id;
  const organizationId = project.organizationId;
  const intl = useIntl();
  const history = useHistory();
  const { getAll: getAllUsers } = useGetAllUsers();
  const fetchUsers = getAllUsers;
  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const emptyUser = createEmptyUser(intl);
  const [initialValues, setInitialValues] =
    useState<UserCreateOnView>(emptyUser);

  const [open, setOpen] = useState(false);
  const emailsString = useMemo(
    () =>
      allUsers.items.map((usr) => usr.email.toLocaleLowerCase()).join(','),
    [allUsers]
  );
  const validationSchema = useMemo(() => {
    return getCreateUserValidationSchema(emailsString);
  }, [emailsString]);

  const validateCreateUser = useCallback(
    (
      key: keyof UserCreateOnView,
      value: UserCreateOnView[keyof UserCreateOnView]
    ): ErrorPresentation => {
      return validate(key, value, validationSchema);
    },
    [validationSchema]
  );

  const handleFormValidation = useMemo(() => {
    return makeHandleFormValidation(validationSchema);
  }, [validationSchema]);

  const openDialog: OpenCreateUserDialog = useCallback(
    ({ siteIds, freeSoloLabel }: OpenCreateUserDialogParams) => {
      setOpen(true);
      const currentValues = { ...initialValues, site: siteIds };
      if (freeSoloLabel) {
        currentValues.label = freeSoloLabel;
      }
      setInitialValues(currentValues);
    },
    [initialValues]
  );
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: UserCreateOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const userCreateModel = userCreateOnViewToUserCreateModel({
        userCreateOnView: values,
        projectId,
        organizationId,
      });

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUploadedMessageListener(
          async (message: UserModel) => {
            await fetchUsers();
            broadcast.close();
            resolve(message);
            setOpen(false);
            clearTimeout(timeout);
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.userChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateUser: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startUserCreate(userCreateModel, uniqueId);
      })
        .then((user: unknown): any => {
          if (user && (user as Identificable)._id) {
            setOpen(false);

            const redirectAfterCreation =
              window.location.href.indexOf('create-user') > 0;
            if (redirectAfterCreation) {
              setTimeout(() => {
                history.push(`/user/${(user as Identificable)._id}`);
                setIsPosting(false);
              }, 1000);
            } else {
              setOpen(false);
              setIsPosting(false);
            }
          }
        })
        .catch(() => {});
    },
    [dispatch, projectId, organizationId, fetchUsers, history]
  );

  const ctx: CreateUserContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateCreateUser,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
    handleFormValidation,
    validateCreateUser,
  ]);

  return (
    <WithCreateUserContext.Provider value={ctx}>
      {children}
    </WithCreateUserContext.Provider>
  );
}

function useCreateUser(): CreateUserContext {
  const context = useContext(WithCreateUserContext);
  if (context === undefined) {
    throw new Error(
      'useCreateUser must be used within an CreateUserContext'
    );
  }
  return context;
}

const withCreateUser =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateUser>
      <Component {...props} />
    </WithCreateUser>
  );

export { WithCreateUser, useCreateUser, withCreateUser };
