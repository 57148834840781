import {
  ProjectCreateOutDto,
  ProjectEditOutDto,
  ProjectInDto,
} from 'shared/domain/project/types/dto';

export interface IProjectUploader {
  POST: (url: string, data: any) => Promise<any>;
  PUT: (url: string, data: any) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadProjectChanges {
  (
    uploader: IProjectUploader,
    body: ProjectEditOutDto,
    url: string
  ): Promise<ProjectInDto>;
}

export interface UploadProject {
  (
    uploader: IProjectUploader,
    body: ProjectCreateOutDto,
    url: string
  ): Promise<ProjectInDto>;
}

export interface UploadProjectDelete {
  (uploader: IProjectUploader, url: string): Promise<unknown>;
}

export interface UploadProjectRestore {
  (uploader: IProjectUploader, url: string): Promise<unknown>;
}

export function uploadProjectChanges(
  uploader: IProjectUploader,
  body: ProjectEditOutDto,
  url: string
): Promise<ProjectInDto> {
  return uploader.PUT(url, body);
}

export function uploadProject(
  uploader: IProjectUploader,
  body: ProjectCreateOutDto,
  url: string
): Promise<ProjectInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadProjectDelete(
  uploader: IProjectUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}

export function uploadProjectRestore(
  uploader: IProjectUploader,
  url: string
): Promise<unknown> {
  return uploader.GET(url);
}
