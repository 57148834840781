import { Subscribe } from 'components/broadcastChannelListeners/channelListener/channelListener';
import { Message } from 'shared/domain/messages/message';
import { BroadcastChannel } from 'broadcast-channel';

export function broadcastToSubscriptionAdapter(
  broadcast: BroadcastChannel<any>
): Subscribe {
  return function adapt(callback: (message: Message) => void) {
    broadcast.onmessage = (event) => {
      callback(event);
    };

    return () => broadcast.close();
  };
}
