import React, {
  useState,
  useEffect,
  MouseEventHandler,
  useMemo,
} from 'react';
import { Chip, Snackbar } from '@mui/material';
import { Alert, AlertTitle } from '@mui/material';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { useIntl } from 'react-intl';

const SwToaster: React.FC = () => {
  const [show, setShow] = useState(false);
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.swNewVersionChannel),
    []
  );
  const intl = useIntl();

  useEffect(() => {
    broadcast.onmessage = async (): Promise<void> => {
      setShow(true);
    };
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  const handleUpdate: MouseEventHandler = (e): void => {
    e.preventDefault();
    broadcast.postMessage({ triggerUpdate: true });
  };

  if (!show) {
    return null;
  }

  return (
    <Snackbar
      open={true}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert severity='info'>
        <AlertTitle>
          {intl.formatMessage({ id: 'service_worker_update_message' })}
        </AlertTitle>
        <Chip
          onClick={handleUpdate}
          label={intl.formatMessage({ id: 'refresh' })}
        />
      </Alert>
    </Snackbar>
  );
};

export default SwToaster;
