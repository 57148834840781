import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import { ReactElement, useState, useEffect } from 'react';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { IssueCard } from 'components/issue/Card';

export function SelectableIssueCard({
  issue,
  onClick,
}: {
  issue: IssueModel;
  onClick?: (issueModel: IssueModel) => void;
}): ReactElement {
  const { selectedStore } = useSelectedStoreContext<string>();
  const [isSelected, setIsSelected] = useState(
    selectedStore.get() === issue._id
  );

  useEffect(() => {
    setIsSelected(selectedStore.get() === issue._id);
    return selectedStore.subscribe(() => {
      setIsSelected(selectedStore.get() === issue._id);
    });
  }, [selectedStore, issue._id]);

  return (
    <IssueCard
      key={`${issue._id}`}
      issue={issue}
      onClick={onClick}
      isSelected={isSelected}
    />
  );
}
