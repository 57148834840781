import SumoLogger from 'sumo-logger';
import {
  endpoint,
  sourceName,
} from 'shared/logger/config/sumoLogicBaseConfig';

export const appSumoLogicLogger = new SumoLogger({
  sourceName,
  endpoint,
  sendErrors: true,
  sourceCategory: 'React app',
});
