import { LocationAreaType, Point } from 'shared/domain/area/types';
import { useImageBoundsContext } from 'components/responsivePanPinchZoom';
import { RefObject, useState, useEffect } from 'react';
import {
  useTransformContext,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { debugLog } from 'shared/logger/debugLog';
import { Color } from 'shared/utils/colors';
import {
  plannedAreaBackgroundPattern,
  plannedAreaBackgroundPatternMarked,
} from './PlannedAreaPattern';

export const positioning = {
  position: 'absolute' as 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
};

export function coordinatesToPercentageString(coordinate: number): string {
  return `${coordinate * 100}%`;
}

// let's assume that the typical img has this size
const baseImageWidth = 10000;
const baseContainerWidth = 500;

/**
 * Returns the size scaled according to image parent div dimensions
 *
 * @remarks
 * method proper for gradient, where userSpaceOnUse pattern approach is used
 * we base here on wrapper dimensions, not image, because
 * - images doesn't always fit the screen
 * - userSpaceOnUse pattern is used
 * the gradient has to similar when
 *
 * @param baseSize - target size for initial, default baseContainerWidth
 * @returns calculated size
 */
export function useSizeScaledToContainerWidth(baseSize: number): number {
  const {
    transformState: { scale },
    props: { initialScale },
    wrapperComponent,
  } = useTransformContext();
  useTransformEffect(({ state, instance }) => {
    setCurrentScale(state.scale);
    instance.wrapperComponent?.offsetHeight &&
      setWrapperWidth(instance.wrapperComponent?.offsetWidth);
  });

  const { width: imageWidth, height: imageHeight } =
    useImageBoundsContext();
  const [currentScale, setCurrentScale] = useState(scale);

  // by default we assume that image takes 100% width
  // if it's not true, wrapperWidth grows and gradient is wide
  // max is 1 because we don't allow on x scroll
  const narrowImageRatio = Math.max(imageWidth / imageHeight, 1);
  const [wrapperWidth, setWrapperWidth] = useState(
    (wrapperComponent?.offsetWidth || baseContainerWidth) *
      narrowImageRatio
  );

  useEffect(() => {
    wrapperComponent?.offsetWidth &&
      setWrapperWidth(wrapperComponent?.offsetWidth * narrowImageRatio);
  }, [wrapperComponent?.offsetWidth, narrowImageRatio]);

  // decrease size with every scroll
  let zoomFactor = currentScale / (initialScale || 1);
  const sizeAfterScroll = baseSize / Math.sqrt(zoomFactor);

  let result = sizeAfterScroll;

  // we use this max because very small pictures don't fit the container initially
  // as the result, scaled elements get too small
  const ratioToBase = wrapperWidth / baseContainerWidth;

  result = result * ratioToBase;
  return Number(result.toFixed(2));
}

export function useSizeScaledToImage(
  baseSize: number,
  minSize?: number
): number {
  const {
    transformState: { scale },
    props: { initialScale },
    wrapperComponent,
  } = useTransformContext();
  const [currentScale, setCurrentScale] = useState(scale);
  const [wrapperHeight, setWrapperHeight] = useState(
    wrapperComponent?.offsetHeight || 0
  );
  const [wrapperWidth, setWrapperWidth] = useState(
    wrapperComponent?.offsetWidth || 0
  );

  useTransformEffect(({ state, instance }) => {
    setCurrentScale(state.scale);
    instance.wrapperComponent?.offsetHeight &&
      setWrapperHeight(instance.wrapperComponent?.offsetHeight);
    instance.wrapperComponent?.offsetWidth &&
      setWrapperWidth(instance.wrapperComponent?.offsetWidth);
  });

  const { width: imageWidth, height: imageHeight } =
    useImageBoundsContext();
  // we use this max because very small pictures don't fit the container initially
  // in result, scaled elements get too small if we compare them to the image
  // in such edge case it's better to fit them to the div container
  const containerWidth = Math.max(wrapperWidth, imageWidth);
  const containerHeight = Math.max(imageHeight, wrapperHeight);
  const actualContainerSize = containerWidth + containerHeight;

  // decrease size with every scroll
  let zoomFactor = currentScale / (initialScale || 1);
  const sizeAfterScroll = baseSize / Math.sqrt(zoomFactor);

  let result = sizeAfterScroll;

  const ratioToBase =
    actualContainerSize / (baseImageWidth + baseImageWidth);

  result = result * ratioToBase;
  if (minSize) {
    result = Math.max(result, minSize);
  }
  return Number(result.toFixed(2));
}

export function parsePositionOnScreenToXY(
  evt: PositionOnScreen,
  svgRef: RefObject<SVGSVGElement>
): Point | null {
  const svg = svgRef.current;
  if (!svg) {
    return null;
  }
  const rect = svg.getBoundingClientRect();

  // The click coordinates in pixels relative to the SVG container
  const mouseX = evt.clientX - rect.left;
  const mouseY = evt.clientY - rect.top;

  // Scales click coordinates to SVG coordinates
  const svgWidth = rect.width;
  const svgHeight = rect.height;

  // Converting click coordinates to SVG coordinates
  const x = mouseX / svgWidth;
  const y = mouseY / svgHeight;

  return { x, y };
}

export type PositionOnScreen = { clientX: number; clientY: number };
export type VertexInitialLocation = {
  startX: number;
  startY: number;
  vertexIndex: number;
};
export function wasItDragEvent(
  place: PositionOnScreen,
  vertexInitialLocation: VertexInitialLocation | null
): vertexInitialLocation is VertexInitialLocation {
  if (!vertexInitialLocation) {
    return false;
  }
  // Calculate the difference in position
  const diffX = Math.abs(place.clientX - vertexInitialLocation.startX);
  const diffY = Math.abs(place.clientY - vertexInitialLocation.startY);
  // Define a threshold for distinguishing between click and drag
  const threshold = 5;

  return diffX > threshold || diffY > threshold;
}

export function getAreaColors(
  type: LocationAreaType,
  isSelected: boolean,
  isHovered: boolean
): {
  lineColor: Color;
  backgroundColor?: Color;
  patternName?:
    | 'plannedAreaBackgroundPattern'
    | 'plannedAreaBackgroundPatternMarked';
} {
  const isActive = isSelected || isHovered;
  if (type === LocationAreaType.planned) {
    if (isActive) {
      return {
        lineColor: Color.purple,
        backgroundColor: Color.purpleWithOpacity,
        patternName: plannedAreaBackgroundPatternMarked,
      };
    }
    return {
      lineColor: Color.red,
      backgroundColor: undefined,
      patternName: plannedAreaBackgroundPattern,
    };
  }
  if (isActive) {
    return {
      lineColor: Color.purple,
      backgroundColor: Color.purpleWithOpacity,
      patternName: undefined,
    };
  }
  return {
    lineColor: Color.blue,
    backgroundColor: Color.blueWithOpacity,
    patternName: undefined,
  };
}

export function shoelaceArea(points: Point[]): number {
  let area = 0;
  const n = points.length;
  for (let i = 0; i < n; i++) {
    const j = (i + 1) % n;
    area += points[i].x * points[j].y - points[j].x * points[i].y;
  }
  return Math.abs(area) / 2;
}
