const OPEN_CREATE_DIRECTORY_FORM_EVENT_TYPE = 'open-directory-form';
const OPEN_EDIT_DIRECTORY_FORM_EVENT_TYPE = 'open-edit-directory-form';
export type OpenEditDirectoryEvent = {
  localId: number;
  name: string;
  _id?: string;
};
export function openDirectoryForm(
  directory?: OpenEditDirectoryEvent
): void {
  if (directory) {
    window.dispatchEvent(
      new CustomEvent<OpenEditDirectoryEvent>(
        OPEN_EDIT_DIRECTORY_FORM_EVENT_TYPE,
        { detail: { localId: directory?.localId, name: directory?.name } }
      )
    );
    return;
  }
  window.dispatchEvent(new Event(OPEN_CREATE_DIRECTORY_FORM_EVENT_TYPE));
}

export function listenToOpenDirectoryForm(
  callback: (ev: Event | CustomEvent<OpenEditDirectoryEvent>) => void
): () => void {
  window.addEventListener(OPEN_CREATE_DIRECTORY_FORM_EVENT_TYPE, callback);
  window.addEventListener(OPEN_EDIT_DIRECTORY_FORM_EVENT_TYPE, callback);

  return () => {
    window.removeEventListener(
      OPEN_CREATE_DIRECTORY_FORM_EVENT_TYPE,
      callback
    );
    window.removeEventListener(
      OPEN_EDIT_DIRECTORY_FORM_EVENT_TYPE,
      callback
    );
  };
}
