import { shallowEqual } from 'shared/utils/shallowEqual';
import { ConfigSet } from './types';
import * as config from '../db/config';
import flush from '../db/flush';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { getFetchConfig } from 'serviceWorker/services/config';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';

const set: ConfigSet = async (data) => {
  const current = await getFetchConfig();
  const dataSet = {
    locale: data.locale,
    frontendVersion: data.frontendVersion,
    api: data.api,
    projectId: data.projectId,
  };

  const broadcast = new BroadcastChannel(ChannelNames.configChannel);
  broadcast.postMessage({
    type: DomainMessagesTypes.configSet,
  });
  broadcast.close();
  if (current) {
    const { ...currentStripped } = current;
    const isEqual = shallowEqual(currentStripped, dataSet);
    if (isEqual) {
      self.dispatchEvent(new Event(RepositoryMessagesTypes.configSet));
      return;
    }
  }
  await config.add(dataSet);
  self.dispatchEvent(new Event(RepositoryMessagesTypes.configSet));
};

const logout = async (): Promise<void> => {
  self.dispatchEvent(new Event(RepositoryMessagesTypes.logout));
  const broadcast = new BroadcastChannel(ChannelNames.logoutChannel);
  broadcast.postMessage({ type: DomainMessagesTypes.logout });
  broadcast.close();
  await flush();
  self.dispatchEvent(new Event(RepositoryMessagesTypes.dataFlushed));
};

const serviceWorkerCheck = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({ type: DomainMessagesTypes.serviceWorkerCheck });
  broadcast.close();
};

export default { set, logout, serviceWorkerCheck };
export { logout };
