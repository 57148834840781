import { useCreateStore } from 'hooks/createStore';
import {
  WithIssueOverlayControls,
  useCreateIssuesVisibleStore,
} from './WithIssueOverlayControls';
import { ComponentType, ReactElement } from 'react';
import { WithSelectedStore } from 'components/common/withSelectedStore';

export const withStoreDependencies =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => {
    const issuesVisibleStore = useCreateIssuesVisibleStore();
    const filtersVisibleStore = useCreateStore(false);
    const selectedIssueStore = useCreateStore<string | undefined>('');
    return (
      <WithIssueOverlayControls
        issuesVisibleStore={issuesVisibleStore}
        filtersVisibleStore={filtersVisibleStore}
      >
        <WithSelectedStore selectedStore={selectedIssueStore}>
          <Component {...props} />
        </WithSelectedStore>
      </WithIssueOverlayControls>
    );
  };
