import { ColumnShapeFactory } from 'components/table/columnShapes';
import { ColumnShape } from 'react-base-table';
import { IntlShape } from 'react-intl';
import { ColumnWidths } from 'setup/types/core';

export class CompanyColumnsFactory extends ColumnShapeFactory {
  constructor(
    formatMessage: IntlShape['formatMessage'],
    columnWidths: ColumnWidths,
    private timezone: string
  ) {
    super(formatMessage, columnWidths);
  }
  create(): ColumnShape[] {
    return [
      this.orderNumber(),
      this.companyShortLabel(),
      this.companyLongLabel(),
      this.finalCostSettled(),
      this.finalCostOutstanding(),
      this.estimatedCost(),
      this.expectedFine(),
      this.imposedFine(),
      this.projectInvolvementType(),
      this.sites(),
      this.taxNumber(),
      this.correspondenceAddressStreetAndNumber(),
      this.correspondenceAddressPostalCode(),
      this.correspondenceAddressCountry(),
      this.registeredAddressStreetAndNumber(),
      this.registeredAddressPostalCode(),
      this.registeredAddressCountry(),
      this.createdBy(),
      this.createdAt(this.timezone),
      this.modifiedAt(this.timezone),
    ];
  }
}
