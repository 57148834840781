import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import { Toaster } from 'components/core';
import { Link } from 'react-router-dom';
import { ReactComponent as FullLogo } from '../../assets/fullLogo.svg';
import { getAuth0Config } from '../../services/auth0/auth_config';
import { useAuth0 } from '../../services/auth0/react-auth0.spa';
import { useLoginPageStyles } from './styles';
import { frontendVersionWithBuildNumber } from 'shared/version';

const Login = (): JSX.Element => {
  const { handleRedirect } = useAuth0();
  const classes = useLoginPageStyles();

  const handleLoginRedirect = (): Promise<void> => {
    const redirect_uri = getAuth0Config().redirectUnauthorizedUrl;

    return handleRedirect(redirect_uri);
  };

  return (
    <Box className={classes.container}>
      <Paper
        elevation={3}
        sx={{
          width: '30%',
          maxWidth: '400px',
          minWidth: '200px',
          minHeight: '360px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <FullLogo
          style={{ marginTop: '48px', marginBottom: '32px' }}
          width='50%'
          fill='black'
        />

        <Divider sx={{ width: '100%' }} />
        <Button
          variant='contained'
          id='loginButton'
          onClick={handleLoginRedirect}
          sx={{ my: 4 }}
        >
          <Typography variant='button'>LOG IN</Typography>
        </Button>
        <Divider sx={{ width: '100%' }}>OR</Divider>
        <Button
          variant='outlined'
          href='https://calendly.com/hustro/30min'
          target='_blank'
          sx={{ mt: 4 }}
        >
          <Typography variant='button'>BOOK A DEMO</Typography>
        </Button>
      </Paper>
      <Box className={classes.footerContainer}>
        <Box className={classes.leftFooterSection}>
          <Box className={classes.leftFooterItem}>
            Version:
            {frontendVersionWithBuildNumber}
          </Box>
          <Box className={classes.leftFooterItem}>
            <Link
              to={{
                pathname: 'https://www.hustro.com/?page_id=740',
              }}
              target='_blank'
              style={{ cursor: 'pointer' }}
            >
              Privacy policy
            </Link>
          </Box>
          <Box className={classes.leftFooterItem}>
            <Link
              to={{
                pathname: 'https://www.hustro.com/',
              }}
              target='_blank'
              style={{ cursor: 'pointer' }}
            >
              About Hustro
            </Link>
          </Box>
        </Box>
        <Box className={classes.rightFooterItem}>
          @ 2021 Hustro. All rights reserved.
        </Box>
      </Box>
      <Toaster />
    </Box>
  );
};

export default Login;
