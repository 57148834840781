import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import {
  Message,
  isCreatedMessage,
  isUpdatedMessage,
  isUploadedMessage,
} from './messages/message';
import { debugLog } from 'shared/logger/debugLog';

type LabelledItem = {
  _id: string;
  label: string;
};
export type LabelledUser = LabelledItem & { email: string };
export type CreatableModel = {
  createdAt: string;
  createdBy?: LabelledUser;
};

export type ModifiableModel = {
  modifiedAt: string;
  modifiedBy?: LabelledUser;
};

export type DeletableModel = {
  deleted: boolean;
};

export type CreateModelBase = CreatableModel &
  ModifiableModel &
  DeletableModel;

export type ModelBase = Identificable &
  CreatableModel &
  ModifiableModel &
  DeletableModel;

function getMessageListener(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean,
  channelName: ChannelNames,
  eventCompare: (event: Message<any>) => boolean
): BroadcastChannel {
  const broadcast = new BroadcastChannel(channelName);

  broadcast.onmessage = (event: Message): void => {
    if (!eventCompare(event) || !messageFilter(event)) {
      return;
    }

    if (event.error) {
      return errorCallback(event.error);
    }

    if (event.data) {
      return successCallback(event.data);
    }
  };

  return broadcast;
}

export function getCreatedMessageListener(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean,
  channelName: ChannelNames
): BroadcastChannel {
  return getMessageListener(
    successCallback,
    errorCallback,
    messageFilter,
    channelName,
    isCreatedMessage
  );
}

export function getUpdatedMessageListener(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean,
  channelName: ChannelNames
): BroadcastChannel {
  return getMessageListener(
    successCallback,
    errorCallback,
    messageFilter,
    channelName,
    isUpdatedMessage
  );
}

export function getUploadedMessageListener(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean,
  channelName: ChannelNames
): BroadcastChannel {
  return getMessageListener(
    successCallback,
    errorCallback,
    messageFilter,
    channelName,
    isUploadedMessage
  );
}
