import { useVisibleFieldsChannelListener } from 'components/broadcastChannelListeners/withVisibleFieldsChannelListener';
import { createEntityPromise } from 'hooks/createEntityPromise';
import { Store } from 'hooks/createStore';
import { useMountedRef } from 'hooks/useMountRef';
import { MutableRefObject, useCallback, useMemo, useState } from 'react';
import { IntlShape } from 'react-intl';
import { Field } from 'shared/domain/visibleField/availableFields';
import { startVisibleFieldsEdit } from 'shared/domain/visibleField/startVisibleFieldsEdit';
import {
  VisibleFieldModel,
  VisibleFieldsEditModel,
} from 'shared/domain/visibleField/visibleFieldModel';
import { Process } from 'shared/types/process';
import { HashMap } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { FieldOnView } from '.';

export function useFieldVisibilitySubmit(
  fieldVisibilityStore: Store<any>,
  formRef: MutableRefObject<any>,
  SPLIT_CHAR: string
): {
  onSubmit: () => Promise<unknown>;
  isPosting: boolean;
} {
  const mountedRef = useMountedRef();
  const { subscribe } = useVisibleFieldsChannelListener();
  const [isPosting, setIsPosting] = useState(false);

  const onSubmit = useCallback(() => {
    setIsPosting(true);
    const addsAndDeletes = Object.keys(formRef.current).reduce(
      (result, key: string) => {
        const [fieldName, processId] = key.split(SPLIT_CHAR);
        if (formRef.current[key]) {
          result.adds.push({ fieldName, processId });
        } else {
          const visibleFieldsOnProcess =
            fieldVisibilityStore.get()[processId] || [];
          const foundField = visibleFieldsOnProcess.find(
            (visibleField) => visibleField.fieldName === fieldName
          );
          if (foundField) {
            result.deletes.push(foundField._id);
          }
        }

        return result;
      },
      { adds: [], deletes: [] } as VisibleFieldsEditModel
    );

    const uniqueId = createUniqueId();

    return createEntityPromise({
      subscribe,
      isMatchingMessage: (message) => {
        return message.uniqueId === uniqueId;
      },
      postMessage: () => {
        startVisibleFieldsEdit(addsAndDeletes, uniqueId);
      },
      entityName: 'visibleFields',
    }).then(() => {
      if (!mountedRef.current) return;
      setIsPosting(false);
    });
  }, [SPLIT_CHAR, fieldVisibilityStore, formRef, mountedRef, subscribe]);

  return useMemo(() => {
    return {
      onSubmit,
      isPosting,
    };
  }, [isPosting, onSubmit]);
}

export function addFieldWithDependsOn({
  result,
  field,
  fieldVisibility,
  selectedProcessId,
  intl,
  sourceFieldVisible,
}: {
  result: HashMap<FieldOnView>;
  field: Field;
  fieldVisibility: HashMap<VisibleFieldModel[]>;
  selectedProcessId: Process;
  intl: IntlShape;
  sourceFieldVisible: boolean;
}): void {
  result[field.fieldName] = {
    ...field,
    disabled: !sourceFieldVisible || !field.canBeDisabled,
    visible: !sourceFieldVisible
      ? false
      : isFieldVisible({
          field,
          fieldVisibility,
          selectedProcessId,
        }),
    label: intl.formatMessage({ id: field.labelId }),
  };
}

export function addField({
  result,
  field,
  fieldVisibility,
  selectedProcessId,
  intl,
}: {
  result: HashMap<FieldOnView>;
  field: Field;
  fieldVisibility: HashMap<VisibleFieldModel[]>;
  selectedProcessId: Process;
  intl: IntlShape;
}): void {
  result[field.fieldName] = {
    ...field,
    disabled: !field.canBeDisabled,
    visible: isFieldVisible({
      field,
      fieldVisibility,
      selectedProcessId,
    }),
    label: intl.formatMessage({ id: field.labelId }),
  };
}

export function isFieldVisible({
  field,
  fieldVisibility,
  selectedProcessId,
}: {
  field: Field;
  fieldVisibility: HashMap<VisibleFieldModel[]>;
  selectedProcessId: Process;
}): boolean {
  return Boolean(
    field.canBeDisabled
      ? (fieldVisibility[selectedProcessId] || []).find(
          (visibleField) =>
            visibleField.fieldName === field.fieldName &&
            !visibleField.deleted
        )
      : field.processes.includes(selectedProcessId)
  );
}
