import {
  Localization,
  arSA as coreArSa,
  bgBG as coreBgBG,
  csCZ as coreCsCZ,
  deDE as coreDeDE,
  enUS as coreEnUs,
  esES as coreEs,
  frFR as coreFr,
  heIL as coreHeIL,
  hrHR as coreHrHR,
  huHU as coreHuHU,
  plPL as corePl,
  ptPT as corePtPT,
  roRO as coreRo,
  ruRU as coreRuUA,
  skSK as coreSkSK,
  trTR as coreTrTR,
  ukUA as coreUkUA,
} from '@mui/material/locale';
import {
  csCZ as datePickersCsCZ,
  deDE as datePickersDeDE,
  enUS as datePickersEnUs,
  esES as datePickersEs,
  frFR as datePickersFr,
  heIL as datePickersHeIL,
  huHU as datePickersHuHU,
  plPL as datePickersPl,
  // brasilian version available only:
  ptBR as datePickersPtBr,
  roRO as datePickersRo,
  ruRU as datePickersRuUA,
  skSK as datePickersSkSK,
  trTR as datePickersTrTR,
  ukUA as datePickersUkUA,
} from '@mui/x-date-pickers/locales';
import { logError } from 'setup/logger/logError';
import { anyToSupportedISO6391LanguageCode } from 'shared/utils/locale/anyToSupportedISO6391LanguageCode';
import { ISO6391LanguageCode } from '../shared/types/locale';

export function resolveMuiCoreTranslation(locale: string): Localization {
  const code = anyToSupportedISO6391LanguageCode(locale);
  switch (code) {
    case ISO6391LanguageCode.en:
      return coreEnUs;
    case ISO6391LanguageCode.ar:
      // saudi arabia version available only:
      return coreArSa;
    case ISO6391LanguageCode.bg:
      return coreBgBG;
    case ISO6391LanguageCode.hr:
      return coreHrHR;
    case ISO6391LanguageCode.cs:
      return coreCsCZ;
    case ISO6391LanguageCode.fr:
      return coreFr;
    case ISO6391LanguageCode.de:
      return coreDeDE;
    case ISO6391LanguageCode.he:
      return coreHeIL;
    case ISO6391LanguageCode.hu:
      return coreHuHU;
    case ISO6391LanguageCode.pl:
      return corePl;
    case ISO6391LanguageCode.pt:
      return corePtPT;
    case ISO6391LanguageCode.ro:
      return coreRo;
    case ISO6391LanguageCode.ru:
      return coreRuUA;
    case ISO6391LanguageCode.sk:
      return coreSkSK;
    case ISO6391LanguageCode.es:
      return coreEs;
    case ISO6391LanguageCode.tr:
      return coreTrTR;
    case ISO6391LanguageCode.uk:
      return coreUkUA;
    default: {
      logError(`Unknown locale code: ${locale}`);
      return coreEnUs;
    }
  }
}

export function resolveMuiDatePickerTranslation(locale: string): object {
  const code = anyToSupportedISO6391LanguageCode(locale);

  switch (code) {
    case ISO6391LanguageCode.en:
      return datePickersEnUs;
    case ISO6391LanguageCode.ar:
      // ar not available
      return datePickersEnUs;
    case ISO6391LanguageCode.bg:
      // bg not available
      return datePickersEnUs;
    case ISO6391LanguageCode.hr:
      // hr not available
      return datePickersEnUs;
    case ISO6391LanguageCode.cs:
      return datePickersCsCZ;
    case ISO6391LanguageCode.fr:
      return datePickersFr;
    case ISO6391LanguageCode.de:
      return datePickersDeDE;
    case ISO6391LanguageCode.he:
      return datePickersHeIL;
    case ISO6391LanguageCode.hu:
      return datePickersHuHU;
    case ISO6391LanguageCode.pl:
      return datePickersPl;
    case ISO6391LanguageCode.pt:
      return datePickersPtBr;
    case ISO6391LanguageCode.ro:
      return datePickersRo;
    case ISO6391LanguageCode.ru:
      return datePickersRuUA;
    case ISO6391LanguageCode.sk:
      return datePickersSkSK;
    case ISO6391LanguageCode.es:
      return datePickersEs;
    case ISO6391LanguageCode.tr:
      return datePickersTrTR;
    case ISO6391LanguageCode.uk:
      return datePickersUkUA;
    default: {
      logError(`Unknown locale code: ${locale}`);
      return datePickersEnUs;
    }
  }
}
