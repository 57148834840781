import { Store, useCreateStore } from 'hooks/createStore';
import {
  ComponentType,
  FC,
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

type DocumentationBrowseDialogContextType = {
  isOpenStore: Store<boolean>;
  closeDialog: () => void;
  openDialog: () => void;
};
const DocumentationBrowseDialogContext = createContext<
  DocumentationBrowseDialogContextType | undefined
>(undefined);

const WithDocumentationBrowseDialog: FC<{
  children?: ReactNode;
}> = ({ children }) => {
  const isOpenStore = useCreateStore(false);

  const closeDialog = useCallback(() => {
    isOpenStore.set(false);
  }, [isOpenStore]);
  const openDialog = useCallback(() => {
    isOpenStore.set(true);
  }, [isOpenStore]);

  const ctx: DocumentationBrowseDialogContextType = useMemo(() => {
    return {
      isOpenStore,
      closeDialog,
      openDialog,
    };
  }, [isOpenStore, closeDialog, openDialog]);

  return (
    <DocumentationBrowseDialogContext.Provider value={ctx}>
      {children}
    </DocumentationBrowseDialogContext.Provider>
  );
};

export function useDocumentationBrowseDialog(): DocumentationBrowseDialogContextType {
  const context = useContext(DocumentationBrowseDialogContext);
  if (context === undefined) {
    throw new Error(
      'useDocumentationBrowseDialog must be used within a DocumentationBrowseDialogContext'
    );
  }
  return context;
}

export function withDocumentationBrowseDialog<T>(
  Component: ComponentType<T>
) {
  return (props: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithDocumentationBrowseDialog>
      <Component {...props} />
    </WithDocumentationBrowseDialog>
  );
}
