import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startLevelRestore({
  levelId,
  projectId,
  uniqueId,
}: {
  levelId: string;
  projectId: string;
  uniqueId: string;
}): void {
  debugLog('startLevelRestore', levelId, projectId, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      levelId: string;
      projectId: String;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.LEVELS,
    method: ServiceMethods.RESTORE,
    data: {
      levelId,
      uniqueId,
      projectId,
    },
  });
  broadcast.close();
}
