import {
  isDataFlushedMessage,
  isProjectDataFlushedMessage,
} from 'components/core/WithDataFlushedSubscriber/model';
import { Message } from 'shared/domain/messages/message';
import { MutableRefObject, useEffect } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

export function useDataFlushedListener(
  mountedRef: MutableRefObject<boolean>,
  resync: CallableFunction
): void {
  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

    broadcast.onmessage = (event: Message): void => {
      if (!mountedRef.current) {
        return;
      }

      if (
        isDataFlushedMessage(event) ||
        isProjectDataFlushedMessage(event)
      ) {
        resync();
      }
    };

    return (): void => {
      broadcast.close();
    };
  }, [resync, mountedRef]);
}
