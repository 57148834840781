import { DateRangeObject } from 'shared/types/analytics';
import { RootCauseFilters } from 'shared/types/analytics';
import { getIssueForm } from 'serviceWorker/services/issueForm/getIssueForm';
import { ApexSeriesAndLabels } from 'shared/types/analytics';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  HashMap,
  LabelledEntity,
  UntranslatedLabelledEntity,
} from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByImpact,
  filterByProcess,
  filterBySite,
  filterBySubcontractor,
  filterByWorktype,
  increaseEntitySeries,
  increaseNoneSeries,
  toSortedApexSeries,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: RootCauseFilters,
  timezone: string
): Promise<ApexSeriesAndLabels> {
  const filteredIssues = issues.filter((issue) => {
    const passedDateRange = filterByDateRange(
      filters.dataScope.filters[0].value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedOriginators = filterBySubcontractor(
      filters.dataScope.filters[1].value as string[],
      issue
    );
    if (!passedOriginators) {
      return false;
    }

    const passedProcess = filterByProcess(
      filters.dataScope.filters[2].value as string[],
      issue
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterBySite(
      filters.dataScope.filters[3].value as string[],
      issue
    );
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterByWorktype(
      filters.dataScope.filters[4].value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedImpact = filterByImpact(
      filters.dataScope.filters[5].value as string[],
      issue
    );
    if (!passedImpact) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      filters.dataScope.filters[6].value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    return true;
  });

  const issueForms = await getIssueForm();

  const rootCausesField = issueForms.find(
    (field: { name: string }) => field.name === 'rootCauses'
  )!;

  const rootCauses: HashMap<LabelledEntity | UntranslatedLabelledEntity> =
    rootCausesField.items!.reduce(
      (
        rootCausesMap: HashMap<LabelledEntity>,
        rootCause: LabelledEntity
      ) => {
        rootCausesMap[rootCause._id] = {
          _id: rootCause._id,
          label: rootCause.label,
        };
        return rootCausesMap;
      },
      {}
    );

  const reducedToRootCauseSummary = filteredIssues.reduce<HashMap<number>>(
    (result, issue) => {
      const issueRootCauses = issue.extendedData.rootCauses || [];

      if (issueRootCauses.length === 0) {
        increaseNoneSeries(result, rootCauses);
      } else {
        issueRootCauses.forEach((rootCause) => {
          increaseEntitySeries(result, rootCauses, {
            _id: rootCause,
            label:
              (rootCauses[rootCause] as LabelledEntity | undefined)
                ?.label || rootCause,
          });
        });
      }

      return result;
    },
    {}
  );

  return Promise.resolve(
    toSortedApexSeries(reducedToRootCauseSummary, rootCauses)
  );
}
