import {
  CreatableEntity,
  ModifiableEntity,
} from 'shared/types/commonEntities';
import {
  Labelled,
  Identificable,
  Deletable,
} from 'shared/types/commonView';

export enum AccessLevel {
  NONE = 'NONE',
  READ = 'READ',
  EDIT = 'EDIT',
}

export type AccessLevelObject = Labelled & {
  value: AccessLevel;
};

export type UserAccess = {
  accessLevel: AccessLevel;
  accessingUser: string;
} & Identificable &
  CreatableEntity &
  ModifiableEntity &
  Deletable;
