import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import {
  FormFieldProps,
  MultiChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import { sortLabelledEntities } from 'helpers/sort';
import { getAvailableAppLocales } from 'intl/mappings';
import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/selectors/users';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import { getAllAvailableTimezones } from 'shared/domain/project/mapping/toView';
import { ProjectCreateOnView } from 'shared/domain/project/types/view';
import { isSuperAdmin } from 'shared/domain/role/isSuperAdmin';
import { LabelledEntity } from 'shared/types/commonView';
import { AVAILABLE_PROCESSES } from 'shared/types/process';
import { DayMomentValidation } from 'shared/types/time';
import { getClientAdminPermission } from './getClientAdminPermission';
import {
  ProjectCreateFormPresentational,
  ProjectEditFormPresentational,
} from './presentational';

function getOptionSelected(
  option: LabelledEntity,
  value: LabelledEntity
): boolean {
  return option._id === value._id;
}

function getOptionLabel(option: { label: string }): string {
  return option.label;
}

export function ProjectForm({
  isDialog,
  isEdit,
}: {
  isDialog: boolean;
  isEdit: boolean;
}): ReactElement {
  const intl = useIntl();

  const { organizationsMap } = useOrganizations();
  const {
    data: { basePermissions },
  } = useSelector(currentUserSelector);

  const organizationsOnWhichProjectCanBeCreated = useMemo(() => {
    const result: LabelledEntity[] = [];
    const clientIdsMapWithCreationPermission = getClientAdminPermission(
      basePermissions,
      organizationsMap
    );

    organizationsMap.forEach((organization) => {
      const hasCreationPermission =
        isSuperAdmin(basePermissions.role) ||
        clientIdsMapWithCreationPermission!.has(organization._id);

      const labelledEntity = {
        _id: organization._id,
        label: organization.label,
        disabled: !hasCreationPermission,
      };
      if (hasCreationPermission) {
        result.push(labelledEntity);
      }
      return;
    });

    return result.sort(sortLabelledEntities);
  }, [organizationsMap, basePermissions]);
  const { values } = useInputForm();
  const timezone = values['timezone']?._id;

  const organizationFieldProps: FormFieldProps<ProjectCreateOnView> =
    useMemo(
      () => ({
        formKey: 'organization',
        labelId: 'select_organization_label',
        required: true,
        fieldName: 'select-organization',
        available: organizationsOnWhichProjectCanBeCreated,
        getOptionSelected,
        getOptionDisabled: (
          option: LabelledEntity & { disabled?: boolean }
        ) => Boolean(option.disabled),
        minRows: 3,
        autoSelect: 'asSingleSelect',
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'project_organization_field',
        entityName: EditableStandardEntityName.organization,
      }),
      [organizationsOnWhichProjectCanBeCreated, isDialog]
    );

  const nameFieldProps: FormFieldProps<ProjectCreateOnView> = useMemo(
    () => ({
      formKey: 'name',
      required: true,
      labelId: 'project_name',
      fieldName: 'text-field-project-name',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'project_name_field',
      localStorageKey: 'project_name',
    }),
    [isDialog]
  );

  const processesFieldProps: MultiChoiceFormFieldProps<ProjectCreateOnView> =
    useMemo(
      () => ({
        formKey: 'processes',
        labelId: 'general_process',
        required: true,
        fieldName: 'multi-select-processes',
        available: AVAILABLE_PROCESSES.map((process) => {
          return {
            _id: process._id,
            label: intl.formatMessage({ id: process.label }),
          };
        }),
        getOptionLabel,
        getOptionSelected,
        minRows: 3,
        disableClearable: true,
        entityName: '',
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'project_processes_field',
      }),
      [intl, isDialog]
    );

  const currencyFieldProps: FormFieldProps<ProjectCreateOnView> = useMemo(
    () => ({
      formKey: 'currency',
      labelId: 'project_currency',
      required: true,
      fieldName: 'select-currency',
      available: [
        { _id: 'EUR', label: 'EUR' },
        { _id: 'PLN', label: 'PLN' },
        { _id: 'TRY', label: 'TRY' },
      ],
      getOptionLabel,
      getOptionSelected: (
        option: LabelledEntity,
        value: LabelledEntity
      ): boolean => option._id === value._id,
      minRows: 3,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'project_currency_field',
    }),
    [isDialog]
  );

  const languageFieldProps: FormFieldProps<ProjectCreateOnView> = useMemo(
    () => ({
      formKey: 'locale',
      labelId: 'project_language',
      required: true,
      fieldName: 'select-locale-code',
      available: getAvailableAppLocales(),
      getOptionLabel,
      getOptionSelected,
      minRows: 3,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'project_language_field',
    }),
    [isDialog]
  );

  const timezoneFieldProps: FormFieldProps<ProjectCreateOnView> = useMemo(
    () => ({
      formKey: 'timezone',
      labelId: 'timezone',
      required: true,
      disabled: isEdit,
      fieldName: 'select-timezone',
      available: getAllAvailableTimezones(),
      getOptionLabel,
      getOptionSelected,
      minRows: 3,
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'project_timezone_field',
    }),
    [isDialog, isEdit]
  );

  const budgetFieldProps: FormFieldProps<ProjectCreateOnView> = useMemo(
    () => ({
      formKey: 'budget',
      required: false,
      labelId: 'project_budget',
      fieldName: 'text-field-project-budget',
      type: 'number',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'project_budget_field',
    }),
    [isDialog]
  );

  const projectStartDateFieldProps: FormFieldProps<ProjectCreateOnView> =
    useMemo(
      () => ({
        formKey: 'projectStartDate',
        required: false,
        labelId: 'project_start_date',
        fieldName: 'text-field-project-start-date',
        timezone,
        daymoment: DayMomentValidation.start,
        clearable: true,
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'project_start_date_field',
      }),
      [timezone, isDialog]
    );

  const projectEndDateFieldProps: FormFieldProps<ProjectCreateOnView> =
    useMemo(
      () => ({
        formKey: 'projectEndDate',
        required: false,
        timezone,
        labelId: 'project_end_date',
        fieldName: 'text-field-project-end-date',
        daymoment: DayMomentValidation.end,
        clearable: true,
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'project_end_date_field',
      }),
      [timezone, isDialog]
    );
  if (isEdit) {
    return (
      <ProjectEditFormPresentational
        nameFieldProps={nameFieldProps}
        processesFieldProps={processesFieldProps}
        currencyFieldProps={currencyFieldProps}
        languageFieldProps={languageFieldProps}
        timezoneFieldProps={timezoneFieldProps}
        budgetFieldProps={budgetFieldProps}
        projectStartDateFieldProps={projectStartDateFieldProps}
        projectEndDateFieldProps={projectEndDateFieldProps}
      />
    );
  }
  return (
    <ProjectCreateFormPresentational
      nameFieldProps={nameFieldProps}
      organizationFieldProps={organizationFieldProps}
      processesFieldProps={processesFieldProps}
      currencyFieldProps={currencyFieldProps}
      languageFieldProps={languageFieldProps}
      timezoneFieldProps={timezoneFieldProps}
      budgetFieldProps={budgetFieldProps}
      projectStartDateFieldProps={projectStartDateFieldProps}
      projectEndDateFieldProps={projectEndDateFieldProps}
    />
  );
}
