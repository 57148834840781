import {
  HttpRequestEntity,
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { IHttpRequestRunner } from 'shared/domain/httpRequest/httpRequestRunner';
import {
  AbortUploadCustomEvent,
  FileServicesUnion,
} from 'shared/domain/messages/httpQueue/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { hardRemoveDocument } from 'serviceWorker/repository/document/hardRemoveDocument';
import { hardRemoveDocumentation } from 'serviceWorker/repository/documentation/hardRemoveDocumentation';
import { isAbortError } from 'serviceWorker/repository/httpRequest/abortRequest';
import { Services } from 'shared/domain/messages/message';
import * as httpRequests from 'serviceWorker/db/httpRequests';
import { HttpError } from 'serviceWorker/helpers/httpError';
import {
  CONFLICT,
  FORBIDDEN,
  UNPROCESSABLE_ENTITY,
} from 'shared/contants';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import { getFetchConfig } from '../config';
import { emitErrorOnProperChannel } from './errorHandlers';
import {
  handleDocumentCreationError,
  handleDocumentationCreationError,
} from './errorHandlers/documentation';
import { httpRequestRunner } from './httpRequestRunner';
import { emitResponseOnProperChannel } from './responseHandlers';

let requestInProgress = false;
let executedUniqueIds = new Map<string, any>();
async function executeNextRequest(
  httpRequestRunner: IHttpRequestRunner
): Promise<void> {
  requestInProgress = true;
  debugLog('executeNextRequest');
  const request: HttpRequestEntity | undefined =
    await httpRequests.getFirst();
  debugLog('request', request);
  if (!request) {
    debugLog('request undefined', request);
    return executeNextFailedRequest(httpRequestRunner);
  }
  const setup = await getFetchConfig();
  if (!setup) {
    debugLog('setup fail', request);
    requestInProgress = false;
    return;
  }
  const uniqueId = request?.data?.uniqueId;
  try {
    if (uniqueId) {
      const existingResponse = executedUniqueIds.get(uniqueId);
      if (existingResponse) {
        // this happens when multiple tabs are open in the browser
        debugLog(
          'uniqueId already executed, skipping',
          uniqueId,
          request,
          existingResponse
        );

        executedUniqueIds.set(uniqueId, existingResponse);
        emitResponseOnProperChannel(request, existingResponse);
        await httpRequests.remove(request._id);

        return;
      }
    }

    const response = await httpRequestRunner.execute(request);
    debugLog('response', response);
    if (uniqueId) {
      executedUniqueIds.set(uniqueId, response);
      // in some places (eg. directories) id is used as uniqueId.
      // Until it's done with uniqueId, we have to clear this map because user can do another operation on the same entity
      setTimeout(() => {
        executedUniqueIds.delete(uniqueId);
      }, 5000);
    }

    emitResponseOnProperChannel(request, response);

    await httpRequests.remove(request._id);
  } catch (e) {
    emitErrorOnProperChannel(request, e);
    await httpRequests.updateOne(request._id, {
      status: HttpRequestStatus.FAILED,
    });
  }
  return executeNextRequest(httpRequestRunner);
}

async function executeNextFailedRequest(
  httpRequestRunner: IHttpRequestRunner,
  skip = 0
): Promise<void> {
  requestInProgress = true;
  debugLog('executeNextFailedRequest');
  const request: HttpRequestEntity | undefined =
    await httpRequests.getFirstFailed(skip);
  debugLog('failedRequest', request);
  if (!request) {
    const nextRequest = await httpRequests.getFirst();
    if (nextRequest) {
      return executeNextRequest(httpRequestRunner);
    }
    debugLog('failedRequest undefined', request);
    requestInProgress = false;
    return;
  }
  const setup = await getFetchConfig();
  if (!setup) {
    debugLog('setup fail', request);
    requestInProgress = false;
    return;
  }

  try {
    const response = await httpRequestRunner.execute(request);
    debugLog('response', response);

    // fire & forget
    emitResponseOnProperChannel(request, response);
    await httpRequests.remove(request._id);
  } catch (e) {
    emitErrorOnProperChannel(request, e);
    skip += await resolveSkipOrRemove(request, e);
  }
  return executeNextFailedRequest(httpRequestRunner, skip);
}

export const init = (): void => {
  httpRequests.count().then((count) => {
    if (count > 0 && !requestInProgress) {
      debugLog('init queue', count, requestInProgress);
      return executeNextRequest(httpRequestRunner);
    }
  });

  self.addEventListener(RepositoryMessagesTypes.newRequest, () => {
    debugLog('MessagesTypes.newRequest', requestInProgress);
    if (requestInProgress) {
      return;
    }
    debugLog('Will start requesting');
    executeNextRequest(httpRequestRunner);
  });

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.abortUpload,
    async function onAbortUpload(
      event: AbortUploadCustomEvent
    ): Promise<void> {
      try {
        const requests = await httpRequests.getByQuery({
          method: 'POST',
          entityType:
            event.detail.type === Services.DOCUMENTS
              ? HttpRequestModelType.document
              : HttpRequestModelType.documentation,
        });
        if (!requests) {
          return;
        }
        const found = requests.find(
          (request) => request.data.localId === event.detail.localId
        );
        if (!found) {
          return;
        }
        httpRequests.remove(found._id);
        await removeAbortedEntity(event);
      } catch (e) {
        swLog(
          `Error on abort upload. ${(e as any)?.message}`,
          LogLevel.INFO,
          e,
          null
        );
      }
    }
  );
};

function removeAbortedEntity(e: AbortUploadCustomEvent): Promise<unknown> {
  const remove = getRemoveHandler(e.detail.type);

  return remove(e.detail.localId);
}

function getRemoveHandler(
  type: FileServicesUnion
): (id: number) => Promise<unknown> {
  switch (type) {
    case Services.DOCUMENTATIONS: {
      return hardRemoveDocumentation;
    }
    case Services.DOCUMENTS: {
      return hardRemoveDocument;
    }
  }
}

async function resolveSkipOrRemove(
  request: HttpRequestEntity,
  e: unknown
): Promise<number> {
  if (isAbortError(e)) {
    return 0;
  }

  if (
    request.entityType === HttpRequestModelType.issue &&
    e instanceof HttpError &&
    e.status === UNPROCESSABLE_ENTITY
  ) {
    await httpRequests.remove(request._id);
    return 0;
  }

  if (request.entityType === HttpRequestModelType.report) {
    await httpRequests.remove(request._id);
    return 0;
  }

  if (
    e instanceof HttpError &&
    (e.status === FORBIDDEN || e.status === UNPROCESSABLE_ENTITY)
  ) {
    await httpRequests.remove(request._id);
    return 0;
  }

  if (
    request.entityType === HttpRequestModelType.documentation &&
    request.method === 'POST'
  ) {
    await handleDocumentationCreationError(httpRequests, request, e);
    return 0;
  }

  if (
    request.entityType === HttpRequestModelType.document &&
    request.method === 'POST'
  ) {
    await handleDocumentCreationError(httpRequests, request, e);
    return 0;
  }

  if (
    (request.entityType === HttpRequestModelType.level ||
      request.entityType === HttpRequestModelType.site) &&
    request.method === 'DELETE' &&
    e instanceof HttpError &&
    e.status === CONFLICT
  ) {
    await httpRequests.remove(request._id);
    return 0;
  }

  await httpRequests.updateOne(request._id, {
    status: HttpRequestStatus.FAILED,
  });
  return 1;
}
