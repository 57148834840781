import { appLocaleToLocaleCodeOutDto } from 'shared/utils/locale/appLocaleToLocaleCodeOutDto';
import { ProjectCreateOutDto, ProjectEditOutDto } from '../types/dto';
import { ProjectCreateModel, ProjectEditModel } from '../types/model';

export function projectCreateModelToCreateOutDto(
  model: ProjectCreateModel
): ProjectCreateOutDto {
  return {
    clientId: model.organizationId,
    name: model.name,
    timezone: model.timezone,
    budget: model.budget,
    currency: model.currency,
    projectStartDate: model.projectStartDate,
    projectEndDate: model.projectEndDate,
    localeCode: appLocaleToLocaleCodeOutDto(model.localeCode),
    processes: model.processes,
  };
}

export function projectEditModelToEditOutDto(
  model: ProjectEditModel
): ProjectEditOutDto {
  return {
    name: model.name,
    timezone: model.timezone,
    budget: model.budget,
    currency: model.currency,
    projectStartDate: model.projectStartDate,
    projectEndDate: model.projectEndDate,
    localeCode: appLocaleToLocaleCodeOutDto(model.localeCode),
  };
}
