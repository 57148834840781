import { getEnvironment } from 'shared/utils/environment';
import { LogData, LogLevel, SharedLogsParams } from 'shared/types/logger';
import { ENVIRONMENTS } from '../enums';
import {
  clearSensitiveDataFromDetailsObject,
  getDeviceInfo,
} from './helpers';

const environment = getEnvironment();

function extractLogDataFromParams(params: SharedLogsParams): LogData {
  const clearedDetails = params.full_error_object
    ? clearSensitiveDataFromDetailsObject(params.full_error_object)
    : {};
  const detailKey =
    params.level === LogLevel.ERROR ? 'full_error_object' : 'details';
  const additionalInfo = params.additionalInfo || {};
  return {
    logId: params.logId,
    msg: params.msg,
    level: params.level,
    user_id: params.user_id,
    user_email: params.user_email,
    ...getDeviceInfo(),
    ...additionalInfo,
    [detailKey]: clearedDetails,
  };
}

export const sharedLog = (params: SharedLogsParams): void => {
  const logData = extractLogDataFromParams(params);
  if (process.env.REACT_APP_NODE_ENV !== 'development') {
    params.loggers.forEach((logger) => logger.log(logData));
  }
  if (environment !== ENVIRONMENTS.PRODUCTION) {
    const log =
      // eslint-disable-next-line no-console
      params.level === LogLevel.ERROR ? console.error : console.log;
    log(
      'Log level:',
      params.level,
      'Message:',
      params.msg,
      'Object:',
      params.full_error_object
    );
    log(logData);
  }
};
