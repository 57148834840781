import { ChangeEvent, ReactElement, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { FormControl, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useStylesSelect } from './styles';
import { isOptionEqualToValue } from 'components/common/simpleInputFields/common';
import { ChartFilter } from 'shared/types/analytics';

export function SelectControl(props: {
  filter: ChartFilter<unknown>;
  localSetter: (value: any) => void;
  localValue: string;
  disabled: boolean;
}): ReactElement {
  const intl = useIntl();
  const classes = useStylesSelect();
  const { labelId, available, labels } = props.filter;
  const localSetter = props.localSetter;
  const value = props.localValue;
  const defaultValue = value
    ? { _id: value, label: labels?.[value] }
    : available?.[0];

  const handleChange = useCallback(
    (_: ChangeEvent<any>, value: LabelledEntity | null): void => {
      if (value && value._id) {
        localSetter(value._id);
      }
    },
    [localSetter]
  );

  const defaultProps = {
    options: available ?? [],
    getOptionLabel: (option: LabelledEntity): string => option.label,
    defaultValue: defaultValue,
    getOptionSelected: (
      option: LabelledEntity,
      value: LabelledEntity
    ): boolean => option._id === value._id,
    value: defaultValue,
  };
  return (
    <FormControl variant='standard' className={classes.formControl}>
      <Autocomplete
        {...defaultProps}
        disabled={props.disabled}
        onChange={handleChange}
        isOptionEqualToValue={isOptionEqualToValue}
        id={`select-control-for-${labelId}`}
        renderInput={(params): ReactElement => (
          <TextField
            variant='outlined'
            {...params}
            label={intl.formatMessage({ id: labelId })}
            margin='normal'
            size='small'
          />
        )}
      />
    </FormControl>
  );
}
