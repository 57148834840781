import { EntityOnProjectRoute } from 'components/common/withProjectChangeDialog/model';
import { useUploadProgressStore } from 'components/common/withUploadProgressStore';
import { DialogFactory } from 'components/core/Dialog/common/DialogContext';
import { selectProject } from 'shared/domain/project/selectProject';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { Dispatch } from 'redux';
import { customMessageErrorToaster } from 'redux/actions/toasterActions';

const descriptionStyle = { margin: '8px 24px' };
const pStyle = { marginBottom: 0 };

export function selectionConfirmationDialog(
  dialogFactory: DialogFactory,
  dispatch: Dispatch,
  projectId: string
): Promise<void> {
  return dialogFactory({
    title: <FormattedMessage id={'dialog_change_project_title_select'} />,
    description: (
      <div style={descriptionStyle}>
        <p style={pStyle}>
          <FormattedMessage id={'dialog_change_project_message_data'} />
        </p>
      </div>
    ),
    customControllerLabels: ['general_cancel', 'general_select'],
    fullWidth: true,
    catchOnCancel: true,
    rejectOnOffline: true,
  })
    .then(() => {
      selectProject({ _id: projectId });
    })
    .catch((e) => {
      if (e?.message === 'offline') {
        dispatch(
          customMessageErrorToaster('snackbar_no_internet_connection')
        );
      }
    });
}

export function changeConfirmationDialog(
  dialogFactory: DialogFactory,
  dispatch: Dispatch,
  projectId: string,
  projectName?: string,
  entity?: EntityOnProjectRoute
): Promise<void> {
  return dialogFactory({
    title: <FormattedMessage id={'dialog_change_project_title_change'} />,
    description: (
      <div style={descriptionStyle}>
        <EntityInAnotherProjectMessage
          projectName={projectName}
          entity={entity}
        />
        <p style={pStyle}>
          <FormattedMessage id={'dialog_change_project_message_data'} />
        </p>
        <FilesPendingMessage />
      </div>
    ),
    customControllerLabels: ['general_cancel', 'general_change'],
    fullWidth: true,
    catchOnCancel: true,
    rejectOnOffline: true,
  })
    .then(() => {
      selectProject({ _id: projectId });
    })
    .catch((e) => {
      if (e?.message === 'offline') {
        dispatch(
          customMessageErrorToaster('snackbar_no_internet_connection')
        );
      }
    });
}

function EntityInAnotherProjectMessage({
  projectName,
  entity,
}: {
  projectName?: string;
  entity?: EntityOnProjectRoute;
}): ReactElement | null {
  if (!entity || !projectName) {
    return null;
  }

  return (
    <FormattedMessage
      id={`dialog_change_project_message_${entity}`}
      values={{ projectName }}
    />
  );
}

function FilesPendingMessage(): ReactElement | null {
  const { filesStore } = useUploadProgressStore();
  const files = filesStore.get();
  const count: number = files.reduce<number>(
    (inProgressCount: number, file) =>
      file.status === 'PENDING' ? ++inProgressCount : inProgressCount,
    0
  );

  if (!count) return null;

  return (
    <p>
      <FormattedMessage
        id={`dialog_change_project_message_files`}
        values={{ count }}
      />
    </p>
  );
}
