import { Subscribe } from 'components/broadcastChannelListeners/channelListener/channelListener';
import { Message } from 'shared/domain/messages/message';

type createEntityPromiseProps = {
  subscribe: Subscribe;
  isMatchingMessage: (event: Message) => boolean;
  postMessage: () => void;
  isMatchingErrorMessage?: (event: Message) => boolean;
  entityName?: string;
};
export function createEntityPromise<T>({
  subscribe,
  isMatchingMessage,
  postMessage,
  isMatchingErrorMessage,
  entityName,
}: createEntityPromiseProps): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    let timeout: ReturnType<typeof setTimeout>;
    const unsubscribe = subscribe((event: Message): void => {
      if (isMatchingMessage(event)) {
        clearTimeout(timeout);
        unsubscribe();
        return resolve(event.data as T);
      }

      if (typeof isMatchingErrorMessage === 'function') {
        if (isMatchingErrorMessage(event)) {
          clearTimeout(timeout);
          unsubscribe();
          return reject(
            `CreateEntityPromise: Matched event as error: ${event.error}`
          );
        }
      }
    });

    timeout = setTimeout(() => {
      unsubscribe();
      reject(
        new Error(
          `CreateEntityPromise timeout. Entity name: ${entityName}`
        )
      );
    }, 10000);

    postMessage();
  });
}
