import { Spinner } from 'components/core';
import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import { organizationEditOnViewToOrganizationEditModel } from 'shared/domain/organization/mapping/toModel';
import { organizationModelToOrganizationEditOnView } from 'shared/domain/organization/mapping/toView';
import { startOrganizationEdit } from 'shared/domain/organization/startOrganizationEdit';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { OrganizationEditOnView } from 'shared/domain/organization/types/view';
import { useGetAllOrganizations } from 'hooks/useGetAllOrganizations';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { EditOrganizationContext } from '../types';
import {
  handleOrganizationEditFormValidation,
  validateOrganizationEdit,
} from '../validation';
const formName = 'organization';
const SUBMIT_EVENT_TYPE = 'submit-organization';
const WithEditOrganizationContext = createContext<
  EditOrganizationContext | undefined
>(undefined);

function WithEditOrganization({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const history = useHistory();
  const intl = useIntl();
  const { getAll: getAllOrganizations } = useGetAllOrganizations();
  const fetchOrganizations = getAllOrganizations;
  const [isPosting, setIsPosting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const { organizationsMap, loading: loadingOrganizations } =
    useOrganizations();
  const { organizationId } = useSelector(projectDataSelector);

  const [existingValues, setExistingValues] = useState<
    OrganizationEditOnView | undefined
  >(undefined);

  useEffect(() => {
    const existingOrganizationModel = organizationsMap.get(organizationId);
    let organizationEditOnView;
    if (existingOrganizationModel) {
      organizationEditOnView = organizationModelToOrganizationEditOnView(
        existingOrganizationModel
      );
      setExistingValues(organizationEditOnView);
    }
  }, [organizationsMap, organizationId]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );

  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setIsDialogOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: OrganizationEditOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const organizationEditModel =
        organizationEditOnViewToOrganizationEditModel(values);
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          (message: OrganizationModel) => {
            broadcast.close();
            resolve(message);
            setIsDialogOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            // fetchOrganizations();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.organizationChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithEditOrganization: Timeout on update.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startOrganizationEdit(organizationEditModel, uniqueId);
      })
        .then((organization: unknown): any => {
          if (organization) {
            history.push(`/projectSettings?target=organizationData`);
            return organization;
          }
        })
        .catch(() => {});
    },
    [dispatch, history]
  );

  const ctx: EditOrganizationContext = useMemo(() => {
    return {
      open: isDialogOpen,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateOrganizationEdit,
      handleFormValidation: handleOrganizationEditFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    existingValues,
    isPosting,
    isDialogOpen,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);
  if (loadingOrganizations) {
    return (
      <Spinner
        reason={`OrganizationWizard: loadingOrganizations. ExistingValues: ${existingValues}`}
      />
    );
  }
  return (
    <WithEditOrganizationContext.Provider value={ctx}>
      {children}
    </WithEditOrganizationContext.Provider>
  );
}

function useEditOrganization(): EditOrganizationContext {
  const context = useContext(WithEditOrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useEditOrganization must be used within an EditOrganizationContext'
    );
  }
  return context;
}

const withEditOrganization =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditOrganization>
      <Component {...props} />
    </WithEditOrganization>
  );

export { WithEditOrganization, useEditOrganization, withEditOrganization };
