import { DateRangeObject } from 'shared/types/analytics';
import { ImpactFilters } from 'shared/types/analytics';
import { getIssueForm } from 'serviceWorker/services/issueForm/getIssueForm';
import { ApexSeriesAndLabels } from 'shared/types/analytics';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  HashMap,
  LabelledEntity,
  UntranslatedLabelledEntity,
} from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByProcess,
  filterBySite,
  filterBySubcontractor,
  filterByWorktype,
  increaseEntitySeries,
  increaseNoneSeries,
  toSortedApexSeries,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: ImpactFilters,
  timezone: string
): Promise<ApexSeriesAndLabels> {
  const [
    dateRangeFilter,
    originatorsFilter,
    processFilter,
    siteFilter,
    workTypeFilter,
    coordinatorFilter,
  ] = filters.dataScope.filters;

  const filteredIssues = issues.filter((issue) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedOriginators = filterBySubcontractor(
      originatorsFilter.value as string[],
      issue
    );
    if (!passedOriginators) {
      return false;
    }

    const passedProcess = filterByProcess(
      processFilter.value as string[],
      issue
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterBySite(siteFilter.value as string[], issue);
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterByWorktype(
      workTypeFilter.value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      coordinatorFilter.value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    return true;
  });

  const issueForms = await getIssueForm();

  const impactField = issueForms.find(
    (field: { name: string }) => field.name === 'impact'
  )!;

  const impacts: HashMap<LabelledEntity | UntranslatedLabelledEntity> =
    impactField.items!.reduce(
      (impactsMap: HashMap<LabelledEntity>, impact: LabelledEntity) => {
        impactsMap[impact._id] = { _id: impact._id, label: impact.label };
        return impactsMap;
      },
      {}
    );

  const reducedToImpactSummary = filteredIssues.reduce<HashMap<number>>(
    (result, issue) => {
      const { impact } = issue.extendedData;

      if (!impact) {
        increaseNoneSeries(result, impacts);
      } else {
        increaseEntitySeries(result, impacts, {
          _id: impact,
          label:
            (impacts[impact] as LabelledEntity | undefined)?.label ||
            impact,
        });
      }

      return result;
    },
    {}
  );

  return toSortedApexSeries(reducedToImpactSummary, impacts);
}
