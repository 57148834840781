import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    graphicUploaderWrapper: {
      width: '100%',
      marginTop: '22px',
      marginBottom: '24px',
    },
    complianceCheck: {
      marginTop: '32px',
    },
    mt24: {
      marginTop: '24px',
    },
    cardsContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      marginBottom: '24px',
      flexWrap: 'wrap',
    },
  })
);
