import React, { useCallback, useEffect, useMemo, useState } from 'react';
import VideocamIcon from '@mui/icons-material/Videocam';
import styles from './styles';
import Image from 'components/common/image';
import { isImage, isVideo } from './helpers';
import { DrawingType } from './types';
import { useSelector } from 'react-redux';
import { StoreState } from 'setup/types/core';
import { MemoThumbnailCard } from './thumbnailCard';
import classnames from 'classnames';
import { selectIcon } from '../../../views/issue/events/eventDetails/helpers';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { useDisplayedFilesContext } from '../../dataProviders/withDisplayedFiles';
import { DisplayedFile } from '../gallery/filesStore';

type Props = {
  id: number;
  src?: string;
  thumbnail?: string;
  onClick: (e: React.SyntheticEvent) => void;
  drawing: DrawingType;
  thumbnailSize: number;
};

export const ListImage: React.FC<Props> = (props) => {
  const cs = styles(props.thumbnailSize);
  const icons = useSelector((state: StoreState) => state.icons.icons);
  const { useDisplayedFiles, downloadFile } = useDisplayedFilesContext();
  const { onClick, id, drawing } = props;
  const [error, setError] = useState(false);

  const fileSelector = useMemo(() => {
    return function fileAtIndexSelector(
      files: DisplayedFile[]
    ): DisplayedFile | undefined {
      return files[id];
    };
  }, [id]);
  const [image] = useDisplayedFiles(fileSelector);

  useEffect(() => {
    setError(false);
    if (!image) return;
    downloadFile(image);
  }, [downloadFile, image]);

  const description = image?.description || image?.title || '';

  const [isLoaded, setIsLoaded] = useState(false);

  const setLoaded = useCallback(() => {
    if (image?.signedRequest) setIsLoaded(true);
  }, [image?.signedRequest]);

  const onError = useCallback(
    (...args: any[]) => {
      if (image?.signedRequest) {
        setError(true);
      }
    },
    [image?.signedRequest]
  );

  if (!image || image.deleted) {
    return null;
  }

  if (isVideo(image)) {
    return (
      <MemoThumbnailCard isLoaded={true} description={description} id={id}>
        <div
          className={classnames('thumbnail', cs.video)}
          onClick={onClick}
        >
          <VideocamIcon />
        </div>
      </MemoThumbnailCard>
    );
  }

  if (isImage(image)) {
    return (
      <MemoThumbnailCard
        isLoaded={isLoaded}
        description={description}
        id={id}
      >
        <Image
          className={classnames(
            'thumbnail',
            cs.image,
            !isLoaded && 'hiddenOpacity'
          )}
          description={description}
          src={imageSrc(error, drawing, image)}
          setLoaded={setLoaded}
          onError={onError}
          onClick={onClick}
        />
      </MemoThumbnailCard>
    );
  }

  return (
    <MemoThumbnailCard isLoaded description={description} id={id}>
      <div className={classnames('thumbnail', cs.pdf)} onClick={onClick}>
        <img
          src={selectIcon(
            image.type,
            image.data?.extension,
            icons?.document.files
          )}
          alt={description}
        />
      </div>
    </MemoThumbnailCard>
  );
};

function imageSrc(
  error: boolean,
  drawing: { thumbnailMergedImageUrl?: string } | undefined,
  image: { thumbnail?: string | undefined }
): string {
  if (error) {
    return DOCUMENT_ERROR_SOURCE;
  }
  if (drawing && drawing.thumbnailMergedImageUrl) {
    return drawing.thumbnailMergedImageUrl;
  }

  return image.thumbnail || '#';
}
