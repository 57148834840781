import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import { ListSearch } from '../../issue/index';
import { FormattedMessage, useIntl } from 'react-intl';

import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from './styles';
import { StoreState } from '../../../setup/types/core';
import { sortByProp } from '../../../helpers/sort';

import { ButtonContained, ButtonText } from '../../general';
import {
  Checkbox,
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { useSelector } from 'react-redux';
import useIsMobile from 'hooks/useIsMobile';
import { LabelledEntity } from 'shared/types/commonView';
import { normalizeForSearch } from 'shared/utils/search';

type DialogProps = {
  label?: string;
  list: LabelledEntity[];
  currentItems: LabelledEntity[];
  isOpen: boolean;
  onClose: () => void;
  onItemsSelection: (items: LabelledEntity[]) => void;
  objectIterationBy?: '_id' | 'label';
};

const itemsToDisplaySearch = 5;

const Dialog = ({
  list,
  label,
  isOpen,
  onClose,
  currentItems,
  onItemsSelection,
  objectIterationBy,
}: DialogProps): React.ReactElement | null => {
  const isMobile = useIsMobile();
  const { currentLocale } = useSelector((state: StoreState) => state.ui);
  const classes = useStyles({ isMobile });

  const propertyIterationBy = objectIterationBy ?? 'label';
  const listSorted: LabelledEntity[] = sortByProp(
    list,
    propertyIterationBy,
    currentLocale
  );
  const listLength = list?.length ?? 0;

  const [checked, setChecked] = useState<LabelledEntity[]>([]);
  const [filteredItems, setFilteredItems] =
    useState<LabelledEntity[]>(listSorted);
  const intl = useIntl();

  useEffect(() => {
    const common = currentItems.filter((el) =>
      list.some((e) => e._id === el._id)
    );

    if (common.length) {
      common.forEach(
        (item) =>
          checked.find((i) => i._id !== item._id) &&
          handleToggle(item, undefined)
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOpen) {
      setFilteredItems(list);
    }
  }, [isOpen, list]);

  const handleSearchFilter = useCallback(
    (searchPhrase: string): void => {
      if (!list) {
        return;
      }

      const filteredList = !searchPhrase
        ? list
        : list.filter((item) =>
            normalizeForSearch(item[propertyIterationBy]).includes(
              normalizeForSearch(searchPhrase)
            )
          );

      setFilteredItems(filteredList);
    },
    [list, propertyIterationBy]
  );

  if (!isOpen) {
    return null;
  }

  const handleClearOptions = (): void => {
    setChecked([]);
  };

  const handleToggle = (
    item: LabelledEntity,
    e: React.MouseEvent | undefined
  ): void => {
    e && e.stopPropagation();
    const currentIndex = checked.findIndex(
      (element) => element._id === item._id
    );
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(item);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSelection = (): void => {
    onItemsSelection(checked);
  };

  const listItems =
    filteredItems &&
    filteredItems
      .filter((item) => item)
      .map((item): ReactElement => {
        const isChecked =
          checked.findIndex((element) => element._id === item._id) !== -1;

        return (
          <ListItem
            dense
            key={item._id}
            role='list-item'
            className={classes.dialogListItem}
            data-qa={`multiselect-dialog-option-${item._id}`}
            onClick={(e): void => handleToggle(item, e)}
          >
            <ListItemIcon className='listItemIcon'>
              <Checkbox
                disableRipple
                edge='start'
                color='primary'
                tabIndex={-1}
                checked={isChecked}
                className={classes.checkbox}
              />
            </ListItemIcon>
            <ListItemText
              id={item._id}
              primary={item[propertyIterationBy]}
              className={classes.listItemText}
            />
          </ListItem>
        );
      });

  const getCurrentList = (): void => {
    setChecked(currentItems);
  };

  return (
    <MaterialDialog
      open={isOpen}
      aria-labelledby='customized-dialog-title'
      TransitionProps={{
        onEntered: getCurrentList,
      }}
      className={classes.dialogWrapper}
      onClose={onClose}
    >
      <div className={classes.dialogHeader}>
        <span className={classes.dialogTitle}>{label}</span>
        <CloseIcon
          onClick={onClose}
          className={classes.closeIcon}
          data-qa='multiselect-dialog-close-button'
        />
      </div>
      <Divider />
      {listLength > itemsToDisplaySearch && (
        <DialogContent className={classes.searchField}>
          <ListSearch
            onSearch={handleSearchFilter}
            data-qa='multiselect-dialog-search-input'
            placeholder={intl.formatMessage({
              id: 'dialog_placeholder_search',
            })}
          />
        </DialogContent>
      )}
      <DialogContent
        classes={{
          root: classNames(classes.dialogContent, 'dialogScrollbar'),
        }}
      >
        {!!listItems.length ? (
          <ul className={classes.dialogListContainer}>{listItems}</ul>
        ) : (
          <div className={classes.noItemsSearchPlaceholder}>
            <FormattedMessage id='dialog_info_nothingFound' />
          </div>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <ButtonText
          onClick={handleClearOptions}
          data-qa='multiselect-dialog-clear-button'
        >
          <FormattedMessage id='general_clear' />
        </ButtonText>
        <ButtonContained
          autoFocus
          onClick={handleSelection}
          disabled={!listItems?.length ?? false}
          data-qa='multiselect-dialog-select-button'
        >
          <FormattedMessage id='general_select' />
        </ButtonContained>
      </DialogActions>
    </MaterialDialog>
  );
};

export default Dialog;
