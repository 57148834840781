import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { EmptyListReason } from '../noTableContentInfo/NoTableContentInfo';

export type SearchInputProps = {
  value: string;
  searchChange: (value: string) => void;
  searchClear: () => void;
  dataQa: string;
  placeholderTextId: string;
  className?: string;
};

export type SearchInputPresentationalProps = {
  ref: React.MutableRefObject<null>;
  handleInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSearchClear: () => void;
  dataQa: string;
  placeholderTextId: string;
  className?: string;
  value: string;
};

export enum EntityLabel {
  company = 'company',
  contract = 'contract',
  user = 'user',
  documentation = 'documentation',
}

export type SearchableTableItemsContext = {
  loading: boolean;
  emptyListReason: EmptyListReason | undefined;
  setSearchPhrase: Dispatch<SetStateAction<string>>;
};
