import {
  CreateDirectoryCustomEventDetail,
  EditDirectoryCustomEventDetail,
  GetDirectoryCustomEventDetail,
} from 'shared/domain/messages/directory/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncDirectories);

const create = (e: CreateDirectoryCustomEventDetail): void => {
  debugLog('CREATE DIRECTORY EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateDirectoryCustomEventDetail>(
      RepositoryMessagesTypes.addDirectory,
      {
        detail: { ...e },
      }
    )
  );
};

const getOne = (e: GetDirectoryCustomEventDetail): void => {
  debugLog('GET SINGLE DIRECTORY', e);
  self.dispatchEvent(
    new CustomEvent<GetDirectoryCustomEventDetail>(
      RepositoryMessagesTypes.getDirectory,
      {
        detail: { id: e.id },
      }
    )
  );
};

const edit = (e: EditDirectoryCustomEventDetail): void => {
  debugLog('EDIT DIRECTORY EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditDirectoryCustomEventDetail>(
      RepositoryMessagesTypes.editDirectory,
      {
        detail: { ...e },
      }
    )
  );
};

export default {
  sync,
  create,
  edit,
  getOne,
};
