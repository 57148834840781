import { OpenCreateDialogParams } from 'components/dataCreationForms/types';
import { ChannelNames } from 'shared/domain/channelNames';
import { getCreatedMessageListener } from 'shared/domain/commonModel';
import { organizationCreateOnViewToOrganizationCreateModel } from 'shared/domain/organization/mapping/toModel';
import { createEmptyOrganization } from 'shared/domain/organization/mapping/toView';
import { startOrganizationCreate } from 'shared/domain/organization/startOrganizationCreate';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { OrganizationCreateOnView } from 'shared/domain/organization/types/view';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { currentUserSelector } from 'redux/selectors/users';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { CreateOrganizationContext } from '../types';
import {
  handleOrganizationCreateFormValidation,
  validateOrganizationCreate,
} from '../validation';
const SUBMIT_EVENT_TYPE = 'submit-organization';
const formName = 'organization';

const WithCreateOrganizationContext = createContext<
  CreateOrganizationContext | undefined
>(undefined);

function WithCreateOrganization({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const history = useHistory();
  // const { resync: fetchOrganizations } = useOrganizations();
  const dispatch = useDispatch();
  const contactEmail = useSelector(currentUserSelector).data.email;

  const [initialValues, setInitialValues] = useState(
    createEmptyOrganization(contactEmail)
  );
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);
  const [open, setOpen] = useState(false);

  const openDialog = useCallback(
    ({ freeSoloLabel }: OpenCreateDialogParams) => {
      setOpen(true);

      initialValues &&
        freeSoloLabel &&
        setInitialValues({
          ...initialValues,
          label: freeSoloLabel,
          name: freeSoloLabel,
        });
    },
    [initialValues]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const closeDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);
  const [isPosting, setIsPosting] = useState(false);

  const submitForm = useCallback(
    async (values: OrganizationCreateOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const organizationCreateModel =
        organizationCreateOnViewToOrganizationCreateModel(values);

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getCreatedMessageListener(
          (message: OrganizationModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.organizationChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('CreateOrganization: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startOrganizationCreate(organizationCreateModel, uniqueId);
      })
        .then((organization: unknown): any => {
          if (organization && (organization as Identificable)._id) {
            return organization;
          }
        })
        .catch(() => {});
    },
    [dispatch]
  );

  const ctx: CreateOrganizationContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateOrganizationCreate,
      handleFormValidation: handleOrganizationCreateFormValidation,
      formName,
    };
  }, [
    closeDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
  ]);

  return (
    <WithCreateOrganizationContext.Provider value={ctx}>
      {children}
    </WithCreateOrganizationContext.Provider>
  );
}

function useCreateOrganization(): CreateOrganizationContext {
  const context = useContext(WithCreateOrganizationContext);
  if (context === undefined) {
    throw new Error(
      'useCreateOrganization must be used within an CreateOrganizationContext'
    );
  }
  return context;
}

const withCreateOrganization =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateOrganization>
      <Component {...props} />
    </WithCreateOrganization>
  );

export {
  WithCreateOrganization,
  useCreateOrganization,
  withCreateOrganization,
};
