import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { SiteHttpRestoreRequestModel } from '../httpQueue/site/types';
import { RestoreSiteUseCase } from 'shared/domain/site/types/useCases';

export class SiteRestorer implements RestoreSiteUseCase {
  constructor(
    private addRequest: (
      request: SiteHttpRestoreRequestModel
    ) => Promise<any>
  ) {}
  async execute({
    siteId,
    projectId,
    uniqueId,
  }: {
    siteId: string;
    uniqueId: string;
    projectId: string;
  }): Promise<void> {
    debugLog('RestoreSiteUseCase', siteId);

    await this.addRequest({
      createdAt: Date.now(),
      method: 'GET',
      data: {
        siteId: siteId,
        projectId: projectId,
        uniqueId: uniqueId,
      },
      url: `/project/${projectId}/site/${siteId}`,
      entityType: HttpRequestModelType.site,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const siteRestorer = new SiteRestorer(addRequest);
