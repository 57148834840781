import { Box, Divider, Typography } from '@mui/material';
import { useBacktrack } from 'components/common/withBacktrack';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import {
  useCreateUser,
  withCreateUser,
} from 'components/dataCreationForms/user/withCreateUser';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { withSites } from 'components/dataProviders/withSites';
import { withUsers } from 'components/dataProviders/withUsers';
import { FormBottonButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { MemoUserForm } from 'components/user/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';

function CreateUserWizardContainer(): React.ReactElement {
  const {
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useCreateUser();
  const intl = useIntl();

  const { back } = useBacktrack();
  const formName = 'user';
  useFormCleanupOnUnmount(formName);
  const onCancel = useCallback(() => {
    back();
  }, [back]);

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {intl.formatMessage({ id: 'add_user_section' })}
      </Typography>
      <Divider />
      <Box sx={{ p: 3, mb: 8 }}>
        <MemoUserForm
          isDialog={false}
          editedUserId={null}
          existingRoleId={null}
        />
      </Box>
      <FormBottonButtons
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoCreateUserWizardContainer = memo(
  withFetcherReady(
    withProcessesData(
      withUsers(withSites(withCreateUser(CreateUserWizardContainer)))
    )
  )
);
