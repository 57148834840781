import React, { useContext } from 'react';
import { styles } from '../../FilterSidebarStyles';
import { DateFnsUtils } from 'helpers/date';
import { IntlContext } from 'intl/IntlProviderWrapper';
import { useIntl } from 'react-intl';
import { DateField } from './helpers';
import { DateTypeIndicator } from 'setup/types/core';
import { creationDateFilterStyles } from './styles';
import { getFilterPresentedLabel } from '../model';
import { Typography } from '@mui/material';
import { DateTime } from 'luxon';

type Props = {
  filterName: string;
  fromDateObject: DateTime | null | undefined;
  handleDateChange: (date: Date, indicator: DateTypeIndicator) => void;
  handleDateClear: (indicator: DateTypeIndicator) => void;
  toDateObject: DateTime | null | undefined;
  label: string | { id: string };
};

const Presentational: React.FC<Props> = ({
  filterName,
  fromDateObject,
  handleDateChange,
  handleDateClear,
  toDateObject,
  label,
}) => {
  const classes = styles();
  const internalClasses = creationDateFilterStyles();

  const intl = useIntl();
  const { calendarLocales } = useContext(IntlContext);

  const presentedLabel = getFilterPresentedLabel(label, intl);

  return (
    <div className={classes.filter}>
      <div className={classes.filterController}>
        <Typography className={classes.filterLabel}>
          {presentedLabel}
        </Typography>
      </div>
      <div className={internalClasses.container}>
        <DateField
          margin
          filterName={filterName}
          indicator={DateTypeIndicator.FROM}
          value={fromDateObject}
          classes={internalClasses}
          maxDate={toDateObject ?? undefined}
          onDateClear={handleDateClear}
          onDateChange={handleDateChange}
          dataQa={`filter-sidebar-dateField-${filterName}-from`}
          label={intl.formatMessage({
            id: 'filters_filter_type_date_from_label',
          })}
        />
        <DateField
          filterName={filterName}
          indicator={DateTypeIndicator.TO}
          value={toDateObject}
          classes={internalClasses}
          minDate={fromDateObject ?? undefined}
          onDateClear={handleDateClear}
          onDateChange={handleDateChange}
          dataQa={`filter-sidebar-dateField-${filterName}-to`}
          label={intl.formatMessage({
            id: 'filters_filter_type_date_to_label',
          })}
        />
      </div>
    </div>
  );
};

export default React.memo(Presentational);
