import { Dispatch, SetStateAction, MutableRefObject } from 'react';
import { ResizeObserverChange } from './types';
import { MENU_BAR_HEADER_HEIGHT } from 'components/core/MenuBar/styles';

export function resize(
  changes: ResizeObserverChange[],
  setWidth: Dispatch<SetStateAction<number>>,
  setHeight: Dispatch<SetStateAction<number>>,
  ref: MutableRefObject<HTMLDivElement | null>
): void {
  const wrapperChange = changes.find((change) => {
    return change.target === ref.current;
  });
  if (wrapperChange) {
    setWidth(wrapperChange.contentRect.width);
    setHeight(wrapperChange.contentRect.height);
    return;
  }

  const bodyChange = changes.find((change) => {
    return change.target === document.body;
  });
  if (bodyChange) {
    setWidth(bodyChange.contentRect.width - MENU_BAR_HEADER_HEIGHT);
    setHeight(bodyChange.contentRect.height - MENU_BAR_HEADER_HEIGHT - 58);
    return;
  }
}
