import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  (theme: DefaultTheme) =>
    createStyles({
      root: {
        boxSizing: 'border-box',
        maxWidth: ({ isMobile }): string => (isMobile ? '100%' : '892px'),
        marginLeft: ({ isMobile }): string => (isMobile ? '16px' : '42px'),
        marginRight: ({ isMobile }): string =>
          isMobile ? '16px' : '42px',
        '& h2': {
          color: '#000000',
          fontWeight: '600',
          fontSize: '1.5rem',
          marginBottom: '8px',
          marginTop: '24px',
        },
        '& h4': {
          color: '#979797',
          fontWeight: '400',
          fontSize: '0.875rem',
          marginTop: '8px',
        },
      },
      h1Margin: {
        marginBottom: ({ isMobile }): string =>
          isMobile ? '24px' : '40px',
      },
      summaryMargin: { marginBottom: '24px' },
      generalAppliedStandardsDescriptionMargin: {
        marginBottom: ({ isMobile }): string =>
          isMobile ? '16px' : '37px',
      },
      generalNormGraphicUploader: {
        marginTop: ({ isMobile }): string => (isMobile ? '0' : '40px'),
      },
      standardDescriptionMargin: { marginBottom: '16px' },
      footerBlankSpaceMargin: { marginBottom: '85px' },
      contentBox: {
        margin: ({ isMobile }): string =>
          isMobile ? '0 0 24px 0' : '0 13px 24px 13px',
      },
      standardBorderRadius: {
        borderRadius: '4px',
        '& .MuiOutlinedInput-root': {
          borderRadius: '4px',
        },
      },
      fullWidth: {
        width: '100%',
      },
      divider: {
        width: ({ isMobile }): string =>
          isMobile ? 'calc(100% + 32px)' : '100%',
        position: ({ isMobile }): 'relative' | 'unset' =>
          isMobile ? 'relative' : 'unset',
        left: ({ isMobile }): string => (isMobile ? '-16px' : 'unset'),
      },
      issueTemplateSubmitFooter: {
        position: 'fixed',
        bottom: '0',
        // navigation has 64px
        width: ({ isMobile }): string =>
          isMobile ? '100%' : 'calc(100% - 64px)',
        '& > div': {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        },
        backgroundColor: '#FFF',
        zIndex: 1,
      },
      castShadow: {
        boxShadow: '0px -4px 4px -2px rgba(0, 0, 0, 0.15)',
      },
      processIcon: {
        width: '41px',
        maxWidth: '41px',
        fontSize: '0.875rem',
        borderRadius: '20px',
        color: 'white',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        height: '21px',
        marginTop: '1px',
        '& > span': {
          marginTop: '1px',
        },
      },
      flexCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },

      processGroup: {
        backgroundColor: 'white',
        width: ({ isMobile }): string =>
          isMobile ? '100%' : 'fit-content',
        '& .processButton': {
          padding: '15px 20px',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#000',
          minWidth: ({ isMobile }): string =>
            isMobile ? '50px' : '180px',
          borderColor: '#8A8A8A',
          '& .toggleButtonText': {
            marginLeft: '10px',
            textTransform: 'none' as 'none',
            fontWeight: 'bold' as 'bold',
          },
          '&:hover': {
            background: 'rgba(251, 204, 48, 0.1)',
          },
          '&.Mui-selected': {
            color: '#000',
            background: 'rgba(251, 204, 48, 0.15)',
            border: '1px solid #FBCC30',
          },
          '&.Mui-selected:hover': {
            color: '#000',
            background: 'rgba(251, 204, 48, 0.1)',
          },

          [theme.breakpoints.down(600)]: {
            padding: '8px',
            '& .processIcon': {
              display: 'none',
            },
            '& .toggleButtonText': {
              marginLeft: 0,
            },
          },
        },
        '&.error > button': {
          borderColor: 'red',
        },
      },
      footerPadding: {
        paddingTop: '11px',
        paddingBottom: '12px',
        paddingRight: '29px',
        paddingLeft: '16px',
        overflow: 'auto',
        '& div': {
          flexShrink: 0,
        },
      },
    })
);
