import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    processIcon: {
      boxSizing: 'border-box',
      fontStyle: 'normal',
      borderRadius: '20px',
      color: 'white',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '1px',
      '& > span': {
        marginTop: '1px',
      },
      flexShrink: 0,
    },
    default: {
      width: '41px',
      maxWidth: '41px',
      fontSize: '0.875rem',
      height: '21px',
    },
    small: {
      fontWeight: 700,
      fontSize: '0.625rem',
      fontStyle: 'normal',
      lineHeight: '0.75rem',
      letterSpacing: '0em',
      height: '19px',
      width: '32.8883056640625px',
      marginTop: '0px',
      '& > span': {
        marginTop: '0px',
      },
    },
    issueCard: {
      width: '41px',
      maxWidth: '41px',
      height: '21px',
      fontWeight: 700,
      fontSize: '0.75rem',
      marginTop: '0px',
    },
  })
);
