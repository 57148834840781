import { RelationsMap, RelationsHashMap } from '../filter/types';
type RelationElem = {
  getter: () => Promise<any>;
  key: string;
  name: string;
  matches: { path: string; type: string }[];
};

// Creates hashmap of related items, allowing us to sort/search by them.
export const genRelationsMap = async (
  relations: RelationElem[]
): Promise<RelationsMap> => {
  const hashMap: RelationsHashMap = {};

  for (const relation of relations) {
    const data = await relation.getter();
    hashMap[relation.name] = {};

    data.forEach((elem: any) => {
      hashMap[relation.name][elem._id] = elem[relation.key];
    });
  }
  const matches = relations.map((relation) => ({
    name: relation.name,
    matches: relation.matches,
  }));

  return { hashMap, matches };
};
