import { LevelModel } from 'shared/domain/level/types/model';
import { LabelledEntity } from 'shared/types/commonView';
import { DocumentationBaseIds } from 'shared/types/documentation';

export interface WarnableLevelChanges {
  (
    chosenLevels: LabelledEntity[] | undefined,
    levels: LevelModel[],
    currentIds: DocumentationBaseIds
  ): LevelChangeWarnings;
}
export type LevelChangeWarnings = {
  replacements: LabelledEntity[];
  removals: LabelledEntity[];
};
export function getWarnableChanges(
  chosenLevels: LabelledEntity[] | undefined,
  levels: LevelModel[],
  currentIds: DocumentationBaseIds
): LevelChangeWarnings {
  const replacements: LabelledEntity[] = [];
  const removals: LabelledEntity[] = [];

  if (!chosenLevels) {
    return {
      replacements,
      removals,
    };
  }

  levels.forEach((level) => {
    const map = level.maps[0];
    if (!map) {
      return;
    }

    const thisLevel = chosenLevels.find(
      (searched) => level._id === searched._id
    );

    if (
      map.documentationId !== currentIds.documentationId &&
      map.versionId !== currentIds.versionId &&
      thisLevel
    ) {
      replacements.push({
        _id: level._id,
        label: level.label,
      });
      return;
    }

    if (
      map.documentationId === currentIds.documentationId &&
      map.versionId === currentIds.versionId &&
      !thisLevel
    ) {
      removals.push({
        _id: level._id,
        label: level.label,
      });
      return;
    }
  });

  return {
    replacements,
    removals,
  };
}
