import React, { Fragment, useMemo } from 'react';
import {
  MemoStatefulSingleSelect,
  MemoStatefulTextField,
  StatefulCheckboxGroup,
} from '../../dataCreationForms/withInputForm/statefulInputsWrapper';
import {
  ButtonContained,
  ButtonIcon,
  ButtonText,
} from 'components/general';
import { CircularProgress, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useStyles } from './styles';
import { DeleteOutline } from '@mui/icons-material';
import { FormFieldProps } from 'components/inspection/Form/types';
import { LanguageOption } from './types';
import { findLastIndex } from './model';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';

type FieldValueFormPresentationalProps = {
  onCancel: () => void;
  onSubmit: () => void;
  isPosting: boolean;
  languageOptions: LanguageOption[];
  addLanguageOption: () => void;
  removeLanguageOption: (index: number) => void;
  processesCheckboxes: FormFieldProps<{ processes: string[] }>;
  disableAddLanguageOption: string | undefined;
  disableRemoveLanguageOption: boolean;
};
export function FieldValueFormPresentational(
  props: FieldValueFormPresentationalProps
): React.ReactElement {
  const {
    onCancel,
    onSubmit,
    isPosting,
    languageOptions,
    addLanguageOption,
    removeLanguageOption,
    processesCheckboxes,
    disableAddLanguageOption,
    disableRemoveLanguageOption,
  } = props;
  const classes = useStyles();
  const disableDeletionOfLastItem = useMemo(() => {
    return languageOptions.filter((option) => !option.removed).length <= 1;
  }, [languageOptions]);

  const indexOfLastNonRemoved = useMemo(() => {
    return findLastIndex((option) => !option.removed, languageOptions);
  }, [languageOptions]);

  return (
    <div className={classes.root}>
      <div className={classes.languageOptions}>
        {languageOptions.map((option, index) => {
          const removeDisabled =
            option.isRemote ||
            disableDeletionOfLastItem ||
            disableRemoveLanguageOption;
          const removeDisabledReason = getRemoveDisabledReason({
            isRemote: option.isRemote,
            isLast: disableDeletionOfLastItem,
            isHustroDefault: disableRemoveLanguageOption,
          });
          return option.removed ? null : (
            <Fragment key={option.key}>
              <MemoForbiddenTooltip
                visibleWhen={Boolean(option.languageSelect.disabled)}
                reason={
                  typeof option.languageSelect.disabled === 'object'
                    ? option.languageSelect.disabled.reason
                    : undefined
                }
              >
                {/* @ts-ignore for some reason ts thinks formKey can be a number */}
                <MemoStatefulSingleSelect {...option.languageSelect} />
              </MemoForbiddenTooltip>

              <MemoForbiddenTooltip
                visibleWhen={Boolean(option.labelField.disabled)}
                reason={
                  typeof option.labelField.disabled === 'object'
                    ? option.labelField.disabled.reason
                    : undefined
                }
              >
                {/* @ts-ignore for some reason ts thinks formKey can be a number */}
                <MemoStatefulTextField {...option.labelField} />
              </MemoForbiddenTooltip>
              <div className={classes.deleteIconContainer}>
                <MemoForbiddenTooltip
                  visibleWhen={removeDisabled}
                  reason={removeDisabledReason}
                >
                  <ButtonIcon
                    onClick={() => removeLanguageOption(index)}
                    disabled={removeDisabled}
                  >
                    <DeleteOutline />
                  </ButtonIcon>
                </MemoForbiddenTooltip>
              </div>
              {indexOfLastNonRemoved !== index ? <Divider /> : null}
            </Fragment>
          );
        })}
      </div>
      <Divider />
      <div className={classes.container}>
        <div className={classes.selfFlexEnd}>
          <MemoForbiddenTooltip
            visibleWhen={Boolean(disableAddLanguageOption)}
            reason={disableAddLanguageOption}
            inlined
          >
            <ButtonContained
              onClick={addLanguageOption}
              disabled={Boolean(disableAddLanguageOption)}
            >
              <FormattedMessage id='form_field_add_next' />
            </ButtonContained>
          </MemoForbiddenTooltip>
        </div>
      </div>

      <Divider />
      <div className={classes.container}>
        <StatefulCheckboxGroup {...processesCheckboxes} />
      </div>
      <Divider />

      <div className={classes.submitButtons}>
        <ButtonText onClick={onCancel} disabled={isPosting}>
          <FormattedMessage id='general_cancel' />
        </ButtonText>
        <ButtonContained onClick={onSubmit} disabled={isPosting}>
          {isPosting && (
            <CircularProgress
              size={13}
              thickness={4}
              disableShrink
              color='secondary'
            />
          )}
          <FormattedMessage id='general_save' />
        </ButtonContained>
      </div>
    </div>
  );
}

function getRemoveDisabledReason({
  isRemote,
  isLast,
  isHustroDefault,
}: any): string | undefined {
  if (isHustroDefault) {
    return 'disabled_cannot_change_hustro_default';
  }
  if (isRemote) {
    return 'disabled_cannot_remove_saved_translation';
  }
  if (isLast) {
    return 'disabled_require_at_least_one_field_option';
  }
}
