import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from 'serviceWorker/helpers/httpRequests';
import { HeadersData } from '../factories/types';
import { pull } from '../pull';
import { FetchRequest } from './fetchRequest';

export const fetch = (
  headersData: HeadersData,
  httpRequest: FetchRequest,
  controller: AbortController
): Promise<{ status: number }> | undefined => {
  const { signal } = controller;
  if (shouldSkip(headersData, httpRequest.url)) {
    return undefined;
  }

  if (signal.aborted) {
    throw new Error('Abort signal');
  }

  const builtUrl = `${headersData.api}/v2/${httpRequest.url}`;
  const request = {
    ...defaultConfig,
    method: httpRequest.method,
    headers: generateHeaders(headersData),
    signal,
  };
  if (httpRequest.data) {
    request.body = JSON.stringify(httpRequest.data);
  }

  return pull(builtUrl, request).then((response) => {
    return parseResponse(response).then((responseJson: any) => {
      return {
        ...responseJson,
        status: response.status,
      };
    });
  });
};

function shouldSkip(data: HeadersData, url: string | undefined): boolean {
  return !data.api || !url;
}
