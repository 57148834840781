import { useIssueCreateMessage } from 'components/dataCreationForms/withIssueCreateMessage';
import { useFieldVisibility } from 'components/dataProviders/withVisibleFields';
import startIssueCreationUseCase from 'shared/domain/issue/startIssueCreation';
import {
  useCancelConfirmation,
  useFormCleanupOnUnmount,
} from 'presentation/dialogForms/dialogFormsHooks';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { StageInternalNames } from 'shared/types/stages';
import { debugLog } from 'shared/logger/debugLog';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { AfterDoneResult } from './afterDoneResult';
import { IssueForm } from './form';
import { addDetectionDate, createInitialValues } from './model';
import {
  FilledIssue,
  IssueWizardProps,
  SetableIssueFields,
} from './types';
import { handleFieldValidation, handleFormValidation } from './validation';

const SUBMIT_EVENT_TYPE = 'submit-issue';

export const IssueWizard = forwardRef<
  { onCancel: () => Promise<void> },
  IssueWizardProps
>((props, ref) => {
  const formName = 'issue';
  const {
    disabledFields,
    beforeSave,
    afterDone,
    initialValues,
    processCode,
  } = props;
  const {
    timezone,
    processes,
    _id: projectId,
  } = useSelector(projectDataSelector);
  const { subscribe } = useIssueCreateMessage();
  const { fieldVisibilityStore } = useFieldVisibility();

  const [isPosting, setIsPosting] = useState<boolean>(false);

  useFormCleanupOnUnmount(formName);
  const dispatch = useDispatch();

  const submitForm = useCallback(
    async (values: FilledIssue) => {
      setIsPosting(true);
      const additionalValues = beforeSave
        ? await beforeSave()
        : { inspection: undefined, protocolItem: undefined };
      const { site, level, process, title } = values;

      const unsubscribe = subscribe(
        (issue) => {
          debugLog('issue recieved', issue);
          unsubscribe();
          afterDone(issue, AfterDoneResult.saved);
        },
        (error) => {
          debugLog(error);
          displayGenericErrorToaster(dispatch);
          afterDone(undefined, AfterDoneResult.cancelled);
        }
      );

      startIssueCreationUseCase({
        primaryData: {
          site: site._id,
          level: level._id,
          title,
          detectionDate: addDetectionDate(
            fieldVisibilityStore,
            timezone,
            process._id
          )?.toString(),
        },
        process: process._id,
        stage: StageInternalNames.draft,
        projectId,
        ...additionalValues,
      });
    },
    [
      afterDone,
      beforeSave,
      timezone,
      projectId,
      subscribe,
      dispatch,
      fieldVisibilityStore,
    ]
  );

  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const formInitialValues: SetableIssueFields = createInitialValues(
    initialValues,
    processCode,
    processes
  );

  const confirmCancel = useCancelConfirmation(formName);

  const onCancel = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }

    afterDone(undefined, AfterDoneResult.cancelled);
  }, [afterDone, confirmCancel]);

  useImperativeHandle(
    ref,
    () => ({
      onCancel,
    }),
    [onCancel]
  );

  return (
    <WithInputForm
      values={formInitialValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={handleFieldValidation}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <IssueForm
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
        disabledFields={disabledFields}
      />
    </WithInputForm>
  );
});
