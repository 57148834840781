import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      divider: {
        width: ({ isMobile }): string =>
          isMobile ? 'calc(100% + 32px)' : '100%',
        position: ({ isMobile }): 'relative' | 'unset' =>
          isMobile ? 'relative' : 'unset',
        left: ({ isMobile }): string => (isMobile ? '-16px' : 'unset'),
      },
      contentBox: {
        display: 'flex',
        justifyContent: ({ isMobile }): string =>
          isMobile ? 'center' : 'flex-start',
        marginLeft: '13px',
        marginRight: '13px',
        marginBottom: '24px',
        marginTop: '24px',
      },
    })
);
