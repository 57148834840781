import { AddContractUseCase } from 'shared/domain/contract/addContract';
import { contractCreateModelToContractCreateOutDto } from 'shared/domain/contract/mapping';
import { ContractCreateModel } from 'shared/domain/contract/types/model';
import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { ContractCreateRequestModel } from '../httpQueue/contractRequestRunner';

export class ContractSaver implements AddContractUseCase {
  constructor(
    private addRequest: (
      request: ContractCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    contractCreateModel: ContractCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddContractUseCase', contractCreateModel);
    const contractCreateOutDto = contractCreateModelToContractCreateOutDto(
      contractCreateModel
    );
    const request: ContractCreateRequestModel = {
      createdAt: Date.now(),
      method: 'POST',
      url: `/project/${contractCreateModel.projectId}/contract`,
      data: {
        contractCreateOutDto,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.contract,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const contractSaver = new ContractSaver(addRequest);
