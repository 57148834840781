import React, {
  useCallback,
  useState,
  useEffect,
  ReactElement,
  MutableRefObject,
  useMemo,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './styles';
import { GU, FileType } from 'components/common/graphicUploader/types';
import { ButtonContained, ButtonText } from 'components/general';
import { ChangeEvent } from './types';
import { MAX_FILE_DESCRIPTION_LENGTH } from '../graphicUploader/helpers';
import useIsMobile from '../../../hooks/useIsMobile';
import { MemoSimpleTextField } from '../simpleInputFields/textField';
import {
  ErrorPresentation,
  stringMaxValidation,
} from 'helpers/validators';
import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import cn from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import { useEditDescriptionForm } from '../../dataCreationForms/withEditDescriptionForm';
import { GalleryState, slideSelector } from './galleryStateStore';
import { Store, useCreateStore } from 'hooks/createStore';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { useStylesCheckbox } from 'views/analytics/filtersRenderer/checkboxControl/styles';
import { MainImage } from '../mainImage';
import { isSupportedAsMainImage } from 'shared/domain/document/isSupportedAsMainImage';
import { makeStyles } from '@mui/styles';

type Props = {
  image: FileType;
  id: number;
  edit?: GU['editFile'];
  close: () => void;
  mainImageHandlerRef: MutableRefObject<MainImage> | undefined;
};

function descriptionValidation(
  description: string | undefined
): ErrorPresentation {
  return stringMaxValidation(MAX_FILE_DESCRIPTION_LENGTH, description);
}

const EditDescription: React.FC<Props> = ({
  image,
  id,
  edit,
  close,
  mainImageHandlerRef,
}) => {
  const [description, setDescription] = useState(
    image.description ?? undefined
  );
  const [error, setError] = useState<ErrorPresentation>(undefined);
  const checkedStore = useCreateStore(
    mainImageHandlerRef
      ? mainImageHandlerRef.current.getMainImageStore().get() === image._id
      : false
  );

  const isMobile = useIsMobile();
  const cs = styles({ isMobile });
  const intl = useIntl();

  const CANCEL = intl.formatMessage({
    id: 'general_cancel',
  });
  const SAVE = intl.formatMessage({
    id: 'general_save',
  });

  useEffect(() => {
    setError(descriptionValidation(description));
  }, [description]);

  const handleSave = useCallback((): void => {
    edit && edit(id, 'description', description);
    if (mainImageHandlerRef) {
      mainImageHandlerRef.current.saveMainImage(
        checkedStore.get(),
        image._id
      );
    }
    close();
  }, [
    edit,
    close,
    description,
    id,
    image._id,
    mainImageHandlerRef,
    checkedStore,
  ]);

  const handleChange = useCallback((e: ChangeEvent): void => {
    setDescription(e.target.value);
  }, []);

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const saveButtonDisabled = error !== undefined;

  return (
    <div>
      <div className={cs.editDescriptionRoot}>
        <MemoSimpleTextField
          handleChange={handleChange}
          labelId='file_input_description'
          fieldName='file-description'
          error={error}
          value={description}
          dense={false}
          reserveSpaceForHelperText={false}
        />
        <MainImageComponent
          mainImageHandlerRef={mainImageHandlerRef}
          image={image}
          checkedStore={checkedStore}
        />
      </div>
      <Divider style={{ marginTop: '16px' }} />
      <div
        className={cn(cs.buttonsContainer)}
        style={{
          padding: '16px 24px',
        }}
      >
        <ButtonText onClick={handleClose}>{CANCEL}</ButtonText>
        <ButtonContained
          onClick={handleSave}
          disabled={saveButtonDisabled}
        >
          {SAVE}
        </ButtonContained>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 16,
      width: '100%',
    },
  },
  dialogContent: {
    padding: 0,
  },
  closeIcon: {
    opacity: 0.54,
    cursor: 'pointer',
  },
  dialogTitle: {
    padding: '16px',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '18px',
    },
  },
});

export function EditDescriptionDialog({
  editFile,
  useGalleryState,
  mainImageHandlerRef,
}: {
  editFile: GU['editFile'];
  useGalleryState: <T>(
    selector: (store: GalleryState) => T
  ) => [T, (value: GalleryState) => void];
  mainImageHandlerRef: MutableRefObject<MainImage> | undefined;
}): ReactElement {
  const { file, idx, setIndex, closeDialog, isOpen } =
    useEditDescriptionForm();
  const [slide] = useGalleryState(slideSelector);
  const classes = useStyles();

  useEffect(() => {
    setIndex(slide);
  }, [slide, setIndex]);

  return (
    <Dialog
      className={classes.root}
      open={isOpen}
      keepMounted={false}
      onClose={closeDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage
          id={'issue_preview_description_edit_dialog_title'}
        />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <EditDescription
          image={file}
          id={idx}
          edit={editFile}
          close={closeDialog}
          mainImageHandlerRef={mainImageHandlerRef}
        />
      </DialogContent>
    </Dialog>
  );
}

function MainImageComponent({
  mainImageHandlerRef,
  image,
  checkedStore,
}: {
  mainImageHandlerRef: MutableRefObject<MainImage> | undefined;
  image: FileType;
  checkedStore: Store<boolean>;
}): ReactElement | null {
  return mainImageHandlerRef ? (
    <MainImageCheckbox
      key={`${mainImageHandlerRef.current.getMainImageStore().get()},${
        image._id
      }`}
      mainImageHandlerRef={mainImageHandlerRef}
      image={image}
      checkedStore={checkedStore}
    />
  ) : null;
}

function MainImageCheckbox({
  mainImageHandlerRef,
  image,
  checkedStore,
}: {
  mainImageHandlerRef: MutableRefObject<MainImage>;
  image: FileType;
  checkedStore: Store<boolean>;
}): ReactElement {
  const classes = useStylesCheckbox();
  const intl = useIntl();

  const [checked, setChecked] = useState(
    Boolean(image._id) &&
      mainImageHandlerRef.current.getMainImageStore().get() === image._id
  );

  useEffect(() => {
    if (!image._id) {
      return;
    }
    const action = (): void =>
      setChecked(
        mainImageHandlerRef.current.getMainImageStore().get() === image._id
      );
    action();
    return mainImageHandlerRef.current
      .getMainImageStore()
      .subscribe(action);
  }, [image._id, mainImageHandlerRef]);

  useEffect(() => {
    checkedStore.set(checked);
  }, [checkedStore, checked]);

  const disabledReason = useMemo(() => {
    const extension = image.data?.extension;
    if (!extension || !isSupportedAsMainImage(extension)) {
      return 'file_upload_error_file_type_not_supported';
    }
    if (!image._id) return 'main_image_disabled_image_not_uploaded_yet';
    return '';
  }, [image]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}
      className={classes.root}
    >
      <ForbiddenTooltip
        visibleWhen={Boolean(disabledReason)}
        reason={disabledReason}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                checked={checked}
                onChange={(_, isChecked) => {
                  setChecked(isChecked);
                }}
                disabled={Boolean(disabledReason)}
              />
            }
            name='use as main'
            label={intl.formatMessage({ id: 'use_as_main_image' })}
          />
        </FormGroup>
      </ForbiddenTooltip>
    </div>
  );
}
