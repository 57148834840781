import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { companyEntityToModel } from 'serviceWorker/repository/company/mapping';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  getCurrentUsers,
  getUsersSynchronized,
} from 'serviceWorker/services/users/getUsers';
import * as dbCompanies from '../../db/companies';
import * as dbCompaniesService from '../../db/companiesService';
import { makeBroadcastAll } from '../factories';
import { CompanyModel } from 'shared/domain/company/types/model';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';

export async function getAllCurrentCompanies(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const [companies, users] = await Promise.all([
    dbCompanies.get(),
    getCurrentUsers(),
  ]);
  const end = Date.now();
  debugLog('getAllCurrentCompanies time:', end - start);
  const currentCompanies = companies.map((company) =>
    companyEntityToModel(company, users)
  );
  broadcast.postMessage({
    data: { items: currentCompanies },
    type: DomainMessagesTypes.allCompanies,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.COMPANIES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}

export const broadcastAllCompanies = makeBroadcastAll<CompanyModel[]>(
  dbCompaniesService,
  {
    get: async () => {
      const [companies, users] = await Promise.all([
        dbCompanies.get(),
        getUsersSynchronized(),
      ]);

      return companies.map((entity) =>
        companyEntityToModel(entity, users)
      );
    },
  },
  ChannelNames.companyChannel,
  DomainMessagesTypes.allCompanies
);
