import {
  OrganizationCreateOutDto,
  OrganizationEditOutDto,
  OrganizationInDto,
} from 'shared/domain/organization/types/dto';

export interface IOrganizationUploader {
  POST: (url: string, data: any) => Promise<any>;
  PUT: (url: string, data: any) => Promise<any>;
}

export interface UploadOrganizationChanges {
  (
    uploader: IOrganizationUploader,
    body: OrganizationEditOutDto,
    url: string
  ): Promise<unknown>;
}

export interface UploadOrganization {
  (
    uploader: IOrganizationUploader,
    body: OrganizationCreateOutDto,
    url: string
  ): Promise<OrganizationInDto>;
}

export function uploadOrganizationChanges(
  uploader: IOrganizationUploader,
  body: OrganizationEditOutDto,
  url: string
): Promise<unknown> {
  return uploader.PUT(url, body);
}

export function uploadOrganization(
  uploader: IOrganizationUploader,
  body: OrganizationCreateOutDto,
  url: string
): Promise<OrganizationInDto> {
  return uploader.POST(url, { ...body });
}
