import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { ButtonContained } from 'components/general';
import { ListImage } from './listImage';
import styles, { DEFAULT_THUMBNAIL_CARD_SIZE } from './styles';
import { Event } from './types';
import { Gallery } from 'components/common/gallery';
import { useGraphicUploaderContext } from '.';
import DragDrop from 'components/common/dragDrop';
import cn from 'classnames';
import {
  makeMainFileStore,
  useStoreMainDisplayedFile,
} from '../gallery/mainDisplayedFile';
import { makeDisplayedFilesStore } from '../gallery/filesStore';
import {
  makeGalleryStatesStore,
  useStoreGalleryState,
} from '../gallery/galleryStateStore';
import { WithDisplayedFiles } from '../../dataProviders/withDisplayedFiles';
import { HashMap } from 'shared/types/commonView';
import { makeDownloadFile } from './downloadFile';
import { DocumentUrlsResult } from 'shared/domain/document/resolveDocumentUrls';
import { identitySelector } from '../gallery/selectors';
import { EditDescriptionDialog } from '../gallery/editDescription';
import {
  useEditDescriptionForm,
  withEditDescriptionForm,
} from '../../dataCreationForms/withEditDescriptionForm';

function filesStringSelector(files: { localId?: number }[]): string {
  return files.map((file) => file.localId || 'unsaved_file_').join(',');
}

function fileIdSelector(file: { localId?: number }): number | undefined {
  return file.localId;
}

const Presentational: React.FC = () => {
  const GU = useGraphicUploaderContext();
  const {
    filesStore,
    addFile,
    config,
    gallery,
    setGallery,
    drawings,
    classes: customClasses,
  } = GU;
  const mainFileStore = useStoreMainDisplayedFile();
  const galleryStateStore = useStoreGalleryState();

  const fileCache = useMemo(() => {
    return {} as HashMap<Promise<DocumentUrlsResult>>;
  }, []);

  const downloadFile = useMemo(() => {
    return makeDownloadFile(fileIdSelector, fileCache, filesStore);
  }, [fileCache, filesStore]);

  const thumbnailSize =
    config.thumbnailSize ?? DEFAULT_THUMBNAIL_CARD_SIZE;

  const inputRef = useRef<HTMLInputElement>(null);
  const classes = styles(thumbnailSize);
  const intl = useIntl();

  const clickHiddenInput = (): void => {
    inputRef?.current?.click();
  };

  const ADD_FILES_DROP = intl.formatMessage({
    id: 'general_add_files',
  });

  const DROP_FILES = intl.formatMessage({
    id: 'issue_preview_drop_zone_tittle',
  });

  const { dataQaPrefix, mainImageHandlerRef } = config;
  const setFiles = useCallback(() => {}, []);
  const useMainDisplayedFile = useMemo(
    () => makeMainFileStore(mainFileStore),
    [mainFileStore]
  );

  const useDisplayedFiles = useMemo(
    () =>
      makeDisplayedFilesStore(
        filesStore,
        (files) => {
          if (!mainImageHandlerRef) {
            return files;
          }
          const mainImageId = mainImageHandlerRef.current
            .getMainImageStore()
            .get();
          if (!mainImageId) {
            return files;
          }
          return files.sort((a, b) => {
            if (a._id === mainImageId) return -1;
            if (b._id === mainImageId) return 1;
            // @ts-ignore;
            return Date.parse(b.modifiedAt) - Date.parse(a.modifiedAt);
          });
        },
        mainImageHandlerRef
      ),
    [filesStore, mainImageHandlerRef]
  );

  const [filesString] = useDisplayedFiles(filesStringSelector);
  const showFiles = useMemo(() => {
    return Boolean(filesString);
  }, [filesString]);
  const [files] = useDisplayedFiles(identitySelector);

  useEffect(() => {
    mainFileStore.set(files[gallery.slide]);
  }, [mainFileStore, files, gallery.slide]);

  useEffect(() => {
    galleryStateStore.set(gallery);
  }, [galleryStateStore, gallery]);

  const useGalleryState = useMemo(
    () => makeGalleryStatesStore(galleryStateStore),
    [galleryStateStore]
  );

  const { openDialog } = useEditDescriptionForm();

  return (
    <>
      {!config.previewOnly && (
        <div
          className={cn(
            classes.buttonContainer,
            customClasses?.buttonContainer
          )}
        >
          <DragDrop
            handleInput={addFile}
            data-qa={`${dataQaPrefix}graphic-uploader-drag-drop-zone`}
          >
            <div className={classes.buttonBox}>
              <ButtonContained
                onClick={clickHiddenInput}
                data-qa={`${dataQaPrefix}graphic-uploader-add-files-button`}
              >
                {ADD_FILES_DROP}
              </ButtonContained>
            </div>
            <div className={classes.dropFilesPrompt}>{DROP_FILES}</div>
          </DragDrop>
        </div>
      )}
      <input
        ref={inputRef}
        type='file'
        onChange={(e: Event): void => {
          if (e.target.files) {
            addFile(Object.values(e.target.files));
          }
          e.target.value = '';
        }}
        accept={config?.file_types?.join()}
        className={classes.fileInput}
        multiple
      />
      <div className={classes.thumbContainer}>
        <WithDisplayedFiles
          filesStore={filesStore}
          useDisplayedFiles={useDisplayedFiles}
          downloadFile={downloadFile}
        >
          <Gallery
            addDrawing={GU.addDrawing}
            changeGallery={GU.changeGallery}
            drawings={GU.drawings}
            editFile={GU.editFile}
            removeFile={GU.removeFile}
            setGallery={GU.setGallery}
            setFiles={setFiles}
            preview={Boolean(config.previewOnly)}
            disableMiniatures={false}
            useMainDisplayedFile={useMainDisplayedFile}
            useGalleryState={useGalleryState}
            downloadFile={downloadFile}
            openEditComponent={openDialog}
            mainImageHandlerRef={mainImageHandlerRef}
          />

          <EditDescriptionDialog
            editFile={GU.editFile}
            useGalleryState={useGalleryState}
            mainImageHandlerRef={mainImageHandlerRef}
          />

          {showFiles &&
            filesString
              .split(',')
              .map((fileString, id) => (
                <ListImage
                  key={`${fileString}-${id}`}
                  id={id}
                  drawing={drawings[id]}
                  thumbnailSize={thumbnailSize}
                  onClick={(): void =>
                    setGallery({ open: true, slide: id })
                  }
                />
              ))}
        </WithDisplayedFiles>
      </div>
    </>
  );
};

export default withEditDescriptionForm(Presentational);
