import { DocumentationFile } from 'components/dataProviders/withDocumentationController/types';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { ReactElement } from 'react';
import { UserRole } from 'shared/types/userRole';
import { DocumentationInformationForm } from './form';
import { DocumentationInformationPreview } from './preview';

export function DocumentationInformation({
  role,
  file,
}: {
  role: UserRole;
  file: DocumentationFile;
}): ReactElement {
  return isAdmin(role) ? (
    <DocumentationInformationForm file={file} />
  ) : (
    <DocumentationInformationPreview file={file} />
  );
}
