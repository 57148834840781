import { useDataFlushedListener } from 'components/broadcastChannelListeners/useDataFlushedListener';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { isDocumentationDeleted } from 'shared/domain/messages/documentation/isDocumentationDeleted';
import { isDocumentationMoved } from 'shared/domain/messages/documentation/isDocumentationMoved';
import {
  Message,
  isCreateOrUpdate,
  isUploadOrUpdate,
} from 'shared/domain/messages/message';
import { useCreateStore } from 'hooks/createStore';
import { useGetDocumentations } from 'hooks/useGetDocumentations';
import { useMountedRef } from 'hooks/useMountRef';
import React, {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { useDocumentationChannelListener } from '../../broadcastChannelListeners/withDocumentationChannelListener';
import { useCurrentDirectoryContext } from '../withCurrentDirectory';
import { isSuccessUpload } from './model';
import { DocumentationsContextType } from './types';

const DocumentationsContext = React.createContext<
  DocumentationsContextType | undefined
>(undefined);

const WithDocumentations: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const mountedRef = useMountedRef();
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const currentDirDocumentationsStore = useCreateStore<
    DocumentationModel[]
  >([]);
  const isLoadingCurrentDirDocumentationsStore =
    useCreateStore<boolean>(true);

  const updateRemoteId = useCallback(
    ({ localId, _id }: { localId: number; _id: string }) => {
      const documentations = currentDirDocumentationsStore.get();
      const found = documentations.find((doc) => doc.localId === localId);
      if (!found) return;
      // set _id without calling all subscribers
      // @ts-ignore readonly
      found._id = _id;
    },
    [currentDirDocumentationsStore]
  );

  const { subscribe } = useDocumentationChannelListener();
  const { get: getDocumentations } = useGetDocumentations();

  const syncCurrentDirectoryDocumentations = useCallback(() => {
    const folderId = currentDirectoryStore.get();

    getDocumentations(currentDirectoryStore.get())
      .then((documentations) => {
        if (!mountedRef.current) {
          return;
        }
        if (currentDirectoryStore.get() !== folderId) {
          return;
        }
        currentDirDocumentationsStore.set(
          documentations.items.filter(isSuccessUpload)
        );
        isLoadingCurrentDirDocumentationsStore.set(false);
      })
      .catch((e) => {
        if (!mountedRef.current) {
          return;
        }
        if (currentDirectoryStore.get() !== folderId) {
          return;
        }
        displayGenericErrorToaster(dispatch);
      });
  }, [
    currentDirectoryStore,
    getDocumentations,
    mountedRef,
    currentDirDocumentationsStore,
    isLoadingCurrentDirDocumentationsStore,
    dispatch,
  ]);

  useEffect(
    function documentationChangesListener() {
      return subscribe((event: Message) => {
        if (
          (isCreateOrUpdate(event) ||
            isDocumentationMoved(event) ||
            isDocumentationDeleted(event)) &&
          event.data
        ) {
          syncCurrentDirectoryDocumentations();
          return;
        }

        if (isUploadOrUpdate(event)) {
          updateRemoteId(event.data);
          return;
        }
      });
    },
    [syncCurrentDirectoryDocumentations, subscribe, updateRemoteId]
  );

  useEffect(
    function errorsListener() {
      return subscribe((event: Message) => {
        if (
          (isCreateOrUpdate(event) ||
            isDocumentationMoved(event) ||
            isDocumentationDeleted(event)) &&
          event.error
        ) {
          displayGenericErrorToaster(dispatch);
        }
      });
    },
    [dispatch, subscribe]
  );

  useEffect(() => {
    const currentDirUnsubscribe = currentDirectoryStore.subscribe(() => {
      isLoadingCurrentDirDocumentationsStore.set(true);
      syncCurrentDirectoryDocumentations();
    });

    syncCurrentDirectoryDocumentations();

    return () => {
      currentDirUnsubscribe();
    };
  }, [
    currentDirectoryStore,
    syncCurrentDirectoryDocumentations,
    getDocumentations,
    isLoadingCurrentDirDocumentationsStore,
  ]);

  useDataFlushedListener(mountedRef, syncCurrentDirectoryDocumentations);

  const ctx: DocumentationsContextType = useMemo(() => {
    return {
      currentDirDocumentationsStore: currentDirDocumentationsStore,
      isLoadingCurrentDirDocumentationsStore:
        isLoadingCurrentDirDocumentationsStore,
      resync: syncCurrentDirectoryDocumentations,
    };
  }, [
    currentDirDocumentationsStore,
    isLoadingCurrentDirDocumentationsStore,
    syncCurrentDirectoryDocumentations,
  ]);

  return (
    <DocumentationsContext.Provider value={ctx}>
      {children}
    </DocumentationsContext.Provider>
  );
};

function withDocumentations<T>(Component: ComponentType<T>) {
  return (props: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithDocumentations>
      <Component {...props} />
    </WithDocumentations>
  );
}

function useDocumentations(): DocumentationsContextType {
  const context = React.useContext(DocumentationsContext);
  if (context === undefined) {
    throw new Error(
      'useDocumentations must be used within a DocumentationsContextProvider'
    );
  }
  return context;
}

export { WithDocumentations, useDocumentations, withDocumentations };
