import { EditFieldValuesUseCase } from 'shared/domain/fieldValue/editFieldValues';
import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import { EditFieldValueModel } from 'shared/domain/fieldValue/fieldValueModel';
import {
  broadcastFieldValuesChange,
  broadcastFieldValuesChangeError,
} from 'serviceWorker/broadcasts/fieldValues';
import {
  fieldValuesRequestRunner,
  IFieldValuesRequestRunner,
} from './fieldValuesRequestRunner';

class FieldValuesChanger implements EditFieldValuesUseCase {
  constructor(
    private requestRunner: IFieldValuesRequestRunner,
    private broadcastFieldValuesChange: (
      fieldsResponse: unknown[],
      uniqueId: string
    ) => void,
    private broadcastFieldValuesChangeError: (
      error: unknown,
      uniqueId: string
    ) => void
  ) {}

  async execute(
    fieldName: EditableFieldValuesEntityName,
    fieldValues: EditFieldValueModel[],
    uniqueId: string
  ): Promise<void> {
    try {
      const fieldValuesResponse: unknown[] =
        await this.requestRunner.execute({
          fieldName,
          fieldValues,
        });
      this.broadcastFieldValuesChange(fieldValuesResponse, uniqueId);
    } catch (e) {
      this.broadcastFieldValuesChangeError(e, uniqueId);
    }
  }
}

export const fieldValuesChanger = new FieldValuesChanger(
  fieldValuesRequestRunner,
  broadcastFieldValuesChange,
  broadcastFieldValuesChangeError
);
