import { ColumnShapeFactory } from 'components/table/columnShapes';
import { ColumnShape } from 'react-base-table';
import { IntlShape } from 'react-intl';
import { ColumnWidths } from 'setup/types/core';

export class ContractColumnsFactory extends ColumnShapeFactory {
  constructor(
    formatMessage: IntlShape['formatMessage'],
    columnWidths: ColumnWidths
  ) {
    super(formatMessage, columnWidths);
  }
  create(): ColumnShape[] {
    return [this.orderNumber(), this.contractLabel()];
  }
}
