import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { LevelInDto } from 'shared/dtos/in/level';
import {
  broadcastUploadedLevelError,
  broadcastUploadedLevel,
  broadcastDeletedLevel,
  broadcastUpdatedLevels,
} from 'serviceWorker/broadcasts/levels';

export function broadcastLevelResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastUploadedLevelError(response.error);
  }

  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastUploadedLevel(response, request.data.uniqueId);
  }

  if (request.method === 'DELETE') {
    return broadcastDeletedLevel(response, request.data.uniqueId);
  }

  if (request.method === 'PUT') {
    return broadcastUpdatedLevels(
      response.map((level: LevelInDto) => level._id),
      request.data.uniqueId
    );
  }
}
