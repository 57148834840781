import { IDocumentUploader } from './uploadDocument';

export interface UploadDocumentDelete {
  (uploader: IDocumentUploader, url: string): Promise<boolean>;
}

export const uploadDocumentDelete: UploadDocumentDelete =
  async function _uploadDocumentDelete(
    uploader: IDocumentUploader,
    url: string
  ): Promise<boolean> {
    try {
      await uploader.DELETE(url);
      return true;
    } catch (e) {
      return false;
    }
  };
