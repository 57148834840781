import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { ProgressBox } from 'components/progress/ProgressBox';
import { ReactElement, useCallback, useEffect, useState } from 'react';

export function DocumentationProgressBox({
  no,
  progressId,
  done,
  max,
  expanded,
}: {
  no: string;
  progressId: string;
  done: number | undefined;
  max: number | undefined;
  expanded: boolean;
}): ReactElement {
  const { select, selectedStore } = useSelectedStoreContext<string>();
  const [selected, setSelected] = useState(
    selectedStore.get() === progressId
  );
  useEffect(() => {
    const action = (): void =>
      setSelected(selectedStore.get() === progressId);
    action();
    return selectedStore.subscribe(action);
  }, [selectedStore, progressId]);

  const color = selected ? '#8E24AA' : '#DADADA';
  const backgroundColor = selected ? 'rgba(142, 36, 170, 0.1)' : '#FFFFFF';
  const progressBarBackground = selected ? '#FFFFFF' : undefined;

  const onClick = useCallback(() => {
    select(progressId);
  }, [progressId, select]);
  const tooltipText = getTooltipText(done, max);

  // https://stackoverflow.com/a/32178833
  const value = ((done || 0) * 100) / (max || 1);
  const percent = Number(
    Math.round(parseFloat(value + 'e' + 2)) + 'e-' + 2
  );

  return (
    <ForbiddenTooltip
      visibleWhen={true}
      reason={tooltipText}
      values={{
        done,
        max,
        percent: percent,
      }}
    >
      <ProgressBox
        no={no}
        done={done}
        max={max}
        onClick={onClick}
        circleColor={color}
        expanded={expanded}
        backgroundColor={backgroundColor}
        progressBarBackground={progressBarBackground}
      />
    </ForbiddenTooltip>
  );
}

function getTooltipText(
  done: number | undefined,
  max: number | undefined
): string {
  if (done === undefined && max === undefined) {
    return 'progress_row_tooltip_no_data_both';
  }
  if (done === undefined) {
    return 'progress_row_tooltip_no_data_completed';
  }
  if (max === undefined) {
    return 'progress_row_tooltip_no_data_planned';
  }

  return 'progress_row_tooltip_data_available';
}
