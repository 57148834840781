import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { SearchInputPresentational } from './presentational';
import { EntityLabel, SearchInputProps } from './types';
import { useSearch } from '../../../hooks/search/useSearch';
import { useGetSearchPhrase } from '../../../hooks/search/useGetSearchPhrase';
import { useSaveSearchPhrase } from '../../../hooks/search/useSaveSearchPhrase';
import {
  getSearchPhraseFromStorage,
  searchPhraseValidation,
} from './helpers';
import { projectIdSelector } from 'helpers/misc';
import { Store } from 'hooks/createStore';

export function SearchInput({
  value,
  searchChange,
  searchClear,
  dataQa,
  placeholderTextId,
  className,
}: SearchInputProps): React.ReactElement {
  const ref = useRef(null);
  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>): void => {
      searchChange(event.target.value);
    },
    [searchChange]
  );

  const handleSearchClear = useCallback((): void => {
    searchClear();
  }, [searchClear]);

  return SearchInputPresentational({
    ref,
    className,
    value,
    dataQa,
    placeholderTextId,
    handleInputChange,
    handleSearchClear,
  });
}

export const makeSearchInput = (
  entityLabel: EntityLabel
): (() => React.ReactElement) => {
  return (): React.ReactElement => {
    const projectId = useSelector(projectIdSelector);
    const [searchPhraseState, setSearchPhraseState] = useState(
      getSearchPhraseFromStorage(projectId, entityLabel)
    );

    const { searchChange, searchClear, searchPhrase } = useSearch(
      setSearchPhraseState,
      searchPhraseState,
      searchPhraseValidation
    );
    useGetSearchPhrase(projectId, entityLabel, setSearchPhraseState);
    useSaveSearchPhrase(projectId, entityLabel, searchPhraseState);

    return (
      <SearchInput
        value={searchPhrase}
        searchChange={searchChange}
        searchClear={searchClear}
        dataQa='toolbar-search-input'
        placeholderTextId='dashboard_search_placeholder'
      />
    );
  };
};

export const makeSearchInputWithStore = (
  entityLabel: EntityLabel,
  store: Store<string>
): (() => React.ReactElement) => {
  return (): React.ReactElement => {
    const projectId = useSelector(projectIdSelector);
    const [initialSearchPhraseState, setSearchPhraseState] = useState(
      store.get() || getSearchPhraseFromStorage(projectId, entityLabel)
    );

    const { searchChange, searchClear, searchPhrase } = useSearch(
      setSearchPhraseState,
      initialSearchPhraseState,
      searchPhraseValidation
    );
    useGetSearchPhrase(projectId, entityLabel, setSearchPhraseState);
    useSaveSearchPhrase(projectId, entityLabel, initialSearchPhraseState);

    useEffect(() => {
      return store.subscribe(() => {
        searchChange(store.get());
      });
    }, [searchChange]);

    return (
      <SearchInput
        value={searchPhrase}
        searchChange={searchChange}
        searchClear={searchClear}
        dataQa='toolbar-search-input'
        placeholderTextId='dashboard_search_placeholder'
      />
    );
  };
};

export const MemoSearchInput = React.memo(SearchInput);
