import { projectIdSelector } from 'helpers/misc';
import { Store } from 'hooks/createStore';
import {
  MouseEvent,
  TouchEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DocumentationProgressActivationButton } from './DocumentationProgressActivationButton';
import { DocumentationProgressBox } from './DocumentationProgressBox';
import { DocumentationProgressExpandLockButton } from './DocumentationProgressExpandLockButton';
import { ProgressModel } from './types';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    documentationProgressRoot: {
      height: '100%',
      width: '64px',
      flexBasis: '64px',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
      position: 'relative',
    },
    expandable: {
      position: 'absolute',
      right: 0,
      top: 0,
      display: 'flex',
      flexDirection: 'column',
      flexFlow: 'column nowrap',
      height: '100%',
      overflow: 'visible',
      backgroundColor: '#FFFFFF',
      transition: 'width 100ms cubic-bezier(0.4, 0.0, 0.2, 1)',
      boxShadow: '0px 2px 9px 0px rgba(0, 0, 0, 0.25)',
    },
    activationButtonContainer: {
      height: '72px',
      width: '100%',
      borderBottom: '1px solid #00000029',
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingRight: '0.75rem',
    },
    progressContainer: {
      height: '100%',
      width: '100%',
      overflowY: 'auto',
      boxSizing: 'border-box',
      overflowX: 'hidden',
    },
  })
);

const stopPropagation = (e: TouchEvent | MouseEvent): void =>
  e.stopPropagation();

export function DocumentationProgress({
  activityStore,
  progress,
  rightPanelVisibleStore,
}: {
  activityStore: Store<boolean>;
  progress: ProgressModel[];
  rightPanelVisibleStore: Store<boolean>;
}): ReactElement | null {
  const classes = useStyles();
  const {
    expanded,
    active,
    toggleActive,
    setExpandedTrue,
    setExpandedFalse,
    toggleLockExpanded,
  } = useActiveAndExpanded(activityStore);

  useEffect(() => {
    rightPanelVisibleStore.set(true);
  }, [rightPanelVisibleStore]);

  return (
    <div
      className={classes.documentationProgressRoot}
      onMouseEnter={setExpandedTrue}
      onMouseMove={setExpandedTrue}
      onMouseLeave={setExpandedFalse}
      // prevents ClickawayListener
      onClick={stopPropagation}
      onTouchEnd={stopPropagation}
    >
      <div
        className={classes.expandable}
        style={{
          width: expanded ? '194px' : '64px',
        }}
      >
        <div className={classes.activationButtonContainer}>
          <DocumentationProgressActivationButton
            active={active}
            onClick={toggleActive}
          />
          <DocumentationProgressExpandLockButton
            active={active}
            expanded={expanded}
            onClick={toggleLockExpanded}
          />
        </div>
        <div className={classes.progressContainer}>
          <Progress
            active={active}
            expanded={expanded}
            progress={progress}
          />
        </div>
      </div>
    </div>
  );
}

function useActiveAndExpanded(activityStore: Store<boolean>): {
  expanded: boolean;
  active: boolean;
  toggleActive: () => void;
  setExpandedTrue: (e: MouseEvent) => void;
  setExpandedFalse: () => void;
  toggleLockExpanded: () => void;
} {
  const lockExpanded = useRef(false);
  const [expanded, setExpanded] = useState(lockExpanded.current);
  const { active, toggleActive } =
    useDocumentationProgressActivityControls(activityStore, setExpanded);

  const setExpandedTrue = useCallback(
    (e: MouseEvent) => {
      const isExpandButton = findExpandLockButtonId(e.target as Element);

      if (isExpandButton) {
        return;
      }
      setExpanded(activityStore.get());
    },
    [activityStore]
  );
  const setExpandedFalse = useCallback(() => {
    const active = activityStore.get();
    setExpanded(active && lockExpanded.current);
  }, [activityStore]);

  const toggleLockExpanded = useCallback(() => {
    lockExpanded.current = !lockExpanded.current;
    setExpanded(activityStore.get() && lockExpanded.current);
  }, [activityStore]);

  return {
    expanded,
    active,
    toggleActive,
    setExpandedTrue,
    setExpandedFalse,
    toggleLockExpanded,
  };
}

function findExpandLockButtonId(
  element: Element | null | undefined
): boolean {
  if (!element) return false;
  return (
    element.id === 'expand-lock-button' ||
    findExpandLockButtonId(element.parentElement)
  );
}

function Progress({
  active,
  expanded,
  progress,
}: {
  active: boolean;
  expanded: boolean;
  progress: ProgressModel[];
}): ReactElement | null {
  if (!active) return null;
  return <ProgressBoxes expanded={expanded} progress={progress} />;
}

function ProgressBoxes({
  expanded,
  progress,
}: {
  expanded: boolean;
  progress: ProgressModel[];
}): ReactElement {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      {progress.map((progress, index) => {
        return (
          <DocumentationProgressBox
            key={progress._id}
            no={`${index + 1}`}
            progressId={progress._id}
            done={progress.done}
            max={progress.max}
            expanded={expanded}
          />
        );
      })}
    </div>
  );
}

function useDocumentationProgressActivityControls(
  activityStore: Store<boolean>,
  setExpanded: (expanded: boolean) => void
): {
  active: boolean;
  toggleActive: () => void;
} {
  const projectId = useSelector(projectIdSelector);
  const [active, setActive] = useState<boolean>(activityStore.get());

  useEffect(() => {
    setActive(activityStore.get());
    return activityStore.subscribe(() => {
      setActive(activityStore.get());
    });
  }, [activityStore, setActive]);

  const toggleActive = useCallback(() => {
    const next = !activityStore.get();
    activityStore.set(next);
    setExpanded(next);
    localStorage.setItem(
      `documentation_progress_active_${projectId}`,
      next ? 'active' : ''
    );
  }, [activityStore, projectId, setExpanded]);

  return useMemo(() => {
    return {
      active,
      toggleActive,
    };
  }, [active, toggleActive]);
}
