import { PromiseExtended } from 'dexie';
import db from './index';
import { clear as usersServiceClear } from './usersService';
import {
  getErrorMsg,
  getErrorTransactionMsg,
  logWithRethrow,
  Operation,
} from './helpers';
import { wrapQuery } from './queryWrapper';
import { entityDbClearFactory } from './entityDbClearFactory';
import { UserEntity } from 'shared/domain/user/types/entity';
import {
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { HashMap } from 'shared/types/commonView';
import { LogLevel } from 'shared/types/logger';
import { Changes } from 'shared/types/commonEntities';
import { broadcastClearUsers } from 'serviceWorker/broadcasts/user/clear';

export const clear = entityDbClearFactory(
  db,
  ['users', 'usersService'],
  () => db.users.clear(),
  usersServiceClear,
  broadcastClearUsers
);

export const quantity = makeDefaultCount<UserEntity>(db, db.users);

export const get = makeDefaultGetMany<UserEntity>(db, db.users);

export const getOne = makeDefaultGetOne<string, UserEntity>(
  db,
  db.users,
  '_id'
);

export const removeBatch = makeDefaultRemoveBatch<string, UserEntity>(
  db,
  db.users,
  '_id'
);

export const addBatch = wrapQuery(
  db,
  (users: UserEntity[]): PromiseExtended<void> => {
    return db
      .transaction(
        'rw',
        db.users,
        async function clearTransaction(): Promise<any> {
          const currentUsers = await get();
          const usersMap = users.reduce<HashMap<UserEntity>>(
            (obj, user) => {
              obj[user._id] = user;
              return obj;
            },
            {}
          );
          const idsToDelete = currentUsers.reduce<string[]>(
            (toDelete, user) => {
              if (!usersMap[user._id]) {
                toDelete.push(user._id);
              }
              return toDelete;
            },
            []
          );
          await db.users.bulkPut(users);
          return removeBatch(idsToDelete);
        }
      )
      .catch((e) => {
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.clear, Operation.addBatch],
            ['users']
          ),
          errorObj: e,
          additionalInfo: null,
        });
      });
  }
);

export const getByIds = makeDefaultGetManyByIds<string, UserEntity>(
  db,
  db.users,
  '_id'
);

export const updateBatch = addBatch;

export const updateOne = wrapQuery(
  db,
  (userId: string, changes: Changes<UserEntity>): PromiseExtended => {
    return db.users.update(userId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'users'),
        errorObj: e,
        additionalInfo: { query: { data: { userId, changes } } },
      })
    );
  }
);
