import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import {
  __HttpRequestModel__,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { AbortUploadCustomEvent } from 'shared/domain/messages/httpQueue/eventMessages';
import { Services } from 'shared/domain/messages/message';
import {
  IDocumentationUploader,
  UploadDocumentation,
} from 'serviceWorker/repository/documentation/uploadDocumentation';
import { UploadDocumentationChange } from 'serviceWorker/repository/documentation/uploadDocumentationChange';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { IRequestRunner } from './httpRequestRunner';

export class DocumentationRequestRunner implements IRequestRunner {
  private abortController: AbortController | undefined;
  private request: __HttpRequestModel__ | undefined;

  constructor(
    private documentationUploader: IDocumentationUploader,
    private uploadDocumentationUseCase: UploadDocumentation,
    private uploadDocumentationChangeUseCase: UploadDocumentationChange,
    private getDocumentation: (id: number) => Promise<DocumentationModel>
  ) {
    // @ts-ignore Custom event.
    self.addEventListener(
      RepositoryMessagesTypes.abortUpload,
      (e: AbortUploadCustomEvent) => {
        if (!this.request || !this.abortController) {
          return;
        }

        const eventEntityType =
          e.detail.type === Services.DOCUMENTS
            ? HttpRequestModelType.document
            : HttpRequestModelType.documentation;
        if (
          this.request.method === 'POST' &&
          this.request.entityType === eventEntityType &&
          this.request.data.localId === e.detail.localId
        ) {
          this.abortController.abort();
        }
      }
    );
  }
  async execute(request: __HttpRequestModel__): Promise<boolean> {
    const abortController = new AbortController();
    this.abortController = abortController;
    this.request = request;
    switch (request.method) {
      case 'POST': {
        return this.uploadDocumentation(request, abortController);
      }
      case 'PUT': {
        return this.updateDocumentation(request);
      }
    }
    throw new Error('Not implemented.');
  }

  private async uploadDocumentation(
    request: __HttpRequestModel__,
    abortController: AbortController
  ): Promise<boolean> {
    const documentation = await this.getDocumentation(
      request.data.localId
    );
    const url = `/project/${request.data.projectId}/documentation`;

    return this.uploadDocumentationUseCase(
      abortController,
      this.documentationUploader,
      documentation,
      url
    );
  }

  private async updateDocumentation(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const documentation = await this.getDocumentation(
      request.data.localId
    );
    if (!documentation._id) {
      return false;
    }
    const url = `/project/${request.data.projectId}/documentation/${documentation._id}`;

    return this.uploadDocumentationChangeUseCase(
      this.documentationUploader,
      url,
      request.data.changes
    );
  }
}
