import { ReactElement, useMemo } from 'react';
import { ButtonContained } from 'components/general';
import { CreateProjectDialog } from './create/dialog';
import { FormattedMessage } from 'react-intl';
import { getClientAdminPermission } from 'components/project/projectForm/getClientAdminPermission';
import { useSelector } from 'react-redux';
import { currentUserSelector } from 'redux/selectors/users';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { useCreateProject } from 'components/dataCreationForms/project/withCreateProject';
import { useOrganizations } from 'components/dataProviders/withOrganizations';

export function CreateProjectButton(): ReactElement {
  const { openDialog } = useCreateProject();
  const {
    data: { basePermissions },
  } = useSelector(currentUserSelector);
  const { organizationsMap } = useOrganizations();
  const clientIdMap = useMemo(() => {
    if (!basePermissions || !organizationsMap) {
      return;
    }
    return getClientAdminPermission(basePermissions, organizationsMap);
  }, [basePermissions, organizationsMap]);

  const reason = clientIdMap
    ? 'create_project_disabled_reason'
    : 'create_project_disabled_reason_no_clients';

  return (
    <>
      <ForbiddenTooltip
        visibleWhen={!clientIdMap || !clientIdMap.size}
        reason={reason}
      >
        <ButtonContained
          onClick={() => openDialog({})}
          disabled={!clientIdMap || !clientIdMap.size}
        >
          <FormattedMessage id='create_project' />
        </ButtonContained>
      </ForbiddenTooltip>
      <CreateProjectDialog />
    </>
  );
}
