import { FieldValueModel } from 'shared/domain/fieldValue/fieldValueModel';
import { FieldValueTranslationModel } from 'shared/domain/fieldValueTranslation/fieldValueTranslationModel';
import {
  FieldValueOnView,
  FieldValueTranslationOnView,
  FieldValueType,
} from './fieldValueOnView';

export function toFieldValueOnView(
  field: FieldValueModel
): FieldValueOnView {
  const createdByClientIdObject = field.createdByClientId
    ? {
        createdByClientId: field.createdByClientId,
      }
    : {};
  return {
    ...createdByClientIdObject,
    _id: field._id,
    canEditTranslations: Boolean(field.createdByClientId),
    label: field.label,
    processes: field.processes,
    valueTranslations: field.valueTranslations.map(
      toFieldValueTranslationOnView
    ),
  };
}

function toFieldValueTranslationOnView(
  translation: FieldValueTranslationModel
): FieldValueTranslationOnView {
  return {
    _id: translation._id,
    deleted: translation.deleted,
    localeCode: translation.localeCode,
    value: translation.value,
    type: FieldValueType.fromRemote,
  };
}
