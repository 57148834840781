import db from './index';
import { clear as hazardCategoriesServiceClear } from './hazardCategoriesService';
import { broadcastClearHazardCategories } from 'serviceWorker/broadcasts/hazardCategories';
import { entityDbClearFactory } from './entityDbClearFactory';
import { HazardCategoryEntity } from 'serviceWorker/repository/hazardCategory/entity';
import {
  makeDefaultAddBatch,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';

export const clear = entityDbClearFactory(
  db,
  ['hazardCategories', 'hazardCategoriesService'],
  () => db.hazardCategories.clear(),
  hazardCategoriesServiceClear,
  broadcastClearHazardCategories
);

export const quantity = makeDefaultCount<HazardCategoryEntity>(
  db,
  db.hazardCategories
);

export const get = makeDefaultGetMany<HazardCategoryEntity>(
  db,
  db.hazardCategories
);

export const getOne = makeDefaultGetOne<string, HazardCategoryEntity>(
  db,
  db.hazardCategories,
  '_id'
);

export const addBatch = makeDefaultAddBatch<HazardCategoryEntity>(
  db,
  db.hazardCategories
);

export const updateBatch = addBatch;

export const removeBatch = makeDefaultRemoveBatch<
  string,
  HazardCategoryEntity
>(db, db.hazardCategories, '_id');
