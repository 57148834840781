import React, { ReactElement, useCallback } from 'react';
import { ErrorPresentation } from '../../../../helpers/validators';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormHelperText, FormLabel, TextField } from '@mui/material';
import { NumberFormatCustom } from 'components/common/simpleInputFields/numberField/helpers';
import { Segments } from '../../../../charts/resolutionTime/types';
import { validateSegment } from '../../../../charts/resolutionTime/model';
import { useStylesSegments } from './styles';
import { ChartFilter } from 'shared/types/analytics';

function SegmentIcon(props: {
  color: string;
  className?: string;
}): React.ReactElement {
  const { color, className } = props;
  return (
    <svg
      className={className}
      width='24'
      height='24'
      viewBox='3 3 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z'
        fill={color}
      />
      <rect x='7' y='7' width='10' height='10' rx='2' fill={color} />
    </svg>
  );
}

function SingleSegment(props: {
  color: string;
  index: number;
  rangeMin?: number;
  rangeMax?: number;
  onChange: (
    index: number,
    value: number | undefined,
    type: 'rangeMin' | 'rangeMax'
  ) => void;
  error?: ErrorPresentation;
  disabled: boolean;
}): React.ReactElement {
  const { index, rangeMin, rangeMax, color, onChange, error, disabled } =
    props;

  const classes = useStylesSegments();
  const intl = useIntl();

  return (
    <div className={classes.segmentRoot}>
      <div className='inputsRow'>
        <SegmentIcon color={color} className={classes.segmentIcon} />
        <TextField
          label={intl.formatMessage({ id: 'from' })}
          variant='outlined'
          value={rangeMin || ''}
          className={classes.textField}
          disabled={disabled}
          onChange={({ target: { value } }): void => {
            onChange(
              index,
              typeof value === 'number' && !Number.isNaN(value)
                ? value
                : undefined,
              'rangeMin'
            );
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              decimalScale: 0,
            },
          }}
        />
        <span className={classes.hyphen} />
        <TextField
          label={intl.formatMessage({ id: 'to' })}
          variant='outlined'
          value={rangeMax || ''}
          className={classes.textField}
          disabled={disabled}
          onChange={({ target: { value } }): void => {
            onChange(
              index,
              typeof value === 'number' && !Number.isNaN(value)
                ? value
                : undefined,
              'rangeMax'
            );
          }}
          InputProps={{
            inputComponent: NumberFormatCustom,
            inputProps: {
              decimalScale: 0,
            },
          }}
        />
      </div>
      <FormHelperText error={!!error} className={classes.helperText}>
        {error && <FormattedMessage id={error.helperText.id} />}
      </FormHelperText>
    </div>
  );
}

export function SegmentsControl(props: {
  filter: ChartFilter<unknown>;
  localSetter: (value: Segments) => void;
  localValue: Segments;
  disabled: boolean;
}): ReactElement {
  const { localValue, localSetter, disabled } = props;
  const handleSegmentChange = useCallback(
    (
      index: number,
      value: number | undefined,
      type: 'rangeMin' | 'rangeMax'
    ): void => {
      const newValue = [...localValue];
      newValue[index][type] = value;
      localSetter(newValue);
    },
    [localValue, localSetter]
  );

  const classes = useStylesSegments();

  return (
    <>
      <FormLabel className={classes.segmentsSectionHeader}>
        <FormattedMessage id='presented_segments_days' />
      </FormLabel>
      <div className={classes.segmentsContainer}>
        {localValue.map((segment, index) => {
          const { rangeMin, rangeMax, color } = segment;

          return (
            <SingleSegment
              key={index}
              color={color}
              rangeMin={rangeMin}
              rangeMax={rangeMax}
              index={index}
              disabled={disabled}
              error={validateSegment(segment)}
              onChange={handleSegmentChange}
            />
          );
        })}
      </div>
    </>
  );
}
