import { FormattedMessage } from 'react-intl';
import React, { useCallback } from 'react';
import { MemoButtonContained } from '../../../general/ButtonContained/ButtonContained';
import { MemoButtonText } from '../../../general/ButtonText/ButtonText';
import {
  CircularProgress,
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import { ArrowDropDown, SaveOutlined } from '@mui/icons-material';
import { ReactComponent as FactCheckOutlined } from '../../../../assets/icons/fact_check_black.svg';
import { makeStyles } from '@mui/styles';
import useIsMobile from '../../../../hooks/useIsMobile';
import { usePopover } from '../../../../hooks/usePopover';

const useSubmitButtonWithPopoverStyles = makeStyles(() => ({
  listItemIcon: {
    minWidth: 'unset',
    marginRight: '16px',
    color: 'black',
  },
  divider: { margin: '0px 16px' },
}));

function SubmitButtonWithPopover(props: {
  posting: boolean;
  onSubmit: () => void;
  onFinish: () => void;
}): React.ReactElement {
  const classes = useSubmitButtonWithPopoverStyles();
  const { posting, onSubmit, onFinish } = props;

  const { attachPopover, popoverOpen, closePopover, anchorEl } =
    usePopover();

  const handleSubmitClick = useCallback(() => {
    closePopover();
    onSubmit();
  }, [onSubmit, closePopover]);

  const handleFinishClick = useCallback(() => {
    closePopover();
    onFinish();
  }, [onFinish, closePopover]);

  return (
    <>
      <MemoButtonContained
        onClick={attachPopover}
        endIcon={
          posting ? <CircularProgress size={16} /> : <ArrowDropDown />
        }
        disabled={posting}
      >
        <FormattedMessage id='general_save' />
      </MemoButtonContained>
      <Menu open={popoverOpen} anchorEl={anchorEl} onClose={closePopover}>
        <MenuItem onClick={handleFinishClick}>
          <ListItemIcon className={classes.listItemIcon}>
            <FactCheckOutlined />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id='form_save_and_finish' />
          </ListItemText>
        </MenuItem>
        <Divider className={classes.divider} />
        <MenuItem onClick={handleSubmitClick}>
          <ListItemIcon className={classes.listItemIcon}>
            <SaveOutlined />
          </ListItemIcon>
          <ListItemText>
            <FormattedMessage id='general_save_as_draft' />
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}

export function SubmitButtons(props: {
  onSubmit: () => void;
  posting: boolean;
  onFinish: () => void;
}): React.ReactElement {
  const { onFinish, onSubmit, posting } = props;

  const isMobile = useIsMobile();

  const mobileButton = (
    <SubmitButtonWithPopover
      posting={posting}
      onFinish={onFinish}
      onSubmit={onSubmit}
    />
  );

  const desktopButtons = (
    <>
      <MemoButtonText
        onClick={onSubmit}
        endIcon={posting ? <CircularProgress size={16} /> : undefined}
        disabled={posting}
      >
        <FormattedMessage id='general_save_as_draft' />
      </MemoButtonText>
      <MemoButtonContained
        onClick={onFinish}
        endIcon={posting ? <CircularProgress size={16} /> : undefined}
        disabled={posting}
      >
        <FormattedMessage id='form_save_and_finish' />
      </MemoButtonContained>
    </>
  );

  return isMobile ? mobileButton : desktopButtons;
}
