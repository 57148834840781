import { BroadcastChannel } from 'broadcast-channel';
import { LevelModel } from 'shared/domain/level/types/model';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { levelEntityToModel } from 'serviceWorker/repository/level/mappings';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  getCurrentUsers,
  getUsersSynchronized,
} from 'serviceWorker/services/users/getUsers';
import { debugLog } from 'shared/logger/debugLog';
import * as dbLevels from '../db/levels';
import * as dbLevelsService from '../db/levelsService';
import {
  makeBroadcastAll,
  makeBroadcastClear,
  makeSimpleBroadcastError,
} from './factories';
import { OnMessage } from './types';

const broadcastLevelError = makeSimpleBroadcastError(
  ChannelNames.levelChannel
);

export const broadcastClearLevels = makeBroadcastClear(
  ChannelNames.levelChannel
);

export const broadcastAllLevels = makeBroadcastAll<LevelModel[]>(
  dbLevelsService,
  {
    get: async () => {
      const [levels, users] = await Promise.all([
        dbLevels.get(),
        getUsersSynchronized(),
      ]);
      return levels.map((level) => levelEntityToModel(level, users));
    },
  },
  ChannelNames.levelChannel,
  DomainMessagesTypes.allLevels
);

export const broadcastUploadedLevel = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.levelChannel
);

export const broadcastDeletedLevel = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.levelChannel
);

export const broadcastUpdatedLevels = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.levelChannel
);

export const broadcastUploadedLevelError = (error: any): void => {
  debugLog('broadcastUploadedLevelError', error);
  broadcastLevelError(DomainMessagesTypes.failUploadedEntity, error);
};

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.levelChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllLevels: {
        await getAllCurrentLevels(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentLevels(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const [levels, users] = await Promise.all([
    dbLevels.get(),
    getCurrentUsers(),
  ]);
  const end = Date.now();
  debugLog('getAllCurrentLevels time:', end - start);
  const currentLevels = levels.map((level) =>
    levelEntityToModel(level, users)
  );
  broadcast.postMessage({
    data: { items: currentLevels },
    type: DomainMessagesTypes.allLevels,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);
  apiBroadcast.postMessage({
    service: Services.LEVELS,
    method: ServiceMethods.SYNC,
  });
  apiBroadcast.close();
}
