import { IDocumentUploader } from './uploadDocument';
export function confirmUpload(
  uploader: IDocumentUploader,
  url: string
): Promise<any> {
  return uploader.GET(`${url}/uploadConfirmation`);
}

export function failUpload(
  uploader: IDocumentUploader,
  url: string
): Promise<any> {
  return uploader.GET(`${url}/uploadFailure`);
}
