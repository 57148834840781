import { sortLabelledEntities } from 'helpers/sort';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import {
  FilterDateType,
  FilterGeneral,
  IssueFilters,
} from 'components/dataProviders/withIssueFilters/types';
import { IssueForm } from 'shared/types/form';

const filterIsDate = (
  filterValues: any
): filterValues is FilterDateType => {
  return (
    filterValues.fromDate !== undefined ||
    filterValues.toDate !== undefined
  );
};

type SeparatedFilters = {
  general: HashMap<FilterGeneral[]>;
  date: HashMap<FilterDateType>;
};

const filterIsGeneral = (
  filterValues: any
): filterValues is FilterGeneral[] => {
  return (
    Array.isArray(filterValues) &&
    filterValues.every((value) => value._id !== undefined)
  );
};

export const separateFilters = (
  filtersProps: IssueFilters
): SeparatedFilters => {
  const initialState: SeparatedFilters = { general: {}, date: {} };

  return Object.keys(filtersProps).reduce((object, filterName) => {
    const filterValues = filtersProps[filterName];

    if (filterIsGeneral(filterValues)) {
      object.general[filterName] = filterValues;
    } else if (filterIsDate(filterValues)) {
      object.date[filterName] = filterValues;
    }

    return object;
  }, initialState);
};

const isLabelledEntity = (value: any): value is LabelledEntity => {
  return value?._id !== undefined && value?.label !== undefined;
};

export const matchOptionsWithFilterValues = (
  options: LabelledEntity[],
  filterValues?: FilterGeneral[]
): LabelledEntity[] => {
  return (
    filterValues
      ?.map((value) => options.find((stage) => stage._id === value._id))
      .filter(isLabelledEntity) ?? []
  );
};

export function impactSelector(issueForm: IssueForm): LabelledEntity[] {
  const impactField = issueForm.find(
    (field) => field.name === IssueFieldNames.impact
  );

  return (
    (impactField && impactField.items!.sort(sortLabelledEntities)) || []
  );
}
