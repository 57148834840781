import React, {
  MutableRefObject,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import Presentational from './presentational';
import { ActionsType, EditFile, RightPanelControls } from './types';
import useHandleKey from 'hooks/keyPress';
import { MainDisplayedFile } from './mainDisplayedFile';
import { isOpenSelector, slideSelector } from './galleryStateStore';
import { useDisplayedFilesContext } from '../../dataProviders/withDisplayedFiles';
import { DisplayedFile, FileSetForDisplay } from './filesStore';
import { DrawingType } from '../graphicUploader/types';
import { MainImage } from '../mainImage';

function clampPreviousSlideIndex(
  nextIndex: number,
  filesLength: number
): number {
  if (nextIndex < 0) {
    return filesLength - 2;
  }
  return nextIndex;
}

function keyhandlerFilter(event: KeyboardEvent): boolean {
  return event.target === document.querySelector('body');
}

function registerKeyboardHandlers(
  changeGallery: (
    place: 'open' | 'slide',
    value: number | boolean
  ) => void,
  moveGallery: (direction: number) => void
): void {
  const ESC_KEY = 'Escape';
  const ARROW_RIGHT_KEY = 'ArrowRight';
  const ARROW_LEFT_KEY = 'ArrowLeft';
  useHandleKey(
    ESC_KEY,
    useCallback(() => changeGallery('open', false), [changeGallery]),
    { filter: keyhandlerFilter }
  );
  useHandleKey(
    ARROW_RIGHT_KEY,
    useCallback(() => moveGallery(1), [moveGallery]),
    { filter: keyhandlerFilter }
  );
  useHandleKey(
    ARROW_LEFT_KEY,
    useCallback(() => moveGallery(-1), [moveGallery]),
    { filter: keyhandlerFilter }
  );
}

export type HideActions = ('REMOVE' | 'DRAW' | 'DOWNLOAD' | 'EDIT')[];
export type GalleryState = {
  open: boolean;
  slide: number;
};
export type GalleryContextType<K = object> = {
  setFiles: (files: FileSetForDisplay<K>[]) => void;
  drawings: DrawingType[];
  addDrawing: (drawing: DrawingType, index: number) => void;
  changeGallery: (
    key: keyof GalleryState,
    value: GalleryState[keyof GalleryState]
  ) => void;
  setGallery: (state: GalleryState) => void;
  removeFile: (index: number) => void;
  editFile?: EditFile<K>;
  preview: boolean;
  useDisplayedFiles: <T>(
    selector: (store: DisplayedFile<K>[]) => T
  ) => [T, (value: DisplayedFile<K>[]) => void];
  useMainDisplayedFile: <T>(
    selector: (store: MainDisplayedFile<K> | undefined) => T
  ) => [T, (value: MainDisplayedFile<K>) => void];
  useGalleryState: <T>(
    selector: (store: GalleryState) => T
  ) => [T, (value: GalleryState) => void];
  disableMiniatures?: boolean;
  downloadFile: (file: DisplayedFile<K>) => void;
  openEditComponent: () => void;
  EditComponent?: (...args: any[]) => ReactElement;
  editTitleId?: string;
  hideActions?: HideActions;
  ImageDisplayOverlay?: () => ReactElement | null;
  additionalActions?: {
    PrefixedActionsComponent: ({
      disabledClass,
    }: {
      disabledClass: string;
    }) => ReactElement | null;
    SuffixedActionsComponent: ({
      disabledClass,
    }: {
      disabledClass: string;
    }) => ReactElement | null;
  };
  MiniaturesOverlay?: () => ReactElement | null;
  RightPanel?: () => ReactElement;
  rightPanelControls?: RightPanelControls;
};

type GalleryProps<K = object> = {
  setFiles: (files: FileSetForDisplay<K>[]) => void;
  drawings: DrawingType[];
  addDrawing: (drawing: DrawingType, index: number) => void;
  changeGallery: (
    key: keyof GalleryState,
    value: GalleryState[keyof GalleryState]
  ) => void;
  setGallery: (state: GalleryState) => void;
  removeFile: (index: number) => void;
  editFile?: EditFile<K>;
  preview: boolean;
  useMainDisplayedFile: <T>(
    selector: (store: MainDisplayedFile<K> | undefined) => T
  ) => [T, (value: MainDisplayedFile<K>) => void];
  useGalleryState: <T>(
    selector: (store: GalleryState) => T
  ) => [T, (value: GalleryState) => void];
  downloadFile: (file: DisplayedFile<K>) => void;
  disableMiniatures?: boolean;
  openEditComponent: () => void;
  EditComponent?: (...args: any[]) => ReactElement;
  editTitleId?: string;
  hideActions?: HideActions;
  ImageDisplayOverlay?: () => ReactElement | null;
  additionalActions?: {
    PrefixedActionsComponent: ({
      disabledClass,
    }: {
      disabledClass: string;
    }) => ReactElement | null;
    SuffixedActionsComponent: ({
      disabledClass,
    }: {
      disabledClass: string;
    }) => ReactElement | null;
  };
  MiniaturesOverlay?: () => ReactElement | null;
  mainImageHandlerRef?: MutableRefObject<MainImage> | undefined;
  RightPanel?: () => ReactElement;
  rightPanelControls?: RightPanelControls;
};
const GalleryContext = React.createContext<
  GalleryContextType<any> | undefined
>(undefined);

function lengthSelector<K>(files: K[]): number {
  return files.length;
}

function undeletedIndexSelector<K extends { deleted?: boolean }>(
  files: K[]
): number {
  return files.findIndex((file: K) => !file.deleted);
}

export function Gallery<K = object>({
  drawings,
  addDrawing,
  changeGallery,
  removeFile: handleRemoveFile,
  editFile,
  setFiles,
  setGallery,
  disableMiniatures,
  preview,
  useMainDisplayedFile,
  useGalleryState,
  downloadFile,
  openEditComponent,
  EditComponent,
  editTitleId,
  hideActions,
  ImageDisplayOverlay,
  additionalActions,
  MiniaturesOverlay,
  mainImageHandlerRef,
  RightPanel,
  rightPanelControls,
}: GalleryProps<K>): ReactElement {
  const { useDisplayedFiles } = useDisplayedFilesContext();
  const [filesLength] = useDisplayedFiles(lengthSelector);
  const [undeletedIndex] = useDisplayedFiles(undeletedIndexSelector);
  const [slide] = useGalleryState(slideSelector);
  const [isOpen] = useGalleryState(isOpenSelector);

  useEffect(() => {
    if (!filesLength && isOpen) {
      setGallery({ open: false, slide: 0 });
    }
  }, [filesLength, isOpen, setGallery]);

  useEffect(() => {
    if (!mainImageHandlerRef) return;

    return mainImageHandlerRef.current
      .getMainImageStore()
      .subscribe(() => {
        changeGallery('slide', 0);
      });
  }, [changeGallery, mainImageHandlerRef]);

  const drawing = drawings[slide];

  const [drawMode, setDrawMode] = useState(false);
  const moveGallery = useCallback(
    (direction: number) => {
      changeGallery(
        'slide',
        Math.min(filesLength - 1, Math.max(0, slide + direction))
      );
    },
    [slide, filesLength, changeGallery]
  );

  registerKeyboardHandlers(changeGallery, moveGallery);

  const handleSlidesAfterDeletion = useCallback(
    (deletedFileIdx: number) => {
      const areAllFilesDeleted = undeletedIndex === -1;

      if (areAllFilesDeleted) {
        changeGallery('open', false);
      } else {
        changeGallery(
          'slide',
          clampPreviousSlideIndex(deletedFileIdx - 1, filesLength)
        );
      }
    },
    [changeGallery, undeletedIndex, filesLength]
  );

  const removeFile = useCallback(
    (idx: number): void => {
      handleRemoveFile(idx);
      handleSlidesAfterDeletion(idx);
    },
    [handleRemoveFile, handleSlidesAfterDeletion]
  );

  const actions: ActionsType = {
    removeFile,
    edit: editFile,
  };

  const ctx = useMemo(
    () => ({
      setFiles,
      drawings,
      addDrawing,
      changeGallery,
      setGallery,
      removeFile,
      editFile,
      disableMiniatures,
      preview,
      useDisplayedFiles,
      useMainDisplayedFile,
      useGalleryState,
      downloadFile,
      openEditComponent,
      EditComponent,
      editTitleId,
      hideActions,
      ImageDisplayOverlay,
      additionalActions,
      MiniaturesOverlay,
      rightPanelControls,
    }),
    [
      setFiles,
      drawings,
      addDrawing,
      changeGallery,
      setGallery,
      removeFile,
      editFile,
      disableMiniatures,
      preview,
      useDisplayedFiles,
      useMainDisplayedFile,
      useGalleryState,
      downloadFile,
      openEditComponent,
      EditComponent,
      editTitleId,
      hideActions,
      ImageDisplayOverlay,
      additionalActions,
      MiniaturesOverlay,
      rightPanelControls,
    ]
  );

  // react 18 types
  // @ts-ignore
  return createPortal(
    <GalleryContext.Provider value={ctx}>
      {isOpen && (
        <Presentational
          drawing={drawing}
          changeGallery={changeGallery}
          moveGallery={moveGallery}
          drawings={drawings}
          idx={slide}
          actions={actions}
          setDrawMode={setDrawMode}
          drawMode={drawMode}
          addDrawing={addDrawing}
          disableMiniatures={disableMiniatures}
          preview={preview}
          additionalActions={additionalActions}
          MiniaturesOverlay={MiniaturesOverlay}
          RightPanel={RightPanel}
        />
      )}
      {EditComponent && <EditComponent />}
    </GalleryContext.Provider>,
    document.body
  );
}

export function useGalleryContext<K = object>(): GalleryContextType<K> {
  const context: GalleryContextType<K> | undefined =
    useContext(GalleryContext);
  if (context === undefined) {
    throw new Error(
      'useGalleryContext must be used within a GalleryContextProvider'
    );
  }
  return context;
}
