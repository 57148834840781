import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';

type SingleEntityBroadcaster<T = unknown> = (
  entity: T,
  uniqueId?: string
) => void;
export function makeSimpleBroadcastEntity<T = unknown>(
  messageType: DomainMessagesTypes,
  channel: ChannelNames
): SingleEntityBroadcaster<T> {
  return function singleEntityBroadcaster(entity, uniqueId): void {
    debugLog(
      `channel '${channel}'; messageType '${messageType}'; entity`,
      entity,
      `; uniqueId `,
      uniqueId
    );
    const broadcast = new BroadcastChannel(channel);
    broadcast.postMessage({
      data: entity,
      type: messageType,
      uniqueId,
    });
    broadcast.close();
  };
}

type ErrorBroadcaster = (
  error: unknown,
  uniqueId?: string,
  data?: any
) => void;
export function makeSimpleBroadcastError(
  messageType: DomainMessagesTypes,
  channel: ChannelNames
): ErrorBroadcaster {
  return function errorBroadcaster(error, uniqueId, data?: any): void {
    debugLog(
      `channel '${channel}'; messageType '${messageType}'; error`,
      error
    );
    const broadcast = new BroadcastChannel(channel);
    broadcast.postMessage({
      error,
      type: messageType,
      uniqueId,
      data,
    });
    broadcast.close();
  };
}
