import { RightPanelControls } from 'components/common/gallery/types';
import { Store } from 'hooks/createStore';
import { ProgressModel } from './types';

export class DocumentationProgressControls implements RightPanelControls {
  constructor(
    private visibleStore: Store<boolean>,
    private activeStore: Store<boolean>,
    private progressModelStore: Store<ProgressModel[]>
  ) {}

  getActivityStore(): Store<boolean> {
    return this.activeStore;
  }

  getVisibleStore(): Store<boolean> {
    return this.visibleStore;
  }

  getIndex(id: string): number {
    return this.progressModelStore
      .get()
      .findIndex((progress) => progress._id === id);
  }
}
