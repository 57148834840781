import { DomainMessagesTypes } from 'shared/domain/messages/message';
import * as dbProjects from '../../db/projects';
import * as dbOrganizations from '../../db/organizations';
import * as dbProjectsService from '../../db/projectsService';
import { Broadcaster } from '../types';
import { resyncCurrentProject } from './one';
import { projectEntityToModel } from 'shared/domain/project/mapping/toModel';

export const broadcastAllProjects: Broadcaster = async (broadcast) => {
  const status = await dbProjectsService.get();
  const projectEntities = await dbProjects.get();
  const hasAll = status?.isDownloaded;
  const projectModels = projectEntities.map((project) =>
    projectEntityToModel(project)
  );

  const data = {
    hasAll,
    projects: projectModels,
  };
  broadcast.postMessage({
    data,
    type: DomainMessagesTypes.allProjects,
  });
  resyncCurrentProject();
};
