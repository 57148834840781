import { useLayoutEffect } from 'react';
export default (): void => {
  useLayoutEffect((): (() => void) => {
    const originalStyle: string = window.getComputedStyle(
      document.body
    ).overflow;
    document.body.style.overflow = 'hidden';
    return (): string => (document.body.style.overflow = originalStyle);
  }, []);
};
