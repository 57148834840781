import { logWithRethrow } from 'serviceWorker/db/helpers';
import { UNAUTHORIZED } from 'shared/contants';
import { LogLevel } from 'shared/types/logger';
import { logout } from '../../api/config';

export function pull(
  // this is natively defined...
  // eslint-disable-next-line no-undef
  urlRequestInfo: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  return fetch(urlRequestInfo, init).then((response) => {
    if (response.status === UNAUTHORIZED) {
      logout();

      const error = new Error('Unauthorized.');
      logWithRethrow({
        errorObj: error,
        msg: error.message,
        logLevel: LogLevel.ERROR,
        additionalInfo: null,
      });
    }

    return response;
  });
}
