import { createStyles, makeStyles } from '@mui/styles';
import { Color } from 'shared/utils/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    card: {
      marginBottom: '3px',
      marginLeft: '3px',
      marginRight: '3px',
      '&.selected': {
        backgroundColor: '#f3e9f6',
        outline: `4px solid ${Color.purple}`,
      },
      '& *': {
        fontFamily: 'Archivo',
      },
    },
    cardHeader: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
      fontWeight: 600,
      fontFamily: 'Archivo',
      fontSize: '1rem',
    },

    cardDateFieldGrid: {
      fontSize: '0.75rem',
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '2fr 1fr 3fr',
      alignItems: 'center',
    },
    cardDateArrow: {
      fontSize: '1.5rem',
      height: '0.75rem !important',
      justifySelf: 'center',
    },
    cardDateValueContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem',
    },
    completionField: {
      fontSize: '0.75rem',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    deleted: {
      opacity: '0.65',
    },
  })
);
