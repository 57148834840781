import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';

function isIssueUpdateMessage(event: Message): boolean {
  return event?.type === DomainMessagesTypes.updatedEntity;
}

function listenToIssueEditMessage(
  successCallback: CallableFunction,
  errorCallback: CallableFunction
): BroadcastChannel {
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);

  broadcast.onmessage = (event: Message): void => {
    if (!isIssueUpdateMessage(event)) {
      return;
    }

    if (event.error) {
      return errorCallback(event.error);
    }

    if (event.data) {
      return successCallback(event.data);
    }
  };

  return broadcast;
}

export default listenToIssueEditMessage;
