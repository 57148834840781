import chartZoomIn from '../../assets/icons/chart_zoom_in_icon.svg';
import chartZoomInSelection from '../../assets/icons/chart_zoom_in_selection_icon.svg';
import chartZoomOut from '../../assets/icons/chart_zoom_out_icon.svg';
import chartMove from '../../assets/icons/chart_move_icon.svg';
import chartReset from '../../assets/icons/chart_reset_zoom_move_icon.svg';
import chartMenu from '../../assets/icons/chart_menu.svg';
import { ApexToolbarOptions } from './types';

function getToolbarOptions(filename: string): ApexToolbarOptions {
  return {
    show: true,
    offsetX: 3,
    offsetY: 0,
    tools: {
      download: `<img src="${chartMenu}" width="24">`,
      zoom: `<img src="${chartZoomInSelection}" width="24">`,
      zoomin: `<img src="${chartZoomIn}" width="24">`,
      zoomout: `<img src="${chartZoomOut}" width="24">`,
      pan: `<img src="${chartMove}" width="24">`,
      reset: `<img src="${chartReset}" width="24">`,
    },

    export: {
      csv: {
        filename,
        columnDelimiter: ',',
        headerCategory: 'category',
        headerValue: 'value',
        dateFormatter(timestamp: number): string {
          return new Date(timestamp).toDateString();
        },
      },
    },
    autoSelected: 'pan',
  };
}

export { chartMenu, getToolbarOptions };
