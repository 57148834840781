import { useCallback, useEffect } from 'react';
import { startSelectedProjectSynchronization } from 'shared/domain/project/startSelectedProjectSynchronization';

type SyncSelectedProject = {
  syncSelectedProject: () => void;
};

export function useSyncSelectedProject(
  withEffect?: boolean
): SyncSelectedProject {
  const syncSelectedProject = useCallback(() => {
    startSelectedProjectSynchronization();
  }, []);

  useEffect(() => {
    if (withEffect) {
      syncSelectedProject();
    }
  }, [syncSelectedProject, withEffect]);

  return {
    syncSelectedProject,
  };
}
