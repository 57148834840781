import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useBacktrack } from 'components/common/withBacktrack';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { Spinner } from 'components/core';
import {
  useEditUser,
  withEditUser,
} from 'components/dataCreationForms/user/withEditUser';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withSites } from 'components/dataProviders/withSites';
import { FormBottonButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { useCallback, memo } from 'react';
import { useIntl } from 'react-intl';
import { withCreateSite } from 'components/dataCreationForms/site/withCreateSite';
import { MemoUserForm } from 'components/user/form';
import { withUsers } from 'components/dataProviders/withUsers';

function EditUserWizardContainer(): React.ReactElement {
  const {
    submitForm,
    isPosting,
    existingValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useEditUser();
  const intl = useIntl();

  const { back } = useBacktrack();
  const formName = 'user';
  useFormCleanupOnUnmount(formName);
  const onCancel = useCallback(() => {
    back();
  }, [back]);
  // TODO we should not wait for sites.
  // Sometimes location fields renders with existing input, sometimes not.
  // I guess it has something to do with Stateful component logic which does not rerender location field
  // This happens in scenario when it initially received empty array and after this array with some content (once per few form renders)
  // Didn't have time to fix this, needs to be done carefully & without breaking other forms
  if (!existingValues || !existingValues?.sites?.length) {
    return (
      <Spinner
        reason={`EditUserWizardContainer Waiting for existing values: ${existingValues} or sites: ${existingValues?.sites}`}
      />
    );
  }
  return (
    <WithInputForm
      values={existingValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {intl.formatMessage({ id: 'edit_user_section' })}
      </Typography>
      <Divider />

      <Box sx={{ p: 3, mb: 8 }}>
        <MemoUserForm
          isDialog={false}
          editedUserId={existingValues._id}
          existingRoleId={existingValues.role?._id}
        />
      </Box>
      <FormBottonButtons
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoEditUserWizardContainer = memo(
  withFetcherReady(
    withProcessesData(
      withUsers(
        withSites(
          withCompanies(
            withContracts(
              withEditUser(withCreateSite(EditUserWizardContainer))
            )
          )
        )
      )
    )
  )
);
