import { DateTime } from 'luxon';
import { LabelledEntity } from 'shared/types/commonView';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';

export const generateNcrNumber = (
  createdAt: string,
  processCode: string,
  siteCode: string,
  slicedId: string
): string => {
  return `${
    DateTime.fromISO(createdAt).year
  }/${processCode}/${siteCode}/${slicedId}`;
};

export const generateLabelList = (
  list: LabelledEntity[] | string
): string => {
  if (!list) {
    return '';
  }

  if (typeof list === 'string') {
    return list;
  }

  return list.map((element) => element.label).join(', ');
};

export function singleIssueUrl(
  projectId: string,
  issueId: string
): string {
  return `/project/${projectId}/issue/${issueId}`;
}

export function createInspectionCode(
  inspectionCreatedAt: string,
  inspectionId: string,
  processCode: string
): string {
  return `${
    DateTime.fromISO(inspectionCreatedAt).year
  }/IN/${processCode}/${inspectionId.slice(-4)}`;
}
