import { ReactElement } from 'react';
import { SvgIconProps, SvgIcon } from '@mui/material';

export function DocumentationIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M30.6 42.75 17.45 38.1l-8.7 3.5q-1.2.55-2.325-.15-1.125-.7-1.125-2.1v-27.9q0-.9.5-1.575.5-.675 1.3-1.025l10.35-3.6 13.15 4.6 8.65-3.5q1.2-.5 2.35.175 1.15.675 1.15 2.075v28.25q0 .8-.525 1.375t-1.275.875Zm-1.7-4.8V13.3L19.1 10v24.65Zm3 0 6.85-2.25V10.65L31.9 13.3Zm-22.65-.7 6.85-2.6V10l-6.85 2.25ZM31.9 13.3v24.65ZM16.1 10v24.65Z'
      />
    </SvgIcon>
  );
}
