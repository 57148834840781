import { useMemo, useEffect, useCallback } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debugLog } from 'shared/logger/debugLog';
import { GetDocuments as GetDocumentsBy } from 'shared/domain/messages/document/eventMessages';

type GetDocuments = {
  getDocuments: (setLoading?: (loading: boolean) => void) => void;
};

export function useGetDocuments(
  query?: any,
  withAutoCall?: boolean,
  loading?: (loading: boolean) => void
): GetDocuments {
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const getDocuments = useCallback(
    (setLoading?: (loading: boolean) => void) => {
      debugLog('getDocuments query', query);
      if (query) {
        setLoading && setLoading(true);
        broadcast.postMessage({
          service: Services.DOCUMENTS,
          method: ServiceMethods.GET_BY_QUERY,
          data: {
            type: GetDocumentsBy.query,
            query,
          },
        });
      }
    },
    [broadcast, query]
  );

  useEffect(() => {
    if (withAutoCall) {
      getDocuments(loading);
    }
  }, [getDocuments, withAutoCall, loading, query]);

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getDocuments,
  };
}
