import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { initialState } from '../reducers/uiReducer';
import { HashMap } from 'shared/types/commonView';

export const uiMigrations = {
  0: (state: any): object => ({
    ...state,
    searchPhrase: '',
    issueView: {
      viewType: 0,
      tableHeaders: [
        'lp',
        'process',
        IssueFieldNames.title,
        'stage',
        IssueFieldNames.site,
        IssueFieldNames.creationDate,
        IssueFieldNames.finalCompletionDate,
        IssueFieldNames.estimatedCost,
        IssueFieldNames.finalCost,
      ],
    },
    filters: {
      ...state.filters,
      createdBy: [],
      creationDate: {
        fromDate: null,
        toDate: null,
      },
    },
  }),
  1: (state: any): object => ({
    ...state,
    searchPhrase: '',
    issueView: {
      viewType: 0,
      tableHeaders: [
        'lp',
        'process',
        IssueFieldNames.title,
        'stage',
        IssueFieldNames.site,
        IssueFieldNames.creationDate,
        IssueFieldNames.finalCompletionDate,
        IssueFieldNames.estimatedCost,
        IssueFieldNames.finalCost,
      ],
    },
    filters: {
      ...state.filters,
      createdBy: [],
      creationDate: {
        fromDate: null,
        toDate: null,
      },
    },
  }),
  2: (): object => initialState,
  3: (): object => initialState,
  4: (state: any): object => {
    const filters: HashMap<object> = {};

    Object.keys(state.filters).forEach((key) => {
      const filter = state.filters[key];

      const filterIsLabelledEntity =
        Array.isArray(filter) &&
        filter.every(
          (value) => value._id !== undefined && value.label !== undefined
        );

      if (filterIsLabelledEntity) {
        filters[key] = filter.map((value: any) => ({ _id: value._id }));
      } else {
        filters[key] = filter;
      }
    });

    return { ...state, filters };
  },
  5: (state: any): void => {
    let v5Filters = {
      sites: [],
      stages: [],
      subcontractors: [],
      createdBy: [],
      assignees: [],
      issueTypes: [],
      creationDate: {
        fromDate: null,
        toDate: null,
      },
      detectionDate: {
        fromDate: null,
        toDate: null,
      },
    };

    const projectId = localStorage.getItem('sw-config-projectId');
    if (projectId) {
      v5Filters = state.filters;
    }
    localStorage.setItem(
      `${projectId}_issue_filters`,
      JSON.stringify(v5Filters)
    );
    const newState = {
      ...state,
    };
    delete newState.filters;
    return newState;
  },
};
