import { useRef, useCallback, useMemo, MouseEvent } from 'react';
import { isAboveThreshold } from './isAboveThreshold';
import { debugLog } from 'shared/logger/debugLog';

export function useClickDetection(action: CallableFunction): {
  handleMouseDown: (e: MouseEvent) => void;
  handleMouseUp: (e: MouseEvent) => void;
  handleMouseMove: (e: MouseEvent) => void;
} {
  const clickTreshold = 8;
  const stateRef = useRef({
    movedAboveTreshold: false,
    startLocation: { x: -1, y: -1 },
    pressed: false,
  });

  const handleMouseDown = useCallback((e: MouseEvent) => {
    debugLog('mousedown');
    stateRef.current = {
      movedAboveTreshold: false,
      pressed: true,
      startLocation: { x: e.clientX, y: e.clientY },
    };
  }, []);

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      debugLog('mouseup');

      if (stateRef.current.movedAboveTreshold) {
        return;
      }
      action(e);
    },
    [action]
  );

  const handleMouseMove = useCallback((e: MouseEvent) => {
    if (!stateRef.current.pressed) {
      return;
    }
    if (
      isAboveThreshold(
        clickTreshold,
        stateRef.current.startLocation.x,
        e.clientX
      ) ||
      isAboveThreshold(
        clickTreshold,
        stateRef.current.startLocation.y,
        e.clientY
      )
    ) {
      stateRef.current.movedAboveTreshold = true;
    }
  }, []);

  return useMemo(() => {
    return {
      handleMouseDown,
      handleMouseUp,
      handleMouseMove,
    };
  }, [handleMouseDown, handleMouseMove, handleMouseUp]);
}
