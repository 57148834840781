import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import {
  makeSimpleBroadcastEntity,
  makeSimpleBroadcastError,
} from 'serviceWorker/repository/broadcasts/factory';

type MultiReportResponse = {
  type: 'multi';
  signedRequests: string[];
};
type SingleReportResponse = {
  type: 'single';
  signedRequest: string;
};

type MultiReportDownloadData = {
  type: 'multi';
  signedRequests: string[];
  statusCode: number;
  reportId: string;
};
type SingleReportDownloadData = {
  type: 'single';
  signedRequest: string;
  statusCode: number;
  reportId: string;
};

type Message = {
  error?: unknown;
  data: MultiReportDownloadData | SingleReportDownloadData;
};

type MessageError = {
  error: any;
  data: { reportId: string; statusCode: number };
};

function createMessage(
  responseData: MultiReportResponse | SingleReportResponse,
  statusCode: number,
  reportId: string
): Message | MessageError {
  if (statusCode === 200) {
    return {
      data: {
        statusCode,
        reportId,
        ...responseData,
      },
    };
  }

  return {
    error: responseData,
    data: {
      reportId,
      statusCode,
    },
  };
}
export function broadcastReportSignedRequest(
  reportId: string,
  reponseData: MultiReportResponse | SingleReportResponse,
  statusCode: number
): void {
  const message = createMessage(reponseData, statusCode, reportId);
  const broadcast = new BroadcastChannel(
    ChannelNames.signedRequestChannel
  );
  broadcast.postMessage({
    ...message,
    type: DomainMessagesTypes.signedRequest,
  });
  broadcast.close();
}

export const broadcastReportCreated = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdEntity,
  ChannelNames.reportChannel
);

export const broadcastCreateReportError = makeSimpleBroadcastError(
  DomainMessagesTypes.createdEntity,
  ChannelNames.reportChannel
);
