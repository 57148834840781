import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import cn from 'classnames';
import { useStyles } from 'components/common/forms/formStyles/styles';
import { signedUrls } from 'components/dataProviders/withOrganizations';
import { ButtonContained, ButtonText } from 'components/general';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { ProjectModel } from 'shared/domain/project/types/model';
import useIsMobile from 'hooks/useIsMobile';
import { ReactElement, memo } from 'react';
import { useIntl } from 'react-intl';
import { useSelectProjectFormStyles } from './styles';

type SelectProjectFormProps = {
  projects: ProjectModel[];
  organizationsMap: Map<string, OrganizationModel>;
  selected: string;
  handleChange: (_: any, value: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
  submitDisabled: boolean;
  canCancel?: boolean;
};

function SelectProjectForm({
  projects,
  selected,
  organizationsMap,
  handleChange,
  onSubmit,
  onCancel,
  submitDisabled,
  canCancel,
}: SelectProjectFormProps): ReactElement {
  const intl = useIntl();
  const formClasses = useStyles();
  const isMobile = useIsMobile();
  const specificClasses = useSelectProjectFormStyles({ isMobile });
  return (
    <Box className={specificClasses.root}>
      <RadioGroup
        value={selected || ''}
        onChange={handleChange}
        name='select-project-radios'
      >
        <Box className={specificClasses.radiosContainer}>
          {Object.values(projects).map((project: any) => {
            const org = organizationsMap.get(project.organizationId);
            const orgLogoSignedUrl =
              org?.logoUsedInPdfHeaders &&
              signedUrls.get(org.logoUsedInPdfHeaders);
            return (
              <Grid
                container
                key={project._id}
                className={specificClasses.radioContainer}
              >
                <Grid item xs={12} sm={8}>
                  <FormControl fullWidth>
                    <FormControlLabel
                      slotProps={{
                        typography: {
                          sx: {
                            width: '100%',
                            textOverflow: 'ellipsis',
                            display: 'block',
                          },
                          variant: 'h3',
                          noWrap: true,
                        },
                      }}
                      sx={{ width: '100%' }}
                      value={project._id}
                      control={<Radio color='primary' />}
                      label={project.name}
                    />

                    <Typography variant='h4' noWrap>
                      {org?.name}
                    </Typography>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  sx={{ display: { xs: 'none', sm: 'inline-flex' } }}
                  sm={4}
                >
                  {orgLogoSignedUrl && (
                    <Box
                      component={'img'}
                      sx={{
                        width: '100% ',
                        objectFit: 'contain',
                        maxWidth: '112px',
                        maxHeight: '56px',
                        marginLeft: 'auto',
                        marginRight: '32px',
                      }}
                      style={{ display: 'inline' }}
                      src={orgLogoSignedUrl}
                    />
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </RadioGroup>
      <Box
        className={cn(formClasses.submitFooter, formClasses.castShadow)}
      >
        <Divider className={cn(formClasses.fullWidth)} />
        <Box className={formClasses.footerPadding}>
          <Box>
            {canCancel && (
              <ButtonText onClick={onCancel}>
                {intl.formatMessage({ id: 'general_cancel' })}
              </ButtonText>
            )}
          </Box>
          <ButtonContained onClick={onSubmit} disabled={submitDisabled}>
            {intl.formatMessage({ id: 'general_select' })}
          </ButtonContained>
        </Box>
      </Box>
    </Box>
  );
}

export const MemoSelectProjectForm = memo(SelectProjectForm);
