import { makeStyles } from '@mui/styles';
import React from 'react';
import {
  MemoStatefulSingleSelect,
  MemoStatefulAutocompleteWithText,
} from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import { ButtonContained, ButtonText } from 'components/general';
import { IssueFormPresentationalProps } from './types';
import { CircularProgress, Divider } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      '&:not(:last-child)': {
        marginBottom: '16px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  submitButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export function IssueFormPresentational(
  props: IssueFormPresentationalProps
): React.ReactElement {
  const {
    processFieldProps,
    siteFieldProps,
    titleFieldProps,
    levelFieldProps,
    onCancel,
    onSubmit,
    isPosting,
  } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MemoStatefulSingleSelect {...processFieldProps} />
      <MemoStatefulSingleSelect {...siteFieldProps} />
      <MemoStatefulSingleSelect {...levelFieldProps} />
      <MemoStatefulAutocompleteWithText {...titleFieldProps} />
      <Divider />
      <div className={classes.submitButtons}>
        <ButtonText onClick={onCancel} disabled={isPosting}>
          <FormattedMessage id='general_cancel' />
        </ButtonText>
        <ButtonContained
          onClick={onSubmit}
          disabled={isPosting}
          endIcon={
            isPosting ? (
              <CircularProgress
                size={13}
                thickness={4}
                disableShrink
                color='secondary'
              />
            ) : undefined
          }
        >
          <FormattedMessage id='bottom_nav_create' />
        </ButtonContained>
      </div>
    </div>
  );
}
