import React, { useCallback, useEffect, useMemo, useRef } from 'react';

const DEBOUNCE_TIME = 250;
export function useSearch(
  afterSearchChange: any,
  initialSearchPhrase: string = '',
  isValidPhrase: (phrase: string) => boolean
): {
  searchChange: (phrase: string) => void;
  searchClear: () => void;
  searchPhrase: string;
} {
  const timeout = useRef<number | undefined>(undefined);
  const [searchPhrase, setSearchPhrase] = React.useState(
    initialSearchPhrase
  );
  const validSearchPhrase = useMemo(() => {
    return isValidPhrase(searchPhrase) ? searchPhrase : '';
  }, [isValidPhrase, searchPhrase]);

  const searchClear = useCallback(() => {
    setSearchPhrase('');
  }, []);

  useEffect(() => {
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(() => {
      afterSearchChange(validSearchPhrase);
    }, DEBOUNCE_TIME);
    return (): void => clearTimeout(timeout.current);
  }, [afterSearchChange, validSearchPhrase]);

  return {
    searchChange: setSearchPhrase,
    searchClear,
    searchPhrase,
  };
}
