import { useBacktrack } from 'components/common/withBacktrack';
import { withRedirect } from 'components/common/withRedirect';
import {
  useSelectedProject,
  withSelectedProject,
} from 'components/dataProviders/withSelectedProject';
import { Spinner } from 'components/core';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { MemoSelectProjectForm } from 'components/project/Select';
import { selectProject } from 'shared/domain/project/selectProject';
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';

import { HashMap } from 'shared/types/commonView';
import { transformArrayToHashMap } from 'shared/utils/array';
import { shouldAutoSelect, sortProjectNames } from './model';
import {
  selectionConfirmationDialog,
  changeConfirmationDialog,
} from './selectionConfirmationDialog';
import { CreateProjectButton } from '../wizard/createProjectButton';
import { useParams } from 'react-router-dom';
import { useProjects } from 'components/dataProviders/withProjects';
import { Box } from '@mui/material';
import { withCreateProject } from 'components/dataCreationForms/project/withCreateProject';
import { ProjectModel } from 'shared/domain/project/types/model';
import {
  useOrganizations,
  withOrganizations,
} from 'components/dataProviders/withOrganizations';
import { debugLog } from 'shared/logger/debugLog';
import { logError } from 'setup/logger/logError';
import { Fetch } from 'hooks/api/service';
import { FetchMethod } from 'shared/types/logger';
import { toMapSignedRequest } from 'views/issue/localization/helpers';

function SelectProjectView(): ReactElement {
  const { back } = useBacktrack();
  const createDialog = useDialog();
  const dispatch = useDispatch();
  const { _id: currentProjectId } = useSelector(projectDataSelector);
  const [projects, setProjects] = useState<HashMap<ProjectModel>>({});
  const [selected, setSelected] = useState<string>(currentProjectId);
  const { projectId: projectIdFromUrl } = useParams<{
    projectId?: string;
  }>();

  const { projects: projectsResponse, loading: projectsLoading } =
    useProjects();
  const { selectedProject, loading: selectedProjectLoading } =
    useSelectedProject();
  const { organizationsMap, loading: organizationsLoading } =
    useOrganizations();
  const loading =
    projectsLoading || selectedProjectLoading || organizationsLoading;

  const select = useCallback((): void => {
    if (selected) {
      const selectedProject = projects[selected];
      if (!currentProjectId) {
        selectionConfirmationDialog(
          createDialog,
          dispatch,
          selectedProject._id
        );
      } else {
        changeConfirmationDialog(
          createDialog,
          dispatch,
          selectedProject._id
        );
      }
    }
  }, [projects, selected, currentProjectId, createDialog, dispatch]);

  useEffect(() => {
    setProjects(transformArrayToHashMap(projectsResponse.items));
    setSelected(
      (selected) => selected || projectsResponse.items[0]?._id || ''
    );

    if (shouldAutoSelect(projectsResponse.total)) {
      selectProject(projectsResponse.items[0]);
    }
  }, [projectsResponse]);

  useEffect(() => {
    if (
      !currentProjectId &&
      !loading &&
      projectIdFromUrl &&
      projects[projectIdFromUrl]
    ) {
      setSelected(projectIdFromUrl);
      selectionConfirmationDialog(
        createDialog,
        dispatch,
        projectIdFromUrl
      );
    }
  }, [
    createDialog,
    currentProjectId,
    dispatch,
    projectIdFromUrl,
    projects,
    loading,
  ]);

  useEffect(() => {
    setSelected((prev) => {
      return selectedProject?._id || prev;
    });
  }, [selectedProject]);

  const handleChange = useCallback((_: any, value: string): void => {
    setSelected(value);
  }, []);

  const cancel = useCallback((): void => {
    if (currentProjectId) {
      back();
    }
  }, [currentProjectId, back]);

  const anyProjectSelected = Boolean(currentProjectId);

  if (loading) {
    return (
      <Spinner
        reason={`SelectProjectView projects loading: ${loading}, organizationsLoading: ${organizationsLoading}, projectsLoading: ${projectsLoading}, selectedProjectLoading: ${selectedProjectLoading}`}
      />
    );
  }

  debugLog('organizationsWithProjectsMap', organizationsMap);
  const disabled = !selected || selected === currentProjectId;

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f2f2f2',
      }}
    >
      <div
        style={{
          backgroundColor: '#f2f2f2',
          paddingTop: '2rem',
          paddingBottom: '1rem',
          alignSelf: 'center',
        }}
      >
        <CreateProjectButton />
      </div>

      <MemoSelectProjectForm
        projects={Object.values(projects).sort(sortProjectNames)}
        organizationsMap={organizationsMap}
        selected={selected}
        handleChange={handleChange}
        onSubmit={select}
        onCancel={cancel}
        submitDisabled={disabled}
        canCancel={anyProjectSelected}
      />
    </Box>
  );
}

export const SelectProject = withOrganizations(
  withSelectedProject(withRedirect(withCreateProject(SelectProjectView)))
);
