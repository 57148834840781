import { Button, ButtonProps } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type ButtonContainedProps = PropsWithChildren<ButtonProps>;

const ButtonContained = ({
  children,
  ...props
}: ButtonContainedProps): JSX.Element => {
  return (
    <Button {...props} disableRipple color='primary' variant='contained'>
      {children}
    </Button>
  );
};

export const MemoButtonContained = React.memo(ButtonContained);
export default ButtonContained;
