import { Spinner } from 'components/core';
import { findModelForEditing } from 'components/dataCreationForms/common';
import {
  makeHandleFormValidation,
  validate,
} from 'components/dataCreationForms/validation';
import { useCompanies } from 'components/dataProviders/withCompanies';
import { useContracts } from 'components/dataProviders/withContracts';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import {
  contractEditOnViewToContractEditModel,
  contractModelToContractOnView,
} from 'shared/domain/contract/mapping';
import { startContractEdit } from 'shared/domain/contract/startContractEdit';
import { ContractModel } from 'shared/domain/contract/types/model';
import { ContractEditOnView } from 'shared/domain/contract/types/view';
import { projectIdSelector } from 'helpers/misc';
import { ErrorPresentation } from 'helpers/validators';
import { useGetAllContracts } from 'hooks/useGetAllContracts';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { EditContractContext } from '../types';
import { getEditContractValidationSchema } from '../validation';
const formName = 'contract';
const SUBMIT_EVENT_TYPE = 'submit-contract';
const WithEditContractContext = createContext<
  EditContractContext | undefined
>(undefined);

function WithEditContract({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const history = useHistory();
  const intl = useIntl();
  const { getAll: getAllContracts } = useGetAllContracts();
  const fetchContracts = getAllContracts;
  const projectId = useSelector(projectIdSelector);

  const [isPosting, setIsPosting] = useState<boolean>(false);
  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const {
    contracts: { items: contracts },
    loading: loadingContracts,
  } = useContracts();
  const {
    companies: { items: companies },
    loading: loadingCompanies,
  } = useCompanies();
  const { id: currentContractId } = useParams<{ id: string }>();

  const [existingValues, setExistingValues] = useState<
    ContractEditOnView | undefined
  >(undefined);

  useEffect(() => {
    const existingContractModel = findModelForEditing(
      contracts,
      currentContractId
    );
    let contractOnView;
    if (existingContractModel) {
      contractOnView = contractModelToContractOnView(
        existingContractModel,
        companies
      );
      setExistingValues(contractOnView);
    }
  }, [contracts, intl, currentContractId, companies]);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const validationSchema = useMemo(() => {
    return getEditContractValidationSchema(contracts, currentContractId);
  }, [contracts, currentContractId]);

  const validateCreateContract = useCallback(
    (
      key: keyof ContractEditOnView,
      value: ContractEditOnView[keyof ContractEditOnView]
    ): ErrorPresentation => {
      return validate(key, value, validationSchema);
    },
    [validationSchema]
  );

  const handleFormValidation = useMemo(() => {
    return makeHandleFormValidation(validationSchema);
  }, [validationSchema]);

  const openDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );

  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setIsDialogOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: ContractEditOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const contractEditModel = contractEditOnViewToContractEditModel(
        values,
        projectId
      );
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          (message: ContractModel) => {
            broadcast.close();
            resolve(message);
            setIsDialogOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            // fetchContracts();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.contractChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithEditContract: Timeout on update.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startContractEdit(contractEditModel, uniqueId);
      })
        .then((contract: unknown): any => {
          if (contract && (contract as Identificable)._id) {
            history.push(`/contract/${(contract as Identificable)._id}`);
            return contract;
          }
        })
        .catch(() => {});
    },
    [dispatch, history, projectId]
  );

  const ctx: EditContractContext = useMemo(() => {
    return {
      open: isDialogOpen,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateCreateContract,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    validateCreateContract,
    handleFormValidation,
    existingValues,
    isPosting,
    isDialogOpen,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);
  if (loadingContracts || loadingCompanies) {
    return (
      <Spinner
        reason={`ContractWizard loadingContracts: ${loadingContracts} || loadingSites: ${loadingCompanies} || existingValues: ${existingValues}`}
      />
    );
  }
  return (
    <WithEditContractContext.Provider value={ctx}>
      {children}
    </WithEditContractContext.Provider>
  );
}

function useEditContract(): EditContractContext {
  const context = useContext(WithEditContractContext);
  if (context === undefined) {
    throw new Error(
      'useEditContract must be used within an EditContractContext'
    );
  }
  return context;
}

const withEditContract =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditContract>
      <Component {...props} />
    </WithEditContract>
  );

export { WithEditContract, useEditContract, withEditContract };
