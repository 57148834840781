import { GetInspectionTemplateCustomEvent } from 'serviceWorker/api/types';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as inspectionTemplates from 'serviceWorker/db/inspectionTemplates';
import * as inspectionTemplatesService from 'serviceWorker/db/inspectionTemplatesService';
import { getOne as getPermissionOnProject } from 'serviceWorker/db/permissions';
import { debounce } from 'serviceWorker/helpers/debounce';
import {
  fetchInspectionTemplates,
  fetchUpdatedInspectionTemplates,
} from './fetchInspectionTemplates';

import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  broadcastAllInspectionTemplates,
  broadcastInspectionTemplate,
} from 'serviceWorker/broadcasts/inspectionTemplates';
import { UserRole } from 'shared/types/userRole';
import { getSelectedProject } from '../selectedProject';

import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}
const pullInspectionTemplates: Pull = makePullEntity({
  fetch: fetchInspectionTemplates,
  channelName: ChannelNames.inspectionTemplateChannel,
  entityName: 'inspectionTemplates',
  entityRepository: inspectionTemplates,
  entityService: inspectionTemplatesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});
const pullInspectionTemplatesHandler = makePullHandler(
  config,
  inspectionTemplates,
  inspectionTemplatesService,
  pullInspectionTemplates,
  clearBroadcasts
);

const synchronizeInspectionTemplates = makeSynchronizeEntity({
  configRepository: config,
  entityService: inspectionTemplatesService,
  entityRepository: inspectionTemplates,
  pullEntityHandler: pullInspectionTemplatesHandler,
  fetchUpdatedEntities: fetchUpdatedInspectionTemplates,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'inspectionTemplates',
  channelName: ChannelNames.inspectionTemplateChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
  canSyncronize: canSyncTemplates,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeInspectionTemplates.bind(
      null,
      broadcastAllInspectionTemplates
    ),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncInspectionTemplates,
    syncDebounced
  );
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    DomainMessagesTypes.getInspectionTemplate,
    function onGetInspectionTemplate(
      e: GetInspectionTemplateCustomEvent
    ): void {
      const broadcastSingleTemplate = broadcastInspectionTemplate.bind(
        null,
        e
      );

      synchronizeInspectionTemplates(broadcastSingleTemplate);
    }
  );
};

async function canSyncTemplates(): Promise<boolean> {
  const selectedProject = await getSelectedProject();
  if (!selectedProject) {
    return false;
  }
  const currentProjectPermissions = await getPermissionOnProject(
    selectedProject._id
  );
  if (!currentProjectPermissions) {
    return false;
  }

  return currentProjectPermissions.role !== UserRole.standard;
}
