import {
  CompanyModel,
  InvolvementType,
} from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';

export function findCompanyModelForEditing(
  companies: CompanyModel[],
  currentCompanyId: string | undefined
): CompanyModel | undefined {
  const companyModel = companies.find(
    (comp) => comp._id === currentCompanyId
  );
  return companyModel;
}

export function shouldDisableInvolvement(
  company: CompanyOnView | undefined,
  companies: CompanyModel[]
): boolean {
  if (!company) {
    return false;
  }

  if (
    company.projectInvolvementType._id === InvolvementType.SUBCONTRACTOR
  ) {
    return false;
  }

  const investors = [];
  const generalContractors = [];
  companies.forEach((company) => {
    if (company.projectInvolvementType === InvolvementType.INVESTOR) {
      // react 18 types
      // @ts-ignore
      return investors.push(company);
    }
    if (
      company.projectInvolvementType === InvolvementType.GENERAL_CONTRACTOR
    ) {
      // react 18 types
      // @ts-ignore
      return generalContractors.push(company);
    }
  });

  if (
    company.projectInvolvementType._id === InvolvementType.INVESTOR &&
    investors.length === 1
  ) {
    return true;
  }
  if (
    company.projectInvolvementType._id ===
      InvolvementType.GENERAL_CONTRACTOR &&
    generalContractors.length === 1
  ) {
    return true;
  }

  return false;
}
