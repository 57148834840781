import React, { Fragment, ReactElement } from 'react';

import { useIntl } from 'react-intl';
import { useStyles } from './styles';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import TextField from '@mui/material/TextField';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import CheckIcon from '@mui/icons-material/Check';

import Divider from '@mui/material/Divider';

import classNames from 'classnames';
import { MemoChecklistItemHeaderText } from './ChecklistItemHeaderText';
import { ChecklistItemPresentationalProps } from './types';
import { add0ToSingleDigitNumber } from './helpers';
import { CHECKLIST_PREFIX_DOM_ELEMENT_ID } from 'views/template/wizard/form/helpers';
import { GraphicUploader } from 'components/common/graphicUploader';
import useIsMobile from '../../../hooks/useIsMobile';
import { ButtonIcon } from 'components/general';

const MemoTextField = React.memo(TextField);

export function Presentational(
  props: ChecklistItemPresentationalProps
): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const intl = useIntl();

  const {
    index,
    errors,
    checklistItem,
    defaultValues,
    indexPlusOne,
    expanded,
    handleExpanded,
    summaryHandler,
    descriptionHandler,
    removeHandler,
    // this is required to detect update when leaving drawing mode
    // eslint-disable-next-line
    graphicUploader,
  } = props;

  return (
    <Fragment>
      <div className={classes.contentBoxAccordion}>
        <Accordion
          id={`${CHECKLIST_PREFIX_DOM_ELEMENT_ID}${index}`}
          className={classes.hustroTemplateAccordion}
          expanded={expanded}
          onChange={handleExpanded}
        >
          <AccordionSummary
            id={`checklistItem${index}`}
            expandIcon={<ExpandMoreIcon />}
            aria-label='Expand'
          >
            <div className='accordionTitle'>
              <span className={classes.accordionIndex}>
                {add0ToSingleDigitNumber(indexPlusOne)}
              </span>
              <DragHandleIcon className={classes.dragFill} />
              <MemoChecklistItemHeaderText
                checklistItemTitle={checklistItem.summary}
              />
            </div>
            <div className={classes.checkIconContainer}>
              <CheckIcon className={classes.checkIcon} />
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.flexColumn}>
              <MemoTextField
                multiline
                className={classNames(
                  classes.standardBorderRadius,
                  classes.fullWidth,
                  classes.checklistItemSummaryMargin
                )}
                label={intl.formatMessage({
                  id: 'inspection_template_wizard_summary',
                })}
                variant='outlined'
                size='small'
                onChange={summaryHandler}
                required
                error={Boolean(errors.summary)}
                helperText={
                  errors.summary &&
                  intl.formatMessage(
                    { id: errors.summary.helperText.id },
                    errors.summary.values
                  )
                }
                defaultValue={defaultValues?.summary}
              />
              <MemoTextField
                multiline
                className={classNames(
                  classes.standardBorderRadius,
                  classes.fullWidth,
                  classes.checklistItemDescriptionMargin
                )}
                label={intl.formatMessage({
                  id: 'inspection_template_wizard_specified_applied_standard_description',
                })}
                variant='outlined'
                size='small'
                onChange={descriptionHandler}
                error={Boolean(errors.description)}
                helperText={
                  errors.description &&
                  intl.formatMessage(
                    {
                      id: errors.description.helperText.id,
                    },
                    errors.description.values
                  )
                }
                defaultValue={defaultValues?.description}
              />
              <GraphicUploader
                {...checklistItem.graphicUploader}
                classes={{
                  buttonContainer: classes.checklistItemGraphicUploader,
                }}
              />
              <div className={classes.deleteIconContainer}>
                <ButtonIcon aria-label='delete' onClick={removeHandler}>
                  <DeleteIcon />
                </ButtonIcon>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider className={classes.divider} />
    </Fragment>
  );
}

export const MemoPresentational = React.memo(Presentational);
