import { DocumentationFields } from 'components/dataCreationForms/withDocumentationForm/types';
import {
  ErrorPresentation,
  requiredValidation,
  stringMinMaxValidation,
  stringMaxValidation,
} from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';

function pass(...args: any[]): ErrorPresentation {
  return undefined;
}
type DocumentationFormValidation = {
  [key in keyof DocumentationFields]: (value: any) => ErrorPresentation;
};
const validation: DocumentationFormValidation = {
  name: nameFieldValidation,
  number: numberFieldValidation,
  levels: pass,
};

export function validate(
  key: keyof DocumentationFields,
  value: DocumentationFields[keyof DocumentationFields]
): ErrorPresentation {
  return validation[key](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: DocumentationFields
): boolean {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = validate(key as keyof DocumentationFields, value);
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}

function nameFieldValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) ||
    stringMinMaxValidation(FieldSize.CUSTOM_DOCUMENTATION_NAME, value)
  );
}

function numberFieldValidation(value: any): ErrorPresentation {
  return value === undefined
    ? undefined
    : stringMaxValidation(FieldSize.S, value);
}
