import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  ProjectCreateCustomEventDetail,
  ProjectDeleteCustomEventDetail,
  ProjectEditCustomEventDetail,
  ProjectRestoreCustomEventDetail,
} from 'shared/domain/messages/project/eventMessages';
import { debounceNext } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { GetProjectEventDetail } from './types';

const pull = debounceNext((): void => {
  self.dispatchEvent(new Event(RepositoryMessagesTypes.getAllProjects));
}, 1000);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetProjectEventDetail>(
      RepositoryMessagesTypes.getProject,
      {
        detail: { id: e.id },
      }
    )
  );
};

const create = (e: ProjectCreateCustomEventDetail): void => {
  debugLog('CREATE PROJECT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<ProjectCreateCustomEventDetail>(
      RepositoryMessagesTypes.addProject,
      {
        detail: {
          projectCreateModel: e.projectCreateModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const edit = (e: ProjectEditCustomEventDetail): void => {
  debugLog('EDIT PROJECT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<ProjectEditCustomEventDetail>(
      RepositoryMessagesTypes.editProject,
      {
        detail: {
          projectEditModel: e.projectEditModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const remove = (e: ProjectDeleteCustomEventDetail): void => {
  debugLog('DELETE PROJECT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<ProjectDeleteCustomEventDetail>(
      RepositoryMessagesTypes.deleteProject,
      {
        detail: {
          projectId: e.projectId,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const restore = (e: ProjectRestoreCustomEventDetail): void => {
  debugLog('RESTORE PROJECT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<ProjectRestoreCustomEventDetail>(
      RepositoryMessagesTypes.restoreProject,
      {
        detail: {
          projectId: e.projectId,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

export default { pull, getOne, create, edit, remove, restore };
