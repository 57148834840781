import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
  ServiceMethods,
  Services,
  isCreateOrUpdate,
} from 'shared/domain/messages/message';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { Fetch } from 'hooks/api/service';
import { useGetAllOrganizations } from 'hooks/useGetAllOrganizations';
import React, {
  PropsWithChildren,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { useAuth0 } from 'services/auth0/react-auth0.spa';
import { debugLog } from 'shared/logger/debugLog';
import { FetchMethod } from 'shared/types/logger';
import { toMapSignedRequest } from 'views/issue/localization/helpers';
import { OrganizationsContextType } from './types';
const SYNC_INTERVAL_WHEN_LOADING = 25000;
export const signedUrls = new Map<string, string>();

async function obtainLogoSignedUrl(
  orgId: string,
  logoId: string
): Promise<string | undefined> {
  if (signedUrls.has(logoId)) {
    return signedUrls.get(logoId);
  }
  const url = `/logo/${logoId}/download`;

  try {
    const documentationResponse: {
      signedRequest: string;
    } = toMapSignedRequest(
      await Fetch[FetchMethod.GET_ASSET](
        url,
        undefined,
        {},
        {
          urlBuilder: (apiUrl, url) =>
            `${apiUrl}/v2/client/${orgId}${url}`,
        }
      )
    );
    signedUrls.set(logoId, documentationResponse.signedRequest);
    return documentationResponse.signedRequest;
  } catch (e) {
    return undefined;
  }
}

function startOrganizationsPulling(): void {
  debugLog('startOrganizationsPulling');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

  broadcast.postMessage({
    service: Services.ORGANIZATIONS,
    method: ServiceMethods.PULL,
    source: 'startOrganizationsPulling',
  });
  broadcast.close();
}

const OrganizationsContext = React.createContext<
  OrganizationsContextType | undefined
>(undefined);

const WithOrganizations: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [organizationsMap, _setOrganizationsMap] = useState<
    Map<string, OrganizationModel>
  >(new Map());

  function setOrganizationsMap(organizations: OrganizationModel[]): void {
    const orgMap = new Map();
    organizations.forEach(async (org) => {
      org.logoUsedInPdfHeadersSignedUrl =
        org.logoUsedInPdfHeaders &&
        (await obtainLogoSignedUrl(org._id, org.logoUsedInPdfHeaders));
      orgMap.set(org._id, org);
    });
    _setOrganizationsMap(orgMap);
  }

  const [loading, setLoading] = useState<boolean>(true);

  const { isAuthenticated } = useAuth0();

  const { getAll: getAllOrganizations } = useGetAllOrganizations();

  useEffect(() => {
    if (isAuthenticated) {
      organizationsMap.forEach(async (organization) => {
        organization.logoUsedInPdfHeaders &&
          obtainLogoSignedUrl(
            organization._id,
            organization.logoUsedInPdfHeaders
          );
      });
    }
  }, [isAuthenticated, organizationsMap]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(
      ChannelNames.organizationChannel
    );
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }
      if (
        event.type === DomainMessagesTypes.state &&
        event.data?.isDownloaded
      ) {
        getAllOrganizations();
      }
    };

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, [getAllOrganizations]);
  useEffect(() => {
    const broadcast = new BroadcastChannel(
      ChannelNames.organizationChannel
    );
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (event.data && isCreateOrUpdate(event)) {
        startOrganizationsPulling();
      }
    };

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const broadcast = new BroadcastChannel(
      ChannelNames.organizationChannel
    );
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (
        event.data &&
        event.type === DomainMessagesTypes.allOrganizations
      ) {
        const hasData = event.data.hasAll;
        if (hasData) {
          setOrganizationsMap(event.data.items);
          setLoading(false);
        }
      }
    };
    // broadcast.close();

    // broadcast.postMessage({
    //   type: MessagesTypes.getState,
    // });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, []);

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (loading && isAuthenticated) {
      debugLog('start organization sync');
      setTimeout(startOrganizationsPulling, 1000);
      interval = setInterval(() => {
        startOrganizationsPulling();
      }, SYNC_INTERVAL_WHEN_LOADING);
    }

    return (): void => {
      clearInterval(interval);
    };
  }, [loading, isAuthenticated]);

  const ctx: OrganizationsContextType = {
    loading,
    organizationsMap,
  };

  return (
    <OrganizationsContext.Provider value={ctx}>
      {children}
    </OrganizationsContext.Provider>
  );
};

const withOrganizations =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithOrganizations>
      <Component {...props} />
    </WithOrganizations>
  );

function useOrganizations(): OrganizationsContextType {
  const context = React.useContext(OrganizationsContext);
  if (context === undefined) {
    throw new Error(
      'useOrganizations must be used within a OrganizationsContextProvider'
    );
  }
  return context;
}

export { WithOrganizations, useOrganizations, withOrganizations };
