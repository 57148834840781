import { DialogOptions } from 'components/core/Dialog/common/Dialog';
import { LevelChangeWarnings } from 'shared/domain/documentation/documentationAsMapWarnings';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { DocumentationFile } from '../../dataProviders/withDocumentationController/types';

export function showWarningsAsDialog(
  createDialog: (options: DialogOptions) => Promise<void>,
  warnings: LevelChangeWarnings,
  currentFile: DocumentationFile
): Promise<{ warningsConfirmed: boolean }> {
  if (!warnings.replacements.length && !warnings.removals.length) {
    return Promise.resolve({ warningsConfirmed: true });
  }

  return createDialog({
    catchOnCancel: true,
    title: (
      <FormattedMessage id='dialog_confirmation_level_change_warning' />
    ),
    description: (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <FormattedMessage id='dialog_confirmation_discard_changes_title_web' />
        </div>
        {warnings.replacements.map((level) =>
          toReplacementMessage(currentFile, level)
        )}
        {warnings.replacements.length ? (
          <div>
            <FormattedMessage id='warning_map_replacement_generic' />
          </div>
        ) : null}
        {warnings.removals.length ? (
          <RemovalMessage levels={warnings.removals} />
        ) : null}
      </div>
    ),
  })
    .then(() => ({ warningsConfirmed: true }))
    .catch(() => ({ warningsConfirmed: false }));
}

function RemovalMessage({
  levels,
}: {
  levels: LabelledEntity[];
}): ReactElement {
  return (
    <div>
      <FormattedMessage
        id='warning_map_removal'
        values={{
          levelString: levels.map((lvl) => lvl.label).join(', '),
          levelsLength: levels.length,
        }}
      />
    </div>
  );
}

function toReplacementMessage(
  currentFile: DocumentationFile,
  level: LabelledEntity
): ReactElement {
  return (
    <div>
      <FormattedMessage
        id='warning_map_replacement_specific'
        values={{
          levelLabel: level.label,
          title: currentFile.title,
          extension: currentFile.data?.extension || '',
        }}
      />
    </div>
  );
}
