import { ChannelListenerContextType } from 'components/broadcastChannelListeners/channelListener/channelListenerFactory';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useCallback } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { createEntityPromise } from './createEntityPromise';
import { BroadcastChannel } from 'broadcast-channel';
import { debugLog } from 'shared/logger/debugLog';

type MakeUseGetAllProps = {
  useChannelListener: () => ChannelListenerContextType;
  postMessageChannel: ChannelNames;
  postMessageType: DomainMessagesTypes;
  isMatchingMessage: (message: Message) => boolean;
  entityName: string;
};
export function makeUseGetAll<T>({
  useChannelListener,
  postMessageChannel,
  postMessageType,
  isMatchingMessage,
  entityName,
}: MakeUseGetAllProps) {
  return function useGetAll(): {
    getAll: () => Promise<{ items: T[] }>;
  } {
    const { subscribe } = useChannelListener();

    const getAll = useCallback(() => {
      const broadcast = new BroadcastChannel(postMessageChannel);
      debugLog('getting all', postMessageType, postMessageChannel);
      return createEntityPromise<{ items: T[] }>({
        subscribe,
        isMatchingMessage,
        postMessage: function postGetAll() {
          broadcast.postMessage({
            type: postMessageType,
            source: 'makeUseGetAll',
          });
          broadcast.close();
        },
        entityName,
      });
    }, [subscribe]);

    return {
      getAll,
    };
  };
}
