import {
  FilterDateType,
  FilterGeneral,
  IssueFilters,
} from 'components/dataProviders/withIssueFilters/types';

type ActiveFilterType = {
  name: string;
  value: FilterGeneral[] | FilterDateType;
};

// ui reducer, too small for breaking it apart (for now) UiReducerType
export const getSearchPhrase = (state: any): string => {
  return state.ui.searchPhrase;
};

export const activeFiltersSelector = (
  filters: IssueFilters
): ActiveFilterType[] => {
  const filtersTable: ActiveFilterType[] = Object.keys(filters).map(
    (f) => ({
      name: f,
      value: filters[f],
    })
  );

  return filtersTable.reduce((acc, filter) => {
    const arrayAndLength =
      Array.isArray(filter.value) && filter.value.length;

    const objectAndLength =
      filter.value instanceof Object &&
      Object.values(filter.value).filter(Boolean).length;

    if (arrayAndLength || objectAndLength) {
      acc.push(filter);
    }

    return acc;
  }, [] as ActiveFilterType[]);
};
