import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceData } from 'serviceWorker/db/service';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { retry } from 'shared/utils/retry';
import { LogLevel } from 'shared/types/logger';
import { MAX_CONCURENT_PULLS } from '../issues/concurentPullConstants';
import {
  HeadersData,
  NormalizedPagedEntityResponseData,
  Pull,
} from './types';

const MAX_CONCURENT_PULL_PAGE_SIZE = 400;

type MakePullPagedEntityProps = {
  fetchPaged: (
    data: HeadersData,
    abortController: AbortController,
    offset: number,
    size: number
  ) => Promise<any> | undefined;
  channelName: ChannelNames;
  entityRepository: any;
  entityService: any;
  entityName: string;
  finishedServiceStateFactory: (
    total: number,
    syncKey: string
  ) => Partial<ServiceData>;
  emitAllBroadcasts: () => void;
};
async function processFetch(
  fetchPaged,
  entityService: any,
  entityRepository: any,
  data: any,
  abortController: any,
  page: { offset: number; size: number },
  activeFetches: any[]
): Promise<any> {
  activeFetches.push(true);
  try {
    const response = await retry(async () => {
      const res = await fetchPaged(
        data,
        abortController,
        page.offset,
        page.size
      );
      if (!res) {
        throw new Error('No response.');
      }
      return res;
    }, 2);
    activeFetches.pop();
    if (abortController.signal.aborted) {
      return;
    }
    await entityService.add({
      isDownloading: true,
    });

    if (abortController.signal.aborted) {
      return;
    }
    const start = Date.now();
    await entityRepository.addBatch(response.items);
    const end = Date.now();
    debugLog(
      `entityRepository paged insert of ${response.items.length} items time:`,
      end - start
    );
  } catch (e) {
    activeFetches.pop();
    throw e;
  }
}

function processFetches(
  fetchPaged,
  entityService,
  entityRepository,
  data: any,
  abortController: AbortController,
  fetchesToDo: { offset: number; size: number }[],
  activeFetches: any[],
  resolve: (value?: unknown) => void,
  reject: (error: Error) => void
): void {
  if (abortController.signal.aborted) {
    return reject(new Error('Aborted'));
  }

  if (!fetchesToDo.length && !activeFetches.length) {
    return resolve();
  }

  while (
    fetchesToDo.length &&
    activeFetches.length < MAX_CONCURENT_PULLS
  ) {
    const page = fetchesToDo.pop()!;
    processFetch(
      fetchPaged,
      entityService,
      entityRepository,
      data,
      abortController,
      page,
      activeFetches
    )
      .then(() => {
        processFetches(
          fetchPaged,
          entityService,
          entityRepository,
          data,
          abortController,
          fetchesToDo,
          activeFetches,
          resolve,
          reject
        );
      })
      .catch((e) => reject(e));
  }
}

export function makePullPagedEntity({
  fetchPaged,
  channelName,
  entityRepository,
  entityService,
  entityName,
  finishedServiceStateFactory,
  emitAllBroadcasts,
}: MakePullPagedEntityProps): Pull {
  return async (data, abortController): Promise<void> => {
    const firstPageParams = {
      size: 100,
      offset: 0,
    };
    const pagesAmount = 1;

    const promise = fetchPaged(
      data,
      abortController,
      firstPageParams.offset,
      firstPageParams.size
    );
    if (!promise) {
      return;
    }

    await entityService.add({
      total: undefined,
      isDownloading: true,
      isDownloaded: false,
      syncKey: undefined,
    });
    return promise
      .then(async (res: NormalizedPagedEntityResponseData) => {
        if (abortController.signal.aborted) {
          return;
        }
        if (!res) {
          await entityService.add({
            isDownloading: false,
          });
          return;
        }
        await entityService.add({
          total: res.items.length,
          isDownloading: true,
        });

        const broadcast = new BroadcastChannel(channelName);
        broadcast.postMessage({
          data: { isDownloading: true },
          type: DomainMessagesTypes.state,
        });

        if (abortController.signal.aborted) {
          return;
        }
        await entityRepository.addBatch(res.items);
        const syncKey = res.syncKey;

        const total = await entityRepository.quantity();
        if (res.hasMore && (total ?? 0) < res.totalCount) {
          const estimatedPulls = Math.max(
            1,
            Math.floor(
              (res.totalCount - firstPageParams.size) /
                MAX_CONCURENT_PULL_PAGE_SIZE
            ) + 1
          );

          await pullPagesConcurrentlyWithLimit(
            fetchPaged,
            entityRepository,
            entityService,
            emitAllBroadcasts,
            finishedServiceStateFactory,
            data,
            abortController,
            {
              ...firstPageParams,
              offset:
                firstPageParams.offset +
                firstPageParams.size * pagesAmount,
              size: MAX_CONCURENT_PULL_PAGE_SIZE,
              firstSyncKey: syncKey,
              pagesAmount: estimatedPulls,
            }
          );
        } else {
          const entityServiceData = finishedServiceStateFactory(
            res.items.length,
            syncKey
          );

          // for some reason getting all data makes IndexedDB faster next time
          const start = Date.now();
          debugLog(`start ${entityName} getAll:`, start);
          await entityRepository.get();
          await entityService.add(entityServiceData);
          const end = Date.now();
          debugLog(`entityRepository ${entityName} time:`, end - start);
          emitAllBroadcasts();
        }
      })
      .catch(async (e: any) => {
        swLog(
          `Problem occured when fetching ${entityName}`,
          LogLevel.ERROR,
          e,
          null
        );
        await entityRepository.clear();
        await entityService.add({
          total: undefined,
          isDownloading: false,
          isDownloaded: false,
          syncKey: undefined,
        });
      });
  };
}

type PagingProps = {
  offset: number;
  size: number;
  firstSyncKey: string;
  pagesAmount?: number;
};

async function pullPagesConcurrentlyWithLimit(
  fetchPaged: any,
  entityRepository: any,
  entityService: any,
  emitBroadcasts: () => void,
  createFinishedEntityServiceData: (
    count: number,
    firstSyncKey: string
  ) => any,
  data: any,
  abortController: AbortController,
  props: PagingProps
): Promise<void> {
  const activeFetches: any[] = [];

  const firstCallSyncKey = props.firstSyncKey as string;
  const params = {
    offset: props.offset,
    size: props.size,
  };

  const fetchesToDo = Array.from({ length: props.pagesAmount || 1 })
    .map((_, multiplier) => {
      return {
        offset: params.offset + multiplier * params.size,
        size: params.size,
      };
    })
    .reverse();

  await new Promise((resolve, reject) => {
    processFetches(
      fetchPaged,
      entityService,
      entityRepository,
      data,
      abortController,
      fetchesToDo,
      activeFetches,
      resolve,
      reject
    );
  });

  const start = Date.now();
  await entityRepository.get();
  const end = Date.now();
  debugLog('pullPagesConcurrentlyWithLimit getAll time: ', end - start);
  const count = await entityRepository.quantity();
  const finishedEntityServiceData = createFinishedEntityServiceData(
    count,
    firstCallSyncKey
  );
  await entityService.add(finishedEntityServiceData);
  emitBroadcasts();
}
