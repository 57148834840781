import React, { useRef, useEffect, ReactElement, Fragment } from 'react';
import { ResizeObserverChange } from 'views/inspections/table/types';
import { MemoButtonPlusCreate } from 'components/general/ButtonPlusCreate';
import TableSkeleton from 'components/table/skeleton';
import { useStyles } from './styles';
import { resize } from 'views/inspections/table/helpers';
import { TableAnchorProps } from './types';
import { WithLoadingLog } from 'components/general/loaders/Spinner/Spinner';

function TableAnchor(props: TableAnchorProps): ReactElement {
  const tableWrapperRef = useRef<HTMLDivElement | null>(null);
  const {
    canCreate,
    creationAction,
    loading,
    headerWithCreation,
    TableComponent,
    SearchInput,
    CreationComponent,
    children,
  } = props;
  const classes = useStyles();
  const [width, setWidth] = React.useState(0);
  const [height, setHeight] = React.useState(0);

  useEffect(() => {
    const ResizeObserver = (window as any).ResizeObserver;
    if (!ResizeObserver) return;
    if (!tableWrapperRef.current) return;
    const theRef = tableWrapperRef.current;
    let timeout: ReturnType<typeof setTimeout>;
    const resizeObserver = new ResizeObserver(
      (changes: ResizeObserverChange[]) => {
        clearTimeout(timeout);
        timeout = setTimeout(
          () => resize(changes, setWidth, setHeight, tableWrapperRef),
          100
        );
      }
    );

    resizeObserver.observe(theRef);
    resizeObserver.observe(document.body);
    return (): void => {
      clearTimeout(timeout);
      resizeObserver.unobserve(theRef);
      resizeObserver.unobserve(document.body);
    };
  }, []);

  useEffect(() => {
    setWidth(tableWrapperRef.current?.clientWidth || 0);
    setHeight(tableWrapperRef.current?.clientHeight || 0);
  }, []);

  return (
    <Fragment>
      {Boolean(headerWithCreation) && (
        <div className={classes.head}>
          {CreationComponent ? (
            <CreationComponent
              headerWithCreation={headerWithCreation}
              canCreate={canCreate}
              creationAction={creationAction}
            />
          ) : (
            <MemoButtonPlusCreate
              id={headerWithCreation!.id}
              textId={headerWithCreation!.textId}
              dataQaId={headerWithCreation!.dataQaId}
              canCreate={canCreate}
              creationAction={creationAction}
            />
          )}
          {SearchInput !== undefined && <SearchInput />}
        </div>
      )}
      {children}
      <div className={classes.relativeWrapper}>
        <div className={classes.absoluteWrapper} ref={tableWrapperRef}>
          {!loading ? (
            <TableComponent width={width} height={height} />
          ) : (
            <WithLoadingLog reason='TableAnchor loading'>
              <TableSkeleton />
            </WithLoadingLog>
          )}
        </div>
      </div>
    </Fragment>
  );
}

export const MemoTableAnchor = React.memo(TableAnchor);
