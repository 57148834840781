import Dexie from 'dexie';
import { CompanyEntity } from 'shared/domain/company/types/entity';
import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { OrganizationEntity } from 'shared/domain/organization/types/entity';
import { ProjectEntity } from 'shared/domain/project/types/entity';
import { SiteEntity } from 'shared/domain/site/types/entity';
import { CorrectiveActionTypeEntity } from 'serviceWorker/repository/correctiveActionType/entity';
import { DirectoryEntity } from 'serviceWorker/repository/directory/entity';
import { DocumentEntity } from 'serviceWorker/repository/document/entity';
import { DocumentationEntity } from 'serviceWorker/repository/documentation/entity';
import { EnvironmentalAspectEntity } from 'serviceWorker/repository/environmentalAspect/entity';
import { HazardCategoryEntity } from 'serviceWorker/repository/hazardCategory/entity';
import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import { InspectionTemplateEntity } from 'serviceWorker/repository/inspectionTemplate/entity';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { LevelEntity } from 'shared/domain/level/types/entity';
import { UserEntity } from 'shared/domain/user/types/entity';
import { VisibleFieldEntity } from 'serviceWorker/repository/visibleField/visibleFieldEntity';
import { WorktypeEntity } from 'serviceWorker/repository/worktype/entity';
import { Identificable } from 'shared/types/commonView';
import { ContractEntity } from 'serviceWorker/repository/contract/entity';
import { ChartDataEntity } from './analytics';
import { ChartFiltersEntity } from './chartFilters';
import { CompaniesServiceData } from './companiesService';
import { ConfigData } from './config';
import { ContractsServiceData } from './contractsService';
import { CorrectiveActionTypesServiceData } from './correctiveActionTypesService';
import { DirectoriesServiceData } from './directoriesService';
import { DocumentationsServiceData } from './documentationsService';
import { EnvironmentalAspectsServiceData } from './environmentalAspectsService';
import { HazardCategoriesServiceData } from './hazardCategoriesService';
import { InspectionTemplatesServiceData } from './inspectionTemplatesService';
import { InspectionsServiceData } from './inspectionsService';
import { IssueFormServiceData } from './issueFormService';
import { IssuesServiceData } from './issuesService';
import { LevelsServiceData } from './levelsService';
import {
  version15Store,
  version15Upgrade,
  version18Store,
  version18Upgrade,
  version23Store,
  version23Upgrade,
  version28Store,
  version28Upgrade,
  version29Upgrade,
  version30Upgrade,
  version31Store,
  version32Store,
  version32Upgrade,
  version33Upgrade,
  version34Store,
  version35Upgrade,
  version38Upgrade,
  version39Store,
} from './migrations';
import { OrganizationsServiceData } from './organizationsService';
import { PermissionsData } from './permissions';
import { ProjectsServiceData } from './projectsService';
import { SitesServiceData } from './sitesService';
import { UsersServiceData } from './usersService';
import { VisibleFieldsServiceData } from './visibleFieldsService';
import { WorktypesServiceData } from './worktypesService';

type HustroTables = {
  [key in HustroDbTablesUnion]: Dexie.Table<unknown>;
};

export type HustroDbTablesUnion =
  | HustroDbEntityTablesUnion
  | HustroDbServiceTablesUnion
  | 'config'
  | 'permissions'
  | 'analytics'
  | 'chartFilters'
  | 'selectedProject'
  | 'httpRequests'
  | 'documents';

export type HustroDbServiceTablesUnion =
  | 'issuesService'
  | 'companiesService'
  | 'contractsService'
  | 'usersService'
  | 'projectsService'
  | 'organizationsService'
  | 'inspectionTemplatesService'
  | 'inspectionsService'
  | 'sitesService'
  | 'worktypesService'
  | 'hazardCategoriesService'
  | 'environmentalAspectsService'
  | 'correctiveActionTypesService'
  | 'levelsService'
  | 'documentationsService'
  | 'directoriesService'
  | 'issueFormService'
  | 'visibleFieldsService';

export type HustroDbEntityTablesUnion =
  | 'issues'
  | 'companies'
  | 'contracts'
  | 'users'
  | 'projects'
  | 'organizations'
  | 'inspectionTemplates'
  | 'inspections'
  | 'sites'
  | 'worktypes'
  | 'hazardCategories'
  | 'environmentalAspects'
  | 'correctiveActionTypes'
  | 'levels'
  | 'documentations'
  | 'directories'
  | 'issueForm'
  | 'visibleFields';

export class HustroDb extends Dexie implements HustroTables {
  analytics!: Dexie.Table<ChartDataEntity>;
  chartFilters!: Dexie.Table<ChartFiltersEntity>;
  companies!: Dexie.Table<CompanyEntity>;
  companiesService!: Dexie.Table<CompaniesServiceData>;
  config!: Dexie.Table<ConfigData>;
  contracts!: Dexie.Table<ContractEntity>;
  contractsService!: Dexie.Table<ContractsServiceData>;
  documents!: Dexie.Table<DocumentEntity>;
  hazardCategories!: Dexie.Table<HazardCategoryEntity>;
  hazardCategoriesService!: Dexie.Table<HazardCategoriesServiceData>;
  httpRequests!: Dexie.Table<HttpRequestEntity>;
  inspections!: Dexie.Table<InspectionEntity>;
  inspectionsService!: Dexie.Table<InspectionsServiceData>;
  inspectionTemplates!: Dexie.Table<InspectionTemplateEntity>;
  inspectionTemplatesService!: Dexie.Table<InspectionTemplatesServiceData>;
  issues!: Dexie.Table<IssueEntity>;
  issuesService!: Dexie.Table<IssuesServiceData>;
  permissions!: Dexie.Table<PermissionsData>;
  projects!: Dexie.Table<ProjectEntity>;
  projectsService!: Dexie.Table<ProjectsServiceData>;
  organizations!: Dexie.Table<OrganizationEntity>;
  organizationsService!: Dexie.Table<OrganizationsServiceData>;
  selectedProject!: Dexie.Table<Identificable>;
  sites!: Dexie.Table<SiteEntity>;
  sitesService!: Dexie.Table<SitesServiceData>;
  users!: Dexie.Table<UserEntity>;
  usersService!: Dexie.Table<UsersServiceData>;
  worktypes!: Dexie.Table<WorktypeEntity>;
  worktypesService!: Dexie.Table<WorktypesServiceData>;
  environmentalAspects!: Dexie.Table<EnvironmentalAspectEntity>;
  environmentalAspectsService!: Dexie.Table<EnvironmentalAspectsServiceData>;
  correctiveActionTypes!: Dexie.Table<CorrectiveActionTypeEntity>;
  correctiveActionTypesService!: Dexie.Table<CorrectiveActionTypesServiceData>;
  levels!: Dexie.Table<LevelEntity>;
  levelsService!: Dexie.Table<LevelsServiceData>;
  documentations!: Dexie.Table<DocumentationEntity>;
  documentationsService!: Dexie.Table<DocumentationsServiceData>;
  directories!: Dexie.Table<DirectoryEntity>;
  directoriesService!: Dexie.Table<DirectoriesServiceData>;
  issueForm!: Dexie.Table<any>;
  issueFormService!: Dexie.Table<IssueFormServiceData>;
  visibleFields!: Dexie.Table<VisibleFieldEntity>;
  visibleFieldsService!: Dexie.Table<VisibleFieldsServiceData>;

  constructor() {
    super('hustro');

    // https://dexie.org/docs/Tutorial/Design#database-versioning
    this.version(15).stores(version15Store).upgrade(version15Upgrade);

    this.version(18).stores(version18Store).upgrade(version18Upgrade);

    this.version(23).stores(version23Store).upgrade(version23Upgrade);

    this.version(28).stores(version28Store).upgrade(version28Upgrade);

    this.version(29).upgrade(version29Upgrade);

    this.version(30).upgrade(version30Upgrade);

    this.version(31).stores(version31Store);

    this.version(32).stores(version32Store).upgrade(version32Upgrade);

    this.version(33).stores({}).upgrade(version33Upgrade);

    this.version(34).stores(version34Store);

    this.version(35).stores({}).upgrade(version35Upgrade);

    this.version(36).stores({
      visibleFields: '&_id, deleted',
      visibleFieldsService: '++_id',
    });

    this.version(37).stores({
      issueForm: '&name',
      issueFormService: '++_id',
      // https://github.com/dexie/Dexie.js/issues/563
      issueForms: null,
      issueFormsService: null,
    });

    this.version(38).stores({}).upgrade(version38Upgrade);

    this.version(39).stores(version39Store);

    this.open();
  }
}

const db = new HustroDb();

export default db;
