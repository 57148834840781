import React, { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import { useIntl } from 'react-intl';
import { MemoPreviewField } from 'components/general/PreviewField';
import { SingleCompanyPresentationalProps } from './types';
import { useCommonSingleEntityViewStyles } from '../../common/styles/useCommonSingleEntityViewStyles';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { ButtonTextIcon } from 'components/general';
import { EditOutlined } from '@mui/icons-material';
import useIsMobile from 'hooks/useIsMobile';

export function SingleCompanyPresentational({
  shortLabel,
  longLabel,
  projectInvolvementType,
  sites,
  editDisabledReason,
  isEditButtonDisabled,
  taxNumber,
  edit,
}: SingleCompanyPresentationalProps): ReactElement {
  const intl = useIntl();
  const isMobile = useIsMobile();
  const classes = useCommonSingleEntityViewStyles({ isMobile });

  return (
    <div className={classes.root}>
      <h1>{shortLabel}</h1>
      <h2>{longLabel}</h2>
      <div className={classes.actionsContainer}>
        <div>
          <MemoForbiddenTooltip
            visibleWhen={isEditButtonDisabled}
            reason={editDisabledReason}
            inlined
          >
            <ButtonTextIcon
              disabled={isEditButtonDisabled}
              startIcon={EditOutlined}
              onClick={edit}
            >
              {intl.formatMessage({
                id: 'general_edit',
              })}
            </ButtonTextIcon>
          </MemoForbiddenTooltip>
        </div>
      </div>

      <Divider />
      <h1>{/*intl.formatMessage({ id: 'basic_information' })*/}</h1>
      <div className='row'>
        <MemoPreviewField
          isHalf
          label={intl.formatMessage({
            id: 'company_involvement_type_field_label',
          })}
          value={projectInvolvementType}
          dense={true}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          label={intl.formatMessage({
            id: 'primaryField_Site',
          })}
          value={sites}
          dense={true}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          isHalf
          label={intl.formatMessage({
            id: 'company_tax_number_field_label',
          })}
          value={taxNumber}
          dense={true}
        />
      </div>
    </div>
  );
}
