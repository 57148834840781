import { BroadcastChannel } from 'broadcast-channel';
import { ApiChannelMessage } from 'shared/domain/messages/message';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { CompanyEditModel } from './types/model';

export function startCompanyEdit(
  company: CompanyEditModel,
  uniqueId: string
): void {
  debugLog('startCompanyEdit', company, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      companyEditModel: CompanyEditModel;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.COMPANIES,
    method: ServiceMethods.EDIT,
    data: {
      companyEditModel: company,
      uniqueId,
    },
  });
  broadcast.close();
}
