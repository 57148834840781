import { get as getWorkTypes } from 'serviceWorker/db/worktypes';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  ApexSeriesAndLabels,
  DateRangeObject,
  WorkTypeFilters,
} from 'shared/types/analytics';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import {
  filterByAssignee,
  filterByDateRange,
  filterByImpact,
  filterByProcess,
  filterBySite,
  filterBySubcontractor,
  increaseEntitySeries,
  increaseNoneSeries,
  toSortedApexSeries,
} from '../seriesCreation';

export async function createSeriesAndLabelsFromIssuesWithFilters(
  issues: IssueEntity[],
  filters: WorkTypeFilters,
  timezone: string
): Promise<ApexSeriesAndLabels> {
  const workTypesLabels = {};
  const workTypesMap = (await getWorkTypes()).reduce((map, workType) => {
    map.set(workType._id, {
      _id: workType._id,
      label: workType.label,
    });
    return map;
  }, new Map<string, LabelledEntity>());

  const [
    dateRangeFilter,
    processFilter,
    siteFilter,
    subcontractorFilter,
    impactFilter,
    assigneeFilter,
  ] = filters.dataScope.filters;

  const filteredIssues = issues.filter((issue) => {
    const passedDateRange = filterByDateRange(
      dateRangeFilter.value as DateRangeObject,
      issue.primaryData.detectionDate || issue.createdAt,
      timezone
    );
    if (!passedDateRange) {
      return false;
    }

    const passedProcess = filterByProcess(
      processFilter.value as string[],
      issue
    );
    if (!passedProcess) {
      return false;
    }

    const passedSite = filterBySite(siteFilter.value as string[], issue);
    if (!passedSite) {
      return false;
    }

    const passedWorktype = filterBySubcontractor(
      subcontractorFilter.value as string[],
      issue
    );
    if (!passedWorktype) {
      return false;
    }

    const passedImpact = filterByImpact(
      impactFilter.value as string[],
      issue
    );
    if (!passedImpact) {
      return false;
    }

    const passedAssignee = filterByAssignee(
      assigneeFilter.value as string[],
      issue
    );
    if (!passedAssignee) {
      return false;
    }

    return true;
  });

  const reducedToWorkTypesSummary = filteredIssues.reduce<HashMap<number>>(
    (result, issue) => {
      const workTypes = issue.extendedData.workTypes || [];

      if (!workTypes.length) {
        increaseNoneSeries(result, workTypesLabels);
      } else {
        workTypes.forEach((workType) =>
          increaseEntitySeries(
            result,
            workTypesLabels,
            workTypesMap.get(workType)!
          )
        );
      }

      return result;
    },
    {}
  );

  return toSortedApexSeries(reducedToWorkTypesSummary, workTypesLabels);
}
