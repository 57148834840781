import { useDirectoryForm } from 'components/dataCreationForms/withDirectoryForm';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DirectoryForm } from 'components/directory/DirectoryForm';
import { ReactElement, useCallback, useMemo } from 'react';
import { handleFormValidation, validate } from './validation';

export function DirectoryWizard({
  name,
}: {
  name?: string;
}): ReactElement {
  const initialValues = useMemo(() => {
    return { name };
  }, [name]);
  const {
    isPosting,
    closeDialog,
    SUBMIT_EVENT_TYPE,
    releaseSubmitEvent,
    formName,
    submitForm,
  } = useDirectoryForm();

  const onCancel = useCallback(() => {
    closeDialog();
  }, [closeDialog]);

  const onSubmit = useCallback(() => {
    releaseSubmitEvent();
  }, [releaseSubmitEvent]);

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <DirectoryForm
        isPosting={isPosting}
        onCancel={onCancel}
        onSubmit={onSubmit}
      />
    </WithInputForm>
  );
}
