import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';
import { useInspectionTemplateChannelListener } from 'components/broadcastChannelListeners/withInspectionTemplateChannelListener';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allInspectionTemplates
  );
}

export const useGetAllInspectionTemplates =
  makeUseGetAll<InspectionTemplateModel>({
    useChannelListener: useInspectionTemplateChannelListener,
    postMessageChannel: ChannelNames.inspectionTemplateChannel,
    postMessageType: DomainMessagesTypes.getAllInspectionTemplates,
    isMatchingMessage,
    entityName: 'inspectionTemplates',
  });
