import { useInputForm } from 'components/dataCreationForms/withInputForm';
import {
  MemoStatefulMultiselect,
  MemoStatefulSingleSelect,
} from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import { useVisibleFieldsState } from 'components/dataProviders/withVisibleFields';
import { MultiChoiceFormFieldProps } from 'components/inspection/Form/types';
import { NonEditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import {
  REPORT_FIELDS_CONTENT_LABELS,
  ReportFieldsContent,
} from 'shared/domain/report/reportModel';
import { createAvailableFieldsSet } from 'shared/domain/visibleField/createAvailableFieldsSet';
import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';
import {
  StaticField,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { LabelledEntity } from 'shared/types/commonView';

export function ReportFieldsSelector(): ReactElement {
  const intl = useIntl();
  const reportFieldsTypeProps: MultiChoiceFormFieldProps<{
    fieldsContent: string;
  }> = useMemo(
    () => ({
      formKey: 'fieldsContent',
      labelId: 'report_field_content_label',
      required: true,
      fieldName: 'select-report-fields-content',
      available: Object.values(ReportFieldsContent).map(
        (key: ReportFieldsContent) => ({
          _id: key,
          label: intl.formatMessage({
            id: REPORT_FIELDS_CONTENT_LABELS[key],
          }),
        })
      ),
      getOptionLabel: (option) => option.label,
      getOptionSelected: (
        option: LabelledEntity,
        value: LabelledEntity
      ): boolean => option._id === value._id,
      minRows: 3,
      entityName: '',
      dense: false,
      reserveSpaceForHelperText: false,
      'data-qa': 'report_fields_content',
    }),
    [intl]
  );

  return (
    <>
      <MemoStatefulSingleSelect {...reportFieldsTypeProps} />
      <ReportFieldsMultiselect />
    </>
  );
}

function ReportFieldsMultiselect(): ReactElement | null {
  const intl = useIntl();
  const processes = useSelector(processesSelector);
  const { values } = useInputForm();
  const visibleFields = useVisibleFieldsState();
  const visibleFieldsSet = useMemo(
    () =>
      createAvailableFieldsSet(
        visibleFields,
        getAvailableFields().filter(
          (field) => !field.canBeDisabled
        ) as StaticField[],
        processes
      ),
    [processes, visibleFields]
  );
  const fieldsMap = useMemo(
    () =>
      getAvailableFields().reduce((result, field) => {
        result[field.fieldName] = field;
        return result;
      }, {}),
    []
  );

  const reportFields = useMemo(() => {
    return {
      formKey: 'selectedFields',
      required: false,
      labelId: 'report_selected_fields_label',
      fieldName: 'multiselect-report-selected-fields',
      available: Array.from(visibleFieldsSet)
        .filter(
          (fieldName) =>
            ![
              IssueFieldNames.level,
              IssueFieldNames.site,
              IssueFieldNames.stage,
              IssueFieldNames.issueNumber,
              IssueFieldNames.title,
              IssueFieldNames.documents,
              IssueFieldNames.hashtag,
            ].includes(fieldName as IssueFieldNames)
        )
        .map((field) => {
          return {
            _id: field,
            label: intl.formatMessage({
              id: fieldsMap[field].labelId,
            }),
          };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label);
        }),
    };
  }, [visibleFieldsSet, intl, fieldsMap]);

  if (!values.fieldsContent) {
    return null;
  }

  if (values.fieldsContent._id !== ReportFieldsContent.selected) {
    return null;
  }

  return <MemoStatefulMultiselect {...reportFields} />;
}
