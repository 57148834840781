// https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086

export const debounce = (
  func: (...args: any[]) => any,
  wait: number
): ((...args: any[]) => void) => {
  let timeout: ReturnType<typeof setTimeout> | null;

  return function executedFunction(...args): void {
    const later = (): void => {
      timeout = null;
      func(...args);
    };
    timeout !== null && clearTimeout(timeout);

    timeout = setTimeout(later, wait);
  };
};

export const debounceNext = (
  func: Function,
  wait: number
): (() => void) => {
  let timeout: number | undefined;
  let timer = 0;

  return function executedFunction(...args): void {
    let now = Date.now();
    const later = (): void => {
      timeout = undefined;
      timer = now;
      func(...args);
    };

    if (now - timer < wait) {
      clearTimeout(timeout);
      timer = now;
      timeout = self.setTimeout(later, wait);
    } else {
      later();
    }
  };
};
