import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { Spinner } from 'components/core';
import { useEditSite } from 'components/dataCreationForms/site/withEditSite';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { MemoSiteForm } from 'components/site/single/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

function EditSiteWizardDialog(): React.ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    existingValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useEditSite();
  const formName = 'site';
  useFormCleanupOnUnmount(formName);
  return (
    <Dialog
      open={open}
      // sx={{ width: { xs: 1, sm: 2, lg: 3, xl: 4 } }}
      fullWidth
      onClose={closeDialog}
    >
      <DialogTitle>
        <FormattedMessage id='edit_site' />
      </DialogTitle>
      <Divider />
      {existingValues ? (
        <DialogContent>
          <WithInputForm
            values={existingValues}
            errors={{}}
            handleFormValidation={handleFormValidation}
            handleFieldValidation={validate}
            onSubmit={submitForm}
            eventType={SUBMIT_EVENT_TYPE}
            dispatchSubmit={releaseSubmitEvent}
            formName={formName}
          >
            <MemoSiteForm
              code={existingValues.code}
              label={existingValues.label}
            />
          </WithInputForm>
        </DialogContent>
      ) : (
        <Spinner
          reason={`EditSiteWizardDialog Waiting for existing values: ${existingValues}`}
        />
      )}
      <Divider />
      <DialogActions>
        <DialogButtons
          isPosting={isPosting}
          onSubmit={releaseSubmitEvent}
          onCancel={closeDialog}
        />
      </DialogActions>
    </Dialog>
  );
}

export const MemoEditSiteWizardDialog = memo(EditSiteWizardDialog);
