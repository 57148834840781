import { Message } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { createUniqueId } from 'shared/utils/id/id';
import { DocumentCreateModel } from './documentCreateModel';
import { DrawingModel } from './drawingModel';
import listenToDocumentCreatedMessage from './listenToDocumentCreatedMessage';
import listenToDocumentDeletedMessage from './listenToDocumentDeletedMessage';
import listenToDocumentUpdatedMessage from './listenToDocumentUpdatedMessage';
import listenToDrawingCreatedMessage from './listenToDrawingCreatedMessage';
import startDocumentCreate from './startDocumentCreate';
import startDocumentDelete from './startDocumentDelete';
import startDocumentEdit from './startDocumentEdit';
import startDrawingCreate from './startDrawingCreate';

function saveDocumentLocally(
  baseUrl: string,
  document: DocumentCreateModel
): Promise<number> {
  const uniqueId = createUniqueId();

  return new Promise((resolve, reject): void => {
    const broadcast = listenToDocumentCreatedMessage(
      (success: number[]): void => {
        broadcast.close();
        debugLog('document create success', success);
        resolve(success[0]);
      },
      (error: any[]): void => {
        broadcast.close();
        debugLog('document create error', error);
        reject(error[0]);
      },
      (event: Message) => {
        return event.uniqueId === uniqueId;
      }
    );

    startDocumentCreate(baseUrl, document, uniqueId);
  });
}

export function saveDocumentsLocally(
  baseUrl: string,
  documents: DocumentCreateModel[]
): Promise<number[]> {
  return Promise.all(
    documents.map((document) => saveDocumentLocally(baseUrl, document))
  );
}

export function saveDocumentChangeLocally(editObject: {
  description: string;
  localId: number;
}): Promise<number> {
  return new Promise((resolve, reject): void => {
    const broadcast = listenToDocumentUpdatedMessage(
      (success: number): void => {
        broadcast.close();
        debugLog('document update success', success);
        resolve(success);
      },
      (error: any): void => {
        broadcast.close();
        debugLog('document update error', error);
        reject(error);
      },
      (event: Message): boolean => {
        return event.data === editObject.localId;
      }
    );

    startDocumentEdit(editObject);
  });
}

export function deleteDocumentLocally(localId: number): Promise<any> {
  return new Promise((resolve, reject): void => {
    const broadcast = listenToDocumentDeletedMessage(
      (success: number): void => {
        broadcast.close();
        debugLog('document delete success', success);
        resolve(success);
      },
      (error: any): void => {
        broadcast.close();
        debugLog('document delete error', error);
        reject(error);
      },
      (event: Message): boolean => {
        return event.data === localId;
      }
    );

    startDocumentDelete(localId);
  });
}

export function saveDrawingLocally(
  drawing: DrawingModel,
  document: { localId: number }
): Promise<{ localId: number }> {
  return new Promise((resolve, reject): void => {
    const broadcast = listenToDrawingCreatedMessage(
      (success: any): void => {
        broadcast.close();
        debugLog('drawing create success', success);
        resolve(success);
      },
      (error: any): void => {
        broadcast.close();
        debugLog('drawing create error', error);
        reject(error);
      },
      (event: Message): boolean => {
        return event.data.localId === document.localId;
      }
    );

    startDrawingCreate(drawing, document.localId);
  });
}
