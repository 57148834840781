import {
  AfterCreateLevelChangeOutDto,
  LevelCreateOutDto,
  LevelEditOutDto,
} from 'shared/dtos/out/level';
import { LevelCreateModel, LevelEditModel } from '../types/model';
import { LevelEntity } from '../types/entity';

export function levelCreateModelToOutDto(
  model: LevelCreateModel
): LevelCreateOutDto {
  return {
    label: model.label,
    site: model.site,
    index: 0,
  };
}

export function levelCreateModelToChangeOutDto(
  model: LevelCreateModel
): AfterCreateLevelChangeOutDto | undefined {
  if (model.map) {
    return {
      documentation: {
        add: [model.map],
      },
    };
  }
}

export function levelEditModelToOutDto(
  levelEditModel: LevelEditModel,
  levelEntity: LevelEntity
): LevelEditOutDto {
  const levelChange: LevelEditOutDto = {
    levelId: levelEditModel._id,
  };
  if (levelEditModel.map) {
    levelChange.documentation = {
      add: [levelEditModel.map],
      remove: levelEntity.maps.map((map) => {
        return {
          documentationId: map.documentationId,
          versionId: map.versionId,
        };
      }),
    };
  }

  if (levelEditModel.label) {
    levelChange.label = levelEditModel.label;
  }

  return levelChange;
}
