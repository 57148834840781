import { ReactElement } from 'react';
import { useHistory } from 'react-router-dom';
import { useLabelledCardStyles } from './styles';

type CardItemProps = {
  redirectTo?: string;
  label: string;
  id: string;
  EndIcon?: ReactElement;
  deleted?: boolean;
};

export const LabelledCard = (props: CardItemProps): ReactElement => {
  const history = useHistory();

  const { id, redirectTo, label, deleted, EndIcon } = props;
  const specificClasses = useLabelledCardStyles({
    isClickable: Boolean(redirectTo),
  });

  return (
    <div
      key={id}
      className={specificClasses.fieldBox}
      onClick={() => {
        redirectTo && history.push(redirectTo);
      }}
    >
      <div className={`w-100 label ${deleted ? 'deleted' : ''}`}>
        {label}
      </div>
      {EndIcon || null}
    </div>
  );
};
