import { ComplianceCheckInDto } from 'shared/dtos/in/complianceCheck';
import { InspectionInDto } from 'shared/dtos/in/inspection';
import { ProtocolItemInDto } from 'shared/dtos/in/protocolItem';
import {
  ComplianceCheckEntity,
  InspectionEntity,
  ProtocolItemEntity,
} from './entity';

function complianceCheckDtoToEntity(
  complianceCheckInDto: ComplianceCheckInDto
): ComplianceCheckEntity {
  return {
    issues: complianceCheckInDto.issues,
    result: complianceCheckInDto.result,
    comment: complianceCheckInDto.comment,
  };
}

function protocolDtoToEntity(
  protocol: ProtocolItemInDto
): ProtocolItemEntity {
  return {
    _id: protocol._id,
    complianceCheck: complianceCheckDtoToEntity(protocol.complianceCheck),
    modifiedAt: protocol.modifiedAt,
    modifiedBy: protocol.modifiedBy,
    templateChecklistItem: protocol.templateChecklistItem,
  };
}

export function inspectionDtoToEntityToSave(
  inspection: InspectionInDto
): InspectionEntity {
  return {
    _id: inspection._id,
    comment: inspection.comment,
    contracts: inspection.contracts,
    controlledParty: inspection.controlledParty,
    createdAt: inspection.createdAt,
    createdBy: inspection.createdBy,
    deleted: inspection.deleted ? 1 : 0,
    inspectionDate: inspection.inspectionDate,
    isCompleted: inspection.isCompleted,
    levels: inspection.levels,
    modifiedAt: inspection.modifiedAt,
    modifiedBy: inspection.modifiedBy,
    protocol: inspection.protocol.map(protocolDtoToEntity),
    site: inspection.site,
    template: inspection.template,
    workTypes: inspection.workTypes,
  };
}
