import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: '3rem',
      maxWidth: '758px',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '0 1rem',
    },
    column: {
      maxWidth: '758px',
      width: '100%',
      marginBottom: '1.5rem',
    },
    fieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.5rem',
      flexGrow: 1,
      width: '100%',
      alignItems: 'center',
      marginBottom: '8rem',
    },
  })
);
