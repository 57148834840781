import {
  Auth0Client,
  Auth0ClientOptions,
  LogoutOptions,
  createAuth0Client,
} from '@auth0/auth0-spa-js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { browserHistory } from 'setup/browserHistory';
import { debugLog } from 'shared/logger/debugLog';
import { ClientContext } from '../../with-client';
import { getAuth0Config } from './auth_config';
interface Auth0ContextType {
  isAuthenticated?: boolean;
  token?: string;
  loading: boolean;
  error?: boolean;
  loginWithRedirect(o: any): Promise<void> | undefined;
  logout(o?: LogoutOptions): void;
  handleRedirect(redirectUrl: string): Promise<void>;
}

interface Auth0ProviderOptions {
  children: React.ReactElement;
  onRedirectCallback?(result: any): void;
}

const onRedirectCallback = (appState: any): void => {
  browserHistory.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const DEFAULT_REDIRECT_CALLBACK = (): void =>
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  );

const Auth0Context = React.createContext<Auth0ContextType | null>(null);
export const useAuth0 = (): Auth0ContextType => {
  const result = useContext(Auth0Context);
  if (process.env.REACT_APP_DISABLE_AUTH) {
    return { ...result!, isAuthenticated: true, token: 'fake_token' };
  }
  return result!;
};

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderOptions & Auth0ClientOptions): React.ReactElement => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
  const [token, setToken] = useState<string>();
  const [error, setError] = useState<boolean>();
  const client = useContext(ClientContext);

  const auth0ClientRef = useRef<Auth0Client | undefined>();
  const [auth0Client, _setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState<boolean>(true);
  const history = useHistory();
  const location = useLocation();

  const setAuth0 = useCallback((client: Auth0Client) => {
    auth0ClientRef.current = client;
    _setAuth0(client);
  }, []);

  const handleRedirect = async (redirect_uri: any): Promise<void> => {
    try {
      if (error || !auth0Client) {
        error && debugLog('Auth0Provider: error', error);
        const auth0FromHook = await createAuth0Client(initOptions);
        setAuth0(auth0FromHook);

        return auth0FromHook.logout(initOptions);
      }
    } finally {
      setError(false);
      loginRedirect({ authorizationParams: { redirect_uri } });
    }
  };

  const saveToken = useCallback(async () => {
    const lsToken = localStorage.getItem('auth0_token');
    const lsExpiration = localStorage.getItem('auth0_token_expiration');
    const nowPlus1Minute = Date.now() + 60 * 1000;

    if (
      lsToken &&
      lsExpiration &&
      parseFloat(lsExpiration) > nowPlus1Minute
    ) {
      setToken(lsToken);
      return;
    }
    if (!auth0ClientRef.current) {
      return;
    }
    const token = await auth0ClientRef.current.getTokenSilently();
    const claims = await auth0ClientRef.current.getIdTokenClaims();
    if (claims) {
      localStorage.setItem('auth0_token', token ?? '');
      localStorage.setItem('auth0_token_expiration', `${claims.exp}000`);
      localStorage.setItem('user_email', claims.email ?? '');
      localStorage.setItem('user_id', claims.sub?.slice(6) ?? '');
    }
    setToken(token);
  }, []);

  useEffect(() => {
    const initAuth0 = async (): Promise<void> => {
      try {
        const auth0FromHook = await createAuth0Client(initOptions);
        setAuth0(auth0FromHook);

        if (
          window.location.search.includes('?code=') ||
          window.location.search.includes('?state=')
        ) {
          try {
            const { appState } =
              await auth0FromHook.handleRedirectCallback();
            onRedirectCallback(appState);
          } catch (error) {
            onRedirectCallback({});
          }
        }
        const isAuthenticated = await auth0FromHook.isAuthenticated();
        setIsAuthenticated(isAuthenticated);

        if (isAuthenticated) {
          await saveToken();
          await client.authenticate();

          const { pathname, search } = location;
          const from = `${pathname}${search}`;
          history.push(from);
        }

        if (window.location.search.includes('?error')) {
          const query = queryString.parse(location.search);
          const { error, error_description } = query;

          // if (error === USER_UNAUTHORIZED) {
          //   ShowUnauthorizedToaster(error_description, dispatch);
          // } else {
          //   ShowGeneralErrorToaster(error_description, dispatch);
          // }

          setError(true);
        }
      } finally {
        setLoading(false);
      }
    };
    initAuth0();
    // eslint-disable-next-line
  }, []);

  const loginRedirect = (o: any): Promise<void> | undefined => {
    if (!auth0Client) {
      return;
    }

    return auth0Client.loginWithRedirect(o);
  };

  const logout: Auth0Client['logout'] = useCallback((o) => {
    if (auth0ClientRef.current && auth0ClientRef.current.logout)
      return auth0ClientRef.current && auth0ClientRef.current.logout(o);
    throw new Error('Logout function missing');
  }, []);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.tokenChannel);

    broadcast.onmessage = async (event: Message) => {
      if (event.type === DomainMessagesTypes.getToken) {
        if (
          !auth0ClientRef.current ||
          !(await auth0ClientRef.current.isAuthenticated())
        ) {
          return;
        }
        await saveToken();
        const token = localStorage.getItem('auth0_token');
        const tokenExpiration = localStorage.getItem(
          'auth0_token_expiration'
        );

        broadcast.postMessage({
          type: DomainMessagesTypes.token,
          data: {
            token,
            tokenExpiration: tokenExpiration
              ? parseFloat(tokenExpiration)
              : 0,
          },
        });
      }
    };

    return () => {
      broadcast.close();
    };
  }, [saveToken]);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        token,
        error,
        loading,
        handleRedirect,
        loginWithRedirect: (o): Promise<void> | undefined =>
          loginRedirect(o),
        logout: logout,
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export const AuthProvider = ({ children }: any): React.ReactElement => {
  const auth0Config = getAuth0Config();
  const cacheLocation =
    process.env.NODE_ENV === 'development'
      ? 'localstorage'
      : auth0Config.cacheLocation;

  const authorizationParams = useMemo(() => {
    return {
      redirect_uri: auth0Config.redirectUri,
      audience: auth0Config.audience,
      scope: 'openid offline_access profile email',
      prompt: 'select_account' as 'select_account',
    };
  }, [auth0Config.redirectUri, auth0Config.audience]);
  return (
    <Auth0Provider
      domain={auth0Config.domain}
      clientId={auth0Config.clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens={true}
      cacheLocation={cacheLocation}
      // https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
      // https://github.com/auth0/auth0-spa-js/blob/master/MIGRATION_GUIDE.md#no-more-iframe-fallback-by-default-when-using-refresh-tokens
      useRefreshTokensFallback={true}
    >
      <>{children}</>
    </Auth0Provider>
  );
};
