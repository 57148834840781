import { useEffect, useCallback } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { useSyncIssues } from './useSyncIssues';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';

type GetFinancialData = {
  getFinancialData: () => void;
};

export function useSyncFinancialData(): GetFinancialData {
  useSyncIssues(true);

  const getFinancialData = useCallback(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
    broadcast.postMessage({
      service: Services.ANALYTICS,
      method: ServiceMethods.SYNC,
      data: {},
    });
    broadcast.close();
  }, []);

  useEffect(() => {
    let mounted = true;
    const issuesBroadcast = new BroadcastChannel(
      ChannelNames.issueChannel
    );

    issuesBroadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }
      if (
        event.data &&
        event.type === DomainMessagesTypes.allIssues &&
        event.data.hasAll
      ) {
        getFinancialData();
        return;
      }
    };

    return (): void => {
      mounted = false;
      issuesBroadcast.close();
    };
  });

  return {
    getFinancialData,
  };
}
