import React, { ReactElement, useMemo } from 'react';

import { useStyles } from '../styles';
import classNames from 'classnames';

import { errorIcon, innerIcon } from '..';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

type ProtocolItemResultIconProps = {
  status: ComplianceCheckResult;
};
function ProtocolItemResultIcon(
  props: ProtocolItemResultIconProps
): ReactElement {
  const { status } = props;
  const classes = useStyles();
  const icon = useMemo(() => innerIcon[status], [status]);

  return (
    <div className={classNames(classes.icon, classes[status])}>{icon}</div>
  );
}

export const MemoProtocolItemResultIcon = React.memo(
  ProtocolItemResultIcon
);

export function ProtocolItemErrorIcon(): ReactElement {
  const classes = useStyles();

  return <div className={classes.errorIcon}>{errorIcon}</div>;
}
