import {
  PropsWithChildren,
  ReactElement,
  RefObject,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
  useTransformContext,
  useTransformEffect,
} from 'react-zoom-pan-pinch';
import { debugLog } from 'shared/logger/debugLog';
import { noop } from 'shared/utils/function';

const ImageBoundsContext = createContext<
  undefined | { width: number; height: number }
>(undefined);

export function ResponsivePanPinchZoom({
  scaleUp,
  zoomFactor = 20,
  onTransformed,
  transformOptions,
  imageRef,
  imageSrc,
  imageLoading,
  imageError,
  onImageLoad,
  container,
  children,
  minimumScaleFactor = 0,
}: PropsWithChildren<{
  scaleUp?: boolean;
  zoomFactor?: number;
  onTransformed?: (
    ref: ReactZoomPanPinchRef,
    state: {
      scale: number;
      positionX: number;
      positionY: number;
    }
  ) => void;
  transformOptions?: any;
  imageRef: RefObject<HTMLImageElement>;
  imageSrc?: string;
  imageLoading: boolean;
  imageError?: string;
  onImageLoad?: () => void;
  container: HTMLDivElement | null;
  minimumScaleFactor: number;
}>): ReactElement {
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [containerHeight, setContainerHeight] = useState<number>(0);

  const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
  const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

  const imageScale = useMemo(() => {
    if (
      containerWidth === 0 ||
      containerHeight === 0 ||
      imageNaturalWidth === 0 ||
      imageNaturalHeight === 0
    )
      return 0;
    const scale = Math.min(
      containerWidth / imageNaturalWidth,
      containerHeight / imageNaturalHeight
    );
    return scaleUp ? scale : Math.min(scale, 1);
  }, [
    scaleUp,
    containerWidth,
    containerHeight,
    imageNaturalWidth,
    imageNaturalHeight,
  ]);
  const minimumImageScale = useMemo(() => {
    return imageScale + minimumScaleFactor * imageScale;
  }, [imageScale, minimumScaleFactor]);

  const handleResize = useCallback(() => {
    if (container !== null) {
      const rect = container.getBoundingClientRect();
      setContainerWidth(rect.width);
      setContainerHeight(rect.height);
    } else {
      setContainerWidth(0);
      setContainerHeight(0);
    }
  }, [container]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const handleImageOnLoad = useCallback((image: HTMLImageElement) => {
    debugLog('handle image', image);
    setImageNaturalWidth(image.naturalWidth);
    setImageNaturalHeight(image.naturalHeight);
  }, []);

  useEffect(() => {
    if (!imageSrc) return;
    const image = new Image();
    image.crossOrigin = 'use-credentials';
    image.onload = () => handleImageOnLoad(image);
    image.src = imageSrc;
  }, [imageSrc, handleImageOnLoad]);

  const maxScale = imageScale * zoomFactor;

  const wrapperStyle = useMemo(() => {
    return {
      width: `${containerWidth}px`,
      height: `${containerHeight}px`,
    };
  }, [containerWidth, containerHeight]);

  const imageContext = useMemo(() => {
    return {
      width: imageNaturalWidth,
      height: imageNaturalHeight,
    };
  }, [imageNaturalWidth, imageNaturalHeight]);

  if (!imageLoading && imageError) {
    return (
      <div
        style={{
          paddingTop: '24px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FormattedMessage id={imageError} />
      </div>
    );
  }
  return (imageScale > 0 && (
    <TransformWrapper
      onTransformed={
        typeof onTransformed === 'function' ? onTransformed : noop
      }
      key={`${containerWidth}x${containerHeight}`}
      initialScale={minimumImageScale}
      minScale={minimumImageScale}
      maxScale={maxScale}
      doubleClick={transformOptions?.doubleClick}
      centerOnInit
    >
      <TransformComponent
        key={`${containerWidth}x${containerHeight}`}
        wrapperStyle={wrapperStyle}
      >
        {!imageLoading && imageSrc && (
          <ResponsiveImage
            imageRef={imageRef}
            imageSrc={imageSrc}
            handleImageOnLoad={handleImageOnLoad}
            onImageLoad={onImageLoad}
          />
        )}
        <ImageBoundsContext.Provider value={imageContext}>
          {children}
        </ImageBoundsContext.Provider>
      </TransformComponent>
    </TransformWrapper>
  )) as ReactElement;
}

function ResponsiveImage({
  imageRef,
  imageSrc,
  handleImageOnLoad,
  onImageLoad,
}: {
  imageRef: RefObject<HTMLImageElement>;
  imageSrc: string;
  handleImageOnLoad: (...args: any[]) => void;
  onImageLoad?: () => void;
}): ReactElement {
  const onLoad = useCallback(() => {
    if (!imageRef.current) {
      return;
    }
    handleImageOnLoad(imageRef.current);
    if (typeof onImageLoad === 'function') {
      onImageLoad();
    }
  }, [handleImageOnLoad, onImageLoad, imageRef]);
  return (
    <img
      crossOrigin='use-credentials'
      ref={imageRef}
      src={imageSrc}
      onLoad={onLoad}
      // because parent disabled this
      style={{ pointerEvents: 'auto' }}
    />
  );
}

export function useImageBoundsContext(): {
  width: number;
  height: number;
} {
  const context = useContext(ImageBoundsContext);
  if (context === undefined) {
    throw new Error(
      'useImageBoundsContext must be used within an ImageBoundsContextProvider'
    );
  }
  return context;
}
