import { DocumentEntity } from 'serviceWorker/repository/document/entity';
import { DocumentUrlsInDto } from 'shared/dtos/in/document';
import { fetchDocumentUrl } from './fetchDocumentUrl';
import { getOne as getIssue } from 'serviceWorker/db/issues';
import { ConfigData } from 'serviceWorker/db/config';
import { debugLog } from 'shared/logger/debugLog';
import { createUrlFromIssueDocument } from './resolveIssueDocumentUrl';
import { createUrlFromTemplateDocument } from './resolveTemplateDocumentUrl';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { createUrlFromInspectionDocument } from './resolveInspectionDocumentUrl';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';

export async function getParent(
  document: DocumentEntity
): Promise<{ issue: IssueEntity } | {}> {
  if (document.issueId) {
    return {
      issue: await getIssue(document.issueId),
    };
  } else {
    return Promise.resolve({});
  }
}

function createUrlFromDocumentEntity(
  setup: ConfigData,
  document: DocumentEntity
): string {
  const url = `/project/${setup.projectId}`;
  if (document.issueId) {
    return url + createUrlFromIssueDocument(document);
  }
  if (document.templateId) {
    return url + createUrlFromTemplateDocument(document);
  }
  if (document.inspectionId) {
    return url + createUrlFromInspectionDocument(document);
  }

  return url;
}

export function resolveDocumentRemoteSource(
  setup: ConfigData,
  document: DocumentEntity
): Promise<DocumentUrlsInDto> {
  const url = `${setup.api}/v2${createUrlFromDocumentEntity(
    setup,
    document
  )}/document/${document._id}/downloadData`;

  return fetchDocumentUrl(setup, url)
    .catch((e): DocumentUrlsInDto => {
      debugLog(`fetchDocumentUrl error`, e);
      return {
        signedRequest: DOCUMENT_ERROR_SOURCE,
        mergedSignedRequest: DOCUMENT_ERROR_SOURCE,
        drawingSignedRequest: DOCUMENT_ERROR_SOURCE,
        mergedThumbnailSignedRequest: DOCUMENT_ERROR_SOURCE,
      };
    })
    .then((url) => {
      debugLog(`resolveDocumentSourceUrl ${JSON.stringify(url)}`, url);
      return url;
    });
}
