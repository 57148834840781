import { DocumentationBaseIds } from 'shared/types/documentation';
import { useIssuesOnMap } from 'components/dataProviders/withIssuesOnMap';
import { getProcessesWithVisiblePositionOnMap } from 'components/dataProviders/withIssuesOnMap/model';
import { useLevels } from 'components/dataProviders/withLevels';
import {
  useFieldVisibility,
  useVisibleFieldsState,
} from 'components/dataProviders/withVisibleFields';
import { findDocumentationOnLevelsAndSites } from 'shared/domain/documentation/findDocumentationOnLevels';
import { ReactElement, useEffect, useMemo } from 'react';
import { IssueAreas } from './IssueAreas';
import { IssuePins } from './IssuePins';

export function DocumentationIssueLocation({
  file,
  visible,
}: {
  file: DocumentationBaseIds;
  visible: boolean;
}): ReactElement | null {
  const { issuesStore, setLevels } = useIssuesOnMap();
  const { loading: loadingFieldVisibility } = useFieldVisibility();

  const visibleFields = useVisibleFieldsState();
  const processesWithPositionOnMap = useMemo(
    () => getProcessesWithVisiblePositionOnMap(visibleFields),
    [visibleFields]
  );

  const { loading: loadingLevels, levels } = useLevels();
  const documentationId = file.documentationId;
  const versionId = file.versionId;

  const { levelsValue } = useMemo(
    () =>
      findDocumentationOnLevelsAndSites(
        levels.items,
        [],
        documentationId,
        versionId
      ),
    [levels.items, documentationId, versionId]
  );

  useEffect(() => {
    setLevels(JSON.stringify(levelsValue));
  }, [file, levelsValue, setLevels]);

  const isUsedAsLevel = levelsValue.length > 0;

  if (
    loadingLevels ||
    loadingFieldVisibility ||
    !isUsedAsLevel ||
    !visible
  ) {
    return null;
  }

  return (
    <>
      <IssueAreas
        issuesStore={issuesStore}
        processesWithPositionOnMap={processesWithPositionOnMap}
      />
      <IssuePins
        issuesStore={issuesStore}
        processesWithPositionOnMap={processesWithPositionOnMap}
      />
    </>
  );
}
