import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FormattedMessage } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useDocumentationBrowseDialog } from 'components/common/withDocumentationBrowseDialog';
import { DocumentationBrowser } from '../DocumentationBrowser';
import { useCurrentDirectoryContext } from 'components/dataProviders/withCurrentDirectory';
import { ArrowBack } from '@mui/icons-material';
import {
  ButtonContained,
  ButtonIcon,
  ButtonText,
} from 'components/general';
import { Store } from 'hooks/createStore';
import { WithSelectedStore } from 'components/common/withSelectedStore';
import { SetValuesOptions } from 'components/dataCreationForms/withInputForm';
import { useDocumentations } from 'components/dataProviders/withDocumentations';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { getCanBeUsedAsMap } from '../model';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 16,
      width: '100%',
    },
  },
  dialogContent: {
    padding: 0,
  },
  closeIcon: {
    opacity: 0.54,
    cursor: 'pointer',
  },
  dialogTitle: {
    padding: '16px',
    paddingLeft: '0',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '18px',
    },
  },
  submitButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px',
  },
});

function selectedStoreToEnableSelect(
  selectedStore: Store<string | undefined>,
  documentationStore: Store<DocumentationModel[]>
): boolean {
  const selected = selectedStore.get();
  if (!selected) return false;
  const [documentationLocalId, selectedType] = selected.split('_');
  if (selectedType !== 'FILE') return false;
  const documentation = documentationStore.get().find((doc) => {
    return `${doc.localId}` === documentationLocalId;
  });
  if (!documentation) return false;
  return getCanBeUsedAsMap(
    documentation.versions[0].extension,
    documentation.versions[0].imageConvertStatus
  );
}

export type SetValuesContext = () => SetValues;
type SetValues = {
  setValues: (
    key: string,
    value: { documentationId: string; versionId: string } | undefined,
    options?: SetValuesOptions | undefined
  ) => void;
};

export type DocumentationBrowseDialogProps = {
  setValuesContext: SetValuesContext;
  formKey: string;
  setFilename: (file: { name: string }) => void;
  selectedStore: Store<string | undefined>;
  title: { name: string } | undefined;
  initialSelect: string | undefined;
  initialDirectory: string;
};

export function DocumentationBrowseDialog({
  setValuesContext,
  formKey,
  setFilename,
  selectedStore,
  title,
  initialSelect,
  initialDirectory,
}: DocumentationBrowseDialogProps): ReactElement {
  const { setValues } = setValuesContext();
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const { isOpenStore, closeDialog } = useDocumentationBrowseDialog();
  const { currentDirDocumentationsStore } = useDocumentations();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(isOpenStore.get());
  const [enableSelect, setEnableSelect] = useState(
    selectedStoreToEnableSelect(
      selectedStore,
      currentDirDocumentationsStore
    )
  );

  useEffect(() => {
    setIsOpen(isOpenStore.get());
    return isOpenStore.subscribe(() => {
      setIsOpen(isOpenStore.get());
    });
  }, [isOpenStore]);

  useEffect(() => {
    setEnableSelect(
      selectedStoreToEnableSelect(
        selectedStore,
        currentDirDocumentationsStore
      )
    );
    return selectedStore.subscribe(() => {
      setEnableSelect(
        selectedStoreToEnableSelect(
          selectedStore,
          currentDirDocumentationsStore
        )
      );
    });
  }, [selectedStore, currentDirDocumentationsStore]);

  const onSubmit = useCallback(() => {
    const enabled = selectedStoreToEnableSelect(
      selectedStore,
      currentDirDocumentationsStore
    );
    if (!enabled) return;
    const selectedId = selectedStore.get()?.split('_')[0];
    const documentation = currentDirDocumentationsStore
      .get()
      .find((d) => `${d.localId}` === selectedId);
    if (!documentation) return;
    setValues(formKey, {
      documentationId: documentation._id,
      versionId: documentation.versions[0]._id,
    });
    setFilename({ name: documentation.name });
    closeDialog();
  }, [
    selectedStore,
    currentDirDocumentationsStore,
    setValues,
    formKey,
    setFilename,
    closeDialog,
  ]);

  const closeAndDeselect = useCallback(() => {
    closeDialog();
    selectedStore.set(initialSelect);
    currentDirectoryStore.set(initialDirectory);
  }, [
    closeDialog,
    selectedStore,
    initialSelect,
    initialDirectory,
    currentDirectoryStore,
  ]);

  return (
    <Dialog
      className={classes.root}
      open={isOpen}
      keepMounted={false}
      onClose={closeAndDeselect}
    >
      <DialogTitle className={classes.dialogTitle}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DocumentationGoUpAction />
          {title ? (
            title.name
          ) : (
            <FormattedMessage id={'general_documentation'} />
          )}
        </div>
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <WithSelectedStore selectedStore={selectedStore}>
          <DocumentationBrowser submit={onSubmit} />
        </WithSelectedStore>
        <Divider />
        <div className={classes.submitButtons}>
          <ButtonText onClick={closeAndDeselect} disabled={false}>
            <FormattedMessage id='general_cancel' />
          </ButtonText>
          <ButtonContained onClick={onSubmit} disabled={!enableSelect}>
            <FormattedMessage id='general_select' />
          </ButtonContained>
        </div>
      </DialogContent>
    </Dialog>
  );
}

function DocumentationGoUpAction(): ReactElement {
  const { goUp } = useCurrentDirectoryContext();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={goUp}
    >
      <ButtonIcon>
        <ArrowBack />
      </ButtonIcon>
    </div>
  );
}
