import { createStyles, makeStyles } from '@mui/styles';
import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';

export const useStyles = makeStyles(() =>
  createStyles({
    templateStatus: {
      fontFamily: 'Archivo',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0em',
      textTransform: 'capitalize',
      alignSelf: 'center',
      color: '#8A8A8A',
    },
    small: {
      fontSize: '0.625rem',
      lineHeight: '11px',
    },
    [InspectionTemplateStatus.draft]: {
      color: '#FF9800',
    },
    [InspectionTemplateStatus.published]: {
      color: '#00904C',
    },
    default: {},
  })
);
