import { BroadcastChannel } from 'broadcast-channel';
import { ButtonOutlined } from 'components/general';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showToaster } from 'redux/actions/toasterActions';
import { ENVIRONMENTS, TOASTER_TYPES } from 'shared/enums';
import { getEnvironment } from 'shared/utils/environment';
import * as serviceWorkerRegistration from '../../serviceWorkerRegistration';
import { isServiceWorkerCheckMessage } from './model';

export function ServiceWorkerCheckButton(): ReactElement | null {
  const environment = getEnvironment();
  const dispatch = useDispatch();

  const onClick = (): void => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

    broadcast.onmessage = (event: Message): void => {
      if (!isServiceWorkerCheckMessage(event)) {
        return;
      }
      broadcast.close();

      dispatch(
        showToaster({
          type: TOASTER_TYPES.SUCCESS,
          message: 'ok',
          toasterPosition: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          hideDelay: 2000,
        })
      );
    };

    broadcast.postMessage({
      service: Services.CONFIG,
      method: ServiceMethods.SERVICE_WORKER_CHECK,
    });

    setTimeout(() => {
      if (!broadcast.isClosed) {
        broadcast.close();
        dispatch(
          showToaster({
            type: TOASTER_TYPES.FAILURE,
            message: 'sw check failed',
            toasterPosition: {
              vertical: 'bottom',
              horizontal: 'center',
            },
            hideDelay: 2000,
          })
        );
      }
    }, 5000);
  };

  if (environment === ENVIRONMENTS.PRODUCTION) {
    return null;
  }
  return <ButtonOutlined onClick={onClick}>Check SW</ButtonOutlined>;
}

export function AutoSwReload(): null {
  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

    const interval = setInterval(() => {
      if (broadcast.isClosed) {
        return;
      }
      broadcast.postMessage({
        service: Services.CONFIG,
        method: ServiceMethods.SERVICE_WORKER_CHECK,
      });
    }, 2000);
    const timeout = setTimeout(() => {
      if (!broadcast.isClosed) {
        clearInterval(interval);
        broadcast.close();
        serviceWorkerRegistration.unregister();
        window.location.reload();
      }
    }, 15000);

    broadcast.onmessage = (event: Message): void => {
      if (!isServiceWorkerCheckMessage(event)) {
        return;
      }
      clearInterval(interval);
      clearTimeout(timeout);
      broadcast.close();
    };

    broadcast.postMessage({
      service: Services.CONFIG,
      method: ServiceMethods.SERVICE_WORKER_CHECK,
    });
  }, []);

  return null;
}
