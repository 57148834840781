import { GalleryStateStore } from 'components/common/gallery/galleryStateStore';
import { CurrentDirectoryStore } from 'components/dataProviders/withCurrentDirectory/currentDirectoryStore';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { Store } from 'hooks/createStore';
import { useGetDocumentation } from 'hooks/useGetDocumentation';
import { useRef, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';

export function useDocumentationFileUrlConrol(
  galleryStateStore: GalleryStateStore,
  currentDirectoryStore: CurrentDirectoryStore,
  currentDirDocumentationsStore: Store<DocumentationModel[]>
): void {
  const { get: getDocumentationById } = useGetDocumentation();
  const { fileId, projectId } = useParams<{
    fileId: string | undefined;
    projectId: string;
  }>();
  const projectIdRef = useRef(projectId);
  const history = useHistory();
  const fileToOpenRef = useRef(fileId);
  const wasOpenRef = useRef(!fileId);

  useEffect(() => {
    const galleryState = galleryStateStore.get();

    async function openDocFromUrl(id: string): Promise<void> {
      const foundDoc = await getDocumentationById(id);
      if (!foundDoc || id !== fileToOpenRef.current) {
        fileToOpenRef.current = undefined;
        wasOpenRef.current = true;
        return;
      }

      const unsub = currentDirDocumentationsStore.subscribe(() => {
        unsub();
        const currentDirDocs = currentDirDocumentationsStore.get();
        const index = currentDirDocs.findIndex((file) => {
          return fileToOpenRef.current === file._id;
        });
        if (index === -1) {
          fileToOpenRef.current = undefined;
          wasOpenRef.current = true;
          return;
        }

        wasOpenRef.current = true;
        galleryStateStore.set({
          open: true,
          slide: index,
        });
      });
      currentDirectoryStore.set(
        foundDoc.parentId,
        (...args: any[]) => false
      );
    }

    if (
      fileToOpenRef.current &&
      !wasOpenRef.current &&
      !galleryState.open
    ) {
      openDocFromUrl(fileToOpenRef.current);
    }
  }, [
    history,
    galleryStateStore,
    currentDirectoryStore,
    currentDirDocumentationsStore,
    getDocumentationById,
  ]);

  useEffect(() => {
    return galleryStateStore.subscribe(() => {
      const { open, slide } = galleryStateStore.get();

      if (open && slide !== -1) {
        const currentDirDocs = currentDirDocumentationsStore.get();

        if (currentDirDocs[slide] && currentDirDocs[slide]._id) {
          history.replace(
            `/project/${projectIdRef.current}/documentation/file/${currentDirDocs[slide]._id}`
          );
          wasOpenRef.current = true;
          return;
        }
        return;
      }

      if (!open) {
        const currentDir = currentDirectoryStore.get();
        const dirPart = `/dir/${currentDir}`;

        history.replace(
          `/project/${projectIdRef.current}/documentation${
            currentDir ? dirPart : ''
          }`
        );
      }
    });
  }, [
    currentDirDocumentationsStore,
    currentDirectoryStore,
    galleryStateStore,
    history,
  ]);
}
