import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDocuments, withDocuments } from '../withDocuments';
import { documentsToDocumentsOnTemplateView } from 'presentation/document/documentsToDocumentsOnTemplateView';
import { DocumentsOnTemplateView } from 'presentation/document/documentsOnTemplateView';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { useGetOneInspectionTemplate } from 'hooks/useGetInspectionTemplate';
import {
  displayGenericErrorToaster,
  genericErrorToaster,
} from 'redux/actions/toasterActions';
import { useDispatch } from 'react-redux';
import { useInspectionTemplateChannelListener } from '../../broadcastChannelListeners/withInspectionTemplateChannelListener';
import {
  Message,
  DomainMessagesTypes,
  isDataChanged,
} from 'shared/domain/messages/message';
import { useMountedRef } from 'hooks/useMountRef';

type InspectionTemplateContext = {
  inspectionTemplate: InspectionTemplateModel | undefined;
  inspectionTemplateDocuments: DocumentsOnTemplateView;
  loading: boolean;
  setId: (newId: string) => void;
};

const WithInspectionTemplateContext = React.createContext<
  InspectionTemplateContext | undefined
>(undefined);

const WithInspectionTemplate: React.FC<
  PropsWithChildren<{
    inspectionTemplateId?: string;
  }>
> = withDocuments(({ children, inspectionTemplateId }) => {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();
  const { subscribe } = useInspectionTemplateChannelListener();

  const [_inspectionTemplateId, setInspectionTemplateId] = useState<
    string | undefined
  >(inspectionTemplateId);
  const [inspectionTemplate, setInspectionTemplate] = useState<
    InspectionTemplateModel | undefined
  >();
  const [loading, setLoading] = useState<boolean>(
    Boolean(_inspectionTemplateId)
  );
  const {
    documents,
    loading: documentsLoading,
    setQuery,
  } = useDocuments();

  const [inspectionTemplateDocuments, setInspectionTemplateDocuments] =
    useState<DocumentsOnTemplateView>({
      templateDocuments: [],
      checklistDocuments: {},
    });

  useEffect(() => {
    setInspectionTemplateDocuments(
      documentsToDocumentsOnTemplateView(documents)
    );
  }, [documents]);

  const { get: getOneInspectionTemplate } = useGetOneInspectionTemplate();

  const resyncInspectionTemplate = useCallback(
    (id: string | undefined) => {
      if (!id) return;
      setLoading(true);
      getOneInspectionTemplate(id)
        .then((templateModel) => {
          if (!mountedRef.current) {
            return;
          }
          setInspectionTemplate(templateModel);
          setLoading(false);
        })
        .catch((e) => {
          if (!mountedRef.current) {
            return;
          }
          displayGenericErrorToaster(dispatch);
        });
    },
    [dispatch, getOneInspectionTemplate, mountedRef]
  );

  useEffect(() => {
    if (_inspectionTemplateId) {
      resyncInspectionTemplate(_inspectionTemplateId);
      setQuery({ templateId: _inspectionTemplateId });
    }
  }, [
    _inspectionTemplateId,
    dispatch,
    resyncInspectionTemplate,
    getOneInspectionTemplate,
    setQuery,
  ]);

  const setId = useCallback(
    (id: string) => {
      // fixes https://hustro.atlassian.net/browse/PT-3948
      if (id === _inspectionTemplateId || inspectionTemplate?._id === id) {
        return;
      }
      setLoading(true);
      setInspectionTemplateId(id);
      setQuery({ templateId: id });
      resyncInspectionTemplate(id);
    },
    [
      _inspectionTemplateId,
      inspectionTemplate?._id,
      setQuery,
      resyncInspectionTemplate,
    ]
  );

  useEffect(
    function dataListener() {
      const unsubscribe = subscribe((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (
          (event.data && isDataChanged(event)) ||
          event.type === DomainMessagesTypes.tableChanged
        ) {
          resyncInspectionTemplate(_inspectionTemplateId);
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [
      _inspectionTemplateId,
      mountedRef,
      resyncInspectionTemplate,
      subscribe,
    ]
  );

  const ctx = {
    inspectionTemplate,
    inspectionTemplateDocuments,
    loading: loading || documentsLoading,
    setId,
  };

  return (
    <WithInspectionTemplateContext.Provider value={ctx}>
      {children}
    </WithInspectionTemplateContext.Provider>
  );
});

const withInspectionTemplate =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithInspectionTemplate>
      <Component {...props} />
    </WithInspectionTemplate>
  );

function useInspectionTemplate(): InspectionTemplateContext {
  const context = React.useContext(WithInspectionTemplateContext);
  if (context === undefined) {
    throw new Error(
      'useInspectionTemplate must be used within an InspectionTemplateContextProvider'
    );
  }
  return context;
}

export {
  WithInspectionTemplate,
  useInspectionTemplate,
  withInspectionTemplate,
};
