import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';

export function isDataFlushedMessage(event: Message): boolean {
  return event.type === DomainMessagesTypes.dataFlushed;
}

export function isProjectDataFlushedMessage(event: Message): boolean {
  return event.type === DomainMessagesTypes.projectDataFlushed;
}
