import { useCallback } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { useIssueChannelListener } from 'components/broadcastChannelListeners/withIssueChannelListener';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { createEntityPromise } from './createEntityPromise';

type GetIssue = {
  getIssue: (id?: string) => Promise<IssueModel>;
};

function isMatchingMessage(id: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.issue &&
    Boolean(event.data) &&
    event.data._id === id
  );
}

function isMatchingErrorMessage(id: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.issue &&
    Boolean(event.error) &&
    event.error.id === id
  );
}

export function useGetIssue(): GetIssue {
  const { subscribe } = useIssueChannelListener();

  const getIssue = useCallback(
    (id?: string): Promise<IssueModel> => {
      if (!id) {
        return Promise.reject(new Error('Id is undefined or empty.'));
      }
      const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
      return createEntityPromise({
        entityName: 'issue',
        subscribe,
        isMatchingMessage: (event: Message) =>
          isMatchingMessage(id, event),
        postMessage: function postGetOneIssue() {
          broadcast.postMessage({
            service: Services.ISSUES,
            method: ServiceMethods.GET_ONE,
            data: {
              id: id,
            },
          });
          broadcast.close();
        },
        isMatchingErrorMessage: (event: Message) =>
          isMatchingErrorMessage(id, event),
      });
    },
    [subscribe]
  );

  return {
    getIssue: getIssue,
  };
}
