import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    w50: {
      width: '50%',
    },
    label: {
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '13px',
      color: '#979797',
      marginBottom: '12px',
    },
    value: {
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '17px',
      minHeight: '17px',
    },
    chipsWrapper: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      backgroundColor: '#F2F2F2',
      marginRight: '8px',
      marginBottom: '8px',
      maxWidth: '100%',
    },
    emptyPlaceholder: { opacity: 0.4 },
  })
);
