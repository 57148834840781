import { Subscribe } from 'components/broadcastChannelListeners/channelListener/channelListener';
import { useCompanyChannelListener } from 'components/broadcastChannelListeners/withCompanyChannelListener';
import { useContractChannelListener } from 'components/broadcastChannelListeners/withContractChannelListener';
import { useIssueFormChannelListener } from 'components/broadcastChannelListeners/withIssueFormChannelListener';
import { useLevelChannelListener } from 'components/broadcastChannelListeners/withLevelChannelListener';
import { Spinner } from 'components/core';
import { useGetAllIssueFormData } from 'hooks/useGetAllIssueFormData';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { FieldSkeleton, IssueForm } from 'shared/types/form';
import { useEntityDataSubscription } from '../useEntityDataSubscription';
import { IssueFormContextType } from './types';

const IssueFormContext = React.createContext<
  IssueFormContextType | undefined
>(undefined);

function WithIssueForm({ children }: PropsWithChildren<{}>): ReactElement {
  const [issueForm, _setIssueForm] = useState<{ items: IssueForm }>({
    items: [],
  });

  const [loading, setLoading] = useState<boolean>(true);
  const { subscribe: subscribeIssueForm } = useIssueFormChannelListener();
  const { subscribe: subscribeCompany } = useCompanyChannelListener();
  const { subscribe: subscribeContract } = useContractChannelListener();
  const { subscribe: subscribeLevel } = useLevelChannelListener();

  const { getAll: getAllIssueFormData } = useGetAllIssueFormData();

  const setIssueForm = useCallback(
    (res: { items: IssueForm }) => {
      _setIssueForm(res);
    },
    [_setIssueForm]
  );
  const entityName = 'issue form';

  useEntityDataSubscription({
    subscribe: subscribeIssueForm,
    getAll: getAllIssueFormData,
    setEntity: setIssueForm,
    setLoading,
    entityName,
  });

  useEntityDataSubscription({
    subscribe: subscribeCompany,
    getAll: getAllIssueFormData,
    setEntity: setIssueForm,
    setLoading,
    entityName,
  });

  useEntityDataSubscription({
    subscribe: subscribeContract,
    getAll: getAllIssueFormData,
    setEntity: setIssueForm,
    setLoading,
    entityName,
  });

  useEntityDataSubscription({
    subscribe: subscribeLevel,
    getAll: getAllIssueFormData,
    setEntity: setIssueForm,
    setLoading,
    entityName,
  });

  const ctx = {
    issueForm: issueForm.items,
  };

  if (loading) {
    return <Spinner reason={`Loading issue form: ${loading}`} />;
  }

  return (
    <IssueFormContext.Provider value={ctx}>
      {children}
    </IssueFormContext.Provider>
  );
}

export const withIssueForm =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithIssueForm>
      <Component {...props} />
    </WithIssueForm>
  );

export function useIssueForm(): IssueFormContextType {
  {
    const context = React.useContext(IssueFormContext);
    if (context === undefined) {
      throw new Error(
        'useIssueForm must be used within a IssueFormContextProvider'
      );
    }
    return context;
  }
}
