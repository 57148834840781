import { Theme as DefaultTheme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      wrappedContent: {
        marginLeft: '16px',
        marginRight: '15px',
      },
      singleContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingLeft: ({ isMobile }): string =>
          isMobile ? '20px' : '84px',
        paddingRight: ({ isMobile }): string => (isMobile ? '20px' : '45'),
        overflowY: 'auto',
        maxWidth: '847px',
      },
      header: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > span': {
          fontFamily: 'Archivo',
          fontSize: '1rem',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '17px',
          letterSpacing: '0em',
          textAlign: 'left',
          marginLeft: '8px',
          marginTop: '30px',
          marginBottom: '30px',
        },
      },
      summaryWithActions: {
        marginBottom: '20px',
        '& h1': {
          fontFamily: 'Archivo',
          fontSize: '1.5rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '29px',
          letterSpacing: '0em',
          textAlign: 'left',
        },
      },
      actionsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'start',
      },
      propertiesContainer: {
        marginTop: '22px',
        marginBottom: '25px',
        fontFamily: 'Archivo',
        fontStyle: 'normal',
        letterSpacing: '0em',
        textAlign: 'left',
        display: 'flex',
        // flexDirection: 'column',
        // '& > div': {
        //   display: 'flex',
        //   flexDirection: 'row',
        // },
        '& .row': {
          marginLeft: '16px',
        },
        '& .row + .row': {
          marginTop: '29px',
        },
        '& .row.chips + .row.chips': {
          marginTop: '22px',
        },
      },
      graphicUploaderWrapper: {
        width: '100%',
        marginTop: '22px',
        marginBottom: '24px',
      },
      protocolContainer: {
        fontFamily: 'Archivo',
        textAlign: 'left',
        '& h2': {
          fontSize: '1.5rem',
          fontStyle: 'normal',
          fontWeight: 600,
          lineHeight: '29px',
          letterSpacing: '0em',
          marginTop: '26px',
        },
        '& .MuiAccordion-root': {
          marginLeft: '16px',
          marginRight: '15px',
        },
      },
    })
);
