import React, { ReactElement, useEffect } from 'react';

import { SingleTemplateProps } from './types';
import { MemoSingleTemplatePresentational } from './presentational';
import {
  useInspectionTemplate,
  withInspectionTemplate,
} from 'components/dataProviders/withInspectionTemplate';
import { SelectTemplatePrompt } from './SelectTemplatePrompt';
import { TemplateListErrorText } from 'views/template/list/types';
import { Spinner } from 'components/core';
import { MemoSingleTemplateError } from '../SingleError';

function SingleTemplate(props: SingleTemplateProps): ReactElement {
  const {
    error,
    template,
    canEditTemplate,
    editTemplate,
    canRemoveTemplate,
    removeTemplate,
    startInspection,
    canStartInspection,
  } = props;
  const templateId = template?._id;

  const {
    inspectionTemplateDocuments,
    setId,
    loading: loadingDocuments,
  } = useInspectionTemplate();

  useEffect(() => {
    if (templateId) {
      setId(templateId);
    }
  }, [setId, templateId]);

  const visibleChecklistItems = React.useMemo(
    () =>
      template ? template.checklist.filter((item) => !item.deleted) : [],
    [template]
  );

  if (error?.templateErrorType === TemplateListErrorText.noTemplates) {
    return <SelectTemplatePrompt />;
  }

  if (
    error?.templateErrorType === TemplateListErrorText.noFilteredTemplates
  ) {
    return <MemoSingleTemplateError error={error} />;
  }

  return template ? (
    <MemoSingleTemplatePresentational
      template={template}
      generalNormDocuments={inspectionTemplateDocuments.templateDocuments}
      loadingDocuments={loadingDocuments}
      canEditTemplate={canEditTemplate}
      editTemplate={editTemplate}
      canRemoveTemplate={canRemoveTemplate}
      removeTemplate={removeTemplate}
      checklist={visibleChecklistItems}
      startInspection={startInspection}
      canStartInspection={canStartInspection}
    />
  ) : (
    <Spinner position='static' reason='MemoSingleTemplate !template' />
  );
}

export const MemoSingleTemplate = React.memo(
  withInspectionTemplate(SingleTemplate)
);
