import { DocumentationEditModel } from 'shared/domain/documentation/documentationModel';
import { EditDocumentationUseCase } from 'shared/domain/documentation/editDocumentation';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { LevelEditsOutDto } from 'shared/dtos/out/level';
import {
  DocumentationChanges,
  DocumentationEntity,
} from 'serviceWorker/repository/documentation/entity';
import {
  documentationEditModelToChanges,
  documentationEditModelToMapChanges,
} from 'serviceWorker/repository/documentation/mappings';
import { LevelEntity } from 'shared/domain/level/types/entity';
import { broadcastUpdatedDocumentation } from 'serviceWorker/broadcasts/documentations';
import { broadcastUpdatedLevels } from 'serviceWorker/broadcasts/levels';
import {
  updateOne as editDocumentation,
  getOne as getOneDocumentation,
} from 'serviceWorker/db/documentations';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getLevels } from 'serviceWorker/db/levels';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';
import { LevelHttpEditRequestModel } from '../httpQueue/level/types';
import { createUniqueId } from 'shared/utils/id/id';

class DocumentationEditor implements EditDocumentationUseCase {
  constructor(
    private editDocumentation: (
      localId: number,
      changes: DocumentationChanges
    ) => Promise<number>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private getLevels: () => Promise<LevelEntity[]>,
    private getOneDocumentation: (
      id: number
    ) => Promise<DocumentationEntity | undefined>,
    private broadcastDocumentationUpdated: (documentation: number) => void,
    private broadcastLevelsUpdated: (...args: any[]) => void
  ) {}
  async execute(
    documentationEditModel: DocumentationEditModel
  ): Promise<void> {
    debugLog('EditDocumentationUseCase', documentationEditModel);
    const localId = documentationEditModel.localId;
    const currentDocumentation = await this.getOneDocumentation(localId);

    if (!currentDocumentation) {
      throw new Error(`Cannot find documentation ${localId}`);
    }

    const documentationChanges: DocumentationChanges =
      documentationEditModelToChanges(documentationEditModel);

    await this.editDocumentation(localId, documentationChanges);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        localId,
        projectId: currentProject?._id,
        changes: documentationChanges,
      },
      entityType: HttpRequestModelType.documentation,
      status: HttpRequestStatus.NEW,
    });

    const levelChangesOutDto = await this.createLevelChangesOutDto(
      documentationEditModel,
      currentDocumentation
    );

    if (levelChangesOutDto.length) {
      const request: LevelHttpEditRequestModel = {
        createdAt: Date.now(),
        method: 'PUT',
        data: {
          //TODO get project from event and use uniqueId properly
          projectId: currentProject?._id as string,
          changes: levelChangesOutDto,
          uniqueId: createUniqueId(),
        },
        url: `/project/${currentProject?._id}/level`,
        entityType: HttpRequestModelType.level,
        status: HttpRequestStatus.NEW,
      };
      await this.addRequest(request);
    } else {
      this.broadcastLevelsUpdated([]);
    }

    this.broadcastDocumentationUpdated(localId);
  }

  private async createLevelChangesOutDto(
    documentationEditModel: DocumentationEditModel,
    currentDocumentation: DocumentationEntity
  ): Promise<LevelEditsOutDto> {
    const mapsMap = new Map();
    if (!documentationEditModel.levels) {
      return Promise.resolve([]);
    }

    const levels = await this.getLevels();
    const currentlyUsedIn: string[] = levels.reduce((usedLevels, lvl) => {
      const found = lvl.maps.find(
        (map) => map.documentationId === currentDocumentation?._id
      );
      if (found && found._id) {
        usedLevels.push(lvl._id);
        mapsMap.set(lvl._id, {
          documentationId: found.documentationId,
          versionId: found.versionId,
        });
      }
      return usedLevels;
    }, [] as string[]);
    const { toRemove, toAdd } = documentationEditModelToMapChanges(
      documentationEditModel,
      currentlyUsedIn
    );

    const removals: LevelEditsOutDto = [];
    toRemove.forEach((mapIdToRemoveDocumentation) => {
      const documentationIdentificator = mapsMap.get(
        mapIdToRemoveDocumentation
      );

      if (!documentationIdentificator) {
        return;
      }
      const { documentationId, versionId } = documentationIdentificator;

      return removals.push({
        levelId: mapIdToRemoveDocumentation,
        documentation: {
          remove: [
            {
              documentationId,
              versionId,
            },
          ],
        },
      });
    });

    const additions: LevelEditsOutDto = [];
    toAdd.forEach((mapIdToAddDocumentation) => {
      return additions.push({
        levelId: mapIdToAddDocumentation,
        documentation: {
          add: [
            {
              documentationId: currentDocumentation._id,
              versionId:
                currentDocumentation.versions[
                  currentDocumentation.versions.length - 1
                ]._id,
            },
          ],
        },
      });
    });
    return [...removals, ...additions];
  }
}

export const documenationEditor = new DocumentationEditor(
  editDocumentation,
  addRequest,
  getCurrentProject,
  getLevels,
  getOneDocumentation,
  broadcastUpdatedDocumentation,
  broadcastUpdatedLevels
);
