import { useProcessesWithField } from 'charts/shared/useProcessesWithField';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { ApexSeriesAndLabels, Chart } from 'shared/types/analytics';
import { ChartType } from 'shared/types/analytics';
import { ChartDetailsContext } from '../../views/analytics/chartDetails/ChartDetailsProvider';
import { customAccessFunction } from '../shared/access';
import { useGetChart } from '../shared/hooks';
import { listViewDonutChartOptions } from '../shared/options/donutChart';
import { GenericChartState } from '../shared/types';
import { useHsHazardTypeFilters } from './filters';
import { detailedViewHsHazardTypeChartOptions } from './options';
import { HazardTypeChartContext } from './types';

export function useHsHazardType(): HazardTypeChartContext {
  const processesWithHazardCategory = useProcessesWithField(
    IssueFieldNames.hazardCategory
  );
  const access = useCallback(() => {
    return Boolean(processesWithHazardCategory.length);
  }, [processesWithHazardCategory]);

  const filters = useHsHazardTypeFilters(processesWithHazardCategory);
  const additionalParams = useMemo(
    () => ({
      visibleProcesses: processesWithHazardCategory.map(
        (process) => process._id
      ),
    }),
    [processesWithHazardCategory]
  );

  const { series, loading, load, reload, updatedAt } =
    useGetChart<ApexSeriesAndLabels>(
      Chart.hsHazardType,
      filters,
      additionalParams
    );

  const isLoading = !filters || loading;

  const isDetailedView = useContext(ChartDetailsContext);
  const options = isDetailedView
    ? detailedViewHsHazardTypeChartOptions
    : listViewDonutChartOptions;

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options,
  });

  useEffect(() => {
    setState((prev: GenericChartState): GenericChartState => {
      return {
        ...prev,
        series: series[0],
        options: {
          ...options,
          labels: series[1],
        },
      };
    });
  }, [series, options]);

  return {
    state,
    filters,
    titleId: 'extendedField_Hazard_category',
    chartType: ChartType.donut,
    availableTypes: [ChartType.donut],
    canAccess: customAccessFunction(access),
    loading: isLoading,
    load: load,
    forceUpdate: reload,
    updatedAt: (): number => updatedAt,
  };
}
