import { Button } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type ButtonTextProps = PropsWithChildren<any>;

const ButtonText = ({
  children,
  ...props
}: ButtonTextProps): JSX.Element => {
  return (
    <Button {...props} variant='text' color='primary'>
      {children}
    </Button>
  );
};

export const MemoButtonText = React.memo(ButtonText);
export default ButtonText;
