import React, { CSSProperties } from 'react';
import { frontendVersionWithBuildNumber } from 'shared/version';

const getStyles = (): CSSProperties => ({
  position: 'fixed' as 'fixed',
  bottom: 0,
  left: 0,
  padding: 7,
  fontSize: '0.5rem',
  backgroundColor: 'transparent',
  color: 'white',
});

const FrontVersion = (): JSX.Element => (
  <div style={getStyles()}>
    <div>version:</div>
    <div>{frontendVersionWithBuildNumber}</div>
  </div>
);

export default React.memo(FrontVersion);
