import { Writer } from '@transcend-io/conflux';
import streamSaver from 'streamsaver';
import { ReadableStream as ReadableStreamPonyfill } from 'web-streams-polyfill/ponyfill';

export function downloadZip(
  signedRequest: string[],
  onDone: () => void
): void {
  const ModernReadableStream = window.WritableStream
    ? window.ReadableStream
    : ReadableStreamPonyfill;

  const files = signedRequest.values();
  const firstFileName = decodeURIComponent(
    decodeURIComponent(signedRequest[0])
  )
    .split('filename=')[1]
    .split('"&')[0]
    .replace('"', '');
  const reportNameArray = firstFileName.split('_');
  reportNameArray.pop();
  const reportName = reportNameArray.join('_');

  new (ModernReadableStream as typeof window.ReadableStream)({
    // pull gets executed whenever some
    // other stream request more data
    async pull(ctrl) {
      const { done, value } = files.next();
      if (done) {
        onDone();
        return ctrl.close();
      }

      const filename = decodeURIComponent(decodeURIComponent(value))
        .split('filename=')[1]
        .split('"&')[0]
        .replace('"', '');

      const url = value;
      const { body } = await fetch(url);

      return ctrl.enqueue({
        name: `${filename}`,
        stream: () => body,
      });
    },
  })
    .pipeThrough(new Writer())
    .pipeTo(streamSaver.createWriteStream(`${reportName}.zip`));
}
