import { getAvailableFields } from 'shared/domain/visibleField/availableFields';
import { HashMap } from 'shared/types/commonView';
import { Process } from 'shared/types/process';
import { VisibleFieldModel } from './visibleFieldModel';

export function toVisibleFieldModelMap(
  fields: HashMap<VisibleFieldModel[]>
): HashMap<HashMap<boolean>> {
  const initialFields = getInitialFields();
  const mapped = Object.keys(fields).reduce(
    (result, key) => {
      fields[key].forEach((field) => {
        result[key][field.fieldName] = true;
      });
      return result;
    },
    {
      [Process.HS]: { ...initialFields[Process.HS] },
      [Process.QA]: { ...initialFields[Process.QA] },
      [Process.ENV]: { ...initialFields[Process.ENV] },
      [Process.TSK]: { ...initialFields[Process.TSK] },
    }
  );

  return mapped;
}

function getInitialFields(): HashMap<HashMap<boolean>> {
  const result = {
    [Process.HS]: {},
    [Process.QA]: {},
    [Process.ENV]: {},
    [Process.TSK]: {},
  };
  getAvailableFields().forEach((field) => {
    if (!field.canBeDisabled) {
      field.processes.forEach((process) => {
        result[process][field.fieldName] = true;
      });
    }
  });
  return result;
}
