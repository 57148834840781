import { DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { useCreateProject } from 'components/dataCreationForms/project/withCreateProject';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { ProjectForm } from 'components/project/projectForm';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

export function CreateProjectDialog(): ReactElement | null {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
    formName,
  } = useCreateProject();
  useFormCleanupOnUnmount(formName);
  if (!initialValues) {
    return null;
  }
  return (
    <Dialog fullWidth open={open} onClose={closeDialog}>
      <WithInputForm
        values={initialValues}
        errors={{}}
        handleFormValidation={handleFormValidation}
        handleFieldValidation={validate}
        onSubmit={submitForm}
        eventType={SUBMIT_EVENT_TYPE}
        dispatchSubmit={releaseSubmitEvent}
        formName={formName}
      >
        <DialogTitle>
          <FormattedMessage id='create_project' />
        </DialogTitle>
        <Divider />

        <DialogContent>
          <ProjectForm isEdit={false} isDialog={true} />
        </DialogContent>

        <Divider />

        <DialogActions>
          <DialogButtons
            isPosting={isPosting}
            onSubmit={releaseSubmitEvent}
            onCancel={closeDialog}
          />
        </DialogActions>
      </WithInputForm>
    </Dialog>
  );
}
