import { Box } from '@mui/material';
import {
  MemoStatefulAutocompleteWithText,
  MemoStatefulMultiselect,
} from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import React from 'react';
import { ContractFormPresentationalProps } from './types';

export function ContractFormPresentational({
  labelFieldProps,
  partiesFieldProps,
}: ContractFormPresentationalProps): React.ReactElement {
  return (
    <Box>
      <MemoStatefulAutocompleteWithText {...labelFieldProps} />
      <MemoStatefulMultiselect {...partiesFieldProps} />
    </Box>
  );
}
