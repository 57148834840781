import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  displayGenericErrorToaster,
  genericErrorToaster,
} from 'redux/actions/toasterActions';
import { logError } from 'setup/logger/logError';
import { HashMap } from 'shared/types/commonView';
import {
  InspectionFlatForm,
  toProtocolItems,
} from 'views/inspections/wizard/model';
import { GU } from '../../common/graphicUploader/types';
import { SubmitOptions } from '../../dataCreationForms/withInputForm';
import { useInspection } from '../withInspection';
import { useInspectionTemplate } from '../withInspectionTemplate';
import {
  filterUnchangedProtocolItems,
  finishInspection,
  inspectionDtoToModel,
  submitInspection,
  toInspectionOutDto,
} from './model';
import { OnDone, SubmitForm } from './types';
import { useUsers } from '../withUsers';

type InspectionSubmit = {
  submitForm: SubmitForm;
  isPosting: boolean;
};

type InspectionSubmitInternal = {
  submitForm: SubmitForm;
  isPosting: boolean;
  _setOnDone: (
    setStateAction: (prevState: OnDone | undefined) => OnDone | undefined
  ) => void;
};

const InspectionSubmitContext = createContext<
  InspectionSubmitInternal | undefined
>(undefined);

const WithInspectionSubmit: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const abortSignalRef = useRef<AbortController>(new AbortController());
  const dispatch = useDispatch();
  // const { usersStore } = useUsersStore();
  const {
    all: { items: users },
  } = useUsers();
  useEffect(() => {
    abortSignalRef.current.abort();
    abortSignalRef.current = new AbortController();

    return (): void => {
      abortSignalRef.current.abort();
    };
  }, []);

  const { inspectionModel, setInspection } = useInspection();
  const { inspectionTemplate: template } = useInspectionTemplate();

  const [isPosting, setIsPosting] = useState(false);
  const [onDone, _setOnDone] = useState<undefined | OnDone>(undefined);

  const submitForm = useCallback(
    (
      form: InspectionFlatForm,
      uploaders?: HashMap<GU>,
      options?: SubmitOptions
    ) => {
      if (!template) {
        return Promise.reject('Template missing');
      }
      const inspectionOutDto = toInspectionOutDto(inspectionModel, form);
      const allProtocolItems = toProtocolItems(form);
      const protocolChanges = filterUnchangedProtocolItems(
        inspectionModel,
        allProtocolItems
      );
      setIsPosting(true);

      return submitInspection({
        templateId: template?._id,
        inspectionId: inspectionModel?._id,
        body: {
          inspection: inspectionOutDto,
          protocol: protocolChanges,
          allProtocolItems: allProtocolItems,
        },
        signal: abortSignalRef.current.signal,
        uploaders: uploaders || {},
        dispatch,
      })
        .then((response) => {
          if (options?.finish) {
            return finishInspection({
              inspectionId: response.inspection._id,
              signal: abortSignalRef.current.signal,
            })
              .then((finishedInspectionResponse) => {
                response.inspection = finishedInspectionResponse;
                return response;
              })
              .catch((e) => {
                logError(
                  `Completing inspection id:${response.inspection._id} failed.`,
                  e
                );
                displayGenericErrorToaster(dispatch);
                // we do not rethrow here. Completing failed so we want to reload inspection by going to preview screen.
                return response;
              });
          }
          return response;
        })
        .then((response) => {
          setIsPosting(false);
          setInspection(inspectionDtoToModel(response.inspection, users));
          return onDone ? onDone(response) : response;
        })
        .catch((e) => {
          setIsPosting(false);
        });
    },
    [template, inspectionModel, dispatch, setInspection, onDone, users]
  );

  const ctx = useMemo(
    () => ({
      submitForm,
      isPosting,
      _setOnDone,
    }),
    [submitForm, isPosting, _setOnDone]
  );

  return (
    <InspectionSubmitContext.Provider value={ctx}>
      {children}
    </InspectionSubmitContext.Provider>
  );
};

function useInspectionSubmit(onDone?: OnDone): InspectionSubmit {
  const context = React.useContext(InspectionSubmitContext);
  if (context === undefined) {
    throw new Error(
      'useInspectionSubmit must be used within an InspectionSubmitContextProvider'
    );
  }

  useEffect(() => {
    if (context._setOnDone) {
      context._setOnDone(() => onDone);
    }
  }, [context, onDone]);

  return context;
}

const withInspectionSubmit =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithInspectionSubmit>
      <Component {...props} />
    </WithInspectionSubmit>
  );

export { useInspectionSubmit, withInspectionSubmit };
