import React, { ReactElement } from 'react';
import { useStyles } from './styles';

type IssueCardChipValue =
  | string
  | { color: string; value: string; textColor?: string; border?: string };
type IssueCardChipProps = {
  values: IssueCardChipValue[];
};
function IssueCardChip(props: IssueCardChipProps): ReactElement {
  const { values } = props;
  const classes = useStyles();
  return (
    <>
      {values.map((val) =>
        typeof val === 'string' ? (
          <div key={val} className={classes.chip}>
            {val}
          </div>
        ) : (
          <div
            key={val.value}
            className={classes.chip}
            style={{
              backgroundColor: val.color,
              color: val.textColor || 'white',
              border: val.border || 'none',
            }}
          >
            {val.value}
          </div>
        )
      )}
    </>
  );
}

export const MemoIssueCardChip = React.memo(IssueCardChip);
