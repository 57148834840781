import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import * as config from 'serviceWorker/db/config';
import * as visibleFields from 'serviceWorker/db/visibleFields';
import * as visibleFieldsService from 'serviceWorker/db/visibleFieldsService';
import { debounce } from 'serviceWorker/helpers/debounce';

import { ChannelNames } from 'shared/domain/channelNames';

import { EditVisibleFieldCustomEvent } from 'serviceWorker/api/types';
import { broadcastAllVisibleFields } from 'serviceWorker/broadcasts/visibleFields';
import { debugLog } from 'shared/logger/debugLog';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import { visibleFieldsEditor } from './editVisibleFields';
import {
  fetchUpdatedVisibleFields,
  fetchVisibleFields,
} from './fetchVisibleFields';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullVisibleFields: Pull = makePullEntity({
  fetch: fetchVisibleFields,
  channelName: ChannelNames.visibleFieldChannel,
  entityName: 'visibleFields',
  entityRepository: visibleFields,
  entityService: visibleFieldsService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullVisibleFieldsHandler = makePullHandler(
  config,
  visibleFields,
  visibleFieldsService,
  pullVisibleFields,
  clearBroadcasts
);

const synchronizeVisibleFields = makeSynchronizeEntity({
  configRepository: config,
  entityService: visibleFieldsService,
  entityRepository: visibleFields,
  pullEntityHandler: pullVisibleFieldsHandler,
  fetchUpdatedEntities: fetchUpdatedVisibleFields,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'visibleFields',
  channelName: ChannelNames.visibleFieldChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeVisibleFields.bind(null, broadcastAllVisibleFields),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncVisibleFields,
    syncDebounced
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editVisibleFields,
    async function onEditVisibleFields(
      e: EditVisibleFieldCustomEvent
    ): Promise<void> {
      debugLog('editVisibleFields event', e);
      visibleFieldsEditor.execute(
        e.detail.visibleFieldsEdit,
        e.detail.uniqueId
      );
    }
  );
};
