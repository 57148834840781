import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { SiteEditModel } from './types/model';

export function startSiteEdit(
  siteEditModel: SiteEditModel,
  uniqueId: string
): void {
  debugLog('startSiteEdit', siteEditModel, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{ siteEditModel: SiteEditModel; uniqueId: string }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.SITES,
    method: ServiceMethods.EDIT,
    data: {
      siteEditModel,
      uniqueId,
    },
  });
  broadcast.close();
}
