import { DocumentationFile } from 'components/dataProviders/withDocumentationController/types';
import { useDocumentationForm } from 'components/dataCreationForms/withDocumentationForm';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DocumentationForm } from 'components/documentation/DocumentationForm';
import { ReactElement, useEffect, useMemo } from 'react';
import { handleFormValidation, validate } from './validation';

type DocumentationEditProps = {
  file: DocumentationFile;
  close: () => void;
};

function _DocumentationEdit({
  file,
  close,
}: DocumentationEditProps): ReactElement {
  const name = file.title;
  const number = file.description;
  const localId = file.localId;
  const {
    submitForm,
    isPosting,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    formName,
    setLocalId,
    setDocumentation,
    setAfterSubmitSuccess,
  } = useDocumentationForm();

  useEffect(() => {
    if (typeof localId === 'number') setLocalId(localId);
  }, [setLocalId, localId]);

  useEffect(() => {
    setDocumentation(file);
  }, [setDocumentation, file]);

  useEffect(() => {
    if (typeof localId !== 'number') close();
  }, [localId, close]);

  useEffect(() => {
    setAfterSubmitSuccess(close);
  }, [setAfterSubmitSuccess, close]);

  const initialValues = useMemo(() => {
    return { name, number };
  }, [name, number]);

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <DocumentationForm
        isPosting={isPosting}
        onCancel={close}
        onSubmit={releaseSubmitEvent}
        file={file}
      />
    </WithInputForm>
  );
}

export const DocumentationEdit = _DocumentationEdit;
