import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { isClientAdmin } from 'shared/domain/role/isClientAdmin';
import { UserEditUseCase } from 'shared/domain/user/editUser';
import { UserEditModel } from 'shared/domain/user/userModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { getOne as getUser } from 'serviceWorker/db/users';
import { UserRole } from 'shared/types/userRole';
import { debugLog } from 'shared/logger/debugLog';
import { userDeleter } from './deleteUser';
import {
  UserHttpCreateRequestModel,
  UserHttpDeleteRequestModel,
  UserHttpEditPermissionsRequestModel,
  UserHttpEditRequestModel,
} from '../httpQueue/user/types';
import { createUniqueId } from 'shared/utils/id/id';

export class UserEditor implements UserEditUseCase {
  constructor(
    private addRequest: (
      request:
        | UserHttpEditRequestModel
        | UserHttpCreateRequestModel
        | UserHttpDeleteRequestModel
        | UserHttpEditPermissionsRequestModel
    ) => Promise<any>
  ) {}

  private async recreatePermissions({
    userId,
    userRole,
    projectId,
    organizationId,
    userPermissionEditRequest,
  }: {
    userRole: UserRole.admin | UserRole.clientAdmin;
    organizationId: string;
    userPermissionEditRequest: UserHttpEditPermissionsRequestModel;
    projectId: string;
    userId: string;
  }): Promise<void> {
    await userDeleter.execute(
      { _id: userId, role: userRole, projectId, organizationId },
      createUniqueId()
    );
    await this.addRequest(userPermissionEditRequest);
  }

  async execute(
    userEditModel: UserEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('UserEditUseCase', userEditModel);
    const userEntity = await getUser(userEditModel._id);
    if (!userEntity) {
      throw new Error('Cannot find user.');
    }
    const userIdUrl = `/user/${userEntity._id}`;
    const { label, permissions } = userEditModel;
    // uniqueId cannot be used twice
    let usedUniqueId = false;
    if (permissions) {
      const permissionUrl = userIdUrl + '/permission';

      // it is not allowed to simply downgrade admin permissions.
      // you have to use DELETE first and POST user after it
      if (isAdmin(userEntity.role) || isClientAdmin(userEntity.role)) {
        const userPermissionEditRequest: UserHttpEditPermissionsRequestModel =
          {
            createdAt: Date.now(),
            method: 'PUT',
            data: {
              userEditOutDto: { permissions },
              uniqueId,
            },
            url: permissionUrl,
            entityType: HttpRequestModelType.user,
            status: HttpRequestStatus.NEW,
          };

        this.recreatePermissions({
          userId: userEditModel._id,
          projectId: userEditModel.projectId,
          organizationId: userEditModel.organizationId,
          userPermissionEditRequest,
          userRole: UserRole.admin,
        });
        usedUniqueId = true;
      } else {
        const permissionChangeRequest: UserHttpEditPermissionsRequestModel =
          {
            createdAt: Date.now(),
            method: 'PUT',
            data: {
              userEditOutDto: { permissions },
              uniqueId,
            },
            url: permissionUrl,
            entityType: HttpRequestModelType.user,
            status: HttpRequestStatus.NEW,
          };
        await this.addRequest(permissionChangeRequest);
        usedUniqueId = true;
      }
    }

    if (label) {
      debugLog('labelChangeRequest', label);
      const labelChangeRequest: UserHttpEditRequestModel = {
        createdAt: Date.now(),
        method: 'PUT',
        data: {
          userEditOutDto: { label },
          uniqueId: usedUniqueId ? createUniqueId() : uniqueId,
        },
        url: userIdUrl,
        entityType: HttpRequestModelType.user,
        status: HttpRequestStatus.NEW,
      };
      await this.addRequest(labelChangeRequest);
    }
  }
}

export const userEditor = new UserEditor(addRequest);
