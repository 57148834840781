// @ts-nocheck
// react 18 types
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Layout, NotFoundPage } from 'components/core';
import { CurrentUser, IssueTable } from '../../views';
import { useSelector } from 'react-redux';
import { StoreState } from '../types/core';
import PrivateRoute from './PrivateRoute';
import ProjectRoutes from './ProjectRoutes';
import RedirectUnauthorized from './RedirectUnauthorized';
import { WithIssueGetParameters } from 'components/dataProviders/withIssueGetParameters';
import { MemoCompany } from 'views/companies/single';
import { MemoCompanies } from 'views/companies/table';
import { MemoContract } from 'views/contracts/single';
import { MemoContracts } from 'views/contracts/table';
import { SelectProject } from 'views/projects/selection';
import ReportDownloadView from 'views/reports/ReportDownloadView';
import VersionMismatchPage from 'components/core/VersionMismatchPage';
import { useSetServiceWorkerConfig } from './helpers';
import { MemoAnalyticsRoute } from './routeComponents/analytics';
import {
  AuthorizableCreateCompanyWizard,
  AuthorizableEditCompanyWizard,
} from './routeComponents/company';
import {
  AuthorizableCreateContractWizard,
  AuthorizableEditContractWizard,
} from './routeComponents/contract';
import {
  AuthorizableFieldSettings,
  AuthorizableFieldsSettings,
} from './routeComponents/fieldSettings';
import {
  AuthorizableInspectionWizard,
  AuthorizableInspections,
} from './routeComponents/inspection';
import {
  AuthorizableEditProjectWizard,
  AuthorizableProjectSettingsView,
} from './routeComponents/project';
import { AuthorizableEditOrganizationWizard } from './routeComponents/organization';
import { AuthorizableSiteView } from './routeComponents/site';
import { AuthorizableTemplateWizard } from './routeComponents/inspectionTemplate';
import {
  AuthorizableCreateUserWizard,
  AuthorizableEditUserWizard,
  AuthorizableUser,
  AuthorizableUsers,
} from './routeComponents/user';
import { LoginContainer } from './LoginContainer';

const DefaultContainer = (): React.ReactElement => {
  return (
    <WithIssueGetParameters>
      <Layout>
        <Switch>
          <Route
            path='/select-project'
            render={(): React.ReactElement => (
              <PrivateRoute component={SelectProject} />
            )}
          />
          <Route
            exact
            path='/issue'
            render={(): React.ReactElement => (
              <React.Suspense fallback={null}>
                <PrivateRoute component={IssueTable} />
              </React.Suspense>
            )}
          />
          <Route
            exact
            path='/organization/:id/edit'
            render={(): React.ReactElement => (
              <PrivateRoute
                component={AuthorizableEditOrganizationWizard}
              />
            )}
          />
          <Route
            exact
            path='/project/:id/edit'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableEditProjectWizard} />
            )}
          />
          <Route
            path='/project'
            render={(props): React.ReactElement => (
              <ProjectRoutes {...props} />
            )}
          />
          <Route
            path='/projectSettings'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableProjectSettingsView} />
            )}
          />
          <Route
            path='/site/:id'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableSiteView} />
            )}
          />
          <Route
            path='/me'
            render={(): React.ReactElement => (
              <PrivateRoute component={CurrentUser} />
            )}
          />
          <Route
            exact
            path='/dashboard'
            render={(): React.ReactElement => <Redirect to={'/issue'} />}
          />
          <Route
            exact
            path='/create-template'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableTemplateWizard} />
            )}
          />
          <Route
            exact
            path='/inspectionTemplate/:id/edit'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableTemplateWizard} />
            )}
          />

          <Route
            path='/create-inspection/:templateId?'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableInspectionWizard} />
            )}
          />

          <Route
            exact
            path='/inspection'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableInspections} />
            )}
          />

          <Route
            path='/analytics/:id?'
            render={(): React.ReactElement => (
              <PrivateRoute component={MemoAnalyticsRoute} />
            )}
          />

          <Route
            exact
            path='/company'
            render={(): React.ReactElement => (
              <PrivateRoute component={MemoCompanies} />
            )}
          />

          <Route
            exact
            path='/company/:id/edit'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableEditCompanyWizard} />
            )}
          />

          <Route
            path='/company/:id'
            render={(): React.ReactElement => (
              <PrivateRoute component={MemoCompany} />
            )}
          />

          <Route
            exact
            path='/contract'
            render={(): React.ReactElement => (
              <PrivateRoute component={MemoContracts} />
            )}
          />

          <Route
            exact
            path='/contract/:id/edit'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableEditContractWizard} />
            )}
          />

          <Route
            path='/contract/:id'
            render={(): React.ReactElement => (
              <PrivateRoute component={MemoContract} />
            )}
          />

          <Route
            exact
            path='/create-company'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableCreateCompanyWizard} />
            )}
          />
          <Route
            exact
            path='/create-contract'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableCreateContractWizard} />
            )}
          />

          <Route
            exact
            path='/user'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableUsers} />
            )}
          />
          <Route
            exact
            path='/user/:id'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableUser} />
            )}
          />
          <Route
            exact
            path='/create-user'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableCreateUserWizard} />
            )}
          />
          <Route
            exact
            path='/user/:id/edit'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableEditUserWizard} />
            )}
          />

          <Route
            exact
            path='/fieldsSettings'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableFieldsSettings} />
            )}
          />

          <Route
            exact
            path='/fieldsSettings/:customizedField'
            render={(): React.ReactElement => (
              <PrivateRoute component={AuthorizableFieldSettings} />
            )}
          />

          <Route
            exact
            path='/'
            render={(): React.ReactElement => <Redirect to={'/issue'} />}
          />
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    </WithIssueGetParameters>
  );
};

const Routes = (): React.ReactElement => {
  const mismatchedVersion = useSelector(
    (state: StoreState) => state.ui.mismatchedVersion
  );

  useSetServiceWorkerConfig();

  return mismatchedVersion ? (
    <VersionMismatchPage />
  ) : (
    <Switch>
      <Route
        exact
        path='/report/:reportId/download'
        component={ReportDownloadView}
      />
      <Route exact path='/redirect' component={RedirectUnauthorized} />
      <Route exact path='/login' component={LoginContainer} />
      <Route component={DefaultContainer} />
    </Switch>
  );
};

export default Routes;
