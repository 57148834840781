import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import {
  currentUserSelector,
  userEqualityFunction,
} from 'redux/selectors/users';
import { UserRole } from 'shared/types/userRole';

export function useCurrentUserRoleInSelectedProject(): UserRole {
  const [role, setRole] = useState(UserRole.standard);
  const { _id: projectId } = useSelector(projectDataSelector);
  const currentUser = useSelector(
    currentUserSelector,
    userEqualityFunction
  );

  useEffect(() => {
    const currentPermission = currentUser.data.permissions.find(
      (permission) => {
        return permission.project === projectId;
      }
    );

    if (!currentPermission) {
      setRole(UserRole.standard);
    } else {
      setRole(currentPermission.role);
    }
  }, [projectId, currentUser]);

  return role;
}
