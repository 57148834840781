import { BroadcastChannel } from 'broadcast-channel';
import { fromDate14DaysEarlier, toDateToday } from 'charts/shared';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import {
  AnyFilters,
  ChartFilter,
  FilterValue,
  FilterValues,
} from 'shared/types/analytics';

type GetChartFilters = {
  getFilters: (
    filtersBroadcast: BroadcastChannel<any>
  ) => Promise<{ filters: FilterValues } | undefined>;
  saveFilters: (filters: AnyFilters<any>) => void;
};

const CHART_FILTERS_REQUEST_TIMEOUT = 25000;

export function useChartFilters(
  // `${projectId}_${Chart}`
  chartId: string
): GetChartFilters {
  const { timezone } = useSelector(projectDataSelector);
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const getFilters: GetChartFilters['getFilters'] = useCallback(
    (filtersBroadcast: BroadcastChannel<any>) => {
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          filtersBroadcast.close();
          reject(new Error(`Chart filters timeout for chart ${chartId}`));
        }, CHART_FILTERS_REQUEST_TIMEOUT);

        filtersBroadcast.onmessage = (event: Message): void => {
          if (
            event.type === DomainMessagesTypes.chartFiltersData &&
            event.data.chartId === chartId
          ) {
            clearTimeout(timeout);
            filtersBroadcast.close();
            return resolve(
              updateDataScopeDateRangeIfDefault(
                timezone,
                event.data.filters
              )
            );
          }
        };

        broadcast.postMessage({
          service: Services.CHART_FILTERS,
          method: ServiceMethods.GET_ONE,
          data: {
            _id: chartId,
          },
        });
      });
    },
    [broadcast, chartId, timezone]
  );

  const saveFilters = useCallback(
    (filters) => {
      broadcast.postMessage({
        service: Services.CHART_FILTERS,
        method: ServiceMethods.SET,
        data: {
          _id: chartId,
          filters: extractFiltersValues(filters),
        },
      });
    },
    [broadcast, chartId]
  );

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getFilters,
    saveFilters,
  };
}

export function extractFiltersValues(
  filters: AnyFilters<any> | undefined
): AnyFilters<any> | undefined {
  return (
    filters &&
    Object.entries(filters).reduce<AnyFilters<any>>(
      (finalResult, filterType): AnyFilters<any> => {
        const [filterKey, filterValue] = filterType;
        if (!filterValue) return finalResult;
        // @ts-ignore too hard
        finalResult[filterKey] = {
          filters: filterValue.filters.map((fltr: ChartFilter<any>) =>
            toFilterValueObject(fltr)
          ),
          labelId: filterValue.labelId,
        };

        return finalResult;
      },
      {} as AnyFilters<any>
    )
  );
}

function toFilterValueObject(filters: ChartFilter<any>): FilterValue {
  return Object.entries(filters).reduce<FilterValue>(
    (result: FilterValue, entry) => {
      const [key, value] = entry;
      if (key === 'value' || key === 'labelId') {
        result[key] = value;
      }

      return result;
    },
    {} as FilterValue
  );
}

function updateDataScopeDateRangeIfDefault(
  timezone: string,
  dataFilters: { filters: AnyFilters<any> } | undefined
): { filters: AnyFilters<any> } | undefined {
  if (
    dataFilters &&
    dataFilters.filters &&
    dataFilters.filters.dataScope &&
    dataFilters.filters.dataScope.filters &&
    dataFilters.filters.dataScope.filters[0] &&
    dataFilters.filters.dataScope.filters[0].labelId ===
      'data_scope_date_range'
  ) {
    const dateRangeValue = dataFilters.filters.dataScope.filters[0].value;

    const from = DateTime.fromISO(dateRangeValue.from);
    const to = DateTime.fromISO(dateRangeValue.to);
    const difference = to.diff(from, 'days');
    if (difference.days === 14) {
      dataFilters.filters.dataScope.filters[0].value.from =
        fromDate14DaysEarlier(timezone);
      dataFilters.filters.dataScope.filters[0].value.to =
        toDateToday(timezone);
    }
  }

  return dataFilters;
}
