import React from 'react';
import FeedbackDialog from './FeedbackDialog';
import StarBorder from '@mui/icons-material/StarBorder';
import useIsMobile from 'hooks/useIsMobile';
import { useIsOffline } from '../../common/withIsOffline';
import ForbiddenTooltip from '../../general/ForbiddenTooltip/ForbiddenTooltip';
import NavigationItemButton from '../Navigation/NavigationItem/NavigationItem';
import { NavigationDestination } from '../../../helpers/navigation';

type FeedbackButtonProps = {
  isNavigationExpanded: boolean;
  onFeedbackDialogClose: () => void;
};

const FeedbackButton = ({
  isNavigationExpanded,
  onFeedbackDialogClose,
}: FeedbackButtonProps): JSX.Element => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const isMobile = useIsMobile();
  const isOffline = useIsOffline();

  const showDialog = (): void => {
    setDialogOpen(true);
  };

  const closeDialog = (): void => {
    setDialogOpen(false);
    onFeedbackDialogClose();
  };

  return (
    <>
      <ForbiddenTooltip
        visibleWhen={isOffline}
        reason='general_offline_operation_unavailable'
      >
        <NavigationItemButton
          textId='feedback_title'
          componentKey={NavigationDestination.leaveFeedback}
          Icon={StarBorder}
          isNavigationExpanded={isNavigationExpanded}
          onClick={showDialog}
        />
      </ForbiddenTooltip>
      {dialogOpen ? <FeedbackDialog onClose={closeDialog} /> : null}
    </>
  );
};

export default FeedbackButton;
