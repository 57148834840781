import { ProjectPermissionDto } from 'shared/dtos/in/user';
import { LabelledEntity } from 'shared/types/commonView';
import { Process } from './process';

export function isBelowSuperAdminPermission(
  permissions: UserPermissionDto
): permissions is BelowSuperAdminPermission {
  return (permissions as BelowSuperAdminPermission).clients !== undefined;
}

export enum UserRole {
  standard = 'standard',
  manager = 'manager',
  viewer = 'viewer',
  admin = 'admin',
  superAdmin = 'superadmin',
  clientAdmin = 'clientadmin',
}

// it't not possible to create superadmin
export type CreatableUserRole =
  | UserRole.clientAdmin
  | UserRole.admin
  | UserRole.manager
  | UserRole.standard
  | UserRole.viewer;

export const USER_ROLE_LABEL_ID: Record<UserRole, string> = {
  [UserRole.standard]: 'role_standard',
  [UserRole.manager]: 'role_manager',
  [UserRole.viewer]: 'role_viewer',
  [UserRole.admin]: 'role_project_admin',
  [UserRole.superAdmin]: 'role_admin',
  [UserRole.clientAdmin]: 'role_admin',
};

type BelowProjectAdminPermission = {
  role: UserRole.standard | UserRole.viewer | UserRole.manager;
  // project id
  _id: string;
  processes: Process[];
  sites: string[];
};

type ProjectAdminPermission = {
  role: UserRole.admin;
  // project id
  _id: string;
  processes: never;
  sites: never;
};

type PermissionOnProject =
  | ProjectAdminPermission
  | BelowProjectAdminPermission;

export type BelowOrganizationAdminPermission = {
  projects: PermissionOnProject[];
  role?: never;
  // organization id
  _id: string;
};

type OrganizationAdminPermission = {
  role: UserRole.clientAdmin;
  projects: never;
  // organization id
  _id: string;
};

type PermissionOnOrganization =
  | OrganizationAdminPermission
  | BelowOrganizationAdminPermission;

type SuperAdminPermission = {
  role: UserRole.superAdmin;
};

type BelowSuperAdminPermission = {
  clients: PermissionOnOrganization[];
  role?: never;
};

export type UserPermissionDto =
  | SuperAdminPermission
  | BelowSuperAdminPermission;

export type UserWithPermissionsInDto = {
  email: string;
  permissions: ProjectPermissionDto[];
  basePermissions: UserPermissionDto;
} & LabelledEntity;

export type UserWithAuthAndPermissions = {
  isAuthenticated: boolean;
  data: UserWithPermissionsInDto;
  error: null | string;
};
