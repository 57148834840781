import { CorrectiveActionTypeModel } from 'shared/domain/correctiveActionType/correctiveActiontypeModel';
import { CorrectiveActionTypeEntity } from './entity';

export function correctiveActionTypeEntityToModel(
  entity: CorrectiveActionTypeEntity
): CorrectiveActionTypeModel {
  const { _id, label, processes, valueTranslations, createdByClientId } =
    entity;

  return { _id, label, processes, valueTranslations, createdByClientId };
}
