import { useMemo } from 'react';
import { toTemplateModelsObject } from 'components/dataProviders/withInspection/model';
import { InspectionFilterItem } from 'components/dataProviders/withInspection/types';
import { useInspections } from 'components/dataProviders/withInspections';
import { useInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';
import { createInspectionCode } from 'helpers/generators';
import { HashMap } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { UserRole } from 'shared/types/userRole';
import { IntlShape } from 'react-intl';

function inspectionModelToFilterItem(
  model: InspectionModel,
  processCode: string
): InspectionFilterItem {
  return {
    ...model,
    code: createInspectionCode(model.createdAt, model._id, processCode),
  };
}

export function useInspectionsFilterItems(
  processes: ProcessType[],
  userRole: UserRole
): InspectionFilterItem[] {
  const { inspections, loading: loadingInspections } = useInspections();
  const { inspectionTemplates, loading: loadingTemplates } =
    useInspectionTemplates();

  return useMemo(() => {
    if (loadingInspections || loadingTemplates) {
      return [];
    }
    const templatesObject = inspectionTemplates.items.reduce(
      toTemplateModelsObject,
      {}
    );

    const processesObject = processes.reduce<HashMap<ProcessType>>(
      (obj, process) => {
        obj[process._id] = process;
        return obj;
      },
      {}
    );

    return inspections.items.map((inspection) => {
      const process = templatesObject[inspection.template].process;
      return inspectionModelToFilterItem(
        inspection,
        processesObject[process].code
      );
    });
  }, [
    inspections.items,
    inspectionTemplates.items,
    loadingInspections,
    loadingTemplates,
    processes,
  ]);
}

export function getFilterPresentedLabel(
  label: string | { id: string },
  intl: IntlShape
): string {
  if (typeof label === 'string') {
    return label;
  }

  return intl.formatMessage({ id: label.id });
}

export const CLOSE_FILTER_ITEM = 'CLOSE_FILTER_ITEM';
