import React, { ReactElement } from 'react';
import { MemoProtocolItemPreview } from '../ProtocolItemPreview';
import { ProtocolOnView } from 'presentation/inspection/inspectionOnView';
import { RenderableProcess } from 'components/common/ProcessIcon/types';

type ProtocolPreviewProps = {
  protocol: ProtocolOnView[];
  renderableProcess: RenderableProcess;
};

function toReactProtocolItem(renderableProcess: RenderableProcess) {
  return function toReactProtocolItem(
    protocolItem: ProtocolOnView,
    index: number
  ): ReactElement {
    return (
      <MemoProtocolItemPreview
        index={index}
        key={protocolItem._id}
        protocolItem={protocolItem}
        renderableProcess={renderableProcess}
      />
    );
  };
}

function ProtocolPreview(props: ProtocolPreviewProps): ReactElement {
  const { protocol, renderableProcess } = props;
  return <>{protocol.map(toReactProtocolItem(renderableProcess))}</>;
}

export const MemoProtocolPreview = React.memo(ProtocolPreview);
