import { useCallback, useMemo, useState } from 'react';
import { ProjectNavigationTarget } from '../views/projects/settings';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';

const getItemKey = (projectId: string): string =>
  `${projectId}_project_navigation_tab`;

const urlQueryName = 'target';

function saveNavigationTargetInBrowser(
  projectId: string,
  target: ProjectNavigationTarget
): void {
  localStorage.setItem(getItemKey(projectId), target);
  const url = new URL(window.location.href);
  url.searchParams.set(urlQueryName, target);

  window.history.pushState(null, '', url.toString());
}

function isProjectNavigationTarget(
  name: null | string | undefined
): name is ProjectNavigationTarget {
  if (!name) {
    return false;
  }
  return Object.values(ProjectNavigationTarget).includes(
    name as ProjectNavigationTarget
  );
}

function getNavigationTargetFromUrl():
  | ProjectNavigationTarget
  | undefined {
  const sourced = new URLSearchParams(window.location.search).get(
    urlQueryName
  );

  if (isProjectNavigationTarget(sourced)) {
    return sourced;
  }
}
function getNavigationTargetFromLocalStorage(
  projectId: string
): ProjectNavigationTarget | undefined {
  const sourced = localStorage.getItem(getItemKey(projectId));

  if (isProjectNavigationTarget(sourced)) {
    return sourced;
  }
}

function determineNavigationTarget(
  projectId: string
): ProjectNavigationTarget {
  const fromUrl = getNavigationTargetFromUrl();
  if (fromUrl) {
    localStorage.setItem(getItemKey(projectId), fromUrl);
    return fromUrl;
  }
  const fromStorage = getNavigationTargetFromLocalStorage(projectId);

  return fromStorage || ProjectNavigationTarget.generalProjectData;
}

type UseNavigationTargetHook = () => {
  setTarget: SaveNavigationTarget;
  target: ProjectNavigationTarget;
};

export type SaveNavigationTarget = (
  target: ProjectNavigationTarget
) => void;
export const useNavigationTarget: UseNavigationTargetHook = () => {
  const { _id: projectId } = useSelector(projectDataSelector);

  const [target, _setTarget] = useState<ProjectNavigationTarget>(
    determineNavigationTarget(projectId)
  );

  const saveNavigationTarget: SaveNavigationTarget = useCallback(
    (target: ProjectNavigationTarget): void => {
      _setTarget(target);
      saveNavigationTargetInBrowser(projectId, target);
    },
    [projectId]
  );

  return useMemo(
    () => ({
      target,
      setTarget: saveNavigationTarget,
    }),
    [target, saveNavigationTarget]
  );
};
