import {
  FieldValueOnView,
  CreateFieldValueOnView,
} from 'presentation/fieldValue/fieldValueOnView';

export function byActiveAndLabel(
  a: FieldValueOnView | CreateFieldValueOnView,
  b: FieldValueOnView | CreateFieldValueOnView
): number {
  if (!a._id) {
    return a.label.localeCompare(b.label);
  }
  if (!a.processes.length === !b.processes.length) {
    return a.label.localeCompare(b.label);
  }
  if (!a.processes.length) {
    return 1;
  }
  if (!b.processes.length) {
    return -1;
  }
  return 0;
}
