import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import {
  IDirectoryUploader,
  UploadDirectory,
} from 'serviceWorker/repository/directory/uploadDirectory';
import { UploadDirectoryChange } from 'serviceWorker/repository/directory/uploadDirectoryChange';
import { IRequestRunner } from './httpRequestRunner';

export class DirectoryRequestRunner implements IRequestRunner {
  constructor(
    private directoryUploader: IDirectoryUploader,
    private uploadDirectoryUseCase: UploadDirectory,
    private uploadDirectoryChangeUseCase: UploadDirectoryChange,
    private getDirectory: (id: number) => Promise<DirectoryModel>
  ) {}
  async execute(request: __HttpRequestModel__): Promise<boolean> {
    switch (request.method) {
      case 'POST': {
        return this.uploadDirectory(request);
      }
      case 'PUT': {
        return this.updateDirectory(request);
      }
    }
    throw new Error('Not implemented.');
  }

  private async uploadDirectory(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const directory = await this.getDirectory(request.data.localId);
    const url = `/project/${request.data.projectId}/dir`;

    return this.uploadDirectoryUseCase(
      this.directoryUploader,
      directory,
      url,
      request.data.uniqueId
    );
  }

  private async updateDirectory(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const directory = await this.getDirectory(request.data.localId);
    if (!directory._id) {
      return false;
    }
    const url = `/project/${request.data.projectId}/dir/${directory._id}`;

    return this.uploadDirectoryChangeUseCase(
      this.directoryUploader,
      request.data.localId,
      request.data.name,
      url
    );
  }
}
