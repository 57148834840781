import { Tooltip } from '@mui/material';
import { useSizeScaledToImage } from 'components/location/area/common';
import { MouseEventHandler, ReactElement, useMemo } from 'react';
import { Color } from 'shared/utils/colors';

export function IndexMarker({
  index,
  color,
  onMouseOver,
  onMouseLeave,
  onClick,
  drawLineFrom,
  tooltip,
}: {
  index: number;
  color: Color;
  onMouseOver: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  drawLineFrom: 'top' | 'bottom';
  onClick: MouseEventHandler;
  tooltip: string;
}): ReactElement {
  const width = useSizeScaledToImage(1);

  const rectHeight = useSizeScaledToImage(10, 10);

  const textFont = useSizeScaledToImage(6, 6);

  const halfWidth = width / 2;
  const radius = useMemo(() => {
    if (index < 10) {
      return textFont * 0.8;
    }
    if (index < 100) {
      return textFont * 0.9;
    }
    if (index < 1000) {
      return textFont;
    }
    return textFont * 1.1;
  }, [index, textFont]);
  const cy = rectHeight + radius / 2;

  return (
    <Tooltip title={tooltip}>
      <g
        data-qa='index-marker'
        xmlns='http://www.w3.org/2000/svg'
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        cursor='pointer'
        onClick={onClick}
        style={{
          transform:
            drawLineFrom === 'bottom'
              ? `translate(50%, 0)`
              : `translate(50%, 100%) scale(1, -1)`,
        }}
      >
        <rect
          x={-1.5 * width}
          width={width * 3}
          height={width * 3}
          fill={color}
        />

        <rect
          x={-halfWidth}
          width={width}
          height={rectHeight}
          fill={color}
        />

        <circle
          cy={cy}
          r={radius}
          fill='white'
          stroke={color}
          strokeWidth={textFont / 8}
        />

        <text
          y={drawLineFrom === 'bottom' ? cy : -cy}
          style={
            drawLineFrom === 'bottom' ? {} : { transform: 'scale(1, -1)' }
          }
          textAnchor='middle'
          dominantBaseline='middle'
          fontFamily='Archivo, Arial'
          fontSize={textFont}
          fill='black'
        >
          {index}
        </text>
      </g>
    </Tooltip>
  );
}
