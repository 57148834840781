import { anyToAppLocaleOnView } from 'shared/utils/locale/anyLocaleCodeToAppLocaleOnView';
import {
  AppLocaleOnView,
  ISO6391LanguageCode,
} from '../shared/types/locale';

export const allAvailableLocaleCodes = Object.values(ISO6391LanguageCode);

export function getAvailableAppLocales(): AppLocaleOnView[] {
  return allAvailableLocaleCodes.map(
    (locale: ISO6391LanguageCode): AppLocaleOnView => {
      return anyToAppLocaleOnView(locale);
    }
  );
}
