import { CSSProperties, ReactElement } from 'react';
import * as actions from 'redux/actionTypes';
import { activeFiltersSelector } from 'redux/selectors/issues';
import { useDispatch } from 'react-redux';
import { ButtonIcon } from 'components/general';
import styles from './styles';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { useIssueFilters } from 'components/dataProviders/withIssueFilters';
import { MenuItem } from '@mui/material';
import { useIntl } from 'react-intl';

function FiltersToggler(): ReactElement {
  const classes = styles();
  const dispatch = useDispatch();
  const intl = useIntl();

  const toggleSidebar = (): void => {
    dispatch({ type: actions.TOGGLE_FILTERS_SIDEBAR });
  };

  return (
    <ButtonIcon
      onClick={toggleSidebar}
      data-qa='toolbar-filters-toggler'
      title={intl.formatMessage({ id: 'filters_action_filter' })}
    >
      <TuneRoundedIcon className={classes.icon} />
      <FiltersCounter />
    </ButtonIcon>
  );
}

export function FiltersTogglerMenuItem({
  handleClose,
  text,
}: {
  handleClose?: () => void;
  text: string;
}): ReactElement {
  const classes = styles();
  const dispatch = useDispatch();

  const toggleSidebar = (): void => {
    dispatch({ type: actions.TOGGLE_FILTERS_SIDEBAR });
    if (typeof handleClose === 'function') handleClose();
  };

  return (
    <MenuItem onClick={toggleSidebar} data-qa='toolbar-filters-toggler'>
      <div
        style={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FiltersCounter />
        <TuneRoundedIcon className={classes.icon} />
      </div>
      <span style={{ paddingLeft: '1rem' }}>{text}</span>
    </MenuItem>
  );
}

function FiltersCounter({
  style,
}: {
  style?: CSSProperties;
}): ReactElement | null {
  const classes = styles();
  const { filters: state, archived } = useIssueFilters();
  const activeFiltersCount =
    activeFiltersSelector(state).length + (archived ? 1 : 0);

  if (!activeFiltersCount) return null;
  return (
    <div style={style} className={classes.filterCount}>
      {activeFiltersCount}
    </div>
  );
}

export default FiltersToggler;

export { FiltersCounter };
