import { InspectionStatus } from 'shared/domain/inspection/inspectionModel';

export const InspectionStatusLabel: Record<InspectionStatus, string> = {
  [InspectionStatus.inProgress]: 'inspection_status_in_progress',
  [InspectionStatus.completed]: 'inspection_status_completed',
};

export enum InspectionStatusSize {
  small = 'small',
  default = 'default',
  table = 'table',
}

export type InspectionStatusProps = {
  status: InspectionStatus;
  size?: InspectionStatusSize;
};
