import * as issues from 'serviceWorker/db/issues';
import * as issuesService from 'serviceWorker/db/issuesService';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

export async function getIssues(): Promise<IssueEntity[]> {
  const issuesStatus = await issuesService.get();

  if (!issuesStatus?.isDownloaded || issuesStatus?.isDownloading) {
    return issuesFromEvent();
  }

  return issues.get();
}

export function issuesFromEvent(): Promise<IssueEntity[]> {
  return new Promise((resolve, reject) => {
    const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
    const timeout = setTimeout(() => {
      reject(new Error('Timeout.'));
      broadcast.close();
    }, 60000);
    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.allIssues) {
        clearTimeout(timeout);
        broadcast.close();
        issues.get().then(resolve).catch(reject);
        return;
      }
    };
  });
}
