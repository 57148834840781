import { Subscribe } from 'components/broadcastChannelListeners/channelListener/channelListener';
import { useCorrectiveActionTypesChannelListener } from 'components/broadcastChannelListeners/channelListener/correctiveActionTypesChannelListener';
import { useEnvironmentalAspectChannelListener } from 'components/broadcastChannelListeners/channelListener/environmentalAspectChannelListener';
import { useHazardCategoryChannelListener } from 'components/broadcastChannelListeners/channelListener/hazardCategoryChannelListener';
import { useWorktypeChannelListener } from 'components/broadcastChannelListeners/channelListener/worktypeChannelListener';
import { FieldValueModel } from 'shared/domain/fieldValue/fieldValueModel';
import { toFieldValueOnView } from 'presentation/fieldValue/mappings';
import React, {
  ReactElement,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useEntityDataSubscription } from '../useEntityDataSubscription';
import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';

type FieldValuesContextType<T> = {
  items: T[];
  allItems: T[];
  total: number;
  loading: boolean;
  resync: (fieldName: EditableFieldValuesEntityName) => void;
};

type FieldValuesInstance<T> = {
  Component: any;
  composeComponent: any;
  useContextFunction: () => FieldValuesContextType<T>;
};

export function FieldValueFactory<T extends FieldValueModel>(
  getterHook: () => { getAll: () => Promise<any> }
): FieldValuesInstance<T> {
  const FieldValuesContext = React.createContext<
    FieldValuesContextType<T> | undefined
  >(undefined);

  const WithFieldValues: ({ children }: any) => any = ({
    children,
  }: any) => {
    const [allItems, _setAllItems] = useState<T[]>([]);
    const setVisibleFields = useCallback(
      (response) => _setAllItems(response.items.map(toFieldValueOnView)),
      [_setAllItems]
    );
    const items = useMemo(() => {
      return allItems.filter((item: { processes: string[] }) => {
        return item.processes.length;
      });
    }, [allItems]);
    const [loading, setLoading] = useState<boolean>(true);

    const { getAll } = getterHook();

    const { subscribe: subscribeWorkTypes } = useWorktypeChannelListener();
    const { subscribe: subscribeHazardCategories } =
      useHazardCategoryChannelListener();
    const { subscribe: subscribeCorrectiveActionTypes } =
      useCorrectiveActionTypesChannelListener();
    const { subscribe: subscribeEnvironmentalAspects } =
      useEnvironmentalAspectChannelListener();
    const { resync: resyncWorktypes } = useEntityDataSubscription({
      subscribe: subscribeWorkTypes,
      getAll,
      setEntity: setVisibleFields,
      setLoading,
      entityName: 'work types',
    });
    const { resync: resyncCorrectiveActionTypes } =
      useEntityDataSubscription({
        subscribe: subscribeCorrectiveActionTypes,
        getAll,
        setEntity: setVisibleFields,
        setLoading,
        entityName: 'proposed corrective action',
      });
    const { resync: resyncEnvironmentalAspects } =
      useEntityDataSubscription({
        subscribe: subscribeEnvironmentalAspects,
        getAll,
        setEntity: setVisibleFields,
        setLoading,
        entityName: 'environmental aspects',
      });
    const { resync: resyncHazardCategories } = useEntityDataSubscription({
      subscribe: subscribeHazardCategories,
      getAll,
      setEntity: setVisibleFields,
      setLoading,
      entityName: 'hazard categories',
    });
    const resync = useCallback(
      (fieldName: EditableFieldValuesEntityName) => {
        switch (fieldName) {
          case EditableFieldValuesEntityName.workTypes:
            return resyncWorktypes();
          case EditableFieldValuesEntityName.environmentalAspect:
            return resyncEnvironmentalAspects();
          case EditableFieldValuesEntityName.hazardCategory:
            return resyncHazardCategories();
          case EditableFieldValuesEntityName.proposedCorrectiveAction:
            return resyncCorrectiveActionTypes();
        }
      },
      [
        resyncWorktypes,
        resyncEnvironmentalAspects,
        resyncHazardCategories,
        resyncCorrectiveActionTypes,
      ]
    );

    const ctx = useMemo(
      () => ({
        items,
        total: items.length,
        allItems,
        loading: loading,
        resync,
      }),
      [items, allItems, loading, resync]
    );

    return (
      <FieldValuesContext.Provider value={ctx}>
        {children}
      </FieldValuesContext.Provider>
    );
  };

  const withFieldValues =
    (Component: any) =>
    ({ ...props }): ReactElement => (
      <WithFieldValues>
        <Component {...props} />
      </WithFieldValues>
    );

  function useFieldValues(): FieldValuesContextType<T> {
    {
      const context = React.useContext(FieldValuesContext);
      if (context === undefined) {
        throw new Error(
          'useFieldValues must be used within a FieldValuesContextProvider'
        );
      }
      return context;
    }
  }

  return {
    Component: WithFieldValues,
    composeComponent: withFieldValues,
    useContextFunction: useFieldValues,
  };
}
