import {
  DirectoryCreateModel,
  DirectoryEditModel,
  DirectoryModel,
} from 'shared/domain/directory/directoryModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { DirectoryInDto } from 'shared/dtos/in/directory';
import { Changes } from 'shared/types/commonEntities';
import { UserEntity } from 'shared/domain/user/types/entity';
import {
  DirectoryEntity,
  DirectoryInsertEntity,
  DirectoryLocalInsertEntity,
} from './entity';

export function directoryInDtoToCreateEntity(
  directoryInDto: DirectoryInDto
): DirectoryInsertEntity {
  return {
    _id: directoryInDto._id,
    name: directoryInDto.name,
    parentId: directoryInDto.parentId ? directoryInDto.parentId : '',
    createdAt: directoryInDto.createdAt,
    createdBy: directoryInDto.createdBy,
    deleted: directoryInDto.deleted ? 1 : 0,
    modifiedAt: directoryInDto.modifiedAt,
    modifiedBy: directoryInDto.modifiedBy,
    syncStatus: SyncStatus.SUCCESS,
  };
}

export function directoryEntityToModel(
  directoryEntity: DirectoryEntity,
  users: UserEntity[]
): DirectoryModel {
  return {
    _id: directoryEntity._id,
    localId: directoryEntity.localId,
    name: directoryEntity.name,
    parentId: directoryEntity.parentId,
    createdAt: directoryEntity.createdAt,
    deleted: directoryEntity.deleted ? true : false,
    modifiedAt: directoryEntity.modifiedAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === directoryEntity.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === directoryEntity.modifiedBy)
    ),
    syncStatus: directoryEntity.syncStatus,
  };
}

export function directoryCreateModelToLocalInsertEntity(
  directoryCreateModel: DirectoryCreateModel
): DirectoryLocalInsertEntity {
  return {
    name: directoryCreateModel.name,
    parentId: directoryCreateModel.parentId
      ? directoryCreateModel.parentId
      : '',
    createdAt: directoryCreateModel.createdAt,
    createdBy: directoryCreateModel.createdBy?._id,
    deleted: directoryCreateModel.deleted ? 1 : 0,
    modifiedAt: directoryCreateModel.modifiedAt,
    modifiedBy: directoryCreateModel.modifiedBy?._id,
    syncStatus: SyncStatus.PENDING,
  };
}

export function directoryEditModelToDirectoryChanges(
  directoryEditModel: DirectoryEditModel
): Changes<DirectoryEntity> {
  return {
    name: directoryEditModel.name,
    modifiedBy: directoryEditModel.modifiedBy,
  };
}
