import { ReactElement } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';
import { FieldValueWizard } from '.';
import { useFieldValueForm } from 'components/dataCreationForms/withFieldValueForm';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 16,
      width: '100%',
    },
  },
  dialogContent: {
    padding: 0,
  },
  closeIcon: {
    opacity: 0.54,
    cursor: 'pointer',
  },
  dialogTitle: {
    padding: '16px',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '18px',
    },
  },
});

export function FieldValueDialog({ instantSubmit }): ReactElement {
  const { open, closeDialog, dialogTitleLabelId } = useFieldValueForm();
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted={false}
      onClose={closeDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id={dialogTitleLabelId} />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <FieldValueWizard instantSubmit={instantSubmit} />
      </DialogContent>
    </Dialog>
  );
}
