import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { VisibleFieldInDto } from 'shared/dtos/in/visibleField';
import { UserEntity } from 'shared/domain/user/types/entity';
import { VisibleFieldEntity } from './visibleFieldEntity';

export function visibleFieldInDtoToEntity(
  visibleField: VisibleFieldInDto
): VisibleFieldEntity {
  return {
    _id: visibleField._id,
    createdAt: visibleField.createdAt,
    createdBy: visibleField.createdBy,
    deleted: visibleField.deleted ? 1 : 0,
    modifiedAt: visibleField.modifiedAt,
    modifiedBy: visibleField.modifiedBy,
    fieldName: visibleField.fieldName,
    processId: visibleField.processId,
  };
}

export function visibleFieldEntityToModel(
  visibleField: VisibleFieldEntity,
  users: UserEntity[]
): VisibleFieldModel {
  return {
    _id: visibleField._id,
    createdAt: visibleField.createdAt,
    deleted: visibleField.deleted ? true : false,
    modifiedAt: visibleField.modifiedAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === visibleField.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === visibleField.modifiedBy)
    ),
    fieldName: visibleField.fieldName,
    processId: visibleField.processId,
  };
}
