import { ISO6391LanguageCode } from 'shared/types/locale';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  CreateFieldValueOnView,
  EditFieldValueOnView,
  FieldValueOnView,
} from 'presentation/fieldValue/fieldValueOnView';
import {
  ComponentType,
  FC,
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';
import useFormEdit from 'views/issue/forms/useFormEdit';
import { useFieldValuesForm } from '../withFieldValuesForm';
import { fieldToFormInitialValues, formToFieldValue } from './model';

export type FieldValueFlatForm = {
  processes: string[];
  ignoreIndexes: number[];
  _field?: CreateFieldValueOnView | FieldValueOnView;
  [key: string]:
    | string
    | LabelledEntity
    | any[]
    | FieldValueOnView
    | CreateFieldValueOnView
    | undefined;
};
type OpenFieldValueDialogParams = {
  freeSoloLabel?: string;
  field?: CreateFieldValueOnView | FieldValueOnView;
};
type OpenFieldValueDialog = (params?: OpenFieldValueDialogParams) => void;
type CloseDialog = () => void;

type SubmitForm = (
  values: FieldValueFlatForm
) => Promise<FieldValueFlatForm>;
type ReleaseSubmitEvent = () => void;
export type FieldValueFormContext = {
  dialogTitleLabelId: string;
  open: boolean;
  openDialog: OpenFieldValueDialog;
  closeDialog: CloseDialog;
  submitForm: SubmitForm;
  isPosting: boolean;
  initialValues: FieldValueFlatForm;
  SUBMIT_EVENT_TYPE: string;
  releaseSubmitEvent: ReleaseSubmitEvent;
  formName: 'fieldValue';
};

const WithFieldValueFormContext = createContext<
  FieldValueFormContext | undefined
>(undefined);

const WithFieldValueForm: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const context = useContext(WithFieldValueFormContext);
  if (context) {
    debugLog('Recreated context');
  }
  const formName = 'fieldValue';
  const { locale } = useIntl();
  const [open, setOpen] = useState(false);
  // const [instantSubmit, setInstantSubmit] = useState(false);
  const [initialValues, setInitialValues] = useState<FieldValueFlatForm>({
    processes: [],
    ignoreIndexes: [],
    label: '',
  });
  const dialogTitleLabelId = useMemo(() => {
    if (initialValues._field?._id) {
      return 'field_values_edit_option';
    }
    return 'field_values_add_option';
  }, [initialValues]);
  const { setEditing } = useFormEdit();
  const openDialog: OpenFieldValueDialog = useCallback(
    (params) => {
      setOpen(true);
      setInitialValues(
        fieldToFormInitialValues(
          params?.field,
          params?.freeSoloLabel
            ? { providedLabel: params.freeSoloLabel, locale }
            : undefined
        )
      );
      setEditing(false, formName);
    },
    [setEditing, locale]
  );

  const confirmCancel = useCancelConfirmation('fieldValue');
  const { addFieldValue, editFieldValue } = useFieldValuesForm();

  const closeDialog: CloseDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm: SubmitForm = useCallback(
    (values) => {
      setOpen(false);
      if (values._field?._id) {
        editFieldValue(
          formToFieldValue(
            locale as ISO6391LanguageCode,
            values
          ) as EditFieldValueOnView
        );
        return Promise.resolve(values);
      }

      addFieldValue(
        formToFieldValue(
          locale as ISO6391LanguageCode,
          values
        ) as CreateFieldValueOnView
      );
      return Promise.resolve(values);
    },
    [addFieldValue, editFieldValue, setOpen, locale]
  );

  const SUBMIT_EVENT_TYPE = 'submit-field-value';
  const releaseSubmitEvent: ReleaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const ctx: FieldValueFormContext = useMemo(() => {
    return {
      dialogTitleLabelId,
      open,
      openDialog,
      closeDialog,
      submitForm,
      isPosting: false,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      formName,
    };
  }, [
    dialogTitleLabelId,
    open,
    openDialog,
    closeDialog,
    submitForm,
    initialValues,
    releaseSubmitEvent,
  ]);

  return (
    <WithFieldValueFormContext.Provider value={ctx}>
      {children}
    </WithFieldValueFormContext.Provider>
  );
};

function useFieldValueForm(): FieldValueFormContext {
  const context = useContext(WithFieldValueFormContext);
  if (context === undefined) {
    throw new Error(
      'useFieldValueForm must be used within an FieldValueFormContext'
    );
  }
  return context;
}

const withFieldValueForm =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithFieldValueForm>
      <Component {...props} />
    </WithFieldValueForm>
  );

export { WithFieldValueForm, useFieldValueForm, withFieldValueForm };
