export enum Process {
  HS = 'PROCESS_HS',
  QA = 'PROCESS_QA',
  ENV = 'PROCESS_ENV',
  TSK = 'PROCESS_TSK',
}

export const AVAILABLE_PROCESSES = [
  { _id: Process.QA, label: 'process_Quality' },
  { _id: Process.ENV, label: 'process_Env' },
  { _id: Process.HS, label: 'process_H&S' },
  { _id: Process.TSK, label: 'process_Tsk' },
];
