import { EMPTY_cost } from './contants';
import {
  CostsArray,
  FakeEvent,
  InitialCost,
  InputHandler,
  IsCostInvalid,
  Last,
  PreventEmptyCost,
  RemovedCost,
  SingleEstimatedCost,
  SumCosts,
  UpdateCostParser,
  UpdatedCost,
  ValidateCosts,
} from './types';
import { LabelledEntity } from 'shared/types/commonView';

export const isEmptyCost = (cost: SingleEstimatedCost): boolean =>
  !cost.cost && !cost.coveredBy;

export const cleanSet = (set: CostsArray): CostsArray =>
  set.filter((cost) => !isEmptyCost(cost));

/** Get's last array elem. If empty array or undefined - return default value */
export const last: Last = (arr = [], def = '') =>
  arr[arr.length - 1] || def;

/** Allows to set target.value on state object */
export const inputHandler: InputHandler =
  (id, key, call) =>
  (e): any =>
    call(id, key, e.target.value);

export const sumCosts: SumCosts = (costs = []) => {
  if (costs.length === 0) {
    return 0;
  }
  return costs
    .map((c) => parseFloat((c && c.cost) || '0'))
    .reduce((a, b) => a + b);
};

export const isCostInvalid: IsCostInvalid = (costs) => {
  const result: ReturnType<IsCostInvalid> = {};
  costs.forEach(({ cost }, i) => {
    const invalid = {
      cost: !cost,
      coveredBy: false,
    };
    if (invalid.cost || invalid.coveredBy) {
      result[i] = invalid;
    }
  });

  return Object.keys(result).length ? result : false;
};

export const formatMoney = (value: number, currency = ''): string => {
  const options: any = {};
  if (currency) {
    options.style = 'currency';
    options.currency = currency;
  }
  return new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(value);
};

export const parseUpdate: UpdateCostParser = (data) => {
  const emptyCovered = (
    elem: LabelledEntity | null
  ): LabelledEntity | null => (elem?._id ? elem : null);
  return data.map(({ cost, coveredBy }) => ({
    cost: parseFloat(cost) || 0,
    coveredBy: emptyCovered(coveredBy),
  }));
};

export const fakeEvent: FakeEvent = (value, name) => ({
  target: { name, value },
});

export const initialCost: InitialCost = (value = []) =>
  value?.length ? value : [{ ...EMPTY_cost }];

export const updatedCost: UpdatedCost<keyof SingleEstimatedCost> =
  function <T extends keyof SingleEstimatedCost>(
    arr: CostsArray,
    idx: number,
    key: T,
    value: SingleEstimatedCost[T]
  ) {
    const newSet = [...arr];
    newSet[idx][key] = value;
    return newSet;
  };

export const removedCost: RemovedCost = (arr, idx) => {
  const newSet = [...arr];
  newSet.splice(idx, 1);
  return newSet;
};

export const preventEmptyCost: PreventEmptyCost = (arr) => {
  return arr.length ? arr : [{ ...EMPTY_cost }];
};

export const validateCosts: ValidateCosts = (costs) =>
  costs.map(isEmptyCost).filter((e) => e);
