import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';

const drawer = {
  backgroundColor: '#000000',
  transition: 'width 200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
  display: 'flex',
  flexFlow: 'column nowrap',
};

export const drawerExpanded = {
  ...drawer,
  width: '250px',
};
export const drawerCollapsed = {
  ...drawer,
  width: { xs: 0, sm: '64px' },
};

const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(() => ({
  navigationButtonsWrapper: {
    overflowX: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap',
    '& .top-buttons': {
      flex: '1 1 0',
      display: 'flex',
      flexFlow: 'column nowrap',
    },
    '& .bottom-buttons': {
      flex: '1 1 0',
      display: 'flex',
      flexFlow: 'column-reverse nowrap',
      paddingBottom: '32px',
    },
    '& .disabled-link': {
      pointerEvents: 'none',
    },
  },
}));

export default useStyles;
