import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useInputForm } from '../../dataCreationForms/withInputForm';
import Presentational from './presentational';
import { GUProps, ContextType, GU, FileType } from './types';

const GraphicUploaderContext = createContext<ContextType | undefined>(
  undefined
);

function removeTrailingSlash(value: string): string {
  return value.replace(/\/$/, '');
}

function shouldAutoOpenGalleryBasedOnUrl(
  baseUrl: string,
  file: FileType,
  prefix = '/document'
): boolean {
  if (!file) return false;
  return (
    baseUrl + `${prefix}/${file._id}` ===
    removeTrailingSlash(window.location.pathname)
  );
}

export const GraphicUploader: React.FC<GUProps> = ({
  filesStore,
  addFile,
  editFile,
  removeFile,
  drawings,
  addDrawing,
  config,
  classes,
  forwardedRef,
}) => {
  const history = useHistory();
  const [gallery, setGallery] = useState({
    slide: 1,
    open: false,
  });
  const [wasOpen, setWasOpen] = useState(false);

  const changeGallery: ContextType['changeGallery'] = (place, value) => {
    setGallery({ ...gallery, [place]: value });
  };

  const { internalEntityBaseUrl, filePrefix, autoOpenCheck } = config;
  const shouldAutoOpen = autoOpenCheck ?? shouldAutoOpenGalleryBasedOnUrl;

  useEffect(() => {
    if (!internalEntityBaseUrl || wasOpen) {
      return;
    }
    const baseUrl = internalEntityBaseUrl();

    const index = filesStore.get().findIndex((file) => {
      return shouldAutoOpen(baseUrl, file, filePrefix);
    });
    if (index > -1) {
      setGallery({
        slide: index,
        open: true,
      });
    }
  }, [
    filesStore,
    internalEntityBaseUrl,
    wasOpen,
    filePrefix,
    shouldAutoOpen,
  ]);

  useEffect(() => {
    if (gallery.open) {
      setWasOpen(true);
      const currentFile = filesStore.get()[gallery.slide];
      if (
        // @ts-ignore PT-4244
        (currentFile?.versionId || currentFile?._id) &&
        internalEntityBaseUrl
      ) {
        history.replace(
          `${internalEntityBaseUrl()}${
            filePrefix ?? '/document'
            // @ts-ignore  PT-4244
          }/${currentFile._id || currentFile?.versionId}`
        );
      }
    } else {
      if (wasOpen) {
        setWasOpen(false);
        internalEntityBaseUrl && history.replace(internalEntityBaseUrl());
      }
    }
  }, [
    history,
    wasOpen,
    internalEntityBaseUrl,
    filesStore,
    gallery.open,
    gallery.slide,
    filePrefix,
  ]);

  const context = {
    filesStore,
    addFile,
    editFile,
    removeFile,
    drawings,
    addDrawing,
    config,
    setGallery,
    changeGallery,
    gallery,
    classes,
  };

  if (forwardedRef) {
    forwardedRef.current = context;
  }

  return (
    <GraphicUploaderContext.Provider value={context}>
      <Presentational />
    </GraphicUploaderContext.Provider>
  );
};

export const InputFormGraphicUploader: React.FC<{
  uploaderKey: string;
  GU: GU;
}> = ({ uploaderKey, GU }) => {
  const { registerUploader } = useInputForm();

  useEffect(() => {
    registerUploader(uploaderKey, GU);
  }, [uploaderKey, registerUploader, GU]);

  return <GraphicUploader {...GU} />;
};

export function useGraphicUploaderContext(): ContextType {
  const context = useContext(GraphicUploaderContext);
  if (context === undefined) {
    throw new Error(
      'useGraphicUploaderContext must be used within a useGraphicUploaderContextProvider'
    );
  }
  return context;
}
