import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import { IRequestRunner } from './httpRequestRunner';
import {
  IVisibleFieldUploader,
  UploadVisibleFieldChanges,
} from 'serviceWorker/repository/visibleField/uploadVisibleField';

export class VisibleFieldRequestRunner implements IRequestRunner {
  constructor(
    private visibleFieldUploader: IVisibleFieldUploader,
    private uploadVisibieFieldChangesUseCase: UploadVisibleFieldChanges
  ) {}

  execute(request: __HttpRequestModel__): Promise<unknown> {
    return this.uploadVisibleFieldChanges(request);
  }

  private async uploadVisibleFieldChanges(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const url = `/project/${request.data.projectId}/visibleField/update`;

    await this.uploadVisibieFieldChangesUseCase(
      this.visibleFieldUploader,
      request.data.visibleFieldsEdit,
      url
    );
    return true;
  }
}
