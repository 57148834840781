import { FieldValueFlatForm } from 'components/dataCreationForms/withFieldValueForm';
import {
  ErrorPresentation,
  oneOfValidation,
  requiredValidation,
  stringMinMaxValidation,
} from 'helpers/validators';
import { LOCALE_LANGUAGES } from 'shared/types/locale';
import { Dispatch, SetStateAction } from 'react';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';

type FormValueValidation = {
  [key in keyof FieldValueFlatForm]: (value?: any) => ErrorPresentation;
};
const validation: FormValueValidation = {
  valueTranslations: languageValidation,
  label: labelValidation,
  ignoreIndexes: returnUndefined,
  processes: returnUndefined,
  _field: returnUndefined,
};

export function validate(
  key: keyof FieldValueFlatForm,
  value: FieldValueFlatForm[keyof FieldValueFlatForm]
): ErrorPresentation {
  const { key: parsedKey } = parseKey(key);
  if (!parsedKey) {
    return;
  }
  return validation[parsedKey](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: FieldValueFlatForm
): boolean {
  const shouldValidateForm =
    !form._field || form._field.canEditTranslations;
  const errors = shouldValidateForm
    ? Object.entries(form).reduce<HashMap<ErrorPresentation>>(
        (errors, [key, value]) => {
          const { key: keyString, index: indexString } = parseKey(key);
          if (
            shouldSkipFieldValidation(
              form.ignoreIndexes,
              keyString,
              indexString
            )
          ) {
            return errors;
          }
          const error = validate(key, value);
          if (error) {
            errors[key] = error;
          }
          return errors;
        },
        {}
      )
    : {};
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}

function returnUndefined(...args: any[]): undefined {
  return;
}

function labelValidation(value: unknown): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.S, value)
  );
}

function languageValidation(value: unknown): ErrorPresentation {
  return (
    requiredValidation(value) ||
    // @ts-ignore unknown {}
    requiredValidation(value._id) ||
    // @ts-ignore unknown {}
    oneOfValidation(value._id, Object.values(LOCALE_LANGUAGES))
  );
}

function shouldSkipFieldValidation(
  ignoreIndexes: number[],
  key: string | undefined,
  indexString: string | undefined
): key is undefined {
  if (key === undefined) {
    return true;
  }
  if (indexString === undefined) {
    return true;
  }
  const index = parseFloat(indexString);

  if (isNaN(index)) {
    return true;
  } else if (typeof index === 'number') {
    if (ignoreIndexes.includes(index)) {
      return true;
    }
  }
  return false;
}

function parseKey(key: keyof FieldValueFlatForm): {
  key: string | undefined;
  index: string | undefined;
} {
  const fieldStringKeyRegex = new RegExp(/([a-zA-Z]+)(\d+)/g);
  const [_, keyString, indexString] =
    fieldStringKeyRegex.exec(key as string) || [];
  return {
    key: keyString,
    index: indexString,
  };
}
