import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { documentationDeleteDialog } from 'components/documentation/documentationDeleteDialog';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { MAX_DISPLAY_FILE_SIZE } from 'shared/domain/document/documentLimits';
import { DocumentUrlsModel } from 'shared/domain/document/documentUrlsModel';
import { startDocumentationDelete } from 'shared/domain/documentation/startDocumentationDelete';
import { startSetDocumentationParent } from 'shared/domain/documentation/startSetDocumentationParent';
import { Fetch } from 'hooks/api/service';
import { useCreateStore, subscribeMany } from 'hooks/createStore';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { HashMap, Identificable } from 'shared/types/commonView';
import {
  makeDisplayedFilesStore,
  useStoreFiles,
} from '../../common/gallery/filesStore';
import { useDirectories } from '../withDirectories';
import { WithDisplayedFiles } from '../withDisplayedFiles';
import { useDocumentations } from '../withDocumentations';
import { useDocumentationSearchContext } from '../withDocumentationSearch';
import {
  documentationToFileType,
  getFileDetailsForDownload,
  parseLocalFileBlob,
  setFileBroken,
  useDocumentationsSearch,
} from './model';
import {
  DocumentationControllerContextType,
  DocumentationFile,
  DocumentationOnView,
} from './types';
import { useDocumentationSaver } from './useDocumentationUploader';
import { FetchMethod } from 'shared/types/logger';

const DocumentationControllerContext = React.createContext<
  DocumentationControllerContextType | undefined
>(undefined);

const WithDocumentationController: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const createDialog = useDialog();
  const { searchPhraseStore } = useDocumentationSearchContext();
  const {
    currentDirDocumentationsStore,
    isLoadingCurrentDirDocumentationsStore,
  } = useDocumentations();
  const {
    directoriesStore,
    currentDirectoryFoldersStore,
    isLoadingCurrentDirFoldersStore,
    isLoadingDirectoriesStore,
  } = useDirectories();

  const filesStore = useStoreFiles<DocumentationFile>();
  const selectedFilesStore = useCreateStore<Set<DocumentationOnView>>(
    new Set()
  );

  const {
    searchedDocumentationsStore,
    searchedDirectoriesStore,
    loadingStore: loadingSearchStore,
  } = useDocumentationsSearch({
    directoriesStore,
    isLoadingDirectoriesStore,
    searchPhraseStore,
  });

  const selectElements = useCallback(
    (
      files: DocumentationOnView[],
      options?: { ctrlKeyPressed?: boolean }
    ): void => {
      const prevoiusSet = selectedFilesStore.get();

      if (
        !options?.ctrlKeyPressed &&
        files.length === 1 &&
        !prevoiusSet.has(files[0])
      ) {
        selectedFilesStore.set(new Set(files));
        return;
      }

      const currentSet = new Set(Array.from(prevoiusSet));
      files.forEach((file) => {
        if (currentSet.has(file) && options?.ctrlKeyPressed) {
          currentSet.delete(file);
        } else {
          currentSet.add(file);
        }
      });
      selectedFilesStore.set(currentSet);
    },
    [selectedFilesStore]
  );
  const unselectElements = useCallback(() => {
    selectedFilesStore.set(new Set());
  }, [selectedFilesStore]);

  const urlsRef = useRef<HashMap<any>>({});
  const { addDocumentationsFromFiles } = useDocumentationSaver();
  const useDisplayedFiles = useMemo(
    () => makeDisplayedFilesStore(filesStore),
    [filesStore]
  );

  const setSourceUrlsOnFile = useCallback(
    (
      getUrls: Promise<DocumentUrlsModel>,
      fileId: string,
      file: DocumentationFile
    ): void => {
      const searchPhrase = searchPhraseStore.get();
      getUrls.then((response: DocumentUrlsModel) => {
        const files = filesStore.get();
        const fileIndex = files.findIndex(
          (file) => `${file.documentationId}${file.versionId}` === fileId
        );
        if (!files[fileIndex] || files[fileIndex].signedRequest) {
          return;
        }
        const fileWithSignedRequest = {
          ...file,
          loading: false,
          downloadSrc: response.signedRequest,
          thumbnail: response.thumbnailSignedRequest,
          signedRequest: response,
          src: response.imageSignedRequest || response.signedRequest,
        };
        files[fileIndex] = fileWithSignedRequest;
        if (searchPhrase === searchPhraseStore.get()) {
          filesStore.set(files.slice());
        }
      });
    },
    [filesStore, searchPhraseStore]
  );

  const setSourceUrlsOnLocalFile = useCallback(
    (url: string, fileId: number, file: DocumentationFile): void => {
      if (urlsRef.current[url]) {
        return;
      }
      const searchPhrase = searchPhraseStore.get();
      urlsRef.current[url] = fetch(url).catch(() => ({ ok: false }));
      urlsRef.current[url].then(async (response: any) => {
        if (!response.ok) {
          setFileBroken(filesStore, file, fileId);
          return;
        }
        const blob = await parseLocalFileBlob(response);
        const files = filesStore.get();
        const fileIndex = files.findIndex(
          (file) => file.localId === fileId
        );
        if (!files[fileIndex] || files[fileIndex].signedRequest) {
          return;
        }

        const signedRequest = {
          signedRequest: file.downloadSrc as string,
          thumbnailSignedRequest:
            blob.size > MAX_DISPLAY_FILE_SIZE
              ? DOCUMENT_ERROR_SOURCE
              : file.downloadSrc,
        };

        const fileWithSignedRequest = {
          ...file,
          loading: false,
          downloadSrc: file.downloadSrc,
          thumbnail: signedRequest.thumbnailSignedRequest,
          signedRequest: signedRequest,
          src: response.imageSignedRequest || response.signedRequest,
        };
        files[fileIndex] = fileWithSignedRequest;
        if (searchPhrase === searchPhraseStore.get()) {
          filesStore.set(files.slice());
        }
      });
    },
    [filesStore, searchPhraseStore]
  );

  useEffect(() => {
    return subscribeMany(
      [
        currentDirDocumentationsStore,
        searchPhraseStore,
        loadingSearchStore,
        searchedDocumentationsStore,
      ],
      () => {
        urlsRef.current = {};
        filesStore.set(
          (searchPhraseStore.get() && !loadingSearchStore.get()
            ? searchedDocumentationsStore.get()
            : currentDirDocumentationsStore.get()
          ).map(documentationToFileType)
        );
      }
    );
  }, [
    currentDirDocumentationsStore,
    filesStore,
    searchPhraseStore,
    searchedDocumentationsStore,
    loadingSearchStore,
  ]);

  useEffect(() => {
    return filesStore.subscribe(() => {
      const files = filesStore.get();
      files.forEach((file) => {
        const details = getFileDetailsForDownload(file);
        if (!details) {
          return;
        }
        const { url, fileId } = details;
        if (!urlsRef.current[url]) {
          return;
        }
        if (details.isLocal) {
          return setSourceUrlsOnLocalFile(url, fileId as number, file);
        }
        setSourceUrlsOnFile(urlsRef.current[url], fileId as string, file);
      });
    });
  }, [filesStore, setSourceUrlsOnFile, setSourceUrlsOnLocalFile]);

  const downloadFileSignedRequests = useCallback(
    (file: DocumentationFile) => {
      const details = getFileDetailsForDownload(file);
      if (!details) {
        return;
      }
      const { url, fileId } = details;
      if (details.isLocal) {
        return setSourceUrlsOnLocalFile(url, fileId as number, file);
      }

      if (!file.downloadSrc || !urlsRef.current[url]) {
        urlsRef.current[url] = Fetch[FetchMethod.GET](url);
      }
      setSourceUrlsOnFile(urlsRef.current[url], fileId as string, file);
    },
    [setSourceUrlsOnFile, setSourceUrlsOnLocalFile]
  );

  const setParent = useCallback(
    (target: Identificable) => {
      const id = target._id;
      const documentations: number[] = [];
      const directories: number[] = [];
      let moveDirectoryToSelf = false;

      selectedFilesStore.get().forEach((element) => {
        if (element.type === 'DIRECTORY') {
          if (element._id === target._id) {
            moveDirectoryToSelf = true;
          }
          directories.push(element.localId);
        } else {
          documentations.push(element.localId);
        }
      });

      if (moveDirectoryToSelf) {
        return;
      }
      startSetDocumentationParent(id, documentations, directories);
    },
    [selectedFilesStore]
  );

  const archive = useCallback(() => {
    const documentations: number[] = [];
    const directories: number[] = [];

    selectedFilesStore.get().forEach((element) => {
      if (element.type === 'DIRECTORY') {
        directories.push(element.localId);
      } else {
        documentations.push(element.localId);
      }
    });

    createDialog(
      documentationDeleteDialog(selectedFilesStore.get().size)
    ).then(() => {
      startDocumentationDelete(documentations, directories);
    });
  }, [selectedFilesStore, createDialog]);

  const ctx: DocumentationControllerContextType = useMemo(() => {
    return {
      filesStore,
      selectedFilesStore,
      selectElements,
      unselectElements,
      setParent,
      edit: () => {},
      archive,
      addDocumentationsFromFiles,
      currentDirDocumentationsStore,
      isLoadingCurrentDirDocumentationsStore,

      directoriesStore,
      currentDirectoryFoldersStore,
      isLoadingDirectoriesStore,
      isLoadingCurrentDirFoldersStore,

      searchedDirectoriesStore,
      searchedDocumentationsStore,
      loadingSearchStore,
    };
  }, [
    filesStore,
    selectedFilesStore,
    selectElements,
    unselectElements,
    setParent,
    addDocumentationsFromFiles,
    archive,
    currentDirDocumentationsStore,
    isLoadingCurrentDirDocumentationsStore,
    directoriesStore,
    currentDirectoryFoldersStore,
    isLoadingDirectoriesStore,
    isLoadingCurrentDirFoldersStore,
    searchedDirectoriesStore,
    searchedDocumentationsStore,
    loadingSearchStore,
  ]);

  return (
    <DocumentationControllerContext.Provider value={ctx}>
      <WithDisplayedFiles
        filesStore={filesStore}
        downloadFile={downloadFileSignedRequests}
        useDisplayedFiles={useDisplayedFiles}
      >
        {children}
      </WithDisplayedFiles>
    </DocumentationControllerContext.Provider>
  );
};

function withDocumentationController<T>(
  Component: React.ComponentType<T>
) {
  return (props: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithDocumentationController>
      <Component {...props} />
    </WithDocumentationController>
  );
}

function useDocumentationController(): DocumentationControllerContextType {
  const context = React.useContext(DocumentationControllerContext);
  if (context === undefined) {
    throw new Error(
      'useDocumentationController must be used within a DocumentationControllerContextProvider'
    );
  }
  return context;
}

export {
  WithDocumentationController,
  withDocumentationController,
  useDocumentationController,
};
