import { HashMap } from 'shared/types/commonView';
import { StoreState } from 'setup/types/core';
import { ProcessType } from 'shared/types/form';

export function processesSelector(state: StoreState): ProcessType[] {
  return state.projectData.processes;
}

export function toProcessesObject(
  state: StoreState
): HashMap<ProcessType> {
  const processesObject: HashMap<ProcessType> = {};
  state.projectData.processes.forEach((process) => {
    processesObject[process._id] = process;
  });
  return processesObject;
}

export function checkProcessesEquality(
  prev: HashMap<ProcessType>,
  curr: HashMap<ProcessType>
): boolean {
  const prevKeys = Object.keys(prev);
  const currKeys = Object.keys(curr);
  if (prevKeys.length !== currKeys.length) {
    return false;
  }
  return prevKeys.every((prevKey) =>
    currKeys.find((currKey) => prevKey === currKey)
  );
}
