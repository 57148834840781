import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  submitButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 18px',
  },
  languageOptions: {
    display: 'flex',
    maxHeight: '30vh',
    padding: '24px 16px',
    paddingBottom: 0,
    flexFlow: 'column nowrap',
    '& > *': {
      '&:nth-child(4n-3)': {
        marginBottom: '16px',
      },
      '&:nth-child(4n)': {
        marginBottom: '16px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  deleteIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  container: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  selfFlexEnd: {
    alignSelf: 'flex-end',
  },
});
