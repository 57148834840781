import { Theme as DefaultTheme } from '@mui/material/styles';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      wrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '81px',
        padding: '0 32px',
        flexGrow: 1,
        flexShrink: 1,
        width: ({ isMobile }): string => (isMobile ? 'unset' : '300px'),
      },
      errorText: {
        fontFamily: 'Archivo',
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 700,
        lineHeight: '26px',
        letterSpacing: '0em',
        textAlign: 'center',
        marginTop: '41px',
        marginBottom: '24px',
      },
      helperText: {
        fontFamily: 'Archivo',
        fontSize: '1rem',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '21px',
        letterSpacing: '0em',
        textAlign: 'center',
        color: '#757575',
      },
    })
);
