import { InspectionInDto } from 'shared/dtos/in/inspection';
import { ProtocolItemInDto } from 'shared/dtos/in/protocolItem';
import { InspectionOutDto } from 'shared/dtos/out/inspection';
import { ProtocolItemOutDto } from 'shared/dtos/out/protocolItem';
import { Fetch } from 'hooks/api/service';
import { FetchMethod } from 'shared/types/logger';

export function postInspection(
  templateId: string,
  body: InspectionOutDto,
  signal: AbortSignal
): Promise<InspectionInDto> {
  const url = `/inspectionTemplate/${templateId}/inspection`;

  return Fetch[FetchMethod.POST](url, body, {
    signal,
  }) as Promise<InspectionInDto>;
}

export function putInspection(
  inspectionId: string,
  body: Partial<InspectionOutDto>,
  signal: AbortSignal
): Promise<InspectionInDto> {
  const url = `/inspection/${inspectionId}`;
  return Fetch[FetchMethod.PUT](url, body, {
    signal,
  }) as Promise<InspectionInDto>;
}

export function putProtocol(
  inspectionId: string,
  protocolId: string,
  body: ProtocolItemOutDto,
  signal: AbortSignal
): Promise<ProtocolItemInDto> {
  const url = `/inspection/${inspectionId}/protocolItem/${protocolId}`;
  return Fetch[FetchMethod.PUT](url, body, {
    signal,
  }) as Promise<ProtocolItemInDto>;
}
