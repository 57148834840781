import { createStyles, makeStyles } from '@mui/styles';

export const useStylesMultiSelect = makeStyles(() =>
  createStyles({
    multiselect: {
      boxSizing: 'border-box',
      '&.isFilled': {
        paddingTop: '12px',
        marginTop: '-12px',
      },
      width: '100%',
      gridColumn: '1 / -1',
      overflow: 'hidden',
    },
  })
);
