import { Theme } from '@mui/material';
import { CreateCSSProperties, makeStyles } from '@mui/styles';
import classNames from 'classnames';
import React from 'react';
import { Color } from 'shared/utils/colors';
import { hexToRGB } from '../misc';
import './loader.scss';

type LoaderType = {
  type?: string;
  size?: number;
  backgroundColor?: string;
};

const useStyles = makeStyles((theme: Theme) => {
  const [lightborder, darkBorder] = [
    hexToRGB(Color.primaryOrange, 0.15),
    hexToRGB(Color.primaryOrange, 0.8),
  ];

  return {
    loader: ({ size }: LoaderType): CreateCSSProperties<LoaderType> => {
      size = size ?? 24;
      return {
        height: size,
        width: size,
        borderLeft: `${size / 4}px solid ${lightborder}`,
        borderRight: `${size / 4}px solid ${lightborder}`,
        borderBottom: `${size / 4}px solid ${lightborder}`,
        borderTop: `${size / 4}px solid ${darkBorder}`,
        borderRadius: '100%',
      };
    },
  };
});

const getWrapperStyle = (type = ''): React.CSSProperties => {
  const types = type.split(' ');

  return {
    ...(types.includes('fullHeight') && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
    }),
    ...(types.includes('center') && {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    }),
  };
};

const getLoaderStyle = (type = '', size = 24): React.CSSProperties => {
  const types = type.split(' ');

  return {
    ...(types.includes('inline') && {
      marginRight: size / 4,
    }),
  };
};

const Loader = ({
  type,
  size = 24,
  backgroundColor,
}: LoaderType): JSX.Element => {
  const classes = useStyles({ size });

  return (
    <div
      className='loader-wrapper'
      style={{ ...getWrapperStyle(type), backgroundColor }}
    >
      <div
        className={classNames('loaderAnimation', classes.loader)}
        style={getLoaderStyle(type, size)}
      />
    </div>
  );
};

export default Loader;
