import { useSites, withSites } from 'components/dataProviders/withSites';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { toLabelledEntity } from 'components/inspection/Form/model';
import {
  FormFieldProps,
  SingleChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import { IssueFormPresentational } from './presentational';
import { IssueFormProps, SetableIssueFields } from './types';
import { withLevels } from 'components/dataProviders/withLevels';
import {
  useLevelsToSelect,
  withLevelsToSelect,
} from 'components/dataProviders/withLevelsToSelect';
import { levelsExtractor } from 'presentation/level/levelExtractor';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';

function _IssueForm(props: IssueFormProps): React.ReactElement {
  const { processes } = useSelector(projectDataSelector);
  const { onCancel, onSubmit, isPosting, disabledFields } = props;

  const {
    sites: { items: sites },
  } = useSites();
  const { levelsPerSite } = useLevelsToSelect();

  const processFieldProps: FormFieldProps<SetableIssueFields> = useMemo(
    () => ({
      formKey: 'process',
      required: true,
      labelId: 'general_process',
      fieldName: 'singleselect-process',
      disabled: disabledFields?.includes('process')
        ? { reason: 'inspection_form_issue_process_edit_warning' }
        : false,

      available: processes.map(toLabelledEntity),
      autoSelect: 'asSingleSelect',
      reserveSpaceForHelperText: false,
      dense: false,
      'data-qa': 'issue_process_field',
    }),
    [processes, disabledFields]
  );

  const siteFieldProps: SingleChoiceFormFieldProps<SetableIssueFields> =
    useMemo(
      () => ({
        formKey: 'site',
        required: true,
        labelId: 'primaryField_Site',
        fieldName: 'singleselect-site',
        disabled: disabledFields?.includes('site')
          ? { reason: 'inspection_form_issue_site_edit_warning' }
          : false,
        available: sites.filter(keepUndeleted).map(toLabelledEntity),
        autoSelect: 'asSingleSelect',
        entityName: EditableStandardEntityName.location,
        reserveSpaceForHelperText: false,
        dense: false,
        'data-qa': 'issue_location_field',
      }),
      [sites, disabledFields]
    );

  const levelFieldProps: SingleChoiceFormFieldProps<SetableIssueFields> =
    useMemo(
      () => ({
        formKey: 'level',
        required: true,
        labelId: 'primaryField_Level',
        fieldName: 'singleselect-level',
        available: levelsPerSite,
        dependsOn: 'site',
        extractor: levelsExtractor,
        autoSelect: 'asSingleSelect',
        entityName: EditableStandardEntityName.level,
        reserveSpaceForHelperText: false,
        dense: false,
        'data-qa': 'issue_level_field',
      }),
      [levelsPerSite]
    );

  const titleFieldProps: FormFieldProps<SetableIssueFields> = useMemo(
    () => ({
      formKey: 'title',
      required: true,
      labelId: 'inspection_template_wizard_summary',
      fieldName: 'text-field-title',
      localStorageKey: 'issue-summary',
      reserveSpaceForHelperText: false,
      dense: false,
      'data-qa': 'issue_summary_field',
    }),
    []
  );

  return (
    <IssueFormPresentational
      processFieldProps={processFieldProps}
      siteFieldProps={siteFieldProps}
      levelFieldProps={levelFieldProps}
      titleFieldProps={titleFieldProps}
      isPosting={isPosting}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}

export const IssueForm = withSites(
  withLevels(withLevelsToSelect(_IssueForm))
);
