import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { broadcastAllCorrectiveActionTypes } from 'serviceWorker/broadcasts/correctiveActionTypes';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as correctiveActionTypes from 'serviceWorker/db/correctiveActionTypes';
import * as correctiveActionTypesService from 'serviceWorker/db/correctiveActionTypesService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import {
  fetchCorrectiveActionTypes,
  fetchUpdatedCorrectiveActionTypes,
} from './fetchCorrectiveActionTypes';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullCorrectiveActionTypes: Pull = makePullEntity({
  fetch: fetchCorrectiveActionTypes,
  channelName: ChannelNames.correctiveActionTypeChannel,
  entityName: 'correctiveActionTypes',
  entityRepository: correctiveActionTypes,
  entityService: correctiveActionTypesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullCorrectiveActionTypesHandler = makePullHandler(
  config,
  correctiveActionTypes,
  correctiveActionTypesService,
  pullCorrectiveActionTypes,
  clearBroadcasts
);

const synchronizeCorrectiveActionTypes = makeSynchronizeEntity({
  configRepository: config,
  entityService: correctiveActionTypesService,
  entityRepository: correctiveActionTypes,
  pullEntityHandler: pullCorrectiveActionTypesHandler,
  fetchUpdatedEntities: fetchUpdatedCorrectiveActionTypes,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'correctiveActionTypes',
  channelName: ChannelNames.correctiveActionTypeChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeCorrectiveActionTypes.bind(
      null,
      broadcastAllCorrectiveActionTypes
    ),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncCorrectiveActionTypes,
    syncDebounced
  );
};
