import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debuglog } from 'util';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGet } from './makeUseGet';
import { IssueModel } from 'shared/domain/issue/issueModel';

export const useGetIssuesFiltered = makeUseGet<
  { items: IssueModel[]; total: number },
  string
>({
  listenMessageChannel: ChannelNames.issueChannel,
  isMatchingMessage,
  postMessage: broadcastGetIssuesFiltered,
  entityName: 'issues',
});

function broadcastGetIssuesFiltered(query: string): void {
  debuglog('send broadcastGetIssuesFiltered');
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
  const { filters, search, timezone, archived } = JSON.parse(query);

  broadcast.postMessage({
    type: DomainMessagesTypes.getFiltered,
    data: {
      filters,
      search,
      archived,
      timezone,
    },
    uniqueId: query,
  });
  broadcast.close();
}

function isMatchingMessage(id: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.filteredIssues &&
    Boolean(event.data) &&
    event.uniqueId === id
  );
}
