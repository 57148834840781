import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { MemoInspectionForm } from 'components/inspection/Form';
import {
  useRequiredInspectionTemplate,
  withRequiredInspectionTemplate,
} from 'components/dataProviders/withRequiredInspectionTemplate';
import {
  withInspection,
  useInspection,
} from 'components/dataProviders/withInspection';
import { Spinner } from 'components/core';
import {
  SubmitOptions,
  WithInputForm,
} from 'components/dataCreationForms/withInputForm';
import {
  inspectionToFlatForm as inspectionToFlatFormWithDocuments,
  SUBMIT_EVENT_TYPE,
} from './model';
import {
  validateInspectionField,
  createInspectionFormValidation,
  inspectionValidation,
} from './validation';
import { withProcessesData } from 'components/common/withProcessesData';
import { withFetcherReady } from 'components/common/withFetcherReady';
import {
  useRedirectContext,
  withRedirect,
} from 'components/common/withRedirect';
import { useBacktrack } from 'components/common/withBacktrack';
import {
  useInspectionSubmit,
  withInspectionSubmit,
} from 'components/dataProviders/withInspectionSubmit';
import { withSites } from 'components/dataProviders/withSites';
import { withIssueChannelListener } from 'components/broadcastChannelListeners/withIssueChannelListener';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withLevels } from 'components/dataProviders/withLevels';
import { withWorktypes } from 'components/dataProviders/withWorktypes';
import { withIssueForm } from 'components/common/withIssueForm';
import { projectIdSelector } from 'helpers/misc';
import { useSelector } from 'react-redux';
import { withFieldVisibility } from 'components/dataProviders/withVisibleFields';
import { withFieldVisibilityMap } from 'components/dataProviders/withVisibleFields/withVisibleFieldsMap';
import { withUsers } from 'components/dataProviders/withUsers';

function InspectionWizard(): ReactElement {
  const abortSignalRef = useRef<AbortController>(new AbortController());
  const projectId = useSelector(projectIdSelector);

  useEffect(() => {
    abortSignalRef.current.abort();
    abortSignalRef.current = new AbortController();

    return (): void => {
      abortSignalRef.current.abort();
    };
  }, []);

  const { redirect, setRedirection } = useRedirectContext();

  const { back } = useBacktrack();

  const { template, loading: loadingTemplate } =
    useRequiredInspectionTemplate();
  const {
    inspection,
    inspectionDocuments,
    loading: loadingInspection,
    setIgnoreDocuments,
  } = useInspection();

  const onDone = useCallback(
    (response) => {
      if (response) {
        setRedirection({
          path: `/project/${projectId}/inspection/${response.inspection._id}`,
          state: undefined,
        });
        redirect();
      }
      return Promise.resolve(response);
    },
    [setRedirection, redirect, projectId]
  );

  const { submitForm, isPosting } = useInspectionSubmit(onDone);

  const loading = loadingTemplate || loadingInspection;

  const releaseSubmitEvent = useCallback((options: SubmitOptions) => {
    window.dispatchEvent(
      new CustomEvent(SUBMIT_EVENT_TYPE, {
        detail: { finish: options?.finish || false },
      })
    );
  }, []);

  const onCancel = useCallback(() => {
    back();
  }, [back]);

  const inspectionFormValidation = useMemo(() => {
    return createInspectionFormValidation(inspectionValidation, template);
  }, [template]);

  useEffect(() => {
    // This means we are in creation mode. We dont want documents to ever query.
    // https://hustro.atlassian.net/browse/PT-3948
    if (!inspection && !loading) {
      setIgnoreDocuments(true);
    }
  }, [inspection, loading, setIgnoreDocuments]);

  if (loading || !template) {
    return <Spinner reason='InspectionWizard loading || !template' />;
  }

  if (!inspection && !loading) {
    window.history.replaceState(
      '',
      '',
      `/create-inspection/${template._id}`
    );
  }

  const initialValues = inspectionToFlatFormWithDocuments(
    inspection,
    inspectionDocuments
  );

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      eventType={SUBMIT_EVENT_TYPE}
      handleFieldValidation={validateInspectionField}
      handleFormValidation={inspectionFormValidation}
      onSubmit={submitForm}
      dispatchSubmit={releaseSubmitEvent}
      formName='inspection'
    >
      <MemoInspectionForm
        loading={loading}
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoInspectionWizard = memo(
  withFetcherReady(
    withProcessesData(
      withIssueForm(
        withRedirect(
          withIssueChannelListener(
            withSites(
              withLevels(
                withWorktypes(
                  withContracts(
                    withCompanies(
                      withRequiredInspectionTemplate(
                        withInspection(
                          withUsers(
                            withInspectionSubmit(
                              withFieldVisibility(
                                withFieldVisibilityMap(InspectionWizard)
                              )
                            )
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
