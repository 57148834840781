import {
  ComplianceCheckResult,
  InspectionModel,
  ProtocolItemModel,
} from 'shared/domain/inspection/inspectionModel';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { UserModel } from 'shared/domain/user/userModel';
import { InspectionInDto } from 'shared/dtos/in/inspection';
import { ProtocolItemInDto } from 'shared/dtos/in/protocolItem';
import { InspectionOutDto } from 'shared/dtos/out/inspection';
import { Dispatch } from 'react';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { logError } from 'setup/logger/logError';
import { HashMap } from 'shared/types/commonView';
import { InspectionFlatForm } from 'views/inspections/wizard/model';
import { GU } from '../../common/graphicUploader/types';
import {
  postInspection,
  putInspection,
  putProtocol,
} from '../withInspection/api';
import {
  ProtocolOutDtoWithChecklistId,
  SubmitInspectionProps,
} from '../withInspection/types';
import { FinishInspectionProps, InspectionSubmitResponse } from './types';

export const PROTOCOL_ITEM_UPLOADER_PREFIX = `protocol-item-uploader`;

function hasIssues(protocol: InspectionModel['protocol']): boolean {
  return protocol.some(
    (protocolItem) => protocolItem.complianceCheck.issues.length
  );
}

// optional todo: skip values that did not change when inspection is defined;
export function toInspectionOutDto(
  inspection: InspectionModel | undefined,
  form: InspectionFlatForm
): Partial<InspectionOutDto> {
  if (inspection && hasIssues(inspection.protocol)) {
    // we cannot change site so we skip it
    return {
      inspectionDate: form.inspectionDate,
      workTypes: form.workTypes?.map((element) => element._id),
      levels: form.levels?.map((element) => element._id),
      comment: form.comment,
      controlledParty: form.controlledParty?.map((element) => element._id),
      contracts: form.contracts?.map((element) => element._id),
    };
  }

  return {
    inspectionDate: form.inspectionDate,
    workTypes: form.workTypes?.map((element) => element._id),
    site: form.site?._id,
    levels: form.levels?.map((element) => element._id),
    comment: form.comment,
    controlledParty: form.controlledParty?.map((element) => element._id),
    contracts: form.contracts?.map((element) => element._id),
  };
}

export function finishInspection({
  inspectionId,
  signal,
}: FinishInspectionProps): Promise<InspectionInDto> {
  return putInspection(inspectionId, { isCompleted: true }, signal);
}

export function submitInspection({
  body: { inspection, protocol, allProtocolItems },
  signal,
  templateId,
  inspectionId,
  uploaders,
  dispatch,
}: SubmitInspectionProps): Promise<InspectionSubmitResponse> {
  if (!inspectionId) {
    if (!templateId) {
      return Promise.reject(
        'Cannot send inspection. Inpection id and template id is missing.'
      );
    }
    return postInspection(templateId, inspection, signal)
      .catch((e) => {
        displayGenericErrorToaster(dispatch);
        throw e;
      })
      .then(
        putProtocols(
          protocol,
          allProtocolItems,
          signal,
          uploaders,
          dispatch
        )
      );
  }

  return putInspection(inspectionId, inspection, signal)
    .catch((e) => {
      displayGenericErrorToaster(dispatch);
      throw e;
    })
    .then(
      putProtocols(protocol, allProtocolItems, signal, uploaders, dispatch)
    );
}

function putProtocols(
  filteredProtocolItems: ProtocolOutDtoWithChecklistId[],
  allProtocolItems: ProtocolOutDtoWithChecklistId[],
  signal: AbortSignal,
  uploaders: HashMap<GU>,
  dispatch: Dispatch<any>
): (response: InspectionInDto) => Promise<InspectionSubmitResponse> {
  return async (response): Promise<InspectionSubmitResponse> => {
    const inspectionId = response._id;
    const filesPromises: Promise<any>[] = [];

    const inspection = response;
    const protocolPromiseArray = filteredProtocolItems.reduce<
      Promise<ProtocolItemInDto>[]
    >((protocolPromises, protocolItem): Promise<ProtocolItemInDto>[] => {
      const matchingProtocol = response.protocol.find((item) => {
        return item.templateChecklistItem === protocolItem.checklistId;
      });

      if (!matchingProtocol) {
        return protocolPromises;
      }

      if (
        protocolItem.complianceCheck.result ===
          ComplianceCheckResult.notPassed &&
        !matchingProtocol.complianceCheck.issues.length
      ) {
        return protocolPromises;
      }

      protocolPromises.push(
        putProtocol(
          inspectionId,
          matchingProtocol._id,
          protocolItem,
          signal
        )
      );

      return protocolPromises;
    }, []);

    const protocols: ProtocolItemInDto[] = await Promise.all(
      protocolPromiseArray
    ).catch((e) => {
      displayGenericErrorToaster(dispatch);
      throw e;
    });

    allProtocolItems.forEach((protocolItem) => {
      const matchingProtocol = response.protocol.find((item) => {
        return item.templateChecklistItem === protocolItem.checklistId;
      });

      if (!matchingProtocol) {
        return;
      }

      filesPromises.push(
        uploadFiles(
          uploaders,
          inspectionId,
          matchingProtocol._id,
          `${PROTOCOL_ITEM_UPLOADER_PREFIX}-${matchingProtocol.templateChecklistItem}`
        )
      );
    });
    const files = await Promise.all(filesPromises);

    return {
      inspection,
      protocols,
      files,
    };
  };
}

function uploadFiles(
  uploaders: HashMap<GU>,
  inspectionId: string,
  protocolItemId: string,
  key: string
): Promise<unknown> {
  if (!uploaders[key]) {
    return Promise.resolve();
  }
  return uploaders[key]
    .uploadFiles(
      createProtocolDocumentBaseUrl(inspectionId, protocolItemId),
      undefined,
      { rethrow: true }
    )
    .then(() => Promise.all(uploaders[key].executeChangeset()))
    .catch((e: any) => {
      logError(
        `Compliance check document upload failed. Inspection: ${inspectionId}. Protocol item: ${protocolItemId}`,
        e
      );
      throw e;
    });
}

function createProtocolDocumentBaseUrl(
  inspectionId: string,
  protocolItemId: string
): string {
  return `/inspection/${inspectionId}/protocolItem/${protocolItemId}/complianceCheck`;
}

export function inspectionDtoToModel(
  inspectionDto: InspectionInDto,
  users: UserModel[]
): InspectionModel {
  return {
    _id: inspectionDto._id,
    contracts: inspectionDto.contracts,
    controlledParty: inspectionDto.controlledParty,
    createdAt: inspectionDto.createdAt,
    deleted: inspectionDto.deleted,
    inspectionDate: inspectionDto.inspectionDate,
    isCompleted: inspectionDto.isCompleted,
    levels: inspectionDto.levels,
    modifiedAt: inspectionDto.modifiedAt,
    protocol: inspectionDto.protocol.map((protocolItem) =>
      protocolItemInDtoToModel(protocolItem, users)
    ),
    site: inspectionDto.site,
    template: inspectionDto.template,
    workTypes: inspectionDto.workTypes,
    comment: inspectionDto.comment,
    createdBy: toLabelledUser(
      users.find((user) => user._id === inspectionDto.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === inspectionDto.modifiedBy)
    ),
  };
}

function protocolItemInDtoToModel(
  protocolItemInDto: ProtocolItemInDto,
  users: UserModel[]
): ProtocolItemModel {
  const {
    _id,
    complianceCheck,
    modifiedAt,
    templateChecklistItem,
    modifiedBy,
  } = protocolItemInDto;
  return {
    _id,
    complianceCheck,
    modifiedAt,
    templateChecklistItem,
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === modifiedBy)
    ),
  };
}

export function filterUnchangedProtocolItems(
  inspectionModel: InspectionModel | undefined,
  protocolItems: ProtocolOutDtoWithChecklistId[]
): ProtocolOutDtoWithChecklistId[] {
  if (!inspectionModel) {
    return protocolItems;
  }

  const protocolModelMap = new Map<string, ProtocolItemModel>();
  inspectionModel.protocol.forEach((protocolItemModel) => {
    protocolModelMap.set(
      protocolItemModel.templateChecklistItem,
      protocolItemModel
    );
  });

  return protocolItems.filter((protocolItem) => {
    const matchedItem = protocolModelMap.get(protocolItem.checklistId);
    if (!matchedItem) {
      return true;
    }

    if (
      matchedItem.complianceCheck.result ===
        protocolItem.complianceCheck.result &&
      normalize(matchedItem.complianceCheck.comment) ===
        normalize(protocolItem.complianceCheck.comment)
    ) {
      return false;
    }

    return true;
  });
}

function normalize(text: string | undefined): string {
  if (!text) return '';
  return text;
}
