import { ISSUE_REQUEST_STATUS, IssueStatusType } from '../helpers';
import { createSelector } from 'reselect';
import { Issue } from '../../../../setup/types/core';
import { ProcessType } from 'shared/types/form';
import { Res } from '../../../../hooks/api/types';
import { HashMap } from 'shared/types/commonView';
import { UserInDto } from 'shared/dtos/in/user';
import { AccessLevel } from 'shared/types/userAccess';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { IssueOnSingleView } from 'components/dataProviders/withIssue/model';
import { projectDataSelector } from 'redux/selectors/project';

export const errorStatuses: Record<number, ISSUE_REQUEST_STATUS> = {
  403: ISSUE_REQUEST_STATUS.FORBIDDEN,
  404: ISSUE_REQUEST_STATUS.NOT_FOUND,
  422: ISSUE_REQUEST_STATUS.FORBIDDEN,
};

export const issueStatuses: HashMap<IssueStatusType> = {
  initial: {
    status: ISSUE_REQUEST_STATUS.INITIAL,
  },
  loaded: {
    status: ISSUE_REQUEST_STATUS.LOADED,
  },
  eaccess: {
    status: ISSUE_REQUEST_STATUS.FORBIDDEN,
    entity: 'process',
  },
};

export const processes = createSelector(
  [projectDataSelector],
  (projectData) => projectData.processes
);

export const getIssueProcess = (
  processesList: ProcessType[],
  issue?: Pick<IssueOnSingleView, 'process'>
): ProcessType | undefined => {
  const issueProcess = processesList.find(
    (process) => process._id === issue?.process._id
  );

  return issueProcess;
};

export const setIssueStatus = (issue: Res): IssueStatusType => {
  if (!issue.isLoading && issue.data) {
    return issueStatuses.loaded;
  }
  if (issue.error) {
    const { error, status } = issue.error;

    return {
      status: errorStatuses[status] || ISSUE_REQUEST_STATUS.UNKNOWN,
      entity: error?.entityType || 'issue',
    };
  }

  return issueStatuses.initial;
};

export function canEdit(
  userDto: UserInDto,
  userAccesses?: Issue['userAccesses']
): boolean {
  return (
    isManagerOrAdmin(userDto.role) ||
    hasEditInUserAccesses(userDto, userAccesses)
  );
}

function hasEditInUserAccesses(
  userDto: UserInDto,
  userAccesses?: Issue['userAccesses']
): boolean {
  return (
    Array.isArray(userAccesses) &&
    userAccesses.some(
      (access) =>
        access.accessLevel === AccessLevel.EDIT &&
        access.accessingUser === userDto._id
    )
  );
}
