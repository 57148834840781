import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { projectEntityToModel } from 'shared/domain/project/mapping/toModel';
import { ProjectInDto } from 'shared/domain/project/types/dto';
import { ProjectModel } from 'shared/domain/project/types/model';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { ChannelNames } from 'shared/domain/channelNames';
import * as dbProjects from '../../db/projects';
import { makeBroadcastEntity } from '../factories';
import { broadcastCreatedProjectError } from './error';

export const resyncCurrentProject = (): void => {
  self.dispatchEvent(
    new Event(RepositoryMessagesTypes.getSelectedProject)
  );
};

export const broadcastProject = makeBroadcastEntity(
  {
    getOne: async (id) => {
      const entity = await dbProjects.getOne(id);

      if (!entity) {
        throw new Error(`Cannot find project ${id}`);
      }
      return projectEntityToModel(entity);
    },
  },
  ChannelNames.projectChannel,
  DomainMessagesTypes.project
);

export const broadcastUpdatedProject = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.projectChannel
);

export const broadcastCreatedProject = async (
  projectInDto: ProjectInDto,
  uniqueId: string
): Promise<void> => {
  try {
    await dbProjects.addBatch([projectInDto]);
    const projectEntity = await dbProjects.getOne(projectInDto._id);
    if (!projectEntity) {
      throw new Error(`Cannot find project with id ${projectInDto._id}`);
    }
    broadcastProjectSuccess(
      DomainMessagesTypes.createdEntity,
      projectEntityToModel(projectEntity),
      uniqueId
    );
  } catch (error) {
    broadcastCreatedProjectError(error);
  }
};

function broadcastProjectSuccess(
  type: DomainMessagesTypes,
  data: ProjectModel,
  uniqueId?: string
): void {
  const broadcast = new BroadcastChannel(ChannelNames.projectChannel);
  broadcast.postMessage({
    data,
    type,
    uniqueId,
  });
  broadcast.close();
}

export const broadcastDeletedProject = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.projectChannel
);
