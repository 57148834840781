import {
  IssueEventModel,
  IssueLocationType,
  IssueModel,
} from 'shared/domain/issue/issueModel';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { EventEntity, IssueEntity } from './entity';
import { UserEntity } from 'shared/domain/user/types/entity';

export function issueEntityToModel(
  issue: IssueEntity,
  users: UserEntity[]
): IssueModel {
  return {
    ...issue,
    primaryData: {
      assignee: toLabelledUser(
        users.find((user) => user._id === issue.primaryData.assignee)
      ),
      contractNumbers: issue.primaryData.contractNumbers || [],
      description: issue.primaryData.description,
      detectionDate: issue.primaryData.detectionDate,
      level: issue.primaryData.level,
      positionOnMap: issue.primaryData.positionOnMap,
      site: issue.primaryData.site,
      subcontractors: issue.primaryData.subcontractors || [],
      title: issue.primaryData.title,
      executor: toLabelledUser(
        users.find((user) => user._id === issue.primaryData.executor)
      ),
      selectedLocationType:
        issue.primaryData.selectedLocationType || IssueLocationType.pin,
      targetAreas: issue.primaryData.targetAreas || [],
      finalAreas: issue.primaryData.finalAreas || [],
    },
    hashtag: issue._id ? issue._id.slice(-4) : '',
    events: issue.events.map((event) =>
      issueEventEntityToModel(event, users)
    ),
    createdBy: toLabelledUser(
      users.find((user) => user._id === issue.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === issue.modifiedBy)
    ),
    deleted: issue.deleted ? true : false,
    extendedData: {
      ...issue.extendedData,
    },
    mainImage: issue.mainImage,
  };
}

function issueEventEntityToModel(
  issueEventEntity: EventEntity,
  users: UserEntity[]
): IssueEventModel {
  const {
    _id,
    createdAt,
    deleted,
    description,
    modifiedAt,
    title,
    type,
    createdBy,
    modifiedBy,
  } = issueEventEntity;
  return {
    _id,
    createdAt,
    deleted,
    description,
    modifiedAt,
    title,
    type,
    createdBy: toLabelledUser(
      users.find((user) => user._id === createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === modifiedBy)
    ),
  };
}
