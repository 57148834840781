import { ContractModel } from 'shared/domain/contract/types/model';
import {
  ContractCreateOnView,
  ContractEditOnView,
} from 'shared/domain/contract/types/view';
import { FieldSize } from 'shared/types/fieldSize';
import {
  ErrorPresentation,
  Validation,
  notEmptyArrayValidation,
  requiredValidation,
  stringMinMaxValidation,
} from '../../../helpers/validators';
import { noValidation } from '../validation';

export function getEditContractValidationSchema(
  contracts: ContractModel[],
  editedContractId: string | null
): Validation<ContractEditOnView> {
  return {
    _id: noValidation,
    label: createLabelValidation(
      getForbiddenContractNumbers(contracts, editedContractId)
    ),
    parties: partiesValidation,
  };
}

export function getCreateContractValidationSchema(
  contracts: ContractModel[],
  editedContractId: string | null
): Validation<ContractCreateOnView> {
  return {
    label: createLabelValidation(
      getForbiddenContractNumbers(contracts, editedContractId)
    ),
    parties: partiesValidation,
  };
}

function getForbiddenContractNumbers(
  contracts: ContractModel[],
  editedContractId: string | null
): string[] {
  return contracts.reduce<string[]>((prev, next) => {
    if (editedContractId !== next._id) {
      prev.push(next.label);
    }

    return prev;
  }, []);
}

function partiesValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value) || notEmptyArrayValidation(value);
}

function createLabelValidation(forbiddenContractNumbers: string[]) {
  return function (value: unknown): ErrorPresentation {
    return (
      requiredValidation(value) ||
      stringMinMaxValidation(FieldSize.S, value) ||
      uniqueLabelValidation(value, forbiddenContractNumbers)
    );
  };
}

function uniqueLabelValidation(
  value: unknown,
  forbiddenContractNumbers: string[]
): ErrorPresentation {
  return forbiddenContractNumbers.includes(value as string)
    ? { helperText: { id: 'validation_contract_number_exists' } }
    : undefined;
}
