import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import cn from 'classnames';
import React, {
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useMemo,
} from 'react';
import useIsMobile from '../../../hooks/useIsMobile';
import styles from './styles';

// stop propagate to not awake clickAwayListener from ClosedAreas
const stopPropagation: TouchEventHandler = (e) => e.stopPropagation();

type Props = {
  action: (i: number) => void;
  showControllers: boolean;
  opacity?: number;
};

const Controllers: React.FC<Props> = ({
  action,
  showControllers,
  opacity,
}) => {
  const isMobile = useIsMobile();
  const cs = styles({ isMobile });

  if (!showControllers) {
    return null;
  }
  const back: MouseEventHandler = useCallback(
    (e) => {
      // stop propagate to not awake clickAwayListener from ClosedAreas
      e.stopPropagation();
      action(-1);
    },
    [action]
  );
  const forward: MouseEventHandler = useCallback(
    (e) => {
      // stop propagate to not awake clickAwayListener from ClosedAreas
      e.stopPropagation();
      action(1);
    },
    [action]
  );
  const style = useMemo(() => {
    return {
      opacity: typeof opacity === 'number' ? opacity : 1,
    };
  }, [opacity]);

  return (
    <>
      <div
        className={cn(cs.controller, 'left')}
        style={style}
        onClick={back}
        onTouchEnd={stopPropagation}
      >
        <ChevronLeftIcon />
      </div>
      <div
        className={cn(cs.controller, 'right')}
        onClick={forward}
        style={style}
        onTouchEnd={stopPropagation}
      >
        <ChevronRightIcon />
      </div>
    </>
  );
};

export default Controllers;
