import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import {
  ExecuteRestoreLevelUseCaseParams,
  RestoreLevelUseCase,
} from 'shared/domain/level/types/useCases';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { LevelHttpRestoreRequestModel } from '../httpQueue/level/types';

export class LevelRestorer implements RestoreLevelUseCase {
  constructor(
    private addRequest: (
      request: LevelHttpRestoreRequestModel
    ) => Promise<any>
  ) {}
  async execute({
    levelId,
    projectId,
    uniqueId,
  }: ExecuteRestoreLevelUseCaseParams): Promise<void> {
    debugLog('RestoreLevelUseCase', levelId);
    const url = `/project/${projectId}/level/${levelId}/restore`;
    const request: LevelHttpRestoreRequestModel = {
      createdAt: Date.now(),
      method: 'GET',
      data: {
        levelId: levelId,
        projectId,
        uniqueId: uniqueId,
      },
      url,
      entityType: HttpRequestModelType.level,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}
export const levelRestorer = new LevelRestorer(addRequest);
