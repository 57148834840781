import React, { ReactElement, useCallback, useMemo } from 'react';
import { Handler } from 'components/common/forms/fields/FinalCosts/types';
import {
  isGivenLocation,
  NavigationDestination,
} from '../../../../helpers/navigation';
import { useSelector } from 'react-redux';
import NavigationDrawerPresentational from './presentational';
import { ReactComponent as IssueNavigationIcon } from '../../../../assets/icons/navigation_issues.svg';
import { ReactComponent as AnalyticsNavigationIcon } from '../../../../assets/icons/navigation_analytics.svg';
import { ReactComponent as BrowseInspectionNavigationIcon } from '../../../../assets/icons/navigation_browseInspections.svg';
import { ReactComponent as StartInspectionNavigationIcon } from '../../../../assets/icons/navigation_startInspection.svg';
import { ReactComponent as InspectionNavigationIcon } from '../../../../assets/icons/navigation_inspections.svg';
import { ReactComponent as CompanyNavigationIcon } from '../../../../assets/icons/company.svg';
import { ReactComponent as ContractNavigationIcon } from '../../../../assets/icons/contract.svg';
import { ReactComponent as UserNavigationIcon } from '../../../../assets/icons/user.svg';
import { ReactComponent as ProjectPreviewNavigationIcon } from '../../../../assets/icons/navigation_project_preview.svg';
import { NavigationItemProps } from './types';
import {
  convertUserOnClientDtoToUserDto,
  currentUserSelector,
  userEqualityFunction,
} from '../../../../redux/selectors/users';
import { projectDataSelector } from 'redux/selectors/project';
import { UserInDto } from 'shared/dtos/in/user';
import { UserWithAuthAndPermissions } from 'shared/types/userRole';
import { DocumentationIcon } from './icons';
import { isAdmin } from 'shared/domain/role/isAdmin';
import { isStandard } from 'shared/domain/role/isStandard';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { isDefined } from 'shared/domain/role/isDefined';

const MemoIssueNavigationIcon = React.memo(IssueNavigationIcon);
const MemoAnalyticsNavigationIcon = React.memo(AnalyticsNavigationIcon);
const MemoBrowseInspectionNavigationIcon = React.memo(
  BrowseInspectionNavigationIcon
);
const MemoStartInspectionNavigationIcon = React.memo(
  StartInspectionNavigationIcon
);
const MemoInspectionNavigationIcon = React.memo(InspectionNavigationIcon);
const MemoCompanyNavigationIcon = React.memo(CompanyNavigationIcon);
const MemoContractNavigationIcon = React.memo(ContractNavigationIcon);
const MemoUserNavigationIcon = React.memo(UserNavigationIcon);
const MemoCurrentProjectNavigationIcon = React.memo(
  ProjectPreviewNavigationIcon
);
const MemoDocumentationIcon = React.memo(DocumentationIcon);

const NavigationDrawer = ({
  isNavigationExpanded,
  setNavigationExpanded,
  toggleNavigationExpanded,
}): ReactElement => {
  const { _id: projectId } = useSelector(projectDataSelector);

  const currentUser: UserWithAuthAndPermissions = useSelector(
    currentUserSelector,
    userEqualityFunction
  );

  const currentUserDto: UserInDto = useMemo(() => {
    return convertUserOnClientDtoToUserDto(projectId, currentUser.data);
  }, [projectId, currentUser]);

  const isCurrentUserManager = isManagerOrAdmin(currentUserDto.role);
  const isCurrentUserAdmin = isAdmin(currentUserDto.role);

  const setExpanded = useCallback<Handler>(() => {
    setNavigationExpanded(true);
  }, [setNavigationExpanded]);

  const setCollapsed: Handler = () => {
    setNavigationExpanded(false);
  };

  const navigationItems: NavigationItemProps[] = [
    {
      isSubItem: false,
      componentKey: NavigationDestination.issues,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.issues
      ),
      textId: 'navigation_drawer_item_issues',
      redirectAddress: '/issue',
      Icon: MemoIssueNavigationIcon,
      isNavigationExpanded,
    },
    {
      isSubItem: false,
      componentKey: NavigationDestination.documentations,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.documentations
      ),
      textId: 'general_documentation',
      redirectAddress: `/project/${projectId}/documentation`,
      Icon: MemoDocumentationIcon,
      isNavigationExpanded,
      disabled: !projectId,
    },
    {
      isSubItem: false,
      componentKey: NavigationDestination.analytics,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.analytics
      ),
      textId: 'navigation_drawer_item_analytics',
      redirectAddress: '/analytics',
      Icon: MemoAnalyticsNavigationIcon,
      isNavigationExpanded,
    },
    {
      isSubItem: false,
      componentKey: NavigationDestination.companies,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.companies
      ),
      textId: 'navigation_drawer_item_company',
      redirectAddress: '/company',
      Icon: MemoCompanyNavigationIcon,
      isNavigationExpanded,
    },
    {
      isSubItem: false,
      componentKey: NavigationDestination.contracts,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.contracts
      ),
      textId: 'navigation_drawer_item_contract',
      redirectAddress: '/contract',
      Icon: MemoContractNavigationIcon,
      isNavigationExpanded,
    },
  ];

  if (isDefined(currentUserDto.role) && !isStandard(currentUserDto.role)) {
    navigationItems.splice(
      1,
      0,
      {
        isSubItem: false,
        componentKey: NavigationDestination.inspections,
        isButtonRedirectLocationCurrentLocation: false,
        textId: 'navigation_drawer_item_inspections',
        Icon: MemoInspectionNavigationIcon,
        isNavigationExpanded,
      },
      {
        isSubItem: true,
        componentKey: NavigationDestination.browseInspectionTemplates,
        isButtonRedirectLocationCurrentLocation: isGivenLocation(
          NavigationDestination.browseInspectionTemplates
        ),
        // PT-2191 adjust for release
        textId: 'temporary_key_navigation_templates',
        Icon: MemoStartInspectionNavigationIcon,
        redirectAddress: `/project/${projectId}/inspectionTemplate`,
        isNavigationExpanded,
      },
      {
        isSubItem: true,
        componentKey: NavigationDestination.browseInspections,
        isButtonRedirectLocationCurrentLocation: isGivenLocation(
          NavigationDestination.browseInspections
        ),
        textId: 'navigation_drawer_item_browseInspections',
        Icon: MemoBrowseInspectionNavigationIcon,
        redirectAddress: '/inspection',
        isNavigationExpanded,
      }
    );
  }

  if (isCurrentUserManager || isCurrentUserAdmin) {
    navigationItems.splice(8, 0, {
      isSubItem: false,
      componentKey: NavigationDestination.users,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.users
      ),
      textId: 'navigation_drawer_item_users',
      redirectAddress: '/user',
      Icon: MemoUserNavigationIcon,
      isNavigationExpanded,
    });
  }

  if (isCurrentUserAdmin) {
    navigationItems.push({
      isSubItem: false,
      componentKey: NavigationDestination.projectSettings,
      isButtonRedirectLocationCurrentLocation: isGivenLocation(
        NavigationDestination.projectSettings
      ),
      textId: 'general_project',
      redirectAddress: '/projectSettings',
      Icon: MemoCurrentProjectNavigationIcon,
      isNavigationExpanded,
    });
  }

  return (
    <NavigationDrawerPresentational
      setCollapsed={setCollapsed}
      setExpanded={setExpanded}
      toggleNavigationExpanded={toggleNavigationExpanded}
      isNavigationExpanded={isNavigationExpanded}
      navigationItemsProps={navigationItems}
    />
  );
};
export default React.memo(NavigationDrawer);
