import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { StoreState } from '../setup/types/core';
import {
  getDefaultLocale,
  removeEscapingBackslash,
  updateHtmlRootLanguageAttributes,
} from './helpers';

import { useDidMount } from '../hooks/useDidMount';
import {
  ISO6391LanguageCode,
  ISO6391LanguageCodeOption,
} from '../shared/types/locale';
import { ClientContext } from '../with-client';
import { calendarLocales } from './calendarLocales';
import {
  TranslationsObject,
  getUnescapedTranslationObjectByLocale,
} from './getUnescapedTranslationObjectByLocale';

const englishTranslations = getEscapedTranslations(ISO6391LanguageCode.en);
const englishCalendarLocale = calendarLocales[ISO6391LanguageCode.en];

function getEscapedTranslations(
  locale: ISO6391LanguageCodeOption
): TranslationsObject {
  const unescapedTranslationObject =
    getUnescapedTranslationObjectByLocale(locale);
  return removeEscapingBackslash(unescapedTranslationObject);
}

type IntlContextType = {
  messages: TranslationsObject;
  calendarLocales: Locale;
  setLocale: (locale: ISO6391LanguageCode) => void;
};

const IntlContext = React.createContext<IntlContextType>({
  messages: englishTranslations,
  calendarLocales: englishCalendarLocale,
  setLocale: () => {},
});

const IntlProviderWrapper = ({
  children,
}: PropsWithChildren<{}>): React.ReactElement => {
  const { currentLocale } = useSelector((state: StoreState) => state.ui);
  const client = useContext(ClientContext);

  const setLocale = (locale: ISO6391LanguageCode): void => {
    updateHtmlRootLanguageAttributes(locale);
    client.setLanguage(locale);
  };

  useDidMount(() => {
    updateHtmlRootLanguageAttributes(currentLocale);
  });
  const translations = useMemo(
    () => getEscapedTranslations(currentLocale),
    [currentLocale]
  );
  return (
    <IntlContext.Provider
      value={{
        setLocale,
        messages: translations,
        calendarLocales: calendarLocales[currentLocale],
      }}
    >
      {/* // react 18 types
    // @ts-ignore */}
      <IntlProvider
        key={currentLocale}
        locale={currentLocale}
        messages={translations}
        defaultLocale={getDefaultLocale()}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};

export {
  ISO6391LanguageCode as AppLocales,
  IntlContext,
  IntlProviderWrapper,
};
