import { LabelledUser } from 'shared/domain/commonModel';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import {
  CompanyModel,
  InvolvementType,
  involvementTypes,
} from '../types/model';

export function toDisplayedInvolvementType(
  involvementType: InvolvementType,
  intl: IntlShape
): string {
  const projectInvolvementType = `involvement_type_${involvementType.toLowerCase()}`;
  return intl.formatMessage({ id: projectInvolvementType });
}

export function toLabelledInvolvementType(
  involvementType: InvolvementType,
  intl: IntlShape
): { _id: InvolvementType; label: string } {
  return {
    _id: involvementType,
    label: toDisplayedInvolvementType(involvementType, intl),
  };
}

export function companyModelToLabelledEntity(
  company: CompanyModel
): LabelledEntity {
  return {
    label: company.shortLabel,
    _id: company._id,
  };
}

export function createEmptyCompany(
  intl: IntlShape,
  now: DateTime<true>,
  author: LabelledUser
): CompanyOnView {
  return {
    _id: '',
    longLabel: '',
    projectInvolvementType: toLabelledInvolvementType(
      involvementTypes[0],
      intl
    ),
    shortLabel: '',
    sites: [],
    taxNumber: undefined,
    deleted: false,
    createdAt: now,
    modifiedAt: now,
    modifiedBy: {
      _id: author._id,
      label: author.label,
      email: author.email,
    },
    createdBy: {
      _id: author._id,
      label: author.label,
      email: author.email,
    },
  };
}
