import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { AbortUploadCustomEventDetail } from 'shared/domain/messages/httpQueue/eventMessages';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startUploadAbort(
  localId: number,
  type: Services.DOCUMENTS | Services.DOCUMENTATIONS
): void {
  debugLog('startUploadCancel', localId, type);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<AbortUploadCustomEventDetail>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.HTTP_QUEUE,
    method: ServiceMethods.ABORT_UPLOAD,
    data: {
      localId,
      type,
    },
  });
  broadcast.close();
}
