import { LogDestinationUrls } from 'shared/types/logger';
import { getEnvironment } from '../../utils/environment';

const prefix = 'https://collectors.de.sumologic.com/receiver/v1/http/';

const logDestinationUrls: LogDestinationUrls = {
  development: `${prefix}ZaVnC4dhaV1jvkiiqQo26-DaBKz8F2RIPZyyABTOcZNWZvbeB6STvRyHDngYVhivQdi5vvea66FwzsKCCT_lg5jKoPAVb-ocGmeeEtihkm58NO2AszhitQ==`,
  integration_dev: `${prefix}ZaVnC4dhaV1jvkiiqQo26-DaBKz8F2RIPZyyABTOcZNWZvbeB6STvRyHDngYVhivQdi5vvea66FwzsKCCT_lg5jKoPAVb-ocGmeeEtihkm58NO2AszhitQ==`,
  staging_dev: `${prefix}ZaVnC4dhaV1jvkiiqQo26-DaBKz8F2RIPZyyABTOcZNWZvbeB6STvRyHDngYVhivQdi5vvea66FwzsKCCT_lg5jKoPAVb-ocGmeeEtihkm58NO2AszhitQ==`,

  integration: `${prefix}ZaVnC4dhaV2AQCnOt3moHe9Nn-d56QPfikl9beEdO98XWAkPW6m0jxG0xwyq2sMQJsjzfAeezgn3ZaAt6zQqq0vxT27hVfCMUIklLlyZj5fB3WiWjTZkhw==`,
  staging: `${prefix}ZaVnC4dhaV1RmKXcFpSFDKpnrjUHF2kWmVSBJY3nBK2EkQcQpQJGFxokrRIwRka3WoUSXzdvXNCOohLytJNS5l5NrrKkGZd6CXa5ezDE-bmFoBEnIGxu6w==`,
  production: `${prefix}ZaVnC4dhaV18G0Q8hln1663JyrGgCQ5f12ae_dwsGzkhYPVajGcdspniHEd0tEIqUnx2M8J8e09lgvdF0ynd_s2m79FciIb6BZwh8DDXKa09QKvS_KFE1w==`,
};

export const endpoint = logDestinationUrls[getEnvironment()];
export const sourceName = 'Hustro Web App';
