import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { UserWithPermissionsInDto } from 'shared/types/userRole';

type UserData = {
  userData: UserWithPermissionsInDto;
};
export const broadcastCurrentUserPermissions = async (
  userData: UserData,
  source: string
): Promise<void> => {
  const broadcast = new BroadcastChannel(ChannelNames.currentUserChannel);
  broadcast.postMessage({
    data: userData,
    type: DomainMessagesTypes.currentUserPermissions,
    source,
  });
  broadcast.close();
};
