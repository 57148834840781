import { DocumentCreateModel } from 'shared/domain/document/documentCreateModel';
import { DocumentParentBond } from 'shared/domain/document/documentModel';
import { DrawingModel } from 'shared/domain/document/drawingModel';
import { UploadStatus } from 'shared/types/uploadStatus';

export type GetDocumentsCustomEvent =
  CustomEvent<GetDocumentsCustomEventDetail>;

export enum GetDocuments {
  query = 'query',
  ids = 'ids',
}
export interface GetDocumentsCustomEventDetail {
  type: GetDocuments;
}
export interface GetDocumentsByQueryCustomEventDetail
  extends GetDocumentsCustomEventDetail {
  type: GetDocuments.query;
  query: DocumentParentBond;
}
export interface GetDocumentsByIdsCustomEventDetail
  extends GetDocumentsCustomEventDetail {
  ids: number[];
  type: GetDocuments.ids;
}

export type GetDocumentCustomEvent =
  CustomEvent<GetDocumentCustomEventDetail>;
export type GetDocumentCustomEventDetail = { id: number };

export type CreateDocumentCustomEvent =
  CustomEvent<CreateDocumentCustomEventDetail>;
export type CreateDocumentCustomEventDetail = {
  baseUrl: string;
  document: DocumentCreateModel;
  uniqueId: string;
};

export type EditDocumentCustomEvent =
  CustomEvent<EditDocumentCustomEventDetail>;
export type EditDocumentCustomEventDetail = {
  localId: number;
  description?: string;
  _id?: string;
  clearLocalData?: true;
  drawingUploadStatus?: UploadStatus;
  deleted?: boolean;
};

export type DeleteDocumentCustomEvent =
  CustomEvent<DeleteDocumentCustomEventDetail>;
export type DeleteDocumentCustomEventDetail = {
  localId: number;
};

export type CreateDrawingCustomEvent =
  CustomEvent<CreateDrawingCustomEventDetail>;
export type CreateDrawingCustomEventDetail = {
  drawing: DrawingModel;
  localId: number;
};
