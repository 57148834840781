import { Spinner } from 'components/core';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';

import { StoreState } from 'setup/types/core';

export const withFetcherReady =
  (Component: React.ComponentType) =>
  ({ ...props }): ReactElement => (
    <WithFetcherReady>
      <Component {...props} />
    </WithFetcherReady>
  );

type WithFetcherReadyProps = { children: ReactElement };

function WithFetcherReady({
  children,
}: WithFetcherReadyProps): ReactElement {
  const projectData = useSelector(projectDataSelector);
  const userData = useSelector((store: StoreState) => store.user);

  if (!Boolean(projectData._id) || !userData.isAuthenticated) {
    return (
      <Spinner
        reason={`WithFetcherReady projectData._id: ${projectData._id}, userData.isAuthenticated: ${userData.isAuthenticated}`}
      />
    );
  }

  return children;
}
