import { Store, useCreateStore } from 'hooks/createStore';
import {
  ComponentType,
  FC,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

type IssueCountContextType = {
  issueCountStore: Store<number>;
  setCount: (number: number) => void;
  isLoadingStore: Store<boolean>;
  setLoading: (loading: boolean) => void;
};

const IssueCountContext = createContext<IssueCountContextType | undefined>(
  undefined
);

const WithIssueCount: FC<PropsWithChildren<{}>> = ({ children }) => {
  const issueCountStore = useCreateStore<number>(0);
  const isLoadingStore = useCreateStore<boolean>(false);

  const setCount = useCallback(
    (number: number) => {
      issueCountStore.set(number);
    },
    [issueCountStore]
  );

  const setLoading = useCallback(
    (loading: boolean) => {
      isLoadingStore.set(loading);
    },
    [isLoadingStore]
  );

  const ctx: IssueCountContextType = useMemo(() => {
    return {
      issueCountStore,
      isLoadingStore,
      setCount,
      setLoading,
    };
  }, [isLoadingStore, issueCountStore, setCount, setLoading]);

  return (
    <IssueCountContext.Provider value={ctx}>
      {children}
    </IssueCountContext.Provider>
  );
};

const withIssueCount =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithIssueCount>
      <Component {...props} />
    </WithIssueCount>
  );

function useIssueCount(): IssueCountContextType {
  const context = useContext(IssueCountContext);
  if (context === undefined) {
    throw new Error(
      'useIssueCount must be used within a IssueCountContextProvider'
    );
  }
  return context;
}

function IssueCount(): ReactElement | null {
  const { issueCountStore, isLoadingStore } = useIssueCount();
  const [count, setCount] = useState(issueCountStore.get());
  const [loading, setLoading] = useState(isLoadingStore.get());

  useEffect(() => {
    const action = (): void => {
      setCount(issueCountStore.get());
    };
    return issueCountStore.subscribe(action);
  }, [issueCountStore]);

  useEffect(() => {
    const action = (): void => {
      setLoading(isLoadingStore.get());
    };
    return isLoadingStore.subscribe(action);
  }, [isLoadingStore]);

  if (loading) {
    return null;
  }
  return <span>{count}</span>;
}

export { withIssueCount, useIssueCount, IssueCount };
