import { useUserChannelListener } from 'components/broadcastChannelListeners/withUserChannelListener';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { UserModel } from 'shared/domain/user/userModel';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allUsers
  );
}

export const useGetAllUsers = makeUseGetAll<UserModel>({
  useChannelListener: useUserChannelListener,
  postMessageChannel: ChannelNames.userChannel,
  postMessageType: DomainMessagesTypes.getAllUsers,
  isMatchingMessage,
  entityName: 'users',
});
