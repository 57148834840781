import { useEffect } from 'react';
import { useAuth0 } from '../../services/auth0/react-auth0.spa';
import { getAuth0Config } from '../../services/auth0/auth_config';

const UnauthorizedScreen = ({ from }: { from: string }): JSX.Element => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    const redirect_uri = getAuth0Config().redirectUnauthorizedUrl;

    localStorage.setItem('redirect_link', from);
    loginWithRedirect({
      authorizationParams: {
        redirect_uri,
      },
    });
  });

  return <div />;
};

export default UnauthorizedScreen;
