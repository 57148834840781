import { IssueProp } from '../../setup/types/core';
import { initialState } from '../reducers/issueReducer';
import { PersistedState } from 'redux-persist/es/types';

type PersistedStateV0 = Partial<IssueProp> & Partial<PersistedState>;

export const issueMigrations = {
  0: (previousState: PersistedState): PersistedStateV0 => {
    return {
      ...previousState,
      syncData: {},
    };
  },
  1: (): object => initialState,
};
