import { sanitizeInput } from 'helpers/misc';
import { sortByProp } from 'helpers/sort';
import { LabelledEntity } from 'shared/types/commonView';

type MaybeBool = boolean | undefined | '';
type OptionFilter = (
  phrase: string
) => (value: Partial<LabelledEntity>) => MaybeBool;

export const optionFilter: OptionFilter =
  (searchPhrase) =>
  (value): MaybeBool => {
    const option = value?.label?.toLowerCase();
    const phrase = searchPhrase.toLocaleLowerCase();

    return option?.search(sanitizeInput(phrase)) !== -1;
  };

type FilterSelected = (
  option: LabelledEntity,
  reverse?: boolean
) => (value: LabelledEntity) => boolean;

export const filterSelected: FilterSelected =
  (option, reverse) =>
  (value): boolean => {
    const match = value._id !== option._id;
    return reverse ? !match : match;
  };

export const optionalSort = <T extends LabelledEntity>(
  predicate: boolean,
  optionsFiltered: T[]
): T[] => {
  return predicate
    ? sortByProp(optionsFiltered, 'label')
    : optionsFiltered;
};
