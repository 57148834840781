import * as dbEnvironmentalAspects from '../db/environmentalAspects';
import * as dbEnvironmentalAspectsService from '../db/environmentalAspectsService';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeBroadcastAll, makeBroadcastClear } from './factories';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { environmentalAspectEntityToModel } from 'serviceWorker/repository/environmentalAspect/environmentalAspectEntityToModel';
import { debugLog } from 'shared/logger/debugLog';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { OnMessage } from './types';
import { BroadcastChannel } from 'broadcast-channel';

export const broadcastClearEnvironmentalAspects = makeBroadcastClear(
  ChannelNames.environmentalAspectChannel
);

export const broadcastAllEnvironmentalAspects = makeBroadcastAll(
  dbEnvironmentalAspectsService,
  {
    get: async () => {
      const environmentalAspects = await dbEnvironmentalAspects.get();
      return environmentalAspects.map(environmentalAspectEntityToModel);
    },
  },
  ChannelNames.environmentalAspectChannel,
  DomainMessagesTypes.allEnvironmentalAspects
);

const init = (): void => {
  const broadcast = new BroadcastChannel(
    ChannelNames.environmentalAspectChannel
  );

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllEnvironmentalAspects: {
        await getAllCurrentEnvironmentalAspects(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentEnvironmentalAspects(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const items = await dbEnvironmentalAspects.get();
  const end = Date.now();
  debugLog('getAllCurrentEnvironmentalAspects time:', end - start);
  broadcast.postMessage({
    data: { items },
    type: DomainMessagesTypes.allEnvironmentalAspects,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.ENVIRONMENTAL_ASPECTS,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
