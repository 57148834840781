import { ISO6391LanguageCode } from 'shared/types/locale';
import { isSupportedLocale } from './isSupportedLocale';

export function anyToSupportedISO6391LanguageCode(
  locale: any
): ISO6391LanguageCode {
  if (typeof locale !== 'string') {
    return ISO6391LanguageCode.en;
  }
  const twoFirstLetters = locale.slice(0, 2);
  if (isSupportedLocale(twoFirstLetters)) {
    return twoFirstLetters;
  }
  return ISO6391LanguageCode.en;
}
