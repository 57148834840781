import { BroadcastChannel } from 'broadcast-channel';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  ProjectCreateCustomEvent,
  ProjectDeleteCustomEvent,
  ProjectEditCustomEvent,
  ProjectRestoreCustomEvent,
} from 'shared/domain/messages/project/eventMessages';
import { ProjectInDto } from 'shared/domain/project/types/dto';
import {
  ConfigData,
  GetProjectCustomEvent,
} from 'serviceWorker/api/types';
import { broadcastAllProjects } from 'serviceWorker/broadcasts/project/all';
import { broadcastProject } from 'serviceWorker/broadcasts/project/one';
import { ChannelNames } from 'shared/domain/channelNames';
import * as projects from 'serviceWorker/db/projects';
import * as projectsService from 'serviceWorker/db/projectsService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import { getFetchConfig } from '../config';
import { Pull, ServiceDataShape } from '../factories/types';
import { projectSaver } from './addProject';
import { projectDeleter } from './deleteProject';
import { projectEditor } from './editProject';
import { fetchProjects } from './fetchProjects';
import { projectRestorer } from './restoreProject';

// TODO use generic factory
const pullProjects: Pull = async (
  data,
  abortController
): Promise<void> => {
  fetchProjects(data, abortController)
    ?.then(async (res: ProjectInDto[]) => {
      await projectsService.add({
        total: res.length,
        isDownloading: true,
      });
      const broadcast = new BroadcastChannel(ChannelNames.projectChannel);
      broadcast.postMessage({
        data: { isDownloading: true },
        type: DomainMessagesTypes.state,
      });
      await projects.addBatch(res);

      const projectsServiceData = createFinishedProjectServiceData(
        res.length
      );
      await projectsService.add(projectsServiceData);
      broadcastAllProjects(broadcast);
    })
    .catch(async (e: any) => {
      swLog(
        'Problem occured when fetching projects',
        LogLevel.ERROR,
        e,
        null
      );
      await projects.clear();
      await projectsService.add({
        total: undefined,
        isDownloading: false,
        isDownloaded: false,
        syncKey: undefined,
      });
    });
};

function createFinishedProjectServiceData(
  totalCount: number
): ServiceDataShape {
  return {
    total: totalCount,
    isDownloading: false,
    isDownloaded: true,
  };
}

const pullProjectsHandler = async (): Promise<void> => {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
  });
  const setup = await getFetchConfig();
  const status = await projectsService.get();
  const required: (keyof ConfigData)[] = ['frontendVersion', 'api'];
  const hasRequired =
    required.map((elem) => setup && setup[elem]).filter((e) => !e)
      .length === 0;

  const shouldPull = hasRequired && !status?.isDownloading;

  const shouldReset = status?.shouldReset;
  if (!setup) {
    return;
  }

  if (shouldPull || shouldReset) {
    await projectsService.reset();
    await projectsService.add({ isDownloading: true });
    await projects.clear();
    pullProjects(setup, abortController);
  }
};

const getProjectHandler = async (
  e: GetProjectCustomEvent
): Promise<void> => {
  await pullProjectsHandler();
  const status = await projectsService.get();

  if (!status?.isDownloaded) {
    await waitForAllProjects();
  }

  broadcastProject(e);
};

const waitForAllProjects = async (): Promise<void> => {
  return new Promise((resolve) => {
    const broadcast = new BroadcastChannel(ChannelNames.projectChannel);
    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.allProjects) {
        broadcast.close();
        resolve();
      }
    };
  });
};

export const init = (): void => {
  const debounced = debounce(pullProjectsHandler, 250);

  self.addEventListener(DomainMessagesTypes.getAllProjects, debounced);

  //@ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getProject,
    function onGetProject(e: GetProjectCustomEvent): void {
      debugLog('onGetProject', e);
      getProjectHandler(e);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addProject,
    function onAddProject(e: ProjectCreateCustomEvent): void {
      debugLog('addProject event', e);
      projectSaver.execute(e.detail.projectCreateModel, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editProject,
    async function onEditProject(
      e: ProjectEditCustomEvent
    ): Promise<void> {
      debugLog('editProject event', e);
      projectEditor.execute({
        projectEditModel: e.detail.projectEditModel,
        uniqueId: e.detail.uniqueId,
        projectId: e.detail.projectEditModel._id,
      });
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteProject,
    async function onDeleteProject(
      e: ProjectDeleteCustomEvent
    ): Promise<void> {
      debugLog('deleteProject event', e);
      projectDeleter.execute(e.detail.projectId, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.restoreProject,
    async function onRestoreProject(
      e: ProjectRestoreCustomEvent
    ): Promise<void> {
      debugLog('restoreProject event', e);
      projectRestorer.execute(e.detail.projectId, e.detail.uniqueId);
    }
  );
};
