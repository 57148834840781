import {
  updateOne as updateDocumentation,
  getOne,
} from 'serviceWorker/db/documentations';
import { UploadStatus } from 'shared/types/uploadStatus';

interface SetUploadStatus {
  (localId: number, uploadStatus: UploadStatus): Promise<number>;
}

export const setUploadStatus: SetUploadStatus =
  async function _setUploadStatus(localId, uploadStatus) {
    const documentation = await getOne(localId);
    const version = documentation?.versions[0];
    if (!version) {
      return Promise.resolve(1);
    }
    return updateDocumentation(localId, {
      versions: [{ ...version, uploadStatus }],
    });
  };
