import { makeStyles } from '@mui/styles';

const styles = makeStyles(() => ({
  controlRoot: {
    marginLeft: -4,
    marginRight: 0,
  },
  controlLabel: {
    fontSize: '0.75rem',
    lineHeight: '0.8rem',
  },
  radioRoot: {
    padding: 4,
  },
}));

export default styles;
