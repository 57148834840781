import { EnvironmentalAspectModel } from 'shared/domain/environmentalAspect/environmentalAspectModel';
import { EnvironmentalAspectEntity } from './entity';

export function environmentalAspectEntityToModel(
  entity: EnvironmentalAspectEntity
): EnvironmentalAspectModel {
  const { _id, label, processes, valueTranslations, createdByClientId } =
    entity;

  return {
    _id,
    label,
    processes,
    valueTranslations,
    createdByClientId,
  };
}
