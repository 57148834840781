import { Message } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { createUniqueId } from 'shared/utils/id/id';
import { DocumentationCreateModel } from './documentationModel';
import { listenToDocumentationCreatedMessage } from './listenToDocumentationCreatedMessage';
import { startDocumentationCreate } from './startDocumentationCreate';

export function saveDocumentationLocally(
  documentation: DocumentationCreateModel
): Promise<number> {
  const uniqueId = createUniqueId();

  return new Promise((resolve, reject): void => {
    const broadcast = listenToDocumentationCreatedMessage(
      (success: number[]): void => {
        broadcast.close();
        debugLog('documentation create success', success);
        resolve(success[0]);
      },
      (error: any[]): void => {
        broadcast.close();
        debugLog('documentation create error', error);
        reject(error[0]);
      },
      (event: Message) => {
        return event.uniqueId === uniqueId;
      }
    );

    startDocumentationCreate(documentation, uniqueId);
  });
}
