import React, {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { useGetOneInspection } from 'hooks/useGetInspection';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';
import { useDispatch, useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import {
  toProcessesObject,
  checkProcessesEquality,
} from 'redux/selectors/processes';
import { useInspectionTemplate } from '../withInspectionTemplate';
import { useDocuments, withDocuments } from '../withDocuments';
import { DocumentsOnTemplateView } from 'presentation/document/documentsOnTemplateView';
import {
  DocumentsOnInspectionView,
  documentsToDocumentsOnInspectionView,
} from 'presentation/document/documentsToDocumentsOnInspectionView';
import { toInspectionOnView } from 'presentation/inspection/inspectionModelToInspectionOnView';
import { InspectionOnView } from 'presentation/inspection/inspectionOnView';
import { useInspectionDependencies } from './model';
import {
  displayGenericErrorToaster,
  genericErrorToaster,
} from 'redux/actions/toasterActions';
import { useInspectionChannelListener } from '../../broadcastChannelListeners/withInspectionChannelListener';
import {
  Message,
  DomainMessagesTypes,
  isDataChanged,
} from 'shared/domain/messages/message';
import { useMountedRef } from 'hooks/useMountRef';

type InspectionContextType = {
  inspection: InspectionOnView | undefined;
  inspectionModel: InspectionModel | undefined;
  inspectionTemplateDocuments: DocumentsOnTemplateView;
  inspectionDocuments: DocumentsOnInspectionView;
  loading: boolean;
  setInspection: (inspection: InspectionModel) => void;
  getInspection: () => any;
  setId: (id: string) => void;
  setIgnoreDocuments: (bool: boolean) => void;
};

const InspectionContext = React.createContext<
  InspectionContextType | undefined
>(undefined);

const WithInspection: React.FC<{
  children?: ReactNode;
}> = withDocuments(({ children }) => {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();
  const { locale } = useIntl();
  const { subscribe } = useInspectionChannelListener();

  const { inspectionId: inspectionIdParam } = useParams<{
    inspectionId: string;
  }>();
  const [inspectionModel, setInspectionModel] = useState<
    InspectionModel | undefined
  >(undefined);
  const {
    inspectionTemplate,
    inspectionTemplateDocuments,
    loading: loadingTemplate,
    setId: setTemplateId,
  } = useInspectionTemplate();

  const {
    documents,
    loading: documentsLoading,
    setQuery,
  } = useDocuments();

  const [ignoreDocuments, setIgnoreDocuments] = useState(false);

  const { timezone } = useSelector(projectDataSelector);
  const processes = useSelector(toProcessesObject, checkProcessesEquality);

  const [inspection, setInspection] = useState<
    InspectionOnView | undefined
  >(undefined);
  const [inspectionDocuments, setInspectionDocuments] =
    useState<DocumentsOnInspectionView>({});

  const [inspectionId, setInspectionId] = useState<string | undefined>(
    inspectionIdParam || inspectionModel?._id
  );

  const [loading, setLoading] = useState<boolean>(Boolean(inspectionId));

  const { get: getInspectionPromise } = useGetOneInspection();

  const {
    loading: loadingDependencies,
    sites,
    levels,
    workTypes,
    companies,
    contracts,
  } = useInspectionDependencies();

  const getInspection = useCallback(() => {
    if (!inspectionId) {
      return;
    }
    getInspectionPromise(inspectionId)
      .then((inspectionModel) => {
        if (!mountedRef.current) {
          return;
        }
        setInspectionModel(inspectionModel);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        displayGenericErrorToaster(dispatch);
      });
  }, [dispatch, getInspectionPromise, inspectionId, mountedRef]);

  useEffect(() => {
    if (!inspectionId) {
      return;
    }

    getInspection();
  }, [inspectionId, getInspection]);

  useEffect(() => {
    if (inspectionModel) {
      if (!ignoreDocuments) {
        setQuery((prev: any) => {
          if (prev?.inspectionId === inspectionModel._id) {
            return prev;
          }
          return { inspectionId: inspectionModel._id };
        });
      }
      setTemplateId(inspectionModel.template);
    }
  }, [inspectionModel, setTemplateId, setQuery, ignoreDocuments]);

  useEffect(() => {
    if (loadingDependencies) {
      return;
    }
    if (inspectionTemplate && inspectionModel) {
      setInspection(
        toInspectionOnView(
          processes,
          inspectionTemplate,
          inspectionModel,
          sites,
          levels,
          workTypes,
          companies,
          contracts
        )
      );
      setLoading(false);
    }
  }, [
    processes,
    inspectionTemplate,
    inspectionModel,
    timezone,
    loadingDependencies,
    sites,
    levels,
    workTypes,
    companies,
    contracts,
  ]);

  useEffect(() => {
    setInspectionDocuments(
      documentsToDocumentsOnInspectionView(documents)
    );
  }, [documents]);

  useEffect(
    function dataListener() {
      const unsubscribe = subscribe((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (
          (event.data && isDataChanged(event)) ||
          event.type === DomainMessagesTypes.tableChanged
        ) {
          getInspection();
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [getInspection, mountedRef, subscribe]
  );

  const ctx = {
    inspection,
    inspectionModel,
    inspectionTemplateDocuments,
    inspectionDocuments,
    loading:
      loading ||
      loadingTemplate ||
      documentsLoading ||
      loadingDependencies,
    setId: setInspectionId,
    setInspection: setInspectionModel,
    getInspection: getInspection,
    setIgnoreDocuments,
  };

  return (
    <InspectionContext.Provider value={ctx}>
      {children}
    </InspectionContext.Provider>
  );
});

const withInspection =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithInspection>
      <Component {...props} />
    </WithInspection>
  );

function useInspection(): InspectionContextType {
  const context = React.useContext(InspectionContext);
  if (context === undefined) {
    throw new Error(
      'useInspection must be used within a InspectionContextProvider'
    );
  }
  return context;
}

export { WithInspection, withInspection, useInspection };
