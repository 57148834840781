import React, {
  ComponentType,
  NamedExoticComponent,
  ReactElement,
} from 'react';
import { Route } from 'react-router-dom';

import PrivateRoute from './PrivateRoute';
import Spinner from 'components/general/loaders/Spinner/Spinner';
import { UserInDto } from 'shared/dtos/in/user';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { projectDataSelector } from 'redux/selectors/project';

type GenericRoleAuthorizerProps = {
  authorized: boolean;
  unauthorized: boolean;
  authorizedComponent: NamedExoticComponent | ComponentType<any>;
  unauthorizedComponent: NamedExoticComponent | ComponentType<any>;
};

function GenericRoleAuthorizer(
  props: GenericRoleAuthorizerProps
): ReactElement {
  const {
    authorized,
    unauthorized,
    authorizedComponent,
    unauthorizedComponent,
  } = props;
  const project = useSelector(projectDataSelector);
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);

  if (!currentUser._id || !project._id) {
    return (
      <Spinner
        reason={`!currentUser._id: ${currentUser._id}, !project._id: ${project._id}`}
      />
    );
  }

  if (authorized) {
    return (
      <React.Suspense fallback={null}>
        <PrivateRoute component={authorizedComponent} />
      </React.Suspense>
    );
  }

  if (unauthorized) {
    // react 18 types
    // @ts-ignore
    return <Route component={unauthorizedComponent} />;
  }

  return <Spinner reason={'GenericRoleAuthorizer fallback'} />;
}

export const MemoGenericRoleAuthorizer = React.memo(GenericRoleAuthorizer);
