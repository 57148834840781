import { ReactElement, useState, useEffect } from 'react';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useIntl } from 'react-intl';
import { useGalleryContext } from 'components/common/gallery';
import { identitySelector } from 'components/common/gallery/selectors';
import { DocumentationBaseIds } from 'shared/types/documentation';
import { useIssueOverlayControls } from './WithIssueOverlayControls';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import TuneRoundedIcon from '@mui/icons-material/TuneRounded';
import { FiltersCounter } from 'components/core/Layout/filters/FiltersToggler';
import { getCanBeUsedAsMap } from '../model';
import { useMountedRef } from 'hooks/useMountRef';

export function DocumentationIssuesActions({
  disabledClass,
}: {
  disabledClass: string;
}): ReactElement | null {
  const mountedRef = useMountedRef();
  const [file] =
    useGalleryContext<DocumentationBaseIds>().useMainDisplayedFile(
      identitySelector
    );
  const canBeUsedAsMap = getCanBeUsedAsMap(
    file?.data?.extension,
    file?.imageConvertStatus
  );

  const {
    issuesVisibleStore,
    setFile,
    toggleVisible,
    toggleFiltersVisible,
    disabled,
    filtersDisabled,
    filtersDisabledReason,
  } = useIssueOverlayControls();

  const [visible, setVisible] = useState(issuesVisibleStore.get());

  useEffect(() => {
    setFile(file);
  }, [file, setFile]);

  useEffect(() => {
    return issuesVisibleStore.subscribe(() => {
      if (!mountedRef.current) return;
      setVisible(issuesVisibleStore.get());
    });
  }, [issuesVisibleStore, mountedRef]);

  if (!canBeUsedAsMap) {
    return null;
  }

  return (
    <>
      <ForbiddenTooltip
        visibleWhen={disabled}
        reason='documentation_file_not_used_as_map'
      >
        <li
          onClick={toggleVisible}
          className={disabled ? disabledClass : ''}
        >
          {visible && <VisibilityOutlinedIcon />}
          {!visible && <VisibilityOffOutlinedIcon />}
        </li>
      </ForbiddenTooltip>
      <ForbiddenTooltip
        visibleWhen={filtersDisabled}
        reason={filtersDisabledReason}
      >
        <li
          onClick={toggleFiltersVisible}
          className={filtersDisabled ? disabledClass : ''}
          style={{
            position: 'relative' as 'relative',
          }}
        >
          {visible && (
            <>
              <TuneRoundedIcon />
              <FiltersCounter
                style={{
                  boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.85)',
                }}
              />
            </>
          )}
          {!visible && <TuneRoundedIcon />}
        </li>
      </ForbiddenTooltip>
    </>
  );
}

export function DisabledDocumentationIssuesActions({
  disabledClass,
}: {
  disabledClass: string;
}): ReactElement | null {
  const intl = useIntl();
  const SHOW = intl.formatMessage({
    id: 'documentation_show_issues_action',
  });
  const FILTERS = intl.formatMessage({
    id: 'documentation_filter_action',
  });

  const [file] =
    useGalleryContext<DocumentationBaseIds>().useMainDisplayedFile(
      identitySelector
    );
  const canBeUsedAsMap = getCanBeUsedAsMap(
    file?.data?.extension,
    file?.imageConvertStatus
  );

  if (canBeUsedAsMap) {
    return null;
  }

  return (
    <>
      <ForbiddenTooltip
        visibleWhen={true}
        reason='documentation_file_not_supported_as_map'
      >
        <li className={disabledClass}>
          <VisibilityOffOutlinedIcon />
          <span>{SHOW}</span>
        </li>
      </ForbiddenTooltip>
      <ForbiddenTooltip
        visibleWhen={true}
        reason='documentation_file_not_supported_as_map'
      >
        <li className={disabledClass}>
          <TuneRoundedIcon />
          <span>{FILTERS}</span>
        </li>
      </ForbiddenTooltip>
    </>
  );
}
