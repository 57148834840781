import { LevelInDto } from 'shared/dtos/in/level';
import {
  LevelCreateOutDto,
  LevelEditsOutDto,
} from 'shared/dtos/out/level';

export interface ILevelUploader {
  POST: (url: string, data: LevelCreateOutDto) => Promise<any>;
  PUT: (url: string, data: LevelEditsOutDto) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadLevelChanges {
  (
    uploader: ILevelUploader,
    body: LevelEditsOutDto,
    url: string
  ): Promise<LevelInDto[]>;
}

export interface UploadLevel {
  (
    uploader: ILevelUploader,
    body: LevelCreateOutDto,
    url: string
  ): Promise<LevelInDto>;
}

export interface UploadLevelDelete {
  (uploader: ILevelUploader, url: string): Promise<unknown>;
}

export interface UploadLevelRestore {
  (uploader: ILevelUploader, url: string): Promise<unknown>;
}

export function uploadLevelChanges(
  uploader: ILevelUploader,
  body: LevelEditsOutDto,
  url: string
): Promise<LevelInDto[]> {
  return uploader.PUT(url, body);
}

export function uploadLevel(
  uploader: ILevelUploader,
  body: LevelCreateOutDto,
  url: string
): Promise<LevelInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadLevelDelete(
  uploader: ILevelUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}

export function uploadLevelRestore(
  uploader: ILevelUploader,
  url: string
): Promise<unknown> {
  return uploader.GET(url);
}
