import React, { MouseEvent, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { TextField, InputAdornment, TextFieldProps } from '@mui/material';
import { ButtonIcon } from 'components/general';
import { Clear, InsertInvitation } from '@mui/icons-material';
import { DateTypeIndicator } from 'setup/types/core';
import {
  datepickerDateToProjectTimezonedString,
  dateTimeToDayMomentDateTime,
  dateTimeToDayMomentString,
} from 'shared/utils/date/dates';
import { FilterDateType } from 'components/dataProviders/withIssueFilters/types';
import { DayMomentValidation } from 'shared/types/time';
import { WithStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

type CalendarInputAdornmentProps = {
  clearable: boolean;
  indicator: string;
  filterName: string;
  onDateClear: () => void;
};

const CalendarInputAdornment = ({
  clearable,
  indicator,
  filterName,
  onDateClear,
}: CalendarInputAdornmentProps): React.ReactElement => {
  const handleDateClear = (event: MouseEvent): void => {
    event.stopPropagation();
    onDateClear();
  };

  return (
    <InputAdornment position='end'>
      <ButtonIcon
        {...(clearable && { onClick: handleDateClear })}
        data-qa={`filter-sidebar-date-button-${filterName}-${indicator}`}
      >
        {clearable ? <Clear /> : <InsertInvitation />}
      </ButtonIcon>
    </InputAdornment>
  );
};

export const calculateDate = (
  date: DateTime,
  toDate: FilterDateType['toDate'],
  fromDate: FilterDateType['fromDate'],
  dateType: string
): FilterDateType => {
  return {
    toDate:
      dateType === DateTypeIndicator.TO
        ? dateTimeToDayMomentString(date, DayMomentValidation.end)
        : toDate,
    fromDate:
      dateType === DateTypeIndicator.FROM
        ? dateTimeToDayMomentString(date, DayMomentValidation.start)
        : fromDate,
  };
};

type DateFieldType = WithStyles<any> & {
  label: string;
  maxDate?: DateTime | null | undefined;
  minDate?: DateTime | null | undefined;
  margin?: boolean;
  indicator: DateTypeIndicator;
  filterName: string;
  dataQa: string;
  value: DateTime | null | undefined;
  onDateClear: (indicator: DateTypeIndicator) => void;
  // MUI5 TODO
  onDateChange: (date: any, indicator: DateTypeIndicator) => void;
};

export const DateField = ({
  label,
  maxDate,
  minDate,
  value,
  margin,
  indicator,
  onDateChange,
  dataQa,
}: DateFieldType): React.ReactElement => {
  return (
    <span
      {...(margin && {
        style: {
          marginRight: '8px',
        },
      })}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePicker
          label={label}
          value={value}
          data-qa={dataQa}
          format='dd/MM/yyyy'
          maxDate={maxDate ?? undefined}
          minDate={minDate ?? undefined}
          // @ts-ignore
          onChange={(date): void => onDateChange(date, indicator)}
          slotProps={{
            // The actions will be different between desktop and mobile
            actionBar: ({ wrapperVariant }) => ({
              actions:
                wrapperVariant === 'desktop'
                  ? ['clear', 'today']
                  : ['clear', 'cancel', 'today'],
            }),
          }}
          // TODO MUI5
          // maxDateMessage={intl.formatMessage({
          //   id: 'filters_error_dateFromBeforeTo',
          // })}
          // minDateMessage={intl.formatMessage({
          //   id: 'filters_error_dateToBeforeFrom',
          // })}
        />
      </LocalizationProvider>
    </span>
  );
};
