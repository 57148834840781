import { isViewer } from 'shared/domain/role/isViewer';
import {
  noPermission,
  noPermissionInOffline,
  Permission,
} from 'helpers/permission';
import { LabelledEntity } from 'shared/types/commonView';
import { UserInDto } from 'shared/dtos/in/user';

export function toLabel(entity: LabelledEntity): string {
  return entity.label;
}

export function editPermission(
  isInspectionCompleted: boolean,
  isOffline: boolean,
  currentUser: UserInDto
): Permission {
  if (isOffline) {
    return noPermissionInOffline();
  }

  if (isInspectionCompleted) {
    return noPermission('inspection_edit_on_completed_warning');
  }

  if (isViewer(currentUser.role)) {
    return noPermission('tooltip_action_forbidden');
  }

  return {
    permission: true,
  };
}
