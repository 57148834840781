import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { OnMessage } from '../types';
import { getFromDbAndBroadcastAllOrganizations } from './all';
import { broadcastState } from './state';

export const initOrganizationsListener = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.organizationChannel);

  const handler: OnMessage = async (event) => {
    debugLog('organization channel event', event);
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }
      case DomainMessagesTypes.getAllOrganizations: {
        await getFromDbAndBroadcastAllOrganizations(broadcast);
        return;
      }
    }
  };

  broadcast.onmessage = handler;
};
