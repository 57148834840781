import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';
import { useInspectionChannelListener } from 'components/broadcastChannelListeners/withInspectionChannelListener';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allInspections
  );
}

export const useGetAllInspections = makeUseGetAll<InspectionModel>({
  useChannelListener: useInspectionChannelListener,
  postMessageChannel: ChannelNames.inspectionChannel,
  postMessageType: DomainMessagesTypes.getAllInspections,
  isMatchingMessage,
  entityName: 'inspections',
});
