import { ENVIRONMENTS, HOSTS } from '../enums';

const DEV_ENVIRONMENTS = {
  integration: ENVIRONMENTS.INTEGRATION_DEV,
  staging: ENVIRONMENTS.STAGING_DEV,
} as const;

export function isDevEnv(): boolean {
  return getEnvironment() === ENVIRONMENTS.DEVELOPMENT;
}

export function getEnvironment(): ENVIRONMENTS {
  let location;
  if (typeof window !== 'undefined') {
    location = window.location;
  } else {
    // because the global object in service worker is self
    location = self.location;
  }
  const host = location.hostname as HOSTS;

  switch (host) {
    case HOSTS.INTEGRATION:
      return ENVIRONMENTS.INTEGRATION;
    case HOSTS.STAGING:
      return ENVIRONMENTS.STAGING;
    case HOSTS.PRODUCTION:
      return ENVIRONMENTS.PRODUCTION;
    default:
      return selectDevelopmentEnvironment();
  }
}

function selectDevelopmentEnvironment(): ENVIRONMENTS {
  if (
    process.env.REACT_APP_DEV_ENVIRONMENT &&
    // @ts-ignore
    DEV_ENVIRONMENTS[process.env.REACT_APP_DEV_ENVIRONMENT]
  ) {
    // @ts-ignore
    return DEV_ENVIRONMENTS[process.env.REACT_APP_DEV_ENVIRONMENT];
  }
  return ENVIRONMENTS.DEVELOPMENT;
}
