import * as config from 'serviceWorker/db/config';
import { GetReportSignedRequestEvent } from 'serviceWorker/api/types';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { pull } from '../pull';
import {
  defaultConfig,
  generateHeaders,
} from 'serviceWorker/helpers/httpRequests';
import { broadcastReportSignedRequest } from 'serviceWorker/broadcasts/reports';
import { ConfigData } from 'serviceWorker/db/config';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { CreateReportCustomEvent } from 'shared/domain/messages/report/eventMessages';
import { reportRequestCreator } from './createReportRequest';
import { logWithRethrow } from 'serviceWorker/db/helpers';
import { IssueListReportModel } from 'shared/domain/report/reportModel';
import { SingleIssueReportModel } from 'shared/domain/report/singleIssueReportModel';
import { getToken } from '../config';
import { SingleInspectionReportModel } from 'shared/domain/report/singleInspectionReportModel';
import { LogLevel } from 'shared/types/logger';
import { frontendVersionWithBuildNumber } from 'shared/version';
import { apiUrl as apiUrlFromGlobalObject } from 'shared/apiUrl';

function pullSignedRequest(
  setup: ConfigData | undefined,
  abortController: AbortController,
  reportId: string,
  reportToken: string
): Promise<Response> {
  const headers: HeadersInit = getHeadersForPullSignedRequest(setup);

  const url: string = getUrlForPullSignedRequest(
    setup,
    reportId,
    reportToken
  );

  return pull(url, {
    ...defaultConfig,
    method: 'GET',
    headers,
    signal: abortController.signal,
  });
}

function getHeadersForPullSignedRequest(
  setup: config.ConfigData | undefined
): HeadersInit {
  const token = getToken();
  const canGenerateHeaders =
    token && setup?.locale && setup?.frontendVersion;

  return setup && canGenerateHeaders
    ? generateHeaders(setup)
    : {
        'Content-Type': 'application/json',
        'hustro-client': 'web',
        'hustro-client-version': frontendVersionWithBuildNumber,
      };
}

function getUrlForPullSignedRequest(
  setup: config.ConfigData | undefined,
  reportId: string,
  reportToken: string
): string {
  const apiUrl = setup ? setup.api : apiUrlFromGlobalObject;

  return `${apiUrl}/v2/report/${reportId}/download?token=${reportToken}`;
}

async function getReportSignedRequestHandler(
  e: GetReportSignedRequestEvent
): Promise<void> {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
  });
  debugLog('getReportSignedRequestHandler event', e);
  const { id, token } = e.detail;

  const setup: ConfigData | undefined = await config.get();

  try {
    const response: Response = await pullSignedRequest(
      setup,
      abortController,
      id,
      token
    );

    //we can't use parseResponse here, because we care about the response contents, event if its status is not 200
    const parsedResponse = await response.json().catch((e) =>
      logWithRethrow({
        msg: 'Could not parse response',
        logLevel: LogLevel.ERROR,
        errorObj: e,
        additionalInfo: {
          response: response,
        },
      })
    );

    debugLog(parsedResponse);
    broadcastReportSignedRequest(id, parsedResponse, response.status);
  } catch (e) {
    swLog(
      'Problem occured when getting signedRequest',
      LogLevel.INFO,
      e,
      null
    );
  }
}

export const init = (): void => {
  //@ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getReportSignedRequest,
    function onGetReportSignedRequest(
      e: GetReportSignedRequestEvent
    ): void {
      debugLog('onGetReportSignedUrl', e);
      getReportSignedRequestHandler(e);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addReport,
    async function onAddReport(e: CreateReportCustomEvent): Promise<void> {
      debugLog('addReport event', e);
      reportRequestCreator.execute(
        e.detail as
          | IssueListReportModel
          | SingleIssueReportModel
          | SingleInspectionReportModel,
        e.detail.uniqueId
      );
    }
  );
};
