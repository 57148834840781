import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& > p': {
        fontFamily: 'Archivo',
        fontSize: '0.875rem',
        fontWeight: 400,
        lineHeight: '15px',
        color: '#979797',
        margin: 'unset',
        marginBottom: '24px',
      },
    },
    cardsContainer: {
      marginTop: '24px',
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      gap: '8px',
      marginBottom: '16px',
    },
    firstIssueDialogHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      paddingRight: '10px',
    },
    firstIssueDialogIcon: {
      opacity: 0.54,
      cursor: 'pointer',
      alignSelf: 'center',
    },
    firstIssueDialogContent: {
      marginTop: '14px',
      marginBottom: '14px',
    },
  })
);
