import {
  IssueEventModel,
  IssueModel,
} from 'shared/domain/issue/issueModel';
import { getOne as getOneIssue } from 'serviceWorker/db/issues';
import { DocumentModel } from 'shared/domain/document/documentModel';
import { issueEntityToModel } from 'serviceWorker/repository/issue/issueEntityToModel';
import { ISendDocumentUrlBuilder } from 'serviceWorker/repository/document/sendDocumentUrlBuilder';
import { getUsersSynchronized } from '../users/getUsers';

interface IssueService {
  getOne(id: string): Promise<IssueModel>;
}

class SendDocumentUrlBuilder implements ISendDocumentUrlBuilder {
  constructor(private issueService: IssueService) {}
  execute(document: DocumentModel, projectId: string): Promise<string> {
    if (document.issueId) {
      return this.buildIssueUrl(document, projectId);
    }
    if (document.templateId) {
      return this.buildTemplateUrl(document, projectId);
    }
    if (document.inspectionId) {
      return this.buildInspectionUrl(document, projectId);
    }
    return Promise.reject(new Error('Not implemented.'));
  }

  private async buildIssueUrl(
    document: DocumentModel,
    projectId: string
  ): Promise<string> {
    const issue = await this.issueService.getOne(document.issueId);
    if (!issue._id) {
      throw new Error('Issue remote id not found.');
    }
    const event = document.eventId
      ? issue.events.find((event) => event._id === document.eventId)
      : undefined;

    if (document.eventId && !event?._id) {
      throw new Error('Event remote id not found.');
    }

    return `/project/${projectId}/issue/${
      issue._id
    }${this.createEventUrlPart(event)}${this.createDocumentUrlPart(
      document
    )}`;
  }

  private async buildTemplateUrl(
    document: DocumentModel,
    projectId: string
  ): Promise<string> {
    return `/project/${projectId}${this.createTemplateUrlPart(
      document
    )}${this.createChecklistUrlPart(document)}${this.createDocumentUrlPart(
      document
    )}`;
  }

  private async buildInspectionUrl(
    document: DocumentModel,
    projectId: string
  ): Promise<string> {
    return `/project/${projectId}${this.createInspectionUrlPart(
      document
    )}${this.createProtocolUrlPart(document)}${this.createDocumentUrlPart(
      document
    )}`;
  }

  private createDocumentUrlPart(document: DocumentModel): string {
    return document._id ? `/document/${document._id}` : '/document';
  }

  private createEventUrlPart(event: IssueEventModel | undefined): string {
    return event ? `/event/${event._id}` : '';
  }

  private createTemplateUrlPart(document: DocumentModel): string {
    const templateUrl = `/inspectionTemplate/${document.templateId}`;
    return document.checklistId
      ? templateUrl
      : `${templateUrl}/generalNorm`;
  }

  private createChecklistUrlPart(document: DocumentModel): string {
    return document.checklistId
      ? `/checklistItem/${document.checklistId}/norm`
      : '';
  }

  private createInspectionUrlPart(document: DocumentModel): string {
    return `/inspection/${document.inspectionId}`;
  }

  private createProtocolUrlPart(document: DocumentModel): string {
    if (!document.protocolId) {
      throw new Error('Missing document protocolId binding.');
    }
    return `/protocolItem/${document.protocolId}/complianceCheck`;
  }
}

export const sendDocumentUrlBuilder = new SendDocumentUrlBuilder({
  getOne: async (id: string) => {
    const [issue, users] = await Promise.all([
      getOneIssue(id),
      getUsersSynchronized(),
    ]);
    if (!issue) {
      throw new Error('Cannot find issue with id ' + id);
    }
    return issueEntityToModel(issue, users);
  },
});
