import initIssuesListener from './issues';
import { initUsersListener } from './user/init';
import initAnalyticsListener from './analytics';
import initInspectionTemplatesListener from './inspectionTemplates';
import initDocumentationsListener from './documentations';
import initDirectoriesListener from './directories';
import initSitesListener from './sites';
import initLevelsListener from './levels';
import initWorktypesListener from './worktypes';
import initHazardCategoriesListener from './hazardCategories';
import initEnvironmentalAspectsListener from './environmentalAspects';
import initCorrectiveActionTypesListener from './correctiveActionTypes';
import initIssueFormListener from './issueForm';
import initInspectionsListener from './inspections';
import initVisibleFieldsListener from './visibleFields';
import { initCompaniesListener } from './company/init';
import { initContractsListener } from './contract/init';
import { initProjectsListener } from './project/init';
import { initOrganizationsListener } from './organization/init';

export default (): void => {
  initAnalyticsListener();
  initIssuesListener();
  initCompaniesListener();
  initContractsListener();
  initUsersListener();
  initProjectsListener();
  initOrganizationsListener();
  initInspectionTemplatesListener();
  initDocumentationsListener();
  initDirectoriesListener();
  initSitesListener();
  initLevelsListener();
  initWorktypesListener();
  initHazardCategoriesListener();
  initEnvironmentalAspectsListener();
  initCorrectiveActionTypesListener();
  initIssueFormListener();
  initInspectionsListener();
  initVisibleFieldsListener();
};
