import { UserModel } from 'shared/domain/user/userModel';
import { ProjectPermissionDto, UserInDto } from 'shared/dtos/in/user';
import { StoreState } from 'setup/types/core';
import {
  UserWithPermissionsInDto,
  UserWithAuthAndPermissions,
  UserRole,
} from 'shared/types/userRole';
import { nowISO } from 'shared/utils/date/dates';

function getPermissionsOrCreateDefault(
  projectId: string,
  projectPermissions?: ProjectPermissionDto
): Pick<UserInDto, 'role' | 'sites' | 'processes'> {
  const { role, processes, sites } = projectPermissions ?? {
    role: UserRole.standard,
    sites: [],
    processes: [],
  };

  return { role, processes, sites };
}

export function convertUserOnClientDtoToUserModel(
  currentProjectId: string,
  userOnClientDto: UserWithPermissionsInDto
): UserModel {
  const { _id, permissions, email, label } = userOnClientDto;

  const projectPermissions = permissions.find(
    (permission) => permission.project === currentProjectId
  );

  // TODO user on client has no created at and modified at properties
  return {
    createdAt: nowISO(),
    modifiedAt: nowISO(),
    _id,
    email,
    label,
    ...getPermissionsOrCreateDefault(currentProjectId, projectPermissions),
  };
}

export function convertUserOnClientDtoToUserDto(
  currentProjectId: string,
  userOnClientDto: UserWithPermissionsInDto
): UserInDto {
  const { _id, permissions, email, label } = userOnClientDto;

  const projectPermissions = permissions.find(
    (permission) => permission.project === currentProjectId
  );

  // TODO user on client has no created at and modified at properties
  return {
    createdAt: nowISO(),
    modifiedAt: nowISO(),
    _id,
    email,
    label,
    ...getPermissionsOrCreateDefault(currentProjectId, projectPermissions),
  };
}

export function currentProjectUserSelector(store: StoreState): UserInDto {
  const currentProjectId = store.projectData._id;

  return convertUserOnClientDtoToUserDto(
    currentProjectId,
    store.user.data
  );
}

export function currentProjectUserModelSelector(
  store: StoreState
): UserModel {
  const currentProjectId = store.projectData._id;

  return convertUserOnClientDtoToUserModel(
    currentProjectId,
    store.user.data
  );
}

export function currentUserSelector(
  state: StoreState
): UserWithAuthAndPermissions {
  return state.user;
}

function equalData(
  prev: UserWithPermissionsInDto,
  curr: UserWithPermissionsInDto
): boolean {
  return (
    prev._id === curr._id &&
    prev.permissions === curr.permissions &&
    prev.label === curr.label &&
    prev.email === curr.email
  );
}

function equalError(prev: null | string, curr: null | string): boolean {
  return prev === curr;
}

function equalIsAuthenticated(prev: boolean, curr: boolean): boolean {
  return prev === curr;
}

export function userEqualityFunction(
  prev: UserWithAuthAndPermissions,
  curr: UserWithAuthAndPermissions
): boolean {
  return (
    equalData(prev.data, curr.data) &&
    equalError(prev.error, curr.error) &&
    equalIsAuthenticated(prev.isAuthenticated, curr.isAuthenticated)
  );
}
