import { createStyles, makeStyles } from '@mui/styles';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { forwardRef, memo, ReactElement, Ref } from 'react';
import { useIntl } from 'react-intl';
import cn from 'classnames';
import { SimpleCheckboxGroupProps } from './types';
import { LabelledEntity } from 'shared/types/commonView';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiCheckbox-root': {
        zIndex: 0,
        '&.Mui-checked .MuiIconButton-label:after': {
          content: '""',
          top: '31%',
          left: '32%',
          width: '35%',
          height: '39%',
          position: 'absolute',
          backgroundColor: theme.palette.primary.contrastText,
          zIndex: -1,
          borderColor: 'transparent',
        },
      },
    },
    label: {
      color: '#979797',
      fontSize: '0.75rem',
      fontWeight: 400,
    },
  })
);

function SimpleCheckboxGroup(
  props: SimpleCheckboxGroupProps<LabelledEntity>,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const {
    available,
    labelId,
    required,
    fieldName,
    disabled,
    handleChange,
    error,
    isHalf,
    value,
    getOptionLabel,
  } = props;
  const intl = useIntl();
  const classes = useStyles();

  return (
    <div ref={ref} className={cn(classes.root, isHalf && 'halfWidth')}>
      <FormControl
        // variant='standard'
        component='fieldset'
        aria-controls={fieldName}
        disabled={Boolean(disabled)}
        required={required}
        error={Boolean(error)}
      >
        {labelId ? (
          <span className={classes.label}>
            {labelId ? intl.formatMessage({ id: labelId }) : ''}
          </span>
        ) : null}
        <FormGroup>
          {available.map((key) => (
            <FormControlLabel
              key={key._id}
              control={
                <Checkbox
                  color='primary'
                  checked={value.includes(key._id)}
                  onChange={(_, isChecked) => {
                    const newValue = isChecked
                      ? [...value, key._id]
                      : value.filter((val: string) => val !== key._id);

                    handleChange(_, newValue);
                  }}
                  name={key._id}
                />
              }
              label={intl.formatMessage({ id: getOptionLabel(key) })}
            />
          ))}
        </FormGroup>
      </FormControl>
    </div>
  );
}

export const MemoSimpleCheckboxGroup = memo(
  forwardRef(SimpleCheckboxGroup)
);
