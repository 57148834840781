import {
  DocumentationCreateModel,
  DocumentationEditModel,
  DocumentationModel,
  VersionBaseModel,
  VersionModel,
} from 'shared/domain/documentation/documentationModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import {
  DocumentationInDto,
  VersionInDto,
} from 'shared/dtos/in/documentation';
import { UserEntity } from 'shared/domain/user/types/entity';
import { UploadStatus } from 'shared/types/uploadStatus';
import { nowISO } from 'shared/utils/date/dates';
import {
  DocumentationChanges,
  DocumentationEntity,
  DocumentationInsertEntity,
  DocumentationLocalInsertEntity,
  VersionEntity,
  VersionInsertEntity,
} from './entity';

export function documentationInDtoToCreateEntity(
  documentation: DocumentationInDto
): DocumentationInsertEntity {
  return {
    _id: documentation._id,
    createdAt: documentation.createdAt,
    createdBy: documentation.createdBy,
    deleted: documentation.deleted ? 1 : 0,
    modifiedAt: documentation.modifiedAt,
    modifiedBy: documentation.modifiedBy,
    name: documentation.name,
    number: documentation.number,
    parentId: documentation.parentId ? documentation.parentId : '',
    versions: documentation.versions.map(versionInDtoToEntity),
    syncStatus: SyncStatus.SUCCESS,
  };
}

function versionInDtoToEntity(version: VersionInDto): VersionEntity {
  return {
    _id: version._id,
    createdAt: version.createdAt,
    createdBy: version.createdBy,
    deleted: version.deleted,
    modifiedAt: version.modifiedAt,
    modifiedBy: version.modifiedBy,
    extension: version.extension,
    remoteVersionId: version.remoteVersionId,
    uploadStatus: version.uploadStatus,
    syncStatus: SyncStatus.SUCCESS,
    imageConvertStatus: version.imageConvertStatus,
  };
}

export function documentationCreateModelToLocalInsertEntity(
  documentation: DocumentationCreateModel
): DocumentationLocalInsertEntity {
  const now = nowISO();
  return {
    createdAt: now,
    createdBy: documentation.createdBy?._id,
    deleted: 0,
    modifiedAt: now,
    modifiedBy: documentation.modifiedBy?._id,
    name: documentation.name,
    number: documentation.number,
    parentId: documentation.parentId,
    versions: documentation.versions.map((version) =>
      versionBaseModelToLocalInsertEntity(version, now)
    ),
    syncStatus: SyncStatus.PENDING,
  };
}

function versionBaseModelToLocalInsertEntity(
  version: VersionBaseModel,
  now: string = nowISO()
): VersionInsertEntity {
  return {
    createdAt: now,
    createdBy: version.createdBy?._id,
    deleted: false,
    extension: version.extension,
    modifiedAt: now,
    modifiedBy: version.modifiedBy?._id,
    remoteVersionId: '',
    uploadStatus: UploadStatus.unknown,
    syncStatus: SyncStatus.PENDING,
    localData: version.localData!,
    type: version.type!,
    imageConvertStatus: version.imageConvertStatus,
  };
}

export function documentationEntityToModel(
  documentation: DocumentationEntity,
  users: UserEntity[]
): DocumentationModel {
  return {
    _id: documentation._id,
    createdAt: documentation.createdAt,
    deleted: documentation.deleted,
    localId: documentation.localId,
    modifiedAt: documentation.modifiedAt,
    name: documentation.name,
    parentId: documentation.parentId,
    versions: documentation.versions.map((version) =>
      versionEntityToModel(version, users)
    ),
    createdBy: toLabelledUser(
      users.find((user) => user._id === documentation.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === documentation.modifiedBy)
    ),
    number: documentation.number,
  };
}

function versionEntityToModel(
  version: VersionEntity,
  users: UserEntity[]
): VersionModel {
  return {
    _id: version._id,
    createdAt: version.createdAt,
    deleted: version.deleted,
    modifiedAt: version.modifiedAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === version.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === version.modifiedBy)
    ),
    extension: version.extension,
    remoteVersionId: version.remoteVersionId,
    uploadStatus: version.uploadStatus,
    localData: version.localData,
    type: version.type,
    imageConvertStatus: version.imageConvertStatus,
  };
}

export function documentationEditModelToChanges(
  documentationEditModel: DocumentationEditModel
): DocumentationChanges {
  const result: DocumentationChanges = {};
  if (documentationEditModel.name !== undefined)
    result.name = documentationEditModel.name;
  if (documentationEditModel.number !== undefined)
    result.number = documentationEditModel.number;
  return result;
}

type DocumentationAsMapChanges = {
  toRemove: Set<string>;
  toAdd: Set<string>;
};
export function documentationEditModelToMapChanges(
  documentationEditModel: DocumentationEditModel,
  currentlyUsedIn: string[]
): DocumentationAsMapChanges {
  const toRemove = new Set(currentlyUsedIn);
  const toAdd = new Set<string>();

  documentationEditModel.levels?.forEach((lvl) => {
    if (currentlyUsedIn.includes(lvl._id)) {
      toRemove.delete(lvl._id);
    } else {
      toAdd.add(lvl._id);
    }
  });

  return {
    toRemove,
    toAdd,
  };
}
