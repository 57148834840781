import { Divider, CircularProgress } from '@mui/material';
import { MemoStatefulAutocompleteWithText } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import { ButtonText, ButtonContained } from 'components/general';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { useStyles as useFormStyles } from 'components/common/forms/formStyles/styles';
import { useStyles } from './styles';
import cn from 'classnames';
import { FormFieldProps } from 'components/inspection/Form/types';

type SetableDirectoryFields = {
  name: string;
};

export function DirectoryForm({
  onCancel,
  onSubmit,
  isPosting,
}: {
  onCancel: () => void;
  onSubmit: () => void;
  isPosting: boolean;
}): ReactElement {
  const formClasses = useFormStyles();
  const classes = useStyles();

  const nameField: FormFieldProps<SetableDirectoryFields> = {
    formKey: `name`,
    required: true,
    labelId: 'directory_name_field_label',
    fieldName: `directory-name`,
    localStorageKey: `directory-name`,
    reserveSpaceForHelperText: false,
    dense: false,
    'data-qa': 'directory_name_field',
  };

  return (
    <div className={classes.root}>
      <div className={cn(formClasses.root, classes.form)}>
        <div className='row'>
          <MemoStatefulAutocompleteWithText {...nameField} />
        </div>
      </div>
      <Divider />
      <div className={classes.submitButtons}>
        <ButtonText onClick={onCancel} disabled={isPosting}>
          <FormattedMessage id='general_cancel' />
        </ButtonText>
        <ButtonContained
          onClick={onSubmit}
          disabled={isPosting}
          endIcon={
            isPosting ? (
              <CircularProgress
                size={13}
                thickness={4}
                disableShrink
                color='secondary'
              />
            ) : undefined
          }
        >
          <FormattedMessage id='general_save' />
        </ButtonContained>
      </div>
    </div>
  );
}
