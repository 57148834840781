import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    height: '100%',
  },
  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  items: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'row',
    '& > div': {
      '&:not(:last-of-type)': {
        marginRight: 10,
      },
    },
  },
});

const IssueSkeleton = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Skeleton variant='rectangular' width={'100%'} height={80} />
        <div style={{ marginTop: 100 }} />
        <div className={classes.items}>
          <Skeleton variant='rectangular' width={'25%'} height={50} />
          <Skeleton variant='rectangular' width={'25%'} height={50} />
          <Skeleton variant='rectangular' width={'25%'} height={50} />
          <Skeleton variant='rectangular' width={'25%'} height={50} />
        </div>
        <div className={classes.items}>
          <Skeleton variant='rectangular' width={'25%'} height={50} />
          <Skeleton variant='rectangular' width={'25%'} height={50} />
          <Skeleton variant='rectangular' width={'25%'} height={50} />
          <Skeleton variant='rectangular' width={'25%'} height={50} />
        </div>
        <div style={{ marginTop: 40 }} />
        <Skeleton variant='text' width={'20%'} height={30} />
        <Skeleton variant='rectangular' width={'100%'} height={80} />
        <div style={{ marginTop: 60 }} />
        <Skeleton variant='text' width={'20%'} height={30} />
        <Skeleton variant='rectangular' width={'100%'} height={80} />
      </div>
    </div>
  );
};

export default IssueSkeleton;
