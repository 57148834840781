import { FilledSegment, Segment, SegmentOutDto, Segments } from './types';
import { ErrorPresentation } from '../../helpers/validators';

export function getOutDtoSegments(segments: Segments): SegmentOutDto[] {
  return segments.reduce<SegmentOutDto[]>((dtos, segment) => {
    if (isSegmentFilledAndValid(segment)) {
      const { rangeMax, rangeMin } = segment;
      dtos.push({ rangeMin, rangeMax });
    }

    return dtos;
  }, []);
}

export function validateSegment(segment: Segment): ErrorPresentation {
  const { rangeMin, rangeMax } = segment;

  if (isSegmentEmpty(segment)) {
    return undefined;
  }

  if (isSegmentFilledAndValid(segment)) {
    return undefined;
  }

  if (rangeMin === undefined || rangeMax === undefined) {
    return {
      helperText: {
        id: 'average_resolution_time_chart_segment_validation_from_to_filled',
      },
    };
  }

  if (rangeMin > rangeMax) {
    return {
      helperText: {
        id: 'average_resolution_time_chart_segment_validation_from_smaller_than_to',
      },
    };
  }
}

export function filterValidSegments(segments: Segments): Segments {
  return segments.filter(isSegmentFilledAndValid);
}

function isSegmentEmpty(segment: Segment): boolean {
  const { rangeMin, rangeMax } = segment;

  return rangeMin === undefined && rangeMax === undefined;
}

function isSegmentFilledAndValid(
  segment: Segment
): segment is FilledSegment {
  const { rangeMin, rangeMax } = segment;

  if (rangeMin === undefined || rangeMax === undefined) {
    return false;
  }

  return rangeMin <= rangeMax;
}
