import { BroadcastChannel } from 'broadcast-channel';
import { GetChartCustomEvent } from 'serviceWorker/api/types';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import * as analytics from 'serviceWorker/db/analytics';
import { ChartDataEntity } from 'serviceWorker/db/analytics';
import { debounce } from 'serviceWorker/helpers/debounce';
import { ChannelNames } from 'shared/domain/channelNames';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { Chart, FilterValues } from 'shared/types/analytics';
import { getIssues } from '../issues/getIssues';
import { calculate } from './charts';
import {
  calculateFinances,
  canReuseLastSavedChart,
  createChartDataEntity,
  getConfig,
} from './model';

async function handleGetChart(event: GetChartCustomEvent): Promise<void> {
  const chart: Chart = event?.detail.chart;
  const recievedFilters: any = event?.detail.filters;
  const recievedLocale: string = event?.detail.locale;
  const forceUpdate: boolean | undefined = event?.detail.forceUpdate;
  const recievedAdditionalParams: any | undefined =
    event?.detail.additionalParams;
  const broadcast = new BroadcastChannel(ChannelNames.analyticsChannel);
  const { series, filters, locale, additionalParams, updatedAt } =
    await getChart(
      chart,
      recievedFilters,
      recievedLocale,
      recievedAdditionalParams,
      forceUpdate
    );

  broadcast.postMessage({
    type: DomainMessagesTypes.chartData,
    data: {
      chart: chart,
      series: series,
      filters: filters,
      locale: locale,
      additionalParams: additionalParams,
      updatedAt: updatedAt,
    },
  });
  broadcast.close();
}

async function handleGetFinancialData(): Promise<void> {
  const issues = await getIssues();

  const calculatedData = calculateFinances(issues);

  const broadcast = new BroadcastChannel(ChannelNames.analyticsChannel);
  broadcast.postMessage({
    type: DomainMessagesTypes.financialData,
    data: calculatedData,
  });
}

export const init = (): void => {
  const handleGetFinancialDataDebounced = debounce(
    handleGetFinancialData,
    250
  );
  // @ts-ignore ts does not like Custom Event.
  self.addEventListener(RepositoryMessagesTypes.getChart, handleGetChart);
  self.addEventListener(
    RepositoryMessagesTypes.getFinancialData,
    handleGetFinancialDataDebounced
  );
};

async function getChart(
  chart: Chart,
  filtersFromClient: FilterValues,
  locale: string,
  additionalParams?: any,
  forceUpdate?: boolean
): Promise<ChartDataEntity> {
  const recievedChart = await analytics.getOne(chart);
  if (
    canReuseLastSavedChart(
      recievedChart,
      forceUpdate,
      locale,
      filtersFromClient,
      additionalParams
    )
  ) {
    return recievedChart;
  } else {
    const [issues, projectData] = await Promise.all([
      getIssues(),
      getConfig(),
    ]);
    const data = await calculate(
      chart,
      issues,
      filtersFromClient,
      projectData.timezone,
      additionalParams
    );

    const chartEntity = createChartDataEntity(
      data,
      filtersFromClient,
      chart,
      projectData.projectId,
      locale,
      additionalParams,
      Date.now()
    );

    analytics.addBatch([chartEntity]);

    return chartEntity;
  }
}
