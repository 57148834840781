import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import hash from 'hash-sum';
import { IssueFormEntity } from 'serviceWorker/repository/issueForm/issueForm';
import { makeBroadcastAll } from 'serviceWorker/broadcasts/factories';
import { ChannelNames } from 'shared/domain/channelNames';
import { ConfigData } from 'serviceWorker/db/config';
import * as issueForm from 'serviceWorker/db/issueForm';
import * as issueFormService from 'serviceWorker/db/issueFormService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { LogLevel } from 'shared/types/logger';
import { getFetchConfig } from '../config';
import { isSameAsPrev } from '../factories/isSameAsPrev';
import { Pull, ServiceDataShape } from '../factories/types';
import { fetchIssueForm } from './fetchIssueForm';

const prevUpdate = {
  current: {
    hashString: '',
    itemsString: '',
  },
};

const broadcastAllIssueFormData = makeBroadcastAll(
  issueFormService,
  issueForm,
  ChannelNames.issueFormChannel,
  DomainMessagesTypes.allIssueForm
);

const pullIssueForm: Pull = async (
  data,
  abortController
): Promise<void> => {
  await issueFormService.add({ isDownloading: true });
  fetchIssueForm(data, abortController)
    .then(async (response: IssueFormEntity[] | undefined) => {
      if (!response) {
        await issueFormService.add({
          total: undefined,
          isDownloading: false,
          isDownloaded: false,
          syncKey: undefined,
        });
        return;
      }

      await issueFormService.add({
        total: response.length,
        isDownloading: true,
      });
      const issueFormsEmpty = (await issueForm.get()).length === 0;

      const itemsString = JSON.stringify(response);
      const hashString = hash(itemsString);

      const currentUpdate = {
        hashString: hashString,
        itemsString: itemsString,
      };
      if (
        !isSameAsPrev(currentUpdate, prevUpdate.current) ||
        issueFormsEmpty
      ) {
        prevUpdate.current = currentUpdate;
        await issueForm.updateBatch(response as IssueFormEntity[]);
      }

      const issueFormServiceData = createFinishedIssueFormServiceData(
        response.length
      );
      await issueFormService.add(issueFormServiceData);
      broadcastAllIssueFormData();
    })
    .catch(async (e: any) => {
      swLog(
        'Problem occured when fetching issueForms',
        LogLevel.INFO,
        e,
        null
      );
      await issueForm.clear();
      await issueFormService.add({
        total: undefined,
        isDownloading: false,
        isDownloaded: false,
        syncKey: undefined,
      });
    });
};

const pullIssueFormHandler = async (): Promise<void> => {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
  });
  const setup = await getFetchConfig();
  const status = await issueFormService.get();
  const required: (keyof ConfigData)[] = [
    'frontendVersion',
    'api',
    'projectId',
  ];
  const hasRequired =
    required.map((elem) => setup && setup[elem]).filter((e) => !e)
      .length === 0;

  const shouldPull = hasRequired && !status?.isDownloading;

  const shouldReset = status?.shouldReset;
  if (!setup) {
    return;
  }

  if (shouldPull || shouldReset) {
    // clears shouldReset && cleans old users
    await issueFormService.reset();
    await issueFormService.add({ isDownloading: true });
    pullIssueForm(setup, abortController);
  }
};

export const init = (): void => {
  const syncDebounced = debounce(pullIssueFormHandler, 250);

  self.addEventListener(
    RepositoryMessagesTypes.syncIssueForm,
    syncDebounced
  );
};

function createFinishedIssueFormServiceData(
  totalCount: number
): ServiceDataShape {
  return {
    total: totalCount,
    isDownloading: false,
    isDownloaded: true,
  };
}
