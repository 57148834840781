import { HashMap } from 'shared/types/commonView';

class Dispatcher {
  private readonly events: HashMap<{ listeners: Function[] }>;
  constructor() {
    this.events = {};
  }

  addListener = (event: string, callback: Function): void | boolean => {
    if (this.events[event] === undefined) {
      this.events[event] = {
        listeners: [],
      };
    }

    this.events[event].listeners.push(callback);
  };

  removeListener = (event: string, callback: Function): void | boolean => {
    if (this.events[event] === undefined) {
      return false;
    }

    this.events[event].listeners = this.events[event].listeners.filter(
      (listener) => {
        return listener.toString() !== callback.toString();
      }
    );
  };

  dispatch = (event: string, details: unknown): void | boolean => {
    if (this.events[event] === undefined) {
      return false;
    }

    this.events[event].listeners.forEach((listener) => {
      listener(details);
    });
  };
}

export default Dispatcher;
