import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { UserInDto } from 'shared/dtos/in/user';
import { broadcastUploadedUserError } from 'serviceWorker/broadcasts/user/error';
import {
  broadcastUploadedUser,
  broadcastUpdatedUser,
  broadcastDeletedUser,
} from 'serviceWorker/broadcasts/user/one';

export function broadcastUserResponse(
  request: HttpRequestEntity,
  response: UserInDto | any
): void {
  if (response.error) {
    return broadcastUploadedUserError(response.error);
  }
  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastUploadedUser(response, request.data.uniqueId) as any;
  }
  if (request.method === 'PUT') {
    return broadcastUpdatedUser(response, request.data.uniqueId) as any;
  }
  if (request.method === 'DELETE') {
    return broadcastDeletedUser(response, request.data.uniqueId);
  }
}
