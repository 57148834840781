import { BroadcastChannel } from 'broadcast-channel';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { ChannelNames } from 'shared/domain/channelNames';
import { debuglog } from 'util';
import { makeUseGet } from './makeUseGet';

export const useGetOneInspectionTemplate = makeUseGet<
  InspectionTemplateModel,
  string
>({
  listenMessageChannel: ChannelNames.inspectionTemplateChannel,
  isMatchingMessage,
  postMessage: broadcastGetOneInspectionTemplate,
  entityName: 'inspectionTemplate',
});

function broadcastGetOneInspectionTemplate(id: string): void {
  debuglog('send broadcastGetOneInspectionTemplate');
  const broadcast = new BroadcastChannel(
    ChannelNames.inspectionTemplateChannel
  );
  broadcast.postMessage({
    type: DomainMessagesTypes.getInspectionTemplate,
    data: {
      id,
    },
  });
  broadcast.close();
}

function isMatchingMessage(id: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.inspectionTemplate &&
    Boolean(event.data) &&
    event.data._id === id
  );
}
