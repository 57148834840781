import { broadcastDocumentationDeleted } from 'serviceWorker/broadcasts/documentations';
import { remove as removeDocumentation } from 'serviceWorker/db/documentations';

interface HardRemoveDocumentation {
  (localId: number): Promise<unknown>;
}

export const hardRemoveDocumentation: HardRemoveDocumentation =
  async function _hardRemoveDocumentation(localId) {
    await removeDocumentation(localId);
    return broadcastDocumentationDeleted(localId);
  };
