import {
  ModelBase,
  CreatableModel,
  ModifiableModel,
  DeletableModel,
} from 'shared/domain/commonModel';
import { Identificable } from 'shared/types/commonView';

export enum InvolvementType {
  SUBCONTRACTOR = 'SUBCONTRACTOR',
  GENERAL_CONTRACTOR = 'GENERAL_CONTRACTOR',
  INVESTOR = 'INVESTOR',
}

type BaseCompany = {
  // contracts: string[];
  longLabel: string;
  projectInvolvementType: InvolvementType;
  shortLabel: string;
  sites: string[];
  taxNumber?: string;
  correspondenceAddress?: {
    streetAndNumber?: string;
    postalCode?: string;
    country?: string;
  };
  registeredOfficeAddress?: {
    streetAndNumber?: string;
    postalCode?: string;
    country?: string;
  };
};

export type CompanyModel = ModelBase & BaseCompany;

export type CompanyCreateModel = CreatableModel &
  ModifiableModel &
  DeletableModel &
  BaseCompany & { projectId: string };

export type CompanyEditModel = Identificable &
  BaseCompany & { projectId: string };

export const involvementTypes = [
  InvolvementType.SUBCONTRACTOR,
  InvolvementType.GENERAL_CONTRACTOR,
  InvolvementType.INVESTOR,
];

export const projectInvolvementTypeLabelIds: {
  [k in InvolvementType]: string;
} = {
  [InvolvementType.GENERAL_CONTRACTOR]:
    'involvement_type_general_contractor',
  [InvolvementType.INVESTOR]: 'involvement_type_investor',
  [InvolvementType.SUBCONTRACTOR]: 'involvement_type_subcontractor',
};
