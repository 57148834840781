import { ConfigData } from 'serviceWorker/db/config';
import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from 'serviceWorker/helpers/httpRequests';
import { pull } from '../pull';

export function fetchDocumentUrl(
  data: ConfigData,
  fullUrl: string
): Promise<any> {
  const config = {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
  };
  config.cache = 'default';
  return pull(fullUrl, config).then(parseResponse);
}
