import { LabelledUser } from 'shared/domain/commonModel';
import { CompanyOnView } from 'shared/domain/company/types/view';
import {
  CompanyCreateOutDto,
  CompanyEditOutDto,
} from 'shared/dtos/out/company';
import { DateTime } from 'luxon';
import { IntlShape } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { toDisplayedInvolvementType } from './mapping/toView';
import {
  CompanyCreateModel,
  CompanyEditModel,
  CompanyModel,
  InvolvementType,
  involvementTypes,
} from './types/model';

export function companyModelToLabelledEntity(
  company: CompanyModel
): LabelledEntity {
  return {
    label: company.shortLabel,
    _id: company._id,
  };
}

export function companyOnViewToCompanyCreateModel(
  fields: CompanyOnView,
  projectId: string
): CompanyCreateModel {
  return {
    ...fields,
    // contracts: fields.contracts.map(contract => contract._id),
    createdAt: fields.createdAt.toISO(),
    modifiedAt: fields.modifiedAt.toISO(),
    projectInvolvementType: fields.projectInvolvementType._id,
    sites: fields.sites.map((site) => site._id),
    taxNumber: fields.taxNumber,
    projectId,
  };
}

function textOnViewToTextOnModel(
  value: string | undefined | null
): string | undefined {
  if (value === null) {
    return '';
  }
  return value;
}

export function companyOnViewToCompanyEditModel(
  fields: CompanyOnView,
  projectId
): CompanyEditModel {
  return {
    shortLabel: fields.shortLabel,
    longLabel: fields.longLabel,
    _id: fields._id,
    // contracts: fields.contracts.map(contract => contract._id),
    projectInvolvementType: fields.projectInvolvementType._id,
    sites: fields.sites.map((site) => site._id),
    taxNumber: textOnViewToTextOnModel(fields.taxNumber),
    projectId,
  };
}

export function companyEditModelToEditOutDto(
  model: CompanyEditModel
): CompanyEditOutDto {
  return {
    longLabel: model.longLabel,
    projectInvolvementType: model.projectInvolvementType,
    shortLabel: model.shortLabel,
    sites: model.sites,
    taxNumber: model.taxNumber,
  };
}

export function companyCreateModelToCreateOutDto(
  model: CompanyCreateModel
): CompanyCreateOutDto {
  return {
    longLabel: model.longLabel,
    projectInvolvementType: model.projectInvolvementType,
    shortLabel: model.shortLabel,
    sites: model.sites,
    taxNumber: model.taxNumber,
  };
}

export function toLabelledInvolvementType(
  involvementType: InvolvementType,
  intl: IntlShape
): { _id: InvolvementType; label: string } {
  return {
    _id: involvementType,
    label: toDisplayedInvolvementType(involvementType, intl),
  };
}

export function createEmptyCompany(
  intl: IntlShape,
  now: DateTime<true>,
  author: LabelledUser
): CompanyOnView {
  return {
    _id: '',
    longLabel: '',
    projectInvolvementType: toLabelledInvolvementType(
      involvementTypes[0],
      intl
    ),
    shortLabel: '',
    sites: [],
    taxNumber: undefined,
    deleted: false,
    createdAt: now,
    modifiedAt: now,
    modifiedBy: {
      _id: author._id,
      label: author.label,
      email: author.email,
    },
    createdBy: {
      _id: author._id,
      label: author.label,
      email: author.email,
    },
  };
}
