import {
  getSearchPhraseChangeEventName,
  SearchPhraseChangeEventDetail,
} from 'components/common/SearchInput/helpers';
import { EntityLabel } from 'components/common/SearchInput/types';
import React, { useEffect } from 'react';

export function useSearchUpdate(
  entityLabel: EntityLabel,
  setSearchPhrase: React.Dispatch<React.SetStateAction<string>>
): void {
  useEffect(() => {
    function updateSearchPhrase(
      e: CustomEvent<SearchPhraseChangeEventDetail>
    ): void {
      setSearchPhrase(e.detail.searchPhrase);
    }
    const eventName = getSearchPhraseChangeEventName(entityLabel);

    // @ts-ignore ts does not like custom event here. Can't properly type it.
    window.addEventListener(eventName, updateSearchPhrase);

    return (): void => {
      // @ts-ignore ts does not like custom event here. Can't properly type it.
      window.removeEventListener(eventName, updateSearchPhrase);
    };
  }, [entityLabel, setSearchPhrase]);
}
