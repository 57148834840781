import { PromiseExtended } from 'dexie';
import { HustroDb, HustroDbTablesUnion } from '.';
import {
  logWithRethrow,
  Operation,
  getErrorTransactionMsg,
} from './helpers';
import { wrapQuery } from './queryWrapper';
import { LogLevel } from 'shared/types/logger';

export function entityDbClearFactory(
  db: HustroDb,
  dbEntityTables: HustroDbTablesUnion[],
  dbEntityClear: () => Promise<void>,
  dbServiceClear: () => Promise<void>,
  broadcastClear: () => void
): () => Promise<void> {
  return wrapQuery(db, (): PromiseExtended<void> => {
    const tables = dbEntityTables.map((table) => db[table]);

    return db
      .transaction(
        'rw',
        tables,
        function clearTransaction(): Promise<any> {
          return Promise.all([dbEntityClear(), dbServiceClear()]);
        }
      )
      .then(() => {
        broadcastClear();
      })
      .catch((e) => {
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg([Operation.clear], dbEntityTables),
          errorObj: e,
          additionalInfo: null,
        });
      });
  });
}
