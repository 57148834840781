import React, { useEffect, useRef, useState, useCallback } from 'react';
import cn from 'classnames';
import styles from './styles';

type Props = {
  handleInput: Function;
  children: React.ReactNode;
  'data-qa': string;
};

const DragAndDrop: React.FC<Props> = ({
  handleInput,
  children,
  ['data-qa']: dataQa,
}) => {
  const cs = styles();
  const [isOver, setIsOver] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleDragIn = useCallback((e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(true);
  }, []);

  const handleDrag = useCallback((e: any): void => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragOut = useCallback((e: any): void => {
    e.preventDefault();
    e.stopPropagation();
    setIsOver(false);
  }, []);

  const handleDrop = useCallback(
    (e: any): void => {
      e.preventDefault();
      e.stopPropagation();
      setIsOver(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        handleInput(Object.values(e.dataTransfer.files));
      }
    },
    [handleInput]
  );

  useEffect((): (() => void) => {
    const div = ref.current;
    if (div) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('drop', handleDrop);
      div.addEventListener('dragover', handleDrag);
    }

    return (): void => {
      if (div) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('drop', handleDrop);
        div.removeEventListener('dragover', handleDrag);
      }
    };
  });

  return (
    <div
      className={cn(cs.dropzone, { [cs.active]: isOver })}
      ref={ref}
      data-qa={dataQa}
    >
      {children}
    </div>
  );
};

export default DragAndDrop;
