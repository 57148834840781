import { ReactElement } from 'react';
import { Color } from 'shared/utils/colors';
import { useSizeScaledToContainerWidth } from './common';

export const plannedAreaBackgroundPattern = 'plannedAreaBackgroundPattern';
export const plannedAreaBackgroundPatternMarked =
  'plannedAreaBackgroundPatternMarked';

const height = 100;
export function PlannedAreaPatternDefinitions(): ReactElement {
  const props = {
    height,
    patternUnits: 'userSpaceOnUse',
    patternTransform: 'rotate(45 50 50)',
    pointerEvents: 'none',
  };

  const strokeWidth = useSizeScaledToContainerWidth(1.5);

  const width = strokeWidth * 2;
  return (
    <svg width={0} height={0}>
      <defs>
        <pattern
          id={plannedAreaBackgroundPattern}
          width={width}
          {...props}
        >
          <Line color={Color.red} strokeWidth={strokeWidth} />
        </pattern>
        <pattern
          id={plannedAreaBackgroundPatternMarked}
          width={width}
          {...props}
        >
          <Line color={Color.purple} strokeWidth={strokeWidth} />
        </pattern>
      </defs>
    </svg>
  );
}

function Line({ color, strokeWidth }): ReactElement {
  return (
    <line
      pointerEvents='none'
      stroke={color}
      strokeWidth={strokeWidth}
      y2={height}
    />
  );
}
