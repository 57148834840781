import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { UserEntity } from 'shared/domain/user/types/entity';
import { InspectionTemplateEntity } from './entity';

export function inspectionTemplateEntityToModel(
  entity: InspectionTemplateEntity,
  users: UserEntity[]
): InspectionTemplateModel {
  const {
    _id,
    checklist,
    createdAt,
    deleted,
    generalNorm,
    modifiedAt,
    process,
    status,
    summary,
    createdBy,
    modifiedBy,
  } = entity;
  return {
    _id,
    checklist,
    createdAt,
    deleted,
    generalNorm,
    modifiedAt,
    process,
    status,
    summary,
    createdBy: toLabelledUser(
      users.find((user) => user._id === createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === modifiedBy)
    ),
  };
}
