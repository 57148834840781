import React from 'react';
import { InfoBanner } from 'components/general/InfoBanner';
import PadlockIcon from 'assets/icons/padlock.svg';

const InsufficientPermissionsPage = (): React.ReactElement => {
  return (
    <InfoBanner
      dataQa='insufficient-permissions-page'
      messageId='errorPage_403_insufficientPermissions'
      icon={PadlockIcon}
    />
  );
};

export default React.memo(InsufficientPermissionsPage);
