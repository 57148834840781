import { forwardRef, PropsWithChildren, Ref } from 'react';
import classNames from 'classnames';
import {
  alpha,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  iconButton: {
    '&:hover': {
      backgroundColor: alpha('#000000', 0.05),
    },
    '&:active': {
      backgroundColor: alpha('#000000', 0.15),
    },
  },
});

const ButtonIcon = (
  {
    children,
    title,
    ...props
  }: PropsWithChildren<IconButtonProps & { title?: string }>,
  ref: Ref<any> | undefined
): JSX.Element => {
  const classes = useStyles();
  if (title && !props.disabled) {
    return (
      <Tooltip title={title}>
        <IconButton
          {...props}
          ref={ref}
          disableRipple={props?.disableRipple}
          className={classNames(classes.iconButton, props.className)}
          size='large'
        >
          {children}
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <IconButton
      {...props}
      ref={ref}
      disableRipple={props?.disableRipple}
      className={classNames(classes.iconButton, props.className)}
      size='large'
    >
      {children}
    </IconButton>
  );
};

export default forwardRef(ButtonIcon);
