import { LabelledEntity } from 'shared/types/commonView';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { StageInternalNames } from './stages';
import { DayMomentValidation } from '../../shared/types/time';
import { Process } from 'shared/types/process';
import { Color } from 'shared/utils/colors';

export interface StringFieldValidation {
  minLength?: number;
  maxLength?: number;
}

export interface NumberFieldValidation {
  decimalPlaces?: number;
  minValue?: number;
  maxValue?: number;
}

export interface MultiselectValidation {
  minAmount?: number;
  maxAmount?: number;
}

type Validation =
  | StringFieldValidation
  | NumberFieldValidation
  | MultiselectValidation;

export interface FieldSkeleton {
  name: IssueFieldNames;
  label: string;
  required: boolean;
  requiredFromStage?: StageInternalNames;
  inputType: string;
  expectedDataType: string;
  fieldType: FormFieldType;
  processIds?: string[];
  validation?: Validation;
  labelSuffix?: string;
  dependsOn?: DependsOn;
  dependingItems?: DependingItem[];
  items?: LabelledEntity[];
  dayMomentValidation?: DayMomentValidation;
  disabled?: { reason: string };
  currency?: number;
}

export enum FormFieldType {
  primary = 'primary',
  extended = 'extended',
}

export enum DependanceBehavior {
  hidden = 'hidden',
  disabled = 'disabled',
}

type DependsOn = {
  behavior: DependanceBehavior;
  type: FormFieldType;
  name: string;
};

export type DependingItem = {
  parentItemId: string;
  itemIds: string[];
};

export enum InputType {
  textField = 'textField',
  textArea = 'textArea',
  dropdown = 'dropdown',
  documents = 'documents',
  datePicker = 'datePicker',
  numberField = 'number',
  multiSelect = 'multiSelect',
  map = 'mapWithMarker',
  finalCost = 'finalCost',
  estimatedCost = 'estimatedCost',
  hashtag = 'hashtag',
}

export enum ExpectedDataType {
  date = 'date',
  string = 'string',
}

export type IssueForm = FieldSkeleton[];

export type ProcessType = {
  _id: Process;
  label: string;
  code: string;
  icon?: string;
  color: Color;
  userHasAccess: boolean;
  currentInitialFormVersion: number;
};
