import { useCallback, MouseEvent, useState } from 'react';

export function usePopover(): {
  anchorEl: HTMLElement | null;
  closePopover: () => void;
  attachPopover: (event: MouseEvent<HTMLElement>) => void;
  popoverOpen: boolean;
} {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const closePopover = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const attachPopover = useCallback(
    (event: MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },

    []
  );
  const popoverOpen = Boolean(anchorEl);

  return {
    anchorEl,
    closePopover,
    attachPopover,
    popoverOpen,
  };
}
