import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';

const NotFoundPage = (): React.ReactElement => {
  return (
    <Box
      margin='dense'
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        sx={{ py: 2 }}
        margin='dense'
        variant='h3'
        component='span'
      >
        <FormattedMessage id='errorPage_403_notFound' />
      </Typography>
      <Typography margin='dense' variant='h4' component='span'>
        <FormattedMessage id='errorPage_403_pageNotFoundExtended' />
      </Typography>
    </Box>
  );
};

export default React.memo(NotFoundPage);
