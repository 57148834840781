import { useEffect, useMemo, useState } from 'react';
import { Store } from 'hooks/createStore';
import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { isSupportedAsMap } from 'shared/domain/documentation/availableExtensionsAsMap';
import { ImageConvertStatus } from 'shared/domain/imageConvertStatus/imageConvertStatus';

export function useDirectoriesState(
  dirStore: Store<DirectoryModel[]>,
  isLoadingStore: Store<boolean>
): { directories: DirectoryModel[]; isLoadingDirectories: boolean } {
  const [directories, setDirectories] = useState<DirectoryModel[]>(
    dirStore.get()
  );
  const [loadingDirectories, setLoadingDirectories] = useState(
    isLoadingStore.get()
  );

  useEffect(() => {
    setDirectories(dirStore.get());
    return dirStore.subscribe(() => {
      setDirectories(dirStore.get());
    });
  }, [dirStore]);

  useEffect(() => {
    setLoadingDirectories(isLoadingStore.get());
    return isLoadingStore.subscribe(() => {
      setLoadingDirectories(isLoadingStore.get());
    });
  }, [isLoadingStore]);

  return useMemo(() => {
    return {
      directories,
      isLoadingDirectories: loadingDirectories,
    };
  }, [directories, loadingDirectories]);
}

export function useDocumentationsState(
  docStore: Store<DocumentationModel[]>,
  isLoadingStore: Store<boolean>
): {
  documentations: DocumentationModel[];
  isLoadingDocumentations: boolean;
} {
  const [documentations, setDocumentations] = useState<
    DocumentationModel[]
  >(docStore.get());
  const [loadingDocumentations, setLoadingDocumentations] = useState(
    isLoadingStore.get()
  );

  useEffect(() => {
    setDocumentations(docStore.get());
    return docStore.subscribe(() => {
      setDocumentations(docStore.get());
    });
  }, [docStore]);

  useEffect(() => {
    setLoadingDocumentations(isLoadingStore.get());
    return isLoadingStore.subscribe(() => {
      setLoadingDocumentations(isLoadingStore.get());
    });
  }, [isLoadingStore]);

  return useMemo(() => {
    return {
      documentations,
      isLoadingDocumentations: loadingDocumentations,
    };
  }, [documentations, loadingDocumentations]);
}

export function useSearchState(
  searchedDirStore: Store<DirectoryModel[]>,
  searchedDocStore: Store<DocumentationModel[]>,
  isLoadingStore: Store<boolean>
): {
  searchedDirectories: DirectoryModel[];
  searchedDocumentations: DocumentationModel[];
  isLoadingSearch: boolean;
} {
  const [searchedDirectories, setSearchedDirectories] = useState<
    DirectoryModel[]
  >(searchedDirStore.get());

  const [searchedDocumentations, setSearchedDocumentations] = useState<
    DocumentationModel[]
  >(searchedDocStore.get());

  const [isLoadingSearch, setIsLoadingSearch] = useState(
    isLoadingStore.get()
  );

  useEffect(() => {
    setSearchedDirectories(searchedDirStore.get());
    return searchedDirStore.subscribe(() => {
      setSearchedDirectories(searchedDirStore.get());
    });
  }, [searchedDirStore]);

  useEffect(() => {
    setSearchedDocumentations(searchedDocStore.get());
    return searchedDocStore.subscribe(() => {
      setSearchedDocumentations(searchedDocStore.get());
    });
  }, [searchedDocStore]);

  useEffect(() => {
    setIsLoadingSearch(isLoadingStore.get());
    return isLoadingStore.subscribe(() => {
      setIsLoadingSearch(isLoadingStore.get());
    });
  }, [isLoadingStore]);

  return useMemo(() => {
    return {
      searchedDirectories,
      searchedDocumentations,
      isLoadingSearch,
    };
  }, [isLoadingSearch, searchedDirectories, searchedDocumentations]);
}

export function getCanBeUsedAsMap(
  extension = '',
  imageConvertStatus: ImageConvertStatus | undefined
): boolean {
  return isSupportedAsMap(extension, imageConvertStatus);
}
