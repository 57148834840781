import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import {
  useCustomizedFields,
  withCustomizedFields,
} from 'components/dataProviders/withCustomizedFields';
import { LabelledCardList } from 'components/common/labelledCard/list';
import { LabelledCard } from 'components/common/labelledCard/single';

function _FieldsSettings(): ReactElement {
  const customizedFields = useCustomizedFields();

  const intl = useIntl();
  const cardItems = Object.values(customizedFields).map((field) => {
    return {
      key: field.fieldName,
      label: intl.formatMessage({
        id: field.fieldLabelId,
      }),
      redirectTo: `fieldsSettings/${field.fieldName}`,
    };
  });

  return (
    <LabelledCardList>
      {cardItems.map((cardItem) => (
        <LabelledCard
          // @ts-ignore TODO MUI5
          key={cardItem.key}
          // @ts-ignore TODO MUI5
          id={cardItem.key}
          label={cardItem.label}
          redirectTo={cardItem.redirectTo}
        />
      ))}
    </LabelledCardList>
  );
}

export const FieldsSettings = withCustomizedFields(_FieldsSettings);
