import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      width: '100%',
      '& .bright:not(.error)': {
        borderColor: '#C2C2C2',
      },
      '& .bright:hover:not(.error)': {
        borderColor: '#000000',
      },
    },
    width100: {
      width: '100%',
    },
    standardBorderRadius: {
      borderRadius: '4px',
      '& .MuiOutlinedInput-root': {
        borderRadius: '4px',
      },
    },
  })
);
