import { ReactElement } from 'react';
import { makeStyles } from '@mui/styles';
import { ReportWizard } from '.';
import {
  ReportFields,
  useCreateReport,
} from 'components/dataCreationForms/withCreateReport';
import { FormattedMessage } from 'react-intl';
import {
  SingleIssueReportValues,
  useCreateSingleIssueReport,
} from 'components/dataCreationForms/withCreateSingleIssueReport';
import {
  SingleInspectionReportFields,
  useCreateSingleInspectionReport,
} from 'components/dataCreationForms/withCreateSingleInspectionReport';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 16,
      width: '100%',
    },
  },
  dialogContent: {
    padding: '24px 16px',
  },
  dialogTitle: {
    padding: '16px',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '18px',
    },
  },
});

export function CreateReportDialog(): ReactElement {
  const { open, closeDialog } = useCreateReport();
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted={false}
      onClose={closeDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id='issue_list_send_report_action' />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <ReportWizard<ReportFields, Omit<ReportFields, 'issues'>>
          useReportContext={useCreateReport}
        />
      </DialogContent>
    </Dialog>
  );
}

export function CreateSingleIssueReportDialog(): ReactElement {
  const { open, closeDialog } = useCreateSingleIssueReport();
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted={false}
      onClose={closeDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id='issue_list_send_report_action' />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <ReportWizard<SingleIssueReportValues>
          useReportContext={useCreateSingleIssueReport}
        />
      </DialogContent>
    </Dialog>
  );
}

export function CreateSingleInspectionReportDialog(): ReactElement {
  const { open, closeDialog } = useCreateSingleInspectionReport();
  const classes = useStyles();

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted={false}
      onClose={closeDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id='issue_list_send_report_action' />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        <ReportWizard<SingleInspectionReportFields>
          useReportContext={useCreateSingleInspectionReport}
        />
      </DialogContent>
    </Dialog>
  );
}
