import { AdditionalAppLogInfo, LogLevel } from 'shared/types/logger';
import { sharedLog } from 'shared/logger/sharedLog';
import { appSumoLogicLogger } from './appSumoLogicLogger';
import { appSentryLog } from './initSentryInApp';

export const appLog = (
  msg: string,
  level: LogLevel,
  additionalInfo: AdditionalAppLogInfo,
  full_error_object?: any
): void => {
  sharedLog({
    user_id: localStorage.getItem('user_id') || '',
    user_email: localStorage.getItem('user_email') || '',
    msg,
    level,
    additionalInfo,
    full_error_object,
    loggers: [appSumoLogicLogger, { log: appSentryLog }],
  });
};
