import React, { useState, useRef } from 'react';
import { TextField } from '@mui/material';
import styles from './styles';
import useOnClickOutside from 'hooks/clickOutside';
import useKeyPress from 'hooks/keyPress';
import { PaginationContextType } from './types';

type Props = Pick<
  PaginationContextType,
  'setPage' | 'page' | 'items' | 'rowsPerPage'
> & { close: () => void };

const PickPage: React.FC<Props> = ({
  setPage,
  page,
  close,
  items,
  rowsPerPage,
}) => {
  const ref = useRef(null);
  const [pick, setPick] = useState('');
  const cs = styles();
  const maxPage = Math.floor(items / rowsPerPage);
  const onSet = (): void => {
    close();
    if (pick !== '') {
      const pickParsed = parseInt(pick) - 1;
      if (Number.isNaN(pickParsed)) {
        return;
      }

      const tooHigh = pickParsed * rowsPerPage > items;
      const tooLow = pickParsed < 0;
      if (!tooHigh && !tooLow) {
        setPage(pickParsed);
      }
    }
  };
  useOnClickOutside(ref, onSet);
  useKeyPress('Enter', onSet);

  const onChange: React.ChangeEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e): void => {
    const { value } = e.target;
    setPick(value);
  };

  return (
    <div className={cs.editPage} ref={ref}>
      <TextField
        variant='standard'
        data-qa='pagination-page-input'
        value={pick || ''}
        placeholder={(page + 1).toString()}
        onChange={onChange}
        onBlur={onSet}
      />
      / {maxPage}
    </div>
  );
};

export default PickPage;
