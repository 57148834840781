import { useState, ReactElement, useMemo, useCallback } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useDocumentationController } from 'components/dataProviders/withDocumentationController';
import { useIntl } from 'react-intl';
import { OpenEditDirectoryEvent } from 'presentation/directory/openDirectoryInput';

type ContextMenu = {
  mouseX: number;
  mouseY: number;
} | null;
export function useContextMenu(): {
  contextMenu: ContextMenu;
  handleClose: () => void;
  handleContextMenu: (event: MouseEvent) => void;
} {
  const [contextMenu, setContextMenu] = useState<ContextMenu>(null);

  const handleContextMenu = useCallback((event: MouseEvent) => {
    setContextMenu((prev) => {
      return prev === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
          // Other native context menus might behave different.
          // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
          null;
    });
  }, []);

  const handleClose = useCallback(() => {
    setContextMenu(null);
  }, []);

  return useMemo(
    () => ({
      contextMenu,
      handleContextMenu,
      handleClose,
    }),
    [contextMenu, handleClose, handleContextMenu]
  );
}

export function DocumentationContextMenu({
  contextMenu,
  handleClose,
  editFile,
  editDirectory,
}: {
  contextMenu: { mouseY: number; mouseX: number } | null;
  handleClose: () => void;
  editFile: () => void;
  editDirectory: (editDirectoryEvent: OpenEditDirectoryEvent) => void;
}): ReactElement {
  const intl = useIntl();
  const { archive, selectedFilesStore } = useDocumentationController();

  const handleEdit = useCallback(() => {
    handleClose();
    const selectedSet = selectedFilesStore.get();
    if (selectedSet.size !== 1) {
      return;
    }
    const selectedElement = Array.from(selectedSet)[0];

    if (selectedElement.type === 'DIRECTORY') {
      editDirectory(selectedElement);
      return;
    }
    editFile();
  }, [editFile, editDirectory, handleClose, selectedFilesStore]);

  const handleArchive = useCallback(() => {
    handleClose();
    archive();
  }, [archive, handleClose]);

  return (
    <Menu
      open={contextMenu !== null}
      onClose={handleClose}
      anchorReference='anchorPosition'
      anchorPosition={
        contextMenu !== null
          ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={handleArchive}>
        {intl.formatMessage({ id: 'general_archive' })}
      </MenuItem>
      <MenuItem
        onClick={handleEdit}
        disabled={selectedFilesStore.get().size !== 1}
      >
        {intl.formatMessage({ id: 'general_edit' })}
      </MenuItem>
    </Menu>
  );
}
