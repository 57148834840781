import React, {
  useImperativeHandle,
  useState,
  forwardRef,
  useContext,
  PropsWithChildren,
  Ref,
} from 'react';
import FeedbackMark, { MarkRef } from './FeedbackMark';
import FeedbackInput, { FInputRef } from './FeedbackInput';
import FeedbackSummary from './FeedbackSummary';
import { Typography } from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { leaveDialogDispatcher } from '../../../setup/navigation/NavigationPrompt';
import { ClientContext } from '../../../with-client';

const styles = {
  feedbackWrapper: {
    padding: '20px 24px 32px 24px',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column' as 'column',
    boxSizing: 'border-box' as 'border-box',
  },
  header: {
    whiteSpace: 'pre-line' as 'pre-line',
    color: '#37474F',
    lineHeight: '1.375rem',
  },
};

type FeedbackProps = { onClose: () => void };

type TabProps = PropsWithChildren<{ id: number }>;

const FeedbackC = (
  { onClose }: FeedbackProps,
  ref: Ref<unknown> | undefined
): React.ReactElement => {
  const [visibleTab, setVisibleTab] = useState(0);
  const feedbackMark = React.useRef<MarkRef>(null);
  const feedbackInput = React.useRef<FInputRef>(null);
  const client = useContext(ClientContext);

  useImperativeHandle(ref, () => ({
    handleClose,
  }));

  const Tab = ({ id, children }: TabProps): JSX.Element | null =>
    id === visibleTab ? (
      <div style={styles.feedbackWrapper}>{children}</div>
    ) : null;

  const onSwitchScreen = (): void => {
    setVisibleTab((current) => current + 1);
  };

  const handleFeedback = (feedback: string): void => {
    const rating = feedbackMark!.current!.getRating();

    client.createFeedback(feedback, rating);
    onSwitchScreen();
  };

  const handleClose = (): void => {
    const isMarkDirty = feedbackMark?.current?.isDirty;

    const isInputDirty = feedbackInput?.current?.isDirty;

    if (isMarkDirty || isInputDirty) {
      leaveDialogDispatcher.dispatch('show', {
        onConfirm: () => onClose(),
      });

      return;
    }

    onClose();
  };

  return (
    <>
      <Tab id={0}>
        <Typography variant='subtitle1' style={styles.header}>
          <FormattedMessage id='feedback_description_web' />
        </Typography>
        <FeedbackMark ref={feedbackMark} />
        <FeedbackInput
          ref={feedbackInput}
          onClose={handleClose}
          onFeedbackSubmit={handleFeedback}
        />
      </Tab>
      <Tab id={1}>
        <FeedbackSummary onClose={onClose} />
      </Tab>
    </>
  );
};

const Feedback = forwardRef(FeedbackC);

export default Feedback;
