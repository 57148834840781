import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { UserModel } from 'shared/domain/user/userModel';
import { UserInDto } from 'shared/dtos/in/user';
import { UserEntity } from '../../../shared/domain/user/types/entity';

export function userInDtoToEntity(user: UserInDto): UserEntity {
  return {
    _id: user._id,
    createdAt: user.createdAt,
    createdBy: user.createdBy,
    modifiedAt: user.modifiedAt,
    modifiedBy: user.modifiedBy,

    sites: user.sites,
    label: user.label,
    processes: user.processes,
    role: user.role,
    email: user.email,
  };
}

export function userEntityToModel(
  user: UserEntity,
  users: UserEntity[]
): UserModel {
  return {
    _id: user._id,
    createdAt: user.createdAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === user.createdBy)
    ),
    modifiedAt: user.modifiedAt,
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === user.modifiedBy)
    ),

    sites: user.sites,
    label: user.label,
    processes: user.processes,
    role: user.role,
    email: user.email,
  };
}
