import { IntlShape } from 'react-intl';
import { AnyAction } from 'redux';
import { debugLog } from 'shared/logger/debugLog';
import { HashMap } from 'shared/types/commonView';
import { ToasterMessageProp } from '../../setup/types/core';
import { TOASTER_TYPES } from 'shared/enums';

type ToasterPositionType = {
  vertical: 'top' | 'bottom';
  horizontal: 'left' | 'center' | 'right';
};

type ToasterType = {
  message: string | { id: string };
  type: TOASTER_TYPES.SUCCESS | TOASTER_TYPES.FAILURE;
  toasterPosition?: ToasterPositionType;
  hideDelay?: number;
};

export const showToaster = ({
  message,
  type,
  toasterPosition = {
    vertical: 'bottom',
    horizontal: 'center',
  },
  hideDelay = 3000,
}: ToasterType): AnyAction => {
  return {
    type: 'TOASTER_MESSAGE',
    meta: {
      type,
      message,
      toasterPosition,
      hideDelay,
    },
  };
};

export const genericErrorToaster = {
  type: 'TOASTER_MESSAGE',
  meta: {
    type: TOASTER_TYPES.FAILURE,
    message: {
      id: 'toaster_something_went_wrong',
    },
    toasterPosition: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    hideDelay: 3000,
  },
};

export function displayGenericErrorToaster(
  dispatch: any,
  logMessage?: string
): void {
  debugLog('displayGenericErrorToaster', logMessage);
  dispatch(genericErrorToaster);
}

export function customMessageErrorToaster(
  messageId: string,
  params?: HashMap<string | number | null | boolean>
): AnyAction {
  return {
    type: 'TOASTER_MESSAGE',
    meta: {
      type: TOASTER_TYPES.FAILURE,
      message: {
        id: messageId,
        messageParams: params,
      },
      toasterPosition: {
        vertical: 'bottom',
        horizontal: 'center',
      },
      hideDelay: 3000,
    },
  };
}

export const getErrorToasterAction = (intl: IntlShape): AnyAction => ({
  type: 'TOASTER_MESSAGE',
  meta: {
    type: TOASTER_TYPES.FAILURE,
    message: intl.formatMessage({
      id: 'toaster_something_went_wrong',
    }),
    toasterPosition: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    hideDelay: 3000,
  },
});

export const showDummyToaster = (
  message: ToasterMessageProp
): AnyAction => {
  return {
    type: 'DUMMY',
    meta: {
      message,
      type: TOASTER_TYPES.SUCCESS,
      toasterPosition: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    },
  };
};
