import { BroadcastChannel } from 'broadcast-channel';
import {
  Progress,
  UploadProgresFile,
  useUploadProgressStore,
} from 'components/common/withUploadProgressStore';
import { ChannelNames } from 'shared/domain/channelNames';
import { startUploadAbort } from 'shared/domain/httpQueue/startUploadAbort';
import {
  ApiChannelMessage,
  isCreatedMessage,
  isFailUploadedMessage,
  isUploadedMessage,
  Message,
  Services,
} from 'shared/domain/messages/message';
import { Store } from 'hooks/createStore';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDialog } from '../Dialog/common/DialogContext';
import { getFileTitle, isFileCreation, matchesLocalId } from './model';

type UploadProgressContextType = {
  filesStore: Store<UploadProgresFile[]>;
  clear: () => Promise<void>;
};

const UploadProgressContext = createContext<
  UploadProgressContextType | undefined
>(undefined);

export const UploadProgressController: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { filesStore } = useUploadProgressStore();
  const createDialog = useDialog();
  const abortUpload = useCallback(() => {
    filesStore.get().forEach((file) => {
      if (file.status === Progress.PENDING && file.localId) {
        startUploadAbort(file.localId, file.type);
      }
    });
  }, [filesStore]);

  const clear = useCallback(() => {
    if (
      filesStore.get().some((file) => file.status === Progress.PENDING)
    ) {
      return createDialog({
        title: <FormattedMessage id='dialog_title_abort_upload' />,
        description: (
          <span>
            <FormattedMessage id='dialog_description_abort_upload' />
          </span>
        ),
        customControllerLabels: ['general_cancel', 'confirm_abort'],
      }).then(() => {
        abortUpload();
        filesStore.set([]);
      });
    }
    filesStore.set([]);
    return Promise.resolve();
  }, [abortUpload, createDialog, filesStore]);

  const ctx: UploadProgressContextType = useMemo(() => {
    return {
      filesStore,
      clear,
    };
  }, [filesStore, clear]);

  useEffect(() => {
    const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);
    apiBroadcast.onmessage = (message: ApiChannelMessage) => {
      if (!isFileCreation(message)) {
        return;
      }

      const newFile = {
        uniqueFileId: message.data.uniqueId,
        status: Progress.PENDING,
        type: message.service,
        title: getFileTitle(message),
      };
      filesStore.set([...filesStore.get(), newFile]);
    };
    return () => {
      apiBroadcast.close();
    };
  }, [filesStore]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(
      ChannelNames.documentationChannel
    );

    broadcast.onmessage = (message: Message) => {
      if (isCreatedMessage(message)) {
        const foundFile = filesStore
          .get()
          .find((file) => file.uniqueFileId === message.uniqueId);

        if (!foundFile) {
          return;
        }
        foundFile.localId = message.data[0];
        foundFile.status = Progress.PENDING;
        filesStore.set(filesStore.get().slice());
        return;
      }

      if (isUploadedMessage(message)) {
        const foundFile = filesStore
          .get()
          .find(
            (file) =>
              file.type === Services.DOCUMENTATIONS &&
              matchesLocalId(file, message)
          );

        if (!foundFile) {
          return;
        }

        foundFile.status = Progress.UPLOADED;
        filesStore.set(filesStore.get().slice());
        return;
      }

      if (isFailUploadedMessage(message)) {
        const foundFile = filesStore
          .get()
          .find(
            (file) =>
              file.type === Services.DOCUMENTATIONS &&
              file.localId === message.data
          );

        if (!foundFile) {
          return;
        }

        foundFile.status = Progress.FAILED;
        filesStore.set(filesStore.get().slice());
        return;
      }
    };

    return () => {
      broadcast.close();
    };
  }, [filesStore]);

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.documentChannel);

    broadcast.onmessage = (message: Message) => {
      if (isCreatedMessage(message)) {
        const foundFile = filesStore
          .get()
          .find((file) => file.uniqueFileId === message.uniqueId);

        if (!foundFile) {
          return;
        }
        foundFile.localId = message.data[0];
        foundFile.status = Progress.PENDING;
        filesStore.set(filesStore.get().slice());
        return;
      }

      if (isUploadedMessage(message)) {
        const foundFile = filesStore
          .get()
          .find(
            (file) =>
              file.type === Services.DOCUMENTS &&
              file.localId === message.data.localId
          );

        if (!foundFile) {
          return;
        }

        foundFile.status = Progress.UPLOADED;
        filesStore.set(filesStore.get().slice());
        return;
      }

      if (isFailUploadedMessage(message)) {
        const foundFile = filesStore
          .get()
          .find(
            (file) =>
              file.type === Services.DOCUMENTS &&
              file.localId === message.data
          );

        if (!foundFile) {
          return;
        }

        foundFile.status = Progress.FAILED;
        filesStore.set(filesStore.get().slice());
        return;
      }
    };

    return () => {
      broadcast.close();
    };
  }, [filesStore]);

  useEffect(() => {
    function onBeforeUnload(e: any): true | undefined {
      const files = filesStore.get();
      const result = files.every(
        (file) => file.status === Progress.UPLOADED
      );
      if (result) {
        return void 0;
      }

      e.returnValue = true;
      return true;
    }
    window.addEventListener('beforeunload', onBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [filesStore]);

  return (
    <UploadProgressContext.Provider value={ctx}>
      {children}
    </UploadProgressContext.Provider>
  );
};

export function useUploadProgress(): UploadProgressContextType {
  const context = useContext(UploadProgressContext);
  if (context === undefined) {
    throw new Error(
      'useUploadProgress must be used within an UploadProgressContextProvider'
    );
  }
  return context;
}
