import React, { PropsWithChildren, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { SelectProject } from 'views/projects/selection';
import { WithProjectLoading } from '../withProjectLoader';

const WithSelectProject: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const projectId = useSelector(projectDataSelector)._id;

  return projectId ? (
    <WithProjectLoading>{children}</WithProjectLoading>
  ) : (
    <SelectProject />
  );
};

const withSelectProject =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithSelectProject>
      <Component {...props} />
    </WithSelectProject>
  );

export { WithSelectProject, withSelectProject };
