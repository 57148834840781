import React, { useEffect } from 'react';
import { Dialog } from 'components/issue';
import Dispatcher from '../../../helpers/dispatcher';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TABLE_SET_VISIBLE_COLUMNS } from '../../../redux/actionTypes';
import { LabelledEntity } from 'shared/types/commonView';

export const tableSettingsDialog = new Dispatcher();

type TableSettingsDialogProps = {
  availableHeaders: LabelledEntity[];
  currentHeaders: LabelledEntity[];
};

const TableSettingsDialog = ({
  availableHeaders,
  currentHeaders,
}: TableSettingsDialogProps): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDialogOpen = (): void => {
    setDialogOpen(true);
  };

  const handleDialogClose = (): void => {
    setDialogOpen(false);
    tableSettingsDialog.dispatch('closeDialog', {});
  };

  useEffect(() => {
    tableSettingsDialog.addListener('openDialog', handleDialogOpen);

    return (): void => {
      tableSettingsDialog.removeListener('openDialog', handleDialogOpen);
    };
    // eslint-disable-next-line
  }, []);

  const handleColumnSelection = (
    _selectedColumns: LabelledEntity[]
  ): void => {
    const columnsIds = _selectedColumns.map((col) => col._id);

    dispatch({
      type: TABLE_SET_VISIBLE_COLUMNS,
      payload: columnsIds,
    });

    handleDialogClose();
  };

  return (
    <Dialog
      list={availableHeaders}
      currentItems={currentHeaders}
      isOpen={dialogOpen}
      onClose={handleDialogClose}
      onItemsSelection={handleColumnSelection}
      objectIterationBy='label'
      label={intl.formatMessage({
        id: 'issue_tableView_settings_label',
      })}
    />
  );
};

export default TableSettingsDialog;
