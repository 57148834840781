import * as _config from 'serviceWorker/db/config';
import { HttpError } from 'serviceWorker/helpers/httpError';
import { apiUrl } from 'shared/apiUrl';
import { syncOnUprocessableEntity } from 'shared/domain/synchronization/syncOnUnprocessable';
import { getToken } from '../config';

const BASE_URL = `${apiUrl}/v2`;

const defaultConfig: RequestInit = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
};

async function generateHeaders(
  headers: HeadersInit = {}
): Promise<HeadersInit> {
  const config = await _config.get();
  const token = getToken();
  if (!config || !token) {
    throw new Error('Config or token missing.');
  }

  return {
    'Content-Type': 'application/json',
    'Accept-Language': config.locale,
    Authorization: `Bearer ${token}`,
    'hustro-client': 'web',
    'hustro-client-version': config.frontendVersion,
    ...headers,
  };
}
async function toJson(response: Response): Promise<any> {
  syncOnUprocessableEntity(response);
  if (response.ok) {
    return response.json();
  }

  const parsedResponse = await response.json();
  throw new HttpError(
    createMessage({ ...parsedResponse.error, status: response.status }),
    response.status
  );
}

function sendPostFile(
  abortController: AbortController,
  url: string,
  data: any
): Promise<any> {
  return fetch(url, {
    method: 'POST',
    headers: {
      'x-amz-acl': 'public-read',
    },
    body: data,
    signal: abortController.signal,
  });
}

async function sendPost(
  url: string,
  data: any,
  abortController?: AbortController
): Promise<any> {
  const headers = await generateHeaders();
  return fetch(BASE_URL + url, {
    ...defaultConfig,
    method: 'POST',
    headers,
    body: JSON.stringify(data),
    signal: abortController?.signal,
  }).then(toJson);
}

async function sendGet(url: string): Promise<any> {
  const headers = await generateHeaders();
  return fetch(BASE_URL + url, {
    ...defaultConfig,
    cache: 'default',
    method: 'GET',
    headers,
  }).then(toJson);
}

async function sendDelete(url: string): Promise<any> {
  const headers = await generateHeaders();
  return fetch(BASE_URL + url, {
    ...defaultConfig,
    method: 'DELETE',
    headers,
  }).then(toJson);
}

async function sendPut(url: string, data: any): Promise<any> {
  const headers = await generateHeaders();
  return fetch(BASE_URL + url, {
    ...defaultConfig,
    method: 'PUT',
    headers,
    body: JSON.stringify(data),
  }).then(toJson);
}

export const Fetcher = {
  POST_FILE: sendPostFile,
  POST: sendPost,
  GET: sendGet,
  DELETE: sendDelete,
  PUT: sendPut,
};

function createMessage(
  error:
    | {
        message?: string;
        details?: string;
        status?: number;
      }
    | undefined
): string {
  return `${error?.message} ${error?.details} ${error?.status}`;
}
