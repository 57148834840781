import { BroadcastChannel } from 'broadcast-channel';
import { Dispatch, SetStateAction } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';

export function listenToHasAll(
  setLoadingState: Dispatch<SetStateAction<boolean>>,
  channel: ChannelNames,
  allMessage: DomainMessagesTypes
): () => void {
  const mountedRef = { current: true };
  const broadcast = new BroadcastChannel(channel);

  broadcast.onmessage = (event: Message): void => {
    if (!mountedRef.current) {
      return;
    }

    if (event.data && event.type === allMessage) {
      if (event.data.hasAll) {
        setLoadingState(false);
      }
    } else if (event.type === DomainMessagesTypes.clearData) {
      setLoadingState(true);
    }
  };

  return (): void => {
    mountedRef.current = false;
    broadcast.close();
  };
}
