import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';
import { ProjectHttpDeleteRequestModel } from '../httpQueue/project/types';
import { ProjectDeleteUseCase } from 'shared/domain/project/types/useCase';

export class ProjectDeleter implements ProjectDeleteUseCase {
  constructor(
    private addRequest: (
      request: ProjectHttpDeleteRequestModel
    ) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>
  ) {}
  async execute(projectId: string, uniqueId: string): Promise<void> {
    debugLog('DeleteProjectUseCase', projectId);
    const url = `/project/${projectId}`;
    const request: ProjectHttpDeleteRequestModel = {
      createdAt: Date.now(),
      method: 'DELETE',
      data: {
        projectId: projectId,
        uniqueId: uniqueId,
      },
      url,
      entityType: HttpRequestModelType.project,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const projectDeleter = new ProjectDeleter(
  addRequest,
  getCurrentProject
);
