import { Store } from 'hooks/createStore';
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
} from 'react';

type SelectedStoreContextType<T = any> = {
  selectedStore: Store<T | undefined>;
  select: (item: T | undefined) => void;
};

const SelectedStoreContext = createContext<
  SelectedStoreContextType | undefined
>(undefined);

export function WithSelectedStore<T>({
  children,
  selectedStore,
}: PropsWithChildren<{
  selectedStore: Store<T | undefined>;
}>): ReactElement {
  const select = useCallback(
    (item: T | undefined) => {
      if (selectedStore.get() === item) {
        selectedStore.set(undefined);
      } else {
        selectedStore.set(item);
      }
    },
    [selectedStore]
  );

  const ctx: SelectedStoreContextType<T | undefined> = useMemo(() => {
    return { selectedStore, select };
  }, [selectedStore, select]);
  return (
    <SelectedStoreContext.Provider value={ctx}>
      {children}
    </SelectedStoreContext.Provider>
  );
}

export function useSelectedStoreContext<T>(): SelectedStoreContextType<
  T | undefined
> {
  const context = useContext(SelectedStoreContext);
  if (context === undefined) {
    throw new Error(
      'useSelectedStoreContext must be used within an SelectedStoreContextProvider'
    );
  }
  return context;
}
