import {
  ComponentType,
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
} from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { Subscribe, useChannelListener } from './channelListener';

type ChannelListenerInstance = {
  Component: ComponentType<PropsWithChildren<{}>>;
  useContextFunction: () => ChannelListenerContextType;
  composeComponent: (
    Component: ComponentType<any>
  ) => ({ ...props }: { [x: string]: any }) => ReactElement;
};

export type ChannelListenerContextType = {
  subscribe: Subscribe;
};

export function WithChannelListenerFactory(
  channel: ChannelNames
): ChannelListenerInstance {
  const ChannelListenerContext = createContext<
    ChannelListenerContextType | undefined
  >(undefined);

  function WithChannelListener({
    children,
  }: PropsWithChildren<{}>): ReactElement {
    const ctx = useChannelListener(channel);

    return (
      <ChannelListenerContext.Provider value={ctx}>
        {children}
      </ChannelListenerContext.Provider>
    );
  }

  const channelListener: ChannelListenerInstance = {
    Component: WithChannelListener,

    useContextFunction:
      function useChannelListenerContext(): ChannelListenerContextType {
        const context = useContext(ChannelListenerContext);
        if (context === undefined) {
          throw new Error(
            `useChannelListenerContext: ${channel} must be used within an ChannelListenerContextProvider on ${channel}`
          );
        }
        return context;
      },

    composeComponent: function withChannelListener(
      Component: ComponentType<any>
    ) {
      return ({ ...props }) => {
        return (
          <WithChannelListener>
            <Component {...props} />
          </WithChannelListener>
        );
      };
    },
  };

  return channelListener;
}
