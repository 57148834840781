import { CompanyModel } from 'shared/domain/company/types/model';
import { ColumnShapeSkeleton, InitialColumnWidth } from '../types';
import { StandardHeader } from 'components/table/ColumnHeader';
import { ContractModel } from 'shared/domain/contract/types/model';
import { UserModel } from 'shared/domain/user/userModel';
import { SORT_TYPE } from 'shared/filter/sort/types';

const base = {
  flexGrow: 0,
  flexShrink: 0,
  sortable: true,
  sortType: SORT_TYPE.STRING,
  style: {
    justifyContent: 'flex-start',
  },
};

export type TextColumnParams = {
  initialWidth: InitialColumnWidth;
  accessor?: (entity: any) => string;
  labelId: string;
  dataKey: string;
};

export function getTextColumnShape(
  params: TextColumnParams
): ColumnShapeSkeleton {
  return {
    ...base,
    ...params,
    headerRenderer: StandardHeader,
    style: {
      justifyContent: 'flex-start',
    },
  };
}

export function getCompanyShortLabelColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    initialWidth: 192,
    accessor: (entity: CompanyModel): string => entity.shortLabel,
    labelId: 'company_table_short_label',
    dataKey: 'shortLabel',
  };
}

export function getCompanyLongLabelColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    initialWidth: 680,
    accessor: (entity: CompanyModel): string => entity.longLabel,
    labelId: 'company_long_label_field_label',
    dataKey: 'longLabel',
  };
}

export function getContractLabelColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    initialWidth: 680,
    accessor: (entity: ContractModel): string => entity.label,
    labelId: 'primaryField_Contract_numbers',
    dataKey: 'label',
    headerRenderer: StandardHeader,
  };
}

export function getUserLabelColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    initialWidth: 340,
    headerRenderer: StandardHeader,
    labelId: 'user_view_name_input_label',
    dataKey: 'label',
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
export function getRoleColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    headerRenderer: StandardHeader,
    // cellRenderer: RoleCell,
    accessor: (user: UserModel) => user.role,
    initialWidth: 170,
    labelId: 'access_management_role',
    dataKey: 'role',
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
