import { AddFieldValuesUseCase } from 'shared/domain/fieldValue/addFieldValues';
import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import { CreateFieldValueModel } from 'shared/domain/fieldValue/fieldValueModel';
import {
  broadcastFieldValuesCreate,
  broadcastFieldValuesCreateError,
} from 'serviceWorker/broadcasts/fieldValues';
import {
  fieldValuesRequestRunner,
  IFieldValuesRequestRunner,
} from './fieldValuesRequestRunner';

class FieldValuesCreator implements AddFieldValuesUseCase {
  constructor(
    private requestRunner: IFieldValuesRequestRunner,
    private broadcastFieldValuesCreate: (
      fieldsResponse: unknown[],
      uniqueId: string
    ) => void,
    private broadcastFieldValuesCreateError: (
      error: unknown,
      uniqueId: string
    ) => void
  ) {}

  async execute(
    fieldName: EditableFieldValuesEntityName,
    fieldValues: CreateFieldValueModel[],
    uniqueId: string
  ): Promise<void> {
    try {
      const fieldValuesResponse: unknown[] =
        await this.requestRunner.execute({
          fieldName,
          fieldValues,
        });
      this.broadcastFieldValuesCreate(fieldValuesResponse, uniqueId);
    } catch (e) {
      this.broadcastFieldValuesCreateError(e, uniqueId);
    }
  }
}

export const fieldValuesCreator = new FieldValuesCreator(
  fieldValuesRequestRunner,
  broadcastFieldValuesCreate,
  broadcastFieldValuesCreateError
);
