import { BroadcastChannel } from 'broadcast-channel';
import { fromDate14DaysEarlier, toDateToday } from 'charts/shared';
import {
  clearFilterByFieldVisibility,
  createLabels,
  setMultiselectFilterValue,
} from 'charts/shared/filters';
import { useAssignees } from 'charts/shared/hooks';
import {
  makeSingleDependencySetter,
  sanitizeDateFilterValue,
} from 'charts/shared/model';
import { DefinedDependencies, Dependencies } from 'charts/shared/types';
import { useIssueForm } from 'components/common/withIssueForm';
import { useSites } from 'components/dataProviders/withSites';
import { useFieldVisibility } from 'components/dataProviders/withVisibleFields';
import { useWorktypes } from 'components/dataProviders/withWorktypes';
import { useChartFilters } from 'hooks/useChartFilters';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { impactSelector } from 'redux/selectors/helpers';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  StaticField,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { createAvailableFieldsSet } from 'shared/domain/visibleField/createAvailableFieldsSet';
import {
  Chart,
  FilterTypes,
  FilterValues,
  OriginatorFilters,
} from 'shared/types/analytics';
import { LabelledEntity } from 'shared/types/commonView';
import { Process } from 'shared/types/process';
import { OriginatorDependencies } from './types';

export function useOriginatorFilters(): OriginatorFilters | undefined {
  const assignees = useAssignees();
  const { items: workTypes } = useWorktypes();

  const {
    processes: allProcesses,
    _id: projectId,
    timezone,
  } = useSelector(projectDataSelector);
  const processes = useMemo(() => {
    return allProcesses.filter((process) => {
      return process._id !== Process.TSK;
    });
  }, [allProcesses]);

  const { issueForm } = useIssueForm();
  const impacts = useMemo(() => impactSelector(issueForm), [issueForm]);

  const {
    sites: { items: sites },
  } = useSites();

  const { fieldVisibilityStore, loading: loadingVisibleFields } =
    useFieldVisibility();

  const { getFilters, saveFilters } = useChartFilters(
    `${projectId}_${Chart.originator}`
  );

  const [filters, setFilters] = useState<OriginatorFilters | undefined>(
    undefined
  );
  const [dependencies, setDependencies] = useState<
    Dependencies<OriginatorDependencies, OriginatorFilters>
  >({});

  useEffect(() => {
    if (loadingVisibleFields || !processes.length) return;
    setFilters(
      (
        _: OriginatorFilters | undefined
      ): OriginatorFilters | undefined => {
        const {
          filters,
          assignees,
          impacts,
          processes,
          sites,
          projectId,
          workTypes,
          timezone,
        } = dependencies;
        if (!filters) return undefined;
        if (!assignees) return undefined;
        if (!impacts) return undefined;
        if (!processes) return undefined;
        if (!sites) return undefined;
        if (!projectId) return undefined;
        if (!workTypes) return undefined;
        if (!timezone) return undefined;

        return clearFilterByFieldVisibility(
          createAvailableFieldsSet(
            fieldVisibilityStore.get(),
            getAvailableFields().filter(
              (field) => !field.canBeDisabled
            ) as StaticField[],
            processes
          ),
          createFilters(
            dependencies as DefinedDependencies<
              OriginatorDependencies,
              OriginatorFilters
            >
          )
        );
      }
    );
  }, [
    dependencies,
    fieldVisibilityStore,
    loadingVisibleFields,
    processes.length,
  ]);

  useEffect(() => {
    setDependencies(makeSingleDependencySetter('assignees', assignees));
  }, [assignees]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('processes', processes));
  }, [processes]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('sites', sites));
  }, [sites]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('projectId', projectId));
  }, [projectId]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('workTypes', workTypes));
  }, [workTypes]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('timezone', timezone));
  }, [timezone]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('impacts', impacts));
  }, [impacts]);
  useEffect(() => {
    setDependencies(makeSingleDependencySetter('setFilters', setFilters));
  }, [setFilters]);

  useEffect(() => {
    const filtersBroadcast = new BroadcastChannel(
      ChannelNames.chartFiltersChannel
    );
    let mounted = true;
    getFilters(filtersBroadcast)
      .then((res) => {
        if (!mounted) return;
        setDependencies(
          makeSingleDependencySetter(
            'filters',
            res?.filters || defaultValues()
          )
        );
      })
      .catch(() => {
        if (!mounted) return;
        setDependencies(
          makeSingleDependencySetter('filters', defaultValues())
        );
      });

    return (): void => {
      filtersBroadcast.close();
      mounted = false;
    };
  }, [getFilters]);

  useEffect(() => {
    if (filters && projectId) {
      saveFilters(filters);
    }
  }, [filters, projectId, saveFilters]);

  return filters;
}

function defaultValues(): FilterValues {
  return {
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_date_range',
          value: {
            from: fromDate14DaysEarlier,
            to: toDateToday,
          },
        },
        {
          labelId: 'general_process',
          value: [],
        },
        {
          labelId: 'data_scope_sites',
          value: [],
        },
        {
          labelId: 'extendedField_Work_type',
          value: [],
        },
        {
          labelId: 'extendedField_Impact',
          value: [],
        },
        {
          labelId: 'filters_filter_type_assignee',
          value: [],
        },
      ],
    },
  };
}

function createFilters({
  filters,
  assignees,
  impacts,
  processes,
  sites,
  workTypes,
  timezone,
  setFilters,
}: DefinedDependencies<
  OriginatorDependencies,
  OriginatorFilters
>): OriginatorFilters {
  return {
    dataScope: {
      labelId: 'data_scope_tab',
      filters: [
        {
          labelId: 'data_scope_date_range',
          type: FilterTypes.daterange,
          value: {
            from: sanitizeDateFilterValue(
              filters.dataScope.filters[0].value.from,
              timezone
            ),
            to: sanitizeDateFilterValue(
              filters.dataScope.filters[0].value.to,
              timezone
            ),
          },
          setter: (value): void => {
            setFilters(
              (
                prev: OriginatorFilters | undefined
              ): OriginatorFilters | undefined => {
                if (!prev) return prev;
                const result = {
                  ...prev,
                  dataScope: {
                    ...prev.dataScope,
                    filters: [...prev.dataScope.filters],
                  },
                };
                result.dataScope.filters[0] = {
                  ...prev.dataScope.filters[0],
                  value: value,
                };
                return result;
              }
            );
          },
        },
        {
          labelId: 'general_process',
          type: FilterTypes.multiselect,
          available: processes.map((process: LabelledEntity) => {
            return { _id: process._id, label: process.label };
          }),
          labels: createLabels(processes),
          value: filters.dataScope.filters[1].value,
          setter: (value: string[]): void => {
            setFilters(
              (
                prev: OriginatorFilters | undefined
              ): OriginatorFilters | undefined => {
                if (!prev) return prev;
                return setMultiselectFilterValue(prev, 1, value);
              }
            );
          },
        },
        {
          labelId: 'data_scope_sites',
          type: FilterTypes.multiselect,
          available: sites,
          labels: createLabels(sites),
          value: filters.dataScope.filters[2].value,
          setter: (value: string[]): void => {
            setFilters(
              (
                prev: OriginatorFilters | undefined
              ): OriginatorFilters | undefined => {
                if (!prev) return prev;
                return setMultiselectFilterValue(prev, 2, value);
              }
            );
          },
          fieldRelation: IssueFieldNames.site,
        },
        {
          labelId: 'extendedField_Work_type',
          type: FilterTypes.multiselect,
          available: workTypes,
          labels: createLabels(workTypes),
          value: filters.dataScope.filters[3].value,
          setter: (value: string[]): void => {
            setFilters(
              (
                prev: OriginatorFilters | undefined
              ): OriginatorFilters | undefined => {
                if (!prev) return prev;
                return setMultiselectFilterValue(prev, 3, value);
              }
            );
          },
          fieldRelation: IssueFieldNames.workTypes,
        },
        {
          labelId: 'extendedField_Impact',
          type: FilterTypes.multiselect,
          available: impacts,
          labels: createLabels(impacts),
          value: filters.dataScope.filters[4].value,
          setter: (value: string[]): void => {
            setFilters(
              (
                prev: OriginatorFilters | undefined
              ): OriginatorFilters | undefined => {
                if (!prev) return prev;
                return setMultiselectFilterValue(prev, 4, value);
              }
            );
          },
          fieldRelation: IssueFieldNames.impact,
        },
        {
          labelId: 'filters_filter_type_assignee',
          type: FilterTypes.multiselect,
          available: assignees,
          labels: createLabels(assignees),
          value: filters.dataScope.filters[5].value,
          setter: (value: string[]): void => {
            setFilters(
              (
                prev: OriginatorFilters | undefined
              ): OriginatorFilters | undefined => {
                if (!prev) return prev;
                return setMultiselectFilterValue(prev, 5, value);
              }
            );
          },
          fieldRelation: IssueFieldNames.assignee,
        },
      ],
    },
  };
}
