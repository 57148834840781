import { DocumentCreateModel } from 'shared/domain/document/documentCreateModel';
import { DocumentationCreateModel } from 'shared/domain/documentation/documentationModel';
import { ApiChannelMessage } from 'shared/domain/messages/message';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  MouseEventHandler,
  TouchEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
type DocumentationFileCreationMessage = {
  service: Services.DOCUMENTATIONS;
  method: ServiceMethods;
  data: { documentation: DocumentationCreateModel } & { uniqueId: string };
};

type DocumentFileCreationMessage = {
  service: Services.DOCUMENTS;
  method: ServiceMethods;
  data: { document: DocumentCreateModel } & { uniqueId: string };
};

type FileCreationMessage =
  | DocumentationFileCreationMessage
  | DocumentFileCreationMessage;

export function isFileCreation(
  message: ApiChannelMessage
): message is FileCreationMessage {
  const fileServices = [Services.DOCUMENTATIONS, Services.DOCUMENTS];
  return (
    message.method === ServiceMethods.CREATE &&
    fileServices.includes(message.service)
  );
}

type Position =
  | {
      x: number;
      y: number;
    }
  | undefined;
type MoveHandlers = {
  position: Position;
  mouseMoveHandler: MouseEventHandler;
  touchMoveHandler: TouchEventHandler;
  touchStartHandler: TouchEventHandler;
  mouseDownHandler: MouseEventHandler;
  moveEnd: () => void;
  reset: () => void;
};
export function useMovableElement(): MoveHandlers {
  const [position, setPosition] = useState<Position>();
  const dragRef = useRef(false);
  const dragOffset = useRef<any | undefined>();
  const isMobile = useIsMobile();

  const setDragOn = useCallback((event: React.MouseEvent): void => {
    event.persist();
    dragRef.current = true;
    dragOffset.current = {
      x: event.nativeEvent.offsetX,
      y: event.nativeEvent.offsetY,
    };
  }, []);

  const setTouchDragOn = useCallback((event: React.TouchEvent): void => {
    event.persist();
    dragRef.current = true;
    const rect = event.currentTarget.getBoundingClientRect();
    dragOffset.current = {
      x: event.touches[0].clientX - rect.left,
      y: event.touches[0].clientY - rect.top,
    };
  }, []);

  const setDragOff = useCallback((): void => {
    dragRef.current = false;
    dragOffset.current = undefined;
  }, []);

  const move = useCallback((event: React.MouseEvent): void => {
    event.persist();
    if (dragRef.current && dragOffset.current) {
      setPosition({
        x: event.clientX - dragOffset.current.x,
        y: event.clientY - dragOffset.current.y,
      });
    }
  }, []);

  const touchmove = useCallback((event: React.TouchEvent): void => {
    event.persist();
    if (dragRef.current && dragOffset.current) {
      setPosition({
        x: event.touches[0].clientX - dragOffset.current.x,
        y: event.touches[0].clientY - dragOffset.current.y,
      });
    }
  }, []);

  const reset = useCallback(() => {
    dragRef.current = false;
    dragOffset.current = false;
    setPosition(undefined);
  }, []);

  useEffect(() => {
    reset();
  }, [isMobile, reset]);

  useEffect(() => {
    window.addEventListener('resize', reset);
    return () => window.removeEventListener('resize', reset);
  }, [reset]);

  return useMemo(() => {
    return {
      position,
      mouseMoveHandler: move,
      touchMoveHandler: touchmove,
      touchStartHandler: setTouchDragOn,
      mouseDownHandler: setDragOn,
      moveEnd: setDragOff,
      reset,
    };
  }, [
    move,
    position,
    setDragOff,
    setDragOn,
    setTouchDragOn,
    touchmove,
    reset,
  ]);
}

export function getFileTitle(message: FileCreationMessage): string {
  switch (message.service) {
    case Services.DOCUMENTS: {
      return message.data.document.title;
    }
    case Services.DOCUMENTATIONS: {
      return message.data.documentation.name;
    }
  }
}

export function matchesLocalId(
  file: { localId?: number },
  message: { data?: number | { localId: number } }
): boolean {
  if (typeof message.data === 'number') {
    return file.localId === message.data;
  }

  return file.localId === message.data?.localId;
}
