import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  CreateSiteCustomEventDetail,
  EditSiteCustomEventDetail,
  SiteDeleteCustomEventDetail,
  SiteRestoreCustomEventDetail,
} from 'shared/domain/messages/site/eventMessages';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';
import { GetSiteEventDetail } from './types';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncSites);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetSiteEventDetail>(RepositoryMessagesTypes.getSite, {
      detail: { id: e.id },
    })
  );
};

const create = (e: CreateSiteCustomEventDetail): void => {
  debugLog('CREATE SITE EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateSiteCustomEventDetail>(
      RepositoryMessagesTypes.addSite,
      {
        detail: {
          siteCreateModel: e.siteCreateModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const edit = (e: EditSiteCustomEventDetail): void => {
  debugLog('EDIT SITE EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditSiteCustomEventDetail>(
      RepositoryMessagesTypes.editSite,
      {
        detail: {
          siteEditModel: e.siteEditModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const remove = (e: SiteDeleteCustomEventDetail): void => {
  debugLog('REMOVE SITE EVENT', e);
  self.dispatchEvent(
    new CustomEvent<SiteDeleteCustomEventDetail>(
      RepositoryMessagesTypes.deleteSite,
      {
        detail: {
          siteId: e.siteId,
          projectId: e.projectId,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const restore = (e: SiteRestoreCustomEventDetail): void => {
  debugLog('RESTORE SITE EVENT', e);
  self.dispatchEvent(
    new CustomEvent<SiteRestoreCustomEventDetail>(
      RepositoryMessagesTypes.restoreSite,
      {
        detail: {
          siteId: e.siteId,
          projectId: e.projectId,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

export default { sync, getOne, create, edit, remove, restore };
