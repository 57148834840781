import {
  AutocompleteChangeDetails,
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  TextField,
} from '@mui/material';
import Chip from '@mui/material/Chip';
import {
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
} from '@mui/material/useAutocomplete';
import { ErrorPresentation, emailValidation } from 'helpers/validators';
import {
  ChangeEvent,
  ComponentType,
  Dispatch,
  FC,
  PropsWithChildren,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { currentUserSelector } from 'redux/selectors/users';
import { noop } from 'shared/utils/function';
import { useInputForm } from '../../dataCreationForms/withInputForm';
import { useUsers, withUsers } from '../../dataProviders/withUsers';
const ignoredChars = [' ', "'", ',', ';'];

export type EmailValue = {
  email: string;
  label?: string;
};

type EmailInput = {
  // onKeyCapture: KeyboardEventHandler<HTMLDivElement>;
  onInputChange: (
    event: ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void;
  onValueChange: (
    event: ChangeEvent<{}>,
    value: EmailValue[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void;
  renderTags: (
    value: EmailValue[],
    getTagProps: AutocompleteGetTagProps
  ) => ReactNode;
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  getOptionLabel: (option: EmailValue) => string;
  availableOptions: EmailValue[];
  value: EmailValue[];
  inputValue: string;
  error: ErrorPresentation;
  setFormKey: (value: string) => void;
};
const EmailInputContext = createContext<EmailInput | undefined>(undefined);

const additionalConfirmKeys = [' ', 'Tab', ',', ';'];

const WithEmailInput: FC<PropsWithChildren<{}>> = withUsers(
  ({ children }) => {
    const currentUser = useSelector(currentUserSelector);

    const {
      all: { items: users },
    } = useUsers();

    const [emails, setEmails] = useState<EmailValue[]>([
      {
        email: currentUser.data.email,
        label: currentUser.data.label,
      },
    ]);
    const [availableOptions, setAvailableOptions] = useState<EmailValue[]>(
      []
    );

    useEffect(() => {
      setAvailableOptions(
        users.map((user) => {
          return {
            label: user.label,
            email: user.email,
          };
        })
      );
    }, [users]);

    const [inputValue, setInputValue] = useState('');

    const dispatch = useDispatch();
    const [error, setError] = useState<ErrorPresentation>(undefined);

    const setFormKey = useStatefulInputForm(emails, inputValue);

    // const keyCapture: KeyboardEventHandler<HTMLDivElement> = useCallback(
    //   event => {
    //     if (additionalConfirmKeys.includes(event.key)) {
    //       event.key = 'Enter';
    //     }
    //   },
    //   []
    // );

    useEffect(() => {
      // react fires set callbacks in it's own order. We need to clear error when
      // new value is valid and inputValue is cleared by valueChange. This ensures that.
      if (!inputValue && emails.length > 0) {
        setError(undefined);
      }
    }, [inputValue, emails]);
    const inputChange = useCallback(
      (
        _: ChangeEvent<{}>,
        value: string,
        reason: AutocompleteInputChangeReason
      ) => {
        setInputValue((prev) => {
          const error = emailValidation(prev);
          if (reason === 'reset' && error) {
            setError(error);
            return prev;
          }
          if (
            reason === 'input' &&
            (value === '@' ||
              ignoredChars.some((ignoredChar) =>
                value.includes(ignoredChar)
              ))
          ) {
            return prev;
          }
          setError(undefined);
          return value;
        });
      },
      []
    );

    const valueChange = useCallback(
      (
        _: ChangeEvent<{}>,
        value: (EmailValue | string)[],
        reason: AutocompleteChangeReason
      ) => {
        if (!value.length) {
          return setEmails([]);
        }
        if (reason === 'removeOption') {
          setError(undefined);
          return setEmails(value as EmailValue[]);
        }

        const newValueElement: EmailValue =
          typeof value[value.length - 1] === 'string'
            ? ({
                email: value[value.length - 1],
                label: value[value.length - 1],
              } as EmailValue)
            : (value[value.length - 1] as EmailValue);

        const error = emailValidation(newValueElement.email);

        if (!error) {
          setInputValue('');
          setError(undefined);
          setEmails((prev) => {
            return [...prev, newValueElement];
          });
        } else {
          setInputValue((prev) => prev);
          setError(error);
          if (reason !== 'blur') {
            displayGenericErrorToaster(dispatch);
          }
        }
      },
      [dispatch, setInputValue]
    );

    const renderTags = useCallback(
      (value, getTagProps) =>
        value.map((option: EmailValue, index: number) => (
          <Chip
            size='small'
            label={option.label || option.email}
            {...getTagProps({ index })}
          />
        )),
      []
    );

    const renderInput = useCallback(
      (props) => (
        <EmailTextField
          {...props}
          error={Boolean(error)}
          helperText={error && error.helperText.id}
        />
      ),
      [error]
    );

    const getOptionLabel = useCallback(
      (option: EmailValue) => option.label || option.email,
      []
    );

    const filterOptions = useCallback(
      (
        options: EmailValue[],
        state: { inputValue: string }
      ): EmailValue[] => {
        const inputValue = state.inputValue.toLocaleLowerCase();
        const filteredOptions = options.filter(
          (option) =>
            option.email.toLocaleLowerCase().includes(inputValue) ||
            option.label?.toLocaleLowerCase().includes(inputValue)
        );

        return filteredOptions;
      },
      []
    );

    const ctx: EmailInput = useMemo(() => {
      return {
        // onKeyCapture: keyCapture,
        onInputChange: inputChange,
        onValueChange: valueChange,
        value: emails,
        filterOptions,
        setFormKey,
        renderTags,
        renderInput,
        getOptionLabel,
        availableOptions,
        inputValue,
        error,
      };
    }, [
      emails,
      inputValue,
      // keyCapture,
      inputChange,
      valueChange,
      renderTags,
      renderInput,
      getOptionLabel,
      availableOptions,
      error,
      filterOptions,
      setFormKey,
    ]);

    return (
      <EmailInputContext.Provider value={ctx}>
        {children}
      </EmailInputContext.Provider>
    );
  }
);

const withEmailInput =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEmailInput>
      <Component {...props} />
    </WithEmailInput>
  );

function useEmailInput(): EmailInput {
  const context = useContext(EmailInputContext);
  if (context === undefined) {
    throw new Error(
      'useEmailInput must be used within a EmailInputContextProvider'
    );
  }
  return context;
}

export { WithEmailInput, useEmailInput, withEmailInput };

function EmailTextField(
  params: AutocompleteRenderInputParams & {
    error: ErrorPresentation;
    helperText: string | undefined;
    required: boolean | undefined;
    formerror: ErrorPresentation;
  }
): ReactElement {
  const intl = useIntl();
  // defaultly onMouseDown opens dropdown. We dont want this feature
  params.inputProps.onMouseDown = noop;
  const error = Boolean(params.error) || Boolean(params.formerror);
  const formHelperText = error
    ? params.helperText || params.formerror?.helperText?.id
    : undefined;
  return (
    <TextField
      {...params}
      required={params.required}
      variant='outlined'
      label={intl.formatMessage({ id: 'send_report_label_to' })}
      inputProps={params.inputProps}
      error={error}
      helperText={error && intl.formatMessage({ id: formHelperText })}
    />
  );
}

function useStatefulInputForm(
  emails: EmailValue[],
  inputValue: string
): Dispatch<SetStateAction<string | undefined>> {
  const { setValues, setInputValues } = useInputForm();
  const [formKey, setFormKey] = useState<string>();
  const skipFormEdit = useRef(true);
  const prevInputValue = useRef(inputValue || '');

  useEffect(() => {
    if (formKey) {
      setValues(formKey, emails, { skipFormEdit: skipFormEdit.current });
      skipFormEdit.current = false;
    }
  }, [emails, setValues, formKey]);

  useEffect(() => {
    if (formKey && prevInputValue.current !== inputValue) {
      setInputValues(formKey, inputValue, { withFormEdit: true });
      prevInputValue.current = inputValue;
    }
  }, [setInputValues, inputValue, formKey]);

  return setFormKey;
}
