import { createStyles, StyleRules } from '@mui/styles';

const styles = (): StyleRules =>
  createStyles({
    issueTableWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    controllersWrapper: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 16px',
      boxSizing: 'border-box',
      height: '56px',
      // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
      zIndex: 100,
    },
    rightPanel: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
    },
  });

export default styles;
