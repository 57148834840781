import React from 'react';
import { Layout, Spinner, Toaster } from 'components/core';
import { ReportDownload } from 'components/report/download';
import { useAuth0 } from 'services/auth0/react-auth0.spa';
import { WithReportDownload } from 'components/common/withReportDownload';

function ReportDownloadView(): React.ReactElement {
  const { isAuthenticated, loading: auth0ClientLoading } = useAuth0();

  const component = auth0ClientLoading ? (
    <Spinner
      position='static'
      reason='ReportDownloadView auth0ClientLoading'
    />
  ) : (
    <ReportDownload />
  );

  if (isAuthenticated) {
    return (
      <WithReportDownload>
        <Layout>{component}</Layout>
      </WithReportDownload>
    );
  }

  return (
    <WithReportDownload>
      {component}
      <Toaster />
    </WithReportDownload>
  );
}

export default ReportDownloadView;
