import React, { ReactElement } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { useStyles } from './styles';
import cn from 'classnames';

type PlusIconProps = {
  disabled?: boolean;
};
function PlusIcon(props: PlusIconProps): ReactElement {
  const classes = useStyles();
  const { disabled } = props;
  return (
    <div className={cn(classes.plus, disabled && classes.disabled)}>
      <AddIcon />
    </div>
  );
}

export const MemoPlusIcon = React.memo(PlusIcon);
