import { DateTime } from 'luxon';
import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import { HashMap } from 'shared/types/commonView';
import { FieldSize } from 'shared/types/fieldSize';

export type ErrorPresentation =
  | {
      helperText: { id: string };
      values?: HashMap<any>;
    }
  | undefined;

const equal = (object1: unknown, object2: unknown): boolean => {
  return JSON.stringify(object1) === JSON.stringify(object2);
};

const useDeepCompareMemoize = <T>(value: T): T | undefined => {
  const ref = useRef<T>();

  if (!equal(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};
const useDeepCompareEffectNoCheck = (
  callback: EffectCallback,
  dependencies: DependencyList
): void => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, useDeepCompareMemoize(dependencies));
};

export const STANDARD_TITLE_MINIMUM = 3;
export const STANDARD_TITLE_MAXIMUM = 150;
export function standardTitleValidation(input: string): boolean {
  return (
    input.length < STANDARD_TITLE_MINIMUM ||
    input.length > STANDARD_TITLE_MAXIMUM
  );
}

export const STANDARD_DESCRIPTION_MAXIMUM = 3000;
export function standardDescriptionValidation(input: string): boolean {
  return input.length > STANDARD_DESCRIPTION_MAXIMUM;
}

export function requiredValidation(value: unknown): ErrorPresentation {
  return value === undefined || value === null || value === ''
    ? { helperText: { id: 'validation_is_required' } }
    : undefined;
}

export function emailValidation(value: unknown): ErrorPresentation {
  return !value || typeof value !== 'string' || !isStringValidEmail(value)
    ? { helperText: { id: 'validation_value_must_be_email' } }
    : undefined;
}

function isStringValidEmail(value: string): boolean {
  return (
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
      ) !== null
  );
}

export function customStringMinMaxValidation(
  min: number,
  fieldSize: FieldSize,
  value: unknown
): ErrorPresentation {
  return typeof value !== 'string' ||
    value.length < min ||
    value.length > fieldSize
    ? {
        helperText: { id: 'validation_string_length_between' },
        values: {
          minLength: min,
          maxLength: fieldSize,
        },
      }
    : undefined;
}

export const stringMinMaxValidation = customStringMinMaxValidation.bind(
  null,
  STANDARD_TITLE_MINIMUM
);

export function stringMaxValidation(
  fieldSize: FieldSize,
  value?: unknown
): ErrorPresentation {
  if (value === '' || value === undefined || value === null) {
    return;
  }
  if (typeof value !== 'string') {
    return {
      helperText: { id: 'error - wrong value type' },
    };
  }
  return value.length > fieldSize
    ? {
        helperText: { id: 'validation_string_length_maximum_web' },
        values: {
          maxLength: fieldSize,
        },
      }
    : undefined;
}

export function notEmptyArrayValidation(
  value: unknown
): ErrorPresentation {
  return Array.isArray(value) && value.length > 0
    ? undefined
    : { helperText: { id: 'validation_is_required' } };
}

export function maxArraySizeValidation(
  size: number,
  value: unknown
): ErrorPresentation {
  return Array.isArray(value) && value.length <= size
    ? undefined
    : {
        helperText: { id: 'validation_multiselect_amount_maximum_web' },
        values: { maxAmount: size },
      };
}

export function oneOfValidation(
  value: unknown,
  ofValues: any[]
): ErrorPresentation {
  return ofValues.includes(value)
    ? undefined
    : {
        helperText: { id: 'validation_one_of' },
        values: { of: ofValues },
      };
}

export function manyOfValidation(
  ofValues: any[],
  value: unknown[]
): ErrorPresentation {
  return value.every((val) => ofValues.includes(val))
    ? undefined
    : {
        helperText: { id: 'validation_one_of' },
        values: { of: ofValues },
      };
}

export function isISOStringValidation(value: any): ErrorPresentation {
  const d = new Date(value);
  const result = !Number.isNaN(d.valueOf());
  return result
    ? undefined
    : { helperText: { id: 'validation_date_must_be_iso_format' } };
}
export function isDateTimeValidation(value: any): ErrorPresentation {
  return DateTime.isDateTime(value)
    ? undefined
    : { helperText: { id: 'validation_date_must_be_luxon_date_format' } };
}

export type Validation<T> = {
  [key in keyof Required<T>]: (
    value: any,
    ...args: any
  ) => ErrorPresentation;
};

export { useDeepCompareEffectNoCheck };
