import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
} from 'react';

import { MemoSingleInspectionPresentational } from './presentational';
import {
  useRedirectContext,
  withRedirect,
} from 'components/common/withRedirect';
import { editPermission } from './helpers';
import { useIsOffline } from '../../common/withIsOffline';
import { UserInDto } from 'shared/dtos/in/user';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from '../../../redux/selectors/users';
import { useInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import { InspectionOnView } from 'presentation/inspection/inspectionOnView';
import { useInspection } from 'components/dataProviders/withInspection';
import {
  useIssuesOnInspection,
  withIssuesOnInspection,
} from 'components/dataProviders/withIssuesOnInspection';
import { projectIdSelector } from 'helpers/misc';

type SingleInspectionProps = {
  inspection: InspectionOnView;
};
function SingleInspection(props: SingleInspectionProps): ReactElement {
  const { inspection } = props;
  const { inspectionTemplateDocuments } = useInspectionTemplate();
  const { inspectionDocuments } = useInspection();
  const isOffline = useIsOffline();
  const currentUser: UserInDto = useSelector(currentProjectUserSelector);
  const projectId = useSelector(projectIdSelector);
  const isInspectionCompleted = inspection.isCompleted;
  const inspectionId = inspection._id;
  const templateId = inspection.template._id;
  const { redirect, setRedirection, setCanRedirect } =
    useRedirectContext();
  const { setInspection } = useIssuesOnInspection();

  useEffect(() => {
    if (inspectionId) {
      setInspection(inspectionId);
    }
  }, [inspectionId, setInspection]);

  const edit = useCallback(() => {
    setRedirection({
      path: `/project/${projectId}/inspection/${inspectionId}/edit`,
      state: {
        templateId,
      },
    });
    setCanRedirect(!isInspectionCompleted);
    redirect();
  }, [
    redirect,
    setRedirection,
    setCanRedirect,
    inspectionId,
    templateId,
    isInspectionCompleted,
    projectId,
  ]);

  const memoedPermission = useMemo(() => {
    return editPermission(isInspectionCompleted, isOffline, currentUser);
  }, [isInspectionCompleted, isOffline, currentUser]);

  return (
    <MemoSingleInspectionPresentational
      inspection={inspection}
      generalNormDocuments={inspectionTemplateDocuments.templateDocuments}
      inspectionDocuments={inspectionDocuments}
      edit={edit}
      editPermission={memoedPermission}
    />
  );
}

export const MemoSingleInspection = React.memo(
  withRedirect(withIssuesOnInspection(SingleInspection))
);
