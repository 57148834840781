import { CellRenderer } from 'components/table/types';
import {
  CompanyModel,
  projectInvolvementTypeLabelIds,
} from 'shared/domain/company/types/model';
import { FormattedMessage } from 'react-intl';

export const ProjectInvolvementTypeCell: CellRenderer<CompanyModel> = ({
  rowData,
}) => (
  <span>
    <FormattedMessage
      id={projectInvolvementTypeLabelIds[rowData.projectInvolvementType]}
    />
  </span>
);
