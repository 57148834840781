import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import { Issue } from '../../../setup/types/core';
import { AfterDoneResult } from '../../issue/wizard/afterDoneResult';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { CreateIssueDialog } from '../../issue/wizard/createIssueDialog';
import { singleIssueUrl } from 'helpers/generators';

const CreateIssueServiceContext = createContext<() => void>(() => {});

export const useCreateIssue = (): (() => void) =>
  useContext(CreateIssueServiceContext);

export function useRedirectToIssue(): (
  issueId: string,
  // ready to take issue from state (local changes) instead of url
  issue?: any
) => void {
  const history = useHistory();
  const { _id: projectId } = useSelector(projectDataSelector);

  return useCallback(
    (issueId, issue) => {
      history.push(singleIssueUrl(projectId, issueId), {
        issue,
      });
    },
    [history, projectId]
  );
}

export const CreateIssueContext = ({
  children,
}: PropsWithChildren<{}>): React.ReactElement => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const redirectToIssue = useRedirectToIssue();

  const openDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);
  const closeDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const handleAfterDone = useCallback(
    (issue: Issue | undefined, result: AfterDoneResult) => {
      if (result === AfterDoneResult.saved && issue) {
        redirectToIssue(issue._id, issue);
      } else {
        closeDialog();
      }
    },
    [redirectToIssue, closeDialog]
  );

  return (
    <CreateIssueServiceContext.Provider value={openDialog}>
      {children}
      <CreateIssueDialog afterDone={handleAfterDone} open={dialogOpen} />
    </CreateIssueServiceContext.Provider>
  );
};
