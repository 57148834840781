import { InputAdornment, TextFieldProps } from '@mui/material';
import { WithStyles } from '@mui/styles';
import { ThousandSeparatorFormat } from 'components/general/ThousandSeparatorFormat/ThousandSeparatorFormat';
import { PropsWithChildren, forwardRef } from 'react';
import { NumberFormatValues } from 'react-number-format';
import { FieldSkeleton, NumberFieldValidation } from 'shared/types/form';

type NumberFormatCustomProps = PropsWithChildren<any>;

export const NumberFormatCustom = forwardRef(
  (props: NumberFormatCustomProps, ref: any): JSX.Element => {
    const { onChange, name, ...other } = props;

    return (
      <ThousandSeparatorFormat
        {...other}
        isNumericString
        getInputRef={ref}
        onValueChange={(values: NumberFormatValues): void => {
          onChange({
            target: {
              name,
              value: values.floatValue,
            },
          });
        }}
      />
    );
  }
);

export const getNumberFieldProps = (
  classes: WithStyles<any>['classes'],
  field: FieldSkeleton & { validation?: NumberFieldValidation }
): TextFieldProps => {
  return {
    InputLabelProps: {
      classes: {
        root: classes.cssLabel,
        focused: classes.cssFocused,
      },
    },
    InputProps: {
      classes: {
        root: classes.root,
        focused: classes.cssFocused,
        notchedOutline: classes.fieldCommon,
      },
      endAdornment: (
        <InputAdornment position='end' disablePointerEvents={true}>
          {field.labelSuffix}
        </InputAdornment>
      ),
      inputComponent: NumberFormatCustom,
      inputProps: {
        decimalScale: field.validation?.decimalPlaces,
        thousandSeparator: '',
      },
    },
  };
};
