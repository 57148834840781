import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
export const useFieldSettingsStyles = makeStyles<DefaultTheme>(() =>
  createStyles({
    root: {
      padding: '0 1rem',
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px auto',
      marginTop: '38px',
      alignItems: 'center',
      maxWidth: '758px',
    },
    titleTextContainer: {
      flexGrow: 1,
      maxWidth: '758px',
      alignSelf: 'self-start',
    },
    title: {
      margin: 0,
    },
    dividerContainer: {
      flexGrow: 1,
      maxWidth: '758px',
      margin: '0 auto',
    },
    addButtonContainer: {
      flexGrow: 1,
      maxWidth: '758px',
      margin: '0 auto',
      marginTop: '24px',
    },
    fieldBoxes: {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px',
      alignItems: 'center',
      gap: '8px',
    },
    fieldLabel: {
      cursor: 'pointer',
      flexGrow: 1,
    },
    bottomSpace: {
      width: '100%',
      height: '100px',
    },
    flexRowCenter: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
      cursor: 'pointer',
      flexGrow: 1,
    },
  })
);
