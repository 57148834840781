import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { ContractsContextType, ContractsResponse } from './types';
import { filterBySearchPhrase } from '../../common/SearchInput/helpers';
import { useEmptyListReason } from '../../../hooks/table/useEmptyListReason';
import { ContractModel } from 'shared/domain/contract/types/model';
import { useEntityDataSubscription } from '../../common/useEntityDataSubscription';
import { useContractChannelListener } from '../../broadcastChannelListeners/withContractChannelListener';
import { useGetAllContracts } from 'hooks/useGetAllContracts';
import { useCompanyChannelListener } from '../../broadcastChannelListeners/withCompanyChannelListener';
import {
  Message,
  isDataChanged,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';

const initialContracts = { items: [], total: 0 };
const contractSearchableFields = [
  'label',
  'createdBy.label',
  'parties.companyShortLabel',
  'parties.companyLongLabel',
];
const ContractsContext = React.createContext<
  ContractsContextType | undefined
>(undefined);

// react 18 types
// @ts-ignore
const WithContracts: React.FC = ({ children }) => {
  const [contracts, setContracts] =
    useState<ContractsResponse>(initialContracts);
  const [searchPhrase, setSearchPhrase] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const filtered: ContractModel[] = useMemo(() => {
    return filterBySearchPhrase(
      contracts.items,
      searchPhrase,
      contractSearchableFields
    );
  }, [searchPhrase, contracts]);
  const emptyListReason = useEmptyListReason(
    searchPhrase,
    filtered.length,
    0
  );

  const { subscribe: subscribeContracts } = useContractChannelListener();
  const { subscribe: subscribeCompanies } = useCompanyChannelListener();
  const { getAll: getAllContracts } = useGetAllContracts();
  const { resync, mountedRef } = useEntityDataSubscription<ContractModel>({
    subscribe: subscribeContracts,
    getAll: getAllContracts,
    setEntity: setContracts,
    setLoading,
    entityName: 'contracts',
  });

  useEffect(
    function dataListener() {
      const unsubscribe = subscribeCompanies((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (
          (event.data && isDataChanged(event)) ||
          event.type === DomainMessagesTypes.tableChanged
        ) {
          resync();
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [resync, subscribeCompanies, mountedRef]
  );

  const ctx = useMemo(() => {
    return {
      contracts: { items: filtered, total: filtered.length },
      loading: loading,
      emptyListReason,
      setSearchPhrase,
    };
  }, [emptyListReason, filtered, loading]);

  return (
    <ContractsContext.Provider value={ctx}>
      {children}
    </ContractsContext.Provider>
  );
};

const withContracts =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithContracts>
      <Component {...props} />
    </WithContracts>
  );

function useContracts(): ContractsContextType {
  {
    const context = React.useContext(ContractsContext);
    if (context === undefined) {
      throw new Error(
        'useContracts must be used within a ContractsContextProvider'
      );
    }
    return context;
  }
}

export { WithContracts, withContracts, useContracts };
