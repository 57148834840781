import {
  CreateFieldValueModel,
  EditFieldValueModel,
} from 'shared/domain/fieldValue/fieldValueModel';
import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import { FieldValueTranslationModel } from 'shared/domain/fieldValueTranslation/fieldValueTranslationModel';
import { ProjectEntity } from 'shared/domain/project/types/entity';
import { FieldValueInDto } from 'shared/dtos/in/fieldValueInDto';
import * as config from 'serviceWorker/db/config';
import { getOne as projectGetOne } from 'serviceWorker/db/projects';
import { defaultConfig } from 'serviceWorker/helpers/httpRequests';
import { HeadersData } from '../factories/types';
import { fetch } from '../httpQueue/fetch';
import { FetchRequest } from '../httpQueue/fetchRequest';

function fieldNameToFieldUrl(
  fieldName: EditableFieldValuesEntityName
): string {
  const fields = {
    workTypes: 'worktype',
    hazardCategory: 'hazardCategory',
    environmentalAspect: 'environmentalAspect',
    proposedCorrectiveAction: 'correctiveActionType',
  };
  return fields[fieldName];
}

type FieldData = {
  fieldName: EditableFieldValuesEntityName;
  fieldValues: (CreateFieldValueModel | EditFieldValueModel)[];
};
type EditRequestProps = {
  setup: config.ConfigData;
  abortController: AbortController;
  clientId: string;
  urlFieldName: string;
  fieldValueId: string;
  fieldTranslations: FieldValueTranslationModel[] | undefined;
  processes: string[];
};
type CreateRequestProps = {
  setup: config.ConfigData;
  abortController: AbortController;
  clientId: string;
  urlFieldName: string;
  fieldTranslations: FieldValueTranslationModel[] | undefined;
  processes: string[];
};
export interface IFieldValuesRequestRunner {
  execute(fieldData: FieldData): Promise<unknown[]>;
}

class FieldValuesRequestRunner implements IFieldValuesRequestRunner {
  constructor(
    private getConfig: () => Promise<void | config.ConfigData>,
    private getProject: (id: string) => Promise<ProjectEntity | undefined>,
    private fetch: (
      headersData: HeadersData,
      httpRequest: FetchRequest,
      controller: AbortController
    ) =>
      | Promise<{
          status: number;
        }>
      | undefined
  ) {}

  async execute(fieldData: FieldData): Promise<unknown[]> {
    const abortController = new AbortController();
    const setup = await this.getConfig();
    if (!setup) {
      throw new Error('Cannot get config');
    }
    if (!setup.projectId) {
      throw new Error('Cannot get current setup project');
    }

    const project = await this.getProject(setup.projectId);
    if (!project) {
      throw new Error(`Cannot find project: ${setup.projectId}`);
    }
    const clientId = project.clientId;
    const urlFieldName = fieldNameToFieldUrl(fieldData.fieldName);

    return Promise.all(
      fieldData.fieldValues.flatMap((fieldValue) => {
        if (fieldValue._id) {
          return this.runEditRequest({
            setup,
            abortController,
            clientId,
            urlFieldName,
            fieldValueId: fieldValue._id,
            fieldTranslations: fieldValue.valueTranslations || [],
            processes: fieldValue.processes,
          });
        } else {
          return this.runCreateRequest({
            setup,
            abortController,
            clientId,
            urlFieldName,
            fieldTranslations: fieldValue.valueTranslations,
            processes: fieldValue.processes,
          });
        }
      })
    );
  }

  private runEditRequest({
    setup,
    abortController,
    clientId,
    urlFieldName,
    fieldValueId,
    fieldTranslations,
    processes,
  }: EditRequestProps): Promise<unknown>[] {
    const visibilityUrl = `client/${clientId}/${urlFieldName}/${fieldValueId}/visibleOnProcess`;
    const translationsUrl = `client/${clientId}/${urlFieldName}/${fieldValueId}/translation`;
    const visibilityResponse =
      this.fetch(
        setup,
        {
          ...defaultConfig,
          method: 'POST',
          url: visibilityUrl,
          data: {
            processes: processes,
            projectId: `${setup.projectId}`,
          },
        } as FetchRequest,
        abortController
      ) || Promise.reject(new Error('Cannot fetch.'));
    const translationsResponses =
      fieldTranslations?.map((translation: FieldValueTranslationModel) => {
        return (
          this.fetch(
            setup,
            {
              method: 'POST',
              url: translationsUrl,
              data: {
                localeCode: translation.localeCode,
                value: translation.value,
              },
            } as FetchRequest,
            abortController
          ) || Promise.reject(new Error('Cannot fetch.'))
        );
      }) || [];

    return [visibilityResponse, ...translationsResponses];
  }

  private runCreateRequest({
    setup,
    abortController,
    clientId,
    urlFieldName,
    fieldTranslations,
    processes,
  }: CreateRequestProps): Promise<unknown> {
    const url = `client/${clientId}/${urlFieldName}`;
    return (
      this.fetch(
        setup,
        {
          method: 'POST',
          url,
          data: {
            valueTranslations: fieldTranslations,
          },
        } as FetchRequest,
        abortController
      ) || Promise.reject(new Error('Cannot fetch.'))
    ).then((response) => {
      if (response.status !== 201) {
        return Promise.reject(
          new Error('Create field value response does not have _id.')
        );
      }
      const fieldId = (
        response as FieldValueInDto & {
          status: number;
        }
      )._id;
      const visibilityUrl = `client/${clientId}/${urlFieldName}/${fieldId}/visibleOnProcess`;
      return (
        this.fetch(
          setup,
          {
            method: 'POST',
            url: visibilityUrl,
            data: {
              processes: processes,
              projectId: `${setup.projectId}`,
            },
          } as FetchRequest,
          abortController
        ) || Promise.reject(new Error('Cannot fetch.'))
      );
    });
  }
}

export const fieldValuesRequestRunner = new FieldValuesRequestRunner(
  config.get,
  projectGetOne,
  fetch
);
