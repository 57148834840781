import React from 'react';
import { useNavigationTarget } from '../../../hooks/useNavigationTarget';
import { MemoProjectGeneralData } from '../generalData';
import { NavigationDestination } from '../../../helpers/navigation';
import { FieldsSettings } from '../../fieldsSettings/list';
import { NavigationHeader } from 'components/core/TabNavigation/NavigationHeader/NavigationHeader';
import { ProjectStructureView } from '../structure';
import { FieldVisibility } from 'views/fieldsVisibility';
import { MemoEditOrganizationWizardContainer } from 'views/organization/wizard/edit/container';
import { MemoOrganizationGeneralData } from 'views/organization/generalData';

export enum ProjectNavigationTarget {
  generalProjectData = 'generalProjectData',
  fieldValues = 'fieldValues',
  projectStructure = 'projectStructure',
  fieldVisibility = 'fieldVisibility',
  organizationData = 'organizationData',
}

function Content({
  target,
}: {
  target: ProjectNavigationTarget;
}): React.ReactElement {
  switch (target) {
    case ProjectNavigationTarget.generalProjectData:
      return <MemoProjectGeneralData />;
    case ProjectNavigationTarget.projectStructure:
      return <ProjectStructureView />;
    case ProjectNavigationTarget.fieldValues:
      return <FieldsSettings />;
    case ProjectNavigationTarget.fieldVisibility:
      return <FieldVisibility />;
    case ProjectNavigationTarget.organizationData:
      return <MemoOrganizationGeneralData />;
  }
}

const availableTargets: ProjectNavigationTarget[] = [
  ProjectNavigationTarget.generalProjectData,
  ProjectNavigationTarget.projectStructure,
  ProjectNavigationTarget.fieldValues,
  ProjectNavigationTarget.fieldVisibility,
  ProjectNavigationTarget.organizationData,
];

export const ProjectSettingsView = (): React.ReactElement => {
  const { target, setTarget } = useNavigationTarget();

  return (
    <div>
      <NavigationHeader
        availableTargets={availableTargets}
        baseLocation={`/${NavigationDestination.projectSettings}`}
        setTarget={setTarget}
        currentTarget={target}
      />
      <Content target={target} />
    </div>
  );
};
