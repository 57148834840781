import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { broadcastDocumentationMovedError } from 'serviceWorker/broadcasts/documentations';
import { broadcastDocumentationError } from '../errorHandlers/documentation';

export function broadcastDocumentationResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastDocumentationError(request, response.error);
  }
  return;
}

export function broadcastDocumentationMoveResponse(
  request: HttpRequestEntity,
  response: any
): void {
  if (response.error) {
    return broadcastDocumentationMovedError(response.error);
  }
  return;
}
