import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  ApiChannelMessage,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { VisibleFieldsEditModel } from './visibleFieldModel';

export function startVisibleFieldsEdit(
  visibleFieldsEdit: VisibleFieldsEditModel,
  uniqueId: string
): void {
  debugLog('startVisibleFieldsEdit', visibleFieldsEdit, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      visibleFieldsEdit: VisibleFieldsEditModel;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.VISIBLE_FIELDS,
    method: ServiceMethods.EDIT,
    data: {
      visibleFieldsEdit,
      uniqueId,
    },
  });
  broadcast.close();
}
