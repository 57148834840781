import { IssueModel } from 'shared/domain/issue/issueModel';
import { useCreateStore, Store } from 'hooks/createStore';
import { useGetIssuesFiltered } from 'hooks/useGetIssuesFiltered';
import {
  FC,
  PropsWithChildren,
  createContext,
  useMemo,
  ComponentType,
  ReactElement,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  displayGenericErrorToaster,
  genericErrorToaster,
} from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { useIssueChannelListener } from '../../broadcastChannelListeners/withIssueChannelListener';
import {
  Message,
  isDataChanged,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useMountedRef } from 'hooks/useMountRef';
type IssuesOnInspectionContextType = {
  issuesStore: Store<IssueModel[]>;
  isLoadingStore: boolean;
  setInspection: (inspection: string | undefined) => void;
};

const IssuesOnInspectionContext = createContext<
  IssuesOnInspectionContextType | undefined
>(undefined);

const WithIssuesOnInspection: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const mountedRef = useMountedRef();
  const issuesStore = useCreateStore<IssueModel[]>([]);

  const inspectionStore = useCreateStore<string | undefined>(undefined);
  const [isLoadingStore, setIsLoadingStore] = useState<boolean>(false);
  const { timezone } = useSelector(projectDataSelector);
  const { get: getIssuesFiltered } = useGetIssuesFiltered();
  const { subscribe } = useIssueChannelListener();

  const synchronizeIssuesOnInspection = useCallback(() => {
    const inspection = inspectionStore.get();
    if (!inspection) {
      setIsLoadingStore(false);
      return;
    }
    setIsLoadingStore(true);

    const filtersStringified = JSON.stringify({
      filters: [
        {
          path: 'inspection',
          type: 'direct',
          value: [{ _id: inspection }],
        },
      ],
      search: [],
      archived: [true, false],
      timezone,
    });

    getIssuesFiltered(filtersStringified)
      .then((issuesResponse) => {
        if (!mountedRef.current) {
          return;
        }
        if (inspection !== inspectionStore.get()) {
          return;
        }
        issuesStore.set(issuesResponse.items);
        setIsLoadingStore(false);
      })
      .catch(() => {
        if (!mountedRef.current) {
          return;
        }
        if (inspection !== inspectionStore.get()) {
          return;
        }
        displayGenericErrorToaster(dispatch);
        setIsLoadingStore(false);
      });
  }, [
    inspectionStore,
    timezone,
    getIssuesFiltered,
    mountedRef,
    issuesStore,
    dispatch,
  ]);

  useEffect(() => {
    const action = (): void => {
      synchronizeIssuesOnInspection();
    };
    action();
    return inspectionStore.subscribe(action);
  }, [inspectionStore, synchronizeIssuesOnInspection]);

  useEffect(
    function dataListener() {
      const unsubscribe = subscribe((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (
          (event.data && isDataChanged(event)) ||
          event.type === DomainMessagesTypes.tableChanged
        ) {
          synchronizeIssuesOnInspection();
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [synchronizeIssuesOnInspection, subscribe, mountedRef]
  );

  const ctx: IssuesOnInspectionContextType = useMemo(() => {
    return {
      issuesStore,
      isLoadingStore,
      setInspection: inspectionStore.set,
    };
  }, [issuesStore, inspectionStore, isLoadingStore]);

  return (
    <IssuesOnInspectionContext.Provider value={ctx}>
      {children}
    </IssuesOnInspectionContext.Provider>
  );
};

const withIssuesOnInspection =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithIssuesOnInspection>
      <Component {...props} />
    </WithIssuesOnInspection>
  );

function useIssuesOnInspection(): IssuesOnInspectionContextType {
  const context = useContext(IssuesOnInspectionContext);
  if (context === undefined) {
    throw new Error(
      'useIssuesOnInspection must be used within a IssuesOnInspectionContextProvider'
    );
  }
  return context;
}

export {
  WithIssuesOnInspection,
  withIssuesOnInspection,
  useIssuesOnInspection,
};
