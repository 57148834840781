import { BroadcastChannel } from 'broadcast-channel';
import addIssue from 'serviceWorker/services/httpQueue/issue/addIssue';
import editIssue from 'serviceWorker/services/httpQueue/issue/editIssue';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import hash from 'hash-sum';
import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import {
  broadcastAllDeletedIssues,
  broadcastAllIssues,
  broadcastIssue,
  broadcastIssueList,
} from 'serviceWorker/broadcasts/issues';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as issues from 'serviceWorker/db/issues';
import * as issuesService from 'serviceWorker/db/issuesService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { retry } from 'shared/utils/retry';
import { LogLevel } from 'shared/types/logger';
import { swLog } from '../../helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import {
  ConfigData,
  CreateIssueCustomEvent,
  DeleteIssueCustomEvent,
  EditIssueCustomEvent,
  GetIssueCustomEvent,
  GetIssueListCustomEvent,
} from '../../api/types';
import { getFetchConfig } from '../config';
import { isSameAsPrev } from '../factories/isSameAsPrev';
import { Data, ServiceDataShape } from '../factories/types';
import {
  MAX_CONCURENT_PULLS,
  MAX_CONCURENT_PULL_PAGE_SIZE,
} from './concurentPullConstants';
import { issueDeleter } from './deleteIssue';
import { fetchIssues, fetchUpdatedIssues } from './fetchIssues';
import {
  BroadcastTypes,
  IssueBroadcast,
  Props,
  PullIssues,
} from './types';
import * as configRepository from 'serviceWorker/db/config';

const prevUpdate = {
  current: {
    hashString: '',
    itemsString: '',
  },
};

const broadcasts: IssueBroadcast[] = [];
function emitBroadcasts(): void {
  while (broadcasts.length) {
    const broadcast = broadcasts.pop();
    broadcast!.action();
  }
}

const pullFirstPage: PullIssues = async (
  data,
  abortController
): Promise<void> => {
  const params = {
    offset: 0,
    size: 100,
  };
  const pagesAmount = 1;

  debugLog(
    'pullFirstPage: pulling issues from project',
    data.projectId,
    data
  );
  try {
    const response = await fetchIssues(
      data,
      `/issue?offset=0&size=${params.size}&deleted=[true,false]`,
      abortController
    );
    if (!response) {
      await issuesService.add({
        isDownloading: false,
      });
      return;
    }
    if (abortController.signal.aborted) {
      return;
    }

    await issuesService.add({
      total: response.totalCount,
      isDownloading: true,
    });
    const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
    broadcast.postMessage({
      data: { isDownloading: true },
      type: DomainMessagesTypes.state,
    });

    if (abortController.signal.aborted) {
      return;
    }
    await issues.addBatch(response.issues);
    const syncKey = response.syncKey;

    const total = await issues.quantity();
    if (response.hasMore && (total ?? 0) < response.totalCount) {
      const estimatedPulls = Math.max(
        1,
        Math.floor(
          (response.totalCount - params.size) /
            MAX_CONCURENT_PULL_PAGE_SIZE
        ) + 1
      );

      await pullIssuesConcurrentlyWithLimit(data, abortController, {
        ...params,
        offset: params.offset + params.size * pagesAmount,
        size: MAX_CONCURENT_PULL_PAGE_SIZE,
        firstSyncKey: syncKey,
        pagesAmount: estimatedPulls,
      });
    } else {
      const issueServiceData = createFinishedIssueServiceData(
        response.totalCount,
        syncKey
      );
      await issuesService.add(issueServiceData);
      emitBroadcasts();
    }
  } catch (e) {
    swLog('Problem occured when fetching issues', LogLevel.ERROR, e, null);

    await issues.clear();
  }
};

const pullIssuesHandler = async (
  status: issuesService.IssuesServiceData | undefined
): Promise<void> => {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
    broadcasts.length = 0;
  });
  self.addEventListener(DomainMessagesTypes.clearData, () => {
    abortController.abort();
    broadcasts.length = 0;
  });
  self.addEventListener(
    RepositoryMessagesTypes.clearSelectedProjectData,
    () => {
      abortController.abort();
      broadcasts.length = 0;
    }
  );
  const setup = await getFetchConfig();

  const required: (keyof ConfigData)[] = [
    'locale',
    'frontendVersion',
    'api',
    'projectId',
  ];
  const hasRequired =
    required.map((elem) => setup && setup[elem]).filter((e) => !e)
      .length === 0;

  const shouldPull =
    hasRequired && !status?.isDownloading && !status?.isDownloaded;

  const shouldReset = status?.shouldReset;

  if (!setup) {
    return;
  }

  if (shouldPull || shouldReset) {
    // clears shouldReset && cleans old issues

    await Promise.all([
      issuesService.add({
        total: undefined,
        isDownloading: true,
        isDownloaded: false,
        syncKey: undefined,
      }),
      issues.clearForPull(),
    ]);

    return pullFirstPage(setup, abortController);
  }
};

const synchronizeIssues = async (): Promise<void> => {
  const abortController = new AbortController();
  self.addEventListener(DomainMessagesTypes.clearData, () => {
    abortController.abort();
    broadcasts.length = 0;
  });
  self.addEventListener(
    RepositoryMessagesTypes.clearSelectedProjectData,
    () => {
      abortController.abort();
      broadcasts.length = 0;
    }
  );
  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
    broadcasts.length = 0;
  });

  const [setup, service] = await Promise.all([
    getFetchConfig(),
    issuesService.get(),
  ]);
  const syncKey = service?.syncKey;
  if (setup && !service?.isDownloading && !syncKey) {
    pullIssuesHandler(service);
    return;
  }

  if (syncKey && setup) {
    const newState = {
      ...service,
      isDownloading: true,
    };
    await issuesService.addSync(newState);
    const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
    broadcast.postMessage({
      data: newState,
      type: DomainMessagesTypes.state,
    });

    try {
      const data: {
        issues: any[];
        entitiesWithoutAccess: any[];
        syncKey: string;
      } = await fetchUpdatedIssues(setup, syncKey, abortController);

      if (!data || abortController.signal.aborted) {
        return;
      }

      if (data.entitiesWithoutAccess.length) {
        await issues.removeBatch(data.entitiesWithoutAccess);
      }

      if (data.issues.length) {
        const issuesString = JSON.stringify(data.issues);
        const hashString = hash(issuesString);

        const currentUpdate = {
          hashString: hashString,
          itemsString: issuesString,
        };
        if (!isSameAsPrev(currentUpdate, prevUpdate.current)) {
          prevUpdate.current = currentUpdate;
          if (abortController.signal.aborted) {
            return;
          }
          await issues.updateBatch(data.issues);
        }
      }

      const quantity = await issues.quantity();
      const finishedState = createFinishedIssueServiceData(
        quantity,
        data.syncKey
      );
      await issuesService.addSync(finishedState);
      emitBroadcasts();
      broadcast.postMessage({
        data: finishedState,
        type: DomainMessagesTypes.state,
      });
      broadcast.close();
      return;
    } catch (e) {
      prevUpdate.current = { itemsString: '', hashString: '' };

      broadcast.close();
      const msg = 'Problem occured when syncing issues';
      swLog(msg, LogLevel.ERROR, e, null);
    }
  }
};

function createFinishedIssueServiceData(
  totalCount: number,
  syncKey: string
): ServiceDataShape & { hasMore: boolean } {
  return {
    total: totalCount,
    isDownloading: false,
    isDownloaded: true,
    syncKey: syncKey,
    hasMore: false,
  };
}

// TODO: PT-3577
// Currently only the queue will know the issue returned from POST/PUT
// Until we create local changes we cannot broadcast new issue from here, which we actually WANT to do.
function createIssue(event: CreateIssueCustomEvent): void {
  addIssue(event.detail).catch((e) => {
    swLog(e.message, LogLevel.ERROR, e, null);
  });
}

function updateIssue(event: EditIssueCustomEvent): void {
  editIssue(event.detail.address, event.detail.model).catch((e) => {
    swLog(e.message, LogLevel.ERROR, e, null);
  });
}

export async function getIssueById(
  id: string
): Promise<IssueEntity | undefined> {
  return issues.getOne(id);
}

export async function getIssuesByIds(
  ids: string[]
): Promise<IssueEntity[]> {
  return issues.getByIds(ids);
}

export const init = (): void => {
  const syncDebounced = debounce(synchronizeIssues, 250);

  self.addEventListener(
    RepositoryMessagesTypes.syncIssues,
    function onSyncIssues() {
      broadcasts.push({
        type: BroadcastTypes.broadcastAll,
        action: () => {
          broadcastAllIssues();
          broadcastAllDeletedIssues();
        },
      });
      syncDebounced();
    }
  );
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    DomainMessagesTypes.getIssue,
    function onGetIssue(e: GetIssueCustomEvent): void {
      broadcasts.push({
        type: BroadcastTypes.broadcastSingle,
        action: broadcastIssue.bind(null, e),
      });
      syncDebounced();
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getIssueList,
    function onGetIssueList(e: GetIssueListCustomEvent): void {
      broadcasts.push({
        type: BroadcastTypes.broadcastList,
        action: broadcastIssueList.bind(null, e),
      });
      syncDebounced();
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(RepositoryMessagesTypes.addIssue, createIssue);
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(RepositoryMessagesTypes.editIssue, updateIssue);
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteIssue,
    function onDeleteIssue(e: DeleteIssueCustomEvent) {
      issueDeleter.execute(e.detail.issueId);
    }
  );
};

async function pullIssuesConcurrentlyWithLimit(
  data: Data,
  abortController: AbortController,
  props: Props
): Promise<void> {
  const activeFetches: any[] = [];

  const firstCallSyncKey = props.firstSyncKey as string;
  const params = {
    offset: props.offset,
    size: props.size,
  };

  const fetchesToDo = Array.from({ length: props.pagesAmount || 1 })
    .map((_, multiplier) => {
      return `/issue?offset=${
        params.offset + multiplier * params.size
      }&size=${params.size}&deleted=[true,false]`;
    })
    .reverse();

  await new Promise((resolve, reject) => {
    processFetches(
      data,
      abortController,
      fetchesToDo,
      activeFetches,
      resolve,
      reject
    );
  });

  const count = await issues.quantity();
  const issueServiceData = createFinishedIssueServiceData(
    count,
    firstCallSyncKey
  );
  await issuesService.add(issueServiceData);
  emitBroadcasts();
}

function processFetches(
  data: Data,
  abortController: AbortController,
  fetchesToDo: string[],
  activeFetches: any[],
  resolve: (value?: unknown) => void,
  reject: (error: Error) => void
): void {
  if (abortController.signal.aborted) {
    return reject(new Error('Aborted'));
  }

  if (!fetchesToDo.length && !activeFetches.length) {
    return resolve();
  }

  while (
    fetchesToDo.length &&
    activeFetches.length < MAX_CONCURENT_PULLS
  ) {
    const url = fetchesToDo.pop()!;
    processFetch(data, abortController, url, activeFetches)
      .then(() => {
        processFetches(
          data,
          abortController,
          fetchesToDo,
          activeFetches,
          resolve,
          reject
        );
      })
      .catch((e) => reject(e));
  }
}

async function processFetch(
  data: any,
  abortController: any,
  url: string,
  activeFetches: any[]
): Promise<any> {
  activeFetches.push(true);
  try {
    const response = await retry(async () => {
      debugLog('processFetch: Fetching issues from project', url, data);
      const res = await fetchIssues(data, url, abortController);
      if (!res) {
        throw new Error('No response.');
      }
      return res;
    }, 2);
    activeFetches.pop();

    await issuesService.add({
      total: response.totalCount,
      isDownloading: true,
    });
    const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
    broadcast.postMessage({
      data: { isDownloading: true },
      type: DomainMessagesTypes.state,
    });
    await issues.addBatch(response.issues);
  } catch (e) {
    activeFetches.pop();
    throw e;
  }
}
