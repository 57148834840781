import { makeStyles } from '@mui/styles';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import SubheaderText from '../SubheaderText/SubheaderText';

type InfoBannerProps = PropsWithChildren<{
  icon?: string;
  dataQa: string;
  messageId: string;
}>;

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    margin: '0 24px',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    margin: 'auto 0',
    '& p': {
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontFamily: 'Archivo',
      color: '#757575',
      margin: '0 auto 16px auto',
      maxWidth: 500,
    },
  },
  icon: {
    height: 88,
    marginBottom: 48,
  },
  title: {
    marginBottom: 24,
    fontWeight: 700,
  },
});

export function InfoBanner({
  icon,
  dataQa,
  messageId,
  children,
}: InfoBannerProps): React.ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  const [title, info] = intl.formatMessage({ id: messageId }).split('\n');

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        {icon && (
          <img src={icon} alt='no results icon' className={classes.icon} />
        )}
        <SubheaderText className={classes.title} data-qa={dataQa}>
          {title}
        </SubheaderText>
        {info && <p>{info}</p>}
        {children}
      </div>
    </div>
  );
}
