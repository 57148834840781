import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { Login } from '../../views';

import { useAuth0 } from '../../services/auth0/react-auth0.spa';
import { debugLog } from 'shared/logger/debugLog';

export const LoginContainer = (): React.ReactElement => {
  const { isAuthenticated } = useAuth0();
  !isAuthenticated &&
    debugLog(
      'LoginContainer: user is not authenticated ',
      isAuthenticated
    );
  if (isAuthenticated) {
    // react 18 types
    // @ts-ignore
    return <Redirect to='/issue' />;
  }

  // react 18 types
  // @ts-ignore
  return <Route path='/login' component={Login} />;
};
