import { DEFAULT_THUMBNAIL_CARD_SIZE } from './styles';
import { CONFIG_TYPE } from './types';

export const DEFAULT_CONFIG: CONFIG_TYPE = {
  max_file_size: 209715200, // 200MB
  /*
  NOTE: we do video/* and other video formats
  as Safari converts video/* to *.mov
*/
  file_types: [
    'image/*',
    'video/mp4',
    'video/x-m4v',
    'video/*',
    'application/pdf',
  ],
  thumbnailSize: DEFAULT_THUMBNAIL_CARD_SIZE,
  baseUrl: '',
  dataQaPrefix: '',
  atomicChanges: false,
};
