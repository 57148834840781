import {
  memo,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { StatefulComponentProps } from './types';
import { findDependencies, useDependsOn, useAutoSelect } from './model';
import { useDebouncedCallback } from 'helpers/misc';

function StatefulComponent({
  Component,
  formKey,
  ...fieldProps
}: StatefulComponentProps): ReactElement {
  const didMountRef = useRef(false);
  const valueRef = useRef();

  const { errors, values, setValues, registerField, setInputValues } =
    useInputForm();
  const componentRefFunction = useCallback(
    // react 18 types
    // @ts-ignore
    (component) => {
      registerField(
        formKey,
        component,
        fieldProps.labelId,
        fieldProps.dependsOn
      );
    },
    [registerField, formKey, fieldProps.labelId, fieldProps.dependsOn]
  );

  const [value, setValue] = useState(values[formKey]);

  const autoSelect = fieldProps.autoSelect;

  const [defaultValue] = useState(
    values[formKey] || fieldProps.defaultValue
  );

  const decidingFieldValue = fieldProps.dependsOn
    ? values[fieldProps.dependsOn]
    : undefined;

  const [available, setDependencies] = useState(
    findDependencies(
      fieldProps.dependsOn,
      fieldProps.extractor,
      fieldProps.available,
      decidingFieldValue
    ) || fieldProps.available
  );

  useEffect(() => {
    setDependencies(
      findDependencies(
        fieldProps.dependsOn,
        fieldProps.extractor,
        fieldProps.available,
        decidingFieldValue
      ) || fieldProps.available
    );
  }, [
    fieldProps.dependsOn,
    fieldProps.extractor,
    fieldProps.available,
    decidingFieldValue,
  ]);

  const debounce = Boolean(fieldProps.debounce);

  const timeoutRef = useRef<number | undefined>(undefined);

  useEffect(() => {
    // value from form should always overwrite local value
    const valueInForm = values[formKey];
    setValue(valueInForm);
    valueRef.current = valueInForm;
  }, [values, formKey]);

  useEffect(() => {
    if (didMountRef.current) {
      // this prevents form edit on automatically populated multiselects.
      if (value === valueRef.current) {
        return;
      }
      setValues(formKey, value);
    }
  }, [formKey, value, setValues]);

  const inputValue = fieldProps.inputValue;

  useEffect(() => {
    setInputValues(formKey, inputValue);
  }, [setInputValues, formKey, inputValue]);

  useDependsOn(
    fieldProps.dependsOn,
    values,
    formKey,
    setValues,
    valueRef,
    didMountRef
  );

  useEffect(() => {
    didMountRef.current = true;
  }, []);

  const onSelection = useCallback(
    (_, selectedValues: any): void => {
      setValue(selectedValues);
    },
    [setValue]
  );

  const debouncedOnSelection = useDebouncedCallback(
    timeoutRef,
    onSelection,
    200
  );

  const onChange = useCallback(
    (_, change: any): void => {
      setValue(change);
    },
    [setValue]
  );

  const debouncedOnChange = useDebouncedCallback(
    timeoutRef,
    onChange,
    200
  );

  const onDelete = useCallback(
    (_, __, valueToDelete: any): void => {
      setValue((prev: any[]) => {
        return prev.filter((val: any) => val !== valueToDelete);
      });
    },
    [setValue]
  );
  const debouncedOnDelete = useDebouncedCallback(
    timeoutRef,
    onDelete,
    200
  );

  useAutoSelect(autoSelect, value, available, setValue);

  // TODO: make fields properly show disability with tooltip and right reason.
  const isDisabled = fieldProps.disabled; //shouldDisable(fieldProps.disabled, dependencies);

  return (
    <Component
      {...fieldProps}
      ref={componentRefFunction}
      available={available}
      disabled={isDisabled}
      error={errors[formKey]}
      value={value || null}
      defaultValue={defaultValue}
      onSelection={debounce ? debouncedOnSelection : onSelection}
      onDelete={debounce ? debouncedOnDelete : onDelete}
      handleChange={debounce ? debouncedOnChange : onChange}
    />
  );
}

export const MemoStatefulFormInputComponent = memo(StatefulComponent);
