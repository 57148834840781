import { normalizeForSearch } from 'shared/utils/search';
import { SingleFilter, Filter, AsyncFilter, RelationsMap } from './types';

const SEARCH_KEY_BLACKLIST = [
  '_id',
  'documents',
  'events',
  'createdAt',
  'modifiedAt',
  'assignee',
  'createdBy',
  'modifiedBy',
  'executor',
  'documentationId',
  'versionId',
  'project',
  'site',
  'maps',
  'positionOnMap',
  'azureId',
  'inspection',
  'protocolItem',
  'userAccesses',
  'detectionDate',
  'contractNumbers',
] as string[];

// https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab
export const getPropByPath = <T>(
  object: T | object | any[] | undefined,
  path: string | string[]
): any => {
  if (path === '') {
    return object;
  }
  const _path = Array.isArray(path) ? path : path.split('.');
  if (object && _path.length)
    //@ts-ignore
    return getPropByPath(object[_path.shift()], _path);
  return object;
};

// Multiple types of filtering values
export const filtersByType: {
  [key: string]: (
    value: any | undefined,
    filter: SingleFilter['value']
  ) => boolean;
} = {
  // if value is array of {_id} we need to match at least one
  oneOfIds: (value: { _id: string }[] | undefined, filterValues) => {
    if (!Array.isArray(filterValues)) {
      return true;
    }
    if (!value) {
      return false;
    }
    return value.find((elem) => {
      return filterValues.find((filterValue) => {
        return filterValue._id === elem._id;
      });
    })
      ? true
      : false;
  },
  oneOfDirect: (value: string[] | undefined, filterValues: unknown) => {
    if (!Array.isArray(filterValues)) {
      return true;
    }
    if (!value) {
      return false;
    }

    return value.find((elem: string) => {
      return filterValues.find((filterValue) => {
        return filterValue._id === elem;
      });
    })
      ? true
      : false;
  },
  // if value is just {_id}
  directId: (value: { _id: string } | undefined, filterValues) => {
    if (!Array.isArray(filterValues)) {
      return true;
    }
    if (!value) {
      return false;
    }
    return filterValues.find((filterValue) => {
      return filterValue._id === value._id;
    })
      ? true
      : false;
  },
  // if value is string
  direct: (value, filterValues) => {
    if (!Array.isArray(filterValues)) {
      return true;
    }
    return filterValues.find((filterValue) => {
      return filterValue._id === value;
    })
      ? true
      : false;
  },
  // if we want to find element between two dates
  dateRange: (value, filterValues) => {
    if (Array.isArray(filterValues) || typeof filterValues === 'string') {
      return true;
    }
    if (!value) return false;
    // comparing unix timestamp should do the job perfectly
    // curr is current value, not current timestamp
    const curr = new Date(value).getTime();
    // if to or from value is undefined we set it to element timestamp
    // that results in match (i.e if both are undefined - (curr >= curr && curr <= curr) === true)
    const from = filterValues.fromDate
      ? new Date(filterValues.fromDate).getTime()
      : curr;
    const to = filterValues.toDate
      ? new Date(filterValues.toDate).getTime()
      : curr;
    return curr >= from && curr <= to;
  },
  // search in value for user search key, value may be: string, number, array, object, boolean
  search: (value, filterValues) => {
    if (typeof filterValues !== 'string') {
      return false;
    }
    const findInObj = (obj: any, val: string): any[] => {
      let objects = [];
      val = normalizeHashtagSearch(val);
      for (var i in obj) {
        if (!obj.hasOwnProperty(i) || SEARCH_KEY_BLACKLIST.includes(i))
          continue;
        // recursively go deeper
        if (typeof obj[i] == 'object') {
          const subRes = findInObj(obj[i], val);
          if (subRes) {
            // react 18 types
            // @ts-ignore
            objects.push(...subRes);
          }
        } else {
          // if value is an array we need to...
          if (Array.isArray(obj[i])) {
            // ...iterate...
            for (var a in obj[i]) {
              // ...and recursively go deeper
              const subRes = findInObj(obj[i][a], val);
              if (subRes) {
                // react 18 types
                // @ts-ignore
                objects.push(...subRes);
              }
            }
          } else {
            //skip _id fields - this might change to an array of blacklisted keys

            // turn value to lower case, enforce string,
            // do the same for search key and then search in string
            const match = normalizeForSearch(obj[i]).includes(
              normalizeForSearch(val)
            );
            if (match) {
              // react 18 types
              // @ts-ignore
              objects.push(i);
            }
          }
        }
      }
      return objects;
    };

    return findInObj(value, filterValues).length > 0;
  },
};

// Runs filters on data set
export const filtering: Filter = ({ set, filters, options }) => {
  const filteringResult = set.filter((elem) => {
    const filtered = filters
      .filter((filter) => {
        // if filter is wrong we're returning element to be NOT matching
        if (filter.type === 'invalid') {
          return false;
        }
        const exec = filtersByType[filter.type];
        // https://hustro.atlassian.net/browse/PT-3985
        // On issue we skip filtering and an issue passes when field is not visible
        if (
          options &&
          typeof options.skip === 'function' &&
          options.skip(elem, filter)
        ) {
          return true;
        }
        if (exec) {
          const value = getPropByPath(elem, filter.path);

          const result = exec(value, filter.value);
          return result;
        }
        return false;
      })
      .filter((res) => res !== undefined);
    if (options?.any) {
      return filtered.length > 0;
    }
    //only when all filters are valid element is considered matching
    return filtered.length === filters.length;
  });
  return filteringResult;
};

// Generates array of filters out of searchKey and relations hash map
const genRelationSearchFilters = (
  relationsMap: RelationsMap,
  searchKey?: string
): SingleFilter[] | [] => {
  if (!searchKey) {
    return [];
  }
  const res: SingleFilter[] = [];
  Object.keys(relationsMap.hashMap).forEach((key) => {
    const elems = Object.entries(relationsMap.hashMap[key]);
    // get elements which "name" matches search string
    // and map them to array of '_id' objects as regular filter obj value key
    const matchingIds = elems
      .filter(([id, name]) => {
        return normalizeForSearch(name).includes(
          normalizeForSearch(searchKey)
        );
      })
      .map(([id]) => ({ _id: id }));
    // get matches for relation,
    // matches represent filter obj without value
    // and populate it with value from above - resulting in filter array
    relationsMap.matches
      .find((elem: any) => elem.name === key)
      ?.matches.forEach((match: any) => {
        res.push({ ...match, value: matchingIds });
      });
  });

  return res;
};

// runs filters created by generated search filters map
// out of relation hash map and search key itself
export const searching: AsyncFilter = async ({
  set,
  filters,
  relationsMap,
}) => {
  const searchKey = filters.find((elem) => elem.type === 'search')?.value;

  const relationFilters = relationsMap
    ? await genRelationSearchFilters(relationsMap, searchKey as string)
    : [];
  if (filters.length + relationFilters.length === 0) {
    return set;
  }
  return filtering({
    set,
    filters: [...filters, ...relationFilters],
    options: { any: true },
  });
};

function normalizeHashtagSearch(value: string): string {
  if (value.startsWith('#') && value.length >= 4 && value.length <= 5) {
    return value.slice(1);
  }
  return value;
}
