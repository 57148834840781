import { broadcastAllWorktypes } from 'serviceWorker/broadcasts/worktypes';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as worktypes from 'serviceWorker/db/worktypes';
import * as worktypesService from 'serviceWorker/db/worktypesService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { fetchUpdatedWorktypes, fetchWorktypes } from './fetchWorktypes';

import { RepositoryMessagesTypes } from 'serviceWorker/const/events';

import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullWorktypes: Pull = makePullEntity({
  fetch: fetchWorktypes,
  channelName: ChannelNames.worktypeChannel,
  entityName: 'worktypes',
  entityRepository: worktypes,
  entityService: worktypesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullWorktypesHandler = makePullHandler(
  config,
  worktypes,
  worktypesService,
  pullWorktypes,
  clearBroadcasts
);

const synchronizeWorktypes = makeSynchronizeEntity({
  configRepository: config,
  entityService: worktypesService,
  entityRepository: worktypes,
  pullEntityHandler: pullWorktypesHandler,
  fetchUpdatedEntities: fetchUpdatedWorktypes,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'worktypes',
  channelName: ChannelNames.worktypeChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeWorktypes.bind(null, broadcastAllWorktypes),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncWorktypes,
    syncDebounced
  );
};
