import { CompanyOnView } from 'shared/domain/company/types/view';
import {
  Permission,
  noPermission,
  noPermissionInOffline,
} from 'helpers/permission';
import React, { memo, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toUserRole } from 'redux/selectors/role';
import { EDIT_COMPANY_PERMISSIONS } from 'setup/permissions';
import { useIsOffline } from '../../common/withIsOffline';
import { SingleCompanyPresentational } from './presentational';

type SingleCompanyProps = CompanyOnView;

function SingleCompany(props: SingleCompanyProps): React.ReactElement {
  const {
    _id,
    sites,
    shortLabel,
    longLabel,
    projectInvolvementType,
    taxNumber,
  } = props;

  const history = useHistory();
  const isOffline = useIsOffline();
  const sitesLabels = useMemo(
    () => sites.map((site: any) => site.label),
    [sites]
  );
  const userRole = useSelector(toUserRole);

  const editPermission = useMemo((): Permission => {
    if (!EDIT_COMPANY_PERMISSIONS[userRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [userRole, isOffline]);

  const edit = useCallback((): void => {
    history.push(`/company/${_id}/edit`);
  }, [_id, history]);

  return (
    <SingleCompanyPresentational
      shortLabel={shortLabel}
      longLabel={longLabel}
      projectInvolvementType={projectInvolvementType.label}
      taxNumber={taxNumber || ''}
      editDisabledReason={editPermission.reason}
      isEditButtonDisabled={!editPermission.permission}
      edit={edit}
      sites={sitesLabels}
    />
  );
}

export const MemoSingleCompany = memo(SingleCompany);
