import { Identificable, LabelledEntity } from 'shared/types/commonView';
import { LocaleCountryAndLanguageCode } from 'shared/types/locale';

export type FieldValueOnView = LabelledEntity & {
  processes: string[];
  canEditTranslations: boolean;
  valueTranslations: FieldValueTranslationOnView[];
  createdByClientId?: string;
};

export type CreateFieldValueOnView = {
  _id?: undefined;
  localId: string;
  label: string;
  processes: string[];
  canEditTranslations: boolean;
  valueTranslations: FieldValueTranslationOnView[];
  createdByClientId?: string;
};

export type EditFieldValueOnView = Identificable & {
  processes: string[];
  valueTranslations?: FieldValueTranslationOnView[];
  createdByClientId?: string;
};

export type WorktypeOnView = FieldValueOnView;
export type HazardCategoryOnView = FieldValueOnView;
export type EnvironmentalAspectOnView = FieldValueOnView;
export type CorrectiveActionTypeOnView = FieldValueOnView;

export enum FieldValueType {
  fromRemote = 'fromRemote',
  toCreate = 'toCreate',
}

export type FieldValueTranslationOnView = {
  deleted: boolean;
  localeCode: LocaleCountryAndLanguageCode;
  value: string;
  _id: string;
  type: FieldValueType;
};
