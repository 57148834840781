import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import { SetDirectorySync } from 'serviceWorker/repository/directory/setDirectorySync';
import { SetDocumentationSync } from 'serviceWorker/repository/documentation/setDocumentationSync';
import {
  IDocumentationDeleteUploader,
  UploadDocumentationDelete,
} from 'serviceWorker/repository/documentation/uploadDocumentationDelete';
import { Identificable } from 'shared/types/commonView';
import { IRequestRunner } from './httpRequestRunner';

export class DocumentationDeleteRequestRunner implements IRequestRunner {
  constructor(
    private documentationDeleteUploader: IDocumentationDeleteUploader,
    private uploadDocumentationDeleteUseCase: UploadDocumentationDelete,
    private getDocumentations: (
      ids: number[]
    ) => Promise<(Partial<Identificable> & { localId: number })[]>,
    private getDirectories: (
      ids: number[]
    ) => Promise<(Partial<Identificable> & { localId: number })[]>,
    private setDocumentationSync: SetDocumentationSync,
    private setDirectorySync: SetDirectorySync
  ) {}

  execute(request: __HttpRequestModel__): Promise<boolean> {
    switch (request.method) {
      case 'DELETE': {
        return this.deleteDocumentation(request);
      }
    }
    throw new Error('Not implemented.');
  }

  private async deleteDocumentation(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const [documentations, directories] = await Promise.all([
      this.getDocumentations(request.data.documentations),
      this.getDirectories(request.data.directories),
    ]);

    const documentationBaseUrl = `/project/${request.data.projectId}/documentation`;
    const directoryBaseUrl = `/project/${request.data.projectId}/dir`;

    try {
      await Promise.all([
        ...documentations.map((doc) => {
          if (!doc._id) return Promise.resolve(true);
          return this.uploadDocumentationDeleteUseCase(
            this.documentationDeleteUploader,
            `${documentationBaseUrl}/${doc._id}`
          );
        }),

        ...directories.map((dir) => {
          return this.uploadDocumentationDeleteUseCase(
            this.documentationDeleteUploader,
            `${directoryBaseUrl}/${dir._id}`
          );
        }),
      ]);

      await Promise.all([
        ...documentations.map((doc) => {
          return this.setDocumentationSync(
            doc.localId,
            SyncStatus.SUCCESS
          );
        }),
        ...directories.map((dir) => {
          return this.setDirectorySync(dir.localId, SyncStatus.SUCCESS);
        }),
      ]);

      return true;
    } catch (e) {
      [
        ...documentations.map((doc) => {
          return this.setDocumentationSync(
            doc.localId,
            SyncStatus.PENDING
          );
        }),
        ...directories.map((dir) => {
          return this.setDirectorySync(dir.localId, SyncStatus.PENDING);
        }),
      ];
      return false;
    }
  }
}
