import { createStyles, makeStyles } from '@mui/styles';
import { InspectionStatus } from 'shared/domain/inspection/inspectionModel';
import { InspectionStatusSize } from './types';

export const useStyles = makeStyles(() =>
  createStyles({
    inspectionStatus: {
      fontFamily: 'Archivo',
      fontStyle: 'normal',
      fontWeight: 700,
      letterSpacing: '0em',
      textTransform: 'capitalize',
    },
    [InspectionStatusSize.small]: {
      fontSize: '0.625rem',
      lineHeight: '0.7rem',
    },
    [InspectionStatusSize.table]: {
      fontSize: '0.75rem',
      lineHeight: '0.75rem',
    },
    [InspectionStatus.inProgress]: {
      color: '#FF9800',
    },
    [InspectionStatus.completed]: {
      color: '#00904C',
    },
    default: {},
  })
);
