import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { ProjectEditModel } from './types/model';

export function startProjectEdit(
  data: ProjectEditModel,
  uniqueId: string
): void {
  debugLog('startProjectEdit', data);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.PROJECTS,
    method: ServiceMethods.EDIT,
    data: { projectEditModel: data, uniqueId },
  });
  broadcast.close();
}
