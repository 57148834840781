import { debugLog } from 'shared/logger/debugLog';
import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from '../../helpers/httpRequests';
import { HeadersData } from '../factories/types';
import { pull } from '../pull';

export const fetchOrganizations = (
  data: HeadersData,
  controller: AbortController
): Promise<any> | undefined => {
  const { signal } = controller;
  if (shouldSkip(data, signal)) {
    return;
  }
  let headers;
  try {
    headers = generateHeaders(data);
  } catch (e) {
    debugLog('Error generating headers', e, 'organizations');
    return;
  }

  const builtUrl = `${data.api}/v2/client/me`;
  debugLog('Starting to pull organizations', builtUrl);
  return pull(builtUrl, {
    ...defaultConfig,
    method: 'GET',
    headers,
    signal,
  }).then(parseResponse);
};

function shouldSkip(data: HeadersData, signal: AbortSignal): boolean {
  return !data.api || signal.aborted;
}
