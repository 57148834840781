import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from '@mui/material';
import {
  resolveMuiCoreTranslation,
  resolveMuiDatePickerTranslation,
} from 'intl/muiTranslations';
import React, { PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { Color } from 'shared/utils/colors';

export const ThemeProviderWrapper = ({
  children,
}: PropsWithChildren<{}>): React.ReactElement => {
  const { locale } = useIntl();
  const theme = createTheme(
    {
      //TODO https://hustro.atlassian.net/browse/PT-5934
      // direction: getTextDirectionByLocale(intl.locale),
      palette: {
        primary: {
          main: Color.primaryOrange,
          light: Color.primaryLightOrange,
          contrastText: Color.black,
        },
        secondary: {
          main: '#7D5260',
          light: '#FFD8E4',
          contrastText: '#fff',
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              // borderRadius: 8,
              borderWidth: '2px !important',
            },
            outlined: {
              color: 'black',
            },
            containedPrimary: {
              // fontWeight: 700,
            },
            text: {
              color: 'black',
            },
          },
        },

        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            },
            // notchedOutline: {
            //   // borderColor: '#e6e6e6',
            //   borderColor: 'transparent',
            // },
          },
        },
        MuiFormControl: {
          defaultProps: {
            variant: 'outlined',
            // margin: 'dense',
          },
        },
        MuiInputLabel: {
          defaultProps: {
            variant: 'outlined',
          },
        },
        MuiTextField: {
          defaultProps: {
            variant: 'outlined',
          },
        },
      },
      typography: {
        fontFamily: 'Archivo, Arial',
        h1: {
          fontSize: '2rem',
          fontWeight: 'bold',
        },
        h3: {
          fontSize: '1rem',
          fontWeight: 'bold',
        },
        h4: {
          fontSize: '1rem',
          color: '#979797',
        },
        button: {
          lineHeight: '1.8',
        },
      },
    },
    resolveMuiDatePickerTranslation(locale),
    resolveMuiCoreTranslation(locale)
  );
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}> {children} </ThemeProvider>
    </StyledEngineProvider>
  );
};
