import { createdByAccessor } from 'components/table/accessors';
import { SORT_TYPE } from 'shared/filter/sort/types';
import { ColumnShapeSkeleton } from '../types';
import { StandardHeader } from 'components/table/ColumnHeader';
import { cellCreatedBy } from 'components/table/renderers/cell/createdBy';

export function getCreatedByColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 192,
    flexGrow: 0,
    flexShrink: 0,
    sortable: true,
    resizable: true,
    sortType: SORT_TYPE.STRING,
    accessor: createdByAccessor,
    cellRenderer: cellCreatedBy,
    labelId: 'issue_preview_label_created_by',
    dataKey: 'createdBy',
    style: {
      justifyContent: 'flex-start',
    },
  };
}

export function getInspectionAuthorColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    key: 'author',
    initialWidth: 100,
    // TODO PT-5126
    minWidth: 100,
    flexGrow: 1,
    flexShrink: 1,
    // TODO PT-5126
    sortable: false,
    resizable: true,
    headerRenderer: StandardHeader,
    labelId: 'inspection_table_author',
    dataKey: 'author',
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
