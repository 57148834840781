import { LogoutOptions } from '@auth0/auth0-spa-js';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import React, { ReactElement, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { USER_LOGOUT } from 'redux/actionTypes';
import { useAuth0 } from 'services/auth0/react-auth0.spa';

type WithLogoutProps = { children: ReactElement };

type LogoutContextType = {
  loading: boolean;
  logout: (o?: LogoutOptions | undefined) => void;
};

const LogoutContext = React.createContext<LogoutContextType | undefined>(
  undefined
);

function WithLogout({ children }: WithLogoutProps): ReactElement {
  const { loading, logout: auth0Logout } = useAuth0();
  const dispatch = useDispatch();

  const logout = useCallback((): void => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
    broadcast.postMessage({
      service: Services.CONFIG,
      method: ServiceMethods.LOGOUT,
    });
    broadcast.close();
    auth0Logout();
    dispatch({ type: USER_LOGOUT });
  }, [auth0Logout, dispatch]);

  const ctx = useMemo(() => {
    return {
      logout,
      loading,
    };
  }, [logout, loading]);

  return (
    <LogoutContext.Provider value={ctx}>{children}</LogoutContext.Provider>
  );
}

function useLogout(): LogoutContextType {
  const context = React.useContext(LogoutContext);
  if (context === undefined) {
    throw new Error(
      'useLogout must be used within an LogoutContextProvider'
    );
  }
  return context;
}

const withLogout =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithLogout>
      <Component {...props} />
    </WithLogout>
  );

export { WithLogout, useLogout, withLogout };
