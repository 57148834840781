import formatRelative from 'date-fns/formatRelative';
import { calendarLocales } from 'intl/calendarLocales';

export function toRelativeDate(
  date: Date | undefined,
  baseDate: Date,
  fallback: string,
  locale: string
): string {
  return date
    ? formatRelative(date, baseDate, { locale: calendarLocales[locale] })
    : fallback;
}

export function capitalize(text: string): string {
  return text[0].toLocaleUpperCase() + text.slice(1);
}
