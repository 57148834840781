import React, { ReactElement } from 'react';
import useIsMobile from '../../../../hooks/useIsMobile';
import { ProjectNavigationTarget } from '../../../../views/projects/settings';
import { useStyles } from './styles';
import { NavigationTab } from '../NavigationTab/NavigationTab';
import { SaveNavigationTarget } from 'hooks/useNavigationTarget';

type NavigationHeaderProps = {
  availableTargets: ProjectNavigationTarget[];
  baseLocation: string;
  setTarget: SaveNavigationTarget;
  currentTarget: ProjectNavigationTarget;
};

export function NavigationHeader(
  props: NavigationHeaderProps
): ReactElement {
  const isMobile = useIsMobile();
  const [isExpanded, setExpanded] = React.useState<boolean>(false);

  const classes = useStyles({ isMobile, isExpanded });
  const { currentTarget, baseLocation, setTarget } = props;

  return (
    <div className={classes.header}>
      {props.availableTargets.map((target) => {
        return (
          <NavigationTab
            key={target}
            tabTarget={target}
            currentTarget={currentTarget}
            baseLocation={baseLocation}
            setTarget={setTarget}
            setExpanded={setExpanded}
            isExpanded={isExpanded}
          />
        );
      })}
    </div>
  );
}
