import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { EditableFieldValuesEntityName } from './fields';
import { CreateFieldValueModel } from './fieldValueModel';

export function startFieldValuesCreate(
  fieldName: EditableFieldValuesEntityName,
  fieldValues: CreateFieldValueModel[],
  uniqueId: string
): void {
  debugLog('startFieldValuesCreate', fieldName, fieldValues, uniqueId);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.FIELD_VALUES,
    method: ServiceMethods.CREATE,
    data: {
      fieldName,
      fieldValues,
      uniqueId,
    },
  });
  broadcast.close();
}
