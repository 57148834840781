import { UserInDto } from 'shared/dtos/in/user';
import { UserEditOutDto, UserCreateOutDto } from 'shared/dtos/out/user';

export interface IUserUploader {
  POST: (url: string, data: any) => Promise<any>;
  PUT: (url: string, data: any) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadUserChange {
  (
    uploader: IUserUploader,
    body: UserEditOutDto,
    url: string
  ): Promise<UserInDto[]>;
}

export interface UploadUser {
  (
    uploader: IUserUploader,
    body: UserCreateOutDto,
    url: string
  ): Promise<UserInDto>;
}

export interface UploadUserDelete {
  (uploader: IUserUploader, url: string): Promise<unknown>;
}

export function uploadUserChange(
  uploader: IUserUploader,
  body: UserEditOutDto,
  url: string
): Promise<UserInDto[]> {
  return uploader.PUT(url, body);
}

export function uploadUser(
  uploader: IUserUploader,
  body: UserCreateOutDto,
  url: string
): Promise<UserInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadUserDelete(
  uploader: IUserUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}
