import { useContext, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { FormattedMessage } from 'react-intl';
import { SubheaderText as Title } from 'components/general';
import { HeaderContext } from 'components/core/Layout/HeaderContext';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    margin: '0 24px',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  content: {
    whiteSpace: 'break-spaces',
    textAlign: 'center',
  },
  title: {
    marginTop: 12,
    marginBottom: 24,
  },
  info: {
    fontSize: '1rem',
    lineHeight: '1.25rem',
    fontFamily: 'Archivo',
  },
});

const EntityNotFoundView = ({ entity = 'issue' }): JSX.Element => {
  const { setRouteFor } = useContext(HeaderContext);
  const classes = useStyles();

  useEffect(() => {
    setRouteFor('notFoundScreen');

    // run once
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <Title className={classes.title}>
          <FormattedMessage id={`entity_not_found_${entity}_title`} />
        </Title>
        <p className={classes.info}>
          <FormattedMessage id={`entity_not_found_${entity}_info`} />
        </p>
      </div>
    </div>
  );
};

export default EntityNotFoundView;
