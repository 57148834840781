import React, { PropsWithChildren } from 'react';
import { Button } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  contrastingButton: {
    fontSize: '1rem',
    fontWeight: 600,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 2,
    boxShadow: 'none',
    color: 'black',
  },
}));

type ButtonContrastingProps = PropsWithChildren<any>;

const ButtonContrasting = ({
  children,
  ...props
}: ButtonContrastingProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      // disableRipple
      className={classNames(props.className, classes.contrastingButton)}
    >
      {children}
    </Button>
  );
};

export const MemoButtonContrasting = React.memo(ButtonContrasting);
export default ButtonContrasting;
