import { PropsWithChildren } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';

const useStyles = makeStyles({
  outlinedButton: {
    fontSize: '1rem',
    fontWeight: 600,
    paddingLeft: 12,
    paddingRight: 12,
    borderRadius: 2,
    border: '1px solid #ddd',
    backgroundColor: 'transparent',
    color: '#000000',
    '&:hover': {
      border: '1px solid #ddd',
      backgroundColor: '#F2F2F2',
    },
  },
});

type ButtonOutlinedProps = PropsWithChildren<any>;

const ButtonOutlined = ({
  children,
  ...props
}: ButtonOutlinedProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Button
      {...props}
      color='primary'
      variant='outlined'
      className={classNames(props.className, classes.outlinedButton)}
    >
      {children}
    </Button>
  );
};

export default ButtonOutlined;
