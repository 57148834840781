import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { SiteEditModel } from 'shared/domain/site/types/model';
import { EditSiteUseCase } from 'shared/domain/site/types/useCases';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { SiteHttpEditRequestModel } from '../httpQueue/site/types';

export class SiteEditor implements EditSiteUseCase {
  constructor(
    private addRequest: (request: SiteHttpEditRequestModel) => Promise<any>
  ) {}

  async execute(
    siteEditModel: SiteEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditSiteUseCase', siteEditModel);
    const request: SiteHttpEditRequestModel = {
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        siteEditModel: {
          label: siteEditModel.label,
          code: siteEditModel.code,
          _id: siteEditModel._id,
          projectId: siteEditModel.projectId,
        },
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.site,
      status: HttpRequestStatus.NEW,
      url: `/project/${siteEditModel.projectId}/site/${siteEditModel._id}`,
    };

    await this.addRequest(request);
  }
}

export const siteEditor = new SiteEditor(addRequest);
