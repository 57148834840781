import { IssueModel } from 'shared/domain/issue/issueModel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';
import { GetIssueEventDetail, GetIssueListEventDetail } from './types';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncIssues);

const getOne = (e: { id: string }): void => {
  self.dispatchEvent(
    new CustomEvent<GetIssueEventDetail>(
      RepositoryMessagesTypes.getIssue,
      {
        detail: { id: e.id },
      }
    )
  );
  const broadcastChannel = new BroadcastChannel(ChannelNames.issueChannel);
  broadcastChannel.postMessage({
    type: RepositoryMessagesTypes.getIssue,
    data: {
      id: e.id,
    },
  });
};

const getList = (e: { ids: string[] }): void => {
  self.dispatchEvent(
    new CustomEvent<GetIssueListEventDetail>(
      RepositoryMessagesTypes.getIssueList,
      {
        detail: { ids: e.ids },
      }
    )
  );
};

const create = (e: IssueModel): void => {
  debugLog('createIssue event', e);
  self.dispatchEvent(
    new CustomEvent<IssueModel>(RepositoryMessagesTypes.addIssue, {
      detail: { ...e },
    })
  );
};

const edit = (e: IssueModel): void => {
  debugLog('editIssue event', e);
  self.dispatchEvent(
    new CustomEvent<IssueModel>(RepositoryMessagesTypes.editIssue, {
      detail: { ...e },
    })
  );
};

const remove = (e: { id: string }): void => {
  debugLog('remove issue event', e);
  self.dispatchEvent(
    new CustomEvent<any>(RepositoryMessagesTypes.deleteIssue, {
      detail: { issueId: e.id },
    })
  );
};

export default { sync, getOne, create, edit, getList, remove };
