import React, { useCallback, ChangeEvent } from 'react';
import PanelController from './PanelController';
import classNames from 'classnames';

import { ChipList } from '../index';

import { Typography } from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import createStyles from '@mui/styles/createStyles';
import { LabelledEntity } from 'shared/types/commonView';

const styles = createStyles({
  panelWrapper: {
    margin: 0,
    minHeight: '3rem',
    position: 'relative' as 'relative',
    borderRadius: '0.25rem',
    padding: '0.75rem 1rem',
    cursor: 'pointer',
    boxSizing: 'border-box' as 'border-box',
    '& legend': {
      padding: 0,
    },
    '&.Mui-disabled': {
      cursor: 'default',
    },
  },
  panelLabel: {
    position: 'absolute' as 'absolute',
    top: '1rem',
    left: '0.625rem',
    fontFamily: 'Archivo, Arial',
    fontSize: '1rem',
    color: '#979797',
    fontWeight: 400,
    lineHeight: 1,
    transition: 'all 0.2s linear',
    '&.error': {
      color: '#F44336',
    },
  },
  panelLabelFilled: {
    top: '-6px',
    fontSize: '0.65rem',
    transition: 'all 0.2s linear',
    zIndex: 1,
    backgroundColor: '#FFFFFF',
  },
  errorMessage: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '1em',
    margin: '8px 14px 0',
  },
});

type LabelProps = {
  className: string;
  label: string;
  required: boolean;
};

const Label = ({
  className,
  label,
  required,
}: LabelProps): React.ReactElement => (
  <label className={className}>
    <span>&nbsp;</span>
    {label}
    {required && ' *'}
    <span>&nbsp;</span>
  </label>
);

type PanelTypes = WithStyles<typeof styles> & {
  label: string;
  onBlur?: () => void;
  required: boolean;
  helperText?: string;
  className?: string;
  value?: LabelledEntity[];
  fieldName: string;
  availableItems?: LabelledEntity[];
  onDelete: (
    event: ChangeEvent<Element>,
    field: string,
    itemToDelete: LabelledEntity,
    value: LabelledEntity[] | undefined
  ) => void;
  disabled?: boolean;
  onSelection: (field: string, items: LabelledEntity[]) => void;
};

const UploadItemsPanel = ({
  label,
  classes,
  onBlur,
  required,
  helperText,
  className,
  value,
  onDelete,
  availableItems,
  fieldName,
  disabled,
  onSelection,
}: PanelTypes): React.ReactElement => {
  const handleDelete = useCallback(
    (...args: [ChangeEvent<Element>, string, LabelledEntity]): void => {
      onDelete(args[0], args[1], args[2], value);
    },
    [onDelete, value]
  );

  return (
    <>
      <div
        tabIndex={0}
        onBlur={onBlur}
        data-qa={`issue-form-field-${fieldName}`}
        className={classNames(
          classes.panelWrapper,
          className,
          helperText && 'Mui-error',
          disabled && 'Mui-disabled'
        )}
      >
        <legend>
          <Label
            required={required}
            label={label}
            className={classNames(
              classes.panelLabel,
              value?.length && classes.panelLabelFilled,
              helperText && 'error'
            )}
          />
        </legend>
        <ChipList
          items={value as LabelledEntity[]}
          fieldName={fieldName as string}
          onItemDelete={handleDelete}
          editable={!disabled}
        />
        <PanelController
          label={label}
          disabled={disabled}
          elementTypes={availableItems || []}
          currentItems={value || []}
          onSelection={onSelection}
          fieldName={fieldName}
          error={!!helperText}
          className={className}
        />
      </div>
      {helperText && (
        <Typography
          color='error'
          variant='h6'
          component='p'
          className={classes.errorMessage}
        >
          {helperText}
        </Typography>
      )}
    </>
  );
};

export default withStyles(styles)(UploadItemsPanel);
