import { DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { useCreateOrganization } from 'components/dataCreationForms/organization/withCreateOrganization';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { OrganizationForm } from 'components/organization/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React from 'react';
import { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';

function CreateOrganizationDialog(): ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
    formName,
  } = useCreateOrganization();
  useFormCleanupOnUnmount(formName);

  return (
    <Dialog fullWidth open={open} onClose={closeDialog}>
      <WithInputForm
        values={initialValues}
        errors={{}}
        handleFormValidation={handleFormValidation}
        handleFieldValidation={validate}
        onSubmit={submitForm}
        eventType={SUBMIT_EVENT_TYPE}
        dispatchSubmit={releaseSubmitEvent}
        formName={formName}
      >
        <DialogTitle>
          <FormattedMessage id='create_organization' />
        </DialogTitle>
        <Divider />

        <DialogContent>
          <OrganizationForm isDialog={true} />
        </DialogContent>

        <Divider />

        <DialogActions>
          <DialogButtons
            isPosting={isPosting}
            onSubmit={releaseSubmitEvent}
            onCancel={closeDialog}
          />
        </DialogActions>
      </WithInputForm>
    </Dialog>
  );
}

export const MemoCreateOrganizationWizardDialog = React.memo(
  CreateOrganizationDialog
);
