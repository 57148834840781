import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
import { MENU_BAR_HEADER_HEIGHT } from 'components/core/MenuBar/styles';

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      headLeft: {
        display: 'flex',
        alignItems: 'center',
        flexGrow: 1,
        flexShrink: 1,
        gap: ({ isMobile }): string => (isMobile ? '4px' : '16px'),
      },
      head: {
        width: '100%',
        boxSizing: 'border-box',
        gap: '8px',
        height: '56px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
        zIndex: 1,
        padding: '10px 16px',
      },
      content: {
        display: 'flex',
        overflowY: 'hidden' as 'hidden',
        height: `calc(100vh - ${MENU_BAR_HEADER_HEIGHT}px - 56px)`,
      },
      showListButton: {
        display: ({ isMobile }): string => (isMobile ? 'block' : 'none'),
      },
      listButtonWrap: {
        flexShrink: 0,
      },
    })
);
