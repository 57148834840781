import { StandardHeader } from 'components/table/ColumnHeader';
import { ColumnShapeSkeleton } from '../types';

export function getControlledPartyColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    key: 'controlledParty',
    initialWidth: 100,
    // TODO PT-5126
    minWidth: 100,
    flexGrow: 1,
    flexShrink: 1,
    sortable: false,
    resizable: true,
    headerRenderer: StandardHeader,
    labelId: 'inspection_controlled_party',
    dataKey: 'joinedControlledParty',
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
