import {
  createContext,
  FC,
  ReactNode,
  useState,
  useCallback,
  useContext,
  ComponentType,
  ReactElement,
  useMemo,
} from 'react';
import { useGraphicUploaderContext } from '../../common/graphicUploader';
import { FileType } from '../../common/graphicUploader/types';

type EditDescriptionFormContext = {
  isOpen: boolean;
  closeDialog: () => void;
  openDialog: () => void;
  file: FileType;
  idx: number;
  setIndex: (i: number) => void;
};
const WithEditDescriptionFormContext = createContext<
  EditDescriptionFormContext | undefined
>(undefined);

const WithEditDescriptionForm: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { filesStore } = useGraphicUploaderContext();
  const [isOpen, setIsOpen] = useState(false);
  const [index, setIndex] = useState(-1);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, []);
  const openDialog = useCallback(() => {
    setIsOpen(true);
  }, []);

  const ctx = useMemo(() => {
    return {
      isOpen,
      closeDialog,
      openDialog,
      idx: index,
      file: filesStore.get()[index],
      setIndex,
    };
  }, [closeDialog, filesStore, index, isOpen, openDialog]);

  return (
    <WithEditDescriptionFormContext.Provider value={ctx}>
      {children}
    </WithEditDescriptionFormContext.Provider>
  );
};

export function useEditDescriptionForm(): EditDescriptionFormContext {
  const context = useContext(WithEditDescriptionFormContext);
  if (context === undefined) {
    throw new Error(
      'useEditDescriptionForm must be used within an EditDescriptionFormContext'
    );
  }
  return context;
}

export const withEditDescriptionForm =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditDescriptionForm>
      <Component {...props} />
    </WithEditDescriptionForm>
  );
