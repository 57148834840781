import { Transaction } from 'dexie';
import { DocumentParentBond } from 'shared/domain/document/documentModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { HttpRequestModelType } from 'shared/domain/httpRequest/httpRequestModel';
import { DocumentInDto } from 'shared/dtos/in/document';
import { ProtocolItemInDto } from 'shared/dtos/in/protocolItem';
import {
  DocumentEntity,
  DocumentInsertEntity,
} from 'serviceWorker/repository/document/entity';
import { Identificable } from 'shared/types/commonView';

export const version15Store = {
  issues: `&_id, createdAt, createdBy, modifiedAt, modifiedBy, deleted, stage, process, hashtag`,
  issuesService: `++_id, total, isDownloading, isDownloaded, shouldReset, syncKey`,
  config: `++_id, locale, token, frontendVersion, api, projectId, timezone`,
  companies: `&_id, shortLabel, longLabel, deleted, createdAt, createdBy, modifiedAt, modifiedBy, sites, projectInvolvementType`,
  companiesService: `++_id, total, isDownloading, isDownloaded, shouldReset, syncKey`,
  contracts: `&_id, label, parties, createdBy, modifiedAt, modifiedBy`,
  contractsService: `++_id, total, isDownloading, isDownloaded, shouldReset, syncKey`,
  users: `&_id, label`,
  usersService: `++_id, total, isDownloading, isDownloaded, shouldReset`,
  permissions: `++_id, sites, processes, role`,
  analytics: `&_id, name, projectId, filters, series, locale, additionalParams, updatedAt`,
  chartFilters: `&_id, filters`,
  projects: `&_id, contractNumbers, name, sites, subcontractors, timezone`,
  projectsService: `++_id, total, isDownloading, isDownloaded, shouldReset`,
  selectedProject: `&_id`,
  inspectionTemplates: `&_id, checklist, generalNorm, process, status, summary, createdBy, createdAt, deleted, modifiedAt, modifiedBy`,
  inspectionTemplatesService: `++_id, total, isDownloading, isDownloaded, shouldReset, syncKey`,
  httpRequests: `++_id, method, url, data, headers, createdAt`,
  inspections: `&_id, template, workTypes, controlledParty, protocol, contracts, site, levels, isCompleted, summary, inspectionDate, comment, createdAt, deleted, modifiedAt, modifiedBy`,
  inspectionsService: `++_id, total, isDownloading, isDownloaded, shouldReset, syncKey`,
};
export const version15Upgrade = (
  transaction: Transaction
): Promise<any> => {
  return transaction
    .table('issues')
    .toCollection()
    .modify((issue) => {
      issue.deleted = issue.deleted ? 1 : 0;
    });
};

export const version18Store = {
  sites: `&_id, label`,
  sitesService: `++_id`,
  projects: `&_id, name`,
  issues: `&_id, deleted, stage`,
  issuesService: `++_id`,
  config: `++_id, locale, token, frontendVersion, api, projectId, timezone`,
  companies: `&_id`,
  companiesService: `++_id`,
  contracts: `&_id`,
  contractsService: `++_id`,
  users: `&_id, label`,
  usersService: `++_id`,
  permissions: `++_id`,
  analytics: `&_id, name, projectId, additionalParams, updatedAt`,
  chartFilters: `&_id, filters`,
  projectsService: `++_id`,
  selectedProject: `&_id`,
  inspectionTemplates: `&_id, deleted`,
  inspectionTemplatesService: `++_id`,
  httpRequests: `++_id, status`,
  inspections: `&_id, deleted`,
  inspectionsService: `++_id`,
  documents: `++localId, &_id, issueId, eventId, inspectionId, templateId, checklistId, protocolId`,
};

export const version18Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  const documents: DocumentInsertEntity[] = [];
  const BINDINGS_BASE = {
    issueId: '',
    eventId: '',
    templateId: '',
    inspectionId: '',
    checklistId: '',
    protocolId: '',
  };

  function inDtoToDocumentInsertEntity(
    documentInDto: DocumentInDto,
    parentEntityObject: DocumentParentBond
  ): any {
    return {
      ...BINDINGS_BASE,
      ...parentEntityObject,
      ...documentInDto,
    };
  }

  await transaction
    .table('issues')
    .toCollection()
    .modify((issue) => {
      const docs = [
        ...issue.primaryData.documents.map((document: DocumentInDto) =>
          inDtoToDocumentInsertEntity(document, {
            issueId: issue._id,
          })
        ),
        ...issue.events.flatMap((event: any) => {
          const eventDocs = event.documents.map(
            (document: DocumentInDto) =>
              inDtoToDocumentInsertEntity(document, {
                issueId: issue._id,
                eventId: event._id,
              })
          );
          return eventDocs;
        }),
      ];

      documents.push(...docs);
    });

  await transaction
    .table('inspections')
    .toCollection()
    .modify((inspection) => {
      const docs = inspection.protocol.flatMap(
        (protocolItem: ProtocolItemInDto) =>
          protocolItem.complianceCheck.documents.map(
            (document: DocumentInDto) =>
              inDtoToDocumentInsertEntity(document, {
                inspectionId: inspection._id,
                protocolId: protocolItem._id,
              })
          )
      );
      documents.push(...docs);
    });

  await transaction
    .table('inspectionTemplates')
    .toCollection()
    .modify((template) => {
      const docs = [
        ...template.generalNorm.documents.map((document: DocumentInDto) =>
          inDtoToDocumentInsertEntity(document, {
            templateId: template._id,
          })
        ),
        ...template.checklist.flatMap((checklistItem: any) => {
          const checklistDocs = checklistItem.norm.documents.map(
            (document: DocumentInDto) =>
              inDtoToDocumentInsertEntity(document, {
                templateId: template._id,
                checklistId: checklistItem._id,
              })
          );
          return checklistDocs;
        }),
      ];

      documents.push(...docs);
    });

  await transaction.table('documents').bulkAdd(documents);
};

export const version23Store = {
  analytics: `&_id, name, projectId, additionalParams, updatedAt`,
  chartFilters: `&_id, filters`,
  companies: `&_id`,
  companiesService: `++_id`,
  config: `++_id, locale, token, frontendVersion, api, projectId, timezone`,
  contracts: `&_id`,
  contractsService: `++_id`,
  documents: `++localId, &_id, issueId, eventId, inspectionId, templateId, checklistId, protocolId`,
  hazardCategories: `&_id, label, processes`,
  hazardCategoriesService: `++_id`,
  httpRequests: `++_id, status`,
  inspections: `&_id, deleted`,
  inspectionsService: `++_id`,
  inspectionTemplates: `&_id, deleted`,
  inspectionTemplatesService: `++_id`,
  issues: `&_id, deleted, stage`,
  issuesService: `++_id`,
  permissions: `++_id`,
  projects: `&_id, name`,
  projectsService: `++_id`,
  selectedProject: `&_id`,
  sites: `&_id, label`,
  sitesService: `++_id`,
  users: `&_id, label`,
  usersService: `++_id`,
  worktypes: `&_id, label, processes`,
  worktypesService: `++_id`,
  environmentalAspects: `&_id, label, processes`,
  environmentalAspectsService: `++_id`,
  correctiveActionTypes: `&_id, label, processes`,
  correctiveActionTypesService: `++_id`,
};
export const version23Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  const DOCUMENT_ENTITY_TYPES = [
    HttpRequestModelType.document,
    HttpRequestModelType.drawing,
  ];
  const httpRequests = await transaction
    .table('httpRequests')
    .toCollection()
    .toArray();

  await transaction
    .table('documents')
    .toCollection()
    .modify((document: DocumentEntity) => {
      const currentDocumentRequests = httpRequests.filter((request) => {
        return (
          DOCUMENT_ENTITY_TYPES.includes(request.entityType) &&
          request.data.localId === document.localId
        );
      });

      document.syncStatus = SyncStatus.SUCCESS;
      document.drawingSyncStatus = SyncStatus.SUCCESS;

      if (!currentDocumentRequests.length) {
        return;
      }

      currentDocumentRequests.forEach((documentRequest) => {
        if (documentRequest.entityType === HttpRequestModelType.document) {
          document.syncStatus = SyncStatus.PENDING;
        } else if (
          documentRequest.entityType === HttpRequestModelType.drawing
        ) {
          document.drawingSyncStatus = SyncStatus.PENDING;
        }
      });
    });
};

export const version28Store = {
  sites: `&_id, label, deleted`,
  levels: `&_id, label, site, deleted`,
  levelsService: `++_id`,
  documentations: `++localId, &_id, parentId, deleted, name`,
  documentationsService: `++_id`,
  users: `&_id, label, role`,
  directories: `++localId, &_id, parentId, deleted, name`,
  directoriesService: `++_id`,
};
export const version28Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction
    .table('documentations')
    .toCollection()
    .modify((documentation: any) => {
      documentation.parentId = documentation.parentId
        ? documentation.parentId
        : '';
      documentation.deleted = documentation.deleted ? 1 : 0;
    });
};

function updateEntityAuthor(item: any): void {
  item.createdBy = item.createdBy?._id;
  item.modifiedBy = item.modifiedBy?._id;
}
export const version29Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction
    .table('companies')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('contracts')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('directories')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('documentations')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('documents')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('inspectionTemplates')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('inspections')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('sites')
    .toCollection()
    .modify(updateEntityAuthor);

  await transaction
    .table('levels')
    .toCollection()
    .modify(updateEntityAuthor);
};

export const version30Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction
    .table('directories')
    .toCollection()
    .modify((item: any) => {
      item.deleted = item.deleted ? 1 : 0;
    });
};

export const version31Store = {
  httpRequests: `++_id, status, method, entityType`,
};

export const version32Store = {
  companies: `&_id, deleted`,
  contracts: `&_id, deleted`,
};

export const version32Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction
    .table('companies')
    .toCollection()
    .modify((item: any) => {
      item.deleted = item.deleted ? 1 : 0;
    });

  await transaction
    .table('contracts')
    .toCollection()
    .modify((item: any) => {
      item.deleted = item.deleted ? 1 : 0;
    });
};

export const version33Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction
    .table('issues')
    .toCollection()
    .modify((item: any) => {
      item.primaryData.site = item.primaryData.site._id;
      item.primaryData.level = item.primaryData.level._id;
      item.primaryData.subcontractors =
        item.primaryData.subcontractors?.map(
          (element: Identificable) => element._id
        );
      item.primaryData.contractNumbers =
        item.primaryData.contractNumbers?.map(
          (element: Identificable) => element._id
        );

      item.extendedData.workTypes = item.extendedData.workTypes?.map(
        (element: Identificable) => element._id
      );
      item.extendedData.rootCauses = item.extendedData.rootCauses?.map(
        (element: Identificable) => element._id
      );
      item.extendedData.impact = item.extendedData.impact?._id;
      item.extendedData.environmentalAspect =
        item.extendedData.environmentalAspect?._id;
      item.extendedData.effect = item.extendedData.effect?._id;
      item.extendedData.hazardCategory =
        item.extendedData.hazardCategory?.map(
          (element: Identificable) => element._id
        );
      item.extendedData.subcontractorRepresentative =
        item.extendedData.subcontractorRepresentative?._id;
      item.extendedData.decisionToImposeFine =
        item.extendedData.decisionToImposeFine?._id;
      item.extendedData.circumstances =
        item.extendedData.circumstances?._id;
      item.extendedData.proposedCorrectiveAction =
        item.extendedData.proposedCorrectiveAction?._id;
      item.extendedData.spilledSubstance =
        item.extendedData.spilledSubstance?._id;
      item.extendedData.estimatedCost =
        item.extendedData.estimatedCost?.map(
          (cost: { coveredBy: Identificable }) => ({
            ...cost,
            coveredBy: cost.coveredBy?._id,
          })
        );
      item.extendedData.finalCost = item.extendedData.finalCost?.map(
        (cost: { coveredBy: Identificable }) => ({
          ...cost,
          coveredBy: cost.coveredBy?._id,
        })
      );
      item.process = item.process._id;

      item.events = item.events?.map(
        (event: { description: { type: 'mention'; user: any }[] }) => ({
          ...event,
          description:
            event.description?.map((descriptionPart) => {
              if (descriptionPart.type === 'mention') {
                descriptionPart.user = descriptionPart.user?._id;
              }
              return descriptionPart;
            }) || [],
        })
      );
    });

  await transaction
    .table('inspections')
    .toCollection()
    .modify((item: any) => {
      item.contracts = item.contracts?.map(
        (element: Identificable) => element._id
      );
      item.controlledParty = item.controlledParty?.map(
        (element: Identificable) => element._id
      );
      item.levels = item.levels?.map(
        (element: Identificable) => element._id
      );
      item.site = item.site?._id;
      item.workTypes = item.workTypes?.map(
        (element: Identificable) => element._id
      );
    });
};

export const version34Store = {
  issueForms: `&process`,
  issueFormsService: `++_id`,
};

export const version35Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction
    .table('issues')
    .toCollection()
    .modify((item: any) => {
      const hasTargetAreaData =
        Array.isArray(item.primaryData.targetArea) &&
        item.primaryData.targetArea.length > 0;

      item.primaryData.targetAreas = hasTargetAreaData
        ? [[...item.primaryData.targetArea]]
        : [];

      const hasFinalAreaData =
        Array.isArray(item.primaryData.finalArea) &&
        item.primaryData.finalArea.length > 0;

      item.primaryData.finalAreas = hasFinalAreaData
        ? [[...item.primaryData.finalArea]]
        : [];

      delete item.primaryData.targetArea;
      delete item.primaryData.finalArea;
    });

  await transaction
    .table('httpRequests')
    .toCollection()
    .modify((item: any) => {
      // we specificly do not use HttpRequestModelTypes in migations, it might change in future.
      if (item.entityType === 'issue') {
        if (
          item.data.primaryData.targetArea &&
          Array.isArray(item.data.primaryData.targetArea) &&
          item.data.primaryData.targetArea.length
        ) {
          item.data.primaryData.targetAreas = [
            [...item.data.primaryData.targetArea],
          ];
          delete item.data.primaryData.targetArea;
        }

        if (
          item.data.primaryData.finalArea &&
          Array.isArray(item.data.primaryData.finalArea) &&
          item.data.primaryData.finalArea.length
        ) {
          item.data.primaryData.finalAreas = [
            [...item.data.primaryData.finalArea],
          ];
          delete item.data.primaryData.finalArea;
        }
      }
    });
};

export const version38Upgrade = async (
  transaction: Transaction
): Promise<any> => {
  await transaction.table('chartFilters').clear();
};

export const version39Store = {
  organizations: `&_id, label`,
  organizationsService: `++_id`,
};
