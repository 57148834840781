export enum Services {
  CONFIG = 'config',
  ISSUES = 'issues',
  COMPANIES = 'companies',
  CONTRACTS = 'contracts',
  USERS = 'users',
  PERMISSIONS = 'permissions',
  ANALYTICS = 'analytics',
  LANGUAGE = 'language',
  CHART_FILTERS = 'chartFilters',
  PROJECTS = 'projects',
  ORGANIZATIONS = 'organizations',
  SELECTED_PROJECT = 'selectedProject',
  INSPECTION_TEMPLATES = 'inspectionTemplates',
  INSPECTIONS = 'inspections',
  DOCUMENTS = 'documents',
  DRAWINGS = 'drawings',
  SITES = 'sites',
  REPORTS = 'reports',
  WORKTYPES = 'worktypes',
  HAZARD_CATEGORIES = 'hazardCategories',
  FIELD_VALUES = 'fieldValues',
  ENVIRONMENTAL_ASPECTS = 'environmentalAspects',
  CORRECTIVE_ACTION_TYPES = 'correctiveActionTypes',
  LEVELS = 'levels',
  DOCUMENTATIONS = 'documentations',
  DIRECTORIES = 'directories',
  HTTP_QUEUE = 'httpQueue',
  ISSUE_FORMS = 'issueForms',
  VISIBLE_FIELDS = 'visibleFields',
}

export enum ServiceMethods {
  SET = 'set',
  SYNC = 'sync',
  //used where we don't support syncKey param - e.g. /user
  PULL = 'pull',
  LOGOUT = 'logout',
  CHANGE = 'change',
  GET_ONE = 'getOne',
  GET_LIST = 'getList',
  GET_BY_QUERY = 'getByQuery',
  CLEAR_PROJECT_DATA = 'clearProjectData',
  SERVICE_WORKER_CHECK = 'serviceWorkerCheck',
  CREATE = 'create',
  EDIT = 'edit',
  REMOVE = 'remove',
  RESTORE = 'restore',
  GET_SOURCES = 'getSources',
  SET_PARENT = 'setParent',
  ABORT_UPLOAD = 'abortUpload',
}
export enum DomainMessagesTypes {
  configSet = 'configSet',

  allIssues = 'allIssues',
  getIssue = 'getIssue',
  // TODO: allDeletedIssues are not used in react, why and should we remove some code?
  allDeletedIssues = 'allDeletedIssues',

  filteredIssues = 'filteredIssues',
  issue = 'issue',

  logout = 'logout',
  projectDataFlushed = 'projectDataFlushed',

  getState = 'getState',
  state = 'state',
  getFiltered = 'getFiltered',
  getFilteredIssueIdList = 'getFilteredIssueIdList',
  filteredIssueIdList = 'filteredIssueIdList',

  allCompanies = 'allCompanies',
  company = 'company',
  getAllCompanies = 'getAllCompanies',

  allContracts = 'allContracts',
  contract = 'contract',
  getAllContracts = 'getAllContracts',

  clearData = 'clearData',
  dataFlushed = 'dataFlushed',

  allUsers = 'allUsers',
  user = 'user',
  getAllUsers = 'getAllUsers',

  chartData = 'chartData',
  chartFiltersData = 'chartFiltersData',

  financialData = 'financialData',

  allProjects = 'allProjects',
  getAllProjects = 'getAllProjects',
  project = 'project',

  selectedProject = 'selectedProject',

  allOrganizations = 'allOrganizations',
  getAllOrganizations = 'getAllOrganizations',

  serviceWorkerCheck = 'serviceWorkerCheck',

  getInspectionTemplate = 'getInspectionTemplate',
  getAllInspectionTemplates = 'getAllInspectionTemplates',
  allInspectionTemplates = 'allInspectionTemplates',
  inspectionTemplate = 'inspectionTemplate',

  createdEntity = 'createdEntity',
  updatedEntity = 'updatedEntity',
  deletedEntity = 'deletedEntity',
  uploadedEntity = 'uploadedEntity',
  failUploadedEntity = 'failUploadedEntity',

  getInspection = 'getInspection',
  getAllInspections = 'getAllInspections',
  allInspections = 'allInspections',
  inspection = 'inspection',

  documents = 'documents',
  getDocument = 'getDocument',
  document = 'document',
  documentUrls = 'documentUrls',

  createdDrawing = 'createdDrawing',

  allSites = 'allSites',
  site = 'site',
  getAllSites = 'getAllSites',

  signedRequest = 'signedRequest',

  allWorktypes = 'allWorktypes',
  getAllWorktypes = 'getAllWorktypes',

  allHazardCategories = 'allHazardCategories',
  getAllHazardCategories = 'getAllHazardCategories',

  allEnvironmentalAspects = 'allEnvironmentalAspects',
  getAllEnvironmentalAspects = 'getAllEnvironmentalAspects',

  allCorrectiveActionTypes = 'allCorrectiveActionTypes',
  getAllCorrectiveActionTypes = 'getAllCorrectiveActionTypes',

  allLevels = 'allLevels',
  getAllLevels = 'getAllLevels',
  documentations = 'documentations',
  getSearchedDocumentations = 'getSearchedDocumentations',
  hasAllDocumentations = 'hasAllDocumentations',
  getDocumentation = 'getDocumentation',
  getDocumentations = 'getDocumentations',
  documentation = 'documentation',
  allDirectories = 'allDirectories',
  getDirectories = 'getDirectories',
  getAllDirectories = 'getAllDirectories',
  directories = 'directories',
  findRoots = 'findRoots',
  directoryRoots = 'directoryRoots',

  documentationMoved = 'documentationMoved',
  documentationDeleted = 'documentationDeleted',

  tableChanged = 'tableChanged',
  registerDbChangesListeners = 'registerDbChangesListeners',

  currentUserPermissions = 'currentUserPermissions',

  allIssueForm = 'allIssueForm',
  getAllIssueForm = 'getAllIssueForm',

  getAllVisibleFields = 'getAllVisibleFields',
  allVisibleFields = 'allVisibleFields',

  token = 'token',
  getToken = 'getToken',
}

export type Message<T = any> = {
  type: keyof typeof DomainMessagesTypes;
  data?: T;
  error?: any;
  uniqueId?: string;
};

export function isFailUploadedMessage(event: Message): boolean {
  return event.type === DomainMessagesTypes.failUploadedEntity;
}

export function isUploadedMessage(event: Message): boolean {
  return event.type === DomainMessagesTypes.uploadedEntity;
}

export function isUpdatedMessage(event: Message): boolean {
  return event.type === DomainMessagesTypes.updatedEntity;
}

export function isCreatedMessage(event: Message): boolean {
  return event.type === DomainMessagesTypes.createdEntity;
}

export function isCreateOrUpdate(event: Message): boolean {
  return (
    isCreatedMessage(event) ||
    event.type === DomainMessagesTypes.updatedEntity
  );
}

export function isUploadOrUpdate(event: Message): boolean {
  return (
    isUploadedMessage(event) ||
    event.type === DomainMessagesTypes.updatedEntity
  );
}

export function isDataChanged(event: Message): boolean {
  return (
    isUploadedMessage(event) ||
    event.type === DomainMessagesTypes.updatedEntity ||
    event.type === DomainMessagesTypes.deletedEntity
  );
}

export type ApiChannelMessage<T = any> = {
  service: Services;
  method: ServiceMethods;
  data?: T;
  uniqueId?: string;
};
