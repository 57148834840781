import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';

function isDrawingCreatedMessage(event: Message): boolean {
  return event?.type === DomainMessagesTypes.createdDrawing;
}

function listenToDrawingCreatedMessage(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean
): BroadcastChannel {
  const broadcast = new BroadcastChannel(ChannelNames.documentChannel);

  broadcast.onmessage = (event: Message): void => {
    if (!isDrawingCreatedMessage(event) && !messageFilter(event)) {
      return;
    }

    if (event.error) {
      return errorCallback(event.error);
    }

    if (event.data) {
      return successCallback(event.data);
    }
  };

  return broadcast;
}

export default listenToDrawingCreatedMessage;
