import {
  CreateContractCustomEvent,
  EditContractCustomEvent,
  GetContractCustomEvent,
} from 'shared/domain/messages/contract/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { ContractEntity } from 'serviceWorker/repository/contract/entity';
import { getAndBroadcastAllContracts } from 'serviceWorker/broadcasts/contract/all';
import { getAndBroadcastOneContract } from 'serviceWorker/broadcasts/contract/one';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as contracts from 'serviceWorker/db/contracts';
import * as contractsService from 'serviceWorker/db/contractsService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { awaitEntities } from '../factories/awaitEntities';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import { waitForMessage } from '../factories/waitForMessage';
import { contractSaver } from './addContract';
import { contractEditor } from './editContract';
import { fetchContracts, fetchUpdatedContracts } from './fetchContracts';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const waitForContracts = waitForMessage(
  ChannelNames.contractChannel,
  DomainMessagesTypes.allContracts
);

const pullContracts: Pull = makePullEntity({
  fetch: fetchContracts,
  channelName: ChannelNames.contractChannel,
  entityName: 'contracts',
  entityRepository: contracts,
  entityService: contractsService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullContractsHandler = makePullHandler(
  config,
  contracts,
  contractsService,
  pullContracts,
  clearBroadcasts
);

const synchronizeContracts = makeSynchronizeEntity({
  configRepository: config,
  entityService: contractsService,
  entityRepository: contracts,
  pullEntityHandler: pullContractsHandler,
  fetchUpdatedEntities: fetchUpdatedContracts,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'contracts',
  channelName: ChannelNames.contractChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export async function getContractsByIds(
  ids: string[]
): Promise<ContractEntity[]> {
  await awaitEntities(contractsService, waitForContracts);
  return contracts.getByIds(ids);
}

export async function getContractById(
  id: string
): Promise<ContractEntity | undefined> {
  await awaitEntities(contractsService, waitForContracts);
  return contracts.getOne(id);
}

export async function getContracts(): Promise<ContractEntity[]> {
  await awaitEntities(contractsService, waitForContracts);
  return contracts.get();
}

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeContracts.bind(null, getAndBroadcastAllContracts),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncContracts,
    syncDebounced
  );
  // @ts-ignore https://github.com/microsoft/TypeScript/issues/28357
  self.addEventListener(
    RepositoryMessagesTypes.getContract,
    function onGetContract(e: GetContractCustomEvent): void {
      synchronizeContracts(getAndBroadcastOneContract.bind(null, e));
    }
  );
  // @ts-ignore https://github.com/microsoft/TypeScript/issues/28357
  self.addEventListener(
    RepositoryMessagesTypes.addContract,
    function onAddContract(e: CreateContractCustomEvent): void {
      debugLog('addContract event', e);
      contractSaver.execute(
        e.detail.contractCreateModel,
        e.detail.uniqueId
      );
    }
  );

  // @ts-ignore https://github.com/microsoft/TypeScript/issues/28357
  self.addEventListener(
    RepositoryMessagesTypes.editContract,
    async function onEditContract(
      e: EditContractCustomEvent
    ): Promise<void> {
      debugLog('editContract event', e);
      contractEditor.execute(
        e.detail.contractEditModel,
        e.detail.uniqueId
      );
    }
  );
};
