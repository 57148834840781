import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  UserCreateCustomEvent,
  UserDeleteCustomEvent,
  UserEditCustomEvent,
} from 'shared/domain/messages/user/eventMessages';
import { UserInDto } from 'shared/dtos/in/user';
import { UserEntity } from 'shared/domain/user/types/entity';
import { ConfigData, GetUserCustomEvent } from 'serviceWorker/api/types';
import { broadcastAllUsers } from 'serviceWorker/broadcasts/user/all';
import { getAndBroadcastUser } from 'serviceWorker/broadcasts/user/one';
import { ChannelNames } from 'shared/domain/channelNames';
import * as users from 'serviceWorker/db/users';
import * as usersService from 'serviceWorker/db/usersService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import { getFetchConfig } from '../config';
import { awaitEntities } from '../factories/awaitEntities';
import { Pull, ServiceDataShape } from '../factories/types';
import { waitForMessage } from '../factories/waitForMessage';
import { userSaver } from './addUser';
import { userDeleter } from './deleteUser';
import { userEditor } from './editUser';
import { fetchUsers } from './fetchUsers';

const waitForUsers = waitForMessage(
  ChannelNames.userChannel,
  DomainMessagesTypes.allUsers
);

function createFinishedUserServiceData(
  totalCount: number
): ServiceDataShape {
  return {
    total: totalCount,
    isDownloading: false,
    isDownloaded: true,
  };
}

const pullUsers: Pull = async (data, abortController): Promise<void> => {
  fetchUsers(data, abortController)
    ?.then(async (response: UserInDto[]) => {
      if (abortController.signal.aborted) {
        return;
      }
      await usersService.add({
        total: response.length,
        isDownloading: true,
      });

      const broadcast = new BroadcastChannel(ChannelNames.userChannel);
      broadcast.postMessage({
        data: { isDownloading: true },
        type: DomainMessagesTypes.state,
      });

      if (abortController.signal.aborted) {
        return;
      }
      await users.addBatch(response as UserEntity[]);

      const userServiceData = createFinishedUserServiceData(
        response.length
      );
      await usersService.add(userServiceData);
      broadcastAllUsers(broadcast);
    })
    .catch(async (e: any) => {
      swLog(
        'Problem occured when fetching users',
        LogLevel.ERROR,
        e,
        null
      );
      await users.clear();
      await usersService.add({
        total: undefined,
        isDownloading: false,
        isDownloaded: false,
        syncKey: undefined,
      });
    });
};

const pullUsersHandler = async (): Promise<void> => {
  const abortController = new AbortController();

  self.addEventListener(DomainMessagesTypes.logout, () => {
    abortController.abort();
  });
  const setup = await getFetchConfig();
  const status = await usersService.get();
  const required: (keyof ConfigData)[] = [
    'frontendVersion',
    'api',
    'projectId',
  ];
  const hasRequired =
    required.map((elem) => setup && setup[elem]).filter((e) => !e)
      .length === 0;

  const shouldPull = hasRequired && !status?.isDownloading;

  const shouldReset = status?.shouldReset;
  if (!setup) {
    return;
  }

  if (shouldPull || shouldReset) {
    // clears shouldReset && cleans old users
    await usersService.reset();
    await usersService.add({ isDownloading: true });
    pullUsers(setup, abortController);
  }
};

export async function getUsersByIds(ids: string[]): Promise<UserEntity[]> {
  await awaitEntities(usersService, waitForUsers);
  return users.getByIds(ids);
}

export async function getUsers(): Promise<UserEntity[]> {
  await awaitEntities(usersService, waitForUsers);
  return users.get();
}

export async function getUserById(
  id: string
): Promise<UserEntity | undefined> {
  await awaitEntities(usersService, waitForUsers);
  return users.getOne(id);
}

export const init = (): void => {
  const debounced = debounce(pullUsersHandler, 250);

  self.addEventListener(DomainMessagesTypes.getAllUsers, debounced);

  //@ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getUser,
    function onGetUser(e: GetUserCustomEvent): void {
      debugLog('onGetUser', e);
      getAndBroadcastUser(e);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addUser,
    function onAddUser(e: UserCreateCustomEvent): void {
      debugLog('addUser event', e);
      userSaver.execute(e.detail.userCreateModel, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editUser,
    async function onEditLevel(e: UserEditCustomEvent): Promise<void> {
      debugLog('editUser event', e);
      userEditor.execute(e.detail.userEditModel, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteUser,
    async function onDeleteUser(e: UserDeleteCustomEvent): Promise<void> {
      debugLog('deleteUser event', e);
      userDeleter.execute(e.detail.userDeleteModel, e.detail.uniqueId);
    }
  );
};
