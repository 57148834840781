import { useOrganizations } from 'components/dataProviders/withOrganizations';
import { useProjects } from 'components/dataProviders/withProjects';
import { getClientAdminPermission } from 'components/project/projectForm/getClientAdminPermission';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { currentUserSelector } from 'redux/selectors/users';
import { ChannelNames } from 'shared/domain/channelNames';
import { getCreatedMessageListener } from 'shared/domain/commonModel';
import { projectCreateOnViewToProjectCreateModel } from 'shared/domain/project/mapping/toModel';
import { selectProject } from 'shared/domain/project/selectProject';
import { startProjectCreate } from 'shared/domain/project/startProjectCreate';
import { ProjectModel } from 'shared/domain/project/types/model';
import { ProjectCreateOnView } from 'shared/domain/project/types/view';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { CreateProjectContext } from '../types';
import {
  handleProjectCreateFormValidation,
  validateProjectCreate,
} from '../validation';
import { createEmptyProject } from './createEmptyProject';

export const formName = 'project';
const WithCreateProjectContext = createContext<
  CreateProjectContext | undefined
>(undefined);

function WithCreateProject({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const history = useHistory();
  const { fetchProjects } = useProjects();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { organizationId } = useSelector(projectDataSelector);
  const { organizationsMap, loading: organizationsLoading } =
    useOrganizations();
  const currentOrg = useMemo(() => {
    if (!organizationsLoading) {
      return organizationId
        ? organizationsMap.get(organizationId)
        : undefined;
    }
  }, [organizationId, organizationsLoading, organizationsMap]);

  const {
    data: { basePermissions },
  } = useSelector(currentUserSelector);

  const adminPermissionsMap = useMemo(() => {
    return getClientAdminPermission(basePermissions, organizationsMap);
  }, [basePermissions, organizationsMap]);
  const canCreateOnCurrentOrg = useMemo(
    () =>
      currentOrg &&
      adminPermissionsMap &&
      adminPermissionsMap.has(organizationId),
    [adminPermissionsMap, currentOrg, organizationId]
  );
  const initialOrganization = useMemo(
    () =>
      canCreateOnCurrentOrg
        ? currentOrg
        : adminPermissionsMap?.keys().next().value &&
          organizationsMap.get(adminPermissionsMap?.keys().next().value),
    [
      organizationsMap,
      adminPermissionsMap,
      canCreateOnCurrentOrg,
      currentOrg,
    ]
  );

  const initialValues: ProjectCreateOnView | undefined =
    initialOrganization && createEmptyProject(intl, initialOrganization);

  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const confirmCancel = useCancelConfirmation(formName);
  const closeDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);
  const [isPosting, setIsPosting] = useState(false);

  const submitForm = useCallback(
    async (values: ProjectCreateOnView) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const projectCreateModel =
        projectCreateOnViewToProjectCreateModel(values);

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getCreatedMessageListener(
          (message: ProjectModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchProjects();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.projectChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateProject: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startProjectCreate(projectCreateModel, uniqueId);
      })
        .then((project: unknown): any => {
          if (project && (project as Identificable)._id) {
            selectProject(project as Identificable);
            history.push('/projectSettings?target=generalProjectData');
            return project;
          }
        })
        .catch(() => {});
    },
    [dispatch, fetchProjects, history]
  );
  const SUBMIT_EVENT_TYPE = 'submit-project';
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const ctx: CreateProjectContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateProjectCreate,
      handleFormValidation: handleProjectCreateFormValidation,
      formName,
    };
  }, [
    closeDialog,
    initialValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
  ]);

  return (
    <WithCreateProjectContext.Provider value={ctx}>
      {children}
    </WithCreateProjectContext.Provider>
  );
}

function useCreateProject(): CreateProjectContext {
  const context = useContext(WithCreateProjectContext);
  if (context === undefined) {
    throw new Error(
      'useCreateProject must be used within an CreateProjectContext'
    );
  }
  return context;
}

const withCreateProject =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateProject>
      <Component {...props} />
    </WithCreateProject>
  );

export { WithCreateProject, useCreateProject, withCreateProject };
