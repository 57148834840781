import { createStyles, makeStyles } from '@mui/styles';
import { ReactElement } from 'react';

export const useStyles = makeStyles(() =>
  createStyles({
    circledNumberRoot: (props: { color: string }) => ({
      border: `2px solid ${props.color}`,
      borderRadius: '50%',
      fontSize: '0.75rem',
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Archivo',
      fontWeight: 700,
      boxSizing: 'border-box',
    }),
  })
);

export function CircledNumber({
  no,
  color,
}: {
  no: string;
  color: string;
}): ReactElement {
  const classes = useStyles({ color });
  return (
    <div className={classes.circledNumberRoot}>
      <span>{no}</span>
    </div>
  );
}
