import Autocomplete, {
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteInputChangeReason,
  AutocompleteRenderInputParams,
} from '@mui/material/Autocomplete';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEventHandler,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
} from 'react';
import { SimpleEmailInputProps } from './types';
import cn from 'classnames';
import { useStyles } from './styles';
import { FilterOptionsState } from '@mui/material/useAutocomplete';
import { AutocompleteGetTagProps } from '@mui/material';
import { isOptionEqualToValue } from './common';

type AutocompleteInputWithTags<T> = {
  onKeyCapture: KeyboardEventHandler<HTMLDivElement> | undefined;
  onInputChange: (
    event: ChangeEvent<{}>,
    value: string,
    reason: AutocompleteInputChangeReason
  ) => void | undefined;
  onValueChange: (
    event: ChangeEvent<{}>,
    value: any[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<any> | undefined
  ) => void | undefined;
  renderTags: (
    value: T[],
    getTagProps: AutocompleteGetTagProps
  ) => ReactNode;
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  getOptionLabel: (option: T) => string;
  availableOptions: T[];
  values: T[];
  inputValue: string;
  filterOptions:
    | ((options: any[], state: FilterOptionsState<any>) => any[])
    | undefined;
};

type EmailValue = {
  email: string;
  label: string;
};

export function isEmailOptionEqualToValue(
  option: string | EmailValue,
  value: EmailValue
): boolean {
  if (typeof option === 'string' || typeof value === 'string') {
    return false;
  }
  if (option?.email === value?.email) {
    return true;
  }
  return false;
}

function _AutocompleteWithTags(
  props: SimpleEmailInputProps<any> & AutocompleteInputWithTags<any>,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const {
    onKeyCapture,
    onInputChange,
    value,
    inputValue,
    onValueChange,
    renderTags,
    renderInput,
    getOptionLabel,
    availableOptions,
    isHalf,
    required,
    error,
    PopperComponent,
    filterOptions,
  } = props;
  const classes = useStyles();
  const input = useCallback(
    // react 18 types
    // @ts-ignore
    (params) => {
      return renderInput({ ...params, required, formerror: error });
    },
    [required, renderInput, error]
  );

  return (
    <div ref={ref} className={cn(classes.root, isHalf && 'halfWidth')}>
      <Autocomplete
        multiple={true}
        id='email-input'
        freeSolo
        data-qa={'autocomplete-with-tags'}
        filterSelectedOptions
        autoSelect
        options={availableOptions}
        // @ts-ignore TODO MUI5
        value={value || []}
        inputValue={inputValue}
        isOptionEqualToValue={isEmailOptionEqualToValue}
        renderTags={renderTags}
        renderInput={input}
        onInputChange={onInputChange}
        onChange={onValueChange}
        onKeyDownCapture={onKeyCapture}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        PopperComponent={PopperComponent}
      />
    </div>
  );
}

export const AutocompleteWithTags: any = forwardRef(_AutocompleteWithTags);
