import { AutocompleteRenderInputParams, Box } from '@mui/material';
import { Disabled } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper/types';
import { ErrorPresentation } from 'helpers/validators';
import React, { Ref, forwardRef, memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { FieldSkeleton } from 'shared/types/form';
import {
  getFreeSoloProps,
  getAutocompleteDisplayedHelperText as getSelectDisplayedHelperText,
  unifiedGetOptionLabel,
} from '../common';
import { MemoMultiAutocomplete } from './multiAutocomplete';
import { MemoMultiSelect } from './multiSelect';
import { FormValuesGetter } from '../types';

type MultiChoiceProps = {
  available: LabelledEntity[];
  defaultValue: LabelledEntity[];
  labelId: string;
  localisedLabel: string;
  required: boolean;
  disabled?: Disabled;
  handleChange: (_: any, value: LabelledEntity[]) => void;
  error?: string;
  value: LabelledEntity[] | null;
  id: string;
  disableClearable?: Boolean;
  hidden?: boolean;
  field?: FieldSkeleton;
  renderInput?: (
    params: AutocompleteRenderInputParams
  ) => React.ReactElement;
  renderOption?: (
    props: object,
    option: LabelledEntity
  ) => React.ReactElement;
  entityName?: string;
  helperText?: string;
  reserveSpaceForHelperText: boolean;
  dense: boolean;
  formValuesGetter?: FormValuesGetter;
};

const MultiChoice = (
  {
    available,
    value,
    handleChange,
    disabled,
    id,
    required,
    labelId,
    localisedLabel,
    error,
    disableClearable,
    entityName,
    helperText,
    dense,
    reserveSpaceForHelperText,
    formValuesGetter,
  }: MultiChoiceProps,
  ref: Ref<HTMLDivElement>
): React.ReactElement => {
  const intl = useIntl();
  const multiChoiceProps = useMemo(
    () => ({
      available,
      disabled: Boolean(disabled),
      value: value || null,
      id: `${id || labelId}-multiChoice`,
      localisedLabel: `${
        localisedLabel || (labelId && intl.formatMessage({ id: labelId }))
      }`,
      error,
      ref,
      required: !!required,
      handleChange,
      helperText: getSelectDisplayedHelperText(intl, helperText, error),
      getOptionLabel: unifiedGetOptionLabel,
      disableClearable: !!disableClearable,
      dense,
      reserveSpaceForHelperText,
    }),
    [
      available,
      disabled,
      value,
      id,
      error,
      ref,
      required,
      handleChange,
      disableClearable,
      dense,
      reserveSpaceForHelperText,
      labelId,
      intl,
      helperText,
      localisedLabel,
    ]
  );

  if (entityName) {
    const freeSoloProps = {
      entityName,
      formValuesGetter,
      ...getFreeSoloProps(entityName),
    };
    if (freeSoloProps && freeSoloProps.Provider) {
      return (
        <freeSoloProps.Provider>
          <MemoMultiAutocomplete
            {...multiChoiceProps}
            {...freeSoloProps}
          />
        </freeSoloProps.Provider>
      );
    }
  }

  if (available.length > 5) {
    return <MemoMultiAutocomplete {...multiChoiceProps} />;
  }
  return (
    <MemoMultiSelect
      {...multiChoiceProps}
      labelId={labelId}
      id={`${multiChoiceProps.id}-select`}
    />
  );
};

const ForwardedMultiChoice = forwardRef<HTMLDivElement, MultiChoiceProps>(
  MultiChoice
) as React.ForwardRefExoticComponent<MultiChoiceProps>;

export const MemoMultiChoice = memo(ForwardedMultiChoice);
