import { CSSProperties, PropsWithChildren } from 'react';

function outerContainerStyles(height?: number): CSSProperties {
  return {
    display: 'flex',
    overflowY: 'hidden',
  };
}

const innerContainerStyles: CSSProperties = {
  overflowY: 'auto',
  width: '100%',
};

type ViewWrapperProps = PropsWithChildren<{ height?: number }>;

const ViewWrapper = (props: ViewWrapperProps): JSX.Element => {
  return (
    <div style={outerContainerStyles(props.height)}>
      <div style={innerContainerStyles}>{props.children}</div>
    </div>
  );
};

export default ViewWrapper;
