import { IntlShape } from 'react-intl';
import { StageInternalNames } from 'shared/types/stages';

type Stage = {
  _id: string;
  label: string;
};

const stages = ({ formatMessage }: IntlShape): Stage[] => [
  {
    _id: StageInternalNames.draft,
    label: formatMessage({ id: 'general_stage_draft' }),
  },
  {
    _id: StageInternalNames.consultation,
    label: formatMessage({ id: 'general_stage_consultation' }),
  },
  {
    _id: StageInternalNames.execution,
    label: formatMessage({ id: 'general_stage_execution' }),
  },
  {
    _id: StageInternalNames.resolved,
    label: formatMessage({ id: 'general_stage_resolved' }),
  },
];

export const getStages = (
  intl: IntlShape
): { _id: string; label: string }[] => {
  return stages(intl);
};
