import React, { useCallback, useRef, useState } from 'react';
import styles from './styles';
import {
  ContextType,
  DrawingType,
} from 'components/common/graphicUploader/types';
import Draw from './draw';
import useIsMobile from '../../../hooks/useIsMobile';
import { useGalleryContext } from '.';
import { identitySelector } from './selectors';

const zeroOpacity = { opacity: 0 };
type DrawingDisplayProps = {
  drawMode: boolean;
  setDrawMode: (bool: boolean) => void;
  drawing: DrawingType;
  addDrawing: ContextType['addDrawing'];
  idx: number;
};
const DrawingDisplay: React.FC<DrawingDisplayProps> = (props) => {
  const { setDrawMode, drawing, addDrawing, idx } = props;
  const [file] =
    useGalleryContext().useMainDisplayedFile(identitySelector);
  const isMobile = useIsMobile();
  const cs = styles({ isMobile });
  const ref = useRef(null);
  const [container, setContainer] = useState<any>(null);
  const updateContainer = useCallback(() => {
    setContainer(ref.current);
  }, []);

  if (!file) return null;

  return (
    <>
      <img
        ref={ref}
        key={file.title}
        className={cs.drawingDisplay}
        src={drawing?.mergedImageUrl || file.downloadSrc}
        style={zeroOpacity}
        alt={file.description || file.title}
        onLoad={updateContainer}
      />
      {container && (
        <Draw
          container={container}
          file={file}
          setDrawMode={setDrawMode}
          addDrawing={addDrawing}
          idx={idx}
          drawing={drawing}
        />
      )}
    </>
  );
};

export default DrawingDisplay;
