import { PromiseExtended } from 'dexie';
import { ProjectEntity } from 'shared/domain/project/types/entity';
import { Changes } from 'shared/types/commonEntities';
import { LogLevel } from 'shared/types/logger';
import {
  makeDefaultAddBatch,
  makeDefaultClear,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import db from './index';
import { wrapQuery } from './queryWrapper';

export const clear = makeDefaultClear(db, db.projects);

export const getOne = makeDefaultGetOne<string, ProjectEntity>(
  db,
  db.projects,
  '_id'
);

export const get = makeDefaultGetMany<ProjectEntity>(db, db.projects);

export const addBatch = wrapQuery(db, async (data: any): Promise<any> => {
  return db.transaction('rw', db.projects, async function () {
    await clear();
    return db?.projects?.bulkPut(data).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.addBatch, 'projects'),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  });
});

export const updateBatch = addBatch;

export const updateLocalBatch = makeDefaultAddBatch<ProjectEntity>(
  db,
  db.projects
);

export const updateOne = wrapQuery(
  db,
  (
    projectId: string,
    changes: Changes<ProjectEntity>
  ): PromiseExtended => {
    return db.projects.update(projectId, changes).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'projects'),
        errorObj: e,
        additionalInfo: { query: { data: { projectId, changes } } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<string, ProjectEntity>(
  db,
  db.projects,
  '_id'
);
