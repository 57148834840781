import { useRef, useCallback, useMemo, TouchEvent } from 'react';
import { isAboveThreshold } from './isAboveThreshold';

export function useTouchClickDetection(action: CallableFunction): {
  handleTouchDown: (e: TouchEvent) => void;
  handleTouchUp: (e: TouchEvent, actionArgs?: any) => void;
  handleTouchMove: (e: TouchEvent) => void;
} {
  const clickTreshold = 8;
  const stateRef = useRef({
    movedAboveTreshold: false,
    startLocation: { x: -1, y: -1 },
    pressed: false,
  });

  const reset = useCallback(() => {
    stateRef.current = {
      movedAboveTreshold: false,
      startLocation: { x: -1, y: -1 },
      pressed: false,
    };
  }, []);

  const handleTouchDown = useCallback((e: TouchEvent) => {
    const movedAboveTreshold =
      e.touches.length > 1 || stateRef.current.movedAboveTreshold;
    stateRef.current = {
      movedAboveTreshold: movedAboveTreshold,
      pressed: true,
      startLocation: { x: e.touches[0].clientX, y: e.touches[0].clientY },
    };
  }, []);

  const handleTouchUp = useCallback(
    (e: TouchEvent, actionArgs?: any) => {
      if (stateRef.current.movedAboveTreshold) {
        if (e.touches.length === 0) {
          reset();
        }
        return;
      }
      action(actionArgs ? actionArgs : e);
    },
    [action, reset]
  );

  const handleTouchMove = useCallback((e: TouchEvent) => {
    if (!stateRef.current.pressed) {
      return;
    }
    if (
      isAboveThreshold(
        clickTreshold,
        stateRef.current.startLocation.x,
        e.touches[0].clientX
      ) ||
      isAboveThreshold(
        clickTreshold,
        stateRef.current.startLocation.y,
        e.touches[0].clientY
      )
    ) {
      stateRef.current.movedAboveTreshold = true;
    }
  }, []);

  return useMemo(() => {
    return {
      handleTouchDown,
      handleTouchUp,
      handleTouchMove,
    };
  }, [handleTouchDown, handleTouchMove, handleTouchUp]);
}
