import { CONFIG_TYPE } from 'components/common/graphicUploader/types';
import { createInspectionTemplateGeneralNormDocumentsBaseUrl } from '../communicator/helpers';
import {
  ChecklistItemError,
  CreateInspectionTemplateErrors,
  FormChecklistItem,
} from './types';
import { summaryValidation } from './validation';
import { Identificable } from 'shared/types/commonView';
import { ErrorPresentation } from 'helpers/validators';

const SCROLL_BLOCK_OFFSET = 80;

export function canAddItem(checklist: FormChecklistItem[]): boolean {
  const lastItem = checklist.slice(-1)[0];
  return !summaryValidation(lastItem?.summary);
}

function findScrollableElement(node: Element | null): Element | null {
  if (node == null) {
    return null;
  }

  if (node.scrollHeight > node.clientHeight) {
    return node;
  } else {
    return findScrollableElement(node.parentNode as Element | null);
  }
}

function scrollToElement(element: Element | null): void {
  const scrollable = findScrollableElement(element);

  if (element && scrollable) {
    scrollable.scrollBy({
      top: element.getBoundingClientRect().top - SCROLL_BLOCK_OFFSET,
      behavior: 'smooth',
    });
  }
}

export const SUMMARY_DOM_ELEMENT_ID = 'summary';
export const PROCESSES_DOM_ELEMENT_ID = 'processes';
export const GENERAL_NORM_DOM_ELEMENT_ID = 'generalNorm';
export const CHECKLIST_PREFIX_DOM_ELEMENT_ID = 'checklist-';
export function scrollToFirstError(
  summaryError: ErrorPresentation,
  processError: ErrorPresentation,
  descriptionError: ErrorPresentation,
  checklistErrors: ChecklistItemError[]
): void {
  if (summaryError) {
    scrollToElement(document.querySelector(`#${SUMMARY_DOM_ELEMENT_ID}`));
  } else if (processError) {
    scrollToElement(
      document.querySelector(`#${PROCESSES_DOM_ELEMENT_ID}`)
    );
  } else if (descriptionError) {
    scrollToElement(
      document.querySelector(`#${GENERAL_NORM_DOM_ELEMENT_ID}`)
    );
  } else {
    const index = checklistErrors.findIndex(
      (checklistError) =>
        checklistError.summary || checklistError.description
    );
    scrollToElement(
      document.querySelector(`#${CHECKLIST_PREFIX_DOM_ELEMENT_ID}${index}`)
    );
  }
}

export function isEmptyChangeInObject(
  prev: CreateInspectionTemplateErrors,
  validationError: ErrorPresentation,
  key: 'summary' | 'description' | 'process'
): boolean {
  return (
    prev[key] === validationError ||
    prev[key]?.helperText?.id === validationError?.helperText?.id
  );
}

function isCreationView(url: string): boolean {
  return url.includes('create-template');
}

export function chooseTitle(url: string): { id: string } {
  return isCreationView(url)
    ? { id: 'inspection_template_wizard_create_template' }
    : { id: 'inspection_template_wizard_edit_template' };
}

export function copyChecklistItemValues(
  list: FormChecklistItem[]
): FormChecklistItem[] {
  return list.map((item) => {
    return {
      _id: item._id,
      uniqueKeyId: item.uniqueKeyId,
      summary: item.summary,
      description: item.description,
      errors: {
        summary: item.errors.summary,
        description: item.errors.description,
      },
      graphicUploader: item.graphicUploader,
    };
  });
}

export function checkIndentificableListEquality(
  prev: Identificable[],
  curr: Identificable[]
): boolean {
  if (prev.length !== curr.length) {
    return false;
  }
  return prev.every((prevProcess) =>
    curr.find((currProcess) => prevProcess._id === currProcess._id)
  );
}

const baseSettings = {
  baseUrl: '',
  dataQaPrefix: 'general-applied-standards-',
};
export function createGUSettings(id?: string): CONFIG_TYPE {
  return id
    ? {
        ...baseSettings,
        baseUrl: createInspectionTemplateGeneralNormDocumentsBaseUrl(id),
      }
    : baseSettings;
}
