import { ChannelListenerContextType } from 'components/broadcastChannelListeners/channelListener/channelListenerFactory';
import { Message } from 'shared/domain/messages/message';
import { useCallback } from 'react';
import { createEntityPromise } from './createEntityPromise';

type MakeUsePullAllProps = {
  useChannelListener: () => ChannelListenerContextType;
  pullFunction: () => void;
  isMatchingMessage: (message: Message) => boolean;
  entityName: string;
};

export function makeUsePullAll<T>({
  useChannelListener,
  pullFunction,
  isMatchingMessage,
  entityName,
}: MakeUsePullAllProps) {
  return function useGetAll(): {
    getAll: () => Promise<T>;
  } {
    const { subscribe } = useChannelListener();

    const getAll = useCallback(() => {
      return createEntityPromise<T>({
        subscribe,
        isMatchingMessage,
        postMessage: pullFunction,
        entityName,
      });
    }, [subscribe]);

    return {
      getAll: getAll,
    };
  };
}
