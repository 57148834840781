import { Tooltip } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';
import { Color } from 'shared/utils/colors';

export const drawingDataForXMarkInsidePin =
  'M12.4624 5.64746L9.65693 8.45289L6.8515 5.64746L5.32422 7.17474L8.12965 9.98017L5.32422 12.7856L6.8515 14.3129L9.65693 11.5075L12.4624 14.3129L13.9896 12.7856L11.1842 9.98017L13.9896 7.17474L12.4624 5.64746Z';

export const drawingDataForUnselectedPin =
  'M0 9.65699C0 4.31806 4.31806 0 9.65699 0C14.9959 0 19.314 4.31806 19.314 9.65699C19.314 16.8997 9.65699 27.5914 9.65699 27.5914C9.65699 27.5914 0 16.8997 0 9.65699Z';
export const whiteXMark = (
  <path d={drawingDataForXMarkInsidePin} fill='white' />
);

export function NonInteractivePinIcon({
  color,
}: {
  color: Color;
}): ReactElement {
  return (
    <g pointerEvents='none'>
      <path
        pointerEvents='all'
        fillRule='evenodd'
        clipRule='evenodd'
        d={drawingDataForUnselectedPin}
        fill={color}
      />
      {whiteXMark}
    </g>
  );
}
