export enum IssueFieldNames {
  amountPercentage = 'amountPercentage',
  assignee = 'assignee',
  circumstances = 'circumstances',
  completedAmount = 'completedAmount',
  completionDateDelay = 'completionDateDelay',
  contaminatedSoilScale = 'contaminatedSoilScale',
  contractNumbers = 'contractNumbers',
  costCode = 'costCode',
  createdBy = 'createdBy',
  creationDate = 'createdAt',
  daysOfInabilityToWork = 'daysOfInabilityToWork',
  decisionToImposeFine = 'decisionToImposeFine',
  description = 'description',
  detectionDate = 'detectionDate',
  documents = 'documents',
  effect = 'effect',
  environmentalAspect = 'environmentalAspect',
  estimatedCost = 'estimatedCost',
  executedByCompanies = 'executedByCompanies',
  executor = 'executor',
  expectedFine = 'expectedFine',
  finalCompletionDate = 'finalCompletionDate',
  finalCost = 'finalCost',
  finalStartDate = 'finalStartDate',
  hashtag = 'hashtag',
  hazardCategory = 'hazardCategory',
  impact = 'impact',
  imposedFine = 'imposedFine',
  issueNumber = 'issueNumber',
  level = 'level',
  modificationDate = 'modifiedAt',
  modifiedBy = 'modifiedBy',
  personUnableToWork = 'personUnableToWork',
  positionOnMap = 'positionOnMap',
  proposedCorrectiveAction = 'proposedCorrectiveAction',
  rootCauses = 'rootCauses',
  site = 'site',
  soilLeakageScale = 'soilLeakageScale',
  solutionMethod = 'solutionMethod',
  solutionProposal = 'solutionProposal',
  spilledSubstance = 'spilledSubstance',
  stage = 'stage',
  startDateDelay = 'startDateDelay',
  subcontractorRepresentative = 'subcontractorRepresentative',
  subcontractors = 'subcontractors',
  targetAmount = 'targetAmount',
  targetCompletionDate = 'targetCompletionDate',
  targetStartDate = 'targetStartDate',
  title = 'title',
  waterLeakageScale = 'waterLeakageScale',
  workTypes = 'workTypes',
  numberOfEmployees = 'numberOfEmployees',
}
