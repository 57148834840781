import { useSizeScaledToImage } from '../area/common';
export const pinTransition =
  'width 0.2s ease-in-out, height 0.2s ease-in-out';
export const selectedPinViewBox = '0 0 31 44';
export const unselectedPinViewBox = '0 0 19.5 28';
const basePinWidth = 500;
const heightToWidth = 1.4;
const selectedToStandardPinRatio = 1.5;

export function useScaledPinWidth({
  isSelected,
  enlarged,
}: {
  isSelected: boolean;
  enlarged: boolean;
}): number {
  let result = useSizeScaledToImage(basePinWidth);
  if (isSelected) {
    result = result * selectedToStandardPinRatio;
  }
  if (enlarged) {
    result = result * 1.5;
  }
  return result;
}

export function getScaledPinHeight(
  width: number,
  isSelected: boolean
): number {
  return isSelected
    ? width * selectedToStandardPinRatio
    : width * heightToWidth;
}
export function getViewBox(isSelected: boolean): string {
  return isSelected ? '0 0 31 44' : '0 0 19.5 28';
}
