import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../services/auth0/react-auth0.spa';
import { NavigationDestination } from '../helpers/navigation';
import { useCallback } from 'react';
import { debugLog } from 'shared/logger/debugLog';

export function useRedirectToBrowseInspectionTemplates(): () => void {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  return useCallback((): void => {
    redirectTo(
      NavigationDestination.browseInspectionTemplates,
      history,
      isAuthenticated
    );
  }, [history, isAuthenticated]);
}

export function useRedirectToIssueTable(): () => void {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  return (): void => {
    redirectTo(NavigationDestination.issues, history, isAuthenticated);
  };
}

export function useRedirect(): (path: string) => void {
  const history = useHistory();
  const { isAuthenticated } = useAuth0();
  return (path: string): void => {
    if (!isAuthenticated) {
      debugLog('useRedirect: user is not authenticated ', isAuthenticated);
      return history.push('/login');
    }
    return history.push(path);
  };
}

function redirectTo(
  navigationDestination: NavigationDestination,
  history: any,
  isAuthenticated?: boolean
): void {
  if (!isAuthenticated) {
    debugLog('redirectTo: user is not authenticated ', isAuthenticated);
    return history.push('/login');
  }
  switch (navigationDestination) {
    case NavigationDestination.issues:
      return history.push(`/issue`);
    case NavigationDestination.browseInspectionTemplates:
      return history.push(`/inspectionTemplate`);
    case NavigationDestination.browseInspections:
      return history.push(`/inspection`);
    default:
      return history.push(`/${navigationDestination}`);
  }
}
