import { SiteInDto } from 'shared/dtos/in/site';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { flushTableWithService } from 'serviceWorker/db/flush';
import { LogLevel } from 'shared/types/logger';
import { INVALID_SYNCKEY } from 'shared/contants';
import { invalidSyncKeyErrorMsg, logWithRethrow } from '../../db/helpers';
import {
  defaultConfig,
  generateHeaders,
  makeNormalizeResponse,
} from '../../helpers/httpRequests';
import {
  HeadersData,
  NormalizedEntityResponseData,
} from '../factories/types';
import { pull } from '../pull';

const parseResponse = makeNormalizeResponse<SiteInDto>('sites');

export const fetchSites = (
  data: HeadersData,
  controller: AbortController
): Promise<NormalizedEntityResponseData<SiteInDto>> | undefined => {
  const { signal } = controller;
  if (shouldSkip(data, signal)) {
    return;
  }
  const builtUrl = `${data.api}/v2/project/${data.projectId}/site`;

  return pull(builtUrl, {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
    signal,
  }).then(parseResponse);
};

export const fetchUpdatedSites = (
  data: HeadersData,
  syncKey: string,
  controller: AbortController
): Promise<NormalizedEntityResponseData<SiteInDto>> | undefined => {
  const { signal } = controller;
  if (!data.api || !data.projectId) {
    //not enough data, skip.
    return;
  }
  const builtUrl = `${data.api}/v2/project/${
    data.projectId
  }/site?syncKey=${encodeURIComponent(syncKey)}`;

  return pull(builtUrl, {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
    signal,
  })
    .then(async (response: Response) => {
      if (response.status === INVALID_SYNCKEY) {
        await flushTableWithService('sites');
        self.dispatchEvent(new Event(RepositoryMessagesTypes.configSet));
        const msg = invalidSyncKeyErrorMsg('sites');
        return logWithRethrow({
          msg: msg,
          errorObj: null,
          logLevel: LogLevel.INFO,
          additionalInfo: null,
        });
      }
      return response;
    })
    .then(parseResponse);
};

function shouldSkip(data: HeadersData, signal: AbortSignal): boolean {
  return !data.api || !data.projectId || signal.aborted;
}
