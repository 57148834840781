import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
const iconStylesCommon = {
  position: 'absolute' as 'absolute',
  right: 8,
  fontSize: '1.5rem',
  color: '#757575',
  cursor: 'pointer',
};

const height = 42;

export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  (theme) => ({
    searchButton: {
      padding: '10px',
      position: 'relative',
    },
    searchWrap: {
      width: 0,
      flexShrink: 1,
      flexGrow: 1,
      height: `${height}px`,
      position: 'relative' as 'relative',
      [theme.breakpoints.down(612)]: {
        marginLeft: 0,
      },
      maxWidth: '250px',
      display: 'flex',
    },
    searchInputWrap: {
      display: 'flex',
      width: 0,
      flexShrink: 1,
      flexGrow: 1,
    },
    searchIcon: {
      ...iconStylesCommon,
      top: 'calc(50% + 1px)',
      transform: 'translateY(-50%)',
    },
    clearIcon: {
      ...iconStylesCommon,
      top: '50%',
      transform: 'translateY(-50%)',
      '&:hover': {
        color: '#000000',
      },
    },
    searchInput: {
      fontSize: '1rem',
      lineHeight: '1.1rem',
      height: `${height}px`,
      width: 0,
      flexShrink: 1,
      flexGrow: 1,
      boxSizing: 'border-box',
      border: '1px solid #DADADA',
      borderRadius: 3,
      padding: '10px 32px 10px 10px',
      outline: 'none',
      '&:hover': {
        border: '1px solid #979797',
      },
      '&:active, &:focus': {
        border: `2px solid ${theme.palette.primary.main}`,
        padding: '8px 32px 8px 8px',
      },
      '&::placeholder': {
        color: '#979797',
      },
    },
  })
);
