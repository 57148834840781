import { projectIdSelector } from 'helpers/misc';
import { Store, useCreateStore } from 'hooks/createStore';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export function useDocumentationProgressActivityStore(
  issuesVisibleStore: Store<boolean>
): Store<boolean> {
  const projectId = useSelector(projectIdSelector);
  const initialActiveState = getInitialActiveState(projectId);
  const activityStore = useCreateStore(initialActiveState);

  useEffect(() => {
    const action = (): void => {
      const active = activityStore.get();
      if (active) issuesVisibleStore.set(active);
    };

    action();
    return activityStore.subscribe(action);
  }, [activityStore, projectId, issuesVisibleStore]);

  useEffect(() => {
    const action = (): void => {
      const issuesVisible = issuesVisibleStore.get();
      if (!issuesVisible) {
        activityStore.set(false);
      }
      if (issuesVisible) {
        const initialActiveState = getInitialActiveState(projectId);
        activityStore.set(initialActiveState);
      }
    };
    action();
    return issuesVisibleStore.subscribe(action);
  }, [activityStore, issuesVisibleStore, projectId]);

  return activityStore;
}

function getInitialActiveState(projectId: string): boolean {
  const savedState = localStorage.getItem(
    `documentation_progress_active_${projectId}`
  );
  return typeof savedState === 'string' ? Boolean(savedState) : true;
}
