import { ColumnShape, SortOrder } from 'react-base-table';
import React from 'react';
import { SORT_TYPE } from 'shared/filter/sort/types';

export type ColumnSortHandler<T> = (args: {
  column: ColumnShape<T>;
  key: React.Key;
  order: SortOrder;
}) => void;

export type ColumnValue = string | number | null;

type Accessor<T> = (source: T) => ColumnValue;

export type TableColumnProp<T> = ColumnShape<T> & {
  _id: string;
  accessor?: Accessor<T>;
  sortType?: SORT_TYPE;
  label: string;
  mergeGroup?: {
    _id: string;
    label: string;
  };
};

export type CellRenderer<T> = ColumnShape<T>['cellRenderer'];

export enum TableWidthStorageKey {
  company = `company_table_column_widths`,
  inspection = 'inspection_table_column_widths',
  user = 'user_table_column_widths',
  documentation = 'documentation_table_column_widths',
  contract = 'contract_table_column_widths',
  issue = 'issue_table_column_widths',
}
