import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { UserEntity } from 'shared/domain/user/types/entity';
import { SiteOnView } from 'shared/domain/site/types/view';
import { SiteEntity } from '../types/entity';
import { SiteModel, SiteCreateModel, SiteEditModel } from '../types/model';

export function siteOnViewToSiteModel(fields: SiteOnView): SiteModel {
  return {
    ...fields,
    createdAt: fields.createdAt.toISO(),
    modifiedAt: fields.modifiedAt.toISO(),
  };
}

export function siteOnViewToSiteCreateModel(
  fields: SiteOnView,
  projectId: string
): SiteCreateModel {
  return {
    ...fields,
    createdAt: fields.createdAt.toISO(),
    modifiedAt: fields.modifiedAt.toISO(),
    projectId,
  };
}

export function siteOnViewToSiteEditModel(
  fields: SiteOnView,
  projectId: string
): SiteEditModel {
  return {
    label: fields.label,
    code: fields.code,
    _id: fields._id,
    projectId,
  };
}

export function siteEntityToModel(
  site: SiteEntity,
  users: UserEntity[]
): SiteModel {
  return {
    _id: site._id,
    code: site.code,
    createdAt: site.createdAt,
    label: site.label,
    modifiedAt: site.modifiedAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === site.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === site.modifiedBy)
    ),
    deleted: site.deleted ? true : false,
  };
}
