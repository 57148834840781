import { createStyles, makeStyles } from '@mui/styles';
import { ReactElement } from 'react';

export const useStyles = makeStyles(() =>
  createStyles({
    progressTextRoot: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '0.875rem',
      fontFamily: 'Archivo',
    },
  })
);

export function ProgressText({
  done,
  max,
}: {
  done: number | undefined;
  max: number | undefined;
}): ReactElement {
  const classes = useStyles();
  if (done === undefined || max === undefined) {
    return (
      <div className={classes.progressTextRoot}>
        <span>-/-</span>
        <span>-</span>
      </div>
    );
  }

  const valueInPercent = Math.round((done * 100) / (max || 1));
  return (
    <div className={classes.progressTextRoot}>
      <span>
        {done}/{max}
      </span>
      <span>{Math.floor(valueInPercent)}%</span>
    </div>
  );
}
