import React, { useMemo } from 'react';
import { LabelledEntity } from 'shared/types/commonView';
import useIsMobile from '../../../hooks/useIsMobile';
import { MemoPreviewField } from '../../general/PreviewField';

import { ButtonContained, ButtonIcon } from 'components/general';
import { useIntl } from 'react-intl';
import { useCommonSingleEntityViewStyles } from '../../common/styles/useCommonSingleEntityViewStyles';

import { EditOutlined } from '@mui/icons-material';
import Divider from '@mui/material/Divider';

import { ShowConditionally } from 'components/common/ShowConditionally';
import { LabelledCardList } from 'components/common/labelledCard/list';
import { LabelledCard } from 'components/common/labelledCard/single';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { Deletable } from 'shared/types/commonView';

import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';
import { ReactComponent as RestoreIcon } from 'assets/icons/restore_v2.svg';
import { OpenCreateLevelDialog } from 'components/dataCreationForms/level/types';
import { OpenEditDialog } from 'components/dataCreationForms/types';
import { SiteOnView } from 'shared/domain/site/types/view';

function boolToNumber(value: Boolean): number {
  return value ? 1 : 0;
}

type SingleSiteProps = SiteOnView & {
  openCreateLevelDialog: OpenCreateLevelDialog;
  openEditLevelDialog: OpenEditDialog;
  deleteLevel: (level: LabelledEntity) => void;
  restoreLevel: (level: LabelledEntity) => void;
  canDeleteLevel: (levels: Deletable[]) => {
    disabled: boolean;
    reason?: string;
  };
};
export function SingleSite(props: SingleSiteProps): React.ReactElement {
  const {
    code,
    levels,
    label,
    _id: siteId,
    openCreateLevelDialog,
    openEditLevelDialog,
    deleteLevel,
    restoreLevel,
    canDeleteLevel,
  } = props;
  const isMobile = useIsMobile();
  const intl = useIntl();
  const classes = useCommonSingleEntityViewStyles({ isMobile });

  const cardListChildren = useMemo(() => {
    const { disabled, reason } = canDeleteLevel(levels);

    return levels
      .sort((a, b) => boolToNumber(a.deleted) - boolToNumber(b.deleted))
      .map((cardItem) => (
        <LabelledCard
          key={cardItem._id}
          id={cardItem._id}
          label={cardItem.label}
          deleted={cardItem.deleted}
          EndIcon={
            <>
              <ButtonIcon
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openEditLevelDialog({ _id: cardItem._id });
                }}
              >
                <EditOutlined />
              </ButtonIcon>
              <ShowConditionally isShown={!cardItem.deleted}>
                <ForbiddenTooltip visibleWhen={disabled} reason={reason}>
                  <ButtonIcon
                    onClick={(e: React.MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      deleteLevel(cardItem);
                    }}
                    disabled={disabled}
                  >
                    <ArchiveIcon />
                  </ButtonIcon>
                </ForbiddenTooltip>
              </ShowConditionally>
              <ShowConditionally isShown={cardItem.deleted}>
                <ButtonIcon
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    restoreLevel(cardItem);
                  }}
                >
                  <RestoreIcon />
                </ButtonIcon>
              </ShowConditionally>
            </>
          }
        />
      ));
  }, [
    levels,
    openEditLevelDialog,
    deleteLevel,
    canDeleteLevel,
    restoreLevel,
  ]);

  return (
    <div className={classes.root}>
      <h1>{intl.formatMessage({ id: 'primaryField_Site' })}</h1>
      <Divider />
      <div className='row'>
        <MemoPreviewField
          isHalf
          label={intl.formatMessage({
            id: 'site_preview_label',
          })}
          value={label}
          dense={true}
        />
      </div>
      <div className='row'>
        <MemoPreviewField
          isHalf
          label={intl.formatMessage({
            id: 'site_preview_code',
          })}
          value={code}
          dense={true}
        />
      </div>
      <Divider className='row' />
      <div className={classes.actionsContainer}>
        <h2 className='row'>
          {intl.formatMessage({
            id: 'site_preview_levels',
          })}
        </h2>
        <div>
          <ButtonContained
            onClick={() => openCreateLevelDialog({ siteId })}
          >
            {intl.formatMessage({
              id: 'create_level',
            })}
          </ButtonContained>
        </div>
      </div>
      <LabelledCardList>{cardListChildren}</LabelledCardList>
    </div>
  );
}
