import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isDefined } from 'shared/domain/role/isDefined';
import { isStandard } from 'shared/domain/role/isStandard';
import { UserInDto } from 'shared/dtos/in/user';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoInspection } from 'views/inspections/single';
import { MemoInspections } from 'views/inspections/table';
import { MemoInspectionWizard } from 'views/inspections/wizard';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';

export const AuthorizableInspection = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAuthorized = useMemo(() => {
      return isDefined(currentUser.role) && !isStandard(currentUser.role);
    }, [currentUser]);

    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAuthorized}
        unauthorized={isStandard(currentUser.role)}
        authorizedComponent={MemoInspection}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableInspectionWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAuthorized = useMemo(() => {
      return isDefined(currentUser.role) && !isStandard(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAuthorized}
        unauthorized={isStandard(currentUser.role)}
        authorizedComponent={MemoInspectionWizard}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableInspections = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);

    const isCurrentUserAuthorized = useMemo(() => {
      return isDefined(currentUser.role) && !isStandard(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isCurrentUserAuthorized}
        unauthorized={isStandard(currentUser.role)}
        authorizedComponent={MemoInspections}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
