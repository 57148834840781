import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  InspectionTemplatesContextType,
  InspectionTemplatesItems,
} from './types';
import { useSelector } from 'react-redux';
import { toUserRole } from 'redux/selectors/role';
import { useInspectionTemplateChannelListener } from '../../broadcastChannelListeners/withInspectionTemplateChannelListener';
import { useEntityDataSubscription } from '../../common/useEntityDataSubscription';
import { UserRole } from 'shared/types/userRole';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { useGetAllInspectionTemplates } from 'hooks/useGetAllInspectionTemplates';

const initialInspectionTemplates: InspectionTemplatesItems = {
  items: [],
  total: 0,
};

export const InspectionTemplatesContext = React.createContext<
  InspectionTemplatesContextType | undefined
>(undefined);

function getEmptyInspectionTemplates(): Promise<{
  items: InspectionTemplateModel[];
}> {
  return Promise.resolve({ items: [] });
}

const WithInspectionTemplates = ({
  children,
}: PropsWithChildren<{}>): ReactElement => {
  const subscribers = useRef(new Set<CallableFunction>());
  const currentUserRole = useSelector(toUserRole);
  const [inspectionTemplates, setInspectionTemplates] =
    useState<InspectionTemplatesItems>(initialInspectionTemplates);
  const [loading, setLoading] = useState<boolean>(true);

  const { subscribe } = useInspectionTemplateChannelListener();
  const { getAll: getAllInspectionTemplates } =
    useGetAllInspectionTemplates();

  const registerTableChangedCallback = useCallback((cb) => {
    subscribers.current.add(cb);

    return () => subscribers.current.delete(cb);
  }, []);

  const afterTableChange = useCallback(() => {
    subscribers.current.forEach((cb) => cb());
  }, []);

  const { resync } = useEntityDataSubscription({
    subscribe,
    getAll:
      currentUserRole && currentUserRole !== UserRole.standard
        ? getAllInspectionTemplates
        : getEmptyInspectionTemplates,
    setEntity: setInspectionTemplates,
    setLoading,
    afterTableChange,
    entityName: 'inspection templates',
  });

  const ctx = {
    inspectionTemplates: inspectionTemplates,
    loading: loading,
    registerTableChangedCallback,
    resync,
  };

  return (
    <InspectionTemplatesContext.Provider value={ctx}>
      {children}
    </InspectionTemplatesContext.Provider>
  );
};

const withInspectionTemplates =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithInspectionTemplates>
      <Component {...props} />
    </WithInspectionTemplates>
  );

function useInspectionTemplates(): InspectionTemplatesContextType {
  const context = React.useContext(InspectionTemplatesContext);
  if (context === undefined) {
    throw new Error(
      'useInspectionTemplates must be used within a InspectionTemplatesContextProvider'
    );
  }
  return context;
}

export {
  WithInspectionTemplates,
  withInspectionTemplates,
  useInspectionTemplates,
};
