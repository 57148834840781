import React, { Fragment, ReactElement, useMemo } from 'react';

import { MemoCardList } from 'components/template/CardList';
import { MemoSingleTemplate } from 'components/template/Single';
import { MemoTemplateListSkeleton } from './skeleton';

import { TemplateListPresentationalProps } from './types';
import { useStyles } from './styles';
import { MemoButtonPlusCreate } from 'components/general/ButtonPlusCreate';
import { MemoSearchInput } from 'components/common/SearchInput';
import useIsMobile from 'hooks/useIsMobile';

function TemplateListPresentational(
  props: TemplateListPresentationalProps
): ReactElement {
  const {
    templates,
    activeId,
    showList,
    handleClick,
    error,
    loaded,
    canCreateTemplate,
    createTemplate,
    canEditTemplate,
    editTemplate,
    canRemoveTemplate,
    removeTemplate,
    setCanScroll,
    searchChange,
    searchClear,
    searchPhrase,
    startInspection,
    canStartInspection,
  } = props;

  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });

  const activeTemplate = useMemo(
    () => templates.find((template) => template._id === activeId),
    [templates, activeId]
  );

  return loaded ? (
    <Fragment>
      <div className={classes.head}>
        <div className={classes.headLeft}>
          <MemoButtonPlusCreate
            id='create-inspection-template-button'
            textId='button_create_inspection_template'
            dataQaId='create-inspection-template-button'
            canCreate={canCreateTemplate}
            creationAction={createTemplate}
          />
          <MemoSearchInput
            value={searchPhrase}
            searchChange={searchChange}
            searchClear={searchClear}
            placeholderTextId='dashboard_search_placeholder'
            dataQa='inspection-template-search'
          />
        </div>
      </div>
      <div className={classes.content}>
        {(!isMobile || showList) && (
          <MemoCardList
            list={templates}
            activeId={activeId}
            handleClick={handleClick}
            setCanScroll={setCanScroll}
            error={error}
          />
        )}
        {(!isMobile || !showList) && (
          <MemoSingleTemplate
            template={activeTemplate}
            error={error}
            canEditTemplate={canEditTemplate}
            editTemplate={editTemplate}
            canRemoveTemplate={canRemoveTemplate}
            removeTemplate={removeTemplate}
            startInspection={startInspection}
            canStartInspection={canStartInspection}
          />
        )}
      </div>
    </Fragment>
  ) : (
    <MemoTemplateListSkeleton />
  );
}

export const MemoTemplateListPresentational = React.memo(
  TemplateListPresentational
);
