import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';
import { ApiChannelMessage } from 'shared/domain/messages/message';
import { SiteCreateModel } from './types/model';

export function startSiteCreate(
  site: SiteCreateModel,
  uniqueId: string
): void {
  debugLog('startSiteCreate', site, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      siteCreateModel: SiteCreateModel;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.SITES,
    method: ServiceMethods.CREATE,
    data: {
      siteCreateModel: site,
      uniqueId,
    },
  });
  broadcast.close();
}
