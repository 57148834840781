import { HashMap } from 'shared/types/commonView';

export function addToHashMap<T>(
  object: HashMap<T[]>,
  document: T,
  id: string
): void {
  if (object[id]) {
    object[id].push(document);
  } else {
    object[id] = [document];
  }
}
