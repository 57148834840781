import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { siteEntityToModel } from 'shared/domain/site/mapping/toModel';
import { SiteEntity } from 'shared/domain/site/types/entity';
import { SiteModel } from 'shared/domain/site/types/model';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { awaitEntities } from 'serviceWorker/services/factories/awaitEntities';
import { waitForMessage } from 'serviceWorker/services/factories/waitForMessage';
import {
  getCurrentUsers,
  getUsersSynchronized,
} from 'serviceWorker/services/users/getUsers';
import { debugLog } from 'shared/logger/debugLog';
import { GetSiteCustomEvent } from '../api/types';
import * as dbSites from '../db/sites';
import * as dbSitesService from '../db/sitesService';
import {
  makeBroadcastAll,
  makeBroadcastClear,
  makeSimpleBroadcastError,
} from './factories';
import { OnMessage } from './types';

const broadcastSiteError = makeSimpleBroadcastError(
  ChannelNames.siteChannel
);

export const broadcastClearSites = makeBroadcastClear(
  ChannelNames.siteChannel
);

export const broadcastAllSites = makeBroadcastAll<SiteModel[]>(
  dbSitesService,
  {
    get: async () => {
      const [sites, users] = await Promise.all([
        dbSites.get(),
        getUsersSynchronized(),
      ]);
      return sites.map((site) => siteEntityToModel(site, users));
    },
  },
  ChannelNames.siteChannel,
  DomainMessagesTypes.allSites
);

const waitForSites = waitForMessage(
  ChannelNames.siteChannel,
  DomainMessagesTypes.allSites
);

async function getSiteById(id: string): Promise<SiteEntity | undefined> {
  await awaitEntities(dbSitesService, waitForSites);
  return dbSites.getOne(id);
}

export const broadcastSite = async (
  e: GetSiteCustomEvent
): Promise<void> => {
  const broadcast = new BroadcastChannel(ChannelNames.siteChannel);
  try {
    const siteEntity = await getSiteById(e.detail.id);
    if (siteEntity) {
      const users = await getUsersSynchronized();
      const data = siteEntityToModel(siteEntity, users);
      broadcast.postMessage({
        data,
        type: DomainMessagesTypes.site,
      });
    } else {
      broadcast.postMessage({
        error: Error(`could not a find site with id ${e.detail.id}`),
        type: DomainMessagesTypes.site,
      });
    }
  } catch (e) {
    broadcast.postMessage({ error: e, type: DomainMessagesTypes.site });
  }
  broadcast.close();
};

export const broadcastUploadedSite = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.siteChannel
);

export const broadcastUpdatedSite = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.siteChannel
);

export const broadcastDeletedSite = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.siteChannel
);

export const broadcastUploadedSiteError = (error: any): void => {
  debugLog('broadcastUploadedSiteError', error);
  broadcastSiteError(DomainMessagesTypes.failUploadedEntity, error);
};

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.siteChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllSites: {
        await getAllCurrentSites(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentSites(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const [sites, users] = await Promise.all([
    dbSites.get(),
    getCurrentUsers(),
  ]);
  const end = Date.now();
  debugLog('getAllCurrentSites time:', end - start);
  const currentSites = sites.map((site) => siteEntityToModel(site, users));
  broadcast.postMessage({
    data: { items: currentSites },
    type: DomainMessagesTypes.allSites,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.SITES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
