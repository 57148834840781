import { DialogType } from 'components/core/Dialog/common/Dialog';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import listenToReportCreatedMessage from 'shared/domain/report/listenToReportCreatedMessage';
import { SingleInspectionReportModel } from 'shared/domain/report/singleInspectionReportModel';
import { startReportCreate } from 'shared/domain/report/startReportCreate';
import {
  LANGUAGE_LABELS,
  LOCALE_LANGUAGES,
  ISO6391LanguageCodeOption,
} from 'shared/types/locale';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import { LabelledEntity } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { CreateSingleInspectionReportDialog } from 'views/reports/wizard/dialog';
import { EmailValue } from '../../common/withEmailInput';
import { useInspection } from '../../dataProviders/withInspection';

export type SingleInspectionReportFields = {
  name: string;
  message: string;
  recipients: EmailValue[];
  localeCode: LabelledEntity;
  inspection: string;
};

type CreateSingleInspectionReportContext = {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  submitForm: (values: SingleInspectionReportFields) => Promise<any>;
  isPosting: boolean;
  initialValues: SingleInspectionReportFields;
  SUBMIT_EVENT_TYPE: string;
  releaseSubmitEvent: () => void;
  ContentSelection: () => ReactElement | null;
};

function toSingleInspectionReportModel({
  name,
  message,
  recipients,
  inspection,
  localeCode,
}: SingleInspectionReportFields): SingleInspectionReportModel {
  return {
    recipients: recipients.map((recipient) => recipient.email),
    name,
    message,
    inspection,
    localeCode: localeCode._id,
  };
}

const CreateSingleInspectionReportContext = createContext<
  CreateSingleInspectionReportContext | undefined
>(undefined);

const WithCreateSingleInspectionReport: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const dispatch = useDispatch();
  const createDialog = useDialog();
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const confirmCancel = useCancelConfirmation('report');
  const closeDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);
  const [isPosting, setIsPosting] = useState(false);
  const { inspection } = useInspection();
  const [inspectionId, setInspectionId] = useState(inspection?._id);

  useEffect(() => {
    setInspectionId(inspection?._id);
  }, [inspection]);

  const submitForm = useCallback(
    async (values) => {
      if (!inspectionId) return;
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const reportModel = toSingleInspectionReportModel({
        ...values,
        inspection: inspectionId,
      });

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = listenToReportCreatedMessage(
          (message: SingleInspectionReportModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            createDialog({
              catchOnCancel: false,
              title: <FormattedMessage id='dialog_report_sent_title' />,
              description: (
                <span>
                  <FormattedMessage id='dialog_report_sent_message' />
                </span>
              ),
              type: DialogType.info,
              customControllerLabels: ['general_ok', 'general_ok'],
            });
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          }
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(
            new Error(
              'WithCreateSingleInspectionReport: Timeout on upload.'
            )
          );
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startReportCreate(reportModel, uniqueId);
      }).catch(() => {});
    },
    [dispatch, createDialog, inspectionId]
  );

  const project = useSelector(projectDataSelector);
  const localeCode = project.localeCode.slice(
    0,
    2
  ) as ISO6391LanguageCodeOption;
  const initialValues: SingleInspectionReportFields = useMemo(() => {
    return {
      recipients: [],
      name: '',
      message: '',
      localeCode: {
        _id: LOCALE_LANGUAGES[localeCode],
        label: LANGUAGE_LABELS[localeCode],
      },
      inspection: '',
    };
  }, [localeCode]);

  const SUBMIT_EVENT_TYPE = 'submit-single-inspection-report';
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const ctx: CreateSingleInspectionReportContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      ContentSelection: () => null,
    };
  }, [
    open,
    openDialog,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
  ]);

  return (
    <CreateSingleInspectionReportContext.Provider value={ctx}>
      {children}
      <CreateSingleInspectionReportDialog />
    </CreateSingleInspectionReportContext.Provider>
  );
};

function useCreateSingleInspectionReport(): CreateSingleInspectionReportContext {
  const context = useContext(CreateSingleInspectionReportContext);
  if (context === undefined) {
    throw new Error(
      'useCreateSingleInspectionReport must be used within an CreateSingleInspectionReportContext'
    );
  }
  return context;
}

const withCreateSingleInspectionReport =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateSingleInspectionReport>
      <Component {...props} />
    </WithCreateSingleInspectionReport>
  );

export {
  useCreateSingleInspectionReport,
  withCreateSingleInspectionReport,
  WithCreateSingleInspectionReport,
};
