import { SmallChartCard } from 'charts/shared/SmallCard';
import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { MemoChartReloader } from '../chartReloader';
import { ChartRenderer } from '../ChartRenderer';
import { useChartsContext } from '../ChartsProvider';
import { Grid } from '@mui/material';
import { useStyles } from './styles';

type ChartCardTitleProps = { titleId: string };

function ChartCardTitle(props: ChartCardTitleProps): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  const { titleId } = props;
  return (
    <h2 className={classes.cardTitle}>
      {intl.formatMessage({ id: titleId })}
    </h2>
  );
}

function AnalyticsDashboard(): ReactElement {
  const charts = useChartsContext();
  const classes = useStyles();

  return (
    <div className={classes.dashboardContainer}>
      <Grid container spacing={3}>
        {Object.keys(charts).map((id) => (
          <MemoChartReloader key={id} context={charts[id]}>
            <Grid item xs={12} lg={6}>
              <SmallChartCard
                key={id}
                redirectUrl={`/analytics/${id}`}
                loading={charts[id].loading}
              >
                <ChartCardTitle titleId={charts[id].titleId} />
                <ChartRenderer id={id} />
              </SmallChartCard>
            </Grid>
          </MemoChartReloader>
        ))}
      </Grid>
    </div>
  );
}

export const MemoAnalyticsDashboard = React.memo(AnalyticsDashboard);
