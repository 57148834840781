import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    wrappedContent: {
      marginLeft: '16px',
      marginRight: '15px',
    },
    generalNormContainer: {
      fontFamily: 'Archivo',
      textAlign: 'left',
      '& h2': {
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '29px',
        letterSpacing: '0em',
        marginTop: '26px',
      },
    },
    graphicUploaderWrapper: {
      width: '100%',
      marginTop: '22px',
      marginBottom: '24px',
    },
  })
);
