import { SiteInDto } from 'shared/dtos/in/site';
import { SiteEntity } from '../types/entity';

export function siteInDtoToEntity(site: SiteInDto): SiteEntity {
  return {
    _id: site._id,
    code: site.code,
    createdAt: site.createdAt,
    createdBy: site.createdBy,
    label: site.label,
    modifiedAt: site.modifiedAt,
    modifiedBy: site.modifiedBy,
    deleted: site.deleted ? 1 : 0,
  };
}
