import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { projectEditModelToEditOutDto } from 'shared/domain/project/mapping/toDto';
import {
  ProjectEditUseCase,
  ProjectExecuteEditUseCaseParams,
} from 'shared/domain/project/types/useCase';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { ProjectHttpEditRequestModel } from '../httpQueue/project/types';

export class ProjectEditor implements ProjectEditUseCase {
  constructor(
    private addRequest: (
      request: ProjectHttpEditRequestModel
    ) => Promise<any>
  ) {}

  async execute({
    projectId,
    projectEditModel,
    uniqueId,
  }: ProjectExecuteEditUseCaseParams): Promise<void> {
    debugLog('EditProjectUseCase', projectEditModel);

    const projectEditOutDto =
      projectEditModelToEditOutDto(projectEditModel);
    const url = `/project/${projectEditModel._id}`;
    const request: ProjectHttpEditRequestModel = {
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        projectEditOutDto: projectEditOutDto,
        projectId: projectId,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.project,
      status: HttpRequestStatus.NEW,
      url,
    };

    await this.addRequest(request);
  }
}

export const projectEditor = new ProjectEditor(addRequest);
