import { UserModel } from 'shared/domain/user/userModel';
import { UserRole } from 'shared/types/userRole';

export function usersWithAccessSelector(
  roles: UserRole[],
  site: string,
  process: string,
  users: UserModel[]
): UserModel[] {
  return users.filter(
    (user) =>
      roles.includes(user.role) &&
      user.sites.includes(site) &&
      user.processes.includes(process)
  );
}
