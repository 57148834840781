import { DocumentModel } from 'shared/domain/document/documentModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  __HttpRequestModel__,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { AbortUploadCustomEvent } from 'shared/domain/messages/httpQueue/eventMessages';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { ISendDocumentUrlBuilder } from 'serviceWorker/repository/document/sendDocumentUrlBuilder';
import { UploadClearDrawing } from 'serviceWorker/repository/document/uploadClearDrawing';
import {
  IDocumentUploader,
  UploadDocument,
} from 'serviceWorker/repository/document/uploadDocument';
import { UploadDocumentChange } from 'serviceWorker/repository/document/uploadDocumentChange';
import { UploadDocumentDelete } from 'serviceWorker/repository/document/uploadDocumentDelete';
import { UploadDrawing } from 'serviceWorker/repository/document/uploadDrawing';
import { Services } from 'shared/domain/messages/message';
import { DocumentChanges } from '../documents/editDocument';
import { IRequestRunner } from './httpRequestRunner';

export class DocumentRequestRunner implements IRequestRunner {
  private abortController: AbortController | undefined;
  private request: __HttpRequestModel__ | undefined;

  constructor(
    private documentUploader: IDocumentUploader,
    private getDocument: (id: number) => Promise<DocumentModel>,
    private hardDelete: (id: number[]) => Promise<any>,
    private urlBuilder: ISendDocumentUrlBuilder,
    private uploadDocumentUseCase: UploadDocument,
    private uploadDocumentChangeUseCase: UploadDocumentChange,
    private uploadDocumentDeleteUseCase: UploadDocumentDelete,
    private updateDocument: (
      id: number,
      changes: DocumentChanges,
      options?: { skipModifyTime: boolean }
    ) => Promise<any>
  ) {
    // @ts-ignore Custom event.
    self.addEventListener(
      RepositoryMessagesTypes.abortUpload,
      (e: AbortUploadCustomEvent) => {
        if (!this.request || !this.abortController) {
          return;
        }

        const eventEntityType =
          e.detail.type === Services.DOCUMENTS
            ? HttpRequestModelType.document
            : HttpRequestModelType.documentation;
        if (
          this.request.method === 'POST' &&
          this.request.entityType === eventEntityType &&
          this.request.data.localId === e.detail.localId
        ) {
          this.abortController.abort();
        }
      }
    );
  }

  execute(request: __HttpRequestModel__): Promise<boolean> {
    const abortController = new AbortController();
    this.abortController = abortController;
    this.request = request;
    switch (request.method) {
      case 'POST': {
        return this.uploadDocument(request, abortController);
      }
      case 'PUT': {
        return this.uploadDocumentChange(request);
      }
      case 'DELETE': {
        return this.uploadDocumentDelete(request);
      }
      case 'GET': {
        throw new Error('Not implemented.');
      }
    }
  }

  private async uploadDocument(
    request: __HttpRequestModel__,
    abortController: AbortController
  ): Promise<boolean> {
    const document = await this.getDocument(request.data.localId);
    const url = await this.urlBuilder.execute(
      document,
      request.data.projectId
    );

    return this.uploadDocumentUseCase(
      abortController,
      this.documentUploader,
      document,
      url
    );
  }

  private async uploadDocumentChange(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const document = await this.getDocument(request.data.localId);
    const url = await this.urlBuilder.execute(
      document,
      request.data.projectId
    );

    return this.uploadDocumentChangeUseCase(
      this.documentUploader,
      url,
      request.data.changes
    );
  }

  private async uploadDocumentDelete(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const documentLocalId = request.data.localId;
    const document = await this.getDocument(documentLocalId);
    const url = await this.urlBuilder.execute(
      document,
      request.data.projectId
    );

    try {
      const result = await this.uploadDocumentDeleteUseCase(
        this.documentUploader,
        url
      );
      await this.hardDelete([documentLocalId]);
      return result;
    } catch (e) {
      this.updateDocument(
        documentLocalId,
        { deleted: false, syncStatus: SyncStatus.SUCCESS },
        { skipModifyTime: true }
      );
      return false;
    }
  }
}

export class DrawingRequestRunner implements IRequestRunner {
  constructor(
    private documentUploader: IDocumentUploader,
    private getDocument: (id: number) => Promise<DocumentModel>,
    private urlBuilder: ISendDocumentUrlBuilder,
    private uploadDrawingUseCase: UploadDrawing,
    private uploadClearDrawingUseCase: UploadClearDrawing
  ) {}

  async execute(request: __HttpRequestModel__): Promise<boolean> {
    const document = await this.getDocument(request.data.localId);
    const url = await this.urlBuilder.execute(
      document,
      request.data.projectId
    );

    if (
      !request.data.changes['data.isDrawn'] &&
      !request.data.changes['data.drawingSrc']
    ) {
      return this.uploadClearDrawingUseCase(
        this.documentUploader,
        document,
        url
      );
    }

    return this.uploadDrawingUseCase(
      this.documentUploader,
      document,
      url,
      {
        isDrawn: request.data.changes['data.isDrawn'],
        drawingSrc: request.data.changes['data.drawingSrc'],
      }
    );
  }
}
