import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface InspectionTemplatesServiceData extends ServiceData {}
const { add, clear, addSync, get, reset } =
  entityServiceFactory<InspectionTemplatesServiceData>(
    db,
    'inspectionTemplatesService'
  );

export { add, clear, addSync, get, reset };
