import { createStyles, makeStyles } from '@mui/styles';
import { MENU_BAR_HEADER_HEIGHT } from '../core/MenuBar/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    head: {
      width: '100%',
      boxSizing: 'border-box',
      height: '56px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
      zIndex: 1,
      paddingLeft: '20px',
      gap: '16px',
    },
    content: {
      display: 'flex',
      overflowY: 'hidden',
      height: `calc(100vh - ${MENU_BAR_HEADER_HEIGHT}px - 56px)`,
    },
    relativeWrapper: {
      position: 'relative',
      width: '100%',
      overflowY: 'hidden',
      overflowX: 'hidden',
      display: 'flex',
      flex: 1,
    },
    absoluteWrapper: {
      width: '100%',
      position: 'absolute',
      height: '100%',
      display: 'flex',
      flex: 1,
    },
  })
);
