import { HttpRequestEntity } from 'shared/domain/httpRequest/httpRequestModel';
import { ContractInDto } from 'shared/dtos/in/contract';
import {
  broadcastUploadedContractError,
  broadcastCreatedContractError,
} from 'serviceWorker/broadcasts/contract/error';
import {
  broadcastCreatedContract,
  broadcastUpdatedContract,
  broadcastDeletedContract,
} from 'serviceWorker/broadcasts/contract/one';

export function broadcastContractResponse(
  request: HttpRequestEntity,
  response: ContractInDto | any
): void {
  if (response.error) {
    return broadcastUploadedContractError(response.error);
  }
  if (request.method === 'POST' || request.method === 'GET') {
    return broadcastCreatedContract(
      response,
      request.data.uniqueId
    ) as any;
  }
  if (request.method === 'PUT') {
    return broadcastUpdatedContract(
      response,
      request.data.uniqueId
    ) as any;
  }
  if (request.method === 'DELETE') {
    return broadcastDeletedContract(response, request.data.uniqueId);
  }
}

export function broadcastContractError(
  request: HttpRequestEntity,
  error: any
): void {
  broadcastCreatedContractError(error);
}
