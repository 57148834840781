import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceData } from 'serviceWorker/db/service';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import {
  HeadersData,
  NormalizedEntityResponseData,
  Pull,
  ResponseWithSyncKey,
} from './types';
import { getFinishedServiceStateWithoutSyncKey } from './getFinishedServiceState';
import { Identificable } from 'shared/types/commonView';

type MakePullWithSyncKeyEntityProps = {
  fetch: (
    data: HeadersData,
    abortController: AbortController
  ) => Promise<any> | undefined;
  channelName: ChannelNames;
  entityRepository: any;
  entityService: any;
  entityName: string;
  finishedServiceStateFactory: (
    total: number,
    syncKey: string
  ) => Partial<ServiceData>;
  emitAllBroadcasts: () => void;
  withoutSyncKey?: boolean;
};

export function makePullEntity({
  fetch,
  channelName,
  entityRepository,
  entityService,
  entityName,
  finishedServiceStateFactory,
  emitAllBroadcasts,
  withoutSyncKey,
}: MakePullWithSyncKeyEntityProps): Pull {
  return async (data, abortController): Promise<void> => {
    debugLog('pulling entityName: ', entityName);
    const useSyncKey = !withoutSyncKey;
    const promise = fetch(data, abortController);

    if (!promise) {
      // TODO it seems this should be extended to all entities
      entityName === 'organizations' &&
        (await entityService.add({
          total: undefined,
          isDownloading: false,
          isDownloaded: false,
          syncKey: undefined,
        }));
      return;
    }

    await entityService.add({
      total: undefined,
      isDownloading: true,
      isDownloaded: false,
      syncKey: undefined,
    });
    return promise
      .then(
        async (res: NormalizedEntityResponseData | Identificable[]) => {
          if (abortController.signal.aborted) {
            return;
          }
          if (!res) {
            await entityService.add({
              isDownloading: false,
            });
            return;
          }
          await entityService.add({
            total: useSyncKey
              ? (res as ResponseWithSyncKey).items.length
              : (res as Identificable[]).length,
            isDownloading: true,
          });

          const broadcast = new BroadcastChannel(channelName);
          broadcast.postMessage({
            data: { isDownloading: true },
            type: DomainMessagesTypes.state,
          });

          if (abortController.signal.aborted) {
            return;
          }
          await entityRepository.addBatch(
            useSyncKey ? (res as ResponseWithSyncKey).items : res
          );
          const syncKey =
            useSyncKey && ((res as ResponseWithSyncKey).syncKey as string);

          const entityServiceData = syncKey
            ? finishedServiceStateFactory(
                (res as ResponseWithSyncKey).items.length,
                syncKey
              )
            : getFinishedServiceStateWithoutSyncKey(
                (res as Identificable[]).length
              );

          // for some reason getting all data makes IndexedDB faster next time
          const start = Date.now();
          debugLog(`start ${entityName} getAll:`, start);
          await entityRepository.get();
          await entityService.add(entityServiceData);
          const end = Date.now();
          debugLog(`entityRepository ${entityName} time:`, end - start);
          emitAllBroadcasts();
          // TODO it seems this should be extended to all entities
          // unless the issue is solved by useEntitySubscriber - for verification

          entityName === 'organizations' &&
            broadcast.postMessage({
              data: { isDownloaded: true },
              type: DomainMessagesTypes.state,
            });
        }
      )
      .catch(async (e: any) => {
        swLog(
          `Problem occured when fetching ${entityName}`,
          LogLevel.ERROR,
          e,
          null
        );
        await entityRepository.clear();
        await entityService.add(
          useSyncKey
            ? {
                total: undefined,
                isDownloading: false,
                isDownloaded: false,
                syncKey: undefined,
              }
            : {
                total: undefined,
                isDownloading: false,
                isDownloaded: false,
              }
        );
      });
  };
}
