import React, { ReactElement } from 'react';
import { useStyles } from './styles';
import { SingleTemplateErrorProps } from './types';

import { ReactComponent as BrowserIcon } from 'assets/icons/browser.svg';
import { useIntl } from 'react-intl';
import useIsMobile from 'hooks/useIsMobile';

function SingleTemplateError(
  props: SingleTemplateErrorProps
): ReactElement {
  const { error } = props;
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const intl = useIntl();

  return (
    <div className={classes.wrapper}>
      <BrowserIcon />
      <p className={classes.errorText}>
        {intl.formatMessage({ id: error.templateErrorType })}
      </p>
      <span className={classes.helperText}>
        {intl.formatMessage({ id: error.helperText })}
      </span>
    </div>
  );
}

export const MemoSingleTemplateError = React.memo(SingleTemplateError);
