import React, { Fragment, ReactElement, useCallback } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
} from '@mui/material';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import { MemoPreviewField } from 'components/general/PreviewField';
import { createTemplateChecklistItemDocumentsBaseUrl } from 'views/template/wizard/communicator/helpers';
import { GraphicUploader } from 'components/common/graphicUploader';
import { createComplianceCheckDocumentsBaseUrl } from 'views/inspections/single/helpers';
import { MemoProtocolItemResultIcon } from '../ComplianceCheckResult/Icon';
import { MemoComplianceCheckPreview } from '../ComplianceCheckPreview';
import useScrollToOnInit from 'hooks/scrollToOnInit';
import { useInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { useInspection } from 'components/dataProviders/withInspection';
import { ProtocolOnView } from 'presentation/inspection/inspectionOnView';
import { RenderableProcess } from 'components/common/ProcessIcon/types';
import { projectIdSelector } from 'helpers/misc';
import { useSelector } from 'react-redux';

type ProtocolItemPreviewProps = {
  protocolItem: ProtocolOnView;
  index: number;
  renderableProcess: RenderableProcess;
};

function shouldBeExpandedBasedOnUrl(
  protocolItem: ProtocolOnView
): boolean {
  return (
    window.location.pathname.includes(
      protocolItem.templateChecklistItem._id
    ) || window.location.pathname.includes(protocolItem._id)
  );
}

function add0ToSingleDigitNumber(number: number): string {
  return number < 10 ? '0' + number : `${number}`;
}
const PROTOCOLPREVIEW_PREFIX_DOM_ELEMENT_ID = 'protocol-item-';
function ProtocolItemPreview(
  props: ProtocolItemPreviewProps
): ReactElement {
  const projectId = useSelector(projectIdSelector);
  const { index, protocolItem, renderableProcess } = props;
  const indexPlusOne = index + 1;
  const classes = useStyles();
  const intl = useIntl();
  const { inspectionTemplateDocuments } = useInspectionTemplate();
  const { inspectionDocuments } = useInspection();

  const checklistItem = protocolItem.templateChecklistItem;
  const checklistItemUploaderConfig = React.useMemo(
    () => ({
      baseUrl: createTemplateChecklistItemDocumentsBaseUrl(
        checklistItem.templateId,
        checklistItem._id
      ),
      previewOnly: true as true,
      internalEntityBaseUrl: (): string =>
        `/project/${projectId}/inspection/${protocolItem.inspectionId}/checklist/${protocolItem.templateChecklistItem._id}`,
    }),
    [projectId, protocolItem, checklistItem.templateId, checklistItem._id]
  );
  const checklistDocuments =
    inspectionTemplateDocuments.checklistDocuments[checklistItem._id];
  const checklistItemGU = useGraphicUploader(
    checklistDocuments,
    checklistItemUploaderConfig
  );

  const complianceCheckUploaderConfig = React.useMemo(
    () => ({
      baseUrl: createComplianceCheckDocumentsBaseUrl(
        protocolItem.inspectionId,
        protocolItem._id
      ),
      previewOnly: true as true,
      internalEntityBaseUrl: (): string =>
        `/project/${projectId}/inspection/${protocolItem.inspectionId}/protocol/${protocolItem._id}`,
    }),
    [projectId, protocolItem.inspectionId, protocolItem._id]
  );

  const protocolDocuments = inspectionDocuments[protocolItem._id];
  const complianceCheckGU = useGraphicUploader(
    protocolDocuments,
    complianceCheckUploaderConfig
  );

  const [expanded, setExpanded] = React.useState<boolean>(
    shouldBeExpandedBasedOnUrl(protocolItem)
  );
  const handleExpanded = useCallback((_, isExpanded: boolean) => {
    setExpanded(isExpanded);
  }, []);
  const expandIcon = React.useMemo(() => <ExpandMoreIcon />, []);

  useScrollToOnInit(
    expanded,
    `#${PROTOCOLPREVIEW_PREFIX_DOM_ELEMENT_ID}${index}`
  );

  return (
    <Fragment>
      <div>
        <Accordion
          id={`${PROTOCOLPREVIEW_PREFIX_DOM_ELEMENT_ID}${index}`}
          className={classes.hustroTemplateAccordion}
          expanded={expanded}
          onChange={handleExpanded}
        >
          <AccordionSummary
            id={`protocolItemPreview${index}`}
            expandIcon={expandIcon}
            aria-label='Expand'
            data-qa='protocol-item-preview-expand'
          >
            <span
              className={classNames(
                'accordionTitle',
                expanded ? 'bold' : null
              )}
            >
              <span className={classes.accordionIndex}>
                {add0ToSingleDigitNumber(indexPlusOne)}
              </span>
              <span
                className={classNames(
                  'checklistItemSummary',
                  expanded ? 'expanded' : null
                )}
              >
                {checklistItem.summary}
              </span>
            </span>
            <MemoProtocolItemResultIcon
              status={protocolItem.complianceCheck.result}
            />
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.flexColumn}>
              <div className={classes.flexColumn}>
                <MemoPreviewField
                  label={intl.formatMessage({
                    id: 'checklist_item_preview_field_label_description',
                  })}
                  value={checklistItem.norm.description || ''}
                  dense={true}
                />
                <div className={classes.graphicUploaderWrapper}>
                  <GraphicUploader {...checklistItemGU} />
                </div>
              </div>
              <Divider />
              <div
                className={classNames(
                  classes.flexColumn,
                  classes.complianceCheck
                )}
              >
                <MemoComplianceCheckPreview
                  protocolItem={protocolItem}
                  graphicUploader={complianceCheckGU}
                  renderableProcess={renderableProcess}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
      <Divider />
    </Fragment>
  );
}

export const MemoProtocolItemPreview = React.memo(ProtocolItemPreview);
