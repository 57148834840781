import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { ChartFiltersUnion } from 'shared/types/analytics';

const LAST_UPDATED = 'last_update';
const DATA = 'data';
const CACHE_ID = 'cache_id';
const CACHE_TIME = 1000 * 60 * 60; // 1h

type CacheId = string & {
  __value__: never;
};
type ResponseWithData<T> = { data: T };
type UseCache = {
  fetchData: <T>(
    realFetch: (fetchParams: string) => Promise<ResponseWithData<T>>,
    paramsToApply: string
  ) => Promise<ResponseWithData<T>>;
  setDisabled: (bool: boolean) => void;
  updatedAt: () => number;
};

export function useCache(id: string): UseCache {
  const intl = useIntl();
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(true);
  }, [intl]);

  const updatedAt = useCallback(() => lastUpdated(id), [id]);

  const fetchData = useCallback(
    function fetch<T>(
      realFetch: (paramsToApply: string) => Promise<ResponseWithData<T>>,
      paramsToApply: string
    ) {
      const now = Date.now();
      const lastUpdate = lastUpdated(id);
      const cacheId = toCacheId(paramsToApply);

      if (canUseCache(disabled, id, cacheId, now, lastUpdate)) {
        const cached = getCachedObject(`${DATA}_${id}`) as T;
        return Promise.resolve({ data: cached });
      }

      return realFetch(paramsToApply).then((res) => {
        setDisabled(false);
        setCache(id, JSON.stringify(res.data), cacheId);
        return res;
      });
    },
    [id, disabled]
  );

  return {
    fetchData,
    setDisabled,
    updatedAt,
  };
}

type UseCachedIssueCharts = {
  disabled: boolean;
  fetchData: (realFetch: () => Promise<any>) => Promise<any>;
  setDisabled: (bool: boolean) => void;
  updatedAt: () => number;
};

function setCache(id: string, data: any, cacheId: CacheId): void {
  localStorage.setItem(`${DATA}_${id}`, data);
  localStorage.setItem(`${CACHE_ID}_${id}`, cacheId);
  localStorage.setItem(`${LAST_UPDATED}_${id}`, `${Date.now()}`);
}

function getCachedObject(cacheKey: string): unknown {
  return JSON.parse(getCachedString(cacheKey) as string);
}

function getCachedString(cacheKey: string): string | null {
  return localStorage.getItem(cacheKey);
}

function getCachedId(cacheKey: string): CacheId {
  return (getCachedString(cacheKey) || '') as CacheId;
}

function lastUpdated(id: string): number {
  return parseFloat(getCachedString(`${LAST_UPDATED}_${id}`) || '0');
}

function canUseCache(
  disabled: boolean,
  id: string,
  params: CacheId,
  now: number,
  lastUpdate: number
): boolean {
  const cacheId = getCachedId(`${CACHE_ID}_${id}`);
  return !disabled && params === cacheId && now - lastUpdate < CACHE_TIME;
}

function canUseCachedIssueCharts(
  id: string,
  filters: CacheId,
  now: number,
  lastUpdate: number
): boolean {
  const cacheId = getCachedId(`${CACHE_ID}_${id}`);
  return (
    Boolean(filters) &&
    filters === cacheId &&
    now - lastUpdate < CACHE_TIME
  );
}

function toCacheId(urlParams: string): CacheId {
  return (urlParams ? urlParams : '') as CacheId;
}

function filtersToCacheId(
  filters: ChartFiltersUnion,
  locale: string
): CacheId {
  return (JSON.stringify(
    filters.dataScope.filters.map((filter: { value: any }) => {
      if (filter.value.from && filter.value.to) {
        return {
          from: new Date(filter.value.from).toISOString().slice(0, 10),
          to: new Date(filter.value.to).toISOString().slice(0, 10),
        };
      }
      return filter.value;
    })
  ) + locale) as CacheId;
}
