import { HashMap } from 'shared/types/commonView';

export type PostFile = (
  abortController: AbortController,
  url: string,
  data: FormData
) => Promise<any>;

export function uploadFile(
  abortController: AbortController,
  postFile: PostFile,
  fields: HashMap<any>,
  url: string,
  file: any
): Promise<any> {
  const formData = new FormData();
  const awsFields = fields;
  Object.keys(awsFields).forEach((key) => {
    formData.append(key, awsFields[key]);
  });

  if (file) {
    formData.append('file', file);
  }

  return postFile(abortController, url, formData);
}
