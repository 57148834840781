import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useProjectChannelListener } from 'components/broadcastChannelListeners/withProjectChannelListener';
import { startAllProjectsSynchronization } from 'shared/domain/project/startAllProjectsSynchronization';
import { makeUsePullAll } from './makeUsePullAll';
import { ProjectModel } from 'shared/domain/project/types/model';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allProjects
  );
}

export const useGetAllProjects = makeUsePullAll<{
  projects: ProjectModel[];
}>({
  useChannelListener: useProjectChannelListener,
  pullFunction: startAllProjectsSynchronization,
  isMatchingMessage,
  entityName: 'projects',
});
