import listenToDocumentUpdatedMessage from 'shared/domain/document/listenToDocumentUpdatedMessage';
import startDocumentEdit from 'shared/domain/document/startDocumentEdit';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { Message } from 'shared/domain/messages/message';
import { UploadStatus } from 'shared/types/uploadStatus';
import { debugLog } from 'shared/logger/debugLog';

interface SetDocumentDrawingUploadStatus {
  (
    localId: number,
    status: UploadStatus,
    syncStatus: SyncStatus
  ): Promise<number>;
}

export const setDocumentDrawingUploadStatus: SetDocumentDrawingUploadStatus =
  function _setDocumentDrawingUploadStatus(
    localId: number,
    status: UploadStatus,
    syncStatus: SyncStatus
  ): Promise<number> {
    return new Promise((resolve, reject): void => {
      const broadcast = listenToDocumentUpdatedMessage(
        (success: any): void => {
          broadcast.close();
          debugLog('setDocumentDrawingUploadStatus success', success);
          resolve(success);
        },
        (error: any): void => {
          broadcast.close();
          debugLog('setDocumentDrawingUploadStatus error', error);
          reject(error);
        },
        (event: Message): boolean => {
          return event.data === localId;
        }
      );

      startDocumentEdit({
        localId,
        drawingUploadStatus: status,
        drawingSyncStatus: syncStatus,
      });
    });
  };
