import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { Broadcaster } from '../types';
import * as dbOrganizationsService from '../../db/organizationsService';
import { debugLog } from 'shared/logger/debugLog';

export const broadcastState: Broadcaster = async (broadcast) => {
  const status = await dbOrganizationsService.get();
  debugLog('broadcasting organizations state', status);
  broadcast.postMessage({
    data: status,
    type: DomainMessagesTypes.state,
    entityName: 'organizations',
  });
};
