import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

export function waitForMessage(
  channel: ChannelNames,
  messageType: DomainMessagesTypes
): () => Promise<void> {
  return function wait(): Promise<void> {
    return new Promise((resolve, reject) => {
      const broadcast = new BroadcastChannel(channel);
      broadcast.onmessage = (event: Message): void => {
        if (event.type === messageType) {
          broadcast.close();
          resolve();
        }
      };
    });
  };
}
