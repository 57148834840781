import { IntlShape } from 'react-intl';
import { UserRole } from 'shared/types/userRole';
import { UserCreateOnView } from 'shared/domain/user/types/view';

export function createEmptyUser(intl: IntlShape): UserCreateOnView {
  return {
    email: '',
    label: '',
    role: {
      label: intl.formatMessage({ id: 'role_standard' }),
      _id: UserRole.standard,
    },
    processes: [],
    sites: [],
  };
}
