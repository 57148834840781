import { useEffect } from 'react';
import { EntityLabel } from 'components/common/SearchInput/types';
import { getSearchPhraseFromStorage } from 'components/common/SearchInput/helpers';

export function useGetSearchPhrase(
  projectId: string,
  entityLabel: EntityLabel,
  setSearchPhraseState: (
    value: ((prevState: string) => string) | string
  ) => void
): void {
  useEffect(() => {
    if (!projectId) {
      return;
    }

    setSearchPhraseState(
      getSearchPhraseFromStorage(projectId, entityLabel)
    );
  }, [projectId, entityLabel, setSearchPhraseState]);
}
