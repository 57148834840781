import { companyCreateModelToCreateOutDto } from 'shared/domain/company/mapping';
import { CompanyCreateModel } from 'shared/domain/company/types/model';
import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { CompanyHttpCreateRequestModel } from '../httpQueue/company/types';
import { AddCompanyUseCase } from 'shared/domain/company/types/useCases';

export class CompanySaver implements AddCompanyUseCase {
  constructor(
    private addRequest: (
      request: CompanyHttpCreateRequestModel
    ) => Promise<any>
  ) {}
  async execute(
    companyCreateModel: CompanyCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddCompanyUseCase', companyCreateModel);
    const companyCreateOutDto =
      companyCreateModelToCreateOutDto(companyCreateModel);
    const request: CompanyHttpCreateRequestModel = {
      createdAt: Date.now(),
      method: 'POST',
      url: `/project/${companyCreateModel.projectId}/company`,
      data: {
        companyCreateOutDto,
        uniqueId: uniqueId,
        projectId: companyCreateModel.projectId,
      },
      entityType: HttpRequestModelType.company,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const companySaver = new CompanySaver(addRequest);
