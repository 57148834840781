import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: '15px 20px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#000',
      borderColor: '#8A8A8A',
      maxWidth: '127px',
      flexGrow: 1,
    },

    green: {
      '&:hover': {
        background: 'rgba(67, 160, 71, 0.1)',
      },
      '&.Mui-selected': {
        color: '#43A047',
        background: 'rgba(67, 160, 71, 0.15)',
        border: '1px solid #43A047',
      },
      '&.Mui-selected:hover': {
        color: '#43A047',
        background: 'rgba(67, 160, 71, 0.25)',
      },
    },
    red: {
      '&:hover': {
        background: 'rgba(229, 57, 53, 0.13)',
      },
      '&.Mui-selected': {
        color: '#E53935',
        background: 'rgba(229, 57, 53, 0.18)',
        border: '1px solid #E53935',
      },
      '&.Mui-selected:hover': {
        color: '#E53935',
        background: 'rgba(229, 57, 53, 0.28)',
      },
    },

    toggleButtonText: {
      marginLeft: '10px',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    group: {
      width: '100%',
      '& .MuiToggleButton-root': {
        lineHeight: 'unset',
      },
    },
  })
);
