import { useContractChannelListener } from 'components/broadcastChannelListeners/withContractChannelListener';
import { ContractModel } from 'shared/domain/contract/types/model';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';
import { debugLog } from 'shared/logger/debugLog';

function isMatchingMessage(event: Message): boolean {
  debugLog(
    'Trying to match event',
    DomainMessagesTypes.allContracts,
    event,
    'with result',
    Boolean(event.data) && event.type === DomainMessagesTypes.allContracts
  );
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allContracts
  );
}

export const useGetAllContracts = makeUseGetAll<ContractModel>({
  useChannelListener: useContractChannelListener,
  postMessageChannel: ChannelNames.contractChannel,
  postMessageType: DomainMessagesTypes.getAllContracts,
  isMatchingMessage,
  entityName: 'contracts',
});
