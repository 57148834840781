import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debuglog } from 'util';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGet } from './makeUseGet';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';

export const useGetOneInspection = makeUseGet<
  InspectionModel,
  string | number
>({
  listenMessageChannel: ChannelNames.inspectionChannel,
  isMatchingMessage,
  postMessage: broadcastGetOneInspection,
  entityName: 'inspection',
});

function broadcastGetOneInspection(id: string | number): void {
  debuglog('send broadcastGetOneInspection');
  const broadcast = new BroadcastChannel(ChannelNames.inspectionChannel);
  broadcast.postMessage({
    type: DomainMessagesTypes.getInspection,
    data: {
      id,
    },
  });
  broadcast.close();
}

function isMatchingMessage(id: string | number, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.inspection &&
    Boolean(event.data) &&
    (event.data._id === id || event.data.localId === id)
  );
}
