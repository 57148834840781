import { useEffect } from 'react';

type UseScrollToOnInit = (canScroll: boolean, selector: string) => void;

const useScrollToOnInit: UseScrollToOnInit = (canScroll, selector) => {
  useEffect(
    () => {
      if (canScroll) {
        document.querySelector(selector)?.scrollIntoView();
      }
    },
    // only once on init
    // eslint-disable-next-line
    []
  );
  return;
};

export default useScrollToOnInit;
