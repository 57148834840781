import { PromiseExtended, Dexie } from 'dexie';
import db from './index';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import {
  DocumentEntity,
  DocumentInsertEntity,
} from 'serviceWorker/repository/document/entity';
import { DocumentParentRelation } from 'serviceWorker/repository/document/relations';
import {
  DocumentBindings,
  DocumentParentBond,
} from 'shared/domain/document/documentModel';
import { nowISO } from 'shared/utils/date/dates';
import { DocumentChanges } from 'serviceWorker/services/documents/editDocument';
import { UploadStatus } from 'shared/types/uploadStatus';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

function keepLocalDrawingDataOnUnuploadedStatus(
  documentToInsert: DocumentInsertEntity,
  document: DocumentEntity
): DocumentInsertEntity {
  if (
    documentToInsert._id &&
    documentToInsert.data?.uploadStatus === UploadStatus.success &&
    document.syncStatus === SyncStatus.SUCCESS &&
    document.localData
  ) {
    documentToInsert.localData = undefined;
  }

  if (document.syncStatus !== SyncStatus.SUCCESS) {
    documentToInsert.deleted = document.deleted;
  }

  if (
    (document.syncStatus !== SyncStatus.SUCCESS ||
      document.drawingSyncStatus !== SyncStatus.SUCCESS) &&
    documentToInsert.data
  ) {
    documentToInsert.syncStatus = document.syncStatus;
    documentToInsert.data.isDrawn = Boolean(document.data?.isDrawn);
  }

  if (documentToInsert.data?.isDrawn) {
    if (
      documentToInsert.data.mergedUploadStatus !== UploadStatus.success ||
      document.drawingSyncStatus !== SyncStatus.SUCCESS
    ) {
      documentToInsert.drawingSyncStatus = document.drawingSyncStatus;
      documentToInsert.data.mergedSrc = document.data?.mergedSrc;
      documentToInsert.data.thumbnailMergedSrc =
        document.data?.thumbnailMergedSrc;
    }
    if (
      documentToInsert.data.drawingUploadStatus !== UploadStatus.success ||
      document.drawingSyncStatus !== SyncStatus.SUCCESS
    ) {
      documentToInsert.drawingSyncStatus = document.drawingSyncStatus;
      documentToInsert.data.drawingSrc = document.data?.drawingSrc;
    }
  }

  return documentToInsert;
}

const update = wrapQuery(
  db,
  (
    documentFindStrategy: CallableFunction,
    data: DocumentInsertEntity
  ): PromiseExtended<any> => {
    return documentFindStrategy().then((document: DocumentEntity) => {
      if (!document) {
        throw new Error('Cannot find document to update');
      } else {
        const documentWithLocalDrawingData =
          keepLocalDrawingDataOnUnuploadedStatus(data, document);

        return db.documents.update(
          document.localId,
          documentWithLocalDrawingData
        );
      }
    });
  }
);

const getOneByRemote = makeDefaultGetOne<string, DocumentEntity>(
  db,
  db.documents,
  '_id'
);

export const getOne = makeDefaultGetOne<number, DocumentEntity>(
  db,
  db.documents,
  'localId'
);

export const upsertBatch = wrapQuery(
  db,
  async (data: DocumentInsertEntity[]): Promise<unknown> => {
    const primaryKeys = await db.documents.orderBy('_id').keys();

    const duplicates: DocumentInsertEntity[] = [];
    const docMap = {};
    const newData = data.filter((document) => {
      if (!document._id) return false;
      if (primaryKeys.includes(document._id) || docMap[document._id]) {
        docMap[document._id] = true;
        duplicates.push(document);
        return false;
      }
      docMap[document._id] = true;
      return true;
    });

    await db.documents.bulkPut(newData as any[]);

    const modifications: Promise<any>[] = duplicates.map(
      (document: DocumentInsertEntity) => {
        // @ts-ignore because it says it requires localId but
        // localId is an auto incement and we dont know it here yet.
        return db.documents.add(document).catch((e) => {
          if (e.name === Dexie.errnames.Constraint) {
            const documentFindStrategy = (): Promise<
              DocumentEntity | undefined
            > => {
              if (!document._id) {
                return Promise.resolve(undefined);
              }
              return getOneByRemote(document._id);
            };
            return update(documentFindStrategy, document);
          }
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.upsertBatch, 'documents'),
            errorObj: e,
            additionalInfo: { query: { data } },
          });
        });
      }
    );

    return Promise.all(modifications);
  }
);

export const addDocument = wrapQuery(
  db,
  async (document: DocumentInsertEntity): Promise<number> => {
    return db.documents.add(document as DocumentEntity).catch((e) => {
      if (e.name === Dexie.errnames.Constraint) {
        const documentFindStrategy = (): Promise<
          DocumentEntity | undefined
        > => {
          if (!document._id) {
            return Promise.resolve(undefined);
          }
          return getOneByRemote(document._id);
        };
        return update(documentFindStrategy, document);
      }
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.add, 'documents'),
        errorObj: e,
        additionalInfo: { query: { data: document } },
      });
    });
  }
);

export const getByIds = makeDefaultGetManyByIds<number, DocumentEntity>(
  db,
  db.documents,
  'localId'
);

export const getByQuery = wrapQuery(
  db,
  (query: DocumentParentBond): Promise<DocumentEntity[]> => {
    const [key, value] = Object.entries(query)[0];

    return db.documents
      .where(key)
      .equals(value)
      .toArray()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.getByQuery, 'documents'),
          errorObj: e,
          additionalInfo: {
            query,
          },
        })
      );
  }
);

export const removeBatch = makeDefaultRemoveBatch<number, DocumentEntity>(
  db,
  db.documents,
  'localId'
);

export const remove = wrapQuery(
  db,
  async (id: number): Promise<unknown> => {
    return db.documents.delete(id).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.remove, 'documents'),
        errorObj: e,
        additionalInfo: { query: { id } },
      })
    );
  }
);

export const removeBatchByParentId = wrapQuery(
  db,
  async (query: DocumentParentRelation): Promise<any> => {
    const [key, value] = Object.entries(query)[0];

    await db.documents
      .where(key)
      .anyOf(value as (string | number)[])
      .delete()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.removeBatch, 'documents'),
          errorObj: e,
          additionalInfo: { query },
        })
      );
    return query;
  }
);

export const removeBatchByParentType = wrapQuery(
  db,
  async (key: keyof DocumentBindings): Promise<any> => {
    await db.documents
      .where(key)
      .notEqual('')
      .delete()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.removeBatch, 'documents'),
          errorObj: e,
          additionalInfo: { query: { key } },
        })
      );
    return key;
  }
);

export const updateOne = wrapQuery(
  db,
  async (
    localId: number,
    changes: DocumentChanges,
    options?: { skipModifyTime: boolean }
  ): Promise<any> => {
    const modifiedAt = options?.skipModifyTime
      ? {}
      : { modifiedAt: nowISO() };
    return db.documents
      .update(localId, { ...changes, ...modifiedAt })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.updateOne, 'documents'),
          errorObj: e,
          additionalInfo: { query: changes },
        })
      );
  }
);

export const updateDrawing = wrapQuery(
  db,
  async (localId: number, changes: any): Promise<any> => {
    return db.documents
      .update(localId, { ...changes, modifiedAt: nowISO() })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.updateDrawing, 'documents'),
          errorObj: e,
          additionalInfo: { query: changes },
        })
      );
  }
);
