import React from 'react';
import SubheaderText from '../../general/SubheaderText/SubheaderText';
import GDPRErrorIcon from '../../../assets/icons/gdprError.png';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    margin: '0 24px',
    backgroundColor: '#F2F2F2',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    whiteSpace: 'break-spaces',
    textAlign: 'center',
    margin: 'auto 0',
  },
  title: {
    marginBottom: 24,
    fontWeight: 700,
  },
  info: {
    fontSize: '1rem',
    lineHeight: '1.375rem',
    fontFamily: 'Archivo',
    color: '#757575',
    margin: '0 auto',
    maxWidth: 300,
  },
  icon: {
    height: 88,
    marginBottom: 48,
  },
});

const GDPRError = (): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <img
          src={GDPRErrorIcon}
          alt='gdpr error icon'
          className={classes.icon}
        />
        <SubheaderText
          className={classes.title}
          data-qa='gdpr_clause_error_title'
        >
          <FormattedMessage id='gdpr_clause_error_title' />
        </SubheaderText>
        <p className={classes.info}>
          <FormattedMessage id='gdpr_clause_unavailable' />
        </p>
      </div>
    </div>
  );
};

export default GDPRError;
