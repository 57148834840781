import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { DirectoryCreateModel } from './directoryModel';
import { BroadcastChannel } from 'broadcast-channel';

export function startDirectoryCreate(
  directory: DirectoryCreateModel,
  uniqueId: string
): void {
  debugLog('startDirectoryCreate', directory, uniqueId);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DIRECTORIES,
    method: ServiceMethods.CREATE,
    data: {
      directory,
      uniqueId,
    },
  });
  broadcast.close();
}
