import { debugLog } from 'shared/logger/debugLog';
import { FetchErrorDetails, LogLevel } from 'shared/types/logger';
import { appLog } from './appLog';
import { provideLogInfo } from './provideLogInfo';

export const logFetchInfo = (
  message: string,
  details?: FetchErrorDetails
): void => {
  const additionalInfo = provideLogInfo();
  debugLog(message, LogLevel.INFO, additionalInfo, details);
  appLog(message, LogLevel.INFO, additionalInfo, details);
};
