import { findModelForEditing } from 'components/dataCreationForms/common';
import {
  EditContext,
  OpenEditDialogParams,
} from 'components/dataCreationForms/types';
import { useLevels } from 'components/dataProviders/withLevels';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import { levelOnViewToEditModel } from 'shared/domain/level/mapping/toModel';
import { levelModelToLevelOnView } from 'shared/domain/level/mapping/toView';
import { startLevelEdit } from 'shared/domain/level/startLevelEdit';
import { LevelModel } from 'shared/domain/level/types/model';
import { LevelOnView } from 'shared/domain/level/types/view';
import { projectIdSelector } from 'helpers/misc';
import { useGetAllLevels } from 'hooks/useGetAllLevels';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { createUniqueId } from 'shared/utils/id/id';
import { handleFormValidation, validateLevel } from '../validation';

const formName = 'level';
const SUBMIT_EVENT_TYPE = 'submit-level';
const WithEditLevelContext = createContext<
  EditContext<LevelOnView> | undefined
>(undefined);

function WithEditLevel({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const {
    levels: { items: levels },
  } = useLevels();
  const intl = useIntl();
  const { getAll: getAllLevels } = useGetAllLevels();
  const fetchLevels = getAllLevels;
  const projectId = useSelector(projectIdSelector);

  const [isPosting, setIsPosting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const [currentLevelId, setCurrentLevelId] = useState(
    useParams<{ id: string }>().id
  );

  const [existingValues, setExistingValues] = useState<
    LevelOnView | undefined
  >(undefined);

  useEffect(() => {
    const existingLevelModel = findModelForEditing(levels, currentLevelId);
    let levelOnView;
    if (existingLevelModel) {
      levelOnView = levelModelToLevelOnView(existingLevelModel);
      setExistingValues(levelOnView);
    }
  }, [levels, intl, currentLevelId]);

  const [open, setOpen] = useState(false);

  const openDialog = useCallback(({ _id }: OpenEditDialogParams) => {
    setOpen(true);
    setCurrentLevelId(_id);
  }, []);
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
    setExistingValues(undefined);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: LevelOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const levelEditModel = levelOnViewToEditModel(values, projectId);
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          (message: LevelModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            fetchLevels();
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.levelChannel
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithEditLevel: Timeout on update.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 15000);

        startLevelEdit(levelEditModel, uniqueId);
      })
        .then((level: unknown): any => {
          if (level && (level as Identificable)._id) {
            setOpen(false);
            return level;
          }
        })
        .catch(() => {});
    },
    [dispatch, projectId, fetchLevels]
  );

  const ctx: EditContext<LevelOnView> = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateLevel,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    existingValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
  ]);

  return (
    <WithEditLevelContext.Provider value={ctx}>
      {children}
    </WithEditLevelContext.Provider>
  );
}

function useEditLevel(): EditContext<LevelOnView> {
  const context = useContext(WithEditLevelContext);
  if (context === undefined) {
    throw new Error(
      'useEditLevel must be used within an EditLevelContext'
    );
  }
  return context;
}

const withEditLevel =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditLevel>
      <Component {...props} />
    </WithEditLevel>
  );

export { WithEditLevel, useEditLevel, withEditLevel };
