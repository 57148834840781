import React, { memo, useEffect } from 'react';
import {
  useSelectedProject,
  withSelectedProject,
} from 'components/dataProviders/withSelectedProject';
import { Spinner } from 'components/core';
import { SingleProject } from 'components/project/single';
import {
  useProject,
  withProject,
} from 'components/dataProviders/withProject';

const ProjectGeneralData = (): React.ReactElement => {
  const { selectedProject } = useSelectedProject();

  const { setId, project, loading } = useProject();

  useEffect(() => {
    if (!selectedProject?._id) {
      return;
    }

    setId(selectedProject._id);
  }, [selectedProject, setId]);

  if (loading || !project) {
    return <Spinner reason='ProjectGeneralData loading || !project' />;
  }

  return <SingleProject {...project} />;
};

export const MemoProjectGeneralData = memo(
  withSelectedProject(withProject(ProjectGeneralData))
);
