import Skeleton from '@mui/material/Skeleton';
import { ReactComponent as ArchiveIcon } from 'assets/icons/archive.svg';
import { ReactComponent as RestoreIcon } from 'assets/icons/restore_v2.svg';
import { ShowConditionally } from 'components/common/ShowConditionally';
import { LabelledCard } from 'components/common/labelledCard/single';
import { useCommonSingleEntityViewStyles } from 'components/common/styles/useCommonSingleEntityViewStyles';
import { ButtonContained, ButtonIcon } from 'components/general';
import useIsMobile from 'hooks/useIsMobile';
import React, {
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { LabelledCardList } from 'components/common/labelledCard/list';
import { useSites, withSites } from 'components/dataProviders/withSites';

import { EditOutlined } from '@mui/icons-material';
import {
  useCreateSite,
  withCreateSite,
} from 'components/dataCreationForms/site/withCreateSite';
import {
  useEditSite,
  withEditSite,
} from 'components/dataCreationForms/site/withEditSite';
import {
  useInspections,
  withInspections,
} from 'components/dataProviders/withInspections';
import { withLevels } from 'components/dataProviders/withLevels';
import { withLevelsToSelect } from 'components/dataProviders/withLevelsToSelect';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';
import {
  createdAtSortAsc,
  groupAndSort,
  groupDeleted,
} from 'helpers/sort';
import { MemoCreateSiteWizardDialog } from 'views/site/wizard/create/dialog';
import { MemoEditSiteWizardDialog } from 'views/site/wizard/edit/dialog';

function _ProjectStructureView(): ReactElement {
  const isMobile = useIsMobile();
  const classes = useCommonSingleEntityViewStyles({ isMobile });
  const { openDialog: openEditSiteDialog } = useEditSite();
  const { openDialog: openCreateSiteDialog } = useCreateSite();

  const intl = useIntl();

  const { inspections, loading: loadingInspections } = useInspections();

  const {
    sites: { items: sites },
    loading: loadingSites,
    deleteSite,
    restoreSite,
  } = useSites();

  const inspectionsMap = useMemo(() => {
    const map = new Map<string, InspectionModel[]>();
    inspections.items.forEach((inspection) => {
      map.set(inspection.site, [
        ...(map.get(inspection.site) || []),
        inspection,
      ]);
    });
    return map;
  }, [inspections]);

  const loading = loadingSites || loadingInspections;
  const canDeleteSite = useCallback(
    (siteId: string) => {
      const inspections = inspectionsMap.get(siteId) || [];
      const canDelete = inspections.every(
        (inspection) => inspection.isCompleted
      );
      return {
        disabled: !canDelete,
        reason: 'site_delete_conflict_inspections_in_progress',
      };
    },
    [inspectionsMap]
  );

  const cardItems = sites
    .sort(groupAndSort(groupDeleted, createdAtSortAsc))
    .map((site) => {
      return {
        _id: site._id,
        label: site.label,
        redirectTo: `/site/${site._id}`,
        deleted: site.deleted,
        code: site.code,
      };
    });

  const cardChildren = useMemo(() => {
    return cardItems.map((cardItem) => {
      const { disabled, reason } = canDeleteSite(cardItem._id);
      return (
        <LabelledCard
          key={cardItem._id}
          id={cardItem._id}
          label={cardItem.label}
          redirectTo={cardItem.redirectTo}
          deleted={cardItem.deleted}
          EndIcon={
            <>
              <ButtonIcon
                data-qa={'project-structure-edit-site'}
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  e.stopPropagation();
                  openEditSiteDialog({ _id: cardItem._id });
                }}
              >
                <EditOutlined />
              </ButtonIcon>
              <ShowConditionally isShown={!cardItem.deleted}>
                <ForbiddenTooltip visibleWhen={disabled} reason={reason}>
                  <ButtonIcon
                    data-qa={'project-structure-archive-site'}
                    disabled={disabled}
                    onClick={(e: MouseEvent) => {
                      e.preventDefault();
                      e.stopPropagation();
                      if (disabled) {
                        return;
                      }
                      deleteSite(cardItem);
                    }}
                  >
                    <ArchiveIcon />
                  </ButtonIcon>
                </ForbiddenTooltip>
              </ShowConditionally>
              <ShowConditionally isShown={cardItem.deleted}>
                <ButtonIcon
                  onClick={(e: MouseEvent) => {
                    e.preventDefault();
                    e.stopPropagation();
                    restoreSite(cardItem);
                  }}
                >
                  <RestoreIcon />
                </ButtonIcon>
              </ShowConditionally>
            </>
          }
        />
      );
    });
  }, [
    cardItems,
    deleteSite,
    canDeleteSite,
    restoreSite,
    openEditSiteDialog,
  ]);

  return (
    <div className={classes.root}>
      <div className={classes.actionsContainer}>
        <h2>{intl.formatMessage({ id: 'general_sites' })}</h2>
        <ButtonContained
          data-qa={'project-structure-create-site'}
          onClick={() => openCreateSiteDialog({})}
        >
          {intl.formatMessage({ id: 'create_site' })}
        </ButtonContained>
      </div>

      {loading ? (
        <>
          <Skeleton variant='text' width={'90%'} height={56} />
          <Skeleton variant='text' width={'90%'} height={56} />
          <Skeleton variant='text' width={'90%'} height={56} />
        </>
      ) : (
        <LabelledCardList>{cardChildren}</LabelledCardList>
      )}

      <MemoCreateSiteWizardDialog />
      <MemoEditSiteWizardDialog />
    </div>
  );
}

export const ProjectStructureView = withSites(
  withInspections(
    withLevels(
      withLevelsToSelect(
        withCreateSite(withEditSite(_ProjectStructureView))
      )
    )
  )
);
