import { IDocumentUploader } from './uploadDocument';

type Changes = { description: string };

export interface UploadDocumentChange {
  (
    uploader: IDocumentUploader,
    url: string,
    data: Changes
  ): Promise<boolean>;
}

export const uploadDocumentChange: UploadDocumentChange =
  async function _uploadDocumentChange(
    uploader: IDocumentUploader,
    url: string,
    data: Changes
  ): Promise<boolean> {
    try {
      await uploader.PUT(url, data);
      return true;
    } catch (e) {
      return false;
    }
  };
