import { AppRoot } from 'AppRoot';
import { serviceWorkerOnMainThreadStarter } from 'ServiceWorkerOnMainThread';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga4';
import { persistStore } from 'redux-persist';
import { initSentryInApp } from 'setup/logger/initSentryInApp';
import { browserHistory } from 'setup/browserHistory';
import { ENVIRONMENTS } from 'shared/enums';
import { TableStatePreserver } from 'views/issueTable/tableView/TableState';
import { API } from './api';
import { Client } from './client';
import './index.scss';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './setup/store';
import { getEnvironment } from './shared/utils/environment';

if (process.env.NODE_ENV === 'development') {
  // const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, {
  //   trackAllPureComponents: true,
  // });
}
const env = getEnvironment();

initSentryInApp();

if (env === ENVIRONMENTS.PRODUCTION) {
  ReactGA.initialize('G-8PWDQ2BWMT');
} else {
  ReactGA.initialize('G-PRR3K48C8M');
}

const persistor = persistStore(store);

const api = new API();
const client = new Client(api, store);
const tableState = new TableStatePreserver();

if (process.env.REACT_APP_DISABLE_AUTH) {
  client.authenticate();
}

function hasNewProcessValue(value: string | null): boolean {
  return Boolean(value) && value!.includes('PROCESS_');
}
(function clearOldAnalyticsFilters(): void {
  Object.keys(localStorage).forEach((key) => {
    if (!key.includes('data_scope_process')) {
      return;
    }
    if (hasNewProcessValue(localStorage.getItem(key))) {
      return;
    }
    localStorage.removeItem(key);
  });
})();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <AppRoot
    api={api}
    client={client}
    tableState={tableState}
    store={store}
    persistor={persistor}
    history={browserHistory}
  />
);

if (process.env.REACT_APP_DISABLE_WORKER) {
  serviceWorkerRegistration.unregister();
  serviceWorkerOnMainThreadStarter.initialize();
} else {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://cra.link/PWA
  serviceWorkerRegistration.register();
}

// https://stackoverflow.com/questions/29741922/prevent-service-worker-from-automatically-stopping
function keepServiceWorkerAlive(): void {
  setInterval(() => {
    fetch('keep-alive.txt');
  }, 20000);
}
keepServiceWorkerAlive();
