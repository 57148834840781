import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ApexAxisChartSeries } from 'shared/types/analytics';
import { useCache } from '../../hooks/useCache';
import { StoreState } from '../../setup/types/core';
import { ClientContext } from '../../with-client';
import {
  AverageResolutionTimeDto,
  AverageResolutionTimePerProcess,
  ResolutionTimeChartContext,
  ResolutionTimeFilters,
  Segments,
} from './types';
import { useCurrentUserRoleInSelectedProject } from 'hooks/useCurrentUserRoleInSelectedProject';
import { IntlShape, useIntl } from 'react-intl';
import { isDefined } from 'shared/domain/role/isDefined';
import { isStandard } from 'shared/domain/role/isStandard';
import { ChartType } from 'shared/types/analytics';
import { HashMap } from 'shared/types/commonView';
import { WithUserRoleObject } from '../../helpers/misc';
import { isoToProjectTimezonedDate } from '../../shared/utils/date/dates';
import { ChartYAxis } from '../../views/analytics/ChartYAxis';
import { ChartDetailsContext } from '../../views/analytics/chartDetails/ChartDetailsProvider';
import { GenericChartState } from '../shared/types';
import { useResolutionTimeFilters } from './filters';
import { filterValidSegments } from './model';
import {
  create2DigitFormatter,
  detailedViewResolutionTimeChartOptions,
  listViewResolutionTimeChartOptions,
} from './options';

function getAverage(array: number[]): number {
  return array.reduce((prev, curr) => prev + curr, 0) / array.length;
}

function createSeriesForSegments(
  res: AverageResolutionTimeDto,
  filtersSegments: Segments,
  isPercentage: boolean,
  intl: IntlShape
): { name: string; data: (number | null)[]; color: string }[] {
  return filterValidSegments(filtersSegments).map(
    (filterSegment, index) => {
      return {
        name: intl.formatMessage(
          { id: 'average_resolution_time_chart_segment_series' },
          { segmentNumber: index + 1 }
        ),
        color: filterSegment.color,
        data: res.map(({ segments }) => {
          const matchingSegment = segments.find(
            (segment) =>
              segment.rangeMin === filterSegment.rangeMin &&
              segment.rangeMax === filterSegment.rangeMax
          );

          if (matchingSegment) {
            return getSingleValueFromMultipleProcesses(
              matchingSegment.averageResolutionTimePerProcess,
              isPercentage
            );
          } else {
            return null;
          }
        }),
      };
    }
  );
}

function getSingleValueFromMultipleProcesses(
  avgResTime: AverageResolutionTimePerProcess[],
  isPercentage: boolean
): number | null {
  const resolutionTime = avgResTime.map((resTime) =>
    isPercentage ? resTime.delayInPercentage : resTime.days
  );

  // this array should never be empty (because there should be always at least one process),
  // but this check is just in case
  return resolutionTime.length > 0 ? getAverage(resolutionTime) : null;
}

function createSeriesFromResponseWithFilters(
  res: AverageResolutionTimeDto,
  filters: ResolutionTimeFilters,
  intl: IntlShape
): ApexAxisChartSeries {
  const isPercentage: boolean =
    filters.chartSettings.filters[2].value === ChartYAxis.percentage;
  const segments: Segments = filters.presented.filters[0].value;

  const overAll: (number | null)[] = res.map(
    ({ averageResolutionTimePerProcess }) => {
      return getSingleValueFromMultipleProcesses(
        averageResolutionTimePerProcess,
        isPercentage
      );
    }
  );

  const series: ApexAxisChartSeries = [
    {
      name: intl.formatMessage({
        id: 'average_resolution_time_chart_overall_series',
      }),
      data: overAll,
    },
    ...createSeriesForSegments(res, segments, isPercentage, intl),
  ];

  return series;
}

function displayFormattedDate(
  day: string,
  timezone: string,
  intl: IntlShape
): string {
  return isoToProjectTimezonedDate(day, timezone).toLocaleString(
    {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    },
    { locale: intl.locale }
  );
}

function canUserAccessChart(user: WithUserRoleObject): boolean {
  return isDefined(user.role) && !isStandard(user.role);
}

export function useResolutionTime(): ResolutionTimeChartContext {
  const client = useContext(ClientContext);
  const role = useCurrentUserRoleInSelectedProject();
  const timezone = useSelector((state: StoreState) => {
    return state.projectData.timezone;
  });

  const { fetchData, setDisabled, updatedAt } = useCache('ResolutionTime');
  const forceUpdate = useCallback(() => setDisabled(true), [setDisabled]);

  const isDetailedView = useContext(ChartDetailsContext);

  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const [lastLoaded, setLastLoaded] = useState<number>(Date.now());

  const load = useCallback(() => {
    setLastLoaded(Date.now());
  }, []);

  const filters = useResolutionTimeFilters();
  const options = isDetailedView
    ? detailedViewResolutionTimeChartOptions
    : listViewResolutionTimeChartOptions;
  const chartType = filters.chartSettings.filters[0].value as ChartType;
  const availableTypes = filters.chartSettings.filters[0]
    .available as ChartType[];

  const [state, setState] = useState<GenericChartState>({
    series: [],
    options: options,
  });

  useEffect(() => {
    if (isStandard(role)) {
      return;
    }
    let mounted = true;
    setLoading(true);
    const urlParams = [
      ...filters.dataScope.filters,
      filters.presented.filters[0],
    ]
      .map((filter) => filter.toUrlQuery!(filter.value))
      .join('');

    fetchData(client.fetchResolutionTime.bind(client), urlParams).then(
      (r) => {
        if (!mounted) {
          return;
        }
        //@ts-ignore
        setState((prev) => {
          const { hasDataLabels } = filters.chartSettings.filters[1]
            .value as HashMap<boolean>;
          const isPercentage =
            filters.chartSettings.filters[2].value ===
            ChartYAxis.percentage;

          return {
            ...prev,
            series: createSeriesFromResponseWithFilters(
              r.data,
              filters,
              intl
            ),
            options: {
              ...options,
              dataLabels: {
                ...options.dataLabels,
                enabled: hasDataLabels,
              },
              xaxis: {
                ...prev.options.xaxis,
                categories: r.data.map(({ day }) =>
                  displayFormattedDate(day, timezone, intl)
                ),
              },
              tooltip: {
                ...prev.options.tooltip,
                y: {
                  ...prev.options.tooltip?.y,
                  formatter: create2DigitFormatter(
                    isPercentage
                      ? '%'
                      : intl.formatMessage({ id: 'chart_y_axis_days' })
                  ),
                },
              },
            },
          };
        });

        setLoading(false);
        return (): void => {
          mounted = false;
        };
      }
    );
  }, [
    client,
    filters,
    intl,
    fetchData,
    lastLoaded,
    options,
    timezone,
    role,
  ]);

  return {
    state,
    filters,
    filtersDisabledOffline: true,
    titleId: 'average_resolution_time_chart',
    chartType,
    availableTypes,
    canAccess: canUserAccessChart,
    loading,
    load,
    forceUpdate,
    updatedAt,
  };
}
