import { ReactElement } from 'react';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { ReportForm } from 'components/report/Form';
import {
  handleFormValidation as defaultHandleFormValidation,
  validate as defaultValidate,
} from './validation';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { HashMap } from 'shared/types/commonView';

type ReportWizardProps<
  T extends HashMap<any>,
  K extends HashMap<any> = T,
> = {
  useReportContext: () => {
    open: boolean;
    openDialog: () => void;
    closeDialog: () => void;
    submitForm: (values: T) => Promise<any>;
    isPosting: boolean;
    initialValues: K;
    SUBMIT_EVENT_TYPE: string;
    releaseSubmitEvent: () => void;
    validate?: (...args: any[]) => any;
    handleFormValidation?: (...args: any[]) => any;
    ContentSelection: () => ReactElement | null;
  };
};
export function ReportWizard<
  T extends HashMap<any>,
  K extends HashMap<any> = T,
>({ useReportContext }: ReportWizardProps<T, K>): ReactElement {
  const formName = 'report';
  useFormCleanupOnUnmount(formName);
  const {
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
    ContentSelection,
  } = useReportContext();

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      handleFormValidation={
        handleFormValidation || defaultHandleFormValidation
      }
      handleFieldValidation={validate || defaultValidate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <ReportForm
        isPosting={isPosting}
        onCancel={closeDialog}
        onSubmit={releaseSubmitEvent}
        hasImageContent={Boolean(initialValues.imageContent)}
        ContentSelection={ContentSelection}
      />
    </WithInputForm>
  );
}
