import { ReactElement, memo, useEffect } from 'react';
import { AutocompleteWithTags } from 'components/common/simpleInputFields/autocompleteWithTags';
import { MemoAutocompleteWithText } from 'components/common/simpleInputFields/autocompleteWithText';
import { MemoSimpleCheckboxGroup } from 'components/common/simpleInputFields/checkboxGroup';
import { MemoSimpleDatepicker } from 'components/common/simpleInputFields/datepicker';
import { MemoSingleChoice } from 'components/common/simpleInputFields/singleChoice';
import { MemoSimpleTextField } from 'components/common/simpleInputFields/textField';
import {
  useEmailInput,
  withEmailInput,
} from 'components/common/withEmailInput';
import { MemoStatefulFormInputComponent } from './stateful';
import { StatefulProps } from './types';
import { MemoMultiChoice } from 'components/common/simpleInputFields/multichoice';

export function StatefulMultiselect({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  return (
    <MemoStatefulFormInputComponent
      {...props}
      formKey={formKey}
      Component={MemoMultiChoice}
    />
  );
}

export const MemoStatefulMultiselect = memo(StatefulMultiselect);

export function StatefulSingleSelect({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  return (
    <MemoStatefulFormInputComponent
      {...props}
      formKey={formKey}
      Component={MemoSingleChoice}
    />
  );
}

export const MemoStatefulSingleSelect = memo(StatefulSingleSelect);

export function StatefulDatepicker({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  return (
    <MemoStatefulFormInputComponent
      {...props}
      formKey={formKey}
      Component={MemoSimpleDatepicker}
    />
  );
}

export const MemoStatefulDatepicker = memo(StatefulDatepicker);

export function StatefulTextField({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  return (
    <MemoStatefulFormInputComponent
      {...props}
      formKey={formKey}
      Component={MemoSimpleTextField}
      debounce={props.debounce ?? true}
    />
  );
}

export const MemoStatefulTextField = memo(StatefulTextField);

export function StatefulAutocompleteWithText({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  return (
    <MemoStatefulFormInputComponent
      {...props}
      formKey={formKey}
      Component={MemoAutocompleteWithText}
      debounce={props.debounce ?? true}
    />
  );
}

export const MemoStatefulAutocompleteWithText = memo(
  StatefulAutocompleteWithText
);

function _StatefulEmailInput({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  const emailInputContext = useEmailInput();
  useEffect(() => {
    emailInputContext.setFormKey(formKey);
  }, [formKey, emailInputContext]);

  return (
    <MemoStatefulFormInputComponent
      {...emailInputContext}
      {...props}
      Component={AutocompleteWithTags}
      formKey={formKey}
    />
  );
}

export const StatefulEmailInput = withEmailInput(_StatefulEmailInput);

export function StatefulCheckboxGroup({
  formKey,
  ...props
}: StatefulProps): ReactElement {
  return (
    <MemoStatefulFormInputComponent
      {...props}
      Component={MemoSimpleCheckboxGroup}
      formKey={formKey}
    />
  );
}
