import * as dbDocumentationsService from '../db/documentationsService';
import * as dbDocumentations from '../db/documentations';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  makeBroadcastClear,
  makeBroadcastEntity,
  makeSimpleBroadcastError,
} from './factories';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';
import { OnMessage } from './types';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import { documentationEntityToModel } from 'serviceWorker/repository/documentation/mappings';
import { getUsersSynchronized } from 'serviceWorker/services/users/getUsers';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { LogLevel } from 'shared/types/logger';
import { awaitEntities } from 'serviceWorker/services/factories/awaitEntities';
import { waitForMessage } from 'serviceWorker/services/factories/waitForMessage';
import { UploadStatus } from 'shared/types/uploadStatus';

export const broadcastDocumentationError = makeSimpleBroadcastError(
  ChannelNames.documentationChannel
);

export const broadcastClearDocumentations = makeBroadcastClear(
  ChannelNames.documentationChannel
);

const broadcastDocumentation = makeBroadcastEntity<DocumentationModel>(
  {
    getOne: async (id: string) => {
      const [documentation, users] = await Promise.all([
        dbDocumentations.getOneByRemote(id),
        getUsersSynchronized(),
      ]);
      if (!documentation) {
        throw new Error(`Cannot find documentation ${id}`);
      }

      return documentationEntityToModel(documentation, users);
    },
  },
  ChannelNames.documentationChannel,
  DomainMessagesTypes.documentation
);

export const broadcastHasAllDocumentations = (): void => {
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  broadcast.postMessage({
    data: { hasAll: true },
    type: DomainMessagesTypes.hasAllDocumentations,
  });
  broadcast.close();
};

export const broadcastCreatedDocumentation = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdEntity,
  ChannelNames.documentationChannel
);

export const broadcastUpdatedDocumentation = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.documentationChannel
);

export const broadcastUploadedDocumentation = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.documentationChannel
);

export const broadcastFailUploadedDocumentation =
  makeSimpleBroadcastEntity(
    DomainMessagesTypes.failUploadedEntity,
    ChannelNames.documentationChannel
  );

export const broadcastDocumentationMoved = makeSimpleBroadcastEntity(
  DomainMessagesTypes.documentationMoved,
  ChannelNames.documentationChannel
);

export const broadcastDocumentationDeleted = makeSimpleBroadcastEntity(
  DomainMessagesTypes.documentationDeleted,
  ChannelNames.documentationChannel
);

export const broadcastCreatedDocumentationError = (error: any): void => {
  debugLog('broadcastCreatedDocumentationError', error);
  broadcastDocumentationError(DomainMessagesTypes.createdEntity, error);
};

export const broadcastUpdatedDocumentationError = (error: any): void => {
  debugLog('broadcastUpdatedDocumentationError', error);
  broadcastDocumentationError(DomainMessagesTypes.updatedEntity, error);
};

export const broadcastDocumentationMovedError = (error: any): void => {
  debugLog('broadcastCreatedDocumentationError', error);
  broadcastDocumentationBatchError(
    DomainMessagesTypes.documentationMoved,
    error
  );
};
export const broadcastDocumentationDeletedError = (error: any): void => {
  debugLog('broadcastDocumentationDeletedError', error);
  broadcastDocumentationBatchError(
    DomainMessagesTypes.documentationDeleted,
    error
  );
};

function broadcastDocumentationBatchError(
  type: DomainMessagesTypes,
  error: any
): void {
  const broadcastDocumentation = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  const broadcastDirectory = new BroadcastChannel(
    ChannelNames.directoryChannel
  );
  broadcastDocumentation.postMessage({
    error,
    type,
  });
  broadcastDirectory.postMessage({
    error,
    type,
  });
  broadcastDocumentation.close();
  broadcastDirectory.close();
}

const init = (): void => {
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getDocumentations: {
        broadcastDocumentationsByQuery(
          event.data.parentId,
          event.uniqueId
        );
        resync();
        return;
      }
      case DomainMessagesTypes.getDocumentation: {
        broadcastDocumentation(
          new CustomEvent<any>(DomainMessagesTypes.documentation, {
            detail: { id: event.data.id },
          })
        );
        return;
      }
      case DomainMessagesTypes.getSearchedDocumentations: {
        broadcastSearchedDocumentations(
          event.data.searchPhrase,
          event.uniqueId!
        );
        resync();
        return;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function broadcastDocumentationsByQuery(
  value: string,
  uniqueId?: string
): Promise<void> {
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  try {
    const start = Date.now();
    await awaitEntities(
      dbDocumentationsService,
      waitForMessage(
        ChannelNames.documentationChannel,
        DomainMessagesTypes.hasAllDocumentations
      )
    );
    const documentations = await getDocumentationsByQuery(value);
    const end = Date.now();
    debugLog('getDocumentationsByQuery time:', end - start);

    broadcast.postMessage({
      data: {
        items: documentations,
      },
      type: DomainMessagesTypes.documentations,
      uniqueId,
    });
  } catch (e) {
    if (e instanceof Error) {
      swLog(
        e.message,
        LogLevel.INFO,
        {
          functionName: 'broadcastDocumentationsByQuery',
          params: [
            ['value', value],
            ['uniqueId', uniqueId],
          ],
        },
        null
      );
    }
  }
  broadcast.close();
}

async function getDocumentationsByQuery(
  value: string
): Promise<DocumentationModel[]> {
  const [docs, users] = await Promise.all([
    dbDocumentations.getByParentId(value),
    getUsersSynchronized(),
  ]);
  return docs
    .map((doc) => documentationEntityToModel(doc, users))
    .filter((doc) => {
      if (!doc.versions[0]) return true;
      return doc.versions[0].uploadStatus !== UploadStatus.failed;
    });
}

async function broadcastSearchedDocumentations(
  value: string,
  uniqueId: string
): Promise<void> {
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  try {
    const start = Date.now();
    await awaitEntities(
      dbDocumentationsService,
      waitForMessage(
        ChannelNames.documentationChannel,
        DomainMessagesTypes.hasAllDocumentations
      )
    );
    const documentations = await getSearchedDocumentations(value);
    const end = Date.now();

    debugLog('getSearchedDocumentations time:', end - start);

    broadcast.postMessage({
      data: {
        items: documentations,
      },
      type: DomainMessagesTypes.documentations,
      uniqueId,
    });
  } catch (e) {
    if (e instanceof Error) {
      swLog(
        e.message,
        LogLevel.INFO,
        {
          functionName: 'getSearchedDocumentations',
          params: [
            ['value', value],
            ['uniqueId', uniqueId],
          ],
        },
        null
      );
    }
  }
  broadcast.close();
}

async function getSearchedDocumentations(
  value: string
): Promise<DocumentationModel[]> {
  const [docs, users] = await Promise.all([
    dbDocumentations.getSearched(value),
    getUsersSynchronized(),
  ]);
  return docs
    .map((doc) => documentationEntityToModel(doc, users))
    .filter((doc) => {
      if (!doc.versions[0]) return true;
      return doc.versions[0].uploadStatus !== UploadStatus.failed;
    });
}

function resync(): void {
  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.DOCUMENTATIONS,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
