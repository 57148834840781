import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';
import { useIssueFormChannelListener } from 'components/broadcastChannelListeners/withIssueFormChannelListener';
import { FieldSkeleton } from 'shared/types/form';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allIssueForm
  );
}

export const useGetAllIssueFormData = makeUseGetAll<FieldSkeleton>({
  useChannelListener: useIssueFormChannelListener,
  postMessageChannel: ChannelNames.issueFormChannel,
  postMessageType: DomainMessagesTypes.getAllIssueForm,
  isMatchingMessage,
  entityName: 'issueForm',
});
