import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  DomainMessagesTypes,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { GetInspectionTemplateEventDetail } from 'serviceWorker/api/types';
import { getAllInspectionTemplates } from 'serviceWorker/services/inspectionTemplates/getAllInspectionTemplates';
import { getInspectionTemplateById } from 'serviceWorker/services/inspectionTemplates/getInspectionTemplateById';
import * as inspectionTemplatesService from '../db/inspectionTemplatesService';
import {
  makeBroadcastAll,
  makeBroadcastClear,
  makeBroadcastEntity,
} from './factories';
import { Broadcaster, OnMessage } from './types';

export const broadcastClearInspectionTemplates = makeBroadcastClear(
  ChannelNames.inspectionTemplateChannel
);

export const broadcastAllInspectionTemplates = makeBroadcastAll<
  InspectionTemplateModel[]
>(
  inspectionTemplatesService,
  {
    get: getAllInspectionTemplates,
  },
  ChannelNames.inspectionTemplateChannel,
  DomainMessagesTypes.allInspectionTemplates
);

export const broadcastInspectionTemplate = makeBroadcastEntity(
  { getOne: getInspectionTemplateById },
  ChannelNames.inspectionTemplateChannel,
  DomainMessagesTypes.inspectionTemplate
);

const broadcastState: Broadcaster = async (broadcast) => {
  const status = await inspectionTemplatesService.get();
  broadcast.postMessage({ data: status, type: DomainMessagesTypes.state });
};

const unsyncedBroadcastInspectionTemplate =
  makeBroadcastEntity<InspectionTemplateModel>(
    {
      getOne: getInspectionTemplateById,
    },
    ChannelNames.inspectionTemplateChannel,
    DomainMessagesTypes.inspectionTemplate
  );

const init = (): void => {
  const broadcast = new BroadcastChannel(
    ChannelNames.inspectionTemplateChannel
  );

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }
      case DomainMessagesTypes.getInspectionTemplate: {
        if (!event.data) {
          return;
        }

        await unsyncedBroadcastInspectionTemplate(
          new CustomEvent<GetInspectionTemplateEventDetail>(
            DomainMessagesTypes.getInspectionTemplate,
            {
              detail: { id: event.data.id },
            }
          )
        );
        resync();
        break;
      }
      case DomainMessagesTypes.getAllInspectionTemplates: {
        await broadcastAllInspectionTemplates();
        resync();
      }
    }
  };

  broadcast.onmessage = handler;
};

function resync(): void {
  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.INSPECTION_TEMPLATES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}

export default init;
