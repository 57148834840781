import {
  HttpRequestEntity,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { isAbortError } from 'serviceWorker/repository/httpRequest/abortRequest';
import {
  broadcastCreatedDocumentationError,
  broadcastUpdatedDocumentationError,
  broadcastDocumentationMovedError,
  broadcastDocumentationDeletedError,
} from 'serviceWorker/broadcasts/documentations';

export function handleDocumentationCreationError(
  httpRequests: { remove: (id: number) => Promise<any> },
  request: HttpRequestEntity,
  error: unknown
): Promise<void> {
  if (
    error instanceof Error &&
    (error.message.includes('Cannot find documentation') ||
      error.message.includes('Cannot access documentation'))
  ) {
    return httpRequests.remove(request._id);
  }

  return Promise.resolve();
}

export function handleDocumentCreationError(
  httpRequests: { remove: (id: number) => Promise<any> },
  request: HttpRequestEntity,
  error: unknown
): Promise<void> {
  if (
    error instanceof Error &&
    (error.message.includes('Cannot find document') ||
      error.message.includes('Cannot access document'))
  ) {
    return httpRequests.remove(request._id);
  }

  return Promise.resolve();
}

export function broadcastDocumentationError(
  request: HttpRequestEntity,
  error: any
): void {
  if (request.method === 'POST' && !isAbortError(error)) {
    broadcastCreatedDocumentationError(error);
  } else if (
    request.method === 'PUT' &&
    request.entityType !== HttpRequestModelType.documentationMove
  ) {
    broadcastUpdatedDocumentationError(error);
  } else if (
    request.method === 'PUT' &&
    request.entityType === HttpRequestModelType.documentationMove
  ) {
    broadcastDocumentationMovedError(error);
  } else if (request.method === 'DELETE') {
    broadcastDocumentationDeletedError(error);
  }
}
