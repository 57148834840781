import { ReactElement, useCallback } from 'react';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { handleFormValidation, validate } from './validation';
import { useFieldValueForm } from 'components/dataCreationForms/withFieldValueForm';
import { FieldValueForm } from 'components/fieldSettings/form';
import { useFieldValuesForm } from 'components/dataCreationForms/withFieldValuesForm';

export function FieldValueWizard({ instantSubmit }): ReactElement {
  const {
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    formName,
  } = useFieldValueForm();

  const { submitForm: submitWholeForm } = useFieldValuesForm();
  const func = useCallback(() => {
    if (!instantSubmit) {
      return releaseSubmitEvent();
    }

    releaseSubmitEvent();
    submitWholeForm();
  }, [instantSubmit, releaseSubmitEvent, submitWholeForm]);

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <FieldValueForm
        isPosting={isPosting}
        onCancel={closeDialog}
        onSubmit={func}
      />
    </WithInputForm>
  );
}
