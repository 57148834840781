import { BroadcastChannel } from 'broadcast-channel';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { startProjectDelete } from 'shared/domain/project/startProjectDelete';
import { startProjectRestore } from 'shared/domain/project/startProjectRestore';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { useGetAllProjects } from 'hooks/useGetAllProjects';
import { useMountedRef } from 'hooks/useMountRef';
import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { ChannelNames } from 'shared/domain/channelNames';
import { useAuth0 } from 'services/auth0/react-auth0.spa';
import { LabelledEntity } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';
import { sortProjectNames } from '../../../views/projects/selection/model';
import { setModelsAfterDeletion, setModelsAfterRestore } from '../common';
import { ProjectModel } from 'shared/domain/project/types/model';

const initialProjects = { items: [], total: 0 };

export type ProjectsResponse = { items: ProjectModel[]; total: number };
export type ProjectsContextType = {
  projects: ProjectsResponse;
  loading: boolean;
  fetchProjects: () => void;
};

const ProjectsContext = React.createContext<
  ProjectsContextType | undefined
>(undefined);

const WithProjects: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const mountedRef = useMountedRef();
  const [projects, setProjects] =
    useState<ProjectsResponse>(initialProjects);
  const [loading, setLoading] = useState<boolean>(true);
  const createDialog = useDialog();
  const { getAll: getAllProjects } = useGetAllProjects();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.projectChannel);
    let mounted = true;

    broadcast.onmessage = (event: Message): void => {
      if (!mounted) {
        return;
      }

      if (event.data && event.type === DomainMessagesTypes.allProjects) {
        const hasData = event.data.hasAll;
        if (hasData) {
          setProjects({
            items: event.data.projects.sort(sortProjectNames),
            total: event.data.projects.length,
          });
          setLoading(false);
        }
      }
    };

    broadcast.postMessage({
      type: DomainMessagesTypes.getState,
    });

    return (): void => {
      broadcast.close();
      mounted = false;
    };
  }, []);

  const fetchProjects = useCallback(
    (retryCounter = 0) => {
      if (isAuthenticated) {
        debugLog(`WithProjects: fetchProjects retry no ${retryCounter}`);
        getAllProjects()
          .then((response) => {
            if (!mountedRef.current) {
              return;
            }

            setProjects({
              items: response.projects.sort(sortProjectNames),
              total: response.projects.length,
            });
            setLoading(false);
          })
          .catch((e) => {
            if (!mountedRef.current) {
              return;
            }
            if (retryCounter < 3) {
              return fetchProjects(retryCounter + 1);
            }
            setLoading(false);
            displayGenericErrorToaster(dispatch);
          });
      }
    },
    [dispatch, getAllProjects, isAuthenticated, mountedRef]
  );

  useEffect(() => {
    if (loading) {
      fetchProjects();
      const interval = setInterval(fetchProjects, 1000);
      return () => clearInterval(interval);
    }
  }, [fetchProjects, loading]);

  const deleteProject = useCallback(
    (project: LabelledEntity) => {
      createDialog({
        title: (
          <FormattedMessage id='dialog_confirmation_delete_project_title' />
        ),
        description: (
          <FormattedMessage
            id='dialog_confirmation_delete_project_description'
            values={{ projectName: project.label }}
          />
        ),
        customControllerLabels: ['general_cancel', 'general_archive'],
      }).then(() => {
        startProjectDelete(project._id, project._id);
        setProjects((prev) => setModelsAfterDeletion(prev, project));
      });
    },
    [createDialog]
  );

  const restoreProject = useCallback((project: LabelledEntity) => {
    startProjectRestore(project._id, project._id);
    setProjects((prev) => setModelsAfterRestore(prev, project));
  }, []);
  const ctx = {
    projects: projects,
    loading: loading,
    fetchProjects: fetchProjects,
    deleteProject,
    restoreProject,
  };

  return (
    <ProjectsContext.Provider value={ctx}>
      {children}
    </ProjectsContext.Provider>
  );
};

const withProjects =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithProjects>
      <Component {...props} />
    </WithProjects>
  );

function useProjects(): ProjectsContextType {
  const context = React.useContext(ProjectsContext);
  if (context === undefined) {
    throw new Error(
      'useProjects must be used within a ProjectsContextProvider'
    );
  }
  return context;
}

export { WithProjects, useProjects, withProjects };
