import { BroadcastChannel } from 'broadcast-channel';
import {
  isUploadedMessage,
  Message,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';

export function listenToDirectoryUploadedMessage(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean
): BroadcastChannel {
  const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);

  broadcast.onmessage = (event: Message): void => {
    if (!isUploadedMessage(event) || !messageFilter(event)) {
      return;
    }

    if (event.error) {
      return errorCallback(event.error);
    }

    if (event.data) {
      return successCallback(event.data);
    }
  };

  return broadcast;
}
