import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { CreateIssueModel } from './issueModel';

function startIssueCreationUseCase(issue: CreateIssueModel): void {
  debugLog('startIssueCreationUseCase');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.ISSUES,
    method: ServiceMethods.CREATE,
    data: issue,
  });
  broadcast.close();
}

export default startIssueCreationUseCase;
