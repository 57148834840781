import { DeviceInfo } from 'shared/types/logger';
import { isMobileDevice } from '../utils/isMobileDevice';

export function clearSensitiveDataFromDetailsObject(
  obj: object | null | undefined
): object | null | undefined {
  obj &&
    Object.keys(obj).forEach((key) => {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'string') {
          if (key.toLowerCase() === 'authorization') {
            obj[key] = 'The value was cleared out due security reasons.';
          }
        } else {
          if (typeof obj[key] === 'object' && obj[key] !== null) {
            clearSensitiveDataFromDetailsObject(obj[key] as object);
          }
        }
      }
    });
  return obj;
}

export const getDeviceInfo = (): DeviceInfo => {
  const nav = getNavigator();
  return {
    device: {
      isMobile: isMobileDevice(nav?.userAgent),
      userAgent: nav?.userAgent,
      logicalProcessors: nav?.hardwareConcurrency,
    },
  };
};

export function getNavigator(): Navigator {
  return typeof window !== 'undefined' ? window.navigator : self.navigator;
}

type AsyncLogDetails = {
  totalSpace?: number;
  usedSpace?: number;
  idbSpace?: number;
  userAgent?: string;
  logicalProcessors?: number;
};

export async function extractNavigatorDetails(
  navigator?: Navigator
): Promise<AsyncLogDetails> {
  let quota, totalSpace, usedSpace, idbSpace;
  try {
    quota = await navigator?.storage?.estimate();
    totalSpace = quota?.quota;
    usedSpace = quota?.usage;
    // @ts-ignore usageDetails are experimental feature, not supported by default in ts
    idbSpace = quota?.usageDetails?.indexedDB;
  } catch (e) {}

  return {
    totalSpace,
    usedSpace,
    idbSpace,
  };
}
