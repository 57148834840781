import { Tooltip } from '@mui/material';
import { MouseEventHandler, ReactElement } from 'react';
import { Color } from 'shared/utils/colors';
const drawingDataForSelectedPin =
  'M0 15.2388C0 6.81391 6.81391 0 15.2388 0C23.6636 0 30.4775 6.81391 30.4775 15.2388C30.4775 26.6678 15.2388 43.5393 15.2388 43.5393C15.2388 43.5393 0 26.6678 0 15.2388Z';
const whiteCircle = <circle cx='15.5' cy='15.5' r='6.5' fill='white' />;

export function InteractiveSelectedPinIcon({
  handleMouseDown,
  handleMouseMove,
  handleMouseUp,
  tooltip,
}: {
  handleMouseDown: MouseEventHandler;
  handleMouseMove: MouseEventHandler;
  handleMouseUp: MouseEventHandler;
  tooltip: string;
}): ReactElement {
  return (
    <Tooltip title={tooltip}>
      <g
        cursor='pointer'
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d={drawingDataForSelectedPin}
          fill={Color.purple}
        />
        {whiteCircle}
      </g>
    </Tooltip>
  );
}
