import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
export const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  () =>
    createStyles({
      iconButton: {
        position: 'relative',
        left: '-8px',
        alignSelf: 'center',
        fontSize: '1rem',
      },
      button: {
        textTransform: 'none',
        '&.MuiButton-text': {
          padding: ({ isMobile }): string =>
            isMobile ? '0 0 0 4px' : '6px 8px',
        },
        '&.MuiButton-root': {
          minWidth: '48px',
          fontSize: '1rem',
        },
      },
    })
);
