import React from 'react';
import DateFilterComponent from './DateFilter';
import { styles } from '../FilterSidebarStyles';
import { Props as PresentationalProps } from './Presentational';
import FilterItem from './FilterItem';

type DateFiltersProps = {
  elems: PresentationalProps['createdFilters']['dateFields'];
};

export const DateFilters: React.FC<DateFiltersProps> = ({ elems }) => {
  return (
    <>
      {elems.map((filter) => (
        <DateFilterComponent
          key={filter.filterName}
          label={filter.label}
          filterName={filter.filterName}
          {...filter.filterValues}
        />
      ))}
    </>
  );
};

type FilterItemsProps = {
  elems: PresentationalProps['createdFilters']['generalFields'];
};

export const FilterItems: React.FC<FilterItemsProps> = ({ elems }) => {
  const classes = styles();

  return (
    <>
      {elems.map((filter) => (
        <FilterItem
          {...filter}
          key={filter.filterName}
          classes={classes}
        />
      ))}
    </>
  );
};
