import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  controllerAdornment: {
    position: 'relative',
    right: '0.5rem',
    color: '#757575',
    opacity: '0.54',
    fontSize: '1.4rem',
    '&.error': { color: '#f44336' },
  },
  controllerWrapper: {
    borderRadius: '4px',
    border: '1px solid #8A8A8A',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    boxSizing: 'border-box',
    '&.error': { borderColor: '#E53935' },
    '&.disabled:not(.error)': { borderColor: '#B1B1B1 !important' },
    '&:hover': { borderColor: '#141414' },
  },
});
