import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debounceNext } from 'serviceWorker/helpers/debounce';

export function makeDefaultSync(
  type: RepositoryMessagesTypes
): () => void {
  return debounceNext((): void => {
    self.dispatchEvent(new Event(type));
  }, 2000);
}
