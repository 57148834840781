import {
  ChecklistItemModel,
  InspectionTemplateModel,
} from 'shared/domain/template/inspectionTemplateModel';
import { Dispatch } from 'redux';
import { customMessageErrorToaster } from 'redux/actions/toasterActions';
import { FormChecklistItem } from '../form/types';
import { TemplateDefaultValues } from './types';

const EMPTY_CHECKLIST_ITEM: FormChecklistItem = {
  uniqueKeyId: '',
  summary: '',
  description: '',
  errors: {
    summary: undefined,
    description: undefined,
  },
  graphicUploader: undefined,
};

function mongoObjectId(): string {
  const timestamp = ((new Date().getTime() / 1000) | 0).toString(16);
  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

export function createChecklistItem(): FormChecklistItem {
  return {
    ...EMPTY_CHECKLIST_ITEM,
    uniqueKeyId: mongoObjectId(),
  };
}

export function emptyDefaultValues(): TemplateDefaultValues {
  return {
    _id: '',
    summary: '',
    generalNorm: {
      description: '',
      documents: [],
    },
    process: null,
    checklist: [createChecklistItem()],
  };
}

function toFormChecklistItem(
  checklistItem: ChecklistItemModel
): FormChecklistItem {
  return {
    _id: checklistItem._id,
    uniqueKeyId: checklistItem._id,
    summary: checklistItem.summary,
    description: checklistItem.norm.description,
    errors: {
      summary: undefined,
      description: undefined,
    },
    graphicUploader: undefined,
  };
}

export function toDefaultValues(
  template: InspectionTemplateModel
): TemplateDefaultValues {
  return {
    _id: template._id,
    process: template.process,
    summary: template.summary,
    generalNorm: {
      description: template.generalNorm.description,
      documents: [],
    },
    checklist: template.checklist.reduce<FormChecklistItem[]>(
      (checklist, checklistItem) => {
        if (!checklistItem.deleted) {
          checklist.push(toFormChecklistItem(checklistItem));
        }

        return checklist;
      },
      []
    ),
  };
}

export function deepCopy<T>(object: T): T {
  return JSON.parse(JSON.stringify(object));
}

export function permissionsRedirectWithMessage(
  redirect: () => void,
  dispatch: Dispatch,
  message: string
): void {
  dispatch(customMessageErrorToaster(message));
  redirect();
}
