import { useGetAllEnvironmentalAspects } from 'hooks/useGetAllEnvironmentalAspects';
import { EnvironmentalAspectOnView } from 'presentation/fieldValue/fieldValueOnView';
import { FieldValueFactory } from '../../common/fieldValueFactory';

export const {
  Component: WithEnvironmentalAspects,
  composeComponent: withEnvironmentalAspects,
  useContextFunction: useEnvironmentalAspects,
} = FieldValueFactory<EnvironmentalAspectOnView>(
  useGetAllEnvironmentalAspects
);
