import { EMPTY_cost } from './contants';
import {
  CostsArray,
  FakeEvent,
  InitialCost,
  InputHandler,
  Last,
  PreventEmptyCost,
  RemovedCost,
  SingleFinalCost,
  SumCosts,
  UpdateCostParser,
  UpdatedCost,
  ValidateCosts,
} from './types';
import { LabelledEntity } from 'shared/types/commonView';

export const isEmptyCost = (cost: SingleFinalCost): boolean =>
  !isNumber(cost.outstanding) &&
  !isNumber(cost.settled) &&
  !cost.coveredBy;

export const cleanSet = (set: CostsArray): CostsArray =>
  set.filter((cost) => !isEmptyCost(cost));

/** Gets last array elem. If empty array or undefined - return default value */
export const last: Last = (arr = [], def = '') =>
  arr[arr.length - 1] || def;

/** Allows to set target.value on state object */
export const inputHandler: InputHandler =
  (id, key, call) =>
  (e): any =>
    call(id, key, e.target.value);

export const sumCosts: SumCosts = (costs = [], sumVariant) => {
  if (costs.length === 0) {
    return 0;
  }
  const reducer = (a: number, b: number): number => a + b;
  let mapper: (cost: SingleFinalCost) => number;

  if (sumVariant === 'settled') {
    mapper = (cost): number => {
      const { settled } = cost || EMPTY_cost;
      return parseFloat(settled || '0');
    };
  } else if (sumVariant === 'outstanding') {
    mapper = (cost): number => {
      const { outstanding } = cost || EMPTY_cost;
      return parseFloat(outstanding || '0');
    };
  } else {
    mapper = (cost): number => {
      const { settled, outstanding } = cost || EMPTY_cost;
      return parseFloat(settled || '0') + parseFloat(outstanding || '0');
    };
  }

  return costs.map(mapper).reduce(reducer);
};

export const formatMoney = (value: number, currency = 'EUR'): string =>
  new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

export const parseUpdate: UpdateCostParser = (data) => {
  const emptyCovered = (
    elem: LabelledEntity | undefined | null
  ): LabelledEntity | undefined | null => (elem?._id ? elem : null);
  return data.map(({ settled, outstanding, coveredBy }) => ({
    settled: parseFloat(settled) || 0,
    outstanding: parseFloat(outstanding) || 0,
    coveredBy: emptyCovered(coveredBy),
  }));
};

export const fakeEvent: FakeEvent = (value, name) => ({
  target: { name, value },
});

export const initialCost: InitialCost = (value = []) =>
  value?.length ? value : [{ ...EMPTY_cost }];

export const updatedCost: UpdatedCost<keyof SingleFinalCost> = function <
  T extends keyof SingleFinalCost,
>(arr: CostsArray, idx: number, key: T, value: SingleFinalCost[T]) {
  const newSet = [...arr];
  newSet[idx][key] = value;
  return newSet;
};

export const removedCost: RemovedCost = (arr, idx) => {
  const newSet = [...arr];
  newSet.splice(idx, 1);
  return newSet;
};

export const preventEmptyCost: PreventEmptyCost = (arr) => {
  return arr.length ? arr : [{ ...EMPTY_cost }];
};

export const isNumber = (val: unknown): boolean => {
  const isNumber = typeof val === 'number';
  if (isNumber) {
    return true;
  }

  const isString = typeof val === 'string';
  if (!isString) {
    return false;
  }

  const isNaN = Number.isNaN(parseInt(val as string)); //at this point, we know it's string
  const isEmpty = val === '';
  return !isNaN && !isEmpty;
};

export const validateCosts: ValidateCosts = (costs) =>
  costs.map(isEmptyCost).filter((e) => e);
