import db from './index';
import { clear as correctiveActionTypesClear } from './correctiveActionTypesService';
import { broadcastClearCorrectiveActionTypes } from 'serviceWorker/broadcasts/correctiveActionTypes';
import { entityDbClearFactory } from './entityDbClearFactory';
import { CorrectiveActionTypeEntity } from 'serviceWorker/repository/correctiveActionType/entity';
import {
  makeDefaultAddBatch,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';

export const clear = entityDbClearFactory(
  db,
  ['correctiveActionTypes', 'correctiveActionTypesService'],
  () => db.correctiveActionTypes.clear(),
  correctiveActionTypesClear,
  broadcastClearCorrectiveActionTypes
);

export const quantity = makeDefaultCount<CorrectiveActionTypeEntity>(
  db,
  db.correctiveActionTypes
);

export const get = makeDefaultGetMany<CorrectiveActionTypeEntity>(
  db,
  db.correctiveActionTypes
);

export const getOne = makeDefaultGetOne<
  string,
  CorrectiveActionTypeEntity
>(db, db.correctiveActionTypes, '_id');

export const addBatch = makeDefaultAddBatch<CorrectiveActionTypeEntity>(
  db,
  db.correctiveActionTypes
);

export const updateBatch = addBatch;

export const removeBatch = makeDefaultRemoveBatch<
  string,
  CorrectiveActionTypeEntity
>(db, db.correctiveActionTypes, '_id');
