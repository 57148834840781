import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { isStandard } from 'shared/domain/role/isStandard';
import { isViewer } from 'shared/domain/role/isViewer';
import { UserInDto } from 'shared/dtos/in/user';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoCreateCompanyWizardContainer } from 'views/companies/wizard/create/container';
import { MemoEditCompanyWizardContainer } from 'views/companies/wizard/edit/container';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';

export const AuthorizableCreateCompanyWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserStandardOrViewer = useMemo(() => {
      return isStandard(currentUser.role) || isViewer(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isManagerOrAdmin(currentUser.role)}
        unauthorized={isCurrentUserStandardOrViewer}
        authorizedComponent={MemoCreateCompanyWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableEditCompanyWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserStandardOrViewer = useMemo(() => {
      return isStandard(currentUser.role) || isViewer(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isManagerOrAdmin(currentUser.role)}
        unauthorized={isCurrentUserStandardOrViewer}
        authorizedComponent={MemoEditCompanyWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
