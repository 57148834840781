import { Point } from 'shared/domain/area/types';
import { ReactElement } from 'react';
import {
  coordinatesToPercentageString,
  useSizeScaledToContainerWidth,
} from './common';
import { Color } from 'shared/utils/colors';

export const lineBaseSize = 1.5;

export function Line({
  start,
  end,
  color,
  dotted,
}: {
  start: Point;
  end: Point;
  color: Color;
  dotted?: boolean;
}): ReactElement | null {
  return (
    <line
      x1={coordinatesToPercentageString(start.x)}
      y1={coordinatesToPercentageString(start.y)}
      x2={coordinatesToPercentageString(end.x)}
      y2={coordinatesToPercentageString(end.y)}
      height='100%'
      width='100%'
      pointerEvents='none'
      stroke={color as string}
      strokeWidth={useSizeScaledToContainerWidth(lineBaseSize)}
      strokeDasharray={dotted ? 4 : 'none'}
    />
  );
}
