import {
  accessorCreationDate,
  createdByAccessor,
} from 'components/table/accessors';
import { SORT_TYPE } from 'shared/filter/sort/types';
import { ColumnShape } from 'react-base-table';
import { IntlShape } from 'react-intl';
import { HashMap } from 'shared/types/commonView';
import { DocumentationNameWithIconCell } from './DocumentationNameWithIconCell';
import { StandardHeader } from 'components/table/ColumnHeader';
import { cellCreatedBy } from 'components/table/renderers/cell/createdBy';
import { createCellCreationDate } from 'components/table/renderers/cell/dates';

export function createColumns(
  intl: IntlShape,
  timezone: string,
  columnWidths: HashMap<number | undefined>
): ColumnShape[] {
  return [
    {
      key: 'name',
      index: 1,
      width: columnWidths['name'] ?? 340,
      flexGrow: 0,
      flexShrink: 0,
      sortable: false,
      resizable: true,
      // react 18 types
      // @ts-ignore
      headerRenderer: StandardHeader,
      // react 18 types
      // @ts-ignore
      cellRenderer: DocumentationNameWithIconCell,
      label: intl.formatMessage({
        id: 'documentation_column_label_name',
      }),
      dataKey: 'name',
      style: {
        padding: '0 16px',
        justifyContent: 'flex-start',
      },
    },
    {
      key: 'number',
      index: 2,
      width: columnWidths['number'] ?? 140,
      flexGrow: 0,
      flexShrink: 0,
      sortable: false,
      resizable: true,
      // react 18 types
      // @ts-ignore
      headerRenderer: StandardHeader,
      label: intl.formatMessage({
        id: 'documentation_column_label_number',
      }),
      dataKey: 'number',
      style: {
        padding: '0 16px',
        justifyContent: 'flex-start',
      },
    },
    {
      key: 'createdBy',
      index: 3,
      width: columnWidths['createdBy'] ?? 140,
      flexGrow: 0,
      flexShrink: 0,
      sortable: false,
      resizable: true,
      sortType: SORT_TYPE.STRING,
      accessor: createdByAccessor,
      // react 18 types
      // @ts-ignore
      cellRenderer: cellCreatedBy,
      // react 18 types
      // @ts-ignore
      headerRenderer: StandardHeader,
      label: intl.formatMessage({
        id: 'issue_preview_label_created_by',
      }),
      dataKey: 'createdBy',
      style: {
        padding: '0 16px',
        justifyContent: 'flex-start',
      },
    },
    {
      key: 'createdAt',
      index: 4,
      width: columnWidths['createdAt'] ?? 140,
      flexGrow: 0,
      flexShrink: 0,
      sortable: false,
      resizable: true,
      sortType: SORT_TYPE.NUMBERS,
      accessor: accessorCreationDate,
      // react 18 types
      // @ts-ignore
      cellRenderer: createCellCreationDate(timezone),
      // react 18 types
      // @ts-ignore
      headerRenderer: StandardHeader,
      label: intl.formatMessage({
        id: 'issue_preview_label_creation_date',
      }),
      dataKey: 'createdAt',
      style: {
        padding: '0 16px',
        justifyContent: 'flex-start',
      },
    },
  ];
}
