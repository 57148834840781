import { EditOutlined } from '@mui/icons-material';
import { Box, Divider, Grid } from '@mui/material';
import { useIsOffline } from 'components/common/withIsOffline';
import { ButtonTextIcon } from 'components/general';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { MemoPreviewField } from 'components/general/PreviewField';
import {
  Permission,
  noPermission,
  noPermissionInOffline,
} from 'helpers/permission';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toUserRole } from 'redux/selectors/role';
import { EDIT_ORGANIZATION_PERMISSIONS } from 'setup/permissions';

type SingleOrganizationProps = {
  _id: string;
  name: string;
  label: string;
  contactEmail: string;
  logoUsedInPdfHeadersSignedUrl?: string;
};

export const SingleOrganization = (
  props: SingleOrganizationProps
): React.ReactElement => {
  //TODO https://hustro.atlassian.net/browse/PT-3821
  const { _id, name, label, contactEmail, logoUsedInPdfHeadersSignedUrl } =
    props;

  const intl = useIntl();
  const userRole = useSelector(toUserRole);
  const isOffline = useIsOffline();

  const editPermission = useMemo((): Permission => {
    if (!EDIT_ORGANIZATION_PERMISSIONS[userRole]) {
      return noPermission('tooltip_action_forbidden');
    }
    if (isOffline) {
      return noPermissionInOffline();
    }
    return {
      permission: true,
    };
  }, [userRole, isOffline]);

  const history = useHistory();
  const edit = useCallback((): void => {
    history.push(`/organization/${_id}/edit`);
  }, [_id, history]);
  return (
    <Box sx={{ px: 4, py: 1 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8}>
          <h1>{label}</h1>
        </Grid>
        <Grid item xs={12} sm={4} sx={{ my: 'auto' }}>
          <MemoForbiddenTooltip
            visibleWhen={!editPermission.permission}
            reason={editPermission.reason}
            inlined
          >
            <ButtonTextIcon
              disabled={!editPermission.permission}
              startIcon={EditOutlined}
              onClick={edit}
            >
              {intl.formatMessage({
                id: 'general_edit',
              })}
            </ButtonTextIcon>
          </MemoForbiddenTooltip>
        </Grid>
      </Grid>

      <Divider sx={{ my: 1 }} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <MemoPreviewField
            label={intl.formatMessage({ id: 'organization_label' })}
            value={label}
            dense={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MemoPreviewField
            // className={specificClasses.field}
            label={intl.formatMessage({ id: 'organization_name' })}
            value={name}
            dense={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <MemoPreviewField
            label={intl.formatMessage({ id: 'organization_contactEmail' })}
            value={contactEmail}
            dense={true}
          />
        </Grid>
        {logoUsedInPdfHeadersSignedUrl && (
          <Grid item xs={12} sx={{ p: 2 }}>
            <img
              src={logoUsedInPdfHeadersSignedUrl}
              style={{ width: '100%', height: '100%', maxWidth: '300px' }}
              data-qa='organization-logo'
              alt='This logo is used in pdf headers'
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
