import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { inspectionTemplateEntityToModel } from 'serviceWorker/repository/inspectionTemplate/inspectionTemplateEntityToModel';
import { ChannelNames } from 'shared/domain/channelNames';
import * as inspectionTemplates from 'serviceWorker/db/inspectionTemplates';
import * as inspectionTemplatesService from 'serviceWorker/db/inspectionTemplatesService';
import { awaitEntities } from '../factories/awaitEntities';
import { waitForMessage } from '../factories/waitForMessage';
import { getUsersSynchronized } from '../users/getUsers';

const waitForInspectionTemplates = waitForMessage(
  ChannelNames.inspectionTemplateChannel,
  DomainMessagesTypes.allInspectionTemplates
);

export async function getInspectionTemplateById(
  id: string
): Promise<InspectionTemplateModel | undefined> {
  await awaitEntities(
    inspectionTemplatesService,
    waitForInspectionTemplates
  );
  const [entity, users] = await Promise.all([
    inspectionTemplates.getOne(id),
    getUsersSynchronized(),
  ]);
  return entity
    ? inspectionTemplateEntityToModel(entity, users)
    : undefined;
}
