import { LabelledUser } from 'shared/domain/commonModel';
import { LevelOnView } from 'shared/domain/level/types/view';
import { DateTime } from 'luxon';
export const FORM_NAME = 'level';
export function createEmptyLevel(
  now: DateTime<true>,
  author: LabelledUser
): LevelOnView {
  return {
    _id: '',
    site: '',
    label: '',
    deleted: false,
    createdAt: now,
    modifiedAt: now,
    modifiedBy: {
      _id: author._id,
      email: author.email,
      label: author.label,
    },
    createdBy: {
      _id: author._id,
      email: author.email,
      label: author.label,
    },
  };
}
