import { sanitizeInput } from './misc';
import { normalizeForSearch } from '../shared/utils/search';

export const isPhraseFound = (
  properties: any[],
  searchPhrase: string
): boolean => {
  let result = false;
  properties.forEach((property) => {
    if (result || (typeof property !== 'number' && !property?.length)) {
      return;
    }

    let _property =
      typeof property === 'number' ? property.toString() : property;

    const sanitizedPhrase = sanitizeInput(searchPhrase);

    if (
      normalizeForSearch(_property).search(
        normalizeForSearch(sanitizedPhrase)
      ) !== -1
    ) {
      result = true;
    }
  });
  return result;
};
