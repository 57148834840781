import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';
import { EditVisibleFieldCustomEventDetail } from './types';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncVisibleFields);

const edit = (e: EditVisibleFieldCustomEventDetail): void => {
  debugLog('EDIT VISIBLE FIELDS EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditVisibleFieldCustomEventDetail>(
      RepositoryMessagesTypes.editVisibleFields,
      {
        detail: {
          uniqueId: e.uniqueId,
          visibleFieldsEdit: e.visibleFieldsEdit,
        },
      }
    )
  );
};

export default { sync, edit };
