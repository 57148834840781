import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '12px 12px 12px 11px',
      background: '#FFFFFF',
      border: '1px solid #EEEEEE',
      boxSizing: 'border-box',
      borderRadius: '3px',
      width: '100%',
      maxWidth: '815px',
      fontFamily: 'Archivo',
      fontStyle: 'normal',
      letterSpacing: '0em',
      textAlign: 'left',
      '&.archived': {
        opacity: '65%',
      },

      '& h1': {
        marginTop: 0,
        fontSize: '0.875rem',
        fontWeight: '600',
        lineHeight: '15px',
      },
      '& > span': {
        fontSize: '0.625rem',
        fontWeight: '400',
        lineHeight: '11px',
        color: '#979797',
      },
    },
    card: {
      cursor: 'pointer',
      transition:
        'box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      '&:hover': {
        boxShadow:
          '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
      },
    },
    cardBottom: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '11px',
    },
    chipsWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      '& > div': {
        marginRight: '4px',
      },
    },
    cardLoading: {
      position: 'relative',
      height: '60px',
      width: '215px',
    },
  })
);
