import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';
import { MessageDescriptor } from 'react-intl';

export enum InspectionTemplateStatusSize {
  small = 'small',
  default = 'default',
}

export type StatusFormatter = (descriptor: MessageDescriptor) => string;

export type InspectionTemplateStatusProps = {
  status: InspectionTemplateStatus;
  size?: InspectionTemplateStatusSize;
  displayArchived?: boolean;
};
