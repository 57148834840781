import React from 'react';
import { makeStyles } from '@mui/styles';
import { useIntl } from 'react-intl';
import { ReactComponent as BrowserIcon } from 'assets/icons/browser.svg';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '100px',
    width: '100%',
  },
  title: {
    fontFamily: 'Archivo',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0em',
    textAlign: 'center',
    marginTop: '41px',
    marginBottom: '24px',
  },
});

export function SelectTemplatePrompt(): React.ReactElement {
  const classes = useStyles();
  const intl = useIntl();

  return (
    <div className={classes.wrapper}>
      <BrowserIcon />
      <span className={classes.title}>
        {intl.formatMessage({
          id: 'inspection_templates_list_select_prompt',
        })}
      </span>
    </div>
  );
}
