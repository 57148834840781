import { useEffect, useState, Dispatch } from 'react';
import { useBlobFileStorage } from 'components/core/BlobFileStorage';
import { DocumentCreateModel } from 'shared/domain/document/documentCreateModel';
import {
  MAX_DISPLAY_FILE_SIZE,
  MAX_FILES,
  MAX_FILE_NAME_LENGTH,
} from 'shared/domain/document/documentLimits';
import { DocumentBindings } from 'shared/domain/document/documentModel';
import {
  deleteDocumentLocally,
  saveDocumentChangeLocally,
  saveDocumentsLocally,
  saveDrawingLocally,
} from 'shared/domain/document/saveDocumentLocally';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';
import { customMessageErrorToaster } from 'redux/actions/toasterActions';
import { debugLog } from 'shared/logger/debugLog';
import { DEFAULT_CONFIG } from './constants';
import {
  areEqual,
  atomicCall,
  MAX_FILE_DESCRIPTION_LENGTH,
} from './helpers';
import {
  Changes,
  ChangeSet,
  CONFIG_TYPE,
  DrawingType,
  FileReadSuccess,
  FileReadError,
  FileReadResult,
  GU,
  ResultType,
  UseGraphicUploader,
  UploaderFile,
} from './types';
import { toDrawingModel } from 'presentation/document/drawingToDrawingModel';
import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { DisplayedFile, useStoreFiles } from '../gallery/filesStore';
import { DocumentOnView } from 'presentation/document/documentOnView';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { MainImage } from '../mainImage';
import { useMountedRef } from 'hooks/useMountRef';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { Store } from 'hooks/createStore';
import { BroadcastChannel } from 'broadcast-channel';

type ProcessSingleFile = (
  file: File,
  config: CONFIG_TYPE
) => Promise<FileReadResult>;

function getExtension(fileName: string): string {
  const nameSplitOnDot = fileName.split('.');
  if (nameSplitOnDot.length < 2) {
    return '';
  }
  return nameSplitOnDot.pop() as string;
}

function toFileReadResult(
  resolve: (result: FileReadResult) => void,
  file: File,
  config: CONFIG_TYPE
): void {
  if ((config.max_file_size || 0) < file.size) {
    return resolve({
      resultType: ResultType.error,
      error: 'max_file_size',
    } as FileReadError);
  }

  const fileSource = URL.createObjectURL(file);

  return resolve({
    file,
    resultType: ResultType.success,
    src:
      file.size > MAX_DISPLAY_FILE_SIZE
        ? DOCUMENT_ERROR_SOURCE
        : fileSource,
    size: file.size,
    title: file.name.substring(0, MAX_FILE_NAME_LENGTH),
    description: file.name.substring(0, MAX_FILE_DESCRIPTION_LENGTH),
    type: file.type,
    data: {
      extension: getExtension(file.name),
      name: file.name,
    },
    downloadSrc: fileSource,
    syncStatus: SyncStatus.PENDING,
    drawingSyncStatus: SyncStatus.PENDING,
    thumbnail:
      file.size > MAX_DISPLAY_FILE_SIZE
        ? DOCUMENT_ERROR_SOURCE
        : fileSource,
  } as FileReadSuccess);
}

const processSingleFile: ProcessSingleFile = (file, config) =>
  new Promise((res) => {
    if (!file) {
      const error: FileReadError = {
        resultType: ResultType.error,
        error: 'no_file',
      };
      return res(error);
    }
    toFileReadResult(res, file, config);
  });

const failed = (res: FileReadResult[]): FileReadError[] => {
  return res.filter(
    (elem): elem is FileReadError => elem.resultType === ResultType.error
  );
};

const preventOverflow = <T>(
  oldFilesLength: number,
  newFiles: T[]
): T[] => {
  if (oldFilesLength > MAX_FILES) {
    return [];
  }
  return newFiles.slice(0, MAX_FILES - oldFilesLength);
};

const showErrors = (
  dispatch: Dispatch<any>,
  failedFiles: FileReadError[],
  filesLength: number
): AnyAction | void => {
  const overflow = filesLength > MAX_FILES ? filesLength - MAX_FILES : 0;

  if (overflow) {
    return dispatch(customMessageErrorToaster('too_many_files'));
  }

  if (failedFiles.length) {
    if (failedFiles.length > 1) {
      return dispatch(
        customMessageErrorToaster('many_files_error', {
          amount: failedFiles.length,
        })
      );
    } else {
      return dispatch(
        customMessageErrorToaster('file_upload_error_file_tooBig')
      );
    }
  }
};

function sortWithMainFile<T extends Omit<FileReadSuccess, 'resultType'>>(
  files: (T & FileReadSuccess & { loading: boolean; _id?: string })[],
  mainImageHandler?: MainImage | undefined
): (T & FileReadSuccess & { loading: boolean; _id?: string })[] {
  if (!mainImageHandler) return files;
  const mainImageId = mainImageHandler.getMainImageStore().get();
  if (!mainImageId) return files;
  return files.sort((a, b) => {
    if (a._id === mainImageId) return -1;
    if (b._id === mainImageId) return 1;
    // @ts-ignore
    return Date.parse(b.modifiedAt) - Date.parse(a.modifiedAt);
  });
}

function processInitialFiles<
  T extends Omit<FileReadSuccess, 'resultType'>,
>(
  files: T[],
  mainImageHandler?: MainImage | undefined
): (T & FileReadSuccess & { loading: boolean; _id?: string })[] {
  if (!files) {
    return [];
  }

  return sortWithMainFile(
    files.map((file) => ({
      ...file,
      resultType: ResultType.success,
      loading: Boolean(file.signedRequest),
    })),
    mainImageHandler
  );
}

function toCreateDocumentModel(
  bindings: DocumentBindings,
  file: FileReadSuccess
): DocumentCreateModel {
  return {
    data: file.data as any,
    description: file.description,
    title: file.title,
    type: file.type,
    deleted: false,
    localData: file.downloadSrc,
    syncStatus: SyncStatus.PENDING,
    drawingSyncStatus: SyncStatus.PENDING,
    ...bindings,
  };
}

// @ts-ignore WIP LOCAL GU uses UseGraphicUploader types while in progress PT-3769
export const useGraphicUploader: UseGraphicUploader = (
  initialFiles,
  settings
) => {
  const config = { ...DEFAULT_CONFIG, ...settings };
  const blobFileStorage = useBlobFileStorage();
  const [initialFilesState, setInitialFilesState] = useState(
    initialFiles || []
  );
  const [loading, setLoading] = useState(false);
  const filesStore = useStoreFiles<DocumentOnView | FileReadSuccess>(
    processInitialFiles(
      initialFilesState,
      config.mainImageHandlerRef?.current
    )
  );
  useFilesUploadRemoteUpdate(filesStore);
  const [drawings, setDrawings] = useState<DrawingType[]>([]);
  const [changeSet, setChangeset] = useState<ChangeSet>({});

  const dispatch = useDispatch();

  const handleChangeSet = (
    localId: number | undefined,
    changes: Changes
  ): void => {
    if (!localId) {
      return;
    }
    const newChangeset = { ...changeSet };
    const place = newChangeset[localId];
    newChangeset[localId] = {
      ...(place || {}),
      ...changes,
    };
    setChangeset(newChangeset);
  };

  useEffect(() => {
    if (!areEqual(initialFilesState, initialFiles || [])) {
      setInitialFilesState(initialFiles || []);
    }
  }, [initialFilesState, initialFiles]);

  useEffect(() => {
    filesStore.set(
      processInitialFiles(
        initialFilesState,
        config.mainImageHandlerRef?.current
      )
    );
  }, [filesStore, initialFilesState, config.mainImageHandlerRef]);

  const addFile: GU['addFile'] = async (filesToAdd) => {
    if (config.previewOnly) {
      return;
    }

    const processedFiles: FileReadResult[] = await Promise.all(
      filesToAdd.map((file) => processSingleFile(file, config))
    );
    const failedFiles = failed(processedFiles);
    const successFiles = processedFiles.filter(
      (elem): elem is FileReadSuccess => !(elem as FileReadError).error
    );

    const allSuccessfulFiles: UploaderFile<
      DocumentOnView | FileReadSuccess
    >[] = [...filesStore.get(), ...successFiles];

    const newSet: UploaderFile<DocumentOnView | FileReadSuccess>[] =
      allSuccessfulFiles.slice(0, MAX_FILES);
    const toAdd = preventOverflow(filesStore.get().length, successFiles);
    showErrors(dispatch, failedFiles, allSuccessfulFiles.length);

    filesStore.set(newSet);

    const fallback = (): void => {
      if (!config.baseUrl) {
        return;
      }
      // TODO: GU remove ! PT-3769
      const bindings = config
        .documentBinderFactory?.(config.baseUrl)
        .binding()!;
      saveDocumentsLocally(
        config.baseUrl,
        toAdd.map((fileToAdd) =>
          toCreateDocumentModel(bindings, fileToAdd)
        )
      )
        .then((res) => {
          let index = 0;
          const prev = filesStore.get();
          const newFiles = prev.map((file) => {
            if (file.localId === undefined) {
              file.localId = res[index];
              index += 1;
            }
            return file;
          });
          filesStore.set(newFiles);
          debugLog('save document locally response', res);
        })
        .catch((error) => {
          debugLog('error save documents locally', error);
        });
    };

    atomicCall(
      config,
      'upload',
      [toAdd, drawings, filesStore.get()],
      fallback
    );
  };

  const editFile: GU['editFile'] = (index, key, value) => {
    if (config.previewOnly) {
      return;
    }
    const newSet = [...filesStore.get()];
    newSet[index] = {
      ...newSet[index],
      [key]: value,
    };
    filesStore.set(newSet);
    handleChangeSet(newSet[index].localId, { [key]: value });
    const fallback = (): Promise<unknown> => {
      return saveDocumentChangeLocally({
        localId: newSet[index].localId as number,
        description: value as string,
      }).catch((error: any) => {
        debugLog('error', error);
      });
    };

    atomicCall(
      config,
      'edit',
      [filesStore.get()[index], { [key]: value }],
      fallback
    );
  };

  const removeFile: GU['removeFile'] = (id) => {
    if (config.previewOnly) {
      return;
    }
    const file = filesStore.get()[id];
    const fallback = (): void => {
      if (typeof file.localId === 'number') {
        debugLog('atomicCall callRemoveFile', id, file.localId);

        deleteDocumentLocally(file.localId!)
          .then((res) => {
            debugLog('removeFile atomic call result', res);
          })
          .catch((error) => {
            debugLog('removeFile atomic call error', error);
          });
      }
    };
    atomicCall(config, 'remove', [file], fallback);
    handleChangeSet(file.localId, { deleted: true });
    const newSet = [...filesStore.get()];
    newSet.splice(id, 1);
    filesStore.set(newSet);

    setDrawings((prev) => {
      const newDrawings = [...prev];
      if (newDrawings[id]) {
        newDrawings.splice(id, 1);
      }
      return newDrawings;
    });
  };

  const addDrawing: GU['addDrawing'] = (drawing, index) => {
    if (drawing?.clear && filesStore.get()[index].localId) {
      blobFileStorage.removeItem(`${filesStore.get()[index].localId}`);
    } else if (drawing?.mergedImageUrl) {
      blobFileStorage.setItem(
        drawing.mergedImageUrl,
        drawing.mergedImageUrl
      );
    }
    setDrawings((prev: DrawingType[]): DrawingType[] => {
      const newSet: DrawingType[] = [...prev];
      newSet[index] = drawing;
      return newSet;
    });

    const fallback = (): void => {
      const currentDrawings: DrawingType[] = [];
      currentDrawings[index] = drawing;
      debugLog('save drawing locally');
      const localId = filesStore.get()[index].localId;
      if (localId === undefined) {
        return;
      }
      saveDrawingLocally(toDrawingModel(drawing), {
        localId,
      });
    };

    atomicCall(
      config,
      'uploadDrawing',
      [filesStore.get(), drawing, index],
      fallback
    );
  };

  const saveFilesLocally: GU['uploadFiles'] = (
    baseUrl,
    onDone,
    options
  ) => {
    if (config.previewOnly || !baseUrl) {
      return;
    }
    // TODO: GU remove ! PT-3769
    const bindings = config.documentBinderFactory?.(baseUrl).binding()!;
    const filesToSave = filesStore
      .get()
      .filter(
        (file): file is DisplayedFile<FileReadSuccess> => !file.localId
      )
      .map((fileToSave) => toCreateDocumentModel(bindings, fileToSave));

    debugLog('saveFilesLocally, uploadFiles', filesToSave);
    return saveDocumentsLocally(baseUrl, filesToSave)
      .then((res) => {
        let recentlySavedFileIdsIndex = 0;
        const allSavedFiles = filesStore.get().map((file) => {
          if (file.localId === undefined) {
            const localId = res[recentlySavedFileIdsIndex];
            recentlySavedFileIdsIndex += 1;
            if (localId === undefined) {
              throw new Error(
                'Cannot assign undefined to localId property of saved file.'
              );
            }
            file.localId = localId;
            return {
              ...file,
              localId,
            } as FileReadSuccess & {
              localId: number;
            };
          }
          return file as FileReadSuccess & {
            localId: number;
          };
        });

        const drawingPromises: Promise<{ localId: number }>[] = [];
        drawings.forEach((drawing, index) => {
          if (!drawing) {
            return;
          }

          const promise = saveDrawingLocally(toDrawingModel(drawing), {
            localId: allSavedFiles[index].localId,
          });
          drawingPromises.push(promise);
        });

        return Promise.all(drawingPromises);
      })
      .then(() => {
        setLoading(false);
        if (onDone) return onDone();
      });
  };

  const executeChangeset = (): Promise<unknown>[] => {
    const asArray = Object.entries(changeSet).map(([key, value]) => ({
      localId: parseFloat(key),
      changes: value,
    }));

    const toRemove = asArray.filter((elem) => elem.changes.deleted);
    const toEdit = asArray.filter((elem) => !elem.changes.deleted);

    const toRemoveCalls = toRemove.map((data) => {
      debugLog('toRemoveCalls', data);
      return deleteDocumentLocally(data.localId);
    });
    const toEditCalls = toEdit.map((data) => {
      debugLog('toEditCalls', data);
      return saveDocumentChangeLocally({
        localId: data.localId,
        description: data.changes.description as string,
      });
    });

    return [...toRemoveCalls, ...toEditCalls];
  };
  return {
    filesStore,
    drawings,
    addDrawing,
    addFile,
    editFile,
    removeFile,
    uploadFiles: saveFilesLocally,
    loading,
    config,
    executeChangeset,
    changeSet,
  };
};

function useFilesUploadRemoteUpdate(filesStore: Store<any[]>): void {
  const mountedRef = useMountedRef();
  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.documentChannel);

    broadcast.onmessage = (event: Message): void => {
      if (
        !mountedRef.current ||
        event.type !== DomainMessagesTypes.uploadedEntity
      ) {
        return;
      }

      const file = filesStore
        .get()
        .find((d) => d.localId === event.data.localId);
      if (!file) {
        return;
      }
      file._id = event.data._id;
    };

    return (): void => {
      broadcast.close();
    };
  }, [mountedRef, filesStore]);
}
