import { ApiChannelMessage } from 'shared/domain/messages/message';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { BroadcastChannel } from 'broadcast-channel';
import { LevelEditModel } from './types/model';

export function startLevelEdit(
  level: LevelEditModel,
  uniqueId: string
): void {
  debugLog('startLevelEdit', level, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<{
      level: LevelEditModel;
      uniqueId: string;
    }>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.LEVELS,
    method: ServiceMethods.EDIT,
    data: {
      level,
      uniqueId,
    },
  });
  broadcast.close();
}
