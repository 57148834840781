import React, { ReactElement, useCallback } from 'react';
import { Chip } from '@mui/material';
import { MemoInspectionTemplateStatus } from '../Status';
import { InspectionTemplateStatusSize } from '../Status/types';
import { InspectionTemplateCardProps } from './types';
import { useStyles } from './styles';
import classNames from 'classnames';
import useIsMobile from 'hooks/useIsMobile';
import { MemoProcessIcon } from 'components/common/ProcessIcon';
import { ProcessIconSize } from 'components/common/ProcessIcon/types';

export const INSPECTION_TEMPLATE_CARD_PREFIX = `inspection-template-card`;

function InspectionTemplateCard(
  props: InspectionTemplateCardProps
): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const { template, handleClick, isActive } = props;

  const onClick = useCallback(() => {
    handleClick(template);
  }, [handleClick, template]);

  return (
    <div
      id={`${INSPECTION_TEMPLATE_CARD_PREFIX}-${template._id}`}
      className={classNames(
        classes.card,
        isActive ? 'active' : null,
        template.fromUrl ? 'additional' : null
      )}
      onClick={onClick}
    >
      <p className={classes.cardTitle}>{template.summary}</p>
      <div className={classes.cardBottom}>
        <div className={classes.cardBottomGroup}>
          <MemoProcessIcon
            color={template.renderableProcess.color}
            code={template.renderableProcess.code}
            size={ProcessIconSize.small}
          />
          <Chip className={classes.cardChip} label={template.code} />
        </div>
        <div className={classes.cardBottomGroup}>
          <MemoInspectionTemplateStatus
            status={template.status}
            size={InspectionTemplateStatusSize.small}
            displayArchived={template.deleted}
          />
        </div>
      </div>
    </div>
  );
}

export const MemoInspectionTemplateCard = React.memo(
  InspectionTemplateCard
);
