import React, { ReactElement } from 'react';
import cn from 'classnames';
import Actions from './actions';
import styles from './styles';
import { ActionsType } from './types';
import useIsMobile from 'hooks/useIsMobile';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { GalleryDivider } from './galleryDivider';
import { useGalleryContext } from '.';

function titleSelector(file: { title: string } | undefined): string {
  return file?.title || '';
}

export function TopBar(props: {
  onCloseClick: () => void;
  actions: ActionsType;
  idx: number;
  setDrawMode: (bool: boolean) => void;
  disableActions: boolean;
  preview?: boolean;
  PrefixedActions: ({
    disabledClass,
  }: {
    disabledClass: string;
  }) => ReactElement | null;
  SuffixedActions: ({
    disabledClass,
  }: {
    disabledClass: string;
  }) => ReactElement | null;
}): ReactElement {
  const {
    actions,
    idx,
    onCloseClick,
    setDrawMode,
    disableActions,
    PrefixedActions,
    SuffixedActions,
  } = props;

  const isMobile = useIsMobile();
  const cs = styles({ isMobile });
  const { useMainDisplayedFile, EditComponent, editTitleId } =
    useGalleryContext();
  const [title] = useMainDisplayedFile(titleSelector);

  return (
    <div className={cs.topBarContainer}>
      <div className={cs.topBar}>
        <button
          className={cn(cs.textButton, cs.topElement)}
          onClick={onCloseClick}
        >
          <ArrowBackIcon />
          <p className={cs.title}>{title}</p>
        </button>
        <Actions
          actions={actions}
          idx={idx}
          setDrawMode={setDrawMode}
          disabled={disableActions}
          EditComponent={EditComponent}
          titleId={editTitleId}
          PrefixedActions={PrefixedActions}
          SuffixedActions={SuffixedActions}
        />
      </div>
      <GalleryDivider />
    </div>
  );
}
