import { HashMap } from 'shared/types/commonView';

function objHasKey<T>(obj: T, key: PropertyKey): key is keyof T {
  return Object.prototype.hasOwnProperty.call(obj, key);
}

const deepFind = <T>(obj: any, path: string): T[] => {
  if (!path) {
    return [];
  }
  let paths = path.split('.');
  const result: T[] = [];
  let current = obj;

  paths.forEach((path, index) => {
    if (!current) {
      return;
    }
    const pathFound = current && current[path] !== undefined;
    if (Array.isArray(current)) {
      current.forEach((el) => {
        const foundInObjectFromArray: T[] = deepFind(el, path);
        if (foundInObjectFromArray) {
          foundInObjectFromArray.forEach((elem) => result.push(elem));
        }
      });
    }
    const isLastIteration = index + 1 === paths.length;
    if (pathFound && isLastIteration) {
      result.push(current[path]);
    }
    current = current[path];
  });
  return result;
};

const iterate = <T>(obj: HashMap<any>, key: string): T | undefined => {
  let result;

  for (let property in obj) {
    if (objHasKey(obj, property)) {
      if (property === key) {
        return obj[key]; // returns the value
      } else if (typeof obj[property] === 'object') {
        // in case it is an object
        result = iterate(obj[property], key);

        if (typeof result !== 'undefined') {
          return result as T;
        }
      }
    }
  }
};

const isObject = <T>(value: unknown): value is T => {
  return typeof value === 'object' && Boolean(value);
};

export { deepFind, iterate, objHasKey, isObject };
