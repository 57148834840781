import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) => ({
  filterCount: {
    fontSize: '0.5rem',
    position: 'absolute' as 'absolute',
    top: 2,
    right: 2,
    padding: '2px 4px',
    backgroundColor: theme.palette.primary.main,
    border: `thin solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    color: 'rgba(0, 0, 0, 0.85)',
  },
  icon: {
    color: '#000',
  },
}));

export default useStyles;
