import React from 'react';
import EntityNotFoundView from './EntityNotFoundView';
import { Spinner } from 'components/core';
import { ISSUE_REQUEST_STATUS, IssueStatusType } from './helpers';

export const issueStateController = (
  issueState: IssueStatusType
): React.ReactElement | null => {
  switch (issueState.status) {
    case ISSUE_REQUEST_STATUS.LOADING:
      return (
        <Spinner reason='issueStateController ISSUE_REQUEST_STATUS.LOADING' />
      );
    case ISSUE_REQUEST_STATUS.NOT_FOUND:
    case ISSUE_REQUEST_STATUS.FORBIDDEN:
    case ISSUE_REQUEST_STATUS.UNKNOWN:
      return <EntityNotFoundView entity={issueState.entity} />;
    default:
      return null;
  }
};
