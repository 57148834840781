import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoSingleCompany } from 'components/company/Single';
import { Spinner } from 'components/core';
import {
  useCompany,
  withCompany,
} from 'components/dataProviders/withCompany';
import { withSites } from 'components/dataProviders/withSites';
import { useGetCompany } from 'hooks/useGetCompany';
import { memo, ReactElement } from 'react';
import { useParams } from 'react-router-dom';

function SingleCompanyView(): ReactElement | null {
  const { id } = useParams<{ id: string }>();
  const { company, loading } = useCompany();
  useGetCompany(id, true);

  if (loading || !company) {
    return <Spinner reason='SingleCompanyView loading || !company' />;
  }

  return <MemoSingleCompany {...company} />;
}

export const MemoCompany = memo(
  withFetcherReady(
    withProcessesData(withSites(withCompany(SingleCompanyView)))
  )
);
