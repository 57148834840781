import { IssueFieldNames } from './types/fieldNames';
import { IssueFormSchema } from './types/issueFormSchema';

export function getIssueFormSchema(): IssueFormSchema {
  return [
    {
      sectionTitleId: 'form_section_problem_related_attachments',
      fields: [IssueFieldNames.documents],
    },
    {
      sectionTitleId: 'form_section_key_information',
      fields: [
        IssueFieldNames.title,
        IssueFieldNames.site,
        IssueFieldNames.level,
        IssueFieldNames.positionOnMap,
        IssueFieldNames.subcontractors,
        IssueFieldNames.contractNumbers,
        IssueFieldNames.workTypes,
        IssueFieldNames.impact,
        IssueFieldNames.targetAmount,
        IssueFieldNames.completedAmount,
        IssueFieldNames.amountPercentage,
        IssueFieldNames.description,
        IssueFieldNames.hashtag,
      ],
    },
    {
      sectionTitleId: 'form_section_time',
      fields: [
        IssueFieldNames.targetStartDate,
        IssueFieldNames.finalStartDate,
        IssueFieldNames.startDateDelay,
        IssueFieldNames.targetCompletionDate,
        IssueFieldNames.finalCompletionDate,
        IssueFieldNames.completionDateDelay,
        IssueFieldNames.detectionDate,
        IssueFieldNames.creationDate,
        IssueFieldNames.modificationDate,
      ],
    },
    {
      sectionTitleId: 'form_section_involved_personel',
      fields: [
        IssueFieldNames.assignee,
        IssueFieldNames.numberOfEmployees,
        IssueFieldNames.executedByCompanies,
        IssueFieldNames.executor,
        IssueFieldNames.subcontractorRepresentative,
        IssueFieldNames.createdBy,
        IssueFieldNames.modifiedBy,
      ],
    },
    {
      sectionTitleId: 'form_section_corrective_action',
      fields: [
        IssueFieldNames.solutionProposal,
        IssueFieldNames.proposedCorrectiveAction,
        IssueFieldNames.solutionMethod,
      ],
    },
    {
      sectionTitleId: 'form_section_issue_analysis',
      fields: [
        IssueFieldNames.rootCauses,
        IssueFieldNames.effect,
        IssueFieldNames.hazardCategory,
        IssueFieldNames.personUnableToWork,
        IssueFieldNames.daysOfInabilityToWork,
        IssueFieldNames.circumstances,
        IssueFieldNames.environmentalAspect,
        IssueFieldNames.spilledSubstance,
        IssueFieldNames.soilLeakageScale,
        IssueFieldNames.waterLeakageScale,
        IssueFieldNames.contaminatedSoilScale,
      ],
    },
    {
      sectionTitleId: 'form_section_fine',
      fields: [
        IssueFieldNames.decisionToImposeFine,
        IssueFieldNames.expectedFine,
        IssueFieldNames.imposedFine,
      ],
    },
    {
      sectionTitleId: 'form_section_costs',
      fields: [
        IssueFieldNames.estimatedCost,
        IssueFieldNames.finalCost,
        IssueFieldNames.costCode,
      ],
    },
  ];
}
