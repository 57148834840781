import { Point } from 'shared/domain/area/types';
import { ReactElement } from 'react';
import { lineBaseSize } from './Line';
import { useSizeScaledToContainerWidth } from './common';
import { Color } from 'shared/utils/colors';

export function Polyline({
  points,
  lineColor,
  backgroundPatternName,
  backgroundColor,
  vpwidth,
  vpheight,
  displayAsPolygon,
  pointerEvents,
}: {
  points: Point[];
  lineColor: Color;
  backgroundPatternName?:
    | 'plannedAreaBackgroundPattern'
    | 'plannedAreaBackgroundPatternMarked';
  backgroundColor?: Color;
  vpwidth: number;
  vpheight: number;
  displayAsPolygon: boolean;
  pointerEvents: 'none' | 'all';
}): ReactElement | null {
  let polylinePoints = '';
  points.forEach((point) => {
    polylinePoints =
      polylinePoints + `${point.x * vpwidth},${point.y * vpheight} `;
  });
  const fill = backgroundPatternName
    ? `url(#${backgroundPatternName})`
    : backgroundColor;

  const PolygonOrPolyline = displayAsPolygon ? 'polygon' : 'polyline';

  return (
    <PolygonOrPolyline
      points={polylinePoints}
      stroke={lineColor}
      strokeWidth={useSizeScaledToContainerWidth(lineBaseSize)}
      fill={fill}
      pointerEvents={pointerEvents}
    />
  );
}
