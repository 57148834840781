import { Message } from 'shared/domain/messages/message';
import { useRef, useEffect, useCallback, useMemo } from 'react';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';

type SubscribeCallback = (event: Message) => void;
type Unsubscribe = () => void;
export type Subscribe = (callback: SubscribeCallback) => Unsubscribe;
type ChannelListenerContextType = {
  subscribe: Subscribe;
};

export function useChannelListener(
  channel: ChannelNames
): ChannelListenerContextType {
  const subscribersRef = useRef(new Set<SubscribeCallback>());

  useEffect(() => {
    const broadcast = new BroadcastChannel(channel);
    const subscribers = subscribersRef.current;

    broadcast.onmessage = (event: Message): void => {
      subscribers.forEach((callback) => callback(event));
    };

    return () => {
      broadcast.close();
      subscribers.clear();
    };
  }, [channel]);

  const subscribe: Subscribe = useCallback(
    (callback: SubscribeCallback) => {
      subscribersRef.current.add(callback);
      return () => subscribersRef.current.delete(callback);
    },
    []
  );

  return useMemo(() => {
    return {
      subscribe,
    };
  }, [subscribe]);
}
