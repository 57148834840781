import { store } from 'setup/store';
import { LOCALE_LANGUAGES } from 'shared/types/locale';
import { frontendVersionWithBuildNumber } from 'shared/version';
import { buildUrl, makeStatusHandler } from './helpers';
import { FetchShape } from './types';

enum FetchMethod {
  PUT = 'PUT',
  DELETE = 'DELETE',
  GET = 'GET',
  POST = 'POST',
}

const generateHeaders = (headers: HeadersInit = {}): HeadersInit => {
  const {
    ui: { currentLocale },
  } = store.getState();
  const token = localStorage.getItem('auth0_token');

  return {
    'Content-Type': 'application/json',
    'Accept-Language': LOCALE_LANGUAGES[currentLocale],
    Authorization: `Bearer ${token}`,
    'hustro-client': 'web',
    'hustro-client-version': frontendVersionWithBuildNumber,
    ...headers,
  };
};

const defaultConfig: RequestInit = {
  mode: 'cors',
  cache: 'no-cache',
  credentials: 'same-origin',
  redirect: 'follow',
  referrerPolicy: 'no-referrer',
};

const assetConfig: RequestInit = {
  ...defaultConfig,
  cache: 'default',
};

export const Fetch: FetchShape = {
  amazonFilePOST: (url, data, requestInit = {}) => {
    return fetch(url, {
      method: FetchMethod.POST,
      headers: {
        'x-amz-acl': 'public-read',
      },
      body: data,
      ...requestInit,
    });
  },
  PUT: (url, data, requestInit = {}, fetchConfig) => {
    const fullUrl = buildUrl(url, fetchConfig?.urlBuilder);
    const method = FetchMethod.PUT;
    const statusHandler = makeStatusHandler(
      fullUrl,
      method,
      data,
      requestInit,
      fetchConfig
    );

    return fetch(fullUrl, {
      ...defaultConfig,
      method,
      headers: generateHeaders(),
      body: JSON.stringify(data),
      ...requestInit,
    })
      .then(statusHandler)
      .catch(statusHandler);
  },

  POST: (url, data, requestInit = {}, fetchConfig) => {
    const fullUrl = buildUrl(url, fetchConfig?.urlBuilder);
    const method = FetchMethod.POST;
    const statusHandler = makeStatusHandler(
      fullUrl,
      method,
      data,
      requestInit,
      fetchConfig
    );

    return fetch(fullUrl, {
      ...defaultConfig,
      method,
      headers: generateHeaders(),
      body: JSON.stringify(data),
      ...requestInit,
    })
      .then(statusHandler)
      .catch(statusHandler);
  },
  GET: (url, data, requestInit = {}, fetchConfig) => {
    const fullUrl = buildUrl(url, fetchConfig?.urlBuilder);
    const method = FetchMethod.GET;
    const statusHandler = makeStatusHandler(
      fullUrl,
      method,
      data,
      requestInit,
      fetchConfig
    );

    return fetch(fullUrl, {
      ...defaultConfig,
      method,
      headers: generateHeaders(),
      ...requestInit,
    })
      .then(statusHandler)
      .catch(statusHandler);
  },
  DELETE: (url, data, requestInit = {}, fetchConfig) => {
    const fullUrl = buildUrl(url, fetchConfig?.urlBuilder);
    const method = FetchMethod.DELETE;
    const statusHandler = makeStatusHandler(
      fullUrl,
      method,
      data,
      requestInit,
      fetchConfig
    );

    return fetch(fullUrl, {
      ...defaultConfig,
      method,
      headers: generateHeaders(),
      ...requestInit,
    })
      .then(statusHandler)
      .catch(statusHandler);
  },
  GET_ASSET: (url, _, requestInit = {}, fetchConfig) => {
    const fullUrl = buildUrl(url, fetchConfig?.urlBuilder);
    const config = {
      ...assetConfig,
      method: FetchMethod.GET,
      headers: generateHeaders(),
      ...requestInit,
    };
    return fetch(fullUrl, config).then((assetResponse) => {
      return assetResponse.json();
    });
  },
};
