import { Identificable } from 'shared/types/commonView';
import { updateOne as editDocumentation } from 'serviceWorker/db/documentations';
import { updateOne as editDirectory } from 'serviceWorker/db/directories';
import { add as addRequest } from 'serviceWorker/db/httpRequests';

import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import {
  HttpRequestModel,
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { broadcastDocumentationDeleted } from 'serviceWorker/broadcasts/documentations';
import { broadcastDirectoryDeleted } from 'serviceWorker/broadcasts/directories';
import { DeleteDocumentationUseCase } from 'shared/domain/documentation/deleteDocumentation';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';

class DocumentationDeleter implements DeleteDocumentationUseCase {
  constructor(
    private deleteDirectory: (
      directoryLocalId: number
    ) => Promise<unknown>,
    private deleteDocumentation: (
      documenationLocalId: number
    ) => Promise<unknown>,
    private addRequest: (
      request: __HttpRequestModel__
    ) => Promise<unknown>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDocumentationDeleted: (
      documentations: number[],
      directories: number[]
    ) => void
  ) {}

  async execute(
    documentations: number[],
    directories: number[]
  ): Promise<void> {
    await Promise.all([
      ...documentations.map((docLocalId) =>
        this.deleteDocumentation(docLocalId)
      ),
      ...directories.map((dirLocalId) => this.deleteDirectory(dirLocalId)),
    ]);
    this.broadcastDocumentationDeleted(documentations, directories);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'DELETE',
      data: {
        documentations,
        directories,
        projectId: currentProject?._id,
      },
      entityType: HttpRequestModelType.documentationDelete,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const documentationDeleter = new DocumentationDeleter(
  (directoryLocalId) =>
    editDirectory(directoryLocalId, {
      deleted: 1,
      syncStatus: SyncStatus.PENDING_DELETE,
    }),
  (documentationLocalId) =>
    editDocumentation(documentationLocalId, {
      deleted: 1,
      syncStatus: SyncStatus.PENDING_DELETE,
    }),
  addRequest,
  getCurrentProject,
  (documentations: number[], directories: number[]) => {
    broadcastDocumentationDeleted(documentations);
    broadcastDirectoryDeleted(directories);
  }
);
