import { saveBlobOnUsersDevice } from './download';

type FileDownload = (
  fileSource: string | undefined,
  filename: string | undefined,
  extension: string | undefined
) => Promise<void>;

type ExternalFileDownload = (
  fileSource: string,
  filename: string
) => Promise<void>;

const downloadExternalFile: ExternalFileDownload = (src, filename) => {
  if (!src || !filename) {
    return Promise.reject(new Error('Source or filename empty.'));
  }
  return fetch(src)
    .then((resp) => resp.blob())
    .then((blob) => {
      saveBlobOnUsersDevice(blob, filename);
    });
};

function ensureExtension(
  title: string,
  extension: string | undefined
): string {
  if (!extension) return title;
  return title.endsWith(`.${extension}`) ? title : `${title}.${extension}`;
}

const downloadFile: FileDownload = (src, filename, extension) => {
  if (!src || !filename) {
    return Promise.reject(new Error('Source or filename empty.'));
  }
  return typeof src === 'string'
    ? downloadExternalFile(src, ensureExtension(filename, extension))
    : saveBlobOnUsersDevice(src, ensureExtension(filename, extension));
};

export default downloadFile;
