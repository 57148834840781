import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { OnMessage } from '../types';
import { getAndBroadcastAllUsers } from './all';
import { broadcastState } from './state';

export const initUsersListener = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.userChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }
      case DomainMessagesTypes.getAllUsers: {
        await getAndBroadcastAllUsers(broadcast);
        return;
      }
    }
  };

  broadcast.onmessage = handler;
};
