import { DeleteIssueUseCase } from 'shared/domain/issue/deleteIssue';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { updateOne as editIssue } from 'serviceWorker/db/issues';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';
import { HttpRequestModel } from 'shared/domain/httpRequest/httpRequestModel';

// TODO PT-4896: fix issue request runner;
class IssueDeleter implements DeleteIssueUseCase {
  constructor(
    private deleteIssue: (issueId: string) => Promise<unknown>,
    private addRequest: (
      request: HttpRequestModel<any>
    ) => Promise<unknown>,
    private getCurrentProject: () => Promise<Identificable | undefined>
  ) {}

  async execute(issueId: string): Promise<void> {
    debugLog('DeleteIssueUseCase', issueId);

    await this.deleteIssue(issueId);
  }
}

export const issueDeleter = new IssueDeleter(
  (issueId) => editIssue(issueId, { deleted: 1 }),
  addRequest,
  getCurrentProject
);
