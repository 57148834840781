export function createSearchableString(values: string[]): string {
  return values.map((value) => normalizeForSearch(`${value}`)).join(' ');
}

export function normalizeForSearch(value: string): string {
  return `${value}`
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\u0142/g, 'l')
    .toLowerCase();
}

export function genericSearchPhraseValidation(phrase: string): boolean {
  return phrase.length > 2;
}
