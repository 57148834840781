import { ModelBase } from 'shared/domain/commonModel';
import { LabelledEntity } from 'shared/types/commonView';

export function setModelsAfterDeletion<T extends ModelBase>(
  prev: {
    items: T[];
    total: number;
  },
  deletedModel: LabelledEntity
): { items: T[]; total: number } {
  const foundModel = prev.items.find(
    (modelItem) => modelItem._id === deletedModel._id
  );
  if (!foundModel) {
    return prev;
  }
  foundModel.deleted = true;

  return {
    items: [...prev.items],
    total: prev.items.length,
  };
}

export function setModelsAfterRestore<T extends ModelBase>(
  prev: {
    items: T[];
    total: number;
  },
  restoredModel: LabelledEntity
): { items: T[]; total: number } {
  const foundModel = prev.items.find(
    (modelItem) => modelItem._id === restoredModel._id
  );
  if (!foundModel) {
    return prev;
  }
  foundModel.deleted = false;

  return {
    items: [...prev.items],
    total: prev.items.length,
  };
}
