import { StandardHeader } from 'components/table/ColumnHeader';
import { ColumnShapeSkeleton } from '../types';

export function getWorktypesColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    index: 7,
    initialWidth: 100,
    minWidth: 100,
    flexGrow: 2,
    flexShrink: 1,
    sortable: false,
    resizable: true,
    headerRenderer: StandardHeader,
    labelId: 'inspection_table_work_type',
    dataKey: 'joinedWorkTypes',
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
