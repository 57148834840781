import { ReactElement, useCallback, useMemo } from 'react';

import { byActiveAndLabel } from './model';

import { useFieldValuesForm } from 'components/dataCreationForms/withFieldValuesForm';
import { FieldSettingsPresentational } from './presentational';
import { useBacktrack } from 'components/common/withBacktrack';
import { useFieldValueForm } from 'components/dataCreationForms/withFieldValueForm';
import { SingleFieldSettingsProps } from './types';

export function SingleFieldSettings({
  useFieldValues,
  onFieldBoxClick,
  titleLabelId,
}: SingleFieldSettingsProps): ReactElement {
  const { allItems, loading } = useFieldValues();
  const { submitForm, isPosting, newFields, fieldChanges, hasChanges } =
    useFieldValuesForm();
  const { openDialog } = useFieldValueForm();
  const { back } = useBacktrack();

  const onCancel = useCallback(() => {
    back();
  }, [back]);
  const onSubmit = useCallback(() => {
    submitForm();
  }, [submitForm]);

  const sorted = useMemo(
    () => [...newFields, ...allItems].sort(byActiveAndLabel),
    [allItems, newFields]
  );

  const addNewOption = useCallback(() => {
    openDialog();
  }, [openDialog]);

  return (
    <FieldSettingsPresentational
      isPosting={isPosting}
      sorted={sorted}
      fieldChanges={fieldChanges}
      hasChanges={hasChanges}
      onSubmit={onSubmit}
      onCancel={onCancel}
      onFieldBoxClick={onFieldBoxClick}
      loading={loading}
      titleLabelId={titleLabelId}
      addNewOption={addNewOption}
    />
  );
}
