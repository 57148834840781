export enum TOASTER_TYPES {
  SUCCESS = 0,
  FAILURE = 1,
}

export enum HOSTS {
  LOCALHOST = 'localhost',
  INTEGRATION = 'integ.o.hustroapp.com',
  STAGING = 'stage.o.hustroapp.com',
  PRODUCTION = 'app.hustro.com',
}

export enum ENVIRONMENTS {
  DEVELOPMENT = 'development',
  INTEGRATION_DEV = 'integration_dev',
  INTEGRATION = 'integration',
  STAGING = 'staging',
  STAGING_DEV = 'staging_dev',
  PRODUCTION = 'production',
}
