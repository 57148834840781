import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
} from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardActionArea } from '@mui/material';
import { useStyles } from './styles';

type SmallChartCardProps = PropsWithChildren<{
  loading: boolean;
  redirectUrl: string;
}>;

export function SmallChartCard({
  children,
  redirectUrl,
  loading,
}: SmallChartCardProps): ReactElement {
  const history = useHistory();
  const classes = useStyles();

  const redirectTo = useCallback(() => {
    history.push(redirectUrl);
  }, [history, redirectUrl]);

  return (
    <Card className={classes.card}>
      <CardActionArea
        className={classes.actionArea}
        onClick={redirectTo}
        disabled={loading}
      >
        <div className={classes.childrenContainer}>{children}</div>
      </CardActionArea>
    </Card>
  );
}
