import { DateTime, Duration, ToRelativeCalendarOptions } from 'luxon';

export type EditedAt = {
  period: string | null;
  now: boolean;
};

export function lastEdit(
  dateNow: DateTime,
  date: DateTime,
  locale: string = 'en'
): EditedAt {
  const difference = dateNow.diff(date, [
    'years',
    'months',
    'weeks',
    'days',
    'hours',
    'minutes',
    'seconds',
  ]);

  const unit = unitFromDifference(difference);

  if (unit === 'seconds') {
    return {
      period: null,
      now: true,
    };
  }

  return {
    period: date.setLocale(locale).toRelativeCalendar({
      base: dateNow,
      unit,
    }),
    now: false,
  };
}

function unitFromDifference(
  difference: Duration
): ToRelativeCalendarOptions['unit'] {
  if (difference.years) {
    return 'years';
  }
  if (difference.months) {
    return 'months';
  }
  if (difference.weeks) {
    return 'weeks';
  }
  if (difference.days) {
    return 'days';
  }
  if (difference.hours) {
    return 'hours';
  }
  if (difference.minutes) {
    return 'minutes';
  }
  return 'seconds';
}
