import * as Sentry from '@sentry/browser';
import { makeFetchTransport } from '@sentry/browser';
import { getSentryConfig } from 'shared/logger/config/getSentryConfig';
import { LogData } from 'shared/types/logger';
import { version } from '../../../package.json';

export async function initSentryInSW(): Promise<void> {
  if (!process.env.REACT_APP_DISABLE_SENTRY) {
    Sentry.init({
      ...getSentryConfig('ServiceWorker', version),
      transport: makeFetchTransport,
      integrations: [Sentry.extraErrorDataIntegration()],
      // beforeSend: (event) => {
      //   Sentry.withScope((scope) => {
      //     // //@ts-ignore https://stackoverflow.com/questions/37006008/typescript-index-signature-is-missing-in-type
      //     // setup && scope.setTags(setup);
      //   });
      //   return event;
      // },
    });
  }
}

export function swSentryLog(data: LogData): void {
  if (!process.env.REACT_APP_DISABLE_SENTRY) {
    Sentry.withScope((scope) => {
      scope.setLevel(data.level);
      scope.setUser({ email: data.user_email, id: data.user_id });
    });
    Sentry.captureException(data);
  }
}
