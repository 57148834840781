import { Tooltip } from '@mui/material';
import { Point } from 'shared/domain/area/types';
import { MouseEventHandler, ReactElement } from 'react';
import { Color } from 'shared/utils/colors';
import { Polyline } from './Polyline';
import { positioning } from './common';
const viewportHeight = 1000;

export function ClosedArea({
  points,
  widthToHeightRatio,
  onClick,
  onMouseOver,
  onMouseLeave,
  backgroundColor,
  patternName,
  lineColor,
  tooltip,
  cursor,
  zIndex,
  disableInteraction,
}: {
  points: Point[];
  widthToHeightRatio: number;
  onClick: MouseEventHandler;
  onMouseOver: MouseEventHandler;
  onMouseLeave: MouseEventHandler;
  backgroundColor?: Color;
  patternName?:
    | 'plannedAreaBackgroundPattern'
    | 'plannedAreaBackgroundPatternMarked';
  lineColor: Color;
  tooltip?: string;
  cursor: 'auto' | 'pointer' | 'crosshair';
  zIndex?: number;
  disableInteraction?: boolean;
}): ReactElement {
  const viewportWidth = viewportHeight * widthToHeightRatio;

  const areaSvg = (
    <svg
      style={{ ...positioning, zIndex: zIndex }}
      viewBox={`0 0 ${viewportWidth} ${viewportHeight}`}
      preserveAspectRatio='none'
      data-qa='closed-area'
      pointerEvents='none'
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      //@ts-ignore
      // onTouchStart={onClick}
    >
      <g cursor={cursor}>
        <Polyline
          points={points}
          lineColor={lineColor}
          backgroundColor={backgroundColor}
          backgroundPatternName={patternName}
          vpheight={viewportHeight}
          vpwidth={viewportWidth}
          displayAsPolygon={true}
          // interaction is disabled on lcoation preview and when resizing other area
          pointerEvents={disableInteraction ? 'none' : 'all'}
        />
      </g>
    </svg>
  );
  if (tooltip) {
    return <Tooltip title={tooltip}>{areaSvg}</Tooltip>;
  }
  return areaSvg;
}
