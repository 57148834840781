import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { Process } from 'shared/types/process';

export type Field = StaticField | DynamicField;

export type StaticField = {
  fieldName: IssueFieldNames;
  canBeDisabled: false;
  labelId: string;
  processes: Process[];
  dependsOn?: IssueFieldNames | IssueFieldNames[];
};
export type DynamicField = {
  fieldName: IssueFieldNames;
  canBeDisabled: true;
  labelId: string;
  processes?: undefined;
  dependsOn?: IssueFieldNames | IssueFieldNames[];
};

export function getAvailableFields(): Field[] {
  return [
    {
      fieldName: IssueFieldNames.assignee,
      canBeDisabled: true,
      labelId: 'primaryField_Assignee',
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.circumstances,
      canBeDisabled: true,
      labelId: 'extendedField_Circumstances',
    },
    {
      fieldName: IssueFieldNames.completedAmount,
      canBeDisabled: true,
      labelId: 'extendedField_Completed_amount',
    },
    {
      fieldName: IssueFieldNames.completionDateDelay,
      canBeDisabled: true,
      labelId: 'issue_delay_label',
      dependsOn: [
        IssueFieldNames.targetCompletionDate,
        IssueFieldNames.finalCompletionDate,
      ],
    },
    {
      fieldName: IssueFieldNames.contaminatedSoilScale,
      canBeDisabled: true,
      labelId: 'extendedfield_contaminated_soil_scale',
      dependsOn: IssueFieldNames.environmentalAspect,
    },
    {
      fieldName: IssueFieldNames.contractNumbers,
      canBeDisabled: true,
      labelId: 'primaryField_Contract_numbers',
      dependsOn: IssueFieldNames.subcontractors,
    },
    {
      fieldName: IssueFieldNames.costCode,
      canBeDisabled: true,
      labelId: 'extendedField_Cost_code',
    },
    {
      fieldName: IssueFieldNames.createdBy,
      canBeDisabled: true,
      labelId: 'issue_preview_label_created_by',
    },
    {
      fieldName: IssueFieldNames.creationDate,
      canBeDisabled: true,
      labelId: 'issue_preview_label_creation_date',
    },
    {
      fieldName: IssueFieldNames.daysOfInabilityToWork,
      canBeDisabled: true,
      labelId: 'extendedField_Days_of_inability_to_work',
    },
    {
      fieldName: IssueFieldNames.decisionToImposeFine,
      canBeDisabled: true,
      labelId: 'extendedField_Decision_to_impose_a_fine',
    },
    {
      fieldName: IssueFieldNames.description,
      canBeDisabled: true,
      labelId: 'primaryField_Description',
    },
    {
      fieldName: IssueFieldNames.detectionDate,
      canBeDisabled: true,
      labelId: 'primaryField_Detection_date',
    },
    {
      fieldName: IssueFieldNames.documents,
      canBeDisabled: true,
      labelId: 'field_documents',
    },
    {
      fieldName: IssueFieldNames.effect,
      canBeDisabled: true,
      labelId: 'extendedField_Effect',
    },
    {
      fieldName: IssueFieldNames.environmentalAspect,
      canBeDisabled: false,
      labelId: 'extendedField_Environmental_aspect_category',
      processes: [Process.ENV],
    },
    {
      fieldName: IssueFieldNames.estimatedCost,
      canBeDisabled: true,
      labelId: 'extendedField_Estimated_cost',
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.executedByCompanies,
      canBeDisabled: true,
      labelId: 'extendedField_Executed_by_companies',
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.executor,
      canBeDisabled: true,
      labelId: 'primaryField_Executor',
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.expectedFine,
      canBeDisabled: true,
      labelId: 'extendedField_Expected_fine',
    },
    {
      fieldName: IssueFieldNames.finalCompletionDate,
      canBeDisabled: false,
      labelId: 'extendedField_Final_completion_date',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
    },
    {
      fieldName: IssueFieldNames.finalCost,
      canBeDisabled: true,
      labelId: 'extendedField_Final_cost',
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.finalStartDate,
      canBeDisabled: false,
      labelId: 'extendedField_Final_start_date',
      processes: [Process.TSK],
    },
    {
      fieldName: IssueFieldNames.hashtag,
      canBeDisabled: true,
      labelId: 'issue_preview_label_hashtag',
    },
    {
      fieldName: IssueFieldNames.hazardCategory,
      canBeDisabled: true,
      labelId: 'extendedField_Hazard_category',
    },
    {
      fieldName: IssueFieldNames.impact,
      canBeDisabled: true,
      labelId: 'extendedField_Impact',
    },
    {
      fieldName: IssueFieldNames.imposedFine,
      canBeDisabled: true,
      labelId: 'extendedField_Imposed_fine',
    },
    {
      fieldName: IssueFieldNames.issueNumber,
      canBeDisabled: false,
      labelId: 'pdf_issue_number_label',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
    },
    {
      fieldName: IssueFieldNames.level,
      canBeDisabled: false,
      labelId: 'primaryField_Level',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.modificationDate,
      canBeDisabled: true,
      labelId: 'issue_tableView_header_modifiedAt_date',
    },
    {
      fieldName: IssueFieldNames.modifiedBy,
      canBeDisabled: true,
      labelId: 'field_modified_by',
    },
    {
      fieldName: IssueFieldNames.personUnableToWork,
      canBeDisabled: true,
      labelId: 'extendedField_Person_unable_to_work',
    },
    {
      fieldName: IssueFieldNames.positionOnMap,
      canBeDisabled: true,
      labelId: 'primaryField_Position_on_map',
      dependsOn: IssueFieldNames.level,
    },
    {
      fieldName: IssueFieldNames.proposedCorrectiveAction,
      canBeDisabled: true,
      labelId: 'extendedField_Proposed_corrective_action',
    },
    {
      fieldName: IssueFieldNames.rootCauses,
      canBeDisabled: true,
      labelId: 'extendedField_Root_cause',
    },
    {
      fieldName: IssueFieldNames.site,
      canBeDisabled: false,
      labelId: 'primaryField_Site',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
    },
    {
      fieldName: IssueFieldNames.soilLeakageScale,
      canBeDisabled: true,
      labelId: 'extendedfield_soil_leakage_scale',
      dependsOn: IssueFieldNames.environmentalAspect,
    },
    {
      fieldName: IssueFieldNames.solutionMethod,
      canBeDisabled: true,
      labelId: 'extendedField_Final_corrective_action_description',
    },
    {
      fieldName: IssueFieldNames.solutionProposal,
      canBeDisabled: true,
      labelId: 'extendedField_Proposed_corrective_action_description',
    },
    {
      fieldName: IssueFieldNames.spilledSubstance,
      canBeDisabled: true,
      labelId: 'extendedfield_spilled_substance',
      dependsOn: IssueFieldNames.environmentalAspect,
    },
    {
      fieldName: IssueFieldNames.stage,
      canBeDisabled: false,
      labelId: 'general_stage_label',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
    },
    {
      fieldName: IssueFieldNames.startDateDelay,
      canBeDisabled: true,
      labelId: 'issue_start_date_delay_label',
      dependsOn: [
        IssueFieldNames.targetStartDate,
        IssueFieldNames.finalStartDate,
      ],
    },
    {
      fieldName: IssueFieldNames.amountPercentage,
      canBeDisabled: true,
      labelId: 'issue_amount_percentage_label',
      dependsOn: [
        IssueFieldNames.targetAmount,
        IssueFieldNames.completedAmount,
      ],
    },
    {
      fieldName: IssueFieldNames.subcontractorRepresentative,
      canBeDisabled: true,
      labelId: 'extendedField_Subcontractor_representative',
    },
    {
      fieldName: IssueFieldNames.subcontractors,
      canBeDisabled: false,
      labelId: 'primaryField_Subcontractors',
      processes: [Process.ENV, Process.HS, Process.QA],
      dependsOn: IssueFieldNames.site,
    },
    {
      fieldName: IssueFieldNames.targetAmount,
      canBeDisabled: true,
      labelId: 'extendedField_Target_amount',
    },
    {
      fieldName: IssueFieldNames.targetCompletionDate,
      canBeDisabled: false,
      labelId: 'extendedField_Target_completion_date',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
    },
    {
      fieldName: IssueFieldNames.targetStartDate,
      canBeDisabled: false,
      labelId: 'extendedField_Target_start_date',
      processes: [Process.TSK],
    },
    {
      fieldName: IssueFieldNames.title,
      canBeDisabled: false,
      labelId: 'primaryField_Title',
      processes: [Process.ENV, Process.HS, Process.QA, Process.TSK],
    },
    {
      fieldName: IssueFieldNames.waterLeakageScale,
      canBeDisabled: true,
      labelId: 'extendedfield_water_leakage_scale',
      dependsOn: IssueFieldNames.environmentalAspect,
    },
    {
      fieldName: IssueFieldNames.workTypes,
      canBeDisabled: true,
      labelId: 'extendedField_Work_type',
    },
    {
      fieldName: IssueFieldNames.numberOfEmployees,
      canBeDisabled: true,
      labelId: 'extendedField_number_of_employees',
    },
  ];
}

export function getStaticFieldsOnProcess(process: Process): StaticField[] {
  return getAvailableFields().filter((field) => {
    return !field.canBeDisabled && field.processes.includes(process);
  }) as StaticField[];
}
