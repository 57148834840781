import { Theme } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      boxSizing: 'border-box',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '100%',
      borderRadius: '4px',
      background: '#FFFFFF',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
      position: 'relative',
      marginTop: '22px',
      padding: '24px',
      '&.mt0': {
        marginTop: 0,
      },
      '&.p0': {
        padding: 0,
      },
    },
  })
);
