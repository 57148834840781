import { IssueEntity } from 'serviceWorker/repository/issue/entity';
import { Chart, FilterValues } from 'shared/types/analytics';
import { createSeriesAndLabelsFromIssuesWithFilters as environmentalAspectCalculation } from './environmentalAspect';
import { createSeriesFromIssuesWithFilters as hsEffectCalculation } from './hsEffect';
import { createSeriesAndLabelsFromIssuesWithFilters as hsHazardTypeCalculation } from './hsHazardType';
import { createSeriesAndLabelsFromIssuesWithFilters as impactCalculation } from './impact';
import { createSeriesAndLabelsFromIssuesWithFilters as originatorCalculation } from './originator';
import { createSeriesAndLabelsFromIssuesWithFilters as rootCauseCalculation } from './rootCause';
import { createSeriesAndLabelsFromIssuesWithFilters as workTypeCalculation } from './workType';

const chartCalculation = {
  [Chart.originator]: originatorCalculation,
  [Chart.rootCause]: rootCauseCalculation,
  [Chart.impact]: impactCalculation,
  [Chart.workType]: workTypeCalculation,
  [Chart.environmentalAspect]: environmentalAspectCalculation,
  [Chart.hsEffect]: hsEffectCalculation,
  [Chart.hsHazardType]: hsHazardTypeCalculation,
};

export function calculate(
  chart: Chart,
  issues: IssueEntity[],
  filters: FilterValues,
  timezone: string,
  additionalParams: any | undefined
): Promise<any> {
  return chartCalculation[chart](
    issues,
    // @ts-ignore in calculation we only care filters are of type FilterValues. I don't know yet how to handle this
    filters,
    timezone,
    additionalParams
  );
}
