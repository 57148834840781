import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';
import { StatusFormatter } from './types';

const statuses = {
  [InspectionTemplateStatus.draft]: (
    formatMessage: StatusFormatter
  ): string => {
    return formatMessage({ id: 'inspection_template_status_draft' });
  },
  [InspectionTemplateStatus.published]: (
    formatMessage: StatusFormatter
  ): string => {
    return formatMessage({ id: 'inspection_template_status_published' });
  },
};

export function statusToLabel(
  formatMessage: StatusFormatter,
  status: InspectionTemplateStatus
): string {
  return statuses[status](formatMessage);
}
