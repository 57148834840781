import { useDirectoryChannelListener } from 'components/broadcastChannelListeners/withDirectoryChannelListener';
import { DirectoryModel } from 'shared/domain/directory/directoryModel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allDirectories
  );
}

export const useGetAllDirectories = makeUseGetAll<DirectoryModel>({
  useChannelListener: useDirectoryChannelListener,
  postMessageChannel: ChannelNames.directoryChannel,
  postMessageType: DomainMessagesTypes.getAllDirectories,
  isMatchingMessage,
  entityName: 'directories',
});
