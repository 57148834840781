import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import { DialogType } from 'components/core/Dialog/common/Dialog';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { FormFieldProps } from 'components/inspection/Form/types';
import { ReportFieldsSelector } from 'components/report/ReportFieldsSelector';
import { ErrorPresentation } from 'helpers/validators';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  Dispatch,
  FC,
  ReactElement,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { projectDataSelector } from 'redux/selectors/project';
import listenToReportCreatedMessage from 'shared/domain/report/listenToReportCreatedMessage';
import {
  BaseReportModel,
  IMAGE_CONTENT_LABELS,
  ImageContent,
  IssueListReportModel,
  REPORT_FIELDS_CONTENT_LABELS,
} from 'shared/domain/report/reportModel';
import { startReportCreate } from 'shared/domain/report/startReportCreate';
import { getAvailableFields } from 'shared/domain/visibleField/availableFields';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { ISO6391LanguageCode } from 'shared/types/locale';
import { createUniqueId } from 'shared/utils/id/id';
import { anyToAppLocaleOnView } from 'shared/utils/locale/anyLocaleCodeToAppLocaleOnView';
import { CreateReportDialog } from 'views/reports/wizard/dialog';
import { EmailValue } from '../../common/withEmailInput';
import { useIssuesContext } from '../../dataProviders/withIssues';
import { MemoStatefulSingleSelect } from '../withInputForm/statefulInputsWrapper';
import {
  getSavedImageContent,
  getSavedReportFieldsContent,
  getSavedSelectedFields,
  saveFieldsContent,
  saveImageContent,
  saveSelectedFields,
} from './model';
import { IssuesReportFormFields } from './types';
import { handleFormValidation, validate } from './validation';

export type ReportFields = {
  name: string;
  message: string;
  recipients: EmailValue[];
  localeCode: LabelledEntity;
  issues: string[];
  imageContent: LabelledEntity<ImageContent>;
  fieldsContent: LabelledEntity;
  selectedFields: LabelledEntity[];
};

type CreateReportContext = {
  open: boolean;
  openDialog: () => void;
  closeDialog: () => void;
  submitForm: (values: ReportFields) => Promise<any>;
  isPosting: boolean;
  initialValues: Omit<ReportFields, 'issues'>;
  SUBMIT_EVENT_TYPE: string;
  releaseSubmitEvent: () => void;
  validate: (
    key: keyof IssuesReportFormFields,
    value: IssuesReportFormFields[keyof IssuesReportFormFields]
  ) => ErrorPresentation;
  handleFormValidation: (
    errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
    form: IssuesReportFormFields
  ) => boolean;
  ContentSelection: () => ReactElement | null;
};

function toIssueListReportModel({
  name,
  message,
  recipients,
  issues,
  localeCode,
  imageContent,
  fieldsContent,
  selectedFields,
}: ReportFields): IssueListReportModel {
  return {
    recipients: recipients.map((recipient) => recipient.email),
    name,
    message,
    issues,
    localeCode: localeCode._id,
    imageContent: imageContent._id,
    fieldsContent: fieldsContent._id,
    selectedFields: selectedFields.map((field) => field._id),
  };
}

const WithCreateReportContext = createContext<
  CreateReportContext | undefined
>(undefined);

const WithCreateReport: FC<{ children?: ReactNode }> = ({ children }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const createDialog = useDialog();
  const [open, setOpen] = useState(false);
  const openDialog = useCallback(() => {
    setOpen(true);
  }, []);
  const confirmCancel = useCancelConfirmation('report');
  const closeDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);
  const [isPosting, setIsPosting] = useState(false);

  const { getAllFilteredIssues } = useIssuesContext();
  const project = useSelector(projectDataSelector);

  const submitForm = useCallback(
    async (values) => {
      setIsPosting(true);
      const uniqueId = createUniqueId();
      const reportModel = toIssueListReportModel({
        ...values,
        issues: (await getAllFilteredIssues()).items.map(
          (issue) => issue._id
        ),
      });
      saveImageContent(project._id, values.imageContent._id);
      saveFieldsContent(values.fieldsContent._id);
      saveSelectedFields(
        project._id,
        values.selectedFields.map((field) => field._id).join(',')
      );

      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = listenToReportCreatedMessage(
          (message: IssueListReportModel) => {
            broadcast.close();
            resolve(message);
            setOpen(false);
            setIsPosting(false);
            clearTimeout(timeout);
            createDialog({
              catchOnCancel: false,
              title: <FormattedMessage id='dialog_report_sent_title' />,
              description: (
                <span>
                  <FormattedMessage id='dialog_report_sent_message' />
                </span>
              ),
              type: DialogType.info,
              customControllerLabels: ['general_ok', 'general_ok'],
            });
          },
          (error: Error) => {
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          }
        );
        timeout = setTimeout(() => {
          broadcast.close();
          reject(new Error('WithCreateReport: Timeout on upload.'));
          setIsPosting(false);
          displayGenericErrorToaster(dispatch);
        }, 60000);

        startReportCreate(reportModel, uniqueId);
      }).catch(() => {});
    },
    [dispatch, createDialog, getAllFilteredIssues, project._id]
  );

  const savedImageContent = getSavedImageContent(project._id);
  const savedFieldsContent = getSavedReportFieldsContent();
  const savedSavedSelectedFields = useMemo(
    () =>
      getSavedSelectedFields(
        intl,
        project._id,
        getAvailableFields().reduce((result, field) => {
          result[field.fieldName] = field;
          return result;
        }, {})
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [intl, project._id, open]
  );
  const initialValues: ReportFields = useMemo(() => {
    return {
      recipients: [],
      name: '',
      message: '',
      localeCode: anyToAppLocaleOnView(project.localeCode),
      issues: [],
      imageContent: {
        _id: savedImageContent,
        label: IMAGE_CONTENT_LABELS[savedImageContent],
      },
      fieldsContent: {
        _id: savedFieldsContent,
        label: intl.formatMessage({
          id: REPORT_FIELDS_CONTENT_LABELS[savedFieldsContent],
        }),
      },
      selectedFields: savedSavedSelectedFields,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    savedImageContent,
    savedFieldsContent,
    savedSavedSelectedFields,
    intl,
    open,
    project.localeCode,
  ]);

  const SUBMIT_EVENT_TYPE = 'submit-report';
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);

  const imageContentFieldProps:
    | FormFieldProps<BaseReportModel & { imageContent: ImageContent }>
    | undefined = useMemo(() => {
    return {
      formKey: 'imageContent',
      labelId: 'report_image_content_label',
      required: true,
      fieldName: 'select-report-image-content',
      available: Object.values(ImageContent).map((v) => ({
        _id: v,
        label: intl.formatMessage({ id: IMAGE_CONTENT_LABELS[v] }),
      })),
      getOptionLabel: (option) => intl.formatMessage({ id: option.label }),
      getOptionSelected: (
        option: LabelledEntity,
        value: LabelledEntity
      ): boolean => option._id === value._id,
      minRows: 3,
      reserveSpaceForHelperText: false,
      dense: false,
      'data-qa': 'report_image_content_field',
    };
  }, [intl]);

  const ContentSelection = useMemo(() => {
    return () => (
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel-report-content'
          id='panel-report-content-header'
        >
          <FormattedMessage id='report_section_content' />
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '1rem',
            }}
          >
            {imageContentFieldProps ? (
              <MemoStatefulSingleSelect {...imageContentFieldProps} />
            ) : null}

            <ReportFieldsSelector />
          </div>
        </AccordionDetails>
      </Accordion>
    );
  }, [imageContentFieldProps]);

  const ctx: CreateReportContext = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog,
      submitForm,
      isPosting,
      initialValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate,
      handleFormValidation,
      ContentSelection,
    };
  }, [
    open,
    openDialog,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    ContentSelection,
  ]);

  return (
    <WithCreateReportContext.Provider value={ctx}>
      {children}
      <CreateReportDialog />
    </WithCreateReportContext.Provider>
  );
};

function useCreateReport(): CreateReportContext {
  const context = useContext(WithCreateReportContext);
  if (context === undefined) {
    throw new Error(
      'useCreateReport must be used within an CreateReportContext'
    );
  }
  return context;
}

const withCreateReport =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithCreateReport>
      <Component {...props} />
    </WithCreateReport>
  );

export { WithCreateReport, useCreateReport, withCreateReport };
