import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';

function startDocumentDelete(localId: number): void {
  debugLog('startDocumentDelete');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTS,
    method: ServiceMethods.REMOVE,
    data: {
      localId,
    },
  });
  broadcast.close();
}

export default startDocumentDelete;
