import React, { ReactElement, useEffect } from 'react';

import Spinner from 'components/general/loaders/Spinner/Spinner';

import { useParams } from 'react-router-dom';
import { useRedirectToBrowseInspectionTemplates } from 'hooks/useRedirect';

import {
  deepCopy,
  emptyDefaultValues,
  permissionsRedirectWithMessage,
  toDefaultValues,
} from './helpers';
import {
  TemplateDefaultValues,
  TemplateWizardPopulatorProps,
} from './types';

import { useDispatch } from 'react-redux';

import { MemoTemplateWizardForm } from '../form';
import { useInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import { InspectionTemplateStatus } from 'shared/domain/template/templateStatus';

function TemplateWizardPopulator(
  props: TemplateWizardPopulatorProps
): ReactElement {
  const { id } = useParams<{ id: string }>();
  const isCreationMode = !id;
  const redirect = useRedirectToBrowseInspectionTemplates();
  const dispatch = useDispatch();
  const {
    inspectionTemplate,
    loading,
    inspectionTemplateDocuments: documents,
  } = useInspectionTemplate();

  const { submitCreation, submitEdition, publish } = props;

  const [defaultValues, setDefaultValues] =
    React.useState<TemplateDefaultValues>(emptyDefaultValues());
  const [canRender, setCanRender] = React.useState<boolean>(false);

  useEffect(() => {
    if (isCreationMode) {
      setCanRender(true);
      return;
    }
    if (!loading && inspectionTemplate) {
      if (
        inspectionTemplate.status === InspectionTemplateStatus.published
      ) {
        return permissionsRedirectWithMessage(
          redirect,
          dispatch,
          'inspection_template_edit_disabled_reason'
        );
      }

      setDefaultValues(toDefaultValues(inspectionTemplate));
      setCanRender(true);
    }
  }, [inspectionTemplate, loading, isCreationMode, redirect, dispatch]);

  return canRender ? (
    <MemoTemplateWizardForm
      originalDefaultValues={deepCopy(defaultValues)}
      defaultValues={deepCopy(defaultValues)}
      submitHandler={id ? submitEdition : submitCreation}
      publishHandler={publish}
      generalNormDocuments={documents.templateDocuments}
      idParam={id}
    />
  ) : (
    <Spinner reason='TemplateWizardPopulator canRender false' />
  );
}

export const MemoTemplateWizardPopulator = React.memo(
  TemplateWizardPopulator
);
