import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { OnMessage } from '../types';
import { getAllCurrentCompanies } from './all';
import { broadcastState } from './state';
import { BroadcastChannel } from 'broadcast-channel';

export const initCompaniesListener = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.companyChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }

      case DomainMessagesTypes.getAllCompanies: {
        await getAllCurrentCompanies(broadcast);
        return;
      }
    }
  };

  broadcast.onmessage = handler;
};
