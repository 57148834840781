import { ReactElement, useEffect, useState } from 'react';
import { useGalleryContext } from 'components/common/gallery';
import { identitySelector } from 'components/common/gallery/selectors';
import { DocumentationBaseIds } from 'shared/types/documentation';
import { isOpenSelector } from 'components/common/gallery/galleryStateStore';

import { useIssueOverlayControls } from '../DocumentationTable/WithIssueOverlayControls';
import { DocumentationIssueLocation } from './DocumentationIssueLocation';

export function DocumentationMapOverlay(): ReactElement | null {
  const [file] =
    useGalleryContext<DocumentationBaseIds>().useMainDisplayedFile(
      identitySelector
    );
  const [isOpen] = useGalleryContext().useGalleryState(isOpenSelector);
  const { issuesVisibleStore } = useIssueOverlayControls();
  const [visible, setVisible] = useState(issuesVisibleStore.get());

  useEffect(() => {
    setVisible(issuesVisibleStore.get());
    return issuesVisibleStore.subscribe(() => {
      setVisible(issuesVisibleStore.get());
    });
  }, [issuesVisibleStore]);

  if (!file || !isOpen) return null;

  return <DocumentationIssueLocation file={file} visible={visible} />;
}
