import { Identificable } from 'shared/types/commonView';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { debugLog } from 'shared/logger/debugLog';
import { VisibleFieldsEditModel } from 'shared/domain/visibleField/visibleFieldModel';
import { EditVisibleFieldsUseCase } from 'shared/domain/visibleField/editVisibleFields';
import { VisibleFieldsEditHttpRequest } from 'serviceWorker/repository/visibleField/visibleFieldsEditHttpRequest';

export class VisibleFieldsEditor implements EditVisibleFieldsUseCase {
  constructor(
    private addRequest: (
      request: VisibleFieldsEditHttpRequest
    ) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>
  ) {}

  async execute(
    visibleFieldsEditModel: VisibleFieldsEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditVisibleFieldsUseCase', visibleFieldsEditModel);

    const currentProject = await this.getCurrentProject();
    if (!currentProject?._id) {
      throw new Error('Cannot find projectId.');
    }

    await this.addRequest({
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        visibleFieldsEdit: visibleFieldsEditModel,
        projectId: currentProject._id,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.visibleField,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const visibleFieldsEditor = new VisibleFieldsEditor(
  addRequest,
  getCurrentProject
);
