import { LabelledEntity } from 'shared/types/commonView';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { OrganizationEntity } from '../types/entity';
import { OrganizationModel } from '../types/model';
import {
  OrganizationCreateOnView,
  OrganizationEditOnView,
  OrganizationOnView,
} from '../types/view';

export function organizationEntityToLabelledEntity(
  organization: OrganizationEntity
): LabelledEntity {
  return {
    _id: organization._id,
    label: organization.label,
  };
}
export function organizationModelToLabelledEntity(
  organization: OrganizationModel
): LabelledEntity {
  return {
    _id: organization._id,
    label: organization.label,
  };
}

export function organizationModelToOrganizationEditOnView(
  organization: OrganizationModel
): OrganizationEditOnView {
  return {
    _id: organization._id,
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
    logoUsedInPdfHeaders: organization.logoUsedInPdfHeaders,
  };
}

export function organizationModelToOrganizationOnView(
  organization: OrganizationModel
): OrganizationOnView {
  return {
    modifiedAt: stringToViewDate(organization.modifiedAt),
    createdAt: stringToViewDate(organization.createdAt),

    _id: organization._id,
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
    logoUsedInPdfHeaders: organization.logoUsedInPdfHeaders,
    logoUsedInPdfHeadersSignedUrl:
      organization.logoUsedInPdfHeadersSignedUrl,
  };
}

export function createEmptyOrganization(
  contactEmail: string
): OrganizationCreateOnView {
  return {
    name: '',
    label: '',
    contactEmail,
  };
}
