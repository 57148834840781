import * as dbHazardCategories from '../db/hazardCategories';
import * as dbHazardCategoriesService from '../db/hazardCategoriesService';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeBroadcastAll, makeBroadcastClear } from './factories';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { OnMessage } from './types';
import { BroadcastChannel } from 'broadcast-channel';

export const broadcastClearHazardCategories = makeBroadcastClear(
  ChannelNames.hazardCategoryChannel
);

export const broadcastAllHazardCategories = makeBroadcastAll(
  dbHazardCategoriesService,
  dbHazardCategories,
  ChannelNames.hazardCategoryChannel,
  DomainMessagesTypes.allHazardCategories
);

const init = (): void => {
  const broadcast = new BroadcastChannel(
    ChannelNames.hazardCategoryChannel
  );

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getAllHazardCategories: {
        await getAllCurrentHazardCategories(broadcast);
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

export default init;

async function getAllCurrentHazardCategories(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const items = await dbHazardCategories.get();
  const end = Date.now();
  debugLog('getAllCurrentHazardCategories time:', end - start);
  broadcast.postMessage({
    data: { items },
    type: DomainMessagesTypes.allHazardCategories,
  });

  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.HAZARD_CATEGORIES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}
