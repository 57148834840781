import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const styles = makeStyles((theme: Theme) => ({
  drawer: {
    width: 360,
    marginTop: 64,
    zIndex: 1300,
    userSelect: 'none',
  },
  headerWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 16px',
    minHeight: '56px',
    maxHeight: '56px',
    boxSizing: 'border-box',
    borderBottom: 'thin solid rgba(0, 0, 0, 0.12)',
  },
  headerTitle: {
    fontSize: '0.875rem',
    lineHeight: '0.938rem',
    fontWeight: 600,
    cursor: 'pointer',
  },
  contentWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '10px 16px',
    overflow: 'auto',
  },
  filterContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  filter: {
    marginBottom: 20,
    userSelect: 'none',
  },
  filterController: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '1.8rem',
    '& > .toggler': {
      fill: '#e0e0e0',
      fontSize: '1.8rem',
      cursor: 'pointer',
      transition: 'all 0.2s linear',
      '&:hover': {
        fill: 'rgba(0,0,0,.54)',
      },
      '&.expanded': {
        fill: 'rgba(0,0,0,.74)',
        transition: 'all 0.2s linear',
        transform: 'rotate(180deg)',
      },
      '&.filterDisabled': {
        fill: '#fafafa',
        pointerEvents: 'none',
      },
    },
  },
  filterLabel: {
    fontSize: '0.75rem',
    lineHeight: '0.938rem',
    fontWeight: 600,
  },
  filterBox: {
    minHeight: '42px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    boxSizing: 'border-box',
    padding: '4px',
    borderRadius: 4,
    border: 'thin solid #DADADA',
    cursor: 'pointer',
    transition: 'border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
    '&.active': {
      borderColor: theme.palette.primary.main,
      '&:hover': {
        borderColor: theme.palette.primary.main,
      },
    },
    '&:hover': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
    },
  },
  filterList: {
    width: '100%',
    maxHeight: 0,
    listStyle: 'none',
    padding: 0,
    marginTop: 2,
    boxSizing: 'border-box',
    zIndex: 1,
    transition: 'all 0.2s ease-out',
    border: 'thin solid transparent',
    borderRadius: 4,
    backgroundColor: '#fff',
    overflowY: 'hidden',
    '&.open': {
      maxHeight: '200px',
      border: 'thin solid #DADADA',
      transition: 'all 0.4s ease-in',
      boxShadow: '0px 2px 4px rgba(0,0,0,.2)',
      overflowY: 'auto',
      scrollbarColor: `${theme.palette.primary.light} ${theme.palette.primary.dark}`,
    },
  },
  filterChip: {
    height: '28px',
    // to prevent force new empty line
    // for chip with long name
    maxWidth: '95%',
    margin: '2px',
    backgroundColor: '#F2F2F2',
    overflow: 'hidden',
  },
  filterSearch: {
    fontSize: '1rem',
    flexGrow: 1,
    border: 0,
    outline: 0,
    fontFamily: 'inherit',
    padding: '0 0 0 4px',
    height: '28px',
    minWidth: 0,
    flexBasis: '30%',
    '&:disabled': {
      backgroundColor: 'unset',
    },
  },
  optionWrapper: {
    minHeight: '30px',
    display: 'flex',
    alignItems: 'center',
    padding: '4px 12px',
    fontSize: '0.8125rem',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: '#fafafa',
      cursor: 'pointer',
    },
    '&.optionSelected': {
      backgroundColor: theme.palette.primary.main,
    },
    '& svg': {
      fontSize: '1rem',
    },
  },
  optionsCount: {
    paddingLeft: 4,
    color: '#979797',
    '&.optionSelected': {
      color: theme.palette.text.primary,
    },
  },
  toggleIcon: {
    fill: '#dadada',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  results: {
    color: '#bbb',
  },
  filler: {
    paddingTop: 80,
  },
}));

export { styles };
