import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { userEntityToModel } from 'serviceWorker/repository/user/mappings';
import { GetUserCustomEvent } from 'serviceWorker/api/types';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUserById } from 'serviceWorker/services/users';
import { getUsersSynchronized } from 'serviceWorker/services/users/getUsers';
import { debugLog } from 'shared/logger/debugLog';

export const broadcastUploadedUser = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.userChannel
);
export const broadcastUpdatedUser = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.userChannel
);

export const getAndBroadcastUser = async (
  e: GetUserCustomEvent
): Promise<void> => {
  const broadcast = new BroadcastChannel(ChannelNames.userChannel);
  try {
    const userEntity = await getUserById(e.detail.id);
    debugLog('!!!userEntity to be broadcasted', userEntity);
    if (userEntity) {
      const users = await getUsersSynchronized();
      debugLog('!!! users', users);
      const userModel = userEntityToModel(userEntity, users);
      debugLog('!!! userModel to be broadcasted', userModel);
      broadcast.postMessage({
        data: userModel,
        type: DomainMessagesTypes.user,
      });
    } else {
      debugLog('!!!userEntity not found');

      broadcast.postMessage({
        error: Error(`could not a find user with id ${e.detail.id}`),
        type: DomainMessagesTypes.user,
      });
    }
  } catch (e) {
    debugLog('!!!error while getting user', e);
    broadcast.postMessage({ error: e, type: DomainMessagesTypes.user });
  }
  broadcast.close();
};

export const broadcastDeletedUser = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.userChannel
);
