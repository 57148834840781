import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
export const useStyles = makeStyles<
  DefaultTheme,
  { isMobile: boolean; isCurrentTab: boolean; isExpanded: boolean }
>((theme) => ({
  tab: {
    borderBottom: ({ isMobile, isCurrentTab }): string =>
      !isMobile && isCurrentTab
        ? `3px solid ${theme.palette.primary.main}`
        : '1px solid rgba(0, 0, 0, 0.12)',
    height: '100%',
    display: ({ isMobile, isExpanded, isCurrentTab }): string =>
      isMobile && !isExpanded && !isCurrentTab ? 'none' : '',
    order: ({ isMobile, isCurrentTab }) =>
      isMobile && isCurrentTab ? -1 : 'inherit',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#EEEEEE',
    },
  },
  icon: {
    display: 'inline-flex',
    verticalAlign: 'top',
    marginLeft: '10px',
  },
}));
