import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
export const useSelectProjectFormStyles = makeStyles<
  DefaultTheme,
  { isMobile: boolean }
>(() =>
  createStyles({
    root: {
      backgroundColor: '#f2f2f2',
      minHeight: '100%',
    },
    bottomSpace: {
      width: '100%',
      height: '100px',
    },
    radiosContainer: {
      display: 'flex',
      flexDirection: 'column',
      margin: '24px',
      alignItems: 'center',
      gap: '8px',
    },
    radioContainer: {
      padding: '4px',
      paddingLeft: '13px',
      borderRadius: '3px',
      backgroundColor: 'white',
      maxWidth: '758px',
      width: '100%',
      boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.15)',
      fontFamily: 'Archivo',
      fontSize: '1rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '17px',
      letterSpacing: '0em',
      display: 'flex',
      flexDirection: 'row',
      height: '72px',
      // '& > .w-100': {
      //   width: '100%',
      // },
    },
  })
);
