import React, { ReactElement, CSSProperties } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  Divider,
  DialogContent,
} from '@mui/material';
import { useStyles } from './styles';
import useIsMobile from 'hooks/useIsMobile';
import { FirstIssueDialogProps } from './types';
import { useIntl } from 'react-intl';

function createFirstIssueDialogProps(isMobile: boolean): {
  PaperProps: {
    style: CSSProperties;
  };
} {
  return {
    PaperProps: {
      style: {
        minWidth: isMobile ? '100%' : 500,
        maxWidth: 800,
      },
    },
  };
}

function FirstIssueDialog({
  firstIssueDialogOpen,
  firstIssueDialogHandleClose,
  children,
}: FirstIssueDialogProps): ReactElement {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const intl = useIntl();
  return (
    <Dialog
      {...createFirstIssueDialogProps(isMobile)}
      open={firstIssueDialogOpen}
      onClose={firstIssueDialogHandleClose}
    >
      <div className={classes.firstIssueDialogHeader}>
        <DialogTitle id='alert-dialog-title'>
          {intl.formatMessage({ id: 'toolbar_title_create_issue' })}
        </DialogTitle>
        <CloseIcon
          className={classes.firstIssueDialogIcon}
          onClick={firstIssueDialogHandleClose}
          data-qa='dialog-close-button'
          id='closeFirstIssueDialogButton'
        />
      </div>

      <Divider />
      <DialogContent>
        <div className={classes.firstIssueDialogContent}>{children}</div>
      </DialogContent>
    </Dialog>
  );
}

export { FirstIssueDialog };
