import {
  extractNavigatorDetails,
  getDeviceInfo,
  getNavigator,
} from './helpers';

export async function asyncLog(
  logger: { log: (body: object) => void },
  logId: string,
  currentUserId: string,
  currentUserEmail: string
): Promise<void> {
  const logObj = await extractNavigatorDetails(getNavigator());
  logger.log({
    logId,
    currentUserId,
    currentUserEmail,
    ...getDeviceInfo(),
    asyncDetails: logObj,
  });
}
