import React, { ReactElement } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { minMaxRand } from 'shared/utils/number';
import { useSkeletonStyles } from './styles';
import { ColumnShape, Column } from 'react-base-table';
import ColumnHeader from './ColumnHeader';

const drawHeader: ColumnShape['headerRenderer'] = ({
  container,
  column,
}) => <ColumnHeader container={container} column={column} />;

export function toReactColumn(props: ColumnShape): ReactElement {
  return (
    <Column
      {...props}
      key={props._id}
      dataKey={props.dataKey}
      headerRenderer={drawHeader}
    />
  );
}
const columnMax = [5, 12, 35, 22, 8, 18];
const mt16 = { marginTop: 16 };

const _TableSkeleton = (): JSX.Element => {
  const classes = useSkeletonStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.items}>
          {Array.from({ length: 6 }).map((_, ndx) => (
            <Skeleton
              key={`skeleton-table-head-${ndx}`}
              variant='text'
              width={`${columnMax[ndx]}%`}
            />
          ))}
        </div>
        <div style={mt16} />
        {Array.from({ length: 12 }).map((_, ndx) => (
          <div key={ndx} className={classes.items}>
            {Array.from({ length: 6 }).map((_, ndx) => (
              <div key={ndx} style={{ width: `${columnMax[ndx]}%` }}>
                <Skeleton
                  variant='rectangular'
                  width={`${minMaxRand(36, 100)}%`}
                />
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default React.memo(_TableSkeleton);
export const TableSkeleton = React.memo(_TableSkeleton);
