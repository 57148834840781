import { projectIdSelector } from 'helpers/misc';
import { Store, useCreateStore } from 'hooks/createStore';
import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { SortingOrder } from 'shared/filter/sort/types';
import { SortData } from 'shared/filter/types';

export type Parameters = {
  offset: number;
  deleted: boolean;
  search: string;
  sort: SortData;
};

type ParametersContext = {
  setParameters(data: Partial<Parameters>): void;
  parametersStore: Store<Parameters>;
};

const IssueGetParametersContext = createContext<
  ParametersContext | undefined
>(undefined);

export function WithIssueGetParameters({
  children,
}: PropsWithChildren<{}>): ReactElement {
  const projectId = useSelector(projectIdSelector);
  const savedParameters = localStorage.getItem(
    `issue_get_parameters_${projectId}`
  );
  const initParameters =
    projectId === undefined || savedParameters === null
      ? {
          offset: 0,
          deleted: false,
          search: '',
          sort: {
            key: IssueFieldNames.modificationDate,
            path: IssueFieldNames.modificationDate,
            type: 'number',
            order: SortingOrder.desc,
          },
        }
      : JSON.parse(savedParameters);

  const parametersStore = useCreateStore(initParameters);

  useEffect(() => {
    const savedParameters = localStorage.getItem(
      `issue_get_parameters_${projectId}`
    );

    if (projectId === undefined || savedParameters === null) {
      return;
    }

    parametersStore.set(JSON.parse(savedParameters));
  }, [projectId, parametersStore]);

  const setParameters = useCallback(
    (data: Partial<Parameters>): void => {
      if (!projectId) return;
      const newParams = {
        ...parametersStore.get(),
        ...data,
      };
      parametersStore.set(newParams);
      localStorage.setItem(
        `issue_get_parameters_${projectId}`,
        JSON.stringify(newParams)
      );
    },
    [parametersStore, projectId]
  );

  const ctx = useMemo(() => {
    return {
      parametersStore,
      setParameters,
    };
  }, [parametersStore, setParameters]);
  return (
    <IssueGetParametersContext.Provider value={ctx}>
      {children}
    </IssueGetParametersContext.Provider>
  );
}

export function useIssueGetParameters(): ParametersContext {
  const context = useContext(IssueGetParametersContext);
  if (context === undefined) {
    throw new Error(
      'useIssueGetParameters must be used within a IssueGetParametersContextProvider'
    );
  }
  return context;
}
