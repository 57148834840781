import { IDocumentBinder } from 'shared/domain/document/documentBinder';
import { DocumentUrlsModel } from 'shared/domain/document/documentUrlsModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { ImageConvertStatus } from 'shared/domain/imageConvertStatus/imageConvertStatus';
import { DocumentOnView } from 'presentation/document/documentOnView';
import React, { MutableRefObject } from 'react';
import { FileInfo } from 'setup/types/core';
import { HashMap } from 'shared/types/commonView';
import { DisplayedFile } from '../gallery/filesStore';
import { MainImage } from '../mainImage';

export type UploaderFile<T = object> = FileType & T;

type EditPlace<T = object> = (keyof FileType | 'deleted') | keyof T;
type EditValue = string | number | boolean | null | undefined;

type GraphicUploaderClasses = { buttonContainer?: string };

export type GUProps = Pick<
  GU,
  | 'filesStore'
  | 'addFile'
  | 'editFile'
  | 'removeFile'
  | 'config'
  | 'drawings'
  | 'addDrawing'
> & {
  classes?: GraphicUploaderClasses;
  forwardedRef?: MutableRefObject<ContextType | undefined>;
};

export type CONFIG_TYPE = {
  baseUrl: string;
  max_file_size?: number;
  file_types?: string[];
  thumbnailSize?: number;
  atomicChanges?: boolean;
  dataQaPrefix: string;
  actions?: {
    upload?: Function;
    edit?: Function;
    remove?: Function;
    uploadDrawing?: Function;
  };
  previewOnly?: boolean;
  internalEntityBaseUrl?: Function;
  // TODO: make it required in localGraphicUploader; PT-3769
  documentBinderFactory?: (baseUrl?: string) => IDocumentBinder;
  sourceResolver?: (
    localId: number
  ) => Promise<{ documentUrls: DocumentUrlsModel }>;
  filePrefix?: string;
  autoOpenCheck?: (baseUrl: string, file: any, prefix?: string) => boolean;
  mainImageHandlerRef?: MutableRefObject<MainImage>;
};

type SETTINGS = Partial<CONFIG_TYPE>;

type FileAPI = DocumentOnView;

export type FileType = {
  file?: File;
  src?: string;
  thumbnail?: string;
  size?: number;
  title: string;
  description?: string | null;
  type: string;
  deleted?: boolean;
  _id?: string;
  data?: Partial<FileInfo>;
  error?: string;
  index?: number;
  signedRequest?: DocumentUrlsModel;
  localId?: number;
  downloadSrc?: string | undefined;
  drawing?: DrawingType;
  imageConvertStatus?: ImageConvertStatus;
  drawingSyncStatus?: SyncStatus;
};

export type DefinedDrawing = {
  data: string;
  blob: any;
  imageUrl: string;
  mergedImageUrl: string;
  thumbnailMergedImageUrl?: string;
  drawingImageUrl?: string;
  clear: boolean;
};
type ClearDrawing = {
  data?: undefined;
  blob?: undefined;
  imageUrl?: undefined;
  mergedImageUrl?: undefined;
  thumbnailMergedImageUrl?: undefined;
  drawingImageUrl?: undefined;
  clear: true;
};
type AnyDrawing = {
  data?: string;
  blob?: any;
  imageUrl?: string;
  mergedImageUrl?: string;
  thumbnailMergedImageUrl?: string;
  drawingImageUrl?: string;
  clear?: boolean;
};

export type DrawingType =
  | DefinedDrawing
  | ClearDrawing
  | AnyDrawing
  | undefined;

export type Files = Array<FileType>;

type Drawings = DrawingType[];

type UploadFilesOptions = {
  rethrow?: boolean;
};

type FilesStore = {
  get: () => DisplayedFile[];
  set: (f: UploaderFile[]) => void;
  subscribe: (callback: () => void) => () => void;
};
export type GU = {
  filesStore: FilesStore;
  addFile: (file: File[]) => void;
  editFile: (id: number, key: EditPlace, value: EditValue) => void;
  removeFile: (id: number) => void;
  uploadFiles: (
    baseUrl: string,
    onDone: ((params?: any) => any) | undefined,
    options?: UploadFilesOptions
  ) => any;
  drawings: Drawings;
  addDrawing: (drawing: DrawingType, index: number) => void;
  loading: boolean;
  config: CONFIG_TYPE;
  changeSet: ChangeSet;
  executeChangeset: () => Promise<any>[];
};

export type UseGraphicUploader = (
  initialFiles?: Array<FileAPI>,
  settings?: SETTINGS,
  alwaysReinit?: true
) => GU;

export type Event = React.ChangeEvent<HTMLInputElement>;

type GallerySetter = (params: { slide: number; open: boolean }) => void;

type ExtendContext = {
  setGallery: GallerySetter;
  changeGallery: (
    place: 'open' | 'slide',
    value: number | boolean
  ) => void;
  gallery: { slide: number; open: boolean };
};

export type ContextType = GUProps & ExtendContext;

export type AtomicCall = (
  config: CONFIG_TYPE,
  actionName: keyof NonNullable<CONFIG_TYPE['actions']>,
  params: any,
  fallback: Function
) => void;

export type Changes = { [key in EditPlace]?: EditValue };
export type ChangeSet = HashMap<Changes>;

export interface FileReadSuccess extends FileReadResult {
  data: {
    extension: string;
    isDrawn?: boolean;
    mergedSrc?: string;
  };
  // TODO: fix DocumentOnView description PT-3773
  description?: string | null | undefined;
  file?: File;
  src: string;
  title: string;
  type: string;
  // Files can have localId undefined when they were not saved to DB yet.
  localId?: number;
  resultType: ResultType.success;
  signedRequest?: DocumentUrlsModel;
  downloadSrc: string | undefined;
  syncStatus: SyncStatus;
  drawingSyncStatus: SyncStatus;
}

export interface FileReadError extends FileReadResult {
  error: string;
  resultType: ResultType.error;
}

export enum ResultType {
  success = 'success',
  error = 'error',
}

export interface FileReadResult {
  resultType: ResultType;
}
