import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import { LocationAreaType, Point } from 'shared/domain/area/types';
import { PlannedAreaPatternDefinitions } from 'components/location/area/PlannedAreaPattern';
import { shoelaceArea } from 'components/location/area/common';
import {
  IssueLocationType,
  IssueModel,
} from 'shared/domain/issue/issueModel';
import { isInRange } from 'helpers/misc';
import { Store, subscribeMany } from 'hooks/createStore';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { debugLog } from 'shared/logger/debugLog';
import { DocumentationArea } from './DocumentationArea';

type AreaType = {
  type: LocationAreaType;
  points: Point[];
  key: string;
  areaSize: number;
  id: string;
  tooltip: string;
};

export function IssueAreas({
  issuesStore,
  processesWithPositionOnMap,
}: {
  issuesStore: Store<IssueModel[]>;
  processesWithPositionOnMap: string[];
}): ReactElement {
  const clickableElement = useRef<HTMLDivElement | null>(null);
  const { select, selectedStore } = useSelectedStoreContext<
    string | undefined
  >();

  const [areas, setAreas] = useState(
    issuesToAreas(issuesStore.get(), processesWithPositionOnMap).sort(
      makeSortArea(selectedStore)
    )
  );

  useEffect(() => {
    return subscribeMany([issuesStore, selectedStore], () => {
      const newAreas = issuesToAreas(
        issuesStore.get(),
        processesWithPositionOnMap
      );

      setAreas(newAreas.sort(makeSortArea(selectedStore)));
    });
  }, [issuesStore, selectedStore, processesWithPositionOnMap]);

  return (
    <>
      <PlannedAreaPatternDefinitions />
      {areas.map((area) => {
        return (
          <DocumentationArea
            key={area.key}
            tooltip={area.tooltip}
            areaType={area.type}
            points={area.points}
            id={area.id}
            onClick={(evt) => {
              debugLog('clicked documentation area');
              evt.preventDefault();
              evt.stopPropagation();
              // select(area.id);
              selectedStore.set(area.id);
            }}
          />
        );
      })}
    </>
  );
}

function issuesToAreas(
  issues: IssueModel[],
  processesWithPositionOnMap: string[]
): AreaType[] {
  return issues.reduce((result: AreaType[], issue: IssueModel) => {
    if (
      issue.primaryData.selectedLocationType !== IssueLocationType.area ||
      !processesWithPositionOnMap.includes(issue.process)
    ) {
      return result;
    }

    issue.primaryData.targetAreas.forEach((area, index) => {
      result.push({
        key: `${issue._id},${index},${LocationAreaType.planned}`,
        points: area,
        type: LocationAreaType.planned,
        areaSize: shoelaceArea(area),
        id: issue._id,
        tooltip: issue.primaryData.title,
      });
    });

    issue.primaryData.finalAreas.forEach((area, index) => {
      result.push({
        key: `${issue._id},${index},${LocationAreaType.completed}`,
        points: area,
        type: LocationAreaType.completed,
        areaSize: shoelaceArea(area),
        id: issue._id,
        tooltip: issue.primaryData.title,
      });
    });

    return result;
  }, []);
}

function makeSortArea(selectedStore: Store<string | undefined>) {
  return function sortWithSelectedLast(a: AreaType, b: AreaType): number {
    const selectedId = selectedStore.get();
    if (a.id === selectedId) {
      return 1;
    }
    if (b.id === selectedId) {
      return -1;
    }
    return sortArea(a, b);
  };
}

function sortArea(a: AreaType, b: AreaType): number {
  return b.areaSize - a.areaSize;
}

function isInBounds(x: number, y: number): boolean {
  return isInRange(x, 0, 1) && isInRange(y, 0, 1);
}
