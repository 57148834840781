import { memo, ReactElement } from 'react';

import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoSingleInspection } from 'components/inspection/Single';

import { SingleInspectionSkeleton } from './skeleton';
import {
  useInspection,
  withInspection,
} from 'components/dataProviders/withInspection';
import { withInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import { withSites } from 'components/dataProviders/withSites';
import { withLevels } from 'components/dataProviders/withLevels';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withWorktypes } from 'components/dataProviders/withWorktypes';
import { withIssueForm } from 'components/common/withIssueForm';
import { withIssueChannelListener } from 'components/broadcastChannelListeners/withIssueChannelListener';
import { withContracts } from 'components/dataProviders/withContracts';
import { withFieldVisibility } from 'components/dataProviders/withVisibleFields';
import { withFieldVisibilityMap } from 'components/dataProviders/withVisibleFields/withVisibleFieldsMap';
import { withProjectChangeDialog } from 'components/common/withProjectChangeDialog';

function Inspection(): ReactElement {
  const { inspection, loading } = useInspection();

  return !loading ? (
    <MemoSingleInspection inspection={inspection}></MemoSingleInspection>
  ) : (
    <SingleInspectionSkeleton />
  );
}

export const MemoInspection = memo(
  withFetcherReady(
    withProjectChangeDialog(
      withProcessesData(
        withIssueChannelListener(
          withIssueForm(
            withSites(
              withLevels(
                withWorktypes(
                  withContracts(
                    withCompanies(
                      withInspectionTemplate(
                        withInspection(
                          withFieldVisibility(
                            withFieldVisibilityMap(Inspection)
                          )
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
