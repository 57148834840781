import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { CreateReportUseCase } from 'shared/domain/report/createReport';
import { IssueListReportModel } from 'shared/domain/report/reportModel';
import { SingleInspectionReportModel } from 'shared/domain/report/singleInspectionReportModel';
import { SingleIssueReportModel } from 'shared/domain/report/singleIssueReportModel';
import { Identificable } from 'shared/types/commonView';

class ReportRequestCreator implements CreateReportUseCase {
  constructor(
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>
  ) {}
  async execute(
    reportModel:
      | IssueListReportModel
      | SingleIssueReportModel
      | SingleInspectionReportModel,
    uniqueId: string
  ): Promise<void> {
    const currentProject = await this.getCurrentProject();
    if (!currentProject) {
      throw new Error('Current project is undefined.');
    }
    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      data: {
        reportData: reportModel,
        projectId: currentProject._id,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.report,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const reportRequestCreator = new ReportRequestCreator(
  addRequest,
  getCurrentProject
);
