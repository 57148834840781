import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    '& > *': {
      '&:not(:last-child)': {
        marginBottom: '16px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  submitButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
