import { Message } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';
import { DocumentUrlsModel } from './documentUrlsModel';
import listenToDocumentUrlsMessage from './listenToDocumentUrlsResolvedMessage';
import startDocumentUrlsResolving from './startDocumentUrlResolve';

export type DocumentUrlsResult = {
  localId: number;
  documentUrls: DocumentUrlsModel;
};
export function resolveDocumentUrls(
  localId: number
): Promise<DocumentUrlsResult> {
  return new Promise((resolve, reject): void => {
    const broadcast = listenToDocumentUrlsMessage(
      (success: any): void => {
        broadcast.close();
        debugLog(
          'resolveDocumentUrls success',
          success,
          JSON.stringify(success)
        );
        resolve(success);
      },
      (error: any): void => {
        broadcast.close();
        debugLog('resolveDocumentUrls error', error);
        reject(error);
      },
      (event: Message): boolean => {
        return event.data.localId === localId;
      }
    );

    startDocumentUrlsResolving(localId);
  });
}
