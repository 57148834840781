import React, {
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Identificable } from 'shared/types/commonView';
import {
  getEmptyIssueFilters,
  getSavedFiltersObject,
  saveIssueFilters,
} from './model';
import {
  FilterDateType,
  IssueFilersActions as IssueFiltersActions,
  IssueFilters,
} from './types';
import { useIssueFiltersPrefixContext } from './issueFiltersPrefixContext';
import { projectIdSelector } from 'helpers/misc';

function reducer(
  state: IssueFilters,
  action: { type: IssueFiltersActions; payload: any }
): IssueFilters {
  switch (action.type) {
    case IssueFiltersActions.SET_FILTER: {
      const { filterName, filterOptions } = action.payload;

      return {
        ...state,
        [filterName]: filterOptions,
      };
    }
    case IssueFiltersActions.SET_ALL: {
      return action.payload;
    }
    default:
      throw new Error();
  }
}

type IssueFiltersContextType = {
  filters: IssueFilters;
  archived: boolean;
  resetFilters: () => void;
  setFilter: (
    filterName: string,
    values: Identificable[] | FilterDateType
  ) => void;
  setArchived: (value: boolean) => void;
};

const IssueFiltersContext = React.createContext<
  IssueFiltersContextType | undefined
>(undefined);

const WithIssueFilters: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const projectId = useSelector(projectIdSelector);
  const idPrefix = useIssueFiltersPrefixContext();
  const uniqueId = useMemo(() => {
    return idPrefix ? `${idPrefix}_${projectId}` : projectId;
  }, [projectId, idPrefix]);

  const [filters, dispatch] = useReducer(
    reducer,
    getSavedFiltersObject(uniqueId)
  );

  const [archived, _setArchived] = useState(
    localStorage.getItem(`${uniqueId}_archived`) === 'true'
  );

  useEffect(() => {
    try {
      const savedFilters = getSavedFiltersObject(uniqueId);
      dispatch({
        type: IssueFiltersActions.SET_ALL,
        payload: savedFilters,
      });
    } catch (e) {
      dispatch({
        type: IssueFiltersActions.SET_ALL,
        payload: getEmptyIssueFilters(),
      });
    }
  }, [uniqueId]);

  useEffect(() => {
    if (!uniqueId) {
      return;
    }
    saveIssueFilters(uniqueId, filters);
  }, [filters, uniqueId]);

  const setArchived = useCallback(
    (value: boolean) => {
      localStorage.setItem(`${uniqueId}_archived`, `${value}`);
      _setArchived(value);
    },
    [uniqueId]
  );

  const resetFilters = useCallback(() => {
    dispatch({
      type: IssueFiltersActions.SET_ALL,
      payload: getEmptyIssueFilters(),
    });
    setArchived(false);
  }, [setArchived]);

  const setFilter = useCallback((filterName, values) => {
    dispatch({
      type: IssueFiltersActions.SET_FILTER,
      payload: {
        filterName,
        filterOptions: values,
      },
    });
  }, []);

  const ctx: IssueFiltersContextType = {
    filters,
    archived,
    resetFilters,
    setFilter,
    setArchived,
  };

  return (
    <IssueFiltersContext.Provider value={ctx}>
      {children}
    </IssueFiltersContext.Provider>
  );
};

function withIssueFilters(Component: React.ComponentType) {
  return ({ ...props }): ReactElement => (
    <WithIssueFilters>
      <Component {...props} />
    </WithIssueFilters>
  );
}

function useIssueFilters(): IssueFiltersContextType {
  const context = useContext(IssueFiltersContext);
  if (context === undefined) {
    throw new Error(
      'useIssueFilters must be used within IssueFiltersContextProvider'
    );
  }

  return context;
}

export { WithIssueFilters, withIssueFilters, useIssueFilters };
