import {
  correspondenceAddressCountryAccessor,
  registeredAddressCountryAccessor,
} from 'components/table/accessors';
import { SORT_TYPE } from 'shared/filter/sort/types';
import {
  correspondenceAddressMergeGroup,
  registeredAddressMergeGroup,
} from './mergeGroup';
import { ColumnShapeSkeleton } from '../../types';

const base = {
  initialWidth: 192,
  flexGrow: 0,
  flexShrink: 0,
  labelId: 'address_country',
  sortable: true,
  resizable: true,
  sortType: SORT_TYPE.STRING,
  style: {
    justifyContent: 'flex-start',
  },
};

export function getCorrespondenceCountryColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: correspondenceAddressCountryAccessor,
    dataKey: 'correspondenceAddress.country',
    mergeGroup: correspondenceAddressMergeGroup,
  };
}

export function getRegisteredCountryColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: registeredAddressCountryAccessor,
    dataKey: 'registeredOfficeAddress.country',
    mergeGroup: registeredAddressMergeGroup,
  };
}
