import InsufficientPermissionsPage from 'components/core/InsufficientPermissionsPage/InsufficientPermissionsPage';
import { isManagerOrAdmin } from 'shared/domain/role/isManagerOrAdmin';
import { isStandard } from 'shared/domain/role/isStandard';
import { isViewer } from 'shared/domain/role/isViewer';
import { UserInDto } from 'shared/dtos/in/user';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { currentProjectUserSelector } from 'redux/selectors/users';
import { MemoCreateContractWizardContainer } from 'views/contracts/wizard/create/container';
import { MemoEditContractWizardContainer } from 'views/contracts/wizard/edit/container';
import { MemoGenericRoleAuthorizer } from '../GenericRoleAuthorizer';
export const AuthorizableCreateContractWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserStandardOrViewer = useMemo(() => {
      return isStandard(currentUser.role) || isViewer(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isManagerOrAdmin(currentUser.role)}
        unauthorized={isCurrentUserStandardOrViewer}
        authorizedComponent={MemoCreateContractWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);

export const AuthorizableEditContractWizard = React.memo(
  (): React.ReactElement => {
    const currentUser: UserInDto = useSelector(currentProjectUserSelector);
    const isCurrentUserStandardOrViewer = useMemo(() => {
      return isStandard(currentUser.role) || isViewer(currentUser.role);
    }, [currentUser]);
    return (
      <MemoGenericRoleAuthorizer
        authorized={isManagerOrAdmin(currentUser.role)}
        unauthorized={isCurrentUserStandardOrViewer}
        authorizedComponent={MemoEditContractWizardContainer}
        unauthorizedComponent={InsufficientPermissionsPage}
      />
    );
  }
);
