import { LabelledEntity } from 'shared/types/commonView';
import { AVAILABLE_PROCESSES } from 'shared/types/process';
import { stringToViewDate } from 'shared/utils/date/stringToViewDate';
import { toViewDate } from 'shared/utils/date/toViewDate';
import { anyToAppLocaleOnView } from 'shared/utils/locale/anyLocaleCodeToAppLocaleOnView';
import { ProjectModel } from '../types/model';
import {
  ProjectCreateOnView,
  ProjectEditOnView,
  ProjectOnView,
} from '../types/view';

export function getAllAvailableTimezones(): LabelledEntity[] {
  //@ts-ignore TODO upgrade to latest version of typescript https://github.com/microsoft/TypeScript/issues/49231
  return Intl.supportedValuesOf('timeZone').map((tz: string) => {
    return {
      _id: tz,
      label: tz,
    };
  });
}

export function timezoneIdToLabelledEntity(timezoneId): LabelledEntity {
  //@ts-ignore TODO upgrade to latest version of typescript https://github.com/microsoft/TypeScript/issues/49231
  const timezone = Intl.supportedValuesOf('timeZone').find(
    (tz: string) => tz === timezoneId
  );
  return {
    _id: timezone || 'Europe/London',
    label: timezone || 'Europe/London',
  };
}

export const findInitialCurrency = (locale: string): LabelledEntity => {
  if (locale === 'pl') {
    return { _id: 'PLN', label: 'PLN' };
  }
  if (locale === 'tr') {
    return { _id: 'TRY', label: 'TRY' };
  }
  return { _id: 'EUR', label: 'EUR' };
};

function baseMapper(
  model: ProjectModel,
  organizationName: string
): ProjectCreateOnView {
  return {
    projectStartDate: toViewDate(model.projectStartDate),
    projectEndDate: toViewDate(model.projectEndDate),
    organization: { _id: model.organizationId, label: organizationName },
    name: model.name,
    timezone: timezoneIdToLabelledEntity(model.timezone),
    budget: model.budget?.toString() || '',
    currency: { _id: model.currency, label: model.currency },
    locale: anyToAppLocaleOnView(model.localeCode),
    processes: model.processes.map((processId) => {
      return AVAILABLE_PROCESSES.find(
        (process) => process._id === processId
      ) as LabelledEntity;
    }),
  };
}

export function projectModelToProjectEditOnView(
  model: ProjectModel,
  organizationName: string
): ProjectEditOnView {
  return {
    _id: model._id,
    ...baseMapper(model, organizationName),
  };
}

export function projectModelToProjectOnView(
  model: ProjectModel,
  organizationName: string
): ProjectOnView {
  return {
    createdAt: stringToViewDate(model.createdAt),
    modifiedAt: stringToViewDate(model.modifiedAt),
    _id: model._id,
    deleted: model.deleted,
    createdBy: model.createdBy,
    modifiedBy: model.modifiedBy,
    ...baseMapper(model, organizationName),
  };
}
