export function sortProjectNames(
  a: { name: string },
  b: { name: string }
): number {
  return a.name
    .toLocaleLowerCase()
    .localeCompare(b.name.toLocaleLowerCase());
}

const SELECTION_HAPPENED_KEY = 'hadChosenProject';
export function setSelectionHappened(): void {
  localStorage.setItem(SELECTION_HAPPENED_KEY, '1');
}

function getSelectionHappened(): string | null {
  return localStorage.getItem(SELECTION_HAPPENED_KEY);
}

export function shouldAutoSelect(projectsCount: number): boolean {
  return projectsCount === 1 && !getSelectionHappened();
}
