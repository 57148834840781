import { BINDINGS_BASE } from 'shared/domain/document/documentBinder';
import { DocumentParentBond } from 'shared/domain/document/documentModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { DocumentInDto } from 'shared/dtos/in/document';
import { DocumentInsertEntity } from './entity';

export function inDtoToDocumentInsertEntity(
  documentInDto: DocumentInDto,
  parentEntityObject: DocumentParentBond
): DocumentInsertEntity {
  return {
    ...BINDINGS_BASE,
    ...parentEntityObject,
    ...documentInDto,
    syncStatus: SyncStatus.SUCCESS,
    drawingSyncStatus: SyncStatus.SUCCESS,
  };
}
