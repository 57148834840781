import { PropsWithChildren } from 'react';

export function ShowConditionally({
  isShown,
  children,
}: PropsWithChildren<{ isShown: boolean }>): any | null {
  // trouble typing, leaving any
  if (!isShown || !children) {
    return null;
  }
  return children;
}
