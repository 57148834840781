import { useCorrectiveActionTypes } from 'components/dataProviders/withCorrectiveActionTypes';
import { useEnvironmentalAspects } from 'components/dataProviders/withEnvironmentalAspects';
import { useHazardCategories } from 'components/dataProviders/withHazardCategories';
import { useIssueForm } from 'components/common/withIssueForm';
import { useLevels } from 'components/dataProviders/withLevels';
import { useSites } from 'components/dataProviders/withSites';
import { useWorktypes } from 'components/dataProviders/withWorktypes';
import { keepUndeleted } from 'shared/domain/deletable/filters';
import { useCreateStore, Store } from 'hooks/createStore';
import { filterFieldValuesByProcess } from 'presentation/fieldValue/filterFieldValues';
import { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { HashMap } from 'shared/types/commonView';
import { generateForm } from 'views/issue/issueView/UnifiedIssueViewContainer/generateForm';
import { LegacyIssueForm } from 'views/issue/issueView/UnifiedIssueViewContainer/types';

export function useGenerateForms(): {
  forms: HashMap<LegacyIssueForm>;
  loading: boolean;
  loadingStore: Store<boolean>;
} {
  const { issueForm } = useIssueForm();
  const { processes } = useSelector(projectDataSelector);
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const {
    levels: { items: levels },
    loading: loadingLevels,
  } = useLevels();
  const { items: workTypes, loading: loadingWorkTypes } = useWorktypes();
  const { items: hazardCategories, loading: loadingHazardCategories } =
    useHazardCategories();
  const { items: environmentalAspects, loading: loadingAspects } =
    useEnvironmentalAspects();
  const { items: correctiveActionTypes, loading: loadingCorrective } =
    useCorrectiveActionTypes();

  const loading =
    loadingSites ||
    loadingLevels ||
    loadingWorkTypes ||
    loadingHazardCategories ||
    loadingAspects ||
    loadingCorrective;

  const loadingStore = useCreateStore(loading);

  useEffect(() => {
    loadingStore.set(loading);
  }, [loading, loadingStore]);

  const resultRef = useRef({
    forms: {},
    loading: loading,
    loadingStore,
  });

  return useMemo(() => {
    const result: { [key: string]: LegacyIssueForm } = {};
    if (loading) return resultRef.current;
    processes.forEach((process) => {
      if (!issueForm) {
        return;
      }
      const form = generateForm(
        JSON.parse(JSON.stringify(issueForm)),
        filterFieldValuesByProcess(workTypes, process._id),
        filterFieldValuesByProcess(hazardCategories, process._id),
        filterFieldValuesByProcess(environmentalAspects, process._id),
        filterFieldValuesByProcess(correctiveActionTypes, process._id),
        levels.filter(keepUndeleted),
        sites.filter(keepUndeleted)
      );
      result[process._id] = form;
    });
    return {
      forms: result,
      loading: loading,
      loadingStore,
    };
  }, [
    processes,
    issueForm,
    workTypes,
    hazardCategories,
    correctiveActionTypes,
    environmentalAspects,
    levels,
    sites,
    loading,
    loadingStore,
  ]);
}
