import { AddDocumentUseCase } from 'shared/domain/document/addDocument';
import { DocumentCreateModel } from 'shared/domain/document/documentCreateModel';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { documentCreateModelToDocumentInsertEntity } from 'serviceWorker/repository/document/documentModelToEntity';
import { DocumentInsertEntity } from 'serviceWorker/repository/document/entity';
import { broadcastCreatedDocument } from 'serviceWorker/broadcasts/documents';
import { addDocument } from 'serviceWorker/db/documents';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { debugLog } from 'shared/logger/debugLog';

// exported for tests, do we need them thou?
export class DocumentSaver implements AddDocumentUseCase {
  constructor(
    private addDocument: (data: DocumentInsertEntity) => Promise<number>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDocumentSaved: (
      document: [number],
      uniqueId: string
    ) => void
  ) {}
  async execute(
    baseUrl: string,
    document: DocumentCreateModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('AddDocumentUseCase', baseUrl, document);
    const documentToAdd: DocumentInsertEntity =
      documentCreateModelToDocumentInsertEntity(document);
    const savedDocument: number = await this.addDocument(documentToAdd);

    this.broadcastDocumentSaved([savedDocument], uniqueId);

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      method: 'POST',
      data: {
        localId: savedDocument,
        projectId: currentProject?._id,
      },
      entityType: HttpRequestModelType.document,
      status: HttpRequestStatus.NEW,
    });
  }
}

const documentSaver = new DocumentSaver(
  addDocument,
  addRequest,
  getCurrentProject,
  broadcastCreatedDocument
);

export default documentSaver;
