import { Button, CircularProgress, Divider, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import cn from 'classnames';
import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import useIsMobile from 'hooks/useIsMobile';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';

export function SubmitFooter({
  onCancel,
  isPosting,
  onSubmit,
  disableSubmit,
}: {
  onCancel: () => void;
  isPosting: boolean;
  onSubmit: () => void;
  disableSubmit?: boolean;
}): ReactElement {
  const isMobile = useIsMobile();
  const classes = useStyles({ isMobile });
  const intl = useIntl();
  return (
    <div className={cn(classes.submitFooter, classes.castShadow)}>
      <Divider className={cn(classes.fullWidth)} />
      <div className={classes.footerPadding}>
        <Button onClick={onCancel} disabled={isPosting}>
          {intl.formatMessage({ id: 'general_cancel' })}
        </Button>
        <div>
          <MemoButtonContained
            onClick={onSubmit}
            endIcon={
              isPosting ? <CircularProgress size={16} /> : undefined
            }
            disabled={isPosting || disableSubmit}
          >
            {intl.formatMessage({ id: 'general_save' })}
          </MemoButtonContained>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles<Theme, { isMobile: boolean }>(() =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    castShadow: {
      boxShadow: '0px -4px 4px -2px rgba(0, 0, 0, 0.15)',
    },
    submitButtons: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '16px',
    },
    submitButtonSpacing: { marginRight: '19px' },
    // @ts-ignore
    submitFooter: {
      position: 'fixed',
      bottom: '0',
      // navigation has 64px
      width: ({ isMobile }): string =>
        isMobile ? '100%' : 'calc(100% - 64px)',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      backgroundColor: '#FFF',
      zIndex: 1,
    },
    footerPadding: {
      paddingTop: '11px',
      paddingBottom: '12px',
      paddingRight: '29px',
      paddingLeft: '16px',
    },
  })
);
