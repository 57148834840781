import { ReactElement } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export function ClearIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path d='M40.6 12.85 38.9 9.1l-3.75-1.7 3.75-1.7 1.7-3.75 1.7 3.75 3.75 1.7-3.75 1.7Zm-24.1 0L14.8 9.1l-3.75-1.7 3.75-1.7 1.7-3.75 1.7 3.75 3.75 1.7-3.75 1.7Zm24.1 24.1-1.7-3.75-3.75-1.7 3.75-1.7 1.7-3.75 1.7 3.75 3.75 1.7-3.75 1.7ZM9.35 43.4 4.6 38.65q-.55-.55-.6-1.275-.05-.725.6-1.375l22.5-22.5q.6-.6 1.45-.6t1.45.6l4.5 4.5q.6.6.6 1.45t-.6 1.45L12 43.4q-.6.6-1.325.6t-1.325-.6Zm1.15-2.85L26.15 24.9l-3.1-3.1L7.4 37.45Z' />
    </SvgIcon>
  );
}

export function UndoIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M28.4 38H14v-3h14.45q3.5 0 6.025-2.325Q37 30.35 37 26.9t-2.525-5.775Q31.95 18.8 28.45 18.8H13.7l5.7 5.7-2.1 2.1L8 17.3 17.3 8l2.1 2.1-5.7 5.7h14.7q4.75 0 8.175 3.2Q40 22.2 40 26.9t-3.425 7.9Q33.15 38 28.4 38Z'
      />
    </SvgIcon>
  );
}

export function DeleteIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M13.05 42q-1.25 0-2.125-.875T10.05 39V10.5H8v-3h9.4V6h13.2v1.5H40v3h-2.05V39q0 1.2-.9 2.1-.9.9-2.1.9Zm21.9-31.5h-21.9V39h21.9Zm-16.6 24.2h3V14.75h-3Zm8.3 0h3V14.75h-3Zm-13.6-24.2V39Z'
      />
    </SvgIcon>
  );
}

export function DrawIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M28 42q-.6 0-1.05-.45-.45-.45-.45-1.05 0-.65.45-1.075Q27.4 39 28 39q2.95 0 4.975-1.2Q35 36.6 35 35q0-1.15-1.475-2.25t-3.975-1.7l2.35-2.35q3.15.95 4.625 2.625T38 35q0 3.35-3.05 5.175Q31.9 42 28 42ZM12 27.3q-3.2-.7-4.6-2.2Q6 23.6 6 22q0-1.75 1.3-3.15t6-3.1q3.3-1.2 4.25-1.95.95-.75.95-1.75 0-1.25-1.1-2.15Q16.3 9 14 9q-1.35 0-2.3.35-.95.35-1.7 1.1-.4.4-1.025.475Q8.35 11 7.85 10.6q-.55-.4-.575-1-.025-.6.375-1.05.85-1.1 2.55-1.825Q11.9 6 14 6q3.4 0 5.45 1.625Q21.5 9.25 21.5 12.05q0 2.05-1.425 3.475Q18.65 16.95 14.5 18.5q-3.35 1.25-4.425 1.975Q9 21.2 9 22q0 .8 1.35 1.525 1.35.725 4.05 1.375Zm24.8-7.7-6.4-6.4 2.25-2.25q.9-.9 2-.9t2 .9l2.4 2.4q.9.9.9 2t-.9 2ZM11 39h2.1l17.25-17.25-2.1-2.1L11 36.9Zm-3 3v-6.4l20.25-20.25 6.4 6.4L14.4 42Zm20.25-22.35 2.1 2.1Z'
      />
    </SvgIcon>
  );
}

export function DownloadIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z'
      />
    </SvgIcon>
  );
}

export function InfoIcon(props: SvgIconProps): ReactElement {
  return (
    <SvgIcon {...props} viewBox='0 0 48 48'>
      <path
        xmlns='http://www.w3.org/2000/svg'
        d='M22.65 34h3V22h-3ZM24 18.3q.7 0 1.175-.45.475-.45.475-1.15t-.475-1.2Q24.7 15 24 15q-.7 0-1.175.5-.475.5-.475 1.2t.475 1.15q.475.45 1.175.45ZM24 44q-4.1 0-7.75-1.575-3.65-1.575-6.375-4.3-2.725-2.725-4.3-6.375Q4 28.1 4 23.95q0-4.1 1.575-7.75 1.575-3.65 4.3-6.35 2.725-2.7 6.375-4.275Q19.9 4 24.05 4q4.1 0 7.75 1.575 3.65 1.575 6.35 4.275 2.7 2.7 4.275 6.35Q44 19.85 44 24q0 4.1-1.575 7.75-1.575 3.65-4.275 6.375t-6.35 4.3Q28.15 44 24 44Zm.05-3q7.05 0 12-4.975T41 23.95q0-7.05-4.95-12T24 7q-7.05 0-12.025 4.95Q7 16.9 7 24q0 7.05 4.975 12.025Q16.95 41 24.05 41ZM24 24Z'
      />
    </SvgIcon>
  );
}
