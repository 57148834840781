import { useLocation } from 'react-router-dom';

export enum EntityOnProjectRoute {
  issue = 'issue',
  event = 'event',
  file = 'file',
  directory = 'directory',
  template = 'template',
  inspection = 'inspection',
}

export function chooseEntity(
  location: ReturnType<typeof useLocation>
): EntityOnProjectRoute | undefined {
  if (location.pathname.includes('documentation/file')) {
    return EntityOnProjectRoute.file;
  }
  if (location.pathname.includes('documentation/dir')) {
    return EntityOnProjectRoute.directory;
  }
  if (location.pathname.includes('documentation')) {
    return EntityOnProjectRoute.directory;
  }
  if (
    location.pathname.includes('/event') &&
    location.pathname.includes('/issue')
  ) {
    return EntityOnProjectRoute.event;
  }
  if (location.pathname.includes('/issue')) {
    return EntityOnProjectRoute.issue;
  }
  if (location.pathname.includes('/inspectionTemplate')) {
    return EntityOnProjectRoute.template;
  }
  if (location.pathname.includes('/inspection/')) {
    return EntityOnProjectRoute.inspection;
  }

  return undefined;
}
