import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  ComponentType,
} from 'react';

const IssueFiltersPrefixContext = createContext('');

export function WithIssueFiltersPrefixContext({
  children,
  prefix,
}: PropsWithChildren<{ prefix: string }>): ReactElement {
  return (
    <IssueFiltersPrefixContext.Provider value={prefix}>
      {children}
    </IssueFiltersPrefixContext.Provider>
  );
}

export function makeComposabeWithIssueFiltersPrefix(
  prefix: string
): (Component: ComponentType) => () => ReactElement {
  return (Component) => {
    return (): ReactElement => (
      <WithIssueFiltersPrefixContext prefix={prefix}>
        <Component />
      </WithIssueFiltersPrefixContext>
    );
  };
}

export function useIssueFiltersPrefixContext(): string {
  const context = useContext(IssueFiltersPrefixContext);
  if (context === undefined) {
    return '';
  }

  return context;
}
