import { ApexOptions } from 'apexcharts';
import { getToolbarOptions } from 'charts/shared/toolbar';
import { PresentedTypes } from 'shared/types/analytics';

export enum ColorValue {
  detected = '#F37C22',
  resolved = '#7EB24A',
}

export const DetectedResolvedLineColor: {
  [key in PresentedTypes]: ColorValue;
} = {
  [PresentedTypes.detected]: ColorValue.detected,
  [PresentedTypes.resolved]: ColorValue.resolved,
};

export const dataLabels = {
  enabled: true,
  offsetY: -16,
  style: {
    colors: ['#000000'],
    fontWeight: '100',
    fontSize: '8px',
  },
  background: { enabled: false, foreColor: '#000000', dropShadow: {} },
};
export const fillLine = {
  type: 'gradient',
  gradient: {
    type: 'vertical',
    inverseColors: false,
    shade: 'light',
    shadeIntensity: 1,
    opacityFrom: 0.7,
    opacityTo: 0.9,
    stops: [0, 90, 100],
  },
};
export const fillBar = {
  type: 'solid',
};
const sharedOptions: ApexOptions = {
  colors: [
    DetectedResolvedLineColor[PresentedTypes.detected],
    DetectedResolvedLineColor[PresentedTypes.resolved],
  ],
  dataLabels: dataLabels,
  plotOptions: {
    bar: {
      dataLabels: {
        position: 'top',
      },
    },
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  legend: {
    horizontalAlign: 'left' as 'left',
    offsetX: -26,
    // CANNOT USE REM
    fontSize: '14px',
    showForSingleSeries: true,
  },
  yaxis: {
    show: true,
    floating: true,
    opposite: true,
    max: (max: number): number => 1.1 * max,
    labels: {
      offsetX: 23,
      offsetY: -6,
      align: 'right',
      style: {
        colors: ['#979797'],
        fontFamily: 'Archivo, Arial',
      },
    },
  },
};

export const listViewDetectedResolvedChartOptions: ApexOptions = {
  colors: sharedOptions.colors,
  chart: {
    offsetY: 10,
    fontFamily: 'Archivo, Arial',
    toolbar: { show: false },
    redrawOnParentResize: true,
  },
  plotOptions: sharedOptions.plotOptions,
  dataLabels: sharedOptions.dataLabels,
  markers: sharedOptions.markers,
  stroke: sharedOptions.stroke,
  yaxis: sharedOptions.yaxis,
  xaxis: {
    categories: [],
    axisTicks: {
      show: false,
    },
    tickPlacement: 'on',
    labels: {
      rotate: -90,
      style: {
        fontFamily: 'Archivo, Arial',
        cssClass: 'apexcharts-xaxis-label detected-resolved-xaxis-labels',
      },
    },
  },
  grid: {
    padding: {
      top: 24,
      left: 0,
      right: -15,
    },
  },
  legend: sharedOptions.legend,
};

export const detailedViewDetectedResolvedChartOptions: ApexOptions = {
  colors: sharedOptions.colors,
  chart: {
    fontFamily: 'Archivo, Arial',
    toolbar: getToolbarOptions('detectedResolved'),
    redrawOnParentResize: true,
    offsetY: 10,
  },
  plotOptions: sharedOptions.plotOptions,
  dataLabels: sharedOptions.dataLabels,
  markers: sharedOptions.markers,
  stroke: sharedOptions.stroke,
  yaxis: sharedOptions.yaxis,
  xaxis: {
    categories: [],
    axisTicks: {
      show: false,
    },
    tickPlacement: 'on',
    labels: {
      rotate: -90,
      style: {
        fontFamily: 'Archivo, Arial',
        cssClass: 'apexcharts-xaxis-label detected-resolved-xaxis-labels',
      },
    },
  },
  grid: {
    padding: {
      ...sharedOptions.grid?.padding,
      right: -20,
      left: 0,
    },
  },
  legend: sharedOptions.legend,
};
