import {
  correspondenceAddressPostalCodeAccessor,
  registeredAddressPostalCodeAccessor,
} from 'components/table/accessors';
import {
  correspondenceAddressMergeGroup,
  registeredAddressMergeGroup,
} from './mergeGroup';
import { ColumnShapeSkeleton } from '../../types';
import { SORT_TYPE } from 'shared/filter/sort/types';

const base = {
  initialWidth: 192,
  flexGrow: 0,
  flexShrink: 0,
  sortable: true,
  sortType: SORT_TYPE.STRING,
  style: {
    justifyContent: 'flex-start',
  },
  labelId: 'address_postal_code',
};

export function getCorrespondencePostalCodeColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: correspondenceAddressPostalCodeAccessor,
    dataKey: 'correspondenceAddress.postalCode',
    mergeGroup: correspondenceAddressMergeGroup,
  };
}

export function getRegisteredPostalCodeColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    ...base,
    accessor: registeredAddressPostalCodeAccessor,
    dataKey: 'registeredOfficeAddress.postalCode',
    mergeGroup: registeredAddressMergeGroup,
  };
}
