import { SortingOrder } from 'shared/filter/sort/types';
import { AppLocales } from '../intl/IntlProviderWrapper';
import { HashMap, LabelledEntity } from 'shared/types/commonView';

export function sortLabelledEntities(
  a: LabelledEntity,
  b: LabelledEntity
): number {
  return a.label?.localeCompare(b.label) || 0;
}

function desc<T>(a: T, b: T, orderBy: keyof T): number {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getSorting<T>(
  order: SortingOrder,
  orderBy: keyof T
): (a: T, b: T) => number {
  return order === SortingOrder.desc
    ? (a, b): number => desc(a, b, orderBy)
    : (a, b): number => -desc(a, b, orderBy);
}

export function stableSort<T>(
  array: T[],
  compareFunction: (a: T, b: T) => number
): T[] {
  const stabilizedThis: [T, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order = compareFunction(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  //@ts-ignore-next-line
  return stabilizedThis.map((el) => el[0]);
}

export function sortByProp<
  T extends HashMap<string | number>,
  K extends keyof T,
>(list: T[], property: K, locale?: AppLocales): T[] {
  return list.sort((a, b) => {
    const valueA = a[property];
    const valueB = b[property];
    if (
      valueA &&
      valueB &&
      typeof valueA === 'string' &&
      typeof valueB === 'string'
    ) {
      return valueA.localeCompare(valueB, locale);
    }

    return valueA && valueB && valueA > valueB ? 1 : -1;
  });
}

export function safeSortByProp<
  T extends { label: string; [key: string]: string | number },
>(list: T[], property: string): T[] {
  if (list.some((element) => element[property] === undefined)) {
    return sortByProp(list, 'label');
  }

  return sortByProp(list, property);
}

export const modifiedAtSort = <T extends { modifiedAt: string }>(
  a: T,
  b: T
): number => (a.modifiedAt < b.modifiedAt ? 1 : -1);

export const createdAtSortAsc = <T extends { createdAt: string }>(
  a: T,
  b: T
): number => (a.createdAt > b.createdAt ? 1 : -1);

function boolToNumber(bool: boolean): number {
  return bool ? 1 : -1;
}
export const groupDeleted = <T extends { deleted: boolean }>(
  a: T,
  b: T
): number => boolToNumber(a.deleted) - boolToNumber(b.deleted);

export function groupAndSort<T>(
  groupBy: (a: T, b: T) => number,
  sortBy: (a: T, b: T) => number
): (a: T, b: T) => number {
  return function sort(a: T, b: T): number {
    return groupBy(a, b) + sortBy(a, b);
  };
}
