import { CellRenderer } from 'components/table/types';
import { DateTime } from 'luxon';
import { toStandardDateDisplayStringFormat } from 'shared/utils/date/dates';

export const displayDate = (
  date: DateTime | null | undefined | string,
  timezone: string
): null | string => {
  if (!date) {
    return null;
  }

  return toStandardDateDisplayStringFormat(date, timezone);
};

export function createCellModificationDate(
  timezone: string
): CellRenderer<{ modifiedAt: DateTime }> {
  return ({ rowData }): string | null => {
    return displayDate(rowData.modifiedAt, timezone);
  };
}

export function createCellCreationDate(
  timezone: string
): CellRenderer<{ createdAt: DateTime }> {
  return ({ rowData }): string | null => {
    return displayDate(rowData.createdAt, timezone);
  };
}

export function createCellExecutionDate(
  timezone: string
): CellRenderer<{ inspectionDate: DateTime }> {
  return ({ rowData }): string | null => {
    return displayDate(rowData.inspectionDate, timezone);
  };
}
