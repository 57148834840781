import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStylesCheckbox = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& .MuiCheckbox-root': {
        zIndex: 0,
        '&.Mui-checked .MuiIconButton-label:after': {
          content: '""',
          top: '31%',
          left: '32%',
          width: '35%',
          height: '39%',
          position: 'absolute',
          backgroundColor: theme.palette.primary.contrastText,
          zIndex: -1,
          borderColor: 'transparent',
        },
      },
      '& .MuiTypography-root': {
        userSelect: 'none',
      },
    },
  })
);
