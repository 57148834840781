import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useBacktrack } from 'components/common/withBacktrack';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { capitalizeFirst } from '../../helpers/misc';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '80px',
    marginRight: '16px',
    color: 'black',
    '&:active': {
      '& > span, & > svg': {
        color: 'rgba(0,0,0,.54)',
      },
    },
  },

  goBackContainer: {
    display: 'flex',
    flexShrink: 0,
  },
}));

const Navigator = (): React.ReactElement | null => {
  const intl = useIntl();
  const { titleStore, back } = useBacktrack();
  const classes = useStyles();

  const [title, setTitle] = useState(titleStore.get());
  useEffect(() => {
    return titleStore.subscribe(() => {
      setTitle(titleStore.get());
    });
  }, [titleStore]);

  if (!title?.length) {
    return null;
  }

  return (
    <div className={classes.goBackContainer}>
      <div
        onClick={back}
        data-qa='menuBarGoBack'
        className={classes.wrapper}
      >
        <ArrowBackIcon />

        <Box
          component='span'
          sx={{
            fontSize: { sm: 14, lg: 12 },
            display: { xs: 'none', sm: 'block' },
            fontWeight: 600,
            mx: 1,
          }}
        >
          {capitalizeFirst(intl.formatMessage({ id: title }))}
        </Box>
      </div>
    </div>
  );
};

export default Navigator;
