import { ReactElement } from 'react';
import { ProgressBar } from './ProgressBar';
import { ProgressText } from './ProgressText';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    barColorPrimary: (props: { color: string }) => ({
      backgroundColor: `${props.color} !important`,
    }),
  })
);

export function ProgressIndicator({
  done,
  max,
  progressBarBackground,
}: {
  done: number | undefined;
  max: number | undefined;
  progressBarBackground?: string;
}): ReactElement {
  const color = getProgressIndicatorColor(done, max);
  const classes = useStyles({ color });
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '75%',
        gap: '0.125rem',
      }}
    >
      <ProgressBar
        done={done}
        max={max}
        classes={classes}
        backgroundColor={progressBarBackground}
      />
      <ProgressText done={done} max={max} />
    </div>
  );
}

function getProgressIndicatorColor(
  done: number = 0,
  max: number = 1
): string {
  const percent = Math.abs((done * 100) / (max || 1));

  if (percent <= 10) {
    return '#F2471C';
  }

  if (percent > 10 && percent <= 30) {
    return '#FA8100';
  }

  if (percent > 30 && percent <= 50) {
    return '#FFAA00';
  }

  if (percent > 50 && percent <= 70) {
    return '#FDD22F';
  }

  if (percent > 70 && percent <= 90) {
    return '#B8C32D';
  }
  if (percent > 90) {
    return '#71AA3A';
  }

  return '#000000';
}
