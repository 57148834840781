import cn from 'classnames';
import { Spinner } from 'components/core';
import { useCompanies } from 'components/dataProviders/withCompanies';
import { IssueCard } from 'components/issue/Card';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { Store } from 'hooks/createStore';
import { ReactElement, useMemo } from 'react';
import { useStyles } from './styles';

type LoadableCardProps = {
  issueId: string;
  useStoreContext: () => {
    issuesStore: Store<IssueModel[]>;
    isLoadingStore: boolean;
  };
  onClick?: OnClick;
};
type IssueCardWithLoadingProps = {
  issue: IssueModel | undefined;
  loading: boolean;
  onClick?: OnClick;
};

type OnClick = (issue: IssueModel) => void;

function IssueCardWithLoading({
  onClick,
  issue,
  loading,
}: IssueCardWithLoadingProps): ReactElement {
  const classes = useStyles();
  const { loading: loadingCompanies } = useCompanies();

  if (!issue || loading || loadingCompanies) {
    return (
      <div className={cn(classes.root, classes.cardLoading)}>
        <Spinner
          reason={`IssueCardWithLoading !issue: ${!issue} || loading: ${loading} || loadingCompanies: ${loadingCompanies}`}
        />
      </div>
    );
  }

  return <IssueCard onClick={onClick} key={issue._id} issue={issue} />;
}

export function LoadableIssueCard({
  issueId,
  onClick,
  useStoreContext,
}: LoadableCardProps): ReactElement {
  const { issuesStore, isLoadingStore } = useStoreContext();
  const issue = useMemo(
    () =>
      !isLoadingStore &&
      issuesStore.get().find((issue) => issue._id === issueId),
    [issueId, isLoadingStore, issuesStore]
  );

  return (
    <IssueCardWithLoading
      onClick={onClick}
      issue={issue || undefined}
      loading={isLoadingStore}
    />
  );
}
