import { Divider } from '@mui/material';
import { MemoSingleChoice } from 'components/common/simpleInputFields/singleChoice';
import { Spinner } from 'components/core';
import ViewWrapper from 'components/core/Layout/ViewWrapper';
import {
  useFieldVisibility,
  withFieldVisibility,
} from 'components/dataProviders/withVisibleFields';
import { FieldWithState } from 'components/fieldVisibility/BoxWithState';
import { SubmitFooter } from 'components/submitFooter';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import {
  Dispatch,
  Fragment,
  MutableRefObject,
  ReactElement,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';
import {
  Field,
  getAvailableFields,
} from 'shared/domain/visibleField/availableFields';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { ProcessType } from 'shared/types/form';
import { Process } from 'shared/types/process';
import {
  addField,
  addFieldWithDependsOn,
  isFieldVisible,
  useFieldVisibilitySubmit,
} from './model';
import { useStyles } from './styles';
import { SingleChoiceValue } from 'components/common/simpleInputFields/singleChoice/types';
import { getIssueFormSchemaWithRemovedUnknown } from 'shared/domain/issueForm/getIssueFormSchemaWithRemovedUnknown';

export type FieldOnView = {
  fieldName: IssueFieldNames;
  canBeDisabled: boolean;
  labelId: string;
  visible: boolean;
  label: string;
  disabled: boolean;
};

const SPLIT_CHAR = ',';

function _FieldVisibility(): ReactElement {
  const settersRef = useRef<HashMap<Dispatch<SetStateAction<boolean>>>>(
    {}
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const processes = useSelector(processesSelector);
  const formRef = useRef({});
  const fields = useMemo(() => getAvailableFields(), []);
  const processRef = useRef(processes[0]);
  const [selectedProcess, setSelectedProcess] = useState(processes[0]);
  const { fieldVisibilityStore, resync } = useFieldVisibility();
  const { isPosting, onSubmit } = useFieldVisibilitySubmit(
    fieldVisibilityStore,
    formRef,
    SPLIT_CHAR
  );
  const [isDirty, setIsDirty] = useState(false);

  const handleSubmit = useCallback(() => {
    setIsDirty(false);
    onSubmit().then(() => {
      containerRef.current?.parentElement?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    });
  }, [onSubmit]);
  const handleCancel = useCallback(() => {
    setIsDirty(false);
    resync();
    containerRef.current?.parentElement?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [resync]);

  const available = useMemo(
    () =>
      processes.map((process: ProcessType) => ({
        _id: process._id,
        label: process.label,
      })) as LabelledEntity[],
    [processes]
  );

  const onChange = useCallback((event: any, value: boolean) => {
    setIsDirty(true);
    const fieldName = event.target.id;
    if (
      settersRef.current[fieldName] &&
      typeof settersRef.current[fieldName] === 'function'
    ) {
      settersRef.current[fieldName](value);
    }
    const processId = processRef.current._id;
    formRef.current[`${fieldName}${SPLIT_CHAR}${processId}`] = value;
  }, []);

  const onProcessChange = useCallback(
    (_, value: SingleChoiceValue) => {
      if (value?._id) {
        const foundProcess = processes.find(
          (process) => process._id === value._id
        )!;
        setSelectedProcess(foundProcess);
        processRef.current = foundProcess;
      } else {
        setSelectedProcess(processes[0]);
        processRef.current = processes[0];
      }
    },
    [processes]
  );

  return (
    <>
      <ViewWrapper height={56}>
        <div ref={containerRef} className={classes.root}>
          <div className={classes.column}>
            <MemoSingleChoice
              key={'select-process'}
              localisedLabel={''}
              labelId='field_visibility_select_process_dropdown_label'
              available={available}
              required={true}
              id={'select-process'}
              error={undefined}
              handleChange={onProcessChange}
              defaultValue={processes[0]}
              value={selectedProcess}
              disableClearable={true}
              dense={true}
              reserveSpaceForHelperText={true}
            />
          </div>
          <div className={classes.fieldsContainer}>
            <Fields
              key={selectedProcess._id}
              fields={fields}
              settersRef={settersRef}
              onChange={onChange}
              selectedProcess={selectedProcess}
            />
          </div>
        </div>
      </ViewWrapper>
      <SubmitFooter
        isPosting={isPosting}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        disableSubmit={!isDirty}
      />
    </>
  );
}

export const FieldVisibility = withFieldVisibility(_FieldVisibility);

function Fields({
  fields,
  settersRef,
  onChange,
  selectedProcess,
}: {
  fields: Field[];
  settersRef: MutableRefObject<HashMap<Dispatch<SetStateAction<boolean>>>>;
  onChange: (event: any, value: boolean) => void;
  selectedProcess: ProcessType;
}): ReactElement {
  const intl = useIntl();
  const { fieldVisibilityStore, loading } = useFieldVisibility();

  const [fieldVisibility, setFieldVisibility] = useState(
    fieldVisibilityStore.get()
  );
  const [resyncTime, setResyncTime] = useState(Date.now());

  useEffect(() => {
    setFieldVisibility(fieldVisibilityStore.get());
    return fieldVisibilityStore.subscribe(() => {
      setFieldVisibility(fieldVisibilityStore.get());
      setResyncTime(Date.now());
    });
  }, [fieldVisibilityStore]);

  const schema = getIssueFormSchemaWithRemovedUnknown(
    fields,
    selectedProcess._id
  );

  const fieldOnViewMap: HashMap<FieldOnView> = useMemo(() => {
    if (loading) return {};
    const fieldsOnView: HashMap<FieldOnView> = fields.reduce(
      (result, field) => {
        if (field.dependsOn) {
          const sourceFieldVisible = isSourceFieldVisible(
            fields,
            field.dependsOn,
            fieldVisibility,
            selectedProcess._id
          );

          addFieldWithDependsOn({
            result,
            field,
            fieldVisibility,
            selectedProcessId: selectedProcess._id,
            intl,
            sourceFieldVisible,
          });
        } else {
          addField({
            result,
            field,
            fieldVisibility,
            selectedProcessId: selectedProcess._id,
            intl,
          });
        }

        return result;
      },
      {} as HashMap<FieldOnView>
    );
    return fieldsOnView;
  }, [loading, fieldVisibility, fields, intl, selectedProcess]);

  if (loading) {
    return <Spinner reason='FieldsVisibility loading' />;
  }

  return (
    <>
      {schema.map((section) => {
        return (
          <Fragment key={section.sectionTitleId}>
            <h2 style={{ alignSelf: 'flex-start' }}>
              {intl.formatMessage({ id: section.sectionTitleId })}
            </h2>
            {section.fields.map((fieldName) => {
              const field = fieldOnViewMap[fieldName];
              if (!field) return null;
              return (
                <FieldWithState
                  key={`${field.fieldName},${selectedProcess._id},${field.visible},${resyncTime}`}
                  initialIsChecked={field.visible}
                  field={field}
                  settersRef={settersRef}
                  onChange={onChange}
                  label={field.label}
                  disabled={field.disabled}
                />
              );
            })}
          </Fragment>
        );
      })}
    </>
  );
}

function findVisibleSourceField(
  fields: Field[],
  dependsOnField: IssueFieldNames,
  fieldVisibility: HashMap<VisibleFieldModel[]>,
  selectedProcessId: Process
): boolean {
  const sourceField = fields.find((f) => f.fieldName === dependsOnField);
  if (!sourceField) return false;
  return isFieldVisible({
    field: sourceField,
    fieldVisibility,
    selectedProcessId,
  });
}

function isSourceFieldVisible(
  fields: Field[],
  dependsOn: IssueFieldNames | IssueFieldNames[],
  fieldVisibility: HashMap<VisibleFieldModel[]>,
  selectedProcessId: Process
): boolean {
  return Array.isArray(dependsOn)
    ? dependsOn.every((dependsOnField) =>
        findVisibleSourceField(
          fields,
          dependsOnField,
          fieldVisibility,
          selectedProcessId
        )
      )
    : findVisibleSourceField(
        fields,
        dependsOn,
        fieldVisibility,
        selectedProcessId
      );
}
