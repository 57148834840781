import listenToDocumentUpdatedMessage from 'shared/domain/document/listenToDocumentUpdatedMessage';
import startDocumentEdit from 'shared/domain/document/startDocumentEdit';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { Message } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export interface SetDocumentSync {
  (localId: number, syncStatus: SyncStatus): Promise<number>;
}

export const setDocumentSync: SetDocumentSync = function _setDocumentSync(
  localId: number,
  syncStatus: SyncStatus
): Promise<number> {
  return new Promise((resolve, reject): void => {
    const broadcast = listenToDocumentUpdatedMessage(
      (success: any): void => {
        broadcast.close();
        debugLog('setDocumentSync success', success);
        resolve(success);
      },
      (error: any): void => {
        broadcast.close();
        debugLog('setDocumentSync error', error);
        reject(error);
      },
      (event: Message): boolean => {
        return event.data === localId;
      }
    );

    startDocumentEdit({ localId, syncStatus });
  });
};
