import { LinearProgress } from '@mui/material';
import { ReactElement } from 'react';

export function ProgressBar({
  done,
  max,
  classes,
  backgroundColor = '#D9D9D9',
}: {
  done: number | undefined;
  max: number | undefined;
  classes: { barColorPrimary: string };
  backgroundColor?: string;
}): ReactElement {
  const normalized = Math.min(
    100,
    Math.max(0, calculatePercent(done, max))
  );
  return (
    <LinearProgress
      style={{
        width: '100%',
        height: '4px',
        borderRadius: '4px',
        backgroundColor: backgroundColor,
      }}
      classes={{
        barColorPrimary: classes.barColorPrimary,
      }}
      variant='determinate'
      value={normalized}
    />
  );
}

function calculatePercent(
  done: number | undefined,
  max: number | undefined
): number {
  if (!done || !max) return 0;
  return (done * 100) / max;
}
