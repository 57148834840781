import { EditContractUseCase } from 'shared/domain/contract/editContract';
import { contractEditModelToContractEditOutDto } from 'shared/domain/contract/mapping';
import { ContractEditModel } from 'shared/domain/contract/types/model';
import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { ContractEditRequestModel } from '../httpQueue/contractRequestRunner';

export class ContractEditor implements EditContractUseCase {
  constructor(
    private addRequest: (request: ContractEditRequestModel) => Promise<any>
  ) {}

  async execute(
    contractEditModel: ContractEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditContractUseCase', contractEditModel);

    const contractOutDto =
      contractEditModelToContractEditOutDto(contractEditModel);
    const request: ContractEditRequestModel = {
      createdAt: Date.now(),
      method: 'PUT',
      url: `/project/${contractEditModel.projectId}/contract/${contractEditModel._id}`,
      data: {
        contractEditOutDto: contractOutDto,
        projectId: contractEditModel.projectId,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.contract,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const contractEditor = new ContractEditor(addRequest);
