import { DocumentOnView } from 'presentation/document/documentOnView';
import { DocumentModel } from 'shared/domain/document/documentModel';

export function documentModelToDocumentOnView(
  document: DocumentModel
): DocumentOnView | undefined {
  if (!document.data) {
    return undefined;
  }

  return {
    _id: document._id,
    localId: document.localId,
    title: document.title,
    // if we say 'FILE', it will show (jpg icon)
    type: document.type,
    data: document.data,
    // TODO: PT-3773
    description: document.description,
    deleted: document.deleted,
    localData: document.localData,
    src: '#',
    downloadSrc: document.localData || '',
    syncStatus: document.syncStatus,
    drawingSyncStatus: document.drawingSyncStatus,
  };
}
