import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() =>
  createStyles({
    accordionIndex: { marginRight: '14px' },

    hustroTemplateAccordion: {
      '&.MuiAccordion-root:before': { opacity: 0 },
      '&.MuiPaper-elevation1': {
        boxShadow: 'unset',
      },
      '&.MuiAccordion-root.Mui-expanded:before': { opacity: 0 },
      '& .MuiAccordionSummary-root': {
        padding: 0,
        '&.Mui-expanded': {
          minHeight: '48px',
        },
        '& .accordionTitle': {
          display: 'flex',
          flexDirection: 'row' as 'row',
          alignItems: 'center',
          flexGrow: 1,
          '&.bold': {
            fontWeight: 700,
          },
        },
        '& .checklistItemSummary': {
          width: 0,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          flexGrow: 1,
          textOverflow: 'ellipsis',
          marginRight: '8px',
          '&.expanded': {
            whiteSpace: 'normal',
          },
        },
      },
      '& .MuiAccordionDetails-root': {
        padding: 0,
      },
      '& .MuiAccordionSummary-content': {
        display: 'flex',
        justifyContent: 'space-between',
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
    checkIconContainer: {
      border: '1px solid #C4C4C4',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkIcon: {
      fill: '#C4C4C4',
      height: '17px',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    graphicUploaderWrapper: {
      width: '100%',
      marginTop: '22px',
      marginBottom: '24px',
    },
    complianceCheck: {
      marginTop: '32px',
    },
  })
);
