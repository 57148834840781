import { DocumentCreateModel } from 'shared/domain/document/documentCreateModel';
import {
  CreateDocumentCustomEventDetail,
  DeleteDocumentCustomEventDetail,
  EditDocumentCustomEventDetail,
  GetDocumentCustomEventDetail,
  GetDocumentsByIdsCustomEventDetail,
  GetDocumentsByQueryCustomEventDetail,
} from 'shared/domain/messages/document/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';

const getOne = (e: any): void => {
  debugLog('GET SINGLE DOCUMENT', e);
  self.dispatchEvent(
    new CustomEvent<GetDocumentCustomEventDetail>(
      DomainMessagesTypes.getDocument,
      {
        detail: { id: e.id },
      }
    )
  );
};

const getList = (e: any): void => {
  debugLog('GET DOCUMENTS BY IDS', e);
  self.dispatchEvent(
    new CustomEvent<GetDocumentsByIdsCustomEventDetail>(
      RepositoryMessagesTypes.getDocuments,
      {
        detail: { ids: e.ids, type: e.type },
      }
    )
  );
};

const getByQuery = (e: any): void => {
  debugLog('GET DOCUMENTS BY QUERY', e);
  self.dispatchEvent(
    new CustomEvent<GetDocumentsByQueryCustomEventDetail>(
      RepositoryMessagesTypes.getDocuments,
      {
        detail: { query: e.query, type: e.type },
      }
    )
  );
};

const create = (e: {
  baseUrl: string;
  document: DocumentCreateModel;
  uniqueId: string;
}): void => {
  debugLog('CREATE DOCUMENT EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateDocumentCustomEventDetail>(
      RepositoryMessagesTypes.addDocument,
      {
        detail: { ...e },
      }
    )
  );
};

const edit = (e: any): void => {
  debugLog('editDocument event', e);
  self.dispatchEvent(
    new CustomEvent<EditDocumentCustomEventDetail>(
      RepositoryMessagesTypes.editDocument,
      {
        detail: { ...e },
      }
    )
  );
};

const remove = (e: any): void => {
  debugLog('removeDocument event', e);
  self.dispatchEvent(
    new CustomEvent<DeleteDocumentCustomEventDetail>(
      RepositoryMessagesTypes.deleteDocument,
      {
        detail: { ...e },
      }
    )
  );
};

const getSources = (e: any): void => {
  debugLog('getSources event', e);
  self.dispatchEvent(
    new CustomEvent<GetDocumentCustomEventDetail>(
      RepositoryMessagesTypes.getDocumentUrls,
      {
        detail: { id: e.id },
      }
    )
  );
};

export default {
  getOne,
  getList,
  getByQuery,
  create,
  edit,
  remove,
  getSources,
};
