import { Subscribe } from 'components/broadcastChannelListeners/channelListener/channelListener';
import {
  DomainMessagesTypes,
  Message,
  isDataChanged,
  isFailUploadedMessage,
} from 'shared/domain/messages/message';
import { useMountedRef } from 'hooks/useMountRef';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { useDataFlushedListener } from '../../broadcastChannelListeners/useDataFlushedListener';

export type UseEntityDataSubscriptionProps<T> = {
  subscribe: Subscribe;
  getAll: () => Promise<{ items: T[] }>;
  setEntity: (entity: { items: T[]; total: number }) => void;
  setLoading: (isLoading: boolean) => void;
  afterTableChange?: () => void;
  entityName?: string;
};

export function useEntityDataSubscription<T>({
  subscribe,
  getAll,
  setEntity,
  setLoading,
  afterTableChange,
  entityName,
}: UseEntityDataSubscriptionProps<T>): {
  resync: () => void;
  mountedRef: MutableRefObject<boolean>;
} {
  const mountedRef = useMountedRef();
  const dispatch = useDispatch();

  const resync = useCallback(() => {
    getAll()
      .then((response) => {
        if (!mountedRef.current) {
          return;
        }
        setEntity({
          items: response.items,
          total: response.items.length,
        });
      })
      .catch((e) => {
        if (!mountedRef.current) {
          return;
        }
        displayGenericErrorToaster(
          dispatch,
          `Error while resyncing ${
            entityName || 'unnamed entity'
          }. Received error: ${e}`
        );
      });
  }, [getAll, mountedRef, setEntity, dispatch, entityName]);

  useEffect(
    function dataListener() {
      const unsubscribe = subscribe((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (
          (event.data && isDataChanged(event)) ||
          event.type === DomainMessagesTypes.tableChanged
        ) {
          resync();
          if (afterTableChange) {
            afterTableChange();
          }
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [resync, subscribe, afterTableChange, mountedRef]
  );

  useEffect(
    function errorListener() {
      const unsubscribe = subscribe((event: Message): void => {
        if (!mountedRef.current) {
          return;
        }

        if (event.error && isFailUploadedMessage(event)) {
          displayGenericErrorToaster(
            dispatch,
            `Error while uploading ${
              entityName || 'unnamed entity'
            }. Received error: ${event.error}`
          );

          resync();
        }
      });

      return (): void => {
        unsubscribe();
      };
    },
    [resync, dispatch, subscribe, mountedRef, entityName]
  );

  useEffect(() => {
    getAll()
      .then((response) => {
        if (!mountedRef.current) {
          return;
        }

        setEntity({
          items: response.items,
          total: response.items.length,
        });
        setLoading(false);
      })
      .catch((e) => {
        if (!mountedRef.current) {
          return;
        }
        setLoading(false);
        displayGenericErrorToaster(
          dispatch,
          `Error while fetching ${
            entityName || 'unnamed entity'
          }. Received error: ${e}`
        );
      });
  }, [getAll, dispatch, setEntity, setLoading, mountedRef, entityName]);

  useDataFlushedListener(mountedRef, resync);

  return useMemo(() => {
    return {
      resync,
      mountedRef,
    };
  }, [mountedRef, resync]);
}
