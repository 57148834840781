import { ErrorPresentation } from 'helpers/validators';
import { HashMap } from 'shared/types/commonView';

export function findError(
  errors: HashMap<ErrorPresentation>,
  checklistItemId: string
): ErrorPresentation {
  const errorKey = Object.keys(errors).find((key) =>
    key.includes(`result-${checklistItemId}`)
  );

  if (errorKey) {
    return errors[errorKey];
  }
  return undefined;
}
