import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';

const useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(
  (theme: DefaultTheme) => ({
    dialogWrapper: {
      zIndex: 20000,
      '& .MuiPaper-root': {
        margin: '16px',
        width: '500px',
        flexShrink: 1,
      },
    },
    dialogTitle: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
    dialogHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
      alignItems: 'center',
    },
    dialogActions: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '24px',
      fontFamily: 'Archivo',
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    dialogContent: {
      '&:first-child': {
        paddingTop: 15,
      },
      width: '100%',
      boxSizing: 'border-box',
      minHeight: '10rem',
      maxHeight: '15rem',
      padding: '15px 24px',
      overflow: 'hidden' as 'hidden',
      overflowY: 'auto' as 'auto',
    },
    dialogListContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      margin: 0,
      width: '100%',
    },
    dialogListItem: {
      whiteSpace: 'nowrap' as 'nowrap',
      padding: '0',
      fontSize: '0.875rem',
      '& .listItemIcon': {
        minWidth: '1.5rem',
        width: '1.5rem',
        height: '1.5rem',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    listItemText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden' as 'hidden',
      '& .MuiListItemText-primary': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '0.775rem',
        lineHeight: '24px',
      },
    },
    searchField: {
      display: 'flex',
      alignItems: 'center',
      padding: '8px 24px',
      justifyContent: 'space-between',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    noItemsSearchPlaceholder: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      userSelect: 'none' as 'none',
      whiteSpace: 'pre-line',
      textAlign: 'center',
    },
    closeIcon: {
      opacity: 0.54,
      cursor: 'pointer',
    },
    checkbox: {
      color: '#757575',
      zIndex: 0,
      '&:hover': {
        backgroundColor: 'unset',
      },
      '&.Mui-checked': {
        '&:hover': {
          backgroundColor: 'unset',
        },
        '& .MuiIconButton-label:after': {
          content: '""',
          top: '27%',
          left: '32%',
          width: ' 35%',
          height: '50%',
          position: 'absolute',
          backgroundColor: theme.palette.primary.contrastText,
          zIndex: -1,
          borderColor: 'transparent',
        },
      },
    },
  })
);

export { useStyles };
