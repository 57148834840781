import { PromiseExtended } from 'dexie';
import {
  __HttpRequestModel__,
  HttpRequestEntity,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import {
  makeDefaultCount,
  makeDefaultGetMany,
} from './defaultDaoFactories';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import db from './index';
import { wrapQuery } from './queryWrapper';

export const add = wrapQuery(
  db,
  (data: __HttpRequestModel__): PromiseExtended<number> => {
    return (
      db.httpRequests
        // @ts-ignore _id is auto incremented
        .add(data)
        .then((id) => {
          debugLog('MessagesTypes.newRequest');
          self.dispatchEvent(
            new Event(RepositoryMessagesTypes.newRequest)
          );
          return id;
        })
        .catch((e) =>
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.add, 'httpRequests'),
            errorObj: e,
            additionalInfo: { query: { data } },
          })
        )
    );
  }
);

export const getFirst = wrapQuery(
  db,
  (): PromiseExtended<HttpRequestEntity | undefined> => {
    return db.httpRequests
      .where('status')
      .equals(HttpRequestStatus.NEW)
      .first()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.first, 'httpRequests'),
          errorObj: e,
          additionalInfo: {},
        })
      );
  }
);

export const getByQuery = makeDefaultGetMany<HttpRequestEntity>(
  db,
  db.httpRequests
);

export const getFirstFailed = wrapQuery(
  db,
  (skip: number): PromiseExtended<HttpRequestEntity | undefined> => {
    return db.httpRequests
      .where('status')
      .equals(HttpRequestStatus.FAILED)
      .offset(skip)
      .first()
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.getOne, 'httpRequests'),
          errorObj: e,
          additionalInfo: {},
        })
      );
  }
);

export const remove = wrapQuery(
  db,
  (id: number): PromiseExtended<void> => {
    return db.httpRequests.delete(id).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.remove, 'httpRequests'),
        errorObj: e,
        additionalInfo: {},
      })
    );
  }
);

export const count = makeDefaultCount<HttpRequestEntity>(
  db,
  db.httpRequests
);

export const updateOne = wrapQuery(
  db,
  (
    id: number,
    data: { status: HttpRequestStatus }
  ): PromiseExtended<number> => {
    return db.httpRequests.update(id, data).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.updateOne, 'httpRequests'),
        errorObj: e,
        additionalInfo: {},
      })
    );
  }
);
