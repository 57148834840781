import { Theme as DefaultTheme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

const dateRangeStaticButtonHeight = 35;
export const useStylesDateRange = makeStyles<
  DefaultTheme,
  { isMobile: boolean }
>((theme: DefaultTheme) =>
  createStyles({
    dateRange: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      gridColumn: '1 / -1',
      order: -1,
      boxSizing: 'border-box',
      '& .MuiFormControl-root.MuiTextField-root': {
        height: '48px',
        width: '100%',
        fill: '#757575',
        '& input': {
          height: '48px',
          boxSizing: 'border-box',
          borderColor: '1px solid #8A8A8A',
        },
      },
      '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiOutlinedInput-adornedEnd':
        {
          paddingRight: 0,
        },
    },
    popoverRangeControl: {
      '& .rdrDateRangePickerWrapper': {
        maxWidth: ({ isMobile }): string => (isMobile ? '340px' : '620px'),
        fontFamily: 'Archivo',
        flexFlow: 'row wrap',
        '& input': {
          fontFamily: 'Archivo',
        },
        '& select': {
          fontFamily: 'Archivo',
        },
        '& .rdrCalendarWrapper': {
          flexGrow: 1,
          '& .rdrMonth': {
            flexGrow: 1,
            boxSizing: 'border-box',
            width: ({ isMobile }): string => (isMobile ? '0' : '27.667em'),
            '& .rdrMonthName': {
              display: 'none',
            },
          },
          '& .rdrDayToday .rdrDayNumber span:after': {
            backgroundColor: theme.palette.primary.main,
          },
          '& .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after':
            {
              backgroundColor: theme.palette.primary.contrastText,
            },
          '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span':
            {
              color: theme.palette.primary.contrastText,
            },
          '& .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview': {
            backgroundColor: 'rgba(255, 255, 255, 0.4)',
          },
          '& .rdrMonthAndYearPickers': {
            width: 0,
            flexGrow: 1,
          },
        },
        '& .rdrDefinedRangesWrapper': {
          order: 1,
          flexGrow: 1,
          width: 'unset',
          borderLeft: ({ isMobile }): string =>
            isMobile ? 'none' : '1px solid #DADADA',
          display: 'flex',
          '& .rdrInputRanges': {
            display: 'none',
          },
          '& .rdrStaticRanges': {
            minWidth: '270px',
            flexGrow: 1,
            boxSizing: 'border-box',
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: ({ isMobile }): string =>
              isMobile ? '0px' : '22px',
            paddingBottom: '10px',
            flexFlow: ({ isMobile }): string =>
              isMobile ? 'row wrap' : 'column nowrap',
            justifyContent: 'space-between',
            '& .rdrStaticRange': {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxSizing: 'border-box',
              height: `${dateRangeStaticButtonHeight}px`,
              textTransform: 'uppercase',
              fontFamily: 'Archivo',
              fontSize: '0.875rem',
              fontWeight: 700,
              letterSpacing: '0em',
              flexGrow: 1,
              minWidth: '136px',
              width: ({ isMobile }): string =>
                isMobile ? 'calc(50% - 8px)' : 'unset',
              border: '1px solid #DADADA',
              margin: '4px',
              '& .rdrStaticRangeLabel': {
                lineHeight: `${dateRangeStaticButtonHeight}px`,
                padding: 0,
                textAlign: 'left',
                paddingLeft: '12px',
                height: '100%',

                width: '100%',
                color: '#000000',
              },
            },
          },
        },
      },

      '& .rdrStaticRangeSelected .rdrStaticRangeLabel': {
        backgroundColor: '#D8D8D8',
      },
    },
  })
);
