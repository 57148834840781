import {
  ComplianceCheckModel,
  InspectionModel,
  ProtocolItemModel,
} from 'shared/domain/inspection/inspectionModel';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import {
  ComplianceCheckEntity,
  InspectionEntity,
  ProtocolItemEntity,
} from './entity';
import { UserEntity } from 'shared/domain/user/types/entity';

export function inspectionEntityToModel(
  inspectionEntity: InspectionEntity,
  users: UserEntity[]
): InspectionModel {
  return {
    _id: inspectionEntity._id,
    contracts: inspectionEntity.contracts,
    controlledParty: inspectionEntity.controlledParty,
    createdAt: inspectionEntity.createdAt,
    deleted: inspectionEntity.deleted ? true : false,
    inspectionDate: inspectionEntity.inspectionDate,
    isCompleted: inspectionEntity.isCompleted,
    levels: inspectionEntity.levels,
    modifiedAt: inspectionEntity.modifiedAt,
    protocol: inspectionEntity.protocol.map((protocolItem) =>
      protocolEntityToModel(protocolItem, users)
    ),
    site: inspectionEntity.site,
    template: inspectionEntity.template,
    workTypes: inspectionEntity.workTypes,
    comment: inspectionEntity.comment,
    createdBy: toLabelledUser(
      users.find((user) => user._id === inspectionEntity.createdBy)
    ),
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === inspectionEntity.modifiedBy)
    ),
  };
}

function protocolEntityToModel(
  protocolEntity: ProtocolItemEntity,
  users: UserEntity[]
): ProtocolItemModel {
  return {
    _id: protocolEntity._id,
    complianceCheck: complianceCheckEntityToModel(
      protocolEntity.complianceCheck
    ),
    modifiedAt: protocolEntity.modifiedAt,
    templateChecklistItem: protocolEntity.templateChecklistItem,
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === protocolEntity.modifiedBy)
    ),
  };
}

function complianceCheckEntityToModel(
  complianceCheckEntity: ComplianceCheckEntity
): ComplianceCheckModel {
  return {
    issues: complianceCheckEntity.issues,
    result: complianceCheckEntity.result,
    comment: complianceCheckEntity.comment,
  };
}
