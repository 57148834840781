import SumoLogger from 'sumo-logger';
import { ENVIRONMENTS } from '../enums';

export enum FetchMethod {
  GET = 'GET',
  PUT = 'PUT',
  POST = 'POST',
  DELETE = 'DELETE',
  amazonFilePOST = 'amazonFilePOST',
  GET_ASSET = 'GET_ASSET',
}

export enum LogLevel {
  INFO = 'info',
  ERROR = 'error',
}

type LogFn = (body: LogData) => void;

export type LogData = {
  user_id?: string;
  user_email: string;
  msg: string;
  level: LogLevel;
  additionalInfo?: AdditionalAppLogInfo | AdditionalSwLogInfo | null;
  full_error_object?: any;
  logId?: string;
};

export type SharedLogsParams = LogData & {
  loggers: Array<SumoLogger | { log: LogFn }>;
};

export type LogDestinationUrls = {
  [key in ENVIRONMENTS]: string;
};

export type AdditionalSwLogInfo = {
  query?: object;
  response?: object;
  headersData?: object;
  userData?: object;
  syncKey?: string;
  setup?: object;
  service?: object;
  entityName?: string;
};

export type AdditionalAppLogInfo = {
  client_version: string | null;
  user_email: string;
  user_id: string;
};

export type FetchErrorDetails = {
  requestError: any;
  requestData: any;
  requestConfig: any;
  url: string;
  method: FetchMethod;
  response: any;
};

export type SwLogger = (
  message: string,
  logLevel: LogLevel,
  errorObject: any,
  additionalInfo: AdditionalSwLogInfo | null
) => void;

export type DeviceInfo = {
  device: {
    isMobile: boolean;
    userAgent: string;
    logicalProcessors: number;
  };
};
