import { Divider } from '@mui/material';
import { Skeleton } from '@mui/material';
import React, { ReactElement } from 'react';
import ViewWrapper from 'components/core/Layout/ViewWrapper';

const wrapperStyle = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  flexGrow: 1,
  marginTop: '20px',
  marginLeft: '51px',
  paddingRight: '45px',
  overflowY: 'auto' as 'auto',
  width: '816px',
};
const dividerStyle = { marginTop: '15px', marginBottom: '15px' };
export function SingleInspectionSkeleton(): ReactElement {
  //TODO https://hustro.atlassian.net/browse/PT-2401
  return (
    <ViewWrapper>
      <div style={wrapperStyle}>
        <Skeleton variant='text' width={'25%'} height={80} />

        <Divider style={dividerStyle} />
        <Skeleton variant='rectangular' width={'100%'} height={80} />

        <Divider style={dividerStyle} />
        <Skeleton variant='rectangular' width={'100%'} height={100} />
        <Skeleton variant='text' width={'45%'} height={60} />
        <Skeleton variant='text' width={'100%'} height={60} />
        <Skeleton variant='text' width={'45%'} height={60} />

        <Divider style={dividerStyle} />
        <Skeleton variant='text' width={'35%'} height={100} />
        <Skeleton variant='text' width={'100%'} height={100} />
        <Skeleton variant='rectangular' width={'100%'} height={250} />

        <Divider style={dividerStyle} />
        <Skeleton variant='text' width={'35%'} height={50} />
        <Skeleton variant='rectangular' width={'100%'} height={50} />
        <Skeleton variant='rectangular' width={'100%'} height={50} />
        <Skeleton variant='rectangular' width={'100%'} height={50} />
        <Skeleton variant='rectangular' width={'100%'} height={50} />
        <Skeleton variant='rectangular' width={'100%'} height={50} />
      </div>
    </ViewWrapper>
  );
}
