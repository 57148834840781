import { Box } from '@mui/material';
import React from 'react';
import HustroLogo from '../../../../assets/logoLetter.svg';
import useStyles from './styles';

function HeaderLogoComponent(): JSX.Element {
  const classes = useStyles();

  return (
    <Box
      data-qa='app-header-client-logo'
      className={classes.shortLogoContainer}
      boxShadow={1}
      sx={{
        backgroundColor: '#000',
      }}
    >
      <img
        alt='shortLogo'
        src={HustroLogo}
        className={classes.shortLogo}
      />
    </Box>
  );
}

export const HeaderLogo = React.memo(HeaderLogoComponent);
