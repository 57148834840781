import React from 'react';
import { Avatar } from '@mui/material';

function stringToColor(string: string): string {
  let hash = 0;
  let i: number;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function getInitials(name: string): string {
  const words = name.split(' ');
  const firstInitial = words[0] ? words[0][0] : '';
  const secondInitial = words[1] ? words[1][0] : '';

  return `${firstInitial}${secondInitial}`;
}

export default function BackgroundLetterAvatar({
  userName,
}: {
  userName: string;
}): React.ReactElement {
  return (
    <Avatar
      alt={userName}
      children={getInitials(userName)}
      sx={{
        bgcolor: stringToColor(userName),
      }}
    />
  );
}
