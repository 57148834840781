// @ts-nocheck
// react 18 types
import { WithLoadingLog } from 'components/general/loaders/Spinner/Spinner';
import React from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import GDRPInfo from 'components/core/GDPRClause/GDRPInfo';
import NotFoundPage from 'components/core/NotFoundPage/NotFoundPage';
import Bouncer from 'components/general/loaders/Bouncing/Bouncer';
import { useAuth0 } from '../../services/auth0/react-auth0.spa';
import IssueSkeleton from '../../views/issue/issueView/IssueSkeleton';
import UnifiedIssueViewContainer from '../../views/issue/issueView/UnifiedIssueViewContainer';
import PrivateRoute from './PrivateRoute';
import UnauthorizedScreen from './UnauthorizedScreen';
import { AuthorizableDocumentations } from './routeComponents/documentation';
import { AuthorizableInspection } from './routeComponents/inspection';
import { AuthorizableInspectionWizard } from './routeComponents/inspection';
import { AuthorizableTemplate } from './routeComponents/inspectionTemplate';

const ProjectRoutes = ({
  location,
}: RouteComponentProps): React.ReactElement => {
  const { isAuthenticated } = useAuth0();

  if (isAuthenticated === undefined) {
    return <Bouncer backgroundColor='#ccc' />;
  }

  if (isAuthenticated === false) {
    const { pathname, search } = location;
    const from = `${pathname}${search}`;

    return <UnauthorizedScreen from={from} />;
  }

  const issue = '/project/:projectId/issue/:issueId';
  const issueWithDocument =
    '/project/:projectId/issue/:issueId/document/:documentId';
  const event = '/project/:projectId/issue/:issueId/event/:eventId';
  const eventWithDocument =
    '/project/:projectId/issue/:issueId/event/:eventId/document/:documentId';

  const legacyIssue = '/project/:projectId/site/:siteId/issue/:issueId';
  const legacyIssueWithDocument =
    '/project/:projectId/site/:siteId/issue/:issueId/document/:documentId';
  const legacyEvent =
    '/project/:projectId/site/:siteId/issue/:issueId/event/:eventId';
  const legacyEventWithDocument =
    '/project/:projectId/site/:siteId/issue/:issueId/event/:eventId/document/:documentId';

  return (
    <Switch>
      <Route
        // order matters
        path={[
          legacyEventWithDocument,
          legacyIssueWithDocument,
          legacyEvent,
          legacyIssue,
        ]}
        exact
        render={(): React.ReactElement => {
          const pathnameArray = location.pathname.split('/');
          const siteIndex = pathnameArray.findIndex(
            (text) => text === 'site'
          );
          if (siteIndex < 0) {
            return <Redirect to={'/issue'} />;
          }
          pathnameArray.splice(siteIndex, 2);

          return <Redirect to={pathnameArray.join('/')} />;
        }}
      />

      <Route
        // order matters
        path={[eventWithDocument, issueWithDocument, event, issue]}
        exact
        render={(): React.ReactElement => {
          return (
            <React.Suspense
              fallback={
                <WithLoadingLog reason='Route React.Suspense fallback'>
                  <IssueSkeleton />
                </WithLoadingLog>
              }
            >
              <PrivateRoute component={UnifiedIssueViewContainer} />
            </React.Suspense>
          );
        }}
      />

      <Route
        path={[
          '/project/:projectId/inspectionTemplate/:id/checklist/:checklistId/document/:documentId',
          '/project/:projectId/inspectionTemplate/:id/checklist/:checklistId',
          '/project/:projectId/inspectionTemplate/:id/document/:documentId',
          '/project/:projectId/inspectionTemplate/:id?',
          '/project/:projectId/inspectionTemplate',
        ]}
        render={(): React.ReactElement => (
          <PrivateRoute component={AuthorizableTemplate} />
        )}
      />

      <Route
        path={[
          '/project/:projectId/documentation/dir/:folderId?',
          '/project/:projectId/documentation/file/:fileId?',
          '/project/:projectId/documentation',
        ]}
        render={(): React.ReactElement => (
          <PrivateRoute component={AuthorizableDocumentations} />
        )}
      />

      <Route
        exact
        path='/project/:projectId/inspection/:inspectionId/edit'
        render={(): React.ReactElement => (
          <PrivateRoute component={AuthorizableInspectionWizard} />
        )}
      />

      <Route
        path={[
          '/project/:projectId/inspection/:inspectionId/template/:templateId/document/:documentId',
          '/project/:projectId/inspection/:inspectionId/protocol/:protocolId/document/:documentId',
          '/project/:projectId/inspection/:inspectionId',
        ]}
        render={(): React.ReactElement => (
          <PrivateRoute component={AuthorizableInspection} />
        )}
      />

      <Route
        path='/project/:projectId/gdpr/clause'
        render={(): React.ReactElement => {
          return <GDRPInfo />;
        }}
      />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default ProjectRoutes;
