import {
  DrawingType,
  DefinedDrawing,
} from 'components/common/graphicUploader/types';
import {
  DrawingModel,
  Drawings,
} from 'shared/domain/document/drawingModel';

export function toDrawingModel(drawing: DrawingType): DrawingModel {
  if (!drawing) {
    throw new Error('Cannot convert undefined to DrawingModel');
  }
  if (drawing.clear && drawing.data) {
    return {
      ...(drawing as DefinedDrawing),
      type: Drawings.withClear,
    };
  }

  if (drawing.clear && !drawing.data) {
    return {
      clear: true,
      type: Drawings.fullClear,
    };
  }

  if (!drawing.clear && drawing.data) {
    return {
      ...(drawing as DefinedDrawing),
      clear: false,
      type: Drawings.withoutClear,
    };
  }

  throw new Error('Unknown conversion to DrawingModel');
}
