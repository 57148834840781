import {
  ErrorPresentation,
  requiredValidation,
  stringMinMaxValidation,
  stringMaxValidation,
  notEmptyArrayValidation,
  emailValidation,
  oneOfValidation,
  manyOfValidation,
} from 'helpers/validators';
import { Dispatch, SetStateAction } from 'react';
import { HashMap, LabelledEntity } from 'shared/types/commonView';
import { getAvailableFields } from 'shared/domain/visibleField/availableFields';
import {
  IssuesReportFormFields,
  IssuesReportFormValidation,
} from './types';
import { ReportFieldsContent } from 'shared/domain/report/reportModel';
import { FieldSize } from 'shared/types/fieldSize';

const validation: IssuesReportFormValidation = {
  recipients: recipientsValidation,
  name: nameValidation,
  message: messageValidation,
  localeCode: localeCodeValidation,
  fieldsContent: fieldsTypeValidation,
  selectedFields: selectedFieldsValidation,
};

function nameValidation(value: any): ErrorPresentation {
  return (
    requiredValidation(value) || stringMinMaxValidation(FieldSize.L, value)
  );
}

function messageValidation(value: any): ErrorPresentation {
  return stringMaxValidation(FieldSize.XL, value);
}

function recipientsValidation(value: any): ErrorPresentation {
  return requiredValidation(value) ||
    notEmptyArrayValidation(value) ||
    value.every((val: any) => emailValidation(val?.email))
    ? { helperText: { id: 'validation_value_must_be_email' } }
    : undefined;
}

function localeCodeValidation(value: any): ErrorPresentation {
  return requiredValidation(value);
}

function fieldsTypeValidation(value: LabelledEntity): ErrorPresentation {
  return oneOfValidation(value._id, Object.values(ReportFieldsContent));
}

function selectedFieldsValidation(
  value: LabelledEntity[]
): ErrorPresentation {
  return manyOfValidation(
    getAvailableFields().map((field) => field.fieldName),
    value.map((v) => v._id)
  );
}

export function validate(
  key: keyof IssuesReportFormFields,
  value: IssuesReportFormFields[keyof IssuesReportFormFields]
): ErrorPresentation {
  if (!validation[key]) return;
  return validation[key](value);
}

export function handleFormValidation(
  errorsSetter: Dispatch<SetStateAction<HashMap<ErrorPresentation>>>,
  form: IssuesReportFormFields
): boolean {
  const errors = Object.entries(form).reduce<HashMap<ErrorPresentation>>(
    (errors, [key, value]) => {
      const error = validate(
        key as keyof IssuesReportFormFields,
        value as any
      );
      if (error) {
        errors[key] = error;
      }
      return errors;
    },
    {}
  );
  errorsSetter(errors);
  return Object.keys(errors).length > 0;
}
