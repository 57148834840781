export interface IDocumentationDeleteUploader {
  DELETE: (url: string) => Promise<unknown>;
}

export interface UploadDocumentationDelete {
  (uploader: IDocumentationDeleteUploader, url: string): Promise<boolean>;
}

export const uploadDocumentationDelete: UploadDocumentationDelete =
  async function _uploadDocumentationDelete(
    uploader: IDocumentationDeleteUploader,
    url: string
  ): Promise<boolean> {
    try {
      await uploader.DELETE(url);
      return true;
    } catch (e) {
      return false;
    }
  };
