import { UserCreateOutDto } from 'shared/dtos/out/user';
import { UserCreateModel } from '../userModel';

export function userCreateModelToCreateOutDto(
  userCreateModel: UserCreateModel
): UserCreateOutDto {
  return {
    label: userCreateModel.label,
    email: userCreateModel.email,
    permissions: userCreateModel.permissions,
  };
}
