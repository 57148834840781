import React, { useCallback, useEffect, useRef } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useDialog } from '../Dialog/common/DialogContext';
import useFormEdit from '../../../views/issue/forms/useFormEdit';

type LeaveDialogProps = {
  open: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

const LeaveDialog = ({
  open,
  onConfirm,
  onCancel,
}: LeaveDialogProps): JSX.Element => {
  const { clearAllForms } = useFormEdit();
  const createDialog = useDialog();
  const openRef = useRef(open);
  const intl = useIntl();

  const showDiscardDialog = useCallback(
    (onConfirm): Promise<void> => {
      const handleSubmit = (): void => {
        onConfirm();
        clearAllForms();
      };

      return createDialog({
        catchOnCancel: true,
        title: intl.formatMessage({
          id: 'dialog_confirmation_discard_changes_title_web',
        }),
        description: (
          <span>
            <FormattedMessage id='dialog_confirmation_discard_changes_web' />
          </span>
        ),
      })
        .then(handleSubmit)
        .catch(onCancel);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    openRef.current = open;
  }, [open]);

  useEffect(() => {
    if (openRef.current) {
      showDiscardDialog(onConfirm);
    }
  }, [onConfirm, showDiscardDialog]);

  return <div />;
};

export default LeaveDialog;
