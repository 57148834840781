import { ReactElement, useContext, useEffect } from 'react';
import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import {
  isDataFlushedMessage,
  isProjectDataFlushedMessage,
} from './model';
import { ClientContext } from 'with-client';
import { useHistory } from 'react-router-dom';
import { startAllProjectsSynchronization } from 'shared/domain/project/startAllProjectsSynchronization';
import { Message } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

type WithDataFlushedSubscriberProps = { children: ReactElement };

export function WithDataFlushedSubscriber({
  children,
}: WithDataFlushedSubscriberProps): ReactElement {
  const client = useContext(ClientContext);
  const history = useHistory();

  useEffect(() => {
    const broadcast = new BroadcastChannel(ChannelNames.apiChannel);

    broadcast.onmessage = (event: Message): void => {
      if (isDataFlushedMessage(event)) {
        client.authenticate();
        debugLog('startAllProjectsSynchronization after data flushed');
        startAllProjectsSynchronization();
        return;
      }

      if (isProjectDataFlushedMessage(event)) {
        client.fetchMe();
        debugLog(
          'startAllProjectsSynchronization after project data flushed'
        );

        startAllProjectsSynchronization();
        return;
      }
    };

    return (): void => {
      broadcast.close();
    };
  }, [client, history]);

  return children;
}
