import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  GetChartFiltersCustomEvent,
  SetChartFiltersCustomEvent,
} from 'serviceWorker/api/types';
import { ChannelNames } from 'shared/domain/channelNames';
import * as chartFilters from 'serviceWorker/db/chartFilters';

async function getChartFilters(
  event: GetChartFiltersCustomEvent
): Promise<void> {
  const _id = event?.detail._id;
  const filters = await chartFilters.getOne(_id);

  const broadcast = new BroadcastChannel(ChannelNames.chartFiltersChannel);
  broadcast.postMessage({
    type: DomainMessagesTypes.chartFiltersData,
    data: {
      chartId: _id,
      filters: filters,
    },
  });
  broadcast.close();
}

function setChartFilters(event: SetChartFiltersCustomEvent): void {
  const _id = event?.detail._id;
  const filters = event?.detail.filters;

  chartFilters.add({ _id, filters });
}

export const init = (): void => {
  // @ts-ignore Custom Event
  self.addEventListener(
    RepositoryMessagesTypes.getChartFilters,
    getChartFilters
  );
  // @ts-ignore Custom Event
  self.addEventListener(
    RepositoryMessagesTypes.setChartFilters,
    setChartFilters
  );
};
