import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, createStore, Store } from 'redux';

import { persistedReducer } from '../redux/reducers';
import { ToasterMiddleware } from '../redux/middlewares';
import { browserHistory } from './browserHistory';

//@ts-ignore
const historyMiddleware = routerMiddleware(browserHistory);
const middlewares = [historyMiddleware, ToasterMiddleware];

// @ts-ignore TODO MUI5
const storeWithMiddleware = applyMiddleware(...middlewares)(createStore);

export const configureStore = (): Store => {
  const store = storeWithMiddleware(
    persistedReducer,
    composeWithDevTools()
  );

  if (process.env.REACT_APP_NODE_ENV === 'dev') {
    if (module.hot) {
      module.hot.accept('../redux/reducers', () => {
        store.replaceReducer(persistedReducer);
      });
    }
  }

  return store;
};

export const store = configureStore();
