import { BroadcastChannel } from 'broadcast-channel';
import { ContractModel } from 'shared/domain/contract/types/model';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ContractInDto } from 'shared/dtos/in/contract';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { contractEntityToModel } from 'serviceWorker/repository/contract/mapping';
import { ChannelNames } from 'shared/domain/channelNames';
import { getUsersSynchronized } from 'serviceWorker/services/users/getUsers';
import * as dbCompanies from '../../db/companies';
import * as dbContracts from '../../db/contracts';
import { makeBroadcastEntity } from '../factories';
import { broadcastCreatedContractError } from './error';

async function getOneContractModelFromDbById(
  id: string
): Promise<ContractModel> {
  const [entity, users] = await Promise.all([
    dbContracts.getOne(id),
    getUsersSynchronized(),
  ]);
  if (!entity) {
    throw new Error(`Cannot find contract ${id}`);
  }
  const companies = await dbCompanies.get();
  return contractEntityToModel(entity, companies, users);
}

export const getAndBroadcastOneContract = makeBroadcastEntity(
  {
    getOne: getOneContractModelFromDbById,
  },
  ChannelNames.contractChannel,
  DomainMessagesTypes.contract
);

export const broadcastUploadedContract = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.contractChannel
);

export const broadcastDeletedContract = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.contractChannel
);

export const broadcastCreatedContract = async (
  contractInDto: ContractInDto,
  uniqueId: string
): Promise<void> => {
  try {
    await dbContracts.addBatch([contractInDto]);
    const contractEntity = await dbContracts.getOne(contractInDto._id);
    if (!contractEntity) {
      throw new Error(`Cannot find contract with id ${contractInDto._id}`);
    }
    const users = await getUsersSynchronized();
    const companies = await dbCompanies.get();
    broadcastContractSuccess(
      DomainMessagesTypes.createdEntity,
      contractEntityToModel(contractEntity, companies, users),
      uniqueId
    );
  } catch (error) {
    broadcastCreatedContractError(error);
  }
};

export const broadcastUpdatedContract = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.contractChannel
);

function broadcastContractSuccess(
  type: DomainMessagesTypes,
  data: ContractModel,
  uniqueId?: string
): void {
  const broadcast = new BroadcastChannel(ChannelNames.contractChannel);
  broadcast.postMessage({
    data,
    type,
    uniqueId,
  });
  broadcast.close();
}

// const getAndBroadcastContract = async (
//   e: GetContractCustomEvent
// ): Promise<void> => {
//   const broadcast = new BroadcastChannel(ChannelNames.contractChannel);
//   try {
//     const contractEntity = await getContractById(e.detail.id);
//     if (contractEntity) {
//       const [allContractPartiesEntities, users] = await Promise.all([
//         getAllContractPartyEntities([contractEntity]),
//         getUsersSynchronized(),
//       ]);
//       broadcast.postMessage({
//         data: contractEntityToModel(
//           contractEntity,
//           allContractPartiesEntities,
//           users
//         ),
//         type: MessagesTypes.contract,
//       });
//     } else {
//       broadcast.postMessage({
//         error: Error(`could not a find contract with id ${e.detail.id}`),
//         type: MessagesTypes.contract,
//       });
//     }
//   } catch (e) {
//     broadcast.postMessage({ error: e, type: MessagesTypes.contract });
//   }
//   broadcast.close();
// };
