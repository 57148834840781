import * as dbVisibleFields from 'serviceWorker/db/visibleFields';
import { debugLog } from 'shared/logger/debugLog';
import { getCurrentUsers } from 'serviceWorker/services/users/getUsers';
import { visibleFieldEntityToModel } from 'serviceWorker/repository/visibleField/mappings';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';

const cachedResponseHolder = { current: [] as VisibleFieldModel[] };
let lastRequest = 0;

export async function getAllCurrentVisibleFieldsPromise(): Promise<
  VisibleFieldModel[]
> {
  const start = Date.now();
  if (start - lastRequest < 1000) {
    debugLog('getAllCurrentVisibleFields returned cached response');
    return cachedResponseHolder.current;
  }
  const [visibleFields, users] = await Promise.all([
    dbVisibleFields.get(),
    getCurrentUsers(),
  ]);
  const end = Date.now();
  debugLog('getAllCurrentVisibleFields time:', end - start);
  cachedResponseHolder.current = visibleFields.map((visibleField) =>
    visibleFieldEntityToModel(visibleField, users)
  );
  lastRequest = end;
  return cachedResponseHolder.current;
}
