import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
  DragEvent,
} from 'react';
import {
  DirectoryRoot,
  useCurrentDirectoryContext,
} from 'components/dataProviders/withCurrentDirectory';
import { useIntl } from 'react-intl';
import { NavigateNext as BreadcrumbsSeparator } from '@mui/icons-material';
import { useDocumentationController } from 'components/dataProviders/withDocumentationController';
import { useDocumentationSearchContext } from 'components/dataProviders/withDocumentationSearch';

export function DocumentationBreadcrumbs(): ReactElement {
  const intl = useIntl();
  const { searchPhraseStore } = useDocumentationSearchContext();
  const { currentDirectoryRootsStore, currentDirectoryStore } =
    useCurrentDirectoryContext();
  const { setParent } = useDocumentationController();
  const rootBreadcrumb = useMemo(() => {
    return {
      _id: '',
      name: intl.formatMessage({ id: 'general_documentation' }),
    };
  }, [intl]);
  const [breadcrubms, setBreadcrumbs] = useState<DirectoryRoot[]>([
    rootBreadcrumb,
    ...currentDirectoryRootsStore.get(),
  ]);

  useEffect(() => {
    setBreadcrumbs([rootBreadcrumb, ...currentDirectoryRootsStore.get()]);
  }, [rootBreadcrumb, currentDirectoryRootsStore]);

  const breadcrubmsSetCallback = useCallback(() => {
    const hasSearchPhrase = Boolean(searchPhraseStore.get());
    if (hasSearchPhrase) {
      setBreadcrumbs([]);
    } else {
      setBreadcrumbs([
        rootBreadcrumb,
        ...currentDirectoryRootsStore.get(),
      ]);
    }
  }, [rootBreadcrumb, currentDirectoryRootsStore, searchPhraseStore]);

  useEffect(() => {
    return searchPhraseStore.subscribe(breadcrubmsSetCallback);
  }, [breadcrubmsSetCallback, searchPhraseStore]);

  useEffect(() => {
    return currentDirectoryRootsStore.subscribe(breadcrubmsSetCallback);
  }, [breadcrubmsSetCallback, currentDirectoryRootsStore]);

  const onClick = useCallback(
    (id: string) => {
      currentDirectoryStore.set(id);
    },
    [currentDirectoryStore]
  );

  const onDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    (e.target as HTMLDivElement).style.backgroundColor =
      'rgba(251, 204, 48, 0.45)';
  }, []);
  const onDragLeave = useCallback((e: DragEvent<HTMLDivElement>) => {
    (e.target as HTMLDivElement).style.backgroundColor = 'unset';
  }, []);

  return (
    <div
      style={{ display: 'flex', marginLeft: '0.75rem', flexWrap: 'wrap' }}
    >
      {breadcrubms.map((breadcrumb, index, array) => (
        <div
          key={breadcrumb._id}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={() => setParent(breadcrumb)}
            style={{
              padding: '0.5em',
              borderRadius: '4px',
              fontWeight: index + 1 === array.length ? 800 : 400,
              fontSize: '0.875rem',
              fontFamily: 'Archivo',
              cursor: 'pointer',
              marginTop: `0.25em`,
              marginBottom: `0.25em`,
            }}
            onClick={() => onClick(breadcrumb._id)}
          >
            {breadcrumb.name}
          </p>
          {index + 1 < array.length && (
            <BreadcrumbsSeparator htmlColor='#BFBFBF' fontSize='small' />
          )}
        </div>
      ))}
    </div>
  );
}
