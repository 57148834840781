import { DomainMessagesTypes } from 'shared/domain/messages/message';
import {
  makeSimpleBroadcastEntity,
  makeSimpleBroadcastError,
} from 'serviceWorker/repository/broadcasts/factory';
import { ChannelNames } from 'shared/domain/channelNames';

export const broadcastFieldValuesChange = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.fieldValuesChannel
);

export const broadcastFieldValuesChangeError = makeSimpleBroadcastError(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.fieldValuesChannel
);

export const broadcastFieldValuesCreate = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdEntity,
  ChannelNames.fieldValuesChannel
);

export const broadcastFieldValuesCreateError = makeSimpleBroadcastError(
  DomainMessagesTypes.createdEntity,
  ChannelNames.fieldValuesChannel
);
