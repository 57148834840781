import { makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
export const useStyles = makeStyles<
  DefaultTheme,
  { isMobile: boolean; isExpanded: boolean }
>(() => ({
  header: {
    height: '56px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    flexDirection: ({ isMobile }) => (isMobile ? 'column' : 'row'),
  },
}));
