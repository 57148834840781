import {
  HttpPostRequestModel,
  HttpPutRequestModel,
  __HttpRequestModel__,
  HttpRequestModelType,
} from 'shared/domain/httpRequest/httpRequestModel';
import { LevelInDto } from 'shared/dtos/in/level';
import {
  AfterCreateLevelChangeOutDto,
  LevelEditsOutDto,
  LevelCreateOutDto,
} from 'shared/dtos/out/level';
import {
  ILevelUploader,
  UploadLevel,
  UploadLevelChanges,
  UploadLevelDelete,
  UploadLevelRestore,
} from 'serviceWorker/repository/level/uploadLevel';
import { IRequestRunner } from '../httpRequestRunner';
import {
  LevelHttpCreateRequestModel,
  LevelHttpDeleteRequestModel,
  LevelHttpEditRequestModel,
  LevelHttpRestoreRequestModel,
} from './types';

export class LevelRequestRunner implements IRequestRunner {
  constructor(
    private levelUploader: ILevelUploader,
    private uploadLevelUseCase: UploadLevel,
    private saveLevel: (data: LevelInDto) => Promise<unknown>,
    private uploadLevelChangesUseCase: UploadLevelChanges,
    private uploadLevelDeleteUseCase: UploadLevelDelete,
    private deleteLevel: (levelId: string) => Promise<unknown>,
    private uploadLevelRestoreUseCase: UploadLevelRestore,
    private restoreLevel: (levelId: string) => Promise<unknown>
  ) {}
  async execute(
    request:
      | __HttpRequestModel__
      | LevelHttpCreateRequestModel
      | LevelHttpEditRequestModel
      | LevelHttpDeleteRequestModel
      | LevelHttpRestoreRequestModel
  ): Promise<unknown> {
    switch (request.method) {
      case 'POST': {
        return this.uploadLevel(request as LevelHttpCreateRequestModel);
      }
      case 'PUT': {
        return this.uploadLevelChanges(
          request as LevelHttpEditRequestModel
        );
      }
      case 'DELETE': {
        return this.uploadLevelDelete(
          request as LevelHttpDeleteRequestModel
        );
      }
      case 'GET': {
        return this.uploadLevelRestore(
          request as LevelHttpRestoreRequestModel
        );
      }
    }
  }

  private async uploadLevel(
    request: LevelHttpCreateRequestModel
  ): Promise<boolean> {
    if (!request.data?.projectId || !request.data?.levelCreateOutDto) {
      throw new Error('Cannot upload level without values');
    }

    let levelInDto = await this.uploadLevelUseCase(
      this.levelUploader,
      request.data.levelCreateOutDto,
      request.url
    );
    if (request.data.afterCreateLevelChangeOutDto) {
      const setMapResponse = await this.uploadLevelChangesUseCase(
        this.levelUploader,
        [
          {
            levelId: levelInDto._id,
            documentation:
              request.data.afterCreateLevelChangeOutDto.documentation,
          },
        ],
        request.url
      );
      levelInDto = setMapResponse[0];
    }
    await this.saveLevel(levelInDto);
    return true;
  }

  private uploadLevelChanges(
    request: LevelHttpEditRequestModel
  ): Promise<unknown> {
    if (!request.data?.projectId || !request.data?.changes) {
      throw new Error('Cannot update level without values');
    }
    return this.uploadLevelChangesUseCase(
      this.levelUploader,
      request.data.changes,
      request.url
    );
  }

  private async uploadLevelDelete(
    request: LevelHttpDeleteRequestModel
  ): Promise<boolean> {
    // const url = `/project/${request.data.projectId}/level/${request.data.levelId}`;

    await this.uploadLevelDeleteUseCase(this.levelUploader, request.url);
    await this.deleteLevel(request.data.levelId);
    return true;
  }

  private async uploadLevelRestore(
    request: LevelHttpRestoreRequestModel
  ): Promise<boolean> {
    // const url = `/project/${request.data.projectId}/level/${request.data.levelId}/restore`;

    await this.uploadLevelRestoreUseCase(this.levelUploader, request.url);
    await this.restoreLevel(request.data.levelId);
    return true;
  }
}
