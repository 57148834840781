import React, { memo } from 'react';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { useParams } from 'react-router-dom';
import { withUsers } from 'components/dataProviders/withUsers';
import { useUser, withUser } from 'components/dataProviders/withUser';
import { useGetUser } from 'hooks/useGetUser';
import { SingleUser } from 'components/user/single';
import { withProcessesData } from 'components/common/withProcessesData';
import { Spinner } from 'components/core';
import { withSites } from 'components/dataProviders/withSites';
import { debugLog } from 'shared/logger/debugLog';

function SingleUserView(): React.ReactElement {
  // const { id } = useParams<{ id: string }>();
  const { loading, user } = useUser();
  // useGetUser(id, true);

  if (loading || !user) {
    debugLog('SingleUserView loading || !user', loading, user);
    return <Spinner reason='SingleUserView loading || !user' />;
  }

  return <SingleUser {...user} />;
}

export const MemoUser = memo(
  withFetcherReady(
    withProcessesData(withSites(withUsers(withUser(SingleUserView))))
  )
);
