import React, { useCallback } from 'react';

import { useCreateIssue } from './createIssueContext/CreateIssueContext';
import { MemoButtonPlusCreate } from 'components/general/ButtonPlusCreate';
import { Permission, validateCreatePermission } from 'helpers/permission';
import { useIsOffline } from 'components/common/withIsOffline';

const CreateButton = (): JSX.Element => {
  const createIssue = useCreateIssue();
  const isOffline = useIsOffline();

  const canCreate = useCallback((): Permission => {
    return validateCreatePermission(true, isOffline);
  }, [isOffline]);

  return (
    <MemoButtonPlusCreate
      id='createIssueButton'
      canCreate={canCreate}
      creationAction={createIssue}
      dataQaId='toolbar-create-issue-button'
      textId='toolbar_title_create_issue'
    />
  );
};

export default CreateButton;
