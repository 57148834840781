import { toShortISO, nowTimezonedDateTime } from 'shared/utils/date/dates';

export const palette = {
  orange: '#FDB22B',
  yellow: '#FCD748',
  aquamarine: '#C0C942',
  lightgreen: '#7EB24A',
  green: '#479F4C',
  darkgreen: '#12897B',
  marine: '#1BACC0',
  lightblue: '#1B9DE2',
  blue: '#288AE2',
  darkblue: '#3A4CA9',
  purple: '#8D2CA8',
  pink: '#D62162',
  red: '#E33B3B',
  gray: '#DADADA',
  black: '#000000',
};

export function fromDate14DaysEarlier(timezone: string): string {
  return toShortISO(nowTimezonedDateTime(timezone).minus({ days: 14 }));
}

export function fromDate1MonthEarlier(timezone: string): string {
  return toShortISO(nowTimezonedDateTime(timezone).minus({ month: 1 }));
}

export function toDateToday(timezone: string): string {
  return toShortISO(nowTimezonedDateTime(timezone));
}
