import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import { AxiosResponse } from 'axios';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { useInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';
import { DocumentToTemplateFromUrlBinder } from 'shared/domain/document/documentBinder';
import { InspectionTemplateInDto } from 'shared/dtos/in/template';
import { projectIdSelector } from 'helpers/misc';
import { ErrorPresentation } from 'helpers/validators';
import { useRedirect } from 'hooks/useRedirect';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import { StoreState } from 'setup/types/core';
import { ProcessType } from 'shared/types/form';
import { Process } from 'shared/types/process';
import useFormEdit from 'views/issue/forms/useFormEdit';
import ViewWrapper from 'components/core/Layout/ViewWrapper';
import { logError } from 'setup/logger/logError';
import { createChecklistItem } from '../populator/helpers';
import { TemplateDefaultValues } from '../populator/types';
import {
  canAddItem,
  checkIndentificableListEquality,
  chooseTitle,
  copyChecklistItemValues,
  createGUSettings,
  isEmptyChangeInObject,
  scrollToFirstError,
} from './helpers';
import { MemoPresentational } from './presentational';
import {
  CreateInspectionTemplateErrors,
  FormChecklistItem,
  TemplateValidItem,
  TemplateWizardFormProps,
} from './types';
import {
  chooseOptimalChecklistErrors,
  chooseOptimalErrors,
  descriptionValidation,
  processValidation,
  summaryValidation,
} from './validation';

function TemplateWizardForm(props: TemplateWizardFormProps): ReactElement {
  const projectId = useSelector(projectIdSelector);
  const formName = 'template';
  const {
    originalDefaultValues,
    defaultValues,
    submitHandler,
    publishHandler,
    generalNormDocuments,
    idParam,
  } = props;
  const refDefaultValues = useRef<TemplateDefaultValues>(defaultValues);
  const createDialog = useDialog();
  const redirect = useRedirect();
  const dispatch = useDispatch();
  const { setEditing } = useFormEdit();
  const { registerTableChangedCallback, resync } =
    useInspectionTemplates();

  const allProcesses = useSelector(
    (state: StoreState) => state.projectData.processes,
    checkIndentificableListEquality
  );
  const processes = useMemo(
    () =>
      allProcesses.filter(
        (process: ProcessType) => process._id !== Process.TSK
      ),
    [allProcesses]
  );

  const [title] = React.useState(chooseTitle(window.location.pathname));

  const [isUploading, setUploading] = React.useState<boolean>(false);
  const [isPublishing, setPublishing] = React.useState<boolean>(false);

  const [summary, setSummary] = React.useState<string>(
    defaultValues.summary
  );
  const [process, setProcess] = React.useState<string | null>(
    defaultValues.process
  );
  const [generalNorm, setGeneralNorm] = React.useState<string>(
    defaultValues.generalNorm.description
  );

  const [checklist, setChecklist] = React.useState<FormChecklistItem[]>(
    copyChecklistItemValues(defaultValues.checklist)
  );
  const [canAdd, setCanAdd] = React.useState<boolean>(false);

  const [errors, setErrors] =
    React.useState<CreateInspectionTemplateErrors>({
      summary: undefined,
      process: undefined,
      description: undefined,
    });

  const [saveDraft, setSaveDraft] = React.useState<boolean>(false);
  const [saveAndPublish, setSaveAndPublish] =
    React.useState<boolean>(false);

  const GU = useGraphicUploader(generalNormDocuments, {
    ...createGUSettings(idParam),
    documentBinderFactory: (baseUrl?: string) => {
      if (!baseUrl) throw new Error('From url binder requires URL');
      return new DocumentToTemplateFromUrlBinder(baseUrl);
    },
  });

  useEffect(() => {
    setCanAdd(canAddItem(checklist));
  }, [checklist, setCanAdd]);

  const handleTemplateSummaryChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setSummary(newValue);
      setEditing(true, formName);
      setErrors((prev) => {
        const validation = summaryValidation(newValue);
        if (isEmptyChangeInObject(prev, validation, 'summary')) {
          return prev;
        }
        return {
          ...prev,
          summary: validation,
        };
      });
    },
    [setEditing]
  );

  const handleProcessChange = useCallback(
    (_: React.MouseEvent<HTMLElement>, newProcess: string | null) => {
      if (newProcess !== null) {
        setEditing(true, formName);
        setProcess(newProcess);
        setErrors((prev) => {
          if (isEmptyChangeInObject(prev, undefined, 'process')) {
            return prev;
          }
          return {
            ...prev,
            process: undefined,
          };
        });
      }
    },
    [setEditing]
  );

  const handleGeneralNormDescriptionChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setEditing(true, formName);
      const newValue = event.target.value;
      setGeneralNorm(newValue);
      setErrors((prev) => {
        const validation = descriptionValidation(newValue);
        if (isEmptyChangeInObject(prev, validation, 'description')) {
          return prev;
        }
        return {
          ...prev,
          description: validation,
        };
      });
    },
    [setEditing]
  );

  const handleChecklistItemAdd = useCallback(() => {
    setEditing(true, formName);
    setChecklist((prev) => {
      if (canAddItem(prev)) {
        return [...prev, createChecklistItem()];
      }
      return prev;
    });
  }, [setEditing]);

  const handleChecklistItemRemove = useCallback(
    (itemId: string) => {
      setChecklist((prev) => {
        const index = prev.findIndex(
          (item) => item.uniqueKeyId === itemId
        );

        if (prev.length > 1) {
          setEditing(true, formName);
        }

        return prev.length > 1
          ? [...prev.slice(0, index), ...prev.slice(index + 1)]
          : prev;
      });
      // removing an item causes a bug - new checklist item has default values of the
      // last one on the list. We want to prevent that and this seems as an easiest solution
      // since it doesn't affect already set values and does the job.
      refDefaultValues.current.checklist.splice(-1);
    },
    [setEditing]
  );

  const newChecklistItem = (
    prevChecklistItem: FormChecklistItem,
    key: string,
    value: string,
    validation: ErrorPresentation
  ): FormChecklistItem => {
    return {
      ...prevChecklistItem,
      [key]: value,
      errors: {
        ...prevChecklistItem.errors,
        [key]: validation,
      },
    };
  };

  const handleChecklistItemSummaryChange = useCallback(
    (itemId: string, value: string) => {
      setEditing(true, formName);
      setChecklist((prev) => {
        const index = prev.findIndex(
          (item) => item.uniqueKeyId === itemId
        );
        return [
          ...prev.slice(0, index),
          newChecklistItem(
            prev[index],
            'summary',
            value,
            summaryValidation(value)
          ),
          ...prev.slice(index + 1),
        ];
      });
    },
    [setEditing]
  );

  const handleChecklistItemNormDescriptionChange = useCallback(
    (itemId: string, value: string) => {
      setEditing(true, formName);
      // this causes unnecesary rerenders (since description is only shown in the actual input box, and the change can happen outside react)
      // but we leave it like this RN.
      setChecklist((prev) => {
        const index = prev.findIndex(
          (item) => item.uniqueKeyId === itemId
        );
        return [
          ...prev.slice(0, index),
          newChecklistItem(
            prev[index],
            'description',
            value,
            descriptionValidation(value)
          ),
          ...prev.slice(index + 1),
        ];
      });
    },
    [setEditing]
  );

  const validateForm = useCallback((): boolean => {
    const summaryError = summaryValidation(summary);
    const processError = processValidation(process);
    const descriptionError = descriptionValidation(generalNorm);
    const checklistErrors = checklist.map((checklistItem) => ({
      summary: summaryValidation(checklistItem.summary),
      description: descriptionValidation(checklistItem.description),
    }));

    setErrors((prev) =>
      chooseOptimalErrors(prev, {
        summary: summaryError,
        process: processError,
        description: descriptionError,
      })
    );
    setChecklist(chooseOptimalChecklistErrors(checklist, checklistErrors));

    scrollToFirstError(
      summaryError,
      processError,
      descriptionError,
      checklistErrors
    );

    return (
      !summaryError &&
      !processError &&
      !descriptionError &&
      checklistErrors.every(
        (checklistItem) =>
          !checklistItem.summary && !checklistItem.description
      )
    );
  }, [checklist, summary, generalNorm, process]);

  const onSubmit =
    useCallback((): Promise<null | AxiosResponse<InspectionTemplateInDto>> => {
      if (!validateForm()) {
        return Promise.resolve(null);
      }
      const template: TemplateValidItem = {
        // we passed validation, we can safely cast it to string
        process: process as string,
        summary: summary,
        generalNorm: generalNorm,
      };

      return submitHandler(
        template,
        GU,
        checklist,
        originalDefaultValues._id,
        originalDefaultValues
      );
    }, [
      GU,
      process,
      summary,
      generalNorm,
      checklist,
      originalDefaultValues,
      validateForm,
      submitHandler,
    ]);

  const handleSaveDraft = useCallback(() => {
    return createDialog({
      title: <FormattedMessage id='save_as_draft_question_dialog_title' />,
      description: (
        <span>
          <FormattedMessage id='save_as_draft_question_dialog_helper_text' />
        </span>
      ),
      customControllerLabels: ['general_cancel', 'general_save_as_draft'],
    }).then(() => {
      setUploading(true);
      return onSubmit()
        .then(
          (
            newestTemplate: null | AxiosResponse<InspectionTemplateInDto>
          ) => {
            if (!newestTemplate) {
              setUploading(false);
              return;
            }
            let unsubscribe;
            const tableChangedPromise = new Promise((resolve) => {
              unsubscribe = registerTableChangedCallback(resolve);
            });

            resync();

            return tableChangedPromise.then(() => {
              unsubscribe();
              setEditing(false, formName);
              redirect(
                `/project/${projectId}/inspectionTemplate/${newestTemplate.data?._id}`
              );
            });
          }
        )
        .catch((error) => {
          logError('Saving template as draft failed', error);

          setUploading(false);
          displayGenericErrorToaster(dispatch);
        });
    });
  }, [
    createDialog,
    onSubmit,
    resync,
    registerTableChangedCallback,
    setEditing,
    redirect,
    projectId,
    dispatch,
  ]);

  const handlePublish = useCallback(() => {
    return createDialog({
      title: <FormattedMessage id='publish_question_dialog_title' />,
      description: (
        <span>
          <FormattedMessage id='publish_question_dialog_helper_text' />
        </span>
      ),
      customControllerLabels: ['general_cancel', 'general_publish'],
    }).then(() => {
      setPublishing(true);
      return onSubmit()
        .then((templateResponse) => {
          if (!templateResponse) {
            setPublishing(false);
            return;
          }
          return publishHandler(templateResponse.data._id);
        })
        .then(
          (
            newestTemplate: AxiosResponse<InspectionTemplateInDto> | void
          ) => {
            if (!newestTemplate) {
              setPublishing(false);
              return;
            }
            let unsubscribe;
            const tableChangedPromise = new Promise((resolve) => {
              unsubscribe = registerTableChangedCallback(resolve);
            });

            resync();

            return tableChangedPromise.then(() => {
              unsubscribe();
              setEditing(false, formName);
              redirect(
                `/project/${projectId}/inspectionTemplate/${newestTemplate.data?._id}`
              );
            });
          }
        )
        .catch((error) => {
          logError('Publishing template failed', error);

          setPublishing(false);
          displayGenericErrorToaster(dispatch);
        });
    });
  }, [
    createDialog,
    onSubmit,
    publishHandler,
    resync,
    registerTableChangedCallback,
    setEditing,
    redirect,
    projectId,
    dispatch,
  ]);

  const handleCancel = useCallback(() => {
    createDialog({
      title: <FormattedMessage id='discard_question_dialog_title' />,
      description: (
        <span>
          <FormattedMessage id='discard_question_dialog_helper_text' />
        </span>
      ),
      customControllerLabels: ['general_cancel', 'general_discard'],
    }).then(() => {
      redirect(`/project/${projectId}/inspectionTemplate`);
    });
  }, [createDialog, redirect, projectId]);

  // Below function are created to pass a non changing handlers to children and prevent
  // their rerender because of state changes (summary, process, norm, documents)
  useEffect(() => {
    if (saveDraft) {
      handleSaveDraft();
    }
    setSaveDraft(false);
  }, [saveDraft, handleSaveDraft, setSaveDraft]);

  useEffect(() => {
    if (saveAndPublish) {
      handlePublish();
    }
    setSaveAndPublish(false);
  }, [saveAndPublish, handlePublish, setSaveAndPublish]);

  const onSaveDraftClick = useCallback(() => {
    setSaveDraft(true);
  }, [setSaveDraft]);

  const onPublishClick = useCallback(() => {
    setSaveAndPublish(true);
  }, [setSaveAndPublish]);

  //TODO https://hustro.atlassian.net/browse/PT-2401
  return (
    <ViewWrapper>
      <MemoPresentational
        title={title}
        defaultValues={refDefaultValues.current}
        handleProcessChange={handleProcessChange}
        // currently sending whole checklist item is an overcomplication that causes
        // some rerenders, but I also dont know how documents will behave so I wont be changing it now.
        checklist={checklist}
        errors={errors}
        process={process}
        processes={processes}
        isUploading={isUploading}
        isPublishing={isPublishing}
        handleSaveDraft={onSaveDraftClick}
        handlePublish={onPublishClick}
        handleCancel={handleCancel}
        handleTemplateSummaryChange={handleTemplateSummaryChange}
        handleGeneralNormDescriptionChange={
          handleGeneralNormDescriptionChange
        }
        canAdd={canAdd}
        handleChecklistItemAdd={handleChecklistItemAdd}
        handleChecklistItemRemove={handleChecklistItemRemove}
        handleChecklistItemSummaryChange={handleChecklistItemSummaryChange}
        handleChecklistItemNormDescriptionChange={
          handleChecklistItemNormDescriptionChange
        }
        graphicUploader={GU}
      />
    </ViewWrapper>
  );
}

export const MemoTemplateWizardForm = React.memo(TemplateWizardForm);
