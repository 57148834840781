import { MemoEmptyChart } from 'charts/shared/EmptyChart';
import { Spinner } from 'components/core';
import { ReactElement, useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useChartContext } from './ChartsProvider';
import { ChartDetailsContext } from './chartDetails/ChartDetailsProvider';
import { ChartType } from 'shared/types/analytics';
import { useIntl } from 'react-intl';
import {
  aggregateSeries,
  createOptionsWithAggregatedLabels,
  mutatingTranslateLabels,
} from './helpers';
import useIsMobile from 'hooks/useIsMobile';

const DONUT_CHART_MAX_NUMBER_OF_SERIES = 10;

const style = (height: string | number): object => ({
  width: '100%',
  height: height,
});
const wrapperStyle = {
  display: 'flex',
  flexDirection: 'row' as 'row',
  height: '100%',
};

type ChartRendererProps = {
  height?: string | number;
  id: string;
};

export function ChartRenderer({
  height,
  id,
}: ChartRendererProps): ReactElement {
  const isMobile = useIsMobile();
  const chartContext = useChartContext(id);
  const isDetailedView = useContext(ChartDetailsContext);
  const intl = useIntl();

  if (!chartContext) {
    return <></>;
  }

  if (chartContext.loading || !chartContext.filters) {
    return (
      <Spinner
        height='350px'
        position='static'
        reason='ChartRenderer chartContext.loading || !chartContext.filters'
      />
    );
  }

  const { chartType, state } = chartContext;
  const { options, series } = state;

  if (!state || !chartType) {
    return <></>;
  }

  if (!state.series.length) {
    return <MemoEmptyChart />;
  }

  mutatingTranslateLabels(options, intl);

  const shouldAggregate = chartType === ChartType.donut && !isDetailedView;

  const aggregatedSeries = shouldAggregate
    ? aggregateSeries(series, DONUT_CHART_MAX_NUMBER_OF_SERIES)
    : series;

  const chartOptions = shouldAggregate
    ? createOptionsWithAggregatedLabels(
        options,
        intl,
        DONUT_CHART_MAX_NUMBER_OF_SERIES
      )
    : options;

  const chartHeight: string | number = height || 350;
  const extended = {
    ...chartOptions,
    legend: {
      ...chartOptions.legend,
      position: 'bottom' as 'bottom',
    },
  };

  return (
    <div style={wrapperStyle}>
      <div id='chart' style={style(chartHeight)}>
        <ReactApexChart
          key={`${chartType},${isMobile},${JSON.stringify(
            chartOptions
          )},${JSON.stringify(aggregatedSeries)}`}
          options={isMobile ? extended : chartOptions}
          series={aggregatedSeries}
          type={chartType}
          height='100%'
          width='100%'
        />
      </div>
    </div>
  );
}
