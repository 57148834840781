import {
  AutocompleteRenderInputParams,
  Box,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from '@mui/material';
import { Disabled } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper/types';
import { ErrorPresentation } from 'helpers/validators';
import React, {
  ReactElement,
  Ref,
  forwardRef,
  memo,
  useCallback,
  useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';

type MultiSelectProps = {
  available: LabelledEntity[];
  labelId: string;
  localisedLabel: string;
  required: boolean;
  disabled?: Disabled;
  handleChange: (_: any, value: LabelledEntity[]) => void;
  error?: string;

  value: LabelledEntity[] | null;
  id: string;
  ref: Ref<HTMLDivElement>;

  hidden?: boolean;
  helperText?: string;
  dense: boolean;
  reserveSpaceForHelperText: boolean;
};

type GetRenderInputProps = {
  id: string;
  required: boolean;
  error: ErrorPresentation | string;
  label: string;
  placeholder: string;
  helperText?: string;
};

const MultiSelect = (
  {
    available,
    value,
    handleChange,
    disabled,
    id,
    required,
    localisedLabel,
    error,
    helperText,
    dense,
    reserveSpaceForHelperText,
  }: MultiSelectProps,
  ref: Ref<HTMLDivElement>
): React.ReactElement => {
  const propagateNewValues = useCallback(
    (e: any, newValues: string[]) => {
      const valuesToPropagate: LabelledEntity[] = newValues.map(
        (optionId: string | LabelledEntity) =>
          // TODO zabezpiecz sie przed undefined
          // https://github.com/mui/material-ui/issues/14286
          available.find((item) => item._id === optionId) as LabelledEntity
      );
      handleChange(e, valuesToPropagate);
    },
    [handleChange, available]
  );

  const selectHandleChange = useCallback(
    (event: SelectChangeEvent<any>, _: React.ReactNode) => {
      propagateNewValues(event, event.target.value);
    },
    [propagateNewValues]
  );
  const valueIds = useMemo(
    () => (value?.length ? value.map((v) => v._id) : []),
    [value]
  );
  const getOptionLabelById = useCallback(
    (id) => available.find((item) => item._id === id)?.label,
    [available]
  );
  const handleChipDelete = useCallback(
    (e: any, key: string, existingList: string[]) => {
      const newValue = existingList.filter((item) => item !== key);
      propagateNewValues(e, newValue);
    },
    [propagateNewValues]
  );

  return (
    <FormControl
      margin={dense ? 'dense' : 'none'}
      data-qa={'form-control-multiSelect'}
      fullWidth
      ref={ref}
      disabled={!!disabled}
    >
      <InputLabel id={`${id}-inputLabel`}>{localisedLabel}</InputLabel>
      <Select
        variant='outlined'
        margin={dense ? 'dense' : 'none'}
        error={!!error}
        multiple
        value={valueIds}
        label={localisedLabel}
        onChange={selectHandleChange}
        id={`${id}-select`}
        required={!!required}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((optionId) => (
              <Chip
                key={optionId}
                // https://github.com/mui/material-ui/issues/18658#issuecomment-799453268
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                label={getOptionLabelById(optionId)}
                variant={'outlined'}
                onDelete={(e) => handleChipDelete(e, optionId, valueIds)}
              />
            ))}
          </Box>
        )}
      >
        {available.map((option) => (
          <MenuItem key={option._id} value={option._id}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error={!!error}>
        {helperText || (reserveSpaceForHelperText && ' ')}
      </FormHelperText>
    </FormControl>
  );
};

const ForwardedMultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(
  MultiSelect
) as React.ForwardRefExoticComponent<MultiSelectProps>;

export const MemoMultiSelect = memo(ForwardedMultiSelect);
