import { SORT_TYPE } from 'shared/filter/sort/types';
import { LabelledEntity } from 'shared/types/commonView';
import { isObject } from 'shared/utils/object';
import cmpalphanum from '../cmpalnum';
import { getPropByPath } from '../filter';
import { SortData } from '../types';

//needed to fix funky data coming from columns
const customSortMap: { [key: string]: any } = {
  'extendedData.estimatedCost': {
    type: 'number',
  },
  'extendedData.finalCost': {
    type: 'number',
  },
};

const setCustomSort = (sort: SortData): SortData => {
  const custom = customSortMap[sort.path] || {};
  return { ...sort, ...custom };
};

const emptyASCNumberValue = Number.MAX_SAFE_INTEGER;
const emptyDESCNumberValue = Number.MIN_SAFE_INTEGER;

const customExtractors: {
  [key: string]: (val: any, order: string) => any;
} = {
  'extendedData.estimatedCost': (val, order): number => {
    if (val === undefined) {
      return order === 'asc' ? emptyASCNumberValue : emptyDESCNumberValue;
    }
    if (typeof val === 'number') {
      return val;
    }
    const result = val.reduce(
      (a: number, b: { cost: undefined | string }) => {
        const B = b.cost ? parseFloat(b.cost) : 0;
        return a + B;
      },
      0
    );
    if (typeof result === 'object') {
      return result.cost;
    }
    return result;
  },
  'extendedData.finalCost': (val, order): number => {
    if (val === undefined) {
      return order === 'asc' ? emptyASCNumberValue : emptyDESCNumberValue;
    }
    if (typeof val === 'number') {
      return val;
    }
    //@ts-ignore
    const result = val.reduce((a: number, b: any) => {
      const settledB = b.settled ? parseFloat(b.settled) : 0;
      const outstandingB = b.outstanding ? parseFloat(b.outstanding) : 0;
      const B = settledB + outstandingB;
      return a + B;
    }, 0);
    if (typeof result === 'object') {
      const settled = result.settled ? parseFloat(result.settled) : 0;
      const outstanding = result.outstanding
        ? parseFloat(result.outstanding)
        : 0;
      return settled + outstanding;
    }
    return result;
  },
};

const getLabel = (elem: any): string | undefined => {
  if (elem && typeof elem === 'object') {
    if (elem.label) {
      return elem.label;
    } else {
      return elem[Object.keys(elem)[0]];
    }
  }
  return elem;
};

const valuesByOrder = <T>(a: T, b: T, order: string): [T, T] => {
  return order === 'asc' ? [a, b] : [b, a];
};

const toDateOrNumber = (val: any, order: string): number => {
  if (val === undefined) {
    return order === 'asc' ? emptyASCNumberValue : emptyDESCNumberValue;
  }
  if (typeof val === 'number') {
    return val;
  }
  const toDate = Date.parse(val);
  const isInvalidDate = Number.isNaN(toDate);
  const toNumber = Number.parseFloat(val);
  return isInvalidDate ? toNumber : toDate;
};

const extractValue = (elem: object, sort: SortData): any => {
  const val = getPropByPath(elem, sort.path);
  const custom = customExtractors[sort.path];
  if (custom) {
    const result = custom(val, sort.order);
    return result;
  }
  return val;
};

function sortWithEmptyLast(
  x: string | undefined,
  y: string | undefined,
  order: 'asc' | 'desc'
): number {
  if (!x && !y) {
    return 0;
  }
  // nulls/undefined always last
  if (!x && y) {
    return order === 'asc' ? 1 : -1;
  }
  if (x && !y) {
    return order === 'asc' ? -1 : 1;
  }
  return cmpalphanum(x!.toLocaleLowerCase(), y!.toLocaleLowerCase());
}

function sortWithNaNLast(
  x: number,
  y: number,
  order: 'asc' | 'desc'
): number {
  if (isNaN(x) && isNaN(y)) {
    return 0;
  }
  // NaNs always last
  if (isNaN(x) && !isNaN(y)) {
    return order === 'asc' ? 1 : -1;
  }
  if (!isNaN(x) && isNaN(y)) {
    return order === 'asc' ? -1 : 1;
  }
  return cmpalphanum(`${x}`, `${y}`);
}

const sorters: {
  [key in SORT_TYPE | 'hashMap']: <T extends object>(
    sort: SortData,
    hashMap?: { [key: string]: string } | null
  ) => (a: T, b: T) => number;
} = {
  number:
    (sort) =>
    (a, b): number => {
      const A: number | undefined = extractValue(a, sort);
      const B: number | undefined = extractValue(b, sort);
      const parsedA = toDateOrNumber(A, sort.order);
      const parsedB = toDateOrNumber(B, sort.order);
      const [x, y] = valuesByOrder(parsedA, parsedB, sort.order);
      return sortWithNaNLast(x, y, sort.order);
    },
  string:
    (sort) =>
    (a, b): number => {
      const A: string | undefined = extractValue(a, sort);
      const B: string | undefined = extractValue(b, sort);
      const [x, y] = valuesByOrder(getLabel(A), getLabel(B), sort.order);
      return sortWithEmptyLast(x, y, sort.order);
    },
  hashMap:
    (sort, hashMap) =>
    (a, b): number => {
      if (!hashMap) {
        return -1;
      }
      const A: LabelledEntity | string = getPropByPath(a, sort.path);
      const B: LabelledEntity | string = getPropByPath(b, sort.path);
      const hashedA = hashMap[isObject<LabelledEntity>(A) ? A._id : A];
      const hashedB = hashMap[isObject<LabelledEntity>(B) ? B._id : B];
      const [x, y] = valuesByOrder(hashedA, hashedB, sort.order);
      return sortWithEmptyLast(x, y, sort.order);
    },
};

export const sorting = <T extends object>(
  data: T[],
  sort: SortData,
  hashMap: { [key: string]: string } | null
): T[] => {
  if (!sort) {
    return data;
  }
  const sortParsed = setCustomSort(sort);
  const sortType = hashMap ? 'hashMap' : sortParsed.type;
  const sorter = sorters[sortType];
  if (!sorter) {
    return data;
  }
  return data.sort(sorter(sortParsed, hashMap));
};
