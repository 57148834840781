import React, { ReactElement, useCallback } from 'react';
import { MemoButtonPlusCreate } from 'components/general/ButtonPlusCreate';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useIntl } from 'react-intl';
import { openDocumentationInput } from 'shared/domain/documentation/openDocumentationInput';
import { TableAnchorHeader } from 'components/tableAnchor/types';
import { openDirectoryForm } from 'presentation/directory/openDirectoryInput';

export function DocumentationCreateMenu(props: {
  headerWithCreation: TableAnchorHeader;
}): ReactElement {
  const intl = useIntl();
  const { headerWithCreation } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = useCallback((): void => {
    setAnchorEl(null);
  }, []);

  const createDocumentation = useCallback(() => {
    openDocumentationInput();
    handleClose();
  }, [handleClose]);

  const createDirectory = useCallback(() => {
    openDirectoryForm();
    handleClose();
  }, [handleClose]);

  const documentationCreatePermission = useCallback(() => {
    return { permission: true };
  }, []);

  return (
    <div>
      <MemoButtonPlusCreate
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        creationAction={handleClick}
        canCreate={documentationCreatePermission}
        id={headerWithCreation.id}
        textId={headerWithCreation.textId}
        dataQaId={headerWithCreation.dataQaId}
      />
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <MenuItem onClick={createDocumentation}>
          {intl.formatMessage({ id: 'create_documentation' })}
        </MenuItem>
        <MenuItem onClick={createDirectory}>
          {intl.formatMessage({ id: 'create_directory' })}
        </MenuItem>
      </Menu>
    </div>
  );
}
