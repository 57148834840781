import React from 'react';
import { ButtonContained, ButtonText } from '../../general';
import { FormattedMessage } from 'react-intl';

const styles = {
  feedbackControllers: {
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: 'Archivo',
    marginTop: 80,
  },
  feedbackMark: {
    fill: '#979797',
    fontSize: '2.2rem',
    marginRight: '24px',
  },
};

type FeedbackControllersProps = {
  disabled: boolean;
  onClose: () => void;
  onSubmit: () => void;
};

const FeedbackControllers = ({
  disabled,
  onClose,
  onSubmit,
}: FeedbackControllersProps): JSX.Element => {
  return (
    <div style={styles.feedbackControllers}>
      <ButtonText onClick={onClose} data-qa='feedback-cancel-button'>
        <FormattedMessage id='general_cancel' />
      </ButtonText>
      <ButtonContained
        onClick={onSubmit}
        disabled={disabled}
        data-qa='feedback-send-button'
      >
        <FormattedMessage id='general_send' />
      </ButtonContained>
    </div>
  );
};

export default FeedbackControllers;
