import React, {
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { shouldBeExpanded } from './helpers';
import { MemoPresentational } from './presentational';

import {
  ChecklistItemProps,
  FormChecklistItemWithUploader,
} from './types';
import { createTemplateChecklistItemDocumentsBaseUrl } from 'views/template/wizard/communicator/helpers';
import { useInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { DocumentToChecklistFromUrlBinder } from 'shared/domain/document/documentBinder';
import { useMountedRef } from 'hooks/useMountRef';

function ChecklistItem(props: ChecklistItemProps): ReactElement {
  const mountedRef = useMountedRef();
  const {
    templateId,
    index,
    errors,
    checklistItem,
    defaultValuesObject,
    handleChecklistItemRemove,
    handleChecklistItemSummaryChange,
    handleChecklistItemNormDescriptionChange,
  } = props;

  const { inspectionTemplateDocuments } = useInspectionTemplate();
  const documents =
    checklistItem._id !== undefined
      ? inspectionTemplateDocuments.checklistDocuments[checklistItem._id]
      : [];

  // I wanted to add graphicUploader hook to checklist in TemplateWizard but can't because of hooks rules.
  const graphicUploader = useGraphicUploader(documents, {
    baseUrl: createTemplateChecklistItemDocumentsBaseUrl(
      templateId,
      checklistItem._id
    ),
    dataQaPrefix: `checklist-item-${index}-`,
    documentBinderFactory: (baseUrl?: string) => {
      if (!baseUrl) throw new Error('From url binder requires URL');
      return new DocumentToChecklistFromUrlBinder(baseUrl);
    },
  });
  checklistItem.graphicUploader = graphicUploader;

  const [expanded, setExpanded] = React.useState<boolean>(true);

  const indexPlusOne = index + 1;

  useEffect(
    function openErroredChecklistItem() {
      setExpanded(shouldBeExpanded(errors, expanded));
    },
    [errors, expanded]
  );

  const handleExpanded = useCallback(
    (_, isExpanded: boolean) => {
      setExpanded(shouldBeExpanded(errors, isExpanded));
    },
    [errors]
  );

  const summaryHandler = useCallback(
    (event) => {
      handleChecklistItemSummaryChange(
        checklistItem.uniqueKeyId,
        event.target.value
      );
    },
    [checklistItem.uniqueKeyId, handleChecklistItemSummaryChange]
  );
  const descriptionHandler = useCallback(
    (event) => {
      handleChecklistItemNormDescriptionChange(
        checklistItem.uniqueKeyId,
        event.target.value
      );
    },
    [checklistItem.uniqueKeyId, handleChecklistItemNormDescriptionChange]
  );

  const removeHandler = useCallback(() => {
    handleChecklistItemRemove(checklistItem.uniqueKeyId);
  }, [checklistItem.uniqueKeyId, handleChecklistItemRemove]);

  const [files, setFiles] = useState<any[]>([]);
  useEffect(() => {
    return graphicUploader.filesStore.subscribe(() => {
      if (!mountedRef.current) {
        return;
      }
      setFiles(graphicUploader.filesStore.get());
    });
  }, [graphicUploader.filesStore, mountedRef]);

  return (
    <MemoPresentational
      index={index}
      errors={errors}
      checklistItem={checklistItem as FormChecklistItemWithUploader}
      graphicUploader={graphicUploader}
      files={files}
      defaultValues={defaultValuesObject}
      indexPlusOne={indexPlusOne}
      expanded={expanded}
      handleExpanded={handleExpanded}
      summaryHandler={summaryHandler}
      descriptionHandler={descriptionHandler}
      removeHandler={removeHandler}
    />
  );
}

export const MemoChecklistItem = React.memo(ChecklistItem);
