import { companyEditModelToEditOutDto } from 'shared/domain/company/mapping';
import { CompanyEditModel } from 'shared/domain/company/types/model';
import { EditCompanyUseCase } from 'shared/domain/company/types/useCases';
import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { CompanyHttpEditRequestModel } from '../httpQueue/company/types';

export class CompanyEditor implements EditCompanyUseCase {
  constructor(
    private addRequest: (
      request: CompanyHttpEditRequestModel
    ) => Promise<any>
  ) {}

  async execute(
    companyEditModel: CompanyEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditCompanyUseCase', companyEditModel);

    const companyEditOutDto =
      companyEditModelToEditOutDto(companyEditModel);
    const url = `/project/${companyEditModel.projectId}/company/${companyEditModel._id}`;
    const request: CompanyHttpEditRequestModel = {
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        companyEditOutDto,
        projectId: companyEditModel.projectId,
        uniqueId: uniqueId,
      },
      entityType: HttpRequestModelType.company,
      status: HttpRequestStatus.NEW,
      url,
    };

    await this.addRequest(request);
  }
}

export const companyEditor = new CompanyEditor(addRequest);
