import { DocumentModel } from 'shared/domain/document/documentModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  PostFile,
  uploadFile,
} from 'serviceWorker/repository/file/postFile';
import { isAbortError } from 'serviceWorker/repository/httpRequest/abortRequest';
import {
  broadcastFailUploadedDocument,
  broadcastUploadedDocument,
} from 'serviceWorker/broadcasts/documents';
import {
  AddRemoteIdToDocument,
  addRemoteIdToDocument,
} from './addRemoteIdToDocument';
import { hardRemoveDocument } from './hardRemoveDocument';
import { setDocumentSync, SetDocumentSync } from './setDocumentSync';
import { confirmUpload, failUpload } from './uploadConfirm';

export interface IDocumentUploader {
  POST: (url: string, data: any) => Promise<any>;
  GET: (url: string) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  POST_FILE: PostFile;
  PUT: (url: string, data: any) => Promise<any>;
}

export interface UploadDocument {
  (
    abortController: AbortController,
    uploader: IDocumentUploader,
    document: DocumentModel,
    url: string
  ): Promise<boolean>;
}

// export for testing purposes
export function makeUploadDocument(
  addRemoteIdToDocument: AddRemoteIdToDocument,
  setDocumentSync: SetDocumentSync
): UploadDocument {
  return async function _uploadDocument(
    abortController: AbortController,
    uploader: IDocumentUploader,
    document: DocumentModel,
    url: string
  ): Promise<boolean> {
    if (!document.localData) {
      throw new Error('Cannot access document data to upload.');
    }
    const imageData: Response = await fetch(document.localData).catch(
      async () => {
        await hardRemoveDocument(document.localId);
        throw new Error('Cannot access document data to upload.');
      }
    );
    const imageBlob: Blob = await imageData.blob();
    const fileName = ensureFileName(document.data?.name);
    const file = new File([imageBlob], fileName, {
      type: document.type,
    });

    const signedData = await getSignedUrlForUpload(
      uploader,
      url,
      document
    );
    await addRemoteIdToDocument(document.localId, signedData.document._id);
    try {
      await uploadFile(
        abortController,
        uploader.POST_FILE,
        signedData.signedRequest.fields,
        signedData.signedRequest.url,
        file
      );
      await confirmUpload(uploader, `${url}/${signedData.document._id}`);
      await setDocumentSync(document.localId, SyncStatus.SUCCESS);
      broadcastUploadedDocument({
        localId: document.localId,
        _id: signedData.document._id,
      });
      return true;
    } catch (e) {
      await setDocumentSync(document.localId, SyncStatus.FAIL);
      await failUpload(uploader, `${url}/${signedData.document._id}`);
      if (isAbortError(e)) {
        await hardRemoveDocument(document.localId);
      }
      broadcastFailUploadedDocument(document.localId);
    }

    return false;
  };
}

export const uploadDocument: UploadDocument = makeUploadDocument(
  addRemoteIdToDocument,
  setDocumentSync
);

function getSignedUrlForUpload(
  uploader: IDocumentUploader,
  url: string,
  file: any
): Promise<any> {
  const body = {
    description: file.description,
    fileName: ensureFileName(file.data?.name),
    fileType: file.type,
    title: file.title,
  };

  return uploader.POST(url, body);
}

function ensureFileName(fileName: string | undefined): string {
  return fileName || 'unknown_file';
}
