import { DateTime } from 'luxon';

interface CalculateDelayUseCase {
  execute(
    targetDate: string | undefined,
    finalDate: string | undefined,
    projectTimezone: string
  ): number | null;
}

class DelayCalculatorWithAhead implements CalculateDelayUseCase {
  execute(
    targetDate: string | undefined | null,
    finalDate: string | undefined | null,
    projectTimezone: string
  ): number | null {
    if (!targetDate) {
      return null;
    }
    const now = DateTime.now().setZone(projectTimezone).startOf('day');

    const timezonedTargetDate =
      DateTime.fromISO(targetDate).startOf('day');

    let diff: number;
    if (finalDate) {
      const timezonedFinalDate =
        DateTime.fromISO(finalDate).startOf('day');

      diff = timezonedFinalDate?.diff(timezonedTargetDate, 'days', {
        conversionAccuracy: 'longterm',
      }).days;
    } else {
      diff = now.diff(timezonedTargetDate, 'days', {
        conversionAccuracy: 'longterm',
      }).days;
    }

    return typeof diff === 'number' && !isNaN(diff) ? diff : 0;
  }
}

export const delayCalculatorWithAhead = new DelayCalculatorWithAhead();

class DelayCalculator implements CalculateDelayUseCase {
  execute(
    targetDate: string | undefined | null,
    finalDate: string | undefined | null,
    projectTimezone: string
  ): number | null {
    const diff = delayCalculatorWithAhead.execute(
      targetDate,
      finalDate,
      projectTimezone
    );
    if (diff === null) return null;
    return diff >= 0 ? diff : 0;
  }
}

export const delayCalculator = new DelayCalculator();
