import {
  ErrorPresentation,
  standardDescriptionValidation,
  standardTitleValidation,
  STANDARD_DESCRIPTION_MAXIMUM,
  STANDARD_TITLE_MAXIMUM,
  STANDARD_TITLE_MINIMUM,
} from 'helpers/validators';
import {
  ChecklistItemError,
  CreateInspectionTemplateErrors,
  FormChecklistItem,
} from './types';

const ERROR_VALIDATION_REQUIRED = { helperText: { id: 'required' } };
const ERROR_STANDARD_TITLE_LENGTH = {
  helperText: {
    id: 'validation_string_length_between',
  },
  values: {
    minLength: STANDARD_TITLE_MINIMUM,
    maxLength: STANDARD_TITLE_MAXIMUM,
  },
};
const ERROR_STANDARD_DESCRIPTION_LENGTH = {
  helperText: {
    id: 'validation_string_length_maximum_web',
  },
  values: { maxLength: STANDARD_DESCRIPTION_MAXIMUM },
};

export function summaryValidation(summary: string): ErrorPresentation {
  if (!summary) {
    return ERROR_VALIDATION_REQUIRED;
  } else if (standardTitleValidation(summary)) {
    return ERROR_STANDARD_TITLE_LENGTH;
  }

  return undefined;
}

export function processValidation(
  process: string | null
): ErrorPresentation {
  return !process ? ERROR_VALIDATION_REQUIRED : undefined;
}

export function descriptionValidation(
  description: string
): ErrorPresentation {
  return standardDescriptionValidation(description)
    ? ERROR_STANDARD_DESCRIPTION_LENGTH
    : undefined;
}

function identicalErrors(
  prev: CreateInspectionTemplateErrors,
  curr: CreateInspectionTemplateErrors
): boolean {
  return (
    curr.description?.helperText.id === prev.description?.helperText.id &&
    curr.summary?.helperText.id === prev.summary?.helperText.id &&
    curr.process?.helperText.id === prev.process?.helperText.id
  );
}

export function chooseOptimalErrors(
  prev: CreateInspectionTemplateErrors,
  curr: CreateInspectionTemplateErrors
): CreateInspectionTemplateErrors {
  return identicalErrors(prev, curr) ? prev : curr;
}

function identicalChecklistError(
  prev: ChecklistItemError,
  curr: ChecklistItemError
): boolean {
  return (
    curr.description?.helperText.id === prev.description?.helperText.id &&
    curr.summary?.helperText.id === prev.summary?.helperText.id
  );
}

export function chooseOptimalChecklistErrors(
  checklist: FormChecklistItem[],
  checklistErrors: ChecklistItemError[]
): FormChecklistItem[] {
  const indexes = checklist.reduce<number[]>(
    (indexesWithChange, item: FormChecklistItem, index: number) => {
      if (!identicalChecklistError(item.errors, checklistErrors[index])) {
        indexesWithChange.push(index);
      }
      return indexesWithChange;
    },
    []
  );

  if (!indexes.length) {
    return checklist;
  }

  return checklist.map((checklistItem, index) => {
    if (indexes.includes(index)) {
      return {
        ...checklistItem,
        errors: checklistErrors[index],
      };
    }
    return checklistItem;
  });
}
