import { useIssuesOnMap } from 'components/dataProviders/withIssuesOnMap';
import { useLevels } from 'components/dataProviders/withLevels';
import { findDocumentationOnLevelsAndSites } from 'shared/domain/documentation/findDocumentationOnLevels';
import { ReactElement, useMemo, useEffect } from 'react';
import { IssueCards } from './IssueCards';
import { Slide } from '@mui/material';
import { DocumentationBaseIds } from 'shared/types/documentation';

export function DocumentationIssueCards({
  file,
  visible,
}: {
  file: DocumentationBaseIds;
  visible: boolean;
}): ReactElement | null {
  const { issuesStore, setLevels } = useIssuesOnMap();

  const { loading: loadingLevels, levels } = useLevels();
  const documentationId = file.documentationId;
  const versionId = file.versionId;

  const { levelsValue } = useMemo(
    () =>
      findDocumentationOnLevelsAndSites(
        levels.items,
        [],
        documentationId,
        versionId
      ),
    [levels.items, documentationId, versionId]
  );

  useEffect(() => {
    setLevels(JSON.stringify(levelsValue));
  }, [file, levelsValue, setLevels]);

  const isUsedAsLevel = levelsValue.length > 0;
  const isVisible = !loadingLevels && isUsedAsLevel && visible;

  return (
    <Slide direction='up' in={isVisible} mountOnEnter unmountOnExit>
      <IssueCards issuesStore={issuesStore} />
    </Slide>
  );
}
