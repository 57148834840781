import { FileReadSuccess } from 'components/common/graphicUploader/types';
import {
  DocumentationAuthor,
  DocumentationCreateModel,
} from 'shared/domain/documentation/documentationModel';
import { getConvertableStatus } from 'shared/domain/documentation/getConvertableStatus';
import { UploadStatus } from 'shared/types/uploadStatus';
import { nowISO } from 'shared/utils/date/dates';

export function fileToCreateDocumentationModel(
  file: FileReadSuccess,
  author: DocumentationAuthor,
  currentDirectory: string
): DocumentationCreateModel {
  const extension = file.data?.extension || '';
  const now = nowISO();

  return {
    name: file.title,
    deleted: 0,
    parentId: currentDirectory,
    createdAt: now,
    createdBy: author,
    modifiedAt: now,
    modifiedBy: author,
    versions: [
      {
        extension: extension,
        remoteVersionId: '',
        uploadStatus: UploadStatus.unknown,
        localData: file.downloadSrc,
        createdAt: now,
        createdBy: author,
        modifiedAt: now,
        modifiedBy: author,
        type: file.type,
        imageConvertStatus: getConvertableStatus(extension),
      },
    ],
  };
}
