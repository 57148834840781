import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debounceNext } from 'serviceWorker/helpers/debounce';

const change = debounceNext((): void => {
  self.dispatchEvent(new Event(RepositoryMessagesTypes.languageChange));
  self.dispatchEvent(
    new Event(RepositoryMessagesTypes.clearSelectedProjectData)
  );
}, 1000);

export default { change };
