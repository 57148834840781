import { PaginationData } from './types';

export const paginate = (
  data: any[],
  pagination: PaginationData
): any[] => {
  if (!pagination || pagination.page === undefined || !pagination.size) {
    return data;
  }
  const start = pagination.page * pagination.size;
  const end = start + pagination.size;

  return data.slice(start, end);
};
