import { OrganizationEntity } from '../types/entity';
import {
  OrganizationCreateModel,
  OrganizationEditModel,
  OrganizationModel,
} from '../types/model';
import {
  OrganizationCreateOnView,
  OrganizationEditOnView,
} from '../types/view';

export function organizationCreateOnViewToOrganizationCreateModel(
  organization: OrganizationCreateOnView
): OrganizationCreateModel {
  return {
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
  };
}

export function organizationEditOnViewToOrganizationEditModel(
  organization: OrganizationEditOnView
): OrganizationEditModel {
  return {
    _id: organization._id,
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
  };
}

export function organizationEntityToModel(
  organization: OrganizationEntity
): OrganizationModel {
  return {
    _id: organization._id,
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
    logoUsedInPdfHeaders: organization.logoUsedInPdfHeaders,
    createdAt: organization.createdAt,
    modifiedAt: organization.modifiedAt,
  };
}
