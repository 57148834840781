import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { useStyles } from './styles';
import { ProtocolHeaderProps } from './types';

export function ProtocolHeader({
  protocolIssues,
  classes,
}: ProtocolHeaderProps): ReactElement {
  const cs = useStyles({ classes });
  const intl = useIntl();

  return (
    <div className={cs.root}>
      <h2 style={{ marginTop: '16px' }}>
        {intl.formatMessage({
          id: 'inspection_template_header_checklist',
        })}
      </h2>
      <div className={cs.protocolIssuesCounter}>
        {protocolIssues > 99 ? '99+' : protocolIssues}
      </div>
    </div>
  );
}
