import { flushTableWithService } from 'serviceWorker/db/flush';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { LogLevel } from 'shared/types/logger';
import { INVALID_SYNCKEY } from 'shared/contants';
import { invalidSyncKeyErrorMsg, logWithRethrow } from '../../db/helpers';
import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from '../../helpers/httpRequests';
import { HeadersData } from '../factories/types';
import { pull } from '../pull';
import { IssuesInDto } from './types';

export const fetchIssues = (
  data: HeadersData,
  url: string,
  controller: AbortController
): Promise<IssuesInDto> | undefined => {
  const { signal } = controller;
  if (shouldSkip(data, url)) {
    return;
  }

  if (signal.aborted) {
    throw new Error('Abort signal');
  }

  const requests = [];

  const builtUrl = `${data.api}/v2/project/${data.projectId}${url}`;
  requests.push(
    // react 18 types
    // @ts-ignore
    pull(builtUrl, {
      ...defaultConfig,
      method: 'GET',
      headers: generateHeaders(data),
      signal,
    }).then(<(response: Response) => Promise<IssuesInDto>>parseResponse)
  );

  return Promise.all(requests).then((responses: IssuesInDto[]) => {
    const result: IssuesInDto = {
      issues: [],
      totalCount: 0,
      hasMore: true,
      syncKey: '',
    };

    responses.forEach((response: IssuesInDto) => {
      if (!response.hasMore) {
        result.hasMore = false;
      }
      result.issues.push(...response.issues);
      result.totalCount = response.totalCount;
      result.syncKey = response.syncKey;
    });

    return result;
  });
};

export const fetchUpdatedIssues = (
  data: HeadersData,
  syncKey: string,
  controller: AbortController
): Promise<any> | undefined => {
  const { signal } = controller;
  if (!data.api || !data.projectId) {
    //not enough data, skip.
    return;
  }
  const builtUrl = `${data.api}/v2/project/${
    data.projectId
  }/issue?syncKey=${encodeURIComponent(syncKey)}`;

  return pull(builtUrl, {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
    signal,
  })
    .then(async (response: Response) => {
      if (response.status === INVALID_SYNCKEY) {
        await flushTableWithService('issues');
        self.dispatchEvent(new Event(RepositoryMessagesTypes.configSet));
        const msg = invalidSyncKeyErrorMsg('issues');
        return logWithRethrow({
          msg,
          errorObj: null,
          logLevel: LogLevel.INFO,
          additionalInfo: null,
        });
      }
      return response;
    })
    .then(parseResponse);
};

function shouldSkip(data: HeadersData, url: string): boolean {
  return !data.api || !data.projectId || !url;
}
