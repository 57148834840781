import { DocumentEntity } from 'serviceWorker/repository/document/entity';

export function createUrlFromEventDocument(
  document: DocumentEntity
): string {
  return `/event/${document.eventId}`;
}

export function createUrlFromIssueDocument(
  document: DocumentEntity
): string {
  const url = `/issue/${document.issueId}`;
  if (document.eventId) {
    return url + createUrlFromEventDocument(document);
  }

  return url;
}
