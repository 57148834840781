import { AddDrawingUseCase } from 'shared/domain/document/addDrawing';
import { DrawingModel } from 'shared/domain/document/drawingModel';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  HttpRequestModelType,
  HttpRequestStatus,
  __HttpRequestModel__,
} from 'shared/domain/httpRequest/httpRequestModel';
import { broadcastCreatedDrawing } from 'serviceWorker/broadcasts/documents';
import { updateDrawing } from 'serviceWorker/db/documents';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { get as getCurrentProject } from 'serviceWorker/db/selectedProject';
import { Identificable } from 'shared/types/commonView';
import { UploadStatus } from 'shared/types/uploadStatus';
import { debugLog } from 'shared/logger/debugLog';
import { nowISO } from 'shared/utils/date/dates';

function drawingToDocumentChanges(drawing: DrawingModel): any {
  if (drawing.clear && !drawing.data) {
    return {
      'data.isDrawn': false,
      'data.drawingSrc': '',
      'data.mergedSrc': '',
      modifiedAt: nowISO(),
      drawingSyncStatus: SyncStatus.PENDING,
    };
  } else {
    return {
      'data.isDrawn': true,
      'data.drawingSrc': drawing.drawingImageUrl,
      'data.mergedSrc': drawing.mergedImageUrl,
      'data.thumbnailMergedSrc': drawing.thumbnailMergedImageUrl,
      'data.type': drawing.blob.type,
      'data.drawnUploadStatus': UploadStatus.unknown,
      modifiedAt: nowISO(),
      drawingSyncStatus: SyncStatus.PENDING,
    };
  }
}

export class DrawingSaver implements AddDrawingUseCase {
  constructor(
    private updateDrawing: (
      localId: number,
      drawing: DrawingModel
    ) => Promise<void>,
    private addRequest: (request: __HttpRequestModel__) => Promise<any>,
    private getCurrentProject: () => Promise<Identificable | undefined>,
    private broadcastDrawingSaved: (document: { localId: number }) => void
  ) {}
  async execute(drawing: DrawingModel, localId: number): Promise<void> {
    debugLog('AddDrawingUseCase', drawing, localId);
    const changes = drawingToDocumentChanges(drawing);
    await this.updateDrawing(localId, changes);
    this.broadcastDrawingSaved({ localId });

    const currentProject = await this.getCurrentProject();
    await this.addRequest({
      createdAt: Date.now(),
      entityType: HttpRequestModelType.drawing,
      data: { localId, projectId: currentProject?._id, changes },
      method: 'POST',
      status: HttpRequestStatus.NEW,
    });
  }
}

const drawingSaver = new DrawingSaver(
  updateDrawing,
  addRequest,
  getCurrentProject,
  broadcastCreatedDrawing
);

export default drawingSaver;
