import { useCallback, useMemo, useState } from 'react';
import { HashMap } from 'shared/types/commonView';
import { ColumnShape } from 'react-base-table';
import { TableWidthStorageKey } from 'components/table/types';

/* Used "any" because currently resources have nothing in common.
Can't use T extends Identificable because e.g. DocumentationOnView may have no remote _id yet
Can't use T extends Creatable because UserDTO does not contain createdAt & createdBy */
type HandleResizeEndParams<T> = {
  column: ColumnShape<T>;
  width: number;
};

export type SaveColumnWidth<T> = (
  params: HandleResizeEndParams<T>
) => void;

export function useSavedTableColumnWidths<T>(
  tableKey: TableWidthStorageKey
): {
  columnWidths: HashMap<number | undefined>;
  saveColumnWidth: SaveColumnWidth<T>;
} {
  const [columnWidths] = useState<HashMap<number | undefined>>(
    safeGetColumnWidths(tableKey)
  );

  const _saveColumnWidth: SaveColumnWidth<T> = useCallback(
    (params: HandleResizeEndParams<T>): void => {
      if (!tableKey) {
        throw new Error(
          'No table key when attempting to save column width'
        );
      }
      if (!params.column.key) {
        throw new Error(
          'No column key when attempting to save column width'
        );
      }
      // @ts-ignore TODO MUI5
      setInLocalStorage(tableKey, params.column.key, params.width);
    },
    [tableKey]
  );

  return useMemo(
    () => ({
      columnWidths,
      saveColumnWidth: _saveColumnWidth,
    }),
    [columnWidths, _saveColumnWidth]
  );
}

function safeGetColumnWidths(
  tableKey: string
): HashMap<number | undefined> {
  const savedData = localStorage.getItem(tableKey);
  if (!savedData) {
    return {};
  }

  try {
    const parsed = JSON.parse(savedData);
    return typeof parsed === 'object' && parsed !== null ? parsed : {};
  } catch (e) {
    return {};
  }
}

function setInLocalStorage(
  tableKey: string,
  id: string | number,
  width: number
): void {
  const state = safeGetColumnWidths(tableKey);
  state[`${id}`] = width;
  localStorage.setItem(tableKey, JSON.stringify(state));
}
