import {
  useDocumentationController,
  withDocumentationController,
} from 'components/dataProviders/withDocumentationController';
import {
  MouseEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  getCanBeUsedAsMap,
  useDirectoriesState,
  useDocumentationsState,
} from '../model';
import { useCurrentDirectoryContext } from 'components/dataProviders/withCurrentDirectory';
import { DocumentationRowIcon } from '../DocumentationIcon';
import { ExpandMoreOutlined } from '@mui/icons-material';
import { useSelectedStoreContext } from 'components/common/withSelectedStore';
import { DocumentationBrowserSkeleton } from './DocumentationBrowserSkeleton';
import ForbiddenTooltip from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { ButtonIcon } from 'components/general';

function _DocumentationBrowser({
  submit,
}: {
  submit: () => void;
}): ReactElement {
  const lastClickRef = useRef(0);
  const { selectedStore } = useSelectedStoreContext<string | undefined>();
  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const {
    currentDirDocumentationsStore,
    isLoadingCurrentDirDocumentationsStore,
    currentDirectoryFoldersStore,
    isLoadingCurrentDirFoldersStore,
  } = useDocumentationController();

  const { directories, isLoadingDirectories } = useDirectoriesState(
    currentDirectoryFoldersStore,
    isLoadingCurrentDirFoldersStore
  );

  const { documentations, isLoadingDocumentations } =
    useDocumentationsState(
      currentDirDocumentationsStore,
      isLoadingCurrentDirDocumentationsStore
    );

  const [isLoading, setIsLoading] = useState(
    isLoadingDirectories || isLoadingDocumentations
  );

  useEffect(() => {
    setIsLoading(isLoadingDirectories || isLoadingDocumentations);
  }, [isLoadingDirectories, isLoadingDocumentations]);

  const doubleClick = useCallback(
    (id: string) => {
      if (id.includes('DIRECTORY')) {
        const localId = id.split('_')[0];
        const directory = directories.find(
          (d) => `${d.localId}` === localId
        );
        currentDirectoryStore.set(directory?._id!);
        return;
      } else {
        submit();
      }
    },
    [currentDirectoryStore, directories, submit]
  );

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const current = selectedStore.get();
      const isEqual = current === event.currentTarget.id;
      const isDoubleClick = Date.now() - lastClickRef.current < 500;
      lastClickRef.current = Date.now();
      if (isEqual && isDoubleClick) {
        doubleClick(current);
        return;
      }
      selectedStore.set(event.currentTarget.id);
    },
    [selectedStore, doubleClick]
  );

  if (isLoading) {
    return <DocumentationBrowserSkeleton />;
  }

  return (
    <div
      style={{
        height: '240px',
        overflowY: 'auto',
      }}
    >
      {directories.map((d) => {
        const id = `${d.localId}_DIRECTORY`;
        return (
          <DocumentationSelectableListElement
            key={id}
            id={id}
            onClick={onClick}
            name={d.name}
            type='DIRECTORY'
            openDirectory={doubleClick}
          />
        );
      })}
      {documentations.map((d) => {
        const id = `${d.localId}_FILE`;
        return (
          <DocumentationSelectableListElement
            key={id}
            id={id}
            onClick={onClick}
            name={d.name}
            disabled={
              !getCanBeUsedAsMap(
                d.versions[0].extension,
                d.versions[0].imageConvertStatus
              )
            }
          />
        );
      })}
    </div>
  );
}

export const DocumentationBrowser = withDocumentationController(
  _DocumentationBrowser
);

function DocumentationSelectableListElement({
  onClick,
  id,
  type,
  name,
  openDirectory,
  disabled,
}: {
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  id: string;
  type?: 'DIRECTORY';
  name: string;
  openDirectory?: (id: string) => void;
  disabled?: boolean;
}): ReactElement {
  const { selectedStore } = useSelectedStoreContext();
  const [selected, setSelected] = useState(selectedStore.get() === id);

  useEffect(() => {
    setSelected(selectedStore.get() === id);
    return selectedStore.subscribe(() => {
      setSelected(selectedStore.get() === id);
    });
  }, [selectedStore, id]);

  return (
    <SelectableListElement
      id={id}
      onClick={onClick}
      selected={selected}
      disabled={disabled}
      disabledReason='documentation_file_not_supported_as_map'
    >
      <DocumentationRowIcon type={type} disabled={disabled} />
      <div
        style={{
          flexGrow: 1,
          fontSize: '0.875rem',
          fontFamily: 'Archivo',
          userSelect: 'none',
        }}
        key={id}
      >
        {name}
      </div>
      <OpenDirectoryButton
        onClick={openDirectory ? () => openDirectory(id) : undefined}
        selected={selected}
        type={type}
      />
    </SelectableListElement>
  );
}

function SelectableListElement({
  children,
  selected,
  onClick,
  id,
  disabled,
  disabledReason,
}: PropsWithChildren<{
  selected?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  id: string;
  disabled?: boolean;
  disabledReason?: string;
}>): ReactElement {
  return (
    <ForbiddenTooltip
      visibleWhen={Boolean(disabled)}
      reason={disabledReason}
    >
      <div
        id={`${id}`}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '0.5rem',
          height: '2.5rem',
          paddingLeft: '0.75rem',
          cursor: disabled ? 'auto' : 'pointer',
          backgroundColor: selected ? '#FBCC30' : '#FFFFFF',
          color: disabled ? '#E0E0E0' : '#000',
        }}
        onClick={disabled ? undefined : onClick}
      >
        {children}
      </div>
    </ForbiddenTooltip>
  );
}

function OpenDirectoryButton({
  selected,
  type,
  onClick,
}: {
  selected: boolean;
  type?: 'DIRECTORY';
  onClick?: () => void;
}): ReactElement | null {
  return selected && type ? (
    <ButtonIcon onClick={onClick} style={{ padding: '8px' }}>
      <ExpandMoreOutlined style={{ transform: 'rotate(-90deg)' }} />
    </ButtonIcon>
  ) : null;
}
