import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import queryString from 'query-string';
import cn from 'classnames';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles';
import { PaginationContextType, Props } from './types';
import { end, start } from './helpers';
import PickPage from './pickPage';
import useIsMobile from 'hooks/useIsMobile';
import { useSyncIssues } from 'hooks/useSyncIssues';

export const PaginationContext =
  React.createContext<PaginationContextType>({
    items: 0,
    setItems: () => {},
    page: 0,
    setPage: () => {},
    rowsPerPage: 50,
    isLoading: false,
    setLoading: () => {},
    displayStart: 0,
  });

export const Pagination: React.FC<Props> = ({
  page,
  items,
  rowsPerPage,
  setPage,
  isLoading,
  displayStart,
}) => {
  const cs = styles();
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const displayEnd = end({ page, rowsPerPage, items });
  const displayNext = (page + 1) * rowsPerPage < items;
  const displayPrev = page !== 0;
  const toggleOpen = (): void => setOpen(!open);

  if (items === 0) {
    return null;
  }

  return (
    <div className={cs.container}>
      {!isMobile && (
        <>
          {open && (
            <PickPage
              page={page}
              setPage={setPage}
              close={toggleOpen}
              items={items}
              rowsPerPage={rowsPerPage}
            />
          )}
          {isLoading && !open && <CircularProgress size={14} />}
          <span
            className={cs.range}
            onClick={toggleOpen}
            data-qa='pagination-button-current-range'
          >
            {displayStart}-{displayEnd} / {items}
          </span>
        </>
      )}
      <ChevronLeftIcon
        data-qa='pagination-button-previous-page'
        className={cn(cs.arrow, cs.arrowLeft, {
          [cs.active]: displayPrev,
        })}
        onClick={(): false | void => displayPrev && setPage(page - 1)}
      />
      <ChevronRightIcon
        data-qa='pagination-button-next-page'
        className={cn(cs.arrow, { [cs.active]: displayNext })}
        onClick={(): false | void => displayNext && setPage(page + 1)}
      />
    </div>
  );
};

const SESSION_STORAGE_PAGE_KEY = 'tablePage';

const setPageInStorage = (value: number): void => {
  sessionStorage.setItem(SESSION_STORAGE_PAGE_KEY, `${value}`);
};

const WithPagination: React.FC<{ children: JSX.Element }> = ({
  children,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchValues = queryString.parse(search);
  const initPage = (searchValues.page as string) || '0';
  const [isLoading, setLoading] = useState(false);
  const [items, setItems] = useState(0);
  const [page, setPage] = useState(parseInt(initPage));
  const rowsPerPage = 50;
  const displayStart = start({ page, rowsPerPage });
  const { syncIssues } = useSyncIssues();

  useEffect(() => {
    const savedPage = sessionStorage.getItem(SESSION_STORAGE_PAGE_KEY);
    const parsedSavedPage = parseFloat(savedPage || '0');
    const urlPage = parseInt((searchValues?.page as string) ?? '0');
    if (savedPage !== null && !isNaN(parsedSavedPage) && !(urlPage > 0)) {
      setPage(parsedSavedPage);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setPageInStorage(page);
  }, [page]);

  useEffect(() => {
    history.push({
      search: `?page=${page}`,
    });
  }, [page, history]);

  useEffect(() => {
    setPage((page) => {
      const hasItems = items !== 0;
      const isOverLimit = page * rowsPerPage > items;
      if (hasItems && isOverLimit) {
        const lastPage = Math.ceil(items / rowsPerPage - 1);
        setPageInStorage(lastPage);
        return lastPage;
      }
      setPageInStorage(page);
      return page;
    });
  }, [items]);

  const pageSetter = useCallback(
    (value: number) => {
      setPageInStorage(value);
      setPage(value);
      syncIssues();
    },
    [syncIssues]
  );

  const ctx: PaginationContextType = {
    page,
    setPage: pageSetter,
    rowsPerPage,
    items,
    setItems,
    isLoading,
    setLoading,
    displayStart,
  };

  return (
    <PaginationContext.Provider value={ctx}>
      {children}
    </PaginationContext.Provider>
  );
};

export function usePagination(): PaginationContextType {
  const context = React.useContext(PaginationContext);
  if (context === undefined) {
    throw new Error(
      'usePagination must be used within a PaginationContextProvider'
    );
  }
  return context;
}

export default WithPagination;
