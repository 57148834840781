import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debuglog } from 'util';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGet } from './makeUseGet';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';

export const useGetSearchedDocumentations = makeUseGet<
  { items: DocumentationModel[] },
  string
>({
  listenMessageChannel: ChannelNames.documentationChannel,
  isMatchingMessage,
  postMessage: broadcastGetSearchedDocumentations,
  entityName: 'documentations',
});

function broadcastGetSearchedDocumentations(searchPhrase: string): void {
  debuglog('send broadcastGetSearchedDocumentations');
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  broadcast.postMessage({
    type: DomainMessagesTypes.getSearchedDocumentations,
    data: {
      searchPhrase: searchPhrase,
    },
    uniqueId: searchPhrase,
  });
  broadcast.close();
}

function isMatchingMessage(searchPhrase: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.documentations &&
    Boolean(event.data) &&
    event.uniqueId === searchPhrase
  );
}
