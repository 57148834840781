import { ChannelNames } from 'shared/domain/channelNames';
import { get as dbGetIssueFormData } from 'serviceWorker/db/issueForm';
import { getOne as dbGetOneProject } from 'serviceWorker/db/projects';

import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { get as getSelectedProject } from 'serviceWorker/db/selectedProject';
import { IssueFormEntity } from 'serviceWorker/repository/issueForm/issueForm';
import { retry } from 'shared/utils/retry';

export async function getIssueForm(): Promise<IssueFormEntity[]> {
  const selectedProject = await retry(() => getSelectedProject(), 5);
  if (!selectedProject || !selectedProject._id) {
    throw new Error('Project is not selected.');
  }
  const [issueForm, project] = await Promise.all([
    dbGetIssueFormData(),
    retry(
      () =>
        dbGetOneProject(selectedProject._id).then((project) => {
          if (!project) {
            throw new Error('Recieved empty project data');
          }
          return project;
        }),
      10
    ),
  ]);

  if (!project) {
    throw new Error('Cannot get project data in 10 reties.');
  }

  if (!issueForm.length) {
    await waitForAllIssueFormData();
    return dbGetIssueFormData();
  }

  return issueForm;
}

export async function waitForAllIssueFormData(): Promise<void> {
  return new Promise((resolve, reject) => {
    const broadcast = new BroadcastChannel(ChannelNames.issueFormChannel);
    let timeout = setTimeout(() => reject(new Error('Timeout')), 30000);

    broadcast.onmessage = (event: Message): void => {
      if (event.type === DomainMessagesTypes.allIssueForm) {
        clearTimeout(timeout);
        broadcast.close();
        resolve();
      }
    };

    const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);
    apiBroadcast.postMessage({
      service: Services.ISSUE_FORMS,
      method: ServiceMethods.SYNC,
    });
    apiBroadcast.close();
  });
}
