import * as Sentry from '@sentry/react';
import { store } from 'setup/store';
import { StoreState } from 'setup/types/core';
import { getSentryConfig } from 'shared/logger/config/getSentryConfig';
import { LogData } from 'shared/types/logger';
import { version } from '../../../package.json';

export async function initSentryInApp(): Promise<void> {
  if (!process.env.REACT_APP_DISABLE_SENTRY) {
    Sentry.init({
      ...getSentryConfig('React', version),
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.extraErrorDataIntegration(),
      ],

      beforeSend: (event) => {
        const state: StoreState = store.getState();
        Sentry.withScope((scope) => {
          scope.setUser({
            email:
              state.user?.data?.email ||
              localStorage.getItem('user_email') ||
              'unknown',
            id:
              state.user?.data?._id ||
              localStorage.getItem('user_id') ||
              'unknown',
          });
        });

        return event;
      },

      tracesSampleRate: 1.0,
      ignoreErrors: ['Login required', 'Request aborted'],
    });
  }
}

// almost same as swSentryLog but uses sentry/react
export function appSentryLog(data: LogData): void {
  if (!process.env.REACT_APP_DISABLE_SENTRY) {
    Sentry.withScope((scope) => {
      scope.setLevel(data.level);
      scope.setUser({ email: data.user_email, id: data.user_id });
    });

    Sentry.captureException(data);
  }
}
