import React, { PropsWithChildren } from 'react';
import NumberFormat, {
  NumberFormatProps,
  NumberFormatValues,
} from 'react-number-format';

const MAX_NUMBER_OF_DIGITS = 15;

const checkNumberOfDigits = (
  numberFormatValues: NumberFormatValues
): boolean => {
  const { value } = numberFormatValues;
  const numberOfDigits: number = value.replace('.', '').length;

  return numberOfDigits <= MAX_NUMBER_OF_DIGITS;
};

export const ThousandSeparatorFormat = (
  props: PropsWithChildren<NumberFormatProps>
): React.ReactElement => {
  const { children, thousandSeparator, ...otherProps } = props;

  return (
    // @ts-ignore TODO MUI5
    <NumberFormat
      isAllowed={checkNumberOfDigits}
      allowNegative={false}
      thousandSeparator={thousandSeparator}
      decimalSeparator=','
      fixedDecimalScale
      {...otherProps}
    >
      {children}
    </NumberFormat>
  );
};
