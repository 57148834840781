import React, { CSSProperties, HTMLAttributes } from 'react';

const makeStyles = (maxRows: number): CSSProperties => {
  return {
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: maxRows,
    overflow: 'hidden',
  };
};

type ContentClampProps = HTMLAttributes<HTMLDivElement> & {
  maxRows: number;
};

export const ContentClamp = ({
  maxRows,
  ...props
}: ContentClampProps): JSX.Element => (
  <div {...props} style={makeStyles(maxRows)} />
);
