import { makeStyles } from '@mui/styles';

export const useStylesSegments = makeStyles({
  segmentsSectionHeader: {
    gridColumn: '1 / -1',
  },
  segmentsContainer: {
    gridColumn: '1 / -1',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0 8px',
  },
  segmentRoot: {
    display: 'flex',
    minWidth: '216px',
    maxWidth: '280px',
    flexWrap: 'wrap',
    '& .inputsRow': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      padding: '0 16px',
    },
  },
  segmentIcon: {
    width: '18px',
    height: '18px',
    marginRight: '16px',
    flexShrink: 0,
  },
  textField: {
    height: '48px',
    maxWidth: '90px',
    fill: '#757575',
    '& input': {
      height: '48px',
      boxSizing: 'border-box',
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#8A8A8A',
    },

    '& .MuiInputLabel-outlined:not(.MuiInputLabel-shrink)': {
      transform: 'translate(14px, 16px) scale(1)',
    },
  },
  hyphen: {
    borderTop: '1px solid #8A8A8A',
    width: '11px',
    flexShrink: 0,
    margin: '0 6px',
  },
  helperText: {
    margin: '4px 0 16px 0',
    minHeight: '1.66em',
    width: '100%',
    padding: '0 16px 0 50px',
  },
});
