import { makeStyles } from '@mui/styles';

const styles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  arrow: {
    cursor: 'pointer',
    opacity: 0.5,
  },
  arrowLeft: {
    paddingLeft: '0.75rem',
  },
  active: {
    opacity: 1,
  },
  range: {
    minWidth: '140px',
    padding: '0 0 0 10px',
    cursor: 'pointer',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
  },
  editPage: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '100%',
    height: '100%',
    background: '#fff',
    zIndex: 100,
    padding: '0 0 0 20px',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
  },
});

export default styles;
