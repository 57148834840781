import React, { useEffect } from 'react';
import { CreateIssueContext } from './createIssueContext/CreateIssueContext';
import { HeaderContext } from 'components/core/Layout/HeaderContext';
import CreatePaginationContext from 'components/common/withPagination/withPagination';
import { WithIssues } from 'components/dataProviders/withIssues';
import Presentational from './presentational';
import FilterSidebar from 'components/core/Layout/filters/FilterSidebar';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withIssueForm } from 'components/common/withIssueForm';
import { withProcessesData } from 'components/common/withProcessesData';
import { withIssueFilters } from 'components/dataProviders/withIssueFilters';
import { WithInspections } from 'components/dataProviders/withInspections';
import { WithInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';
import { WithCreateReport } from 'components/dataCreationForms/withCreateReport';
import { withCorrectiveActionTypes } from 'components/dataProviders/withCorrectiveActionTypes';
import { withEnvironmentalAspects } from 'components/dataProviders/withEnvironmentalAspects';
import { withHazardCategories } from 'components/dataProviders/withHazardCategories';
import { withWorktypes } from 'components/dataProviders/withWorktypes';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withLevels } from 'components/dataProviders/withLevels';
import { withSites } from 'components/dataProviders/withSites';
import { useSelector } from 'react-redux';
import { StoreState } from 'setup/types/core';
import { WithFieldVisibility } from 'components/dataProviders/withVisibleFields';

const IssueTable: React.FC = () => {
  const { setRouteFor } = React.useContext(HeaderContext);
  const state = useSelector((state: StoreState) => state);
  const { sidebarOpen } = state.ui;

  useEffect(() => {
    setRouteFor('issueTable');
    // run once
    // eslint-disable-next-line
  }, []);

  return (
    <CreateIssueContext>
      <CreatePaginationContext>
        <WithFieldVisibility>
          <WithIssues>
            <WithCreateReport>
              <Presentational />
              <WithInspectionTemplates>
                <WithInspections>
                  <FilterSidebar sidebarOpen={sidebarOpen} />
                </WithInspections>
              </WithInspectionTemplates>
            </WithCreateReport>
          </WithIssues>
        </WithFieldVisibility>
      </CreatePaginationContext>
    </CreateIssueContext>
  );
};

export default withFetcherReady(
  withProcessesData(
    withSites(
      withLevels(
        withCompanies(
          withContracts(
            withIssueForm(
              withIssueFilters(
                withWorktypes(
                  withHazardCategories(
                    withEnvironmentalAspects(
                      withCorrectiveActionTypes(IssueTable)
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
