import { ReactElement, useEffect, useMemo, useState } from 'react';
import { DocumentationProgress } from '.';
import { Store } from 'hooks/createStore';
import useIsMobile from 'hooks/useIsMobile';
import { useVisibleFieldsState } from 'components/dataProviders/withVisibleFields';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { HashMap } from 'shared/types/commonView';
import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { useIssuesOnMap } from 'components/dataProviders/withIssuesOnMap';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { ProgressModel } from './types';

export function useDocumentationProgressComponent(
  rightPanelVisibleStore: Store<boolean>,
  activityStore: Store<boolean>,
  progressStore: Store<ProgressModel[]>
): () => ReactElement {
  return useMemo(() => {
    return () => (
      <DocumentationProgressDisplayController
        activityStore={activityStore}
        rightPanelVisibleStore={rightPanelVisibleStore}
        progressStore={progressStore}
      />
    );
  }, [activityStore, rightPanelVisibleStore, progressStore]);
}

function DocumentationProgressDisplayController({
  activityStore,
  rightPanelVisibleStore,
  progressStore,
}: {
  activityStore: Store<boolean>;
  rightPanelVisibleStore: Store<boolean>;
  progressStore: Store<ProgressModel[]>;
}): ReactElement | null {
  const isMobile = useIsMobile();
  const visibleFields = useVisibleFieldsState();
  const hasRequiredFields = getRequiredFields(visibleFields);

  const { issuesStore } = useIssuesOnMap();

  const [progress, setProgress] = useState(
    issueToProgressModel(issuesStore.get(), visibleFields)
  );
  useEffect(() => {
    const action = (): void => {
      const progressModels = issueToProgressModel(
        issuesStore.get(),
        visibleFields
      );
      setProgress(progressModels);
      progressStore.set(progressModels);
    };
    action();
    return issuesStore.subscribe(action);
  }, [issuesStore, visibleFields, progressStore]);

  const hidden = isMobile || !hasRequiredFields || progress.length === 0;
  useEffect(() => {
    if (hidden) {
      rightPanelVisibleStore.set(false);
    }
  }, [hidden, rightPanelVisibleStore]);

  if (isMobile || !hasRequiredFields || progress.length === 0) {
    return null;
  }

  return (
    <DocumentationProgress
      activityStore={activityStore}
      progress={progress}
      rightPanelVisibleStore={rightPanelVisibleStore}
    />
  );
}

function getRequiredFields(fields: HashMap<VisibleFieldModel[]>): boolean {
  return Object.keys(fields).some((key) => {
    const completed = fields[key].find(
      (field) => field.fieldName === IssueFieldNames.completedAmount
    );
    const target = fields[key].find(
      (field) => field.fieldName === IssueFieldNames.targetAmount
    );

    return Boolean(completed && target);
  });
}

function issueToProgressModel(
  issues: IssueModel[],
  visibleFields: HashMap<VisibleFieldModel[]>
): ProgressModel[] {
  const positionOnMapVisibility = {};
  const targetAmountVisibility = {};
  const completedAmountVisibility = {};

  Object.keys(visibleFields).forEach((key) => {
    positionOnMapVisibility[key] = Boolean(
      visibleFields[key].find(
        (f) => f.fieldName === IssueFieldNames.positionOnMap
      )
    );
    targetAmountVisibility[key] = Boolean(
      visibleFields[key].find(
        (f) => f.fieldName === IssueFieldNames.targetAmount
      )
    );

    completedAmountVisibility[key] = Boolean(
      visibleFields[key].find(
        (f) => f.fieldName === IssueFieldNames.completedAmount
      )
    );
  });

  return issues
    .filter((issue) => {
      return (
        targetAmountVisibility[issue.process] &&
        completedAmountVisibility[issue.process]
      );
    })
    .sort((a, b) => {
      if (
        positionOnMapVisibility[a.process] &&
        positionOnMapVisibility[b.process]
      )
        return 0;
      if (positionOnMapVisibility[a.process]) return -1;
      return 1;
    })
    .map((issue) => {
      return {
        _id: issue._id,
        done: issue.extendedData.completedAmount,
        max: issue.extendedData.targetAmount,
      };
    });
}
