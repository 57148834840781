import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { DocumentationEditModel } from './documentationModel';

export function startDocumentationEdit(
  documentation: DocumentationEditModel
): void {
  debugLog('startDocumentationEdit', documentation);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTATIONS,
    method: ServiceMethods.EDIT,
    data: documentation,
  });
  broadcast.close();
}
