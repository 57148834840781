import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';
import {
  DomainMessagesTypes,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { inspectionEntityToModel } from 'serviceWorker/repository/inspection/inspectionEntityToModel';
import { GetInspectionEventDetail } from 'serviceWorker/api/types';
import { getInspectionById } from 'serviceWorker/services/inspections/getInspectionById';
import { getUsersSynchronized } from 'serviceWorker/services/users/getUsers';
import * as inspections from '../db/inspections';
import * as inspectionsService from '../db/inspectionsService';
import {
  makeBroadcastAll,
  makeBroadcastClear,
  makeBroadcastEntity,
} from './factories';
import { Broadcaster, OnMessage } from './types';

export const broadcastClearInspections = makeBroadcastClear(
  ChannelNames.inspectionChannel
);

export const broadcastAllInspections = makeBroadcastAll<InspectionModel[]>(
  inspectionsService,
  {
    get: async () => {
      const [entities, users] = await Promise.all([
        inspections.get(),
        getUsersSynchronized(),
      ]);
      return entities.map((entity) =>
        inspectionEntityToModel(entity, users)
      );
    },
  },
  ChannelNames.inspectionChannel,
  DomainMessagesTypes.allInspections
);

export const broadcastInspection = makeBroadcastEntity<InspectionModel>(
  {
    getOne: getInspectionById,
  },
  ChannelNames.inspectionChannel,
  DomainMessagesTypes.inspection
);

const broadcastState: Broadcaster = async (broadcast) => {
  const status = await inspectionsService.get();
  broadcast.postMessage({ data: status, type: DomainMessagesTypes.state });
};

const unsyncedBroadcastInspection = makeBroadcastEntity<InspectionModel>(
  {
    getOne: getInspectionById,
  },
  ChannelNames.inspectionChannel,
  DomainMessagesTypes.inspection
);

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.inspectionChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }
      case DomainMessagesTypes.getInspection: {
        if (!event.data) {
          return;
        }

        await unsyncedBroadcastInspection(
          new CustomEvent<GetInspectionEventDetail>(
            DomainMessagesTypes.getInspection,
            {
              detail: { id: event.data.id },
            }
          )
        );
        resync();
        break;
      }
      case DomainMessagesTypes.getAllInspections: {
        await broadcastAllInspections();
        resync();
      }
    }
  };

  broadcast.onmessage = handler;
};

function resync(): void {
  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.INSPECTIONS,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}

export default init;
