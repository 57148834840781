import { GU } from 'components/common/graphicUploader/types';
import {
  InspectionTemplateOutDto,
  ChecklistItemOutDto,
  CreateChecklistItemsOutDto,
} from 'shared/dtos/out/template';
import { FormChecklistItem } from '../form/types';
import { TemplateDefaultValues } from '../populator/types';
import { ChecklistChangeObject } from './types';

export function hasChanges(
  oldValues: TemplateDefaultValues,
  newValues: InspectionTemplateOutDto
): boolean {
  if (oldValues.process !== newValues.process) {
    return true;
  }
  if (oldValues.summary !== newValues.summary) {
    return true;
  }
  return (
    oldValues.generalNorm.description !== newValues.generalNorm.description
  );
}

export function createChecklistChangeObject(
  oldValues: FormChecklistItem[],
  newValues: FormChecklistItem[]
): ChecklistChangeObject {
  const startingValue = {
    created: [],
    changed: [],
    deleted: oldValues.filter(
      (oldValue) =>
        !newValues.find((newValue) => newValue._id === oldValue._id)
    ),
  };

  return newValues.reduce<ChecklistChangeObject>((result, value) => {
    if (!value._id) {
      result.created.push(value);
      return result;
    }

    const previousValueChecklistItem = oldValues.find(
      (oldValue) => oldValue._id === value._id
    );

    // this should never happen but I don't want to use '!' above, better safe than sorry.
    if (!previousValueChecklistItem) {
      return result;
    }

    const change = optimizeChecklistChange(
      previousValueChecklistItem,
      value
    );
    if (change) {
      result.changed.push(change);
    }
    return result;
  }, startingValue);
}

function optimizeChecklistChange(
  oldValue: FormChecklistItem,
  newValue: FormChecklistItem
): FormChecklistItem | undefined {
  if (oldValue.summary !== newValue.summary) {
    return newValue;
  }
  if (oldValue.description !== newValue.description) {
    return newValue;
  }

  return undefined;
}

export function toCreateChecklistItemsDto(
  checklist: FormChecklistItem[]
): CreateChecklistItemsOutDto {
  return checklist.map((itemToCreate) => ({
    _id: itemToCreate._id,
    summary: itemToCreate.summary,
    norm: {
      description: itemToCreate.description,
    },
  }));
}

export function toChecklistItemDto(
  checklist: FormChecklistItem,
  index?: number
): ChecklistItemOutDto {
  return {
    _id: checklist._id,
    index: index,
    summary: checklist.summary,
    norm: {
      description: checklist.description,
    },
  };
}

export function toUploaders(checklist: FormChecklistItem): GU {
  return checklist.graphicUploader!;
}

export function createInspectionTemplateGeneralNormDocumentsBaseUrl(
  id: string
): string {
  return `/inspectionTemplate/${id}/generalNorm`;
}

export function createTemplateChecklistItemDocumentsBaseUrl(
  templateId: string,
  checklistItemId?: string
): string {
  return templateId && checklistItemId
    ? `/inspectionTemplate/${templateId}/checklistItem/${checklistItemId}/norm`
    : '';
}
