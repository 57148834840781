import cn from 'classnames';
import { useStyles } from 'components/common/forms/formStyles/styles';
import { MemoPreviewField } from 'components/general/PreviewField';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { InspectionFormPresentationalProps } from '../types';
import { useInspectionFormStyles } from './styles';
import { Divider, Grid } from '@mui/material';
import { Button } from '@mui/material';

import { MemoProcessIcon } from 'components/common/ProcessIcon';
import {
  MemoStatefulAutocompleteWithText,
  MemoStatefulDatepicker,
  MemoStatefulMultiselect,
  MemoStatefulSingleSelect,
} from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import { InspectionStatus } from 'shared/domain/inspection/inspectionModel';
import useIsMobile from '../../../../hooks/useIsMobile';
import { ContextedGeneralNormPreview } from '../../GeneralNormPreview';
import { ProtocolHeader } from '../../ProtocolHeader';
import { MemoProtocolItem } from '../../ProtocolItem';
import { MemoInspectionStatus } from '../../Status';
import { InspectionStatusSize } from '../../Status/types';
import { SubmitButtons } from './submitButtons';

export function InspectionFormPresentational({
  isPosting,
  template,
  executionFieldProps,
  workTypesFieldProps,
  sitesFieldProps,
  levelsFieldProps,
  controlledPartiesFieldProps,
  contractsFieldProps,
  commentFieldProps,
  checklist,
  protocolIssues,
  onSubmit,
  onFinish,
  onCancel,
}: InspectionFormPresentationalProps): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const specificClasses = useInspectionFormStyles({ isMobile });

  return (
    <>
      <div>
        <div className={specificClasses.root}>
          <h2 className='m0 full-grid-span'>{template.summary}</h2>
          <Divider sx={{ my: 2 }} />
          <Grid container columnSpacing={1} rowSpacing={0}>
            <Grid item xs={8} sm={4}>
              <MemoPreviewField
                label={intl.formatMessage({
                  id: 'general_process',
                })}
                component={
                  <MemoProcessIcon
                    code={template.renderableProcess.code}
                    color={template.renderableProcess.color}
                  />
                }
                dense={true}
              />
            </Grid>
            <Grid item xs={8} sm={4}>
              <MemoPreviewField
                label={intl.formatMessage({
                  id: 'inspection_status',
                })}
                component={
                  <MemoInspectionStatus
                    status={InspectionStatus.inProgress}
                    size={InspectionStatusSize.default}
                  />
                }
                dense={true}
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2 }} />
          <Grid container columnSpacing={1} rowSpacing={0}>
            <Grid item xs={8} sm={6} md={4}>
              <MemoStatefulDatepicker {...executionFieldProps} />
            </Grid>
            <Grid item xs={8} sm={6} lg={4}>
              <MemoStatefulMultiselect {...workTypesFieldProps} />
            </Grid>
            <Grid item xs={8} sm={6} lg={4}>
              <MemoStatefulSingleSelect {...sitesFieldProps} />
            </Grid>
            <Grid item xs={8} sm={6} lg={4}>
              <MemoStatefulMultiselect {...levelsFieldProps} />
            </Grid>
            <Grid item xs={8} sm={6} lg={4}>
              <MemoStatefulMultiselect {...controlledPartiesFieldProps} />
            </Grid>
            <Grid item xs={8} sm={6} lg={4}>
              <MemoStatefulMultiselect isHalf {...contractsFieldProps} />
            </Grid>
            <Grid item xs={12}>
              <MemoStatefulAutocompleteWithText {...commentFieldProps} />
            </Grid>
          </Grid>
          <Divider textAlign='left'>
            <h2>
              {intl.formatMessage({
                id: 'inspection_template_header_general_applied_standard',
              })}
            </h2>
          </Divider>
          <ContextedGeneralNormPreview
            classes={{
              graphicUploaderWrapper: 'norm-preview-uploader-wrapper',
              wrappedContent: 'norm-preview-content',
              generalNormContainer: 'norm-preview-container',
            }}
          />
          <Divider className='divider full-grid-span' />
          <div className='full-grid-span'>
            <ProtocolHeader
              protocolIssues={protocolIssues}
              classes={{ root: 'protocol-header-root' }}
            />
          </div>
          <div className={specificClasses.checklist}>
            {checklist.map((checklistItem, index) => {
              return (
                <MemoProtocolItem
                  key={checklistItem._id}
                  checklistItem={checklistItem}
                  index={index}
                  classes={{ divider: 'divider' }}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={specificClasses.bottomSpace} />
      <div className={cn(classes.submitFooter, classes.castShadow)}>
        <Divider className={cn(classes.fullWidth)} />
        <div className={classes.footerPadding}>
          <Button onClick={onCancel} disabled={isPosting}>
            {intl.formatMessage({ id: 'general_cancel' })}
          </Button>
          <div className={classes.submitButtons}>
            <SubmitButtons
              onSubmit={onSubmit}
              posting={isPosting}
              onFinish={onFinish}
            />
          </div>
        </div>
      </div>
    </>
  );
}
