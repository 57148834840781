import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { organizationEntityToModel } from 'shared/domain/organization/mapping/toModel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import * as dbOrganizations from '../../db/organizations';
import * as dbOrganizationsService from '../../db/organizationsService';

export async function getFromDbAndBroadcastAllOrganizations(
  broadcast: BroadcastChannel
): Promise<void> {
  const start = Date.now();
  const status = await dbOrganizationsService.get();

  const organizations = await dbOrganizations.get();
  const end = Date.now();
  debugLog('getAllCurrentOrganizations time:', end - start);
  const currentOrganizations = organizations.map((organization) =>
    organizationEntityToModel(organization)
  );
  debugLog('postingAllOrganizations', currentOrganizations);
  const hasAll = status?.isDownloaded;

  const data = {
    hasAll,
    items: currentOrganizations,
  };
  broadcast.postMessage({
    data,
    type: DomainMessagesTypes.allOrganizations,
  });

  // const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  // apiBroadcast.postMessage({
  //   service: Services.ORGANIZATIONS,
  //   method: ServiceMethods.PULL,
  // });

  // apiBroadcast.close();
}
