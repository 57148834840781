import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { Identificable } from 'shared/types/commonView';

function emitProject(project: Identificable): void {
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.SELECTED_PROJECT,
    method: ServiceMethods.SET,
    data: {
      _id: project._id,
    },
  });
  broadcast.close();
}

export function selectProject(project?: Identificable): void {
  if (project?._id) {
    emitProject(project);
  }
}
