import React, { memo, ReactElement } from 'react';

import { MemoButtonContained } from 'components/general/ButtonContained/ButtonContained';
import { MemoForbiddenTooltip } from 'components/general/ForbiddenTooltip/ForbiddenTooltip';
import { useStyles } from './styles';
import { FirstIssueDialog } from './firstIssueDialog';
import { useIntl } from 'react-intl';
import { NotPassedPresentationalProps, SharedContentProps } from './types';
import { SetableIssueFields } from '../../../../views/issue/wizard/types';
import { CreateIssueDialog } from '../../../../views/issue/wizard/createIssueDialog';

const fieldsToDisable: (keyof SetableIssueFields)[] = ['site', 'process'];

function ComplianceCheckFormNotPassedPresentational({
  disabled,
  disabledReason,
  issueCards,
  createInspectionIssue,
  processCode,
  open,
  handleClose,
  initialValues,
  beforeSave,
  firstIssueDialogOpen,
  firstIssueDialogHandleClose,
}: NotPassedPresentationalProps): ReactElement {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SharedContent
        disabled={disabled}
        disabledReason={disabledReason}
        createInspectionIssue={createInspectionIssue}
      />
      <div className={classes.cardsContainer}>{issueCards}</div>
      <FirstIssueDialog
        firstIssueDialogOpen={firstIssueDialogOpen}
        firstIssueDialogHandleClose={firstIssueDialogHandleClose}
      >
        <SharedContent
          disabled={disabled}
          disabledReason={disabledReason}
          createInspectionIssue={createInspectionIssue}
        />
      </FirstIssueDialog>

      <CreateIssueDialog
        beforeSave={beforeSave}
        processCode={processCode}
        initialValues={initialValues}
        disabledFields={fieldsToDisable}
        afterDone={handleClose}
        open={open}
      />
    </div>
  );
}

function SharedContent({
  createInspectionIssue,
  disabled,
  disabledReason,
}: SharedContentProps): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <p>{intl.formatMessage({ id: 'not_compatible_helper_text' })}</p>
      <MemoForbiddenTooltip
        visibleWhen={disabled}
        reason={disabledReason || ''}
        inlined
      >
        <MemoButtonContained
          disabled={disabled}
          onClick={createInspectionIssue}
        >
          {intl.formatMessage({ id: 'toolbar_title_create_issue' })}
        </MemoButtonContained>
      </MemoForbiddenTooltip>
    </div>
  );
}

export const MemoComplianceCheckFormNotPassedPresentational = memo(
  ComplianceCheckFormNotPassedPresentational
);
