import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { WithIssueCreateMessage } from 'components/dataCreationForms/withIssueCreateMessage';
import { WithFieldVisibility } from 'components/dataProviders/withVisibleFields';
import React, { useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { IssueWizard } from './index';
import { IssueWizardProps } from './types';

const useStyles = makeStyles({
  root: {
    '& .MuiDialog-paper': {
      margin: 16,
      width: '100%',
    },
  },
  dialogContent: {
    padding: '24px 16px',
  },
  closeIcon: {
    opacity: 0.54,
    cursor: 'pointer',
  },
  dialogTitle: {
    padding: '16px',
    '& .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      height: '18px',
    },
  },
});

export function CreateIssueDialog(
  props: IssueWizardProps & { open: boolean }
): React.ReactElement {
  const { open, ...wizardProps } = props;

  const wizardRef = useRef<{
    onCancel: () => Promise<void>;
  }>(null);

  const classes = useStyles();

  const handleCloseDialog = useCallback(async () => {
    if (wizardRef.current) {
      await wizardRef.current.onCancel();
    }
  }, []);

  return (
    <Dialog
      className={classes.root}
      open={open}
      keepMounted={false}
      onClose={handleCloseDialog}
    >
      <DialogTitle className={classes.dialogTitle}>
        <FormattedMessage id='toolbar_title_create_issue' />
      </DialogTitle>
      <Divider />
      <DialogContent className={classes.dialogContent}>
        {/* // react 18 types
            // @ts-ignore */}
        <WithIssueCreateMessage>
          <WithFieldVisibility>
            <IssueWizard {...wizardProps} ref={wizardRef} />
          </WithFieldVisibility>
        </WithIssueCreateMessage>
      </DialogContent>
    </Dialog>
  );
}
