import React, {
  forwardRef,
  useState,
  useImperativeHandle,
  Ref,
} from 'react';
import classNames from 'classnames';
import StarFilled from '@mui/icons-material/Star';
import StarBorder from '@mui/icons-material/StarBorder';
import { createStyles, WithStyles, withStyles } from '@mui/styles';
import { Theme } from '@mui/material';

const styles = createStyles((theme: Theme) => ({
  feedbackMarkWrapper: {
    marginTop: '30px',
    marginBottom: '36px',
  },
  feedbackMark: {
    fill: '#979797',
    fontSize: '2.2rem',
    marginRight: '24px',
  },
  feedbackIcon: {
    fontSize: '1.875rem',
    marginRight: '28px',
    fill: '#979797',
    cursor: 'pointer',
  },
  starFilled: {
    fill: theme.palette.primary.main,
  },
}));

type StarComponentProps = WithStyles<any> & {
  index: number;
  rating: number;
  onClick: (number: number) => void;
};

const StarComponent = ({
  index,
  rating,
  classes,
  onClick,
}: StarComponentProps): React.ReactElement => {
  const ratingValue = index + 1;

  const isFilled = ratingValue <= rating;
  const Star = isFilled ? StarFilled : StarBorder;
  const className = isFilled
    ? classNames(classes.feedbackIcon, classes.starFilled)
    : classes.feedbackIcon;

  return (
    <Star
      key={index}
      className={className}
      onClick={(): void => onClick(ratingValue)}
      data-qa={`feedback-rating-star-${ratingValue}`}
    />
  );
};

export type MarkRef = {
  getRating: () => number;
  isDirty: boolean;
};

const Mark = (
  { classes }: WithStyles<any>,
  ref: Ref<unknown> | undefined
): React.ReactElement => {
  const [rating, setRating] = useState<number>(0);

  useImperativeHandle(ref, () => ({
    getRating: (): number => {
      return rating;
    },
    isDirty: !!rating,
  }));

  return (
    <div className={classes.feedbackMarkWrapper}>
      {Array.from({ length: 5 }).map((_, index) => (
        <StarComponent
          index={index}
          rating={rating}
          classes={classes}
          onClick={setRating}
        />
      ))}
    </div>
  );
};

const FeedbackMark = forwardRef<MarkRef, WithStyles<any>>(Mark);

export default withStyles(styles)(FeedbackMark);
