import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debuglog } from 'util';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGet } from './makeUseGet';
import { DirectoryModel } from 'shared/domain/directory/directoryModel';

export const useGetDirectories = makeUseGet<
  { items: DirectoryModel[] },
  string
>({
  listenMessageChannel: ChannelNames.directoryChannel,
  isMatchingMessage,
  postMessage: broadcastGetDirectories,
  entityName: 'directories',
});

function broadcastGetDirectories(folder: string): void {
  debuglog('send broadcastGetDirectories');
  const broadcast = new BroadcastChannel(ChannelNames.directoryChannel);
  broadcast.postMessage({
    type: DomainMessagesTypes.getDirectories,
    data: {
      parentId: folder,
    },
    uniqueId: folder,
  });
  broadcast.close();
}

function isMatchingMessage(folderId: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.directories &&
    Boolean(event.data) &&
    event.uniqueId === folderId
  );
}
