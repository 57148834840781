import { ServiceData } from './service';
import db from './index';
import { entityServiceFactory } from './entityServiceFactory';

export interface DocumentationsServiceData extends ServiceData {}
const { add, clear, addSync, get, reset } =
  entityServiceFactory<DocumentationsServiceData>(
    db,
    'documentationsService'
  );

export { add, clear, addSync, get, reset };
