import { startProjectDataSynchronization } from 'shared/domain/project/startProjectDataSynchronization';
import { UNPROCESSABLE_ENTITY } from 'shared/contants';

export function syncOnUprocessableEntity(response: {
  status: number;
}): void {
  if (response.status === UNPROCESSABLE_ENTITY) {
    startProjectDataSynchronization();
  }
}
