import {
  CreateFieldValueOnView,
  EditFieldValueOnView,
  FieldValueOnView,
  FieldValueTranslationOnView,
  FieldValueType,
} from 'presentation/fieldValue/fieldValueOnView';
import { FieldValueTranslation } from 'shared/dtos/in/fieldValueTranslation';
import { LabelledEntity } from 'shared/types/commonView';
import {
  ISO6391LanguageCode,
  ISO6391LanguageCodeOption,
  LANGUAGE_LABELS,
  LOCALE_LANGUAGES,
  LocaleCountryAndLanguageCode,
} from 'shared/types/locale';
import { Process } from 'shared/types/process';
import { FieldValueFlatForm } from '.';
type FlatValueTranslations = {
  [key: string]: LabelledEntity | string;
};

const toLocaleCode = (locale: string): ISO6391LanguageCodeOption =>
  locale.slice(0, 2) as ISO6391LanguageCodeOption;
export function fieldToFormInitialValues(
  field?: CreateFieldValueOnView | FieldValueOnView,
  newItem?: { providedLabel: string; locale: any }
): FieldValueFlatForm {
  const processes = field?.processes || [
    Process.HS,
    Process.QA,
    Process.ENV,
    Process.TSK,
  ];
  const newTranslations: FieldValueTranslationOnView[] = [];

  if (newItem?.providedLabel) {
    const translation: FieldValueTranslationOnView = {
      type: FieldValueType.toCreate,
      value: newItem.providedLabel,
      deleted: false,
      localeCode: newItem.locale,
      _id: '',
    };
    newTranslations.push(translation);
  }
  const valueTranslations = field?.valueTranslations || newTranslations;

  const flatValueTranslations = valueTranslations.reduce(
    (result: FlatValueTranslations, current, index: number) => {
      const shortLocaleCode = toLocaleCode(current.localeCode);
      result[`valueTranslations${index}`] = {
        _id: LOCALE_LANGUAGES[shortLocaleCode],
        label: LANGUAGE_LABELS[shortLocaleCode],
      };
      result[`label${index}`] = current.value;
      return result;
    },
    {}
  );

  return {
    ...flatValueTranslations,
    processes,
    ignoreIndexes: [],
    _field: field,
  };
}

export function formToFieldValue(
  currentLanguage: ISO6391LanguageCode,
  values: FieldValueFlatForm
): CreateFieldValueOnView | EditFieldValueOnView {
  const keys: string[] = Object.keys(values);
  const fieldValue = keys.reduce<
    CreateFieldValueOnView | EditFieldValueOnView
  >(
    (
      result: CreateFieldValueOnView | EditFieldValueOnView,
      key: string
    ) => {
      if (key === 'processes') {
        result[key] = values[key];
      }
      if (key.includes('valueTranslations')) {
        addTranslationValue(
          key,
          values[key] as { _id: LocaleCountryAndLanguageCode },
          result,
          values.ignoreIndexes,
          values._field
        );
      }
      if (key.includes('label')) {
        addTranslationLabelValue(
          key,
          values[key] as string,
          result,
          values.ignoreIndexes
        );
      }
      return result;
    },
    values._field?._id
      ? ({ _id: values._field._id } as EditFieldValueOnView)
      : ({
          canEditTranslations: true,
          localId:
            (values._field as CreateFieldValueOnView)?.localId ||
            `${Date.now()}`,
        } as CreateFieldValueOnView)
  );

  if (fieldValue.valueTranslations) {
    fieldValue.valueTranslations = fieldValue.valueTranslations.filter(
      (x) => x
    );
  }

  if (!fieldValue._id) {
    (fieldValue as CreateFieldValueOnView).label = setLabel(
      currentLanguage,
      fieldValue as CreateFieldValueOnView
    );
  }
  return fieldValue;
}

function getTranslationType(
  field: CreateFieldValueOnView | FieldValueOnView | undefined,
  translationLocaleCode: LocaleCountryAndLanguageCode
): FieldValueType {
  if (!field) {
    return FieldValueType.toCreate;
  }
  const foundTranslation = field.valueTranslations.find(
    (translation) => translation.localeCode === translationLocaleCode
  );
  if (foundTranslation) {
    return foundTranslation.type;
  }
  return FieldValueType.toCreate;
}

type TranslationValues = (Partial<FieldValueTranslationOnView> & {
  type: FieldValueType;
})[];
function addTranslationValue(
  key: string,
  value: { _id: LocaleCountryAndLanguageCode },
  result: { valueTranslations?: TranslationValues },
  indexesToIgnore: number[],
  field: CreateFieldValueOnView | FieldValueOnView | undefined
): void {
  const index = parseFloat(key.split('valueTranslations')[1]);

  if (indexesToIgnore.includes(index)) {
    return;
  }

  const type: FieldValueType = getTranslationType(field, value._id);

  if (result.valueTranslations) {
    result.valueTranslations[index] = {
      ...result.valueTranslations[index],
      localeCode: value._id,
      type,
    };
  } else {
    result.valueTranslations = [];
    result.valueTranslations[index] = { localeCode: value._id, type };
  }
}

function addTranslationLabelValue(
  key: string,
  value: string,
  result: { valueTranslations?: Partial<FieldValueTranslation>[] },
  indexesToIgnore: number[]
): void {
  const index = parseFloat(key.split('label')[1]);

  if (indexesToIgnore.includes(index)) {
    return;
  }

  if (result.valueTranslations) {
    result.valueTranslations[index] = {
      ...result.valueTranslations[index],
      value: value,
    };
  } else {
    result.valueTranslations = [];
    result.valueTranslations[index] = {
      value: value,
    };
  }
}

function setLabel(
  currentLanguage: ISO6391LanguageCode,
  fieldValue: CreateFieldValueOnView
): string {
  const currentLanguageTranslation = fieldValue.valueTranslations.find(
    (translation) => {
      return translation.localeCode.includes(currentLanguage);
    }
  );
  return currentLanguageTranslation
    ? currentLanguageTranslation.value
    : fieldValue.valueTranslations[0].value;
}
