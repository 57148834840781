import { DOCUMENT_ERROR_SOURCE } from 'shared/domain/document/documentError';
import { MAX_DISPLAY_FILE_SIZE } from 'shared/domain/document/documentLimits';
import { DocumentUrlsModel } from 'shared/domain/document/documentUrlsModel';
import {
  resolveDocumentUrls,
  DocumentUrlsResult,
} from 'shared/domain/document/resolveDocumentUrls';
import { HashMap } from 'shared/types/commonView';
import { DisplayedFile, DisplayedFilesStore } from '../gallery/filesStore';
import { FileReadSuccess } from './types';

async function getDocumentSize(
  type: string,
  documentString: string
): Promise<number> {
  if (!type.includes('image')) return 0;
  if (documentString.startsWith('http')) {
    const response = await fetch(documentString);
    if (response.ok) return (await response.blob()).size;
    else return 0;
  } else {
    // estimated good enough datastring size
    return 3 * (documentString.length / 4);
  }
}

function canSetSignedRequest(file: DisplayedFile): boolean {
  return file && !file.signedRequest;
}

function setFileSignedReuqest(
  fileId: number | undefined,
  filesStore: DisplayedFilesStore,
  fileSelector: (file: DisplayedFile) => number | undefined,
  thumbnail: string,
  downloadSrc: string,
  src: string,
  signedRequest: DocumentUrlsModel
): void {
  if (fileId === undefined) return;

  const files = filesStore.get();
  const fileIndex = files.findIndex(
    (file) => fileSelector(file) === fileId
  );
  const file = files[fileIndex];

  const fileWithSignedRequest = {
    ...file,
    loading: false,
    downloadSrc,
    thumbnail,
    src,
    signedRequest,
  };

  files[fileIndex] = fileWithSignedRequest;
  filesStore.set(files.slice());
}

export function makeDownloadFile(
  fileIdSelector: (
    file: DisplayedFile<FileReadSuccess | object>
  ) => number | undefined,
  cache: HashMap<any>,
  filesStore: DisplayedFilesStore
): (file: DisplayedFile<FileReadSuccess | object>) => void {
  return function downloadFile(
    file: DisplayedFile<FileReadSuccess | object>
  ): void {
    const fileId = fileIdSelector(file);
    if (fileId === undefined) {
      return fakeSignedRequestForUnsavedFile(
        file as DisplayedFile<FileReadSuccess>,
        filesStore
      );
    }

    if (cache[fileId]) {
      return cache[fileId].then(setFileSourceUrls);
    }
    cache[fileId] = resolveDocumentUrls(fileId);

    cache[fileId].then(setFileSourceUrls);

    async function setFileSourceUrls(
      response: DocumentUrlsResult
    ): Promise<void> {
      const files = filesStore.get();
      const fileIndex = files.findIndex(
        (file) => fileIdSelector(file) === fileId
      );

      if (!canSetSignedRequest(files[fileIndex])) {
        return;
      }

      const originalSrc = response.documentUrls.signedRequest;

      const downloadSrc =
        file.data &&
        file.data.isDrawn &&
        response.documentUrls.mergedSignedRequest
          ? response.documentUrls.mergedSignedRequest
          : response.documentUrls.signedRequest;

      let thumbnail =
        files[fileIndex].data?.isDrawn &&
        response.documentUrls.mergedThumbnailSignedRequest
          ? response.documentUrls.mergedThumbnailSignedRequest
          : response.documentUrls.thumbnailSignedRequest;

      if (!thumbnail) {
        const size = await getDocumentSize(
          files[fileIndex].type,
          response.documentUrls.signedRequest
        );
        if (size < MAX_DISPLAY_FILE_SIZE) {
          thumbnail = response.documentUrls.signedRequest;
        } else {
          thumbnail = DOCUMENT_ERROR_SOURCE;
        }
      }

      setFileSignedReuqest(
        fileId,
        filesStore,
        fileIdSelector,
        thumbnail,
        downloadSrc,
        originalSrc,
        response.documentUrls
      );
    }
  };
}

function fakeSignedRequestForUnsavedFile(
  file: DisplayedFile<FileReadSuccess>,
  filesStore: DisplayedFilesStore
): void {
  if (file.signedRequest) return;
  let thumbnail = DOCUMENT_ERROR_SOURCE;
  if (file.file!.size < MAX_DISPLAY_FILE_SIZE) {
    thumbnail = file.src;
  }

  const files = filesStore.get();
  const fileIndex = files.findIndex((_f) => file === _f);
  const foundFile = files[fileIndex];
  if (!foundFile) return;

  const fileWithSignedRequest = {
    ...foundFile,
    loading: false,
    thumbnail: thumbnail,
    signedRequest: {
      signedRequest: file.downloadSrc as string,
    },
  };

  files[fileIndex] = fileWithSignedRequest;
  filesStore.set(files.slice());
}
