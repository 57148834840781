import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { debugLog } from 'shared/logger/debugLog';
import { SiteHttpDeleteRequestModel } from '../httpQueue/site/types';
import { DeleteSiteUseCase } from 'shared/domain/site/types/useCases';

export class SiteDeleter implements DeleteSiteUseCase {
  constructor(
    private addRequest: (
      request: SiteHttpDeleteRequestModel
    ) => Promise<any>
  ) {}
  async execute({
    siteId,
    projectId,
    uniqueId,
  }: {
    siteId: string;
    uniqueId: string;
    projectId: string;
  }): Promise<void> {
    debugLog('DeleteSiteUseCase', siteId);
    await this.addRequest({
      createdAt: Date.now(),
      method: 'DELETE',
      data: {
        siteId: siteId,
        projectId,
        uniqueId: uniqueId,
      },
      url: `/project/${projectId}/site/${siteId}`,
      entityType: HttpRequestModelType.site,
      status: HttpRequestStatus.NEW,
    });
  }
}

export const siteDeleter = new SiteDeleter(addRequest);
