import { Gallery } from 'components/common/gallery';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { useCurrentDirectoryContext } from 'components/dataProviders/withCurrentDirectory';
import { useDocumentationController } from 'components/dataProviders/withDocumentationController';
import { DocumentationOnView } from 'components/dataProviders/withDocumentationController/types';
import { withDocumentationForm } from 'components/dataCreationForms/withDocumentationForm';
import { useDocumentationSearchContext } from 'components/dataProviders/withDocumentationSearch';
import { withIssueChannelListener } from 'components/broadcastChannelListeners/withIssueChannelListener';
import { withIssueForm } from 'components/common/withIssueForm';
import { withLevels } from 'components/dataProviders/withLevels';
import { withSites } from 'components/dataProviders/withSites';
import { TableSkeleton } from 'components/table/skeleton';
import { listenToOpenDocumentationInput } from 'shared/domain/documentation/openDocumentationInput';
import useKeyPress from 'hooks/keyPress';
import { useSavedTableColumnWidths } from 'hooks/table/useSavedTableColumnWidths';
import { openDirectoryForm } from 'presentation/directory/openDirectoryInput';
import {
  ChangeEvent,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import BaseTable, { Column, ColumnShape } from 'react-base-table';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { TableWidthStorageKey } from '../../table/types';
import { DocumentationEmpty } from './DocumentationEmpty';
import { createColumns } from './columns';
import {
  useDirectoriesState,
  useDocumentationsState,
  useSearchState,
} from '../model';
import { Identificable } from 'shared/types/commonView';
import {
  DocumentationContextMenu,
  useContextMenu,
} from './DocumentationContextMenu';
import { useDocumentationFileUrlConrol } from './useDocumentationFileUrlControl';
import FilterSidebar from 'components/core/Layout/filters/FilterSidebar';
import { withWorktypes } from 'components/dataProviders/withWorktypes';
import { withCorrectiveActionTypes } from 'components/dataProviders/withCorrectiveActionTypes';
import { withEnvironmentalAspects } from 'components/dataProviders/withEnvironmentalAspects';
import { withHazardCategories } from 'components/dataProviders/withHazardCategories';
import { withInspectionTemplates } from 'components/dataProviders/withInspectionTemplates';
import { useStyles } from './styles';
import { useDocumentationGallery } from './useDocumentationGallery';
import { withInspections } from 'components/dataProviders/withInspections';
import { useTouchClickDetection } from 'hooks/clickDetection/useTouchClickDetection';
import { withStoreDependencies } from './WithStoreDependencies';
import { useIssueOverlayControls } from './WithIssueOverlayControls';

type HandlerProps = {
  rowIndex: number;
  rowData: DocumentationOnView;
  // Don't know how to type it, It's an event based on handle but BaseTable does not like it.
  event: any;
};

function _DocumentationTable({
  width,
  height,
}: {
  width: number;
  height: number;
}): ReactElement {
  const { searchPhraseStore, setSearchPhrase: setContextSearchPhrase } =
    useDocumentationSearchContext();

  const [searchPhrase, setSearchPhrase] = useState(
    searchPhraseStore.get()
  );

  useEffect(() => {
    return searchPhraseStore.subscribe(() => {
      setSearchPhrase(searchPhraseStore.get());
    });
  }, [searchPhraseStore]);

  const { currentDirectoryStore } = useCurrentDirectoryContext();
  const {
    addDocumentationsFromFiles,

    selectElements,
    unselectElements,
    selectedFilesStore,

    setParent,

    currentDirDocumentationsStore,
    isLoadingCurrentDirDocumentationsStore,

    currentDirectoryFoldersStore,
    isLoadingCurrentDirFoldersStore,

    searchedDirectoriesStore,
    searchedDocumentationsStore,
    loadingSearchStore,
  } = useDocumentationController();

  const { directories, isLoadingDirectories } = useDirectoriesState(
    currentDirectoryFoldersStore,
    isLoadingCurrentDirFoldersStore
  );

  const { documentations, isLoadingDocumentations } =
    useDocumentationsState(
      currentDirDocumentationsStore,
      isLoadingCurrentDirDocumentationsStore
    );

  const { searchedDirectories, searchedDocumentations, isLoadingSearch } =
    useSearchState(
      searchedDirectoriesStore,
      searchedDocumentationsStore,
      loadingSearchStore
    );

  const [selected, setSelected] = useState<Set<DocumentationOnView>>(
    new Set(selectedFilesStore.get())
  );

  const rows: DocumentationOnView[] = useMemo(() => {
    return [
      ...(searchPhrase ? searchedDirectories : directories).map((dir) => ({
        ...dir,
        type: 'DIRECTORY' as 'DIRECTORY',
        uniqueKeyId: `${dir.localId}-directory`,
      })),
      ...(searchPhrase ? searchedDocumentations : documentations).map(
        (doc) => ({
          ...doc,
          uniqueKeyId: `${doc.localId}-file`,
        })
      ),
    ];
  }, [
    documentations,
    directories,
    searchPhrase,
    searchedDirectories,
    searchedDocumentations,
  ]);

  const { handleContextMenu, handleClose, contextMenu } = useContextMenu();

  const { timezone } = useSelector(projectDataSelector);
  const classes = useStyles();
  const intl = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const { columnWidths, saveColumnWidth } =
    useSavedTableColumnWidths<DocumentationOnView>(
      TableWidthStorageKey.documentation
    );
  const columns = useMemo(
    () => createColumns(intl, timezone, columnWidths),
    [intl, timezone, columnWidths]
  );

  const GalleryController = useDocumentationGallery();

  const {
    setGallery,
    openEditComponent,
    changeGallery,
    galleryStateStore,
  } = GalleryController;

  useDocumentationFileUrlConrol(
    galleryStateStore,
    currentDirectoryStore,
    currentDirDocumentationsStore
  );

  useEffect(() => {
    function openFilesInput(): void {
      if (!inputRef.current) return;

      inputRef.current.click();
    }
    return listenToOpenDocumentationInput(openFilesInput);
  }, []);

  useEffect(() => {
    return selectedFilesStore.subscribe(() => {
      setSelected(selectedFilesStore.get());
    });
  }, [selectedFilesStore]);

  useKeyPress(
    'a',
    useCallback(
      (event: KeyboardEvent) => {
        // we check target to properly handle form input
        if (event.ctrlKey && event.target === document.body) {
          event.preventDefault();
          selectElements(rows);
        }
      },
      [selectElements, rows]
    ),
    { stopPreventDefault: true }
  );
  useKeyPress(
    'Escape',
    useCallback(() => {
      unselectElements();
    }, [unselectElements])
  );

  const openElement = useCallback(
    ({ rowIndex, rowData, event }: HandlerProps): void => {
      selectElements([rowData], {
        ctrlKeyPressed: event.ctrlKey,
      });

      if (rowData.type === 'DIRECTORY') {
        if (!rowData._id) return;
        setContextSearchPhrase('');
        currentDirectoryStore.set(rowData._id);
        return;
      }

      const directoriesLength = rows.filter(
        (row) => row.type === 'DIRECTORY'
      ).length;
      setGallery({ open: true, slide: rowIndex - directoriesLength });
    },
    [
      currentDirectoryStore,
      rows,
      selectElements,
      setContextSearchPhrase,
      setGallery,
    ]
  );

  const { handleTouchDown, handleTouchMove, handleTouchUp } =
    useTouchClickDetection(openElement);

  const rowHandleTouchStart = useCallback(
    (e: HandlerProps) => {
      handleTouchDown(e.event);
    },
    [handleTouchDown]
  );
  const rowHandleTouchMove = useCallback(
    (e: HandlerProps) => {
      handleTouchMove(e.event);
    },
    [handleTouchMove]
  );
  const rowHandleTouchEnd = useCallback(
    (e: HandlerProps) => {
      handleTouchUp(e.event, e);
    },
    [handleTouchUp]
  );

  const rowEventHandlers = useMemo(() => {
    return {
      onMouseDown: ({ rowIndex, rowData, event }: HandlerProps): void => {
        selectElements([rowData], {
          ctrlKeyPressed: event.ctrlKey,
        });

        if (rowData.type === 'DIRECTORY') {
          return;
        }

        const directoriesLength = rows.filter(
          (row) => row.type === 'DIRECTORY'
        ).length;
        changeGallery('slide', rowIndex - directoriesLength);
      },
      onDoubleClick: openElement,
      onTouchStart: rowHandleTouchStart,
      onTouchMove: rowHandleTouchMove,
      onTouchEnd: rowHandleTouchEnd,
      onDrop: ({ rowData }: { rowData: DocumentationOnView }) => {
        if (!rowData._id) return;
        const moveToSelf = Array.from(selectedFilesStore.get()).find(
          (element) => {
            return (
              element.type === 'DIRECTORY' && element._id === rowData._id
            );
          }
        );
        if (moveToSelf) return;
        setParent(rowData as Identificable);
      },
      onContextMenu: ({ event }: HandlerProps) => {
        event.persist();
        event.preventDefault();
        event.stopPropagation();
        handleContextMenu(event);
      },
    };
  }, [
    rowHandleTouchStart,
    rowHandleTouchMove,
    rowHandleTouchEnd,
    selectElements,
    rows,
    changeGallery,
    setParent,
    handleContextMenu,
    openElement,
    selectedFilesStore,
  ]);

  function toReactColumn(column: ColumnShape): ReactElement {
    return <Column {...column} />;
  }

  const reactColumns = columns.map(toReactColumn);
  const isEmpty = searchPhrase
    ? !searchedDirectories.length && !searchedDocumentations.length
    : !documentations.length && !directories.length;

  const rowClassName = useCallback(
    ({ rowData }: { rowData: DocumentationOnView }) => {
      return selected.has(rowData) ? 'selected' : '';
    },
    [selected]
  );

  const rowProps = useCallback(
    (a) => {
      a.rowData.selectedFilesStore = selectedFilesStore;
      return a;
    },
    [selectedFilesStore]
  );

  if (searchPhrase && isLoadingSearch) {
    return <TableSkeleton />;
  }

  if (isLoadingDocumentations || isLoadingDirectories) {
    return <TableSkeleton />;
  }

  return (
    <>
      {isEmpty ? (
        <div style={{ width: '100%', height: '100%' }}>
          <DocumentationEmpty searchPhrase={searchPhrase} />
        </div>
      ) : (
        <>
          <BaseTable
            fixed
            rowKey='uniqueKeyId'
            width={width}
            height={height}
            data={rows}
            className={classes.table}
            rowEventHandlers={rowEventHandlers}
            onColumnResizeEnd={saveColumnWidth}
            rowClassName={rowClassName}
            rowRenderer={rowRenderer}
            rowProps={rowProps}
          >
            {reactColumns}
          </BaseTable>

          <Gallery {...GalleryController} />
          <DocumentationGallerySidebar />
          <DocumentationContextMenu
            contextMenu={contextMenu}
            handleClose={handleClose}
            editFile={openEditComponent}
            editDirectory={openDirectoryForm}
          />
        </>
      )}

      <div style={{ width: 0, height: 0 }}>
        <input
          ref={inputRef}
          style={{ width: 0, height: 0 }}
          type='file'
          onChange={(e: ChangeEvent<HTMLInputElement>): void => {
            if (e.target.files) {
              addDocumentationsFromFiles(Object.values(e.target.files));
            }
            e.target.value = '';
          }}
          multiple
        />
      </div>
    </>
  );
}

export const DocumentationTable = withIssueForm(
  withSites(
    withLevels(
      withIssueChannelListener(
        withCompanies(
          withDocumentationForm(
            withWorktypes(
              withHazardCategories(
                withEnvironmentalAspects(
                  withCorrectiveActionTypes(
                    withInspectionTemplates(
                      withInspections(
                        withStoreDependencies(_DocumentationTable)
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);

function allowDragOver(e: any): void {
  e.preventDefault();
}
function rowRenderer(props: any): ReactElement {
  const { cells, rowData } = props;
  const selectedFilesStore = rowData.selectedFilesStore;
  const moveToSelf = Array.from(
    (selectedFilesStore?.get() as Set<DocumentationOnView>) || []
  ).find((element) => {
    return element.type === 'DIRECTORY' && element._id === rowData._id;
  });

  const onDragOver =
    rowData.type === 'DIRECTORY' && !moveToSelf
      ? allowDragOver
      : undefined;

  return (
    <div onDragOver={onDragOver} draggable style={{ display: 'flex' }}>
      {cells}
    </div>
  );
}

function DocumentationGallerySidebar(): ReactElement {
  const { filtersVisibleStore } = useIssueOverlayControls();
  const [sidebarOpen, setSidebarOpen] = useState(
    filtersVisibleStore.get()
  );
  useEffect(() => {
    const action = (): void => setSidebarOpen(filtersVisibleStore.get());
    action();
    return filtersVisibleStore.subscribe(action);
  });

  const toggleSidebar = useCallback(() => {
    filtersVisibleStore.set(!filtersVisibleStore.get());
  }, [filtersVisibleStore]);

  return (
    <FilterSidebar
      sidebarOpen={sidebarOpen}
      toggleSidebar={toggleSidebar}
    />
  );
}
