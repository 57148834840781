import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import {
  IDocumentationMoveUploader,
  UploadDocumentationMove,
} from 'serviceWorker/repository/documentation/uploadDocumentationMove';
import { Identificable } from 'shared/types/commonView';
import { IRequestRunner } from './httpRequestRunner';

export class DocumentationMoveRequestRunner implements IRequestRunner {
  constructor(
    private documentationMoveUploader: IDocumentationMoveUploader,
    private uploadDocumentationMoveUseCase: UploadDocumentationMove,
    private getDocumentations: (
      ids: number[]
    ) => Promise<Partial<Identificable>[]>,
    private getDirectories: (
      ids: number[]
    ) => Promise<Partial<Identificable>[]>
  ) {}

  execute(request: __HttpRequestModel__): Promise<boolean> {
    switch (request.method) {
      case 'PUT': {
        return this.moveDocumentation(request);
      }
    }
    throw new Error('Not implemented.');
  }

  private async moveDocumentation(
    request: __HttpRequestModel__
  ): Promise<boolean> {
    const parentId = request.data.parentId;
    const [documentations, directories] = await Promise.all([
      this.getDocumentations(request.data.documentations),
      this.getDirectories(request.data.directories),
    ]);

    const documentationBaseUrl = `/project/${request.data.projectId}/documentation`;
    const directoryBaseUrl = `/project/${request.data.projectId}/dir`;

    try {
      if (!documentations.every((doc) => doc._id)) {
        throw new Error('Cannot move unuploaded documentation.');
      }
      await Promise.all([
        ...documentations.map((doc) => {
          return this.uploadDocumentationMoveUseCase(
            this.documentationMoveUploader,
            `${documentationBaseUrl}/${doc._id}`,
            parentId
          );
        }),

        ...directories.map((dir) => {
          return this.uploadDocumentationMoveUseCase(
            this.documentationMoveUploader,
            `${directoryBaseUrl}/${dir._id}`,
            parentId
          );
        }),
      ]);

      return true;
    } catch (e) {
      return false;
    }
  }
}
