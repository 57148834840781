import { ReactElement, useCallback } from 'react';

import Switch from '@mui/material/Switch';
import { useFieldValuesForm } from 'components/dataCreationForms/withFieldValuesForm';

export function FieldValuesSwitch({
  checked,
  _id,
  disabled,
}: {
  _id: string | number;
  checked: boolean;
  disabled: boolean;
}): ReactElement {
  const { fieldChanges, enableValue, disableValue } = useFieldValuesForm();
  const field =
    typeof _id === 'string'
      ? fieldChanges.find((field) => field._id === _id)
      : undefined;
  const isChecked = field
    ? Boolean(field.processes.length)
    : Boolean(checked);

  const onChange = useCallback(
    (_: any, value: boolean) => {
      if (value) {
        enableValue(_id);
      } else {
        disableValue(_id);
      }
    },
    [enableValue, disableValue, _id]
  );

  return (
    <Switch
      checked={isChecked}
      disabled={disabled}
      color='primary'
      onChange={onChange}
    />
  );
}
