import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debuglog } from 'util';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { makeUseGet } from './makeUseGet';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';

export const useGetDocumentation = makeUseGet<DocumentationModel, string>({
  listenMessageChannel: ChannelNames.documentationChannel,
  isMatchingMessage,
  postMessage: broadcastGetDocumentation,
  entityName: 'documentation',
});

function broadcastGetDocumentation(id: string): void {
  debuglog(`send broadcastGetDocumentation ${id}`);
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  broadcast.postMessage({
    type: DomainMessagesTypes.getDocumentation,
    data: {
      id,
    },
    uniqueId: id,
  });
  broadcast.close();
}

function isMatchingMessage(id: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.documentation &&
    Boolean(event.data) &&
    event.data._id === id
  );
}
