import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
import useIsMobile from '../../../../hooks/useIsMobile';

const _useStyles = makeStyles<DefaultTheme, { isMobile: boolean }>(() =>
  createStyles({
    root: {
      boxSizing: 'border-box',
      margin: ({ isMobile }): string =>
        isMobile ? '16px' : '54px 0 16px 54px',
      maxWidth: ({ isMobile }): string => (isMobile ? '100%' : '854px'),
    },
    fullWidth: {
      width: '100%',
    },
    summaryMargin: { marginBottom: '27px' },
    // multiselect: {
    //   boxSizing: 'border-box',
    //   marginLeft: '16px',
    //   marginRight: '16px',
    //   width: '100%',
    //   '& .bright:not(.error)': {
    //     borderColor: '#C2C2C2',
    //   },
    //   '& .bright:hover:not(.error)': {
    //     borderColor: '#000000',
    //   },
    // },
    formControl: {
      minWidth: '220px',
    },
    castShadow: {
      boxShadow: '0px -4px 4px -2px rgba(0, 0, 0, 0.15)',
    },
    submitButtons: {
      display: 'flex',
      flexDirection: 'row',
      columnGap: '16px',
    },
    submitButtonSpacing: { marginRight: '19px' },
    submitFooter: {
      position: 'fixed',
      bottom: '0',
      // navigation has 64px
      width: ({ isMobile }): string =>
        isMobile ? '100%' : 'calc(100% - 64px)',
      '& > div': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      backgroundColor: '#FFF',
      zIndex: 1,
    },
    footerSpace: {
      width: '100%',
      height: '120px',
    },
    footerPadding: {
      paddingTop: '11px',
      paddingBottom: '12px',
      paddingRight: '29px',
      paddingLeft: '16px',
    },
  })
);

export const useStyles = (): ReturnType<typeof _useStyles> => {
  const isMobile = useIsMobile();
  return _useStyles({ isMobile });
};
