import { IDocumentationUploader } from './uploadDocumentation';

type Changes = { description: string };

export interface UploadDocumentationChange {
  (
    uploader: IDocumentationUploader,
    url: string,
    data: Changes
  ): Promise<boolean>;
}

export const uploadDocumentationChange: UploadDocumentationChange =
  async function _uploadDocumentationChange(
    uploader: IDocumentationUploader,
    url: string,
    data: Changes
  ): Promise<boolean> {
    try {
      await uploader.PUT(url, data);
      return true;
    } catch (e) {
      return false;
    }
  };
