import { CompanyModel } from 'shared/domain/company/types/model';
import { DateTime } from 'luxon';
import { CreatableOnView } from 'shared/types/commonView';

export const makeDateAccessor = (
  dateValue: DateTime<true> | null
): number | null => {
  return dateValue?.toMillis() || null;
};
export const accessorCreationDate = (source: {
  createdAt: DateTime;
}): number | null => makeDateAccessor(source.createdAt);

export const accessorExecutionDate = (source: {
  inspectionDate: DateTime<true>;
}): number | null => makeDateAccessor(source.inspectionDate);

export const accessorModificationDate = (source: {
  modifiedAt: DateTime;
}): number | null => makeDateAccessor(source.modifiedAt);
export const createdByAccessor = (
  source: CreatableOnView
): string | null => source.createdBy?.label ?? null;

export const correspondenceAddressStreetAndNumberAccessor = (
  entity: CompanyModel
): string | null => entity.correspondenceAddress?.streetAndNumber ?? null;
export const correspondenceAddressCountryAccessor = (
  entity: CompanyModel
): string | null => entity.correspondenceAddress?.country ?? null;

export const correspondenceAddressPostalCodeAccessor = (
  entity: CompanyModel
): string | null => entity.correspondenceAddress?.postalCode ?? null;

export const registeredAddressStreetAndNumberAccessor = (
  entity: CompanyModel
): string | null =>
  entity.registeredOfficeAddress?.streetAndNumber ?? null;

export const registeredAddressPostalCodeAccessor = (
  entity: CompanyModel
): string | null => entity.registeredOfficeAddress?.postalCode ?? null;

export const registeredAddressCountryAccessor = (
  entity: CompanyModel
): string | null => entity.registeredOfficeAddress?.country ?? null;
