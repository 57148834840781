import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import {
  CreateFieldValueModel,
  EditFieldValueModel,
} from 'shared/domain/fieldValue/fieldValueModel';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  CreateFieldValuesCustomEventDetail,
  EditFieldValuesCustomEventDetail,
} from 'serviceWorker/repository/fieldValue/eventMessages';
import { debugLog } from 'shared/logger/debugLog';

const create = (e: {
  fieldName: EditableFieldValuesEntityName;
  fieldValues: CreateFieldValueModel[];
  uniqueId: string;
}): void => {
  debugLog('createFieldValues event', e);
  self.dispatchEvent(
    new CustomEvent<CreateFieldValuesCustomEventDetail>(
      RepositoryMessagesTypes.createFieldValues,
      {
        detail: { ...e },
      }
    )
  );
};

const edit = (e: {
  fieldName: EditableFieldValuesEntityName;
  fieldValues: EditFieldValueModel[];
  uniqueId: string;
}): void => {
  debugLog('editFieldValues event', e);
  self.dispatchEvent(
    new CustomEvent<EditFieldValuesCustomEventDetail>(
      RepositoryMessagesTypes.editFieldValues,
      {
        detail: { ...e },
      }
    )
  );
};

export default { create, edit };
