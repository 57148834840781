import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { CreateReportCustomEventDetail } from 'shared/domain/messages/report/eventMessages';
import { GetReportSignedRequestModel } from 'shared/domain/report/getReportModel';
import { IssueListReportModel } from 'shared/domain/report/reportModel';
import { SingleIssueReportModel } from 'shared/domain/report/singleIssueReportModel';
import { debugLog } from 'shared/logger/debugLog';
import { GetReportSignedRequestEventDetail } from './types';

const getOne = (data: GetReportSignedRequestModel): void => {
  debugLog('receivedGetSignedRequest', data);
  self.dispatchEvent(
    new CustomEvent<GetReportSignedRequestEventDetail>(
      RepositoryMessagesTypes.getReportSignedRequest,
      {
        detail: data,
      }
    )
  );
};

const create = (
  data: (IssueListReportModel | SingleIssueReportModel) & {
    uniqueId: string;
  }
): void => {
  debugLog('CREATE REPORT EVENT', data);
  self.dispatchEvent(
    new CustomEvent<CreateReportCustomEventDetail>(
      RepositoryMessagesTypes.addReport,
      {
        detail: data,
      }
    )
  );
};

export default { getOne, create };
