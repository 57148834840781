import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { DocumentationCreateModel } from './documentationModel';

export function startDocumentationCreate(
  documentation: DocumentationCreateModel,
  uniqueId: string
): void {
  debugLog('startDocumentationCreate', documentation, uniqueId);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTATIONS,
    method: ServiceMethods.CREATE,
    data: {
      documentation,
      uniqueId,
    },
  });
  broadcast.close();
}
