import * as actions from '../actionTypes';
import { AnyAction } from 'redux';
import { ProjectInfo } from '../../setup/types/core';
import { ISO6391LanguageCode } from 'shared/types/locale';

function sortFields(
  data: Partial<ProjectInfo>,
  currentState: ProjectInfo
): Partial<ProjectInfo> {
  return {
    ...data,
    processes: data.processes || currentState.processes,
  };
}

export const initialState: ProjectInfo = {
  _id: '',
  organizationId: '',
  code: '',
  name: '',
  processes: [],
  error: null,
  userHasAccess: false,
  timezone: '',
  budget: 0,
  currency: '',
  projectStartDate: null,
  projectEndDate: null,
  localeCode: ISO6391LanguageCode.en,
  pdfContactFooter: undefined,
  pdfFooter: undefined,
  pdfHeader: undefined,
};

const projectDataReducer = (
  state = initialState,
  action: AnyAction
): ProjectInfo => {
  switch (action.type) {
    case actions.FETCH_PROJECT_SUCCESS:
    case actions.FETCH_PROCESS_LIST_SUCCESS:
      const payloadSorted = sortFields(action.payload, state);
      return {
        ...state,
        ...payloadSorted,
      };
    case actions.FETCH_PROJECT_FAILURE:
      return {
        ...state,
        error: { message: action.error },
      };
    default:
      return state;
  }
};

export default projectDataReducer;
