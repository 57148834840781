import { EditableFieldValuesEntityName } from 'shared/domain/fieldValue/fields';
import { FieldValueOnView } from 'presentation/fieldValue/fieldValueOnView';
import React, { FC, ReactElement, ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useCustomizedFields,
  withCustomizedFields,
} from '../withCustomizedFields';

export type FieldValueContext = {
  allItems: FieldValueOnView[];
  loading: boolean;
  resync: (fieldName: EditableFieldValuesEntityName) => void;
};
type CustomizedFieldContextType = {
  useContext: () => FieldValueContext;
  Provider: FC<unknown>;
  fieldName: EditableFieldValuesEntityName;
  fieldLabelId: string;
};

const CustomizedFieldContext = React.createContext<
  CustomizedFieldContextType | undefined
>(undefined);

const WithCustomizedField: FC<{
  children?: ReactNode;
  customizedFieldName?: EditableFieldValuesEntityName;
}> = withCustomizedFields(({ customizedFieldName, children }) => {
  let customizedField: EditableFieldValuesEntityName;
  if (customizedFieldName) {
    customizedField = customizedFieldName;
  } else {
    const params = useParams<{
      customizedField: string;
    }>();
    // TODO MUI5
    customizedField =
      params.customizedField as unknown as EditableFieldValuesEntityName;
  }

  const customizedFields = useCustomizedFields();

  const ctx: CustomizedFieldContextType = useMemo(
    () => ({
      useContext: customizedFields[customizedField].useContext,
      Provider: customizedFields[customizedField].Provider,
      fieldName: customizedFields[customizedField].fieldName,
      fieldLabelId: customizedFields[customizedField].fieldLabelId,
    }),
    [customizedFields, customizedField]
  );

  return (
    <CustomizedFieldContext.Provider value={ctx}>
      {children}
    </CustomizedFieldContext.Provider>
  );
});

const withCustomizedField =
  (
    Component: React.ComponentType<any>,
    customizedFieldName?: EditableFieldValuesEntityName
  ) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithCustomizedField customizedFieldName={customizedFieldName}>
      <Component {...props} />
    </WithCustomizedField>
  );

function useCustomizedField(): CustomizedFieldContextType {
  const context = React.useContext(CustomizedFieldContext);
  if (context === undefined) {
    throw new Error(
      'useCustomizedField must be used within a CustomizedFieldContextProvider'
    );
  }
  return context;
}

export { WithCustomizedField, withCustomizedField, useCustomizedField };
