import { BroadcastChannel } from 'broadcast-channel';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';

export function startDocumentationDelete(
  documentations: number[],
  directories: number[]
): void {
  debugLog('startDocumentationDelete', documentations, directories);
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.DOCUMENTATIONS,
    method: ServiceMethods.REMOVE,
    data: {
      documentations,
      directories,
    },
  });
  broadcast.close();
}
