import React, { CSSProperties, memo, ReactElement } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles(() =>
  createStyles({
    chip: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '2px 16px 2px 16px',
      border: '1px solid',
      boxSizing: 'border-box',
      borderRadius: '25px',
      height: '46px',
      alignSelf: 'flex-start',
      marginBottom: '24px',
      '& span': {
        textTransform: 'lowercase',
      },
    },
  })
);

type BorderedChipProps = {
  text: string;
  icon?: ReactElement;
  color?: string;
  style?: CSSProperties;
  className?: string;
};

const BorderedChip: React.FC<BorderedChipProps> = ({
  text,
  icon,
  color,
  className,
  style,
}) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.chip, className)}
      style={{
        borderColor: color,
        color: color,
        ...style,
      }}
    >
      {icon}
      <span>{text}</span>
    </div>
  );
};

export default memo(BorderedChip);
