import { init as initIssues } from './issues';

import { init as initApi } from 'serviceWorker/api';
import broadcasts from 'serviceWorker/broadcasts';
import { init as initDbChangesListener } from 'serviceWorker/services/dbChangeListener';
import { init as initIssueFormsListener } from 'serviceWorker/services/issueForm';
import { init as initVisibleFieldsListener } from 'serviceWorker/services/visibleFields';
import { init as initAnalytics } from './analytics';
import { init as initChartFilters } from './chartFilters';
import { init as initCompanies } from './companies';
import { init as initContracts } from './contracts';
import { init as initCorrectiveActionTypes } from './correctiveActionTypes';
import { init as initDataClearer } from './dataClearer';
import { init as initDirectories } from './directories';
import { init as initDocumentations } from './documentations';
import { init as initDocuments } from './documents';
import { init as initEnvironmentalAspects } from './environmentalAspects';
import { init as initInterceptor } from './fetchInterceptor';
import { init as initFieldValues } from './fieldValues';
import { init as initHazardCategories } from './hazardCategories';
import { init as initHttpQueue } from './httpQueue';
import { init as initInspectionTemplates } from './inspectionTemplates';
import { init as initInspections } from './inspections';
import { init as initLevels } from './levels';
import { init as initOrganizations } from './organizations';
import { init as initPermissions } from './permissions';
import { init as initProjects } from './projects';
import { init as initReports } from './reports';
import { init as initSelectedProject } from './selectedProject';
import { init as initSites } from './sites';
import { init as initUsers } from './users';
import { init as initWorktypes } from './worktypes';

import * as usersService from 'serviceWorker/db/usersService';
import { clear as usersClear } from '../db/users';
import { clear as usersServiceClear } from '../db/usersService';

import * as issuesService from 'serviceWorker/db/issuesService';
import { clear as issuesClear } from '../db/issues';
import { clear as issuesServiceClear } from '../db/issuesService';

import * as companiesService from 'serviceWorker/db/companiesService';
import { clear as companiesClear } from '../db/companies';
import { clear as companiesServiceClear } from '../db/companiesService';

import * as contractsService from 'serviceWorker/db/contractsService';
import { clear as contractsClear } from '../db/contracts';
import { clear as contractsServiceClear } from '../db/contractsService';

import * as projectsService from 'serviceWorker/db/projectsService';
import { clear as projectsClear } from '../db/projects';
import { clear as projectsServiceClear } from '../db/projectsService';

import * as organizationsService from 'serviceWorker/db/organizationsService';
import { clear as organizationsClear } from '../db/organizations';
import { clear as organizationsServiceClear } from '../db/organizationsService';

import * as inspectionTemplatesService from 'serviceWorker/db/inspectionTemplatesService';
import { clear as inspectionTemplatesClear } from '../db/inspectionTemplates';
import { clear as inspectionTemplatesServiceClear } from '../db/inspectionTemplatesService';

import * as inspectionsService from 'serviceWorker/db/inspectionsService';
import { clear as inspectionsClear } from '../db/inspections';
import { clear as inspectionsServiceClear } from '../db/inspectionsService';

import * as sitesService from 'serviceWorker/db/sitesService';
import { clear as sitesClear } from '../db/sites';
import { clear as sitesServiceClear } from '../db/sitesService';

import * as worktypesService from 'serviceWorker/db/worktypesService';
import { clear as worktypesClear } from '../db/worktypes';
import { clear as worktypesServiceClear } from '../db/worktypesService';

import * as hazardCategoriesService from 'serviceWorker/db/hazardCategoriesService';
import { clear as hazardCategoriesClear } from '../db/hazardCategories';
import { clear as hazardCategoriesServiceClear } from '../db/hazardCategoriesService';

import * as environmentalAspectsService from 'serviceWorker/db/environmentalAspectsService';
import { clear as environmentalAspectsClear } from '../db/environmentalAspects';
import { clear as environmentalAspectsServiceClear } from '../db/environmentalAspectsService';

import * as correctiveActionTypesService from 'serviceWorker/db/correctiveActionTypesService';
import {
  clear as correctiveActionTypesClear,
  clear as correctiveActionTypesServiceClear,
} from '../db/correctiveActionTypes';

import * as levelsService from 'serviceWorker/db/levelsService';
import { clear as levelsClear } from '../db/levels';
import { clear as levelsServiceClear } from '../db/levelsService';

import * as documentationsService from 'serviceWorker/db/documentationsService';
import { clear as documentationsClear } from '../db/documentations';
import { clear as documentationsServiceClear } from '../db/documentationsService';

import * as directoriesService from 'serviceWorker/db/directoriesService';
import { clear as directoriesClear } from '../db/directories';
import { clear as directoriesServiceClear } from '../db/directoriesService';

import * as issueFormsService from 'serviceWorker/db/issueFormService';
import { clear as issueFormsClear } from '../db/issueForm';
import { clear as issueFormsServiceClear } from '../db/issueFormService';

import * as visibleFieldsService from 'serviceWorker/db/visibleFieldsService';
import { clear as visibleFieldsClear } from '../db/visibleFields';
import { clear as visibleFieldsServiceClear } from '../db/visibleFieldsService';
import { InitAll } from './factories/types';

export const initAllServices: InitAll = (self) => {
  Promise.all([
    issuesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(issuesServiceClear());
        promises.push(issuesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    companiesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(companiesServiceClear());
        promises.push(companiesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    contractsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(contractsServiceClear());
        promises.push(contractsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    usersService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(usersServiceClear());
        promises.push(usersClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    projectsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(projectsServiceClear());
        promises.push(projectsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    organizationsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(organizationsServiceClear());
        promises.push(organizationsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    inspectionTemplatesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(inspectionTemplatesServiceClear());
        promises.push(inspectionTemplatesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    inspectionsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(inspectionsServiceClear());
        promises.push(inspectionsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    sitesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(sitesServiceClear());
        promises.push(sitesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    worktypesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(worktypesServiceClear());
        promises.push(worktypesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    hazardCategoriesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(hazardCategoriesServiceClear());
        promises.push(hazardCategoriesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    environmentalAspectsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(environmentalAspectsServiceClear());
        promises.push(environmentalAspectsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    correctiveActionTypesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(correctiveActionTypesServiceClear());
        promises.push(correctiveActionTypesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    levelsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(levelsServiceClear());
        promises.push(levelsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    documentationsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(documentationsServiceClear());
        promises.push(documentationsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    directoriesService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(directoriesServiceClear());
        promises.push(directoriesClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    issueFormsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(issueFormsServiceClear());
        promises.push(issueFormsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
    visibleFieldsService.get().then((data): Promise<any> => {
      const promises: Promise<void>[] = [];
      if (data?.isDownloading) {
        promises.push(visibleFieldsServiceClear());
        promises.push(visibleFieldsClear());
      }
      return promises.length ? Promise.all(promises) : Promise.resolve();
    }),
  ]).then(() => {
    [
      initDataClearer,
      initDocuments,
      initIssues,
      initCompanies,
      initContracts,
      initUsers,
      initPermissions,
      initAnalytics,
      initChartFilters,
      initProjects,
      initOrganizations,
      initInspectionTemplates,
      initInspections,
      initSelectedProject,
      initApi,
      initInterceptor,
      initSites,
      initLevels,
      initReports,
      initWorktypes,
      initHazardCategories,
      initEnvironmentalAspects,
      initCorrectiveActionTypes,
      initFieldValues,
      initDocumentations,
      initDirectories,
      initIssueFormsListener,
      initVisibleFieldsListener,
      initDbChangesListener,
      broadcasts,
      initHttpQueue,
    ].forEach((init) => {
      init();
    });
  });
};
