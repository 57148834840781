import { useEnvironmentalAspectChannelListener } from 'components/broadcastChannelListeners/channelListener/environmentalAspectChannelListener';
import { EnvironmentalAspectModel } from 'shared/domain/environmentalAspect/environmentalAspectModel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) &&
    event.type === DomainMessagesTypes.allEnvironmentalAspects
  );
}

export const useGetAllEnvironmentalAspects =
  makeUseGetAll<EnvironmentalAspectModel>({
    useChannelListener: useEnvironmentalAspectChannelListener,
    postMessageChannel: ChannelNames.environmentalAspectChannel,
    postMessageType: DomainMessagesTypes.getAllEnvironmentalAspects,
    isMatchingMessage,
    entityName: 'environmentalAspects',
  });
