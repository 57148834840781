import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  CreateFieldValuesCustomEvent,
  EditFieldValuesCustomEvent,
} from 'serviceWorker/repository/fieldValue/eventMessages';
import { debugLog } from 'shared/logger/debugLog';
import { fieldValuesCreator } from './createFieldValues';
import { fieldValuesChanger } from './editFieldValues';

export const init = (): void => {
  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.createFieldValues,
    async function onCreateFieldValues(
      e: CreateFieldValuesCustomEvent
    ): Promise<void> {
      debugLog('createFieldValues event', e);
      fieldValuesCreator.execute(
        e.detail.fieldName,
        e.detail.fieldValues,
        e.detail.uniqueId
      );
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editFieldValues,
    function onEditFieldValues(
      e: EditFieldValuesCustomEvent
    ): Promise<void> {
      debugLog('editFieldValues event', e);
      return fieldValuesChanger.execute(
        e.detail.fieldName,
        e.detail.fieldValues,
        e.detail.uniqueId
      );
    }
  );
};
