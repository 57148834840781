import { IconsProp } from '../../setup/types/core';
import { createReducer } from '../helpers';
import * as actions from '../actionTypes';

const initialState: IconsProp = {
  loading: false,
  error: null,
  icons: {
    document: {
      form: '',
      files: { blank: '' },
    },
    process: {
      ncr: '',
    },
  },
};

const updateState = (state: any, stateSlice: any): IconsProp => ({
  ...state,
  ...stateSlice,
});

const fetchIconsBegin = (state: any): IconsProp =>
  updateState(state, { loading: true });

const fetchIconsSuccess = (state: any, action: any): IconsProp =>
  updateState(state, {
    loading: false,
    error: null,
    icons: action.payload.icons,
  });

const fetchIconsFailure = (state: any, action: any): IconsProp =>
  updateState(state, {
    loading: false,
    error: action.payload.error,
    icons: null,
  });

const iconsReducer = createReducer(initialState, {
  [actions.FETCH_ICONS_BEGIN]: fetchIconsBegin,
  [actions.FETCH_ICONS_SUCCESS]: fetchIconsSuccess,
  [actions.FETCH_ICONS_FAILURE]: fetchIconsFailure,
});

export default iconsReducer;
