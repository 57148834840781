import { PropsWithChildren, ReactElement, useEffect } from 'react';
import { Loader } from '../index';
import { debugLog } from 'shared/logger/debugLog';
import { logInfo } from 'setup/logger/logInfo';

type SpinnerType = {
  withBackDrop?: boolean;
  height?: string;
  position?: 'static' | 'absolute' | 'relative';
  reason: string;
};

const styles = {
  spinner: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
};

const Spinner = ({
  withBackDrop,
  height,
  position,
  reason,
}: SpinnerType): JSX.Element => {
  useEffect(() => {
    if (!reason) return;
    debugLog(`SPINNER reason: ${reason}`);
    const t = setTimeout(() => {
      debugLog(`SPINNER OVER 3 seconds reason: ${reason}`);
      logInfo(`SPINNER OVER 3 seconds reason: ${reason}`);
    }, 3000);

    return () => {
      clearTimeout(t);
    };
  }, [reason]);

  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          ...styles.spinner,
          height: height || styles.spinner.height,
          position: position || styles.spinner.position,
          ...(withBackDrop && {
            backgroundColor: 'rgba(0,0,0,.54)',
          }),
        }}
      >
        <Loader />
      </div>
    </div>
  );
};

export function WithLoadingLog({
  children,
  reason,
}: PropsWithChildren<{ reason: string }>): ReactElement {
  useEffect(() => {
    if (!reason) return;
    debugLog(`SPINNER reason: ${reason}`);
    const t = setTimeout(() => {
      debugLog(`SPINNER OVER 3 seconds reason: ${reason}`);
      logInfo(`SPINNER OVER 3 seconds reason: ${reason}`);
    }, 3000);

    return () => {
      clearTimeout(t);
    };
  }, [reason]);

  return <>{children}</>;
}

export default Spinner;
