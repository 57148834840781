import { ContractEntity, ContractPartyEntity } from './entity';
import { toLabelledUser } from 'shared/domain/user/toLabelledUser';
import { ContractInDto } from 'shared/dtos/in/contract';
import { UserEntity } from 'shared/domain/user/types/entity';
import {
  ContractPartyModel,
  ContractModel,
} from 'shared/domain/contract/types/model';
import { CompanyEntity } from 'shared/domain/company/types/entity';

function contractPartyEntityToContractPartyModel(
  contractParty: ContractPartyEntity,
  companies: CompanyEntity[]
): ContractPartyModel {
  const company = companies.find(
    (company) => company._id === contractParty.companyId
  );
  const {
    correspondenceAddress,
    overrideCompanyCorrespondenceAddress,
    companyId,
    signingDate,
    contactPerson,
  } = contractParty;
  return {
    correspondenceAddress,
    overrideCompanyCorrespondenceAddress,
    companyId,
    signingDate,
    contactPerson,
    companyShortLabel: company?.shortLabel || '',
    companyLongLabel: company?.longLabel || '',
  };
}

export function contractEntityToModel(
  contractEntity: ContractEntity,
  companies: CompanyEntity[],
  users: UserEntity[]
): ContractModel {
  const {
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    deleted,
    _id,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
  } = contractEntity;
  return {
    createdAt,
    createdBy: toLabelledUser(
      users.find((user) => user._id === createdBy)
    ),
    modifiedAt,
    modifiedBy: toLabelledUser(
      users.find((user) => user._id === modifiedBy)
    ),

    deleted: Boolean(deleted),
    _id,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
    parties: contractEntity.parties.map((contractParty) =>
      contractPartyEntityToContractPartyModel(contractParty, companies)
    ),
  };
}

export function contractInDtoToEntity(
  inDto: ContractInDto
): ContractEntity {
  const {
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    deleted,
    _id,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
  } = inDto;

  return {
    createdAt,
    createdBy,
    modifiedAt,
    modifiedBy,
    deleted: deleted ? 1 : 0,
    _id,
    label,
    term,
    monetaryValue,
    timeToRespondToIssue,
    timeToResolveIssue,
    parties: inDto.parties,
  };
}
