import {
  FormFieldProps,
  MultiChoiceFormFieldProps,
} from 'components/inspection/Form/types';
import { toLabelledInvolvementType } from 'shared/domain/company/mapping';
import {
  InvolvementType,
  involvementTypes,
} from 'shared/domain/company/types/model';
import { CompanyOnView } from 'shared/domain/company/types/view';
import { EditableStandardEntityName } from 'shared/domain/fieldValue/fields';
import React, { memo, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';
import { CompanyFieldsPresentational } from './presentational';
import { CompanyFormProps } from './types';

function CompanyForm({
  disableInvolvement,
  sites,
  isDialog,
  initialValues,
  type,
}: CompanyFormProps & { type: 'short' | 'long' }): React.ReactElement {
  const intl = useIntl();
  const isLongForm = type === 'long';

  const shortLabelFieldProps: FormFieldProps<CompanyOnView> = {
    formKey: 'shortLabel',
    required: true,
    labelId: 'company_short_label_field_label',
    fieldName: 'text-field-shortLabel',
    localStorageKey: 'company-short-label',
    reserveSpaceForHelperText: !isDialog,
    dense: true,
    'data-qa': 'company_short_label_field',
  };

  const longLabelFieldProps: FormFieldProps<CompanyOnView> & {
    defaultValue?: string;
    value?: string;
  } = {
    formKey: 'longLabel',
    required: true,
    labelId: 'company_long_label_field_label',
    fieldName: 'text-field-longLabel',
    localStorageKey: 'company-long-label',
    reserveSpaceForHelperText: !isDialog,
    dense: true,
    'data-qa': 'company_long_label_field',
  };

  const taxNumberFieldProps: FormFieldProps<CompanyOnView> = {
    formKey: 'taxNumber',
    required: false,
    labelId: 'company_tax_number_field_label',
    fieldName: 'text-field-taxNumber',
    localStorageKey: 'company-tax-number',
    reserveSpaceForHelperText: !isDialog,
    dense: true,
    'data-qa': 'company_tax_number_field',
  };
  const sitesFieldProps: MultiChoiceFormFieldProps<CompanyOnView> = {
    formKey: 'sites',
    required: true,
    labelId: 'filters_filter_type_site',
    fieldName: 'multiselect-sites',
    available: sites,
    entityName: EditableStandardEntityName.location,
    reserveSpaceForHelperText: !isDialog,
    dense: true,
    'data-qa': 'company_sites',
  };

  const projectInvolvementTypeFieldProps: FormFieldProps<CompanyOnView> & {
    value?: {
      _id: InvolvementType;
      label: string;
    };
  } = useMemo(() => {
    const available = involvementTypes.map((value) =>
      toLabelledInvolvementType(value, intl)
    );
    return {
      formKey: 'projectInvolvementType',
      required: true,
      labelId: 'company_involvement_type_field_label',
      fieldName: 'singleselect-projectInvolvementType',
      value: initialValues.projectInvolvementType,
      disabled: disableInvolvement,
      available,
      getOptionLabel: (option: LabelledEntity): string =>
        intl.formatMessage({ id: option.label }),
      reserveSpaceForHelperText: !isDialog,
      dense: true,
      'data-qa': 'company_involvement_type_field',
    };
  }, [
    intl,
    disableInvolvement,
    initialValues.projectInvolvementType,
    isDialog,
  ]);

  return (
    <CompanyFieldsPresentational
      shortLabelFieldProps={shortLabelFieldProps}
      longLabelFieldProps={longLabelFieldProps}
      sitesFieldProps={sitesFieldProps}
      projectInvolvementTypeFieldProps={
        isLongForm ? projectInvolvementTypeFieldProps : undefined
      }
      taxNumberFieldProps={isLongForm ? taxNumberFieldProps : undefined}
    />
  );
}

function ShortCompanyForm(props: CompanyFormProps): React.ReactElement {
  return <CompanyForm {...props} type='short' />;
}

function LongCompanyForm(props: CompanyFormProps): React.ReactElement {
  return <CompanyForm {...props} type='long' />;
}

export const MemoLongCompanyForm = memo(LongCompanyForm);
export const MemoShortCompanyForm = memo(ShortCompanyForm);
