import { ReactElement, useEffect } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { projectDataSelector } from 'redux/selectors/project';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

export function ProjectLoading(): ReactElement {
  const { name } = useSelector(projectDataSelector);
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    return (): void => {
      if (history.location.pathname.includes('select-project')) {
        history.push('/issue');
      }
    };
  }, [history]);

  return (
    <div className={classes.root}>
      <div className={classes.loadingBox}>
        <div className={classes.projectBox}>
          <div className={classes.projectLabel}>
            {intl.formatMessage({ id: 'general_project' })}
          </div>
          <div className={classes.projectName}>{name}</div>
        </div>
        <div className={classes.loadingLabel}>
          {intl.formatMessage({ id: 'project_loading_data' })}
        </div>
        <Box className={classes.progressBox}>
          <LinearProgress />
        </Box>
      </div>
    </div>
  );
}
