import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { broadcastAllEnvironmentalAspects } from 'serviceWorker/broadcasts/environmentalAspects';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as environmentalAspects from 'serviceWorker/db/environmentalAspects';
import * as environmentalAspectsService from 'serviceWorker/db/environmentalAspectsService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullHandler } from '../factories/makePullHandler';
import { makePullEntity } from '../factories/makePullEntity';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
import {
  fetchEnvironmentalAspects,
  fetchUpdatedEnvironmentalAspects,
} from './fetchEnvironmentalAspects';

const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullEnvironmentalAspects: Pull = makePullEntity({
  fetch: fetchEnvironmentalAspects,
  channelName: ChannelNames.environmentalAspectChannel,
  entityName: 'environmentalAspects',
  entityRepository: environmentalAspects,
  entityService: environmentalAspectsService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullEnvironmentalAspectsHandler = makePullHandler(
  config,
  environmentalAspects,
  environmentalAspectsService,
  pullEnvironmentalAspects,
  clearBroadcasts
);

const synchronizeEnvironmentalAspects = makeSynchronizeEntity({
  configRepository: config,
  entityService: environmentalAspectsService,
  entityRepository: environmentalAspects,
  pullEntityHandler: pullEnvironmentalAspectsHandler,
  fetchUpdatedEntities: fetchUpdatedEnvironmentalAspects,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'environmentalAspects',
  channelName: ChannelNames.environmentalAspectChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeEnvironmentalAspects.bind(
      null,
      broadcastAllEnvironmentalAspects
    ),
    250
  );

  self.addEventListener(
    RepositoryMessagesTypes.syncEnvironmentalAspects,
    syncDebounced
  );
};
