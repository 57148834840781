import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HashMap } from 'shared/types/commonView';

const componentsInfo: HashMap<{
  redirectLabel: string;
  route?: string;
  redirectTo?: string;
}> = {
  issueTable: {
    route: '/issue',
    redirectLabel: '',
  },
  issueView: {
    redirectLabel: 'issue_navigation_back_to_list',
    redirectTo: '/issue',
  },
  issueCreate: {
    redirectLabel: 'issue_navigation_back_to_list',
    redirectTo: '/issue',
  },
  userEdit: {
    redirectLabel: 'issue_navigation_back_to_list',
    redirectTo: '/issue',
  },
  notFoundScreen: {
    redirectLabel: 'issue_navigation_back_to_list',
    redirectTo: '/issue',
  },
};

const initialState = {
  title: '',
  pathname: '',
  setRouteTitle: (title: any): void => {},
  setRouteFor: (component: string): void => {},
  goBack: (): void => {},
};

export const HeaderContext = React.createContext(initialState);

export const HeaderContextProvider = ({
  children,
}: any): React.ReactElement => {
  const [title, setTitle] = useState('');
  const [pathname, setPathname] = useState('');

  const history = useHistory();

  const setRouteTitle = useCallback(
    (newTitle: string): void => {
      if (title === newTitle) {
        return;
      }

      setTitle(newTitle);
    },
    [title]
  );

  const setRouteFor = useCallback(
    (component: string): void => {
      setRouteTitle(componentsInfo[component]?.redirectLabel ?? '');
      setPathname(componentsInfo[component].redirectTo ?? '');
    },
    [setRouteTitle]
  );

  const goBack = useCallback((): void => {
    history.goBack();
  }, [history]);

  return (
    // prettier-ignore
    <HeaderContext.Provider value={{
      title, setRouteTitle, setRouteFor, goBack, pathname
    }}>
        {children}
    </HeaderContext.Provider>
  );
};
