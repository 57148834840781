import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  UserCreateCustomEventDetail,
  UserDeleteCustomEventDetail,
  UserEditCustomEventDetail,
} from 'shared/domain/messages/user/eventMessages';
import { debounceNext } from 'serviceWorker/helpers/debounce';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';
import { GetUserEventDetail } from './types';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncUsers);

const create = (e: UserCreateCustomEventDetail): void => {
  debugLog('CREATE USER EVENT', e);
  self.dispatchEvent(
    new CustomEvent<UserCreateCustomEventDetail>(
      RepositoryMessagesTypes.addUser,
      {
        detail: {
          userCreateModel: e.userCreateModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

const edit = (e: UserEditCustomEventDetail): void => {
  debugLog('EDIT USER EVENT', e);
  self.dispatchEvent(
    new CustomEvent<UserEditCustomEventDetail>(
      RepositoryMessagesTypes.editUser,
      {
        detail: { userEditModel: e.userEditModel, uniqueId: e.uniqueId },
      }
    )
  );
};

const pull = debounceNext((): void => {
  self.dispatchEvent(new Event(RepositoryMessagesTypes.getAllUsers));
}, 1000);

const getOne = (e: any): void => {
  self.dispatchEvent(
    new CustomEvent<GetUserEventDetail>(RepositoryMessagesTypes.getUser, {
      detail: { id: e.id },
    })
  );
};

const remove = (e: UserDeleteCustomEventDetail): void => {
  debugLog('DELETE USER EVENT', e);
  self.dispatchEvent(
    new CustomEvent<UserDeleteCustomEventDetail>(
      RepositoryMessagesTypes.deleteUser,
      {
        detail: {
          userDeleteModel: e.userDeleteModel,
          uniqueId: e.uniqueId,
        },
      }
    )
  );
};

export default { sync, create, edit, pull, getOne, remove };
