import {
  broadcastAllSites,
  broadcastSite,
} from 'serviceWorker/broadcasts/sites';
import { ChannelNames } from 'shared/domain/channelNames';
import * as config from 'serviceWorker/db/config';
import * as sites from 'serviceWorker/db/sites';
import * as sitesService from 'serviceWorker/db/sitesService';
import { debounce } from 'serviceWorker/helpers/debounce';
import { fetchSites, fetchUpdatedSites } from './fetchSites';

import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  CreateSiteCustomEvent,
  EditSiteCustomEvent,
  SiteDeleteCustomEvent,
  SiteRestoreCustomEvent,
} from 'shared/domain/messages/site/eventMessages';
import { debugLog } from 'shared/logger/debugLog';
import { GetSiteCustomEvent } from '../../api/types';
import { siteSaver } from './addSite';
import { siteDeleter } from './deleteSite';
import { siteEditor } from './editSite';
import { siteRestorer } from './restoreSite';

import { getFinishedServiceStateWithSyncKey } from '../factories/getFinishedServiceState';
import { makePullEntity } from '../factories/makePullEntity';
import { makePullHandler } from '../factories/makePullHandler';
import { makeSynchronizeEntity } from '../factories/makeSynchronizeEntity';
import { Pull } from '../factories/types';
const broadcasters: CallableFunction[] = [];
function addBroadcast(broadcast: CallableFunction): void {
  broadcasters.push(broadcast);
}
function emitAllBroadcasts(): void {
  while (broadcasters.length) {
    const broadcaster = broadcasters.pop();
    broadcaster!();
  }
}
function clearBroadcasts(): void {
  broadcasters.length = 0;
}

const pullSites: Pull = makePullEntity({
  fetch: fetchSites,
  channelName: ChannelNames.siteChannel,
  entityName: 'sites',
  entityRepository: sites,
  entityService: sitesService,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  emitAllBroadcasts,
});

const pullSitesHandler = makePullHandler(
  config,
  sites,
  sitesService,
  pullSites,
  clearBroadcasts
);

const synchronizeSites = makeSynchronizeEntity({
  configRepository: config,
  entityService: sitesService,
  entityRepository: sites,
  pullEntityHandler: pullSitesHandler,
  fetchUpdatedEntities: fetchUpdatedSites,
  finishedServiceStateFactory: getFinishedServiceStateWithSyncKey,
  entityName: 'sites',
  channelName: ChannelNames.siteChannel,
  addBroadcast,
  emitAllBroadcasts,
  clearBroadcasts,
});

export const init = (): void => {
  const syncDebounced = debounce(
    synchronizeSites.bind(null, broadcastAllSites),
    250
  );

  self.addEventListener(RepositoryMessagesTypes.syncSites, syncDebounced);

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.getSite,
    function onGetSite(e: GetSiteCustomEvent): void {
      synchronizeSites(broadcastSite.bind(null, e));
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.addSite,
    function onAddSite(e: CreateSiteCustomEvent): void {
      debugLog('addSite event', e);
      siteSaver.execute(e.detail.siteCreateModel, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.editSite,
    async function onEditSite(e: EditSiteCustomEvent): Promise<void> {
      debugLog('editSite event', e);
      siteEditor.execute(e.detail.siteEditModel, e.detail.uniqueId);
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.deleteSite,
    function onDeleteSite(e: SiteDeleteCustomEvent): void {
      debugLog('deleteSite event', e);
      siteDeleter.execute({
        siteId: e.detail.siteId,
        uniqueId: e.detail.uniqueId,
        projectId: e.detail.projectId,
      });
    }
  );

  // @ts-ignore ts does not like custom event here. Can't properly type it.
  self.addEventListener(
    RepositoryMessagesTypes.restoreSite,
    function onRestoreSite(e: SiteRestoreCustomEvent): void {
      debugLog('restoreSite event', e);
      siteRestorer.execute({
        siteId: e.detail.siteId,
        uniqueId: e.detail.uniqueId,
        projectId: e.detail.projectId,
      });
    }
  );
};
