import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStylesSelect = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: '24px',
      minWidth: '220px',
      '& .MuiInputBase-root': {
        minWidth: '220px',
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);
