import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    overflow: 'hidden',
  },
  form: {
    margin: 0,
    padding: '16px 24px',
    maxHeight: '70vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  submitButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '16px 24px',
  },
});
