import { CompanyInDto } from 'shared/dtos/in/company';
import {
  CompanyCreateOutDto,
  CompanyEditOutDto,
} from 'shared/dtos/out/company';

export interface ICompanyUploader {
  POST: (url: string, data: CompanyCreateOutDto) => Promise<any>;
  PUT: (url: string, data: CompanyEditOutDto) => Promise<any>;
  DELETE: (url: string) => Promise<any>;
  GET: (url: string) => Promise<any>;
}

export interface UploadCompanyChanges {
  (
    uploader: ICompanyUploader,
    body: CompanyEditOutDto,
    url: string
  ): Promise<unknown>;
}

export interface UploadCompany {
  (
    uploader: ICompanyUploader,
    body: CompanyCreateOutDto,
    url: string
  ): Promise<CompanyInDto>;
}

export interface UploadCompanyDelete {
  (uploader: ICompanyUploader, url: string): Promise<unknown>;
}

export interface UploadCompanyRestore {
  (uploader: ICompanyUploader, url: string): Promise<unknown>;
}

export function uploadCompanyChanges(
  uploader: ICompanyUploader,
  body: CompanyEditOutDto,
  url: string
): Promise<unknown> {
  return uploader.PUT(url, body);
}

export function uploadCompany(
  uploader: ICompanyUploader,
  body: CompanyCreateOutDto,
  url: string
): Promise<CompanyInDto> {
  return uploader.POST(url, { ...body });
}

export function uploadCompanyDelete(
  uploader: ICompanyUploader,
  url: string
): Promise<unknown> {
  return uploader.DELETE(url);
}

export function uploadCompanyRestore(
  uploader: ICompanyUploader,
  url: string
): Promise<unknown> {
  return uploader.GET(url);
}
