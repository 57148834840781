import { useFieldVisibility } from 'components/dataProviders/withVisibleFields';
import { toVisibleFieldModelMap } from 'shared/domain/visibleField/visibleFieldsMap';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { processesSelector } from 'redux/selectors/processes';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { ProcessType } from 'shared/types/form';

export function useProcessesWithField(
  field: IssueFieldNames
): ProcessType[] {
  const userProcesses = useSelector(processesSelector);
  const { fieldVisibilityStore, loading: loadingVisibleFields } =
    useFieldVisibility();

  const processes = useMemo(() => {
    if (loadingVisibleFields) return [];

    const processes: string[] = [];
    const fieldVisibilityMap = toVisibleFieldModelMap(
      fieldVisibilityStore.get()
    );

    Object.keys(fieldVisibilityMap).forEach((processId) => {
      if (fieldVisibilityMap[processId][field]) {
        processes.push(processId);
      }
    });
    return processes
      .map((processId) => {
        return userProcesses.find((process) => process._id === processId);
      })
      .filter((x) => x) as ProcessType[];
  }, [fieldVisibilityStore, loadingVisibleFields, userProcesses, field]);

  return processes;
}
