import { ReactElement, useCallback, useMemo } from 'react';
import { MemoTableAnchor } from 'components/tableAnchor';
import { withDocumentations } from 'components/dataProviders/withDocumentations';
import { DocumentationTable } from 'components/documentation/DocumentationTable';
import { TableAnchorHeader } from 'components/tableAnchor/types';
import { WithDocumentationController } from 'components/dataProviders/withDocumentationController';
import { withDirectories } from 'components/dataProviders/withDirectories';
import { DocumentationCreateMenu } from 'components/documentation/DocumentationCreateButton';
import { DirectoryDialog } from '../directory/dialog';
import { withCurrentDirectory } from 'components/dataProviders/withCurrentDirectory';
import {
  useDocumenationUrlControl,
  useIsLoadingDocumentation,
} from './model';
import { DocumentationBreadcrumbs } from 'components/documentation/DocumentationBreadcrumbs';
import { EntityLabel } from 'components/common/SearchInput/types';
import {
  useDocumentationSearchContext,
  withDocumentationSearch,
} from 'components/dataProviders/withDocumentationSearch';
import { makeSearchInputWithStore } from 'components/common/SearchInput';
import { withIssueChannelListener } from 'components/broadcastChannelListeners/withIssueChannelListener';
import { withDirectoryChannelListener } from 'components/broadcastChannelListeners/withDirectoryChannelListener';
import { withDocumentationChannelListener } from 'components/broadcastChannelListeners/withDocumentationChannelListener';
import { withIssuesOnMap } from 'components/dataProviders/withIssuesOnMap';
import { withIssueFilters } from 'components/dataProviders/withIssueFilters';
import { makeComposabeWithIssueFiltersPrefix } from 'components/dataProviders/withIssueFilters/issueFiltersPrefixContext';
import { withProjectChangeDialog } from 'components/common/withProjectChangeDialog';
import { withFieldVisibility } from 'components/dataProviders/withVisibleFields';
import { withFieldVisibilityMap } from 'components/dataProviders/withVisibleFields/withVisibleFieldsMap';

function _Documentations(): ReactElement {
  const isLoading = useIsLoadingDocumentation();
  useDocumenationUrlControl();
  const { searchPhraseStore } = useDocumentationSearchContext();

  const DocumentationSearchInput = useMemo(() => {
    return makeSearchInputWithStore(
      EntityLabel.documentation,
      searchPhraseStore
    );
  }, [searchPhraseStore]);

  const documentationCreatePermission = useCallback(() => {
    return { permission: true };
  }, []);
  const creationAction = useCallback(() => {
    return;
  }, []);
  const documentationHeader: TableAnchorHeader = useMemo(() => {
    return {
      id: 'documentation-header',
      textId: 'general_add',
      dataQaId: 'documentation_create_button',
    };
  }, []);

  return (
    // react 18 types
    // @ts-ignore
    <WithDocumentationController>
      <MemoTableAnchor
        canCreate={documentationCreatePermission}
        creationAction={creationAction}
        TableComponent={DocumentationTable}
        loading={isLoading}
        headerWithCreation={documentationHeader}
        CreationComponent={DocumentationCreateMenu}
        SearchInput={DocumentationSearchInput}
      >
        <DocumentationBreadcrumbs />
      </MemoTableAnchor>
      <DirectoryDialog />
    </WithDocumentationController>
  );
}

const withDocumentationIssueFiltersPrefix =
  makeComposabeWithIssueFiltersPrefix('documentation');

export const Documentations = withIssueChannelListener(
  withDirectoryChannelListener(
    withDocumentationChannelListener(
      withDocumentationSearch(
        withCurrentDirectory(
          withDirectories(
            withDocumentations(
              withDocumentationIssueFiltersPrefix(
                withIssueFilters(
                  withFieldVisibility(
                    withFieldVisibilityMap(
                      withIssuesOnMap(
                        withProjectChangeDialog(_Documentations)
                      )
                    )
                  )
                )
              )
            )
          )
        )
      )
    )
  )
);
