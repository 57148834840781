import {
  ImageContent,
  ReportFieldsContent,
} from 'shared/domain/report/reportModel';
import { IntlShape } from 'react-intl';
import { HashMap, LabelledEntity } from 'shared/types/commonView';

export function getSavedImageContent(projectId: string): ImageContent {
  const value = localStorage.getItem(`report_image_content_${projectId}`);
  if (value === null) {
    return ImageContent.all;
  }
  return value as ImageContent;
}

export function saveImageContent(
  projectId: string,
  value: ImageContent
): void {
  localStorage.setItem(
    `report_image_content_${projectId}`,
    value as string
  );
}

export function getSavedReportFieldsContent(): ReportFieldsContent {
  const value = localStorage.getItem(`issues_report_fields_content`);
  if (value === null) {
    return ReportFieldsContent.filled;
  }
  return value as ReportFieldsContent;
}

export function saveFieldsContent(value: ReportFieldsContent): void {
  localStorage.setItem(`issues_report_fields_content`, value as string);
}

export function getSavedSelectedFields(
  intl: IntlShape,
  projectId: string,
  fieldsMap: HashMap<any>
): LabelledEntity[] {
  const value = localStorage.getItem(
    `issues_report_selected_fields_${projectId}`
  );
  if (value === null || !value) {
    return [];
  }

  return value.split(',').map((fieldName) => {
    return {
      _id: fieldName,
      label: intl.formatMessage({
        id: fieldsMap[fieldName].labelId,
      }),
    };
  });
}

export function saveSelectedFields(
  projectId: string,
  value: string
): void {
  localStorage.setItem(
    `issues_report_selected_fields_${projectId}`,
    value
  );
}
