import { InputProps, TextField } from '@mui/material';
import { ChangeEvent } from 'components/common/gallery/types';
import {
  FocusEventHandler,
  ReactElement,
  Ref,
  forwardRef,
  memo,
  useCallback,
} from 'react';
import { useIntl } from 'react-intl';
import { getHelperTextFromError, getLocalizedLabel } from './common';
import { NumberFormatCustom } from './numberField/helpers';
import { SimpleTextInputProps } from './types';

function SimpleTextField(
  {
    value,
    localisedLabel,
    labelId,
    required,
    fieldName,
    disabled,
    handleChange,
    error,
    minRows,
    type,
    sx,
    onBlur,
    dense,
    reserveSpaceForHelperText,
  }: SimpleTextInputProps<string>,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const intl = useIntl();
  const helperText = getHelperTextFromError(error, intl);
  const label = getLocalizedLabel(intl, localisedLabel, labelId);
  const unifiedHandleChange = useCallback(
    (event: ChangeEvent) => {
      handleChange(event, event.target.value);
    },
    [handleChange]
  );

  const unifiedHandleChangeOnBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event): void => {
      handleChange(event, event.target.value);
    },
    [handleChange]
  );

  const InputProps: Partial<InputProps> = {};

  if (type === 'number') {
    InputProps.inputComponent = NumberFormatCustom;
    InputProps.inputProps = { decimalScale: 2, thousandSeparator: '.' };
  }

  return (
    <TextField
      name={fieldName}
      multiline
      fullWidth
      minRows={minRows}
      required={required}
      disabled={Boolean(disabled)}
      data-qa={`form-field-textField-${fieldName}`}
      autoComplete='off'
      // className={cn(specificClasses.standardBorderRadius)}
      id={`text-input-${fieldName}`}
      label={label}
      margin={dense ? 'dense' : 'none'}
      variant='outlined'
      // onChange={unifiedHandleChange}
      onBlur={onBlur || unifiedHandleChangeOnBlur}
      error={Boolean(error)}
      helperText={helperText || (reserveSpaceForHelperText && ' ')}
      // value={value || ''}
      defaultValue={value || ''}
      InputProps={InputProps}
      sx={sx}
    />
  );
}

export const MemoSimpleTextField = memo(forwardRef(SimpleTextField));
