import { CorrectiveActionTypeModel } from 'shared/domain/correctiveActionType/correctiveActiontypeModel';
import { EnvironmentalAspectModel } from 'shared/domain/environmentalAspect/environmentalAspectModel';
import { HazardCategoryModel } from 'shared/domain/hazardCategory/hazardCategoryModel';
import { LevelModel } from 'shared/domain/level/types/model';
import { SiteModel } from 'shared/domain/site/types/model';
import { UserModel } from 'shared/domain/user/userModel';
import { WorktypeModel } from 'shared/domain/worktype/worktypeModel';
import { sortLabelledEntities } from 'helpers/sort';
import { LabelledEntity } from 'shared/types/commonView';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import {
  FieldSkeleton,
  FormFieldType,
  IssueForm,
} from 'shared/types/form';
import { LegacyIssueForm } from './types';

export const insertFieldValues = <T extends LabelledEntity>(
  fieldName: IssueFieldNames,
  extendedFields: FieldSkeleton[],
  values: T[]
): void => {
  const field = extendedFields.find((field) => field.name === fieldName);

  if (field) {
    field.items = values;
  }
};

export const sortFieldValues = <T extends FieldSkeleton>(
  fields: T[]
): T[] => {
  const affectedFields = [
    IssueFieldNames.proposedCorrectiveAction,
    IssueFieldNames.assignee,
    IssueFieldNames.site,
    IssueFieldNames.estimatedCost,
    IssueFieldNames.finalCost,
    IssueFieldNames.executor,
  ];

  return fields.map((field) => {
    const shouldSortField = affectedFields.includes(field.name);
    if (shouldSortField && Array.isArray(field.items)) {
      field.items.sort(sortLabelledEntities);
    }

    return field;
  });
};

export function generateForm(
  issueForm: IssueForm,
  processWorkTypes: WorktypeModel[],
  hazardCategories: HazardCategoryModel[],
  environmentalAspects: EnvironmentalAspectModel[],
  correctiveActionTypes: CorrectiveActionTypeModel[],
  levels: LevelModel[],
  sites: SiteModel[],
  usersWithAccess?: UserModel[]
): LegacyIssueForm {
  const filterUndefined = (elem: unknown): boolean => Boolean(elem);

  const { primaryFields, extendedFields } = separateFields(issueForm);
  const primaryFieldsWithoutDocuments =
    primaryFields &&
    primaryFields.filter(
      (prop) =>
        filterUndefined(prop) && prop.name !== IssueFieldNames.documents
    );

  insertFieldValues(
    IssueFieldNames.site,
    primaryFieldsWithoutDocuments,
    sites
  );

  insertFieldValues(
    IssueFieldNames.level,
    primaryFieldsWithoutDocuments,
    levels
  );

  const filteredFields = extendedFields.filter(filterUndefined);
  insertFieldValues(
    IssueFieldNames.workTypes,
    filteredFields,
    processWorkTypes
  );
  insertFieldValues(
    IssueFieldNames.hazardCategory,
    filteredFields,
    hazardCategories
  );
  insertFieldValues(
    IssueFieldNames.environmentalAspect,
    filteredFields,
    environmentalAspects
  );
  insertFieldValues(
    IssueFieldNames.proposedCorrectiveAction,
    filteredFields,
    correctiveActionTypes
  );
  if (usersWithAccess) {
    insertFieldValues(
      IssueFieldNames.assignee,
      primaryFieldsWithoutDocuments,
      usersWithAccess
    );
    insertFieldValues(
      IssueFieldNames.executor,
      primaryFieldsWithoutDocuments,
      usersWithAccess
    );
  }

  return {
    primaryFields: sortFieldValues(primaryFieldsWithoutDocuments),
    extendedFields: sortFieldValues(filteredFields),
  };
}

export function separateFields(form: IssueForm): LegacyIssueForm {
  return form.reduce<LegacyIssueForm>(
    (result, field?: FieldSkeleton) => {
      if (!field || !field.fieldType) return result;
      if (field.fieldType === FormFieldType.primary) {
        result.primaryFields.push(field);
      } else {
        result.extendedFields.push(field);
      }
      return result;
    },
    {
      primaryFields: [],
      extendedFields: [],
    } as LegacyIssueForm
  );
}
