import { ApexOptions } from 'apexcharts';
import { palette } from '../shared';
import { dataLabels } from '../detectedResolved/options';
import { getToolbarOptions } from '../shared/toolbar';

const chartPalette = [palette.marine];

export function create2DigitFormatter(
  suffix?: string
): (value: unknown) => string {
  return function (value): string {
    if (typeof value === 'number') {
      return value.toFixed(2) + (suffix ? ` ${suffix}` : '');
    }

    return '';
  };
}

export const detailedViewResolutionTimeChartOptions: ApexOptions = {
  colors: chartPalette,
  chart: {
    offsetY: 10,
    redrawOnParentResize: true,
    fontFamily: 'Archivo, Arial',
    toolbar: getToolbarOptions('averageResolutionTime'),
  },
  dataLabels: { ...dataLabels, formatter: create2DigitFormatter() },
  stroke: {
    width: 3,
    colors: chartPalette,
    curve: 'straight',
    dashArray: [7, 0, 0],
  },
  markers: {
    size: 0,
  },
  legend: {
    horizontalAlign: 'left' as 'left',
    offsetX: -26,
    // CANNOT USE REM
    fontSize: '14px',
    showForSingleSeries: true,
  },
  yaxis: {
    decimalsInFloat: 2,
    floating: true,
    opposite: true,
    labels: {
      offsetX: 72,
      offsetY: -6,
      align: 'right',
      style: {
        colors: ['#979797'],
        fontFamily: 'Archivo, Arial',
      },
    },
  },
  xaxis: {
    axisTicks: {
      show: false,
    },
    tickPlacement: 'on',
    labels: {
      rotate: -90,
      style: {
        fontFamily: 'Archivo, Arial',
        cssClass: 'apexcharts-xaxis-label detected-resolved-xaxis-labels',
      },
    },
  },
  grid: {
    padding: {
      top: 24,
      right: 5,
      left: 5,
    },
  },
  tooltip: {
    shared: true,
    intersect: false,
    y: {
      formatter: create2DigitFormatter(),
    },
  },
};

export const listViewResolutionTimeChartOptions: ApexOptions = {
  ...detailedViewResolutionTimeChartOptions,
  chart: {
    ...detailedViewResolutionTimeChartOptions.chart,
    toolbar: { show: false },
  },
  grid: {
    ...detailedViewResolutionTimeChartOptions.grid,
    padding: {
      ...detailedViewResolutionTimeChartOptions.grid?.padding,
    },
  },
};
