import React, { ReactElement, useCallback, useState } from 'react';
import { HashMap } from 'shared/types/commonView';

type BlobFileStorageContext = {
  setItem: (key: string, value: string) => void;
  getItem: (key: string) => string | null;
  removeItem: (key: string) => void;
};

const BlobFileStorage = React.createContext<
  BlobFileStorageContext | undefined
>(undefined);

type WithBlobFileStorageType = { children?: ReactElement };

function WithBlobFileStorage({
  children,
}: WithBlobFileStorageType): ReactElement {
  const [blobs, setBlobs] = useState<HashMap<string>>({});

  const setItem = useCallback((key: string, value: string) => {
    setBlobs((prev) => {
      const next = { ...prev, [key]: value };
      return next;
    });
  }, []);
  const getItem = useCallback(
    (key: string) => {
      return blobs[key] || null;
    },
    [blobs]
  );

  const removeItem = useCallback((key: string) => {
    setBlobs((prev) => {
      const next = { ...prev };
      delete next[key];
      return next;
    });
  }, []);

  const ctx = {
    setItem,
    getItem,
    removeItem,
  };

  return (
    <BlobFileStorage.Provider value={ctx}>
      {children}
    </BlobFileStorage.Provider>
  );
}

function useBlobFileStorage(): BlobFileStorageContext {
  const context = React.useContext(BlobFileStorage);
  if (context === undefined) {
    throw new Error(
      'useBlobFileStorage must be used within a BlobFileStorageContextProvider'
    );
  }
  return context;
}

export { WithBlobFileStorage, useBlobFileStorage };
