import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { Layout } from '../index';
import { ButtonContained } from '../../general';

const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
};

const reloadPage = (): void => {
  window.location.reload();
};

const VersionMismatchPage = (): React.ReactElement => {
  return (
    <Layout>
      <Box margin='dense' style={style}>
        <Typography
          sx={{ py: 2 }}
          margin='dense'
          variant='h3'
          component='span'
        >
          <FormattedMessage id='errorPage_409_conflictVersionMismatch' />
        </Typography>
        <Typography variant='h4' component='span'>
          <FormattedMessage id='errorPage_409_conflictVersionMismatchExtended' />
        </Typography>
        <ButtonContained
          style={{ marginTop: '16px' }}
          onClick={reloadPage}
        >
          <FormattedMessage id='refresh' />
        </ButtonContained>
      </Box>
    </Layout>
  );
};

export default React.memo(VersionMismatchPage);
