import { SetableInspectionFields } from 'components/dataProviders/withInspectionSubmit/types';
import { InspectionTemplateItem } from 'components/dataProviders/withRequiredInspectionTemplate/types';
import {
  COMPLIANCE_CHECK_FORM_COMMENT,
  COMPLIANCE_CHECK_RESULT_PREFIX,
} from 'components/inspection/ComplianceCheck';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import {
  requiredValidation,
  ErrorPresentation,
  notEmptyArrayValidation,
  maxArraySizeValidation,
  stringMaxValidation,
  oneOfValidation,
} from 'helpers/validators';
import { HashMap } from 'shared/types/commonView';
import { InspectionFlatForm } from './model';
import {
  InspectionFormValidation,
  InspectionValidation,
  InspectionValidationFunction,
} from './types';
import { FieldSize } from 'shared/types/fieldSize';

const validation: InspectionValidation = {
  comment: commentValidation,
  site: siteValidation,
  levels: levelsValidation,
  contracts: contractsValidation,
  controlledParty: controlledPartyValidation,
  inspectionDate: pass,
  workTypes: workTypesValidation,
};

export function validateInspectionField(
  key: keyof SetableInspectionFields,
  value: SetableInspectionFields[keyof SetableInspectionFields],
  ...args: any
): ErrorPresentation {
  if (key.startsWith(`${COMPLIANCE_CHECK_FORM_COMMENT}`)) {
    return validation['comment'](value);
  }

  return validation[key] && validation[key](value, ...args);
}

export function createInspectionFormValidation(
  validation: InspectionValidationFunction,
  template: InspectionTemplateItem | undefined
): InspectionFormValidation {
  return (setErrors, form, options): boolean => {
    const finish = options?.finish;
    const errors = validation(finish, template, form);
    setErrors(errors);
    return Boolean(Object.keys(errors).length);
  };
}

export function inspectionValidation(
  finish: undefined | boolean,
  template: InspectionTemplateItem | undefined,
  form: InspectionFlatForm
): HashMap<ErrorPresentation> {
  return validate(finish, template, validation, form);
}

function validate(
  finish: boolean | undefined,
  template: InspectionTemplateItem | undefined,
  validationObject: HashMap<(value: unknown) => ErrorPresentation>,
  form: InspectionFlatForm
): HashMap<ErrorPresentation> {
  const formErrors = Object.entries(validationObject).reduce<
    HashMap<ErrorPresentation>
  >((errors, [key, findError]) => {
    const error = findError(form[key]);
    if (error) {
      errors[key] = error;
    }
    return errors;
  }, {} as HashMap<ErrorPresentation>);

  const commentErrors = template?.checklist.reduce(
    (protocolErrors, checklistItem) => {
      if (checklistItem.deleted) {
        return protocolErrors;
      }
      const protocolCommentKey = `${COMPLIANCE_CHECK_FORM_COMMENT}-${checklistItem._id}`;
      const protocolItemComment = form[protocolCommentKey];
      const errorComment = commentValidation(protocolItemComment);
      if (errorComment) {
        protocolErrors[protocolCommentKey] = errorComment;
      }

      return protocolErrors;
    },
    {} as HashMap<ErrorPresentation>
  );

  if (!finish) {
    return { ...formErrors, ...commentErrors };
  }

  const protocolErrors = template?.checklist.reduce(
    (protocolErrors, checklistItem) => {
      if (checklistItem.deleted) {
        return protocolErrors;
      }
      const protocolResultKey = `${COMPLIANCE_CHECK_RESULT_PREFIX}-${checklistItem._id}`;
      const protocolItemResult = form[protocolResultKey];

      const error = validateProtocolItem(protocolItemResult);
      if (error) {
        protocolErrors[protocolResultKey] = error;
      }

      return protocolErrors;
    },
    {} as HashMap<ErrorPresentation>
  );

  return {
    ...formErrors,
    ...commentErrors,
    ...protocolErrors,
  };
}

function validateProtocolItem(
  value: ComplianceCheckResult | undefined
): ErrorPresentation {
  return (
    requiredValidation(value) ||
    oneOfValidation(value, [
      ComplianceCheckResult.passed,
      ComplianceCheckResult.notPassed,
      ComplianceCheckResult.notApplicable,
    ])
  );
}

function commentValidation(value: unknown): ErrorPresentation {
  return stringMaxValidation(FieldSize.L, value);
}

function siteValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value);
}

function levelsValidation(value: unknown): ErrorPresentation {
  return requiredValidation(value) || notEmptyArrayValidation(value);
}

function contractsValidation(value: unknown): ErrorPresentation {
  return value === undefined
    ? undefined
    : maxArraySizeValidation(5, value);
}

function controlledPartyValidation(value: unknown): ErrorPresentation {
  return value === undefined
    ? undefined
    : maxArraySizeValidation(10, value);
}

function workTypesValidation(value: unknown): ErrorPresentation {
  return value === undefined
    ? undefined
    : maxArraySizeValidation(5, value);
}

function pass(...args: any): undefined {
  return undefined;
}
