import { Box, Divider, Typography } from '@mui/material';
import { useBacktrack } from 'components/common/withBacktrack';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoContractForm } from 'components/contract/Form';
import { Spinner } from 'components/core';
import {
  useEditContract,
  withEditContract,
} from 'components/dataCreationForms/contract/withEditContract';
import { withCreateSite } from 'components/dataCreationForms/site/withCreateSite';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import {
  useCompanies,
  withCompanies,
} from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withSites } from 'components/dataProviders/withSites';
import { FormBottonButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { companyModelToLabelledEntity } from 'shared/domain/company/mapping';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { LabelledEntity } from 'shared/types/commonView';

function EditContractWizardContainer(): React.ReactElement {
  const {
    submitForm,
    isPosting,
    existingValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useEditContract();
  const intl = useIntl();

  const { back } = useBacktrack();
  const formName = 'contract';
  useFormCleanupOnUnmount(formName);
  const onCancel = useCallback(() => {
    back();
  }, [back]);
  const {
    companies: { items: companies },
  } = useCompanies();

  const companiesAsLabelledEntities: LabelledEntity[] = useMemo(
    () => companies.map(companyModelToLabelledEntity),
    [companies]
  );

  if (!existingValues) {
    return (
      <Spinner
        reason={`EditContractWizardContainer Waiting for existing values: ${existingValues}`}
      />
    );
  }

  return (
    <WithInputForm
      values={existingValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {intl.formatMessage({ id: 'edit_contract_section' })}
      </Typography>
      <Divider />

      <Box sx={{ p: 3, mb: 8 }}>
        <MemoContractForm
          companies={companiesAsLabelledEntities}
          isDialog={false}
        />
      </Box>
      <FormBottonButtons
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoEditContractWizardContainer = memo(
  withFetcherReady(
    withProcessesData(
      withSites(
        withContracts(
          withCompanies(
            withEditContract(withCreateSite(EditContractWizardContainer))
          )
        )
      )
    )
  )
);
