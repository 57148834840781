import { UserInDto } from 'shared/dtos/in/user';
import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from '../../helpers/httpRequests';
import { HeadersData } from '../factories/types';
import { pull } from '../pull';

export const fetchUsers = (
  data: HeadersData,
  controller: AbortController
): Promise<UserInDto[]> | undefined => {
  const { signal } = controller;
  if (shouldSkip(data, signal)) {
    return;
  }
  const builtUrl = `${data.api}/v2/project/${data.projectId}/user`;

  return pull(builtUrl, {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
    signal,
  }).then(parseResponse) as Promise<UserInDto[]>;
};

function shouldSkip(data: HeadersData, signal: AbortSignal): boolean {
  return !data.api || !data.projectId || signal.aborted;
}
