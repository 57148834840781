import { SORT_TYPE } from 'shared/filter/sort/types';
import { CompanyModel } from 'shared/domain/company/types/model';
import { ColumnShapeSkeleton } from '../types';

export function getTaxNumberColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 192,
    flexGrow: 0,
    flexShrink: 0,
    sortable: false,
    sortType: SORT_TYPE.STRING,
    accessor: (entity: CompanyModel): string | null =>
      entity.taxNumber ?? null,
    labelId: 'company_tax_number_field_label',
    dataKey: 'taxNumber',
    style: {
      justifyContent: 'flex-start',
    },
  };
}
