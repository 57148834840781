import {
  forwardRef,
  Ref,
  useImperativeHandle,
  useState,
  ChangeEvent,
} from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import FeedbackControllers from './FeedbackControllers';
import { FieldSize } from 'shared/types/fieldSize';

const styles = makeStyles({
  charCount: {
    color: '#ccc',
    fontSize: '0.75rem',
    position: 'absolute' as 'absolute',
    right: '8px',
    bottom: '4px',
  },
  input: {
    width: '100%',
    fontSize: '0.875rem',
    position: 'relative' as 'relative',
    boxSizing: 'border-box' as 'border-box',
    margin: 0,
    color: '#979797',
    '& .MuiInputBase-input': {
      padding: 0,
    },
    '& > div': {
      borderRadius: '5px',
    },
  },
  errorContainer: {
    minHeight: '20px',
  },
  errorMessage: {
    fontSize: '0.75rem',
    color: 'rgb(244, 67, 54)',
    marginLeft: '0.875rem',
  },
});

type FInputProps = {
  onFeedbackSubmit: (feedback: string) => void;
  onClose: () => void;
};

export type FInputRef = {
  isDirty: boolean;
};

const FInput = (
  { onFeedbackSubmit, onClose }: FInputProps,
  ref: Ref<unknown> | undefined
): JSX.Element => {
  const [feedback, setFeedback] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [validated, setValidated] = useState(false);
  const [touched, setTouched] = useState(false);
  const intl = useIntl();
  const classes = styles();

  useImperativeHandle(ref, () => ({
    isDirty: feedback !== '',
  }));

  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ): void => {
    const feedBackValue = event.target.value;
    const currentlyValidated = feedBackValue.length >= 5;

    setFeedback(feedBackValue);
    setCharCount(feedBackValue.length);

    if (currentlyValidated !== validated) {
      setValidated(!!currentlyValidated);
    }

    if (!touched) {
      setTouched(true);
    }
  };

  const handleFeedback = (): void => {
    onFeedbackSubmit(feedback);
  };

  return (
    <>
      <TextField
        autoFocus
        multiline
        label={null}
        margin='dense'
        variant='outlined'
        defaultValue={feedback}
        data-qa='feedback-input'
        className={classes.input}
        onChange={handleInputChange}
        error={touched && !validated}
        inputProps={{ maxLength: FieldSize.L }}
        placeholder={intl.formatMessage({
          id: 'feedback_hint',
        })}
        InputProps={{
          endAdornment: (
            <span className={classes.charCount}>
              {charCount}/{FieldSize.XL}
            </span>
          ),
        }}
      />
      <div className={classes.errorContainer}>
        {touched && !validated && (
          <span className={classes.errorMessage}>
            <FormattedMessage
              id='validation_string_length_at_least_web'
              values={{
                minLength: '5',
              }}
            />
          </span>
        )}
      </div>
      <FeedbackControllers
        disabled={!validated}
        onClose={onClose}
        onSubmit={handleFeedback}
      />
    </>
  );
};

const FeedbackInput = forwardRef<FInputRef, FInputProps>(FInput);

export default FeedbackInput;
