import React, {
  ChangeEvent,
  Fragment,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { makeStyles } from '@mui/styles';
import ClearAllSharpIcon from '@mui/icons-material/ClearAllSharp';
import { useSearch } from '../../../hooks/search/useSearch';

const useStyles = makeStyles({
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  searchInput: {
    height: '1.25rem',
    width: '100%',
    outline: 'none',
    border: 'none',
    fontFamily: 'Archivo, Arial',
    fontSize: '1rem',
  },
});

const iconStyle = {
  fill: 'rgba(0,0,0,.27)',
  cursor: 'pointer',
  width: '1.25rem',
  height: '1.25rem',
};

const defaultPhraseValidation = (phrase: string): boolean =>
  phrase.length > 0;

type ListSearchProps = {
  placeholder: string;
  onSearch: CallableFunction;
  phraseValidation?: (phrase: string) => boolean;
  'data-qa': string;
};
const ListSearch = ({
  placeholder,
  onSearch,
  phraseValidation,
  ...props
}: ListSearchProps): React.ReactElement => {
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();

  const { searchChange, searchClear, searchPhrase } = useSearch(
    onSearch,
    '',
    phraseValidation || defaultPhraseValidation
  );

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      searchChange(e.target.value);
    },
    [searchChange]
  );

  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef && inputRef.current.focus();
    }
  });

  return (
    <Fragment>
      <input
        ref={inputRef}
        value={searchPhrase}
        placeholder={placeholder}
        data-qa={props['data-qa']}
        className={classes.searchInput}
        onChange={onChange}
      />
      {!!searchPhrase.length && (
        <ClearAllSharpIcon onClick={searchClear} style={iconStyle} />
      )}
    </Fragment>
  );
};

export default ListSearch;
const MemoListSearch = React.memo(ListSearch);
export { MemoListSearch };
