import { HashMap } from 'shared/types/commonView';
import { InspectionTemplateModel } from 'shared/domain/template/inspectionTemplateModel';
import { useEffect, useMemo } from 'react';
import { useCreateStore } from 'hooks/createStore';
import { useSites } from '../withSites';
import { useLevels } from '../withLevels';
import { useWorktypes } from '../withWorktypes';
import { useCompanies } from '../../dataProviders/withCompanies';
import { useContracts } from '../withContracts';

export function toTemplateModelsObject(
  result: HashMap<InspectionTemplateModel>,
  curr: InspectionTemplateModel
): HashMap<InspectionTemplateModel> {
  result[curr._id] = curr;
  return result;
}

export function useInspectionDependencies(): any {
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const {
    levels: { items: levels },
    loading: loadingLevels,
  } = useLevels();
  const { items: workTypes, loading: loadingWorkTypes } = useWorktypes();
  const {
    companies: { items: companies },
    loading: loadingCompanies,
  } = useCompanies();
  const {
    contracts: { items: contracts },
    loading: loadingContracts,
  } = useContracts();

  const loading =
    loadingSites ||
    loadingLevels ||
    loadingWorkTypes ||
    loadingCompanies ||
    loadingContracts;

  const loadingStore = useCreateStore(loading);

  useEffect(() => {
    loadingStore.set(loading);
  }, [loading, loadingStore]);

  return useMemo(() => {
    return {
      sites,
      levels,
      workTypes,
      companies,
      contracts,
      loadingStore,
      loading,
    };
  }, [
    sites,
    levels,
    workTypes,
    companies,
    contracts,
    loadingStore,
    loading,
  ]);
}
