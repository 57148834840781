import React, {
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { HashMap } from 'shared/types/commonView';
import { IssueModel } from 'shared/domain/issue/issueModel';
import listenToIssueEditMessage from 'shared/domain/issue/listenToIssueEditMessage';

type Unsubscribe = () => void;
type IssueEditSuccessAction = (issueModel: IssueModel) => void;
type IssueEditErrorAction = (error: any) => void;
type IssueEditAction = {
  successAction: IssueEditSuccessAction;
  errorAction: IssueEditErrorAction;
};

type IssueEditMessageContext = {
  subscribe: (
    action: IssueEditSuccessAction,
    errorAction: IssueEditErrorAction
  ) => Unsubscribe;
};
const IssueEditSuccess = React.createContext<
  IssueEditMessageContext | undefined
>(undefined);

// react 18 types
// @ts-ignore
const WithIssueEditMessage: React.FC = ({ children }) => {
  const uniqueKeyRef = useRef(1);
  const subscriptions = useRef<HashMap<IssueEditAction>>({});
  const [lastEdition, setLastEdition] = useState<IssueModel>();
  const [error, setError] = useState<any | undefined>();

  useEffect((): (() => void) => {
    const setParsed = (issueModel: IssueModel): void => {
      setLastEdition(issueModel);
    };
    const broadcast = listenToIssueEditMessage(setParsed, setError);
    return (): void => {
      broadcast.close();
    };
  }, []);

  useEffect(() => {
    if (!lastEdition) {
      return;
    }
    Object.values(subscriptions.current).forEach(({ successAction }) => {
      successAction(lastEdition);
    });
  }, [lastEdition]);

  useEffect(() => {
    if (!error) {
      return;
    }
    Object.values(subscriptions.current).forEach(({ errorAction }) => {
      errorAction(error);
    });
  }, [error]);

  const subscribe = useCallback(
    (
      action: IssueEditSuccessAction,
      errorAction: IssueEditErrorAction
    ): Unsubscribe => {
      const key = uniqueKeyRef.current;
      subscriptions.current[key] = {
        successAction: action,
        errorAction: errorAction,
      };
      uniqueKeyRef.current += 1;

      return (): void => {
        delete subscriptions.current[key];
      };
    },
    []
  );

  const ctx = {
    subscribe,
  };

  return (
    <IssueEditSuccess.Provider value={ctx}>
      {children}
    </IssueEditSuccess.Provider>
  );
};

const withIssueEditMessage =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    // react 18 types
    // @ts-ignore
    <WithIssueEditMessage>
      <Component {...props} />
    </WithIssueEditMessage>
  );

function useIssueEditMessage(): IssueEditMessageContext {
  const context = React.useContext(IssueEditSuccess);
  if (context === undefined) {
    throw new Error(
      'useIssueEditMessage must be used within an IssueEditMessageContextProvider'
    );
  }
  return context;
}

export { WithIssueEditMessage, withIssueEditMessage, useIssueEditMessage };
