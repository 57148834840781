import { BroadcastChannel } from 'broadcast-channel';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import * as dbOrganizations from '../../db/organizations';
import { organizationEntityToModel } from 'shared/domain/organization/mapping/toModel';
import { OrganizationInDto } from 'shared/domain/organization/types/dto';
import { OrganizationModel } from 'shared/domain/organization/types/model';
import { broadcastCreatedOrganizationError } from './error';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';

export const broadcastUpdatedOrganization = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.organizationChannel
);

export const broadcastCreatedOrganization = async (
  organizationInDto: OrganizationInDto,
  uniqueId: string
): Promise<void> => {
  try {
    await dbOrganizations.addBatch([organizationInDto]);
    const organizationEntity = await dbOrganizations.getOne(
      organizationInDto._id
    );
    if (!organizationEntity) {
      throw new Error(
        `Cannot find organization with id ${organizationInDto._id}`
      );
    }
    broadcastOrganizationSuccess(
      DomainMessagesTypes.createdEntity,
      organizationEntityToModel(organizationEntity),
      uniqueId
    );
  } catch (error) {
    broadcastCreatedOrganizationError(error);
  }
};

function broadcastOrganizationSuccess(
  type: DomainMessagesTypes,
  data: OrganizationModel,
  uniqueId?: string
): void {
  const broadcast = new BroadcastChannel(ChannelNames.organizationChannel);
  broadcast.postMessage({
    data,
    type,
    uniqueId,
  });
  broadcast.close();
}
