import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useBacktrack } from 'components/common/withBacktrack';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoLongCompanyForm } from 'components/company/Form';
import {
  useCreateCompany,
  withCreateCompany,
} from 'components/dataCreationForms/company/withCreateCompany';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withSites } from 'components/dataProviders/withSites';
import { FormBottonButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { useCallback, memo } from 'react';
import { useIntl } from 'react-intl';

function CreateCompanyWizardContainer(): React.ReactElement {
  const {
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
    availableSites,
  } = useCreateCompany();
  const intl = useIntl();

  const { back } = useBacktrack();
  const formName = 'company';
  useFormCleanupOnUnmount(formName);
  const onCancel = useCallback(() => {
    back();
  }, [back]);

  return (
    <WithInputForm
      values={initialValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {intl.formatMessage({ id: 'add_company_section' })}
      </Typography>
      <Divider />
      <Box sx={{ p: 3, mb: 8 }}>
        <MemoLongCompanyForm
          disableInvolvement={false}
          sites={availableSites}
          isDialog={false}
          initialValues={initialValues}
        />
      </Box>
      <FormBottonButtons
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoCreateCompanyWizardContainer = memo(
  withFetcherReady(
    withProcessesData(
      withSites(
        withCompanies(
          withContracts(withCreateCompany(CreateCompanyWizardContainer))
        )
      )
    )
  )
);
