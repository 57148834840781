import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { DirectoryInDto } from 'shared/dtos/in/directory';
import { setDirectorySync } from './setDirectorySync';
import { IDirectoryUploader } from './uploadDirectory';

export interface UploadDirectoryChange {
  (
    uploader: IDirectoryUploader,
    localId: number,
    name: string,
    url: string,
    uniqueId?: string
  ): Promise<boolean>;
}

export async function uploadDirectoryChange(
  uploader: IDirectoryUploader,
  localId: number,
  name: string,
  url: string,
  uniqueId?: string
): Promise<any> {
  try {
    await uploadChange(uploader, name, url);
  } catch (e) {
    await setDirectorySync(localId, SyncStatus.FAIL);
    throw e;
  }
}

function uploadChange(
  uploader: IDirectoryUploader,
  name: string,
  url: string
): Promise<DirectoryInDto> {
  return uploader.PUT(url, {
    name,
  });
}
