import React from 'react';
import { WithStyles, withStyles } from '@mui/styles';
import { ButtonContained } from '../../general';

import { FormattedMessage } from 'react-intl';
import feedSummary from '../../../assets/icons/feedSummary.svg';
import { Typography } from '@mui/material';

const styles = {
  feedBackSummary: {
    height: '100%',
    display: 'flex',
    textAlign: 'center' as 'center',
    flexDirection: 'column' as 'column',
    justifyContent: 'space-between',
  },
  summaryInfo: {
    color: '#000000',
    fontWeight: 600,
    lineHeight: '1.125rem',
  },
  submitButton: {
    alignSelf: 'flex-end' as 'flex-end',
    marginTop: 80,
  },
  summaryImage: {
    height: '120px',
    marginBottom: '52px',
  },
};

type FeedbackSummaryProps = WithStyles<typeof styles> & {
  onClose: () => void;
};

const FeedbackSummary = ({
  classes,
  onClose,
}: FeedbackSummaryProps): JSX.Element => {
  return (
    <div className={classes.feedBackSummary}>
      <div>
        <img
          alt='icon'
          src={feedSummary}
          className={classes.summaryImage}
        />
        <Typography variant='subtitle1' className={classes.summaryInfo}>
          <FormattedMessage id='feedback_final_message' />
        </Typography>
      </div>
      <ButtonContained
        onClick={onClose}
        className={classes.submitButton}
        data-qa='feedback-finish-button'
      >
        <FormattedMessage id='general_ok' />
      </ButtonContained>
    </div>
  );
};

export default withStyles(styles)(FeedbackSummary);
