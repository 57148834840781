import { BroadcastChannel } from 'broadcast-channel';
import { DocumentationModel } from 'shared/domain/documentation/documentationModel';
import {
  DomainMessagesTypes,
  Message,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debuglog } from 'util';
import { makeUseGet } from './makeUseGet';

export const useGetDocumentations = makeUseGet<
  { items: DocumentationModel[] },
  string
>({
  listenMessageChannel: ChannelNames.documentationChannel,
  isMatchingMessage,
  postMessage: broadcastGetDocumentations,
  entityName: 'documentations',
});

function broadcastGetDocumentations(folder: string): void {
  debuglog('send broadcastGetDocumentations');
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );
  broadcast.postMessage({
    type: DomainMessagesTypes.getDocumentations,
    data: {
      parentId: folder,
    },
    uniqueId: folder,
  });
  broadcast.close();
}

function isMatchingMessage(folderId: string, event: Message): boolean {
  return (
    event.type === DomainMessagesTypes.documentations &&
    Boolean(event.data) &&
    event.uniqueId === folderId
  );
}
