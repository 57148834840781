import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { debugLog } from 'shared/logger/debugLog';

export function startAllProjectsSynchronization(): void {
  debugLog('startAllProjectsSynchronization');
  const broadcast = new BroadcastChannel(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.PROJECTS,
    method: ServiceMethods.PULL,
    source: 'startAllProjectsSynchronization',
  });
  broadcast.close();
}
