import { useBacktrack } from 'components/common/withBacktrack';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { useInspection } from 'components/dataProviders/withInspection';
import { putProtocol } from 'components/dataProviders/withInspection/api';
import { useIssuesOnInspection } from 'components/dataProviders/withIssuesOnInspection';
import { useRequiredInspectionTemplate } from 'components/dataProviders/withRequiredInspectionTemplate';
import { LoadableIssueCard } from 'components/inspection/IssueCardPreview';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import { IssueModel } from 'shared/domain/issue/issueModel';
import { InspectionInDto } from 'shared/dtos/in/inspection';
import { IssueInDto } from 'shared/dtos/in/issue';
import { projectIdSelector } from 'helpers/misc';
import {
  ReactElement,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { displayGenericErrorToaster } from 'redux/actions/toasterActions';
import useFormEdit from 'views/issue/forms/useFormEdit';
import { AfterDoneResult } from 'views/issue/wizard/afterDoneResult';
import { singleIssueUrl } from '../../../../helpers/generators';
import { LabelledEntity } from 'shared/types/commonView';
import { MemoComplianceCheckFormNotPassedPresentational } from './presentational';
import { ComplianceCheckFormNotPassedProps } from './types';

export type ProtocolIdentification = {
  inspection: string | undefined;
  protocolItem: string | undefined;
};

function ComplianceCheckFormNotPassed(
  props: ComplianceCheckFormNotPassedProps
): ReactElement {
  const { resultKey, checklistItemId } = props;
  const projectId = useSelector(projectIdSelector);

  const { template } = useRequiredInspectionTemplate();
  const { inspection, getInspection } = useInspection();
  const createDialog = useDialog();
  const history = useHistory();
  const { pushLocation } = useBacktrack();
  const dispatch = useDispatch();
  const { values, submitForm, setValues, validate } = useInputForm();

  const site: LabelledEntity = values.site;
  const levels = values.levels;

  const renderableProcess = template?.renderableProcess;
  const processCode = renderableProcess?.code;
  const protocolItem = inspection?.protocol?.find((protocol) => {
    return protocol.templateChecklistItem._id === checklistItemId;
  });

  const protocolRef = useRef<ProtocolIdentification>({
    inspection: inspection?._id,
    protocolItem: protocolItem?._id,
  });
  useEffect(() => {
    protocolRef.current = {
      inspection: inspection?._id,
      protocolItem: protocolItem?._id,
    };
  }, [inspection, protocolItem]);

  const beforeSave = useCallback(() => {
    if (!protocolRef.current.inspection) {
      return submitForm().then(
        (submitResponse: { inspection: InspectionInDto }) => {
          protocolRef.current = {
            inspection: submitResponse.inspection._id,
            protocolItem: submitResponse.inspection.protocol.find(
              (protocol) =>
                protocol.templateChecklistItem === checklistItemId
            )?._id,
          };

          return protocolRef.current;
        }
      );
    }
    return Promise.resolve(protocolRef.current);
  }, [submitForm, checklistItemId]);

  const [issues, setIssues] = useState(
    protocolItem?.complianceCheck.issues || []
  );

  useEffect(() => {
    if (protocolItem?.complianceCheck.issues) {
      setIssues(protocolItem?.complianceCheck.issues);
    }
  }, [protocolItem]);

  const pushEditLocation = useCallback(() => {
    const id = inspection?._id;
    if (!id) {
      return;
    }

    const editInspectionUrl = `/project/${projectId}/inspection/${id}/edit`;

    const editLocation = {
      pathname: editInspectionUrl,
      state: history.location.state,
    };

    pushLocation(editLocation as (typeof history)['location']);
  }, [inspection?._id, pushLocation, history, projectId]);

  const issueCardOnClick = useCallback(
    (issueModel: IssueModel) => {
      createDialog({
        title: <FormattedMessage id='go_to_issue' />,
        description: (
          <span>
            <FormattedMessage id='save_inspection_automatically' />
          </span>
        ),
        customControllerLabels: ['general_cancel', 'general_yes'],
        catchOnCancel: true,
      })
        .then(() => submitForm())
        .then(() => {
          pushEditLocation();

          history.push(singleIssueUrl(projectId, issueModel._id));
        })
        .catch(() => {});
    },
    [submitForm, pushEditLocation, history, createDialog, projectId]
  );
  const issueCards = useMemo(() => {
    return issues?.map((issue) => {
      return (
        <LoadableIssueCard
          onClick={issueCardOnClick}
          key={issue}
          issueId={issue}
          useStoreContext={useIssuesOnInspection}
        />
      );
    });
  }, [issues, issueCardOnClick]);

  const [firstIssueDialogOpen, setFistIssueDialogOpen] = useState(false);
  const firstIssueDialogHandleClose = useCallback(() => {
    setValues(
      resultKey,
      protocolItem?.complianceCheck.result || ComplianceCheckResult.unset
    );
    setFistIssueDialogOpen(false);
  }, [setValues, resultKey, protocolItem]);
  const { setEditing } = useFormEdit();

  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(
    (
      maybeIssue: IssueInDto | undefined,
      reason: 'backdropClick' | 'escapeKeyDown' | AfterDoneResult
    ) => {
      const abortController = new AbortController();
      if (reason === 'backdropClick') {
        return;
      }
      setOpen(false);
      setEditing(true, 'issue');

      if (reason === AfterDoneResult.saved) {
        if (
          !protocolRef.current.inspection ||
          !protocolRef.current.protocolItem
        ) {
          throw new Error(
            'Unable to save protocol item. Missing inspeciton ID or protocol item ID.'
          );
        }

        putProtocol(
          protocolRef.current.inspection,
          protocolRef.current.protocolItem,
          {
            complianceCheck: {
              result: ComplianceCheckResult.notPassed,
            },
          },
          abortController.signal
        )
          .then(() => {
            getInspection();
            setIssues((prev) => {
              if (maybeIssue && maybeIssue._id)
                return [...prev, maybeIssue._id];
              return prev;
            });
          })
          .catch(() => {
            displayGenericErrorToaster(dispatch);
            getInspection();
          });
      } else {
        setValues(
          resultKey,
          protocolItem?.complianceCheck.result ||
            ComplianceCheckResult.unset
        );
      }
    },
    [
      getInspection,
      setValues,
      resultKey,
      protocolItem,
      dispatch,
      setEditing,
    ]
  );

  const createInspectionIssue = useCallback(() => {
    setFistIssueDialogOpen(false);
    const isInvalid = validate();
    if (isInvalid) {
      return;
    }
    if (protocolItem) {
      handleOpen();
      return;
    }
    createDialog({
      title: (
        <FormattedMessage id='protocol_item_create_issue_warning_title' />
      ),
      description: (
        <span>
          <FormattedMessage id='protocol_item_create_issue_warning' />
        </span>
      ),
      customControllerLabels: ['general_cancel', 'bottom_nav_create'],
      catchOnCancel: true,
    })
      .then(() => {
        handleOpen();
      })
      .catch(() => {
        setValues(resultKey, ComplianceCheckResult.unset);
      });
  }, [
    protocolItem,
    setValues,
    resultKey,
    handleOpen,
    createDialog,
    validate,
  ]);

  const initialValues = useMemo(
    () => ({
      site,
    }),
    [site]
  );

  const isCreationDisabled = !site || !arrayAndNotEmpty(levels);
  const creationDisabledReason =
    isCreationDisabled && 'protocol_item_create_issue_site_empty_warning';

  useEffect(() => {
    if (!inspection || !protocolItem?.complianceCheck.issues.length) {
      const isInvalid = validate();
      if (isInvalid) {
        setValues(resultKey, ComplianceCheckResult.unset);
      } else {
        setFistIssueDialogOpen(true);
      }
    }
    // if we include dependencies the dialog will open too often and also on top of issue creation dialog.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MemoComplianceCheckFormNotPassedPresentational
      issueCards={issueCards}
      createInspectionIssue={createInspectionIssue}
      processCode={processCode}
      initialValues={initialValues}
      firstIssueDialogOpen={firstIssueDialogOpen}
      firstIssueDialogHandleClose={firstIssueDialogHandleClose}
      open={open}
      handleClose={handleClose}
      disabled={isCreationDisabled}
      disabledReason={creationDisabledReason}
      beforeSave={beforeSave}
    />
  );
}

function arrayAndNotEmpty(value: any): boolean {
  return Array.isArray(value) && Boolean(value.length);
}

export const MemoComplianceCheckFormNotPassed = memo(
  ComplianceCheckFormNotPassed
);
