import { GraphicUploader } from 'components/common/graphicUploader';
import { useGraphicUploader } from 'components/common/graphicUploader/useLocalGraphicUploader';
import { useInspectionTemplate } from 'components/dataProviders/withInspectionTemplate';
import { useRequiredInspectionTemplate } from 'components/dataProviders/withRequiredInspectionTemplate';
import { MemoPreviewField } from 'components/general/PreviewField';
import {
  DocumentToTemplateBinder,
  IDocumentBinder,
} from 'shared/domain/document/documentBinder';
import { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { createInspectionTemplateGeneralNormDocumentsBaseUrl } from 'views/template/wizard/communicator/helpers';
import { useStyles } from './styles';
import {
  GeneralNormPreviewProps,
  GeneralNormPreviewWithGraphicUploaderProps,
} from './types';

function GeneralNormPreview({
  generalNormDescription,
  GU,
  classes,
}: GeneralNormPreviewProps): ReactElement {
  const cs = useStyles({ classes });
  const intl = useIntl();
  return (
    <div className={cs.generalNormContainer}>
      <div className={cs.wrappedContent}>
        <MemoPreviewField
          label={intl.formatMessage({
            id: 'inspection_template_field_label_standard_description',
          })}
          value={generalNormDescription}
          dense={true}
        />
        <div className={cs.graphicUploaderWrapper}>
          <GraphicUploader {...GU} />
        </div>
      </div>
    </div>
  );
}

function ContextedGeneralNormPreview({
  classes,
}: Pick<GeneralNormPreviewProps, 'classes'>): ReactElement | null {
  const { template } = useRequiredInspectionTemplate();

  if (!template) return null;

  return (
    <GeneralNormPreviewWithGraphicUploader
      template={template}
      classes={classes}
    />
  );
}

function GeneralNormPreviewWithGraphicUploader({
  template,
  classes,
}: GeneralNormPreviewWithGraphicUploaderProps): ReactElement {
  const generalNormDescription = template.generalNorm.description;
  const { inspectionTemplateDocuments } = useInspectionTemplate();
  const GU = useGraphicUploader(
    inspectionTemplateDocuments.templateDocuments,
    {
      baseUrl: createInspectionTemplateGeneralNormDocumentsBaseUrl(
        template._id
      ),
      previewOnly: true,
      // TODO: PT-3769 should this be required when on preview?
      documentBinderFactory: (): IDocumentBinder =>
        new DocumentToTemplateBinder({
          templateId: template._id,
        }),
    },
    true
  );
  return (
    <GeneralNormPreview
      classes={classes}
      generalNormDescription={generalNormDescription}
      GU={GU}
    />
  );
}

export { GeneralNormPreview, ContextedGeneralNormPreview };
