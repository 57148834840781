import { Location } from './types';

export function isRelevantLocationChange(
  location: Location,
  lastLocations: Location[]
): boolean {
  if (location.pathname === '/') {
    return false;
  }
  const prevLocation = lastLocations.slice(-1)[0];
  if (!prevLocation) {
    return true;
  }
  return (
    prevLocation.pathname !== location.pathname ||
    prevLocation.search !== location.search
  );
}

export function canReturnToLocation(location: Location): boolean {
  return !location.pathname.includes('create');
}

export function resolveTitle(
  currentLocation: Location,
  lastLocations: Location[]
): string | undefined {
  if (lastLocations.length > 1) {
    return 'general_back';
  }
  return undefined;
}

export function buildUrl(location: Location): string {
  if (location.search) {
    return `${location.pathname}${location.search}`;
  }
  return location.pathname;
}
