import {
  useCustomizedField,
  withCustomizedField,
} from 'components/dataProviders/withCustomizedField';
import {
  useFieldValueForm,
  withFieldValueForm,
} from 'components/dataCreationForms/withFieldValueForm';
import { withFieldValuesForm } from 'components/dataCreationForms/withFieldValuesForm';
import { SingleFieldSettings } from 'components/fieldSettings/single';
import { ReactElement, useCallback, useState } from 'react';
import { FieldValueDialog } from '../wizard/dialog';
import {
  CreateFieldValueOnView,
  FieldValueOnView,
} from 'presentation/fieldValue/fieldValueOnView';

function _FieldSettingsWithFieldValuesProvider(): ReactElement {
  const { Provider } = useCustomizedField();

  return (
    // react 18 types
    // @ts-ignore
    <Provider>
      <FieldSettingsBase />
    </Provider>
  );
}

function _FieldSettings(): ReactElement {
  const { useContext, fieldLabelId } = useCustomizedField();
  const { openDialog } = useFieldValueForm();
  const [key, setKey] = useState<number | undefined>(undefined);
  const open = useCallback(
    (field: CreateFieldValueOnView | FieldValueOnView) => {
      setKey(Math.random());
      openDialog({ field });
    },
    [openDialog]
  );
  return (
    <>
      <SingleFieldSettings
        useFieldValues={useContext}
        onFieldBoxClick={open}
        titleLabelId={fieldLabelId}
      />
      <FieldValueDialog instantSubmit={false} key={key} />
    </>
  );
}
const FieldSettingsBase = withFieldValuesForm(
  withFieldValueForm(_FieldSettings)
);
export const FieldSettings = withCustomizedField(
  _FieldSettingsWithFieldValuesProvider
);
