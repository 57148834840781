import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { debugLog } from 'shared/logger/debugLog';
import { makeDefaultSync } from './apiFactories';
import {
  CreateLevelCustomEventDetail,
  EditLevelCustomEventDetail,
  LevelDeleteCustomEventDetail,
  LevelRestoreCustomEventDetail,
} from 'shared/domain/messages/level/eventMessages';

const sync = makeDefaultSync(RepositoryMessagesTypes.syncLevels);

const create = (e: CreateLevelCustomEventDetail): void => {
  debugLog('CREATE LEVEL EVENT', e);
  self.dispatchEvent(
    new CustomEvent<CreateLevelCustomEventDetail>(
      RepositoryMessagesTypes.addLevel,
      {
        detail: { ...e },
      }
    )
  );
};

const edit = (e: EditLevelCustomEventDetail): void => {
  debugLog('EDIT LEVEL EVENT', e);
  self.dispatchEvent(
    new CustomEvent<EditLevelCustomEventDetail>(
      RepositoryMessagesTypes.editLevel,
      {
        detail: { ...e },
      }
    )
  );
};

const remove = (e: LevelDeleteCustomEventDetail): void => {
  debugLog('REMOVE LEVEL EVENT', e);
  self.dispatchEvent(
    new CustomEvent<LevelDeleteCustomEventDetail>(
      RepositoryMessagesTypes.deleteLevel,
      {
        detail: { ...e },
      }
    )
  );
};

const restore = (e: LevelRestoreCustomEventDetail): void => {
  debugLog('RESTORE LEVEL EVENT', e);
  self.dispatchEvent(
    new CustomEvent<LevelRestoreCustomEventDetail>(
      RepositoryMessagesTypes.restoreLevel,
      {
        detail: { ...e },
      }
    )
  );
};

export default { sync, create, edit, remove, restore };
