export type Permission = {
  permission: boolean;
  reason?: string;
};

export function noPermission(message: string): Permission {
  return {
    permission: false,
    reason: message,
  };
}

export function noPermissionInOffline(): Permission {
  return {
    permission: false,
    reason: 'general_offline_operation_unavailable',
  };
}

export function validateCreatePermission(
  isAllowed: Boolean,
  isOffline: Boolean
): Permission {
  if (!isAllowed) {
    return noPermission('tooltip_action_forbidden');
  }
  if (isOffline) {
    return noPermissionInOffline();
  }

  return {
    permission: true,
  };
}
