import { Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      overflow: 'hidden',
      '& .selected': {
        backgroundColor: `rgba(251, 204, 48, 0.15) !important`,
        borderColor: `#FBCC30`,
        '&:hover': {
          backgroundColor: `rgba(251, 204, 48, 0.45) !important`,
        },
      },
    },
  })
);
