import { useEffect, useCallback } from 'react';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SET_FORM_EDITING } from 'redux/actionTypes';
import { StoreState } from 'setup/types/core';
import { FormName } from 'presentation/forms';

export function useFormCleanupOnUnmount(form: FormName): void {
  const dispatch = useDispatch();

  useEffect(() => {
    return (): void => {
      dispatch({ type: SET_FORM_EDITING, form, isFormEditing: false });
    };
  }, [dispatch, form]);
}

export function useCancelConfirmation(form: FormName): () => Promise<{
  cancelConfirmed: boolean;
}> {
  const createDialog = useDialog();
  const isFormEditing = useSelector((state: StoreState) => {
    return state.ui.isFormEditing[form];
  });

  const showCancelConfirmationDialog = useCallback(async (): Promise<{
    cancelConfirmed: boolean;
  }> => {
    return createDialog({
      catchOnCancel: true,
      title: (
        <FormattedMessage id='dialog_confirmation_discard_changes_title_web' />
      ),
      description: (
        <span>
          <FormattedMessage id='dialog_confirmation_discard_changes_web' />
        </span>
      ),
    })
      .then(() => ({ cancelConfirmed: true }))
      .catch(() => ({ cancelConfirmed: false }));
  }, [createDialog]);

  const confirmCancel = useCallback(async () => {
    const isFormClean = !isFormEditing;
    if (isFormClean) {
      return Promise.resolve({ cancelConfirmed: true });
    } else {
      return showCancelConfirmationDialog();
    }
  }, [isFormEditing, showCancelConfirmationDialog]);

  return confirmCancel;
}
