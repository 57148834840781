import { __HttpRequestModel__ } from 'shared/domain/httpRequest/httpRequestModel';
import * as config from 'serviceWorker/db/config';
import { HeadersData } from '../../factories/types';
import { FetchRequest } from '../fetchRequest';
import { IRequestRunner } from '../httpRequestRunner';

export class IssueRequestRunner implements IRequestRunner {
  constructor(
    private getConfig: () => Promise<void | config.ConfigData>,
    private fetch: (
      headersData: HeadersData,
      httpRequest: FetchRequest,
      controller: AbortController
    ) =>
      | Promise<{
          status: number;
        }>
      | undefined
  ) {}
  async execute(request: __HttpRequestModel__): Promise<any> {
    const abortController = new AbortController();
    const setup = await this.getConfig();
    if (!setup) {
      throw new Error('Cannot get config');
    }
    const response = this.fetch(
      setup,
      request as FetchRequest,
      abortController
    );
    if (!response) {
      throw new Error('Cannot fetch.');
    }
    return response;
  }
}
