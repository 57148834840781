import { BroadcastChannel } from 'broadcast-channel';
import { ChannelNames } from 'shared/domain/channelNames';
import { IssueModel } from 'shared/domain/issue/issueModel';
import {
  DomainMessagesTypes,
  ServiceMethods,
  Services,
} from 'shared/domain/messages/message';
import { IssueInDto } from 'shared/dtos/in/issue';
import { issueEntityToModel } from 'serviceWorker/repository/issue/issueEntityToModel';
import {
  GetIssueEventDetail,
  GetIssueListCustomEvent,
} from 'serviceWorker/api/types';
import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import {
  getIssueById,
  getIssuesByIds,
} from 'serviceWorker/services/issues';
import {
  getCurrentUsers,
  getUsersSynchronized,
} from 'serviceWorker/services/users/getUsers';
import { swLog } from 'serviceWorker/helpers/makeSwLogger';
import { debugLog } from 'shared/logger/debugLog';
import { LogLevel } from 'shared/types/logger';
import * as dbIssues from '../db/issues';
import * as dbIssuesService from '../db/issuesService';
import {
  makeBroadcastAll,
  makeBroadcastAllDeleted,
  makeBroadcastEntity,
} from './factories';
import { Broadcaster, OnMessage } from './types';

export const broadcastAllIssues = makeBroadcastAll(
  dbIssuesService,
  {
    get: () => {
      return Promise.resolve([]);
    },
  },
  ChannelNames.issueChannel,
  DomainMessagesTypes.allIssues
);

export const broadcastAllDeletedIssues = makeBroadcastAllDeleted(
  dbIssuesService,
  dbIssues,
  ChannelNames.issueChannel,
  DomainMessagesTypes.allDeletedIssues
);

export const broadcastIssue = makeBroadcastEntity<IssueModel>(
  {
    getOne: async function (id: string) {
      const [issue, users] = await Promise.all([
        getIssueById(id),
        getUsersSynchronized(),
      ]);
      if (!issue) {
        throw new Error(`Cannot find issue with id ${id}`);
      }

      return issueEntityToModel(issue, users);
    },
  },
  ChannelNames.issueChannel,
  DomainMessagesTypes.issue
);

export const broadcastIssueList = async (
  e: GetIssueListCustomEvent
): Promise<void> => {
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
  try {
    const issue = await getIssuesByIds(e.detail.ids);
    debugLog('broadcastIssueList', issue, e.detail.ids);
    broadcast.postMessage({
      data: { items: issue, ids: e.detail.ids },
      type: RepositoryMessagesTypes.getIssueList,
    });
  } catch (error) {
    debugLog('broadcastIssueList error', error, e);
    swLog('Error occured when broadcasting entity', LogLevel.INFO, error, {
      entityName: broadcast.name,
    });
    broadcast.postMessage({
      error: {
        ids: e.detail.ids,
        errorObject: error,
      },
      type: RepositoryMessagesTypes.getIssueList,
    });
  }
  broadcast.close();
};

function broadcastIssueSuccess(
  type: DomainMessagesTypes,
  data: IssueModel
): void {
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
  broadcast.postMessage({
    data,
    type,
  });
  broadcast.close();
}

export const broadcastCreatedIssue = async (
  issueInDto: IssueInDto
): Promise<void> => {
  try {
    await dbIssues.addBatch([issueInDto]);
    const [issue, users] = await Promise.all([
      dbIssues.getOne(issueInDto._id),
      getUsersSynchronized(),
    ]);
    if (!issue) {
      throw new Error(`Cannot find issue with id ${issueInDto._id}`);
    }
    broadcastIssueSuccess(
      DomainMessagesTypes.createdEntity,
      issueEntityToModel(issue, users)
    );
  } catch (error) {
    broadcastCreatedIssueError(error);
  }
};

export const broadcastUpdatedIssue = async (
  issueInDto: IssueInDto
): Promise<void> => {
  try {
    await dbIssues.updateBatch([issueInDto]);
    const [issue, users] = await Promise.all([
      dbIssues.getOne(issueInDto._id),
      getUsersSynchronized(),
    ]);
    if (!issue) {
      throw new Error(`Cannot find issue with id ${issueInDto._id}`);
    }
    broadcastIssueSuccess(
      DomainMessagesTypes.updatedEntity,
      issueEntityToModel(issue, users)
    );
  } catch (error) {
    broadcastUpdatedIssueError(error);
  }
};

function broadcastIssueError(type: DomainMessagesTypes, error: any): void {
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);
  broadcast.postMessage({
    error,
    type,
  });
  broadcast.close();
}

export const broadcastUpdatedIssueError = (error: any): void => {
  debugLog('broadcastUpdatedIssueError', error);
  broadcastIssueError(DomainMessagesTypes.updatedEntity, error);
};

export const broadcastCreatedIssueError = (error: any): void => {
  debugLog('broadcastUpdatedIssueError', error);
  broadcastIssueError(DomainMessagesTypes.createdEntity, error);
};

const broadcastState: Broadcaster = async (broadcast) => {
  const status = await dbIssuesService.get();
  broadcast.postMessage({ data: status, type: DomainMessagesTypes.state });
};

const broadcastFiltered: Broadcaster = async (broadcast, event) => {
  const [issues, users] = await Promise.all([
    dbIssues.getFiltered(
      event.data.filters,
      event.data.search,
      event.data.sort,
      event.data.pagination,
      event.data.archived,
      event.data.timezone
    ),
    getUsersSynchronized(),
  ]);
  const models = issues.items.map((issue) =>
    issueEntityToModel(issue, users)
  );
  postFilteredIssues(broadcast, models, issues.total, event.uniqueId);
};

const broadcastFilteredIdList: Broadcaster = async (broadcast, event) => {
  const issueIds = await dbIssues.getFilteredIdList(
    event.data.filters,
    event.data.search,
    event.data.sort,
    event.data.pagination,
    event.data.archived,
    event.data.timezone
  );
  broadcast.postMessage({
    data: issueIds,
    type: DomainMessagesTypes.filteredIssueIdList,
    uniqueId: event.uniqueId,
  });
};

const unsyncedBroadcastIssue = makeBroadcastEntity<IssueModel>(
  {
    getOne: async function (id: string) {
      const start = Date.now();
      const [issue, users] = await Promise.all([
        getIssueById(id),
        getCurrentUsers(),
      ]);
      const end = Date.now();
      debugLog('getOneIssue time:', end - start);
      if (!issue) {
        throw new Error(`Cannot find issue with id ${id}`);
      }

      return issueEntityToModel(issue, users);
    },
  },
  ChannelNames.issueChannel,
  DomainMessagesTypes.issue
);

const init = (): void => {
  const broadcast = new BroadcastChannel(ChannelNames.issueChannel);

  const handler: OnMessage = async (event) => {
    switch (event.type) {
      case DomainMessagesTypes.getState: {
        await broadcastState(broadcast);
        return;
      }
      case DomainMessagesTypes.getFiltered: {
        await broadcastFiltered(broadcast, event);
        resync();
        break;
      }
      case DomainMessagesTypes.getIssue: {
        if (!event.data) {
          return;
        }

        await unsyncedBroadcastIssue(
          new CustomEvent<GetIssueEventDetail>(
            RepositoryMessagesTypes.getIssue,
            {
              detail: { id: event.data.id },
            }
          )
        );
        resync();
        break;
      }
      case DomainMessagesTypes.getFilteredIssueIdList: {
        await broadcastFilteredIdList(broadcast, event);
        resync();
        break;
      }
    }
  };

  broadcast.onmessage = handler;
};

function postFilteredIssues(
  broadcast: BroadcastChannel,
  models: IssueModel[],
  total: number,
  uniqueId: string
): void {
  broadcast.postMessage({
    data: {
      items: models,
      total: total,
    },
    uniqueId: uniqueId,
    type: DomainMessagesTypes.filteredIssues,
  });
}

function resync(): void {
  const apiBroadcast = new BroadcastChannel(ChannelNames.apiChannel);

  apiBroadcast.postMessage({
    service: Services.ISSUES,
    method: ServiceMethods.SYNC,
  });

  apiBroadcast.close();
}

export default init;
