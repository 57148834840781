import { ErrorBoundary } from '@sentry/react';
import { WithOrganizationChannelListener } from 'components/broadcastChannelListeners/withOrganizationChannelListener';
import { WithProjectChannelListener } from 'components/broadcastChannelListeners/withProjectChannelListener';
import { WithBacktrack } from 'components/common/withBacktrack';
import { WithIsOffline } from 'components/common/withIsOffline';
import { WithIsProjectLoading } from 'components/common/withIsProjectLoading';
import { WithUploadProgressStore } from 'components/common/withUploadProgressStore';
import { WithBlobFileStorage } from 'components/core/BlobFileStorage';
import { HeaderContextProvider } from 'components/core/Layout/HeaderContext';
import { SomethingWentWrongPage } from 'components/core/SomethingWentWrongPage';
import SwToaster from 'components/core/SwToaster';
import { WithDataFlushedSubscriber } from 'components/core/WithDataFlushedSubscriber';
import { WithLogout } from 'components/core/WithLogout';
import { WithLogoutSubscriber } from 'components/core/WithLogoutSubscriber';
import { WithProjectChangeSubscriber } from 'components/core/WithProjectChangeSubscriber';
import { WithOrganizations } from 'components/dataProviders/withOrganizations';
import { WithProjects } from 'components/dataProviders/withProjects';
import { AutoSwReload } from 'components/serviceWorkerCheck';
import { IntlProviderWrapper } from 'intl/IntlProviderWrapper';
import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { AuthProvider } from 'services/auth0/react-auth0.spa';
import Routes from 'setup/navigation/routes';
import { WithProductFruits } from 'setup/productFruits';
import { ThemeProviderWrapper } from 'setup/theme';
import { APIContext } from 'with-api';
import { ClientContext } from 'with-client';
import { TableStateContext } from 'with-table';

export function AppRoot({
  api,
  client,
  tableState,
  store,
  persistor,
  history,
}: any): React.ReactElement {
  return (
    <WithIsOffline>
      <AutoSwReload />
      <APIContext.Provider value={api}>
        <ClientContext.Provider value={client}>
          <TableStateContext.Provider value={tableState}>
            {/* @ts-ignore */}
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                {/* @ts-ignore */}
                <Router history={history}>
                  <AuthProvider>
                    <IntlProviderWrapper>
                      <ThemeProviderWrapper>
                        <WithProductFruits>
                          <WithOrganizationChannelListener>
                            <WithOrganizations>
                              <WithIsProjectLoading>
                                <WithProjectChannelListener>
                                  <WithProjects>
                                    <SwToaster />
                                    <WithLogout>
                                      <WithLogoutSubscriber>
                                        <ErrorBoundary
                                          fallback={
                                            <SomethingWentWrongPage />
                                          }
                                        >
                                          <WithBacktrack>
                                            <HeaderContextProvider>
                                              <WithBlobFileStorage>
                                                <WithDataFlushedSubscriber>
                                                  <WithProjectChangeSubscriber>
                                                    <WithUploadProgressStore>
                                                      <Routes />
                                                    </WithUploadProgressStore>
                                                  </WithProjectChangeSubscriber>
                                                </WithDataFlushedSubscriber>
                                              </WithBlobFileStorage>
                                            </HeaderContextProvider>
                                          </WithBacktrack>
                                        </ErrorBoundary>
                                      </WithLogoutSubscriber>
                                    </WithLogout>
                                  </WithProjects>
                                </WithProjectChannelListener>
                              </WithIsProjectLoading>
                            </WithOrganizations>
                          </WithOrganizationChannelListener>
                        </WithProductFruits>
                      </ThemeProviderWrapper>
                    </IntlProviderWrapper>
                  </AuthProvider>
                </Router>
              </PersistGate>
            </Provider>
          </TableStateContext.Provider>
        </ClientContext.Provider>
      </APIContext.Provider>
    </WithIsOffline>
  );
}
