import { useCallback, useEffect, useMemo } from 'react';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { BroadcastChannel } from 'broadcast-channel';
import { debugLog } from 'shared/logger/debugLog';

type GetUser = {
  getUser: () => void;
};

export function useGetUser(id: string, withAutoCall?: boolean): GetUser {
  const broadcast = useMemo(
    () => new BroadcastChannel(ChannelNames.apiChannel),
    []
  );

  const getUser = useCallback(() => {
    broadcast.postMessage({
      service: Services.USERS,
      method: ServiceMethods.GET_ONE,
      data: {
        id: id,
      },
    });
    debugLog('!!!wyemitowalem event getUser', id);
  }, [broadcast, id]);

  useEffect(() => {
    if (withAutoCall) {
      getUser();
    }
  }, [getUser, withAutoCall]);

  useEffect(() => {
    return (): void => {
      broadcast.close();
    };
  }, [broadcast]);

  return {
    getUser,
  };
}
