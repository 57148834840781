import { levelModelToLevelOnView } from 'shared/domain/level/mapping/toView';
import { LevelModel } from 'shared/domain/level/types/model';
import { LevelOnView } from 'shared/domain/level/types/view';
import { SiteModel } from 'shared/domain/site/types/model';
import React, { PropsWithChildren, ReactElement, useMemo } from 'react';
import { HashMap } from 'shared/types/commonView';
import { useLevels } from '../withLevels';
import { useSites } from '../withSites';

type LevelsToSelectContextType = {
  levels: LevelModel[];
  levelsPerSite: HashMap<LevelOnView[]>;
  loading: boolean;
};
const LevelsToSelectContext = React.createContext<
  LevelsToSelectContextType | undefined
>(undefined);

function getLevelDeleted(
  site: SiteModel | undefined,
  level: LevelOnView | LevelModel
): boolean {
  if (!site) return true;
  return site.deleted || level.deleted;
}

const WithLevelsToSelect: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const { sites, loading: loadingSites } = useSites();
  const { levels: levelsResponse, loading: loadingLevels } = useLevels();

  const sitesMap = useMemo(() => {
    const map = new Map();
    sites.items.forEach((site) => map.set(site._id, site));
    return map;
  }, [sites]);

  const { levelsPerSite, levels } = useMemo((): {
    levelsPerSite: HashMap<LevelOnView[]>;
    levels: LevelModel[];
  } => {
    const lvls: LevelModel[] = [];
    const lvlsPerSite = levelsResponse.items.reduce<
      HashMap<LevelOnView[]>
    >((result, level): HashMap<LevelOnView[]> => {
      const site = sitesMap.get(level.site);
      const isDeleted = getLevelDeleted(site, level);
      const lvl = {
        ...level,
        deleted: isDeleted,
      };

      if (Array.isArray(result[level.site])) {
        result[level.site].push(levelModelToLevelOnView(lvl));
      } else {
        result[level.site] = [levelModelToLevelOnView(lvl)];
      }
      lvls.push(lvl);
      return result;
    }, {});

    return { levels: lvls, levelsPerSite: lvlsPerSite };
  }, [sitesMap, levelsResponse]);

  const ctx: LevelsToSelectContextType = useMemo(() => {
    return {
      levels: levels,
      levelsPerSite: levelsPerSite,
      loading: loadingSites || loadingLevels,
    };
  }, [levels, loadingSites, loadingLevels, levelsPerSite]);
  return (
    <LevelsToSelectContext.Provider value={ctx}>
      {children}
    </LevelsToSelectContext.Provider>
  );
};

function withLevelsToSelect<T>(Component: React.ComponentType<T>) {
  return (props: T & JSX.IntrinsicAttributes): ReactElement => (
    <WithLevelsToSelect>
      <Component {...props} />
    </WithLevelsToSelect>
  );
}

function useLevelsToSelect(): LevelsToSelectContextType {
  const context = React.useContext(LevelsToSelectContext);
  if (context === undefined) {
    throw new Error(
      'useLevelsToSelect must be used within a LevelsToSelectContextProvider'
    );
  }
  return context;
}

export { WithLevelsToSelect, useLevelsToSelect, withLevelsToSelect };
