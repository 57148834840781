import React, { ReactElement } from 'react';
import { useIntl } from 'react-intl';
import { ReactComponent as ChartNoDataIcon } from '../../../assets/icons/chart_no_data.svg';
import { useStyles } from './styles';

function EmptyChart(): ReactElement {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={classes.root}>
      <ChartNoDataIcon />
      <p className={classes.iconText}>
        {intl.formatMessage({ id: 'chart_no_data' })}
      </p>
      <div className={classes.helpers}>
        <span>
          {intl.formatMessage({ id: 'chart_no_data_helper_line_1' })}
        </span>
        <span>
          {intl.formatMessage({ id: 'chart_no_data_helper_line_2' })}
        </span>
      </div>
    </div>
  );
}

export const MemoEmptyChart = React.memo(EmptyChart);
