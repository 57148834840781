import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const styles = makeStyles<Theme, { isMobile: boolean }>(
  (theme: Theme) => ({
    sideList: {
      width: 250,
      height: '100%',
      position: 'relative' as 'relative',
      margin: theme.spacing(2, 2, 2, 2),
    },
    drawer: {
      marginTop: '64px',
      width: 290,
    },
    email: {
      marginTop: theme.spacing(1),
    },
    logOutButton: {
      marginTop: theme.spacing(2),
    },
    verticalDivider: {
      backgroundColor: 'rgba(37, 37, 37, 0.1)',
    },
  })
);
