import { ApiChannelMessage } from 'shared/domain/messages/message';
import { ServiceMethods, Services } from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';
import { debugLog } from 'shared/logger/debugLog';
import { UserEditModel } from './userModel';
import { BroadcastChannel } from 'broadcast-channel';
import { UserEditCustomEventDetail } from 'shared/domain/messages/user/eventMessages';

export function startUserEdit(
  userEditModel: UserEditModel,
  uniqueId: string
): void {
  debugLog('startUserEdit', userEditModel, uniqueId);
  const broadcast = new BroadcastChannel<
    ApiChannelMessage<UserEditCustomEventDetail>
  >(ChannelNames.apiChannel);
  broadcast.postMessage({
    service: Services.USERS,
    method: ServiceMethods.EDIT,
    data: {
      userEditModel,
      uniqueId,
    },
  });
  broadcast.close();
}
