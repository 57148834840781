import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FieldValueFlatForm } from 'components/dataCreationForms/withFieldValueForm';
import { useInputForm } from 'components/dataCreationForms/withInputForm';
import { FormFieldProps } from 'components/inspection/Form/types';
import {
  allAvailableLocaleCodes,
  getAvailableAppLocales,
} from 'intl/mappings';
import {
  CreateFieldValueOnView,
  FieldValueOnView,
  FieldValueType,
} from 'presentation/fieldValue/fieldValueOnView';
import { useIntl } from 'react-intl';
import { Identificable, LabelledEntity } from 'shared/types/commonView';
import { AVAILABLE_PROCESSES } from 'shared/types/process';
import {
  filterLanugagesToChoose,
  findLastIndex,
  getLabelChangeDisabledReason,
  getLanguageSelectDisabledReason,
} from './model';
import { FieldValueFormPresentational } from './presentational';
import { LanguageOption } from './types';

function defaultFilter<T>(x: T): boolean {
  return Boolean(x);
}

function createLanguageSelectField(
  index: number,
  filterFunction: (e: LabelledEntity) => boolean = defaultFilter,
  disabled?: string
): FormFieldProps<{ [key: string]: LabelledEntity }> {
  const available = getAvailableAppLocales().filter(filterFunction);
  return {
    formKey: `valueTranslations${index}`,
    labelId: 'field_values_language',
    required: true,
    fieldName: `field-value-select-language-${index}`,
    available: available,
    getOptionLabel: (option: { label: string }) => option.label,
    getOptionSelected: (
      option: LabelledEntity,
      value: LabelledEntity
    ): boolean => option._id === value._id,
    minRows: 3,
    disabled: disabled ? { reason: disabled } : undefined,
    reserveSpaceForHelperText: false,
    dense: false,
    'data-qa': 'field_value_language_field',
  };
}

function createLabelField(
  index: number,
  disabled?: string
): FormFieldProps<{ [key: string]: string }> {
  return {
    formKey: `label${index}`,
    required: true,
    labelId: 'field_values_label',
    fieldName: `field-value-label-${index}`,
    disabled: disabled ? { reason: disabled } : undefined,
    reserveSpaceForHelperText: false,
    dense: false,
    'data-qa': 'field_value_label_field',
  };
}

function createInitialLanguageOptions(values: {
  _field?: CreateFieldValueOnView | FieldValueOnView;
}): LanguageOption[] {
  if (!values._field) {
    return [
      {
        languageSelect: createLanguageSelectField(0),
        labelField: createLabelField(0),
        removed: false,
        key: `${Date.now()},0`,
        isRemote: false,
      },
    ];
  }
  const filterValues: string[] = [];
  return values._field.valueTranslations.map(
    (
      element: Identificable & { type: FieldValueType },
      index: number,
      translations: (Identificable & { type: FieldValueType })[]
    ) => {
      const isRemote = element.type === FieldValueType.fromRemote;
      const disabledLanguageSelect = getLanguageSelectDisabledReason({
        isHustroDefault: !values._field!.canEditTranslations,
        isRemote: isRemote,
        hasNext: index < translations.length - 1,
      });
      const disabledLabelChange = getLabelChangeDisabledReason({
        isHustroDefault: !values._field!.canEditTranslations,
      });
      const result = {
        languageSelect: createLanguageSelectField(
          index,
          filterLanugagesToChoose([...filterValues]),
          disabledLanguageSelect
        ),
        labelField: createLabelField(index, disabledLabelChange),
        removed: false,
        key: `${Date.now()},${index}`,
        isRemote,
      };
      filterValues.push(element._id);
      return result;
    }
  );
}

export function FieldValueForm(props: {
  onSubmit: () => void;
  onCancel: () => void;
  isPosting: boolean;
}): ReactElement {
  const intl = useIntl();
  const { onSubmit, onCancel, isPosting } = props;
  const { setValues, values } = useInputForm();

  const [languageOptions, setLanguageOptions] = useState(
    createInitialLanguageOptions(values as FieldValueFlatForm)
  );

  const ignoreIndexes = useMemo(() => {
    return languageOptions.reduce<number[]>((result, element, index) => {
      if (element.removed) {
        result.push(index);
      }
      return result;
    }, []);
  }, [languageOptions]);

  useEffect(() => {
    if (!values._field) {
      setValues(`valueTranslations${0}`, undefined, {
        skipFormEdit: true,
        skipValidation: true,
        forceKeySet: true,
      });
      setValues(`label${0}`, undefined, {
        skipFormEdit: true,
        skipValidation: true,
        forceKeySet: true,
      });
    }
    // onmount once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValues('ignoreIndexes', ignoreIndexes, {
      skipFormEdit: true,
      skipValidation: true,
    });
  }, [ignoreIndexes, setValues]);

  const addLanguageOption = useCallback(() => {
    setLanguageOptions((prev) => {
      const index = prev.length;
      const filterValues: string[] = [];
      const newPrev = prev.map((option) => {
        option.languageSelect.disabled = {
          reason: 'disabled_cannot_change_language_after_add_next',
        };
        if (!option.removed) {
          filterValues.push(values[option.languageSelect.formKey]._id);
        }
        return option;
      });
      setValues(`valueTranslations${index}`, undefined, {
        skipFormEdit: true,
        skipValidation: true,
        forceKeySet: true,
      });
      setValues(`label${index}`, undefined, {
        skipFormEdit: true,
        skipValidation: true,
        forceKeySet: true,
      });
      return [
        ...newPrev,
        {
          languageSelect: createLanguageSelectField(
            index,
            filterLanugagesToChoose([...filterValues])
          ),
          labelField: createLabelField(index),
          removed: false,
          key: `${Date.now()},${index}`,
          isRemote: false,
        },
      ];
    });
  }, [values, setValues]);

  const removeLanguageOption = useCallback((index) => {
    setLanguageOptions((prev) => {
      if (prev[index].isRemote) {
        return prev;
      }
      prev[index].removed = true;
      return [...prev];
    });
  }, []);

  const processesCheckboxes: FormFieldProps<{
    processes: string[];
  }> = useMemo(
    () => ({
      formKey: 'processes',
      labelId: 'field_value_processes_description',
      fieldName: `field-value-processes`,
      available: AVAILABLE_PROCESSES.map((process) => {
        return {
          _id: process._id,
          label: intl.formatMessage({ id: process.label }),
        };
      }),
      required: false,
      getOptionLabel: (option: { label: string }) => option.label,
      reserveSpaceForHelperText: false,
      dense: false,
      'data-qa': 'field_value_processes_field',
    }),
    [intl]
  );

  const disableAddLanguageOption = useMemo(() => {
    if (values._field && !values._field.canEditTranslations) {
      return 'disabled_cannot_change_hustro_default';
    }
    const activeLanguageOptions = languageOptions.filter((option) => {
      return !option.removed;
    });
    if (activeLanguageOptions.length === allAvailableLocaleCodes.length) {
      return 'disabled_cannot_add_more_translations';
    }

    const lastNonRemovedIndex = findLastIndex((option) => {
      return !option.removed;
    }, languageOptions);
    const lastNonRemoved = languageOptions[lastNonRemovedIndex];
    if (
      !values[lastNonRemoved.labelField.formKey] ||
      !values[lastNonRemoved.languageSelect.formKey]
    ) {
      return 'disabled_cannot_add_translation_must_be_filled';
    }
    return;
  }, [languageOptions, values]);

  const disableRemoveLanguageOption = useMemo(() => {
    if (values._field) {
      return !values._field.canEditTranslations;
    }
    return false;
  }, [values._field]);

  return (
    <FieldValueFormPresentational
      onSubmit={onSubmit}
      onCancel={onCancel}
      isPosting={isPosting}
      languageOptions={languageOptions}
      addLanguageOption={addLanguageOption}
      removeLanguageOption={removeLanguageOption}
      processesCheckboxes={processesCheckboxes}
      disableAddLanguageOption={disableAddLanguageOption}
      disableRemoveLanguageOption={disableRemoveLanguageOption}
    />
  );
}
