import { BroadcastChannel } from 'broadcast-channel';
import { makeSimpleBroadcastEntity } from 'serviceWorker/repository/broadcasts/factory';
import { GetDocumentsCustomEvent } from 'shared/domain/messages/document/eventMessages';
import { DomainMessagesTypes } from 'shared/domain/messages/message';
import { isGetByIds, isGetByQuery } from 'serviceWorker/api/typeGuards';
import { ChannelNames } from 'shared/domain/channelNames';
import { DocumentModel } from 'shared/domain/document/documentModel';

export function broadcastDocuments(
  e: GetDocumentsCustomEvent,
  documents: DocumentModel[]
): void {
  const broadcast = new BroadcastChannel(ChannelNames.documentChannel);
  if (isGetByIds(e.detail)) {
    broadcast.postMessage({
      data: {
        items: documents,
        type: e.detail.type,
        ids: e.detail.ids,
      },
      type: DomainMessagesTypes.documents,
    });
  }

  if (isGetByQuery(e.detail)) {
    broadcast.postMessage({
      data: {
        items: documents,
        type: e.detail.type,
        query: e.detail.query,
      },
      type: DomainMessagesTypes.documents,
    });
  }
  broadcast.close();
}

export const broadcastDocument = makeSimpleBroadcastEntity<DocumentModel>(
  DomainMessagesTypes.document,
  ChannelNames.documentChannel
);

export const broadcastDocumentUrls = makeSimpleBroadcastEntity(
  DomainMessagesTypes.documentUrls,
  ChannelNames.documentChannel
);

export const broadcastCreatedDocument = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdEntity,
  ChannelNames.documentChannel
);

export const broadcastUpdatedDocument = makeSimpleBroadcastEntity(
  DomainMessagesTypes.updatedEntity,
  ChannelNames.documentChannel
);

export const broadcastUploadedDocument = makeSimpleBroadcastEntity(
  DomainMessagesTypes.uploadedEntity,
  ChannelNames.documentChannel
);

export const broadcastFailUploadedDocument = makeSimpleBroadcastEntity(
  DomainMessagesTypes.failUploadedEntity,
  ChannelNames.documentChannel
);

export const broadcastRemovedDocument = makeSimpleBroadcastEntity(
  DomainMessagesTypes.deletedEntity,
  ChannelNames.documentChannel
);

export const broadcastCreatedDrawing = makeSimpleBroadcastEntity(
  DomainMessagesTypes.createdDrawing,
  ChannelNames.documentChannel
);
