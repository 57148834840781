import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useBacktrack } from 'components/common/withBacktrack';
import { withFetcherReady } from 'components/common/withFetcherReady';
import { withProcessesData } from 'components/common/withProcessesData';
import { MemoLongCompanyForm } from 'components/company/Form';
import { Spinner } from 'components/core';
import {
  useEditCompany,
  withEditCompany,
} from 'components/dataCreationForms/company/withEditCompany';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { withCompanies } from 'components/dataProviders/withCompanies';
import { withContracts } from 'components/dataProviders/withContracts';
import { withSites } from 'components/dataProviders/withSites';
import { FormBottonButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import { useCallback, memo } from 'react';
import { useIntl } from 'react-intl';
import { withCreateSite } from 'components/dataCreationForms/site/withCreateSite';

function EditCompanyWizardContainer(): React.ReactElement {
  const {
    submitForm,
    isPosting,
    existingValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    disableInvolvement,
    validate,
    availableSites,
  } = useEditCompany();
  const intl = useIntl();

  const { back } = useBacktrack();
  const formName = 'company';
  useFormCleanupOnUnmount(formName);
  const onCancel = useCallback(() => {
    back();
  }, [back]);
  // TODO we should not wait for sites.
  // Sometimes location fields renders with existing input, sometimes not.
  // I guess it has something to do with Stateful component logic which does not rerender location field
  // This happens in scenario when it initially received empty array and after this array with some content (once per few form renders)
  // Didn't have time to fix this, needs to be done carefully & without breaking other forms
  if (!existingValues || !existingValues?.sites?.length) {
    return (
      <Spinner
        reason={`EditCompanyWizardContainer Waiting for existing values: ${existingValues} or sites: ${existingValues?.sites}`}
      />
    );
  }
  return (
    <WithInputForm
      values={existingValues}
      errors={{}}
      handleFormValidation={handleFormValidation}
      handleFieldValidation={validate}
      onSubmit={submitForm}
      eventType={SUBMIT_EVENT_TYPE}
      dispatchSubmit={releaseSubmitEvent}
      formName={formName}
    >
      <Typography sx={{ m: 3 }} variant='h1' margin='dense'>
        {intl.formatMessage({ id: 'edit_company_section' })}
      </Typography>
      <Divider />

      <Box sx={{ p: 3, mb: 8 }}>
        <MemoLongCompanyForm
          disableInvolvement={disableInvolvement}
          sites={availableSites}
          isDialog={false}
          initialValues={existingValues}
        />
      </Box>
      <FormBottonButtons
        isPosting={isPosting}
        onSubmit={releaseSubmitEvent}
        onCancel={onCancel}
      />
    </WithInputForm>
  );
}

export const MemoEditCompanyWizardContainer = memo(
  withFetcherReady(
    withProcessesData(
      withSites(
        withCompanies(
          withContracts(
            withEditCompany(withCreateSite(EditCompanyWizardContainer))
          )
        )
      )
    )
  )
);
