import { Color } from 'shared/utils/colors';

export type RenderableProcess = {
  color: Color;
  code: string;
};

export enum ProcessIconSize {
  small = 'small',
  default = 'default',
  issueCard = 'issueCard',
}

export type ProcessIconProps = {
  color: Color;
  code: string;
  size?: ProcessIconSize;
  className?: string;
};
