import { CircularProgress } from '@mui/material';
import { CellRenderer } from 'components/table/types';
import { ReactElement } from 'react';

export function LoadingCellRenderer<T>(
  key: keyof T,
  OutputComponent: ({ value }: { value: any }) => ReactElement
): CellRenderer<T> {
  return ({ rowData, cellData }): ReactElement => {
    if (
      !rowData[key] ||
      !Object.keys(rowData[key] as unknown as object).length
    ) {
      return <CircularProgress size={16} />;
    }
    return <OutputComponent value={cellData} />;
  };
}
