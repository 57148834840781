import React, { ReactElement } from 'react';
import classNames from 'classnames';
import { useStyles } from './styles';
import { useIntl } from 'react-intl';
import { IssueStageProps, IssueStageSize, StageLabel } from './types';

function IssueStage(props: IssueStageProps): ReactElement {
  const classes = useStyles();
  const intl = useIntl();
  const { stage, issueArchived, size } = props;

  return (
    <div
      className={classNames(
        classes.issueStatus,
        classes[size || IssueStageSize.small],
        !issueArchived && classes[stage]
      )}
    >
      {intl.formatMessage({ id: StageLabel[stage] })}
    </div>
  );
}

export const MemoIssueStage = React.memo(IssueStage);
