import { createStyles, makeStyles } from '@mui/styles';
import { Theme as DefaultTheme } from '@mui/material';
import { MobileTreshold } from '../../../../hooks/useIsMobile';

export const useInspectionFormStyles = makeStyles<
  DefaultTheme,
  { isMobile: boolean }
>((theme: DefaultTheme) =>
  createStyles({
    root: {
      maxWidth: ({ isMobile }): string => (isMobile ? '100%' : '854px'),
      margin: ({ isMobile }): string =>
        isMobile ? '16px 16px 0 16px' : '40px 0 0 54px',
      '& .m0': {
        margin: 0,
      },
      '& .norm-preview-uploader-wrapper': {
        marginBottom: 0,
        '& > div': {
          marginBottom: 0,
        },
      },
      '& .norm-preview-content': {
        margin: ({ isMobile }): string | number =>
          isMobile ? 0 : '0 16px',
      },
      '& .norm-preview-container h2': {
        marginTop: 0,
      },
      '& .MuiFormControl-root': {
        width: '100%',
      },
      '& .MuiOutlinedInput-adornedEnd': {
        paddingRight: 0,
      },
      '& .protocol-header-root h2': {
        margin: ({ isMobile }): string | number =>
          isMobile ? 0 : 'initial',
      },
      '& .divider': {
        position: ({ isMobile }): 'relative' | 'unset' =>
          isMobile ? 'relative' : 'unset',
        left: ({ isMobile }): string => (isMobile ? '-16px' : 'unset'),
        width: ({ isMobile }): string =>
          isMobile ? 'calc(100% + 32px)' : 'unset',
      },
    },
    checklist: {
      marginLeft: ({ isMobile }): string | number =>
        isMobile ? 0 : '16px',
      gridColumn: '1 / -1',
    },
    bottomSpace: {
      width: '100%',
      marginTop: '100px',
    },
  })
);
