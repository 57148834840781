import React, { ReactElement } from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonTextIcon } from 'components/general';
import { SaveNavigationTarget } from '../../../../hooks/useNavigationTarget';
import { useStyles } from './styles';
import useIsMobile from '../../../../hooks/useIsMobile';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ProjectNavigationTarget } from '../../../../views/projects/settings';
export type NavigationTabProps = {
  tabTarget: ProjectNavigationTarget;
  currentTarget: ProjectNavigationTarget;
  baseLocation: string;
  setTarget: SaveNavigationTarget;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  isExpanded: boolean;
};

function matchTabLabelIdByTarget(target: ProjectNavigationTarget): string {
  switch (target) {
    case ProjectNavigationTarget.generalProjectData:
      return 'project_settings_navigation_general_project_data';
    case ProjectNavigationTarget.fieldValues:
      return 'project_settings_navigation_field_values';
    case ProjectNavigationTarget.projectStructure:
      return 'project_settings_navigation_project_structure';
    case ProjectNavigationTarget.fieldVisibility:
      return 'project_settings_navigation_field_visibility';
    case ProjectNavigationTarget.organizationData:
      return 'project_settings_navigation_organization_data';
  }
}

export function NavigationTab(props: NavigationTabProps): ReactElement {
  const isMobile = useIsMobile();
  const { currentTarget, tabTarget, isExpanded, setExpanded, setTarget } =
    props;
  const isCurrentTab = currentTarget === tabTarget;

  const classes = useStyles({
    isMobile,
    isCurrentTab,
    isExpanded: props.isExpanded,
  });

  return (
    <ButtonTextIcon
      style={{ zIndex: 2 }}
      key={tabTarget}
      aria-label={tabTarget}
      onClick={() => {
        if (isMobile) {
          if (!isExpanded) {
            return setExpanded(true);
          }
          setExpanded(false);
        }
        setTarget(tabTarget);
      }}
      data-qa={`${tabTarget}-button`}
      className={classes.tab}
    >
      <FormattedMessage id={matchTabLabelIdByTarget(tabTarget)} />
      <span className={classes.icon}>
        {isMobile &&
          isCurrentTab &&
          (isExpanded ? (
            <KeyboardArrowUpIcon />
          ) : (
            <KeyboardArrowDownIcon />
          ))}
      </span>
    </ButtonTextIcon>
  );
}
