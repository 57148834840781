import { FormFieldProps } from 'components/inspection/Form/types';
import { OrganizationCreateModel } from 'shared/domain/organization/types/model';
import { ReactElement, useMemo } from 'react';
import { OrganizationFormPresentational } from './presentational';

export function OrganizationForm({
  isDialog,
}: {
  isDialog: boolean;
}): ReactElement {
  const nameFieldProps: FormFieldProps<OrganizationCreateModel> = useMemo(
    () => ({
      formKey: 'name',
      required: true,
      labelId: 'organization_name',
      fieldName: 'text-field-organization-name',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'organization_name_field',
      localStorageKey: 'organization_name',
    }),
    [isDialog]
  );
  const labelFieldProps: FormFieldProps<OrganizationCreateModel> = useMemo(
    () => ({
      formKey: 'label',
      required: true,
      labelId: 'organization_label',
      fieldName: 'text-field-organization-label',
      dense: true,
      reserveSpaceForHelperText: !isDialog,
      'data-qa': 'organization_label_field',
      localStorageKey: 'organization_label',
    }),
    [isDialog]
  );
  const contactEmailFieldProps: FormFieldProps<OrganizationCreateModel> =
    useMemo(
      () => ({
        formKey: 'contactEmail',
        required: true,
        labelId: 'organization_contactEmail',
        fieldName: 'text-field-organization-contactEmail',
        dense: true,
        reserveSpaceForHelperText: !isDialog,
        'data-qa': 'organization_contactEmail_field',
        localStorageKey: 'organization_contactEmail',
      }),
      [isDialog]
    );

  return (
    <OrganizationFormPresentational
      nameFieldProps={nameFieldProps}
      labelFieldProps={labelFieldProps}
      contactEmailFieldProps={contactEmailFieldProps}
    />
  );
}
