import { useGetAllCorrectiveActionTypes } from 'hooks/useGetAllCorrectiveActionTypes';
import { CorrectiveActionTypeOnView } from 'presentation/fieldValue/fieldValueOnView';
import { FieldValueFactory } from '../../common/fieldValueFactory';

export const {
  Component: WithCorrectiveActionTypes,
  composeComponent: withCorrectiveActionTypes,
  useContextFunction: useCorrectiveActionTypes,
} = FieldValueFactory<CorrectiveActionTypeOnView>(
  useGetAllCorrectiveActionTypes
);
