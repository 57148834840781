import { SiteEditOutDto, SiteCreateOutDto } from 'shared/dtos/out/site';
import { SiteEditModel, SiteCreateModel } from '../types/model';

export function siteEditModelToSiteChangeOutDto(
  model: SiteEditModel
): SiteEditOutDto {
  return {
    _id: model._id,
    label: model.label,
    code: model.code,
  };
}

export function siteCreateModelToSiteCreateOutDto(
  model: SiteCreateModel
): SiteCreateOutDto {
  return {
    label: model.label,
    code: model.code,
  };
}
