import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  dashboardContainer: {
    padding: '24px 24px 120px 24px',
    backgroundColor: '#F2F2F2',
  },

  cardTitle: {
    fontFamily: 'Archivo',
    fontSize: '1rem',
    fontWeight: 600,
    marginTop: 0,
    lineHeight: '21px',
  },
});
