import { EmptyListReason } from 'components/common/noTableContentInfo/NoTableContentInfo';
import { useMemo } from 'react';

export function useEmptyListReason(
  searchPhrase: string,
  total: number,
  activeFiltersLength: number
): EmptyListReason | undefined {
  return useMemo(() => {
    const hasSearch = Boolean(searchPhrase);
    if (total === 0) {
      if (activeFiltersLength > 0) {
        return EmptyListReason.FILTER_MISMATCH;
      }
      if (hasSearch) {
        return EmptyListReason.SEARCHPHRASE_MISMATCH;
      }
      return EmptyListReason.NOTHING_IN_DB;
    }

    return undefined;
  }, [searchPhrase, total, activeFiltersLength]);
}
