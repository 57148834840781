import Dexie, { PromiseExtended } from 'dexie';
import db from './index';
import { broadcastClearDirectories } from 'serviceWorker/broadcasts/directories';
import { entityDbClearFactory } from './entityDbClearFactory';
import { clear as directoriesServiceClear } from './directoriesService';
import { wrapQuery } from './queryWrapper';
import {
  DirectoryEntity,
  DirectoryInsertEntity,
  DirectoryLocalInsertEntity,
} from 'serviceWorker/repository/directory/entity';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { DirectoryInDto } from 'shared/dtos/in/directory';
import { directoryInDtoToCreateEntity } from 'serviceWorker/repository/directory/mappings';
import { nowISO } from 'shared/utils/date/dates';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { Changes } from 'shared/types/commonEntities';
import {
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

export const clear = entityDbClearFactory(
  db,
  ['directories', 'directoriesService'],
  () => db.directories.clear(),
  directoriesServiceClear,
  broadcastClearDirectories
);

const update = wrapQuery(
  db,
  (
    directoryFindStrategy: () => Promise<DirectoryEntity | undefined>,
    data: DirectoryInsertEntity
  ): Promise<any> => {
    return directoryFindStrategy().then(
      (directory: DirectoryEntity | undefined) => {
        if (!directory) {
          throw new Error('Cannot find directory to update');
        } else {
          const directoryWithLocalData = keepLocalDataOnUnuploadedStatus(
            data,
            directory
          );

          return db.directories.update(
            directory.localId,
            directoryWithLocalData
          );
        }
      }
    );
  }
);

export const quantity = makeDefaultCount<DirectoryEntity>(
  db,
  db.directories
);

export const get = makeDefaultGetMany<DirectoryEntity>(
  db,
  db.directories,
  { deleted: 0 }
);

export const getByQuery = makeDefaultGetMany<DirectoryEntity>(
  db,
  db.directories,
  { deleted: 0 }
);

export const getByIds = makeDefaultGetManyByIds<number, DirectoryEntity>(
  db,
  db.directories,
  'localId'
);

export const getOneByRemote = makeDefaultGetOne<string, DirectoryEntity>(
  db,
  db.directories,
  '_id'
);

export const getOne = makeDefaultGetOne<number, DirectoryEntity>(
  db,
  db.directories,
  'localId'
);

export const fastAddBatch = wrapQuery(
  db,
  (data: DirectoryInDto[]): Promise<any> => {
    const toInsert = data.map((directory) => {
      return directoryInDtoToCreateEntity(directory);
    });
    return (
      db.directories
        // @ts-ignore DirectoryEntityToCreate localId is an autoincrement
        .bulkPut(toInsert)
        .catch((e) => {
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.upsertBatch, 'directories'),
            errorObj: e,
            additionalInfo: { query: { data } },
          });
        })
    );
  }
);

const upsertBatch = wrapQuery(
  db,
  async (data: DirectoryInDto[]): Promise<any> => {
    const primaryKeys = await db.directories.orderBy('_id').keys();

    const duplicates: DirectoryInsertEntity[] = [];
    const docMap = {};
    const newData = data.filter((directory) => {
      if (primaryKeys.includes(directory._id) || docMap[directory._id]) {
        docMap[directory._id] = true;
        duplicates.push(directoryInDtoToCreateEntity(directory));
        return false;
      }
      docMap[directory._id] = true;
      return true;
    });

    await fastAddBatch(newData);

    const modifications: Promise<any>[] = data.map((directory) => {
      const directoryCreateEntity =
        directoryInDtoToCreateEntity(directory);
      return (
        db.directories
          // @ts-ignore DirectoryEntityToCreate localId is an autoincrement
          .add(directoryCreateEntity)
          .catch((e) => {
            if (e.name === Dexie.errnames.Constraint) {
              const directoryFindStrategy = (): Promise<
                DirectoryEntity | undefined
              > => {
                if (!directory._id) {
                  return Promise.resolve(undefined);
                }
                return getOneByRemote(directory._id);
              };
              return update(directoryFindStrategy, directoryCreateEntity);
            }
            logWithRethrow({
              logLevel: LogLevel.INFO,
              msg: getErrorMsg(Operation.upsertBatch, 'directories'),
              errorObj: e,
              additionalInfo: { query: { data } },
            });
          })
      );
    });

    return Promise.all(modifications) as Promise<any>;
  }
);

export const addBatch = upsertBatch;
export const updateBatch = upsertBatch;

export const addLocal = wrapQuery(
  db,
  (data: DirectoryLocalInsertEntity): PromiseExtended<number> => {
    // @ts-ignore DirectoryLocalInsertEntity localId is an autoincrement
    return db.directories.add(data).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.add, 'directories'),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<string, DirectoryEntity>(
  db,
  db.directories,
  '_id'
);

export const updateOne = wrapQuery(
  db,
  async (
    localId: number,
    changes: Changes<DirectoryEntity>,
    options?: { skipModifyTime: boolean }
  ): Promise<any> => {
    const modifiedAt = options?.skipModifyTime
      ? {}
      : { modifiedAt: nowISO() };
    return db.directories
      .update(localId, { ...changes, ...modifiedAt })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.updateOne, 'directories'),
          errorObj: e,
          additionalInfo: { query: { ...changes, localId } },
        })
      );
  }
);

function keepLocalDataOnUnuploadedStatus(
  directoryToInsert: DirectoryInsertEntity,
  existingEntity: DirectoryEntity
): DirectoryInsertEntity {
  if (existingEntity.syncStatus === SyncStatus.PENDING_DELETE) {
    directoryToInsert.deleted = 1;
  }

  return directoryToInsert;
}
