import { arrayFilterOutDuplicates } from '../../helpers/misc';
import * as actions from '../actionTypes';

import {
  FileInfo,
  IssueDocument,
  IssueProp,
} from '../../setup/types/core';

import { Identificable } from 'shared/types/commonView';
import { UploadStatus } from 'shared/types/uploadStatus';
import {
  createReducer,
  makeAction,
  updateInArray,
  updateState,
} from '../helpers';

export const initialState: IssueProp = {
  list: [],
  error: null,
  loading: false,
  syncData: {},
  activeCount: 0,
  deletedCount: 0,
  hasMore: true,
  fetchedDeleted: false,
  fetchedActive: false,
};

const showLoader = (state: any): IssueProp =>
  updateState(state, { loading: true });

const createIssue = (state: any, action: any): IssueProp => {
  const { issue } = action.payload;

  return {
    ...state,
    list: [issue].concat(state.list),
  };
};

const updateIssueData = (state: any, action: any): IssueProp => {
  const { issue } = action.payload;

  const updatedList = updateInArray(
    state.list,
    issue._id,
    (updatedIssue: any) => {
      return {
        ...updatedIssue,
      };
    }
  );

  return updateState(state, {
    list: updatedList,
    loading: false,
  });
};

const fetchIssueFailure = (state: any, action: any): IssueProp =>
  updateState(state, { error: action.payload, loading: false });

const fetchIssueFinish = (state: any): IssueProp =>
  updateState(state, { loading: false });

const fetchIssueSuccess = (
  state: any,
  action: any
): Partial<IssueProp> => {
  return updateState(state, {
    loading: action.payload.hasMore,
    syncData: { ...action.payload.syncData },
    list: arrayFilterOutDuplicates(
      state.list.concat(action.payload.issues),
      '_id'
    ),
    hasMore: action.payload.hasMore,
    ...(action.payload.fetchedActive && {
      fetchedActive: action.payload.fetchedActive,
      activeCount: action.payload.totalCount,
    }),
    ...(action.payload.fetchedDeleted && {
      fetchedDeleted: action.payload.fetchedDeleted,
      deletedCount: action.payload.totalCount,
    }),
  });
};

const updateIssue = (state: any, action: any): IssueProp => {
  const { issue } = action.payload;

  const updatedList = updateInArray(state.list, issue._id, () => issue);

  return updateState(state, {
    list: updatedList,
    loading: false,
  });
};

const deleteIssue = (state: any, action: any): IssueProp => ({
  ...state,
  list: state.list.filter((issue: any) => issue._id !== action.issueId),
  loading: false,
});

const restoreIssue = (state: any, action: any): IssueProp => {
  const { issueId } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    issue.deleted = false;

    return issue;
  });

  return updateState(state, {
    list: updatedList,
  });
};

const addEvent = (state: any, action: any): IssueProp => {
  const { issueId, event } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    issue.events.push(event);

    return issue;
  });

  return updateState(state, { list: updatedList, loading: false });
};

const updateEvent = (state: any, action: any): IssueProp => {
  const { issueId, event } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    const eventIndex = issue.events.findIndex(
      (ev: any) => ev._id === event._id
    );

    event.documents = event.documents.filter((doc: any) => !doc.deleted);
    issue.events[eventIndex] = event;

    return issue;
  });

  return updateState(state, { list: updatedList, loading: false });
};

const deleteEvent = (state: any, action: any): IssueProp => {
  const { issueId, eventId } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    issue.events = issue.events.filter(
      (event: Identificable) => event._id !== eventId
    );

    return issue;
  });

  return updateState(state, {
    list: updatedList,
    loading: false,
  });
};

const addDocument = (state: any, action: any): IssueProp => {
  const { issueId, eventId, document } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    if (eventId) {
      const issueEvent = issue.events.find(
        (event: Identificable) => event._id === eventId
      );

      if (
        issueEvent.documents
          .map((isDoc: any) => isDoc._id)
          .indexOf(document._id) < 0
      ) {
        issueEvent.documents.push(document);
      }
    } else {
      issue.primaryData.documents.push(document);
    }

    return issue;
  });

  return updateState(state, {
    list: updatedList,
  });
};

const deleteDocument = (state: any, action: any): IssueProp => {
  const { issueId, eventId, documentId } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    if (eventId) {
      const issueEvent = issue.events.find(
        (event: Identificable) => event._id === eventId
      );

      if (issueEvent) {
        issueEvent.documents = issueEvent.documents.filter(
          (doc: IssueDocument) => doc._id !== documentId
        );
      }
    } else {
      issue.primaryData.documents = issue.primaryData.documents.filter(
        (doc: IssueDocument) => doc._id !== documentId
      );
    }

    return issue;
  });

  return updateState(state, { list: updatedList });
};

const updateDocument = (state: any, action: any): IssueProp => {
  const { issueId, eventId, document } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    if (eventId) {
      const issueEvent = issue.events.find(
        (event: Identificable) => event._id === eventId
      );

      if (issueEvent) {
        const documentIndex = issueEvent.documents.findIndex(
          (doc: IssueDocument) => doc._id === document._id
        );

        issueEvent.documents[documentIndex] = document;
      }
    } else {
      const documentIndex = issue.primaryData.documents.findIndex(
        (doc: IssueDocument) => doc._id === document._id
      );

      issue.primaryData.documents[documentIndex] = document;
    }

    return issue;
  });

  return updateState(state, { list: updatedList });
};

const findIssueDocumentLevel = (
  issue: any,
  eventId: any
): IssueDocument[] | [] => {
  if (eventId) {
    return issue.events.find(
      (event: Identificable) => event._id === eventId
    ).documents;
  }

  return issue.primaryData.documents;
};

const setDocumentUploadStatus = (
  state: any,
  action: any,
  uploadStatus: UploadStatus
): IssueProp => {
  const { issueId, eventId, documentId } = action.payload;

  const updatedList = updateInArray(state.list, issueId, (issue: any) => {
    const issueDocumentPlace = findIssueDocumentLevel(issue, eventId);

    const document = issueDocumentPlace.find(
      (issDoc: IssueDocument) => issDoc._id === documentId.toString()
    );

    if (document) {
      (document.data as FileInfo).uploadStatus = uploadStatus;
    }

    return issue;
  });

  return updateState(state, {
    list: updatedList,
    loading: false,
  });
};

const confirmDocumentStatus = (state: any, action: any): IssueProp =>
  setDocumentUploadStatus(state, action, UploadStatus.success);

const failDocumentStatus = (state: any, action: any): IssueProp =>
  setDocumentUploadStatus(state, action, UploadStatus.failed);

const logout = (): IssueProp => {
  return initialState;
};

const showingLoaderActions = makeAction(
  [actions.FETCH_ISSUES_BEGIN],
  showLoader
);

const clearSyncData = (state: any): IssueProp =>
  updateState(state, { syncData: {} });

const clearIssueList = (state: any): IssueProp =>
  updateState(state, { list: [] });

const fetchDeleted = (state: any): IssueProp => {
  return updateState(state, {
    loading: true,
    fetchedDeleted: true,
  });
};

const issueReducer = createReducer(initialState, {
  [actions.USER_LOGOUT]: logout,

  [actions.FETCH_ISSUES_BEGIN]: showLoader,
  [actions.FETCH_ISSUES_DELETED]: fetchDeleted,
  [actions.FETCH_ISSUES_SUCCESS]: fetchIssueSuccess,
  [actions.FETCH_ISSUES_FINISH]: fetchIssueFinish,
  [actions.FETCH_ISSUE_FAILURE]: fetchIssueFailure,

  [actions.ISSUE_CREATE_SUCCESS]: createIssue,
  [actions.ISSUE_CREATE_FILL]: updateIssueData,
  [actions.ISSUE_DELETE_SUCCESS]: deleteIssue,
  [actions.ISSUE_RESTORE_SUCCESS]: restoreIssue,

  [actions.EVENT_CREATE]: addEvent,
  [actions.EVENT_EDIT_SUCCESS]: updateEvent,
  [actions.EVENT_DELETE_SUCCESS]: deleteEvent,

  [actions.DOCUMENT_CONFIRMATION_UNKNOWN]: addDocument,
  [actions.DOCUMENT_DELETE_SUCCESS]: deleteDocument,
  [actions.DOCUMENT_CONFIRMATION_SUCCESS]: confirmDocumentStatus,
  [actions.DOCUMENT_CONFIRMATION_FAILURE]: failDocumentStatus,
  [actions.DOCUMENT_UPDATE_SUCCESS]: updateDocument,

  [actions.UPDATE_ISSUE_SUCCESS]: updateIssue,
  [actions.CLEAR_SYNC_DATA]: clearSyncData,
  [actions.CLEAR_ISSUE_LIST]: clearIssueList,
  ...showingLoaderActions,
});

export default issueReducer;
