export enum HttpRequestModelType {
  issue = 'issue',
  document = 'document',
  drawing = 'drawing',
  report = 'report',
  documentation = 'documentation',
  level = 'level',
  directory = 'directory',
  documentationMove = 'documentationMove',
  documentationDelete = 'documentationDelete',
  site = 'site',
  visibleField = 'visibleField',
  project = 'project',
  organization = 'organization',
  company = 'company',
  contract = 'contract',
  user = 'user',
}

export enum HttpRequestStatus {
  FAILED = 'FAILED',
  NEW = 'NEW',
}

/**
 * @deprecated Use HttpRequestModel instead or subtypes (HttpGetRequestModel, HttpPostRequestModel, HttpPutRequestModel, HttpDeleteRequestModel)
 */
export interface __HttpRequestModel__ {
  _id?: number;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url?: string;
  data?: any;
  createdAt: number;
  entityType: HttpRequestModelType;
  status: HttpRequestStatus;
}

export type HttpRequestEntity = {
  _id: number;
} & HttpRequestModel<any>;

export type HttpRequestModel<RequestData> = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  url: string;
  data: RequestData;
  createdAt: number;
  entityType: HttpRequestModelType;
  status: HttpRequestStatus;
};

export interface HttpPostRequestModel<
  RequestData,
  EntityType extends HttpRequestModelType,
> extends HttpRequestModel<RequestData> {
  method: 'POST';
  entityType: EntityType;
}

export interface HttpPutRequestModel<
  RequestData,
  EntityType extends HttpRequestModelType,
> extends HttpRequestModel<RequestData> {
  method: 'PUT';
  entityType: EntityType;
}

export interface HttpDeleteRequestModel<
  RequestData,
  EntityType extends HttpRequestModelType,
> extends HttpRequestModel<RequestData> {
  method: 'DELETE';
  entityType: EntityType;
}

export interface HttpGetRequestModel<
  RequestData,
  EntityType extends HttpRequestModelType,
> extends HttpRequestModel<RequestData> {
  method: 'GET';
  entityType: EntityType;
}
