import { RepositoryMessagesTypes } from 'serviceWorker/const/events';
import { makeDefaultSync } from './apiFactories';
import { GetChartEventDetail } from './types';
import { Chart } from 'shared/types/analytics';

const getOne = (data: {
  chart: Chart;
  filters: any;
  locale: string;
  additionalParams?: any;
  forceUpdate?: boolean;
}): void => {
  self.dispatchEvent(
    new CustomEvent<GetChartEventDetail>(
      RepositoryMessagesTypes.getChart,
      {
        detail: {
          chart: data.chart,
          filters: data.filters,
          locale: data.locale,
          additionalParams: data.additionalParams,
          forceUpdate: data.forceUpdate,
        },
      }
    )
  );
};

const sync = makeDefaultSync(RepositoryMessagesTypes.getFinancialData);

export default { getOne, sync };
