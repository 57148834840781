import { ReactElement } from 'react';
import Divider from '@mui/material/Divider';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      backgroundColor: `#5D5D5D`,
    },
  })
);
export function GalleryDivider(): ReactElement {
  const classes = useStyles();
  return <Divider className={classes.root} />;
}
