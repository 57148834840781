import db from './index';
import { PromiseExtended } from 'dexie';
import { InspectionInDto } from 'shared/dtos/in/inspection';
import { clear as inspectionsServiceClear } from './inspectionsService';
import { broadcastClearInspections } from 'serviceWorker/broadcasts/inspections';
import { entityDbClearFactory } from './entityDbClearFactory';
import {
  logWithRethrow,
  getErrorMsg,
  Operation,
  getErrorTransactionMsg,
} from './helpers';
import { wrapQuery } from './queryWrapper';
import { ProtocolItemInDto } from 'shared/dtos/in/protocolItem';
import { DocumentInDto } from 'shared/dtos/in/document';
import { inDtoToDocumentInsertEntity } from 'serviceWorker/repository/document/documentInDtoToEntity';
import {
  upsertBatch as upsertDocumentsBatch,
  removeBatchByParentId as removeDocumentsBatch,
  removeBatchByParentType as removeDocumentsBatchByType,
} from './documents';
import { InspectionEntity } from 'serviceWorker/repository/inspection/entity';
import { inspectionDtoToEntityToSave } from 'serviceWorker/repository/inspection/inspectionDtoToEntity';
import {
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
} from './defaultDaoFactories';
import { LogLevel } from 'shared/types/logger';

const clearInspections = wrapQuery(
  db,
  (): Promise<void> =>
    db.transaction(
      'rw',
      db.inspections,
      db.documents,
      async function transactionClearInspections(): Promise<void> {
        await Promise.all([
          db.inspections.clear().catch((e) =>
            logWithRethrow({
              logLevel: LogLevel.INFO,
              msg: getErrorMsg(Operation.clear, 'inspections'),
              errorObj: e,
              additionalInfo: null,
            })
          ),
          removeDocumentsBatchByType('inspectionId'),
        ]);
      }
    )
);

export const clear = entityDbClearFactory(
  db,
  ['inspections', 'inspectionsService', 'documents'],
  clearInspections,
  inspectionsServiceClear,
  broadcastClearInspections
);

export const quantity = makeDefaultCount<InspectionEntity>(
  db,
  db.inspections,
  {
    deleted: 0,
  }
);

export const get = makeDefaultGetMany<InspectionEntity>(
  db,
  db.inspections,
  { deleted: 0 }
);

export const getOne = makeDefaultGetOne<string, InspectionEntity>(
  db,
  db.inspections,
  '_id'
);

export const addBatch = wrapQuery(
  db,
  (data: InspectionInDto[]): PromiseExtended<unknown> => {
    return db
      .transaction('rw', db.inspections, db.documents, function () {
        const documentsData = data.flatMap(
          (inspection: InspectionInDto) => {
            const docs = inspection.protocol.flatMap(
              (protocolItem: ProtocolItemInDto) =>
                protocolItem.complianceCheck.documents.map(
                  (document: DocumentInDto) =>
                    inDtoToDocumentInsertEntity(document, {
                      inspectionId: inspection._id,
                      protocolId: protocolItem._id,
                    })
                )
            );
            return docs;
          }
        );

        return Promise.all([
          db.inspections.bulkPut(data.map(inspectionDtoToEntityToSave)),
          upsertDocumentsBatch(documentsData),
        ]);
      })
      .catch((e) => {
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.addBatch],
            ['inspections', 'documents']
          ),
          errorObj: e,
          additionalInfo: {},
        });
      });
  }
);

export const updateBatch = addBatch;

export const removeBatch = wrapQuery(
  db,
  async (ids: string[]): Promise<string[]> => {
    return db
      .transaction('rw', db.inspections, db.documents, async function () {
        const deleteIssues = db.inspections
          .where('_id')
          .anyOf(ids)
          .delete()
          .catch((e) =>
            logWithRethrow({
              logLevel: LogLevel.INFO,
              msg: getErrorMsg(Operation.removeBatch, 'inspections'),
              errorObj: e,
              additionalInfo: { query: { ids } },
            })
          );
        const deleteDocuments = removeDocumentsBatch({
          inspectionId: ids,
        });

        await Promise.all([deleteIssues, deleteDocuments]);
        return ids;
      })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorTransactionMsg(
            [Operation.removeBatch],
            ['inspections', 'documents']
          ),
          errorObj: e,
          additionalInfo: {
            query: {
              ids,
            },
          },
        })
      );
  }
);
