import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import {
  updateOne as updateDocumentation,
  getOne,
} from 'serviceWorker/db/documentations';

export interface SetVersionSync {
  (localId: number, syncStatus: SyncStatus): Promise<number>;
}

export const setVersionSync: SetVersionSync =
  async function _setVersionSync(localId, syncStatus) {
    const documentation = await getOne(localId);
    const version = documentation?.versions[0];
    if (!version) {
      return Promise.resolve(1);
    }
    return updateDocumentation(localId, {
      versions: [{ ...version, syncStatus }],
    });
  };
