import { useSiteChannelListener } from 'components/broadcastChannelListeners/withSiteChannelListener';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { SiteModel } from 'shared/domain/site/types/model';
import { ChannelNames } from 'shared/domain/channelNames';
import { makeUseGetAll } from './makeUseGetAll';

function isMatchingMessage(event: Message): boolean {
  return (
    Boolean(event.data) && event.type === DomainMessagesTypes.allSites
  );
}

export const useGetAllSites = makeUseGetAll<SiteModel>({
  useChannelListener: useSiteChannelListener,
  postMessageChannel: ChannelNames.siteChannel,
  postMessageType: DomainMessagesTypes.getAllSites,
  isMatchingMessage,
  entityName: 'sites',
});
