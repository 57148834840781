import { DialogActions, DialogContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import Divider from '@mui/material/Divider';
import { useCreateSite } from 'components/dataCreationForms/site/withCreateSite';
import { WithInputForm } from 'components/dataCreationForms/withInputForm';
import { DialogButtons } from 'components/general/ButtonsPanel/ButtonsPanel';
import { MemoSiteForm } from 'components/site/single/form';
import { useFormCleanupOnUnmount } from 'presentation/dialogForms/dialogFormsHooks';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';

function CreateSiteWizardDialog(): React.ReactElement {
  const {
    open,
    closeDialog,
    submitForm,
    isPosting,
    initialValues,
    releaseSubmitEvent,
    SUBMIT_EVENT_TYPE,
    handleFormValidation,
    validate,
  } = useCreateSite();
  const formName = 'site';
  useFormCleanupOnUnmount(formName);

  return (
    <Dialog open={open} fullWidth onClose={closeDialog}>
      <WithInputForm
        values={initialValues}
        errors={{}}
        handleFormValidation={handleFormValidation}
        handleFieldValidation={validate}
        onSubmit={submitForm}
        eventType={SUBMIT_EVENT_TYPE}
        dispatchSubmit={releaseSubmitEvent}
        formName={formName}
      >
        <DialogTitle>
          <FormattedMessage id='create_site' />
        </DialogTitle>
        <Divider />

        <DialogContent>
          <MemoSiteForm
            code={initialValues.code}
            label={initialValues.label}
          />
        </DialogContent>
        <Divider />
        <DialogActions>
          <DialogButtons
            isPosting={isPosting}
            onSubmit={releaseSubmitEvent}
            onCancel={closeDialog}
          />
        </DialogActions>
      </WithInputForm>
    </Dialog>
  );
}

export const MemoCreateSiteWizardDialog = memo(CreateSiteWizardDialog);
