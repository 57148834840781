import { VisibleFieldModel } from 'shared/domain/visibleField/visibleFieldModel';
import { IssueFieldNames } from 'shared/domain/issueForm/types/fieldNames';
import { HashMap } from 'shared/types/commonView';
import { Process } from 'shared/types/process';

export function getProcessesWithVisiblePositionOnMap(
  fieldVisibility: HashMap<VisibleFieldModel[]>
): Process[] {
  const result: Process[] = [];
  Object.keys(fieldVisibility).forEach((key) => {
    if (
      fieldVisibility[key].find(
        (field) => field.fieldName === IssueFieldNames.positionOnMap
      )
    ) {
      result.push(key as Process);
    }
  });

  return result;
}
