import { adjust, create, search } from 'assets/icons';
import { InfoBanner } from 'components/general/InfoBanner';
import React, { useMemo } from 'react';

export enum EmptyListReason {
  SEARCHPHRASE_MISMATCH = 'SEARCHPHRASE_MISMATCH',
  FILTER_MISMATCH = 'FILTER_MISMATCH',
  NOTHING_IN_DB = 'NOTHING_IN_DB',
}

type EmptyTableInfoProps = {
  emptyTableReason: EmptyListReason;
};

export const standardListComponentProps = {
  filterMismatch: {
    messageId: 'dashboard_no_issues_filters_view',
    dataQa: 'no-filtering-results',
    icon: adjust,
  },
  searchPhraseMismatch: {
    icon: search,
    dataQa: 'no_search_results',
    messageId: 'dashboard_no_issues_search_phrase_view',
  },
  nothingInDb: {
    messageId: 'general_no_data',
    dataQa: 'empty-list',
    icon: create,
  },
};

function createComponentProps(reason: EmptyListReason): {
  icon: string;
  dataQa: string;
  messageId: string;
} {
  switch (reason) {
    case EmptyListReason.SEARCHPHRASE_MISMATCH:
      return standardListComponentProps.searchPhraseMismatch;
    case EmptyListReason.NOTHING_IN_DB:
      return standardListComponentProps.nothingInDb;
    case EmptyListReason.FILTER_MISMATCH:
      return standardListComponentProps.filterMismatch;
  }
}

export function EmptyTableInfo(
  props: EmptyTableInfoProps
): React.ReactElement {
  const { emptyTableReason } = props;

  const componentProps = useMemo(
    () => createComponentProps(emptyTableReason),
    [emptyTableReason]
  );

  return <InfoBanner {...componentProps} />;
}
