import { HashMap } from 'shared/types/commonView';

export enum ISSUE_REQUEST_STATUS {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  NOT_FOUND = 'NOT_FOUND',
  FORBIDDEN = 'FORBIDDEN',
  UNKNOWN = 'UNKNOWN',
}

export type IssueStatusType = {
  status: ISSUE_REQUEST_STATUS;
  entity?: string;
};

export enum Disability {
  // This is an empty string for a reason, this way it's easy to convert it to boolean
  notDisabled = '',
  deleted = 'deleted',
  noAccess = 'noAccess',
  offline = 'offline',
}

const DisabledTooltipMessages: HashMap<string> = {
  [Disability.deleted]: 'tooltip_action_disabled_issue_deleted',
  [Disability.noAccess]: 'tooltip_action_forbidden',
  [Disability.offline]: 'general_offline_operation_unavailable',
};

export const disabilityToBoolean = (disability: Disability): boolean => {
  return Boolean(disability);
};

export const disabilityToMessage = (disability: Disability): string => {
  return (
    DisabledTooltipMessages[disability] || DisabledTooltipMessages.noAccess
  );
};

export const isDisabled = (
  deleted: boolean,
  noAccess: boolean
): Disability => {
  return deleted
    ? Disability.deleted
    : noAccess
      ? Disability.noAccess
      : Disability.notDisabled;
};

export const parseHashtag = (hashtag: string): string => {
  return `#${hashtag}`;
};
