import { StandardHeader } from 'components/table/ColumnHeader';
import { ColumnShapeSkeleton } from '../types';
import { InspectionStatusRenderer } from 'components/table/renderers/cell/inspectionStatus';

export function getInspectionStatusColumnShapeSkeleton(): ColumnShapeSkeleton {
  return {
    initialWidth: 120,
    flexGrow: 0,
    flexShrink: 0,
    headerRenderer: StandardHeader,
    cellRenderer: InspectionStatusRenderer,
    labelId: 'inspection_table_status',
    style: {
      padding: '0 16px',
      justifyContent: 'flex-start',
    },
  };
}
