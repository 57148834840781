import { Fetch } from 'hooks/api/service';
import { FetchMethod } from 'shared/types/logger';
import { logError } from 'setup/logger/logError';
import { LevelModel } from 'shared/domain/level/types/model';

type MapSignedRequestResponse = {
  signedRequest: string;
  imageSignedRequest?: string;
};
export const getMapSource = async (
  currentLevel: LevelModel | undefined
): Promise<string | undefined> => {
  if (!currentLevel) {
    return undefined;
  }

  if (currentLevel.maps.length === 0) {
    throw new Error('general_no_map_available');
  }

  const levelMap = currentLevel.maps[currentLevel.maps.length - 1];

  if (!levelMap) {
    logError('Cannot find level map.', { currentLevel });
    throw new Error('general_no_map_available');
  }

  const levelUrl = `/documentation/${levelMap.documentationId}/version/${levelMap.versionId}/download`;
  try {
    const documentationResponse: MapSignedRequestResponse =
      toMapSignedRequest(await Fetch[FetchMethod.GET_ASSET](levelUrl));
    return (
      documentationResponse.imageSignedRequest ||
      documentationResponse.signedRequest
    );
  } catch (e) {
    return undefined;
  }
};

export function toMapSignedRequest(
  response: any
): MapSignedRequestResponse {
  if (response.signedRequest) return response as MapSignedRequestResponse;
  throw new Error('Response does not have a signedRequest field.');
}
