import React, {
  forwardRef,
  memo,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
} from 'react';

import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useStyles } from './styles';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { ProtocolItemStatusSelectorProps } from './types';
import { useProtocolItemStatus } from 'components/common/withProtocolItemStatus';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

const COMPLIANCE_CHECK_PREFIX = 'inspection-compliance-check-item';

function ComplianceCheckResultSelector(
  {
    checklistItemId,
    handleChange,
    value,
  }: ProtocolItemStatusSelectorProps,
  ref: Ref<HTMLDivElement>
): ReactElement {
  const intl = useIntl();
  const classes = useStyles();
  const { setStatus } = useProtocolItemStatus();

  useEffect(() => {
    setStatus(value || ComplianceCheckResult.unset);
  }, [value, setStatus]);

  const selfHandleChange = useCallback(
    (_, value: ComplianceCheckResult | undefined) => {
      handleChange(_, value || ComplianceCheckResult.unset);
    },
    [handleChange]
  );

  return (
    <ToggleButtonGroup
      ref={ref}
      id={`${COMPLIANCE_CHECK_PREFIX}-${checklistItemId}`}
      color='primary'
      aria-label='outlined primary button group'
      className={classes.group}
      value={value}
      exclusive
      onChange={selfHandleChange}
    >
      <ToggleButton
        disableRipple
        className={cn(classes.button, classes.green)}
        value={ComplianceCheckResult.passed}
      >
        <span className={classes.toggleButtonText}>
          {intl.formatMessage({ id: 'general_yes' })}
        </span>
      </ToggleButton>
      <ToggleButton
        disableRipple
        className={cn(classes.button, classes.red)}
        value={ComplianceCheckResult.notPassed}
      >
        <span className={classes.toggleButtonText}>
          {intl.formatMessage({ id: 'general_no' })}
        </span>
      </ToggleButton>
      <ToggleButton
        disableRipple
        className={classes.button}
        value={ComplianceCheckResult.notApplicable}
      >
        <span className={classes.toggleButtonText}>
          {intl.formatMessage({
            id: 'protocol_item_form_comatibility_result_neutral',
          })}
        </span>
      </ToggleButton>
    </ToggleButtonGroup>
  );
}

export const MemoComplianceCheckResultSelector = memo(
  forwardRef(ComplianceCheckResultSelector)
);
