import { findModelForEditing } from 'components/dataCreationForms/common';
import {
  EditContext,
  OpenEditDialogParams,
} from 'components/dataCreationForms/types';
import { makeHandleFormValidation } from 'components/dataCreationForms/validation';
import { useSites } from 'components/dataProviders/withSites';
import { useUsers } from 'components/dataProviders/withUsers';
import { getUpdatedMessageListener } from 'shared/domain/commonModel';
import { userEditOnViewToUserEditModel } from 'shared/domain/user/mapping/toModel';
import { userModelToUserOnView } from 'shared/domain/user/mapping/toView';
import { startUserEdit } from 'shared/domain/user/startUserEdit';
import { UserModel } from 'shared/domain/user/userModel';
import { useGetAllUsers } from 'hooks/useGetAllUsers';
import { useCancelConfirmation } from 'presentation/dialogForms/dialogFormsHooks';
import {
  ComponentType,
  ReactElement,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  displayGenericErrorToaster,
  genericErrorToaster,
} from 'redux/actions/toasterActions';
import { processesSelector } from 'redux/selectors/processes';
import { projectDataSelector } from 'redux/selectors/project';
import { ChannelNames } from 'shared/domain/channelNames';
import { Identificable } from 'shared/types/commonView';
import { CreatableUserRole, UserRole } from 'shared/types/userRole';
import { createUniqueId } from 'shared/utils/id/id';
import { UserEditOnView } from 'shared/domain/user/types/view';
import { editUserValidationSchema, validateEditUser } from '../validation';
import { debugLog } from 'shared/logger/debugLog';
const formName = 'user';
const SUBMIT_EVENT_TYPE = 'submit-user';
const WithEditUserContext = createContext<
  EditContext<UserEditOnView> | undefined
>(undefined);

function WithEditUser({
  children,
  onSuccessCallback,
}: {
  children: ReactElement;
  onSuccessCallback?: (_id: string, label: string) => void;
}): ReactElement {
  const {
    all: { items: users },
  } = useUsers();
  const intl = useIntl();
  const project = useSelector(projectDataSelector);
  const projectId = project._id;
  const organizationId = project.organizationId;
  const {
    sites: { items: sites },
    loading: loadingSites,
  } = useSites();
  const processes = useSelector(processesSelector);
  const history = useHistory();
  const [isPosting, setIsPosting] = useState<boolean>(false);

  const dispatch = useDispatch();
  const releaseSubmitEvent = useCallback(() => {
    window.dispatchEvent(new CustomEvent(SUBMIT_EVENT_TYPE));
  }, []);
  const { getAll: getAllUsers } = useGetAllUsers();
  const fetchUsers = getAllUsers;
  const [currentUserId, setCurrentUserId] = useState(
    useParams<{ id: string }>().id
  );

  const [existingValues, setExistingValues] = useState<
    UserEditOnView | undefined
  >(undefined);

  useEffect(() => {
    const existingUserModel = findModelForEditing(users, currentUserId);
    let userOnView;
    if (existingUserModel) {
      userOnView = userModelToUserOnView({
        userModel: existingUserModel,
        sites,
        processes,
        intl,
      });
      setExistingValues(userOnView);
    }
  }, [users, intl, currentUserId, processes, sites]);

  const [open, setOpen] = useState(false);

  const handleFormValidation = useMemo(() => {
    return makeHandleFormValidation(editUserValidationSchema);
  }, []);

  const openDialog = useCallback(({ _id }: OpenEditDialogParams) => {
    setOpen(true);
    setCurrentUserId(_id);
  }, []);
  const onSuccess = useCallback(
    (_id, label) => onSuccessCallback && onSuccessCallback(_id, label),
    [onSuccessCallback]
  );
  const confirmCancel = useCancelConfirmation(formName);
  const openConfirmationDialog = useCallback(async () => {
    const { cancelConfirmed } = await confirmCancel();
    if (!cancelConfirmed) {
      return;
    }
    setOpen(false);
  }, [confirmCancel]);

  const submitForm = useCallback(
    async (values: UserEditOnView) => {
      setIsPosting(true);

      const uniqueId = createUniqueId();
      const userEditModel = userEditOnViewToUserEditModel({
        userEditOnView: values,
        projectId,
        organizationId,
        previousValues: {
          role:
            (existingValues?.role._id as CreatableUserRole) ||
            UserRole.standard,
          sites: existingValues?.sites || [],
          processes: existingValues?.processes || [],
          label: existingValues?.label || '',
        },
      });
      return new Promise((resolve, reject) => {
        let timeout: ReturnType<typeof setTimeout>;
        const broadcast = getUpdatedMessageListener(
          async (message: UserModel) => {
            await fetchUsers();
            debugLog('WithEditUserContext informed about the update');
            broadcast.close();
            resolve(message);
            setOpen(false);
            clearTimeout(timeout);
          },
          (error: Error) => {
            debugLog('WithEditUserContext informed about the error');
            broadcast.close();
            reject(error);
            setIsPosting(false);
            displayGenericErrorToaster(dispatch);
            clearTimeout(timeout);
          },
          (message) => {
            return message.uniqueId === uniqueId;
          },
          ChannelNames.userChannel
        );
        timeout = setTimeout(() => {
          debugLog('WithEditUserContext Timeout');
          broadcast.close();
          reject(new Error('WithEditUser: Timeout on update.'));

          displayGenericErrorToaster(dispatch);
        }, 15000);

        startUserEdit(userEditModel, uniqueId);
      })
        .then((user: unknown): any => {
          if (user && (user as Identificable)._id) {
            setOpen(false);
            debugLog('WithEditUserContext push');

            // TODO we need proper state management here.
            // User permission changes are not stored in DB before HTTP request
            setTimeout(() => {
              history.push(`/user/${(user as Identificable)._id}`);
              setIsPosting(false);
            }, 1000);

            return user;
          }
        })
        .catch(() => {});
    },
    [
      dispatch,
      projectId,
      organizationId,
      existingValues?.sites,
      existingValues?.processes,
      existingValues?.role,
      existingValues?.label,
      fetchUsers,
      history,
    ]
  );

  const ctx: EditContext<UserEditOnView> = useMemo(() => {
    return {
      open,
      openDialog,
      closeDialog: openConfirmationDialog,
      submitForm,
      isPosting,
      existingValues,
      SUBMIT_EVENT_TYPE,
      releaseSubmitEvent,
      validate: validateEditUser,
      handleFormValidation,
      onSuccess,
      formName,
    };
  }, [
    openConfirmationDialog,
    existingValues,
    isPosting,
    open,
    openDialog,
    releaseSubmitEvent,
    submitForm,
    onSuccess,
    handleFormValidation,
  ]);

  return (
    <WithEditUserContext.Provider value={ctx}>
      {children}
    </WithEditUserContext.Provider>
  );
}

function useEditUser(): EditContext<UserEditOnView> {
  const context = useContext(WithEditUserContext);
  if (context === undefined) {
    throw new Error('useEditUser must be used within an EditUserContext');
  }
  return context;
}

const withEditUser =
  (Component: ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithEditUser>
      <Component {...props} />
    </WithEditUser>
  );

export { WithEditUser, useEditUser, withEditUser };
