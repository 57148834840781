import { useCallback, useContext, useEffect, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { ApexAxisChartSeries, ChartType } from 'shared/types/analytics';
import { ChartDetailsContext } from 'views/analytics/chartDetails/ChartDetailsProvider';
import { useCache } from 'hooks/useCache';
import { useMountedRef } from 'hooks/useMountRef';
import { useSelector } from 'react-redux';
import { StoreState } from 'setup/types/core';
import {
  DateRangeInterval,
  DetectedResolvedFilters,
  PresentedTypes,
} from 'shared/types/analytics';
import { HashMap } from 'shared/types/commonView';
import { isoToProjectTimezonedDate } from 'shared/utils/date/dates';
import { ClientContext } from 'with-client';
import { accessForAllRoles } from '../shared/access';
import { useDetectedResolvedFilters } from './filters';
import {
  ColorValue,
  DetectedResolvedLineColor,
  dataLabels,
  detailedViewDetectedResolvedChartOptions,
  fillBar,
  fillLine,
  listViewDetectedResolvedChartOptions,
} from './options';
import {
  DetectedResolvedChartContext,
  DetectedResolvedDto,
  DetectedResolvedState,
} from './types';

function createSeriesFromResponseWithFilters(
  res: DetectedResolvedDto,
  filters: DetectedResolvedFilters,
  intl: IntlShape
): ApexAxisChartSeries {
  const result: ApexAxisChartSeries = [];
  if (!res.length) {
    return result;
  }

  if (filters.presented.filters[0].value.detected) {
    result.push({
      type:
        filters.chartSettings.filters[0].value === ChartType.line
          ? ChartType.area
          : ChartType.bar,
      name: intl.formatMessage({ id: 'issue_type_detected' }),
      data: res.map((r) => r.issuesDetected),
    });
  }
  if (filters.presented.filters[0].value.resolved) {
    result.push({
      type:
        filters.chartSettings.filters[0].value === ChartType.line
          ? ChartType.line
          : ChartType.bar,
      name: intl.formatMessage({ id: 'issue_type_resolved' }),
      data: res.map((r) => r.issuesResolved),
    });
  }
  return result;
}

const categoriesFormatByInterval = {
  [DateRangeInterval.day]: {
    day: '2-digit' as '2-digit',
    month: 'short' as 'short',
    year: 'numeric' as 'numeric',
  },
  [DateRangeInterval.week]: {
    day: '2-digit' as '2-digit',
    month: 'short' as 'short',
    year: 'numeric' as 'numeric',
  },
  [DateRangeInterval.month]: {
    month: 'short' as 'short',
    year: 'numeric' as 'numeric',
  },
  [DateRangeInterval.year]: {
    year: 'numeric' as 'numeric',
  },
};

function createXAxisCategories(
  res: DetectedResolvedDto,
  interval: DateRangeInterval,
  intlContext: IntlShape,
  timezone: string
): string[] {
  return res.map((r) =>
    isoToProjectTimezonedDate(r[interval]!, timezone).toLocaleString(
      categoriesFormatByInterval[interval],
      {
        locale: intlContext.locale,
      }
    )
  );
}

function colorByPresented(presented: HashMap<boolean>): ColorValue[] {
  const colors: ColorValue[] = [];
  if (presented[PresentedTypes.detected]) {
    colors.push(DetectedResolvedLineColor[PresentedTypes.detected]);
  }
  if (presented[PresentedTypes.resolved]) {
    colors.push(DetectedResolvedLineColor[PresentedTypes.resolved]);
  }
  return colors;
}

export function useDetectedResolved(): DetectedResolvedChartContext {
  const mountedRef = useMountedRef();

  const client = useContext(ClientContext);
  const timezone = useSelector((state: StoreState) => {
    return state.projectData.timezone;
  });

  const { fetchData, setDisabled, updatedAt } =
    useCache('DetectedResolved');
  const forceUpdate = useCallback(() => setDisabled(true), [setDisabled]);

  const isDetailedView = useContext(ChartDetailsContext);

  const intl = useIntl();
  const [loading, setLoading] = useState<boolean>(true);
  const [lastLoaded, setLastLoaded] = useState<number>(Date.now());

  const load = useCallback(() => {
    setLastLoaded(Date.now());
  }, []);

  const filters = useDetectedResolvedFilters();
  const options = isDetailedView
    ? detailedViewDetectedResolvedChartOptions
    : listViewDetectedResolvedChartOptions;

  const [state, setState] = useState<DetectedResolvedState>({
    series: [],
    options: options,
  });

  const chartType = filters.chartSettings.filters[0].value as ChartType;
  const availableTypes = filters.chartSettings.filters[0]
    .available as ChartType[];

  useEffect(() => {
    if (!mountedRef.current) {
      return;
    }
    setLoading(true);
    const urlParams = filters.dataScope.filters
      .map((filter) => filter.toUrlQuery!(filter.value))
      .join('');

    fetchData(client.fetchResolvedDetected.bind(client), urlParams).then(
      (r) => {
        if (!mountedRef.current) {
          return;
        }
        setState((prev) => {
          const { hasDataLabels } = filters.chartSettings.filters[1]
            .value as HashMap<boolean>;
          return {
            ...prev,
            series: createSeriesFromResponseWithFilters(
              r.data,
              filters,
              intl
            ),
            options: {
              ...options,
              fill:
                filters.chartSettings.filters[0].value === ChartType.bar
                  ? fillBar
                  : fillLine,
              colors: colorByPresented(filters.presented.filters[0].value),
              dataLabels: hasDataLabels ? dataLabels : { enabled: false },
              xaxis: {
                ...options.xaxis,
                categories: createXAxisCategories(
                  r.data,
                  filters.dataScope.filters[0].value as DateRangeInterval,
                  intl,
                  timezone
                ),
              },
            },
          };
        });
        setLoading(false);
      }
    );
  }, [
    client,
    filters,
    intl,
    fetchData,
    lastLoaded,
    options,
    timezone,
    mountedRef,
  ]);

  return {
    state,
    filters,
    filtersDisabledOffline: true,
    titleId: 'detected_resolved_chart',
    chartType,
    availableTypes,
    canAccess: accessForAllRoles,
    loading,
    load,
    forceUpdate,
    updatedAt,
  };
}
