import { ReactElement, useCallback, useMemo } from 'react';

import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import fileDownload from 'helpers/fileDownload';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDialog } from 'components/core/Dialog/common/DialogContext';
import { useDisplayedFilesContext } from '../../dataProviders/withDisplayedFiles';
import { useGraphicUploaderContext } from '.';
import { useEditDescriptionForm } from '../../dataCreationForms/withEditDescriptionForm';
import { useDispatch } from 'react-redux';
import { genericErrorToaster } from 'redux/actions/toasterActions';
import { ButtonIcon } from 'components/general';

type ThumbnailCardDescriptionProps = {
  showActions: boolean;
  description: string;
  id: number;
  onMouseLeave: () => void;
};

export function ThumbnailCardDescription({
  showActions,
  description,
  id,
  onMouseLeave,
}: ThumbnailCardDescriptionProps): ReactElement {
  const dispatch = useDispatch();
  const { config, removeFile, drawings } = useGraphicUploaderContext();
  const fileSelector = useMemo(() => {
    return function fileAtIndexSelector<T>(files: T[]): T {
      return files[id];
    };
  }, [id]);
  const { useDisplayedFiles } = useDisplayedFilesContext();
  const { setIndex, openDialog } = useEditDescriptionForm();
  const [file] = useDisplayedFiles(fileSelector);
  const intl = useIntl();
  const TOOLTIP_DELETE = intl.formatMessage({
    id: 'issue_preview_gallery_file_remove_label',
  });
  const TOOLTIP_DOWNLOAD = intl.formatMessage({ id: 'general_download' });
  const TOOLTIP_EDIT = intl.formatMessage({ id: 'general_edit' });

  const createDialog = useDialog();

  const hideDescriptionActions = onMouseLeave;

  const download = useCallback(() => {
    hideDescriptionActions();
    const src = drawings[id]?.mergedImageUrl || file.downloadSrc;
    fileDownload(src, file.title, file.data?.extension).catch(() =>
      dispatch(genericErrorToaster)
    );
  }, [file, drawings, id, hideDescriptionActions, dispatch]);

  const handleDescriptionEdit = (): void => {
    if (config.previewOnly) {
      return;
    }

    setIndex(id);
    openDialog();
  };

  const handleRemove = useCallback(() => {
    hideDescriptionActions();
    createDialog({
      title: (
        <FormattedMessage id='dialog_confirmation_delete_document_title' />
      ),
      customControllerLabels: [
        'general_cancel',
        'dialog_confirmation_delete_document_title',
      ],
    }).then(() => {
      removeFile(id);
    });
  }, [id, hideDescriptionActions, createDialog, removeFile]);

  return showActions ? (
    <div className='description actions'>
      {!config.previewOnly && (
        <ButtonIcon
          onClick={handleRemove}
          aria-label='delete'
          title={TOOLTIP_DELETE}
        >
          <DeleteOutlinedIcon />
        </ButtonIcon>
      )}
      <ButtonIcon
        onClick={download}
        title={TOOLTIP_DOWNLOAD}
        aria-label='download'
      >
        <GetAppOutlinedIcon />
      </ButtonIcon>
      {!config.previewOnly && (
        <ButtonIcon
          onClick={handleDescriptionEdit}
          title={TOOLTIP_EDIT}
          aria-label='edit'
        >
          <EditOutlinedIcon />
        </ButtonIcon>
      )}
    </div>
  ) : (
    <div className='description'>{description}</div>
  );
}
