import db from './index';
import { clear as worktypesServiceClear } from './worktypesService';
import { broadcastClearWorktypes } from 'serviceWorker/broadcasts/worktypes';
import { entityDbClearFactory } from './entityDbClearFactory';
import { WorktypeEntity } from 'serviceWorker/repository/worktype/entity';
import {
  makeDefaultAddBatch,
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
} from './defaultDaoFactories';

export const clear = entityDbClearFactory(
  db,
  ['worktypes', 'worktypesService'],
  () => db.worktypes.clear(),
  worktypesServiceClear,
  broadcastClearWorktypes
);

export const quantity = makeDefaultCount<WorktypeEntity>(db, db.worktypes);

export const get = makeDefaultGetMany<WorktypeEntity>(db, db.worktypes);

export const getOne = makeDefaultGetOne<string, WorktypeEntity>(
  db,
  db.worktypes,
  '_id'
);

export const addBatch = makeDefaultAddBatch<WorktypeEntity>(
  db,
  db.worktypes
);

export const updateBatch = addBatch;

export const removeBatch = makeDefaultRemoveBatch<string, WorktypeEntity>(
  db,
  db.worktypes,
  '_id'
);
