import { IssueInDto } from 'shared/dtos/in/issue';
import { Data } from '../factories/types';

export type Props = {
  offset: number;
  size: number;
  firstSyncKey?: string;
  pagesAmount?: number;
};

export type PullIssues = (
  data: Data,
  abortController: AbortController,
  props?: Props
) => void;

export type IssuesInDto = {
  hasMore: boolean;
  issues: IssueInDto[];
  syncKey: string;
  totalCount: number;
};

export enum BroadcastTypes {
  broadcastAll = 'all',
  broadcastSingle = 'single',
  broadcastList = 'list',
}

export type IssueBroadcast = {
  type: BroadcastTypes;
  action: CallableFunction;
};
