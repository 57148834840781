import { ReactElement, memo } from 'react';
import { Box } from '@mui/material';
import { MemoStatefulAutocompleteWithText } from 'components/dataCreationForms/withInputForm/statefulInputsWrapper';
import { FormFieldProps } from 'components/inspection/Form/types';
import { SiteOnView } from 'shared/domain/site/types/view';

const labelField: FormFieldProps<SiteOnView> & {
  defaultValue?: string;
} = {
  formKey: `label`,
  required: true,
  labelId: 'site_preview_label',
  fieldName: `site-label`,
  localStorageKey: `site-label`,
  dense: true,
  reserveSpaceForHelperText: true,
  'data-qa': 'site-form-label',
};

const codeField: FormFieldProps<SiteOnView> & {
  defaultValue?: string;
} = {
  formKey: `code`,
  required: true,
  labelId: 'site_preview_code',
  fieldName: `site-code`,
  localStorageKey: `site-code`,
  dense: false,
  reserveSpaceForHelperText: false,
  'data-qa': 'site-form-code',
};

type SiteFormProps = { code: string; label: string };

export function SiteForm({ label, code }: SiteFormProps): ReactElement {
  labelField.defaultValue = label;
  codeField.defaultValue = code;
  return (
    <Box>
      <MemoStatefulAutocompleteWithText {...labelField} />
      <MemoStatefulAutocompleteWithText {...codeField} />
    </Box>
  );
}

export const MemoSiteForm = memo(SiteForm);
