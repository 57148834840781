import { IssueOutDto } from 'shared/dtos/out/issue';
import { LabelledEntity } from 'shared/types/commonView';
import { StageInternalNames } from 'shared/types/stages';
import { CreateIssueModel, IssueModel } from './issueModel';

function identity<T>(x: T): T {
  return x;
}

function labelledEntityToString(
  labelledEntity: LabelledEntity | undefined | null
): string | undefined | null {
  if (labelledEntity === null) return labelledEntity;
  return labelledEntity?._id;
}
function makeCreateIssueModelExtendedDataToOutDtoMap(): {
  [key in keyof IssueModel['extendedData']]: (p: any) => any;
} {
  return {
    workTypes: identity,
    rootCauses: identity,
    estimatedCost: costs,
    finalCost: costs,
    targetCompletionDate: identity,
    finalCompletionDate: identity,
    solutionProposal: identity,
    impact: identity,
    environmentalAspect: identity,
    effect: identity,
    hazardCategory: identity,
    expectedFine: identity,
    imposedFine: identity,
    solutionMethod: identity,
    subcontractorRepresentative: identity,
    decisionToImposeFine: identity,
    circumstances: identity,
    proposedCorrectiveAction: identity,
    costCode: identity,
    daysOfInabilityToWork: identity,
    personUnableToWork: identity,
    spilledSubstance: identity,
    waterLeakageScale: identity,
    soilLeakageScale: identity,
    contaminatedSoilScale: identity,
    targetStartDate: identity,
    finalStartDate: identity,
    executedByCompanies: identity,
    targetAmount: identity,
    completedAmount: identity,
    numberOfEmployees: identity,
  };
}

function makeCreateIssueModelPrimaryDataToOutDtoMap(): {
  [key in keyof IssueModel['primaryData']]: (p: any) => any;
} {
  return {
    site: identity,
    level: identity,
    title: identity,
    assignee: labelledEntityToString,
    description: identity,
    detectionDate: identity,
    subcontractors: identity,
    contractNumbers: identity,
    positionOnMap: identity,
    executor: labelledEntityToString,
    selectedLocationType: identity,
    finalAreas: identity,
    targetAreas: identity,
  };
}

function costs(costs: any[]): any {
  return costs.map((cost) => {
    return {
      ...cost,
      coveredBy: cost.coveredBy?._id,
    };
  });
}

export function createIssueModelToOutDto(
  createModel: CreateIssueModel
): IssueOutDto {
  const outDto: IssueOutDto = {
    process: createModel.process,
    stage: StageInternalNames.draft,
    protocolItem: createModel.protocolItem,
    inspection: createModel.inspection,
  } as any;
  if (createModel.extendedData) {
    const mappings = makeCreateIssueModelExtendedDataToOutDtoMap();
    outDto.extendedData = {} as IssueOutDto['extendedData'];
    const keys = Object.keys(createModel.extendedData);
    // @ts-ignore Object.keys defined badly
    keys.forEach((key: keyof CreateIssueModel['extendedData']) => {
      if (mappings[key] && createModel.extendedData?.[key] !== undefined) {
        // @ts-ignore Object.keys defined badly
        outDto.extendedData[key] = mappings[key](
          // @ts-ignore Object.keys defined badly
          createModel.extendedData[key]
        );
      }
    });
  }
  if (createModel.primaryData) {
    const mappings = makeCreateIssueModelPrimaryDataToOutDtoMap();
    outDto.primaryData = {} as IssueOutDto['primaryData'];
    const keys = Object.keys(createModel.primaryData);
    // @ts-ignore Object.keys defined badly
    keys.forEach((key: keyof CreateIssueModel['primaryData']) => {
      if (mappings[key] && createModel.primaryData?.[key] !== undefined) {
        // @ts-ignore Object.keys defined badly
        outDto.primaryData[key] = mappings[key](
          // @ts-ignore Object.keys defined badly
          createModel.primaryData[key]
        );
      }
    });
  }

  return outDto;
}
