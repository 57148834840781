import {
  HttpRequestModelType,
  HttpRequestStatus,
} from 'shared/domain/httpRequest/httpRequestModel';
import { levelEditModelToOutDto } from 'shared/domain/level/mapping/toDto';
import { LevelEditModel } from 'shared/domain/level/types/model';
import { EditLevelUseCase } from 'shared/domain/level/types/useCases';
import { LevelEntity } from 'shared/domain/level/types/entity';
import { add as addRequest } from 'serviceWorker/db/httpRequests';
import { getOne as getLevel } from 'serviceWorker/db/levels';
import { debugLog } from 'shared/logger/debugLog';
import { LevelHttpEditRequestModel } from '../httpQueue/level/types';

export class LevelEditor implements EditLevelUseCase {
  constructor(
    private addRequest: (
      request: LevelHttpEditRequestModel
    ) => Promise<any>,
    private getLevel: (id: string) => Promise<LevelEntity | undefined>
  ) {}

  async execute(
    levelEditModel: LevelEditModel,
    uniqueId: string
  ): Promise<void> {
    debugLog('EditLevelUseCase', levelEditModel);
    const levelEntity = await this.getLevel(levelEditModel._id);
    if (!levelEntity) {
      throw new Error('Cannot find level.');
    }
    const levelChange = levelEditModelToOutDto(
      levelEditModel,
      levelEntity
    );
    const url = `/project/${levelEditModel.projectId}/level`;
    const request: LevelHttpEditRequestModel = {
      createdAt: Date.now(),
      method: 'PUT',
      data: {
        changes: [levelChange],
        projectId: levelEditModel.projectId,
        uniqueId: uniqueId,
      },
      url,
      entityType: HttpRequestModelType.level,
      status: HttpRequestStatus.NEW,
    };

    await this.addRequest(request);
  }
}

export const levelEditor = new LevelEditor(addRequest, getLevel);
