import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react';
import {
  DisplayedFile,
  DisplayedFilesStore,
} from '../../common/gallery/filesStore';

type DisplayedFilesContextType<K = object> = {
  filesStore: DisplayedFilesStore<K>;
  useDisplayedFiles: <T>(
    selector: (files: DisplayedFile<K>[]) => T
  ) => [T, (value: DisplayedFile<K>[]) => void];
  downloadFile: (file: DisplayedFile<K>) => void;
};
const DisplayedFilesContext = createContext<
  DisplayedFilesContextType<any> | undefined
>(undefined);

export function WithDisplayedFiles<K>({
  filesStore,
  useDisplayedFiles,
  downloadFile,
  children,
}: PropsWithChildren<DisplayedFilesContextType<K>>): ReactElement {
  const ctx = useMemo(() => {
    return { filesStore, useDisplayedFiles, downloadFile };
  }, [filesStore, useDisplayedFiles, downloadFile]);

  return (
    <DisplayedFilesContext.Provider value={ctx}>
      {children}
    </DisplayedFilesContext.Provider>
  );
}

export function useDisplayedFilesContext<
  K = object,
>(): DisplayedFilesContextType<K> {
  const context: DisplayedFilesContextType<K> | undefined = useContext(
    DisplayedFilesContext
  );
  if (context === undefined) {
    throw new Error(
      'useDisplayedFilesContext must be used within a useDisplayedFilesContextProvider'
    );
  }
  return context;
}
