import React, { ReactElement } from 'react';
import { ThousandSeparatorFormat } from 'components/general/ThousandSeparatorFormat/ThousandSeparatorFormat';
import { CostValueCell } from 'views/issueTable/tableScheme/makeColumn';

export function CostValueCellRenderer({
  decimalPlaces,
}: {
  decimalPlaces: number;
}): (value: { value: number }) => ReactElement {
  return ({ value }): ReactElement => (
    <CostValueCell>
      <ThousandSeparatorFormat
        thousandSeparator={'.'}
        value={value}
        displayType={'text'}
        decimalScale={decimalPlaces}
        renderText={(text): string => `${text}`}
      />
    </CostValueCell>
  );
}
