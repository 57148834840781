import Dexie, { PromiseExtended } from 'dexie';
import db from './index';
import { clear as documentationsServiceClear } from './documentationsService';
import { getErrorMsg, logWithRethrow, Operation } from './helpers';
import { wrapQuery } from './queryWrapper';
import { entityDbClearFactory } from './entityDbClearFactory';
import { broadcastClearDocumentations } from 'serviceWorker/broadcasts/documentations';
import {
  DocumentationEntity,
  DocumentationInsertEntity,
  DocumentationLocalInsertEntity,
} from 'serviceWorker/repository/documentation/entity';
import { DocumentationInDto } from 'shared/dtos/in/documentation';
import { documentationInDtoToCreateEntity } from 'serviceWorker/repository/documentation/mappings';
import { nowISO } from 'shared/utils/date/dates';
import { UploadStatus } from 'shared/types/uploadStatus';
import { SyncStatus } from 'shared/domain/entitySyncStatus/syncStatus';
import { Changes } from 'shared/types/commonEntities';
import {
  makeDefaultCount,
  makeDefaultGetMany,
  makeDefaultGetManyByIds,
  makeDefaultGetOne,
  makeDefaultRemoveBatch,
  makeGetManyUndeleted,
} from './defaultDaoFactories';
import { normalizeForSearch } from 'shared/utils/search';
import { LogLevel } from 'shared/types/logger';

export const clear = entityDbClearFactory(
  db,
  ['documentations', 'documentationsService'],
  () => db.documentations.clear(),
  documentationsServiceClear,
  broadcastClearDocumentations
);

const update = wrapQuery(
  db,
  (
    documentationFindStrategy: CallableFunction,
    data: DocumentationInsertEntity
  ): PromiseExtended<any> => {
    return documentationFindStrategy().then(
      (documentation: DocumentationEntity) => {
        if (!documentation) {
          throw new Error('Cannot find document to update');
        } else {
          const documentationWithLocalData =
            keepLocalDataOnUnuploadedStatus(data, documentation);

          return db.documentations.update(
            documentation.localId,
            documentationWithLocalData
          );
        }
      }
    );
  }
);

export const quantity = makeDefaultCount<DocumentationEntity>(
  db,
  db.documentations
);

export const get = makeDefaultGetMany<DocumentationEntity>(
  db,
  db.documentations
);

export const getOneByRemote = makeDefaultGetOne<
  string,
  DocumentationEntity
>(db, db.documentations, '_id');

export const getOne = makeDefaultGetOne<number, DocumentationEntity>(
  db,
  db.documentations,
  'localId'
);

export const getByParentId = makeGetManyUndeleted<
  string,
  DocumentationEntity
>(db, db.documentations, 'parentId');

export const getByIds = makeDefaultGetManyByIds<
  number,
  DocumentationEntity
>(db, db.documentations, 'localId');

export const getSearched = wrapQuery(
  db,
  async (searchPhrase: string): Promise<DocumentationEntity[]> => {
    return db.documentations
      .toArray()
      .then((docs) => {
        return docs.filter(
          (doc: DocumentationEntity) =>
            normalizeForSearch(doc.name).includes(searchPhrase) ||
            normalizeForSearch(doc.number || '').includes(searchPhrase)
        );
      })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.filter, 'documentations'),
          errorObj: e,
          additionalInfo: { query: { searchPhrase } },
        })
      );
  }
);

export const fastAddBatch = wrapQuery(
  db,
  (data: DocumentationInDto[]): Promise<any> => {
    const toInsert = data.map((documentation) => {
      return documentationInDtoToCreateEntity(documentation);
    });
    return (
      db.documentations
        // @ts-ignore DocumentationEntityToCreate localId is an autoincrement
        .bulkPut(toInsert)
        .catch((e) => {
          logWithRethrow({
            logLevel: LogLevel.INFO,
            msg: getErrorMsg(Operation.upsertBatch, 'documentations'),
            errorObj: e,
            additionalInfo: { query: { data } },
          });
        })
    );
  }
);

const upsertBatch = wrapQuery(
  db,
  async (data: DocumentationInDto[]): Promise<any> => {
    const primaryKeys = await db.documentations.orderBy('_id').keys();

    const duplicates: DocumentationInsertEntity[] = [];
    const docMap = {};
    const newData = data.filter((documentation) => {
      if (
        primaryKeys.includes(documentation._id) ||
        docMap[documentation._id]
      ) {
        docMap[documentation._id] = true;
        duplicates.push(documentationInDtoToCreateEntity(documentation));
        return false;
      }
      docMap[documentation._id] = true;
      return true;
    });

    await fastAddBatch(newData);

    const modifications: Promise<any>[] = duplicates.map(
      (documentation) => {
        return (
          db.documentations
            // @ts-ignore DocumentationEntityToCreate localId is an autoincrement
            .add(documentation)
            .catch((e) => {
              if (e.name === Dexie.errnames.Constraint) {
                const documentationFindStrategy = (): Promise<
                  DocumentationEntity | undefined
                > => {
                  if (!documentation._id) {
                    return Promise.resolve(undefined);
                  }
                  return getOneByRemote(documentation._id);
                };
                return update(documentationFindStrategy, documentation);
              }
              logWithRethrow({
                logLevel: LogLevel.INFO,
                msg: getErrorMsg(Operation.upsertBatch, 'documentations'),
                errorObj: e,
                additionalInfo: { query: { data } },
              });
            })
        );
      }
    );

    return Promise.all(modifications) as Promise<any[]>;
  }
);

export const addBatch = upsertBatch;
export const updateBatch = upsertBatch;

export const addLocal = wrapQuery(
  db,
  (data: DocumentationLocalInsertEntity): PromiseExtended<number> => {
    // @ts-ignore DocumentationEntityToCreate localId is an autoincrement
    return db.documentations.add(data).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.add, 'documentations'),
        errorObj: e,
        additionalInfo: { query: { data } },
      })
    );
  }
);

export const removeBatch = makeDefaultRemoveBatch<
  string,
  DocumentationEntity
>(db, db.documentations, '_id');

export const remove = wrapQuery(
  db,
  async (id: number): Promise<unknown> => {
    return db.documentations.delete(id).catch((e) =>
      logWithRethrow({
        logLevel: LogLevel.INFO,
        msg: getErrorMsg(Operation.remove, 'documentations'),
        errorObj: e,
        additionalInfo: { query: { id } },
      })
    );
  }
);

export const updateOne = wrapQuery(
  db,
  async (
    localId: number,
    changes: Changes<DocumentationEntity>,
    options?: { skipModifyTime: boolean }
  ): Promise<any> => {
    const modifiedAt = options?.skipModifyTime
      ? {}
      : { modifiedAt: nowISO() };
    return db.documentations
      .update(localId, { ...changes, ...modifiedAt })
      .catch((e) =>
        logWithRethrow({
          logLevel: LogLevel.INFO,
          msg: getErrorMsg(Operation.updateOne, 'documentations'),
          errorObj: e,
          additionalInfo: { query: changes },
        })
      );
  }
);

function keepLocalDataOnUnuploadedStatus(
  documentationToInsert: DocumentationInsertEntity,
  existingEntity: DocumentationEntity
): DocumentationInsertEntity {
  const versionToInsert = documentationToInsert.versions[0];
  const existingVersion = existingEntity.versions[0];
  if (!versionToInsert && existingVersion) {
    documentationToInsert.versions[0] = existingVersion;
    return documentationToInsert;
  }
  if (!versionToInsert) {
    return documentationToInsert;
  }
  if (
    existingVersion &&
    documentationToInsert._id &&
    existingEntity.syncStatus === SyncStatus.SUCCESS &&
    existingVersion.syncStatus === SyncStatus.SUCCESS &&
    versionToInsert.uploadStatus === UploadStatus.success &&
    existingVersion.localData
  ) {
    documentationToInsert.versions[0].localData = undefined;
  } else {
    documentationToInsert.versions[0].localData =
      existingVersion.localData;
  }

  if (
    existingEntity.syncStatus === SyncStatus.PENDING_DELETE &&
    !documentationToInsert.deleted
  ) {
    documentationToInsert.deleted = 1;
  }

  return documentationToInsert;
}
