import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';
import React, {
  Dispatch,
  PropsWithChildren,
  useMemo,
  useState,
  SetStateAction,
  ReactElement,
} from 'react';

type ProtocolItemStatusType = {
  status: ComplianceCheckResult;
  setStatus: Dispatch<SetStateAction<ComplianceCheckResult>>;
};

const ProtocolItemStatusContext = React.createContext<
  ProtocolItemStatusType | undefined
>(undefined);

const WithProtocolItemStatus: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [status, setStatus] = useState(ComplianceCheckResult.unset);

  const ctx = useMemo(() => {
    return {
      status,
      setStatus,
    };
  }, [status]);

  return (
    <ProtocolItemStatusContext.Provider value={ctx}>
      {children}
    </ProtocolItemStatusContext.Provider>
  );
};

function withProtocolItemStatus(Component: React.ComponentType) {
  return ({ ...props }): ReactElement => (
    <WithProtocolItemStatus>
      <Component {...props} />
    </WithProtocolItemStatus>
  );
}

function useProtocolItemStatus(): ProtocolItemStatusType {
  const context = React.useContext(ProtocolItemStatusContext);
  if (context === undefined) {
    throw new Error(
      'useProtocolItemStatus must be used within a ProtocolItemStatusContextProvider'
    );
  }
  return context;
}

export {
  WithProtocolItemStatus,
  withProtocolItemStatus,
  useProtocolItemStatus,
};
