import React, {
  PropsWithChildren,
  ReactElement,
  useMemo,
  useState,
} from 'react';
import { InspectionItems, InspectionsContextType } from './types';
import { useInspectionChannelListener } from '../../broadcastChannelListeners/withInspectionChannelListener';
import { useEntityDataSubscription } from '../../common/useEntityDataSubscription';
import { useGetAllInspections } from 'hooks/useGetAllInspections';
import { useSelector } from 'react-redux';
import { toUserRole } from 'redux/selectors/role';
import { UserRole } from 'shared/types/userRole';
import { InspectionModel } from 'shared/domain/inspection/inspectionModel';

const initialInspections: InspectionItems = {
  items: [],
  total: 0,
};

export const InspectionsContext = React.createContext<
  InspectionsContextType | undefined
>(undefined);

function getEmptyInspections(): Promise<{ items: InspectionModel[] }> {
  return Promise.resolve({ items: [] });
}

const WithInspections = ({
  children,
}: PropsWithChildren<{}>): ReactElement => {
  const currentUserRole = useSelector(toUserRole);
  const [inspections, setInspections] =
    useState<InspectionItems>(initialInspections);
  const [loading, setLoading] = useState<boolean>(true);
  const { subscribe } = useInspectionChannelListener();
  const { getAll: getAllInspections } = useGetAllInspections();

  useEntityDataSubscription({
    subscribe,
    getAll:
      currentUserRole && currentUserRole !== UserRole.standard
        ? getAllInspections
        : getEmptyInspections,
    setEntity: setInspections,
    setLoading,
    entityName: 'inspections',
  });

  const ctx = useMemo(
    () => ({
      inspections: inspections,
      loading: loading,
    }),
    [inspections, loading]
  );

  return (
    <InspectionsContext.Provider value={ctx}>
      {children}
    </InspectionsContext.Provider>
  );
};

const withInspections =
  (Component: React.ComponentType<any>) =>
  ({ ...props }): ReactElement => (
    <WithInspections>
      <Component {...props} />
    </WithInspections>
  );

function useInspections(): InspectionsContextType {
  const context = React.useContext(InspectionsContext);
  if (context === undefined) {
    throw new Error(
      'useInspections must be used within a InspectionsContextProvider'
    );
  }
  return context;
}

export { WithInspections, withInspections, useInspections };
