import {
  OrganizationEditOutDto,
  OrganizationCreateOutDto,
} from '../types/dto';
import {
  OrganizationEditModel,
  OrganizationCreateModel,
} from '../types/model';

export function organizationEditModelToEditOutDto(
  organization: OrganizationEditModel
): OrganizationEditOutDto {
  return {
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
  };
}

export function organizationCreateModelToCreateOutDto(
  organization: OrganizationCreateModel
): OrganizationCreateOutDto {
  return {
    name: organization.name,
    label: organization.label,
    contactEmail: organization.contactEmail,
  };
}
