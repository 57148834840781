import { IssueFormInDto } from 'shared/dtos/in/issueForm';
import { IssueFormEntity } from 'serviceWorker/repository/issueForm/issueForm';
import { getOne } from 'serviceWorker/db/permissions';
import {
  defaultConfig,
  generateHeaders,
  parseResponse,
} from '../../helpers/httpRequests';
import { getToken } from '../config';
import { HeadersData } from '../factories/types';
import { pull } from '../pull';
import { getSelectedProject } from '../selectedProject';

type ParseFormResponse = () => Promise<IssueFormInDto[]>;

export const fetchIssueForm = async (
  data: HeadersData,
  controller: AbortController
): Promise<IssueFormEntity[] | undefined> => {
  const { signal } = controller;
  if (shouldSkip(data, signal)) {
    return;
  }

  const selectedProject = await getSelectedProject();
  if (!selectedProject) {
    return;
  }
  const project = await getOne(selectedProject._id);
  if (!project) {
    return;
  }
  const token = getToken();
  if (!token) {
    return;
  }
  const builtUrl = `${data.api}/v2/project/${data.projectId}/issueForm`;

  return pull(builtUrl, {
    ...defaultConfig,
    method: 'GET',
    headers: generateHeaders(data),
    signal,
  }).then(<ParseFormResponse>parseResponse);
};

function shouldSkip(data: HeadersData, signal: AbortSignal): boolean {
  return !data.api || !data.projectId || signal.aborted;
}
