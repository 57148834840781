import { BroadcastChannel } from 'broadcast-channel';
import {
  Message,
  DomainMessagesTypes,
} from 'shared/domain/messages/message';
import { ChannelNames } from 'shared/domain/channelNames';

function isDocumentationUpdatedMessage(event: Message): boolean {
  return event?.type === DomainMessagesTypes.updatedEntity;
}

export function listenToDocumentationUpdatedMessage(
  successCallback: CallableFunction,
  errorCallback: CallableFunction,
  messageFilter: (event: Message) => boolean
): BroadcastChannel {
  const broadcast = new BroadcastChannel(
    ChannelNames.documentationChannel
  );

  broadcast.onmessage = (event: Message): void => {
    if (!isDocumentationUpdatedMessage(event) || !messageFilter(event)) {
      return;
    }

    if (event.error) {
      return errorCallback(event.error);
    }

    if (event.data) {
      return successCallback(event.data);
    }
  };

  return broadcast;
}
