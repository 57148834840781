import { createStyles, makeStyles } from '@mui/styles';
import { ComplianceCheckResult } from 'shared/domain/inspection/inspectionModel';

export const iconHeight = { height: '16px' };
export const removeIconStyle = {
  transform: 'rotateY(0deg) rotate(-45deg)',
  height: '20px',
};

export const iconHeightBig = { height: '24px', marginRight: '7px' };
export const removeIconStyleBig = {
  transform: 'rotateY(0deg) rotate(-45deg)',
  height: '32px',
  width: '32px',
  marginRight: '4px',
};

export const ComplianceCheckResultColor: Record<
  ComplianceCheckResult,
  string
> = {
  [ComplianceCheckResult.notPassed]: '#E53935',
  [ComplianceCheckResult.unset]: '#DADADA',
  [ComplianceCheckResult.passed]: '#00904C',
  [ComplianceCheckResult.notApplicable]: '#979797',
};

export const errorIconStyle = {
  // figma says 24px and for some reason the 'path' inside the icon is 24px when it's parent is 29px;
  height: '29px',
  width: '29px',
};

export const useStyles = makeStyles(() =>
  createStyles({
    icon: {
      border: '1px solid',
      borderRadius: '50%',
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
    },
    errorIcon: {
      width: '32px',
      height: '32px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#E53935',
      borderColor: 'transparent',
    },
    [ComplianceCheckResult.unset]: {
      borderColor: ComplianceCheckResultColor[ComplianceCheckResult.unset],
      color: ComplianceCheckResultColor[ComplianceCheckResult.unset],
    },
    [ComplianceCheckResult.passed]: {
      borderColor:
        ComplianceCheckResultColor[ComplianceCheckResult.passed],
      color: ComplianceCheckResultColor[ComplianceCheckResult.passed],
    },
    [ComplianceCheckResult.notPassed]: {
      borderColor:
        ComplianceCheckResultColor[ComplianceCheckResult.notPassed],
      color: ComplianceCheckResultColor[ComplianceCheckResult.notPassed],
    },
    [ComplianceCheckResult.notApplicable]: {
      borderColor:
        ComplianceCheckResultColor[ComplianceCheckResult.notApplicable],
      color:
        ComplianceCheckResultColor[ComplianceCheckResult.notApplicable],
    },
  })
);
